import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { ActivityCalendarModule } from '@web-frontend/shared/components/activity-calendar';

import { TableModule } from '@web-frontend/shared/components/table/table.module';
import { FilterModule } from '@web-frontend/shared/components/filter';
import { BasicMessageModule } from '@web-frontend/shared/components/basic-message';
import { LoadingModule } from '@web-frontend/shared/components/loading';
import { CreateActivityModule } from '@web-frontend/shared/components/create-activity';
import { PermissionsModule } from '@web-frontend/shared/components/permissions';
import { NotResultFoundModule } from '@web-frontend/shared/components/not-result-found';
import { RmFilterModule } from '@web-frontend/shared/components/globals/rm-filter';

import { ActivitiesComponent } from './activities.component';
import { KanbanModule } from '@web-frontend/shared/components/kanban';
import { RomaToolTipModule } from '@web-frontend/shared/directives/roma-tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import {
  ActivityOwnersDialog,
  ListMobileActivitiesComponent,
} from './roma-list-mobile-activities/list-mobile-activities.component';
import { RmBadgeModule } from '@web-frontend/shared/components/globals/rm-badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { StripeSnackbarSharedModule } from '@web-frontend/shared/components/snackbar/stripe-snackbar.module';
import { TranslateModule } from '@ngx-translate/core';
import { DownloadBtnModule } from '@web-frontend/shared/components/download-btn/download-btn.module';

@NgModule({
  declarations: [
    ActivitiesComponent,
    ListMobileActivitiesComponent,
    ActivityOwnersDialog,
  ],
  imports: [
    CommonModule,
    TableModule,
    FilterModule,
    TranslateModule.forChild(),
    BasicMessageModule,
    MatButtonModule,
    MatIconModule,
    LoadingModule,
    CreateActivityModule,
    MatDialogModule,
    ActivityCalendarModule,
    MatButtonToggleModule,
    PermissionsModule,
    KanbanModule,
    RomaToolTipModule,
    NotResultFoundModule,
    RmFilterModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    RmBadgeModule,
    MatCheckboxModule,
    MatChipsModule,
    StripeSnackbarSharedModule,
    DownloadBtnModule,
  ],
  exports: [
    ActivitiesComponent,
    ListMobileActivitiesComponent,
    ActivityOwnersDialog,
  ],
  providers: [DatePipe],
})
export class ActivitiesModule {}
