import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CreateCatalogueService } from '../../../shared/components/create-catalogue/create-catalogue.service';

import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject, Subscription } from 'rxjs';
import { filter, finalize, map, take } from 'rxjs/operators';

import {
  ConvertToKeyValue,
  DealsWrapperCustomPropertySort,
  DealsWrapperPropertySort,
  DynamicPropertyRef,
  Feature,
  FeatureUser,
  ICatalogue,
  ICustomProperty,
  IDeal,
  PaginateResponse,
  PaginateSort,
} from '@tacliatech/types';

import { TranslateService } from '@ngx-translate/core';

import {
  FilterItem,
  FilterItems,
} from '@web-frontend/shared/components/filter';

import { TableSortEvent } from '@web-frontend/shared/components/table';

import {
  AssetService,
  DealService,
  DynamicPropertyService,
  StorageService,
} from '@web-frontend/shared/services';

import { DatePipe } from '@angular/common';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { DeleteBySelectionModalComponent } from '@web-frontend/shared/components/delete-by-selection-modal/delete-by-selection-modal.component';
import { FilterComponent } from '@web-frontend/shared/components/filter/filter.component';
import { RmFilter } from '@web-frontend/shared/components/globals/rm-filter';
import { ModalMobileInfoComponent } from '@web-frontend/shared/components/modal-mobile-info';
import { VideoService } from '@web-frontend/shared/components/modal-video/video.service';
import { TableComponent } from '@web-frontend/shared/components/table/table.component';
import { FileWriteService } from '@web-frontend/shared/helpers/file-write';
import { AuthService } from '@web-frontend/shared/services/auth/auth.service';
import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import {
  CatalogueSearchService,
  CatalogueService,
} from '@web-frontend/shared/services/catalogue';
import { ProductService } from '@web-frontend/shared/services/products';
import { TutorialService } from '@web-frontend/shared/services/tutorial';
import { SandboxService } from '@web-frontend/shared/services/sandbox/sandbox.service';
import { ScrollService } from '@web-frontend/shared/services/scroll';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { CompareObjects, RemoveEmpty } from '@web-frontend/shared/utils';
import { Workbook } from 'exceljs';
import { SOURCE_WEB, SOURCE_WEB_OPTIONS } from './rates.const';
import { INIT_RATES_SEARCH_PARAMS } from './rates.types';
import { DownloadType } from '@web-frontend/shared/components/download-btn/download-btn.component';
import { DownloadBtnService } from '@web-frontend/shared/components/download-btn/download-btn.service';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { UserflowService } from '@web-frontend/shared/services/userflow/userflow.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AnalyticsService } from '../../../shared/services/analytics/analytics.service';
import {
  ConfirmationButtonClass,
  ConfirmationDialogData,
  ConfirmationOutput,
} from '../../../shared/components/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalService } from '../../../shared/components/confirmation-modal/confirmation-modal.service';

@Component({
  selector: 'roma-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatesComponent implements OnInit, AfterViewInit {
  tableMarginTop = '0px';
  distanceToTop = 0;
  error409 = 409;
  errorMessageOnlineBooking = 'OnlineBookingProfileItemsCannotBeEmptyError';

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.tableMarginTop = '0px';
    this.calcTableDistance();
  }

  @HostBinding('class.roma-page')
  hostClass = true;
  @ViewChild(FilterComponent)
  filterComponent: FilterComponent;

  @ViewChild(TableComponent)
  tableComponent: TableComponent;

  showMore = false;

  @Input()
  showTitle = true;

  @Input()
  showFilter = true;

  @Input()
  dealId = '';

  deal: IDeal = null;

  name: string;
  internalReference: string;
  description: string;
  type: string;
  notes: [];
  files: [];
  taxes: [];
  sellPrice: number;
  buyPrice: number;
  category: string;
  customer_id: string;
  createdBy: string;
  updatedBy: string;

  featureRef = Feature;
  featureRefUser = FeatureUser;

  authors: FilterItems = [];

  columns = [
    'select',
    'only_name',
    'internalReference',
    'typeRate',
    'description',
    'categoryObj',
    'usersRates',
    'sellPrice',
    'buyPrice',
  ];

  catalogues: MatTableDataSource<ICatalogue>;
  resultSearch: PaginateResponse<ICatalogue[]>;
  catalogueMap = new Map<string, ICatalogue>();
  totalPages: any[] = [];

  isDataLoaded = false;
  isLoading = false;
  isLoadingPaginate = false;

  customProperties: ICustomProperty[] = [];
  viewTypeActive: 'ROW' = 'ROW';
  allowShowButtonDownTakeMore = false;

  sourceSearch = SOURCE_WEB;
  sourceOptions!: RmFilter.Filter;
  searchParamsObj = {};

  user;

  private sub$ = new Subscription();
  private storeSearchParams = INIT_RATES_SEARCH_PARAMS;
  private busSearchParamsChanges = new Subject();
  public categories: FilterItems = [];

  searchParamsHasChange$ = this.busSearchParamsChanges
    .asObservable()
    .pipe(map((res) => !CompareObjects(INIT_RATES_SEARCH_PARAMS, res)));

  resize$ = this.sandBoxService.screenBusChannel$;

  showVideoTutorialButton: boolean;

  scrollDown$ = this.scrollService.scrollDown$;
  academyLink = '';
  showEmptyState = false;

  constructor(
    private catalogueService: CatalogueService,
    private catalogueSearchService: CatalogueSearchService,
    private createCatalogueService: CreateCatalogueService,
    private changeDetectionRef: ChangeDetectorRef,
    private i18n: TranslateService,
    private authService: AuthService,
    private assetService: AssetService,
    private activatedRouter: ActivatedRoute,
    private dealService: DealService,
    private video: VideoService,
    private fileWrite: FileWriteService,
    private datePipe: DatePipe,
    private sandBoxService: SandboxService,
    private amplitudeService: AmplitudeService,
    private domSanitzer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private router: Router,
    public productService: ProductService,
    private dynamicPropertyService: DynamicPropertyService,
    public dialog: MatDialog,
    private toastService: ToastService,
    private tutorialService: TutorialService,
    private scrollService: ScrollService,
    private readonly brazeService: BrazeService,
    private readonly userFlowService: UserflowService,
    private readonly downloadBtnService: DownloadBtnService,
    private readonly confirmationModalService: ConfirmationModalService,
    private readonly analyticsService: AnalyticsService
  ) {
    this.showTitle = true;
    this.showFilter = true;

    this.matIconRegistry.addSvgIcon(
      'table_icon',
      this.domSanitzer.bypassSecurityTrustResourceUrl(
        'assets/icons/table-horizontal.svg'
      )
    );
  }

  getLang() {
    return this.i18n.currentLang;
  }

  get searchParams() {
    return this.storeSearchParams;
  }

  set searchParams(value) {
    this.busSearchParamsChanges.next(value);
    this.storeSearchParams = value;
  }

  async ngOnInit() {
    // this.adminService.overflow = true;
    this.watchRouteParams();
    this.resolveRouteParams();
    await this.resolveInputParams();
    this.requestCatalogues({ loadingGlobal: true });
    this.watchRefreshList();
    this.authService.user$.subscribe((res) => (this.user = res));
    this.requestProducts();
    this.requestDynamicProperties();

    // TODO -> Once the video is added delete this logic from here and HTML
    // Logic to enable tutorial button
    this.sub$.add(
      this.tutorialService
        .get('rates')
        .pipe(
          finalize(() => {
            this.draw();
          })
        )
        .subscribe((res) => {
          this.academyLink = res?.academy;
          if (res?.source) {
            this.showVideoTutorialButton = !!res.source;
          } else {
            this.showVideoTutorialButton = false;
          }
        })
    );
  }
  ngOnDestroy() {
    // this.adminService.overflow = false;
  }

  ngAfterViewInit() {
    this.resolveOwners();
    this.resolveInputFilterParams();
    this.loadDeal();
  }

  private resolveOwners() {
    this.sub$.add(
      this.assetService.findUsersByAssetsShared().subscribe((res) => {
        this.authors = ConvertToKeyValue(res, 'name', '_id');

        this.draw();
      })
    );
  }

  changeAuthor(evt: FilterItems) {
    const authors = evt.map((author) => author.value);

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'authors[]': authors,
    };
    this.clear();
    this.requestCatalogues({ loadingGlobal: true });
  }

  loadDeal() {
    if (this.dealId) {
      this.dealService
        .findOne(this.dealId)

        .subscribe((res) => {
          if (res) {
            this.deal = res;
          }
        });
    }
  }

  hasQuerySearch() {
    return (
      this.searchParams.author ||
      this.searchParams['deals[]'] ||
      this.searchParams.owner ||
      this.searchParams.keyword
    );
  }

  changeViewType(evt: MatButtonToggleChange) {
    const { value } = evt;

    this.viewTypeActive = value;

    this.requestCatalogues({ loadingGlobal: true });
    this.draw();
  }

  changeCustomProperties(customProperties: { [key: string]: any }) {
    this.searchParams = {
      ...this.searchParams,
      customProperties: JSON.stringify(customProperties),
    };

    this.clear();

    this.requestCatalogues({ loadingGlobal: true });
  }

  private resolveInputFilterParams() {
    if (this.searchParams?.keyword) {
      try {
        this.filterComponent.input.nativeElement.value = this.searchParams?.keyword;
        this.draw();
      } catch (err) {}
    }
  }

  private watchRouteParams() {
    this.sub$.add(
      this.activatedRouter.queryParams
        .pipe(filter((res) => Object.keys(res)?.length > 0))
        .subscribe(() => {
          this.resolveRouteParams();
          this.clear();
          this.resolveInputFilterParams();
          this.requestCatalogues({ loadingGlobal: true });
        })
    );
  }

  private resolveRouteParams() {
    const paramsMap = this.activatedRouter.snapshot.queryParamMap;

    const id = paramsMap.get('id');

    if (id) {
      this.searchParams = {
        ...this.searchParams,
        keyword: id,
      };

      this.draw();
    }
  }

  private watchRefreshList() {
    this.sub$.add(
      this.createCatalogueService.refreshList$
        .pipe(
          map((res) => !!res),
          filter((res) => res)
        )
        .subscribe(() => {
          this.clear();

          this.searchParams = {
            ...this.searchParams,
            page: 1,
          };

          this.requestCatalogues({ loadingGlobal: true });
        })
    );
  }

  private requestCatalogues({
    loadingGlobal = false,
    loadingPagination = false,
  }): void {
    if (loadingGlobal) {
      this.isLoading = true;
      this.draw();
    }

    if (loadingPagination) {
      this.isLoadingPaginate = true;
      this.draw();
    }

    this.searchParams = {
      ...this.searchParams,
      company_id: StorageService.CompanyId,
    };

    const query = RemoveEmpty(this.searchParams);

    this.sub$.add(
      this.catalogueSearchService
        .search({
          ...query,
        })
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.isLoadingPaginate = false;
            this.calcTableDistance();
            this.resetTableMarginTop();
            this.draw();
          })
        )
        .subscribe((res) => {
          this.setCatalogue(res);
          this.showEmptyState =
            Object.keys(this.searchParamsObj).length === 0 &&
            res?.docs?.length === 0;
        })
    );
  }

  requestProducts() {
    this.productService.findAll().subscribe((res) => {
      this.categories = ConvertToKeyValue(res, 'name', '_id');
    });
  }

  nextPage() {
    this.searchParams = {
      ...this.searchParams,
      page: this.searchParams.page + 1,
    };

    this.requestCatalogues({ loadingGlobal: true, loadingPagination: true });
  }

  goToPage(page) {
    this.searchParams = {
      ...this.searchParams,
      page: page,
    };

    this.requestCatalogues({ loadingGlobal: true, loadingPagination: true });
  }

  previousPage() {
    this.searchParams = {
      ...this.searchParams,
      page: this.searchParams.page - 1,
    };

    this.requestCatalogues({ loadingGlobal: true, loadingPagination: true });
  }

  changeOwner(evt: FilterItem) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      owner: evt.value,
    };

    this.clear();
    this.requestCatalogues({ loadingGlobal: true });
  }

  changeInput(keyword: string) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      keyword: keyword ? keyword : null,
    };

    this.clear();
    this.requestCatalogues({ loadingGlobal: true });
  }

  changeDeleted(evt: boolean) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      takeDeleted: evt,
    };

    this.clear();
    this.requestCatalogues({ loadingGlobal: true });
  }

  changeToday(evt: boolean) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      takeToday: evt,
    };

    this.clear();
    this.requestCatalogues({ loadingGlobal: true });
  }

  changeDate(type: 'START' | 'END', value: Date) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      [type === 'START' ? 'from' : 'to']: value,
    };

    this.clear();
    this.requestCatalogues({ loadingGlobal: true });
  }

  changeCategories(evt) {
    // Extracting category id's coming from input as object of key:value pair
    const categories = evt.map((e) => e.value);

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      categories,
    };

    this.clear();
    this.requestCatalogues({ loadingGlobal: true });
  }

  addCatalogue() {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.catalogue_start,
    });
    this.userFlowService.sendEvent({
      event: AmplitudeEvents.catalogue_start,
    });
    this.brazeService.sendEvent({
      event: BrazeEventType.catalogue_start,
    });

    this.createCatalogueService
      .open({
        height: '800px',
        data: {
          deal: this.deal,
        },
      })
      .pipe(map((res) => !res))
      .subscribe();
  }

  sortChange(evt: TableSortEvent) {
    evt.active = evt.active === 'typeRate' ? 'type' : evt.active;
    evt.active = evt.active === 'usersRates' ? 'usersObj.name' : evt.active;
    evt.active = evt.active === 'categoryObj' ? 'categoryObj.name' : evt.active;

    let resCustomProperty = DealsWrapperCustomPropertySort(
      this.customProperties,
      evt.active
    );

    if (!resCustomProperty) {
      resCustomProperty = DealsWrapperPropertySort(evt.active);
    }

    this.clearSortOptions();

    // this.searchParams = {
    //   ...this.searchParams,
    //   page: 1,
    //   sortOptions: null,
    // };

    if (evt.direction) {
      this.searchParams = {
        ...this.searchParams,
        [`sortOptions[${resCustomProperty}]`]: evt.direction,
      };
    } else {
      this.searchParams = {
        ...this.searchParams,
        'sortOptions[created_at]': PaginateSort.DESC,
      };
    }

    this.clear();

    this.requestCatalogues({ loadingGlobal: true, loadingPagination: false });
  }

  editCatalogue(value: ICatalogue) {
    this.catalogueService.findOne(value._id).subscribe((data) => {
      if (data) {
        this.createCatalogueService
          .edit({ data: { catalogue: data } })
          .pipe(map((res) => !res))
          .subscribe();
      }
    });
  }

  showToastError(text: string, description: string): void {
    this.toastService.show({
      text: text,
      description: description,
      type: 'error',
      msDuration: 4000,
      link: 'rates.goToOnlineBooking',
      linkData: () => this.redirectToEditProducts(),
    });
  }

  removeCatalogue(value: ICatalogue) {
    this.sub$.add(
      this.catalogueService.removeOne(value._id).subscribe(
        () => {
          this.requestCatalogues({ loadingGlobal: true });
          this.toastService.show({
            text: 'catalogue.delete.success',
            type: 'success',
            msDuration: 4000,
          });
        },
        (error: HttpErrorResponse) => {
          const { error: errorResponse } = error;
          if (
            errorResponse.statusCode === this.error409 &&
            errorResponse.message === this.errorMessageOnlineBooking
          ) {
            this.showToastError(
              'rates.linkedItemToOnlineBookingErrorTitle',
              'rates.linkedItemToOnlineBookingError'
            );
          }
        }
      )
    );
  }

  takeMore() {
    this.searchParams = {
      ...this.searchParams,
      page: this.searchParams.page + 1,
    };

    this.requestCatalogues({ loadingPagination: true });
  }

  linkCatalogue(value: any) {
    this.router.navigate(['/admin/pof'], {
      queryParams: { view: 'catalogue', id: value._id },
    });
  }

  private setCatalogue(response: PaginateResponse<ICatalogue[]>) {
    this.clear();
    this.resultSearch = response;
    this.totalPages = [];
    let i;
    for (i = 1; i <= this.resultSearch.countPages; i++) {
      this.totalPages.push({
        page: i,
      });
    }

    for (const catalogue of response.docs) {
      this.catalogueMap.set(catalogue?._id, catalogue as ICatalogue);
    }

    if (response.hasNextPage) {
      this.allowShowButtonDownTakeMore = true;
    }

    this.source = Array.from(this.catalogueMap.values());
  }

  private set source(values: ICatalogue[]) {
    this.catalogues = new MatTableDataSource(values);
    this.draw();
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  private clearSortOptions() {
    const searchParams = {
      ...this.searchParams,
    };

    for (const prop in searchParams) {
      if (searchParams[prop]) {
        if (prop.match(/sortOptions/)) {
          delete searchParams[prop];
        }
      }
    }

    this.searchParams = searchParams;
  }

  private clear() {
    this.catalogueMap.clear();
    this.source = [];
  }

  private resolveInputParams() {
    return new Promise((res) => {
      this.searchParams = {
        ...this.searchParams,
        deal: this.dealId,
      };

      res(null);
    });
  }

  openVideo(type: string) {
    const data = {
      event: 'catalogue_TutorialVideo',
    };
    this.amplitudeService.sendTutorialEvent(data, type);
    this.video.open(type);
  }

  async downloadExcel() {
    this.amplitudeService.sendEvent({
      event: 'catalogue_excelDownload',
    });
    const workbook = new Workbook();
    const title = this.i18n.instant('settings.rates').replace('/', '+');

    const worksheet = workbook.addWorksheet(title);
    const header = [
      'Id',
      this.i18n.instant('table.colum.name'),
      this.i18n.instant('table.colum.refInternal'),
      this.i18n.instant('table.colum.description'),
      this.i18n.instant('table.colum.category'),
      this.i18n.instant('table.colum.sellPrice'),
      this.i18n.instant('table.colum.buyPrice'),
    ];

    const notAvailable = this.i18n.instant('generateDocument.notAvailable');

    const customShow = JSON.parse(
      StorageService.CustomProperty('ACTIVITY_MODULE')
    ) as ICustomProperty[];

    if (customShow?.length > 0) {
      for (const param of customShow) {
        header.push(param.name);
      }
    }

    const headerRow = worksheet.addRow(header);
    headerRow.font = {
      color: { argb: '003c48ec' },
      bold: true,
      size: 12,
    };
    headerRow.alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };
    const query = RemoveEmpty(this.searchParams);

    this.sub$.add(
      this.catalogueSearchService
        .search({
          ...query,
          limit: 999999999,
        })
        .pipe(take(1))
        .subscribe((res2) => {
          for (const x1 of res2.docs) {
            const temp = [];
            temp.push(x1._id);
            temp.push(x1.name);
            temp.push(
              x1.internalReference ? x1.internalReference : notAvailable
            );
            temp.push(x1.description ? x1.description : notAvailable);
            temp.push(x1?.categoryObj ? x1?.categoryObj?.name : notAvailable);
            temp.push(x1.sellPrice ? x1.sellPrice : notAvailable);
            temp.push(x1.buyPrice ? x1.buyPrice : notAvailable);

            const row = worksheet.addRow(temp);
            row.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            };
          }

          worksheet.columns.forEach(function (column) {
            column.width = 27;
          });

          //add data and file name and download
          workbook.xlsx.writeBuffer().then(async (data) => {
            const blob = new Blob([data], {
              type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            this.fileWrite
              .writeFile({
                path: `${this.i18n.instant(
                  'settings.rates'
                )}-${this.datePipe.transform(new Date(), 'dd-MM-yyyy')}.xlsx`,
                blob,
              })
              .then(() => {
                // this.toastService.show({
                //   text: this.i18n.instant('general.savedFile'),
                //   type: 'success',
                //   msDuration: 4000,
                // });
                this.downloadBtnService.downloadedSuccessfully();
              });
          });
        })
    );
  }

  private requestDynamicProperties() {
    this.sub$.add(
      this.dynamicPropertyService
        .findDynamicProperties(
          StorageService.CompanyId,
          DynamicPropertyRef.Rates
        )
        .subscribe((res) => {
          this.customProperties = res;
          this.sourceOptions = SOURCE_WEB_OPTIONS({
            assetService: this.assetService,
            productService: this.productService,
            customProperties: res,
            i18n: this.i18n,
          });
          this.draw();
        })
    );
  }

  async applyFilters(evt: {
    authors: Array<{ value: string }>;
    categories: Array<{ value: string }>;
    takeDeleted: boolean;
    customProperties: { [key: string]: any };
  }) {
    const authors = evt?.authors?.length
      ? evt.authors.map((type) => type.value)
      : [];
    const categories = evt?.categories?.length
      ? evt.categories.map((type) => type.value)
      : [];
    const takeDeleted = evt.takeDeleted;

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'authors[]': authors,
      categories: categories,
      takeDeleted: takeDeleted,
      customProperties: JSON.stringify(evt.customProperties),
    };

    this.clear();
    this.resolveRouteParams();
    await this.resolveInputParams();
    this.requestCatalogues({ loadingGlobal: true });
  }

  calcTableDistance() {
    setTimeout(() => {
      const containerTable = document.getElementById('containerTable');
      this.distanceToTop = containerTable?.getBoundingClientRect().top - 74;
    }, 1000);
  }

  checkScroll(e) {
    if (e.target.scrollHeight > 1080) {
      if (e.target.scrollTop <= this.distanceToTop) {
        this.tableMarginTop = '-' + e.target.scrollTop + 'px';
      } else {
        this.tableMarginTop = '-' + this.distanceToTop + 'px';
      }
    }
  }

  private resetTableMarginTop() {
    this.tableMarginTop = '0px';
  }
  /**********************************SELECTION********************************* */
  entriesForDelete!: any[];
  deleteMessage = false;

  unselectItems() {
    this.entriesForDelete = [];
    this.deleteMessage = false;
    this.tableComponent.clearChecks();
  }

  deleteMany(evt: any[]) {
    this.entriesForDelete = evt;
    if (evt.length > 0) {
      this.deleteMessage = true;
    } else {
      this.deleteMessage = false;
    }
  }

  openDeleteManyDialog(): void {
    const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
      panelClass: 'delete-by-selection-modal',
      data: { isPlural: this.entriesForDelete?.length > 1 },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'EXECUTE') {
        this.deleteSelection(this.entriesForDelete);
      }
    });
  }

  async downloadExcelSelection() {
    this.amplitudeService.sendEvent({
      event: 'catalogue_excelDownload',
    });
    const workbook = new Workbook();
    const title = this.i18n.instant('settings.rates').replace('/', '+');

    const worksheet = workbook.addWorksheet(title);

    const header = [
      'Id',
      this.i18n.instant('table.colum.name'),
      this.i18n.instant('table.colum.refInternal'),
      this.i18n.instant('table.colum.description'),
      this.i18n.instant('table.colum.category'),
      this.i18n.instant('table.colum.sellPrice'),
      this.i18n.instant('table.colum.buyPrice'),
    ];

    const notAvailable = this.i18n.instant('generateDocument.notAvailable');

    const customShow = JSON.parse(
      StorageService.CustomProperty('ACTIVITY_MODULE')
    ) as ICustomProperty[];

    if (customShow?.length > 0) {
      for (const param of customShow) {
        header.push(param.name);
      }
    }

    const headerRow = worksheet.addRow(header);
    headerRow.font = {
      color: { argb: '003c48ec' },
      bold: true,
      size: 12,
    };
    headerRow.alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };

    for (const x1 of this.entriesForDelete) {
      const temp = [];
      temp.push(x1._id);
      temp.push(x1.name);
      temp.push(x1.internalReference ? x1.internalReference : notAvailable);
      temp.push(x1.description ? x1.description : notAvailable);
      temp.push(x1?.categoryObj ? x1?.categoryObj?.name : notAvailable);
      temp.push(x1.sellPrice ? x1.sellPrice : notAvailable);
      temp.push(x1.buyPrice ? x1.buyPrice : notAvailable);

      const row = worksheet.addRow(temp);
      row.alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
    }

    worksheet.columns.forEach(function (column) {
      column.width = 27;
    });

    //add data and file name and download
    workbook.xlsx.writeBuffer().then(async (data) => {
      const blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      this.fileWrite
        .writeFile({
          path: `${this.i18n.instant(
            'settings.rates'
          )}-${this.datePipe.transform(new Date(), 'dd-MM-yyyy')}.xlsx`,
          blob,
        })
        .then(() => {
          // this.toastService.show({
          //   text: this.i18n.instant('general.savedFile'),
          //   type: 'success',
          //   msDuration: 4000,
          // });

          this.downloadBtnService.downloadedSuccessfully();
        });
    });
  }

  redirectToEditProducts(): void {
    this.router.navigate(['/admin/online-booking/edit-products']);
  }

  deleteSelection(evt: any[]): void {
    const ids = evt.map((entry) => entry._id);
    this.sub$.add(
      this.catalogueService
        .deleteMany({
          ids: ids,
        })
        .pipe(
          finalize(() => {
            this.deleteMessage = false;
            this.entriesForDelete = [];
          })
        )
        .subscribe(
          () => {
            this.toastService.show({
              text: 'general.deleteManyCorrect',
              type: 'success',
              msDuration: 4000,
              icon: 'assets/icons/kanban/delete.svg',
            });
            this.amplitudeService.sendEvent({
              event: 'catalogue_list_delete',
            });
            this.requestCatalogues({ loadingGlobal: true });
          },
          (error: HttpErrorResponse) => {
            const { error: errorResponse } = error;
            if (
              errorResponse.statusCode === this.error409 &&
              errorResponse.message === this.errorMessageOnlineBooking
            ) {
              const text =
                evt.length === 1
                  ? 'rates.linkedItemToOnlineBookingErrorTitle'
                  : 'rates.linkedItemsToOnlineBookingErrorTitle';
              const description =
                evt.length === 1
                  ? 'rates.linkedItemToOnlineBookingError'
                  : 'rates.linkedItemsToOnlineBookingError';
              this.showToastError(text, description);
            }
          }
        )
    );
  }

  changeFilters(evt: RmFilter.Changes) {
    if (evt.type === 'CHANGE' || evt.type === 'INPUT_CHANGE') {
      const { queryParams } = evt;
      this.searchParamsObj = { ...queryParams };

      if (
        JSON.stringify(queryParams) === JSON.stringify({}) &&
        evt.type === 'INPUT_CHANGE'
      ) {
        delete this.searchParams.keyword;
      }

      if (
        JSON.stringify(queryParams) === JSON.stringify({}) &&
        evt.type === 'CHANGE'
      ) {
        this.searchParams = { ...INIT_RATES_SEARCH_PARAMS };
      }

      this.searchParams = {
        ...INIT_RATES_SEARCH_PARAMS,
        ...this.searchParams,
        ...queryParams,
      };

      if (queryParams?.customProperties) {
        this.searchParams = {
          ...this.searchParams,
          customProperties: JSON.stringify(queryParams.customProperties),
        };
      }

      this.requestCatalogues({ loadingGlobal: true });
    }
  }

  restore() {
    const ids = this.entriesForDelete.map((el) => el?._id);

    this.sub$.add(
      this.catalogueService.restoreMany({ ids: ids }).subscribe(() => {
        this.toastService.show({
          text: this.i18n
            .instant('general.restoreMany')
            .replace('{qtty}', ids.length),
          type: 'success',
          msDuration: 4000,
        });

        this.entriesForDelete = [];
        this.deleteMessage = false;
        this.requestCatalogues({ loadingGlobal: true });
        this.draw();
      })
    );
  }

  /********************************************TOOLTIP************************************************** */
  showToolTip = false;
  iconDialog = 'assets/icons/gl_title-information-outline.svg';
  toggleTooltip(evt) {
    this.showToolTip = evt;
    if (!this.showToolTip) {
      this.iconDialog = 'assets/icons/gl_title-information-outline.svg';
    } else {
      this.iconDialog = 'assets/icons/gl_information-clicked.svg';
    }
  }

  get isMobile(): boolean {
    return window.screen.width < 768;
  }

  showDialog(): void {
    if (!this.isMobile) {
      this.showToolTip = !this.showToolTip;
      this.toggleTooltip(this.showToolTip);
    } else {
      const dialog = this.dialog.open(ModalMobileInfoComponent, {
        data: {
          html: 'rates.tooltip',
          buttonText: 'kanban.dialog.confirmButton',
        },
      });
    }
  }

  public refresh() {
    return this.draw();
  }

  get isLowerThan1450px() {
    return window.innerWidth < 1450;
  }

  download(type: DownloadType) {
    switch (type) {
      case DownloadType.EXCEL:
        if (this.entriesForDelete?.length) {
          this.downloadExcelSelection();
        } else {
          this.downloadExcel();
        }
        break;
      case DownloadType.PDF:
        break;
      default:
        break;
    }
  }
}
