<roma-mobile-clock-hour-admin
  *ngFor="let item of source"
  [content]="{
    id: item.id,
    img: item.img,
    status: item.status,
    title: item.title,
    description: item.description,
    startDate: item.startDate,
    endDate: item.endDate,
    hour: item.hour
  }"
  (actions)="makeActions($event)"
>
</roma-mobile-clock-hour-admin>
