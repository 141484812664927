import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditableFieldModule } from '../editable-field';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { FieldErrorDisplayModule } from '../field-error-display';
import { BudgetItemsComponent } from './budget-items.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { SelectSearchModule } from '../select-search/select-search.module';
import { PermissionsModule } from '@web-frontend/shared/components/permissions';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [BudgetItemsComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    SelectSearchModule,
    AutocompleteLibModule,
    MatButtonModule,
    DragDropModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    EditableFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatCardModule,
    FieldErrorDisplayModule,
    NgSelectModule,
    PermissionsModule,
  ],
  providers: [],
  exports: [BudgetItemsComponent],
})
export class BudgetItemsModule {}
