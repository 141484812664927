<div
  class="total-hours"
  [class.pointer-cursor]="clickable"
  [class.full-width-mobile]="fullWidthMobile"
  (click)="clickable && openModal()"
>
  <img class="icon-header" [src]="'assets/icons/' + icon + '.svg'" />
  <p>{{ clockHoursLabel }}</p>
  <div class="hours-container">
    <h2 [class.invalid-time]="isInvalidTime">
      {{ hourFormatted.replace(':', 'h ') }}m
      <img *ngIf="isInvalidTime" width="16px" class="help-icon" [src]="iconDialog" />
    </h2>
    <span *ngIf="totalHoursFormatted"
      >{{ 'person.create.form.of' | translate }} {{ totalHoursFormatted }}
      {{ 'person.create.form.hours' | translate }}</span
    >
  </div>
</div>
