import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ModalErrorConnectionComponent } from './modal-error-connection.component';
import { ModalErrorConnectionService } from './modal-error-connection.service';

@NgModule({
  declarations: [ModalErrorConnectionComponent],
  imports: [CommonModule, TranslateModule.forChild(), MatDialogModule],
  providers: [ModalErrorConnectionService],
})
export class ModalErrorConnectionModule {}
