import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BudgetSettingsComponent } from './budget-settings.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EditableFieldModule } from '../editable-field';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { BudgetAccountDataModule } from '../budget-account-data/budget-account-data.module';
import { BudgetPaymentMethodDataModule } from '../budget-payment-method/budget-payment-method.module';
import { BudgetLogoModule } from '../budget-logo/budget-logo.module';
import { BudgetBillingDataModule } from '../budget-billing-data/budget-billing-data.module';
import { BudgetPreferencesModule } from '../budget-preferences/budget-preferences.module';
import { LoadingModule } from '../loading';
import { BudgetSequenceSettingsModule } from '../budget-sequence-settings/budget-sequence-settings.module';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionsModule } from '../permissions';
import { PrivacyPoliciesModule } from '../privacy-policies/privacy-policies.module';
import { BackButtonModule } from '../back-button/back-button.module';
@NgModule({
  declarations: [BudgetSettingsComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    EditableFieldModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatCardModule,
    BudgetAccountDataModule,
    BudgetPaymentMethodDataModule,
    BudgetLogoModule,
    BudgetBillingDataModule,
    BudgetPreferencesModule,
    LoadingModule,
    BudgetSequenceSettingsModule,
    PermissionsModule,
    PrivacyPoliciesModule,
    BackButtonModule,
  ],
  exports: [BudgetSettingsComponent],
})
export class BudgetSettingsModule {}
