import { LocationClass } from './location.class';
import PlaceResult = google.maps.places.PlaceResult;

export const MapperPlaceResult = (query: PlaceResult): LocationClass => {
  const location = new LocationClass(query);

  location.latitude = query.geometry.location.lat();
  location.longitude = query.geometry.location.lng();

  for (const address of query?.address_components) {
    if (address) {
      for (const type in address?.types) {
        if (type) {
          switch (address.types[type]) {
            case 'country':
              location.country = address?.long_name;
              location.codeCountry = address?.short_name;
              break;
            case 'administrative_area_level_1':
              location.state = address?.long_name;
              break;
            case 'administrative_area_level_2':
              if (!location.city || location?.city === '') {
                location.city = address?.long_name;
              }
              break;
            case 'locality':
              location.city = address?.long_name;
              break;
            case 'route':
            case 'street_address':
            case 'intersection':
              location.streetName = address?.long_name;
              break;
            case 'street_number':
            case 'administrative_area_level_3':
            case 'administrative_area_level_4':
            case 'administrative_area_level_5':
            case 'sublocality_level_1':
              location.sector = address?.long_name;
              break;
            case 'postal_code':
              location.postCode = address?.long_name;
              break;
          }
        }
      }
    }
  }
  return location;
};
