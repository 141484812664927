import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'roma-qr-legend',
  templateUrl: './qr-legend.component.html',
  styleUrls: ['./qr-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrLegendComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<QrLegendComponent>) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
