<div class="row d-flex">
  <div class="col-12">
    <label class="field-label">{{ title }}</label>
  </div>
</div>
<div *ngIf="!isLoading; else loading" class="main-container">
  <div *ngFor="let position of fileArrayLenght; let i = index">
    <div class="image-item">
      <!-- BEGIN IMAGES SELECTOR ICON '+' (show if no images in temporal array or fileserver array) -->
      <form enctype="multipart/form-data">
        <div
          romaProFeature
          [feature]="
            (
              numFilesAdded == 0
                ? dontHaveNumFilesAdded(i)
                : haveNumFilesAdded(i)
            )
              ? featureRef.SystemPermission.DefaultAllow
              : feature
          "
          [featureUser]="featureUser"
          [customContent]="customContent"
          [ngClass]="[
            existImageLoad(position) ? 'hide' : 'labelInputClass',
            (!(numFilesAdded == 0
              ? dontHaveNumFilesAdded(i)
              : haveNumFilesAdded(i)) &&
              !(hasPermissionFeature$ | async)) ||
            !(hasPermissionFeature$ | async)
              ? 'custom-tooltip top is__disabled'
              : ''
          ]"
          (clicked)="openFileSelector(position)"
        >
          <ng-container>
            <img height="20" width="20" [src]="img" />
            {{ 'general.add' | translate }}
            <div *ngIf="!(permissions$ | async)" class="roma-label">
              <div class="icon"></div>
            </div>
          </ng-container>
        </div>
      </form>
      <!-- END IMAGES SELECTOR ICON '+' -->

      <!-- BEGIN RENDER SERVER IMAGES -->
      <div
        *ngIf="
          sourcesfilesServer.size > 0 && this.sourcesfilesServer.get(position)
        "
        class="image-preview"
        [ngStyle]="{
          'background-image': isFile(this.sourcesfilesServer.get(position))
            ? 'url(' + resolveFile(this.sourcesfilesServer.get(position)) + ')'
            : 'url(' +
              (this.sourcesfilesServer.get(position) | imageFirebase: type) +
              ')'
        }"
        [ngClass]="[
          (i !== 0 && !(hasPermissionFeature$ | async)) ||
          (freeLimit?.consumed >= filesLimit &&
            !(hasPermissionFeature$ | async))
            ? 'custom-tooltip top is__disabled'
            : ''
        ]"
        (clicked)="openImage(position)"
        romaProFeature
        [feature]="determineIfOpen(position)"
      >
        <mat-icon
          (click)="delete(position)"
          class="close-icon"
          aria-hidden="false"
        >
          cancel
        </mat-icon>

        <ng-container
          *ngIf="
            freeLimit?.consumed >= filesLimit &&
            !(hasPermissionFeature$ | async) &&
            !isImageInFreeLimit(position)
          "
        >
          <div class="tiptext">
            {{ 'pro.files.tooltip' | translate }}
          </div>
        </ng-container>
      </div>
      <!-- END BEGIN RENDER SERVER IMAGES -->

      <div
        *ngIf="selectedFile?.get(position)?.pending === true"
        class="text-center"
      >
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="d-flex justify-content-center align-items-center">
    <mat-progress-spinner
      class="loading-spinner"
      mode="indeterminate"
      [diameter]="40"
    ></mat-progress-spinner>
  </div>
</ng-template>
