import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PaymentsService } from '../payments.service';
import Stripe from 'stripe';
import {
  DiscoverResult,
  ErrorResponse,
  ISdkManagedPaymentIntent,
  loadStripeTerminal,
  Terminal,
} from '@stripe/terminal-js';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { from, Observable } from 'rxjs';
import { ConfirmPaymentService } from './services/confirm-payment-modal.service';
import { TacliaPayments } from '@tacliatech/types';
import { MatDialogConfig } from '@angular/material/dialog';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { StorageService } from '@web-frontend/shared/services';

@Component({
  selector: 'roma-create-payment-card',
  templateUrl: './create-payment-card.component.html',
  styleUrls: ['./create-payment-card.component.scss'],
})
export class CreatePaymentCardComponent implements OnInit {
  connectionToken: Stripe.Response<Stripe.Terminal.ConnectionToken>;
  myTerminal: Terminal;
  readers: Stripe.Terminal.Reader[];
  selectedReader: Stripe.Terminal.Reader;
  selection = new SelectionModel<Stripe.Terminal.Reader>(true, []);
  connectedToReader = false;
  connectingToReader = false;
  sendingToReader = false;
  dataSource = new MatTableDataSource<Stripe.Terminal.Reader>([]);
  amount = '0';
  creditCardType: string;
  currency: string;

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private budgetService: BudgetService,
    private paymentsService: PaymentsService,
    private router: Router,
    private confirmPaymentService: ConfirmPaymentService,
    private toastService: ToastService
  ) {}

  displayedColumns: string[] = ['select', 'id', 'label', 'status'];
  async ngOnInit(): Promise<void> {
    this.creditCardType = '4242424242424242';
  }

  createLocation() {}

  getSettings() {
    this.budgetService
      .findSettingsByCompany(StorageService.CompanyId)
      .subscribe((settings) => {
        //this.currency = settings.budgetPreferences.;
      });
  }

  async findReaders() {
    await this.initializeTerminal();
    const locationID: string = this.findLocations(true);
    this.readers = await this.discoverReaderHandler(locationID);
    this.dataSource.data = this.readers;
  }
  findLocations(simulated: boolean): string {
    return simulated ? null : 'tml_FLxuwg5BWJrz2n';
  }

  async initializeTerminal() {
    const StripeTerminal = await loadStripeTerminal();

    this.myTerminal = StripeTerminal.create({
      onFetchConnectionToken: async () => this.getConnectionToken(),
      onUnexpectedReaderDisconnect: async () => this.unexpectedDisconnect(),
    });
  }

  getConnectionToken(): Promise<string> {
    const locationID: string = this.findLocations(true);
    return this.paymentsService
      .getConnectionToken(locationID)
      .toPromise()
      .then(function (data: Stripe.Response<Stripe.Terminal.ConnectionToken>) {
        return data.secret;
      });
  }

  capturePaymentIntentFromServer(
    paymentIntent: Stripe.PaymentIntent
  ): Promise<Stripe.Response<Stripe.PaymentIntent>> {
    return this.paymentsService
      .captureIntent(paymentIntent)
      .toPromise()
      .then((data: Stripe.Response<Stripe.PaymentIntent>) => {
        return data;
      });
  }

  discoverReaderHandler(
    locationId: string
  ): Promise<Stripe.Terminal.Reader[] | []> {
    let config;
    if (locationId)
      config = { simulated: false, location: 'tml_FLxuwg5BWJrz2n66' };
    else config = { simulated: true };

    return (
      this.myTerminal
        .discoverReaders(config)
        // @ts-ignore
        .then((discoverResult: DiscoverResult | ErrorResponse) => {
          if ('error' in discoverResult) {
            this.showErrorMessage(
              TacliaPayments.Errors.StripeErrorEnum
                .DISCOVER_READER_CONNECT_ERROR
            );
            this.draw();
          } else if (
            'discoveredReaders' in discoverResult &&
            discoverResult.discoveredReaders.length == 0
          ) {
            this.showErrorMessage(
              TacliaPayments.Errors.StripeErrorEnum.DISCOVER_READER_EMPTY
            );
            this.draw();
            return Promise.resolve([]);
          } else {
            return Promise.resolve(discoverResult.discoveredReaders);
          }
        })
    );
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }
  unexpectedDisconnect() {
    // In this function, your app should notify the user that the reader disconnected.
    // You can also include a way to attempt to reconnect to a reader.
    console.log('Disconnected from reader');
  }

  isAllSelected($event) {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle($event) {
    if ($event.checked) {
      this.onCompleteRow(this.dataSource);
    }
    this.isAllSelected($event)
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  selectRow($event, reader: Stripe.Terminal.Reader) {
    // console.log($event.checked);
    if ($event.checked) {
      this.selectedReader = reader;
    }
  }

  onCompleteRow(dataSource) {
    // dataSource.data.forEach((element) => {
    //   console.log(element.name);
    // });
  }

  disConnectToReader() {
    this.myTerminal.disconnectReader().then((response) => {
      this.connectedToReader = false;
    });
  }
  connectToReader() {
    if (this.selectedReader) {
      this.connectingToReader = true;
      this.myTerminal
        .connectReader(this.selectedReader)
        .then((connectResult) => {
          if ('error' in connectResult) {
            this.connectingToReader = false;
            this.showErrorMessage(
              TacliaPayments.Errors.StripeErrorEnum.READER_CONNECT_ERROR
            );
          } else {
            this.connectedToReader = true;
            this.connectingToReader = false;
          }
          this.draw();
        });
    } else {
      console.log('There are not selected reader');
    }
  }
  back() {
    this.router.navigateByUrl('/admin/payments');
  }

  createIntentInServer(payload: TacliaPayments.Intent.Request) {
    return this.paymentsService
      .createIntent(payload)
      .toPromise()
      .then(function (data: Stripe.Response<Stripe.PaymentIntent>) {
        return data;
      });
  }

  async startProccessOfPayment(paymentIntent: ISdkManagedPaymentIntent) {
    // Init process of payment in terminal it return a payment intent
    const response:
      | ErrorResponse
      | Stripe.PaymentIntent = await this.proccessPaymentInTerminal(
      paymentIntent
    );

    //validate Errors in response
    if ('error' in response) {
      this.sendingToReader = false;
      this.showErrorMessage(response.error.code);
      this.draw();
      return;
    }

    return this.capturePaymentIntentFromServer(response).then(
      (res: Stripe.Response<Stripe.PaymentIntent>) => {
        this.showSuccessPaymentMessage(this.amount);
        this.sendingToReader = false;
        this.draw();
      }
    );
  }

  async generatePaymentOfCreditCard() {
    // validate Amount
    //
    // this.amount = this.amount * 100;
    const amountConvertion = +this.amount * 100;

    //Params TicketID
    const config = new MatDialogConfig<TacliaPayments.CreditCard.ModalRequest>();
    const params: TacliaPayments.CreditCard.ModalRequest = {
      idSaleTicket: '111012312312',
    };
    config.data = params;
    config.height = '510px';
    config.width = '350px';

    this.sendingToReader = true;

    try {
      const params: TacliaPayments.Intent.Request = {
        amount: amountConvertion,
        connectedAccount: 'acct_1NXbYtQ2oTBBkpOu',
        description: 'Esto es una descriccion de etest',
        currency: 'eur',
        metadata: {},
      };

      // Create Payment Intent
      const intent = await this.createIntentInServer(params);

      // Set credit card and Receive Payment Method in terminal, it sends client_secret from server
      const paymentIntentCollected = await this.receivePaymentMethodInTerminal(
        intent.client_secret
      );

      this.draw();

      //validate Errors in response
      if ('error' in paymentIntentCollected) {
        this.sendingToReader = false;
        this.draw();
        throw new Error('fer');
      }

      // show modal to confirm operation
      this.confirmPaymentService
        .open(config)
        .subscribe((response: TacliaPayments.CreditCard.ModalResponse) => {
          if (response.confirmPayment) {
            // init process of payment
            this.startProccessOfPayment(
              paymentIntentCollected.paymentIntent
            ).catch((error) => {
              this.handleHttpErrorResponse(error);
            });
          } else {
            this.sendingToReader = false;
            this.draw();
          }
        });
    } catch (error) {
      this.handleHttpErrorResponse(error);
    }
  }

  handleHttpErrorResponse(error: any) {
    if (error instanceof HttpErrorResponse) {
      this.sendingToReader = false;
      this.showErrorMessage(error?.error.apiErrorCode);
      this.draw();
    }
  }

  /**
   * Requests the Terminal object to collect a card source from the reader that can be charged.
   */

  collectPaymentMethod(
    secret: string
  ): Observable<{ paymentIntent: ISdkManagedPaymentIntent } | ErrorResponse> {
    //config card simulator
    this.myTerminal.setSimulatorConfiguration({
      testCardNumber: '4242424242424242',
    });

    const obs$ = from(this.myTerminal.collectPaymentMethod(secret));

    return obs$;
  }

  receivePaymentMethodInTerminal(
    secret: string
  ): Promise<{ paymentIntent: ISdkManagedPaymentIntent } | ErrorResponse> {
    const creditCardNumber = this.creditCardType.trim();
    //config card simulator
    this.myTerminal.setSimulatorConfiguration({
      testCardNumber: creditCardNumber,
    });

    return this.myTerminal.collectPaymentMethod(secret);
  }

  proccessPaymentInTerminal(paymentIntent: ISdkManagedPaymentIntent) {
    return this.myTerminal
      .processPayment(paymentIntent)
      .then(function (result) {
        if ('error' in result) {
          //TODO:refactor handle error
          return result as ErrorResponse;
        } else {
          return result.paymentIntent;
        }
      });
  }

  showSuccessPaymentMessage(amount: string) {
    this.toastService.show({
      text: `El pago de ${amount} fue exitoso!`,
      type: 'success',
      msDuration: 4000,
      class: '',
    });
  }

  showErrorMessage(error: string) {
    this.toastService.show({
      text: TacliaPayments.Errors.getStripeError(error),
      type: 'error',
      msDuration: 4000,
      class: '',
    });
  }

  showDefaultErrorMessage(error: any) {
    this.toastService.show({
      text: error,
      type: 'error',
      msDuration: 4000,
      class: '',
    });
  }
}
