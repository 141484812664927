import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionGuard } from './permission.guard';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [PermissionGuard],
})
export class PermissionModule {}
