import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  BudgetCommonData,
  Feature,
  FeatureUser,
  IBudgetCommonData,
  IBudgetsProFeatures,
  IBudgetsSetting,
  ICatalogue,
  IItemBudget,
  ItemBudget,
  PaginateResponse,
  Tax,
  TypeBudget,
  TypeTax,
} from '@tacliatech/types';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import { StorageService } from '@web-frontend/shared/services';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { CatalogueSearchService } from '@web-frontend/shared/services/catalogue';
import { RemoveEmpty } from '@web-frontend/shared/utils';

import { Observable, Subject, Subscription } from 'rxjs';
import { BudgetUtilisService } from '../../../core/admin/budgets/budget-edit/budgets-edit.component';
import { INIT_RATES_SEARCH_PARAMS } from '../../../core/admin/rates/rates.types';
import { finalize } from 'rxjs/operators';
import { TaxService } from '@web-frontend/shared/services/tax';

@Component({
  selector: 'roma-budget-items',
  templateUrl: './budget-items.component.html',
  styleUrls: ['./budget-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetItemsComponent implements OnInit, OnDestroy {
  @Input()
  mode: 'ADD' | 'EDIT' = 'ADD';

  @Input()
  id: string;

  @Input()
  items: IItemBudget[];

  @Input()
  type: string;

  @Input()
  budget: BudgetCommonData = new BudgetCommonData();

  @Input()
  taxes: Tax[] = [];

  @Input()
  featureUser: Feature.Purchasable | FeatureUser.Purchasable =
    Feature.SystemPermission.DefaultAllow;

  @Output()
  addClick = new EventEmitter<MouseEvent>();

  @Output()
  deleteClick = new EventEmitter<ItemBudget>();

  @Output()
  changedItems = new EventEmitter<unknown>();

  @Output()
  changeDiscount = new EventEmitter<boolean>();

  @Input()
  settings: IBudgetsSetting;

  @Input()
  validForm: boolean;

  selectedIvaAssigned = [];
  canEditBasic = true;
  Iv21Default = '1';
  actualConceptValue!: string;

  conceptPlaceholder = 'budgets.settings.create.form.conceptPlaceholder';
  descriptionPlaceholder =
    'budgets.settings.create.form.descriptionPlaceholder';
  quantityPlaceholder = 'budgets.settings.create.form.quantityPlaceholder';
  discountPlaceholder = 'budgets.settings.create.form.discountPlaceholder';
  pricePlaceholder = 'budgets.settings.create.form.pricePlaceholder';
  taxPlaceholder = 'budgets.settings.create.form.taxPlaceholder';
  totalPlaceholder = 'budgets.settings.create.form.totalPlaceholder';

  private sub$ = new Subscription();
  private storeSearchParams = INIT_RATES_SEARCH_PARAMS;
  private busSearchParamsChanges = new Subject();

  catalogues: ICatalogue[];
  resultSearch: PaginateResponse<ICatalogue[]>;
  catalogueMap = new Map<string, ICatalogue>();

  featureRef = Feature;
  featureRefUser = FeatureUser;
  isDataLoaded = false;
  isLoading = false;
  isLoadingPaginate = false;
  hasDiscount = false;

  keyword = 'name';
  oldBudguetCounter = 0;

  proFeatures: IBudgetsProFeatures;

  selectEvent($event, lineform, index) {
    if ($event instanceof PointerEvent === false) {
      this.lines.controls[index].patchValue({
        concept: $event.name,
        price: $event.sellPrice,
        taxes: $event.taxes,
        total: $event.sellPrice * lineform.controls.quantity,
      });
      this.update();
    }
  }

  onChangeSearch(val: string, lineform, index) {
    this.lines.controls[index].patchValue({
      concept: val,
    });
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  onFocused(e, lineform, index) {
    this.markAsRead(lineform, index);
  }

  markAsRead(lineform, index) {
    const s = lineform.controls as FormGroup;
    const line = this.lines?.controls[index] as FormGroup;

    this.lines?.controls.forEach((s, i) => {
      const line = s as FormGroup;
      if (index == i) {
        line.controls.concept.markAsTouched();
      }
    });
  }

  onCleared(e, lineform, index) {
    // do something when input is focused
    this.lines.controls[index].patchValue({
      concept: '',
    });
    //  this.update();
  }
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;

  constructor(
    private fb: FormBuilder,
    private elRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private budgetUtilisService: BudgetUtilisService,
    private budgetService: BudgetService,
    private alertService: AlertService,
    private i18n: TranslateService,
    private catalogueSearchService: CatalogueSearchService,
    private readonly taxService: TaxService
  ) {}

  form = this.fb.group({
    lines: this.fb.array([]),
  });

  get lines() {
    return this.form.controls['lines'] as FormArray;
  }

  addLine() {
    const rateDefault = this.settings?.invoiceSettings?.rateDefault;

    const isObject = (value: any) => {
      return typeof value === 'object' && value !== null;
    };

    // case object take _id,
    // if is string take unique value
    const defaultTax: string[] = rateDefault
      ? isObject(rateDefault)
        ? [(rateDefault as any)?._id]
        : [rateDefault]
      : [];

    const lessonForm = this.fb.group({
      price: [0, [Validators.required]],
      quantity: [1, [Validators.required]],
      description: [null, [Validators.required]],
      concept: [null, [Validators.required]],
      discount: [0],
      taxes: [defaultTax, [Validators.required]],
      total: [0, [Validators.required]],
      position: [null],
    });

    // console.log({ lessonForm: lessonForm.value });
    this.lines.push(lessonForm);
    // console.log({ lines: this.lines });
    this.selectedIvaAssigned.push([this.Iv21Default]);
    this.update();
    this.draw();
  }

  addDiscount() {
    this.hasDiscount = true;
    this.changeDiscount.emit(this.hasDiscount);
  }

  deleteLine(lessonIndex: number) {
    this.lines.removeAt(lessonIndex);
    this.selectedIvaAssigned.splice(lessonIndex, 1);
    this.update();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  ngOnInit(): void {
    this.checkMode();
    this.requestCatalogues({ loadingGlobal: true });
    this.sub$.add(
      this.budgetUtilisService.refreshBudget.subscribe(
        (data: IBudgetCommonData) => {
          this.budget = data;
          this.attachBudgetLines();
        }
      )
    );
  }

  ngAfterViewInit() {
    this.attachBudgetLines();
  }

  ngAfterContentInit() {
    // this.updateWidthDropDown('220');
  }
  updateWidthDropDown(width: string) {
    this.elRef.nativeElement.style.setProperty('--dropdownwidth', width + 'px');
    this.draw();
  }

  updateItems() {
    if (this.validForm) {
      if (this.mode === 'EDIT') {
        this.updateBudget();
        this.draw();
      } else {
        this.draw();
      }
    } else {
      this.alertService.errorOptions(
        this.i18n.instant('budgets.settings.create.form.headerCreateError'),
        { duration: 4500 }
      );
    }
  }

  updateBudget() {
    setTimeout(() => {
      if (!this.budget?.proFeatures) {
        if (this.budget?.discount != 0) {
          this.proFeatures = { hasDiscount: true };
        } else {
          this.proFeatures = { hasDiscount: this.hasDiscount };
        }
        this.budget.proFeatures = this.proFeatures;
        if (!this.budget?.discount) this.budget.discount = 0;
        this.budgetUtilisService.refreshBudget.emit(this.budget);
      }

      const data = JSON.parse(JSON.stringify(this.budget));

      if (this.type == TypeBudget.BUDGET) {
        if (this.id) {
          this.sub$.add(
            this.budgetService.updateOne(this.id, data).subscribe((data1) => {
              //this.budget = data1;
              this.budgetUtilisService.refreshBudget.emit(this.budget);

              /*     this.alertService.success(
                this.i18n.instant('budgets.settings.edit.successUpdated')
              ); */
              this.draw();
            })
          );
        }
      }

      if (this.type == TypeBudget.PROFORM) {
        if (this.id) {
          this.sub$.add(
            this.budgetService
              .updateOneProform(this.id, data)
              .subscribe((data) => {
                //this.budget = data;
                this.budgetUtilisService.refreshBudget.emit(this.budget);
                /*      this.alertService.success(
                this.i18n.instant('budgets.settings.edit.successUpdated')
              ); */
                this.draw();
              })
          );
        }
      }

      if (this.type == TypeBudget.BILL) {
        if (this.id) {
          this.sub$.add(
            this.budgetService
              .updateOneBill(this.id, data)
              .subscribe((data) => {
                //this.budget = data;
                this.budgetUtilisService.refreshBudget.emit(this.budget);
                /*       this.alertService.success(
                this.i18n.instant('budgets.settings.edit.successUpdated')
              ); */
                this.draw();
              })
          );
        }
      }
    }, 200);
  }

  private attachBudgetLines() {
    this.lines.clear();
    if (this.mode === 'EDIT') {
      if (this.budget?.proFeatures) {
        this.fillLocalBudgetItems();
      } else {
        this.fillOldBudgetItems();
        this.recalculateItemsForOldBudgets();
      }
    } else {
      // this.addLine();
    }
    this.draw();
  }

  /** UPDATE FOR RELEASE/2.4.0 */
  /**
   * This function fills the form with the array of items
   * for budgets created in higher versions, with discount
   * and tax calculation per line
   * */
  fillLocalBudgetItems() {
    this.budget.items.forEach((line, index) => {
      const lineForm = this.fb.group({
        price: [line.price, [Validators.required]],
        quantity: [line.quantity, [Validators.required]],
        description: [line.description, [Validators.required]],
        concept: [line.concept, [Validators.required]],
        discount: [line?.discount],
        taxes: [
          line.taxes === undefined ? [] : this.justIds(line.taxes),
          [Validators.required],
        ],
        total: [line.total.toFixed(this.settings.budgetPreferences?.decimals)],
      });

      this.selectedIvaAssigned.push(this.setIva(line.taxes));

      this.lines.push(lineForm);
    });
  }

  /** UPDATE FOR RELEASE/2.4.0 */
  /**
   * This function fills the form with the array of items
   * for the budgets created in later versions,
   * without the discount and without the calculation of taxes per line
   * */
  fillOldBudgetItems() {
    this.budget.items.forEach((line, index) => {
      const lineForm = this.fb.group({
        price: [line.price, [Validators.required]],
        quantity: [line.quantity, [Validators.required]],
        description: [line.description, [Validators.required]],
        concept: [line.concept, [Validators.required]],
        discount: [0],
        taxes: [
          line.taxes === undefined ? [] : this.justIds(line.taxes),
          [Validators.required],
        ],
        total: [
          line.total.toFixed(this.settings.budgetPreferences?.decimals),
          [Validators.required],
        ],
      });

      this.selectedIvaAssigned.push(this.setIva(line.taxes));

      this.lines.push(lineForm);
    });
  }

  /** UPDATE FOR RELEASE/2.4.0 */
  /**
   * As of this version, the logic for calculating line totals has changed,
   * including taxes and introducing discounts.
   * When the previous records are edited, it is necessary to recalculate
   * each TotalItem and store the new value in the database.
   * */
  recalculateItemsForOldBudgets() {
    const items = this.budget.items;
    for (let index = 0; index < this.budget.items.length; index++) {
      let amount = 0;
      let gtax = 0;
      let ret = 0;
      amount = this.getAmount(items[index]);

      items[index].taxes.forEach((tax) => {
        const value =
          ((amount - this.getDiscount(items[index], false)) * tax.value) / 100;

        if (tax.type === TypeTax.RET) {
          ret -= value;
        } else {
          gtax += value;
        }
      });

      this.lines.controls[index].patchValue({
        total: amount - this.getDiscount(items[index], false) + ret + gtax,
      });

      this.budget.items[index].total =
        amount - this.getDiscount(items[index], false) + ret + gtax;
    }

    if (!this.budget?.proFeatures) {
      this.updateBudget();
    }
  }

  justIds(array: any[]) {
    if (array) {
      const plain = array.filter((a) => a !== null).map((a) => a?._id);
      return plain;
    } else {
      return [];
    }
  }

  isDisabled(control: any) {
    const result = this.validForm === false ? true : false;
    if (result) {
      this.form.controls[control].disable();
    } else {
      this.form.controls[control].enable();
    }

    return result;
  }

  checkMode() {
    if (this.mode === 'ADD') {
      this.budget = new BudgetCommonData();
    } else {
      return;
    }
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  updateConcept(event) {
    this.update();
  }

  onAddClick(evt: MouseEvent) {
    this.addClick.next(evt);
  }

  onDeleteClick(evt: IItemBudget) {
    this.deleteClick.next(evt);
  }

  changeIvaAssigned(event: string[], index: any) {
    if (this.budget?.items && this.budget?.items.length > 0) {
      if (event) {
        const aux: Tax[] = this.taxes.filter((a) => a._id === event[index]);
        this.budget.items[index].taxes = aux;
        this.selectedIvaAssigned[index] = this.convertTaxesIds(aux, index);
      }
    }
    this.budgetUtilisService.refreshTotals.emit(this.budget.items);
    this.update();
  }

  /** UPDATE FOR RELEASE/2.4.0 */
  /**
   * As of this version, the tax calculation logic for each item has changed:
   *
   * This calculation must be executed every time the tax selector is edited since now,
   * each tax affects the total directly.
   **/
  convertTaxesIds(taxes: Tax[], index) {
    const ids = [];
    const currentItems = this.budget?.items[index];
    const amount = this.getAmount(currentItems);
    let gtax = 0;
    let ret = 0;

    if (taxes) {
      taxes.forEach((tax) => {
        if (tax) {
          ids.push(tax._id);

          /**START OF TAX AND DISCOUNT CALCULATION */
          const value =
            ((amount - this.getDiscount(currentItems, false)) * tax.value) /
            100;
          if (tax.type === TypeTax.RET) {
            ret -= value;
          } else {
            gtax += value;
          }
          /**END OF TAX AND DISCOUNT CALCULATION */
        }
      });

      if (amount !== 0) {
        /**
         * The total of the current item in the form is updated
         */
        this.lines.controls[index].patchValue({
          total: amount - this.getDiscount(currentItems, false) + ret + gtax,
        });

        /**
         *The total of the item in the budget object is updated.
         This object must be updated for the calculation of global totals.
         */
        if (this.budget?.items[index]) {
          this.budget.items[index].total =
            amount - this.getDiscount(currentItems, false) + ret + gtax;
        }
      }
    }

    return ids;
  }

  setIva(taxes: Tax[]) {
    const ivas = [];
    if (taxes) {
      taxes.forEach((element) => {
        if (element?._id) {
          ivas.push(element?._id);
        }
      });
    }
    return ivas;
  }

  drop(event: CdkDragDrop<unknown>) {
    moveItemInArray(
      this.lines?.controls,
      event.previousIndex,
      event.currentIndex
    );

    // Updating items array after move by drag and drop
    this.budget.items = this.lines.controls.map((line: FormGroup) => {
      return new ItemBudget({
        price: line.controls.price.value,
        quantity: line.controls.quantity.value,
        description: line.controls.description.value,
        discount:
          line.controls.discount.value > 0 ? line.controls.discount.value : 0,
        concept: line.controls.concept.value,
        taxes: line.controls.taxes?.value?.length
          ? this.taxService.taxes.filter((e: Tax) => {
              return line.controls.taxes.value.some((t: string) => e._id === t);
            })
          : [],
        total: Number(line.controls.total.value),
      });
    });

    this.updateItems();

    // this.updateLines();
  }

  /**
   * This function updates the individual totals
   * and prepares the budget object for its update in db
   */
  update(update = true) {
    const h = this.form.value;
    h.lines = this.setTaxes(h.lines as IItemBudget[]);
    this.budget.items = [...h.lines];
    this.budgetUtilisService.refreshTotals.emit(this.budget.items);
    if (update) {
      this.updateItems();
    }

    this.updateLines();
  }

  updateLines() {
    const h = this.form.value;
    const lines = h.lines as IItemBudget[];
    let newlines: IItemBudget[] = new Array(lines.length);

    this.lines?.controls.forEach((s, index) => {
      const line = s as FormGroup;
      const item = new ItemBudget();
      item.price = line.controls.price.value;
      item.quantity = line.controls.quantity.value;
      item.discount = line.controls.discount.value;
      item.description = line.controls.description.value;
      item.concept = line.controls.concept.value;
      item.taxes = line.controls.taxes.value;
      item.total = line.controls.total.value;
      newlines[index] = item;
    });

    newlines = this.setTaxes(newlines as IItemBudget[]);
    this.budget.items = [...newlines];
    this.updateItems();
    this.budgetUtilisService.refreshItems.emit(this.budget.items);
  }

  setTaxes(lines: IItemBudget[]): any {
    let t: Tax[] = [];

    // console.log({ lines, taxes: this.taxes });

    lines.forEach((line, index) => {
      t = [];
      if (line.taxes) {
        line.taxes.forEach((tax) => {
          const a = this.taxes.find((a) => {
            return a._id === tax;
          });

          t.push(a);
        });
        line.taxes = t;
        this.selectedIvaAssigned[index] = this.convertTaxesIds(
          line.taxes,
          index
        );

        if (this.budget?.items[index]?.total)
          line.total = this.budget?.items[index]?.total;
        else line.total = 0;
      }
    });
    return lines;
  }

  findTax(ids: string[]): any {
    let t: Tax[] = [];

    ids.forEach((id) => {
      t = [];

      const a = this.taxes.find((a) => a._id === id);
      t.push(a);
    });

    return t;
  }

  get searchParams() {
    return this.storeSearchParams;
  }

  set searchParams(value) {
    this.busSearchParamsChanges.next(value);
    this.storeSearchParams = value;
  }

  private requestCatalogues({
    loadingGlobal = false,
    loadingPagination = false,
  }) {
    if (loadingGlobal) {
      this.isLoading = true;
      this.draw();
    }

    if (loadingPagination) {
      this.isLoadingPaginate = true;
      this.draw();
    }

    let query = RemoveEmpty(this.searchParams);

    query = {
      ...query,
      company_id: StorageService.CompanyId,
      applyPaginate: false,
    };

    this.sub$.add(
      this.catalogueSearchService
        .search({
          ...query,
        })
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.isLoadingPaginate = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          this.setCatalogue(res);
        })
    );
  }

  private findMaxWidth(lista: any) {
    return Math.max(...lista.map((value) => value.name.length));
  }
  maxWith = 100;

  private setCatalogue(response: PaginateResponse<ICatalogue[]>) {
    this.resultSearch = response;

    for (const catalogue of response.docs) {
      this.catalogueMap.set(catalogue?._id, catalogue as ICatalogue);
    }

    this.source = Array.from(this.catalogueMap.values());
    this.maxWith = this.findMaxWidth(response.docs);
    // this.updateWidthDropDown((this.maxWith * 8).toString());
  }

  private set source(values: ICatalogue[]) {
    this.catalogues = values;
    this.draw();
  }

  onKeyUp($event) {
    this.actualConceptValue = $event?.target?.value;
  }

  setItemData($event, lineform, index) {
    if ($event === undefined) {
      this.update();

      return;
    }

    if ($event.type === 'blur' && !this.actualConceptValue) {
      return;
    }

    if (!$event.items) {
      const catalogue = this.catalogues.find((value) => value._id == $event);
      if (!catalogue) {
        if (!$event.label) {
          this.lines?.controls[index].patchValue({
            concept: this.actualConceptValue,
          });
          this.actualConceptValue = '';
        } else {
          this.lines?.controls[index].patchValue({ concept: $event.label });
          this.actualConceptValue = '';
        }
        this.update();
      } else {
        this.lines?.controls.forEach((s, i) => {
          const line = s as FormGroup;
          if (index == i) {
            //console.log('TAXES:', catalogue.taxes);
            this.lines.controls[index].patchValue({
              concept: catalogue.name,
              price: catalogue.sellPrice,
              description: catalogue.description,
              taxes: catalogue.taxes
                ? catalogue.taxes
                : this.lines.controls[index].value.taxes,
              total: catalogue.sellPrice * line.controls.quantity.value,
            });
            this.calculateTaxesPerLine(this.lines.controls[index].value, index);
            this.update();
            this.update(false); //nedeed for correct reload
          }
        });
        this.actualConceptValue = '';
      }
    }
  }

  setItemData2($event) {}
  change($event) {}

  changeComma(value: number) {
    const valueSttring = String(value);
    return valueSttring.replaceAll('.', ',');
  }

  getAmount(item: IItemBudget) {
    return item?.quantity * item?.price;
  }

  getDiscount(item: IItemBudget, tax: boolean) {
    if (
      item?.discount &&
      (this.budget?.proFeatures?.hasDiscount || item?.discount != 0)
    ) {
      return (this.getAmount(item) * item?.discount) / 100;
    }

    if (tax) return 1;
    else return 0;
  }

  itemsChanged($event, index) {
    this.budget.items[index].quantity = $event.value.quantity;
    this.budget.items[index].price = $event.value.price;

    if (this.budget?.proFeatures?.hasDiscount) {
      if ($event.value.discount <= 0 || $event.value.discount > 100) {
        this.lines.controls[index].patchValue({
          discount: 0,
        });
      }
      this.budget.items[index].discount = $event.value.discount;
      this.calculateTaxesPerLine($event.value, index);
    }

    if ($event.value.quantity > 0 && $event.value.price > 0) {
      this.budget.items[index].total = $event.value.total;
    } else {
      this.budget.items[index].total = 0;
    }

    this.update();
  }

  calculateTaxesPerLine($event, $index) {
    let amount = 0;
    let gtax = 0;
    let ret = 0;

    amount = this.getAmount($event);
    this.budget?.items[$index].taxes.forEach((tax) => {
      const value =
        ((amount - this.getDiscount($event, false)) * tax.value) / 100;
      if (tax.type === TypeTax.RET) {
        ret -= value;
      } else {
        gtax += value;
      }
    });
    this.lines.controls[$index].patchValue({
      total: amount - this.getDiscount($event, false) + ret + gtax,
    });

    this.budget.items[$index].total =
      amount - this.getDiscount($event, false) + ret + gtax;
  }
}
