const hours = [
  {
    hour: '01',
  },
  {
    hour: '02',
  },
  {
    hour: '03',
  },
  {
    hour: '04',
  },
  {
    hour: '05',
  },
  {
    hour: '06',
  },
  {
    hour: '07',
  },
  {
    hour: '08',
  },
  {
    hour: '09',
  },
  {
    hour: '10',
  },
  {
    hour: '11',
  },
  {
    hour: '12',
  },
  {
    hour: '13',
  },
  {
    hour: '14',
  },
  {
    hour: '15',
  },
  {
    hour: '16',
  },
  {
    hour: '17',
  },
  {
    hour: '18',
  },
  {
    hour: '19',
  },
  {
    hour: '20',
  },
  {
    hour: '21',
  },
  {
    hour: '22',
  },
  {
    hour: '23',
  },
  {
    hour: '00',
  },
];

const minutes = [
  {
    min: '00',
  },
  {
    min: '05',
  },
  {
    min: '10',
  },
  {
    min: '15',
  },
  {
    min: '20',
  },
  {
    min: '25',
  },
  {
    min: '30',
  },
  {
    min: '35',
  },
  {
    min: '40',
  },
  {
    min: '45',
  },
  {
    min: '50',
  },
  {
    min: '55',
  },
];

export { hours, minutes };
