<roma-modal-template
  [title]="
    type === 'ADD'
      ? 'rates.catalogueForm.title'
      : ('rates.catalogueForm.editTitle' | translate)
  "
  [footerTemplate]="footerButtons"
  [showDefaultButtons]="false"
  [askBeforeClose]="haveChanges"
  [isLoading]="isLoading"
  (cancel)="close()"
  [showInfo]="true"
>
  <form
    [formGroup]="form"
    class="catalogue-form"
    [ngClass]="{
      'is-disabled': !hasPermissionToEdit
    }"
  >
    <div class="catalogue-row name-type">
      <div class="catalogue-row field name">
        <label class="catalogue-field-label" for="name">
          {{ 'rates.catalogueForm.nameLabel' | translate
          }}<obligatory-field-sign></obligatory-field-sign>
        </label>
        <input
          type="text"
          class="catalogue-form-control"
          id="name"
          aria-describedby="titleHelp"
          formControlName="name"
          maxLength="100"
          [placeholder]="'rates.catalogueForm.namePlaceholder' | translate"
          [ngClass]="{ 'is-invalid': fieldTitle.displayError || false }"
        />
        <div *ngIf="form.value.name?.length === 100">
          <mat-error class="warning-text"
            >{{ 'catalogue.create.name-max-chars' | translate }}
          </mat-error>
        </div>
        <roma-field-error-display
          #fieldTitle
          fieldName="name"
          [errorMsg]="'equipments.create.titleError'"
          [form]="form"
        >
        </roma-field-error-display>
      </div>
      <div class="catalogue-row field type">
        <label class="catalogue-field-label" for="type">
          {{ 'rates.catalogueForm.typeLabel' | translate }}
        </label>
        <ng-select
          class="custom-select"
          id="type"
          formControlName="type"
          [multiple]="false"
          [loading]="false"
          [placeholder]="'rates.catalogueForm.selectPlaceholder' | translate"
          [notFoundText]="'hiring.form.placeholder_notfound' | translate"
        >
          <ng-option *ngFor="let item of types" [value]="item.value">
            {{ item.translate | translate }}
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="catalogue-row field-description">
      <div class="catalogue-row description">
        <label class="catalogue-field-label" for="description">
          {{ 'rates.catalogueForm.descriptionLabel' | translate }}
        </label>
        <label class="catalogue-field-label total" for="description">
          {{ form.value.description?.length || 0 }}/600
        </label>
      </div>
      <textarea
        type="text"
        cols="30"
        rows="5"
        class="catalogue-form-textarea"
        formControlName="description"
        placeholder="{{
          'rates.catalogueForm.descriptionPlaceholder' | translate
        }}"
        maxLength="600"
      >
      </textarea>
      <div *ngIf="form.value.description?.length === 600">
        <mat-error class="error-label">{{
          'rates.catalogueForm.maxLengthDescriptionError' | translate
        }}</mat-error>
      </div>
    </div>
    <div class="catalogue-row">
      <div class="catalogue-row field duration">
        <label class="catalogue-field-label" for="duration">
          {{ 'rates.catalogueForm.durationLabel' | translate }}
        </label>
        <hour-input
          [hour]="durationHours"
          [minutes]="durationMinutes"
          [disabled]="!hasPermissionToEdit"
          (onChange)="onDurationChange($event)"
        ></hour-input>
      </div>
      <div class="catalogue-row field taxes">
        <label class="catalogue-field-label" for="taxes">
          {{ 'rates.catalogueForm.taxesLabel' | translate }}
        </label>
        <ng-select
          class="custom-select"
          id="taxes"
          formControlName="taxes"
          [multiple]="true"
          [loading]="false"
          [placeholder]="'rates.catalogueForm.selectPlaceholder' | translate"
          [notFoundText]="'hiring.form.placeholder_notfound' | translate"
          >
          <ng-option *ngFor="let item of taxes" [value]="item._id">
            {{ item.translate | translate }}
            {{ changeComma(item.value) }}%
          </ng-option>
        </ng-select>
      </div>
      <div class="catalogue-row field sale-price">
        <label class="catalogue-field-label" for="sellPrice">
          {{ 'rates.catalogueForm.salePriceLabel' | translate }}
        </label>
        <input
          type="number"
          class="catalogue-form-control"
          id="sellPrice"
          aria-describedby="titleHelp"
          formControlName="sellPrice"
          maxLength="100"
          [placeholder]="'rates.catalogueForm.salePricePlaceholder' | translate"
        />
      </div>
    </div>
    <div class="catalogue-row half-width" *ngIf="isNeededInCountry">
      <select-long-data-filtered
        style="width: 100%"
        [itemValue]="'id'"
        [selectedItems]="productKeySelected"
        [itemFilter]="'description'"
        [placeHolder]="'catalogue.create.select'"
        [labelText]="'catalogue.SAT'"
        [showWarning]="true"
        [warningText]="'catalogue.invoiceWarning'"
        [items]="productAndServices"
        [multiple]="false"
        [loading]="false"
        [maxDropDownItems]="500"
        [showInfoTooltip]="true"
        [tooltipMessages]="[
          { type: 'html', content: 'catalogue.tooltips.SAT' }
        ]"
        (onSelectedItemsChange)="onSatSelectedItemsChange($event)"
      >
      </select-long-data-filtered>
    </div>
    <hr *ngIf="showAdditionalFields" class="catalogue-row section-divider" />
    <div *ngIf="showAdditionalFields" class="catalogue-row">
      <div class="catalogue-row field half-width">
        <label class="catalogue-field-label" for="category">
          {{ 'rates.catalogueForm.categoryLabel' | translate }}
        </label>
        <ng-select
          id="category"
          bindLabel="name"
          bindValue="_id"
          formControlName="category"
          [multiple]="false"
          [loading]="false"
          [placeholder]="'rates.catalogueForm.selectPlaceholder' | translate"
          [notFoundText]="'hiring.form.placeholder_notfound' | translate"
          [items]="categories"
        >
        </ng-select>
      </div>
      <div class="catalogue-row field half-width">
        <label class="catalogue-field-label" for="idOwner">
          {{ 'rates.catalogueForm.userLabel' | translate }}
        </label>
        <ng-select
          id="idOwner"
          bindLabel="key"
          bindValue="value"
          formControlName="idUsers"
          [items]="owners"
          [multiple]="true"
          [loading]="false"
          [placeholder]="'rates.catalogueForm.selectPlaceholder' | translate"
          [notFoundText]="'hiring.form.placeholder_notfound' | translate"
        >
        </ng-select>
      </div>
    </div>
    <div *ngIf="showAdditionalFields" class="catalogue-row">
      <div class="catalogue-row field half-width">
        <label class="catalogue-field-label" for="internalReference">
          {{ 'rates.catalogueForm.internalReferenceLabel' | translate }}
        </label>
        <input
          type="text"
          class="catalogue-form-control"
          id="internalReference"
          aria-describedby="titleHelp"
          formControlName="internalReference"
          maxLength="100"
          [placeholder]="
            'rates.catalogueForm.internalReferencePlaceholder' | translate
          "
        />
      </div>
      <div class="catalogue-row field half-width">
        <label class="catalogue-field-label" for="buyPrice">
          {{ 'rates.catalogueForm.buyPriceLabel' | translate }}
        </label>
        <input
          type="number"
          class="catalogue-form-control"
          id="buyPrice"
          aria-describedby="titleHelp"
          formControlName="buyPrice"
          maxLength="100"
          [placeholder]="'rates.catalogueForm.buyPricePlaceholder' | translate"
        />
      </div>
    </div>
    <roma-append-custom-properties
      *ngIf="showAdditionalFields && type === 'ADD'"
      [typeRef]="typePropertyRef.Rates"
      [rowLayout]="true"
      size="lg"
      [control]="form.get('customProperties')"
      [parentForm]="form"
      [initForm]="initChildForm"
      [parentFormSubmit]="parentFormSubmit"
      (customPropertiesForm)="customPropertiesFormChange($event)"
      (initialCharge)="initialChargeEvent()"
      (closeModal)="close()"
    >
    </roma-append-custom-properties>
    <div
      class="edit-custom-properties"
      *ngIf="showAdditionalFields && type === 'EDIT'"
    >
      <roma-edit-custom-properties
        [rowLayout]="true"
        [typeRef]="typePropertyRef.Rates"
        [customProperties]="form.get('customProperties').value"
        [updateOnEdit]="false"
        [featureUser]="featureRefUser.Catalogue.update"
        (propertiesRequired)="propertiesRequired($event)"
        (onValueChanged)="eventValueChanged($event)"
      >
      </roma-edit-custom-properties>
    </div>
    <button class="toggle-fields-button" (click)="toggleFields()">
      {{
        showAdditionalFields
          ? ('rates.catalogueForm.hideFields' | translate)
          : isMobile
          ? ('rates.catalogueForm.showAllFieldsMobile' | translate)
          : ('rates.catalogueForm.showAllFields' | translate)
      }}
      <img
        *ngIf="!showAdditionalFields"
        src="assets/icons/arrow-down.svg"
        alt="Show more"
      />
      <img
        *ngIf="showAdditionalFields"
        src="assets/icons/arrow-up.svg"
        alt="Show less"
      />
    </button>
  </form>
</roma-modal-template>

<ng-template #footerButtons>
  <div class="catalogue-form footer">
    <base-button
      [isLoading]="isLoading"
      (click)="submit()"
      size="medium"
      [disabled]="
        !form.value.name?.length ||
        !hasPermissionToEdit ||
        invalidEditCustomFields
      "
    >
      {{ 'activity.create.submit' | translate }}
    </base-button>
  </div>
</ng-template>
