import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClockHoursDurationPipe } from './clock-hours-duration.pipe';

@NgModule({
  declarations: [ClockHoursDurationPipe],
  imports: [CommonModule],
  exports: [ClockHoursDurationPipe],
})
export class ClockHoursDurationModule {}
