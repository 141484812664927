import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  ViewChild,
} from '@angular/core';

import { RmDealSelect } from './rm-deal-select.types';
import { fromEvent, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'rm-deal-select',
  templateUrl: './rm-deal-select.component.html',
  styleUrls: ['./rm-deal-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmDealSelectComponent implements OnInit {
  @ViewChild('inputFilterSearch')
  set input(value: ElementRef<HTMLInputElement>) {
    if (value) {
      this._input = value;
      this.setFocusInputSearch();
      this.patchFilterArgParams();
      this.watchInputSearch();
    }
  }

  @Input()
  placeholderText = '';

  @Input()
  mode: RmDealSelect.Type = 'SINGLE';

  @Input()
  isAbsolute = false;

  @Input()
  isClearable = false;

  @Input()
  listWidth = '';

  @Input()
  addButtonText = 'general.addNewDeal';

  @Input()
  hasError = false;

  _panelClicked = false;
  _isLoading = false;
  _firstLoad = true;
  @Input()
  @HostBinding('class.is-loading')
  set isLoading(value: boolean) {
    this._isLoading = value;
    //if is changed is loaded to false for second time (latter than load), we open the panel
    if (this._isLoading == false) {
      if (this._firstLoad) {
        this._firstLoad = false;
        this._panelClicked = false;
      } else {
        if (this._panelClicked) {
          this.showPanelElement = true;
        }
        this._panelClicked = false;
      }
    }
  }

  get isLoading() {
    return this._isLoading;
  }

  @Input()
  @HostBinding('class.is-disabled')
  disabled!: boolean;

  @Input()
  items: RmDealSelect.Items = [];

  @Input()
  activeItems: RmDealSelect.Items = [];

  @Input()
  inputHeight = '44px';

  @Input()
  type = '';

  @Input()
  showAddButton = false;

  @Output()
  changeItems = new EventEmitter<RmDealSelect.Items>();

  @Output()
  addElement = new EventEmitter<MouseEvent>();

  @Input()
  showPanelElement = false;

  checkBoxItems: any[] = [];
  filterArgs: { name: string } = { name: '' };

  private _input: ElementRef<HTMLInputElement>;
  private sub$ = new Subscription();

  firstLoad = true;
  focusOutA = false;
  focusOutB = false;
  anuleFocusOut = false;

  @HostListener('window:resize', ['$event.target'])
  onResize() {
    this.resizePanel();
  }

  panelWidth = '300px';

  constructor(
    private cdr: ChangeDetectorRef,
    private hostElement: ElementRef
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.resizePanel();
  }

  private resizePanel(): void {
    const styles = getComputedStyle(this.hostElement.nativeElement);
    this.panelWidth = styles.width;
  }

  clickPanel(evt: MouseEvent, open?: boolean, from?: string) {
    this._panelClicked = true;
    if (evt) {
      if (open === true || open === false) {
        this.showPanelElement = open;
      } else {
        this.showPanelElement = !this.showPanelElement;
      }

      if (this.showPanelElement) {
        this.focusOutA = true;
        this.focusOutB = false;
      }
      this.draw();
    }
  }

  async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  anuleFocusOutTimeout() {
    this.anuleFocusOut = true;
    setTimeout(() => {
      this.anuleFocusOut = false;
    }, 200);
  }

  async focusOut(from: string) {
    await this.delay(100);

    if (this.anuleFocusOut == true) {
      return;
    }

    if (from === 'users') {
      this.focusOutA = true;
    }

    if (from === 'status') {
      this.focusOutB = true;
    }

    if (this.focusOutA && this.focusOutB) {
      this.showPanelElement = false;
    }
    this.draw();
  }

  changeSelect(item: RmDealSelect.Item) {
    this.activeItems = [item];
    this.showPanelElement = false;
    this.changeItems.next(this.activeItems);
    this.draw();
  }

  changeCheckBox(evt, item) {
    if (evt.checked) {
      //this.checkBoxItems.push(item.value);
      this.activeItems.push(item);
    } else {
      this.activeItems.splice(
        this.activeItems.findIndex(function (i) {
          return i._id === item._id;
        }),
        1
      );
    }

    this.changeItems.next(this.activeItems);
    this.draw();
  }

  searchCheckFilters(item) {
    if (
      this.activeItems.filter((element) => element._id == item._id).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  addElementHandle(evt: MouseEvent) {
    if (evt) {
      this.addElement.next(evt);
    }
  }

  clearInput(mode: 'in' | 'out') {
    this.activeItems = [];
    this.filterArgs.name = '';
    this.draw();

    if (mode === 'in') {
      this.changeItems.next(this.activeItems);
    }
  }

  private draw() {
    this.cdr.detectChanges();
  }

  private setFocusInputSearch() {
    if (this._input) {
      this._input.nativeElement.focus();
    }
  }

  private patchFilterArgParams() {
    if (this._input && this.filterArgs.name) {
      this._input.nativeElement.value = this.filterArgs.name;
    }
  }

  private watchInputSearch() {
    this.sub$.add(
      fromEvent(this._input.nativeElement, 'input')
        .pipe(
          distinctUntilChanged(),
          debounce(() => timer(450)),
          map((res) => (res.target as HTMLTextAreaElement).value as string)
        )
        .subscribe(this.changeKeyword.bind(this))
    );
  }

  private changeKeyword(keyword: string) {
    this.filterArgs = {
      name: keyword.toString().toLocaleLowerCase(),
    };
    this.draw();
  }

  isEmptyHour(date: Date) {
    return (
      typeof date === 'object' &&
      !date.getHours() &&
      !date.getMinutes() &&
      !date.getSeconds()
    );
  }
}

@Pipe({
  name: 'searchFilter',
  pure: false,
})
export class SearchFilterPipe implements PipeTransform {
  transform(items: RmDealSelect.Items, filter: { name: string }): any {
    if (!items?.length || !filter?.name) {
      return items;
    }

    return items.filter((item) =>
      item.name.match(new RegExp(filter.name, 'i'))
    );
  }
}
