import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MfCalendarComponent } from './mf-calendar.component';
import { AppName, AppUrl } from '../microfrontend.tokens';

const microfrontendKey = 'mf-calendar';

@NgModule({
  imports: [CommonModule],
  declarations: [MfCalendarComponent],
  exports: [MfCalendarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: AppName,
      useValue: microfrontendKey,
    },
    {
      provide: AppUrl,
      useFactory: () => {
        return localStorage.getItem(microfrontendKey);
      },
    },
  ],
})
export class MfCalendarModule {}
