import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BudgetListComponent } from './budget-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EditableFieldModule } from '../editable-field';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BudgetTableModule } from '../budget-table/budget-table.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [BudgetListComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    EditableFieldModule,
    ReactiveFormsModule,
    BudgetTableModule,
  ],
  exports: [BudgetListComponent],
})
export class BudgetListModule {}
