import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Feature, FeatureUser } from '@tacliatech/types';
import { PermissionService } from '@web-frontend/shared/services/permissions';

import SignaturePad from 'signature_pad';
import { Signature } from './signature-waybill.types';

// This component depends on the following script,
// https://cdn.jsdelivr.net/npm/signature_pad@2.3.2/dist/signature_pad.min.js
// documentation: https://github.com/szimek/signature_pad
// https://github.com/ramsatt/Angular9SignaturePad
// located in index.html
@Component({
  selector: 'roma-signature-waybill',
  templateUrl: './signature-waybill.component.html',
  styleUrls: ['./signature-waybill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatureWaybillComponent implements OnInit, AfterViewInit {
  @Input()
  id?: string;

  @Input()
  set source(value: string) {
    this.dataFromUrl = value;
    this.updateCanvas();
  }

  @Input()
  tagBottomCanvas!: string;

  @Input()
  block = false;

  @Input()
  isLoading = false;

  @Input()
  width = 400;

  @Input()
  height = 200;

  @Output()
  changeSignature = new EventEmitter<Signature.SignatureChange>();

  featureRef = Feature;
  hasFeatureSignDocuments$ = this.permissionService.hasFeatureFn(
    Feature.Deal.SignDocuments
  );
  featureRefUser = FeatureUser;
  isEmpty = false;

  // ACCESS TO EXTERNAL COMPONENTS
  public signaturePad!: SignaturePad;
  public dataFromUrl?: string;

  constructor(
    private permissionService: PermissionService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.createCanvas();
    this.watch();
    this.resolveIsEmpty();
  }

  update() {
    this.hasFeatureSignDocuments$.subscribe((res) => {
      this.changeSignature.emit({
        data: this.isEmpty ? '' : this.signaturePad.toDataURL(),
        featureOn: res,
      });
    });
  }

  clear() {
    this.signaturePad.clear();
    this.resolveIsEmpty();
    this.changeSignature.emit({
      data: 'noData',
    });
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  private createCanvas() {
    this.signaturePad = new SignaturePad(
      document.getElementById(this.id as string) as HTMLCanvasElement,
      {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        penColor: 'rgb(0, 0, 0)',
      }
    );

    if (this.block) {
      this.signaturePad.off();
    }

    this.draw();
  }

  // used in external app
  public resolveIsEmpty = () => {
    const isEmpty = this.signaturePad.isEmpty();

    this.isEmpty = isEmpty;
    this.draw();
  };

  private watch() {
    if (this.signaturePad) {
      this.signaturePad.addEventListener(
        'beginStroke',
        () => {
          this.resolveIsEmpty();
        },
        { once: true }
      );

      this.signaturePad.addEventListener(
        'endStroke',
        () => {
          this.resolveIsEmpty();
        },
        { once: true }
      );
    }
  }

  private updateCanvas() {
    if (this.dataFromUrl) {
      setTimeout(() => {
        this.signaturePad.clear();
        this.signaturePad.fromDataURL(this.dataFromUrl as string, {
          width: this.width,
          height: this.height,
        });
        this.resolveIsEmpty();
      });
    } else {
      this.signaturePad && this.signaturePad.clear();
      this.isEmpty = true;
    }
  }
}
