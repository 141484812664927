export const CatchmentChannels = [
  {
    id: 'RELATIONAL',
    en: 'Relational',
    es: 'Relacional',
  },
  {
    id: 'RECOMMENDATION',
    en: 'Recommendation',
    es: 'Recomendación',
  },
  {
    id: 'SITE_WEB',
    en: 'Web site',
    es: 'Sitio web',
  },
  {
    id: 'FRIENDS_OR_FAMILY',
    en: 'Friends or family',
    es: 'Amigos y familia',
  },
  {
    id: 'INTERNETS_AD',
    en: 'Internet ad',
    es: 'Anuncios en internet',
  },
  {
    id: 'FAIR_OR_EVENTS',
    en: 'Fair or events',
    es: 'Feria o eventos',
  },
  {
    id: 'OTHER',
    en: 'Others',
    es: 'Otros',
  },
];

export const TypeFinalsClients = [
  {
    id: 'PARTICULAR',
    en: 'Particular',
    es: 'Particular',
  },
  {
    id: 'BUSINESS',
    en: 'Business',
    es: 'Empresa',
  },
  {
    id: 'OTHER',
    en: 'Others',
    es: 'Otros',
  },
];
