<div *ngIf="title" class="row d-flex">
  <div class="col-12 subtitle">
    {{ title }}
  </div>
</div>
<div
  *ngIf="!isLoading; else loading"
  class="main-container"
  [ngClass]="{ clean__container: singleMode }"
>
  <div [ngClass]="{ 'image-add': !singleMode }">
    <!-- BEGIN IMAGES SELECTOR ICON '+' (show if no images in temporal array or fileserver array) -->
    <form enctype="multipart/form-data">
      <div
        id="add__files__box"
        romaProFeature
        [featureUser]="featureUser"
        [ngClass]="{ labelInputClassCreate: !singleMode }"
        [ngClass]="{
          labelInputClassCreate: !singleMode,
          'custom-tooltip top is__disabled': !(hasPermissionFeature$ | async)
        }"
        (clicked)="openFileSelector()"
      >
        <ng-container *ngIf="!singleMode">
          <img height="30" width="30" [src]="img" />
        </ng-container>
        <ng-container *ngIf="singleMode && !fileArray.length">
          <label class="btn file-btn">
            <img src="assets/icons/gl_attachment.svg" />
            {{ 'deals.add_file' | translate }}
          </label>
        </ng-container>
      </div>
    </form>
    <!-- END IMAGES SELECTOR ICON '+' -->
  </div>
  <div
    *ngFor="
      let file of singleMode ? fileArray.slice(0, 1) : fileArray;
      let i = index
    "
    class="image-container"
  >
    <div class="image-item" *ngIf="!file.markForDelete">
      <!-- BEGIN RENDER SERVER IMAGES -->
      <div
        class="image-preview"
        (mouseover)="file.hover = true"
        [ngStyle]="{ 'background-image': 'url(' + resolveFile(file) + ')' }"
      ></div>
      <div class="image-item-fileText">
        <div class="file-tooltip">
          {{ file.fileName + '.' + file.extension }}
        </div>
        <div class="image-item-fileName">
          {{ file.fileName }}
        </div>

        <div class="image-item-fileExtension">({{ file.extension }})</div>
        <img
          class="close-icon"
          [ngClass]="{ hide: !showCloseIcon }"
          src="assets/icons/gl_close_v2.svg"
          romaProFeature
          [featureUser]="featureUser"
          (clicked)="deleteFileInternal(file)"
        />
      </div>
      <!-- END BEGIN RENDER SERVER IMAGES -->

      <div
        *ngIf="file.hover"
        class="image-preview-over"
        (mouseout)="file.hover = false"
        (click)="downloadFile(file)"
        [ngClass]="[
          (i !== 0 && !(hasPermissionFeature$ | async)) ||
          (freeLimit?.consumed >= filesLimit &&
            !(hasPermissionFeature$ | async))
            ? 'custom-tooltip top is__disabled'
            : ''
        ]"
      >
        <div class="image-preview-over-image">
          <img src="assets/icons/gl_download_v2.svg" />
        </div>
        <div class="image-preview-over-text">
          {{ 'general.downloadExcel' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="d-flex justify-content-center align-items-center">
    <mat-progress-spinner
      class="loading-spinner"
      mode="indeterminate"
      [diameter]="40"
    ></mat-progress-spinner>
  </div>
</ng-template>
