import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendEmailSaleModalComponent } from './send-email-sale-modal.component';
import { ModalTemplateModule } from '../modal-template';
import { I18nChild } from '@web-frontend/shared/i18n';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FieldErrorDisplayModule } from '../field-error-display';
import { SendEmailSaleModalLightComponent } from './send-email-sale-modal-light/send-email-sale-modal-light.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BaseButtonModule } from '@web-frontend/common/components/button/baseButton.module';

@NgModule({
  declarations: [SendEmailSaleModalComponent, SendEmailSaleModalLightComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    I18nChild,
    MatChipsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule,
    FieldErrorDisplayModule,
    MatProgressSpinnerModule,
    BaseButtonModule,
  ],
  exports: [SendEmailSaleModalComponent],
})
export class SendEmailSaleModalModule {}
