import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DealSearchService,
  StorageService,
} from '@web-frontend/shared/services';
import { RmDealSelect } from '../globals/rm-deal-select/rm-deal-select.types';
import { Deal } from '@tacliatech/types';
import { SelectDealConfirmModalService } from './select-deal-confirm-modal';
import { CreateDealService } from '../create-deal';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';

@Component({
  selector: 'roma-user-deals-select-modal',
  templateUrl: './user-deals-select-modal.component.html',
  styleUrls: ['./user-deals-select-modal.component.scss'],
})
export class UserDealsSelectModalComponent implements OnInit {
  userDealsList: RmDealSelect.Items = [];
  selectedDeals: any[] = [];
  isDealListLoading = false;
  userDealsListCharged = false;

  constructor(
    private dialogRef: MatDialogRef<UserDealsSelectModalComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: { selectedDeal; items },
    private dealSearchService: DealSearchService,
    private changeDetectorRef: ChangeDetectorRef,
    private selectDealConfirmModalService: SelectDealConfirmModalService,
    private createDealService: CreateDealService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {}

  requestDeals() {
    this.isDealListLoading = true;
    this.draw();

    this.dealSearchService
      .search({
        author: StorageService.UserId,
      })
      .subscribe((res) => {
        // @ts-ignore
        this.userDealsList = res.docs.map((item) => {
          return {
            _id: item._id,
            name: Deal.GetDealTitle(item),
            final: item.final,
            initDate: item?.dates?.initTimestamp,
            products: item.products,
          };
        });
        this.userDealsListCharged = true;
        this.isDealListLoading = false;
        this.draw();
      });
  }

  changeCalatogueList(event) {
    this.selectedDeals = event;
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  close(params = null) {
    // @ts-ignore
    this.dialogRef.close(params);
  }

  submit() {
    if (this.data.items.length) {
      this.selectDealConfirmModalService
        .open({
          data: null,
        })
        .subscribe((res) => {
          if (res.confirm) {
            // @ts-ignore
            this.close(this.selectedDeals);
          } else {
            // @ts-ignore
            this.close([]);
          }
        });
    } else {
      // @ts-ignore
      this.close(this.selectedDeals);
    }
  }

  addDeal() {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.sale_card_deal_start,
    });
    this.createDealService.open().subscribe((res) => {
      this.requestDeals();
    });
  }
}
