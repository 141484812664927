import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { ModalTemplateModule } from '../../modal-template';
import { ShowDetailClockHourComponent } from './show-detail-clock-hours.component';
import { DateDayMonthModule } from '@web-frontend/shared/pipes/date-day-month/date-day-month.module';
import { AddClockHourModule } from '../../add-clock-hour';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionsModule } from '../../permissions';
import { BaseButtonModule } from '@web-frontend/common/components/button/baseButton.module';

@NgModule({
  declarations: [ShowDetailClockHourComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ModalTemplateModule,
    MatButtonModule,
    ClickDeleteModule,
    DateDayMonthModule,
    AddClockHourModule,
    PermissionsModule,
    BaseButtonModule,
  ],
  exports: [ShowDetailClockHourComponent],
})
export class ShowDetailClockHourModule {}
