import { HttpClient } from '@angular/common/http';
import { environment } from '@web-frontend/environments';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

const devMode = 'mf_dev_mode';

export function microfrontendsLoader(
  httpClient: HttpClient
): () => Observable<{ [key: string]: string }> {
  const importUrl = environment.microfrontendImportsUrl;
  return () =>
    httpClient.get(importUrl).pipe(
      tap((imports: { [key: string]: string }) => {
        if (localStorage.getItem(devMode) === 'true') return;
        for (const mf in imports) {
          localStorage.setItem(mf, imports[mf]);
        }
      }),
      catchError((error) => {
        console.error('Error loading microfrontends', error);
        return of({});
      })
    );
}
