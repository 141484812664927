<div class="card__clock__hour__left">
  <img src="assets/icons/clock-circle.svg" alt="" />
</div>
<div class="card__clock__hour__content">
  <div class="card__clock__hour__content__title">
    {{ content.title }}

    <div *ngIf="content.addTitleLabel" class="roma-label dark">
      {{ 'clockHour.today' | translate }}
    </div>
  </div>
  <div class="card__clock__hour__content__description">
    {{ content.description }}
  </div>
</div>
<div class="card__clock__hour__right">
  <button
    *ngIf="!content?.hour"
    class="roma-button outline"
    [disabled]="disabled"
    (click)="makeAction($event, 'SIGN')"
  >
    {{ 'table.colum.register' | translate }}
  </button>

  <ng-container *ngIf="content?.hour">
    <div
      *ngIf="content.status === 'APPROVED'"
      class="card__clock__hour__right__icon"
    >
      <img src="assets/icons/gl_check_white.svg" alt="" />
    </div>
    <div
      *ngIf="content.status === 'REJECTED'"
      class="card__clock__hour__right__icon-2"
    >
      <img src="assets/icons/gl_error.svg" alt="" />
    </div>
    <div class="card__clock__hour__right__text">
      {{ content.hour }}
    </div>

    <img
      romaClickStopPropagation
      [matMenuTriggerFor]="menu"
      class="more-actions"
      src="/assets/icons/gl_more-vertical.svg"
      (click)="$event.stopPropagation()"
    />
    <mat-menu
      class="custom-template-actions actions-menu"
      #menu="matMenu"
      xPosition="before"
    >
      <button
        class="delete"
        romaClickDelete
        mat-menu-item
        subtitle="deleteModal.body_final"
        romaProFeature
        [featureUser]="featureRefUser.ClockHour.delete"
        (wantDelete)="makeAction('DELETE')"
      >
        {{ 'general.deleteAlt' | translate }}
      </button>
    </mat-menu>
  </ng-container>
</div>
