<div class="day-selector">
  <div
    class="day-item"
    *ngFor="let day of days; let i = index"
    [class.selected]="selectedDays.includes(day.value)"
    (click)="toggleDaySelection(i)"
  >
    {{ getDayLabel(day) }}
  </div>
</div>
