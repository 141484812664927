<roma-modal-template
  [title]="'sales.editArticle'"
  [contentMinHeight]="false"
  [showCancelBtn]="false"
  [textSuccess]="'general.confirm'"
  (cancel)="close()"
  (success)="submit()"
>
  <form [formGroup]="form">
    <div class="catalogue__info">
      <div class="__name">
        <div class="__label">{{ 'general.name' | translate }}</div>
        <div class="__value">{{ catalogue.name }}</div>
      </div>
      <div class="__price">
        <div class="__label">{{ 'billing.price' | translate }}</div>
        <div class="__value __strong">
          {{ totalAmount || 0 | currencyFormat }}
        </div>
      </div>
    </div>

    <div class="catalogue__form">
      <div class="__price__input">
        {{ 'billing.price' | translate }}
        <input
          type="number"
          min="0"
          class="form-control"
          formControlName="sellPrice"
          (change)="formatValue('sellPrice')"
        />
      </div>
      <div class="__stock__input">
        {{ 'general.quantity' | translate }}
        <input
          romaOnlyNumber
          type="number"
          min="1"
          step="0.01"
          class="form-control"
          pattern="^\d+([.,]\d{1,2})?$"
          formControlName="stock"
        />
      </div>
      <div class="__total">
        Total
        <div class="__amount">
          {{ totalAmount || 0 | currencyFormat }}
        </div>
      </div>
    </div>
    <div class="info__text">
      <img src="assets/icons/gl_information-fill-gray.svg" />
      <div class="__text">
        {{ 'rates.editDealCatalogueInfo' | translate }}
      </div>
    </div>
  </form>
</roma-modal-template>
