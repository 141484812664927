import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectSearchService {
  name?: string;
  private removeSubject = new Subject<any>();
  removeEvent$ = this.removeSubject.asObservable();

  private clearSubject = new Subject<any>();
  clearEvent$ = this.clearSubject.asObservable();

  constructor() {}

  setName(n: string) {
    this.name = n;
  }

  removeItem(value) {
    this.removeSubject.next({ name: this.name, value: value });
  }

  clearItems(value) {
    this.clearSubject.next({ name: this.name, value: value });
  }
}
