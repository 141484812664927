<div class="selecting-element" (click)="select()">
  <div class="select-item">
    <img src="assets/icons/icon-upload-file.svg" alt="Icon upload" />
  </div>

  <div class="select-item">
    <div class="select-item__title">
      {{ 'general.uploadFile' | translate }}
    </div>

    <div class="select-item__description">
      {{ 'general.maxUploadFile' | translate }}
    </div>
  </div>
</div>

<ng-container *ngIf="listOfFiles.length">
  <div
    *ngFor="let resource of listOfFiles; let i = index"
    class="selected-files"
  >
    <div class="text">
      {{ resource.name }}
    </div>

    <div class="pointer delete-grey" (click)="deleteResource($event, i)">
      {{ 'general.remove' | translate }}
    </div>
  </div>
</ng-container>

<div
  *ngIf="
    (uploadProgress$ | async) && (uploadProgress$ | async)?.type !== 'HIDDEN'
  "
  class="progress-el"
>
  <div class="progress-container">
    <div
      class="progress-line"
      [ngStyle]="{
        width: (uploadProgress$ | async)?.value + '%'
      }"
    ></div>
  </div>
  <div class="progress-text">{{ (uploadProgress$ | async)?.value }}%</div>

  <div class="progress-options" (click)="cancelResource($event)">
    {{ 'general.cancel' | translate }}
  </div>
</div>
