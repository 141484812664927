import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { StorageService, UserService } from '@web-frontend/shared/services';
import { CompanyService } from '@web-frontend/shared/services/company';
import { finalize } from 'rxjs/operators';
import { ICompany } from '@tacliatech/types';
import { CreateConnectedAccountService } from './services/create-connected-account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'roma-create-connected-account',
  templateUrl: './create-connected-account.component.html',
  styleUrls: ['./create-connected-account.component.scss'],
})
export class CreateConnectedAccountComponent implements OnInit {
  isLoading = false;
  showInfo = false;
  connectedAcountExists = false;
  btnDisabled = false;

  btnText = 'Activar pagos';

  // @ViewChild('stripeIframe') stripeIframe: ElementRef;
  @ViewChild('stripeOnboardingLink')
  stripeOnboardingLink: ElementRef<HTMLAnchorElement>;

  constructor(
    private cdRef: ChangeDetectorRef,
    private companyService: CompanyService,
    private userService: UserService,
    private createConnectedAccountService: CreateConnectedAccountService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.companyService
      .findOne(StorageService.CompanyId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.draw();
        })
      )
      .subscribe((res: ICompany) => {
        this.connectedAcountExists =
          Boolean(res.stripe?.connectedAccountId) ?? false;

        if (this.connectedAcountExists && res.stripe.enabledToCharge) {
          this.btnDisabled = true;
          this.btnText = 'Los pagos ya han sido habilitados en esta compañía';
        }
      });
  }

  ngAfterViewInit(): void {
    // console.log(this.stripeOnboardingLink.nativeElement.href);
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  activatePayments() {
    this.isLoading = true;

    this.createConnectedAccountService
      .activatePayments(this.connectedAcountExists)
      .pipe(
        finalize(() => {
          this.isLoading = false;

          this.draw();
        })
      )
      .subscribe((res) => {
        // this.stripeIframe.nativeElement.src = res['url'];
        this.showInfo = true;
        this.stripeOnboardingLink.nativeElement.href = res['url'];
        this.draw();
      });
  }

  back() {
    this.router.navigateByUrl('/admin/payments');
  }
}
