import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';

import { DealAproveModule } from '../../components/deal-aprove/deal-aprove.module';
import { ClickAproveDealDirective } from './click-aprove-deal.directive';

@NgModule({
  declarations: [ClickAproveDealDirective],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    DealAproveModule,
    MatDialogModule,
  ],
  exports: [ClickAproveDealDirective],
})
export class ClickAproveDealModule {}
