import { ComponentRef, Injectable, Injector } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { ToastComponent } from './toast/toast.component';
import { IToastData, ToastData } from './utils/toast-config';
import { ToastRef } from './utils/toast-ref';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toasts: { height: number; ref: ToastRef }[] = [];
  private lastToast?: ToastRef;
  onClickLink$ = new BehaviorSubject<any>(null);
  onToastClose$ = new BehaviorSubject<boolean>(false);

  constructor(private overlay: Overlay, private parentInjector: Injector) {}

  get isMobile() {
    return window.screen.width < 768;
  }

  addField(data: ToastData, field: string, value: any) {
    if (data[field] == null) {
      return value;
    }
    return data[field];
  }

  initData(data: IToastData) {
    switch (data.type) {
      case 'success':
        data.msDuration = this.addField(data, 'msDuration', 8000);
        data.showClose = this.addField(data, 'showClose', true);
        data.icon = this.addField(
          data,
          'icon',
          'assets/icons/toast/success.svg'
        );
        data.class = data.class
          ? 'stoast success ' + data.class + ' '
          : 'stoast success ';
        if (
          !data.link &&
          data.text?.length <= 80 &&
          !data.class.includes('medium')
        ) {
          data.class += 'small ';
        }
        if (this.isMobile) {
          data.class += 'isMobile ';
        }
        break;
      case 'info':
        data.msDuration = this.addField(data, 'msDuration', 8000);
        data.showClose = this.addField(data, 'showClose', true);
        data.icon = this.addField(data, 'icon', 'assets/icons/toast/info.svg');
        data.class = data.class
          ? 'stoast info ' + data.class + ' '
          : 'stoast info ';
        if (
          !data.link &&
          data.text?.length <= 80 &&
          !data.class.includes('medium')
        ) {
          data.class += 'small ';
        }
        if (this.isMobile) {
          data.class += 'isMobile ';
        }
        break;
      case 'error':
        data.msDuration = this.addField(data, 'msDuration', 8000);
        data.showClose = this.addField(data, 'showClose', true);
        data.icon = this.addField(data, 'icon', 'assets/icons/toast/error.svg');
        data.class = data.class
          ? 'stoast error ' + data.class + ' '
          : 'stoast error ';
        if (
          !data.link &&
          data.text?.length <= 75 &&
          !data.class.includes('medium')
        ) {
          data.class += 'small ';
        }
        if (this.isMobile) {
          data.class += 'isMobile ';
        }
        break;
    }

    return data;
  }

  show(data: IToastData) {
    data = this.initData(data);
    const positionStrategy = this.getPositionStrategy();
    const overlayRef = this.overlay.create({ positionStrategy });

    const toastRef = new ToastRef(overlayRef);

    let height = 60;

    if (!data.class.includes('small') && !data.class.includes('medium')) {
      height = 100;
    } else if (data.class.includes('medium')) {
      height = 70;
    }

    this.toasts.push({
      height,
      ref: toastRef,
    });

    this.lastToast = toastRef;

    const injector = Injector.create({
      parent: this.parentInjector,
      providers: [
        { provide: ToastData, useValue: data },
        { provide: ToastRef, useValue: toastRef },
      ],
    });

    const toastPortal = new ComponentPortal(ToastComponent, null, injector);
    const componentRef = overlayRef.attach(toastPortal);

    // subscribe to output events
    this.outputEvents(componentRef);

    return toastRef;
  }

  outputEvents(componentRef: ComponentRef<ToastComponent>) {
    let sendedLinkClick = false;
    let sendedToastClose = false;
    componentRef.instance.onClickLink.subscribe((evt) => {
      if (!sendedLinkClick) {
        sendedLinkClick = true;
        this.onClickLink$.next(evt);
      }
    });
    componentRef.instance.onToastClose.subscribe((evt) => {
      if (!sendedToastClose) {
        sendedToastClose = true;
        this.onToastClose$.next(true);
      }
    });
  }

  getPositionStrategy() {
    const toasts = this.toasts.filter((fil) => fil.ref?.isVisible());
    if (toasts.length == 0) {
      this.toasts = [];
    }
    for (let i = this.toasts.length - 1; i > -1; i--) {
      if (this.toasts[i].ref.isVisible()) {
        break;
      } else {
        this.toasts.splice(i, 1);
      }
    }
    let bottom = 20;
    for (let i = 0; i < this.toasts.length; i++) {
      bottom += this.toasts[i].height;
    }
    return this.overlay
      .position()
      .global()
      .bottom(bottom + 'px')
      .left('20px');
  }

  //deprecated: this.getInjector(data, toastRef, this.parentInjector);
  getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector) {
    const tokens = new WeakMap();

    tokens.set(ToastData, data);
    tokens.set(ToastRef, toastRef);

    return new PortalInjector(parentInjector, tokens);
  }

  close() {
    this.lastToast?.close();
  }
}
