import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { ActivityGlobal, ObjectUtils, Pagination } from '@tacliatech/tools';

import { CalendarEvent, CalendarView } from 'angular-calendar';

import { Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import {
  addMonths,
  endOfDay,
  endOfWeek,
  startOfDay,
  startOfWeek,
  subMonths,
} from 'date-fns';

import { ActivityService } from './activity.service';
import { ChecklistGlobal } from './activity-calendar.types';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@web-frontend/shared/services';
import {
  BudgetsSetting,
  FIRST_DAY_CALENDAR,
  IBudgetsSetting,
} from '@tacliatech/types';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { DateParsePipe } from '@web-frontend/shared/pipes/date-parse/date-parse.pipe';
import { Lang } from '@web-frontend/shared/i18n';

@Component({
  selector: 'roma-activity-calendar',
  templateUrl: './activity-calendar.component.html',
  styleUrls: ['./activity-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityCalendarComponent implements OnInit {
  @Input()
  set searchParams(value: { [key: string]: any }) {
    this.params = {
      ...value,
      limit: 200,
      page: 1,
      sort: Pagination.PaginateSort.ASC,
      optimized: true,
    };

    this.changeParams();
  }

  get searchParams() {
    return this.params;
  }

  @Output()
  activityClick = new EventEmitter();

  events: CalendarEvent[] = [];

  defaultSettings = new BudgetsSetting();
  calendarDay!: number;

  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();

  CalendarView = CalendarView;
  schedulesTypes = ChecklistGlobal.SchedulesTypes;

  locale = StorageService.GetItem('USER_LANG') as Lang;
  private sub$ = new Subscription();
  refresh: Subject<any> = new Subject();

  activities: ActivityGlobal.Activity[] = [];
  resultSearch!: Pagination.Response<ActivityGlobal.Activity[]>;
  activitiesMap = new Map<string, ActivityGlobal.Activity>();

  loading = false;

  private params: { [key: string]: any } = {};

  private flightRequest!: Subscription;

  constructor(
    private cdRef: ChangeDetectorRef,
    private activitySearchService: ActivityService,
    private i18n: TranslateService,
    private budgetService: BudgetService
  ) {}

  ngOnInit(): void {
    this.watchTranslations();
    this.getSettings();
  }

  getSettings() {
    const id = StorageService.CompanyId;
    let dayInit = StorageService.FirstDayCalendar;
    this.defaultSettings.budgetPreferences.day = dayInit;
    this.sub$.add(
      this.budgetService
        .findSettingsByCompany(id)
        .pipe(finalize(() => this.draw()))
        .subscribe((resp: IBudgetsSetting) => {
          this.defaultSettings = resp;
          if (resp.budgetPreferences?.day) {
            dayInit = resp.budgetPreferences.day;
            StorageService.SetItem(FIRST_DAY_CALENDAR, dayInit);
          } else {
            this.defaultSettings.budgetPreferences.day = 1;
          }
        })
    );
  }

  private requestActivities({ loadingGlobal = false }) {
    if (loadingGlobal) {
      this.loading = true;
      this.draw();
    }

    this.generateDate();

    const query = ObjectUtils.RemoveEmpty(this.searchParams);

    const request$ = this.activitySearchService.search(query).pipe(
      finalize(() => {
        this.loading = false;
        this.draw();
      })
    );

    if (this.flightRequest) {
      this.flightRequest.unsubscribe();
    }

    this.flightRequest = request$.subscribe((res) => {
      this.setActivities(res);
      this.generateEvents();
    });
  }

  changeView() {
    this.generateDate();
    this.requestActivities({ loadingGlobal: true });
    this.draw();
  }

  changeTypeViews(view: CalendarView) {
    if (this.view !== view) {
      this.view = view;

      this.generateDate();
      this.requestActivities({ loadingGlobal: true });
      this.draw();
    }
  }

  eventClick({ id }: CalendarEvent) {
    this.activityClick.emit(id);
  }

  private generateDate() {
    switch (this.view) {
      case CalendarView.Month:
        this.params = {
          ...this.params,
          from: subMonths(this.viewDate, 1),
          to: addMonths(this.viewDate, 1),
        };
        break;

      case CalendarView.Week:
        this.params = {
          ...this.params,
          from: startOfWeek(this.viewDate),
          to: endOfWeek(this.viewDate),
        };
        break;

      case CalendarView.Day:
        this.params = {
          ...this.params,
          from: startOfDay(this.viewDate),
          to: endOfDay(this.viewDate),
        };
        break;
    }
  }

  private setActivities(
    response: Pagination.Response<ActivityGlobal.Activity[]>
  ) {
    this.resultSearch = response;

    for (const deal of response.docs) {
      this.activitiesMap.set(deal?._id, deal);
    }

    this.activities = Array.from(this.activitiesMap.values());
  }

  private generateEvents() {
    this.events = [];

    let localEvents: CalendarEvent[] = [];

    for (const activity of this.activitiesMap.values()) {
      if (activity?.dateTime) {
        const date = new DateParsePipe(this.i18n)
          .transform(activity.dateTime.toString(), 'AvoidLocaleDate')
          .split('/');

        const instance = new Date(
          `${date[2]}-${date[1]}-${date[0]}T${
            activity.hour ? activity.hour : '00:00'
          }`
        );

        localEvents = [
          ...localEvents,
          {
            id: activity._id,
            start: instance,
            title: activity.title,
          },
        ];
      }
    }

    this.events = [...localEvents];
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  private changeParams() {
    this.clear();

    this.requestActivities({ loadingGlobal: true });
  }

  private clear() {
    this.activitiesMap.clear();

    this.events = [];
    this.activities = [];

    this.draw();
  }

  private watchTranslations() {
    this.sub$.add(
      this.i18n.onLangChange.subscribe((res) => {
        const lang = res?.lang as Lang;
        StorageService.SetItem('USER_LANG', lang);
        this.locale = lang;
        this.refresh.next();
        this.draw();
      })
    );
  }
}
