// Importa los módulos necesarios
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class CountryGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    const country = 'ES';
    const userData = JSON.parse(localStorage.getItem('userData'));
    const isValid =
      userData && (!userData.country || userData.country === country);
    if (!isValid) {
      this.router.navigateByUrl('/admin/not-found');
    }
    return isValid;
  }
}
