<roma-modal-template
  [title]="title"
  [disableSuccess]="!can()"
  [showSuccessBtn]="false"
  [showCancelBtn]="false"
  (cancel)="ok()"
  (success)="ok()"
>
  <mat-chip-list *ngIf="type === 'info'">
    <mat-chip *ngFor="let item of elements">
      {{ item.number ? item.pre + '' + item.number : item }}
    </mat-chip>
  </mat-chip-list>
  <ng-container *ngIf="type === 'image'">
    <div class="row">
      <div class="col-12">
        <img
          [src]="images[0].img | image: images[0].type"
          style="width: 100%"
          class="image-element"
        />
      </div>
      <div class="col-12" style="text-align: center; margin-top: 10px">
        <button
          type="button"
          mat-button
          color="link"
          (click)="download(images[0])"
        >
          {{ 'budget.donwload' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</roma-modal-template>
