import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SignatureWaybillModalComponent } from './signature-waybill-modal.component';
import { SignatureWaybillModal } from './signature-waybill-modal.types';

@Injectable({
  providedIn: 'root',
})
export class CreateSignatureWaybillService {
  private refreshListSource = new Subject<boolean>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(
    config?: MatDialogConfig<SignatureWaybillModal.ModalParams>
  ): Observable<SignatureWaybillModal.ModalParamsRes> {
    const dialogRef = this.dialog.open(SignatureWaybillModalComponent, config);

    const source = config?.data?.source;
    dialogRef.backdropClick().subscribe((res) => {
      if (res) return dialogRef.close({ source, success: false });
    });

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }
}
