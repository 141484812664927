<roma-modal-template
  [title]="'sales.salesCard.cancelModalTitle' | translate"
  [size]="'sm'"
  [contentMinHeight]="false"
  [textSuccess]="'sales.salesCard.cancelModalTitle'"
  [headerBottomMargin]="'16px'"
  (cancel)="close()"
  type="sales"
  [showCancelBtn]="true"
  [footerTemplate]="footerButtons"
  [showDefaultButtons]="false"
  [disableSuccess]="cancelReason?.trim() === ''"
  (success)="submit()"
>
  <div class="modal__wrapper">
    <p
      class="subtitle"
      [innerHTML]="'sales.salesCard.cancelModalSubtitle' | translate"
    ></p>

    <textarea
      type="text"
      rows="5"
      class="form-control custom-textarea"
      style="resize: none"
      [(ngModel)]="cancelReason"
      [placeholder]="'sales.salesCard.textAreaPh' | translate"
    >
    </textarea>
  </div>
</roma-modal-template>

<ng-template #footerButtons>
  <div class="modalFooter">
    <base-button
      buttonClass="tertiary"
      (click)="submit()"
      [disabled]="cancelReason.trim().length < 1"
    >
      {{ 'sales.salesCard.cancelModalTitle' | translate }}
    </base-button>
  </div>
</ng-template>
