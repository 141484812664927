import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModule } from '@web-frontend/shared/components/loading';

import { MatButtonModule } from '@angular/material/button';

import { TrackingObjectComponent } from './tracking-objects.component';
import { SelectSearchModule } from '@web-frontend/shared/components/select-search/select-search.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { TrackingService } from '@web-frontend/shared/services/tracking';
import { FilterTrackingModule } from '../filter-tracking';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TrackingObjectComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    LoadingModule,
    MatButtonModule,
    SelectSearchModule,
    GoogleMapsModule,
    FilterTrackingModule,
  ],
  providers: [TrackingService],
  exports: [TrackingObjectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TrackingObjectModule {}
