import { Injectable } from '@angular/core';
import { PlatformService } from '../../components/platform-disable-content/platform.service';
import { Share } from '@capacitor/share';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { uniqueId } from 'lodash';

export interface ShareFile {
  title?: string;
  text?: string;
  uri?: string;
  blob?: Blob;
  fileName?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  navigator: Navigator;

  constructor(private readonly platformService: PlatformService) {
    this.navigator = window.navigator;
  }

  blobToFile(blob: Blob, fileName: string): File {
    return new File([blob], fileName, { type: blob.type });
  }

  blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  getWhatsappUrl(uri: string): string {
    return `https://web.whatsapp.com/send?text=${encodeURIComponent(uri)}`;
  }

  private prepareShare(blob: Blob, path: string): Promise<string> {
    return new Promise<string>(async (resolve) => {
      if (!blob) resolve('');
      const base64Data = await this.blobToBase64(blob);
      await Filesystem.writeFile({
        path: path,
        data: base64Data,
        directory: Directory.Cache,
      });
      Filesystem.getUri({
        directory: Directory.Cache,
        path: path,
      }).then((res) => resolve(res.uri));
    });
  }

  share(shareFile: ShareFile): void {
    const { uri, fileName, blob, text = '', title = '' } = shareFile;

    if (this.platformService.isPc()) {
      const url = this.getWhatsappUrl(uri);
      window.open(url);
    } else {
      if (this.platformService.isMobileSafari()) {
        Share.share({ url: uri });
      } else {
        const id = uniqueId();
        const path = `${id}-${fileName}`;
        this.prepareShare(blob, path).then((url) => {
          Share.share({
            title,
            text,
            ...(url && { files: [url] }),
          }).then(() => {
            if (url)
              Filesystem.deleteFile({
                path: path,
                directory: Directory.Cache,
              });
          });
        });
      }
    }
  }
}
