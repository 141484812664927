import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Capacitor } from '@capacitor/core';
import { Toast } from '@capacitor/toast';

import { from, Observable } from 'rxjs';

import Swal from 'sweetalert2';

import { AlertSureOptions, AlertToastOptions } from './alert.types';
import { SnackbarComponent } from '@web-frontend/shared/services/snackbar/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private _snackBar: MatSnackBar) {}

  sure(
    title: string,
    text: string,
    showCancel = true,
    confirmButtonText = 'Ok',
    cancelButtonText = 'Cancel'
  ): Observable<AlertSureOptions> {
    const dialog = Swal.fire<boolean>({
      title,
      text,
      icon: 'warning',
      showCancelButton: showCancel,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText,
      cancelButtonText,
      heightAuto: true,
    });

    return from(dialog);
  }

  success(message: string) {
    this.showToastMessage(message);
  }

  error(message: string) {
    this.showToastMessage(message);
  }

  errorOptions(message: string, options: AlertToastOptions) {
    this.showToastMessage(message, options);
  }

  customAlert(text: string, type: string) {
    this.showCustomAlert(text, type);
  }

  private showToastMessage(
    message: string,
    options: AlertToastOptions = { duration: 2000 }
  ) {
    if (Capacitor.getPlatform() === 'web') {
      this.showWebToast(message, options);
    } else {
      this.showMobileToast(message, options);
    }
  }

  private showWebToast(message: string, options: AlertToastOptions) {
    this._snackBar.open(message, 'OK', {
      duration: options.duration,
      verticalPosition: 'top',
    });
  }

  private showMobileToast(message: string, options: AlertToastOptions) {
    Toast.show({
      text: message,
      position: 'bottom',
      duration: options.duration > 3000 ? 'long' : 'short',
    }).then();
  }

  private showCustomAlert(text: string, type: string) {
    const snackBarRef = this._snackBar.openFromComponent(SnackbarComponent, {
      duration: 4000,
      verticalPosition: 'top',
      data: {
        type: type,
        text: text,
      },
    });

    snackBarRef.afterDismissed().subscribe((data) => {
      // do something like send event to analytics
    });
  }
}
