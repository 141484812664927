import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { SnackbarService } from './stripe-snackbar.service';
import { PaymentsService } from '../../../core/admin/payments/services/payments.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TacliaPayments } from '@tacliatech/types';
import { AuthService } from '@web-frontend/shared/services';
import { Router } from '@angular/router';

@Component({
  selector: 'roma-shared-stripe-snackbar',
  templateUrl: './stripe-snackbar.component.html',
  styleUrls: ['./stripe-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedStripeSnackbarComponent implements OnInit, OnDestroy {
  stripeObject?: TacliaPayments.IStripe;
  sub$ = new Subscription();
  StatusAccountEnumRef = TacliaPayments.StatusAccount;
  isLoading = true;
  isLoadingFunctionality = false;
  @Input() inPaymentMethods = false;
  isAdmin = false;

  constructor(
    private snackbarService: SnackbarService,
    private paymentService: PaymentsService,
    private authService: AuthService,
    private cdRef: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.sub$.add(
      this.paymentService.stripeObject$
        .pipe(
          finalize(() => {
            this.draw();
          })
        )
        .subscribe((res) => {
          this.isLoading = false;

          if (res) {
            this.stripeObject = res;

            this.draw();
          }
        })
    );

    this.authService.isAdmin$.subscribe((isAdmin) => (this.isAdmin = isAdmin));
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  public displayable(): boolean {
    return this.showSnackbar() && this.isAdmin;
  }

  showSnackbar() {
    if (this.stripeObject) {
      return (
        !this.stripeObject.snackbarClosed &&
        [
          TacliaPayments.StatusAccount.APPROVED_WITH_WARNING,
          TacliaPayments.StatusAccount.REJECTED,
          TacliaPayments.StatusAccount.SUSPENDED,
        ].includes(this.stripeObject?.onboarding?.status)
      );
    }

    return false;
  }

  goToPayments() {
    this.isLoadingFunctionality = true;

    this.snackbarService
      .updateStatus()
      .pipe(
        finalize(() => {
          this.isLoadingFunctionality = false;
          this.router.navigate(['admin', 'payments']);
        })
      )
      .subscribe((res) => {
        this.paymentService.setStripeObject(res);
      });
  }

  close() {
    this.sub$.add(
      this.snackbarService.updateStatus().subscribe((res) => {
        this.paymentService.setStripeObject(res);
      })
    );
  }
}
