import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@web-frontend/shared/services';

@Injectable()
export class AutoLogoutGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  public async canActivate() {
    this.authService.applyAutoLogout();
    return true;
  }
}
