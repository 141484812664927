<div class="header-primary">
  <div class="header-controls">
    <div class="header-option">
      <mat-icon
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="changeView()"
      >
        arrow_back_ios
      </mat-icon>
    </div>

    <div class="header-option">
      <mat-icon
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="changeView()"
      >
        arrow_forward_ios
      </mat-icon>
    </div>
  </div>
  <h3
    class="header-title"
    mwlCalendarToday
    [(viewDate)]="viewDate"
    (viewDateChange)="changeView()"
  >
    {{
      viewDate
        | calendarDate
          : view + 'ViewTitle'
          : locale
          : defaultSettings?.budgetPreferences?.day
    }}
  </h3>

  <mat-chip-list aria-label="Change view">
    <mat-chip
      *ngFor="let viewEl of schedulesTypes"
      class="chip-view"
      [ngClass]="{ active: viewEl.value === view }"
      (click)="changeTypeViews(viewEl.value)"
    >
      {{ viewEl.name | translate | uppercase }}
    </mat-chip>
  </mat-chip-list>
</div>

<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-top">
    <span class="cal-day-number">
      {{
        day.date
          | calendarDate
            : 'monthViewDayNumber'
            : locale
            : defaultSettings?.budgetPreferences?.day
      }}
    </span>
  </div>
  <small class="badge-container">
    <div
      *ngFor="let event of day.events"
      class="badge-title"
      [title]="event.title"
      (click)="eventClick(event)"
    >
      {{ event.title }}
    </div>
  </small>
</ng-template>

<roma-loading *ngIf="loading"></roma-loading>

<div *ngIf="!loading" [ngSwitch]="view">
  <mwl-calendar-month-view
    *ngSwitchCase="CalendarView.Month"
    [viewDate]="viewDate"
    [events]="events"
    [locale]="locale"
    [weekStartsOn]="defaultSettings?.budgetPreferences?.day"
    [cellTemplate]="customCellTemplate"
    (eventClicked)="eventClick($event.event)"
  >
  </mwl-calendar-month-view>
  <mwl-calendar-week-view
    *ngSwitchCase="CalendarView.Week"
    [viewDate]="viewDate"
    [events]="events"
    [locale]="locale"
    [weekStartsOn]="defaultSettings?.budgetPreferences?.day"
    [dayStartHour]="0"
    (eventClicked)="eventClick($event.event)"
  >
  </mwl-calendar-week-view>
  <mwl-calendar-day-view
    *ngSwitchCase="CalendarView.Day"
    [viewDate]="viewDate"
    [events]="events"
    [locale]="locale"
    [dayStartHour]="0"
    (eventClicked)="eventClick($event.event)"
  >
  </mwl-calendar-day-view>
</div>
