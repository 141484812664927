import { StripeErrorsCode } from '../../enum';

/**
 * Api Codes
 */
export const ApiCodeResourceMissing = 'stripe_resource_missing';
export const ApiCodeInvalidAmount = 'stripe_invalid_amount';
export const ApiCodeConnectionToken = 'stripe_connection_token_error';
export const ApiDefaultError = 'stripe_general_error';

//----------------------------------------------
export const StripeErrors = {
  ResourceMissing: (param) => {
    return {
      apiErrorCode: ApiCodeResourceMissing,
      errorMessage: `Intent Resource not Found - ${param}`,
      reason: `StripeResourceMissing`,
    };
  },
  InvalidAmount: (param) => {
    return {
      apiErrorCode: ApiCodeInvalidAmount,
      errorMessage: `InvalidAmount - ${param}`,
      reason: `InvalidAmount`,
    };
  },
  ConnectionTokenError: (param) => {
    return {
      apiErrorCode: ApiCodeConnectionToken,
      errorMessage: `Error creating connection token - location : ${param}`,
      reason: `Creation Error ConnectionToken`,
    };
  },
  InvalidRequestError: (param) => {
    return {
      apiErrorCode: StripeErrorsCode.StripeInvalidRequestError,
      errorMessage: `Invalid request : ${param}`,
      reason: `Invalid Request `,
    };
  },
  StripeDefaultError: (param) => {
    return {
      apiErrorCode: ApiDefaultError,
      errorMessage: `Stripe general server error .${param}`,
      reason: `Stripe server error`,
    };
  },
  InvalidConnectedAccount: (param) => {
    return {
      apiErrorCode: StripeErrorsCode.InvalidConnectedAccount,
      errorMessage: `Invalid account: ${param}`,
      reason: `Invalid connected account`,
    };
  },
};
