import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  private _previousUrl?: string;
  private subs?: Subscription;

  constructor(private router: Router) {}

  public startListen(): void {
    this.subs = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this._previousUrl = event.url;
      });
  }

  public stopListen(): void {
    this.subs.unsubscribe();
    this._previousUrl = undefined;
  }

  public get previousUrl(): string | undefined {
    return this._previousUrl;
  }
}
