<ng-container *ngIf="type === 'DATABASE'; else maps">
  <form [formGroup]="selectForm" (submit)="onSelectObject()">
    <div class="form-group">
      <span for="select-search" class="select-search-label input-label">
        {{ tag | translate }}
      </span>
      <roma-ng-select
        id="select-search"
        bindLabel="name"
        bindValue="_id"
        class="w-100"
        name="aux_property"
        formControlName="aux_property"
        [placeholder]="label | translate"
        appearance="outline"
        [loading]="loading"
        [clearable]="isClearable"
        [multiple]="isMultiple"
        [maxSelectedItems]="maxSelectedItems"
        [closeOnSelect]="true"
        [deepLink]="deepLink"
        [notFoundText]="notFoundText | translate"
        (remove)="onRemoveEvent($event)"
        (clear)="onClearEvent($event)"
        (search)="onSelectObject($event)"
        (change)="onSelectObject($event)"
        (itemSelect)="onItemSelect($event)"
      >
        <ng-option
          *ngFor="let obj of datasource"
          [value]="obj.id ? obj.id : obj"
          [disabled]="obj?.deleted ? 'true' : false"
        >
          {{ obj.name ? obj.name : obj }}
        </ng-option>
      </roma-ng-select>

      <ng-container
        *ngIf="
          selectForm.get('aux_property').invalid &&
          selectForm.get('aux_property').touched
        "
        class="is-invalid"
      >
        {{ labelError | translate }}

        <div
          class="label-invalid"
          *ngIf="!!selectForm.get('aux_property').hasError('required')"
        >
          {{ labelError | translate }}
        </div>
      </ng-container>
    </div>
  </form>

  <div class="container-options-select">
    <ng-container *ngIf="showAddButton === true">
      <button mat-button color="primary" (click)="onAddClick($event)">
        <img src="/assets/images/gl_plus-circle.svg" alt="Añadir" />
        {{ 'general.add' | translate }} {{ tag | translate }}
      </button>
    </ng-container>

    <ng-container *ngIf="showCheckButton === true">
      <mat-checkbox
        color="primary"
        class="ml-2 checkbox-search"
        (change)="checkButtonChange($event)"
      >
        <small> {{ textCheck | translate }} </small>
      </mat-checkbox>
    </ng-container>
  </div>
</ng-container>

<ng-template #maps>
  <mat-form-field appearance="outline" class="mb-0">
    <mat-label>
      {{ label }}
    </mat-label>
    <mat-icon matPrefix class="icon-hiring">place</mat-icon>
    <input
      matInput
      matGoogleMapsAutocomplete
      name="address"
      type="text"
      [value]="setValue"
      (onAutocompleteSelected)="onAutocompleteSelected($event)"
    />
  </mat-form-field>
</ng-template>
