import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'roma-modal-empty-object',
  templateUrl: './modal-empty-object.component.html',
  styleUrls: ['./modal-empty-object.component.scss'],
})
export class ModalEmptyObjectComponent implements OnInit {
  title = '';

  @Input()
  type: any;

  messages = new Map();

  constructor(private activeModal: NgbActiveModal, private router: Router) {
    this.setMessages();
  }

  setMessages() {
    this.messages.set('deal', 'general.empty_deal');
    this.messages.set('activity', 'general.empty_activity');
    this.messages.set('final', 'general.empty_final');
    this.messages.set('vendor', 'general.empty_vendor');
    this.messages.set('project', 'general.empty_project');
    this.messages.set('equipment', 'general.empty_equipment');
    this.messages.set('asset', 'general.empty_asset');
  }

  getMessage() {
    return this.messages.get(this.type);
  }

  ngOnInit(): void {}

  ok() {
    this.activeModal.close();
    this.router.navigateByUrl('/admin/deals');
  }
}
