import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivityGlobal, Pagination } from '@tacliatech/tools';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  constructor(private http: HttpClient) {}

  search(query: { [key: string]: any } = {}) {
    const params = new HttpParams({
      fromObject: {
        ...query,
      },
    });

    return this.http.get<Pagination.Response<ActivityGlobal.Activity[]>>(
      `:API_URL/search/activities`,
      {
        params,
      }
    );
  }
}
