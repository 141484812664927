import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';

import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { ClickStopPropagationModule } from '@web-frontend/shared/directives/click-prevent-default';
import { SplitModule } from '@web-frontend/shared/pipes/split';
import { I18nChild } from '@web-frontend/shared/i18n';

import { ImageModule } from '@web-frontend/shared/pipes/image';
import { ClickDeleteDealModule } from '@web-frontend/shared/directives/click-delete-deal';
import { ClickAproveDealModule } from '@web-frontend/shared/directives/click-aprove-deal';
import { ClickRestoreModule } from '@web-frontend/shared/directives/click-restore/click-restore.module';
import { RmBadgeModule } from '../globals/rm-badge';

import { FilterModule } from '../filter/filter.module';
import { TableComponent } from './table.component';
import { ChipsResumeModule } from '../chips-resume';

import { QrModule } from '../qr';
import { ModalUsersModule } from '../modal-users';
import { DateParseModule } from '@web-frontend/shared/pipes/date-parse/date-parse.module';
import { PermissionsModule } from '../permissions';
import { CurrencyFormatModule } from '@web-frontend/shared/pipes/currency-format/currency-format.module';
import { InfoModule } from '@web-frontend/shared/components/info/info.module';

@NgModule({
  declarations: [TableComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatPaginatorModule,
    FilterModule,
    SplitModule,
    ChipsResumeModule,
    MatChipsModule,
    MatCardModule,
    MatMenuModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatListModule,
    I18nChild,
    ClickDeleteModule,
    ClickStopPropagationModule,
    ClickRestoreModule,
    ClickDeleteDealModule,
    ClickStopPropagationModule,
    ClickAproveDealModule,
    ModalUsersModule,
    QrModule,
    DateParseModule,
    ImageModule.forRoot(),
    PermissionsModule,
    RmBadgeModule,
    MatProgressSpinnerModule,
    CurrencyFormatModule,
    InfoModule,
  ],
  exports: [TableComponent],
})
export class TableModule {}
