import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { PaymentsService } from '../payments.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ValidateAllFormFields } from '@tacliatech/types';
import { finalize } from 'rxjs/operators';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'roma-create-transfer',
  templateUrl: './create-transfer.component.html',
  styleUrls: ['./create-transfer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTransferComponent implements OnInit {
  transferUrl = '';
  transferForm = this.fb.group({
    amount: ['4466', Validators.required],
    currency: ['eur', Validators.required],
    bank_transfer_country: ['ES'],
    bank_transfer_type: ['eu_bank_transfer'],
    // statement_descriptor: [''],
    description: ['factest descipcion desde form'],
    customerData: this.fb.group({
      email: ['testfernandopedro@transfer.com.ar'],
      name: ['factest'],
      description: ['factest descripcion form'],
    }),
  });
  constructor(
    private readonly paymentService: PaymentsService,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly toastService: ToastService,
    private readonly clipboard: Clipboard
  ) {}

  ngOnInit(): void {
    // const finals$ = this.finalService.findFinalsByCompany(
    //   StorageService.CompanyId
    // );
    // finals$.subscribe(console.log);
  }

  checkValidators() {
    ValidateAllFormFields(this.transferForm);
    return this.transferForm.valid;
  }

  draw() {
    this.changeDetectorRef.detectChanges();
  }

  submitTransfer() {
    const isValid = this.checkValidators();
    if (isValid) {
      const obs$ = this.paymentService.createTransfer(this.transferForm.value);
      obs$.pipe(finalize(() => this.draw())).subscribe((transfer) => {
        this.transferUrl =
          transfer.next_action.display_bank_transfer_instructions.hosted_instructions_url;
        this.shareLink();
      });
    } else {
      this.transferForm.markAllAsTouched();
    }
  }

  shareLink() {
    this.clipboard.copy(this.transferUrl);
    this.toastService.show({
      text: 'activity.copyLink',
      type: 'success',
      msDuration: 4000,
      class: '',
    });
  }
}
