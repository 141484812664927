import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  BudgetsSetting,
  FeatureUser,
  IBudgetsSetting,
  PaymentInfoBudget,
  Tax,
} from '@tacliatech/types';
import { StorageService } from '@web-frontend/shared/services';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { TaxService } from '@web-frontend/shared/services/tax';

import { forkJoin, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BudgetUtilisService } from '../../../core/admin/budgets/budget-edit/budgets-edit.component';
import { Router } from '@angular/router';

@Component({
  selector: 'roma-budget-settings',
  templateUrl: './budget-settings.component.html',
  styleUrls: ['./budget-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetSettingsComponent implements OnInit, OnDestroy {
  @Input()
  id: string;

  budget: BudgetsSetting = new BudgetsSetting();

  mode: 'ADD' | 'EDIT' = 'ADD';

  private sub$ = new Subscription();
  isLoading = true;

  featureUserRef = FeatureUser;

  taxes: Tax[] = [];
  paymentMethods: PaymentInfoBudget[] = [];

  @Input()
  showGoBackButton = true;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private budgetService: BudgetService,
    private budgetUtilisService: BudgetUtilisService,
    private taxService: TaxService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.resolveTaxes();
    this.watchRefresh();
    this.getSettings();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  resolveTaxes() {
    this.taxes = this.taxService.findAll();
  }

  watchRefresh() {
    this.sub$.add(
      this.budgetUtilisService.refreshBudgetSettings.subscribe((data) => {
        this.budget = data as IBudgetsSetting;
        this.budgetService.init();

        this.draw();
      })
    );
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  getSettings() {
    this.isLoading = true;
    const id = StorageService.CompanyId;

    this.sub$.add(
      forkJoin([
        this.budgetService.findSettingsByCompany(id),
        this.budgetService.findAllPaymentMethodsByCompany_SELECT(),
      ])
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.draw();
          })
        )
        .subscribe(
          (result) => {
            const settings = result[0] as IBudgetsSetting;
            this.paymentMethods = (result[1] as PaymentInfoBudget[]) ?? [];

            if (settings) {
              this.mode = 'EDIT';
              this.id = settings._id;
              this.budget = settings;
            } else {
              this.budget.idOwner = id;
              this.mode = 'ADD';
              this.createSettings();
            }
          },
          (error) => {
            this.isLoading = false;
            if (error.statusCode === 404) {
              this.budget.idOwner = id;
              this.mode = 'ADD';
              this.createSettings();
            }
          }
        )
    );
  }

  createSettings() {
    //console.log('this.budget', this.budget);
    this.budgetService.createOneSetting(this.budget).subscribe((data) => {
      this.budget = data;
      this.id = this.budget._id;
      this.mode = 'EDIT';
    });
  }

  public backSettings(): void {
    this.router.navigate(['/admin/settings/general']);
  }
}
