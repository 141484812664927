<roma-modal-template
  [title]="'table.colum.attention' | translate"
  [showSuccessBtn]="true"
  [showCancelBtn]="false"
  [responsiveFull]="false"
  [contentMinHeight]="false"
  size="sm"
  [textSuccess]="'profile.accept'"
  (success)="ok()"
  (cancel)="ok()"
>
  <div class="container-item">
    <div class="grid">
      <div class="row">
        <strong>{{ getMessage() | translate }}</strong>
      </div>
    </div>
  </div>
</roma-modal-template>
