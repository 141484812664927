import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Company, FeatureUser, IUser } from '@tacliatech/types';
import { StorageService, UserService } from '@web-frontend/shared/services';
import { CompanyModuleService } from '@web-frontend/shared/services/company';
import { Subscription } from 'rxjs';

class Permission {
  key: string;
  keyName: string;
  value: string;
  checked: boolean;
}

class PermissionsInternal {
  type: string;
  expanded = false;
  permissions?: Permission[];
}

class TablePermissions {
  name: string;
  permissionsKeys: string[];
  show: boolean;
  create: boolean;
  update: boolean;
  delete: boolean;
  approve: boolean;
}

@Component({
  selector: 'roma-manage-permission',
  templateUrl: './manage-permission.component.html',
  styleUrls: ['./manage-permission.component.scss'],
})
export class ManagePermissionComponent implements OnInit, OnDestroy {
  constructor(
    private userService: UserService,
    private companyModuleService: CompanyModuleService
  ) {}
  private sub$ = new Subscription();
  user: IUser;
  permissions: PermissionsInternal[] = [];

  @Input()
  userId: string;

  @Input()
  autoUpdate = true;

  class = 'custom-properties-container col-12 col-xl-5';
  @Input() set adjust(value: boolean) {
    if (value) {
      this.class = 'custom-properties-container ';
    }
  }

  @Output()
  change = new EventEmitter<string[]>();

  tablePermissions: TablePermissions[] = [];
  loading = false;

  modules: Company.Module[];

  ngOnInit(): void {
    this.orderPermissionsMethod();
    /** RECUPERAR TODOS LOS PERMISOS, SIN USUARIO */
    this.getInternalPermissions();

    /** SI NOS ESPECIFICAN EL USUARIO A REVISAR LOS PERMISOS */
    if (this.userId) {
      this.getUser();
    }

    this.sub$.add(
      this.companyModuleService.activeModules$.subscribe((modules) => {
        this.modules = modules;
      })
    );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  orderPermissionsMethod() {
    let perm: TablePermissions = {
      name: 'Inicio',
      permissionsKeys: ['Functionalitie', 'Calendar'],
      show: true,
      create: true,
      update: true,
      delete: true,
      approve: false,
    };
    this.tablePermissions.push(perm);

    perm = {
      name: 'Ventas',
      permissionsKeys: [
        'Deal',
        'Catalogue',
        'Final',
        'Sale',
        'Payment',
        'Quote',
        'Bill',
        'Proform',
        'Waybills',
        'Report',
        'OnlineProfile',
      ],
      show: true,
      create: true,
      update: true,
      delete: true,
      approve: false,
    };
    this.tablePermissions.push(perm);
    perm = {
      name: 'GestionInterna',
      permissionsKeys: [
        'ClockHour',
        'ClockHourAbsence',
        'ClockHourCompany',
        'ClockHourAbsenceCompany',
        'Activity',
        'Geolocation',
        'Equipment',
        'Project',
        'Address',
      ],
      show: true,
      create: true,
      update: true,
      delete: true,
      approve: true,
    };
    this.tablePermissions.push(perm);
    perm = {
      name: 'Gastos',
      permissionsKeys: ['Expense', 'InternalVendor'],
      show: true,
      create: true,
      update: true,
      delete: true,
      approve: false,
    };
    this.tablePermissions.push(perm);
    perm = {
      name: 'Configuracion',
      permissionsKeys: ['User', 'CompanyAdjust'],
      show: true,
      create: true,
      update: true,
      delete: true,
      approve: false,
    };
    this.tablePermissions.push(perm);
  }

  getInternalPermissions() {
    this.loading = true;
    let features = Object.keys(FeatureUser);
    features = features.filter((item) => !item.endsWith('List'));
    for (let i = 0; i < features.length; i++) {
      const permission: PermissionsInternal = {
        type: features[i],
        expanded: false,
      };
      permission.permissions = [];
      const featureItemsKeys = Object.keys(FeatureUser[features[i]]);
      for (let y = 0; y < featureItemsKeys.length; y++) {
        // eslint-disable-next-line no-var
        var perm = {
          key: featureItemsKeys[y],
          keyName: `permissions.keys.${featureItemsKeys[y]}`,
          value: FeatureUser[features[i]][featureItemsKeys[y]],
          checked: true,
        };

        const prefixes = [
          'p_clockhourcompany_',
          'p_clockhourabsencecompany_',
          'p_geolocation_',
          'p_user_',
          'p_comapanyadjust_',
        ];

        if (prefixes.some((prefix) => perm.value.startsWith(prefix))) {
          perm.checked = false;
        }
        permission.permissions.push(perm);
      }
      this.permissions.push(permission);
    }
    this.loading = false;
    if (!this.userId) {
      this.change.emit(this.parseActualPermissions());
    }
  }

  getUser() {
    this.loading = true;
    this.sub$.add(
      this.userService.findById(this.userId).subscribe((res) => {
        this.user = res;
        this.refactorInternalPermissions();
        this.loading = false;
      })
    );
  }

  // @ts-ignore
  haveModule(key: string) {
    switch (key) {
      case 'Functionalitie':
        return true;
      case 'Calendar':
        return this.modules.findIndex((it) => it.id == 'DEALS') != -1;
      case 'Deal':
        return this.modules.findIndex((it) => it.id == 'DEALS') != -1;
      case 'Catalogue':
        return this.modules.findIndex((it) => it.id == 'SALES') != -1;
      case 'Final':
        return this.modules.findIndex((it) => it.id == 'FINALS') != -1;
      case 'Sale':
        return this.modules.findIndex((it) => it.id == 'SALES') != -1;
      case 'Payment':
        return this.modules.findIndex((it) => it.id == 'PAYMENTS') != -1;
      case 'Quote':
        return this.modules.findIndex((it) => it.id == 'SALES') != -1;
      case 'Bill':
        return this.modules.findIndex((it) => it.id == 'SALES') != -1;
      case 'Proform':
        return this.modules.findIndex((it) => it.id == 'SALES') != -1;
      case 'Waybills':
        return this.modules.findIndex((it) => it.id == 'SALES') != -1;
      case 'Report':
        return this.modules.findIndex((it) => it.id == 'REPORTS') != -1;
      case 'OnlineProfile':
        return this.modules.findIndex((it) => it.id == 'DEALS') != -1;
      case 'ClockHour':
        return this.modules.findIndex((it) => it.id == 'CLOCK_HOURS') != -1;
      case 'ClockHourAbsence':
        return this.modules.findIndex((it) => it.id == 'CLOCK_HOURS') != -1;
      case 'ClockHourCompany':
        return this.modules.findIndex((it) => it.id == 'CLOCK_HOURS') != -1;
      case 'ClockHourAbsenceCompany':
        return this.modules.findIndex((it) => it.id == 'CLOCK_HOURS') != -1;
      case 'Activity':
        return this.modules.findIndex((it) => it.id == 'ACTIVITIES') != -1;
      case 'Geolocation':
        return this.modules.findIndex((it) => it.id == 'GEOLOCATION') != -1;
      case 'Equipment':
        return this.modules.findIndex((it) => it.id == 'EQUIPMENTS') != -1;
      case 'Project':
        return this.modules.findIndex((it) => it.id == 'PROJECTS') != -1;
      case 'Address':
        return this.modules.findIndex((it) => it.id == 'ASSETS') != -1;
      case 'Expense':
        return this.modules.findIndex((it) => it.id == 'EXPENSES') != -1;
      case 'InternalVendor':
        return (
          this.modules.findIndex((it) => it.id == 'INTERNAL_VENDORS') != -1
        );
      case 'User':
        return true;
      case 'CompanyAdjust':
        return true;
    }
  }

  haveAllTableModules(keys: string[]) {
    for (let i = 0; i < keys.length; i++) {
      if (this.haveModule(keys[i])) {
        return true;
      }
    }
    return false;
  }

  refactorInternalPermissions() {
    if (this.user.permissions != null && Array.isArray(this.user.permissions)) {
      //actualizamos con los valores actuales del usuario
      for (let i = 0; i < this.permissions.length; i++) {
        for (let y = 0; y < this.permissions[i].permissions.length; y++) {
          if (
            !this.user.permissions.includes(
              this.permissions[i].permissions[y].value
            )
          ) {
            this.permissions[i].permissions[y].checked = false;
          } else {
            this.permissions[i].permissions[y].checked = true;
          }
        }
      }
    }
  }

  updateUserPermissions() {
    if (this.userId) {
      this.sub$.add(
        this.userService
          .update(this.userId, {
            company: StorageService.CompanyId,
            permissions: this.parseActualPermissions(),
          })
          .subscribe((res) => {
            //this.getUser();
          })
      );
    }
  }

  unallowedPermissions = ['p_user_create'];
  deletePermisionsUnallowed(permissions: string[]): string[] {
    for (let i = 0; i < this.unallowedPermissions.length; i++) {
      if (permissions.includes(this.unallowedPermissions[i])) {
        permissions.splice(
          permissions.indexOf(this.unallowedPermissions[i]),
          1
        );
      }
    }
    return permissions;
  }

  parseActualPermissions(): string[] {
    const perms = [];
    this.permissions.forEach((permission) => {
      permission.permissions.forEach((item) => {
        if (item.checked) {
          perms.push(item.value);
        }
      });
    });
    return this.deletePermisionsUnallowed(perms);
  }

  existsPermission(source: string, key: string) {
    const index = this.permissions.findIndex((it) => it.type == key);

    const indexPermission = this.permissions[index].permissions.findIndex(
      (it) => it.value == FeatureUser[key][source]
    );

    //delete checkbox of unallowed permisions
    if (this.unallowedPermissions.includes(FeatureUser[key][source])) {
      return false;
    }

    if (indexPermission != -1) {
      return true;
    } else {
      return false;
    }
  }

  getPermissionByType(source: string, key: string) {
    const index = this.permissions.findIndex((it) => it.type == key);

    return this.permissions[index].permissions.find(
      (it) => it.value == FeatureUser[key][source]
    );
  }

  onClickCheckbox(source: string, key: string) {
    const index = this.permissions.findIndex((it) => it.type == key);

    const indexPermission = this.permissions[index].permissions.findIndex(
      (it) => it.value == FeatureUser[key][source]
    );
    if (
      source === 'show' &&
      this.permissions[index].permissions[indexPermission].checked
    ) {
      //deshabilitar todo
      const features = ['create', 'update', 'delete', 'approve'];

      for (const feature of features) {
        const indexAux = this.permissions[index].permissions.findIndex(
          (it) => it.value === FeatureUser[key][feature]
        );
        if (indexAux !== -1) {
          this.permissions[index].permissions[indexAux].checked = false;
        }
      }
    }

    this.permissions[index].permissions[indexPermission].checked = !this
      .permissions[index].permissions[indexPermission].checked;

    if (
      source !== 'show' &&
      this.permissions[index].permissions[indexPermission].checked
    ) {
      //habilitar show
      const indexAux = this.permissions[index].permissions.findIndex(
        (it) => it.value == FeatureUser[key]['show']
      );
      if (indexAux != -1) {
        this.permissions[index].permissions[indexAux].checked = true;
      }
    }

    //si tenemos el usuario y esta marcado el autoupdate, actualizamos directamente, en caso contrario, emitimos los resultados
    if (this.userId && this.autoUpdate) {
      this.updateUserPermissions();
    } else {
      this.change.emit(this.parseActualPermissions());
    }
  }
}
