import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BudgetSettingsComponent } from '@web-frontend/shared/components/budget-setttings/budget-settings.component';
import { RatesComponent } from '../rates/rates.component';
import { BudgetsEditComponent } from './budget-edit/budgets-edit.component';
import { BudgetsComponent } from './budgets.component';
import { CreateBudgetComponent } from '@web-frontend/shared/components/create-budget/create-budget.component';
import { ShowBudgetComponent } from '@web-frontend/shared/components/show-budget/show-budget.component';
import { UnsaveGuard } from '@web-frontend/shared/guards/unsaved';

const routes: Routes = [
  {
    path: '',
    component: BudgetsComponent,
  },

  {
    path: 'settings',
    component: BudgetSettingsComponent,
  },
  {
    path: 'rates',
    component: RatesComponent,
  },

  {
    path: 'edit',
    component: BudgetsEditComponent,
  },
  {
    path: 'create',
    component: CreateBudgetComponent,
  },
  {
    path: 'show',
    component: ShowBudgetComponent,
    canDeactivate: [UnsaveGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BudgetsRoutingModule {}
