import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectDealConfirmModalComponent } from './select-deal-confirm-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ModalTemplateModule } from '../../modal-template';

@NgModule({
  declarations: [SelectDealConfirmModalComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ModalTemplateModule,
    MatDialogModule,
  ],
})
export class SelectDealConfirmModalModule {}
