import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TacliaPayments } from '@tacliatech/types';
import { Stripe } from 'stripe';

@Injectable({
  providedIn: 'root',
})
export class CreateLocationService {
  constructor(private http: HttpClient) {}

  create(payload: TacliaPayments.Location.CreateRequest) {
    return this.http.post<Stripe.Response<Stripe.Terminal.Location>>(
      ':API_URL/terminal/locations',
      payload
    );
  }

  getLocations() {
    return this.http.get<
      Stripe.Response<Stripe.ApiList<Stripe.Terminal.Location>>
    >(':API_URL/terminal/locations');
  }
}
