import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditableFieldModule } from '../editable-field';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { FieldErrorDisplayModule } from '../field-error-display';
import { BudgetHeaderComponent } from './budget-header.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaskModule } from 'ngx-mask';
import { PermissionsModule } from '../permissions';
import { RmSelectModule } from '../globals/rm-select';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [BudgetHeaderComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    EditableFieldModule,
    ReactiveFormsModule,
    MatCardModule,
    FieldErrorDisplayModule,
    NgSelectModule,
    RmSelectModule,
    MatDatepickerModule,
    NgxMaskModule.forChild(),
    PermissionsModule,
  ],
  exports: [BudgetHeaderComponent],
})
export class BudgetHeaderModule {}
