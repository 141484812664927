import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { AddInternalVendorComponent } from './add-internal-vendor.component';

@Injectable({ providedIn: 'root' })
export class AddInternalVendorService {
  private refreshListSource = new Subject<boolean>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(config: MatDialogConfig = new MatDialogConfig()) {
    config.width = '85vw';
    config.minWidth = '85vw';
    config.height = 'auto';

    const dialogRef = this.dialog.open(AddInternalVendorComponent, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }
}
