import { formatNumber } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  BudgetCommonData,
  IBudgetsSetting,
  PartOfServiceGlobal,
  TypeTax,
} from '@tacliatech/types';
import { environment } from '@web-frontend/environments';
import * as doT from 'dot'; //https://olado.github.io/doT/index.html
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { BudgetService } from '../budgets';
import { StorageService } from '../storage';
import { PreviewComponent } from './preview/preview.component';
import { BudgetTemplate } from './templates/budget/budget';
import { HttpClient } from '@angular/common/http';
import { PartOfServiceTemplate } from './templates/part-of-service/part-of-service';
import { PartOfServiceProvider } from '../part-of-services';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class GenerateDocumentService implements OnDestroy {
  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
    private budgetService: BudgetService,
    private partOfServiceProvider: PartOfServiceProvider,
    private i18n: TranslateService
  ) {}

  settings: IBudgetsSetting | undefined;
  private sub$ = new Subscription();
  private budget: any;
  private partOfService: any;

  loading$ = new BehaviorSubject<boolean>(true);
  getLoading(): Observable<boolean> {
    return this.loading$.asObservable();
  }

  giveLoading(loading: boolean) {
    this.loading$.next(loading);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  /************************************************************************************************************** */
  // BUDGET METHODS
  /************************************************************************************************************** */
  rEq: any[] = [];
  rEt: any[] = [];
  iVa: any[] = [];
  cols = 7;

  async generateBudgetDocument(
    download: boolean,
    budgetId: string,
    type: string,
    name: string
  ) {
    this.budget = await this.getBudgetData(budgetId, type);
    this.settings = await this.getSettings();
    this.calculateRetentions();
    const html = await this.generateBudgetHtml(
      this.budget,
      type,
      this.settings
    );
    if (download) {
      //this.openDialog(html);
      this.openDialog(html, true, name);
    } else {
      this.openDialog(html, false, name);
    }
  }

  setFormat(object: any[], locale, digitsInfo, currency) {
    for (const item of object) {
      item.value = formatNumber(item.value, locale, digitsInfo) + currency;
    }
    return object;
  }

  async generateBudgetHtml(
    budget,
    type: string,
    settings: IBudgetsSetting
  ): Promise<string> {
    const budgetTemplate = new BudgetTemplate();
    let locale = '';
    let currency = '€';
    locale = 'es-AR';
    if (this.settings?.budgetPreferences.currency == 2) {
      currency = '$';
      locale = 'en-US';
    }
    if (this.settings?.budgetPreferences.currency == 3) {
      currency = '$';
      locale = 'mx-ES';
    }
    let digitsInfo = '1.';
    digitsInfo =
      digitsInfo +
      this.settings?.budgetPreferences?.decimals.toString() +
      '-' +
      this.settings?.budgetPreferences?.decimals.toString();

    for (let i = 0; i < budget.items.length; i++) {
      budget.items[i].subtotal =
        budget.items[i].price * budget.items[i].quantity;

      budget.items[i].subtotal = formatNumber(
        budget.items[i].subtotal,
        locale,
        digitsInfo
      );

      budget.items[i].total += this.calculateItemTotalPercentage(
        budget.items[i]
      );

      budget.items[i].total = formatNumber(
        budget.items[i].total,
        locale,
        digitsInfo
      );

      budget.items[i].quantity = formatNumber(
        budget.items[i].quantity,
        locale,
        digitsInfo
      );
      budget.items[i].quantity = budget.items[i].quantity.replace(',00', '');
      budget.items[i].quantity = budget.items[i].quantity.replace('.00', '');
      budget.items[i].price = formatNumber(
        budget.items[i].price,
        locale,
        digitsInfo
      );
    }

    const dataSend = {
      type: type,
      logo: settings?.logo
        ? await this.getUrlImage(settings?.logo, 'budget-logo')
        : null,
      company_name: settings?.data_account?.company_name,
      fiscalName: settings?.data_account?.fiscalName,
      tradename: settings?.data_account?.tradename,
      prefix: settings?.invoiceSettings?.prefixNumber,
      phone: settings?.data_account?.phone,
      address: settings?.billing_addres?.address,
      email: settings?.data_account?.email,
      final_name: budget?.header?.final?.name,
      final_lastName: budget?.header?.final?.lastName,
      final_nif: budget?.header?.final?.nif,
      final_email: budget?.header?.final?.email,
      final_fiscalName: budget?.header?.final?.fiscalName,
      final_address: budget?.header?.final?.address,
      phones: budget?.header?.final?.phones,
      numberDoc: budget?.header.numberDoc,
      date: budget?.header.date ? new Date(budget?.header.date) : '',
      dueDate: budget?.header.dueDate ? new Date(budget?.header.dueDate) : '',
      items: budget?.items,
      subtotal: formatNumber(budget.subtotal, locale, digitsInfo) + currency,
      iva_total: formatNumber(budget.iva_total, locale, digitsInfo) + currency,
      total: formatNumber(budget.total, locale, digitsInfo) + currency,
      ivap: this.iVa,
      retp: this.rEt,
      reqp: this.rEq,
      line1: budget.messages?.line1,
      textShow: budget?.typePayments[0]?.textShow,
    };

    for (let i = 0; i < dataSend.ivap.length; i++) {
      dataSend.ivap[i].value = formatNumber(
        dataSend.ivap[i].value,
        locale,
        digitsInfo
      );
    }

    for (let i = 0; i < dataSend.retp.length; i++) {
      dataSend.retp[i].value = formatNumber(
        dataSend.retp[i].value,
        locale,
        digitsInfo
      );
    }

    for (let i = 0; i < dataSend.reqp.length; i++) {
      dataSend.reqp[i].value = formatNumber(
        dataSend.reqp[i].value,
        locale,
        digitsInfo
      );
    }

    for (let i = 0; i < Object.keys(dataSend).length; i++) {
      if (!dataSend[Object.keys(dataSend)[i]]) {
        dataSend[Object.keys(dataSend)[i]] = '';
      }
    }

    /*console.log({
      ...dataSend,
      budget: budget,
      settings: settings,
    });*/

    const tempFn = doT.template(budgetTemplate.template); //<h1>Here is a sample template {{=it.foo}}</h1>
    const resultHtml = tempFn({
      ...dataSend,
      budget: budget,
      settings: settings,
      currency: currency,
      translations: this.geti18nTranslations(budgetTemplate.translations),
    });
    return resultHtml;
  }

  async getSettings(): Promise<IBudgetsSetting> {
    const sub$ = this.sub$;
    const budgetService = this.budgetService;
    return new Promise(function (resolve, reject) {
      sub$.add(
        budgetService
          .findSettingsByCompany(StorageService.CompanyId)
          .subscribe((res: IBudgetsSetting) => {
            resolve(res);
          })
      );
    });
  }

  async getBudgetData(budgetId, type): Promise<BudgetCommonData> {
    const sub$ = this.sub$;
    const budgetService = this.budgetService;
    return new Promise(function (resolve, reject) {
      sub$.add(
        budgetService.findBudgetDetail(budgetId, type).subscribe((resp) => {
          resolve(resp);
        })
      );
    });
  }

  calculateRetentions() {
    let Aiva: any[] = [];
    let Aret: any[] = [];
    let Arec: any[] = [];
    this.budget.items.forEach((item) => {
      const totalItem = item.price * item.quantity;

      if (item.taxes?.length > 0) {
        item.taxes?.forEach((tax) => {
          if (tax !== null) {
            const value = (totalItem * tax.value) / 100;
            if (tax.type === TypeTax.IVA) {
              Aiva = this.findKey(tax.value, value, Aiva);
            }

            if (tax.type === TypeTax.REC) {
              Arec = this.findKey(tax.value, value, Arec);
            }

            if (tax.type === TypeTax.RET) {
              Aret = this.findKey(tax.value, value, Aret);
            }
          }
        });
        this.rEq = Arec;
        this.rEt = Aret;
        this.iVa = Aiva;
      } else {
        item.taxes = [];
      }
    });
    if (Arec.length === 0) {
      this.cols = this.cols - 1;
    }
    if (Aret.length === 0) {
      this.cols = this.cols - 1;
    }
    if (Aiva.length === 0) {
      this.cols = this.cols - 1;
    }
  }

  calculateItemTotalPercentage(item) {
    let i = 0;
    let IVApercentage = 0;
    let RETpercentage = 0;
    let totalPercentage = 0;
    for (i = 0; i < item.taxes?.length; i++) {
      if (
        item.taxes[i].type === TypeTax.IVA ||
        item.taxes[i].type === TypeTax.REC
      ) {
        IVApercentage = IVApercentage + item.taxes[i].value;
      }

      if (item.taxes[i].type === TypeTax.RET) {
        RETpercentage = RETpercentage + item.taxes[i].value;
      }
    }
    totalPercentage = (item.total / 100) * (IVApercentage - RETpercentage);
    return totalPercentage;
  }

  findKey(key, value, object: any[]) {
    let found = false;
    for (const item of object) {
      if (item.key === key) {
        item.value = item.value + value;
        found = true;
        break;
      }
    }
    if (!found) {
      object.push({ key: key, value: value });
    }
    return object;
  }

  /************************************************************************************************************** */
  // PART OF SERVICE METHODS
  /************************************************************************************************************** */
  async generatePartOfServiceDocument(
    download: boolean,
    partOfServiceId: string,
    name: string
  ) {
    this.partOfService = await this.getPartOfServiceData(partOfServiceId);
    const html = await this.generatePartOfServiceHtml(this.partOfService);

    if (download) {
      //this.openDialog(html);
      this.openDialog(html, true, name);
    } else {
      this.openDialog(html, false, name);
    }
  }

  async getPartOfServiceData(
    partOfServiceId
  ): Promise<PartOfServiceGlobal.PartOfServiceReportFormat> {
    const sub$ = this.sub$;
    const partOfServiceProvider = this.partOfServiceProvider;
    return new Promise(function (resolve, reject) {
      sub$.add(
        partOfServiceProvider
          .getReportData(partOfServiceId)
          .subscribe((res) => {
            resolve(res);
          })
      );
    });
  }

  async generatePartOfServiceHtml(partOfService: any): Promise<string> {
    const template = new PartOfServiceTemplate();
    const dataSend = partOfService;

    if (dataSend.clientLogo) {
      dataSend.clientLogo = await this.getUrlImage(
        dataSend.clientLogo,
        'budget-logo'
      );
    }

    if (dataSend.partOfServiceImages?.length > 0) {
      for (let i = 0; i < dataSend.partOfServiceImages.length; i++) {
        dataSend.partOfServiceImages[i] = await this.getUrlImage(
          dataSend.partOfServiceImages[i],
          'part-of-service'
        );
      }
    } else {
      // If part of service has no images, remove the html code that contains the images
      const startString = '<!-- Imagenes del servicio -->';
      const endString = '<!-- FIN Imagenes del servicio -->';
      const startIndex = template.template.indexOf(startString);
      const endIndex = template.template.indexOf(endString) + endString.length;
      template.template =
        template.template.substring(0, startIndex) +
        template.template.substring(endIndex);
    }

    for (let i = 0; i < Object.keys(dataSend).length; i++) {
      if (dataSend[Object.keys(dataSend)[i]] == '') {
        dataSend[Object.keys(dataSend)[i]] = ' ';
      }
      if (
        Array.isArray(dataSend[Object.keys(dataSend)[i]]) &&
        dataSend[Object.keys(dataSend)[i]].length == 0
      ) {
        dataSend[Object.keys(dataSend)[i]] = null;
      } else {
      }
    }

    if (dataSend.partOfServiceDescription == null) {
      dataSend.partOfServiceDescription = '';
    }

    const tempFn = doT.template(template.template);

    const resultHtml = tempFn({
      ...dataSend,
      translations: this.geti18nTranslations(template.translations),
    });

    return resultHtml;
  }
  /************************************************************************************************************** */
  /*********************** OPEN DIALOG PREVIEW OR DOWNLOAD **************************** */
  /************************************************************************************************************** */
  openDialog(html: string, download: boolean, name: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '90vw';
    dialogConfig.height = '95vh';
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      html: html,
      download: download,
      name: name,
    };
    const dialogRef = this.dialog.open(PreviewComponent, dialogConfig);
    const subscription = dialogRef.componentInstance.finishLoading.subscribe(
      () => {
        this.giveLoading(false);
        subscription.unsubscribe();
      }
    );
  }

  /** DEPRECATED */
  printAsWindows(html: string) {
    const printContents = html.replace('col-sm', 'col-xs');
    const popupWin = window.open(
      '',
      '_blank',
      'top=0,left=0,height=100%,width=auto'
    );
    popupWin.document.open();
    popupWin.document.write(`
        <html>
            ${printContents}
          <body onload="window.print();">
          <body>
        </html>`);
    /* window.close(); */
    popupWin.document.close();
  }

  /************************************************************************************************************** */
  /*********************** TRANSLATION METHOD **************************** */
  /************************************************************************************************************** */
  geti18nTranslations(translations: string[]): any {
    const translation: any = {};

    for (let i = 0; i < translations.length; i++) {
      translation[translations[i]] = this.i18n.instant(
        `generateDocument.${translations[i]}`
      );
    }

    return translation;
  }

  /************************************************************************************************************** */
  /*********************** PARSE FIREBASE URL **************************** */
  /************************************************************************************************************** */
  async getBase64ImageFromUrl(imageUrl) {
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  async getUrlImage(img: string, type: string): Promise<string | null> {
    let url = `${environment.firebaseConfig.storageUrl}/uploads`;

    if (!img && type === 'budget-logo') {
      return null;
    }

    if (!img || !type) {
      return './assets/images/default-user.png';
    }

    //Retrocompatibility
    if (!img.includes('alt=media')) {
      img = img + '?alt=media';
    }

    switch (type) {
      case 'customer':
        url += `%2Fcustomer%2F${img}`;
        break;
      case 'deal':
        url += `%2Fdeal%2F${img}`;
        break;
      case 'evidence':
        url += `%2Fevidence%2F${img}`;
        break;
      case 'expense':
        url += `%2Fexpense%2F${img}`;
        break;
      case 'user':
        url += `%2Fuser%2F${img}`;
        break;
      case 'own-team':
        url += `%2Fown-team%2F${img}`;
        break;
      case 'notes':
        url += `%2Fnotes%2F${img}`;
        break;
      case 'checklist':
        url += `%2Fchecklist%2F${img}`;
        break;
      case 'equipment':
        url += `%2Fequipment%2F${img}`;
        break;
      case 'final':
        url += `%2Ffinal%2F${img}`;
        break;
      case 'address':
        url += `%2Faddress%2F${img}`;
        break;
      case 'vendor':
        url += `%2Fvendor%2F${img}`;
        break;
      case 'project':
        url += `%2Fproject%2F${img}`;
        break;
      case 'budget-logo':
        url += `%2Fbudget-logo%2F${img}`;
        break;
      case 'internal-vendor':
        url += `%2Finternal-vendor%2F${img}`;
        break;
      case 'part-of-service':
        url += `%2Fpart-of-service%2F${img}`;
        break;
      case 'full-url':
        break;
      default:
        url = './assets/images/default-user.png';
        break;
    }

    let resultImage;

    await this.getBase64ImageFromUrl(url)
      .then((result) => (resultImage = result))
      .catch((err) => console.error(err));

    return resultImage;
  }
}
