import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  ViewChild,
} from '@angular/core';

import { Feature } from '@tacliatech/types';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { Subscription, fromEvent, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map } from 'rxjs/operators';
import { RmSelect } from '../rm-select/rm-select.types';
import { RmResponsiveSelect } from './rm-responsive-select.types';

@Component({
  selector: 'rm-responsive-select',
  templateUrl: './rm-responsive-select.component.html',
  styleUrls: ['./rm-responsive-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmResponsiveSelectComponent implements OnInit {
  public innerWidth = 0;
  isResponsive = false;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 768) {
      this.isResponsive = true;
    } else {
      this.isResponsive = false;
    }
  }

  @ViewChild('inputFilterSearch')
  set input(value: ElementRef<HTMLInputElement>) {
    if (value) {
      this._input = value;
      this.setFocusInputSearch();
      this.patchFilterArgParams();
      this.watchInputSearch();
    }
  }

  @Input()
  placeholderText = 'general.search';

  @Input()
  mode: RmSelect.Type = 'SINGLE';

  @Input()
  listWidth = '';

  @Input()
  buttonTitle = 'general.add2';

  @Input()
  hasError = false;

  @Input()
  addButtonText?: string;

  @Input()
  addButtonFeatureUser?: Feature.User;

  public isAvailableUserFeature = false;

  _panelClicked = false;
  _isLoading = false;
  _firstLoad = true;

  @Input()
  @HostBinding('class.is-loading')
  set isLoading(value: boolean) {
    this._isLoading = value;
    //if is changed is loaded to false for second time (latter than load), we open the panel
    if (this._isLoading == false) {
      if (this._firstLoad) {
        this._firstLoad = false;
        this._panelClicked = false;
      } else {
        if (this._panelClicked) {
          this.showPanelElement = true;
        }
        this._panelClicked = false;
      }
    }
  }

  get isLoading() {
    return this._isLoading;
  }

  @Input()
  @HostBinding('class.is-disabled')
  disabled!: boolean;

  @Input()
  items: RmResponsiveSelect.Items = [];

  @Input()
  activeItems: RmResponsiveSelect.Items = [];

  @Input()
  inputHeight = '36px';

  @Input()
  type = '';

  @Output()
  changeItems = new EventEmitter<RmResponsiveSelect.Items>();

  @Output()
  addElement = new EventEmitter<MouseEvent>();

  @Input()
  showPanelElement = false;

  @Input()
  primaryDesign = true;

  checkBoxItems: any[] = [];
  filterArgs: { title: string } = { title: '' };

  private _input: ElementRef<HTMLInputElement>;
  private sub$ = new Subscription();

  firstLoad = true;
  focusOutA = false;
  focusOutB = false;
  anuleFocusOut = false;
  menuIsOpen = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private elRef: ElementRef,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 768) {
      this.isResponsive = true;
    } else {
      this.isResponsive = false;
    }

    if (this.addButtonFeatureUser && this.addButtonText) {
      this.sub$.add(
        this.permissionService.permissions$.subscribe((res) => {
          this.isAvailableUserFeature = res.includes(
            this.addButtonFeatureUser.toString()
          );
        })
      );
    }
  }

  async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  anuleFocusOutTimeout() {
    this.anuleFocusOut = true;
    setTimeout(() => {
      this.anuleFocusOut = false;
    }, 200);
  }

  async focusOut(from: string) {
    await this.delay(100);

    if (this.anuleFocusOut == true) {
      return;
    }

    if (from === 'users') {
      this.focusOutA = true;
    }

    if (from === 'status') {
      this.focusOutB = true;
    }

    if (this.focusOutA && this.focusOutB) {
      this.showPanelElement = false;
    }
    this.draw();
  }

  changeSelect(item: RmSelect.Item) {
    const index = this.activeItems.findIndex((it) => it.id === item.id);

    if (index === -1) {
      if (this.mode === 'SINGLE') {
        this.activeItems = [item];
      } else {
        this.activeItems.push(item);
      }
    } else {
      this.activeItems.splice(index, 1);
    }

    this.showPanelElement = false;
    this.changeItems.next(this.activeItems);

    this.draw();
  }

  changeCheckBox(evt, item) {
    if (evt.checked) {
      //this.checkBoxItems.push(item.value);
      this.activeItems.push(item);
    } else {
      this.activeItems.splice(
        this.activeItems.findIndex(function (i) {
          return i.value === item.value;
        }),
        1
      );
    }

    this.changeItems.next(this.activeItems);
    this.draw();
  }

  searchCheckFilters(item) {
    if (
      this.activeItems.filter((element) => element?.value == item?.value)
        .length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  addElementHandle() {
    this.addElement.next();
  }

  clearInput(item) {
    const index = this.activeItems.findIndex((it) => it.id === item.id);

    this.activeItems.splice(index, 1);
    this.filterArgs.title = '';
    this.changeItems.next(this.activeItems);
    this.draw();
  }

  private draw() {
    this.cdr.detectChanges();
  }

  private setFocusInputSearch() {
    if (this._input) {
      this._input.nativeElement.focus();
    }
  }

  private patchFilterArgParams() {
    if (this._input && this.filterArgs.title) {
      this._input.nativeElement.value = this.filterArgs.title;
    }
  }

  private watchInputSearch() {
    this.sub$.add(
      fromEvent(this._input.nativeElement, 'input')
        .pipe(
          distinctUntilChanged(),
          debounce(() => timer(450)),
          map((res) => (res.target as HTMLTextAreaElement).value as string)
        )
        .subscribe(this.changeKeyword.bind(this))
    );
  }

  private changeKeyword(keyword: string) {
    this.filterArgs = {
      title: keyword.toString().toLocaleLowerCase(),
    };
    this.draw();
  }

  menuClosed() {
    this.menuIsOpen = false;
    this.draw();
  }

  menuOpened() {
    this.menuIsOpen = true;
    this.draw();
  }

  isChecked(item) {
    return this.activeItems.findIndex(item) != -1;
  }

  isInActiveItems(item) {
    return this.activeItems.findIndex((it) => it?.id == item?.id) != -1;
  }

  hideButton() {
    const value =
      this.menuIsOpen ||
      (this.activeItems.length == 1 && this.mode == 'SINGLE');
    return value;
  }
}

@Pipe({
  name: 'searchFilter',
  pure: false,
})
export class SearchFilterPipe implements PipeTransform {
  transform(items: RmResponsiveSelect.Items, filter: { title: string }): any {
    if (!items?.length || !filter?.title) {
      return items;
    }

    return items.filter((item) =>
      item.title.match(new RegExp(filter.title, 'i'))
    );
  }
}
