import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonthlyWeeklyFilterComponent } from './monthly-weekly-filter.component';
import { TranslateModule } from '@ngx-translate/core';
import { WeekMonthSwitchComponent } from './week-month-switch/week-month-switch.component';
import { DatePaginatorComponent } from './date-paginator/date-paginator.component';

@NgModule({
  imports: [CommonModule, TranslateModule.forChild()],
  declarations: [
    MonthlyWeeklyFilterComponent,
    WeekMonthSwitchComponent,
    DatePaginatorComponent,
  ],
  exports: [
    MonthlyWeeklyFilterComponent,
    WeekMonthSwitchComponent,
    DatePaginatorComponent,
  ],
})
export class MonthlyWeeklyFilterModule {}
