import { forkJoin, Observable } from 'rxjs';
import { USER_ID_KEY } from '../constants';
import { AuthService, StorageService } from '../services';
import { CompanyModuleService } from '../services/company';
import { PermissionService } from '../services/permissions';

export function initializeAppFactory(
  authService: AuthService,
  companyModuleService: CompanyModuleService,
  permissionService: PermissionService
): () => Observable<unknown> {
  const isUserLogged = !!StorageService.GetItem(USER_ID_KEY);

  if (!isUserLogged) {
    const el = () =>
      new Observable((obs) => {
        obs.next(null);
        obs.complete();
      });

    return el;
  }

  companyModuleService.init();

  const permissions$ = permissionService.findAll();
  const userRequest$ = authService.handleUserRequest();

  return () => forkJoin([permissions$, userRequest$]);
}
