<roma-modal-template
  [title]="
    type === 'ADD'
      ? ('internalVendor.create.title' | translate)
      : ('internalVendor.edit.title' | translate)
  "
  [footerTemplate]="footerButtons"
  [showDefaultButtons]="false"
  (cancel)="close()"
  [showInfo]="true"
  [isLoading]="isLoading"
  [askBeforeClose]="haveChanges"
  (info)="info('info')"
>
  <form [formGroup]="form" class="global-container">
    <div class="global-item">
      <div class="form-group">
        <label class="field-label" for="name">
          {{ 'internalVendor.create.name' | translate
          }}<obligatory-field-sign></obligatory-field-sign>
        </label>

        <input
          type="text"
          class="form-control"
          id="name"
          aria-describedby="emailHelp"
          formControlName="name"
          maxLength="50"
          [placeholder]="'internalVendor.create.namePlaceholder' | translate"
          [ngClass]="{ 'is-invalid': nameField?.displayError || false }"
        />

        <roma-field-error-display
          #nameField
          fieldName="name"
          errorMsg="internalVendor.create.nameError"
          [form]="form"
        >
        </roma-field-error-display>
      </div>
      <div class="form-group">
        <label class="field-label" for="name">
          {{ 'internalVendor.create.fiscalname' | translate }}
        </label>

        <input
          type="text"
          class="form-control"
          id="name"
          aria-describedby="fiscalName"
          formControlName="fiscalName"
          maxLength="50"
          [placeholder]="'internalVendor.create.namePlaceholder' | translate"
          [ngClass]="{ 'is-invalid': fiscalNameField?.displayError || false }"
        />

        <roma-field-error-display
          #fiscalNameField
          fieldName="fiscalName"
          errorMsg="internalVendor.create.nameError"
          [form]="form"
        >
        </roma-field-error-display>
      </div>

      <div class="form-group">
        <label class="field-label" for="contactName">
          {{ 'internalVendor.create.contactName' | translate }}
        </label>

        <input
          type="text"
          class="form-control"
          id="contactName"
          aria-describedby="emailHelp"
          formControlName="contactName"
          maxLength="50"
          [placeholder]="
            'internalVendor.create.contactNamePlaceholder' | translate
          "
          [ngClass]="{ 'is-invalid': !form.get('contactName').valid }"
        />

        <ng-container *ngIf="controls?.contactName?.errors">
          <div
            *ngIf="controls?.contactName?.errors?.required"
            class="invalid-feedback"
          >
            {{ 'internalVendor.create.contactNameError' | translate }}
          </div>
        </ng-container>
      </div>

      <div class="container-phones">
        <label class="field-label" for="fiscalName">
          {{ 'finals.create.phone' | translate }}
        </label>

        <roma-phone-manager
          [phones]="form.get('phones').value"
          (keypress)="numberOnly($event)"
          [maxPhonesAllow]="5"
          (changePhones)="changePhones($event)"
        ></roma-phone-manager>
      </div>

      <div class="form-group">
        <label class="field-label" for="name">
          {{ 'internalVendor.create.email' | translate }}
        </label>
        <input
          type="email"
          class="form-control"
          id="name"
          aria-describedby="emailHelp"
          formControlName="email"
          maxLength="50"
          [placeholder]="'internalVendor.create.emailPlaceholder' | translate"
          [ngClass]="{
            'is-invalid':
              emailRequiredField?.displayError ||
              emailFormatField?.displayError ||
              emailAsyncField?.displayError
          }"
        />

        <roma-field-error-display
          #emailRequiredField
          fieldName="email"
          errorMsg="internalVendor.create.emailError"
          [form]="form"
        >
        </roma-field-error-display>

        <roma-field-error-display
          #emailFormatField
          fieldName="email"
          errorMsg="internalVendor.create.emailInvalid"
          errorName="email"
          [form]="form"
        >
        </roma-field-error-display>

        <roma-field-error-display
          #emailAsyncField
          fieldName="email"
          errorMsg="internalVendor.create.invalidAsync"
          errorName="invalidAsync"
          [form]="form"
        >
        </roma-field-error-display>
      </div>

      <div class="form-group">
        <label class="field-label" for="idUsers">
          {{ 'projects.create.users' | translate }}
        </label>

        <ng-select
          id="idUsers"
          bindLabel="key"
          bindValue="value"
          formControlName="idUsers"
          [items]="users"
          [multiple]="true"
          [loading]="false"
          [placeholder]="'projects.create.usersPh' | translate"
          [notFoundText]="'hiring.form.placeholder_notfound' | translate"
        >
        </ng-select>
      </div>
    </div>

    <div class="global-item">
      <label class="field-label" for="files"> </label>

      <div class="images-container">
        <roma-image-selector
          [type]="'internal-vendor'"
          [quantity]="5"
          [mode]="this.type"
          [title]="'hiring.form.add-images-lbl' | translate"
          [accept-files]="true"
          [filesServer]="this.params?.files"
          (images-changed)="updateImages($event)"
          [delete-images-service]="deleteImages$"
          [feature]="featureRef.Final.UploadFiles"
          [module]="idModuleRef.InternalVendors"
        >
        </roma-image-selector>
      </div>

      <label class="field-label" for="notes">
        {{ 'equipments.create.notes' | translate }}
      </label>
      <roma-equipment-notes
        [mode]="type"
        [data]="params?.notes || []"
        (changeNotes)="addingNotes($event)"
      >
      </roma-equipment-notes>
    </div>

    <div class="global-item">
      <roma-append-custom-properties
        [typeRef]="typePropertyRef.InternalVendor"
        [rowLayout]="true"
        size="lg"
        [control]="form.get('customProperties')"
        [parentForm]="form"
        [initForm]="initChildForm"
        [parentFormSubmit]="parentFormSubmit"
        (customPropertiesForm)="customPropertiesFormChange($event)"
        (initialCharge)="initialChargeEvent()"
        (closeModal)="close()"
        [showPropertiesMessage]="this.customProperties.length === 0"
      ></roma-append-custom-properties>
    </div>
  </form>
</roma-modal-template>

<ng-template #footerButtons>
  <div class="modalFooter">
    <label class="invited" *ngIf="dataModal?.hasBeenInvited">
      {{ 'internalVendor.create.invited' | translate }}</label
    >
    <div class="groupEndDiv">
      <base-button
        *ngIf="haveChanges"
        buttonClass="tertiary"
        romaClickDelete
        [message]="'general.withoutSave'"
        [buttonDelete]="'general.buttonExit'"
        (wantDelete)="close()"
      >
        {{ 'activity.create.cancel' | translate }}
      </base-button>
      <base-button
        *ngIf="!haveChanges"
        buttonClass="tertiary"
        (click)="close()"
      >
        {{ 'activity.create.cancel' | translate }}
      </base-button>
      <base-button
        [isLoading]="isLoading"
        [disabled]="invalidEditCustomFields"
        (click)="submit()"
      >
        {{
          type === 'ADD'
            ? ('activity.create.submit' | translate)
            : ('activity.edit.submit' | translate)
        }}
      </base-button>
    </div>
  </div>
</ng-template>
