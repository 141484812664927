<mat-list>
  <ng-container *ngFor="let item of _data">
    <mat-divider></mat-divider>
    <mat-list-item (click)="redirect(item._id)">
      <div class="item">
        <div class="item-header">
          <mat-checkbox
            (click)="$event.stopPropagation(); selectRow(item)"
            [checked]="selection.isSelected(item)"
          ></mat-checkbox>
          <div class="numDoc">
            {{ getNumberDoc(item.header) }}
          </div>
          <div class="total">
            {{ item.total || 0 | currencyFormat: 'prefix' }}
          </div>
        </div>

        <div class="item-data">
          <div class="date">
            <img
              class="calendar-icon"
              src="/assets/icons/gl_calendar_fill.svg"
              alt="calendar icon"
            />
            {{ item.header.date ? (item.header.date | date: 'dd/MM':'UTC') : '--' }}
          </div>
          <div class="status">
            <roma-rm-badge
              [statusName]="
                getStatus(item?.status)
                  ? getStatus(item?.status)
                  : ('billing.notAvailable' | translate)
              "
              [color]="getStatusColor(item?.status)"
            ></roma-rm-badge>
          </div>
        </div>
        <div class="item-client">
          {{ item.header?.final?.name || '--' }}
          {{ item.header?.final?.lastName ? item.header?.final?.lastName : '' }}
        </div>
      </div>
    </mat-list-item>
  </ng-container>
</mat-list>
