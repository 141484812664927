import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { App, IFinal, IFinalOut, PaginateResponse } from '@tacliatech/types';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { tap } from 'rxjs/operators';

type QueryDownloadParams = { [key: string]: string | number | boolean };
@Injectable({
  providedIn: 'root',
})
export class FinalService {
  private sub$ = new Subscription();

  private finalsCache = new BehaviorSubject<App.ResourceCached<IFinal[]>>([
    'INIT',
    [],
  ]);

  finals$ = this.finalsCache.asObservable();

  constructor(private http: HttpClient) {}

  public get finals(): IFinal[] {
    return this.finalsCache.value[1];
  }

  init(): void {
    this.finalsCache.next(['LOADING', []]);
    this.sub$.add(
      this.findAll().subscribe((res) => {
        this.finalsCache.next(['LOADED', res]);
      })
    );
  }

  search(query: { [key: string]: any } = {}) {
    const params = new HttpParams({
      fromObject: {
        ...query,
      },
    });

    return this.http.get<PaginateResponse<IFinalOut[]>>(
      `:API_URL/search/finals`,
      {
        params,
      }
    );
  }

  downloadCustomers(
    query: QueryDownloadParams = {}
  ): Observable<PaginateResponse<IFinalOut[]>> {
    const params = new HttpParams({
      fromObject: {
        ...query,
      },
    });

    return this.http.get<PaginateResponse<IFinalOut[]>>(`:API_URL/v2/final`, {
      params,
    });
  }

  searchWithSpecialCharacters(query: { [key: string]: any } = {}) {
    const queryStringParts = [];

    for (const key in query) {
      if (query.hasOwnProperty(key)) {
        const value = query[key];

        if (Array.isArray(value)) {
          value.forEach((val) => {
            queryStringParts.push(
              `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
            );
          });
        } else {
          queryStringParts.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          );
        }
      }
    }

    const queryString = queryStringParts.join('&');
    const url = `:API_URL/search/finals?${queryString}`;
    return this.http.get<PaginateResponse<IFinalOut[]>>(url);
  }

  findAll(): Observable<IFinal[]> {
    return this.http.get<IFinal[]>(`:API_URL/finals/all`);
  }

  create(data: { [key: string]: unknown } = {}): Observable<IFinal> {
    return this.http
      .post<IFinal>(`:API_URL/finals`, { ...data })
      .pipe(
        tap(() => {
          setTimeout(() => {
            this.init();
          }, 500);
        })
      );
  }

  createMultiple(data: { [key: string]: unknown } = {}) {
    return this.http.post<unknown>(`:API_URL/finals/multiple`, { ...data });
  }

  updateOne(
    id: string,
    data: { [key: string]: unknown } = {}
  ): Observable<IFinal> {
    Object.assign(data, {
      lastName: '',
    });
    return this.http.put<IFinal>(`:API_URL/finals/${id}`, { ...data });
  }

  deleteOne(id: string): Observable<IFinal> {
    return this.http.delete<IFinal>(`:API_URL/finals/${id}`);
  }

  findOneCache(id: string): Observable<IFinal> {
    const cachedFinal = this.finals.find((final) => final._id === id);
    return cachedFinal
      ? of(cachedFinal)
      : this.http.get<IFinal>(`:API_URL/finals/${id}`);
  }

  findOne(id: string): Observable<IFinal> {
    return this.http.get<IFinal>(`:API_URL/finals/${id}`);
  }

  findMany(ids: string[]): Observable<{ docs: IFinal[] }> {
    return this.http.get<{ docs: IFinal[] }>(`:API_URL/v2/final/all/${ids}`);
  }

  findDealsByFinal(_id: string): Observable<IFinal[]> {
    return this.http.get<IFinal[]>(`:API_URL/finals/final/${_id}`);
  }

  findFinalsByCompany(id: string): Observable<IFinal[]> {
    return this.http.get<IFinal[]>(`:API_URL/companies/${id}/finals`);
  }

  findAllFinalsByCompany_SELECT(): Observable<IFinal[]> {
    return this.http.get<IFinal[]>(`:API_URL/finals/company/select`);
  }

  restoreMany(data) {
    return this.http.post(`:API_URL/finals/restore-many`, data);
  }

  deleteMany(data) {
    return this.http.post(`:API_URL/finals/delete-many`, data);
  }
}
