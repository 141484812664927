<div
  *ngIf="statusName && selectedColor"
  class="roma-badge"
  [ngStyle]="{
    color: selectedColor.darkColor,
    backgroundColor: selectedColor.lightColor,
    'border-color': selectedColor.darkColor
  }"
>
  <ng-container *ngIf="ellipsis">
    <span class="roma-badge__ellipsis">{{ statusName }}</span>
  </ng-container>
  <ng-container *ngIf="!ellipsis">
    {{ statusName }}
  </ng-container>
</div>

<div
  *ngIf="!statusName && !selectedColor"
  class="roma-badge"
  [ngStyle]="{
    backgroundColor: colors[0].lightColor,
    'border-color': colors[0].darkColor,
    color: colors[0].darkColor
  }"
>
  {{ 'status.no_status' | translate }}
</div>
