<hr />

<h2>Activar pagos:</h2>

<button
  *ngIf="!btnDisabled"
  [disabled]="isLoading || showInfo"
  class="btn roma-button"
  (click)="activatePayments()"
>
  <img
    *ngIf="!isLoading"
    src="/assets/img/icons/arrow-right.svg"
    alt="Iniciar sesión"
  />

  <span class="mx-2">{{ btnText }}</span>

  <mat-progress-spinner
    *ngIf="isLoading"
    class="loading-spinner"
    mode="indeterminate"
    [diameter]="20"
  ></mat-progress-spinner>
</button>

<p *ngIf="btnDisabled">
  Los pagos ya han sido habilitados en esta compañía correctamente &nbsp;
  <img class="arrow" src="/assets/icons/ic_check.svg" />
</p>

<!-- <iframe #stripeIframe src="" frameborder="1"></iframe> -->

<div [hidden]="!showInfo" class="my-5 center">
  <p>
    Haz clic en el siguiente enlace para completar los detalles y habilitar la
    aceptación de pagos:
  </p>
  <a
    #stripeOnboardingLink
    class="btn roma-button"
    href=""
    style="max-width: 150px; display: inline-block"
    >Activar</a
  >
</div>
<div class="space"></div>
<div class="row col-3">
  <button class="roma-button h-50" (click)="back()">Volver</button>
</div>
