import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CreateProjectComponent } from './create-project.component';

@Injectable({
  providedIn: 'root',
})
export class CreateProjectService {
  private refreshListSource = new Subject<unknown>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(config: MatDialogConfig = new MatDialogConfig()) {
    config.minWidth = '80vw';
    config.minHeight = '75vh';
    config.data = {
      type: 'ADD',
      ...config.data,
    };

    const dialogRef = this.dialog.open(CreateProjectComponent, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
        this.emitRefresh(res);
      })
    );
  }

  emitRefresh(data: unknown) {
    this.refreshListSource.next(data);
  }
}
