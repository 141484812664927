import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import {
  RmDealSelectComponent,
  SearchFilterPipe,
} from './rm-deal-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RomaToolTipModule } from '@web-frontend/shared/directives/roma-tooltip';
import { ImageModule } from '@web-frontend/shared/pipes/image';
import { CurrencyFormatModule } from '@web-frontend/shared/pipes/currency-format/currency-format.module';

@NgModule({
  declarations: [RmDealSelectComponent, SearchFilterPipe],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatProgressSpinnerModule,
    MatCheckboxModule,
    RomaToolTipModule,
    CurrencyFormatModule,
    ImageModule.forRoot(),
  ],
  providers: [TitleCasePipe],
  exports: [RmDealSelectComponent],
})
export class RmDealSelectModule {}
