<div class="container__body">
  <div class="roma__label">Color</div>
  <roma-choose-color
    [color]="selectedColor.lightColor"
    (change)="changeColor($event)"
  ></roma-choose-color>
</div>
<div class="container__button__close">
  <div class="button__close" (click)="close()">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke="#000"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="#000"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</div>
