import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import {
  BILL_DROPDOWN_OPEN,
  BillStatus,
  ISummaryTotals,
} from '@tacliatech/types';
import { StorageService } from '../../../../shared/services';

@Component({
  selector: 'budget-total-summary',
  templateUrl: './total-summary.component.html',
  styleUrls: ['./total-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalSummaryComponent implements OnInit, OnChanges {
  @Input()
  totalResults: number;

  @Input()
  totalAmount = 0;

  @Input()
  statusTotals: ISummaryTotals = {};

  @Input()
  showSummary = false;

  statusSummary = [];
  dropdownOpen = true;

  translations = {
    [BillStatus.Pending]: 'status.bill.pending_plural',
    [BillStatus.Paid]: 'status.bill.paid_plural',
    [BillStatus.Overdue]: 'status.bill.overdue_plural',
  };

  colors = {
    [BillStatus.Pending]: '#005580',
    [BillStatus.Paid]: '#05562C',
    [BillStatus.Overdue]: '#D66325',
  };

  background = {
    [BillStatus.Pending]: '#C2E6F8',
    [BillStatus.Paid]: '#E1F7EB',
    [BillStatus.Overdue]: '#FEE7DA',
  };

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private i18n: TranslateService
  ) {}

  ngOnInit(): void {
    const localStatus = JSON.parse(StorageService.GetItem(BILL_DROPDOWN_OPEN));
    this.dropdownOpen = localStatus === null ? this.dropdownOpen : localStatus;
    this.draw();
  }

  ngOnChanges(changes): void {
    try {
      const allStatuses = changes.statusTotals.currentValue || {};

      this.statusSummary = [
        BillStatus.Paid,
        BillStatus.Pending,
        BillStatus.Overdue,
      ].map((id) => {
        const statusId = Number(id);

        return {
          color: this.colors[statusId] || '#15152E',
          background: this.background[statusId] || '#F2F5FF',
          label: this.getStatus(statusId),
          value: allStatuses[statusId] || 0,
        };
      });
    } catch (error) {
      this.statusSummary = [];
    }
  }

  getStatus(id: number): string {
    const statusText = this.translations[id];
    return statusText ? this.i18n.instant(statusText) : '';
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
    StorageService.SetItem(BILL_DROPDOWN_OPEN, this.dropdownOpen);
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }
}
