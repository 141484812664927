import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CreateBudgetComponent } from '../create-budget.component';
import { BudgetSidenavComponent } from './budget-sidenav.component';

@Injectable({
  providedIn: 'root',
})
export class CreateSidenavService {
  private refreshListSource = new Subject<boolean>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(config: MatDialogConfig = new MatDialogConfig()) {
    config.position = { right: '0px' };

    const dialogRef = this.dialog.open(BudgetSidenavComponent, {
      ...config,
      disableClose: false,
    });

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }
}
