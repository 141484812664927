import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClockAbsence, PaginateResponse } from '@tacliatech/types';
import { VacationReport } from '../../../../types-legacy/lib/interfaces/vacation-report.interface';

@Injectable({
  providedIn: 'root',
})
export class ClockAbsenceService {
  constructor(private http: HttpClient) {}

  search(query: { [key: string]: any } = {}) {
    const params = new HttpParams({
      fromObject: {
        ...query,
      },
    });

    return this.http.get<PaginateResponse<ClockAbsence.Output[]>>(
      `:API_URL/search/clock-absences`,
      {
        params,
      }
    );
  }

  create(data: ClockAbsence.Schema) {
    if (typeof data.status === 'string') {
      data.status = +data.status;
    }

    return this.http.post(`:API_URL/clock-absences`, data);
  }

  updateOne(id: string, data: Partial<ClockAbsence.Schema>) {
    if (typeof data.status === 'string') {
      data.status = +data.status;
    }

    return this.http.patch(`:API_URL/clock-absences/${id}`, data);
  }

  deleteOne(id: string) {
    return this.http.delete(`:API_URL/clock-absences/${id}`);
  }

  deleteMany(data) {
    return this.http.post(`:API_URL/clock-absences/delete-many`, data);
  }

  getVacationReport(id: string, year: number) {
    return this.http.get<VacationReport>(
      `:API_URL/clock-absences/vacation-report/${id}/${year}`
    );
  }
}
