<div class="container-logo" [formGroup]="form">
  <div class="row">
    <div class="title-card" *ngIf="showLabels">
      {{ 'budgets.settings.title.logo' | translate }}
      <div
        *ngIf="!(hasFeatureSeeLogo$ | async)"
        class="roma-label warning icon-star-pro-feature"
      >
        <div class="icon"><img src="assets/icons/star.svg" /></div>
        PRO
      </div>
    </div>
  </div>

  <div class="h-100">
    <div class="row content-logo">
      <div class="col-12 text-show" *ngIf="showLabels">
        {{ 'logo-crop.logo-text' | translate }}
      </div>

      <div class="col-12">
        <div class="d-flex justify-content-center">
          <roma-picture-v2
            [src]="
              (hasFeatureSeeLogo$ | async)
                ? (budget.logo | imageFirebase: 'budget-logo')
                : ''
            "
            [id]="budget._id"
            [rawLogo]="budget?.logo || ''"
            [settings]="budget"
          >
          </roma-picture-v2>
        </div>
      </div>
    </div>
  </div>
</div>
