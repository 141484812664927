<div #dragContainer class="drag-cols-container" cdkDropListGroup cdkScrollable>
  <ng-container *ngIf="status.length > 0">
    <roma-deal-pipeline-item
      *ngFor="let el of status"
      #pipelineItem
      cdkDropList
      [status]="el"
      [cdkDropListData]="pipelineItem.deals"
      (cdkDropListDropped)="drop($event, el)"
      [deals]="deals"
      [isLoading]="isLoading"
    >
    </roma-deal-pipeline-item>
  </ng-container>
</div>

<div *ngIf="!status.length" class="col-12">
  <roma-loading></roma-loading>
</div>
