import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';

import { LocationClass } from '@web-frontend/shared/class/location/location.class';

import { ErrorStateMatcher } from '@angular/material/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TranslateService } from '@ngx-translate/core';
import { NgOption } from '@web-frontend/shared/components/ng-select/ng-select.types';

import { SelectSearch } from './select-search.types';
import { SelectSearchService } from '@web-frontend/shared/services/select-search';
import PlaceResult = google.maps.places.PlaceResult;

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl,
    form: FormGroupDirective | NgForm
  ): boolean {
    throw new Error('Method not implemented.');
  }
}

@Component({
  selector: 'roma-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSearchComponent implements OnInit, OnChanges {
  @Input()
  set datasource(
    value: Array<{ id: string; name: string; deleted?: boolean }>
  ) {
    this.source = value;
  }

  get datasource() {
    return this.source;
  }

  @Input()
  setValue: any;

  @Input()
  style?: string;

  @Input()
  label = '';

  @Input()
  isMultiple = false;

  @Input()
  isClearable = false;

  @Input()
  isSelectRequired = false;

  @Input()
  notFoundText = '';

  @Input()
  showAddButton = true;

  @Input()
  showCheckButton = false;

  @Input()
  maxSelectedItems = 100;

  @Input()
  type: SelectSearch = SelectSearch.DatabaseAdd;

  @Input()
  childComponent: any = null;

  @Input()
  controlName?: string;

  @Input()
  loading = false;

  @Input()
  tag = '';

  @Input()
  name = '';

  @Input()
  labelError = '';

  @Input()
  textCheck = '';

  @Input()
  wasChanged = false;

  @Input()
  isLoading = true;

  @Input()
  show = false;

  @Input()
  deepLink = false;

  @Output()
  OnSelectObject = new EventEmitter<unknown>();

  @Output()
  isValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  OnAddClick = new EventEmitter<MouseEvent>();

  @Output()
  OnAddressSelected = new EventEmitter<LocationClass>();

  mySelectModel: any;

  @Output()
  onSelectionChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onCheckChange: EventEmitter<MatCheckboxChange> = new EventEmitter<MatCheckboxChange>();

  @Output()
  itemSelect = new EventEmitter<NgOption>();

  @Output()
  isClearItems: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onRemove: EventEmitter<any> = new EventEmitter<any>();

  private source: Array<{ id: string; name: string }> = [];

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private _fb: FormBuilder,
    public i18n: TranslateService,
    private selectSearchService: SelectSearchService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.datasource) {
      this.isValid.next(false);
    }
  }

  selectForm?: FormGroup;

  ngOnInit(): void {
    this.selectSearchService.setName(this.name);
    this.isLoading = true;

    if (this.isSelectRequired) {
      this.selectForm = this._fb.group({
        aux_property: new FormControl(this.setValue, [Validators.required]),
      });
    } else {
      this.selectForm = this._fb.group({
        aux_property: new FormControl(this.setValue),
      });
    }

    this.draw();
  }

  get aux_property() {
    return this.selectForm?.get('aux_property');
  }

  refreshTranslate() {
    this.i18n.onLangChange.subscribe((res) => {});
  }

  ngAfterViewInit(): void {
    this.draw();
  }

  onChange($event) {
    this.onSelectionChange.emit($event);
  }

  checkButtonChange($event: MatCheckboxChange) {
    if ($event.checked) {
      this.selectForm?.patchValue({
        aux_property: null,
      });
    }

    this.selectForm?.markAllAsTouched();
    this.onCheckChange.emit($event);
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  onAddClick(evt: MouseEvent) {
    this.OnAddClick.next(evt);

    if (this.childComponent) {
      this.childComponent.open().subscribe((res) => {
        if (res?._id) {
          const id = this.isMultiple ? [res?._id] : res?._id;

          this.selectForm?.patchValue({
            aux_property: id,
          });

          const response = this.isMultiple ? [res] : res;
          this.OnSelectObject.next(response);

          this.draw();
        }
      });
    }
  }

  onSelectObject(object: any) {
    this.selectForm?.markAllAsTouched();

    if (object?.$ngOptionLabel?.includes('button')) {
      this.selectForm?.patchValue({
        aux_property: null,
      });
      this.isValid.next(false);
    } else {
      if (this.selectForm?.invalid || object?.items?.length === 0) {
        return this.isValid.next(false);
      }

      this.OnSelectObject.next(object);
      this.isValid.next(true);
    }
  }

  onItemSelect(item: NgOption) {
    this.itemSelect.emit(item);
  }

  onClearEvent($event) {
    this.isClearItems.emit(true);
    this.selectSearchService.clearItems(true);
  }

  onRemoveEvent($event) {
    //console.log('onRemoveEvent',$event)
    this.onRemove.emit($event);
    this.selectSearchService.removeItem(true);
  }

  onAutocompleteSelected(result: PlaceResult) {
    const location = new LocationClass(result);

    // @ts-ignore
    for (const address of result?.address_components) {
      for (const type in address.types) {
        if (type) {
          switch (address.types[type]) {
            case 'country':
              location.country = address.long_name;
              location.codeCountry = address.short_name;
              break;
            case 'administrative_area_level_1':
              location.state = address.long_name;
              break;
            case 'administrative_area_level_2':
              if (!location.city || location.city === '') {
                location.city = address.long_name;
              }
              break;
            case 'locality':
              location.city = address.long_name;
              break;
            case 'route':
            case 'street_address':
            case 'intersection':
              location.streetName = address.long_name;
              break;
            case 'street_number':
            case 'administrative_area_level_3':
            case 'administrative_area_level_4':
            case 'administrative_area_level_5':
            case 'sublocality_level_1':
              location.sector = address.long_name;
              break;
            case 'postal_code':
              location.postCode = address.long_name;
              break;
          }
        }
      }
    }

    this.OnAddressSelected.next(location);
    return location;
  }
}
