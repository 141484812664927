import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Stripe from 'stripe';
import { Observable } from 'rxjs';
import { TacliaPayments } from '@tacliatech/types';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(private http: HttpClient) {}

  getConnectionToken(
    location: string
  ): Observable<Stripe.Response<Stripe.Terminal.ConnectionToken>> {
    return this.http.post<Stripe.Response<Stripe.Terminal.ConnectionToken>>(
      ':API_URL/payments/connection_token',
      { location }
    );
  }

  captureIntent(
    paymentIntent: Stripe.PaymentIntent
  ): Observable<Stripe.Response<Stripe.PaymentIntent>> {
    return this.http.post<Stripe.Response<Stripe.PaymentIntent>>(
      ':API_URL/payments/capture_payment_intent',
      paymentIntent
    );
  }

  createIntent(
    payload: TacliaPayments.Intent.Request
  ): Observable<Stripe.Response<Stripe.PaymentIntent>> {
    return this.http.post<Stripe.Response<Stripe.PaymentIntent>>(
      ':API_URL/payments/create_payment_intent',
      payload
    );
  }

  createLoginLink(connectedAccount?: string) {
    return this.http.get<Stripe.Response<Stripe.LoginLink>>(
      `:API_URL/payments/login-link/${connectedAccount}`
    );
  }

  createConnectedAcount() {
    return this.http.post(':API_URL/payments/create-connected-account', {});
  }

  createTransfer(
    transferData: TacliaPayments.Transfer.Request
  ): Observable<Stripe.Response<Stripe.PaymentIntent>> {
    return this.http.post<Stripe.Response<Stripe.PaymentIntent>>(
      ':API_URL/payments/create-transfer',
      transferData
    );
  }
}
