import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CreatePersonComponent } from '../create-person/create-person.component';

@Injectable({
  providedIn: 'root',
})
export class CreatePersonService {
  private refreshListSource = new Subject<boolean>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  // create person from admin-clock-hours
  open(config?: MatDialogConfig) {
    const dialogRef = this.dialog.open(CreatePersonComponent, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }
}
