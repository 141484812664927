import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanyModuleService } from '@web-frontend/shared/services/company';

@Component({
  selector: 'roma-modal-error-maintenance',
  templateUrl: './modal-error-maintenance.component.html',
  styleUrls: ['./modal-error-maintenance.component.scss'],
})
export class ModalErrorMaintenanceComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalErrorMaintenanceComponent>,
    private companyModuleService: CompanyModuleService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  public navToHomePage(): void {
    this.companyModuleService.goFirstRouteActive();
    this.dialogRef.close();
  }
}
