import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OpenSettingsDirective } from './open-settings.directive';
import { SettingsModalComponent } from './settings-modal.component';
import { BudgetSettingsModule } from '../../components/budget-setttings/budget-settings.module';
import { ModalTemplateModule } from '../../components/modal-template';

@NgModule({
  declarations: [OpenSettingsDirective, SettingsModalComponent],
  imports: [CommonModule, BudgetSettingsModule, ModalTemplateModule],
  exports: [OpenSettingsDirective],
})
export class OpenSettingsModule {}
