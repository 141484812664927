import {
  ICustomGlobalProperty,
  ICustomProperty,
  PartOfServiceGlobal,
} from '@tacliatech/types';
import { SelectItem } from './select-item/select-item.types';

export namespace DealPartOfService {
  export const GetElements = (
    partOfServiceRef: PartOfServiceGlobal.PartOfServiceResponse
  ): SelectItem.Items => {
    let res = [
      {
        title: 'deal_part_of_service.title',
        description: '',
        active: partOfServiceRef.fields.title,
        refField: 'title',
      },
      {
        title: 'deal_part_of_service.description',
        description: '',
        active: partOfServiceRef.fields.description,
        refField: 'description',
      },
      {
        title: 'deal_part_of_service.client',
        description: '',
        active: partOfServiceRef.fields.client,
        refField: 'client',
      },
      {
        title: 'deal_part_of_service.project',
        description: '',
        active: partOfServiceRef.fields.project,
        refField: 'project',
      },
      {
        title: 'deal_part_of_service.address',
        description: '',
        active: partOfServiceRef.fields.address,
        refField: 'address',
      },
      {
        title: 'deal_part_of_service.addressAdditional',
        description: '',
        active: partOfServiceRef.fields.addressAdditional,
        refField: 'addressAdditional',
      },
      {
        title: 'deal_part_of_service.date',
        description: '',
        active: partOfServiceRef.fields.date,
        refField: 'date',
      },
      {
        title: 'deal_part_of_service.urgency',
        description: '',
        active: partOfServiceRef.fields.urgency,
        refField: 'urgency',
      },
      {
        title: 'deal_part_of_service.equipment',
        description: '',
        active: partOfServiceRef.fields.equipment,
        refField: 'equipment',
      },
    ];

    res = res.filter((el) =>
      Object.keys(partOfServiceRef.fields).includes(el.refField)
    );

    return res;
  };

  export const GetCustomPropertiesElements = (
    partOfServiceRes: PartOfServiceGlobal.PartOfServiceResponse,
    customProperties: ICustomProperty[] | ICustomGlobalProperty[]
  ): SelectItem.Items => {
    return customProperties.map((property) => {
      return {
        title: property.name,
        description: '',
        active:
          Object.prototype.hasOwnProperty.call(
            partOfServiceRes?.fieldsCustomProperties || {},
            property.name
          ) && partOfServiceRes?.fieldsCustomProperties[property?.name],
        refField: property.name,
      };
    });
  };

  export const GetCustomGroupPropertiesElements = (
    partOfServiceRes: PartOfServiceGlobal.PartOfServiceResponse,
    customProperties: ICustomProperty[] | ICustomGlobalProperty[]
  ): SelectItem.Items => {
    return customProperties.map((property) => {
      return {
        title: property.name,
        description: '',
        active:
          Object.prototype.hasOwnProperty.call(
            partOfServiceRes?.fieldCustomGroupProperties || {},
            property.name
          ) && partOfServiceRes?.fieldCustomGroupProperties[property?.name],
        refField: property.name,
      };
    });
  };

  export const GetObject = (
    elements: SelectItem.Items
  ): PartOfServiceGlobal.FieldsIncludes => {
    const objRef: PartOfServiceGlobal.FieldsIncludes = PartOfServiceGlobal.DefaultFieldsToInclude();

    for (let i = 0; i < elements.length; i++) {
      const el = elements[i];

      if (Object.prototype.hasOwnProperty.call(objRef, el.refField)) {
        objRef[el.refField] = el.active;
      }
    }

    return objRef;
  };
}
