import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'roma-cancel-sale-modal',
  templateUrl: './cancel-sale-modal.component.html',
  styleUrls: ['./cancel-sale-modal.component.scss'],
})
export class CancelSaleModalComponent implements OnInit {
  cancelReason = '';
  isLoading = false;

  constructor(
    private dialogRef: MatDialogRef<CancelSaleModalComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: { selectedDeal },
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  close(params = null) {
    this.dialogRef.close(params);
  }

  submit(params = null) {
    this.close({
      cancelReason: this.cancelReason,
    });
  }
}
