import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CreatePeriodicityComponent } from './create-periodicity.component';
import { CreatePeriodicityParams } from './create-periodicity.types';

@Injectable({
  providedIn: 'root',
})
export class CreatePeriodicityService {
  private refreshListSource = new Subject<boolean>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(config?: MatDialogConfig<CreatePeriodicityParams>) {
    const dialogRef = this.dialog.open(CreatePeriodicityComponent, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }
}
