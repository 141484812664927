import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { SelectItem } from './select-item.types';

@Component({
  selector: 'roma-select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectItemComponent implements OnInit {
  @Input()
  data: SelectItem.Items;

  @Input()
  isLoading = false;

  @Output()
  changeData = new EventEmitter<SelectItem.ItemsChanged>();

  totalItems = 5;
  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  changeItem(item: SelectItem.Item, index: number, evt: MatSlideToggleChange) {
    const elSelected = this.data[index];
    elSelected.active = evt.checked;

    this.changeData.emit({
      items: this.data,
      changed: elSelected,
    });

    this.draw();
  }

  private draw() {
    this.cdRef.detectChanges();
  }
}
