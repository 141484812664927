export enum BrazeEventType {
  deal_start = 'deal_start',

  equipment_start = 'equipment_start',

  address_start = 'address_start',

  project_start = 'project_start',

  vendor_start = 'vendor_start',

  expense_start = 'expense_start',

  catalogue_start = 'catalogue_start',

  final_start = 'final_start',
  final_create = 'final_create',
  final_edit = 'final_edit',
  final_user_assigned = 'final_user_assigned',
  final_owner_assigned = 'final_owner_assigned',

  sale_start = 'sale_start',
  sale_create = 'sale_create',
  sale_payment_link = 'sale_payment_link',
  sale_ticket_payed = 'sale_ticket_payed',
  sale_ticket_not_payed = 'sale_ticket_not_payed',

  register_start = 'register_start',
  register_save = 'register_save',

  sales_ticket_payed = 'sales_ticket_payed',
  sales_ticket_not_payed = 'sales_ticket_not_payed',

  payment_onboarding_pending = 'payment_onboarding_pending',
  payment_onboarding_incomplete = 'payment_onboarding_incomplete',
  payment_onboarding_rejected = 'payment_onboarding_rejected',
  payment_request_pending = 'payment_RequestPending',
  payment_request_approved = 'payment_RequestApproved',
  payment_request_rejected = 'payment_RequestRejected',

  restore_password = 'restore_password',

  new_user_invitation = 'new_user_invitation',

  online_profile_created = 'online_profile_created',
  online_profile_started = 'online_profile_started',

  budget_send_document = 'budget_send_document',

  bill_send_document = 'bill_send_document',

  proform_send_document = 'proform_send_document',
  waybill_send_document = 'waybill_send_document',

  time_tracking_pending_approval = 'time_tracking_pending_approval',

  task_start = 'task_start',
  task_card_user_assigned = 'task_card_user_assigned',
  task_card_owner_assigned = 'task_card_owner_assigned',

  project_user_assigned = 'project_user_assigned',
  project_owner_assigned = 'project_owner_assigned',

  deal_user_assigned = 'deal_user_assigned',
  deal_owner_assigned = 'deal_owner_assigned',
  deal_canceled = 'deal_canceled',
  deal_date_changed = 'deal_date_changed',
  new_deal_final_created = 'new_deal_final_created',

  general_start_catalogue = 'generalStart_catalogue',

  time_tracking_user_missing = 'timeTracking_user_missing',

  absence_pending_approval = 'absence_pending_approval',
  absence_approved = 'absence_approved',
  absence_rejected = 'absence_rejected',
  absence_create = 'absence_create',
  absence_team_create = 'absenceTeam_create',
  absence_excel_download = 'absence_excel_download',
  absence_edit = 'absence_edit',
  absence_team_edit = 'absenceTeam_edit',
  absence_deleted = 'absence_deleted',

  deal_create = 'deal_create',
  deal_edit = 'deal_edit',
}
