import { IStatus } from '../interfaces';

export enum StatusSalesId {
  Paid = 1,
  NotPaid = 2,
  Cancelled = 3,
  Refunded = 4,
  RefundPending = 5,
  PaymentPending = 6,
  PaymentRejected = 7,
}

export const statusSales: IStatus[] = [
  {
    id: StatusSalesId.Paid,
    name: 'Pagada',
    translate: 'status.paid',
    color: '#DFF6E8',
    allowEdit: false,
    order: 1,
  },
  {
    id: StatusSalesId.NotPaid,
    name: 'No pagada',
    translate: 'status.noPaid',
    color: '#FFF1DB',
    allowEdit: false,
    order: 2,
  },
  {
    id: StatusSalesId.Cancelled,
    name: 'Anulada',
    translate: 'status.cancelled',
    color: '#FFEBEB',
    allowEdit: false,
    order: 3,
  },
  {
    id: StatusSalesId.Refunded,
    name: 'Reembolsada',
    translate: 'status.refunded',
    color: '#FFEBEB',
    allowEdit: false,
    order: 4,
  },
  {
    id: StatusSalesId.RefundPending,
    name: 'Reembolso pendiente',
    translate: 'status.refundPending',
    color: '#FFF1DB',
    allowEdit: false,
    order: 5,
  },
  {
    id: StatusSalesId.PaymentPending,
    name: 'Pendiente de pago',
    translate: 'status.paymentPending',
    color: '#FFF1DB',
    allowEdit: false,
    order: 6,
  },
  {
    id: StatusSalesId.PaymentRejected,
    name: 'Pago rechazado',
    translate: 'status.paymentRejected',
    color: '#FFEBEB',
    allowEdit: false,
    order: 7,
  },
].sort((a, b) => (a.order > b.order ? 1 : -1));
