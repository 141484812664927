import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../services';
import { ClientRegisterService } from '../services/client-register/client-register.service';
import { checkCountryCodeInLocalStorage } from '../utils/country-validator';
import { replaceTextsInObject, replacementsMX } from './replace-mx';

export enum Lang {
  Pt = 'pt',
  En = 'en',
  Es = 'es',
}

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  constructor(
    private translate: TranslateService,
    private clientRegisterService: ClientRegisterService
  ) {}

  private emitChangeLanguage(lang: Lang) {
    try {
      window.dispatchEvent(new CustomEvent('mf-language', { detail: lang }));
    } catch (error) {
      console.error('Error emitting language change', error);
    }
  }

  async switchLang(lang: Lang, module?: string) {
    StorageService.SetItem('USER_LANG', lang);
    this.emitChangeLanguage(lang);
    try {
      this.translate.setDefaultLang(lang);

      const conditional: any = {
        module: 'b',
      };

      if (module) {
        conditional.module = module;
        this.finishTranslate(lang, conditional);
      } else {
        let customerData = StorageService.GetItem('customerData');
        customerData = JSON.parse(customerData);
        if (customerData?.sector_id) {
          this.clientRegisterService
            .getSectorByIdModule(customerData?.sector_id)
            .subscribe((resp) => {
              if (resp[0].module) {
                conditional.module = resp[0].module;
                this.finishTranslate(lang, conditional);
              }
            });
        } else {
          this.finishTranslate(lang, conditional);
        }
      }
    } catch (error) {
      //error
    }
  }

  async finishTranslate(lang, conditional) {
    const JSON_ARCHIVE = await this.translate.getTranslation(lang).toPromise();
    const JSON_CLIENT = await this.translate
      .getTranslation(`client/${lang}/${conditional.module}`)
      .toPromise();

    localStorage.setItem('sectorModule', conditional.module);

    let mergedJson = this.deepMerge(JSON_ARCHIVE, JSON_CLIENT);
    if (lang === Lang.Es) {
      const isMexico = checkCountryCodeInLocalStorage('MX');
      if (isMexico) {
        mergedJson = replaceTextsInObject(mergedJson, replacementsMX);
      }
    }
    this.translate.setTranslation(lang, mergedJson);

    await this.translate.use(lang).toPromise();
  }

  deepMerge(target, source) {
    for (const key of Object.keys(source)) {
      if (source[key] instanceof Object) {
        if (!target[key]) {
          target[key] = {};
        }
        this.deepMerge(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
    return target;
  }

  initializeTranslate() {
    const defaultLang = this.defaultLang();
    this.switchLang(defaultLang);
  }

  defaultLang(): Lang {
    const lang = localStorage.getItem('USER_LANG') as Lang;

    if (lang) {
      return lang;
    }

    const browserLang = navigator.language;

    let defLang: Lang = Lang.En; //default in english
    //configurations like es-MX
    if (
      browserLang.split('-').length == 1 &&
      browserLang.split('-')[0].toLowerCase() === Lang.Es
    ) {
      defLang = Lang.Es;
    }

    //configurations like gl-ES
    if (
      browserLang.split('-').length == 2 &&
      browserLang.split('-')[1].toLowerCase() === Lang.Es
    ) {
      defLang = Lang.Es;
    }

    // gallego, euskera, catalán
    if (['gl', 'eu', 'ca'].includes(browserLang)) {
      defLang = Lang.Es;
    }

    return defLang;
  }
}
