import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';

import { TranslateModule } from '@ngx-translate/core';

import { FilterComponent } from './filter.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { PermissionsModule } from '../permissions';
import { RmSelectModule } from '../globals/rm-select';
import { RomaToolTipModule } from '@web-frontend/shared/directives/roma-tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [FilterComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatToolbarModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    NgSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
    PermissionsModule,
    MatMenuModule,
    RmSelectModule,
    RomaToolTipModule,
    MatProgressSpinnerModule,
  ],
  exports: [FilterComponent],
})
export class FilterModule {}
