import { PaginateSort } from '@tacliatech/types';

export const INIT_ACTIVITY_SEARCH_PARAMS = {
  limit: 50,
  page: 1,
  sort: PaginateSort.DESC,
  applyPaginate: true,
  keyword: '',
  owner: null,
  author: null,
  'sortOptions[created_at]': PaginateSort.DESC,
  sortOptions: null,
  deal: '',
  takeNotified: false,
  takeDeleted: false,
  takeToday: false,
  customProperties: '',
  from: null,
  to: null,
  'authors[]': [],
  'assets[]': [],
  'deals[]': [],
  'ids[]': [],
  'supervisors[]': [],
  'projects[]': [],
  'finals[]': [],
};
