import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QRCodeComponent } from 'angularx-qrcode';
import { v4 as uuidv4 } from 'uuid';

import { QrModalParams } from './qr.types';

@Component({
  selector: 'roma-qr-info',
  templateUrl: './qr-info.component.html',
  styleUrls: ['./qr-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrInfoComponent implements OnInit {
  url?: string;
  size = window.screen.width < 768 ? window.screen.width - 20 : 500;

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<QrInfoComponent>,
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public modalParams: QrModalParams
  ) {}

  ngOnInit(): void {
    this.attachParams();
  }

  close() {
    this.dialogRef.close();
  }

  downloadFile(event: any) {
    event.preventDefault();
    const element: HTMLElement = document.getElementById(
      'qrElement'
    ) as HTMLElement;
    element.click();
  }

  download(qr: QRCodeComponent) {
    try {
      const qrcElement = qr.qrcElement.nativeElement;
      const [image] = qrcElement.children as HTMLImageElement[];

      const a = this.document.createElement('a');

      a.href = image.currentSrc;
      a.download = `qr-code-${uuidv4()}.png`;

      a.click();
    } catch (err) {
      console.error('[qr-info] error download', err);
    }
  }

  private attachParams() {
    Object.assign(this, {
      ...this.modalParams,
    });

    this.draw();
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }
}
