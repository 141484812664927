<mat-progress-spinner
  *ngIf="isLoading"
  class="loading-spinner"
  mode="indeterminate"
  [diameter]="40"
  style="margin: 0px auto"
></mat-progress-spinner>
<ng-container *ngIf="showCustomProperties">
  <div
    romaProSelect
    (isAvailableFeature)="isAvailableFeature($event)"
    [feature]="i < 2 ? featureRef.SystemPermission.DefaultAllow : feature"
    [options]="{
      className: 'roma-label warning right-center'
    }"
    class="wrapper-container wrapper-pro-feature-none"
    *ngFor="let property of dynamicProperties; let i = index"
    [attr.data-index]="i"
  >
    <div
      style="width: 100%; float: left"
      romaProSelect
      class="form-group wrapper-pro-feature-user"
      [featureUser]="featureUser"
    >
      <div class="row">
        <div [ngClass]="rowLayout ? 'col-12' : 'col-5'">
          <label class="field-label" for="name">
            {{ property.name | translate
            }}<obligatory-field-sign
              *ngIf="property.isRequired"
            ></obligatory-field-sign>
          </label>
        </div>
        <div [ngClass]="rowLayout ? 'col-12' : 'col-7'">
          <roma-editable-field
            [smSize]="smSize"
            [value]="valueCustomProperty(property?._id)"
            [fieldName]="
              property.type !== dynamicPropertyTypeRef.Select
                ? 'value'
                : 'options'
            "
            [elements]="
              property.type === dynamicPropertyTypeRef.Select
                ? property.options
                : []
            "
            [type]="typeCustomProperty(property?.type)"
            [isCustomProperty]="true"
            [updateOnEdit]="updateOnEdit"
            [apiUrl]="':API_URL/dynamic-properties/schema-ref'"
            [params]="{ idCustomProperty: property._id, schemaID: idSchema }"
            [block]="false"
            [isMultiple]="property.type === dynamicPropertyTypeRef.Select"
            [showAlertMessage]="options.showAlertMessage"
            [isRequired]="property.isRequired"
            (checkInvalidField)="checkInvalidField($event)"
            (updateLoading)="isLoadingUpdate($event)"
            (requestedApi)="changeProperty()"
            (onValueChanged)="eventValueChanged(property, $event)"
          >
          </roma-editable-field>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="custom-fields-message" *ngIf="showPropertiesMessage && !isLoading">
  <span class="material-icons pointer info ng-star-inserted">info</span>
  <h4>{{ 'expense.messageTitle' | translate }}</h4>
  <p [innerHTML]="'expense.message' | translate"></p>
  <a (click)="goTo('/admin/customization', {})">
    {{ 'expense.messageButton' | translate }}
  </a>
</div>
