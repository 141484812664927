<div class="container-left" [ngClass]="content?.containerLeftClass">
  <div class="title" *ngIf="content?.title">
    {{ content?.title | translate }}
  </div>
  <div
    *ngIf="content?.description"
    class="description"
    [innerHtml]="content?.description | translate"
  ></div>

  <div *ngIf="content?.items?.length" class="list">
    <div *ngFor="let item of content?.items" class="item">
      <img src="assets/icons/ic_buy_check.svg" alt="Check icon" />
      <div class="text">
        {{ item | translate }}
      </div>
    </div>
  </div>

  <div class="container-buttons" [ngClass]="content?.containerButtonClass">
    <base-button
      *ngIf="content?.firstButton"
      [buttonClass]="content?.firstButtonClass"
      [isLoading]="isLoading"
      [size]="buttonSize"
      [ngClass]="{ 'single-button': !content?.secondButton }"
      (click)="onButton1Click()"
    >
      {{ content?.firstButton | translate }}
      <img
        *ngIf="content?.firstButtonIcon"
        class="download-icon"
        [src]="content?.firstButtonIcon"
      />
    </base-button>

    <base-button
      *ngIf="showSecondaryButton(content)"
      [buttonClass]="content?.secondButtonClass"
      [isLoading]="isLoading"
      [size]="buttonSize"
      (click)="onButton2Click()"
    >
      {{ content?.secondButton | translate }}
      <img
        *ngIf="content?.secondButtonIcon"
        class="download-icon"
        [src]="content?.secondButtonIcon"
      />
    </base-button>
  </div>
</div>
<div class="container-right" *ngIf="content?.image">
  <div class="container-image">
    <img [src]="content?.image" alt="Asset image" />
  </div>
</div>
<div class="container-right-video" *ngIf="content?.videoId">
  <youtube-player
    class="custom-border"
    [height]="videoSize().height"
    [width]="videoSize().width"
    [videoId]="content?.videoId"
  ></youtube-player>
</div>
