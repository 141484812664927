import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';

import { NgSelectModule } from '@ng-select/ng-select';

import { CreateActivityComponent } from './create-activity.component';
import { CreateActivityService } from './create-activity.service';
import { ModalTemplateModule } from '../modal-template';
import { FieldErrorDisplayModule } from '../field-error-display';
import { AppendCustomPropertiesModule } from '../append-custom-properties';
import { EditCustomPropertiesModule } from '../edit-custom-properties';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SelectorRecurrenceModule } from '../selector-recurrence';
import { PermissionsModule } from '../permissions';
import { RmSelectModule } from '../globals/rm-select';
import { EquipmentNotesModule } from '../equipments-notes';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SplitModule } from '@web-frontend/shared/pipes/split';
import { NgxMaskModule } from 'ngx-mask';
import { RmResponsiveSelectModule } from '../globals/rm-responsive-select';
import { RmStatusSelectModule } from '../globals/rm-status-select';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { ImageSelectorModule } from '../image-selector-v2';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InfoModule } from '@web-frontend/shared/components/info/info.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CreateActivityComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    MatDialogModule,
    MatIconModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatCheckboxModule,
    MatButtonModule,
    FieldErrorDisplayModule,
    AppendCustomPropertiesModule,
    EditCustomPropertiesModule,
    MatProgressSpinnerModule,
    PermissionsModule,
    SelectorRecurrenceModule,
    RmSelectModule,
    RmStatusSelectModule,
    RmResponsiveSelectModule,
    MatMenuModule,
    EquipmentNotesModule,
    SplitModule,
    NgxMaskModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    ClickDeleteModule,
    ImageSelectorModule,
    MatTooltipModule,
    InfoModule,
  ],
  exports: [CreateActivityComponent],
  providers: [CreateActivityService],
})
export class CreateActivityModule {}
