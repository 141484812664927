<div class="container-account-data" [formGroup]="form">
  <div class="title-card">
    {{ 'budgets.settings.title.accountdata' | translate }}
  </div>

  <form [formGroup]="form" class="global-container">
    <div class="row global-item">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6red">
        <div class="form-group">
          <label for="title">
            {{ 'budgets.settings.create.form.company_name' | translate }}
          </label>
          <input
            #companyNameField
            type="text"
            class="form-control"
            id="company_name"
            aria-describedby="titleHelp"
            formControlName="company_name"
            maxLength="50"
            [placeholder]="
              'budgets.settings.create.form.companyNamePlaceholder' | translate
            "
            [ngClass]="{ 'is-invalid': fieldCompany.displayError || false }"
          />

          <roma-field-error-display
            #fieldCompany
            fieldName="company_name"
            [errorMsg]="'budgets.settings.create.form.companyNameError'"
            [form]="form"
          >
          </roma-field-error-display>

          <div *ngIf="mexicanCompany && !companyNameField.value" class="warning-invoice">
            {{ 'budgets.settings.create.form.requireToInvoice' | translate }}
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="form-group">
          <label for="title">
            {{ 'budgets.settings.create.form.trade_name' | translate }}
          </label>

          <input
            type="text"
            class="form-control"
            id="tradename"
            aria-describedby="titleHelp"
            formControlName="tradename"
            maxLength="50"
            [placeholder]="
              'budgets.settings.create.form.tradeNamePlaceholder' | translate
            "
            [ngClass]="{ 'is-invalid': fieldTradName.displayError || false }"
          />

          <roma-field-error-display
            #fieldTradName
            fieldName="tradename"
            [errorMsg]="'budgets.settings.create.form.tradeNameError'"
            [form]="form"
          >
          </roma-field-error-display>
        </div>
      </div>

      <div *ngIf="mexicanCompany" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="form-group">
          <label for="title">
            {{ 'budgets.settings.create.form.fiscalRegime' | translate | uppercase }}
          </label>

          <ng-select
            #fiscalRegimeField
            [multiple]="false"
            formControlName="fiscal_regime"
            [searchable]="true"
            [clearable]="true"
            [placeholder]="'general.selects' | translate"
            [notFoundText]="'hiring.form.placeholder_notfound' | translate"
            class="fiscalRegime-select"
          >
            <ng-option *ngFor="let item of fiscalRegimes" [value]="item.id">
              {{ item.description }}
            </ng-option>
          </ng-select>
          <div class="warning-invoice" *ngIf="fiscalRegimesReady && !form.get('fiscal_regime').value">
            {{ 'budgets.settings.create.form.requireToInvoice' | translate }}
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="form-group">
          <label for="title">
            {{ (!mexicanCompany ? 'budgets.settings.create.form.fiscal_id' : 'budgets.settings.create.form.RFC') | translate }}
          </label>

          <input
            #fiscalNameField
            type="text"
            class="form-control"
            id="fiscalName"
            aria-describedby="titleHelp"
            formControlName="fiscalName"
            maxLength="50"
            [placeholder]="
              (!mexicanCompany ? 'budgets.settings.create.form.fiscalIdPlaceholder' : 'budgets.settings.create.form.RFCPlaceholder') | translate
            "
            [ngClass]="{ 'is-invalid': fieldFiscalName.displayError || false }"
          />

          <roma-field-error-display
            #fieldFiscalName
            fieldName="fiscalName"
            [errorMsg]="!mexicanCompany ? 'budgets.settings.create.form.fiscalIdError' : 'budgets.settings.create.form.RFCerror'"
            [form]="form"
          >
          </roma-field-error-display>

          <div *ngIf="mexicanCompany && !fiscalNameField.value" class="warning-invoice">
            {{ 'budgets.settings.create.form.requireToInvoice' | translate }}
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="form-group">
          <label for="title">
            {{ 'budgets.settings.create.form.email' | translate }}
          </label>

          <input
            type="text"
            class="form-control"
            id="email"
            aria-describedby="titleHelp"
            formControlName="email"
            maxLength="50"
            (focusout)="validateForm()"
            [placeholder]="
              'budgets.settings.create.form.emailPlaceholder' | translate
            "
            [ngClass]="{
              'is-invalid':
                fieldEmail.displayError ||
                emailFormatField?.displayError ||
                false
            }"
          />

          <roma-field-error-display
            #fieldEmail
            fieldName="email"
            [errorMsg]="'budgets.settings.create.form.emailError'"
            [form]="form"
          >
          </roma-field-error-display>

          <roma-field-error-display
            #emailFormatField
            fieldName="email"
            errorMsg="internalVendor.create.emailInvalid"
            errorName="email"
            [form]="form"
          >
          </roma-field-error-display>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="form-group">
          <label for="title">
            {{ 'budgets.settings.create.form.phone' | translate }}
          </label>

          <input
            type="text"
   (input)="numberOnly($event)"
            class="form-control"
            id="phone"
            aria-describedby="titleHelp"
            formControlName="phone"
            maxLength="50"
            [placeholder]="
              'budgets.settings.create.form.phonePlaceholder' | translate
            "
            [ngClass]="{ 'is-invalid': fieldPhone.displayError || false }"
          />

          <roma-field-error-display
            #fieldPhone
            fieldName="phone"
            [errorMsg]="'budgets.settings.create.form.phoneError'"
            [form]="form"
          >
          </roma-field-error-display>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="form-group">
          <label for="title">
            {{ 'budgets.settings.create.form.business' | translate }}
          </label>

          <ng-select
            [multiple]="false"
            formControlName="business_type"
            [searchable]="false"
            [clearable]="false"
            [placeholder]="'general.select' | translate"
            [notFoundText]="'hiring.form.placeholder_notfound' | translate"
          >
            <ng-option *ngFor="let item of businessTypes" [value]="item.id">
              {{ item.title | translate }}
            </ng-option>
          </ng-select>

          <roma-field-error-display
            #fieldPhone
            fieldName="phone"
            [errorMsg]="'budgets.settings.create.form.phoneError'"
            [form]="form"
          >
          </roma-field-error-display>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <button
          type="submit"
          [disabled]="!isFormValid() || form.pristine"
          [ngClass]="
            fieldPhone.displayError
              ? 'custom-btn-save'
              : 'custom-btn-save mt-24'
          "
          mat-button
          color="primary"
          (click)="submit()"
        >
          {{
            mode === 'ADD'
              ? ('activity.create.submit' | translate)
              : ('activity.edit.submit' | translate)
          }}
          <i class="fa fa-check"></i>
        </button>
      </div>
    </div>

  </form>

  <!-- <code>
    {{ fiscalRegimes | json }}
  </code> -->

  <roma-snackbar-mexico
    *ngIf="fiscalRegimesReady && showSnackbarMexico"
    type="warning"
    [message]="'budgets.settings.create.form.incompleteCompanyData' | translate"
  ></roma-snackbar-mexico>
</div>
