import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnsaveGuard } from './unsave.guard';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [UnsaveGuard],
})
export class UnsaveModule {}
