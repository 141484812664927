import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  MatDateRangeInput,
  MatDateRangePicker,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';

import { format } from 'date-fns';

import { RmFilterModalDashboardDetailService } from '../rm-filter-modal-dhbd-detail';
import { RmFilterInputRangeOptions } from '../rm-filter.const';
import { RmFilter } from '../rm-filter.types';
import { RmFilterModalDashboard } from './rm-filter-modal-dashboard.types';

@Component({
  selector: 'roma-rm-filter-modal-dashboard',
  templateUrl: './rm-filter-modal-dashboard.component.html',
  styleUrls: ['./rm-filter-modal-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmFilterModalDashboardComponent implements OnInit {
  @Input()
  set source(value: RmFilter.Filter) {
    this._source = value;
    if (!this._initialSource) {
      this._initialSource = value.toJson();
    }
  }

  get source() {
    return this._source;
  }

  @ViewChildren(MatDateRangeInput)
  viewChildren!: QueryList<MatDateRangeInput<any>>;

  hoursTypes = [
    { title: 'general.equal', value: 'EQUAL' },
    { title: 'general.major2', value: 'MAJOR' },
    { title: 'general.minor2', value: 'MINOR' },
  ];

  focusInputElValue!: string;
  focusRangeInputStartValue = '';
  focusRangeInputEndValue = '';
  focusInputDate = '';
  inputRangeOptions = RmFilterInputRangeOptions;

  private _source!: RmFilter.Filter;
  private _initialSource!: unknown[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: RmFilterModalDashboard.Params,

    private dialogRef: MatDialogRef<
      RmFilterModalDashboardComponent,
      RmFilterModalDashboard.Result
    >,

    private cdRef: ChangeDetectorRef,
    private rmFilterModalDhbdService: RmFilterModalDashboardDetailService
  ) {}

  ngOnInit(): void {
    this.patchParams();
  }

  submit() {
    this.dialogRef.close({
      source: this.source,
    });
  }

  close() {
    if (this.hasChanges()) {
      this.submit();
    } else {
      this.dialogRef.close(null);
    }
  }

  hasChanges(): boolean {
    return (
      JSON.stringify(this._source.toJson()) !==
      JSON.stringify(this._initialSource)
    );
  }

  clickDetailItem(evt: MouseEvent, item: RmFilter.Select) {
    if (!evt.defaultPrevented) {
      this.rmFilterModalDhbdService
        .open({
          data: {
            source: item,
          },
        })
        .subscribe((res) => {
          item = res.source;
          this.draw();
        });
    }
  }

  private setInputFocus(value: string) {
    this.focusInputElValue = value;
    this.draw();
  }

  changeRadioGroup(evt: MatRadioChange, item: RmFilter.Hour) {
    try {
      if (evt instanceof MatRadioChange) {
        const inputValue = document.getElementById(
          evt.value
        ) as HTMLInputElement;
        const value = +inputValue.value;
        const newValue: [string, number] = [evt.value, value];

        inputValue.focus();
        item.setValue(newValue);

        this.setInputFocus(evt.value);
      }
    } catch (err) {
      console.log(err);
    }
  }

  changeRadioInput(
    evt: KeyboardEvent,
    element: { title: string; value: string },
    item: RmFilter.Hour
  ) {
    try {
      const value = +(evt.target as HTMLTextAreaElement).value;

      const newValue: [string, number] = [element.value, value ? value : null];
      item.setValue(newValue);
    } catch (err) {}
  }

  cleanDateRangeDate(
    evt: MouseEvent,
    picker: MatDateRangePicker<unknown>,
    inputStart: HTMLInputElement,
    inputEnd: HTMLInputElement,
    item: RmFilter.RangeDate
  ) {
    evt.stopPropagation();

    try {
      (picker as any)._model.selection.end = '';
      (picker as any)._model.selection.start = '';
    } catch {}

    inputStart.value = '';
    inputEnd.value = '';
    item.setValue({
      from: null,
      to: null,
    });
  }

  rangeDateChange(
    type: 'START' | 'END',
    evt: MatDatepickerInputEvent<Date>,
    item: RmFilter.RangeDate
  ) {
    const value = evt.value;
    const oldValue = item.getValue();

    if (type === 'START') {
      this.focusRangeInputStartValue = format(value, 'DD/MM/YYYY');
      item.setValue({
        from: value.toUTCString(),
        to: oldValue[1],
      });
    }
    if (type === 'END') {
      this.focusRangeInputEndValue = format(value, 'DD/MM/YYYY');
      item.setValue({
        from: oldValue[0],
        to: value.toUTCString(),
      });
    }
  }

  changeCheckbox(item: RmFilter.Checkbox): void {
    item.setValue(!item.value());
  }

  changeDate(evt: MatDatepickerInputEvent<Date>, item: RmFilter.InputDate) {
    const value = evt.value;
    this.focusInputDate = format(value, 'DD/MM/YYYY');
    this.draw();
    item.setValue(value);
  }

  cleanDate(
    evt: MouseEvent,
    picker: MatDateRangePicker<unknown>,
    input: HTMLInputElement,
    item: RmFilter.InputDate
  ) {
    evt.stopPropagation();

    try {
      (picker as any)._model.selection = '';
    } catch {}

    input.value = '';
    item.setValue(null);
  }

  changeInputRange(evt: KeyboardEvent, item: RmFilter.InputRange) {
    try {
      const value = (evt.target as HTMLTextAreaElement).value;
      const storage = item.value();

      storage.value = +value;

      item.setValue(storage);
    } catch (err) {}
  }

  changeCheckboxInputRange(
    evt: MouseEvent,
    item: RmFilter.InputRange,
    order: '<' | '>' | '<=' | '=' | '>='
  ) {
    const storage = item.value();

    storage.order = storage.order !== order ? order : null;

    item.setValue(storage);

    evt.stopPropagation();
    evt.preventDefault();
  }

  changeInputItem(evt: KeyboardEvent, input: RmFilter.Input) {
    input.setValue((evt.target as HTMLTextAreaElement).value);
  }

  private patchParams() {
    this.source = this.data.source;
    this.draw();

    this.patchHourValues();
    this.patchDateRange();
  }

  private patchHourValues() {
    const hour = this.data.source
      .get()
      .find((el) => el instanceof RmFilter.Hour);

    if (hour && hour instanceof RmFilter.Hour && !hour.isEmpty()) {
      const [first, second] = hour.getValue()[0];
      const inputValue = document.getElementById(first) as HTMLInputElement;

      inputValue.value = second.toString();
      this.setInputFocus(first);
      this.draw();
    }
  }

  private patchDateRange() {
    const ranges = this.data.source
      .get()
      .filter((el) => el instanceof RmFilter.RangeDate);

    for (const range of ranges) {
      if (range && range instanceof RmFilter.RangeDate && !range.isEmpty()) {
        this.focusRangeInputStartValue = format(
          range.getValue()[0],
          'DD/MM/YYYY'
        );
        this.focusRangeInputEndValue = format(
          range.getValue()[1],
          'DD/MM/YYYY'
        );
        this.draw();
      }
    }
  }

  getItemClassCheckBox(baseClass: string, item: RmFilter.Checkbox) {
    return item.class != null
      ? item.class + (item.value() == true ? ' selected' : '')
      : baseClass;
  }

  private draw() {
    this.cdRef.detectChanges();
  }
}
