import { ComponentFactoryResolver, ComponentRef, Directive, ElementRef, HostListener, Input, ViewContainerRef } from '@angular/core';
import { TooltipComponent, tooltipMessage } from './tooltip.component';
import { ModalMobileInfoComponent } from '@web-frontend/shared/components/modal-mobile-info';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[tooltipDir]'
})
export class TooltipDirective {
  tooltipContentUrl: string = './tooltip.html'; // Ruta relativa del archivo HTML del tooltip

  tooltipComponentRef: ComponentRef<TooltipComponent>;
  offsetX = 10; // Ajuste para la posición horizontal del tooltip respecto al cursor
  offsetY = 20; // Ajuste para la posición vertical del tooltip respecto al cursor

  constructor(
    private el: ElementRef, 
    private resolver: ComponentFactoryResolver, 
    private viewContainerRef: ViewContainerRef,
    public dialog: MatDialog,
    public i18n: TranslateService
  ) {
  }

  @Input() 
  tooltipMove: boolean = false;

  messages: tooltipMessage[] = [];
  
  @Input()
  set tooltipMessages (tooltipMessages: (string | tooltipMessage)[]){
    this.messages = tooltipMessages.map((message) => {
      if (typeof message === 'string'){
        return { type: 'text', content: message };
      }else{
        return message;
      }
    });
  }

  @Input()
  tooltipOnMobile: 'tooltip' | 'modal' = 'modal';

  @Input()
  tooltipPosition: 'mouse' | 'bottom' | 'right' = 'mouse';

  @Input()
  tooltipShowOn: 'mouseenter' | 'click' | 'any' = 'mouseenter';

  @Input()
  tooltipHideOn: 'mouseleave' | 'click' | 'any' = 'any';

  @Input()
  tooltipBackground: 'black' | 'white' | 'blue'  = 'black';

  isTooltipVisible: boolean = false;

  @HostListener('mouseenter', ['$event']) onMouseEnter(event: MouseEvent) {
    const isMobile = window.screen.width < 768;
    if (['mouseenter', 'any'].includes(this.tooltipShowOn) && !this.isTooltipVisible && !isMobile){
      this.showTooltip(event.clientX, event.clientY);
    }
  }

  @HostListener('mouseleave', ['$event']) onMouseLeave(event: MouseEvent) {
    if (['mouseleave', 'any'].includes(this.tooltipHideOn)){
      const isInsideTooltip = this.tooltipComponentRef?.location.nativeElement.contains(event.relatedTarget);
      if (!isInsideTooltip) {
        this.hideTooltip();
      }
    }
  }

  @HostListener('click', ['$event']) onMouseClick(event: MouseEvent) {
    event.stopPropagation();
    const isMobile = window.screen.width < 768;

    if (this.isTooltipVisible){
      return;
    }

    if (!isMobile || this.tooltipOnMobile === 'tooltip'){
      if (['click', 'any'].includes(this.tooltipShowOn)){
        this.showTooltip(event?.clientX, event?.clientY);
      }
    }else{
      const dialog = this.dialog.open(ModalMobileInfoComponent, {
        data: {
          messages: this.messages,
          buttonText: 'kanban.dialog.confirmButton',
        },
      });
    }
    
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
    const isInsideTooltip = this.tooltipComponentRef?.location.nativeElement.contains(event.relatedTarget);
    
    if (isInsideTooltip || this.el.nativeElement.contains(event.target)) {
      return;
    }
    this.hideTooltip();
  }

  @HostListener('mousemove', ['$event']) onMouseMove(event: MouseEvent) {
    if (this.tooltipMove) {
      this.updateTooltipPosition(event.clientX, event.clientY);
    }
  }

  private showTooltip(mouseX, mouseY) {
    this.isTooltipVisible = true;
    const tooltipFactory = this.resolver.resolveComponentFactory(TooltipComponent);
    this.tooltipComponentRef = this.viewContainerRef.createComponent(tooltipFactory);

    this.tooltipComponentRef.instance.background = this.tooltipBackground;
    this.tooltipComponentRef.instance.messages = this.messages;
    this.tooltipComponentRef.instance.position = this.tooltipPosition;
    this.tooltipComponentRef.instance.onMouseLeave.subscribe(() => {
      this.hideTooltip();
    });
  }

  private hideTooltip() {
    this.isTooltipVisible = false;
    if (this.tooltipComponentRef) {
      this.tooltipComponentRef.destroy();
    }
  }

  private updateTooltipPosition(x: number, y: number) {
    if (this.tooltipComponentRef) {
      this.tooltipComponentRef.instance.setPosition(x + this.offsetX, y + this.offsetY) ;
    }
  }
}
