import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { SnackbarType } from '@web-frontend/shared/services/snackbar/utils/snackbar-config';

@Component({
  selector: 'roma-basic-snackbar',
  templateUrl: './basic-snackbar.component.html',
  styleUrls: ['./basic-snackbar.component.scss'],
})
export class BasicSnackbarComponent implements OnInit {
  @Input()
  message = '';

  @Input()
  type: SnackbarType;

  @Input()
  icon = '';

  @Input()
  displaySnackbar = false;

  constructor() {}

  ngOnInit() {
    this.icon = `assets/icons/${this.icon}`;
  }

  close() {
    this.displaySnackbar = false;
  }
}
