<roma-modal-template
  [title]="title"
  [showSuccessBtn]="false"
  [showCancelBtn]="false"
  (cancel)="close()"
  (success)="ok()"
>
  <mat-chip-list *ngIf="type === 'info'">
    <mat-chip *ngFor="let item of elements">
      {{ item }}
    </mat-chip>
  </mat-chip-list>

  <div class="image-element" *ngIf="type === 'image'">
    <img
    width="340px"
    height="340px"
    [src]="images[0].img | image: images[0].type"
    />
  </div>
</roma-modal-template>
