export enum NotificationGlobalEvent {
  ChangeBell = 'CN_C',
  ClientEnter = 'C_ENTER',
  ClientLeave = 'C_LEAVE',
  ModulesChange = 'M_CHANGE',
  RefreshPermissions = 'R_PERMISSIONS',
  RefreshStripeStatusAccount = 'R_STRIPE_STATUS_ACCOUNT',
}

export const els = [
  'NEW_MESSAGE',
  'DEAL_UPDATED_STATUS',
  'NEW_BIDDING_RECEIVED',
  'BIDDING_ACCEPTED',
  'DEAL_PENDING_APPROVAL',
  'DEAL_CANCELLED',
  'DEAL_ASSIGNED',
  'NEW_ACTIVITY_ASSIGN',
  'NEW_EXPENSE_ASSIGN',
  'NEW_TASK_ASSIGN',
  'CHANGE_ADDRESS',
  'CHANGE_INIT_DATE',
  'CHANGE_INVOICE',
  'ADD_NOTE',
  'NEW_DEAL_IN_ZONE',
  'ACTIVITY_UPDATED',
  'REQUEST_SUBMIT_CURRENT_POSITION',
  'NEW_BUDGET_CREATED',
];
export enum NotificationEventType {
  NewMessage = 'NEW_MESSAGE',
  DealUpdatedStatus = 'DEAL_UPDATED_STATUS',
  NewBiddingReceived = 'NEW_BIDDING_RECEIVED',
  BiddingAccepted = 'BIDDING_ACCEPTED',
  DealPendingApproval = 'DEAL_PENDING_APPROVAL',
  DealCancelled = 'DEAL_CANCELLED',
  DealAssigned = 'DEAL_ASSIGNED',
  NewActivityAssign = 'NEW_ACTIVITY_ASSIGN',
  NewAExpenseAssign = 'NEW_EXPENSE_ASSIGN',
  NewTaskAssign = 'NEW_TASK_ASSIGN',
  ChangeAddress = 'CHANGE_ADDRESS',
  ChangeInitDate = 'CHANGE_INIT_DATE',
  ChangeInvoice = 'CHANGE_INVOICE',
  AddNote = 'ADD_NOTE',
  NewDealInZone = 'NEW_DEAL_IN_ZONE',
  ActivityUpdated = 'ACTIVITY_UPDATED',
  RequestSubmitCurrentPosition = 'REQUEST_SUBMIT_CURRENT_POSITION',
  DealOwnerAssigned = 'DEAL_OWNER_ASSIGNED',
}
export interface NotificationSchema {
  _id: string;
  to: string[];
  type: NotificationEventType;
  readBy: string[];
  createdAt: Date;
  payload: Record<string, any>;
  metadata?: NotificationSchemaMetadata;
  customer?: any;
  sector?: any;
}

export interface NotificationSchemaMetadata {
  linkGoToTaclia: string;
}

export const NotificationSchemaMetadataDefault = (): NotificationSchemaMetadata => {
  return {
    linkGoToTaclia: 'https://clock.taclia.com',
  };
};

export interface NotificationSchemaExtended extends NotificationSchemaMock {
  toUsers: Array<{
    _id: string;
    email: string;
    phone: string;
    fcmToken: string;
  }>;
}

export interface NotificationSchemaMock extends NotificationSchema {
  title: string;
  description: string;
  date: string;
}

export type NotificationUpdateDto = Partial<NotificationSchema>;

export type NotificationCreateDto = Omit<
  NotificationSchema,
  'createdAt' | 'readBy' | '_id'
>;
export interface CheckAsReadNotification {
  id: string;
}

export enum NotificationUserCase {
  Enter,
  Leave,
  Changed,
}
