import { format } from 'date-fns';

export const dateAndHourToTimestamp = (date: string, hour?: string): number => {
  const data = new Date(date);
  if (hour) {
    const hourSplitted = hour.split(':');
    data.setHours(
      parseInt(hourSplitted[0]),
      parseInt(hourSplitted[1]),
      hourSplitted[2] ? parseInt(hourSplitted[2]) : null
    );
  }
  return data.getTime();
};

export const timestampToDate = (
  timestamp: string | number,
  separator = '/',
  reverseOrder = false
): string => {
  const date = new Date(timestamp);
  const formatter = reverseOrder
    ? `YYYY${separator}MM${separator}DD`
    : `DD${separator}MM${separator}YYYY`;
  return format(date, formatter);
};

export const timestampToDayAndMonth = (time: number, short = true): string => {
  return new Date(time).toLocaleString('default', {
    day: '2-digit',
    month: short ? 'short' : 'long',
  });
};

export const timestampToHour = (
  timestamp: string | number,
  ampm = false
): string => {
  const date = new Date(timestamp);
  const hour = format(date, ampm ? 'hh' : 'HH');
  const minutes = format(date, ampm ? 'mm a' : 'mm');
  return `${hour}:${minutes}`;
};
