import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'roma-modal-vendor',
  templateUrl: './modal-assets.component.html',
  styleUrls: ['./modal-assets.component.scss'],
})
export class ModalAssetsComponent implements OnInit {
  title = '';

  @Input()
  assets: any;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  ok() {
    this.activeModal.close();
  }
}
