import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { interval as observableInterval } from 'rxjs';
import { scan, takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'roma-mobile-modal-list',
  templateUrl: './mobile-modal-list.component.html',
  styleUrls: ['./mobile-modal-list.component.scss'],
})
export class MobileModalListComponent implements OnInit {
  @ViewChild('itemsList', { read: ElementRef })
  itemsList!: ElementRef<HTMLElement>;
  @Input() titleText = '';
  @Input() type: 'SINGLE' | 'MULTIPLE' = 'SINGLE';
  @Input() isOpen = false;
  @Input() isLoading = false;
  @Input() showSelectedItems = true;
  @Output() onClose = new EventEmitter();
  @Input() set items(res) {
    if (res.length) {
      this._items = res;
    }
  }
  _items = [];
  @Input() selectedItems = [];
  @Input()
  addButtonText = 'general.add';
  @Input()
  showAddButton = false;
  @Output()
  changeItems = new EventEmitter();
  _searchValue;
  @Output()
  addElement = new EventEmitter<MouseEvent>();

  constructor() {}

  ngOnInit(): void {}

  selectItem(item) {
    if (this.selectedItems.filter((e) => e.value === item.value).length > 0) {
      this.selectedItems = this.selectedItems.filter(
        (e) => ![item].includes(e)
      );
    } else {
      this.selectedItems.push(item);
      if (this.type === 'SINGLE') {
        this.onClose.next();
      }
    }
    this.changeItems.next(this.selectedItems);
  }

  checkSelectedItem(item) {
    return this.selectedItems.filter((e) => e.value === item.value).length > 0;
  }

  onKeySearch($event) {
    this._searchValue = $event.target.value;
  }

  addElementHandle(evt: MouseEvent) {
    if (evt) {
      this.addElement.next(evt);
    }
  }

  scrollToTop() {
    const el = this.itemsList.nativeElement;
    const duration = 500;
    const interval = 5;
    const move = (el.scrollTop * interval) / duration;
    observableInterval(interval)
      .pipe(
        scan((acc, curr) => acc - move, el.scrollTop),
        tap((position) => (el.scrollTop = position)),
        takeWhile((val) => val > 0)
      )
      .subscribe();
  }
}
