<h1>Reembolsos</h1>

<div>
  <form
    *ngIf="!refund; else refundResult"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    class="form-group mx-auto"
    style="max-width: 35%"
  >
    <label for="ticketId" class="mt-3">Código de ticket: </label>
    <input
      id="ticketId"
      type="text"
      formControlName="ticketId"
      class="form-control"
    />

    <div class="d-flex justify-content-center">
      <button
        *ngIf="!isLoading; else spinner"
        type="submit"
        class="roma-button h-50 mt-3 mx-3"
        [disabled]="isLoading"
        [ngClass]="{ 'disable-button': isLoading }"
      >
        Generar reembolso
      </button>

      <ng-template #spinner>
        <mat-progress-spinner
          *ngIf="isLoading"
          class="loading-spinner mt-3"
          mode="indeterminate"
          [diameter]="20"
        ></mat-progress-spinner>
      </ng-template>
    </div>
  </form>

  <ng-template #refundResult>
    <pre>
      {{ refund | json }}
    </pre>
  </ng-template>
</div>

<hr />
<div class="space"></div>
<div class="row col-3">
  <button class="roma-button h-50" (click)="back()">Volver</button>
</div>
