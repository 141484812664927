import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { App, IEquipment, PaginateResponse } from '@tacliatech/types';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EquipmentService implements OnDestroy {
  private sub$ = new Subscription();

  private equipmentsCache = new BehaviorSubject<
    App.ResourceCached<IEquipment[]>
  >(['INIT', []]);

  equipments$ = this.equipmentsCache.asObservable();

  public get equipments() {
    return this.equipmentsCache.value[1];
  }

  init() {
    this.equipmentsCache.next(['LOADING', []]);
    this.sub$.add(
      this.search({ applyPaginate: false }).subscribe((res) => {
        this.equipmentsCache.next(['LOADED', res.docs]);
      })
    );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  findAll() {
    return this.http.get<IEquipment[]>(`:API_URL/equipments/`);
  }

  constructor(private http: HttpClient) {}

  search(query: { [key: string]: any } = {}) {
    const params = new HttpParams({
      fromObject: {
        ...query,
      },
    });

    return this.http.get<PaginateResponse<IEquipment[]>>(
      `:API_URL/search/equipments`,
      {
        params,
      }
    );
  }

  create(data: { [key: string]: unknown } = {}) {
    return this.http.post<unknown>(`:API_URL/equipments`, { ...data });
  }

  updateOne(id: string, data: { [key: string]: unknown } = {}) {
    return this.http.put<IEquipment>(`:API_URL/equipments/${id}`, { ...data });
  }

  findOne(id: string) {
    return this.http.get<IEquipment>(`:API_URL/equipments/${id}`);
  }

  deleteOne(id: string) {
    return this.http.delete<IEquipment>(`:API_URL/equipments/${id}`);
  }

  findEquipmentsByCompany(id: string) {
    return this.http.get<IEquipment[]>(`:API_URL/company/${id}/equipments`);
  }

  //
  findAllEquipmentsByCompany_SELECT() {
    return this.http.get<IEquipment[]>(`:API_URL/equipments/company/select`);
  }

  restoreMany(data) {
    return this.http.post(`:API_URL/equipments/restore-many`, data);
  }

  deleteMany(data) {
    return this.http.post(`:API_URL/equipments/delete-many`, data);
  }
}
