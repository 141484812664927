import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { geocodeResponse } from '../types/geocode-response.type';

@Injectable()
export class GeocodeApiService {
  private API_KEY_MAP!: string;

  constructor(private httpClient: HttpClient) {
    if (Capacitor.getPlatform() !== 'web') {
      this.API_KEY_MAP = environment.apiKeyMapAndroid;
    } else {
      this.API_KEY_MAP = environment.apiKeyMapWeb;
    }
  }

  public getAddressGeocodeInfo(address: string): Observable<geocodeResponse> {
    return this.httpClient.get<geocodeResponse>(
      `https://maps.googleapis.com/maps/api/geocode/json?key=${
        this.API_KEY_MAP
      }&address=${encodeURIComponent(address)}`
    );
  }
}
