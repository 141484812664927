import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Feature, MembershipSchema } from '@tacliatech/types';
import { SandboxService, StorageService } from '@web-frontend/shared/services';
import { filter, take } from 'rxjs/operators';

import { environment } from '@web-frontend/environments';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { BuyModalComponent } from './buy-modal/buy-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ProFeatureService {
  constructor(
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private sandBoxService: SandboxService,
    private toastService: ToastService
  ) {}

  open(feature: Feature.Purchasable) {
    this.sandBoxService.screenBusChannel$
      .pipe(
        filter((res) => !!res),
        take(1)
      )
      .subscribe((res) => {
        this.openWeb(feature);
      });
  }

  openUser(erroText: string) {
    this.toastService.show({
      text: erroText,
      type: 'error',
      msDuration: 5000,
    });
  }

  private openWeb(feature: Feature.Purchasable) {
    const dialogRef = this.dialog.open<BuyModalComponent>(BuyModalComponent, {
      data: {
        feature,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  get userLanguage(): string {
    return StorageService.GetItem('USER_LANG');
  }
}
