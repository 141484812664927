import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Input,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GenericComponent } from '@web-frontend/shared/class';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class GenericModalService {
  private refreshListSource = new Subject<boolean>();

  componentRef: ComponentRef<any>;

  refreshList$ = this.refreshListSource.asObservable();

  constructor(
    private dialog: MatDialog,
    private resolver: ComponentFactoryResolver
  ) {}

  open(component?: any, config?: MatDialogConfig) {
    const dialogRef = this.dialog.open(component, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }
  /*


  constructor(
    ...
    private resolver: ComponentFactoryResolver,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(this.data.component);
    this.componentRef = this.vcRef.createComponent(factory);
  }


  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    } */
}
