import { DealType } from '../enum';
import { IStatus } from '../interfaces';

export const DealTypes = [
  {
    id: DealType.Team,
    name: 'Team',
  },
  {
    id: DealType.Vendor,
    name: 'Vendor',
  },
];

export const ToKeyValue = (type: { id: string; name: string }) => {
  return {
    key: type.name,
    value: type.id,
  };
};

export const ToKeyValueStatus = (type: IStatus) => {
  return {
    key: type.translate,
    value: type.id,
  };
};
