import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const URL_MS_SALES = 'sales';

@Injectable({
  providedIn: 'root',
})
export class MsSalesApiService {
  constructor(private readonly http: HttpClient) {}

  post<T>(url: string, body: Record<string, any>): Promise<T> {
    return this.http
      .post<T>(`:API_BASE_URL${URL_MS_SALES}${url}`, body)
      .toPromise();
  }

  postResponse<T>(
    url: string,
    body: Record<string, any>,
    options: {
      observe: 'response';
    }
  ): Promise<any> {
    return this.http
      .post<T>(`:API_BASE_URL${URL_MS_SALES}${url}`, body, options)
      .toPromise();
  }

  put<T>(url: string, body: Record<string, any>): Promise<T> {
    return this.http
      .put<T>(`:API_BASE_URL${URL_MS_SALES}${url}`, body)
      .toPromise();
  }

  patch<T>(url: string, body: Record<string, any>): Promise<T> {
    return this.http
      .patch<T>(`:API_BASE_URL${URL_MS_SALES}${url}`, body)
      .toPromise();
  }

  delete<T>(url: string): Promise<T> {
    return this.http
      .delete<T>(`:API_BASE_URL${URL_MS_SALES}${url}`)
      .toPromise();
  }

  get<T>(
    url: string,
    options?: {
      headers?: {
        [header: string]: string | string[];
      };
      params?: {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>;
      };
      responseType?: 'json';
    }
  ): Promise<T> {
    return this.http
      .get<T>(`:API_BASE_URL${URL_MS_SALES}${url}`, options)
      .toPromise();
  }

  getResponse(
    url: string,
    options?: {
      headers?: {
        [header: string]: string | string[];
      };
      params?: {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>;
      };
      observe: 'response';
      responseType?: 'json';
    }
  ): Promise<any> {
    return this.http
      .get(`:API_BASE_URL${URL_MS_SALES}${url}`, options)
      .toPromise();
  }
}
