import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyModuleService } from '@web-frontend/shared/services/company';
import { ActiveModalModule } from './active-modal-module.types';
import { FeatureUser } from '@tacliatech/types';

@Component({
  selector: 'roma-active-modal-module',
  templateUrl: './active-modal-module.component.html',
  styleUrls: ['./active-modal-module.component.scss'],
})
export class ActiveModalModuleComponent implements OnInit {
  public innerWidth = 0;
  isResponsive = false;
  isResponsive1110 = false;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 768) {
      this.isResponsive = true;
    } else {
      this.isResponsive = false;
    }
  }

  title: string;
  content: string;
  featureUserRef = FeatureUser;

  isLoading = false;
  constructor(
    private companyModuleService: CompanyModuleService,
    private dialogRef: MatDialogRef<
      ActiveModalModuleComponent,
      ActiveModalModule.Result
    >,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  get params() {
    return this.data;
  }

  ngOnInit(): void {
    this.onResize();
    this.title = this.data.title;
    this.content = this.data.content;
  }

  confirmActive() {
    this.isLoading = true;
    this.isLoading = false;
    this.dialogRef.close({
      complete: true,
    });
  }

  close() {
    this.dialogRef.close({
      complete: false,
    });
  }
}
