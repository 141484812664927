export namespace AmplitideNs {
  export class CompanyRegisterFields {
    constructor(
      company_register_week,
      company_register_month,
      company_register_year,
      company_register_date
    ) {
      this.company_register_week = company_register_week;
      this.company_register_year = company_register_year;
      this.company_register_year = company_register_year;
      this.company_register_date = company_register_date;
    }
    company_register_week: number;
    // @ts-ignore
    company_register_month: number;
    company_register_year: number;
    company_register_date: string;
  }

  export class UserRegisterFields {
    constructor(
      user_register_week,
      user_register_month,
      user_register_year,
      user_register_date
    ) {
      this.user_register_week = user_register_week;
      this.user_register_month = user_register_month;
      this.user_register_year = user_register_year;
      this.user_register_date = user_register_date;
    }
    user_register_week: number;
    user_register_month: number;
    user_register_year: number;
    user_register_date: string;
  }

  export interface RegisterFields {
    registerWeek: number;
    registerMonth: number;
    registerYear: number;
    registerDate: string;
  }
}
