import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

import { ModalTemplateModule } from '../modal-template';
import { TranslateModule } from '@ngx-translate/core';
import { ModalMapRequestLocationComponent } from './modal-map-request-location.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BaseButtonModule } from '@web-frontend/common/components/button/baseButton.module';

@NgModule({
  declarations: [ModalMapRequestLocationComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    ModalTemplateModule,
    GoogleMapsModule,
    TranslateModule.forChild(),
    MatProgressSpinnerModule,
    BaseButtonModule,
  ],
  exports: [ModalMapRequestLocationComponent],
})
export class ModalMapRequestLocationModule {}
