import { Component, Input, OnInit } from '@angular/core';
import { Deal } from '@tacliatech/types';
import { CreateActivityService } from '../../create-activity';

@Component({
  selector: 'roma-wrapper-tasks',
  templateUrl: './wrapper-tasks.component.html',
  styleUrls: ['./wrapper-tasks.component.scss'],
  providers: [CreateActivityService],
})
export class WrapperTasksComponent implements OnInit {
  @Input()
  dealData: Deal.Output;

  @Input()
  isLoading = false;

  @Input()
  type: 'ADD' | 'EDIT' = 'ADD';

  ngOnInit(): void {}
}
