import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Feature } from '@tacliatech/types';
import { SharePaymentLinkModalService } from './share-payment-link-modal.service';
import { SandboxService } from '@web-frontend/shared/services';

@Component({
  selector: 'roma-share-payment-link-modal',
  templateUrl: './share-payment-link-modal.component.html',
  styleUrls: ['./share-payment-link-modal.component.scss'],
})
export class SharePaymentLinkModalComponent {
  featureRef = Feature;
  resize$ = this.sandBoxService.screenBusChannel$;

  constructor(
    private dialogRef: MatDialogRef<SharePaymentLinkModalComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: { selectedDeal },
    private changeDetectorRef: ChangeDetectorRef,
    public sharePaymentLinkModalService: SharePaymentLinkModalService,
    private sandBoxService: SandboxService
  ) {}

  ngOnInit(): void {}

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  close(params = null) {
    this.dialogRef.close(params);
  }

  submit() {
    this.close();
  }

  // sendSaleEmail() {
  //   this.sharePaymentLinkModalService.sendSaleEmail()
  //   // this.sendEmailSaleModalService
  //   //   .open({
  //   //     data: {
  //   //       idSale: this.idSale,
  //   //       saleStorage: this.saleStorageService.getSale(),
  //   //     },
  //   //   })
  //   //   .subscribe((res) => {
  //   //     this.draw();
  //   //   });
  // }
}
