import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersListResumeComponent } from './users-list-resume.component';
import { ModalTemplateModule } from '../modal-template';
import { ImageModule } from '@web-frontend/shared/pipes/image';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [UsersListResumeComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    TranslateModule.forChild(),
    MatProgressSpinnerModule,
    ImageModule.forRoot(),
  ],
  exports: [UsersListResumeComponent],
})
export class UsersListResumeModule {}
