<div class="list-dropdown-select">
  <!-- desktop-display -->
  <ng-container *ngIf="!isResponsive">
    <div class="desktop-display">
      <div class="status-dropdown">
        <div
          *ngIf="activeItems === null"
          class="active-item"
          [matMenuTriggerFor]="statusMenu"
        >
          {{ 'general.notAssigned' | translate }}
          <img class="clean-input" src="assets/icons/gl_arrow-down.svg" />
        </div>

        <ng-container *ngIf="activeItems">
          <roma-rm-badge
            [statusName]="selectedItem?.title"
            [color]="selectedItem?.color"
            [matMenuTriggerFor]="statusMenu"
          ></roma-rm-badge>
        </ng-container>

        <mat-menu #statusMenu="matMenu" class="dropdown-wrapper">
          <div *ngIf="isLoading" style="text-align: center; width: 100%">
            <mat-progress-spinner
              mode="indeterminate"
              [diameter]="25"
              style="margin: auto"
            ></mat-progress-spinner>
          </div>
          <div class="list-wrapper" *ngIf="!isLoading">
            <div
              class="list-item"
              *ngFor="let status of items"
              [ngClass]="{ active: activeItems === status.value }"
              (click)="changeSelect(status)"
            >
              <img
                *ngIf="activeItems === status.value"
                class="active-check"
                src="assets/icons/gl_check.svg"
              />
              <roma-rm-badge
                [statusName]="status?.title"
                [color]="status?.color"
              ></roma-rm-badge>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </ng-container>
  <!-- mobile display -->
  <ng-container *ngIf="isResponsive">
    <div class="mobile-display">
      <div class="status-dropdown">
        <div
          *ngIf="activeItems === null"
          class="active-item"
          (click)="menuIsOpen = !menuIsOpen"
        >
          {{ 'general.notAssigned' | translate }}
          <img class="clean-input" src="assets/icons/gl_arrow-down.svg" />
        </div>

        <ng-container *ngIf="activeItems">
          <roma-rm-badge
            [statusName]="selectedItem?.title"
            [color]="selectedItem?.color"
            (click)="menuIsOpen = !menuIsOpen"
          ></roma-rm-badge>
        </ng-container>
      </div>
      <div
        *ngIf="menuIsOpen"
        class="mobile-list-wrapper"
        (click)="menuIsOpen = !menuIsOpen"
      ></div>
      <div *ngIf="menuIsOpen" class="list-box">
        <h4>{{ titleText }}</h4>
        <div *ngIf="isLoading" style="text-align: center; width: 100%">
          <mat-progress-spinner
            mode="indeterminate"
            [diameter]="60"
            style="margin: 40px auto"
          ></mat-progress-spinner>
        </div>
        <div *ngIf="!isLoading" class="search-input secondary">
          <input
            #inputFilterSearch
            class="filter-input"
            type="text"
            [placeholder]="'general.search' | translate"
          />
        </div>
        <div class="list-wrapper" *ngIf="!isLoading && mode === 'SINGLE'">
          <div
            class="list-item"
            *ngFor="
              let status of items | searchFilter: filterArgs;
              let i = index
            "
            [ngClass]="{ active: activeItems === status.value }"
            (click)="changeSelect({ value: status.value, title: status.title })"
          >
            <img
              [ngStyle]="
                activeItems === status.value && {
                  visibility: 'visible'
                }
              "
              class="active-check"
              src="assets/icons/gl_check.svg"
            />
            <roma-rm-badge
              [statusName]="status?.title"
              [color]="status?.color"
            ></roma-rm-badge>
          </div>
        </div>
        <div class="text-center close-btn-wrapper">
          <img
            (click)="menuIsOpen = !menuIsOpen"
            class="close-btn"
            src="/assets/icons/btn_close.svg"
            alt="close"
          />
        </div>
      </div>
    </div>
  </ng-container>
</div>
