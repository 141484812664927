import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { Feature, FeatureUser } from '@tacliatech/types';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { ProFeatureService } from './pro-feature.service';
import { v4 as uuidv4 } from 'uuid';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';

interface featureParam {
  type: 'some' | 'every';
  features: [];
}
@Directive({
  selector: '[romaProSelect]',
})
export class ProSelectDirective implements OnInit, OnDestroy {
  @Input()
  feature!: Feature.Purchasable;

  _featureUser: Feature.Purchasable | FeatureUser.Purchasable | featureParam;
  @Input()
  set featureUser(
    value: Feature.Purchasable | FeatureUser.Purchasable | featureParam
  ) {
    this._featureUser = value;
  }

  get featureUser() {
    return this._featureUser;
  }

  @HostBinding('class.is-available')
  isAvailable!: boolean;

  @HostBinding('class.is-user-available')
  isAvailableUser = true;

  @Input() tooltip = 'permissions.errors.permissionStr'; // The text for the tooltip to display
  @Input() delay? = 190; // Optional delay input, in ms

  @Input()
  options;

  @Output()
  isAvailableFeature: EventEmitter<boolean> = new EventEmitter();

  @Output()
  isAvailableUserFeature: EventEmitter<boolean> = new EventEmitter();

  private isRendered = false;
  private idTag = uuidv4();
  private sub$ = new Subscription();
  private myPopup;
  private timer;

  constructor(
    private host: ElementRef,
    private renderer: Renderer2,
    private permissionService: PermissionService,
    private proFeatureService: ProFeatureService,
    private cdRef: ChangeDetectorRef,
    private i18n: TranslateService,
    private amplitudeService: AmplitudeService
  ) {}

  get errorMessageUser(): string {
    if (!this.featureUser) {
      return this.i18n.instant(this.tooltip);
    }

    const { featureSelected, action } = this.findFeatureAndAction();

    if (!featureSelected || !action) {
      return this.i18n.instant(this.tooltip);
    }

    let translation = this.i18n.instant('permissions.errors.permissionStr');
    translation = this.replaceTranslation(translation, 'type', featureSelected);
    translation = this.replaceTranslation(translation, 'action', action);

    return translation;
  }

  findFeatureAndAction(): { featureSelected: string; action: string } {
    const actions = ['show', 'create', 'update', 'delete'];
    let featureSelected;
    let action;

    for (const key of Object.keys(FeatureUser)) {
      if (key.endsWith('List') || !FeatureUser[key]) {
        continue;
      }

      for (const act of actions) {
        if (FeatureUser[key][act] == this.featureUser) {
          featureSelected = key.toLowerCase();
          action = act;
          break;
        }
      }
    }

    return { featureSelected, action };
  }
  replaceTranslation(
    translation: string,
    placeholder: string,
    value: string
  ): string {
    if (translation.includes(`{${placeholder}}`)) {
      translation = translation.replace(
        `{${placeholder}}`,
        this.i18n.instant(`permissions.errors.{${placeholder}}${value}`)
      );
    }
    return translation;
  }

  @HostListener('keypress', ['$event'])
  keyPress(evt: KeyboardEvent) {
    if (!this.isAvailable && this.feature) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    if (!this.isAvailableUser && this.featureUser) {
      evt.preventDefault();
      evt.stopPropagation();
    }
  }

  @HostListener('click', ['$event'])
  clickEvt(evt: MouseEvent) {
    if (!this.isAvailable && this.feature) {
      evt.preventDefault();
      evt.stopPropagation();
      evt.stopImmediatePropagation();

      this.amplitudeService.sendEvent({ event: `planPro_${this.feature}` });
      this.proFeatureService.open(this.feature);
    }

    if (!this.isAvailableUser && this.featureUser) {
      evt.preventDefault();
      evt.stopPropagation();
      this.proFeatureService.openUser(this.errorMessageUser);
    }

    return;
  }

  @HostListener('mouseenter') onMouseEnter(): void {
    if (!this.featureUser || this.isAvailableUser || !this.tooltip) {
      return;
    }
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    if (!this.featureUser || this.isAvailableUser || !this.tooltip) {
      return;
    }
    if (this.timer) clearTimeout(this.timer);
    this.myPopup?.remove();
  }

  ngOnInit(): void {
    if (this.feature) {
      this.handleFeature();
    }

    if (this.featureUser) {
      this.handleFeatureUser();
      this.isAvailableUserFeature.emit(this.isAvailableUser);
    }
  }

  handleFeature(): void {
    this.sub$.add(
      this.permissionService.hasFeatureFn(this.feature).subscribe((res) => {
        this.isAvailable = res;
        this.isAvailableFeature.emit(this.isAvailable);

        this.draw();
      })
    );
  }

  handleFeatureUser(): void {
    if (typeof this.featureUser == 'string') {
      this.sub$.add(
        this.permissionService.permissions$.subscribe((res) => {
          this.isAvailableUser = res.includes(this.featureUser.toString());
          this.draw();
        })
      );
    } else {
      const feature = this.featureUser;
      const featureCheck = this.featureUser.type == 'some' ? 'some' : 'every';
      this.sub$.add(
        this.permissionService.permissions$.subscribe((res) => {
          this.isAvailableUser = feature.features[featureCheck]((item) =>
            res.includes(item)
          );
          this.draw();
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  private draw() {
    this.cdRef.detectChanges();
  }
}
