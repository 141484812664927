/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import userflow from 'userflow.js';
import { StorageService } from '../storage';
import { IUserAttributes } from '../../interfaces/userflow';
import { CompanyService } from '../company';
import { PermissionService } from '../permissions';
import { environment } from '@web-frontend/environments';
import { PlatformService } from '@web-frontend/shared/components/platform-disable-content/platform.service';
import {
  COMPANY_DATA,
  COMPANY_ID_KEY,
  Prices,
  USER_DATA,
} from '@tacliatech/types';
import { IosPermissionService } from '../ios-permission/ios-permission.service';

@Injectable({
  providedIn: 'root',
})
export class UserflowService {
  nowDate: string;
  localCode: string;
  userBackup?: IUserAttributes;
  idBackup?: string;
  userData: any;
  companyData: any;
  customerData: any;

  constructor(
    private companyService: CompanyService,
    private permissionService: PermissionService,
    private platformService: PlatformService,
    private iosPermissions: IosPermissionService
  ) {
    this.nowDate = new Date().toISOString();
    this.localCode = navigator.language;
  }

  public init(): void {
    userflow.init(environment.userflowConfig.key);
  }

  public identifyUser(): void {
    if (!this.iosPermissions.canTrackEvents()) return;

    this.userData = JSON.parse(StorageService.GetItem(USER_DATA));
    this.companyData = JSON.parse(StorageService.GetItem(COMPANY_DATA));
    const prices: Array<Prices> = JSON.parse(
      StorageService.GetItem('companyPrices')
    );
    if (!this.userData || !this.companyData) {
      console.error(
        'User or company data not found in local storage to initialize Userflow correctly.'
      );
      return;
    }
    userflow.setResourceCenterLauncherHidden(this.platformService.isMobile());

    const activeModules: string[] = [];
    const inactiveModules: string[] = [];
    const userDataToIdentify: IUserAttributes = {
      name: this.userData?.name || '',
      email: this.userData?.email || '',
      role: this.userData?.role || '',
      locale_code: this.localCode || '',
      signed_up_at: this.nowDate || '',
      remaining_trial_days: 0,
      device_type: window.innerWidth > 800 ? 'desktop' : 'mobile',
      language: StorageService.GetItem('USER_LANG'),
      billing_period:
        prices.find((price) => price.itemPriceId === this.companyData.plans[0])
          ?.periodUnit || 'month',
      monthlyPrice:
        prices.find((price) => price.periodUnit === 'month')?.price || 1400,
      yearlyPrice:
        prices.find((price) => price.periodUnit === 'year')?.price || 16262,
    };

    const haveCompanyId = !!localStorage.getItem(COMPANY_ID_KEY);
    if (haveCompanyId) {
      this.companyService
        .findOne(localStorage.getItem(COMPANY_ID_KEY))
        .toPromise()
        .then((res) => {
          if (res.modules) {
            res.modules.forEach((element) => {
              if (element.active) {
                activeModules.push(element.id);
              } else {
                inactiveModules.push(element.id);
              }
              userDataToIdentify.active_modules = activeModules;
              userDataToIdentify.inactive_modules = inactiveModules;

              this.checkTrialSubscription(userDataToIdentify);
            });
          }
        });
    }
  }

  private async checkTrialSubscription(userData: IUserAttributes) {
    const res = await this.permissionService.checkTrialSubscription(
      localStorage.getItem(COMPANY_ID_KEY),
      'taclia'
    );

    if (res.remainingDays > 0) {
      userData.plan = 'trial';
      userData.remaining_trial_days = res.remainingDays;
    } else {
      if (res.proPlanActivated) {
        userData.plan = 'pro';
      } else {
        userData.plan = 'free';
      }
    }

    this.userBackup = userData;
    this.idBackup = this.userData.id;
    userflow.identify(this.userData.id, userData as any);
  }

  public async trackEvent(
    eventName: string,
    eventProperties = {}
  ): Promise<void> {
    if (!this.iosPermissions.canTrackEvents()) return;
    try {
      await userflow.track(eventName, eventProperties);
    } catch (error) {
      console.warn(`Userflow track event failed`, error);
    }
  }

  public sendEvent(data: { event: string }): void {
    if (!this.iosPermissions.canTrackEvents()) return;
    userflow.track(data.event);
  }

  public logout(): void {
    if (userflow.isIdentified()) {
      localStorage.removeItem('userData');
      localStorage.removeItem('companyData');
      localStorage.removeItem('customerData');
      userflow.endAll();
      userflow.reset();
    }
  }

  public identifyUserRegister(userData: {
    id: string;
    email: string;
    country: string;
  }): void {
    userflow
      .identify(userData.id, {
        email: userData.email,
        company_country: userData.country,
        device_type: window.innerWidth > 800 ? 'desktop' : 'mobile',
      })
      .then(() => {
        this.sendEvent({ event: 'register_save' });
      })
      .catch();
  }

  public identifyUserWithId(userId: string): void {
    if (userId) {
      userflow.identify(userId);
    }
  }

  public setCustomAttributes(data: { name: string; value: any }): void {
    userflow.updateUser({
      [data.name]: data.value,
    });
  }
}
