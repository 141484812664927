<div class="total-summary table-total md" style="float: left">
  <div class="total-summary-title">
    {{ totalResults || 0 }} {{ 'general.results' | translate }}
    <a
      *ngIf="showSummary"
      class="dropdown-button mobile-hidden"
      (click)="toggleDropdown()"
    >
      {{ 'budget.seeDropdown' | translate }}
      <mat-icon
        svgIcon="arrow_down_icon"
        class="roma-icon material-icons arrow-icon"
        [ngClass]="{ 'arrow-up': dropdownOpen }"
      ></mat-icon>
    </a>
    <div *ngIf="!showSummary" class="total-badge">
      <span class="total">{{
        'budgets.settings.create.form.lbtotal' | translate
      }}</span>
      <span class="number">
        {{ totalAmount | currencyFormat: 'currencyPosition' }}
      </span>
    </div>
  </div>
  <div
    class="total-summary-dropdown mobile-hidden"
    *ngIf="dropdownOpen && showSummary"
  >
    <div class="total-badge">
      <span class="total">{{
        'budgets.settings.create.form.lbtotal' | translate
      }}</span>
      <span class="number">
        {{ totalAmount | currencyFormat: 'currencyPosition' }}
      </span>
    </div>
    <div *ngFor="let status of statusSummary">
      <div
        class="total-badge"
        [ngStyle]="{ backgroundColor: status.background }"
      >
        <span class="total">{{ status.label }}</span>
        <span [ngStyle]="{ color: status.color }" class="number">
          {{ status.value | currencyFormat: 'currencyPosition' }}
        </span>
      </div>
    </div>
  </div>
</div>
