export const countryCodesES = [
  {
    name: 'Afganistán',
    dial_code: '+93',
    code: 'AF',
  },
  {
    name: 'Islas Åland',
    dial_code: '+35818',
    code: 'AX',
  },
  {
    name: 'Albania',
    dial_code: '+355',
    code: 'AL',
  },
  {
    name: 'Argelia',
    dial_code: '+213',
    code: 'DZ',
  },
  {
    name: 'Samoa Americana',
    dial_code: '+1684',
    code: 'AS',
  },
  {
    name: 'Andorra',
    dial_code: '+376',
    code: 'AD',
  },
  {
    name: 'Angola',
    dial_code: '+244',
    code: 'AO',
  },
  {
    name: 'Anguila',
    dial_code: '+1264',
    code: 'AI',
  },
  {
    name: 'Antártida',
    dial_code: '+672',
    code: 'AQ',
  },
  {
    name: 'Antigua y Barbuda',
    dial_code: '+1268',
    code: 'AG',
  },
  {
    name: 'Argentina',
    dial_code: '+54',
    code: 'AR',
  },
  {
    name: 'Armenia',
    dial_code: '+374',
    code: 'AM',
  },
  {
    name: 'Aruba',
    dial_code: '+297',
    code: 'AW',
  },
  {
    name: 'Australia',
    dial_code: '+61',
    code: 'AU',
  },
  {
    name: 'Austria',
    dial_code: '+43',
    code: 'AT',
  },
  {
    name: 'Azerbaiyán',
    dial_code: '+994',
    code: 'AZ',
  },
  {
    name: 'Bahamas',
    dial_code: '+1242',
    code: 'BS',
  },
  {
    name: 'Baréin',
    dial_code: '+973',
    code: 'BH',
  },
  {
    name: 'Bangladesh',
    dial_code: '+880',
    code: 'BD',
  },
  {
    name: 'Barbados',
    dial_code: '+1246',
    code: 'BB',
  },
  {
    name: 'Bielorrusia',
    dial_code: '+375',
    code: 'BY',
  },
  {
    name: 'Bélgica',
    dial_code: '+32',
    code: 'BE',
  },
  {
    name: 'Belice',
    dial_code: '+501',
    code: 'BZ',
  },
  {
    name: 'Benín',
    dial_code: '+229',
    code: 'BJ',
  },
  {
    name: 'Bermudas',
    dial_code: '+1441',
    code: 'BM',
  },
  {
    name: 'Bután',
    dial_code: '+975',
    code: 'BT',
  },
  {
    name: 'Bolivia',
    dial_code: '+591',
    code: 'BO',
  },
  {
    name: 'Bosnia y Herzegovina',
    dial_code: '+387',
    code: 'BA',
  },
  {
    name: 'Botsuana',
    dial_code: '+267',
    code: 'BW',
  },
  {
    name: 'Brasil',
    dial_code: '+55',
    code: 'BR',
  },
  {
    name: 'Territorio Británico del Océano Índico',
    dial_code: '+246',
    code: 'IO',
  },
  {
    name: 'Brunei',
    dial_code: '+673',
    code: 'BN',
  },
  {
    name: 'Bulgaria',
    dial_code: '+359',
    code: 'BG',
  },
  {
    name: 'Burkina Faso',
    dial_code: '+226',
    code: 'BF',
  },
  {
    name: 'Burundi',
    dial_code: '+257',
    code: 'BI',
  },
  {
    name: 'Camboya',
    dial_code: '+855',
    code: 'KH',
  },
  {
    name: 'Camerún',
    dial_code: '+237',
    code: 'CM',
  },
  {
    name: 'Canadá',
    dial_code: '+1',
    code: 'CA',
  },
  {
    name: 'Cabo Verde',
    dial_code: '+238',
    code: 'CV',
  },
  {
    name: 'Islas Caimán',
    dial_code: '+ 345',
    code: 'KY',
  },
  {
    name: 'República Centroafricana',
    dial_code: '+236',
    code: 'CF',
  },
  {
    name: 'Chad',
    dial_code: '+235',
    code: 'TD',
  },
  {
    name: 'Chile',
    dial_code: '+56',
    code: 'CL',
  },
  {
    name: 'China',
    dial_code: '+86',
    code: 'CN',
  },
  {
    name: 'Isla de Navidad',
    dial_code: '+61',
    code: 'CX',
  },
  {
    name: 'Islas Cocos',
    dial_code: '+61',
    code: 'CC',
  },
  {
    name: 'Colombia',
    dial_code: '+57',
    code: 'CO',
  },
  {
    name: 'Comoras',
    dial_code: '+269',
    code: 'KM',
  },
  {
    name: 'República del Congo',
    dial_code: '+242',
    code: 'CG',
  },
  {
    name: 'República Democrática del Congo',
    dial_code: '+243',
    code: 'CD',
  },
  {
    name: 'Islas Cook',
    dial_code: '+682',
    code: 'CK',
  },
  {
    name: 'Costa Rica',
    dial_code: '+506',
    code: 'CR',
  },
  {
    name: 'Costa de Marfil',
    dial_code: '+225',
    code: 'CI',
  },
  {
    name: 'Croacia',
    dial_code: '+385',
    code: 'HR',
  },
  {
    name: 'Cuba',
    dial_code: '+53',
    code: 'CU',
  },
  {
    name: 'Chipre',
    dial_code: '+357',
    code: 'CY',
  },
  {
    name: 'República Checa',
    dial_code: '+420',
    code: 'CZ',
  },
  {
    name: 'Dinamarca',
    dial_code: '+45',
    code: 'DK',
  },
  {
    name: 'Yibuti',
    dial_code: '+253',
    code: 'DJ',
  },
  {
    name: 'Dominica',
    dial_code: '+1767',
    code: 'DM',
  },
  {
    name: 'República Dominicana',
    dial_code: '+1849',
    code: 'DO',
  },
  {
    name: 'Ecuador',
    dial_code: '+593',
    code: 'EC',
  },
  {
    name: 'Egipto',
    dial_code: '+20',
    code: 'EG',
  },
  {
    name: 'El Salvador',
    dial_code: '+503',
    code: 'SV',
  },
  {
    name: 'Guinea Ecuatorial',
    dial_code: '+240',
    code: 'GQ',
  },
  {
    name: 'Eritrea',
    dial_code: '+291',
    code: 'ER',
  },
  {
    name: 'Estonia',
    dial_code: '+372',
    code: 'EE',
  },
  {
    name: 'Etiopía',
    dial_code: '+251',
    code: 'ET',
  },
  {
    name: 'Islas Malvinas',
    dial_code: '+500',
    code: 'FK',
  },
  {
    name: 'Islas Feroe',
    dial_code: '+298',
    code: 'FO',
  },
  {
    name: 'Fiyi',
    dial_code: '+679',
    code: 'FJ',
  },
  {
    name: 'Finlandia',
    dial_code: '+358',
    code: 'FI',
  },
  {
    name: 'Francia',
    dial_code: '+33',
    code: 'FR',
  },
  {
    name: 'Guayana Francesa',
    dial_code: '+594',
    code: 'GF',
  },
  {
    name: 'Polinesia Francesa',
    dial_code: '+689',
    code: 'PF',
  },
  {
    name: 'Gabón',
    dial_code: '+241',
    code: 'GA',
  },
  {
    name: 'Gambia',
    dial_code: '+220',
    code: 'GM',
  },
  {
    name: 'Georgia',
    dial_code: '+995',
    code: 'GE',
  },
  {
    name: 'Alemania',
    dial_code: '+49',
    code: 'DE',
  },
  {
    name: 'Ghana',
    dial_code: '+233',
    code: 'GH',
  },
  {
    name: 'Gibraltar',
    dial_code: '+350',
    code: 'GI',
  },
  {
    name: 'Grecia',
    dial_code: '+30',
    code: 'GR',
  },
  {
    name: 'Groenlandia',
    dial_code: '+299',
    code: 'GL',
  },
  {
    name: 'Granada',
    dial_code: '+1473',
    code: 'GD',
  },
  {
    name: 'Guadalupe',
    dial_code: '+590',
    code: 'GP',
  },
  {
    name: 'Guam',
    dial_code: '+1671',
    code: 'GU',
  },
  {
    name: 'Guatemala',
    dial_code: '+502',
    code: 'GT',
  },
  {
    name: 'Guernsey',
    dial_code: '+44',
    code: 'GG',
  },
  {
    name: 'Guinea',
    dial_code: '+224',
    code: 'GN',
  },
  {
    name: 'Guinea-Bissau',
    dial_code: '+245',
    code: 'GW',
  },
  {
    name: 'Guyana',
    dial_code: '+592',
    code: 'GY',
  },
  {
    name: 'Haití',
    dial_code: '+509',
    code: 'HT',
  },
  {
    name: 'Honduras',
    dial_code: '+504',
    code: 'HN',
  },
  {
    name: 'Hong Kong',
    dial_code: '+852',
    code: 'HK',
  },
  {
    name: 'Hungría',
    dial_code: '+36',
    code: 'HU',
  },
  {
    name: 'Islandia',
    dial_code: '+354',
    code: 'IS',
  },
  {
    name: 'India',
    dial_code: '+91',
    code: 'IN',
  },
  {
    name: 'Indonesia',
    dial_code: '+62',
    code: 'ID',
  },
  {
    name: 'Irán',
    dial_code: '+98',
    code: 'IR',
  },
  {
    name: 'Iraq',
    dial_code: '+964',
    code: 'IQ',
  },
  {
    name: 'Irlanda',
    dial_code: '+353',
    code: 'IE',
  },
  {
    name: 'Isla de Man',
    dial_code: '+44',
    code: 'IM',
  },
  {
    name: 'Israel',
    dial_code: '+972',
    code: 'IL',
  },
  {
    name: 'Italia',
    dial_code: '+39',
    code: 'IT',
  },
  {
    name: 'Jamaica',
    dial_code: '+1876',
    code: 'JM',
  },
  {
    name: 'Japon',
    dial_code: '+81',
    code: 'JP',
  },
  {
    name: 'Jersey',
    dial_code: '+44',
    code: 'JE',
  },
  {
    name: 'Jordania',
    dial_code: '+962',
    code: 'JO',
  },
  {
    name: 'Kazajistán',
    dial_code: '+77',
    code: 'KZ',
  },
  {
    name: 'Kenia',
    dial_code: '+254',
    code: 'KE',
  },
  {
    name: 'Kiribati',
    dial_code: '+686',
    code: 'KI',
  },
  {
    name: 'Corea del Norte',
    dial_code: '+850',
    code: 'KP',
  },
  {
    name: 'Corea del Sur',
    dial_code: '+82',
    code: 'KR',
  },
  {
    name: 'Kuwait',
    dial_code: '+965',
    code: 'KW',
  },
  {
    name: 'Kirguistán',
    dial_code: '+996',
    code: 'KG',
  },
  {
    name: 'Laos',
    dial_code: '+856',
    code: 'LA',
  },
  {
    name: 'Letonia',
    dial_code: '+371',
    code: 'LV',
  },
  {
    name: 'Líbano',
    dial_code: '+961',
    code: 'LB',
  },
  {
    name: 'Lesoto',
    dial_code: '+266',
    code: 'LS',
  },
  {
    name: 'Liberia',
    dial_code: '+231',
    code: 'LR',
  },
  {
    name: 'Libia',
    dial_code: '+218',
    code: 'LY',
  },
  {
    name: 'Liechtenstein',
    dial_code: '+423',
    code: 'LI',
  },
  {
    name: 'Lituania',
    dial_code: '+370',
    code: 'LT',
  },
  {
    name: 'Luxemburgo',
    dial_code: '+352',
    code: 'LU',
  },
  {
    name: 'Macao',
    dial_code: '+853',
    code: 'MO',
  },
  {
    name: 'Macedonia del Norte',
    dial_code: '+389',
    code: 'MK',
  },
  {
    name: 'Madagascar',
    dial_code: '+261',
    code: 'MG',
  },
  {
    name: 'Malaui',
    dial_code: '+265',
    code: 'MW',
  },
  {
    name: 'Malasia',
    dial_code: '+60',
    code: 'MY',
  },
  {
    name: 'Maldivas',
    dial_code: '+960',
    code: 'MV',
  },
  {
    name: 'Malí',
    dial_code: '+223',
    code: 'ML',
  },
  {
    name: 'Malta',
    dial_code: '+356',
    code: 'MT',
  },
  {
    name: 'Islas Marshall',
    dial_code: '+692',
    code: 'MH',
  },
  {
    name: 'Martinica',
    dial_code: '+596',
    code: 'MQ',
  },
  {
    name: 'Mauritania',
    dial_code: '+222',
    code: 'MR',
  },
  {
    name: 'Mauricio',
    dial_code: '+230',
    code: 'MU',
  },
  {
    name: 'Mayotte',
    dial_code: '+262',
    code: 'YT',
  },
  {
    name: 'México',
    dial_code: '+52',
    code: 'MX',
  },
  {
    name: 'Micronesia',
    dial_code: '+691',
    code: 'FM',
  },
  {
    name: 'Moldavia',
    dial_code: '+373',
    code: 'MD',
  },
  {
    name: 'Mónaco',
    dial_code: '+377',
    code: 'MC',
  },
  {
    name: 'Mongolia',
    dial_code: '+976',
    code: 'MN',
  },
  {
    name: 'Montenegro',
    dial_code: '+382',
    code: 'ME',
  },
  {
    name: 'Montserrat',
    dial_code: '+1664',
    code: 'MS',
  },
  {
    name: 'Marruecos',
    dial_code: '+212',
    code: 'MA',
  },
  {
    name: 'Mozambique',
    dial_code: '+258',
    code: 'MZ',
  },
  {
    name: 'Myanmar (Birmania)',
    dial_code: '+95',
    code: 'MM',
  },
  {
    name: 'Namibia',
    dial_code: '+264',
    code: 'NA',
  },
  {
    name: 'Nauru',
    dial_code: '+674',
    code: 'NR',
  },
  {
    name: 'Nepal',
    dial_code: '+977',
    code: 'NP',
  },
  {
    name: 'Países Bajos',
    dial_code: '+31',
    code: 'NL',
  },
  {
    name: 'Antillas Neerlandesas',
    dial_code: '+599',
    code: 'AN',
  },
  {
    name: 'Nueva Caledonia',
    dial_code: '+687',
    code: 'NC',
  },
  {
    name: 'Nueva Zelanda',
    dial_code: '+64',
    code: 'NZ',
  },
  {
    name: 'Nicaragua',
    dial_code: '+505',
    code: 'NI',
  },
  {
    name: 'Níger',
    dial_code: '+227',
    code: 'NE',
  },
  {
    name: 'Nigeria',
    dial_code: '+234',
    code: 'NG',
  },
  {
    name: 'Niue',
    dial_code: '+683',
    code: 'NU',
  },
  {
    name: 'Isla Norfolk',
    dial_code: '+672',
    code: 'NF',
  },
  {
    name: 'Islas Marianas del Norte',
    dial_code: '+1670',
    code: 'MP',
  },
  {
    name: 'Noruega',
    dial_code: '+47',
    code: 'NO',
  },
  {
    name: 'Omán',
    dial_code: '+968',
    code: 'OM',
  },
  {
    name: 'Pakistán',
    dial_code: '+92',
    code: 'PK',
  },
  {
    name: 'Palaos',
    dial_code: '+680',
    code: 'PW',
  },
  {
    name: 'Territorios Palestinos Ocupados',
    dial_code: '+970',
    code: 'PS',
  },
  {
    name: 'Panamá',
    dial_code: '+507',
    code: 'PA',
  },
  {
    name: 'Papúa Nueva Guinea',
    dial_code: '+675',
    code: 'PG',
  },
  {
    name: 'Paraguay',
    dial_code: '+595',
    code: 'PY',
  },
  {
    name: 'Perú',
    dial_code: '+51',
    code: 'PE',
  },
  {
    name: 'Filipinas',
    dial_code: '+63',
    code: 'PH',
  },
  {
    name: 'Pitcairn',
    dial_code: '+872',
    code: 'PN',
  },
  {
    name: 'Polonia',
    dial_code: '+48',
    code: 'PL',
  },
  {
    name: 'Portugal',
    dial_code: '+351',
    code: 'PT',
  },
  {
    name: 'Puerto Rico',
    dial_code: '+1939',
    code: 'PR',
  },
  {
    name: 'Catar',
    dial_code: '+974',
    code: 'QA',
  },
  {
    name: 'Rumanía',
    dial_code: '+40',
    code: 'RO',
  },
  {
    name: 'Rusia',
    dial_code: '+7',
    code: 'RU',
  },
  {
    name: 'Ruanda',
    dial_code: '+250',
    code: 'RW',
  },
  {
    name: 'Reunión',
    dial_code: '+262',
    code: 'RE',
  },
  {
    name: 'San Bartolomé',
    dial_code: '+590',
    code: 'BL',
  },
  {
    name: 'Santa Elena, Ascensión y Tristán de Acuña',
    dial_code: '+290',
    code: 'SH',
  },
  {
    name: 'San Cristóbal y Nieves',
    dial_code: '+1869',
    code: 'KN',
  },
  {
    name: 'Santa Lucía',
    dial_code: '+1758',
    code: 'LC',
  },
  {
    name: 'San Martín',
    dial_code: '+590',
    code: 'MF',
  },
  {
    name: 'San Pedro y Miquelón',
    dial_code: '+508',
    code: 'PM',
  },
  {
    name: 'San Vicente y las Granadinas',
    dial_code: '+1784',
    code: 'VC',
  },
  {
    name: 'Samoa',
    dial_code: '+685',
    code: 'WS',
  },
  {
    name: 'San Marino',
    dial_code: '+378',
    code: 'SM',
  },
  {
    name: 'Santo Tomé y Príncipe',
    dial_code: '+239',
    code: 'ST',
  },
  {
    name: 'Arabia Saudita',
    dial_code: '+966',
    code: 'SA',
  },
  {
    name: 'Senegal',
    dial_code: '+221',
    code: 'SN',
  },
  {
    name: 'Serbia',
    dial_code: '+381',
    code: 'RS',
  },
  {
    name: 'Seychelles',
    dial_code: '+248',
    code: 'SC',
  },
  {
    name: 'Sierra Leona',
    dial_code: '+232',
    code: 'SL',
  },
  {
    name: 'Singapur',
    dial_code: '+65',
    code: 'SG',
  },
  {
    name: 'Eslovaquia',
    dial_code: '+421',
    code: 'SK',
  },
  {
    name: 'Eslovenia',
    dial_code: '+386',
    code: 'SI',
  },
  {
    name: 'Islas Salomón',
    dial_code: '+677',
    code: 'SB',
  },
  {
    name: 'Somalia',
    dial_code: '+252',
    code: 'SO',
  },
  {
    name: 'Sudáfrica',
    dial_code: '+27',
    code: 'ZA',
  },
  {
    name: 'Sudán del Sur',
    dial_code: '+211',
    code: 'SS',
  },
  {
    name: 'Islas Georgias del Sur y Sandwich del Sur',
    dial_code: '+500',
    code: 'GS',
  },
  {
    name: 'España',
    dial_code: '+34',
    code: 'ES',
  },
  {
    name: 'Sri Lanka',
    dial_code: '+94',
    code: 'LK',
  },
  {
    name: 'Sudán',
    dial_code: '+249',
    code: 'SD',
  },
  {
    name: 'Surinam',
    dial_code: '+597',
    code: 'SR',
  },
  {
    name: 'Svalbard y Jan Mayen',
    dial_code: '+47',
    code: 'SJ',
  },
  {
    name: 'Suazilandia',
    dial_code: '+268',
    code: 'SZ',
  },
  {
    name: 'Suecia',
    dial_code: '+46',
    code: 'SE',
  },
  {
    name: 'Suiza',
    dial_code: '+41',
    code: 'CH',
  },
  {
    name: 'República Árabe Siria',
    dial_code: '+963',
    code: 'SY',
  },
  {
    name: 'Taiwán',
    dial_code: '+886',
    code: 'TW',
  },
  {
    name: 'Tayikistán',
    dial_code: '+992',
    code: 'TJ',
  },
  {
    name: 'Tanzania',
    dial_code: '+255',
    code: 'TZ',
  },
  {
    name: 'Tailandia',
    dial_code: '+66',
    code: 'TH',
  },
  {
    name: 'Timor Oriental',
    dial_code: '+670',
    code: 'TL',
  },
  {
    name: 'Togo',
    dial_code: '+228',
    code: 'TG',
  },
  {
    name: 'Tokelau',
    dial_code: '+690',
    code: 'TK',
  },
  {
    name: 'Tonga',
    dial_code: '+676',
    code: 'TO',
  },
  {
    name: 'Trinidad y Tobago',
    dial_code: '+1868',
    code: 'TT',
  },
  {
    name: 'Túnez',
    dial_code: '+216',
    code: 'TN',
  },
  {
    name: 'Turquía',
    dial_code: '+90',
    code: 'TR',
  },
  {
    name: 'Turkmenistán',
    dial_code: '+993',
    code: 'TM',
  },
  {
    name: 'Islas Turcas y Caicos',
    dial_code: '+1649',
    code: 'TC',
  },
  {
    name: 'Tuvalu',
    dial_code: '+688',
    code: 'TV',
  },
  {
    name: 'Uganda',
    dial_code: '+256',
    code: 'UG',
  },
  {
    name: 'Ucrania',
    dial_code: '+380',
    code: 'UA',
  },
  {
    name: 'Emiratos Árabes Unidos',
    dial_code: '+971',
    code: 'AE',
  },
  {
    name: 'Reino Unido',
    dial_code: '+44',
    code: 'GB',
  },
  {
    name: 'Estados Unidos',
    dial_code: '+1',
    code: 'US',
  },
  {
    name: 'Uruguay',
    dial_code: '+598',
    code: 'UY',
  },
  {
    name: 'Uzbekistán',
    dial_code: '+998',
    code: 'UZ',
  },
  {
    name: 'Vanuatu',
    dial_code: '+678',
    code: 'VU',
  },
  {
    name: 'Venezuela',
    dial_code: '+58',
    code: 'VE',
  },
  {
    name: 'Vietnam',
    dial_code: '+84',
    code: 'VN',
  },
  {
    name: 'Islas Vírgenes Británicas',
    dial_code: '+1284',
    code: 'VG',
  },
  {
    name: 'Islas Vírgenes de los Estados Unidos',
    dial_code: '+1340',
    code: 'VI',
  },
  {
    name: 'Wallis y Futuna',
    dial_code: '+681',
    code: 'WF',
  },
  {
    name: 'Yemen',
    dial_code: '+967',
    code: 'YE',
  },
  {
    name: 'Zambia',
    dial_code: '+260',
    code: 'ZM',
  },
  {
    name: 'Zimbabue',
    dial_code: '+263',
    code: 'ZW',
  },
];
