import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BehaviorSubject, fromEvent, Observable } from 'rxjs';

import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  tap,
} from 'rxjs/operators';

const MOBILE_MAX_WIDTH = 768; // Adjust as needed
const TABLET_MAX_WIDTH = 992; // Adjust as needed
const SMALL_DESKTOP_MAX_WIDTH = 1366; // Adjust as needed
export class SetScreen {
  payload: Readonly<{
    mobile: boolean;
    tablet: boolean;
    desktop: boolean;
  }>;

  public constructor(width: number, height: number) {
    let mobile = false;
    let tablet = false;

    mobile = width <= MOBILE_MAX_WIDTH;
    tablet = width <= TABLET_MAX_WIDTH && width > MOBILE_MAX_WIDTH;

    if (tablet) {
      if (height <= 500) {
        tablet = false;
        mobile = true;
      }
    }

    this.payload = {
      mobile,
      tablet,
      desktop: !mobile && !tablet,
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class DeviceInfoService {
  isMobileDevice = false;
  isTabletDevice = false;
  isDesktopDevice = false;

  constructor(private deviceService: DeviceDetectorService) {}

  getDeviceInfo() {
    return {
      isMobileDevice: this.deviceService.isMobile(),
      isTabletDevice: this.deviceService.isTablet(),
      isDesktopDevice: this.deviceService.isDesktop(),
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class SandboxService {
  private _resize$!: Observable<number>;
  // @ts-ignore
  private screenBusChannel = new BehaviorSubject<SetScreen>(null);

  screenBusChannel$ = this.screenBusChannel.asObservable();

  constructor() {
    const resize$ = fromEvent(window, 'resize').pipe(
      debounceTime(200),
      map(() => window.innerWidth, window.innerHeight),
      distinctUntilChanged(),
      startWith(window.innerWidth, window.innerHeight),
      tap(() => this.setWindowWidth(window.innerWidth, window.innerHeight))
    );

    this._resize$ = resize$;
    this._resize$.subscribe();
  }

  isCapacitorDevice(): boolean {
    return Capacitor.getPlatform() !== 'web';
  }

  private setWindowWidth(windowWidth: number, windowHeight: number): void {
    const setScreen = new SetScreen(windowWidth, windowHeight);

    this.screenBusChannel.next(setScreen);
  }
}
