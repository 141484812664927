import { IPovTab } from '../interfaces';
import {
  Feature,
  FeatureUser,
} from '../packages/permissions/permissions.types';

export const COMMONSTAB: IPovTab[] = [
  {
    id: null,
    value: null,
    idTab: 'deals',
    name: 'deals',
    tabName: 'deals.title',
    featureUser: FeatureUser.Deal.show,
    list: null,
    order: 1,
  },
  {
    id: null,
    value: null,
    idTab: 'activities',
    name: 'activities',
    tabName: 'activity.title',
    featureUser: FeatureUser.Activity.show,
    list: null,
    order: 2,
  },
];

export const TAB_EQUIPMENTS: IPovTab[] = [
  {
    id: null,
    value: null,
    idTab: 'equipments',
    name: 'equipments',
    tabName: 'equipments.title2',
    list: null,
    featureUser: FeatureUser.Equipment.show,
    order: 0,
  },
  ...COMMONSTAB,
];

export const TAB_USERS: IPovTab[] = [
  {
    id: null,
    idTab: 'user',
    name: 'user',
    tabName: 'person.title2',
    list: null,
    value: null,
    featureUser: Feature.SystemPermission.DefaultAllow,
    order: 0,
  },
  ...COMMONSTAB,
  {
    id: null,
    idTab: 'payroll',
    name: 'payroll',
    tabName: 'person.payroll',
    featureUser: Feature.SystemPermission.DefaultAllow,
    list: null,
    value: null,
    order: 0,
  },
  {
    id: null,
    idTab: 'contracts',
    name: 'contracts',
    tabName: 'person.contracts',
    featureUser: Feature.SystemPermission.DefaultAllow,
    list: null,
    value: null,
    order: 0,
  },
  {
    id: null,
    idTab: 'documents',
    name: 'documents',
    tabName: 'person.documents',
    featureUser: Feature.SystemPermission.DefaultAllow,
    list: null,
    value: null,
    order: 0,
  },
];

export const TAB_ADDRESS: IPovTab[] = [
  {
    id: null,
    idTab: 'asset',
    name: 'asset',
    tabName: 'asset.title2',
    featureUser: FeatureUser.Address.show,
    list: null,
    value: null,
    order: 0,
  },
  ...COMMONSTAB,
];

export const TAB_FINALS: IPovTab[] = [
  {
    id: null,
    idTab: 'finals',
    name: 'finals',
    tabName: 'finals.title2',
    featureUser: FeatureUser.Final.show,
    list: null,
    value: null,
    order: 0,
  },
  ...COMMONSTAB,
];

export const TAB_EXPENSES: IPovTab[] = [
  {
    id: null,
    idTab: 'expenses',
    name: 'expenses',
    tabName: 'expense.titleTab',
    featureUser: FeatureUser.Expense.show,
    list: null,
    value: null,
    order: 0,
  },
];

export const TAB_VENDOR: IPovTab[] = [
  {
    id: null,
    idTab: 'vendor',
    name: 'vendor',
    tabName: 'vendor.title',
    featureUser: FeatureUser.InternalVendor.show,
    list: null,
    value: null,
    order: 0,
  },
  ...COMMONSTAB,
];

export const TAB_CATALOGUE: IPovTab[] = [
  {
    id: null,
    idTab: 'catalogue',
    name: 'catalogue',
    tabName: 'catalogue.title',
    featureUser: FeatureUser.Catalogue.show,
    list: null,
    value: null,
    order: 0,
  },
];

export const TAB_ACTIVITIES: IPovTab[] = [
  {
    id: null,
    name: 'activity',
    featureUser: FeatureUser.Activity.show,
    value: null,
    order: 0,
  },
  {
    id: null,
    name: 'deal',
    featureUser: FeatureUser.Deal.show,
    value: null,
    order: 1,
  },
  {
    id: null,
    name: 'asset',
    featureUser: FeatureUser.Address.show,
    value: null,
    order: 2,
  },
  {
    id: null,
    name: 'users',
    featureUser: Feature.SystemPermission.DefaultAllow,
    value: null,
    order: 3,
  },
];

export const TAB_NOTES: IPovTab[] = [
  {
    id: null,
    name: 'notes',
    value: null,
    featureUser: Feature.SystemPermission.DefaultAllow,
    order: 0,
  },
  {
    id: null,
    name: 'users',
    value: null,
    featureUser: Feature.SystemPermission.DefaultAllow,
    order: 1,
  },

  {
    id: null,
    name: 'assets',
    featureUser: FeatureUser.Address.show,
    value: null,
    order: 2,
  },
];

export const TAB_FILES: IPovTab[] = [
  {
    id: null,
    idTab: 'payroll',
    name: 'payroll',
    tabName: 'person.payroll',
    featureUser: Feature.SystemPermission.DefaultAllow,
    list: null,
    value: null,
    order: 0,
  },
  {
    id: null,
    idTab: 'contracts',
    name: 'contracts',
    tabName: 'person.contracts',
    featureUser: Feature.SystemPermission.DefaultAllow,
    list: null,
    value: null,
    order: 0,
  },
  {
    id: null,
    idTab: 'documents',
    name: 'documents',
    tabName: 'person.documents',
    featureUser: Feature.SystemPermission.DefaultAllow,
    list: null,
    value: null,
    order: 0,
  },
];

export const TAB_PROJECTS: IPovTab[] = [
  {
    id: null,
    idTab: 'project',
    name: 'project',
    tabName: 'projects.title2',
    featureUser: FeatureUser.Project.show,
    list: null,
    value: null,
    order: 0,
  },
  ...COMMONSTAB,
  ...TAB_EXPENSES,
];
