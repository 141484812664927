import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'roma-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss'],
})
export class ModalInfoComponent implements OnInit {
  @Input()
  title = '';

  @Input()
  type = 'info';

  @Input()
  module = '';

  @Input()
  elements: string[] = [];

  @Input()
  images: Array<{ img: string; type: string }> = [];

  constructor(public dialog: MatDialogRef<ModalInfoComponent>) {}

  ngOnInit(): void {}

  close() {
    this.dialog.close();
  }
  ok() {}
}
