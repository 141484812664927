import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalTemplateModule } from '../modal-template/modal-template.module';
import { MatChipsModule } from '@angular/material/chips';
import { TranslateModule } from '@ngx-translate/core';
import { ModalUsersComponent } from './modal-users.component';

@NgModule({
  declarations: [ModalUsersComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    MatChipsModule,
    TranslateModule.forChild(),
  ],
  exports: [ModalUsersComponent],
})
export class ModalUsersModule {}
