import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateDayMonthPipe } from './date-day-month.pipe';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DateDayMonthPipe],
  imports: [CommonModule, TranslateModule.forChild()],
  exports: [DateDayMonthPipe],
})
export class DateDayMonthModule {}
