import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotResultFoundComponent } from './not-result-found.component';
import { I18nChild } from '@web-frontend/shared/i18n';

@NgModule({
  declarations: [NotResultFoundComponent],
  imports: [CommonModule, I18nChild],
  exports: [NotResultFoundComponent],
})
export class NotResultFoundModule {}
