import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { IDeal, TypeRol } from '@tacliatech/types';

import { AuthService } from '@web-frontend/shared/services';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { CreateDealService } from '@web-frontend/shared/components/create-deal';
import { map } from 'rxjs/operators';

@Component({
  selector: 'roma-deal-pipeline-card',
  templateUrl: './deal-pipeline-card.component.html',
  styleUrls: ['./deal-pipeline-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PipelineDealCardComponent implements OnDestroy {
  @HostBinding('class.card-deal')
  hostClass = true;

  @Input()
  @HostBinding('class.card-deal--marked')
  marked = false;

  @Input()
  deal: IDeal;

  activeUser$ = this.authService.user$;
  rolRef = TypeRol;

  private sub$ = new Subscription();

  company_date_format = this.budgetService.date_format
    ? this.budgetService.date_format
    : 'dd/MM/yyyy';

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private authService: AuthService,
    private router: Router,
    private budgetService: BudgetService,
    private createDealService: CreateDealService
  ) {}

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  @HostListener('click', ['$event'])
  click() {
    this.sub$.add(
      this.activeUser$.subscribe((res) => {
        this.createDealService
          .open({
            data: {
              mode: 'EDIT',
              idDeal: this.deal?._id,
            },
          })
          .pipe(map((res) => !res))
          .subscribe();
      })
    );
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }
}
