<div *ngIf="loading">
  <roma-loading></roma-loading>
</div>
<div class="body-permission" *ngIf="!loading">
  <div class="body-permission2" *ngFor="let perm of tablePermissions">
    <table *ngIf="haveAllTableModules(perm.permissionsKeys)">
      <tr>
        <th class="table-title borderless">
          {{ 'permissions.titles.' + perm.name | translate }}
        </th>
        <th class="table-title check-cell borderless" *ngIf="perm.show">
          {{ 'permissions.keys.show' | translate }}
          <span class="custom-tooltip bottom">
            <img class="info__icon" src="assets/icons/info-icon-2-14.svg" />
            <div class="tiptext left" style="width: 200px; left: -33px">
              {{ 'permissions.editInfoTooltip' | translate }}
            </div>
          </span>
        </th>
        <th class="table-title check-cell borderless" *ngIf="perm.create">
          {{ 'permissions.keys.create' | translate }}
        </th>
        <th class="table-title check-cell borderless" *ngIf="perm.update">
          {{ 'permissions.keys.update' | translate }}
        </th>
        <th class="table-title check-cell borderless" *ngIf="perm.delete">
          {{ 'permissions.keys.delete' | translate }}
        </th>
        <th class="table-title check-cell borderless">
          <div *ngIf="perm.approve">
            {{ 'permissions.keys.approve' | translate }}
          </div>
        </th>
      </tr>
      <ng-container *ngFor="let key of perm.permissionsKeys">
        <tr *ngIf="haveModule(key)">
          <td class="text-cell">
            <label>{{ 'permissions.titles.' + key | translate }}</label>
          </td>
          <td class="check-cell" *ngIf="perm.show">
            <mat-checkbox
              *ngIf="existsPermission('show', key)"
              [checked]="getPermissionByType('show', key)?.checked"
              style="width: 20px"
              (click)="onClickCheckbox('show', key)"
            >
            </mat-checkbox>
          </td>
          <td class="check-cell" *ngIf="perm.create">
            <mat-checkbox
              *ngIf="existsPermission('create', key)"
              [checked]="getPermissionByType('create', key)?.checked"
              style="width: 20px"
              (click)="onClickCheckbox('create', key)"
            >
            </mat-checkbox>
          </td>
          <td class="check-cell" *ngIf="perm.update">
            <mat-checkbox
              *ngIf="existsPermission('update', key)"
              [checked]="getPermissionByType('update', key)?.checked"
              style="width: 20px"
              (click)="onClickCheckbox('update', key)"
            >
            </mat-checkbox>
          </td>
          <td class="check-cell" *ngIf="perm.delete">
            <mat-checkbox
              *ngIf="existsPermission('delete', key)"
              [checked]="getPermissionByType('delete', key)?.checked"
              style="width: 20px"
              (click)="onClickCheckbox('delete', key)"
            >
            </mat-checkbox>
          </td>
          <td class="check-cell" *ngIf="perm.approve">
            <mat-checkbox
              *ngIf="existsPermission('approve', key)"
              [checked]="getPermissionByType('approve', key)?.checked"
              style="width: 20px"
              (click)="onClickCheckbox('approve', key)"
            >
            </mat-checkbox>
          </td>
        </tr>
      </ng-container>
    </table>
    <br />
    <!--<div *ngFor="let item of permissions">
      <roma-add-permission
        [type]="item.type"
        [expanded]="item.expanded"
        [permissions]="item.permissions"
        (change)="onChange($event)"
      ></roma-add-permission>
    </div>-->
  </div>
</div>
