import { Injectable } from '@angular/core';
import IInvoiceAdapter from '../types/invoice-adapter.type';
import { InvoiceApiService } from '../invoice-api.service';
import { v4 as uuidv4 } from 'uuid';
import InvoiceBudgetParams from '../types/invoice-budget-params.type';
import PostInvoiceBodyDTO from '../types/post-invoice-dto.type';
import PatchInvoiceBodyDto from '../types/patch-invoice-dto.type';
import {
  BudgetCommonData,
  IBudgetCommonData,
  ISequence,
  STATUS_BILL_PENDING,
  TypeBudget,
} from '@tacliatech/types';
import InvoiceResponse from '../types/invoice-response.type';
import InvoiceHeader from '../types/invoice-header.type';
import { StorageService } from '@web-frontend/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { SequenceService } from '@web-frontend/shared/services/sequence';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export default class InvoiceAdapter implements IInvoiceAdapter {
  constructor(
    private readonly invoiceApiService: InvoiceApiService,
    private readonly i18n: TranslateService,
    private sequenceService: SequenceService
  ) {}

  _pdfRetries = 0;
  MAX_PDF_RETRIES = 30;
  PDF_RETRY_DELAY = 1000;

  findPdf(params: Record<string, any>): Promise<any> {
    return this.invoiceApiService
      .getResponse(`/invoices/${params.id}/pdf?lang=${this.i18n.currentLang}`, {
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `Bearer ${StorageService.Token}`,
        },
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .then((res) => {
        if (
          (res.status === 204 && this._pdfRetries > this.MAX_PDF_RETRIES) ||
          ![200, 204].includes(res.status)
        ) {
          throw new Error('error_downloading');
        }

        if (res.status === 204 && this._pdfRetries < this.MAX_PDF_RETRIES) {
          this._pdfRetries++;
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(this.findPdf(params));
            }, this.PDF_RETRY_DELAY);
          });
        } else {
          this._pdfRetries = 0;
          return res;
        }
      });
  }

  private mapCreateParams(params: InvoiceBudgetParams): PostInvoiceBodyDTO {
    return {
      id: params._id || uuidv4(),
      prefix: params.header.prefix,
      sequenceId: params.header.sequence_id,
      dueDate: params.header.dueDate,
      customerId: params.header.contact_id,
      observation: params.messages?.line1 || '',
      paymentMethod: params.typePayments[0]?.name || '',
      invoiceType: params.invoiceType,
      purposeUse: params.purposeUse,
      issueAt: params.issueAt,
      lines: params.items
        .filter((item) => item.concept)
        .map((item) => {
          return {
            conceptName: item.concept,
            price: item.price || 0,
            quantity: item.quantity || 1,
            discount: item.discount || 0,
            unit: item.unit,
            productKey: item.productKey,
            tax: item.taxes.map((tax) => {
              return {
                value: tax.value,
                type: tax.type,
              };
            }),
          };
        }),
    };
  }

  async getPaymentOptions(): Promise<
    Array<{ id: string; description: string }>
  > {
    return this.invoiceApiService.get(`/sat/mx/payment-methods`);
  }

  async getUsageOptions(): Promise<Array<{ id: string; description: string }>> {
    return this.invoiceApiService.get(`/sat/mx/cfdi`);
  }

  private toLegacyBill(
    bill: InvoiceHeader,
    sequences: ISequence[]
  ): BudgetCommonData {
    return {
      header: {
        prefix:
          !bill.prefix && bill.sequenceId
            ? sequences.find((it) => it.id === bill.sequenceId)?.prefix
            : bill.prefix,
        date: '' + (bill.issuedAt || bill.setDraftAt),
        dueDate: bill.dueDate,
        contact_id: bill.customerId,
        numberDoc: bill.numberDoc,
        sequence_id: bill.sequenceId,
      },
      items: bill.lines.map((line) => {
        return {
          concept: line.conceptName,
          price: line.price,
          quantity: line.quantity,
          unit: line.unit,
          productKey: line.productKey,
          taxes: line.tax.map((tax) => {
            return {
              value: tax.value,
              type: tax.type,
            };
          }),
        };
      }),
      typePayments: [
        {
          name: bill.paymentMethod,
          _id: bill.paymentMethod,
          textShow: bill.paymentMethod,
        },
      ],
      messages: { line1: bill.observation },
      total: bill.totalWithTax,
      subtotal: bill.total,
      iva_total: bill.total - bill.totalWithTax,
      addicionalInfo: {},
      status: STATUS_BILL_PENDING.id,
      deleted: false,
      company: bill.companyId,
      _id: bill.id,
      draft: !bill.issuedAt,
      createdAt: new Date(bill.setDraftAt),
      invoiceType: bill.invoiceType,
      purposeUse: bill.purposeUse,
    };
  }

  async create(params: InvoiceBudgetParams): Promise<PostInvoiceBodyDTO> {
    const body = this.mapCreateParams(params);
    await this.invoiceApiService.post(`/invoices`, {
      ...body,
    });
    return body;
  }

  async createV2(
    params: InvoiceBudgetParams
  ): Promise<HttpResponse<PostInvoiceBodyDTO>> {
    const body = this.mapCreateParams(params);
    await this.invoiceApiService.postResponse(
      `/invoices`,
      {
        ...body,
      },
      { observe: 'response' }
    );
    return new HttpResponse<PostInvoiceBodyDTO>({ body });
  }

  async update(
    params: InvoiceBudgetParams & { _id?: string; duplicate?: boolean }
  ): Promise<PatchInvoiceBodyDto> {
    const body: PatchInvoiceBodyDto = this.mapCreateParams(params);
    await this.invoiceApiService.patch(`/invoices/${body.id}`, {
      ...body,
    });

    return body;
  }

  async updateV2(
    params: InvoiceBudgetParams & { _id?: string; duplicate?: boolean }
  ): Promise<PatchInvoiceBodyDto> {
    return this.update(params);
  }

  async delete(id: string, type: TypeBudget): Promise<any> {
    return this.invoiceApiService.delete(`/invoices/${id}`);
  }

  async deleteV2(id: string, type: TypeBudget): Promise<any> {
    return this.invoiceApiService.delete(`/invoices/${id}`);
  }

  async findOne(id: string): Promise<any> {
    const ret = await this.invoiceApiService.get<InvoiceHeader>(
      `/invoices/${id}`
    );
    const sequences = await this.sequenceService.getAllSequences().toPromise();
    return this.toLegacyBill(ret, sequences.results);
  }

  async find(type: TypeBudget, params?: any): Promise<IBudgetCommonData[]> {
    const response = await this.invoiceApiService.get<InvoiceResponse>(
      `/invoices`
    );
    const bills: any = {};
    const sequences = await this.sequenceService.getAllSequences().toPromise();
    bills.docs = response.results.map((bill) =>
      this.toLegacyBill(bill, sequences.results)
    );

    return bills;
  }

  async findOnePdf(id: string) {}

  async findSummary(type: TypeBudget, params?: Record<string, any>) {
    return {};
  }

  async getTaxRegimeList(): Promise<{ id: string; description: string }[]> {
    return this.invoiceApiService.get(`/sat/mx/fiscal-regimes`);
  }

  async getProductAndServices(): Promise<
    { id: string; description: string }[]
  > {
    return this.invoiceApiService.get('/sat/mx/products-and-services');
  }

  async getUnits(): Promise<{ id: string; description: string }[]> {
    return this.invoiceApiService.get('/sat/mx/key-units');
  }

  async sendEmail(body: {
    document: string;
    type: TypeBudget;
    id: string;
    to: string[];
    company: string;
  }): Promise<any> {
    const { id, ...rest } = body;
    return this.invoiceApiService.post(`/invoices/${id}/send-email`, rest);
  }
}
