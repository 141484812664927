<roma-modal-template
  [title]="
    params.type === 'ADD'
      ? ('budgets.addModal' | translate)
      : ('budgets.editModal' | translate)
  "
  [isLoading]="isSubmitting"
  (success)="submit()"
  (cancel)="close()"
  [showDefaultButtons]="true"
>
  <form [formGroup]="form" class="global-container">
    <div class="global-item" style="margin-top: 5px">
      <div class="form-group mb-20">
        <label for="name">
          {{ 'projects.create.name' | translate }}
        </label>

        <input
          type="text"
          class="form-control"
          id="name"
          aria-describedby="name"
          formControlName="name"
          [placeholder]="'projects.create.namePh' | translate"
          [ngClass]="{ 'is-invalid': fieldName.displayError || false }"
        />

        <roma-field-error-display
          #fieldName
          fieldName="name"
          [errorMsg]="'projects.create.nameError'"
          [form]="form"
        >
        </roma-field-error-display>
      </div>

      <div class="form-group">
        <label for="name">
          {{ 'budgets.settings.create.form.payment-textshow' | translate }}
        </label>

        <input
          type="text"
          class="form-control"
          id="textShow"
          aria-describedby="textShow"
          formControlName="textShow"
          [placeholder]="
            'budgets.settings.create.form.payment-textshow' | translate
          "
          [ngClass]="{ 'is-invalid': textShow.displayError || false }"
        />

        <roma-field-error-display
          #textShow
          fieldName="textShow"
          [errorMsg]="'projects.create.textShowError'"
          [form]="form"
        >
        </roma-field-error-display>
      </div>
    </div>
  </form>
</roma-modal-template>
