import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { App, ICatalogue, PaginateResponse } from '@tacliatech/types';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CatalogueService {
  private sub$ = new Subscription();

  private cataloguesCache = new BehaviorSubject<
    App.ResourceCached<ICatalogue[]>
  >(['INIT', []]);

  catalogues$ = this.cataloguesCache.asObservable();

  constructor(private http: HttpClient) {}

  init() {
    this.cataloguesCache.next(['LOADING', []]);

    this.sub$.add(
      this.findAll().subscribe((res) => {
        this.cataloguesCache.next(['LOADED', res]);
      })
    );
  }

  public get catalogues() {
    return this.cataloguesCache.value[1];
  }

  search(query: { [key: string]: any } = {}) {
    const params = new HttpParams({
      fromObject: {
        ...query,
      },
    });

    return this.http.get<PaginateResponse<ICatalogue[]>>(
      `:API_URL/search/rates`,
      {
        params,
      }
    );
  }

  findAll() {
    return this.http.get<ICatalogue[]>(`:API_URL/customer-catalogue/`);
  }

  findOne(id: string) {
    return this.http.get<ICatalogue>(`:API_URL/customer-catalogue/${id}`);
  }

  findByUser(id: string) {
    return this.http.get<ICatalogue[]>(
      `:API_URL/customer-catalogue/users/${id}`
    );
  }

  create(data: ICatalogue) {
    return this.http.post(`:API_URL/customer-catalogue`, { ...data });
  }

  updateOne(id: string, params = {}) {
    return this.http.put(`:API_URL/customer-catalogue/${id}`, { ...params });
  }

  removeOne(id: string) {
    return this.http.delete(`:API_URL/customer-catalogue/${id}`);
  }

  restoreMany(data) {
    return this.http.post(`:API_URL/customer-catalogue/restore-many`, data);
  }

  deleteMany(data) {
    return this.http.post(`:API_URL/customer-catalogue/delete-many`, data);
  }
}
