export enum DealType {
  OpenMarket = 'OPEN_MARKET',
  Vendor = 'VENDOR',
  Team = 'TEAM',
}

export enum DealsMockType {
  REQUESTED = 'REQUESTED',
  BUDGETED = 'BUDGETED',
  IN_PROCESS = 'IN_PROCESS',
  FINALIZED = 'FINALIZED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  NEW = 'NEW',
  DEFAULT = 'DEFAULT',
  TODAY = 'TODAY',
  URGENCY = 'URGENCY',
  PAST = 'PAST',
}
