const createAndDownloadBlob = (
  data: Blob,
  filename: string,
  mimeType: string
): void => {
  const blob = new Blob([data], { type: mimeType });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const downloadSheet = (data: Blob, filename: string): void => {
  createAndDownloadBlob(
    data,
    filename,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  );
};

export const downloadZip = (data: Blob, filename: string): void => {
  createAndDownloadBlob(data, filename, 'application/zip');
};
