import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { SendEmailSaleModalComponent } from './send-email-sale-modal.component';
import { SendEmailSaleModalLightComponent } from './send-email-sale-modal-light/send-email-sale-modal-light.component';

@Injectable({
  providedIn: 'root',
})
export class SendEmailSaleModalService {
  dialogRef: any;

  constructor(private dialog: MatDialog) {}

  open(config: MatDialogConfig<any> = new MatDialogConfig()) {
    const dialogRef = this.dialog.open(SendEmailSaleModalComponent, config);
    this.dialogRef = dialogRef;

    return dialogRef.afterClosed();
  }

  openLightVersion(config: MatDialogConfig<any> = new MatDialogConfig()) {
    const dialogRef = this.dialog.open(
      SendEmailSaleModalLightComponent,
      config
    );
    this.dialogRef = dialogRef;

    return dialogRef.afterClosed();
  }
}
