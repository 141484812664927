import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DeleteBySelectionModalComponent } from '../../delete-by-selection-modal/delete-by-selection-modal.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MapperPlaceResult } from '../../../class/location';
import PlaceResult = google.maps.places.PlaceResult;
import { FinalService, StorageService } from '../../../services';
import { RemoveEmpty } from '../../../utils';
import { ToastService } from '../../../services/toast/toast.service';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { Phone } from '../../../interfaces';
import { FormatEmailValidatorAllowNull } from '../../../validators/email';

@Component({
  selector: 'roma-create-final-partially',
  templateUrl: './create-final-partially.component.html',
  styleUrls: ['./create-final-partially.component.scss'],
})
export class CreateFinalPartiallyComponent implements OnInit {
  isLoading = false;
  showToolTip = false;
  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    nif: new FormControl(''),
    address: new FormControl(''),
    idCreatedBy: new FormControl(StorageService.UserId, [Validators.required]),
    email: new FormControl('', FormatEmailValidatorAllowNull()),
    phonesArr: new FormControl([]),
  });
  isResponsive = window.innerWidth <= 768;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isResponsive = window.innerWidth <= 768;
  }

  constructor(
    private readonly dialogRef: MatDialogRef<CreateFinalPartiallyComponent>,
    private readonly dialog: MatDialog,
    private readonly i18n: TranslateService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly finalService: FinalService,
    private readonly toastService: ToastService,
    private analyticsService: AnalyticsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.dialogRef.disableClose = true;
    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onBackdropClick());
  }

  get phone(): Phone {
    return this.form.value?.phonesArr[0] || { pre: '', number: '' };
  }

  close(): void {
    this.onBackdropClick().finally(() => (this.isLoading = false));
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  validateForm(): void {
    this.form.markAllAsTouched();
    this.draw();
  }

  async submit(): Promise<void> {
    this.isLoading = true;
    this.validateForm();
    const value = RemoveEmpty(this.form.value);
    await this.finalService
      .create(value)
      .toPromise()
      .then((res) => {
        this.toastService.show({
          text: this.i18n.instant('finals.create.success'),
          type: 'success',
          msDuration: 4000,
        });
        this.isLoading = false;
        this.dialogRef.close(res._id);
      });

    this.analyticsService.trackEvent({
      sources: ['amplitude', 'braze', 'userflow'],
      eventName: AmplitudeEvents.final_create,
    });
  }

  autocompleteSelected(result: PlaceResult): void {
    const { formattedAddress } = MapperPlaceResult(result);

    this.form.patchValue({
      address: formattedAddress,
    });

    this.draw();
  }

  phoneChanges(event: Phone): void {
    const phones = this.form.value['phonesArr'];
    phones[0] = event;
    this.form.patchValue({
      phonesArr: phones,
    });
  }

  displayTooltip(canDisplay = true): void {
    if (this.isResponsive || !this.form.invalid) return;
    this.showToolTip = canDisplay;
  }

  changeName(event: InputEvent): void {
    const inputElement = event.target as HTMLInputElement;
    this.form.patchValue({
      name: inputElement.value,
    });
    this.validateForm();
    this.draw();
  }

  public async onBackdropClick(): Promise<void> {
    if (this.form.touched) {
      const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
        panelClass: 'delete-by-selection-modal',
        data: {
          title: this.i18n.instant('general.withoutSave'),
          confirmLabel: this.i18n.instant('general.buttonExit'),
          showBody: false,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'EXECUTE') {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
}
