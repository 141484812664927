<roma-modal-template
  [title]="
    type === 'ADD'
      ? ('expense.create.title' | translate)
      : ('expense.edit.title' | translate)
  "
  [isLoading]="isLoading"
  (cancel)="close($event)"
  [showInfo]="true"
  [footerTemplate]="footerButtons"
  [showDefaultButtons]="false"
  [askBeforeClose]="haveChanges"
  (info)="info('info')"
>
  <!-- <div class="row">
  <div class="col">
    <button mat-raised-button (click)="openAddCategory()">{{ 'general.create-category-expenses' | translate }}</button>
  </div>
</div> -->
  <input type="text" style="opacity: 0; width: 0px" />
  <form [formGroup]="form" class="global-container">
    <div class="global-item">
      <div
        romaProSelect
        class="form-group wrapper-pro-feature-user"
        [featureUser]="featureUserScreen"
      >
        <label class="field-label" for="exampleInputEmail1">
          {{ 'expense.create.form.type' | translate }}
        </label>

        <ng-select
          formControlName="type"
          [(ngModel)]="typeExpenseSelected"
          [placeholder]="'expense.create.form.typePlaceholder' | translate"
          [notFoundText]="'hiring.form.placeholder_notfound' | translate"
          [ngClass]="{ 'is-invalid': !form.get('type').valid }"
          (change)="changeTypeExpense($event)"
        >
          <ng-option *ngFor="let item of typeExpenses" [value]="item.id">
            {{ item.translate | translate }}
          </ng-option>
        </ng-select>

        <ng-container *ngIf="controls?.type?.errors">
          <div
            *ngIf="controls?.type?.errors?.required"
            class="invalid-feedback"
          >
            {{ 'expense.create.form.typeError' | translate }}
          </div>
        </ng-container>
      </div>

      <div
        romaProSelect
        class="form-group wrapper-pro-feature-user"
        [featureUser]="featureUserScreen"
      >
        <label class="field-label" for="number">
          {{ 'expense.create.form.number' | translate }}
        </label>
        <input
          class="form-control"
          id="title"
          type="text"
          aria-describedby="titleHelp"
          formControlName="number"
          [placeholder]="'expense.create.form.numberPlaceholder' | translate"
          [ngClass]="{ 'is-invalid': fieldTitle.displayError || false }"
        />

        <roma-field-error-display
          #fieldTitle
          fieldName="number"
          [errorMsg]="'expense.create.form.numberError'"
          [form]="form"
        >
        </roma-field-error-display>
      </div>
      <div
        romaProSelect
        class="form-group wrapper-pro-feature-user"
        [featureUser]="featureUserScreen"
      >
        <label class="field-label" for="title">
          {{ 'expense.create.form.description' | translate }}
        </label>
        <input
          type="text"
          class="form-control"
          id="title"
          aria-describedby="titleHelp"
          formControlName="name"
          maxlength="150"
          [placeholder]="
            'expense.create.form.descriptionPlaceholder' | translate
          "
          [ngClass]="{ 'is-invalid': fieldTitle.displayError || false }"
        />

        <roma-field-error-display
          #fieldTitle
          fieldName="description"
          [errorMsg]="'expense.create.form.descriptionError'"
          [form]="form"
        >
        </roma-field-error-display>
      </div>

      <div
        romaProSelect
        class="form-group wrapper-pro-feature-user"
        [featureUser]="featureUserScreen"
      >
        <label class="field-label" for="dateTime">
          {{ 'expense.create.form.dateTime' | translate }}
        </label>

        <roma-selector-recurrence
          [dateStorage]="inputDateTime"
          (changeRecurrence)="formatUTFDatetime($event, 'init')"
          [showSelectorRecurrence]="false"
        >
        </roma-selector-recurrence>
      </div>

      <div
        romaProSelect
        class="form-group wrapper-pro-feature-user"
        [featureUser]="featureUserScreen"
      >
        <label class="field-label" for="dueDateTime">
          {{ 'expense.create.form.dueDateTime' | translate }}
        </label>

        <roma-selector-recurrence
          [dateStorage]="inputDueDate"
          (changeRecurrence)="formatUTFDatetime($event, 'due')"
          [showSelectorRecurrence]="false"
        >
        </roma-selector-recurrence>
      </div>

      <div
        romaProSelect
        class="form-group wrapper-pro-feature-user"
        [featureUser]="featureUserScreen"
      >
        <label class="field-label" for="concept">
          {{ 'expense.create.form.concept' | translate }}
        </label>
        <textarea
          type="text"
          cols="30"
          rows="5"
          class="form-control custom-textarea"
          formControlName="concept"
        >
        </textarea>
      </div>
    </div>

    <div class="global-item">
      <div
        romaProSelect
        class="wrapper-pro-feature-user"
        [featureUser]="featureUserScreen"
      >
        <roma-image-selector
          [type]="'expense'"
          [quantity]="3"
          [title]="'hiring.form.add-images-lbl' | translate"
          [mode]="type"
          [accept-files]="true"
          [filesServer]="data?.expense?.imgs"
          (images-changed)="updateImages($event)"
          [delete-images-service]="deleteImages$"
          [feature]="featureRef.Final.UploadFiles"
          [module]="idModuleRef.Expenses"
        >
        </roma-image-selector>
      </div>

      <div
        romaProSelect
        class="form-group wrapper-pro-feature-user"
        [featureUser]="featureUserScreen"
      >
        <label class="field-label" for="exampleInputEmail1">
          {{ 'expense.create.form.owner' | translate }}
        </label>

        <ng-select
          bindLabel="name"
          bindValue="_id"
          [items]="allUsers"
          formControlName="users"
          [multiple]="true"
          [placeholder]="'expense.create.form.ownerPlaceholder' | translate"
          [notFoundText]="'hiring.form.placeholder_notfound' | translate"
          [ngClass]="{ 'is-invalid': !form.get('users').valid }"
          [loading]="allUsersLoading"
          (click)="onClickSelect('users')"
        >
        </ng-select>

        <ng-container *ngIf="controls?.users?.errors">
          <div
            *ngIf="controls?.users?.errors?.required"
            class="invalid-feedback"
          >
            {{ 'expense.create.form.ownerError' | translate }}
          </div>
        </ng-container>
      </div>

      <div
        *ngIf="(idActiveModules$ | async).includes(idModuleRef.Projects)"
        romaProSelect
        class="form-group wrapper-pro-feature-user"
        [featureUser]="{
          type: 'every',
          features: [featureUserScreen, featureRefUser.Project.show]
        }"
      >
        <label class="field-label" for="exampleInputEmail1">
          {{ 'expense.create.form.idProject' | translate }}
        </label>

        <rm-select
          [isClearable]="true"
          [placeholderText]="
            'expense.create.form.projectPlaceholder' | translate
          "
          [items]="projectsList"
          [isLoading]="projectsLoading"
          [activeItems]="selectedProject"
          inputHeight="44px"
          type="users"
          [addButtonText]="'general.addNewProject'"
          [addButtonFeatureUser]="featureRefUser.Project.create"
          (changeItems)="changeProject($event)"
          (addElement)="addProject()"
          (click)="onClickSelect('projects')"
        >
        </rm-select>
        <input
          type="hidden"
          [placeholder]="'expense.create.form.projectPlaceholder' | translate"
          formControlName="idProject"
        />
        <ng-container *ngIf="controls?.idProject?.errors">
          <div
            *ngIf="controls?.idProject?.errors?.required"
            class="invalid-feedback"
          >
            {{ 'expense.create.form.idProjectError' | translate }}
          </div>
        </ng-container>
      </div>

      <div
        *ngIf="(idActiveModules$ | async).includes(idModuleRef.InternalVendors)"
        romaProSelect
        class="form-group wrapper-pro-feature-user"
        [featureUser]="{
          type: 'every',
          features: [featureUserScreen, featureRefUser.InternalVendor.show]
        }"
      >
        <label class="field-label" for="exampleInputEmail1">
          {{ 'hiring.type.vendor' | translate }}
        </label>

        <rm-select
          [isClearable]="true"
          [placeholderText]="'hiring.form.placeholder_vendor' | translate"
          [items]="internalVendorList"
          [isLoading]="internalVandorLoading"
          [activeItems]="selectedVendor"
          inputHeight="44px"
          type="users"
          [addButtonText]="'general.addNewVendor'"
          [addButtonFeatureUser]="featureRefUser.InternalVendor.create"
          (changeItems)="changeVendor($event)"
          (addElement)="addVendor()"
          (click)="onClickSelect('vendor')"
        >
        </rm-select>
        <input
          type="hidden"
          [placeholder]="'hiring.form.placeholder_vendor' | translate"
          formControlName="vendor"
        />
      </div>

      <div
        *ngIf="(idActiveModules$ | async).includes(idModuleRef.Deals)"
        romaProSelect
        class="form-group wrapper-pro-feature-user"
        [featureUser]="{
          type: 'every',
          features: [featureUserScreen, featureRefUser.Deal.show]
        }"
      >
        <label class="field-label" for="deal">{{
          'expense.create.form.deal' | translate
        }}</label>

        <rm-select
          #dealSelect
          [isClearable]="true"
          [placeholderText]="'expense.create.form.dealPlaceholder' | translate"
          [items]="deals"
          [disabled]="dealLoadData"
          [isLoading]="dealsLoading"
          [activeItems]="selectedDeal"
          inputHeight="44px"
          (changeItems)="changeDealEvent($event)"
          (click)="onClickSelect('deals')"
        >
        </rm-select>
        <input
          type="hidden"
          [placeholder]="'expense.create.form.dealPlaceholder' | translate"
          formControlName="deal"
        />

        <ng-container *ngIf="controls?.deal?.errors">
          <div
            *ngIf="controls?.deal?.errors?.required"
            class="invalid-feedback"
          >
            {{ 'expense.create.form.dealError' | translate }}
          </div>
        </ng-container>
      </div>

      <div
        romaProSelect
        class="form-group wrapper-pro-feature-user"
        [featureUser]="featureUserScreen"
      >
        <label class="field-label" for="exampleInputEmail1">
          {{ 'expense.create.form.category' | translate }}
        </label>

        <ng-select
          #categorySelect
          formControlName="category"
          [placeholder]="'expense.create.form.categoryPlaceholder' | translate"
          [notFoundText]="'hiring.form.placeholder_notfound' | translate"
          [ngClass]="{ 'is-invalid': !form.get('category').valid }"
          (change)="changeCategory($event)"
          [loading]="categoryExpensesLoading"
          (click)="onClickSelect('categoryExpenses')"
        >
          <ng-option
            *ngFor="let item of categoryExpensesList"
            [value]="item._id"
          >
            {{ translateName(item?.name) | translate }}
          </ng-option>
        </ng-select>

        <ng-container *ngIf="controls?.category?.errors">
          <div
            *ngIf="controls?.category?.errors?.required"
            class="invalid-feedback"
          >
            {{ 'expense.create.form.categoryError' | translate }}
          </div>
        </ng-container>
      </div>

      <!--    <div class="form-group">
        <mat-checkbox
          formControlName="hasBeenNotified"
          color="primary"
          class="ml-4 pt-0"
        >
          <small>
            {{ 'expense.create.form.hasBeenNotified' | translate }}
          </small>
        </mat-checkbox>
      </div> -->
    </div>

    <div class="global-item">
      <div
        romaProSelect
        class="form-group wrapper-pro-feature-user"
        [featureUser]="featureUserScreen"
      >
        <label class="field-label" for="subtotal">
          {{ 'expense.create.form.subtotal' | translate
          }}<obligatory-field-sign></obligatory-field-sign>
        </label>
        <input
          type="number"
          class="form-control"
          id="title"
          aria-describedby="titleHelp"
          formControlName="subtotal"
          maxLength="50"
          [placeholder]="'expense.create.form.subtotalPlaceholder' | translate"
          [ngClass]="{ 'is-invalid': fieldSubtotal.displayError || false }"
        />

        <roma-field-error-display
          #fieldSubtotal
          fieldName="subtotal"
          [errorMsg]="'expense.create.form.subtotalError'"
          [form]="form"
        >
        </roma-field-error-display>
      </div>

      <div
        romaProSelect
        class="form-group wrapper-pro-feature-user"
        [featureUser]="featureUserScreen"
      >
        <label class="field-label" for="total">
          {{ 'expense.create.form.total' | translate
          }}<obligatory-field-sign></obligatory-field-sign>
        </label>
        <input
          type="number"
          class="form-control"
          id="total"
          aria-describedby="titleHelp"
          formControlName="total"
          maxLength="50"
          [placeholder]="'expense.create.form.totalPlaceholder' | translate"
          [ngClass]="{ 'is-invalid': fieldTotal.displayError || false }"
        />

        <roma-field-error-display
          #fieldTotal
          fieldName="total"
          [errorMsg]="'expense.create.form.totalError'"
          [form]="form"
        >
        </roma-field-error-display>
      </div>

      <div
        romaProSelect
        class="form-group wrapper-pro-feature-user"
        [featureUser]="featureUserScreen"
      >
        <label class="field-label" for="exampleInputEmail1">
          {{ 'expense.create.form.status' | translate }}
        </label>

        <ng-select
          formControlName="status"
          [(ngModel)]="statusExpenseSelected"
          [placeholder]="'expense.create.form.statusPlaceholder' | translate"
          [notFoundText]="'hiring.form.placeholder_notfound' | translate"
          [ngClass]="{ 'is-invalid': !form.get('status').valid }"
        >
          <ng-option *ngFor="let item of statusExpenses" [value]="item.id">
            {{ item.translate | translate }}
          </ng-option>
        </ng-select>

        <ng-container *ngIf="controls?.status?.errors">
          <div
            *ngIf="controls?.status?.errors?.required"
            class="invalid-feedback"
          >
            {{ 'expense.create.form.statusError' | translate }}
          </div>
        </ng-container>
      </div>

      <roma-append-custom-properties
        *ngIf="type === 'ADD'"
        [typeRef]="typePropertyRef.Expenses"
        [rowLayout]="true"
        size="lg"
        [control]="form.get('customProperties')"
        [parentForm]="form"
        [initForm]="initChildForm"
        [parentFormSubmit]="parentFormSubmit"
        (customPropertiesForm)="customPropertiesFormChange($event)"
        (closeModal)="close($event)"
        (propertiesLoading)="propertiesLoading($event)"
        (initialCharge)="initialChargeEvent()"
      ></roma-append-custom-properties>

      <!-- {{ expeseditRef?._id }} -->

      <roma-edit-custom-properties
        *ngIf="type === 'EDIT'"
        [rowLayout]="true"
        [idSchema]="expeseditRef?._id"
        [typeRef]="typePropertyRef.Expenses"
        [parentForm]="form"
        [updateOnEdit]="false"
        [customProperties]="expeseditRef?.customProperties"
        [options]="{ showAlertMessage: false }"
        [featureUser]="featureUserScreen"
        (propertiesRequired)="propertiesRequired($event)"
        (changeSuccess)="changeCustomProperty()"
        (propertiesLoading)="propertiesLoading($event)"
        (onValueChanged)="eventValueChanged($event)"
      ></roma-edit-custom-properties>
    </div>
  </form>
</roma-modal-template>

<ng-template #footerButtons>
  <div class="modalFooter">
    <div class="groupEndDiv">
      <base-button
        *ngIf="haveChanges"
        buttonClass="tertiary"
        romaClickDelete
        [message]="'general.withoutSave'"
        [buttonDelete]="'general.buttonExit'"
        (wantDelete)="close()"
      >
        {{ 'activity.create.cancel' | translate }}
      </base-button>
      <base-button
        *ngIf="!haveChanges"
        buttonClass="tertiary"
        (click)="close()"
      >
        {{ 'activity.create.cancel' | translate }}
      </base-button>
      <base-button
        [disabled]="propertiesIsLoading || invalidEditCustomFields"
        romaProFeature
        [featureUser]="featureUserScreen"
        (clicked)="type === 'ADD' ? submit() : editSubmit()"
      >
        {{
          type === 'ADD'
            ? ('activity.create.submit' | translate)
            : ('activity.edit.submit' | translate)
        }}
      </base-button>
    </div>
  </div>
</ng-template>
