/* eslint-disable @typescript-eslint/no-namespace */

import { IFileNote } from '../equipments';

export namespace PartOfServiceGlobal {
  export interface FieldsIncludes {
    title: boolean;
    description: boolean;
    client: boolean;
    project: boolean;
    address: boolean;
    addressAdditional: boolean;
    date: boolean;

    customProperties: boolean;
    equipment: boolean;
    [key: string]: boolean;
  }

  export const DefaultFieldsToInclude = (): FieldsIncludes => {
    return {
      title: true,
      description: true,
      client: true,
      project: true,
      address: true,
      addressAdditional: true,
      date: true,

      customProperties: true,
      equipment: true,
    };
  };

  export const DefaultPartOfServiceSchema = ({
    idDeal,
  }: {
    idDeal: string;
  }): PartOfServiceSchema => {
    return {
      fields: DefaultFieldsToInclude(),
      idDeal,
      fieldsCustomProperties: {},
      fieldCustomGroupProperties: {},
    };
  };

  // This interface defines how the schema
  // part of service will be stored internally in the database.
  export interface PartOfServiceSchema {
    idDeal: string;
    fields: FieldsIncludes;
    fieldsCustomProperties: { [key: string]: boolean };
    fieldCustomGroupProperties: { [key: string]: boolean };
    description?: string;
    note?: string;
    notes?: IFileNote[];
    date?: Date;
    signCompany?: string;
    signClient?: string;
    files?: any[];
  }

  export type PartOfServiceUpdate = Partial<PartOfServiceSchema>;

  // This interface defines the way the schema
  // part of service will be exposed to the frontend
  export interface PartOfServiceResponse extends PartOfServiceSchema {
    _id: string;
  }

  export interface PartOfServiceReportFormat {
    clientAddress: string;
    clientEmail: string;
    clientFiscalName: string;
    clientLogo: string;
    clientName: string;
    companyCode: string;
    companyEmail: string;
    companyName: string;
    companyPhone: string;
    companyTradeName: string;
    customProperties: Array<{ title: string; value: string }>;
    customGroupProperties: Array<{ title: string; value: string }>;
    customGroupPropertyName: string;
    dealAddress: string;
    dealAddressAdditional: string;
    dealClient: string;
    dealDate: string;
    dealDescription: string;
    dealEquipment: string;
    dealProject: string;
    dealTitle: string;
    dealUrgency: string;
    partOfServiceDate: string;
    partOfServiceDescription: string;
    partOfServiceImages: string[];
    partOfServiceRef: string;
    partOfServiceSignatureClient: string;
    partOfServiceSignatureCompany: string;
  }
}
