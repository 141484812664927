<div class="counter-container">
  <h2 class="title">
    {{ 'clockAbsence.vacationDays' | translate }}
    <span class="custom-tooltip bottom textLeft">
      <img class="info__icon" src="assets/icons/gl_information-fill-gray.svg" />
      <div class="tiptext custom-tooltip-text left">
        <span>
          {{
            ((isAdmin$ | async)
              ? 'clockAbsence.tooltipVacationAdmin'
              : 'clockAbsence.tooltipVacationUser'
            ) | translate
          }}
        </span>
      </div>
    </span>
  </h2>
  <div class="counter-wrapper">
    <div class="counter">
      <div class="counter__number">
        <span
          class="counter__number--available"
          [ngClass]="{ disabled: !vacationReport?.available }"
        >
          {{ vacationReport?.available || '--' }}</span
        >
        <span *ngIf="vacationReport?.total" class="counter__number--total"
          >/ {{ vacationReport?.total || '--' }}</span
        >
      </div>
      <div class="counter__label">
        {{ 'clockAbsence.availableDays' | translate }}
      </div>
    </div>
    <div class="counter">
      <div class="counter__number">
        <span
          class="counter__number--available"
          [ngClass]="{ disabled: !vacationReport?.requested }"
          >{{ vacationReport?.requested || '--' }}</span
        >
      </div>
      <div class="counter__label">
        {{ 'clockAbsence.usedDays' | translate }}
      </div>
    </div>
  </div>
</div>
