import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BillingProfileDTO, CITIES } from '@tacliatech/types';
import { Observable, of } from 'rxjs';

import { GenericService } from '../generic';

@Injectable({
  providedIn: 'root',
})
export class BillingProfileService extends GenericService<BillingProfileDTO> {
  findAllByCompany(id: string) {
    return this.client.get<BillingProfileDTO[]>(
      `:API_URL/profile-billing/customer/${id}`
    );
  }

  constructor(http: HttpClient) {
    super(http, 'profile-billing');
  }

  getListCity(): Observable<{ id: string; nm: string }[]> {
    return of(CITIES);
  }
}
