export enum TaskType {
  Daily = 'DAILY',
}

export enum ChecklistMockType {
  Danger = 'Danger',
  Warning = 'Warning',
  Success = 'Success',
  Default = 'Default',
}

export enum TaskHistoryStatus {
  Checked = 'CHECKED',
  Error = 'ERROR',
  Draw = 'DRAW',
}
