<div class="wrapper__body">
  <div class="wrapper__body__content">
    <form [formGroup]="form">
      <div class="wrapper__body__content__title" style="width: fit-content">
        {{ 'finals.create.generalInfo' | translate }}
        <img
          *ngIf="isMexicanCompany"
          tooltipDir
          [tooltipBackground]="'blue'"
          [tooltipMessages]="[
            'finals.tooltips.generalInformation1',
            { type: 'html', content: 'finals.tooltips.generalInformation2' },
            { type: 'html', content: 'finals.tooltips.generalInformation3' },
            { type: 'html', content: 'finals.tooltips.generalInformation4' }
          ]"
          [tooltipPosition]="'bottom'"
          [tooltipShowOn]="'any'"
          [tooltipHideOn]="'click'"
          src="/assets/icons/gl_information-fill-gray.svg"
        />
      </div>

      <!-- name inputs -->
      <div class="wrapper__body__content__field">
        <div
          *ngIf="!(resize$ | async)?.payload?.mobile"
          class="wrapper__body__content__field__label"
        >
          <label class="field-label">
            {{ 'finals.create.name' | translate | uppercase
            }}<obligatory-field-sign></obligatory-field-sign>
          </label>
        </div>
        <div class="wrapper__body__content__field__content">
          <div
            class="input__wrapper wrapper-pro-feature-user"
            romaProSelect
            [featureUser]="
              type === 'ADD'
                ? featureRefUser.Final.create
                : featureRefUser.Final.update
            "
          >
            <label
              *ngIf="(resize$ | async)?.payload?.mobile"
              class="field-label"
            >
              {{ 'finals.create.name' | translate | uppercase
              }}<obligatory-field-sign></obligatory-field-sign>
            </label>
            <input
              #nameInput
              type="text"
              class="form-control md-input"
              formControlName="name"
              [placeholder]="'finals.create.name' | translate"
              [ngClass]="{
                'is-invalid': (nameError && form.get('name').errors) || false
              }"
              (blur)="onBlurMethod('nameInput', nameInput.value)"
              maxlength="255"
            />
            <img
              *ngIf="nameError && form.get('name').errors && !isLoading"
              class="input__error__icon"
              src="assets/icons/gl_input-error.svg"
            />
            <div
              *ngIf="nameError && form.get('name').errors && !isLoading"
              class="general-invalid-feedback"
              style="margin-top: -6px"
            >
              {{ 'general.required' | translate }}
            </div>
          </div>
        </div>
      </div>
      <!-- fiscalName -->
      <div class="wrapper__body__content__field">
        <div class="wrapper__body__content__field__label">
          <label class="field-label">
            {{ 'finals.create.fiscalName' | translate | uppercase }}
          </label>
        </div>
        <div
          class="wrapper__body__content__field__content wrapper-pro-feature-user"
          romaProSelect
          [featureUser]="
            type === 'ADD'
              ? featureRefUser.Final.create
              : featureRefUser.Final.update
          "
        >
          <input
            #fiscalNameInput
            type="text"
            class="form-control md-input"
            formControlName="fiscalName"
            [placeholder]="'finals.create.namePh' | translate"
            (blur)="onBlurMethod('fiscalNameInput', fiscalNameInput.value)"
            maxlength="255"
          />
          <div
            *ngIf="mexicanCompany && !fiscalNameInput.value"
            class="warning-invoice"
          >
            {{ 'budgets.settings.create.form.requireToInvoice' | translate }}
          </div>
        </div>
      </div>
      <!-- RFC -->
      <div *ngIf="mexicanCompany" class="wrapper__body__content__field">
        <div class="wrapper__body__content__field__label">
          <label class="field-label">
            {{ 'budgets.settings.create.form.RFC' | translate | uppercase }}
          </label>
        </div>
        <div
          class="wrapper__body__content__field__content wrapper-pro-feature-user"
          romaProSelect
          [featureUser]="
            type === 'ADD'
              ? featureRefUser.Final.create
              : featureRefUser.Final.update
          "
        >
          <input
            #rfcInput
            type="text"
            class="form-control md-input"
            formControlName="rfc"
            [placeholder]="
              'budgets.settings.create.form.RFCPlaceholder' | translate
            "
            (blur)="onBlurMethod('rfcInput', rfcInput.value)"
            maxlength="255"
          />
          <div
            *ngIf="mexicanCompany && !rfcInput.value"
            class="warning-invoice"
          >
            {{ 'budgets.settings.create.form.requireToInvoice' | translate }}
          </div>
        </div>
      </div>
      <!-- Regimen fiscal -->
      <div
        class="wrapper__body__content__field wrapper-pro-feature-user"
        romaProSelect
        *ngIf="mexicanCompany"
      >
        <div class="wrapper__body__content__field__label">
          <label class="field-label">
            {{
              'budgets.settings.create.form.fiscalRegime'
                | translate
                | uppercase
            }}
          </label>
        </div>
        <div
          class="wrapper__body__content__field__content wrapper-pro-feature-user"
          romaProSelect
          [featureUser]="
            type === 'ADD'
              ? featureRefUser.Final.create
              : featureRefUser.Final.update
          "
        >
          <ng-container *ngIf="loadingFiscalRegimes">
            <mat-spinner [diameter]="20" class="loading-spinner"></mat-spinner>
          </ng-container>
          <ng-container *ngIf="!loadingFiscalRegimes">
            <roma-rm-select-v2
              [isClearable]="true"
              [placeholderText]="'general.select' | translate"
              [items]="fiscalRegimes"
              [isAbsolute]="true"
              [showAddButton]="false"
              [addButtonText]="'general.addButton' | translate"
              (changeItems)="changeFiscalRegimeSelected($event)"
              [activeItems]="fiscalRegimeSelected"
              inputHeight="36px"
              style="width: 100%; margin-bottom: 5px"
            >
            </roma-rm-select-v2>
            <div
              *ngIf="mexicanCompany && fiscalRegimeSelected.length == 0"
              class="warning-invoice"
            >
              {{ 'budgets.settings.create.form.requireToInvoice' | translate }}
            </div>
          </ng-container>
        </div>
      </div>

      <!-- NIF -->
      <div class="wrapper__body__content__field">
        <div class="wrapper__body__content__field__label">
          <label class="field-label">
            {{ 'finals.create.nifShort' | translate | uppercase }}
          </label>
        </div>
        <div
          class="wrapper__body__content__field__content wrapper-pro-feature-user"
          romaProSelect
          [featureUser]="
            type === 'ADD'
              ? featureRefUser.Final.create
              : featureRefUser.Final.update
          "
        >
          <input
            #nifInput
            type="text"
            class="form-control md-input"
            formControlName="nif"
            [placeholder]="'finals.create.nifShortPh' | translate"
            (blur)="onBlurMethod('nifInput', nifInput.value)"
            maxlength="255"
          />
          <div
            *ngIf="mexicanCompany && !nifInput.value"
            class="warning-invoice"
          >
            {{
              'budgets.settings.create.form.warning_bdgsettings_client'
                | translate
            }}
          </div>
        </div>
      </div>
      <div class="wrapper__body__content__divider"></div>
      <div class="wrapper__body__content__title">
        {{ 'finals.create.contactInfo' | translate }}
      </div>
      <!-- email input -->
      <div class="wrapper__body__content__field">
        <div class="wrapper__body__content__field__label">
          <label class="field-label">
            {{ 'finals.create.email' | translate | uppercase }}
          </label>
        </div>
        <div
          class="wrapper__body__content__field__content wrapper-pro-feature-user"
          romaProSelect
          [featureUser]="
            type === 'ADD'
              ? featureRefUser.Final.create
              : featureRefUser.Final.update
          "
        >
          <div class="input__wrapper">
            <ng-container
              *ngFor="
                let email__input of [].constructor(totalEmailInputs)
                  | slice: 0:totalEmailInputs;
                let i = index
              "
            >
              <div class="email-input-wrapper" style="position: relative">
                <input
                  #emailInput
                  (blur)="onBlurMethod('emailInput', emailInput.value)"
                  *ngIf="activeEmailInput > i"
                  type="email"
                  class="form-control md-input"
                  [placeholder]="'finals.create.emailPh' | translate"
                  [formControlName]="'email' + i"
                  [ngClass]="{
                    'is-invalid':
                      (emailErrors && form.get('email' + i).errors) || false
                  }"
                  [value]="form.get('email' + i).value || ''"
                  style="margin-bottom: 12px"
                />
                <img
                  *ngIf="
                    emailErrors && form.get('email' + i).errors && !isLoading
                  "
                  class="input__error__icon"
                  src="assets/icons/gl_input-error.svg"
                />
              </div>
              <div
                *ngIf="
                  emailErrors && form.get('email' + i).errors && !isLoading
                "
                class="general-invalid-feedback"
                style="margin-top: -6px"
              >
                *{{ 'finals.create.emailValidation' | translate }}
              </div>
            </ng-container>
            <button
              *ngIf="activeEmailInput != totalPhoneInputs"
              type="button"
              class="add-field-btn"
              style="margin-top: -4px"
              (click)="activeEmailInput = activeEmailInput + 1; addEmailEvent()"
            >
              {{ 'general.add2' | translate }}
              <img src="assets/icons/gl_plus.svg" style="margin-top: -5px" />
            </button>
          </div>
        </div>
      </div>
      <!-- phone select input -->
      <div class="wrapper__body__content__field">
        <div class="wrapper__body__content__field__label">
          <label class="field-label">
            {{ 'finals.create.phone' | translate | uppercase }}
          </label>
        </div>
        <div
          class="wrapper__body__content__field__content wrapper-pro-feature-user"
          romaProSelect
          [featureUser]="
            type === 'ADD'
              ? featureRefUser.Final.create
              : featureRefUser.Final.update
          "
        >
          <div class="input__wrapper">
            <ng-container *ngFor="let phone of phonesArr; let i = index">
              <roma-rm-phone-select-input
                [phoneValue]="phone || null"
                [autoPrefix]="!phone.pre"
                (phoneChanges)="phoneChanges($event, i)"
              ></roma-rm-phone-select-input>
            </ng-container>
            <button
              *ngIf="phonesArr.length !== totalPhoneInputs"
              type="button"
              class="add-field-btn"
              style="margin-top: -4px"
              (click)="addPhoneEvent()"
            >
              {{ 'general.add2' | translate }}
              <img src="assets/icons/gl_plus.svg" style="margin-top: -5px" />
            </button>
          </div>
        </div>
      </div>
      <div class="wrapper__body__content__divider"></div>
      <!-- aditional information -->
      <div class="wrapper__body__content__title">
        {{ 'finals.create.additionalInformation' | translate }}
      </div>
      <!-- typeClientFinals -->
      <div
        class="wrapper__body__content__field wrapper-pro-feature-user"
        romaProSelect
      >
        <div class="wrapper__body__content__field__label">
          <label class="field-label">
            {{ 'finals.typeClientPh' | translate | uppercase }}
          </label>
        </div>
        <div
          class="wrapper__body__content__field__content wrapper-pro-feature-user"
          romaProSelect
          [featureUser]="
            type === 'ADD'
              ? featureRefUser.Final.create
              : featureRefUser.Final.update
          "
        >
          <roma-rm-select-v2
            [isClearable]="true"
            [placeholderText]="'general.select' | translate"
            [items]="typeClientFinals"
            [isAbsolute]="true"
            [showAddButton]="false"
            [activeItems]="selectedtypeClientFinals"
            [addButtonText]="'general.addButton' | translate"
            (changeItems)="changeTypeClientFinals($event)"
            inputHeight="36px"
            style="width: 100%"
          >
          </roma-rm-select-v2>
        </div>
      </div>
      <!-- users -->
      <div class="wrapper__body__content__field">
        <div class="wrapper__body__content__field__label">
          <label class="field-label">
            {{ 'general.users' | translate | uppercase }}
          </label>
        </div>
        <div
          class="wrapper__body__content__field__content wrapper-pro-feature-user"
          romaProSelect
          [featureUser]="
            type === 'ADD'
              ? featureRefUser.Final.create
              : featureRefUser.Final.update
          "
        >
          <roma-rm-select-v2
            mode="MULTIPLE"
            [isClearable]="true"
            [placeholderText]="'general.select' | translate"
            [isLoading]="usersIsLoading"
            [items]="users"
            [isAbsolute]="true"
            [showAddButton]="false"
            [activeItems]="selectedUsers"
            (changeItems)="changeUsers($event)"
            inputHeight="36px"
            style="width: 100%"
          ></roma-rm-select-v2>
        </div>
      </div>
      <!-- owner -->
      <div
        class="wrapper__body__content__field"
        *ngIf="checkboxItems.showOwnerInput"
      >
        <div class="wrapper__body__content__field__label">
          <label class="field-label">
            {{ 'finals.create.owner' | translate | uppercase }}
          </label>
        </div>
        <div
          class="wrapper__body__content__field__content wrapper-pro-feature-user"
          romaProSelect
          [featureUser]="
            type === 'ADD'
              ? featureRefUser.Final.create
              : featureRefUser.Final.update
          "
        >
          <roma-rm-select-v2
            [isClearable]="true"
            [placeholderText]="'general.select' | translate"
            [addButtonText]="'general.addButton' | translate"
            [items]="users"
            [isAbsolute]="true"
            [showAddButton]="false"
            [activeItems]="selectedOwner"
            (changeItems)="changeOwners($event)"
            inputHeight="36px"
            style="width: 100%"
          ></roma-rm-select-v2>
        </div>
      </div>
      <!-- catchmentChannels -->
      <div
        class="wrapper__body__content__field"
        *ngIf="checkboxItems.showChannelInput"
      >
        <div class="wrapper__body__content__field__label">
          <label class="field-label">
            {{ 'finals.create.catchmentChannel' | translate | uppercase }}
          </label>
        </div>
        <div
          class="wrapper__body__content__field__content wrapper-pro-feature-user"
          romaProSelect
          [featureUser]="
            type === 'ADD'
              ? featureRefUser.Final.create
              : featureRefUser.Final.update
          "
        >
          <roma-rm-select-v2
            [isClearable]="true"
            [placeholderText]="'general.select' | translate"
            [items]="catchmentChannels"
            [isAbsolute]="true"
            [showAddButton]="false"
            [addButtonText]="'general.addButton' | translate"
            [activeItems]="selectedCatchmentChannels"
            (changeItems)="changeCatchmentChannels($event)"
            inputHeight="36px"
            style="width: 100%"
          >
          </roma-rm-select-v2>
        </div>
      </div>
      <!-- view more option -->
      <button
        #seeMore="matMenuTrigger"
        type="button"
        class="add-field-btn see-more-btn"
        [matMenuTriggerFor]="inputsMenu"
        [ngClass]="{ 'is-open': seeMore.menuOpen }"
        style="float: none; padding: 0; text-align: left"
        (click)="sendSeeMoreEvent()"
      >
        {{ 'general.seeMore' | translate }}
        <img
          src="assets/icons/gl_arrow-down-blue.svg"
          style="margin-top: -5px"
        />
      </button>
      <mat-menu #inputsMenu="matMenu" class="dropdown-wrapper bordered-wrapper">
        <div class="list-wrapper">
          <div class="list-item">
            <mat-checkbox
              (click)="changeInputsCheckBox($event, 'showOwnerInput')"
              [checked]="checkboxItems.showOwnerInput"
            >
              {{ 'finals.create.owner' | translate }}
            </mat-checkbox>
          </div>
          <div class="list-item">
            <mat-checkbox
              (click)="changeInputsCheckBox($event, 'showChannelInput')"
              [checked]="checkboxItems.showChannelInput"
            >
              {{ 'finals.create.catchmentChannel' | translate }}
            </mat-checkbox>
          </div>
        </div>
      </mat-menu>
      <!--/ view more option -->
      <!-- address section -->
      <div class="wrapper__body__content__divider"></div>
      <div class="wrapper__body__content__title">
        {{ 'finals.create.address' | translate }}
      </div>
      <!-- address -->
      <div class="wrapper__body__content__field">
        <div class="wrapper__body__content__field__label">
          <label class="field-label">
            {{ 'finals.create.location' | translate | uppercase }}
            <img
              *ngIf="isMexicanCompany"
              class="ignore-field-label"
              tooltipDir
              [tooltipBackground]="'blue'"
              [tooltipMessages]="['finals.tooltips.location']"
              [tooltipPosition]="'right'"
              [tooltipShowOn]="'any'"
              [tooltipHideOn]="'click'"
              src="/assets/icons/gl_information-fill-gray.svg"
              style="height: 16px; width: 16px"
            />
          </label>
        </div>
        <div
          class="wrapper__body__content__field__content wrapper-pro-feature-user"
          romaProSelect
          [featureUser]="
            type === 'ADD'
              ? featureRefUser.Final.create
              : featureRefUser.Final.update
          "
          style="position: relative"
        >
          <input
            matInput
            #inputGoogleEditable="matInput"
            id="inputGoogleEditable"
            (blur)="
              onBlurMethod('inputGoogleEditable', inputGoogleEditable.value)
            "
            matGoogleMapsAutocomplete
            type="text"
            formControlName="address"
            class="form-control md-input"
            [placeholder]="'finals.create.addressPh' | translate"
            (onAutocompleteSelected)="autocompleteSelected($event)"
          />
          <div *ngIf="mexicanCompany" class="warning-invoice">
            <ng-container *ngIf="!inputGoogleEditable.value">
              {{ 'budgets.settings.create.form.requireToInvoice' | translate }}
            </ng-container>
            <ng-container
              *ngIf="inputGoogleEditable.value && !isValidMxAdrress()"
            >
              {{
                'budgets.settings.create.form.missingAddressData' | translate
              }}
            </ng-container>
          </div>

          <div
            *ngIf="
              inputGoogleEditable.focused &&
              inputGoogleEditable.value.length > 20
            "
            class="maxlength__tooltip"
          >
            {{ inputGoogleEditable.value }}
          </div>
        </div>
      </div>
      <!-- address deatil -->
      <div class="wrapper__body__content__field">
        <div class="wrapper__body__content__field__label">
          <label class="field-label">
            {{ 'finals.create.addressAdditional' | translate | uppercase }}
          </label>
        </div>
        <div
          class="wrapper__body__content__field__content wrapper-pro-feature-user"
          romaProSelect
          [featureUser]="
            type === 'ADD'
              ? featureRefUser.Final.create
              : featureRefUser.Final.update
          "
        >
          <input
            #inputDetailLocation
            (blur)="
              onBlurMethod('inputDetailLocation', inputDetailLocation.value)
            "
            type="text"
            formControlName="addressAdditional"
            class="form-control md-input"
            [placeholder]="'finals.create.addressAdditionalPh' | translate"
            (onAutocompleteSelected)="autocompleteSelected($event)"
          />
        </div>
      </div>
      <roma-address-map
        *ngIf="form.get('address').value"
        [address]="form.get('address').value"
      ></roma-address-map>
      <div class="wrapper__body__content__divider"></div>
      <!-- customProperties -->
      <div class="custom__properties__container row">
        <!-- custom-properties -->
        <h4
          class="subtitle"
          (click)="showCustomProperties = !showCustomProperties"
          style="cursor: pointer"
        >
          {{ 'settings.home-customization.customFieldsTitle' | translate }}
          <img
            *ngIf="!showCustomProperties"
            src="assets/icons/gl_up-arrow.svg"
          />
          <img
            *ngIf="showCustomProperties"
            src="assets/icons/gl_down_arrow.svg"
          />
        </h4>
        <!-- Edit custom properties -->
        <roma-edit-custom-properties
          *ngIf="type === 'EDIT'"
          [showCustomProperties]="showCustomProperties"
          [idSchema]="activityEditRef?._id"
          [typeRef]="typePropertyRef.Final"
          [parentForm]="form"
          [customProperties]="customerData?.customProperties"
          [feature]="featureRef.Final.HandleCustomProperties"
          [featureUser]="featureRefUser.Final.update"
          [options]="{ showAlertMessage: false }"
          [updateOnEdit]="false"
          (propertiesRequired)="propertiesRequired($event)"
          (updateLoading)="updateLoading($event)"
          (propertiesLoading)="propertiesLoading($event)"
          (onValueChanged)="eventValueChanged($event)"
          (closeModal)="close()"
          [showPropertiesMessage]="
            customPropertiesLoaded && customProperties.length === 0
          "
        ></roma-edit-custom-properties>
        <!-- Add custom properties -->
        <roma-append-custom-properties
          *ngIf="type === 'ADD'"
          [typeRef]="typePropertyRef.Final"
          [control]="form.get('customProperties')"
          [feature]="featureRef.Final.HandleCustomProperties"
          [showCustomProperties]="showCustomProperties"
          [parentForm]="form"
          [initForm]="initChildForm"
          [parentFormSubmit]="parentFormSubmit"
          [showIsLoading]="type === 'ADD'"
          (customPropertiesForm)="customPropertiesFormChange($event)"
          (propertiesLoading)="type !== 'EDIT' && propertiesLoading($event)"
          (closeModal)="close()"
          [showPropertiesMessage]="
            customPropertiesLoaded && customProperties.length === 0
          "
        ></roma-append-custom-properties>
      </div>
    </form>
  </div>
  <div class="wrapper__body__footer">
    <!-- footer of body -->
    <!-- [matTooltip]="showTooltipSave()" -->

    <button
      type="button"
      role="button"
      class="footer-button roma-button save-btn"
      [disabled]="isLoading || propertiesIsLoading || invalidEditCustomFields"
      (clicked)="type === 'ADD' ? submit() : editSubmit()"
      romaProFeature
      [featureUser]="
        type === 'ADD'
          ? featureRefUser.Final.create
          : featureRefUser.Final.update
      "
    >
      <mat-progress-spinner
        *ngIf="isLoading"
        class="loading-spinner"
        mode="indeterminate"
        [diameter]="20"
      ></mat-progress-spinner>
      <ng-container *ngIf="!isLoading">
        {{
          type === 'ADD'
            ? ('activity.create.submit' | translate)
            : ('activity.edit.submit' | translate)
        }}
      </ng-container>
    </button>

    <button
      *ngIf="type === 'EDIT' && !isLoading && !isLoadingDetailOfCustomer"
      type="button"
      class="footer-button delete-btn"
      romaClickDelete
      romaProFeature
      [featureUser]="featureRefUser.Final.delete"
      (clicked)="sendEvent()"
      (wantDelete)="eventDelete()"
      romaProFeature
      [featureUser]="featureRefUser.Final.delete"
    >
      <img src="assets/icons/gl_trash_red.svg" />
      {{ 'general.deleteAlt' | translate }}
    </button>
  </div>
</div>
<div
  class="wrapper__sidebar wrapper-pro-feature-user"
  romaProSelect
  [featureUser]="
    type === 'ADD' ? featureRefUser.Final.create : featureRefUser.Final.update
  "
>
  <!-- sidebar definition -->
  <h4
    class="subtitle"
    [ngClass]="{ middle: !showDivImg }"
    (click)="toggleDivImg()"
  >
    {{ 'general.fileAttach' | translate }}
    <img *ngIf="!showDivImg" src="assets/icons/gl_up-arrow.svg" />
    <img *ngIf="showDivImg" src="assets/icons/gl_down_arrow.svg" />
  </h4>

  <div *ngIf="showDivImg">
    <roma-image-selector
      [type]="'final'"
      [quantity]="!hasFeatureHandleFinalCustomProperty ? 1 : 10"
      [title]="'fileUpload.maxSize' | translate"
      [feature]="featureRef.Final.UploadFiles"
      [fileArray]="imagesParsed"
      [featureUser]="featureUserScreen"
      (changeFileArray)="changeFileArray($event)"
      [module]="idModuleRef.Finals"
    >
    </roma-image-selector>
  </div>

  <h4 class="subtitle" (click)="toggleDivComments()">
    {{ 'equipments.create.notes' | translate }}
    <img *ngIf="!showDivComments" src="assets/icons/gl_up-arrow.svg" />
    <img *ngIf="showDivComments" src="assets/icons/gl_down_arrow.svg" />
  </h4>
  <div
    romaProSelect
    class="wrapper-pro-feature-user"
    [featureUser]="featureUserScreen"
  >
    <roma-equipment-notes
      [ngStyle]="!showDivComments && { visibility: 'hidden', height: '0' }"
      [mode]="type"
      [data]="customerNotes || []"
      (changeNotes)="addingNotes($event)"
    ></roma-equipment-notes>
  </div>
</div>

<!-- <div class="permissions__overlay"       romaProFeature
[featureUser]="featureRefUser.Activity.show"></div> -->
