<div class="wrapper-container">
  <div class="form-group" style="position: relative">
    <mat-form-field
      [ngClass]="{ 'has-date': dateStorage }"
      class="date-picker"
      appearance="fill"
      style="margin-bottom: 0"
    >
      <input
        #singleDate
        matInput
        [matDatepicker]="picker"
        [disabled]="disabled"
        [(ngModel)]="dateStorage"
        [readonly]="true"
        (ngModelChange)="emitChanges()"
        style="padding-left: 12px; cursor: pointer"
        (click)="picker.open()"
      />
      <mat-datepicker-toggle
        *ngIf="!singleDate.value"
        matSuffix
        [for]="picker"
        style="height: 35px"
      >
        <mat-icon
          matDatepickerToggleIcon
          style="height: 35px; width: 24px; position: relative; display: block"
        >
          <img
            class="calendar-icon"
            src="assets/icons/gl_calendar.svg"
            style="top: 3px"
          />
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <div
        *ngIf="singleDate.value === ''"
        class="fake-placeholder"
        (click)="picker.open()"
      >
        {{ 'deals.dealDetail.visitDate' | translate }}
      </div>
      <div
        class="calendar-clean-icon"
        matSuffix
        style="height: 35px"
        (click)="dateStorage = null; singleDate.value = ''; emitChanges()"
        *ngIf="dateStorage != null"
      >
        <mat-icon
          matDatepickerToggleIcon
          style="height: 36px; position: relative"
        >
          <img class="calendar-icon" src="assets/icons/gl_close-input.svg" />
        </mat-icon>
      </div>
    </mat-form-field>
  </div>
  <div class="hours-container" *ngIf="showHoursInput">
    <input
      class="form-control"
      #timeDate
      id="timeDate"
      [placeholder]="'clockHour.create.form.startDatePlaceholder' | translate"
      matInput
      mask="Hh:m0"
      [showMaskTyped]="true"
      [dropSpecialCharacters]="false"
      [(ngModel)]="selectedHour"
      (change)="hourChange(timeDate.value)"
      (blur)="hourChange(timeDate.value)"
    />
  </div>
  <div class="btn-group" role="group">
    <button
      *ngIf="showSelectorRecurrence"
      type="button"
      class="btn btn-light dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      [disabled]="!dateStorage || disabled"
      (click)="launchModalCreate()"
    >
      <ng-container *ngIf="!recurrence">
        {{ 'hiring.type.repited' | translate }}
      </ng-container>
      <ng-container *ngIf="recurrence">
        {{ 'general.R' + recurrence.periodicity | translate }}
      </ng-container>
    </button>
  </div>
</div>
