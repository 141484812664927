/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-namespace */
import Stripe from 'stripe';
import { StripeErrorsCode } from '../../enum';

export namespace TacliaPayments {
  export namespace Security {
    export enum AuthType {
      CONNECTION_TOKEN = 'CONNECTION_TOKEN',
    }
  }
  export namespace Intent {
    export interface Request {
      amount: number;
      connectedAccount: string;
      description: string;
      currency: string;
      metadata: Record<string, any>;
    }

    export interface Response {
      intentId: number;
      connectedAccount: string;
      metadata: Record<string, any>;
    }
  }

  export namespace Transfer {
    export interface ICustomerData {
      email?: string;
      description?: string;
      name?: string;
    }

    export interface Request {
      customerData: ICustomerData;
      amount: number;
      currency: string;
      bank_transfer_type?: Stripe.PaymentIntent.PaymentMethodOptions.CustomerBalance.BankTransfer.Type;
      bank_transfer_country?: string;
    }
  }

  // --------------------------------------------------------------

  /**
   * Terminal objects
   */
  // --------------------------------------------------------------
  export namespace Terminal {
    export interface HardwareSku {
      id: string;
      object: string;
      amount: number;
      country: string;
      currency: string;
      orderable: number;
      product: string;
      status: string;
      unavailable_after: number;
    }

    export interface ITerminalError {
      code: string;
      message: string;
    }

    export type TerminalError = ITerminalError;
    // --------------------------------------------------------------
  }

  /**
   * Credit Cards Objects
   */
  export namespace CreditCard {
    /**
     * Confirmation of credit card Payments
     */
    // --------------------------------------------------------------
    export interface ModalRequest {
      idSaleTicket: string;
    }
    export interface ModalResponse {
      confirmPayment: boolean;
    }

    export interface ConnectionTokenRequest {
      locationId: string;
      companyId: string;
    }
  }

  export interface Onboarding {
    status: StatusAccount;
    statusDetail?: string;
    onboardingUrl?: string;
    expireUrl?: number;
  }
  export interface IStripe {
    connectedAccountId?: string;
    enabledToCharge: boolean;
    onboarding: TacliaPayments.Onboarding;
    snackbarClosed?: boolean;
    reader?: {
      requested: boolean;
    };
  }

  export interface AccountSession {
    client_secret: string;
  }

  export enum StatusAccount {
    APPROVED = 'approved',
    APPROVED_WITH_WARNING = 'approved_with_warning',
    NOT_COMPLETED = 'not_completed',
    NOT_EXISTS = 'not_exists',
    NOT_STARTED = 'not_started',
    PENDING = 'pending',
    REJECTED = 'rejected',
    SENT = 'sent',
    SUSPENDED = 'suspended',
  }

  export enum Status {
    Pending,
    Rejected,
    Approved,
    Other,
  }

  export namespace Location {
    export interface Address {
      city: string;
      country: string;
      line1: string;
      line2?: string;
      postalCode: string;
      state: string;
    }
    export interface CreateRequest {
      displayName: string;
      address: Address;
    }

    export interface UpdateRequest extends Partial<CreateRequest> {}
  }

  export namespace Reader {
    export interface CreateRequest {
      registrationCode: string;
      locationId: string;
      label?: string;
    }

    export interface UpdateRequest {
      label: string;
    }
  }

  /**
   * Error handled Objects
   */
  export namespace Errors {
    /**
     * Terminal objects
     */
    // --------------------------------------------------------------

    export const handleBackendErrors = (error: any): any => {
      if (error instanceof Stripe.errors.StripePermissionError) {
        console.log('handleBackendErrors StripePermissionError', error.code);
      }

      if (error instanceof Stripe.errors.StripeInvalidRequestError) {
        switch (error.code) {
          case TacliaPayments.Errors.StripeErrorEnum.PARAMETER_INVALID_INTEGER:
            break;

          default:
            break;
        }
        if (
          error.code ===
          TacliaPayments.Errors.StripeErrorEnum.PARAMETER_INVALID_INTEGER
        )
          return StripeErrorsCode.StripeInvalidAmount;
        if (
          error.code === TacliaPayments.Errors.StripeErrorEnum.RESOURCE_MISSING
        )
          return StripeErrorsCode.ResourseMissing;

        if (
          error.code ===
          TacliaPayments.Errors.StripeErrorEnum.INVALID_REQUEST_ERROR
        ) {
          return StripeErrorsCode.ResourseMissing;
        }
      } else {
        return StripeErrorsCode.StripeDefaultError;
      }
    };

    /* The `export enum StripeErrorEnum` is defining an enumeration in TypeScript. It is creating a
     set of named constants that represent different error codes related to Stripe payments. Each
     constant is assigned a string value that describes the specific error. */
    export enum StripeErrorEnum {
      DEFAULT = 'Stripe internal server error',
      INVALID_TEST_CARD = 'invalid_test_card',
      CARD_DECLINED = 'card_declined',
      AUTHENTICATION_REQUIRED = 'authentication_required',
      AMOUNT_INVALID = 'stripe_invalid_amount',
      INVALID_REQUEST_ERROR = 'invalid_request_error',
      GENERAL_ERROR = 'stripe_general_error',
      READER_CONNECT_ERROR = 'stripe_reader_connect_error',
      DISCOVER_READER_CONNECT_ERROR = 'stripe_discover_reader_error',
      DISCOVER_READER_EMPTY = 'stripe_discover_reader_empty',
      PARAMETER_INVALID_INTEGER = 'parameter_invalid_integer',
      RESOURCE_MISSING = 'resource_missing',
      STRIPE_RESOURCE_MISSING = 'stripe_resource_missing',
    }

    /**
     * Terminal objects
     */
    // --------------------------------------------------------------

    /**
     * The function `getStripeError` takes in an error code and returns the corresponding error
     * message for that code in Spanish.
     * @param {StripeErrorEnum} errorCode - The `errorCode` parameter is of type `StripeErrorEnum`. It
     * is used to determine the specific error code for which we want to retrieve the corresponding
     * error message.
     * @returns an error message based on the provided `errorCode`. If the `errorCode` matches one of
     * the predefined StripeErrorEnum values, the corresponding error message will be returned. If the
     * `errorCode` does not match any of the predefined values, the default error message will be
     * returned.
     */
    export const getStripeError = (errorCode: string) => {
      const errors = {
        [StripeErrorEnum.INVALID_TEST_CARD]: 'Numero de tarjeta inválida',
        [StripeErrorEnum.CARD_DECLINED]:
          'La tarjeta tiene fondos insuficientes',
        [StripeErrorEnum.AUTHENTICATION_REQUIRED]:
          'La tarjeta ha sido rechazada. Esta transacción requiere autenticación.',
        [StripeErrorEnum.AMOUNT_INVALID]:
          'El monto debe ser mayor o igual a 1 EUR',
        [StripeErrorEnum.READER_CONNECT_ERROR]:
          'No se pudo establecer conexion con el lector',
        [StripeErrorEnum.DISCOVER_READER_CONNECT_ERROR]:
          'Error al intentar buscar lectores de pagos',
        [StripeErrorEnum.DISCOVER_READER_EMPTY]: 'No posee lectores de pagos',
        [StripeErrorEnum.STRIPE_RESOURCE_MISSING]:
          'No se ha encontrado el recurso ,intente mas tarde porfavor.',
        [StripeErrorEnum.DEFAULT]: 'Error interno del servidor Stripe',
        default: StripeErrorEnum.DEFAULT,
      };
      return errors[errorCode];
    };
  }
  export namespace Taxes {
    export const TacliaTax = 1.5 / 100;
    export const StripeTax = 0.35 / 100;
    export const StripeTaxTransaction = 0.08;
  }
}
