import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';

import { ChipsResumeComponent } from './chips-resume.component';
import { UsersListResumeModule } from '../users-list-resume/users-list-resume.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ChipsResumeComponent],
  imports: [
    CommonModule,
    MatChipsModule,
    TranslateModule.forChild(),
    UsersListResumeModule,
  ],
  exports: [ChipsResumeComponent],
})
export class ChipsResumeModule {}
