import * as Sentry from '@sentry/angular';
import { environment } from '@web-frontend/environments';

const envsAllowed = ['app', 'staging', 'dev1', 'dev2', 'dev3'];

const hostname = window.location.hostname;
const isLocal = /^(localhost|172\.16|192\.168)/.test(hostname);
const env = isLocal ? 'local' : hostname.split('.')[0];

const envIsAllowed = (env: string): boolean => envsAllowed.includes(env);

export function InitSentry(): void {
  try {
    if (envIsAllowed(env)) {
      sentrySetup();
    }
  } catch (error) {
    console.error('Error initializing Sentry', error);
  }

  function sentrySetup() {
    Sentry.init({
      dsn: environment.dsnSentry,
      environment: env,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserApiErrorsIntegration({
          setTimeout: true,
          setInterval: true,
          requestAnimationFrame: true,
          XMLHttpRequest: true,
          eventTarget: true,
        }),
        Sentry.sessionTimingIntegration(),
        Sentry.httpClientIntegration({
          failedRequestStatusCodes: [429, [500, 599]],
        }),
        Sentry.browserProfilingIntegration(),
      ],
      sendClientReports: false,
      tracesSampleRate: 0.7,
      tracePropagationTargets: [
        `${environment.api.type}:${environment.api.host}/${environment.api.prefix}`,
      ],
      replaysSessionSampleRate: environment.production ? 0.3 : 1,
      replaysOnErrorSampleRate: environment.production ? 0.3 : 1,
    });
  }
}
