<div
  (mousedown)="handleMousedown($event)"
  [class.ng-appearance-outline]="appearance === 'outline'"
  [class.ng-has-value]="hasValue"
  class="ng-select-container"
>
  <div class="ng-value-container">
    <div class="ng-placeholder">{{ placeholder }}</div>
    <ng-container
      *ngIf="(!multiLabelTemplate || !multiple) && selectedItems.length > 0"
    >
      <div *ngIf="!selectedItems[0].label" class="absolute-loading">
        <div class="ng-spinner-loader"></div>
      </div>
      <div
        *ngFor="let item of selectedItems; trackBy: trackByOption"
        [class.ng-value-disabled]="item.disabled"
        class="ng-value"
        [attr.title]="deepLink ? 'Ver detalles' : ''"
        (click)="onItemClick(item)"
      >
        <ng-template #defaultLabelTemplate>
          <span
            class="ng-value-icon left"
            (click)="unselect(item)"
            aria-hidden="true"
            *ngIf="item.label"
          >
            ×
          </span>
          <span
            class="ng-value-label"
            [ngItemLabel]="item.label"
            [escape]="escapeHTML"
            *ngIf="item.label"
          ></span>
        </ng-template>

        <ng-template
          [ngTemplateOutlet]="labelTemplate || defaultLabelTemplate"
          [ngTemplateOutletContext]="{
            item: item.value,
            clear: clearItem,
            label: item.label
          }"
        >
        </ng-template>
      </div>
    </ng-container>

    <ng-template
      *ngIf="multiple && multiLabelTemplate && selectedValues.length > 0"
      [ngTemplateOutlet]="multiLabelTemplate"
      [ngTemplateOutletContext]="{ items: selectedValues, clear: clearItem }"
    >
    </ng-template>

    <div
      class="ng-input"
      role="combobox"
      [attr.aria-expanded]="isOpen"
      [attr.aria-owns]="isOpen ? dropdownId : null"
      aria-haspopup="listbox"
    >
      <input
        #searchInput
        [attr.id]="labelForId"
        [attr.tabindex]="tabIndex"
        [readOnly]="!searchable || itemsList.maxItemsSelected"
        [disabled]="disabled"
        [value]="searchTerm ? searchTerm : ''"
        (input)="filter(searchInput.value)"
        (compositionstart)="onCompositionStart()"
        (compositionend)="onCompositionEnd(searchInput.value)"
        (focus)="onInputFocus($event)"
        (blur)="onInputBlur($event)"
        (change)="$event.stopPropagation()"
        [attr.aria-activedescendant]="isOpen ? itemsList?.markedItem?.htmlId : null"
        aria-autocomplete="list"
        [attr.aria-controls]="isOpen ? dropdownId : null"
      />
    </div>
  </div>
  <ng-container *ngIf="loading">
    <ng-template #defaultLoadingSpinnerTemplate>
      <div class="ng-spinner-loader"></div>
    </ng-template>

    <ng-template
      [ngTemplateOutlet]="
        loadingSpinnerTemplate || defaultLoadingSpinnerTemplate
      "
    >
    </ng-template>
  </ng-container>
  <span
    *ngIf="showClear() && selectedItems[0].label"
    class="ng-clear-wrapper"
    title="{{ clearAllText }}"
  >
    <span class="ng-clear" aria-hidden="true">×</span>
  </span>

  <span class="ng-arrow-wrapper">
    <span class="ng-arrow"></span>
  </span>
</div>

<ng-dropdown-panel
  *ngIf="isOpen"
  class="ng-dropdown-panel"
  [virtualScroll]="virtualScroll"
  [bufferAmount]="bufferAmount"
  [appendTo]="appendTo"
  [position]="dropdownPosition"
  [headerTemplate]="headerTemplate"
  [footerTemplate]="footerTemplate"
  [filterValue]="searchTerm"
  [items]="itemsList.filteredItems"
  [markedItem]="itemsList.markedItem"
  (update)="viewPortItems = $event"
  (scroll)="scroll.emit($event)"
  (scrollToEnd)="scrollToEnd.emit($event)"
  (outsideClick)="close()"
  [class.ng-select-multiple]="multiple"
  [ngClass]="appendTo ? classes : null"
  [id]="dropdownId"
  role="listbox"
  aria-label="Options list"
>
  <ng-container>
    <div
      *ngFor="let item of viewPortItems; trackBy: trackByOption"
      class="ng-option"
      [attr.role]="item.children ? 'group' : 'option'"
      [class.ng-option-disabled]="item.disabled"
      [class.ng-option-selected]="item.selected"
      [class.ng-optgroup]="item.children"
      [class.ng-option]="!item.children"
      [class.ng-option-child]="!!item.parent"
      [class.ng-option-marked]="item === itemsList.markedItem"
      [attr.aria-selected]="item.selected"
      [attr.id]="item?.htmlId"
      (click)="toggleItem(item)"
      (mouseover)="onItemHover(item)"
    >
      <ng-template #defaultOptionTemplate>
        <span
          class="ng-option-label"
          [ngItemLabel]="item.label"
          [escape]="escapeHTML"
        ></span>
      </ng-template>

      <ng-template
        [ngTemplateOutlet]="
          item.children
            ? optgroupTemplate || defaultOptionTemplate
            : optionTemplate || defaultOptionTemplate
        "
        [ngTemplateOutletContext]="{
          item: item.value,
          item$: item,
          index: item.index,
          searchTerm: searchTerm
        }"
      >
      </ng-template>
    </div>

    <div
      class="ng-option"
      [class.ng-option-marked]="!itemsList.markedItem"
      (mouseover)="itemsList.unmarkItem()"
      role="option"
      (click)="selectTag()"
      *ngIf="showAddTag"
    >
      <ng-template #defaultTagTemplate>
        <span
          ><span class="ng-tag-label">{{ addTagText }}</span
          >"{{ searchTerm }}"</span
        >
      </ng-template>

      <ng-template
        [ngTemplateOutlet]="tagTemplate || defaultTagTemplate"
        [ngTemplateOutletContext]="{ searchTerm: searchTerm }"
      >
      </ng-template>
    </div>
  </ng-container>

  <ng-container *ngIf="showNoItemsFound()">
    <ng-template #defaultNotFoundTemplate>
      <div class="ng-option ng-option-disabled">{{ notFoundText }}</div>
    </ng-template>

    <ng-template
      [ngTemplateOutlet]="notFoundTemplate || defaultNotFoundTemplate"
      [ngTemplateOutletContext]="{ searchTerm: searchTerm }"
    >
    </ng-template>
  </ng-container>

  <ng-container *ngIf="showTypeToSearch()">
    <ng-template #defaultTypeToSearchTemplate>
      <div class="ng-option ng-option-disabled">{{ typeToSearchText }}</div>
    </ng-template>

    <ng-template
      [ngTemplateOutlet]="typeToSearchTemplate || defaultTypeToSearchTemplate"
    >
    </ng-template>
  </ng-container>

  <ng-container *ngIf="loading && itemsList.filteredItems.length === 0">
    <ng-template #defaultLoadingTextTemplate>
      <div class="ng-option ng-option-disabled">{{ loadingText }}</div>
    </ng-template>

    <ng-template
      [ngTemplateOutlet]="loadingTextTemplate || defaultLoadingTextTemplate"
      [ngTemplateOutletContext]="{ searchTerm: searchTerm }"
    >
    </ng-template>
  </ng-container>
</ng-dropdown-panel>
