<ng-container>
  <div class="wrapper__body">
    <div class="wrapper__body__content">
      <div [ngClass]="{ 'is-hidden': isLoading }">
        <roma-activities
          [customer]="customer"
          [showCalendar]="false"
        ></roma-activities>
      </div>
    </div>
  </div>
</ng-container>
