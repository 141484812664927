<div class="custom-modal__close" (click)="cancel($event)">
  <img [src]="'assets/icons/gl_close.svg'" />
</div>

<div class="modal__title">
  {{ params?.title | translate }}
</div>

<mat-radio-group
  *ngIf="params?.options?.length"
  class="modal__options"
  (change)="changeParamsOptions($event)"
>
  <mat-radio-button
    *ngFor="let option of params?.options"
    class="modal__options__item"
    [value]="option?.value"
  >
    {{ option?.title | translate }}
  </mat-radio-button>
</mat-radio-group>

<div class="modal-actions">
  <button
    class="roma-button"
    [disabled]="!optionSelected"
    (click)="makeAction()"
  >
    {{ params?.actionText | translate }}
  </button>
</div>
