import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'roma-choose-week',
  templateUrl: './choose-week.component.html',
  styleUrls: ['./choose-week.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseWeekComponent implements OnInit {
  @Input()
  set actives(values: number[]) {
    const days = this.days.filter((item, index) => values.includes(index));
    this.activeDays = new Set(days);
    this.draw();
  }

  @Input()
  multiple = false;

  @Output()
  changeActiveDay = new EventEmitter<number[]>();

  days: string[] = [
    'Domingo',
    'Lunesdrt',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado',
  ];

  activeDays = new Set<string>();

  constructor(private cdRef: ChangeDetectorRef) {}

  get activeDaysArray() {
    return [...this.activeDays];
  }

  ngOnInit(): void {}

  changeActiveDayEvt(day: string) {
    if (this.multiple) {
      if (this.activeDays.has(day)) {
        this.activeDays.delete(day);
      } else {
        this.activeDays.add(day);
      }
    } else {
      this.activeDays.clear();
      this.activeDays.add(day);
    }

    this.emitActiveElements();
    this.draw();
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  private emitActiveElements() {
    const days = [...this.activeDays].map((el) => this.days.indexOf(el));
    this.changeActiveDay.emit(days);
  }
}
