import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BudgetSequenceSettingsComponent } from './budget-sequence-settings.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { CreateSequenceModule } from '../create-sequence/create-sequence.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RmSelectV3Module } from '../globals/rm-select-v3';

@NgModule({
  declarations: [BudgetSequenceSettingsComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgSelectModule,
    ClickDeleteModule,
    CreateSequenceModule,
    RmSelectV3Module,
  ],
  exports: [BudgetSequenceSettingsComponent],
})
export class BudgetSequenceSettingsModule {}
