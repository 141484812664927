import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ClockHourService } from '@web-frontend/shared/services';
import { ClockHourView, TeamMember } from '../clock-hour.type';
import { environment } from '@web-frontend/environments';
import { sortBy } from 'lodash';
import { Router } from '@angular/router';
import { AnalyticsService } from '../../../../shared/services/analytics/analytics.service';
import AmplitudeEvents from '../../../../../types/amplitude.enum';
import { ClockHour, Feature, FeatureUser } from '../../../../../types-legacy';
import { ToastService } from '../../../../shared/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { FileWriteService } from '../../../../shared/helpers/file-write';
import { DatePipe } from '@angular/common';
import { DownloadBtnService } from '../../../../shared/components/download-btn/download-btn.service';
import { downloadZip } from '@web-frontend/shared/utils/download-file';

type MappedData = {
  id: string;
  name: string;
  avatar: string;
  workedHours: string;
  remainingHours: string;
  extraHours: string;
  pendingRegisters: number | string;
  haveContractHours: boolean;
};

@Component({
  selector: 'roma-list-team-view',
  templateUrl: './list-team-view.component.html',
  styleUrls: ['./list-team-view.component.scss'],
})
export class ListTeamViewComponent implements OnInit {
  @Input() dateRange: {
    startDate: string;
    endDate: string;
    selectedPeriod: ClockHourView;
  };
  @Input() teamMember: string;
  @Input() updateMetrics = false;
  @Output() downloadAll = new EventEmitter<void>();

  displayedColumns: string[] = [
    'select',
    'name',
    'workedHours',
    'remainingHours',
    'extraHours',
    'pendingRegisters',
  ];
  dataSource: TeamMember[] = [];
  mappedDataSource: MappedData[] = [];
  selection = new SelectionModel<MappedData>(true, []);
  auxSort = { active: null, direction: null };
  columnPrefix = '(--^--)';
  loadingList = true;
  iconDialog = 'assets/icons/gl_information-fill-gray.svg';
  defaultAvatar = 'assets/icons/table-user-icon.svg';
  hasSelectedItems = false;
  featureRef = Feature;
  featureUserRef = FeatureUser;
  statusRef = ClockHour.Status;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly clockHourService: ClockHourService,
    private readonly router: Router,
    private readonly analyticsService: AnalyticsService,
    private readonly toastService: ToastService,
    private readonly i18n: TranslateService,
    private readonly downloadBtnService: DownloadBtnService
  ) {
    this.downloadAll.subscribe(() => {
      this.downloadReport('all');
    });
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dateRange || changes.teamMember || changes.updateMetrics) {
      this.unselectItems();
      this.getTeamMetrics();
    }
  }

  get hasItemsToApproveOrReject(): boolean {
    return this.selection.selected.some((item) => {
      const pendingRegisters = Number(item.pendingRegisters);
      return !isNaN(pendingRegisters) && pendingRegisters > 0;
    });
  }

  get countItemsWithPendingRegisters(): number {
    return this.selection.selected.reduce((total, item) => {
      const pendingRegisters = Number(item.pendingRegisters);
      if (!isNaN(pendingRegisters) && pendingRegisters > 0) {
        return total + pendingRegisters;
      }
      return total;
    }, 0);
  }

  getTeamMetrics() {
    this.loadingList = true;

    if (!this.dateRange.startDate || !this.dateRange.endDate) {
      this.loadingList = false;
      return;
    }

    this.clockHourService
      .getTeamMetrics(
        this.dateRange.startDate,
        this.dateRange.endDate,
        this.teamMember
      )
      .subscribe(
        (response) => {
          this.dataSource = response;
          this.formatData();
        },
        (error) => {
          console.error('Error getting team metrics', error);
          this.loadingList = false;
          this.cd.detectChanges();
        }
      );
  }

  formatData() {
    this.mappedDataSource = this.dataSource.map((item) => {
      return {
        id: item.user.id,
        name: item.user.name,
        avatar: item.user.avatar
          ? `${environment.firebaseConfig.storageUrl}/uploads%2Fuser%2F${item.user.avatar}`
          : '',
        workedHours: this.formatHours(item.metrics.workedHours),
        remainingHours: this.formatHours(item.metrics.remainingHours),
        extraHours: this.formatHours(item.metrics.extraHours),
        pendingRegisters: item.metrics.pendingRegisters,
        haveContractHours: !!item.metrics.contractHours,
      };
    });
    this.loadingList = false;
    this.cd.detectChanges();
  }

  formatHours(timestamp: number | string): string {
    const numericTimestamp = Number(timestamp);

    if (isNaN(numericTimestamp) || numericTimestamp < 0) {
      return '-- --';
    }

    const hours = Math.floor(numericTimestamp / 3600000)
      .toString()
      .padStart(2, '0');
    const minutes = Math.floor((numericTimestamp % 3600000) / 60000)
      .toString()
      .padStart(2, '0');
    return `${hours}h ${minutes}m`;
  }

  toggleSelection(row: MappedData) {
    this.selection.toggle(row);
    this.hasSelectedItems = this.selection.selected.length > 0;
    this.cd.detectChanges();
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.mappedDataSource.forEach((row) => this.selection.select(row));
    this.hasSelectedItems = this.selection.selected.length > 0;
    this.cd.detectChanges();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.mappedDataSource.length;
    return numSelected === numRows;
  }

  isIndeterminate() {
    const numSelected = this.selection.selected.length;
    const numRows = this.mappedDataSource.length;
    return numSelected > 0 && numSelected < numRows;
  }

  trackById(index: number, item: MappedData): string {
    return item.id;
  }

  sortData(sort) {
    const { active, direction } = sort;

    this.auxSort = { active, direction };

    if (direction === '') {
      this.formatData();
      return;
    }

    const isAsc = direction === 'asc';

    this.mappedDataSource = sortBy(this.mappedDataSource, [
      active,
    ]) as MappedData[];
    if (!isAsc) {
      this.mappedDataSource.reverse();
    }

    this.cd.detectChanges();
  }

  goToUserDetail(user: MappedData) {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.trackingTimeTeam_userView,
    });
    this.router.navigate([`/admin/clock-hours/detail/${user.id}`], {
      queryParams: {
        from: this.dateRange.startDate,
        to: this.dateRange.endDate,
        view: this.dateRange.selectedPeriod,
      },
    });
  }

  updateStatusClockHour(
    status: ClockHour.Status.Approved | ClockHour.Status.Rejected
  ) {
    if (status === ClockHour.Status.Approved) {
      this.analyticsService.trackEvent({
        sources: ['amplitude', 'userflow'],
        eventName: AmplitudeEvents.trackingTimeTeam_bulk_approve,
        eventProperties: {
          event_location: 'team_list',
        },
      });
    } else {
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: AmplitudeEvents.trackingTimeTeam_bulk_decline,
        eventProperties: {
          event_location: 'team_list',
        },
      });
    }

    const params: {
      startDate: string;
      endDate: string;
      status: ClockHour.Status.Approved | ClockHour.Status.Rejected;
      ids: string[];
    } = {
      startDate: this.dateRange.startDate,
      endDate: this.dateRange.endDate,
      status,
      ids: [],
    };

    this.selection.selected.forEach((item) => {
      const pendingRegisters = Number(item.pendingRegisters);
      if (!isNaN(pendingRegisters) && pendingRegisters > 0) {
        params.ids.push(item.id);
      }
    });

    this.clockHourService
      .updateStatus(params.startDate, params.endDate, params.status, params.ids)
      .subscribe(
        (response) => {
          this.getTeamMetrics();
          this.toastService.show({
            text:
              params.ids.length +
              ' ' +
              (status === ClockHour.Status.Approved
                ? this.i18n.instant('clockHour.toastApproved')
                : this.i18n.instant('clockHour.toastRejected')),
            type: 'success',
            msDuration: 4000,
          });
          this.unselectItems();
          this.cd.detectChanges();
        },
        (error) => {
          this.unselectItems();
          console.error('Error updating status', error);
        }
      );
  }

  downloadReport(selection: 'all' | 'selected' = 'selected') {
    if (selection === 'selected') {
      this.analyticsService.trackEvent({
        sources: ['amplitude', 'braze', 'userflow'],
        eventName: AmplitudeEvents.trackingTimeTeam_bulk_download,
      });
    }

    const filename = `hour_control-${this.dateRange.startDate}_${this.dateRange.endDate}`;

    const selectedIds =
      selection === 'all'
        ? this.mappedDataSource.map((item) => item.id)
        : this.selection.selected.map((item) => item.id);

    this.clockHourService
      .getTeamReport(
        selectedIds,
        this.dateRange.startDate,
        this.dateRange.endDate
      )
      .subscribe(
        (response) => {
          downloadZip(response, `${filename}.zip`);
          this.downloadBtnService.downloadedSuccessfully();
          this.analyticsService.trackEvent({
            eventName: AmplitudeEvents.trackingTimeTeam_download,
            sources: ['amplitude', 'braze', 'userflow'],
            eventProperties: {
              event_location: 'team_list',
            },
          });
        },
        (error) => {
          console.warn('Error downloading the file', error);
        }
      );
  }

  unselectItems() {
    this.selection.clear();
    this.hasSelectedItems = false;
    this.cd.detectChanges();
  }
}
