import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DealPipelineService {
  private requestPaginateCache = new Subject<boolean>();
  private requestChangeParams = new BehaviorSubject<{ [key: string]: any }>(
    null
  );

  requestPagination$ = this.requestPaginateCache.asObservable();
  changeParams$ = this.requestChangeParams.asObservable();

  constructor() {}

  requestPagination() {
    this.requestPaginateCache.next(true);
  }

  changeParams(query: { [key: string]: any }) {
    this.requestChangeParams.next(query);
  }
}
