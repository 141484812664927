<div (focusout)="focusOut('users')">
  <div
    class="fake-select-overlay"
    *ngIf="!showPanelElement || isLoading"
    [ngStyle]="{ height: inputHeight }"
    (click)="focusOutA = false; clickPanel($event, null)"
  ></div>
  <div
    class="fake-clean-overlay"
    *ngIf="
      !disabled &&
      ((!isLoading && activeItems?.length && mode === 'MULTIPLE') ||
        (!isLoading && activeItems?.length && isClearable && !showPanelElement))
    "
    (click)="focusOutA = false; clearInput('in')"
  ></div>
  <div
    class="fake-select"
    [ngStyle]="{ height: inputHeight }"
    [ngClass]="{
      active: showPanelElement,
      invalid: hasError,
      'border-left': borderLeft
    }"
  >
    <div class="fake-select-content">
      <span
        *ngIf="!activeItems?.length"
        [ngClass]="{ inactive: !activeItems?.length }"
      >
        {{ placeholderText | translate }}
      </span>
      <ng-container *ngIf="activeItems?.length">
        <ng-container *ngIf="mode === 'SINGLE'">
          <ng-container *ngIf="!isBadgeType">
            <span class="single-label">
              {{ activeItems[0].title | translate }}
            </span>
          </ng-container>
          <ng-container *ngIf="isBadgeType">
            <roma-rm-badge
              [statusName]="activeItems[0].title | translate"
              [color]="badgeColor"
              [ellipsis]="true"
            ></roma-rm-badge>
          </ng-container>
        </ng-container>
        <ng-container
          *ngIf="displayMultipleActive && mode === 'MULTIPLE'; else multiple"
        >
          <div class="active-item-container">
            <span *ngFor="let activeItem of activeItems" class="active-item">
              <ng-container *ngIf="type === 'taxes'; else title">
                {{ activeItem.translate | translate | slice: 0:3 }}
                {{ activeItem.value }}%
              </ng-container>
              <ng-template #title>
                {{ activeItem.title | translate }}
              </ng-template>
            </span>
          </div>
        </ng-container>
        <ng-template #multiple>
          <ng-container *ngIf="mode === 'MULTIPLE'">
            {{ placeholderText }}
            <span class="fake-item">({{ activeItems?.length }})</span>
          </ng-container>
        </ng-template>
      </ng-container>
    </div>
    <div class="fake-select-options">
      <img
        *ngIf="!isLoading && !disabled && showPanelElement"
        [src]="'assets/icons/active-up-arrow.svg'"
        width="20px"
      />
      <img
        *ngIf="!isLoading && !disabled && !showPanelElement"
        [src]="'assets/icons/gl_arrow_down.svg'"
        width="20px"
      />
      <img
        *ngIf="disabled"
        width="20px"
        [src]="'assets/icons/disable-down-arrow.svg'"
      />
      <img
        class="clean-input"
        *ngIf="
          !disabled &&
          ((!isLoading && activeItems?.length && mode === 'MULTIPLE') ||
            (!isLoading && activeItems?.length && isClearable))
        "
        src="assets/icons/gl_close-input.svg"
      />
      <mat-progress-spinner
        *ngIf="isLoading && !disabled"
        mode="indeterminate"
        [diameter]="20"
      ></mat-progress-spinner>
    </div>
  </div>
</div>
<div class="desktop-container" [ngClass]="{ 'is-absolute': isAbsolute }">
  <!--(focusout)="focusOut('status')"-->
  <!-- Desktop -->
  <ng-container *ngIf="!isResponsive">
    <div
      *ngIf="!isLoading && showPanelElement && mode === 'SINGLE'"
      romaRomaToolTip
      [showToolTip]="showPanelElement"
      (clicked)="showPanelElement = $event"
      class="users-list"
      [ngStyle]="listWidth !== '' && { width: listWidth }"
      [ngClass]="{ 'is-absolute': isAbsolute, 'border-left': borderLeft }"
      (focusin)="focusInternal = true"
    >
      <div
        *ngIf="maxLength && inputFilterSearch.value?.length"
        class="rm-register-counter"
      >
        {{ inputFilterSearch.value?.length + '/' + maxLength }}
      </div>

      <div class="search-input">
        <input
          #inputFilterSearch
          class="filter-input"
          [ngStyle]="{ height: inputHeight }"
          type="text"
          [placeholder]="placeholderText | translate"
          [ngClass]="{
            'full-width-list': fullWidthList
          }"
          (focus)="selectedIndex = 0"
          (keydown.arrowdown)="changeSelectedIndex(1)"
          (keydown.arrowup)="changeSelectedIndex(-1)"
          (keydown.enter)="changeFilteredSelect()"
          (keydown.tab)="changeFilteredSelect()"
          (keydown.escape)="showPanelElement = false"
          (input)="selectedIndex = 0"
          [attr.maxLength]="maxLength"
        />

        <img
          [src]="iconDropdown"
          (click)="focusOutA = false; clickPanel($event, null)"
        />
      </div>
      <div
        *ngIf="
          showItemFreeToAdd(
            inputFilterSearch.value !== '',
            (items | searchFilter: filterArgs).length > 0
          )
        "
        class="item-free"
        (click)="changeSelect(stringSelectedItem)"
      >
        <div class="item-free-text">
          {{ inputFilterSearch.value }}
        </div>
        <div class="item-free-indication">
          {{ 'general.use' | translate }}
        </div>
      </div>
      <div
        #listWrapper
        class="list-wrapper"
        [ngClass]="{
          'solo-list': type !== 'users',
          'full-width-list': fullWidthList
        }"
        [ngStyle]="listStyle"
      >
        <div
          *ngFor="let item of items | searchFilter: filterArgs; let i = index"
          class="user-item single-check"
          [ngStyle]="listStyleItem"
          [ngClass]="{
            active:
              (activeItems && activeItems[0]?.value === item.value) ||
              (items[i]?.value === 'category.allCategories' && loadDefaultItem),
            'user-item-wrap': isBadgeType,
            'user-item': !isBadgeType,
            itemSelected: i === selectedIndex
          }"
          (click)="changeSelect(item)"
          (mouseenter)="selectedIndex = i"
          (mouseleave)="selectedIndex = null"
        >
          <img
            *ngIf="
              (activeItems && activeItems[0]?.value === item.value) ||
              (items[i]?.value === 'category.allCategories' && loadDefaultItem)
            "
            class="active-check"
            src="assets/icons/gl_check.svg"
          />
          <img
            *ngIf="item.hasOwnProperty('img')"
            class="user-img"
            [src]="
              item?.img
                ? (item.img | imageFirebase: 'user')
                : '/assets/icons/table-user-icon.svg'
            "
          />
          {{ item.title | translate }}
        </div>
      </div>
      <div
        *ngIf="type === 'users'"
        class="add-user"
        romaProFeature
        [featureUser]="addButtonFeatureUser"
        (clicked)="addElementHandle($event)"
      >
        <img src="/assets/icons/gl_plus.svg" />
        {{ addButtonText | translate }}
      </div>
    </div>

    <div
      *ngIf="!isLoading && showPanelElement && mode === 'MULTIPLE'"
      romaRomaToolTip
      [showToolTip]="showPanelElement"
      (clicked)="showPanelElement = $event"
      class="users-list"
      style="padding-top: 0"
      [ngStyle]="listWidth !== '' && { width: listWidth }"
      [ngClass]="{ 'is-absolute': isAbsolute }"
      (focusin)="focusInternal = true"
    >
      <div class="search-input">
        <input
          #inputFilterSearch
          [ngStyle]="{ height: inputHeight }"
          class="filter-input"
          type="text"
          [placeholder]="'general.search' | translate"
          [ngClass]="{
            'full-width-list': fullWidthList
          }"
        />
        <img
          [src]="'assets/icons/gl_arrow_down.svg'"
          (click)="focusOutA = false; clickPanel($event, null)"
        />
      </div>
      <div
        class="list-wrapper"
        [ngClass]="{
          'solo-list': type !== 'users',
          'full-width-list': fullWidthList
        }"
        [ngStyle]="listStyle"
      >
        <div
          *ngFor="let item of items | searchFilter: filterArgs; let i = index"
          class="user-item"
        >
          <mat-checkbox
            (change)="anuleFocusOutTimeout(); changeCheckBox(item)"
            (click)="
              changeCheckBoxByClick($event, item); $event.stopPropagation()
            "
            [checked]="searchCheckFilters(item) ? true : false"
          >
            <img
              *ngIf="item.hasOwnProperty('img')"
              class="user-img"
              [src]="
                item?.img
                  ? (item.img | imageFirebase: 'user')
                  : '/assets/icons/table-user-icon.svg'
              "
            />
            {{ item.title | translate }}
          </mat-checkbox>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Mobile -->
  <ng-container *ngIf="isResponsive">
    <div class="list-dropdown-select">
      <div
        *ngIf="!isLoading && showPanelElement && mode === 'SINGLE'"
        romaRomaToolTip
        [showToolTip]="showPanelElement"
        (clicked)="showPanelElement = $event"
        class="mobile-display"
        [ngStyle]="listWidth !== '' && { width: listWidth }"
        (focusin)="focusInternal = true"
      >
        <div
          *ngIf="showPanelElement"
          class="mobile-list-wrapper"
          (click)="showPanelElement = !showPanelElement"
        ></div>
        <div class="list-box">
          <h4>{{ placeholderText | translate }}</h4>
          <div class="filter-container">
            <div
              *ngIf="maxLength && inputFilterSearch.value?.length"
              class="rm-register-counter"
            >
              {{ inputFilterSearch.value?.length + '/' + maxLength }}
            </div>

            <img class="icon" src="/assets/icons/gl_search.svg" alt="search" />
            <input
              #inputFilterSearch
              class="filter-input"
              [ngStyle]="{ height: inputHeight }"
              type="text"
              [placeholder]="placeholderText | translate"
              [ngClass]="{
                'full-width-list': fullWidthList
              }"
              [attr.maxLength]="maxLength"
            />
          </div>
          <div *ngIf="isLoading" style="text-align: center; width: 100%">
            <mat-progress-spinner
              mode="indeterminate"
              [diameter]="60"
              style="margin: 40px auto"
            ></mat-progress-spinner>
          </div>
          <div
            *ngIf="
              showItemFreeToAdd(
                inputFilterSearch.value !== '',
                (items | searchFilter: filterArgs).length > 0
              )
            "
            class="mobile-item-free"
            (click)="changeSelect(stringSelectedItem)"
          >
            <div class="item-free-text">
              {{ inputFilterSearch.value }}
            </div>
            <div class="item-free-indication">
              {{ 'general.use' | translate }}
            </div>
          </div>
          <div class="list-wrapper" *ngIf="!isLoading">
            <div
              class="list-item"
              *ngFor="
                let item of items | searchFilter: filterArgs;
                let i = index
              "
              [ngClass]="{
                active:
                  (activeItems && activeItems[0]?.value === item.value) ||
                  (items[i]?.value === 'category.allCategories' &&
                    loadDefaultItem),
                'checked-right': checkedRight
              }"
              (click)="changeSelect(item)"
            >
              <img
                *ngIf="
                  (activeItems && activeItems[0]?.value === item.value) ||
                  (items[i]?.value === 'category.allCategories' &&
                    loadDefaultItem)
                "
                class="active-check"
                src="assets/icons/gl_check.svg"
              />
              {{ item.title | translate }}
            </div>
            <div
              *ngIf="type === 'users'"
              class="add-user-mobile"
              romaProFeature
              [featureUser]="addButtonFeatureUser"
              (clicked)="addElementHandle($event)"
            >
              <img src="/assets/icons/gl_plus.svg" />
              {{ addButtonText | translate }}
            </div>
          </div>
          <div class="text-center close-btn-wrapper">
            <img
              (click)="showPanelElement = !showPanelElement"
              class="close-btn"
              src="/assets/icons/btn_close.svg"
              alt="close"
            />
          </div>
        </div>
        <div
          *ngIf="type === 'users'"
          class="add-user"
          romaProFeature
          [featureUser]="addButtonFeatureUser"
          (clicked)="addElementHandle($event)"
        >
          <img src="/assets/icons/gl_plus.svg" />
          {{ addButtonText | translate }}
        </div>
      </div>
      <div
        *ngIf="!isLoading && showPanelElement && mode === 'MULTIPLE'"
        romaRomaToolTip
        [showToolTip]="showPanelElement"
        (clicked)="showPanelElement = $event"
        class="mobile-display"
        [ngStyle]="listWidth !== '' && { width: listWidth }"
        (focusin)="focusInternal = true"
      >
        <div
          *ngIf="showPanelElement"
          class="mobile-list-wrapper"
          (click)="showPanelElement = !showPanelElement"
        ></div>
        <div class="list-box">
          <h4>{{ placeholderText | translate }}</h4>
          <div class="filter-container">
            <img class="icon" src="/assets/icons/gl_search.svg" alt="search" />
            <input
              #inputFilterSearch
              class="filter-input"
              [ngStyle]="{ height: inputHeight }"
              type="text"
              [placeholder]="placeholderText | translate"
              [ngClass]="{
                'full-width-list': fullWidthList
              }"
            />
          </div>
          <div *ngIf="isLoading" style="text-align: center; width: 100%">
            <mat-progress-spinner
              mode="indeterminate"
              [diameter]="60"
              style="margin: 40px auto"
            ></mat-progress-spinner>
          </div>
          <div class="list-wrapper">
            <div
              *ngFor="
                let item of items | searchFilter: filterArgs;
                let i = index
              "
              class="user-item"
            >
              <mat-checkbox
                (click)="
                  anuleFocusOutTimeout();
                  changeCheckBoxByClick($event, item);
                  $event.stopPropagation()
                "
                (change)="anuleFocusOutTimeout(); changeCheckBox($event, item)"
                [checked]="searchCheckFilters(item) ? true : false"
              >
                <img
                  *ngIf="item.hasOwnProperty('img')"
                  class="user-img"
                  [src]="
                    item?.img
                      ? (item.img | imageFirebase: 'user')
                      : '/assets/icons/table-user-icon.svg'
                  "
                />
                <ng-container *ngIf="type === 'taxes'; else title">
                  {{ item.translate | translate }} {{ item.value }}%
                </ng-container>
                <ng-template #title>
                  {{ item.title | translate }}
                </ng-template>
              </mat-checkbox>
            </div>
          </div>
          <div class="text-center close-btn-wrapper">
            <img
              (click)="showPanelElement = !showPanelElement"
              class="close-btn"
              src="/assets/icons/btn_close.svg"
              alt="close"
            />
          </div>
        </div>
        <div
          *ngIf="type === 'users'"
          class="add-user"
          romaProFeature
          [featureUser]="addButtonFeatureUser"
          (clicked)="addElementHandle($event)"
        >
          <img src="/assets/icons/gl_plus.svg" />
          {{ addButtonText | translate }}
        </div>
      </div>
    </div>
  </ng-container>
</div>
