/* eslint-disable @typescript-eslint/no-namespace */

import {
  ICompanySale,
  IDealSale,
  IFinalSale,
  IItemsSale,
  IRefundSale,
  IStatus,
  ITaxSale,
} from '../../../';

export namespace Sale {
  export enum StateEnum {
    Paid = 'paid',
    NotPaid = 'not paid',
    Cancelled = 'cancelled',
    Refunded = 'refunded',
  }

  export enum PaymentMethodEnum {
    Cash = 'cash',
    Card = 'card',
    Others = 'others',
    Link = 'link',
    None = 'none',
    Transfer = 'transfer',
    PaymentLink = 'paymentLink',
  }

  export interface Schema {
    number?: number;
    status?: IStatus;
    idStatus: number;
    final?: IFinalSale;
    deal?: IDealSale;
    taxes?: ITaxSale[];
    items?: IItemsSale[];
    company?: ICompanySale;
    paymentMethod?: PaymentMethodEnum;
    paymentDate?: Date;
    subtotal: number;
    discount: number;
    discountAmount: number;
    total: number;
    createdAt?: Date;
    updatedAt?: Date;
    cancelReason?: string;
    cancelDate?: Date;
    refund?: IRefundSale;
    paymentLink?: {
      id: string;
      url: string;
    };
    transferLink?: {
      id: string;
      url: string;
    };
  }

  export interface Output extends Sale.Schema {
    _id?: string;
    number?: number;
    paymentDate?: Date;
  }

  export interface CreateSaleDto {
    idStatus: number;
    final?: IFinalSale;
    deal?: IDealSale;
    taxes?: ITaxSale[];
    taxesAmount?: number;
    items?: IItemsSale[];
    paymentMethod?: Sale.PaymentMethodEnum;
    paymentDate?: Date;
    subtotal: number;
    total: number;
    discount?: number;
    discountAmount?: number;
    cancelReason?: string;
  }

  export interface UpdateSaleDto {
    idStatus?: number;
    paymentMethod?: Sale.PaymentMethodEnum;
    cancelReason?: string;
    paymentDate?: Date;
    cancelDate?: Date;
  }
}

/**
 *     number?: number;
    deal?: [];
    company: {
      _id: string;
      name: string;
    };
    final: {
      _id: string;
      name: string;
    };

    status?: IStatus;
    paymentMethod?: PaymentMethodEnum;
    paymentDate?: Date;
    subtotal: number;
    total: number;
    actions?: string[];
    items: IItemBudget[];
    discount?: number;
    taxes?: SaleTax[];
    createdAt?: Date;
    updatedAt?: Date;
    cancelReason?: string;
 *
 */
