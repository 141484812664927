<div class="container-payment-method" [formGroup]="form">
  <div class="title-card">
    {{ 'budgets.settings.title.paymenMethod' | translate }}
  </div>
  <div class="payment-list" *ngIf="list?.length > 0">
    <ng-container *ngFor="let payment of list; let i = index">
      <mat-card>
        <mat-card-title>
          <div class="card-title">
            <div class="row">
              <div class="col-11">
                {{ translateName(payment?.name) | translate }}
                <button
                  mat-icon-button
                  class="action-menu"
                  [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="editPayment(payment)">
                    <mat-icon>edit</mat-icon>
                    <span>{{ 'general.edit' | translate }}</span>
                  </button>
                  <button
                    romaClickDelete
                    mat-menu-item
                    subtitle="deleteModal.body_final"
                    (wantDelete)="deletePayment(payment)"
                  >
                    <mat-icon>delete</mat-icon>
                    <span>{{ 'general.remove' | translate }}</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="row">
            <div class="col-11">
              <div class="row">
                <div class="text-show pl-30">
                  {{
                    'budgets.settings.create.form.payment-textshow' | translate
                  }}
                </div>
              </div>
              <div class="row">
                <div class="payment-description pl-30">
                  {{ payment.textShow }}
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </div>

  <div class="w-100 pt-lg-3 add-methods">
    <div class="d-flex flex-row-reverse pr-3">
      <button class="roma-button" (click)="createPayment($event)">
        {{ 'budgets.settings.create.form.buttonAddPayment' | translate }} +
      </button>
    </div>
  </div>
</div>
