import { BDG_SETTINGS } from '../../../types-legacy';

function isValidProperty(property: any): boolean {
  return property !== null && property !== undefined && property !== '';
}

export const checkIfCompanyIsValidForBilling = (): boolean => {
  const bdgDataString = localStorage.getItem(BDG_SETTINGS);
  if (bdgDataString) {
    const budgetData = JSON.parse(bdgDataString);

    const isDataAccountValid: boolean =
      isValidProperty(budgetData?.data_account?.company_name) &&
      isValidProperty(budgetData?.data_account?.fiscalRegime) &&
      isValidProperty(budgetData?.data_account?.fiscalName);

    const isBillingAddressValid: boolean =
      isValidProperty(budgetData?.billing_addres?.address) &&
      isValidProperty(budgetData?.billing_addres?.state) &&
      isValidProperty(budgetData?.billing_addres?.postalcode) &&
      isValidProperty(budgetData?.billing_addres?.country);

    return isDataAccountValid && isBillingAddressValid;
  }

  return false;
};
