export const replaceNonEnglishCharacters = (str) => {
  const replacements = {
    á: 'a',
    â: 'a',
    à: 'a',
    ä: 'a',
    ã: 'a',
    å: 'a',
    ā: 'a',
    ą: 'a',
    ć: 'c',
    č: 'c',
    ç: 'c',
    ĉ: 'c',
    ċ: 'c',
    ð: 'd',
    é: 'e',
    ê: 'e',
    è: 'e',
    ë: 'e',
    ē: 'e',
    ę: 'e',
    ė: 'e',
    ě: 'e',
    ğ: 'g',
    ĝ: 'g',
    ġ: 'g',
    ģ: 'g',
    ĥ: 'h',
    î: 'i',
    ï: 'i',
    í: 'i',
    ī: 'i',
    į: 'i',
    ì: 'i',
    ĵ: 'j',
    ñ: 'n',
    ń: 'n',
    ň: 'n',
    ô: 'o',
    ö: 'o',
    ò: 'o',
    ó: 'o',
    õ: 'o',
    ø: 'o',
    ō: 'o',
    ő: 'o',
    œ: 'oe',
    ŕ: 'r',
    ř: 'r',
    ŗ: 'r',
    ș: 's',
    š: 's',
    ś: 's',
    ţ: 't',
    ť: 't',
    þ: 'th',
    ü: 'u',
    û: 'u',
    ú: 'u',
    ù: 'u',
    ū: 'u',
    ů: 'u',
    ű: 'u',
    ų: 'u',
    ý: 'y',
    ÿ: 'y',
    ž: 'z',
    ź: 'z',
    ż: 'z',
  };

  const regex = new RegExp(`[${Object.keys(replacements).join('')}]`, 'g');

  return str.replace(regex, (match) => replacements[match]);
};
