<mat-progress-spinner
  *ngIf="isLoading; else content"
  class="loading-spinner"
  mode="indeterminate"
  [diameter]="60"
  style="margin: auto"
></mat-progress-spinner>
<ng-template #content>
  <div class="show-budget-header">
    <div class="show-budget-header__left">
      <div class="show-budget-header__back" (click)="back()">
        <img
          src="assets/icons/chevron-left.svg"
          alt="{{ 'general.backLowercase' | translate }}"
        />
        {{ 'general.backLowercase' | translate }}
      </div>
      <div class="show-budget-header__title">
        {{ title | translate }}
        <span *ngIf="isMXandDraft()">{{
          'budgets.show.draft' | translate
        }}</span>
        {{ billTitle }}
      </div>
    </div>
    <div class="show-budget-header__right">
      <base-button
        *ngIf="!isResponsive"
        buttonClass="tertiary"
        size="xsmall"
        icon="assets/icons/actions-menu/pen-line.svg"
        (click)="editDocument(true)"
      >
        {{ 'general.edit' | translate }}
      </base-button>
      <button
        *ngIf="canConvert() && type !== TYPES_OF_BUDGETS.WAL"
        class="roma-button-outline-secondary btn-with-options"
        [matMenuTriggerFor]="convertToMenu"
        [ngClass]="{ active: openConvertToMenu }"
        (click)="showConvertToMenu()"
      >
        <img
          src="assets/icons/gl_black_convert_arrows.svg"
          class="btn-icon prefix"
        />
        {{ 'budgets.show.convertTo' | translate }}
        <img src="assets/icons/gl_arrow-down.svg" class="btn-icon suffix" />
      </button>
      <button
        class="roma-button-outline-secondary btn-with-options btn-convertTo"
        romaProFeature
        [feature]="featureRef.Sale.WayBillDownload"
        [featureUser]="featureRefUser.Bill.create"
        (clicked)="invoiceWaybill()"
        *ngIf="type === TYPES_OF_BUDGETS.WAL"
      >
        <img class="loop-icon" src="assets/icons/actions-menu/loop.svg" />
        {{ 'billing.toInvoice' | translate }}
        <div
          *ngIf="
            !(
              permissionService.hasFeatureFn(featureRef.Sale.WayBillDownload)
              | async
            )
          "
          class="icon-star-pro-feature"
          style="margin-left: 8px"
        >
          <div class="icon">
            <img src="assets/icons/star.svg" />
          </div>
        </div>
      </button>
      <button
        class="roma-button-outline-secondary btn-with-options"
        [matMenuTriggerFor]="moreOptions"
        [ngClass]="{ active: openMoreOptions }"
        (click)="showMoreOptionMenu()"
      >
        {{ 'budgets.show.moreOptions' | translate }}
        <img src="assets/icons/gl_arrow-down.svg" class="btn-icon suffix" />
      </button>
      <base-button
        *ngIf="!isResponsive"
        buttonClass="tertiary"
        size="xsmall"
        icon="assets/icons/mail.svg"
        (click)="sendDocument()"
      >
        {{ 'general.sendByEmail' | translate }}
      </base-button>
      <base-button
        *ngIf="!isResponsive && !isMXandBILL"
        buttonClass="tertiary"
        size="xsmall"
        icon="assets/icons/actions-menu/whatsapp-logo.svg"
        [isLoading]="isLoadingShare"
        (click)="shareDocument()"
      >
        Whatsapp
      </base-button>
      <div class="more-button">
        <ng-container *ngIf="isResponsive">
          <button (click)="onShowMoreMenu()">
            <img [src]="'assets/icons/gl_more.svg'" alt="more" />
          </button>

          <div *ngIf="showMoreMenu" style="position: absolute; z-index: 1000">
            <div class="mobile-list-wrapper" (click)="onShowMoreMenu()"></div>
            <div class="list-box">
              <h4>{{ 'general.actions' | translate }}</h4>
              <div class="list-wrapper">
                <div
                  class="list-item"
                  (click)="showPreview(); onShowMoreMenu()"
                >
                  <span>
                    <img
                      class="btn-icon"
                      src="assets/icons/actions-menu/eye.svg"
                    />
                    {{ 'budgets.show.seeDocument' | translate }}
                  </span>
                </div>
                <div
                  class="list-item"
                  (click)="sendDocument(); onShowMoreMenu()"
                >
                  <span>
                    <img src="assets/icons/actions-menu/mail.svg" />
                    {{ 'general.send' | translate }}
                  </span>
                </div>
                <div
                  class="list-item"
                  (click)="shareDocument(); onShowMoreMenu()"
                >
                  <span>
                    <img src="assets/icons/actions-menu/share.svg" />
                    {{ 'general.share' | translate }}
                  </span>
                </div>
                <div
                  *ngIf="
                    type !== TYPES_OF_BUDGETS.WAL && canConvert();
                    else convertTo_WAL
                  "
                  class="list-item"
                  (click)="showConvertToMenu(); onShowMoreMenu()"
                >
                  <span>
                    <img src="assets/icons/actions-menu/loop.svg" />
                    {{ 'budgets.show.convertTo' | translate }}
                  </span>
                  <div class="arrow">
                    <img
                      src="/assets/icons/actions-menu/chevron-right.svg"
                      alt="{{ 'budgets.show.convertTo' | translate }}"
                    />
                  </div>
                </div>
                <ng-template #convertTo_WAL>
                  <div
                    *ngIf="canConvert()"
                    class="list-item"
                    romaProFeature
                    [feature]="featureRef.Sale.WayBillDownload"
                    [featureUser]="featureRefUser.Bill.create"
                    (clicked)="invoiceWaybill(); onShowMoreMenu()"
                  >
                    <span>
                      <img src="assets/icons/actions-menu/loop.svg" />
                      {{ 'billing.toInvoice' | translate }}
                      <img
                        *ngIf="
                          !(
                            permissionService.hasFeatureFn(
                              featureRef.Sale.WayBillDownload
                            ) | async
                          )
                        "
                        class="pro-badge"
                        src="assets/images/pro-badge.svg"
                      />
                    </span>
                  </div>
                </ng-template>
                <div
                  *ngIf="type !== TYPES_OF_BUDGETS.WAL; else download_WAL"
                  class="list-item"
                  (click)="downloadDocument(); onShowMoreMenu()"
                >
                  <span>
                    <img
                      class="btn-icon"
                      src="assets/icons/actions-menu/download.svg"
                    />
                    {{ 'budgets.download' | translate }}
                  </span>
                </div>
                <ng-template #download_WAL>
                  <div
                    class="list-item"
                    (click)="downloadDocument(); onShowMoreMenu()"
                  >
                    <span>
                      <img
                        class="btn-icon"
                        src="assets/icons/actions-menu/download.svg"
                      />
                      {{ 'budgets.download' | translate }}
                    </span>
                  </div>
                </ng-template>
                <div
                  class="list-item"
                  romaProFeature
                  *ngIf="isTypeBillDraft()"
                  [featureUser]="featureUsedUpdateByType"
                  (clicked)="editDocument(); onShowMoreMenu()"
                >
                  <span>
                    <img
                      class="btn-icon"
                      src="assets/icons/actions-menu/pen-line.svg"
                    />
                    {{ 'budgets.show.edit' | translate }}
                  </span>
                </div>
                <div
                  class="list-item"
                  romaProFeature
                  [feature]="featureRef.Sale.SendBudgetEmail"
                  [featureUser]="featureUsedCreateByType"
                  (clicked)="duplicateDocument(); onShowMoreMenu()"
                >
                  <span>
                    <img
                      class="btn-icon"
                      src="assets/icons/actions-menu/duplicate.svg"
                    />
                    {{ 'budgets.duplicate' | translate }}
                    <img
                      *ngIf="
                        !(
                          permissionService.hasFeatureFn(
                            featureRef.Sale.SendBudgetEmail
                          ) | async
                        )
                      "
                      class="pro-badge"
                      src="assets/images/pro-badge.svg"
                    />
                  </span>
                </div>
                <!-- <div class="list-item" (click)="pdfSrc && printPDF()">
                  <span>
                    <img class="btn-icon" [src]="'assets/icons/gl_print.svg'" />
                    {{ 'general.print' | translate }}
                  </span>
                </div> -->

                <div
                  *ngIf="isTypeBillDraft()"
                  class="list-item"
                  romaProFeature
                  [featureUser]="featureUsedDeleteByType"
                  (clicked)="deleteDocument(); onShowMoreMenu()"
                >
                  <span class="red">
                    <img
                      class="btn-icon"
                      src="assets/icons/actions-menu/trash.svg"
                    />
                    {{ 'budgets.show.delete' | translate }}
                  </span>
                </div>
              </div>
              <div class="text-center close-btn-wrapper">
                <img
                  (click)="onShowMoreMenu()"
                  class="close-btn"
                  src="/assets/icons/btn_close.svg"
                  alt="close"
                />
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="show-budget-body">
    <div class="show-budget-body-line"></div>
    <div class="show-budget-body__preview">
      <mat-progress-spinner
        *ngIf="!pdfSrc && !pdfError"
        class="loading-spinner"
        mode="indeterminate"
        [diameter]="60"
        style="margin: auto"
      ></mat-progress-spinner>
      <pdf-viewer
        [src]="pdfSrc"
        [render-text]="true"
        [external-link-target]="'blank'"
        [fit-to-page]="true"
        [original-size]="false"
        [autoresize]="true"
        style="width: 100%; height: 100%"
        [ngStyle]="{ display: pdfSrc ? 'flex' : 'none' }"
      ></pdf-viewer>
    </div>
    <div class="show-budget-body__right">
      <div class="show-budget-body__right__data-container">
        <div class="show-budget-body__right__data">
          <div class="show-budget-body__right__data-title">
            {{ 'budgets.settings.create.form.detail' | translate }}
          </div>
          <div class="show-budget-body__right__data-content">
            <div class="show-budget-body__right__data-item">
              <div class="show-budget-body__right__data-item-text">
                {{
                  'budgets.settings.create.form.contactsPlaceholder' | translate
                }}
              </div>
              <div class="show-budget-body__right__data-item-text right">
                {{ final?.name }} {{ final?.lastName || '' }}
              </div>
            </div>
            <div class="show-budget-body__right__data-item">
              <div class="show-budget-body__right__data-item-text">
                {{ 'budgets.settings.create.form.date' | translate }}
              </div>
              <div class="show-budget-body__right__data-item-text__right">
                {{ getDate | date: my_format_date }}
              </div>
            </div>
            <div
              romaProSelect
              class="show-budget-body__right__data-item wrapper-pro-feature-user"
              [featureUser]="featureUsedUpdateByType"
            >
              <div class="show-budget-body__right__data-item-text">
                {{ 'budgets.state' | translate }}
              </div>
              <div class="show-budget-body__right__data-item-text__right badge">
                <rm-select-register
                  *ngIf="canChangeStatus"
                  [isClearable]="false"
                  [activeItems]="statusSelected"
                  [items]="statusList"
                  inputHeight="36px"
                  [fullWidthList]="true"
                  [isAbsolute]="true"
                  [isBadgeType]="true"
                  [badgeColor]="colorStatus"
                  [checkedRight]="true"
                  (changeItems)="changeStatus($event)"
                  [disabled]="saveLoading || !canEditStatus"
                >
                </rm-select-register>
                <roma-rm-badge
                  *ngIf="!canChangeStatus && isBill"
                  [statusName]="getStatus(budget?.status)"
                  [color]="getStatusColor(budget?.status)"
                ></roma-rm-badge>
              </div>
            </div>
            <div class="show-budget-body__right__data-item">
              <div class="show-budget-body__right__data-item-text">
                {{
                  'budgets.settings.create.form.totalPlaceholder' | translate
                }}
              </div>
              <div class="show-budget-body__right__data-item-text right total">
                {{ currencySimbol === '$' ? '$' : '' }}
                {{ total }}
                {{ currencySimbol === '€' ? '€' : '' }}
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="deal || project || category">
          <div class="show-budget-body__right__data-separator"></div>
          <div class="show-budget-body__right__data">
            <div class="show-budget-body__right__data-title">
              {{ 'budgets.internalData' | translate }}
            </div>
            <div class="show-budget-body__right__data-content">
              <div *ngIf="deal" class="show-budget-body__right__data-item">
                <div class="show-budget-body__right__data-item-text">
                  {{
                    'budgets.settings.create.form.dealsPlaceholder' | translate
                  }}
                </div>
                <div class="show-budget-body__right__data-item-text__right">
                  {{ deal ? deal : '-' }}
                </div>
              </div>
              <div *ngIf="project" class="show-budget-body__right__data-item">
                <div class="show-budget-body__right__data-item-text">
                  {{ 'budgets.settings.create.form.project' | translate }}
                </div>
                <div class="show-budget-body__right__data-item-text__right">
                  {{ project ? project : '-' }}
                </div>
              </div>
              <div *ngIf="category" class="show-budget-body__right__data-item">
                <div class="show-budget-body__right__data-item-text">
                  {{
                    'budgets.settings.create.form.categorization' | translate
                  }}
                </div>
                <div class="show-budget-body__right__data-item-text__right">
                  {{ category ? category : '-' }}
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            type === TYPES_OF_BUDGETS.WAL &&
            budget.header.showSignatures === true
          "
        >
          <div class="show-budget-body__right__data-separator"></div>
          <div class="show-budget-body__right__data">
            <div class="show-budget-body__right__data-title">
              {{ 'budgets.additionalData' | translate }}
              <img
                *ngIf="!(hasFeatureSignDocuments$ | async)"
                src="assets/icons/star.svg"
              />
            </div>
            <div
              romaProSelect
              class="show-budget-body__right__data-content wrapper-pro-feature-user"
              [featureUser]="featureUsedUpdateByType"
            >
              <div class="wrapper__signature">
                <div class="wrapper__signature__item">
                  <roma-signature-waybill
                    [id]="'client-signature'"
                    [block]="true"
                    [source]="this.budget.signClient || ''"
                    [width]="size1024 ? 128 : 146"
                    [height]="128"
                    [isLoading]="uploadClientSignature"
                    [tagBottomCanvas]="
                      'waybills.additionalData.signCustomer' | translate
                    "
                    (changeSignature)="changeSignature($event, 'CLIENT')"
                  >
                  </roma-signature-waybill>
                </div>

                <div class="wrapper__signature__item">
                  <roma-signature-waybill
                    [id]="'company-signature'"
                    [block]="true"
                    [source]="this.budget.signCompany || ''"
                    [width]="size1024 ? 128 : 146"
                    [height]="128"
                    [isLoading]="uploadCompanySignature"
                    [tagBottomCanvas]="
                      'waybills.additionalData.signCompany' | translate
                    "
                    (changeSignature)="changeSignature($event, 'COMPANY')"
                  ></roma-signature-waybill>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <!--  -->
        <ng-container
          *ngIf="
            type === TYPES_OF_BUDGETS.BILL || type === TYPES_OF_BUDGETS.WAL
          "
        >
          <roma-enable-payments-banner
            *ngIf="showEnablePaymentsBanner"
            [marginTop]="'0px'"
          ></roma-enable-payments-banner>

          <div
            *ngIf="!isCreatingPaymentLink; else loadingCreatingPaymentLink"
            class="custom-tooltip top"
          >
            <button
              class="show-budget-body__right__button"
              *ngIf="type !== TYPES_OF_BUDGETS.WAL && isCountryValid"
              (click)="createOrCopyPaymentLink()"
              [disabled]="!stripeAccountIsApproved || !budget.total"
              [ngClass]="{
                'show-budget-body__right__button__disabled':
                  !stripeAccountIsApproved || !budget.total
              }"
            >
              {{ 'budgets.chargeAndShareLink' | translate }}
            </button>

            <div
              *ngIf="
                !(resize$ | async)?.payload?.mobile && !stripeAccountIsApproved
              "
              class="tiptext"
            >
              {{
                (stripeAccountRestricted
                  ? 'payments.disabled'
                  : 'payments.methodDisabledTooltip'
                ) | translate
              }}
            </div>
          </div>

          <ng-template #loadingCreatingPaymentLink>
            <mat-progress-spinner
              class="loading-spinner"
              mode="indeterminate"
              [diameter]="60"
              style="margin: auto"
            ></mat-progress-spinner>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="isMXandDraft()">
          <div class="btn-issue-invoice-wrapper">
            <button
              (click)="onClickIssueInvoice()"
              [disabled]="saveLoading || !canIssueInvoice"
              class="btn-issue-invoice w-100 d-flex justify-content-center align-items-center"
            >
              <mat-progress-spinner
                *ngIf="saveLoading"
                class="loading-spinner"
                style="stroke: white !important"
                mode="indeterminate"
                [diameter]="20"
              ></mat-progress-spinner>
              <span *ngIf="!saveLoading">
                {{ 'budgets.settings.create.form.save.bill' | translate }}
              </span>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<mat-menu
  class="btn-mat-menu"
  #moreOptions="matMenu"
  (closed)="closeMoreOptionMenu()"
>
  <button
    *ngIf="type !== TYPES_OF_BUDGETS.WAL; else download_WAL"
    mat-menu-item
    (click)="downloadDocument()"
  >
    <img class="btn-icon" src="assets/icons/gl_download.svg" />
    {{ 'budgets.download' | translate }}
  </button>
  <ng-template #download_WAL>
    <button mat-menu-item (click)="downloadDocument()">
      <img class="btn-icon" src="assets/icons/gl_download.svg" />
      {{ 'budgets.download' | translate }}
    </button>
  </ng-template>
  <button
    *ngIf="isTypeBillDraft()"
    mat-menu-item
    romaProFeature
    [featureUser]="featureUsedUpdateByType"
    (clicked)="editDocument()"
  >
    <img class="btn-icon" src="assets/icons/kanban/edit.svg" />
    {{ 'budgets.show.edit' | translate }}
  </button>
  <button
    romaProFeature
    [feature]="featureRef.Sale.SendBudgetEmail"
    [featureUser]="featureUsedCreateByType"
    mat-menu-item
    (clicked)="duplicateDocument()"
  >
    <img class="btn-icon" src="assets/icons/actions-menu/duplicate.svg" />
    {{ 'budgets.show.duplicate' | translate }}
    <div
      *ngIf="
        !(
          permissionService.hasFeatureFn(featureRef.Sale.SendBudgetEmail)
          | async
        )
      "
      class="icon-star-pro-feature"
    >
      <div class="icon" style="margin-left: 8px">
        <img src="assets/icons/star.svg" />
      </div>
    </div>
  </button>
  <button mat-menu-item (click)="printPDF()" [disabled]="!pdfSrc">
    <img class="btn-icon" [src]="'assets/icons/gl_print.svg'" />
    {{ 'general.print' | translate }}
  </button>
  <button
    *ngIf="isTypeBillDraft()"
    mat-menu-item
    romaProFeature
    [featureUser]="featureUsedDeleteByType"
    (clicked)="deleteDocument()"
    class="danger"
  >
    <img class="btn-icon" src="assets/icons/kanban/delete.svg" />
    {{ 'budgets.show.delete' | translate }}
  </button>
</mat-menu>

<mat-menu
  class="btn-mat-menu"
  #convertToMenu="matMenu"
  (closed)="closeConvertToMenu()"
>
  <div
    romaProSelect
    class="wrapper-pro-feature-user"
    [featureUser]="featureRefUser.Bill.create"
  >
    <button
      mat-menu-item
      *ngIf="canConvertToBill()"
      (click)="convertBudget(TYPES_OF_BUDGETS.BILL)"
    >
      {{ 'budgets.show.convertToBill' | translate }}
    </button>
  </div>
  <div
    romaProSelect
    class="wrapper-pro-feature-user"
    [featureUser]="featureRefUser.Proform.create"
  >
    <button
      mat-menu-item
      *ngIf="canConvertToProform()"
      (click)="convertBudget(TYPES_OF_BUDGETS.PROFORM)"
    >
      {{ 'budgets.show.convertToProform' | translate }}
    </button>
  </div>
</mat-menu>
<!-- <div (click)="closeDialog()" class="modal_close_button">
  <img class="close_button" [src]="'assets/icons/gl_close.svg'" alt="close" />
  <span class="close_text">Cerrar</span>
</div>

<section class="header">
  <div class="container header_content">
    <h2>Nueva factura</h2>
    <div class="header_group_buttons">
      <button class="rounded-button" (click)="showPreview()">
        <img [src]="'assets/icons/gl_eye-on.svg'" alt="eye" />{{
          'budgets.title.preview' | translate
        }}
      </button>
      <button class="rounded-button" (click)="showCustomization()">
        <img [src]="'assets/icons/gl_personalizacion.svg'" alt="magic wand" />{{
          'budgets.title.customize' | translate
        }}
      </button>
    </div>
  </div>
</section>

<section class="container form__container">
  <form [formGroup]="form" (ngSubmit)="submitBudget()" class="form">
    <roma-budget-header [formHeader]="form"> </roma-budget-header>
    <div formArrayName="budgetCategories">
      <div *ngFor="let category of budgetCategories.controls; index as i">
        <roma-budget-category [form]="category"></roma-budget-category>
      </div>
    </div>
    <div class="separator"></div>
    <roma-budget-footer
      [formFooter]="form"
      (changedFooter)="changedModel($event, 'footer')"
    >
    </roma-budget-footer>
    <button type="submit">Submit</button>
  </form>
</section> -->

<div
  *ngIf="isResponsive && openConvertToMenu"
  style="position: absolute; z-index: 1500"
>
  <div class="mobile-list-wrapper" (click)="closeConvertToMenu()"></div>
  <div class="list-box">
    <h4>{{ 'general.actions' | translate }}</h4>
    <div class="list-wrapper">
      <div
        romaProSelect
        class="wrapper-pro-feature-user"
        [featureUser]="featureRefUser.Bill.create"
      >
        <div
          class="list-item"
          *ngIf="canConvertToBill()"
          (click)="closeConvertToMenu(); convertBudget(TYPES_OF_BUDGETS.BILL)"
        >
          <span>
            {{ 'budgets.show.convertToBill' | translate }}
          </span>
        </div>
      </div>
      <div
        romaProSelect
        class="wrapper-pro-feature-user"
        [featureUser]="featureRefUser.Proform.create"
      >
        <div
          class="list-item"
          *ngIf="canConvertToProform()"
          (click)="
            closeConvertToMenu(); convertBudget(TYPES_OF_BUDGETS.PROFORM)
          "
        >
          <span>
            {{ 'budgets.show.convertToProform' | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="text-center close-btn-wrapper">
      <img
        (click)="closeConvertToMenu()"
        class="close-btn"
        src="/assets/icons/btn_close.svg"
        alt="close"
      />
    </div>
  </div>
</div>
