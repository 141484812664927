interface IntercomEnvironment {
  appId: string;
}

interface IntercomUser {
  id: string;
  name: string;
  email: string;
}

export function intercomMessengerLead(
  intercomEnvironment: IntercomEnvironment
): void {
  if (window.innerWidth <= 768) return;

  (window as any).intercomSettings = {
    api_base: 'https://api-iam.eu.intercom.io',
    app_id: intercomEnvironment.appId,
  };

  const w = window as any;
  const ic = w.Intercom;

  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    const d = document;
    const i = function (...args: any[]) {
      i.c(args);
    };
    i.q = [];
    i.c = function (args: any[]) {
      i.q.push(args);
    };
    w.Intercom = i;

    const l = function () {
      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${intercomEnvironment.appId}`;
      const x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };

    if (d.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
}

export function intercomMessengerUser(
  intercomEnvironment: IntercomEnvironment,
  user: IntercomUser
): void {
  if (window.innerWidth <= 768) return;

  (window as any).intercomSettings = {
    api_base: 'https://api-iam.eu.intercom.io',
    app_id: intercomEnvironment.appId,
    user_id: user.id,
    name: user.name,
    email: user.email,
  };

  const w = window as any;
  const ic = w.Intercom;

  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    const d = document;
    const i = function (...args: any[]) {
      i.c(args);
    };
    i.q = [];
    i.c = function (args: any[]) {
      i.q.push(args);
    };
    w.Intercom = i;

    const l = function () {
      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${intercomEnvironment.appId}`;
      const x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };

    if (d.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
}
