import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'roma-payment-prototipe',
  templateUrl: './payment-prototipe.component.html',
  styleUrls: ['./payment-prototipe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentPrototipeComponent implements OnInit {
  constructor(private router: Router) {}
  ngOnInit(): void {}

  gotoPaymentCard() {
    this.router.navigateByUrl('/admin/payments-prototype/card');
  }

  gotoConnectedAccount() {
    this.router.navigateByUrl('/admin/payments-prototype/accounts');
  }

  gotoPaymentLink() {
    this.router.navigateByUrl('/admin/payments-prototype/payments-link');
  }

  gotoLoginLink() {
    this.router.navigateByUrl('/admin/payments-prototype/login-link');
  }

  gotoRecurrentPaymentLink() {
    this.router.navigateByUrl(
      '/admin/payments-prototype/payments-recurrent-link'
    );
  }

  gotoRequestReaders() {
    this.router.navigateByUrl('/admin/payments-prototype/request-readers');
  }

  gotoCreateLocation() {
    this.router.navigateByUrl('/admin/payments-prototype/create-location');
  }

  gotoRegisterReader() {
    this.router.navigateByUrl('/admin/payments-prototype/register-reader');
  }

  gotoPaymentsDashboard() {
    this.router.navigateByUrl('/admin/payments-prototype/dashboard');
  }

  gotoRefunds() {
    this.router.navigateByUrl('/admin/payments-prototype/refunds');
  }
}
