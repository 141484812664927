import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractFormComponent } from './contract-form.component';
import { HourSelectModule } from '../hour-select';
import { DaySelectModule } from '../day-select';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ContractFormComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    HourSelectModule,
    DaySelectModule,
  ],
  exports: [ContractFormComponent],
})
export class ContractFormModule {}
