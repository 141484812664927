import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalYesOrNo } from './modal-yes-or-no.type';

@Component({
  selector: 'roma-modal-yes-or-no',
  templateUrl: './modal-yes-or-no.component.html',
  styleUrls: ['./modal-yes-or-no.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalYesOrNoComponent implements OnInit {
  @Input()
  params!: ModalYesOrNo.Params;

  constructor(
    private dialogRef: MatDialogRef<ModalYesOrNoComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ModalYesOrNo.Params
  ) {}

  ngOnInit(): void {
    this.attachParams();
  }

  execute(evt: MouseEvent) {
    if (evt) {
      this.dialogRef.close('EXECUTE');
    }
  }

  cancel(evt: MouseEvent) {
    if (evt) {
      this.dialogRef.close('CANCEL-X');
    }
  }

  cancelButton(evt: MouseEvent) {
    if (evt) {
      this.dialogRef.close('CANCEL');
    }
  }

  private attachParams() {
    if (this.data) {
      this.params = this.data;
    }
  }
}
