export namespace MobileClockHour {
  export interface Content {
    id: string;
    title: string;
    addTitleLabel: boolean;
    description: string;
    date: {
      year: number;
      month: number;
      day: number;
    };
    hour?: string;
    status?: 'PENDING' | 'APPROVED' | 'REJECTED';
    data?: unknown;
  }

  export interface ActionsOptions {
    allowDelete: boolean;
  }

  export interface Action {
    id?: string;
    action: 'SIGN' | 'DELETE';
    date: {
      year: number;
      month: number;
      day: number;
    };
  }

  export const defaultActionsOptions: MobileClockHour.ActionsOptions = {
    allowDelete: true,
  };
}
