import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RmFilterModalDashboardComponent } from './rm-filter-modal-dashboard.component';
import { RmFilterModalDashboard } from './rm-filter-modal-dashboard.types';

@Injectable({
  providedIn: 'root',
})
export class RmFilterModalDashboardService {
  private refreshListSource = new Subject<RmFilterModalDashboard.Result>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(
    config: MatDialogConfig<RmFilterModalDashboard.Params> = new MatDialogConfig()
  ) {
    config.height = '100vh';
    config.width = '100vw';
    config.maxWidth = '100vw';
    config.hasBackdrop = false;

    const dialogRef = this.dialog.open<
      RmFilterModalDashboardComponent,
      RmFilterModalDashboard.Params,
      RmFilterModalDashboard.Result
    >(RmFilterModalDashboardComponent, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }
}
