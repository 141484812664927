import { Injectable } from '@angular/core';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ClickLogoutService } from '@web-frontend/shared/directives/click-logout';

@Injectable()
export class TimeoutInterceptorService implements HttpInterceptor {
  constructor(private clickLogoutService: ClickLogoutService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (err) => {
          if (
            err instanceof HttpErrorResponse &&
            err.status === 401 &&
            err.error.message === 'Unauthorized'
          ) {
            this.clickLogoutService.logout();
          }
        }
      )
    );
  }

  private executeLogout() {}
}
