import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { MobileClockHourAdminComponent } from './mobile-clock-hour-admin.component';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionsModule } from '../../permissions';

@NgModule({
  declarations: [MobileClockHourAdminComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatMenuModule,
    ClickDeleteModule,
    PermissionsModule,
  ],
  exports: [MobileClockHourAdminComponent],
})
export class MobileClockHourAdminModule {}
