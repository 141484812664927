import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  Company,
  CUSTOMER_ID_KEY,
  CustomPropertyFromFormDto,
  Deal,
  DealType,
  DynamicPropertyRef,
  DynamicPropertyType,
  Feature,
  FeatureUser,
  HiringRegisterDto,
  IAsset,
  ICustomGlobalProperty,
  ICustomProperty,
  IDeal,
  IEquipmentNote,
  IFinal,
  IHiringRegisterDto,
  IProject,
  IStatus,
  IUser,
  MapperFinal,
  RecurrenceGlobal,
  TypeRol,
  USER_ID_KEY,
  ValidateAllFormFields,
} from '@tacliatech/types';
import { environment } from '@web-frontend/environments';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { GenericComponent } from '@web-frontend/shared/class';
import {
  ActivitySearchService,
  AssetService,
  AuthService,
  CalendarApi,
  CalendarService,
  DealService,
  DynamicPropertyService,
  FinalService,
  HiringService,
  ProjectService,
  StatusService,
  StorageService,
  UserService,
} from '@web-frontend/shared/services';
import { CompanyModuleService } from '@web-frontend/shared/services/company';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { ProductService } from '@web-frontend/shared/services/products';
import { Observable, Subscription } from 'rxjs';
import { filter, finalize, map, tap } from 'rxjs/operators';
import { EditCustomPropertiesComponent } from '../../edit-custom-properties';
import {
  ADD_HOUR,
  GET_HOURS_GREATER_THAT,
  HourIsGreaterThat,
  HourIsGreaterThat23PM,
  HOURS,
  PARSER_EQUIPMENT,
} from './wrapper-detail.const';

import { MatInput } from '@angular/material/input';
import { INIT_ACTIVITY_SEARCH_PARAMS } from '@web-frontend/core/admin/activities/activities.types';
import { ModalChooseService } from '@web-frontend/shared/directives/click-delete/modal-choose';
import { EquipmentService } from '@web-frontend/shared/services/equipment';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { addMinutes, format } from 'date-fns';
import * as moment from 'moment-timezone';
import { fileData, ImageSelectorComponent } from '../../image-selector-v2';
import { RatesSelect } from '../../rates-select-table';
import { CreatePeriodicityService } from '../../selector-recurrence/create-periodicity/create-periodicity.service';
import { CreatePeriodicityParams } from '../../selector-recurrence/create-periodicity/create-periodicity.types';
import { CreateDealComponent } from '../create-deal.component';

import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';

import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { CreateFinalV2Service } from '../../create-final-v2/create-final-v2.service';
import { CreatePersonService } from '../../create-persons';
import { CreateSaleService } from '../../create-sale';
import { CalendarView } from 'angular-calendar';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { UserflowService } from '@web-frontend/shared/services/userflow/userflow.service';
import { UserflowEventType } from '@web-frontend/shared/services/userflow/userflow-event-type.enum';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import {
  dateAndHourToTimestamp,
  timestampToDate,
  timestampToHour,
} from '@web-frontend/shared/utils/dateAndTime';

@Component({
  selector: 'roma-wrapper-detail',
  templateUrl: './wrapper-detail.component.html',
  styleUrls: ['./wrapper-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WrapperDetailComponent
  extends GenericComponent
  implements OnInit, OnChanges {
  @ViewChild(EditCustomPropertiesComponent)
  editCustomProperties: EditCustomPropertiesComponent;

  @ViewChild(ImageSelectorComponent)
  imageSelector: ImageSelectorComponent;

  @ViewChild('titleInput')
  titleInput: ElementRef;

  @ViewChild('descriptionInput')
  descriptionInput: ElementRef;

  @Output()
  updateFormStatus = new EventEmitter<boolean>();

  @Output()
  updateNotes = new EventEmitter<
    {
      note: string;
      createdBy: {
        name: string;
        id: string;
      };
      date: Date;
    }[]
  >();

  @Output()
  onCreateFinish = new EventEmitter<string>();

  @Input()
  set dealData(value: Deal.Output) {
    this._dealData = value;
    if (this.type === 'EDIT' && this.dealData) {
      this.patchDealData().then();
    }
  }

  get dealData() {
    return this._dealData;
  }

  _dealData: Deal.Output;

  @Input()
  type: 'ADD' | 'EDIT' = 'ADD';

  form: FormGroup = this.fb.group({
    name: new FormControl(null, [Validators.required]),
    description: new FormControl(null),
    idFinal: new FormControl(null, []),
    notes: [[]],
    products: [{}],
    customProperties: [{}],
    imgs: [],
    files: [],
    idGroup: new FormControl(),
    taskers: new FormControl([]),
    asset: new FormControl(),
    status: new FormControl(),
    reporter: new FormControl(),
    idProject: new FormControl(),
    product_id: new FormControl(),
    initDate: new FormControl(),
    initHour: new FormControl(),
    endHour: new FormControl(),
    recurrence: new FormControl(),
    recurrenceEnd: new FormControl(),
    isAllDay: new FormControl(true),
    idEquipments: new FormControl([]),
    tasks: new FormControl(),
  });

  isLoading = false;
  isLoadingCharge = false;
  isLoadingSave = false;
  showDivImg = true;
  showDivComments = true;
  idActiveModules$ = this.companyModuleService.idActiveModules$;
  idModuleRef = Company.IdModule;
  typePropertyRef = DynamicPropertyRef;
  featureRef = Feature;
  featureRefUser = FeatureUser;
  initChildForm = false;
  parentFormSubmit = false;

  finalsLoading = false;
  finalsList: IFinal[] = [];
  finalsSelectItems: any[] = [];
  selectedFinal: Array<{
    id: string;
    img: string;
    title: string;
    value: string;
  }> = [];
  finalsCharged = false;
  showFinalsSelect = false;

  // CustomProperties
  dynamicPropertyTypeRef = DynamicPropertyType;
  customProperties: ICustomProperty[] = [];
  propertiesIsLoading = false;
  hasFeatureHandleDealCustomProperty = false;
  showCustomProperties = true;
  invalidEditCustomFields = false;
  customPropertiesGroupList: Array<{ id: string; name: string }> = [];
  customGroupProperties: ICustomGlobalProperty[] = [];
  isCustomGroupPropertyLoading = true;
  customGroupPropertiesCharged = false;
  selectedCustomGroupProperties: any[] = [];
  customPropertiesGroupSelectItems: any[] = [];
  showCustomPropertiesGroup = true;
  showTemplateForm = false;
  loadingChangeGroupProperties = false;
  customPropertiesLoaded = false;

  customPropertiesToSave: CustomPropertyFromFormDto[] = [];

  assignedLoading = false;
  assignedCharged = false;
  assignedList: IUser[] = [];
  assignedSelectItems: any[] = [];
  selectedAssigned: Array<{
    id: string;
    img: string;
    title: string;
    value: string;
  }> = [];

  assetsLoading = false;
  assetList: IAsset[] = [];
  assetSelectItems: any[] = [];
  assetsCharged = false;
  statusCharged = false;
  selectedAssets: Array<{
    id: string;
    img: string;
    title: string;
    value: string;
  }> = [];

  projectsLoading = false;
  projectsList: IProject[] = [];
  projectsSelectItems: any[] = [];
  projectsCharged = false;
  selectedProjects: Array<{
    id: string;
    img: string;
    title: string;
    value: string;
  }> = [];

  statusLoading = false;
  statusList: IStatus[] = [];
  statusSelectItems: any[] = [];
  selectedStatus: string = null;
  selectedStatusTitle: string = null;

  responsiblesLoading = false;
  responsibleCharged = false;
  responsiblesList = [];
  responsiblesSelectItems = [];
  selectedResponsibles: Array<{
    id: string;
    img: string;
    title: string;
    value: string;
  }> = [];

  isProductsLoading = false;
  productIsCharged = false;
  productsList = [];
  selectedProducts: Array<{
    id: string;
    img: string;
    title: string;
    value: string;
  }> = [];
  productsSelectItems = [];

  isEquipmentsLoading = false;
  equipmentIsCharged = false;
  equipmentList = [];
  selectedEquipments: Array<{
    id: string;
    img: string;
    title: string;
    value: string;
  }> = [];
  equipmentsSelectItems = [];

  tasksIsCharged = false;
  isTasksLoading = false;
  tasksSelectItems = [];
  selectedTasks: Array<{
    id: string;
    title: string;
    value: string;
  }> = [];

  checkboxItems = {
    showStatusInput: false,
    showReporterInput: false,
    showAssetInput: false,
    showProjectInput: false,
    showProductInput: false,
    showEquipmentInput: false,
  };

  dealNotes = [];

  hours = HOURS();
  dealDate = {
    showHourOptions: false,
    showInitHourPanel: false,
    showEndHourPanel: false,
  };

  dealProducts: RatesSelect.Items = [];

  initDate = null;

  showDateField = false;

  isLoadingDetailOfDeal = true;
  private isLoadedDetailOfDeal = false;
  chargeDeal = false;

  get featureUserScreen() {
    if (this.type == 'ADD') {
      return FeatureUser.Deal.create;
    } else {
      return FeatureUser.Deal.update;
    }
  }
  // recurrence fields
  isDealRecurrence = false;
  recurrence!: RecurrenceGlobal.Storage;
  recurrenceEnd!: RecurrenceGlobal.RecurrenceEnd;
  labelRecurrence: string = RecurrenceGlobal.BuilderGeneratorOfTagRecurrenceEnd(
    null,
    null
  );

  private refreshModalOnClose = false;
  private localStorageCheckBoxName = `createDeals.${StorageService.UserId}.checkboxs`;
  private sub$ = new Subscription();

  imagesParsed: fileData[] = [];
  private imagesParsedCopy: fileData[] = [];

  hideTitleInput = false;
  fakeTitle = '';
  isFocus = false;

  @ViewChild('dateInput', {
    read: MatInput,
  })
  dateInput: MatInput;

  @Input()
  set customer(value) {
    if (value) {
      const customerData = value;
      this.selectedFinal.push(customerData);
      this.selectedFinal = this.mapDealsSelect(this.selectedFinal);
      this.showFinalsSelect = true;
      this.form.patchValue({
        idFinal: this.selectedFinal[0].value,
      });
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: AmplitudeEvents.final_card_deal_start,
      });
    }
  }

  constructor(
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private finalService: FinalService,
    private permissionService: PermissionService,
    @Inject(MAT_DIALOG_DATA) public data,
    public userService: UserService,
    private companyModuleService: CompanyModuleService,
    private dynamicPropertyService: DynamicPropertyService,
    private i18n: TranslateService,
    private dealService: DealService,
    private hiringService: HiringService,
    public assetService: AssetService,
    private projectService: ProjectService,
    private statusService: StatusService,
    private productService: ProductService,
    private amplitudeService: AmplitudeService,
    private userflowService: UserflowService,
    private analyticsService: AnalyticsService,
    private dialogRef: MatDialogRef<CreateDealComponent>,
    private toastService: ToastService,
    private createFinalV2Service: CreateFinalV2Service,
    private createPeriodicityService: CreatePeriodicityService,
    private equipmentService: EquipmentService,
    private authService: AuthService,
    private activitySearchService: ActivitySearchService,
    private modalChooseService: ModalChooseService,
    private calendarService: CalendarService,
    private brazeService: BrazeService,
    private createSaleService: CreateSaleService,
    private createPersonService: CreatePersonService
  ) {
    super('hiring');
  }

  async ngOnInit() {
    this.resolveIsLoadingDetailOfDeal();
    this.resolveLocalStorageViewMore();
    this.watchPermissions();
  }

  initialForm: any = {};
  public haveChanges() {
    let detector = false;
    if (this.isLoading && Object.keys(this.initialForm).length === 0) {
      return detector;
    }
    try {
      for (let i = 0; i < Object.keys(this.form.value)?.length; i++) {
        if (
          this.form.value[Object.keys(this.form.value)[i]] !=
          this.initialForm[Object.keys(this.form.value)[i]]
        ) {
          detector = true;
          break;
        }
      }

      if (this.imagesParsedCopy.length) {
        detector = true;
      }

      this.updateFormStatus.next(detector);
    } catch (err) {}

    return detector;
  }

  initialChargeEvent() {
    this.initialForm = { ...this.form.value };
  }

  private resolveIsLoadingDetailOfDeal() {
    if (this.type === 'ADD') {
      this.isLoadingDetailOfDeal = false;
      this.isLoadedDetailOfDeal = true;
      this.showTitleInput();
      this.draw();
    }
  }

  private resolveLocalStorageViewMore() {
    const elements = JSON.parse(
      StorageService.GetItem(this.localStorageCheckBoxName)
    );

    // Date setting from calendar
    if (StorageService.GetItem('newDealDate')) {
      const calendar_initDate = new Date(
        StorageService.GetItem('newDealDate')
      ).toISOString();

      this.form.patchValue({
        initDate: format(calendar_initDate, 'DD/MM/YYYY'),
      });

      if (
        StorageService.GetItem('newDealDateCalendar') !== CalendarView.Month
      ) {
        this.form.patchValue({
          initHour: format(calendar_initDate, 'HH:mm'),
          endHour: format(addMinutes(calendar_initDate, 30), 'HH:mm'),
        });
      }

      this.initDate = calendar_initDate;
      this.showDateField = true;
      StorageService.Remove('newDealDate');
      StorageService.Remove('newDealDateCalendar');
    }

    if (elements) {
      this.checkboxItems = elements;
    }
  }

  get controls() {
    return this.form.controls;
  }

  ngOnChanges(): void {}

  private watchPermissions() {
    this.sub$.add(
      this.permissionService
        .hasFeatureFn(Feature.Deal.HandleCustomProperties)
        .subscribe((hasFeatureHandleDealCustomProperty) => {
          this.hasFeatureHandleDealCustomProperty = hasFeatureHandleDealCustomProperty;
        })
    );
  }

  private async patchDealData() {
    let dataImages = [];

    if (this.dealData?.imgs?.length) {
      dataImages = this.dealData.imgs;
    } else if (this.dealData?.files?.length) {
      dataImages = this.dealData.files;
    }

    this.form.patchValue({
      name: this.dealData?.name,
      description: this.dealData?.description,
      customProperties: this.dealData?.customProperties,
      idGroup: this.dealData?.idGroup,
      notes: this.dealData?.notes,
      products: this.dealData?.products,
      imgs: dataImages,
      files: dataImages,
      asset: this.dealData?.asset,
      idFinal: this.dealData?.idFinal,
      status: this.dealData?.status,
      idProject: this.dealData?.idProject,
      product_id: this.dealData?.product_id,
      reporter: this.dealData?.reporter,
      taskers: this.dealData?.taskers?.length ? this.dealData?.taskers : [],
      recurrence: this.dealData?.recurrence ? this.dealData.recurrence : null,
      recurrenceEnd: this.dealData?.recurrenceEnd
        ? this.dealData.recurrenceEnd
        : null,
      isAllDay: this.dealData?.isAllDay,
      idEquipments: this.dealData?.idEquipments?.length
        ? this.dealData?.idEquipments
        : [],
      tasks: this.dealData?.tasks?.length ? this.dealData?.tasks : [],
    });
    this.initialChargeEvent();

    this.titleInputChange(this.dealData?.name);

    if (this.dealData?.files?.length) {
      const images = this.dealData?.files;

      this.imagesParsed = images?.map((e) => {
        const file = e.fileUploadService;
        return {
          extension: file
            .substring(0, file.indexOf('?'))
            .slice(file.indexOf('.'))
            .replaceAll('.', ''),
          fileFirebaseUrl: file,
          fileName: e.name || file.substring(0, file.indexOf('.')),
        };
      });

      this.draw();
    } else if (this.dealData?.imgs?.length) {
      const images = this.dealData?.imgs;

      this.imagesParsed = images?.map((e) => {
        const file = e;
        return {
          extension: file
            .substring(0, file.indexOf('?'))
            .slice(file.indexOf('.'))
            .replaceAll('.', ''),
          fileFirebaseUrl: file,
          fileName: file.substring(0, file.indexOf('.')),
        };
      });

      this.draw();
    }

    if (this.dealData?.dates?.initTimestamp) {
      const initHour: string = this.dealData.isAllDay
        ? null
        : timestampToHour(this.dealData?.dates?.initTimestamp);
      const endHour: string = this.dealData.isAllDay
        ? null
        : timestampToHour(this.dealData?.dates?.endTimestamp);

      this.form.patchValue({
        initDate: timestampToDate(this.dealData?.dates?.initTimestamp),
        initHour: initHour || null,
        endHour: endHour || null,
      });

      this.initDate = timestampToDate(
        this.dealData?.dates?.initTimestamp,
        '-',
        true
      );
      this.isLoadingDetailOfDeal = false;
      this.isLoadedDetailOfDeal = true;
      this.showDateField = true;
      this.draw();
    }

    if (this.dealData?.asset) {
      const assetObj = this.dealData.assetObj;

      this.selectedAssets = [
        {
          id: assetObj?._id,
          img: '',
          title: assetObj?.name,
          value: assetObj?._id,
        },
      ];
    }

    if (this.dealData?.status) {
      const status = this.dealData.status;

      this.selectedStatus = status._id ?? null;
      this.selectedStatusTitle = status.name;
      this.statusList = [
        {
          ...status,
        },
      ];
      this.statusSelectItems = [
        {
          title: status.name.toString().toLocaleLowerCase(),
          value: status._id,
          id: status._id,
          color: status.color,
        },
      ];

      this.draw();
    }

    if (this.dealData?.customProperties.length === 0) {
      this.customPropertiesLoaded = true;
    }

    if (this.dealData?.idProject && this.dealData?.project) {
      const project = this.dealData.project;
      this.selectedProjects = [
        {
          id: project._id,
          img: '',
          title: project.name,
          value: project._id,
        },
      ];
      this.projectsSelectItems = [
        {
          id: project._id,
          img: '',
          title: project.name,
          value: project._id,
        },
      ];
    }

    if (this.dealData?.idEquipments && this.dealData?.equipments?.length) {
      this.selectedEquipments = this.dealData.equipments.map(PARSER_EQUIPMENT);
      this.equipmentsSelectItems = this.selectedEquipments;
    }

    if (this.dealData?.taskers?.length) {
      await this.requestAssigned();
      const assigned = this.assignedList.filter((user) =>
        this.dealData.taskers.includes(user._id)
      );

      this.selectedAssigned = assigned.map((el) => {
        return {
          id: el?._id,
          title: el?.name,
          value: el?._id,
          img: el?.img,
        };
      });
    }

    if (this.dealData?.idFinal && this.dealData?.final) {
      this.showFinalsSelect = true;
      const final = this.dealData.final;
      this.selectedFinal = [
        {
          id: final?._id,
          title: `${final.name} ${final.lastName ? final.lastName : ''}`,
          img: '',
          value: final._id,
        },
      ];
    }

    if (this.dealData?.reporter && this.dealData?.reporterObj) {
      await this.requestResponsibles();
      const reporter = this.responsiblesList.find(
        (user) => this.dealData.reporter === user._id
      );

      if (reporter) {
        this.selectedResponsibles = [
          {
            id: reporter?._id,
            title: reporter?.name,
            value: reporter?._id,
            img: reporter?.img,
          },
        ];
        this.draw();
      }
    }

    if (this.dealData?.product_id && this.dealData?.product) {
      const product = this.dealData.product;

      this.selectedProducts = [
        {
          id: this.dealData?.product_id,
          title: product?.name,
          value: this.dealData?.product_id,
          img: '',
        },
      ];

      this.draw();
    }

    if (this.dealData?.notes) {
      this.dealNotes = this.dealData?.notes?.map((note) => {
        return {
          text: note.note,
          name: note.createdBy.name,
          idAuthor: note.createdBy.id,
          createdAt: note.date,
          edit: true,
        };
      });
    }

    if (this.dealData?.products?.length) {
      this.dealProducts = this.dealData?.products;
    }

    if (this.dealData?.idGroup) {
      this.onClickSelect('customGroupProperties');
    }

    if (this.dealData?.tasks && this.dealData?.activities?.length) {
      this.selectedTasks = this.dealData.activities.map((item) => {
        return {
          id: item._id,
          name: `${item.title}`,
          title: `${item.title}`,
          value: item._id,
        };
      });
      this.tasksSelectItems = this.selectedTasks;
    }

    try {
      this.isDealRecurrence = Deal.IsDealRecurrence(this.dealData);
    } catch (err) {}

    try {
      if (this.dealData?.recurrence && this.dealData?.recurrenceEnd) {
        this.recurrence = this.dealData.recurrence;
        this.recurrenceEnd = this.dealData.recurrenceEnd;
        this.labelRecurrence = RecurrenceGlobal.BuilderGeneratorOfTagRecurrenceEnd(
          this.dealData.recurrence,
          this.dealData.recurrenceEnd
        );
      }
    } catch (err) {}

    this.initialForm = { ...this.form.value };
    this.isLoadingDetailOfDeal = false;
    this.draw();
  }

  openHour(type: 'START' | 'END') {
    if (type === 'START') {
      this.dealDate.showInitHourPanel = true;
      this.dealDate.showEndHourPanel = false;
    }
    if (type === 'END') {
      this.dealDate.showInitHourPanel = false;
      this.dealDate.showEndHourPanel = true;
    }
    this.draw();
  }

  selectHour(type: 'START' | 'END', hour: string) {
    const hasEndHour = Boolean(this.form.value?.endHour);

    if (type === 'START') {
      this.dealDate.showInitHourPanel = false;

      if (hasEndHour) {
        this.form.patchValue({
          initHour: hour,
        });

        const hourIsGreaterThat = HourIsGreaterThat(
          hour,
          this.form.value.endHour
        );

        if (hourIsGreaterThat) {
          const hourIsGreaterThat23PM = HourIsGreaterThat23PM(hour);

          this.form.patchValue({
            endHour: hourIsGreaterThat23PM ? '23:59' : ADD_HOUR(hour, 1),
          });
        }
      }

      if (!hasEndHour) {
        const hourIsGreaterThat23PM = HourIsGreaterThat23PM(hour);

        this.form.patchValue({
          initHour: hour,
          endHour: hourIsGreaterThat23PM ? '23:59' : ADD_HOUR(hour, 1),
        });
      }
    }

    if (type === 'END') {
      this.dealDate.showEndHourPanel = false;
      this.form.patchValue({
        endHour: hour,
      });
    }

    this.draw();
  }

  filterHoursByInitHour() {
    if (this.form.value?.initHour) {
      const filterHours = GET_HOURS_GREATER_THAT(this.form.value.initHour);

      return filterHours;
    }

    return this.hours;
  }

  filterHours(hourValue: string, hourType: 'initHour' | 'endHour') {
    const CORRECT_TIME_LENGTH = 5;

    if (hourType === 'initHour') {
      if (hourValue) {
        if (hourValue.length === CORRECT_TIME_LENGTH) {
          return this.hours;
        }
        return this.hours.filter((hour) => hour.includes(hourValue));
      }
    } else {
      if (hourValue) {
        if (hourValue.length === CORRECT_TIME_LENGTH) {
          return this.filterHoursByInitHour();
        }
        return this.filterHoursByInitHour().filter((hour) =>
          hour.includes(hourValue)
        );
      } else {
        this.filterHoursByInitHour();
      }
    }

    return this.hours;
  }

  clearHourInputs() {
    this.form.patchValue({
      initHour: null,
      endHour: null,
      initDate: null,
    });

    this.showDateField = false;
    this.draw();
  }

  openModalRecurrence() {
    if (!this.isDealRecurrence) {
      const params = this.builderParams();

      this.createPeriodicityService
        .open({
          data: {
            ...params,
          },
        })
        .pipe(filter((res) => !!res))
        .subscribe((res) => {
          if (res?.recurrence) {
            this.recurrence = res.recurrence;
          }

          if (res?.recurrenceEnd) {
            this.recurrenceEnd = res.recurrenceEnd;
          }

          if (res?.recurrence && res?.recurrenceEnd) {
            this.labelRecurrence = RecurrenceGlobal.BuilderGeneratorOfTagRecurrenceEnd(
              res.recurrence,
              res.recurrenceEnd
            );

            this.form.patchValue({
              recurrence: res.recurrence,
              recurrenceEnd: res.recurrenceEnd,
            });
          }

          if (res?.wantDeleteRecurrence) {
            this.labelRecurrence = RecurrenceGlobal.BuilderGeneratorOfTagRecurrenceEnd(
              null,
              null
            );
            this.recurrence = null;
            this.recurrenceEnd = null;

            this.form.patchValue({
              recurrence: null,
              recurrenceEnd: null,
            });
          }

          this.draw();
        });
    }
  }

  launchModalCreate() {}

  private builderParams() {
    const params: CreatePeriodicityParams =
      !this.recurrence && !this.recurrenceEnd
        ? {
            type: 'ADD',
            params: {
              date: new Date(),
            },
          }
        : {
            type: 'EDIT',
            params: {
              date: new Date(),
              recurrence: this.recurrence,
              recurrenceEnd: this.recurrenceEnd,
            },
          };

    return params;
  }

  private isValidCustomProperties(): boolean {
    let isValid = true;
    const fields = this.editCustomProperties.EditableFieldComponent.toArray();
    let index = 0;
    for (const field of fields) {
      try {
        if (field.type === 'array-string-multiple') {
          if (field.submitNgSelect(field.value || [])) {
            isValid = false;
          }
        } else {
          let fieldInputElement: HTMLInputElement;

          if (field.type === 'text')
            fieldInputElement = field.inputEditable.nativeElement;

          if (field.type === 'date')
            fieldInputElement = field.inputDateEditable.nativeElement;

          if (field.type === 'number')
            fieldInputElement = field.inputNumberEditable.nativeElement;

          if (field.type === 'boolean')
            fieldInputElement = field.selectBoolean.nativeElement;

          if (field.submit(fieldInputElement)) {
            isValid = false;
          }
        }
      } catch (err) {}
      index++;
    }

    return isValid;
  }

  onSaveClick() {
    this.isLoadingSave = true;
    this.type === 'ADD' ? this.submit() : this.editSubmit();
  }

  onChargeClick() {
    this.chargeDeal = true;
    this.isLoadingCharge = true;
    this.type === 'ADD' ? this.submit() : this.editSubmit();
  }

  changeFileArray(evt: fileData[]) {
    this.imagesParsed = evt?.length ? evt : [];
    if (this.imagesParsedCopy && this.imagesParsedCopy.length > evt?.length) {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_card_removeAttachments,
      });
    } else if (evt?.length > 0) {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_card_addAttachments,
      });
    }
    this.imagesParsedCopy = this.imagesParsed;
    this.draw();
  }

  private adjustHoursAndAllDay(): void {
    let initHourValue = this.form.get('initHour').value;
    let endHourValue = this.form.get('endHour').value;

    if (this.form.get('initDate').value) {
      if (!initHourValue && endHourValue) {
        initHourValue = '00:00';
      }
      if (!endHourValue && initHourValue) {
        endHourValue = '23:59';
      }
    }

    const isAllDay = Deal.isAllDay(initHourValue, endHourValue);
    this.form.get('isAllDay').setValue(isAllDay);
    this.initialForm['isAllDay'] = isAllDay;
  }

  /**
   * This function is used in create-deal,
   * it is important that all the transactions that are made here
   * are carried out Synchronously
   */
  public async editSubmit(options: { close: boolean } = { close: true }) {
    this.parentFormSubmit = true;
    const isValid = this.checkValidators();
    const isValidCustomProperties = this.isValidCustomProperties();

    this.adjustHoursAndAllDay();

    if (!isValid || !isValidCustomProperties) {
      this.isLoading = false;
      this.isLoadingSave = false;
      this.isLoadingCharge = false;
      return;
    }

    this.isLoading = true;
    this.draw();

    const images: any = (await this.imageSelector.uploadFiles2()).map(
      (fileObj) => fileObj
    );

    const itemsImg = [];

    if (images.length) {
      images.forEach((image) => {
        itemsImg.push({
          extension: image.extension,
          fileUploadService: image.fileFirebaseUrl,
          name: image.fileName,
        });
      });
    }

    this.form.patchValue({
      files: itemsImg,
      imgs: null,
    });

    if (this.customPropertiesToSave.length > 0) {
      this.form.patchValue({
        customProperties: [
          ...this.form.value.customProperties,
          ...this.customPropertiesToSave,
        ],
      });
    }

    const EVENT = { event: AmplitudeEvents.deal_edit };

    const data = { ...this.form.value };

    let initDate = null;
    let endDate = null;
    if (data?.initDate) {
      initDate = dateAndHourToTimestamp(
        data.initDate,
        data?.initHour || '00:00'
      );
      endDate = dateAndHourToTimestamp(data.initDate, data.endHour || '23:59');
    }

    data.dates = { initTimestamp: initDate, endTimestamp: endDate };

    try {
      const updatedDeal = await this.dealService
        .updateOne(this.dealData?._id, data)
        .toPromise();
      this.isLoading = false;
      this.isLoadingCharge = false;
      this.isLoadingSave = false;
      this.parentFormSubmit = false;
      this.refreshModalOnClose = true;

      this.amplitudeService.sendEvent(EVENT);
      this.toastService.show({
        text: this.i18n.instant('deals.edit'),
        type: 'success',
        msDuration: 4000,
      });

      this.draw();

      if (options.close) {
        this.close();
      }

      // This allows to charge the deal after updating it
      if (this.chargeDeal) {
        this.charge(updatedDeal);
        this.chargeDeal = false;
      }
    } catch (err) {
      this.parentFormSubmit = false;
      this.isLoading = false;
      this.isLoadingCharge = false;
      this.isLoadingSave = false;
      this.draw();
      this.toastService.show({
        text: this.i18n.instant('deals.error'),
        type: 'error',
        msDuration: 4000,
      });
    }
  }

  toggleDateField() {
    this.showDateField = !this.showDateField;
    this.draw();
  }

  scrollToErrorLabel() {
    setTimeout(() => {
      const cusid_ele = document.getElementsByClassName('error-label');
      for (let i = 0; i < cusid_ele.length; ++i) {
        const item = cusid_ele[i];
        if (i === cusid_ele.length - 1) {
          item.scrollIntoView();
        }
      }
    }, 100);
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);
    const initHour = this.form.get('initHour').value;
    const endHour = this.form.get('endHour').value;

    if (this.form.value.idFinal) {
      this.scrollToErrorLabel();
    }

    let isValid = this.form.valid;

    if (initHour && endHour) {
      isValid = isValid && !HourIsGreaterThat(initHour, endHour);
    }

    if (!isValid) {
      this.toastService.show({
        text: this.i18n.instant('general.formErrors'),
        type: 'error',
        msDuration: 4000,
      });
    }
    return isValid;
  }

  async submit() {
    this.parentFormSubmit = true;
    const isValid = this.checkValidators();
    this.haveChanges();

    this.adjustHoursAndAllDay();

    if (!isValid) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      this.isLoading = false;
      this.isLoadingSave = false;
      this.isLoadingCharge = false;
      return;
    }

    this.isLoading = true;
    this.draw();

    const customPropertiesLength = Object.keys(this.form.value.customProperties)
      .length;

    if (this.customPropertiesToSave.length > 0 && customPropertiesLength) {
      const ids = new Set(this.form.value.customProperties.map((d) => d._id));
      const customProperties = [
        ...this.form.value.customProperties,
        ...this.customPropertiesToSave.filter((d) => !ids.has(d._id)),
      ];

      this.form.patchValue({
        customProperties: [...customProperties],
      });
    } else if (
      this.customPropertiesToSave.length > 0 &&
      customPropertiesLength <= 0
    ) {
      this.form.patchValue({
        customProperties: [...this.customPropertiesToSave],
      });
    }

    const data: HiringRegisterDto = await this.convertToDto();

    const obs$: Observable<any> =
      this.type === 'ADD'
        ? this.hiringService.createOne(data)
        : this.dealService.updateOne(this.dealData?._id, data);

    const eventMap = new Map([
      ['ADD', { event: AmplitudeEvents.deal_create }],
      ['EDIT', { event: AmplitudeEvents.deal_edit }],
    ]);

    const EVENT = eventMap.get(this.type).event;
    this.sub$.add(
      obs$.subscribe(
        (res) => {
          const message = this.type === 'ADD' ? 'deals.create' : 'deals.edit';
          this.isLoading = false;
          this.isLoadingCharge = false;
          this.isLoadingSave = false;
          this.parentFormSubmit = false;
          this.toastService.show({
            text: this.i18n.instant(message),
            type: 'success',
            msDuration: 4000,
          });
          this.analyticsService.trackEvent({
            sources: ['amplitude', 'braze', 'userflow'],
            eventName: EVENT,
          });
          this.refreshModalOnClose = true;
          this.onCreateFinish.emit(res._id);
          this.draw();

          // This allows to charge the deal after creating it
          if (this.chargeDeal) {
            this.charge(res as IDeal);
            this.chargeDeal = false;
          }
        },
        () => {
          this.parentFormSubmit = false;
          this.isLoading = false;
          this.isLoadingCharge = false;
          this.isLoadingSave = false;
          this.draw();

          this.toastService.show({
            text: this.i18n.instant('deals.error'),
            type: 'error',
            msDuration: 4000,
          });
        }
      )
    );
  }

  //Promise<HiringRegisterDto>
  private async convertToDto(): Promise<any> {
    const { value } = this.form;

    const date = this.form.value?.initDate
      ? dateAndHourToTimestamp(
          this.form.value.initDate,
          this.form.value?.initHour
        )
      : null;

    const endDate = this.form.value?.initDate
      ? dateAndHourToTimestamp(
          this.form.value.initDate,
          this.form.value?.endHour || '23:59'
        )
      : null;

    const images = (await this.imageSelector.uploadFiles2()).map(
      (fileObj) => fileObj
    );
    const itemsImg = [];

    if (images.length) {
      images.forEach((e) => {
        itemsImg.push({
          extension: e.extension,
          fileUploadService: e.fileFirebaseUrl,
          name: e.fileName,
        });
      });
    }

    const hiringRegisterDto: IHiringRegisterDto = {
      customProperties: value.customProperties,
      dealContactInfo: {
        createdBy: StorageService.GetItem(USER_ID_KEY),
        contactName: '',
        contactPhone: '',
      },
      dealDetailInfo: {
        title: value.name,
        type: DealType.Team,
        name: null,
        project_id: null,
        product_id: value.product_id,
        idProject: value.idProject,
        description: value.description,
        jobDescription: null,
        vertical_id: null,
        imgs: null,
        files: itemsImg?.length ? itemsImg : [],
        notes: value.notes,
        products: value.products,
        stage: null,
        quantity: null,
        idFinal: value.idFinal,
        status: value.status,
        idGroup: value.idGroup,
        recurrence: value?.recurrence ? value?.recurrence : null,
        recurrenceEnd: value?.recurrenceEnd ? value?.recurrenceEnd : null,
        isAllDay: value?.isAllDay ? true : false,
        idEquipments: value?.idEquipments?.length ? value.idEquipments : [],
        tasks: value?.tasks?.length ? value.tasks : [],
        dates: { initTimestamp: date, endTimestamp: endDate },
      },
      dealProfileInfo: {
        owner: StorageService.GetItem(USER_ID_KEY),
        customer: StorageService.GetItem(CUSTOMER_ID_KEY),
        taskers: value.taskers,
        asset: value.asset,
        vendor: null,
        reporter: value.reporter,
        members: [],
        billingProfile: null,
      },
      dealTemporalInfo: {
        timeStatus: undefined,
        offsetTimeStatus: 0,
        visitation: false,
        visitationDate: undefined,
      },
      dealLocationInfo: {
        city: null,
        address: null,
        address2: null,
        latitude: null,
        longitude: null,
      },
    };

    return hiringRegisterDto;
  }

  toggleDivImg() {
    this.showDivImg = !this.showDivImg;
  }

  toggleDivComments() {
    this.showDivComments = !this.showDivComments;
  }

  addingNotes(evt: IEquipmentNote[]) {
    let isEditing = false;
    let event: string;
    const notes = evt.map((note) => {
      return {
        note: note.text,
        createdBy: {
          name: note.name,
          id: note.idAuthor,
        },
        date: note.createdAt,
      };
    });

    if (notes) {
      if (evt.length < this.form.value.notes?.length) {
        event = AmplitudeEvents.deal_card_deleteComments;
      } else {
        notes.map((note, index) => {
          if (this.form.value.notes && this.form.value.notes[index]) {
            if (note.note !== this.form.value.notes[index].note) {
              isEditing = true;
            }
          }
        });
        event = isEditing
          ? AmplitudeEvents.deal_card_editComments
          : AmplitudeEvents.deal_template_delete;
      }
    }

    this.amplitudeService.sendEvent({
      event: event,
    });

    this.form.patchValue({
      notes: notes,
    });
  }

  changeSelectItem(
    evt: Array<{ id: string; img: string; title: string; value: string }>,
    type:
      | 'idFinal'
      | 'taskers'
      | 'idGroup'
      | 'asset'
      | 'projects'
      | 'status'
      | 'reporter'
      | 'product_id'
      | 'idEquipments'
      | 'tasks'
  ) {
    // THIS LINE IS IMPORTANT
    // ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️
    this.controls[type].patchValue(evt[0] ? evt[0].value : null);
    switch (type) {
      case 'idFinal':
        this.selectedFinal = evt;
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_editFinal,
        });
        break;
      case 'taskers':
        const ids = evt.length ? evt.map((el) => el.id) : [];

        this.selectedAssigned = evt;
        this.controls[type].patchValue(ids);

        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_editUser,
        });
        this.brazeService.sendEvent({
          event: BrazeEventType.deal_user_assigned,
          eventProperties: {
            assignedUserIds: ids,
          },
        });
        break;
      case 'asset':
        this.selectedAssets = evt;

        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_editAsset,
        });
        break;
      case 'projects':
        this.selectedProjects = evt;

        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_editProject,
        });
        break;
      case 'reporter':
        this.selectedResponsibles = evt;

        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_editResponsible,
        });
        this.brazeService.sendEvent({
          event: BrazeEventType.deal_owner_assigned,
          eventProperties: {
            assignedUserIds: evt.length ? evt.map((el) => el.id) : [],
          },
        });
        break;
      case 'status':
        const status = evt.length
          ? this.statusList.find(
              (el) => el._id.toString() === evt[0].value.toString()
            )
          : null;

        this.selectedStatus = evt[0].value;
        this.selectedStatusTitle = evt[0].title;

        this.controls[type].patchValue(status);

        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_editStatus,
        });
        break;
      case 'product_id':
        this.selectedProducts = evt;
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_editSaleCategory,
        });
        break;
      case 'idGroup':
        this.selectedCustomGroupProperties = evt;
        this.onChangeDynamicGroupProperties(
          this.selectedCustomGroupProperties[0]?.value || ''
        );
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_editTemplate,
        });
        break;
      case 'idEquipments':
        const idEquipments = evt.map((el) => el.id);

        this.selectedEquipments = evt;
        this.controls[type].patchValue(idEquipments);

        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_editEquipment,
        });
        break;
      case 'tasks':
        this.selectedTasks = evt;
        const tasks = evt.map((el) => el.id);
        this.controls[type].patchValue(tasks);
        break;
    }
  }

  async onClickSelect(
    item:
      | 'finals'
      | 'taskers'
      | 'customGroupProperties'
      | 'assets'
      | 'projects'
      | 'status'
      | 'responsible'
      | 'product'
      | 'equipment'
      | 'tasks'
  ) {
    switch (item) {
      case 'finals':
        if (!this.finalsCharged) {
          this.requestFinals();
        }
        break;
      case 'taskers':
        if (!this.assignedCharged) {
          await this.requestAssigned();
        }
        break;
      case 'assets':
        if (!this.assetsCharged) {
          this.requestAssets();
        }
        break;
      case 'projects':
        if (!this.projectsCharged) {
          this.requestProjects();
        }
        break;
      case 'status':
        if (!this.statusCharged) {
          this.requestStatus();
        }
        break;
      case 'responsible':
        if (!this.responsibleCharged) {
          await this.requestResponsibles();
        }
        break;
      case 'product':
        if (!this.productIsCharged) {
          this.requestProducts();
        }
        break;
      case 'customGroupProperties':
        if (!this.customGroupPropertiesCharged) {
          this.findCustomPropertyGroup();
        }
        break;
      case 'equipment':
        if (!this.equipmentIsCharged) {
          this.requestEquipments();
        }
        break;
      case 'tasks':
        if (!this.tasksIsCharged) {
          this.requestActivities();
        }
        break;
    }
  }

  requestActivities() {
    this.isTasksLoading = true;
    this.sub$.add(
      this.activitySearchService
        .search({ optimized: true, INIT_ACTIVITY_SEARCH_PARAMS })
        .pipe(
          finalize(() => {
            this.isTasksLoading = false;
            this.tasksIsCharged = true;
            this.draw();
          })
        )
        .subscribe((res) => {
          const docs = (res.docs || []).map((item) => {
            return {
              id: item._id,
              name: `${item.title}`,
              title: `${item.title}`,
              value: item._id,
            };
          });
          this.tasksSelectItems = docs;
        })
    );
  }

  private async requestResponsibles() {
    this.responsiblesLoading = true;
    this.draw();

    try {
      const res = await this.assetService
        .findUsersByAssetsShared()
        .pipe(
          map((res) => {
            return res.map((el) => {
              return {
                ...el,
                img: el?.img
                  ? `${environment.firebaseConfig.storageUrl}/uploads%2Fuser%2F${el.img}`
                  : 'assets/images/default-user.png',
              };
            });
          }),
          finalize(() => {
            this.responsiblesLoading = false;
            this.responsibleCharged = true;
            this.draw();
          })
        )
        .toPromise();

      this.responsiblesList = res;
      this.responsiblesSelectItems = this.mapDealsSelect(res);
      this.draw();
    } catch (err) {}
  }

  private requestProducts() {
    this.isProductsLoading = true;

    this.draw();
    this.sub$.add(
      this.productService
        .findAllProductsByCompany_SELECT()
        .pipe(
          finalize(() => {
            this.isProductsLoading = false;
            this.productIsCharged = true;
            this.draw();
          })
        )
        .subscribe((res) => {
          const docs = (res || []).map((item) => {
            return {
              id: item._id,
              name: `${item.name}`,
              title: `${item.name}`,
              value: item._id,
            };
          });
          this.productsList = docs;
          this.productsSelectItems = docs;

          this.draw();
        })
    );
  }

  private requestFinals() {
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Finals)))
        .subscribe(() => {
          this.finalsLoading = true;
          this.draw();

          this.finalService
            .findAllFinalsByCompany_SELECT()
            .pipe(
              map((res) => MapperFinal(res)),
              finalize(() => {
                this.finalsLoading = false;
                this.finalsCharged = true;
                this.draw();
              })
            )
            .subscribe((res) => {
              this.finalsList = res;
              this.finalsSelectItems = this.mapDealsSelect(this.finalsList);
              this.draw();
            });
        })
    );
  }

  private async requestAssigned() {
    this.assignedLoading = true;
    this.draw();

    try {
      const res: IUser[] = await this.userService
        .findAllUsersByCompany_SELECT()
        .pipe(
          finalize(() => {
            this.assignedLoading = false;
            this.assignedCharged = true;
            this.draw();
          })
        )
        .toPromise();

      this.assignedList = res;
      this.assignedSelectItems = this.mapDealsSelect(this.assignedList);
      this.draw();
    } catch (err) {}
  }

  private mapDealsSelect(deals) {
    return deals
      .map((res) => {
        const name = res.name.toString().toLocaleLowerCase();
        const lastName = res.lastName?.toString().toLocaleLowerCase();
        return {
          title: `${name} ${lastName ? lastName : ''}`,
          value: res._id ? res._id : res.id,
          id: res._id ? res._id : res.id,
          img: res?.img,
        };
      })
      .sort((a, b) => (a.title > b.title ? 1 : -1));
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  deleteRecurrenceDeal() {
    const THIS_EVENT = 'THIS_EVENT';
    const THIS_EVENT_AND_SUBSEQUENT = 'THIS_EVENT_AND_SUBSEQUENT';

    this.modalChooseService
      .open({
        title: 'recurrence.delete_title',
        options: [
          { title: 'recurrence.item1', value: THIS_EVENT },
          {
            title: 'recurrence.item2',
            value: THIS_EVENT_AND_SUBSEQUENT,
          },
        ],
        actionText: 'general.confirm',
      })
      .subscribe((res) => {
        const { action } = res;

        if (action === 'EXECUTE') {
          const { option } = res;
          this.handleExecuteDeleteRecurrence(option);
        }
      });
  }

  private handleExecuteDeleteRecurrence(option: unknown) {
    const THIS_EVENT = 'THIS_EVENT';
    const THIS_EVENT_AND_SUBSEQUENT = 'THIS_EVENT_AND_SUBSEQUENT';

    const isDealOriginal = Boolean(this.dealData?.parentRecurrence);

    const obs$ = (deleteType: CalendarApi.DealEventDeleteType) => {
      const obs$ = !isDealOriginal
        ? this.calendarService.deleteDealParentRecurrence({
            idDealParentRecurrence: this.dealData?._id,
            deleteType,
            date: this.dealData?.initDateStr,
          })
        : this.calendarService.deleteDealRecurrence({
            idDealRecurrence: this.dealData?._id,
            deleteType,
            date: this.dealData?.initDateStr,
          });

      obs$.pipe(
        tap((res) => {
          if (res) {
            this.refreshModalOnClose = true;
          }
        }),
        finalize(() => {
          this.isLoading = false;
          this.draw();
        })
      );

      return obs$;
    };

    if (option === THIS_EVENT) {
      this.isLoading = true;
      this.draw();

      obs$(CalendarApi.DealEventDeleteType.ThisEvent).subscribe(() => {
        this.refreshModalOnClose = true;
        this.close();
      });
    } else if (option === THIS_EVENT_AND_SUBSEQUENT) {
      this.isLoading = true;
      this.draw();

      obs$(CalendarApi.DealEventDeleteType.ThisEventAndSubsequent).subscribe(
        () => {
          this.refreshModalOnClose = true;
          this.close();
        }
      );
    }
  }

  sendEvent() {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_card_delete_start,
    });
  }
  eventDelete() {
    if (this.type == 'EDIT') {
      this.isLoading = true;
      this.draw();

      this.sub$.add(
        this.dealService
          .logicalDelete({
            ids: [this.dealData._id],
          })
          .subscribe(() => {
            this.toastService.show({
              text: 'general.deleteManyCorrect',
              type: 'error',
              msDuration: 4000,
              icon: 'assets/icons/kanban/delete.svg',
            });
            this.refreshModalOnClose = true;
            this.amplitudeService.sendEvent({
              event: AmplitudeEvents.deal_card_delete_delete,
            });
            this.close();
          })
      );
    }
  }

  titleInputChange(value: string) {
    if (value != '') {
      this.fakeTitle = value;
      this.hideTitleInput = true;

      if (value != this.dealData?.name) {
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_editName,
        });
      }
    }
  }

  descriptionInputChange(value: string) {
    if (value != '') {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_card_editDescription,
      });
    }
  }

  showTitleInput() {
    try {
      this.hideTitleInput = false;
      setTimeout(() => {
        this.titleInput.nativeElement.focus();
      }, 100);
    } catch (err) {}
  }
  //CUSTOM PROPERTIES//
  updateLoading(evt) {
    this.isLoading = evt;
    this.draw();
  }

  eventValueChanged(evt) {
    this.form.patchValue({ customProperties: [...evt] });
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_card_editCustomField,
    });
  }

  propertiesRequired(value: boolean) {
    this.invalidEditCustomFields = value;
  }

  customPropertiesFormChange($event) {
    this.customProperties = $event;
    this.setCustomProperties();
  }

  changeInputsCheckBox(
    evt: MatCheckboxChange,
    type:
      | 'showStatusInput'
      | 'showReporterInput'
      | 'showAssetInput'
      | 'showProjectInput'
      | 'showProductInput'
      | 'showEquipmentInput'
  ) {
    try {
      this.checkboxItems[type] =
        this.checkboxItems[type] == null || this.checkboxItems[type] == false
          ? true
          : false;
    } catch (err) {
      console.log({ err });
    }

    this.saveInputsCheckBox();
  }

  private requestEquipments() {
    this.isEquipmentsLoading = true;
    this.draw();

    this.sub$.add(
      this.authService.user$
        .pipe(
          filter((res) => Boolean(res)),
          filter((res) => res.role.includes(TypeRol.USER_ROLE))
        )
        .subscribe(() => {
          this.requestEquipmentUserRole();
        })
    );

    this.sub$.add(
      this.authService.user$
        .pipe(
          filter((res) => Boolean(res)),
          filter((res) => res.role.includes(TypeRol.ADMIN_ROLE))
        )
        .subscribe(() => {
          this.requestEquipmentsAdminRole();
        })
    );
  }

  private requestEquipmentUserRole() {
    this.sub$.add(
      this.equipmentService
        .findAllEquipmentsByCompany_SELECT()
        .pipe(
          finalize(() => {
            this.isEquipmentsLoading = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          this.equipmentList = res;
          this.equipmentsSelectItems = res.map(PARSER_EQUIPMENT);
          this.equipmentIsCharged = true;
          this.draw();
        })
    );
  }

  private requestEquipmentsAdminRole() {
    this.sub$.add(
      this.equipmentService
        .findAllEquipmentsByCompany_SELECT()
        .pipe(
          finalize(() => {
            this.isEquipmentsLoading = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          this.equipmentList = res;
          this.equipmentsSelectItems = res.map(PARSER_EQUIPMENT);

          this.equipmentIsCharged = true;
          this.draw();
        })
    );
  }

  private saveInputsCheckBox() {
    localStorage.setItem(
      this.localStorageCheckBoxName,
      JSON.stringify(this.checkboxItems)
    );
  }

  firstInit = true;
  private setCustomProperties() {
    this.sub$.add(
      this.permissionService
        .hasFeatureFn(Feature.Deal.HandleCustomProperties)
        .subscribe((hasFeatureHandleDealCustomProperty) => {
          this.hasFeatureHandleDealCustomProperty = hasFeatureHandleDealCustomProperty;
          const isFieldRequired = (index: number) => {
            return !hasFeatureHandleDealCustomProperty ? index < 2 : true;
          };
          for (let i = 0; i < this.customProperties?.length; i++) {
            if (this.customProperties[i].isRequired) {
              this.form.addControl(
                this.customProperties[i]._id,
                new FormControl(
                  null,
                  isFieldRequired(i) ? [Validators.required] : []
                )
              );
            }
            this.attachToCustomProperties(
              this.customProperties[i],
              this.customProperties[i].value
            );
          }
          for (let i = 0; i < this.customGroupProperties?.length; i++) {
            if (this.customGroupProperties[i].isRequired) {
              this.form.addControl(
                this.customGroupProperties[i]._id,
                new FormControl(
                  this.type === 'EDIT'
                    ? this.valueCustomProperty(
                        this.customGroupProperties[i]._id
                      )
                    : null,
                  isFieldRequired(i) ? [Validators.required] : []
                )
              );
            }
          }
          if (this.firstInit) {
            this.initialForm = { ...this.form.value };
            this.firstInit = false;
          }
          this.initChildForm = true;
          this.parentFormSubmit = false;
          this.customPropertiesLoaded = true;
        })
    );
  }

  propertiesLoading(evt: boolean) {
    this.propertiesIsLoading = evt;
  }

  private findCustomPropertyGroup() {
    this.isCustomGroupPropertyLoading = true;
    this.draw();

    this.pushSubcription(
      this.dynamicPropertyService
        .getGroups()
        .pipe(
          finalize(() => {
            this.isCustomGroupPropertyLoading = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          const docs = res.map((el) => {
            return {
              id: el._id,
              name: el.name,
            };
          });

          this.customPropertiesGroupList = docs;
          this.customPropertiesGroupSelectItems = this.mapDealsSelect(
            this.customPropertiesGroupList
          );
          this.customGroupPropertiesCharged = true;

          if (this.dealData?.idGroup) {
            const group = this.customPropertiesGroupSelectItems.find(
              (x) => x.id === this.dealData?.idGroup
            );

            if (group) {
              this.changeSelectItem([group], 'idGroup');
            }
          }

          this.draw();
        })
    );
  }

  private requestAssets() {
    this.assetsLoading = true;
    this.draw();

    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Assets)))
        .subscribe(() => {
          this.sub$.add(
            this.assetService
              .findAllAssetsByCompany_SELECT()
              .pipe(
                finalize(() => {
                  this.assetsCharged = true;
                  this.assetsLoading = false;
                  this.draw();
                })
              )
              .subscribe((res: IAsset[]) => {
                this.assetList = res;
                this.assetSelectItems = this.mapDealsSelect(this.assetList);
                this.draw();
              })
          );
        })
    );
  }

  private requestStatus() {
    this.statusLoading = true;
    this.draw();

    this.statusService
      .findByUser([StorageService.CompanyId])
      .pipe(
        finalize(() => {
          this.statusLoading = false;
          this.statusCharged = true;
          this.draw();
        })
      )
      .subscribe((res: IStatus[]) => {
        this.statusList = res;
        this.statusSelectItems = res.map((x) => ({
          title: x.name.toString().toLocaleLowerCase(),
          value: x._id,
          id: x._id,
          color: x.color,
        }));
        this.draw();
      });
  }

  private requestProjects() {
    this.projectsLoading = true;
    this.draw();

    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Projects)))
        .subscribe(() => {
          this.draw();

          this.projectService
            .findAllProjectsByCompany_SELECT()
            .pipe(
              finalize(() => {
                this.projectsLoading = false;
                this.draw();
              })
            )
            .subscribe((res) => {
              this.projectsList = res;
              this.projectsSelectItems = this.mapDealsSelect(this.projectsList);
              this.draw();
            });
        })
    );
  }

  private onChangeDynamicGroupProperties(idGroup: string) {
    this.form.patchValue({
      idGroup: idGroup,
    });

    if (idGroup === '') {
      this.showTemplateForm = false;
      this.removeCustomDealTemplates();
    } else {
      this.loadingChangeGroupProperties = true;
      this.draw();

      this.sub$.add(
        this.dynamicPropertyService
          .findByGroup(idGroup)
          .pipe(
            finalize(() => {
              this.loadingChangeGroupProperties = false;
              this.draw();
            })
          )
          .subscribe((res) => {
            this.customGroupProperties = res;
            this.setCustomProperties();
            this.showTemplateForm = true;

            this.draw();
          })
      );
    }
  }

  valueCustomProperty(propertyId: string) {
    const currentProperty = this.dealData?.customProperties?.find(
      (property) => property._id.toString() === propertyId
    );

    if (this?.dealData?.customProperties && currentProperty != null) {
      if (currentProperty.type === 'DATE') {
        if (
          currentProperty.value === 'Invalid Date' ||
          currentProperty.value === null
        ) {
          return '';
        }
        const d = new Date(currentProperty.value);
        d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
        return d;
      }
      return currentProperty.value;
    }

    return null;
  }

  formatDateValue(value) {
    const dateString = value;
    const [day, month, year] = dateString.split('/');
    const dateObj = new Date(+year, +month - 1, +day);

    return dateObj;
  }

  dateInputChange(property, value) {
    this.attachToCustomProperties(
      property,
      moment(this.formatDateValue(value)).format('YYYY-MM-DD')
    );
    this.setCustomPropertyValue(property.isRequired, property._id, value);
  }

  removeCustomDealTemplates() {
    this.isLoading = true;
    let i = 0;
    for (i = 0; i < this.customGroupProperties.length; i++) {
      if (
        this.customPropertiesToSave[this.customGroupProperties[i].name] !==
        undefined
      ) {
        delete this.customPropertiesToSave[this.customGroupProperties[i].name];
      }

      if (this.customGroupProperties[i].isRequired) {
        this.form.removeControl(this.customGroupProperties[i]._id);
      }

      const propertyIndex =
        this.type === 'EDIT'
          ? this.form.value.customProperties.findIndex(
              (element) =>
                element._id.toString() === this.customGroupProperties[i]._id
            )
          : this.customPropertiesToSave.findIndex(
              (element) =>
                element._id.toString() === this.customGroupProperties[i]._id
            );
      if (this.type === 'EDIT') {
        this.form.value.customProperties.splice(propertyIndex, 1);
      } else {
        this.customPropertiesToSave.splice(propertyIndex, 1);
      }
    }
    this.isLoading = false;
  }

  showPropertyInput(propertyId: string): void {
    const btnProperty = document.getElementById('btn-' + propertyId);
    if (btnProperty) {
      btnProperty.style.display = 'none';
    }
    const property = document.getElementById(propertyId);
    if (property) {
      property.style.display = 'block';
    }
  }

  setCustomPropertyValue(isRequired: boolean, id: string, value) {
    if (isRequired) {
      this.form.controls[id].setValue(value);
    }
  }

  dateBlur(el: HTMLInputElement, name: string) {
    try {
      if (el) {
        this.attachToCustomProperties(name, el?.value);
      }
    } catch (err) {}
  }

  attachToCustomProperties(property, value: any) {
    const { name, type, _id } = property;
    const propertyIndex = this.customPropertiesToSave.findIndex(
      (element) => element._id === property._id
    );
    const propertyValues = {
      _id,
      name,
      value,
      type,
    };

    if (propertyIndex < 0) {
      this.customPropertiesToSave = [
        ...this.customPropertiesToSave,
        propertyValues,
      ];
    } else {
      type === 'SELECT' ? (value?.length === 0 ? (value = '') : null) : null;

      if (value) {
        this.customPropertiesToSave[propertyIndex] = propertyValues;
      } else {
        this.customPropertiesToSave.splice(propertyIndex, 1);
      }
    }

    if (this.type === 'EDIT') {
      //Update Form (TYPE = 'EDIT')
      const propertyFormIndex = this.form.value.customProperties.findIndex(
        (element) => element._id === property._id
      );

      if (propertyFormIndex > -1) {
        this.form.value.customProperties[propertyFormIndex].value = value;
        this.form.value.customProperties[propertyFormIndex].type =
          property.type;
      } else {
        this.form.patchValue({
          customProperties: [
            ...this.form.value.customProperties,
            propertyValues,
          ],
        });
      }
    }
  }

  typeCustomProperty(type: DynamicPropertyType) {
    if (type === DynamicPropertyType.String) {
      return 'text';
    } else if (type === DynamicPropertyType.Select) {
      return 'array-string-multiple';
    }

    return type?.toLowerCase();
  }

  isBooleanDefault(property: ICustomProperty, value) {
    return property.value === value;
  }

  close() {
    const refresh = this.refreshModalOnClose ? true : null;
    this.dialogRef.close(refresh);
  }

  changeProducts(event) {
    this.form.patchValue({
      products: event,
    });
  }

  addFinals() {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_card_editFinal_start,
    });
    this.sub$.add(
      this.createFinalV2Service.open().subscribe((data) => {
        this.refreshFinals(data);
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_editFinal_create,
        });
      })
    );
  }

  public addUsers(): void {
    this.analyticsService.trackEvent({
      sources: ['amplitude', 'userflow'],
      eventName: AmplitudeEvents.deal_card_editUser_start,
    });
    this.createPersonService.open().subscribe((data) => {
      if (!data) {
        return;
      }

      this.refreshUsers(data.id);
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_card_editUser_create,
      });
    });
  }

  trackChangeDateIfIsValid(event: MatDatepickerInputEvent<Date>) {
    if (
      this.form.value.initDate &&
      event &&
      event === this.form.value.initDate
    ) {
      this.analyticsService.trackEvent({
        sources: ['amplitude', 'braze'],
        eventName: AmplitudeEvents.deal_date_changed,
        eventProperties: {
          date: this.form.value.initDate,
          hour: this.form.value.initHour,
        },
      });
    }
  }

  private refreshFinals(idAdded: string) {
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Finals)))
        .subscribe(() => {
          this.finalsLoading = true;
          this.draw();

          this.finalService
            .findAllFinalsByCompany_SELECT()
            .pipe(
              finalize(() => {
                this.finalsLoading = false;
                this.draw();
              })
            )
            .subscribe((res) => {
              this.finalsSelectItems = res.map((res) => {
                return {
                  title: `${res.name} ${res.lastName ? res.lastName : ''}`,
                  value: res._id,
                  id: res._id,
                };
              });

              this.updateSelectedContact(idAdded);
            });
        })
    );
  }

  private refreshUsers(idAdded: string): void {
    this.assignedLoading = true;
    this.draw();

    this.sub$.add(
      this.userService
        .findAllUsersByCompany_SELECT()
        .pipe(
          finalize(() => {
            this.assignedLoading = false;
            this.assignedCharged = true;
            this.draw();
          })
        )
        .subscribe((res) => {
          this.assignedList = res;
          this.assignedSelectItems = this.mapDealsSelect(this.assignedList);

          const newUser = this.assignedSelectItems.find(
            (c) => c.id === idAdded
          );

          this.selectedAssigned = [...this.selectedAssigned, newUser];

          if (this.selectedAssigned.length > 0) {
            this.form.patchValue({
              taskers: this.selectedAssigned.map((e) => e.id),
            });
          }
        })
    );
  }

  updateSelectedContact(idContactAdded: string) {
    // search selectedContact
    this.selectedFinal = [
      this.finalsSelectItems.find((c) => c.id === idContactAdded),
    ];

    if (this.selectedFinal.length > 0) {
      // update Form
      this.form.patchValue({
        idFinal: this.selectedFinal[0].value,
      });
      this.draw();
    }
  }

  inputFocus() {
    this.isFocus = true;
    this.draw();
  }

  clearDate() {
    this.dateInput.value = '';
  }

  charge(res: IDeal) {
    // this.router.navigate(['/admin', 'sales'], {
    //   queryParams: { type: 'BDG' },
    // });

    this.createSaleService
      .open({
        position: { right: '0px' },
        data: {
          mode: 'ADD',
          deal: res,
        },
      })
      .pipe(map((res) => !res))
      .subscribe((r) => {
        // this.router.navigate(['/admin', 'sales'], {
        //   queryParams: { type: 'BDG' },
        // });
      });
  }
}
