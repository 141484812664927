import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PictureComponent } from './picture.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingModule } from '../loading';
import { PlatformDisableContentModule } from '../platform-disable-content/platform-disable-content.module';

@NgModule({
  declarations: [PictureComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    LoadingModule,
    TranslateModule.forChild(),
    PlatformDisableContentModule,
  ],
  exports: [PictureComponent],
})
export class PictureModule {}
