import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingModule } from '@web-frontend/shared/components/loading';
import { HourInputComponent } from './hour-input.components';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@web-frontend/shared/helpers/material/material.module';

@NgModule({
  declarations: [HourInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild(),
    LoadingModule,
    MaterialModule,
  ],
  providers: [],
  exports: [HourInputComponent],
})
export class HourInputModule {}
