import { CreateCatalogueModule } from '@web-frontend/shared/components/create-catalogue';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { ActivityCalendarModule } from '@web-frontend/shared/components/activity-calendar';

import { TableModule } from '@web-frontend/shared/components/table/table.module';
import { FilterModule } from '@web-frontend/shared/components/filter';
import { BasicMessageModule } from '@web-frontend/shared/components/basic-message';
import { LoadingModule } from '@web-frontend/shared/components/loading';
import { I18nChild } from '@web-frontend/shared/i18n';
import { CreateActivityModule } from '@web-frontend/shared/components/create-activity';
import { RomaToolTipModule } from '@web-frontend/shared/directives/roma-tooltip';
import { RatesComponent } from './rates.component';
import { PermissionsModule } from '@web-frontend/shared/components/permissions';
import { RmFilterModule } from '@web-frontend/shared/components/globals/rm-filter';
import { NotResultFoundModule } from '@web-frontend/shared/components/not-result-found';
import { StripeSnackbarSharedModule } from '@web-frontend/shared/components/snackbar/stripe-snackbar.module';
import { TranslateModule } from '@ngx-translate/core';
import { DownloadBtnModule } from '@web-frontend/shared/components/download-btn/download-btn.module';
import { BaseButtonModule } from '@web-frontend/common/components/button/baseButton.module';
import { EmptyStateModule } from '../../../common/components/emptyState/empty-state.module';

@NgModule({
  declarations: [RatesComponent],
  imports: [
    CommonModule,
    TableModule,
    FilterModule,
    TranslateModule.forChild(),
    BasicMessageModule,
    MatButtonModule,
    MatIconModule,
    LoadingModule,
    CreateCatalogueModule,
    MatDialogModule,
    ActivityCalendarModule,
    MatButtonToggleModule,
    PermissionsModule,
    RmFilterModule,
    NotResultFoundModule,
    RomaToolTipModule,
    StripeSnackbarSharedModule,
    PermissionsModule,
    DownloadBtnModule,
    BaseButtonModule,
    EmptyStateModule,
  ],
  exports: [RatesComponent],
  providers: [DatePipe],
})
export class RatesModule {}
