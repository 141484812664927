<ng-container *ngIf="_dealProducts.length && showActions">
  <div class="list__header" style="height: 28px;font-weight: 600;font-size: 11px;letter-spacing: 0.6px;border-radius: 4px;padding: 4px 8px;text-transform: uppercase;display: flex;flex-direction: row;background-color: #f2f6fc;color: #97a3c5;">
    <div class="__name" style="flex-basis: 50%">{{ 'general.name' | translate }}</div>
    <div class="__price" style="flex-basis: 30%; text-align: right;" [ngClass]="{ without__stock: !showStock }">
      {{
        showStock
          ? ('general.stockPrice' | translate)
          : ('billing.price' | translate)
      }}
    </div>
  </div>
  <div
    class="catalogue__item"
    *ngFor="let catalogue of _dealProducts"
    romaProFeature
    [featureUser]="featureRefUser.Catalogue.update"
    (clicked)="canEdit ? openEditModal(catalogue) : null"
    style="
      font-size: 14px;
      margin-bottom: 12px;
      padding: 8px;
      border-radius: 6px;
      display: flex;
      flex-direction: row;
      align-items: start;
      cursor: pointer;"
    >
    <div class="catalogue__name"
    style="
      color: #414b66;
      max-width: 50%;
      overflow: hidden;
      display: -webkit-box;
      overflow-wrap: break-word;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      align-self: flex-start;"
    >{{ catalogue.name }}</div>
    <div class="catalogue__price" style="
      color: #15152e;
      margin-left: auto;
      text-align: right;">
      <ng-container *ngIf="showStock">{{ catalogue?.stock }} x</ng-container>
      {{
        catalogue?.sellPrice > 0
          ? (catalogue?.sellPrice | currencyFormat)
          : '--'
      }}
    </div>
    <div class="__actions" *ngIf="showActions">
      <img *ngIf="canEdit" src="assets/icons/gl_edit-gray-fill.svg" />
      <img
        src="assets/icons/gl_trash-gray.svg"
        (click)="$event.stopPropagation(); deleteCatalogue(catalogue._id)"
      />
    </div>
  </div>
  <div class="total__price__row" *ngIf="showTotals" style="
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  border-top: 1px dashed #e3ebfa;
  margin-bottom: 8px;">
    <div class="__total" style="flex-basis: 200px;">Total</div>
    <div class="__price" style="flex-basis: 150px; text-align: right">
      {{ getTotalPrice(_dealProducts) || 0 | currencyFormat }}
    </div>
  </div>
</ng-container>

<ng-container>
  <div class="list__header" *ngIf="!showActions" style="height: 28px;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.6px;
  border-radius: 4px;
  padding: 4px 8px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  background-color: #f2f6fc;
  color: #97a3c5;">
    <p
      class="__name-preview"
      [ngClass]="{ opacity: !showActions && !_dealProducts.length }"
      style="flex-basis: 60%"
    >
      {{ 'sales.table.colum.article' | translate }}
    </p>
    <p
      class="__price-preview"
      style=" flex-basis: 40%;
      text-align: right"
      [ngClass]="{ opacity: !showActions && !_dealProducts.length }"
    >
      {{ 'sales.table.colum.quantity' | translate }}
    </p>
  </div>

  <div *ngIf="_dealProducts.length && !showActions">
    <div class="catalogue__item" *ngFor="let catalogue of _dealProducts" style=" font-size: 14px;
    margin-bottom: 12px;
    padding: 8px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: start;">
      <div class="catalogue__name-preview" style="
      flex-basis: 60%;
      color: #414b66;
      max-width: 50%;
      overflow: hidden;
      display: -webkit-box;
      overflow-wrap: break-word;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      align-self: flex-start;">
        {{ catalogue.name }}
      </div>
      <div class="catalogue__price-preview"
        style="align-self: flex-start;
        margin-left: auto;
        text-align: right;">
          {{ catalogue?.stock }} x
          {{ catalogue?.sellPrice || 0 | currencyFormat }}
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!(resize$ | async)?.payload?.mobile">
  <button
    *ngIf="!showSelectInput && showActions"
    type="button"
    class="add-field-btn"
    (click)="openRmSelectPanel()"
  >
    {{ 'general.add2' | translate }} item
    <img src="assets/icons/gl_plus.svg" style="margin-top: -5px" />
  </button>
  <rm-select
    *ngIf="showSelectInput && showActions"
    [showPanelElement]="true"
    [isClearable]="true"
    [placeholderText]="'general.select' | translate"
    [items]="cataloguesList"
    [isLoading]="isCataloguesLoading"
    [disabled]="isCataloguesLoading"
    [activeItems]="selectedCatalogues"
    [showAddButton]="true"
    [isAbsolute]="false"
    [mode]="'MULTIPLE'"
    inputHeight="36px"
    type="users"
    [addButtonText]="'general.createNew'"
    [addButtonFeatureUser]="featureRefUser.Catalogue.create"
    (addElement)="addCatalogue()"
    (changeItems)="changeCalatogueList($event)"
  >
  </rm-select>
</ng-container>

<ng-container *ngIf="(resize$ | async)?.payload?.mobile">
  <button
    *ngIf="showActions"
    type="button"
    class="add-field-btn"
    (click)="
      !cataloguesCharged ? requestCatalogues() : null; showSelectInput = true
    "
  >
    {{ 'general.add2' | translate }} item
    <img src="assets/icons/gl_plus.svg" style="margin-top: -5px" />
  </button>

  <roma-mobile-modal-list
    [type]="'MULTIPLE'"
    [titleText]="'general.resultProducts' | translate"
    [isOpen]="showSelectInput"
    [items]="cataloguesList"
    [selectedItems]="selectedCatalogues"
    [isLoading]="isCataloguesLoading"
    [showSelectedItems]="false"
    [addButtonText]="'general.addNewProduct'"
    [showAddButton]="true"
    (addElement)="addCatalogue()"
    (changeItems)="changeCalatogueList($event)"
    (onClose)="showSelectInput = false"
  ></roma-mobile-modal-list>
</ng-container>
