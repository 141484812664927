import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileHandle } from '@web-frontend/shared/components/file-upload/file-upload.component';
import { CertificateStatus } from 'src/types-legacy/lib/enum/certificate-status.enum';
import { UserService } from '../users';
import { v4 as uuidv4 } from 'uuid';

const URL_MS_INVOICE = 'invoice';
const URL_MS_CERTIFICATE = 'applications';

@Injectable({
  providedIn: 'root',
})
export class BillDigitalCertificateService {
  countriesThatNeedCertificate: string[] = ['MX'];
  apiUrl: string;

  constructor(private http: HttpClient, private userService: UserService) {}

  async isCompanyAllowedToCreateBills(): Promise<{
    isAllowed: boolean;
    application: any;
  }> {
    if (this.userService.validateCountryMX()) {
      const applications = await this.findApplications();
      return {
        isAllowed:
          applications?.results?.length > 0 &&
          applications.results[0].status === CertificateStatus.Accepted,
        application: applications.results[0],
      };
    } else {
      return { isAllowed: true, application: null };
    }
  }

  async findApplications(id?: string) {
    try {
      const response = await this.http
        .get<any>(
          `:API_BASE_URL${URL_MS_INVOICE}/${URL_MS_CERTIFICATE}${
            id ? `/${id}` : ''
          }`
        )
        .toPromise();
      return response;
    } catch (e) {
      return e;
    }
  }

  async sendApplication(cer: FileHandle, key: FileHandle, password: string) {
    try {
      const formData = new FormData();
      formData.append('id', uuidv4());
      formData.append('files', cer.file);
      formData.append('files', key.file);
      formData.append('password', password);

      const response = await this.http
        .post<any>(
          `:API_BASE_URL${URL_MS_INVOICE}/${URL_MS_CERTIFICATE}`, 
          formData, {observe: 'response'},
        )
        .toPromise();
      return response;
    } catch (e) {
      return e.status;
    }
  }
}
