import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { Browser } from '@capacitor/browser';
import { AuthService, StorageService } from '@web-frontend/shared/services';
import { CompanyService } from '@web-frontend/shared/services/company';
import { Subscription } from 'rxjs';
import { PlatformService } from '../platform-disable-content/platform.service';
import { Router } from '@angular/router';
import { TutorialService } from '@web-frontend/shared/services/tutorial';
import { Lang } from '../../i18n';

const CONTACT_LINKS = {
  en: 'https://www.taclia.com/en/contact',
  default: 'https://www.taclia.com/contacto',
};

@Component({
  selector: 'modal-delete-account',
  templateUrl: './modal-delete-account.component.html',
  styleUrls: ['./modal-delete-account.component.scss'],
})
export class ModalDeleteAccountComponent implements OnInit, OnDestroy {
  step = 1;

  isLoading = false;

  dispatchCloseEvents = true;

  $closeModalSubscriber: Subscription;
  private academyLink: string;

  constructor(
    private readonly amplitudeService: AmplitudeService,
    private readonly dialog: MatDialog,
    private readonly authService: AuthService,
    private readonly companyService: CompanyService,
    private readonly platformService: PlatformService,
    private readonly router: Router,
    private readonly tutorialService: TutorialService
  ) {}

  ngOnInit(): void {
    this.$closeModalSubscriber = this.dialog.afterAllClosed.subscribe(() => {
      this.beforeClose();
    });
    this.getTutorialLink();
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.$closeModalSubscriber.unsubscribe();
    }, 1000);
  }

  private getTutorialLink(): void {
    this.tutorialService
      .get('help-delete')
      .toPromise()
      .then((res) => {
        this.academyLink = res?.academy;
      });
  }

  private closeDialog() {
    this.dialog.closeAll();
  }

  nextStep(): void {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deleteAccountStep1Delete,
    });
    this.step += 1;
  }

  async onClickDeleteAccount(): Promise<void> {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deleteAccountStep2Delete,
    });

    this.isLoading = true;
    const company = await this.companyService.getCompanyInfo().toPromise();
    await this.companyService.delete(company._id).toPromise();

    this.authService.logout();
    if (this.platformService.isMobile()) {
      this.dispatchCloseEvents = false;
      this.closeDialog();
      this.router.navigate(['/login']);
    } else {
      window.location.href = 'https://www.taclia.com/';
    }
  }

  onClickConserve(): void {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deleteAccountStep2Conserve,
    });

    this.closeDialog();
  }

  onClickContact(): void {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deleteAccountStep1Contact,
    });
    const userLang: string = StorageService.GetItem('USER_LANG');
    const url =
      userLang && CONTACT_LINKS[userLang.toLowerCase()]
        ? CONTACT_LINKS[userLang]
        : CONTACT_LINKS['default'];

    Browser.open({
      url,
      presentationStyle: 'popover',
    });
  }

  beforeClose(): void {
    if (this.dispatchCloseEvents) {
      let event = AmplitudeEvents.deleteAccountStep1Close;
      if (this.step === 2) {
        event = AmplitudeEvents.deleteAccountStep2Close;
      }

      this.amplitudeService.sendEvent({
        event,
      });
    }
  }

  onClose(): void {
    this.closeDialog();
  }

  modalTitle(): string {
    return !this.isLoading ? `modalDeleteAccount.step${this.step}.title` : '';
  }
}
