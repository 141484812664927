import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';

import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

import { CreateExpenseService } from './create-expense.service';
import { ModalTemplateModule } from '../modal-template';
import { FieldErrorDisplayModule } from '../field-error-display';
import { AppendCustomPropertiesModule } from '../append-custom-properties';
import { EditCustomPropertiesModule } from '../edit-custom-properties';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SelectorRecurrenceModule } from '../selector-recurrence';
import { RmSelectModule } from '../globals/rm-select';
import { InfoModule } from '@web-frontend/shared/components/info/info.module';
import { PermissionsModule } from '../permissions';
import { ImageSelectorModule } from '../image-selector';
import { CreateExpenseComponent } from './create-expense.component';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { ObligatoryFieldSignModule } from '../obligatory-field-sign/obligatory-field-sign.module';
import { BaseButtonModule } from '@web-frontend/common/components/button/baseButton.module';

@NgModule({
  declarations: [CreateExpenseComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    MatDialogModule,
    MatIconModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatCheckboxModule,
    MatButtonModule,
    FieldErrorDisplayModule,
    AppendCustomPropertiesModule,
    ImageSelectorModule,
    EditCustomPropertiesModule,
    MatProgressSpinnerModule,
    PermissionsModule,
    SelectorRecurrenceModule,
    RmSelectModule,
    InfoModule,
    ClickDeleteModule,
    ObligatoryFieldSignModule,
    BaseButtonModule,
  ],
  exports: [CreateExpenseComponent],
  providers: [CreateExpenseService],
})
export class CreateExpenseModule {}
