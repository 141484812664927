<roma-modal-template
  [title]="
    params.type === 'ADD'
      ? ('equipments.addModal' | translate)
      : ('equipments.editModal' | translate)
  "
  [isLoading]="isLoading"
  (success)="submit()"
  (cancel)="close()"
  [showInfo]="true"
  [askBeforeClose]="haveChanges"
  (info)="info('info')"
>
  <form class="global-container" [formGroup]="form">
    <div class="global-item">
      <div class="form-group">
        <label class="field-label" for="ref">
          {{ 'equipments.create.ref' | translate
          }}<obligatory-field-sign></obligatory-field-sign>
        </label>

        <input
          type="text"
          class="form-control"
          id="ref"
          aria-describedby="ref"
          formControlName="ref"
          [placeholder]="'equipments.create.refPh' | translate"
          [ngClass]="{ 'is-invalid': fieldRef.displayError || false }"
        />

        <roma-field-error-display
          #fieldRef
          fieldName="ref"
          [errorMsg]="'equipments.create.refError'"
          [form]="form"
        >
        </roma-field-error-display>
      </div>

      <div class="form-group">
        <label class="field-label" for="title">
          {{ 'equipments.create.title' | translate }}
        </label>

        <input
          type="text"
          class="form-control"
          id="title"
          aria-describedby="title"
          formControlName="title"
          [placeholder]="'equipments.create.titlePh' | translate"
          [ngClass]="{ 'is-invalid': fieldTitle.displayError || false }"
        />

        <roma-field-error-display
          #fieldTitle
          fieldName="title"
          [errorMsg]="'equipments.create.titleError'"
          [form]="form"
        >
        </roma-field-error-display>
      </div>

      <div
        class="form-group wrapper-pro-feature-user"
        romaProSelect
        [featureUser]="featureRefUser.Address.show"
      >
        <label class="field-label" for="assets">
          {{ 'equipments.create.address' | translate }}
        </label>

        <ng-select
          bindLabel="name"
          bindValue="_id"
          formControlName="idAssets"
          [items]="assets"
          [multiple]="true"
          [placeholder]="'equipments.create.addressPh' | translate"
          [notFoundText]="'hiring.form.placeholder_notfound' | translate"
          [ngClass]="{ 'is-invalid': fieldAsset?.displayError || false }"
          [loading]="assetsLoading"
          (click)="onClickSelect('assets')"
        >
        </ng-select>

        <roma-field-error-display
          #fieldAsset
          fieldName="title"
          [errorMsg]="'checklist.addressError'"
          [form]="form"
        >
        </roma-field-error-display>
      </div>

      <div class="form-group">
        <label class="field-label" for="idUsers">
          {{ 'equipments.create.users' | translate }}
        </label>

        <ng-select
          bindLabel="name"
          bindValue="_id"
          formControlName="idUsers"
          [items]="users"
          [multiple]="true"
          [notFoundText]="'hiring.form.placeholder_notfound' | translate"
          [placeholder]="'equipments.create.usersPh' | translate"
          [loading]="usersLoading"
          (click)="onClickSelect('users')"
        >
        </ng-select>
      </div>

      <div class="form-group">
        <label class="field-label" for="ref">
          {{ 'equipments.create.units' | translate }}
        </label>

        <input
          type="number"
          class="form-control"
          id="ref"
          aria-describedby="units"
          formControlName="units"
          [placeholder]="'equipments.create.unitsPh' | translate"
        />
      </div>

      <!--       <div class="form-group">
        <label for="brand">
          {{ 'equipments.create.brand' | translate }}
        </label>

        <input
          type="text"
          class="form-control"
          id="brand"
          aria-describedby="brand"
          formControlName="brand"
          [placeholder]="'equipments.create.brandPh' | translate"
        />
      </div> -->

      <div
        *ngIf="(idActiveModules$ | async).includes(idModuleRef.Finals)"
        class="form-group wrapper-pro-feature-user"
        romaProSelect
        [featureUser]="featureRefUser.Final.show"
      >
        <label class="field-label" for="final">
          {{ 'finals.final' | translate }}
        </label>

        <ng-select
          id="final"
          bindLabel="key"
          bindValue="value"
          formControlName="final"
          [items]="finals"
          [multiple]="false"
          [notFoundText]="'hiring.form.placeholder_notfound' | translate"
          [placeholder]="'finals.create.final' | translate"
          [loading]="finalsLoading"
          (click)="onClickSelect('finals')"
        >
        </ng-select>
      </div>

      <!--      <div class="form-group">
        <label for="revisionDate">
          {{ 'equipments.create.revisionDate' | translate }}
        </label>

        <input
          type="date"
          class="form-control"
          id="revisionDate"
          aria-describedby="revisionDate"
          formControlName="revisionDate"
          [placeholder]="'equipments.create.revisionDatePh' | translate"
          [ngClass]="{ 'is-invalid': fieldRevisionDate.displayError || false }"
        />

        <roma-field-error-display
          #fieldRevisionDate
          fieldName="revisionDate"
          [errorMsg]="'equipments.create.revisionDateError'"
          [form]="form"
        >
        </roma-field-error-display>
      </div> -->

      <div class="form-group">
        <label class="field-label" for="description">
          {{ 'equipments.create.description' | translate }}
        </label>
        <textarea
          type="text"
          cols="30"
          rows="2"
          class="form-control custom-textarea"
          formControlName="description"
          maxLength="155"
        >
        </textarea>
      </div>
    </div>

    <!-- <div class="global-item">
     <div class="form-group">
        <label for="model">
          {{ 'equipments.create.model' | translate }}
        </label>

        <input
          type="text"
          class="form-control"
          id="model"
          aria-describedby="model"
          formControlName="model"
          [placeholder]="'equipments.create.modelPh' | translate"
        />
      </div>
      <div class="form-group">
        <label for="number">
          {{ 'equipments.create.number' | translate }}
        </label>

        <input
          type="text"
          class="form-control"
          id="number"
          aria-describedby="number"
          formControlName="number"
          [placeholder]="'equipments.create.numberPh' | translate"
        />
      </div>

      <div class="form-group">
        <label for="revisionFrequency">
          {{ 'equipments.create.revisionFrequency' | translate }}
        </label>

        <input
          type="text"
          class="form-control"
          id="revisionFrequency"
          aria-describedby="revisionFrequency"
          formControlName="revisionFrequency"
          [placeholder]="'equipments.create.revisionFrequencyPh' | translate"
        />
      </div>
     <div class="form-group">
        <label for="annualAmortization">
          {{ 'equipments.create.annualAmortization' | translate }}
        </label>

        <input
          romaOnlyNumber
          type="text"
          class="form-control"
          id="annualAmortization"
          aria-describedby="annualAmortization"
          formControlName="annualAmortization"
          [placeholder]="'equipments.create.annualAmortizationPh' | translate"
        />
      </div>

      <div class="form-group">
        <label for="revisionDate">
          {{ 'equipments.create.warrantyDate' | translate }}
        </label>

        <input
          type="date"
          class="form-control"
          id="warrantyDate"
          aria-describedby="warrantyDate"
          formControlName="warrantyDate"
          [placeholder]="'equipments.create.warrantyDatePh' | translate"
        />
      </div>
    </div> -->

    <div class="global-item">
      <label class="field-label" for="files"> </label>

      <div class="images-container">
        <roma-image-selector
          [type]="'equipment'"
          [title]="'hiring.form.add-images-lbl' | translate"
          [quantity]="5"
          [mode]="'ADD'"
          [accept-files]="true"
          [filesServer]="this.modalParams?.data?.files"
          (images-changed)="updateImages($event)"
          [delete-images-service]="deleteImages$"
          (OnErrorEvent)="showAlert($event)"
          [feature]="featureRef.Final.UploadFiles"
          [module]="idModuleRef.Equipments"
        >
        </roma-image-selector>
      </div>

      <label class="field-label" for="notes">
        {{ 'equipments.create.notes' | translate }}
      </label>
      <roma-equipment-notes
        [mode]="modalParams?.type"
        [data]="modalParams?.data?.notes"
        (addingNotes)="addingNotes($event)"
      >
      </roma-equipment-notes>
    </div>

    <div class="global-item">
      <roma-append-custom-properties
        [typeRef]="typePropertyRef.Equipment"
        [rowLayout]="true"
        size="lg"
        [control]="form.get('customProperties')"
        [parentForm]="form"
        [initForm]="initChildForm"
        [parentFormSubmit]="parentFormSubmit"
        (customPropertiesForm)="customPropertiesFormChange($event)"
        (initialCharge)="initialChargeEvent()"
        (closeModal)="close()"
      >
      </roma-append-custom-properties>
    </div>
  </form>
</roma-modal-template>
