import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Period } from '@web-frontend/shared/interfaces/period';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, UserService } from '@web-frontend/shared/services';
import { IUser } from '@tacliatech/types';

@Component({
  selector: 'roma-contract-form-modal',
  templateUrl: './contract-form-modal.component.html',
  styleUrls: ['./contract-form-modal.component.scss'],
})
export class ContractFormModalComponent implements OnInit {
  private sub$ = new Subscription();
  user$ = this.authService.user$;
  user: IUser | null = null;
  isLoading = false;
  adminMode: boolean = false;

  form: FormGroup = new FormGroup({
    contract: new FormGroup({
      workingHours: new FormControl(null),
      workingHoursPeriod: new FormControl(Period.Week),
      workdays: new FormControl([]),
    }),
  });

  constructor(
    private dialogRef: MatDialogRef<ContractFormModalComponent>,
    private readonly toastService: ToastService,
    private readonly i18n: TranslateService,
    private readonly userService: UserService,
    private readonly authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: { userId: string | null }
  ) {}

  ngOnInit() {
    this.user$.subscribe((user) => {
      this.user = user;
    });

    if (!!this.data.userId) {
      this.adminMode = true;
    }
  }

  close(): void {
    this.form.reset();
    this.dialogRef.close(false);
  }

  submit(): void {
    if (this.form.valid) {
      this.isLoading = true;
      const contract = this.form.get('contract')?.value;
      this.sub$.add(
        this.userService
          .updateOne(this.adminMode ? this.data.userId : this.user._id, {
            contract,
            company: this.user.company._id,
          })
          .subscribe(() => {
            this.isLoading = false;
            this.toastService.show({
              text: this.i18n.instant('person.create.form.popupTitle'),
              description: this.i18n.instant(
                this.adminMode
                  ? 'person.create.form.popupSubtitleAdmin'
                  : 'person.create.form.popupSubtitle'
              ),
              type: 'success',
              msDuration: 4000,
            });
            this.dialogRef.close(true);
          })
      );
    }
  }
}
