import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { ToastConfig, ToastData } from '../utils/toast-config';
import { ToastRef } from '../utils/toast-ref';

@Component({
  selector: 'roma-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit, OnDestroy {
  @Output()
  onToastClose = new EventEmitter();

  @Output()
  onClickLink = new EventEmitter();

  iconType?: string | 'info' | 'error';
  private toastConfig: ToastConfig = {
    position: {
      top: 20,
      right: 20,
    },
    animation: {
      fadeOut: 2500,
      fadeIn: 300,
    },
  };

  private intervalId: any;

  constructor(readonly data: ToastData, readonly ref: ToastRef) {
    this.iconType = data.type === 'success' ? 'done' : data.type;
    // TODO this might not be required with strong types
    data.text = data.text ?? '';
    data.class = data.class ?? '';
    data.description = data.description ?? '';
  }

  ngOnInit() {
    this.intervalId = setTimeout(() => {
      this.close();
    }, this.data.msDuration);
  }

  ngOnDestroy() {
    clearTimeout(this.intervalId);
  }

  close() {
    this.onToastClose.emit();
    this.ref.close();
  }

  eventClickLink() {
    if (typeof this.data.linkData === 'function') {
      this.data.linkData();
    }
    this.onClickLink.emit(this.data.linkData);
    this.close();
  }
}
