import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadService } from './file-upload.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [FileUploadService],
})
export class FileUploadModule {}
