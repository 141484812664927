<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<!-- eslint-disable @angular-eslint/template/no-negated-async -->

<!-- Stripe Snackbar -->
<roma-shared-stripe-snackbar></roma-shared-stripe-snackbar>

<!-- Disclaimer row -->
<roma-pro-disclaimer-banner
  *ngIf="isLowerThan1450px"
  (emitRefresh)="refresh()"
  [rootModule]="'sales'"
></roma-pro-disclaimer-banner>

<ng-container *ngIf="showEmptyState">
  <empty-state
    contentType="catalogue"
    (firstButtonClick)="addCatalogue()"
  ></empty-state
></ng-container>

<ng-container *ngIf="!showEmptyState">
  <div class="row" *ngIf="showTitle">
    <div class="col-8 col-md-6">
      <h1 class="page-title">
        {{ 'rates.title' | translate }}
        <div class="tooltip-container">
          <img
            width="32px"
            class="help-icon"
            [src]="iconDialog"
            (click)="showDialog()"
          />
          <div
            *ngIf="showToolTip"
            class="title-tooltip-wrapper"
            (click)="toggleTooltip(false)"
          ></div>
          <div
            class="help-tooltip 22"
            *ngIf="showToolTip"
            romaRomaToolTip
            [showToolTip]="showToolTip"
            (clicked)="toggleTooltip($event)"
          >
            <div [innerHTML]="'rates.tooltip' | translate"></div>
            <a
              *ngIf="academyLink?.length"
              [href]="academyLink"
              target="_blank"
              >{{ 'rates.tooltipUrl' | translate }}</a
            >
          </div>
        </div>
      </h1>
    </div>
    <div class="col-4 col-md-6 video-section">
      <div
        *ngIf="getLang() === 'es'"
        class="tutorial"
        (click)="openVideo('rates')"
      >
        <strong>{{ 'tutorial.tutorial' | translate }}</strong>
        <img class="video" src="assets/icons/gl_play-tutorial.svg" />
      </div>
      <button
        class="roma-button add-button-3 mobile-hidden"
        style="display: inline-block"
        romaProFeature
        [featureUser]="featureRefUser.Catalogue.create"
        (clicked)="addCatalogue()"
      >
        {{ 'general.addButton' | translate }}
        <img class="icon-option" src="assets/icons/gl_plus-btn.svg" />
      </button>
    </div>
  </div>

  <div *ngIf="showFilter" class="row">
    <div class="col-12">
      <div
        *ngIf="!(resize$ | async)?.payload?.mobile"
        class="container__filters"
      >
        <roma-rm-filter-core
          *ngIf="sourceSearch"
          [source]="sourceSearch"
          (changes)="changeFilters($event)"
        ></roma-rm-filter-core>
        <roma-rm-filter-button
          *ngIf="sourceOptions"
          [source]="sourceOptions"
          (changes)="changeFilters($event)"
        ></roma-rm-filter-button>
        <roma-rm-filter-clear
          *ngIf="sourceOptions"
          [sources]="[sourceSearch, sourceOptions]"
          (changes)="changeFilters($event)"
        ></roma-rm-filter-clear>
      </div>

      <div
        *ngIf="(resize$ | async)?.payload?.mobile"
        class="container__filters"
      >
        <roma-rm-filter-button
          *ngIf="sourceOptions"
          [source]="sourceOptions"
          (changes)="changeFilters($event)"
        ></roma-rm-filter-button>
        <button
          class="mobile-button-add"
          romaProFeature
          [featureUser]="featureRefUser.Catalogue.create"
          (clicked)="addCatalogue()"
        >
          <img
            src="/assets/icons/gl_plus-1.svg"
            alt="{{ 'general.add' | translate }}"
          />
        </button>
      </div>
    </div>
  </div>

  <div class="page-options">
    <!--   <mat-button-toggle-group
      #group="matButtonToggleGroup"
      (change)="changeViewType($event)"
    >
      <mat-button-toggle
        value="ROW"
        [checked]="viewTypeActive === 'ROW' ? true : false"
      >
        <mat-icon svgIcon="table_icon"></mat-icon>
        <span class="icon-text">{{ "dashboard.list" | translate }}</span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  
    <a
      *ngIf="!(resize$ | async)?.payload?.mobile"
      class="btn-download"
      [ngClass]="{ 'is-hidden': isLoading || !catalogues?.data?.length }"
      (click)="downloadExcel()"
    >
      {{ "general.downloadExcel" | translate }}
    </a> -->

    <div
      *ngIf="!isLoading || !catalogues?.data?.length"
      class="table-total md"
      style="float: left"
    >
      {{ resultSearch?.total || 0 }} {{ 'general.results' | translate }}
    </div>

    <div class="buttonDiv">
      <base-button
        *ngIf="!isMobile"
        size="xsmall"
        buttonClass="tertiary"
        (click)="onImportClick()"
      >
        {{ 'budgets.import.button' | translate }}
        <img class="download-icon" src="assets/icons/gl_import_Outline.svg" />
      </base-button>

      <roma-shared-download-btn
        *ngIf="!(resize$ | async)?.payload?.mobile && catalogues?.data?.length"
        [showDownloadExcel]="true"
        [showDownloadPdf]="false"
        (download)="download($event)"
      ></roma-shared-download-btn>
    </div>
  </div>
  <div
    id="containerTable"
    class="containerTable"
    [ngClass]="{
      'video-tutorial-box':
        !(searchParamsHasChange$ | async) && !catalogues?.data?.length
    }"
    [ngStyle]="{ 'margin-top': tableMarginTop }"
    (scroll)="checkScroll($event)"
  >
    <div *ngIf="viewTypeActive === 'ROW'">
      <div *ngIf="isLoading" class="col-12">
        <roma-loading></roma-loading>
      </div>

      <div
        *ngIf="
          !isLoading &&
          (searchParamsHasChange$ | async) &&
          !catalogues?.data?.length
        "
        class="col-12"
      >
        <roma-not-result-found></roma-not-result-found>
      </div>

      <!-- <div
        *ngIf="!isLoading && !hasQuerySearch() && !activities?.data?.length"
        class="col-12"
      >
        <roma-basic-message
          [message]="'activity.notResult' | translate"
        ></roma-basic-message>
      </div> -->
      <div class="">
        <div
          class="col-12"
          [ngClass]="{ 'is-hidden': isLoading || !catalogues?.data?.length }"
        >
          <roma-table
            class="table"
            markAction="true"
            type="catalogues"
            idModule="CATALOGUE_MODULE"
            [appendSettings]="'YES'"
            [appendInfo]="'YES'"
            [tagNotAvailable]="'activity.notAvailable' | translate"
            [data]="catalogues"
            [displayedColumns]="columns"
            [customFields]="customProperties"
            [showActions]="false"
            (changeSort)="sortChange($event)"
            (wantDelete)="removeCatalogue($event)"
            (wantEdit)="editCatalogue($event)"
            (wantMark)="markActivity($event)"
            (wantRestore)="removeCatalogue($event)"
            (checkRowChanges)="deleteMany($event)"
          >
          </roma-table>
        </div>
      </div>
      <div *ngIf="!isLoading && isLoadingPaginate" class="col-12">
        <roma-loading></roma-loading>
      </div>
    </div>
  </div>

  <!-- table pagination -->
  <div *ngIf="catalogues?.data?.length" class="pagination">
    <button
      class="arrow prev"
      [attr.disabled]="resultSearch.page === 1 ? true : null"
      (click)="previousPage()"
    >
      <img src="assets/icons/gl_arrow_left.svg" />
    </button>
    <ng-container *ngFor="let page of totalPages">
      <div
        class="page-item"
        *ngIf="page.page < 6"
        [ngClass]="{ 'current-page': page.page == resultSearch.page }"
        (click)="goToPage(page.page)"
      >
        {{ page.page }}
      </div>
      <div
        class="page-item"
        *ngIf="
          resultSearch.countPages > 5 && page.page == resultSearch.countPages
        "
        [ngClass]="{ 'current-page': page.page == resultSearch.page }"
        (click)="goToPage(resultSearch.countPages)"
      >
        ...
        {{ resultSearch.countPages }}
      </div>
    </ng-container>
    <button
      class="arrow next"
      [attr.disabled]="!resultSearch.hasNextPage ? true : null"
      (click)="nextPage()"
    >
      <img src="assets/icons/gl_arrow_right-pagination.svg" />
    </button>
  </div>
  <div *ngIf="deleteMessage" class="row">
    <div
      class="col-12 col-md-11 delete-message-modal"
      style="position: relative"
    >
      <div class="delete-message">
        <strong>
          ({{ entriesForDelete.length }})
          {{ 'general.selected' | translate }}
        </strong>
        <ng-container *ngIf="searchParams?.takeDeleted">
          <!-- restore option   -->
          <button
            class="btn-download btn-restore"
            (clicked)="restore()"
            romaProFeature
            [featureUser]="featureRefUser.Catalogue.update"
          >
            <img src="assets/icons/gl_trash_restore.svg" />
            {{ 'general.restoreButton' | translate }}
          </button>
        </ng-container>
        <ng-container *ngIf="!searchParams?.takeDeleted">
          <roma-shared-download-btn
            *ngIf="catalogues?.data?.length"
            [showDownloadExcel]="true"
            [showDownloadPdf]="false"
            (download)="download($event)"
          ></roma-shared-download-btn>
          <button
            class="btn"
            (clicked)="openDeleteManyDialog()"
            romaProFeature
            [featureUser]="featureRefUser.Catalogue.delete"
          >
            <img src="assets/icons/gl_trash_red.svg" />
            {{ 'general.deleteAlt' | translate }}
          </button>
        </ng-container>
        <div class="btn-close" (click)="unselectItems()"></div>
      </div>
    </div>
  </div>
</ng-container>
