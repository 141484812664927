import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AppName, AppUrl } from './microfrontend.tokens';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'roma-base-microfrontend',
  template: `<p>Microfrontend base</p>`,
  styles: [],
})
export abstract class MicrofrontendComponent<
  T = Record<string, unknown>,
  U = Record<string, unknown>
> implements OnInit, OnDestroy {
  private eventName = `${this.appName}-parent`;

  constructor(
    @Inject(AppName) private appName: string,
    @Inject(AppUrl) private appUrl: string,
    private httpClient: HttpClient,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    if (this.appName && this.appUrl) {
      window.addEventListener(this.appName, this.handleCustomEvent);
      this.loadScript(this.appUrl);
      this.onInit();
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener(this.appName, this.handleCustomEvent);
    const body = <HTMLDivElement>document.body;
    const script = document.querySelector('script#' + this.appName);
    if (script) {
      body.removeChild(script);
    }
  }

  publish = (data: U): void => {
    window.dispatchEvent(new CustomEvent(this.eventName, { detail: data }));
  };

  abstract onInit(): void;

  private loadScript(url: string): void {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.type = 'module';
    script.id = this.appName;
    body.appendChild(script);
  }

  abstract handleCustomEvent(event: CustomEvent<T>): void;
}
