import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ClockHour } from '@tacliatech/types';
import { hours, minutes } from './time-input-objects';

@Component({
  selector: 'roma-add-time',
  templateUrl: './add-time.component.html',
  styleUrls: ['./add-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddTimeComponent implements OnInit {
  @Input()
  mode: 'ADD' | 'EDIT' = 'ADD';

  @Input()
  set data(value: ClockHour.Histories) {
    this._data = value;
    this.patchParams();
  }

  get data() {
    return this._data;
  }

  @Input()
  showButton = true;

  @Input()
  disabled = false;

  @Output()
  changes = new EventEmitter<ClockHour.Histories>();

  histories: ClockHour.Histories = [];

  initialStateForm = () =>
    this.fb.group({
      dates: this.fb.array([]),
    });

  initialStateFormWithOneElement = () =>
    this.fb.group({
      dates: this.fb.array([
        this.fb.group({
          startDate: ['', Validators.required],
          endDate: ['', Validators.required],
          duration: [{}],
          startHour: [{}],
          endHour: [{}],
        }),
      ]),
    });

  form = this.initialStateForm();

  editMode = {
    index: null,
    active: false,
  };

  hour = '01';
  min = '00';

  hours = hours;

  minutes = minutes;

  startTimePicker = null;
  endTimePicker = null;

  private _data: ClockHour.Histories = [];

  constructor(
    private fb: FormBuilder,
    private changeDetectionRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.patchParams();
    //this.addDate();
  }

  hourChange(hour) {
    this.hour = hour;
  }

  minutesChange(min) {
    this.min = min;
  }

  resetTimePlaceholder() {
    this.hour = '01';
    this.min = '00';
  }

  openTimePicker(i, input) {
    const arrayControl = this.form.controls['dates'] as FormArray;
    this.resetTimePlaceholder();
    if (input === 'startDate') {
      this.startTimePicker = i;
      this.endTimePicker = null;
      if (arrayControl.at(i).value.startDate != '') {
        this.convertTimeValues(arrayControl.at(i).value.startDate);
      }
    }

    if (input === 'endDate') {
      this.endTimePicker = i;
      this.startTimePicker = null;
      if (arrayControl.at(i).value.endDate != '') {
        this.convertTimeValues(arrayControl.at(i).value.endDate);
      }
    }
  }

  setTime(i, input) {
    const selectedTime = this.hour + ':' + this.min;
    const langArr = <FormArray>this.form.controls['dates'];

    if (input === 'startDate') {
      langArr.controls[i].patchValue({ startDate: selectedTime });
      this.startTimePicker = null;
      this.onChangeEvent(i);
    } else {
      langArr.controls[i].patchValue({ endDate: selectedTime });
      this.endTimePicker = null;
      this.onChangeEvent(i);
    }
  }

  convertTimeValues(time) {
    const ts = time.toString();
    this.hour = ts.substr(0, 2);
    this.min = ts.substr(3, 2);
  }

  onChangeEvent(index: number) {
    const arrayControl = this.form.controls['dates'] as FormArray;
    if (
      arrayControl.at(index).value.startDate &&
      arrayControl.at(index).value.endDate
    ) {
      if (this.histories.length > index) {
        this.editMode.index = index;
        this.editMode.active = true;
        this.draw();
        this.handleEdit(index);
      } else {
        this.handleAdd(index);
      }

      this.replaceHistoriesEndHour();
      this.draw();
    }
  }

  get dates() {
    return this.form.controls['dates'] as FormArray;
  }

  addDate() {
    const datesForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      duration: [{}],
      startHour: [{}],
      endHour: [{}],
    });
    this.dates.push(datesForm);
    this.draw();
  }

  private handleAdd(i) {
    const history: ClockHour.History = this.getHistoryData(i);

    this.histories = [
      ...this.histories,
      {
        ...history,
      },
    ];
  }

  private handleEdit(i) {
    const editedHistory: ClockHour.History = this.getHistoryData(i);
    this.histories = this.histories.map((history, index) => {
      return this.editMode.index === index
        ? {
            ...history,
            ...editedHistory,
          }
        : history;
    });
    this.resetEditMode();
  }

  private getHistoryData(i: number) {
    const arrayControl = this.form.controls['dates'] as FormArray;

    const startHour: ClockHour.Hour = ClockHour.fromStringHourToObjectHour(
      arrayControl.at(i).value.startDate
    );
    const endHour: ClockHour.Hour = ClockHour.fromStringHourToObjectHour(
      arrayControl.at(i).value.endDate
    );

    const startDate = arrayControl.at(i).value.startDate as string;
    const endDate = arrayControl.at(i).value.endDate as string;

    const duration: ClockHour.Hour = ClockHour.getDifferenceHours(
      endDate,
      startDate
    );

    const history: ClockHour.History = {
      startDate,
      endDate,
      duration,
      startHour,
      endHour,
    };
    return history;
  }

  private resetEditMode() {
    this.editMode.index = null;
    this.editMode.active = false;
    this.draw();
  }

  delete(index: number) {
    this.histories = this.histories.filter((item, i) => index !== i);
    this.dates.clear();
    this.replaceHistoriesEndHour();
    this.resetEditMode();
  }

  private patchParams() {
    this.histories = [...this.data];

    if (this.histories.length) {
      this.form = this.initialStateForm();

      for (let index = 0; this.histories.length > index; index++) {
        const datesForm = this.fb.group({
          startDate: [this.histories[index].startDate, Validators.required],
          endDate: [this.histories[index].endDate, Validators.required],
          duration: [this.histories[index].duration],
          startHour: [this.histories[index].startHour],
          endHour: [this.histories[index].endHour],
        });

        this.dates.push(datesForm);
      }
    } else {
      this.form = this.initialStateFormWithOneElement();
    }
    this.draw();
  }

  private replaceHistoriesEndHour() {
    this.histories = this.histories.map((history) => {
      const endHour: ClockHour.Hour = ClockHour.fromStringHourToObjectHour(
        history.endDate
      );
      return {
        ...history,
        endHour,
      };
    });
    this.changes.emit(this.histories);
    this.draw();
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }
}
