export enum TypeCategory {
  EXPENSES = 'EXPENSES',
  SALES = 'PRODUCTS',
}

export enum TypeExpenseTicket {
  EXPENSE_TICKET = 1,
  PURCHASE_TICKET = 2,
}

export enum StatusExpense {
  PAID = 1,
  PENDING = 2,
  DUE = 3,
}

export default TypeCategory;
