<roma-modal-template
  [size]="'md'"
  [title]="'deals.dealDetail.cancelService' | translate"
  [showDefaultButtons]="false"
  [responsiveFull]="false"
  [disableSuccess]="!form.valid"
  (success)="submit()"
  (cancel)="close()"
>
  <label *ngIf="deal?.cancelled">
    {{ 'deals.dealDetail.cancelled' | translate }}
  </label>
  <div class="row">
    <div class="col-12">
      <div *ngIf="deal?.recurrence" class="container-actions">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios1"
            value="CURRENT"
            [(ngModel)]="actions"
          />
          <label class="form-check-label" for="exampleRadios1">
            {{ 'deals.cancel.form.cancel_this_service' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios2"
            value="NEXT"
            [(ngModel)]="actions"
          />
          <label class="form-check-label" for="exampleRadios2">
            {{
              'deals.cancel.form.Cancel_this_ service_ and_ later' | translate
            }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <br *ngIf="deal?.recurrence" />
  <form [formGroup]="form" *ngIf="!deal?.cancelled">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="form-group" style="margin-bottom: 1px !important">
          <label
            for="cancel_reason"
            class="cancel_reason"
            [innerHtml]="'deals.cancel.form.deal_cancel_reason' | translate"
          ></label>
          <select
            *ngIf="form.get('cancel_reason_select').value !== 'Other'"
            class="form-control"
            id="cancel_reason_select"
            formControlName="cancel_reason_select"
          >
            <option selected disabled value="">
              {{ 'deals.cancel.insert_motive' | translate }}
            </option>
            <option
              *ngFor="let option of selected_options"
              value="{{ option }}"
            >
              {{ option }}
            </option>
            <option value="Other">
              {{ 'deals.cancel.service_other' | translate }}
            </option>
          </select>
          <input
            *ngIf="form.get('cancel_reason_select').value === 'Other'"
            type="text"
            class="form-control"
            id="cancel_reason_input"
            aria-describedby="emailHelp"
            formControlName="cancel_reason_input"
            maxLength="250"
            [placeholder]="
              'deals.cancel.form.cancel_reasonPlaceholder' | translate
            "
          />
          <!--
           <small
            *ngIf="controls?.cancel_reason?.errors"
            id="cancel_reason"
            class="form-text text-muted"
          >
            {{ 'deals.cancel.form.cancel_reasonSmall' | translate }}
          </small>
        -->
          <!-- <ng-container *ngIf="controls?.cancel_reason?.errors">
            <div
              *ngIf="controls?.cancel_reason?.errors?.required"
              class="invalid-feedback"
            >
              {{ 'deals.cancel.form.cancel_reasonError' | translate }}
            </div>
          </ng-container> -->
        </div>
        <button
          class="mat-raised-button mat-secondary action-btn"
          [disabled]="form.invalid || btn_pressed"
          (click)="submit()"
        >
          {{ 'deals.cancel.form.cancel_this_service_confirm' | translate }}
        </button>
      </div>
    </div>
  </form>
</roma-modal-template>
