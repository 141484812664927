<div class="equipment-notes-body">
  <div *ngIf="!notes?.length" class="note-message">
    <p>{{ 'equipments.create.emptyNote' | translate }}</p>
  </div>
  <div *ngFor="let note of notes" class="note-item">
    <p class="note-item-text">{{ note.text }}</p>

    <span>{{ note.createdAt | date: company_date_format }}</span>

    <div class="container-actions">
      <mat-icon>edit</mat-icon>
    </div>
  </div>
</div>

<form class="equipment-notes-footer" [formGroup]="form" (ngSubmit)="addNotes()">
  <textarea
    name=""
    id=""
    cols="30"
    rows="1"
    class="note-input"
    [placeholder]="'equipments.create.notePh' | translate"
    formControlName="text"
    maxlength="255"
  >
  </textarea>

  <button
    type="submit"
    class="taclia"
    mat-mini-fab
    color="accent"
    [disabled]="!form.valid"
  >
    <mat-icon>add</mat-icon>
  </button>
</form>
