/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import {
  Feature,
  FeatureUser,
  IStatus,
  KanbanCard,
  TypeRol,
} from '@tacliatech/types';
import { environment } from '@web-frontend/environments';
import { AuthService } from '@web-frontend/shared/services';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import AmplitudeEvents from 'src/types/amplitude.enum';
import {
  timestampToHour,
  timestampToDayAndMonth,
} from '../../../utils/dateAndTime';
import { getDateStrResumStr } from '../../../utils';

@Component({
  selector: 'roma-kanban-card',
  templateUrl: './kanban-card.component.html',
  styleUrls: ['./kanban-card.component.scss'],
})
export class KanbanCardComponent implements AfterViewInit {
  _overSubMenu = false;

  @HostBinding('class.kanban-card')
  hostClass = true;

  @Input()
  @HostBinding('class.kanban-card--marked')
  marked = false;

  @Input()
  statusData: IStatus[] = [];

  @Input()
  showCardId = false;

  @Input()
  showFinishCard = true;

  @Input()
  showDeleteCard = true;

  @Input()
  showCancelCard = true;

  @Input()
  showRestoreCard = true;

  @ContentChildren('createInput') createInput;

  _card: KanbanCard;
  @Input()
  set card(card: KanbanCard) {
    this._card = card;
  }

  get card(): KanbanCard {
    return this._card;
  }

  get firebaseURL(): string {
    return environment.firebaseConfig.storageUrl + '/uploads%2Fuser%2F';
  }

  @Input()
  placeholderAddCard = 'kanban.placeHolderAddCard';

  @Input()
  type: 'activity' | 'deal' = 'activity';

  @Input()
  deleteCardMessage = 'general.wantDelete';

  @Input()
  restoreCardMessage = 'general.wantDelete';

  @Input()
  finishSelected = false;

  @Input()
  featureUserCreate: Feature.Purchasable | FeatureUser.Purchasable =
    Feature.SystemPermission.DefaultAllow;

  @Input()
  featureUserUpdate: Feature.Purchasable | FeatureUser.Purchasable =
    Feature.SystemPermission.DefaultAllow;

  @Input()
  featureUserDelete: Feature.Purchasable | FeatureUser.Purchasable =
    Feature.SystemPermission.DefaultAllow;

  @Output()
  onCardClick = new EventEmitter<KanbanCard>();

  @Output()
  onCardDblClick = new EventEmitter<KanbanCard>();

  @Output()
  onDeleteCard = new EventEmitter<KanbanCard>();

  @Output()
  onCancelCard = new EventEmitter<KanbanCard>();

  @Output()
  onAddCard = new EventEmitter<KanbanCard>();

  @Output()
  onEditCard = new EventEmitter<KanbanCard>();

  @Output()
  onFinishCard = new EventEmitter<KanbanCard>();

  @Output()
  onChangeStatusCard = new EventEmitter<unknown>();

  @Output()
  onUnfinishCard = new EventEmitter<KanbanCard>();

  @Output()
  onCardSelectChange = new EventEmitter<KanbanCard>();

  @Output()
  onRestoreCard = new EventEmitter<KanbanCard>();

  activeUser$ = this.authService.user$;
  rolRef = TypeRol;

  mouseInMenu = false;
  private isMobile = false;

  company_date_format = this.budgetService.date_format
    ? this.budgetService.date_format
    : 'dd/MM/yyyy';

  constructor(
    private authService: AuthService,
    private budgetService: BudgetService,
    private elementRef: ElementRef,
    private analyticsService: AnalyticsService
  ) {
    this.isMobile = window.screen.width < 768;
  }

  ngAfterViewInit(): void {
    if (this.card.onCreate) {
      this.elementRef?.nativeElement?.querySelector('#createInput').focus();
    }
  }

  get parsedDate(): string | null {
    if (!this.card.date) {
      return null;
    }
    if (this.type === 'deal') {
      const parsed = this.card.date
        ? timestampToDayAndMonth(parseInt(this.card.date), true)
        : null;
      return parsed
        ? parsed.replace(/\b\w/g, (char) => char.toUpperCase())
        : null;
    } else {
      const _parsedDate = getDateStrResumStr(this.card.date);
      return `${_parsedDate.day} ${_parsedDate.monthLocalShort}`;
    }
  }

  get parsedHour(): string | null {
    if (!this.card.hour) {
      return null;
    }
    if (this.type === 'deal') {
      return this.card.hour && !this.card.isAllDay
        ? timestampToHour(parseInt(this.card.hour))
        : null;
    } else {
      return `${this.card.hour}`;
    }
  }

  get showRedCard(): boolean {
    if (!this.card['applyRedStatusInExpireDate']) {
      return false;
    }

    const currentDate = new Date();
    const cardDate = new Date(this.card.date);

    return currentDate > cardDate && !this.card.finished;
  }

  private _lastTimeMousedown: number; //for avoid send event when drag&drop
  private _lastTimeClicked: number; //for avoid spam
  @HostListener('mousedown', ['$event'])
  onMouseDown(): void {
    this._lastTimeMousedown = new Date().getTime();
  }

  @HostListener('dblclick', ['$event'])
  dblclick(): void {
    if (!this.mouseInMenu) {
      this.onCardDblClick.emit(this.card);
    }
  }

  @HostListener('click', ['$event'])
  click(): void {
    if (!this.isValidCardForClick()) {
      return;
    }

    const currentTime = new Date().getTime();
    if (
      this.isDoubleClick(currentTime) &&
      this.isNotTooSoonAfterLastClick(currentTime)
    ) {
      this._lastTimeClicked = currentTime;
      this.onCardClick.emit(this.card);
    }
  }

  _overCard = false;
  _overMenu = false;
  _menuClick = false;

  @HostListener('blur', ['$event'])
  blur(): void {
    this._overCard = false;
    this._overMenu = false;
    this._menuClick = false;
    console.log('blur');
  }

  @HostListener('mouseenter', ['$event'])
  mouseover(): void {
    this._overCard = true;
  }

  @HostListener('mouseleave', ['$event'])
  mouseout(): void {
    this._overCard = false;
  }

  overMenu(): void {
    this._overMenu = true;
  }
  outMenu(): void {
    this._overMenu = false;
  }

  clickMenu(): void {
    this._menuClick = true;
  }

  get menuVisible(): boolean {
    return (
      this.isMobile ||
      this._overCard ||
      this._overMenu ||
      this._menuClick ||
      this._overSubMenu
    );
  }

  eventDeleteCard(): void {
    this.onDeleteCard.emit(this.card);
  }

  eventCancelCard(): void {
    this.onCancelCard.emit(this.card);
  }

  emitEventAmplitudeDelete(): void {
    if (this.type === 'deal') {
      this.analyticsService.trackEvent({
        eventName: AmplitudeEvents.deal_board_delete_start,
        sources: ['amplitude'],
      });
    }
  }
  eventAddCard(): void {
    if (this.card.title) {
      this.card.onCreate = false;
    }
    this.onAddCard.emit(this.card);
  }

  eventEditCard(): void {
    this.onEditCard.emit(this.card);
  }

  eventFinishCard(): void {
    this.onFinishCard.emit(this.card);
  }

  eventChangeStatusCard($event, stat): void {
    this.onChangeStatusCard.emit({ item: this.card, stat });
  }

  eventUnfinishCard(): void {
    this.card.finished = false;
    this.onUnfinishCard.emit(this.card);
  }

  eventCardSelectChange(): void {
    this.card.selected = !this.card.selected;
    this.onCardSelectChange.emit(this.card);
  }

  eventRestoreCard(): void {
    this.card.deleted = true;
    this.onRestoreCard.emit(this.card);
  }

  // Checks if the card has an ID, is not being created, and the mouse is not in the menu.
  private isValidCardForClick(): boolean {
    return this.card._id && !this.card.onCreate && !this.mouseInMenu;
  }

  // Checks if the current click is considered a double click based on the last mousedown event.
  private isDoubleClick(currentTime: number): boolean {
    return currentTime - this._lastTimeMousedown < 1000;
  }

  // Checks if the click is not too soon after the last click event.
  private isNotTooSoonAfterLastClick(currentTime: number): boolean {
    return !this._lastTimeClicked || currentTime - this._lastTimeClicked > 2000;
  }
}
