<!-- <ng-container *ngIf="this.status === StatusAccounteEnumRef.NOT_STARTED">
  <div class="status-container">
    <div class="content-body">
      <div class="content-image">
        <img src="/assets/icons/wrp_ilu_new-window.svg" />
      </div>
      <div class="content-title">
        {{ 'payments.status.notstarted-title' | translate }}
      </div>
      <div class="content-subtitle">
        {{ 'payments.status.notstarted-body' | translate }}
      </div>
      <div class="content-footer-window">
        <span class="footer-text-1">{{
          'payments.status.notstarted-link' | translate
        }}</span>
        <span class="footer-text-2">
          <a
            href="https://academy.taclia.com/kb-tickets/new"
            class="text-link"
            target="_blank"
            >{{ 'payments.status.notstarted-link1' | translate }}</a
          ></span
        >
      </div>
      <br /><br />

      <div>
        <mat-progress-spinner
          *ngIf="!linkOnboarding"
          mode="indeterminate"
          class="loading-spinner"
          [diameter]="55"
        ></mat-progress-spinner>
      </div>

      <ng-container *ngIf="linkOnboarding">
        <p>
          {{ 'payments.auxLinkText' | translate }}
          <a
            (click)="findLoginLink()"
            [href]="linkOnboarding"
            class="text-link"
            >{{ 'payments.auxLinkAnchor' | translate }}</a
          >
        </p>
      </ng-container>
    </div>
  </div>
</ng-container> -->

<!-- PENDING -->

<ng-container *ngIf="this.status === StatusAccounteEnumRef.PENDING">
  <div class="status-container">
    <div
      class="content-body"
      [ngClass]="{
        'mt-landscape-mobile':
          isLandscapeMode && (resize$ | async).payload.mobile,
        'mt-portrait-mobile':
          !isLandscapeMode && (resize$ | async).payload.mobile
      }"
    >
      <div class="content-image">
        <img src="/assets/icons/wrp_ilu.svg" />
      </div>
      <div class="content-title">
        {{ 'payments.status.pending-title' | translate }}
      </div>
      <div class="content-subtitle">
        {{ 'payments.status.pending-body' | translate }}
      </div>
      <div class="content-footer-pending">
        <span class="footer-text-1">{{
          'payments.status.pending-link' | translate
        }}</span>
        <span class="footer-text-2">
          <a
            href="https://www.taclia.com/contacto"
            class="text-link"
            target="_blank"
			(click)="sendHelpEvent()"
            >{{ 'payments.status.pending-link1' | translate }}</a
          ></span
        >
      </div>
    </div>
  </div>
</ng-container>

<!-- NOT COMPLETED = 5-->
<ng-container
  *ngIf="
    this.status === StatusAccounteEnumRef.NOT_STARTED ||
    this.status === StatusAccounteEnumRef.NOT_COMPLETED ||
    this.status === StatusAccounteEnumRef.APPROVED_WITH_WARNING
  "
>
  <div class="status-container">
    <div
      class="content-body"
      [ngClass]="{
        'mt-landscape-mobile':
          isLandscapeMode && (resize$ | async).payload.mobile,
        'mt-portrait-mobile':
          !isLandscapeMode && (resize$ | async).payload.mobile
      }"
    >
      <div class="content-image">
        <img src="/assets/icons/wrp_process.svg" />
      </div>
      <div class="content-title">
        {{ 'payments.status.notcompleted-title' | translate }}
      </div>
      <div class="content-subtitle">
        {{ 'payments.status.notcompleted-body' | translate }}
      </div>

      <div>
        <mat-progress-spinner
          *ngIf="!linkOnboarding || this.isLoadingGetNewLink"
          mode="indeterminate"
          class="loading-spinner"
          [diameter]="55"
        ></mat-progress-spinner>
      </div>
      <a
        (click)="findLoginLink()"
        [ngClass]="{ 'is-disable': (isAdmin | async) === false }"
        [disabled]="(isAdmin | async) === false"
        target="_blank"
        *ngIf="!this.isLoadingGetNewLink && linkOnboarding?.length"
        class="roma-button btn-activate active-link"
        [href]="linkOnboarding"
      >
        <span class="mx-2">{{
          'payments.status.notcompleted-activate' | translate
        }}</span></a
      >

      <!--      <button
        [disabled]="this.isLoadingGetNewLink"
        [ngClass]="{ 'disable-button': this.isLoadingGetNewLink }"
        class="roma-button btn-activate"
        (click)="openLinkFer()"
      >
        <span class="mx-2">{{
          'payments.status.notcompleted-activate' | translate
        }}</span>

      </button> -->
      <div class="content-footer-pending">
        <span class="footer-text-1">{{
          'payments.status.notcompleted-link' | translate
        }}</span>
        <span class="footer-text-2">
          <a
            href="https://academy.taclia.com/kb-tickets/new"
            class="text-link"
            target="_blank"
			(click)="sendHelpEvent()"
            >{{ 'payments.status.notcompleted-link1' | translate }}</a
          ></span
        >
      </div>
    </div>

    <!--     <p>
      {{ 'payments.auxLinkText' | translate }} FER
      <a
        (click)="findLoginLink()"
        [href]="linkOnboarding"
        class="text-link"
        target="_blank"
        >{{ 'payments.auxLinkAnchor' | translate }}</a
      >
    </p> -->
  </div>
</ng-container>

<!-- SUSPENDED -->
<ng-container *ngIf="this.status === StatusAccounteEnumRef.SUSPENDED">
  <div class="status-container">
    <div
      class="content-body"
      [ngClass]="{
        'mt-landscape-mobile':
          isLandscapeMode && (resize$ | async).payload.mobile,
        'mt-portrait-mobile':
          !isLandscapeMode && (resize$ | async).payload.mobile
      }"
    >
      <div class="content-image">
        <img src="/assets/icons/wrp_suspended.svg" />
      </div>
      <div class="content-title">
        {{ 'payments.status.suspended-title' | translate }}
      </div>
      <div class="content-subtitle">
        {{ 'payments.status.suspended-body' | translate }}
      </div>
      <!-- <button
        [disabled]="this.isLoadingGetNewLink"
        [ngClass]="{ 'disable-button': this.isLoadingGetNewLink }"
        class="roma-button btn-activate"
        (click)="openLink()"
      >
        <span class="mx-2">{{
          'payments.status.suspended-link' | translate
        }}</span>
      </button> -->

      <div>
        <mat-progress-spinner
          *ngIf="!linkOnboarding || this.isLoadingGetNewLink"
          mode="indeterminate"
          class="loading-spinner"
          [diameter]="55"
        ></mat-progress-spinner>
      </div>
      <a
        (click)="findLoginLink()"
        target="_blank"
        *ngIf="!this.isLoadingGetNewLink && linkOnboarding?.length"
        class="roma-button btn-activate active-link"
        [href]="linkOnboarding"
      >
        <span class="mx-2">{{
          'payments.status.suspended-link' | translate | translate
        }}</span></a
      >
    </div>
  </div>
</ng-container>

<!-- REJECTED -->
<ng-container *ngIf="this.status === StatusAccounteEnumRef.REJECTED">
  <div class="status-container">
    <div
      class="content-body"
      [ngClass]="{
        'mt-landscape-mobile':
          isLandscapeMode && (resize$ | async).payload.mobile,
        'mt-portrait-mobile':
          !isLandscapeMode && (resize$ | async).payload.mobile
      }"
    >
      <div class="content-image">
        <img src="/assets/icons/wrp_rejected.svg" />
      </div>
      <div class="content-title">
        {{ 'payments.status.rejected-title' | translate }}
      </div>
      <div class="content-subtitle">
        {{ 'payments.status.rejected-body' | translate }}
      </div>
      <!-- <button
        [disabled]="this.isLoadingGetNewLink"
        [ngClass]="{ 'disable-button': isLoadingGetNewLink }"
        class="roma-button btn-activate mb-40"
        (click)="openLink()"
      >
        <span class="mx-2">{{
          'payments.status.suspended-link' | translate
        }}</span>
      </button> -->
      <div class="content-footer center">
        <span class="footer-text-2">
          <a
            href="https://academy.taclia.com/kb-tickets/new"
            class="text-link"
            target="_blank"
            >{{ 'payments.status.rejected-link' | translate }}</a
          ></span
        >
      </div>
    </div>
  </div>
</ng-container>
