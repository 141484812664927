<div
  *ngIf="displayable()"
  class="snackbar"
  [ngStyle]="{ 'margin-top': inPaymentMethods ? '2rem' : null }"
>
  <p>
    {{
      'payments.snackbar.body.' + stripeObject?.onboarding?.status | translate
    }}
    <a [disabled]="isLoadingFunctionality" (click)="goToPayments()">{{
      'payments.snackbar.cta.' + stripeObject?.onboarding?.status | translate
    }}</a>
  </p>

  <mat-icon (click)="close()" class="close">close</mat-icon>
</div>
