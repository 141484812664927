import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SkipSelf,
} from '@angular/core';
import { BudgetCategoryFields } from '../../budget.enum';
import { IBdgCategFieldToDisplay } from '../../budget.interface';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { CreateBudgetComponent } from '../../create-budget.component';
import { UserService } from '../../../../services';

@Component({
  selector: 'roma-budget-category-header',
  templateUrl: './budget-category-header.component.html',
  styleUrls: ['./budget-category-header.component.scss'],
})
export class BudgetCategoryHeaderComponent implements OnInit, AfterViewInit {
  changeDetected = false;

  @Input()
  fieldsToDisplay: IBdgCategFieldToDisplay[] = [
    {
      name: BudgetCategoryFields.QUANTITY,
      nameRef: 'asd',
      checked: false,
      showFieldAsActivable: true,
    },
  ];

  oldFieldsObject: IBdgCategFieldToDisplay[];
  public get CategoryFields(): typeof BudgetCategoryFields {
    return BudgetCategoryFields;
  }

  conceptPlaceholder = 'budgets.settings.create.form.conceptPlaceholder';
  conceptDescriptionPlaceholder =
    'budgets.settings.create.form.conceptAndDescription';
  descriptionPlaceholder =
    'budgets.settings.create.form.descriptionPlaceholder';
  quantityPlaceholder = 'budgets.settings.create.form.quantityPlaceholder';
  unitPlaceholder = 'budgets.settings.create.form.unit';
  discountPlaceholder = 'budgets.settings.create.form.discountPlaceholder';
  discountPlaceholderMobile =
    'budgets.settings.create.form.discountPlaceholderMobile';
  pricePlaceholder = 'budgets.settings.create.form.pricePlaceholder';
  taxPlaceholder = 'budgets.settings.create.form.taxPlaceholder';
  totalPlaceholder = 'budgets.settings.create.form.totalPlaceholder';

  @Input()
  haveChanges: boolean;

  @Output()
  goToPrompt = new EventEmitter<{
    url: string;
    type: string;
    changes: boolean;
  }>();

  constructor(
    private readonly router: Router,
    @SkipSelf() private dialogRef: MatDialogRef<CreateBudgetComponent>,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly userService: UserService
  ) {}

  ngAfterViewInit(): void {
    this.oldFieldsObject = structuredClone(this.fieldsToDisplay);
  }

  ngOnInit(): void {
    this.oldFieldsObject = structuredClone(this.fieldsToDisplay);
  }

  ngDoCheck(): void {
    if (!this.fieldsToDisplay || !this.oldFieldsObject) return;
    for (let i = 0; i < this.fieldsToDisplay.length; i++) {
      const newField = this.fieldsToDisplay[i];
      const oldField = this.oldFieldsObject[i];
      if (oldField.checked !== newField.checked) {
        oldField.checked = newField.checked;
        this.changeDetected = true;
      }
    }

    if (this.changeDetected) {
      this.changeDetector.detectChanges();
    }
    this.changeDetected = false;
  }

  isMX(): boolean {
    return this.userService.validateCountryMX();
  }

  getUnitClass(): string {
    return !this.isMX() ? 'unit' : 'unit-mx';
  }

  resolveShowField(fieldName: BudgetCategoryFields): boolean {
    const fieldObj = this.fieldsToDisplay.find((f) => f.name === fieldName);
    return fieldObj.checked;
  }

  goToSettings(): void {
    this.goToPrompt.emit({
      changes: this.haveChanges,
      url: 'admin/settings/general/account',
      type: 'general-settings',
    });
  }
}
