import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
  selector: 'roma-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent implements OnInit {
  @Output()
  onToastClose = new EventEmitter();

  @Output()
  onClickLink = new EventEmitter();

  @Input()
  message: string;

  @Input()
  type: 'success' | 'error' | 'warning' = 'success';

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private _snackBarRef: MatSnackBarRef<SnackbarComponent>
  ) {}

  ngOnInit() {
    this.type = this.data.type;
    this.message = this.data.text;
  }

  close() {
    this._snackBarRef.dismiss();
    this.onToastClose.emit();
  }
}
