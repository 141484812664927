import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { App, IInternalVendor } from '@tacliatech/types';

import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InternalVendorService {
  private sub$ = new Subscription();

  private vendorsCache = new BehaviorSubject<
    App.ResourceCached<IInternalVendor[]>
  >(['INIT', []]);

  vendors$ = this.vendorsCache.asObservable();

  constructor(private http: HttpClient) {}

  public get vendors() {
    return this.vendorsCache.value[1];
  }

  init() {
    this.vendorsCache.next(['LOADING', []]);
    this.sub$.add(
      this.findAll().subscribe((res) => {
        this.vendorsCache.next(['LOADED', res]);
      })
    );
  }

  findAll() {
    return this.http.get<IInternalVendor[]>(`:API_URL/internal-vendors`);
  }

  findWithDeletedByCompany(id) {
    return this.http.get<IInternalVendor[]>(
      `:API_URL/internal-vendors/company/all/${id}`
    );
  }

  findAllByCompany(id) {
    return this.http.get<IInternalVendor[]>(
      `:API_URL/internal-vendors/company/${id}`
    );
  }

  findInternalVendor(id: string) {
    return this.http.get<IInternalVendor>(`:API_URL/internal-vendors/${id}`);
  }

  create(data: IInternalVendor) {
    return this.http.post(`:API_URL/internal-vendors`, { ...data });
  }

  updateOne(id: string, data: any) {
    return this.http.put(`:API_URL/internal-vendors/${id}`, { ...data });
  }

  removeOne(id: string) {
    return this.http.delete(`:API_URL/internal-vendors/${id}`);
  }

  linkInternalVendor(data: { idVendor: string; owner: string }) {
    return this.http
      .post(`:API_URL/internal-vendors/link-internal-vendors`, {
        ...data,
      })
      .pipe(
        finalize(() => {
          this.init();
        })
      );
  }

  hasPublicProfile(data: { email: string }) {
    return this.http.post<boolean>(
      `:API_URL/internal-vendors/has-public-profile`,
      {
        ...data,
      }
    );
  }

  //
  findAllInternalVendorsByCompany_SELECT() {
    return this.http.get<IInternalVendor[]>(
      `:API_URL/internal-vendors/company/select`
    );
  }

  restoreMany(data) {
    return this.http.post(`:API_URL/internal-vendors/restore-many`, data);
  }

  deleteMany(data) {
    return this.http.post(`:API_URL/internal-vendors/delete-many`, data);
  }
}
