<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div
  [ngStyle]="openMobileFilters && { visibility: 'visible' }"
  class="mobile-table-filters"
>
  <div class="subfilter-title">
    <img
      (click)="openMobileFilters = false"
      class="arrow"
      src="/assets/icons/gl_close.svg"
      alt="link"
    />
    {{ 'general.filterOpen' | translate }}
    <span
      *ngIf="activeFilters || activeCustomFilters"
      class="clean-filters"
      (click)="cleanFilters()"
    >
      {{ 'general.cleanFilters' | translate }}
    </span>
  </div>
  <div class="link-list mobile-table-filters-body" style="margin-bottom: 60px">
    <div
      *ngIf="showStatus"
      class="list-item"
      (click)="openSubFilters('status')"
    >
      {{ 'table.colum.status' | translate }}
      <span class="filter-length" *ngIf="selectedStatus.length > 0">
        (<span *ngIf="selectedStatus.length < 10">0</span
        >{{ selectedStatus.length }})
      </span>
      <img class="arrow" src="/assets/icons/gl_arrow-right.svg" alt="link" />
    </div>
    <div
      *ngIf="showManagers"
      class="list-item"
      (click)="openSubFilters('reporter')"
    >
      {{ 'table.colum.reporter' | translate }}
      <span class="filter-length" *ngIf="selectedReporter.length > 0">
        (<span *ngIf="selectedReporter.length < 10">0</span
        >{{ selectedReporter.length }})
      </span>
      <img class="arrow" src="/assets/icons/gl_arrow-right.svg" alt="link" />
    </div>
    <div
      *ngIf="showAssigneds"
      class="list-item"
      (click)="openSubFilters('assigned')"
    >
      {{ assignedsPlaceholder | translate }}
      <span class="filter-length" *ngIf="selectedAssigneds.length > 0">
        (<span *ngIf="selectedAssigneds.length < 10">0</span
        >{{ selectedAssigneds.length }})
      </span>
      <img class="arrow" src="/assets/icons/gl_arrow-right.svg" alt="link" />
    </div>
    <div
      *ngIf="showCustomers"
      class="list-item"
      (click)="openSubFilters('final')"
    >
      {{ 'table.colum.final' | translate }}
      <span class="filter-length" *ngIf="selectedFinals.length > 0">
        (<span *ngIf="selectedFinals.length < 10">0</span
        >{{ selectedFinals.length }})
      </span>
      <img class="arrow" src="/assets/icons/gl_arrow-right.svg" alt="link" />
    </div>
    <div
      *ngIf="showProjects"
      class="list-item"
      (click)="openSubFilters('project')"
    >
      {{ 'general.project' | translate }}
      <span class="filter-length" *ngIf="selectedProjects.length > 0">
        (<span *ngIf="selectedProjects.length < 10">0</span
        >{{ selectedProjects.length }})
      </span>
      <img class="arrow" src="/assets/icons/gl_arrow-right.svg" alt="link" />
    </div>
    <div *ngIf="showDeals" class="list-item" (click)="openSubFilters('deals')">
      {{ dealsPlaceholder | translate }}
      <span class="filter-length" *ngIf="selectedDeals.length > 0">
        (<span *ngIf="selectedDeals.length < 10">0</span
        >{{ selectedDeals.length }})
      </span>
      <img class="arrow" src="/assets/icons/gl_arrow-right.svg" alt="link" />
    </div>
    <div
      *ngIf="showAssets"
      class="list-item"
      (click)="openSubFilters('address')"
    >
      {{ 'table.colum.address' | translate }}
      <span class="filter-length" *ngIf="selectedAsset.length > 0">
        (<span *ngIf="selectedAsset.length < 10">0</span
        >{{ selectedAsset.length }})
      </span>
      <img class="arrow" src="/assets/icons/gl_arrow-right.svg" alt="link" />
    </div>
    <div
      *ngIf="showFinalTypes"
      class="list-item"
      (click)="openSubFilters('finalType')"
    >
      {{ statusPlaceholder | translate }}
      <span class="filter-length" *ngIf="selectedFinalType.length > 0">
        (<span *ngIf="selectedFinalType.length < 10">0</span
        >{{ selectedFinalType.length }})
      </span>
      <img class="arrow" src="/assets/icons/gl_arrow-right.svg" alt="link" />
    </div>
    <div
      *ngIf="showTypes"
      class="list-item"
      (click)="openSubFilters('dealType')"
    >
      {{ typePlaceholder | translate }}
      <span class="filter-length" *ngIf="selectedType.length > 0">
        (<span *ngIf="selectedType.length < 10">0</span
        >{{ selectedType.length }})
      </span>
      <img class="arrow" src="/assets/icons/gl_arrow-right.svg" alt="link" />
    </div>
    <div
      *ngIf="elements.length > 0"
      class="list-item"
      (click)="openSubFilters('chanels')"
    >
      {{ elementsPlaceholder | translate }}
      <span class="filter-length" *ngIf="selectedChanel.length > 0">
        (<span *ngIf="selectedChanel.length < 10">0</span
        >{{ selectedChanel.length }})
      </span>
      <img class="arrow" src="/assets/icons/gl_arrow-right.svg" alt="link" />
    </div>
    <div
      *ngIf="showAuthors"
      class="list-item"
      (click)="openSubFilters('authors')"
    >
      {{ authorsPlaceholder | translate }}
      <span class="filter-length" *ngIf="selectedAuthors.length > 0">
        (<span *ngIf="selectedAuthors.length < 10">0</span
        >{{ selectedAuthors.length }})
      </span>
      <img class="arrow" src="/assets/icons/gl_arrow-right.svg" alt="link" />
    </div>
    <div
      *ngIf="showCategorys"
      class="list-item"
      (click)="openSubFilters('categories')"
    >
      {{ categoriesPlaceholder | translate }}
      <span class="filter-length" *ngIf="selectedCategories.length > 0">
        (<span *ngIf="selectedCategories.length < 10">0</span
        >{{ selectedCategories.length }})
      </span>
      <img class="arrow" src="/assets/icons/gl_arrow-right.svg" alt="link" />
    </div>
    <div
      *ngIf="showExpenseCategory"
      class="list-item"
      (click)="openSubFilters('expenseCategories')"
    >
      {{ statusPlaceholder | translate }}
      <span class="filter-length" *ngIf="selectedStatus.length > 0">
        (<span *ngIf="selectedStatus.length < 10">0</span
        >{{ selectedStatus.length }})
      </span>
      <img class="arrow" src="/assets/icons/gl_arrow-right.svg" alt="link" />
    </div>
    <div *ngIf="showRol" class="list-item" (click)="openSubFilters('rol')">
      {{ 'general.role' | translate }}
      <span class="filter-length" *ngIf="selectedRol.length > 0">
        (<span *ngIf="selectedRol.length < 10">0</span>{{ selectedRol.length }})
      </span>
      <img class="arrow" src="/assets/icons/gl_arrow-right.svg" alt="link" />
    </div>
    <div
      *ngIf="showStatusBudget"
      class="list-item"
      (click)="openSubFilters('statusBudgets')"
    >
      {{ statusBudgetsPlaceholder | translate }}
      <span class="filter-length" *ngIf="selectedstatusBudgets.length > 0">
        (<span *ngIf="selectedstatusBudgets.length < 10">0</span
        >{{ selectedstatusBudgets.length }})
      </span>
      <img class="arrow" src="/assets/icons/gl_arrow-right.svg" alt="link" />
    </div>
    <div
      *ngIf="showDatePicker"
      class="list-item date-picker-item"
      style="float: left"
    >
      <div *ngIf="showDatePicker" class="filter-item">
        <div class="filter-input">
          <mat-form-field
            *ngIf="showDatePicker"
            appearance="fill"
            style="width: 100%"
            class="date-range"
          >
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                [value]="selectedStartDate"
                [placeholder]="startPlaceholder | translate"
                (dateChange)="selectedStartDate = $event.value"
                (focus)="picker.open()"
              />
              <input
                matEndDate
                [value]="selectedEndDate"
                [placeholder]="endPlaceholder | translate"
                (dateChange)="selectedEndDate = $event.value"
                (focus)="picker.open()"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
              style="height: 35px"
            >
              <mat-icon
                matDatepickerToggleIcon
                style="height: 35px; position: relative"
              >
                <img class="calendar-icon" src="assets/icons/gl_calendar.svg" />
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-date-range-picker touchUi #picker>
              <mat-date-range-picker-actions>
                <button
                  mat-button
                  color="primary"
                  matDateRangePickerApply
                  (click)="activeFilters = true"
                >
                  {{ 'general.apply' | translate }}
                </button>
              </mat-date-range-picker-actions>
            </mat-date-range-picker>
          </mat-form-field>
          <div [ngClass]="{ 'h-36': showAddUserButton }"></div>
        </div>
      </div>
    </div>
    <!-- ⭐️ pro feature -->
    <div class="mobile-custom-properties">
      <!-- mobile custom properties -->
      <ng-container *ngFor="let property of customProperties">
        <div
          *ngIf="property.type === dynamicPropertyTypeRef.String"
          romaProSelect
          [feature]="customParamsFeatureByType"
          class="filter-item wrapper-pro-feature"
        >
          <input
            #propertyInput
            class="form-control lbl-place-holder form-control-alt"
            type="text"
            [placeholder]="('filter.custom' | translate) + ' ' + property.name"
            (keyup)="changeElement(property.name, $event.target.value, false)"
          />
        </div>

        <div
          *ngIf="property.type === dynamicPropertyTypeRef.Number"
          romaProSelect
          [feature]="customParamsFeatureByType"
          class="filter-item wrapper-pro-feature-hideButton"
        >
          <input
            #propertyInput
            class="form-control form-control-alt"
            type="number"
            [placeholder]="('filter.custom' | translate) + ' ' + property.name"
            (keyup)="changeInputNumber(property, $event.target.value)"
          />
          <mat-menu #appMenu="matMenu" class="numeric-menu">
            <mat-checkbox
              (change)="changeCustomNumberOrder(property, '=')"
              [checked]="property.order === null || property.order === '='"
            >
              <button
                mat-menu-item
                (click)="changeCustomNumberOrder(property, '=')"
              >
                {{ 'filter.equal' | translate }}
              </button>
            </mat-checkbox>
            <mat-checkbox
              (change)="changeCustomNumberOrder(property, '<')"
              [checked]="property.order === '<'"
            >
              <button
                mat-menu-item
                (click)="changeCustomNumberOrder(property, '<')"
                aria-checked="true"
              >
                {{ 'filter.minor' | translate }}
              </button>
            </mat-checkbox>
            <mat-checkbox
              (change)="changeCustomNumberOrder(property, '>')"
              [checked]="property.order === '>'"
            >
              <button
                mat-menu-item
                (click)="changeCustomNumberOrder(property, '>')"
                aria-checked="true"
              >
                {{ 'filter.major' | translate }}
              </button>
            </mat-checkbox>
            <mat-checkbox
              (change)="changeCustomNumberOrder(property, '<=')"
              [checked]="property.order === '<='"
            >
              <button
                mat-menu-item
                (click)="changeCustomNumberOrder(property, '<=')"
                aria-checked="true"
              >
                {{ 'filter.minorOrEqual' | translate }}
              </button>
            </mat-checkbox>
            <mat-checkbox
              (change)="changeCustomNumberOrder(property, '>=')"
              [checked]="property.order === '>='"
            >
              <button
                mat-menu-item
                (click)="changeCustomNumberOrder(property, '>=')"
                aria-checked="true"
              >
                {{ 'filter.majorOrEqual' | translate }}
              </button>
            </mat-checkbox>
          </mat-menu>

          <button mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>

        <div
          *ngIf="property.type === dynamicPropertyTypeRef.Date"
          romaProSelect
          [feature]="customParamsFeatureByType"
          class="filter-item wrapper-pro-feature"
        >
          <div class="filter-input" style="position: relative">
            <mat-form-field appearance="fill" style="width: 100%">
              <input
                #singleDate
                style="padding-left: 12px"
                matInput
                [matDatepicker]="singlePicker"
                (dateChange)="
                  changeElement(property.name, $event.target.value || '', false)
                "
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="singlePicker"
                style="height: 35px"
              >
                <mat-icon
                  matDatepickerToggleIcon
                  style="height: 35px; position: relative"
                >
                  <img
                    class="calendar-icon"
                    src="assets/icons/gl_calendar.svg"
                  />
                </mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #singlePicker></mat-datepicker>
              <div
                *ngIf="singleDate.value === ''"
                class="fake-placeholder"
                (click)="singlePicker.open()"
              >
                {{ ('filter.custom' | translate) + ' ' + property.name }}
              </div>
            </mat-form-field>
          </div>
        </div>

        <div
          *ngIf="property.type === dynamicPropertyTypeRef.Boolean"
          romaProSelect
          [feature]="customParamsFeatureByType"
          class="filter-item wrapper-pro-feature"
        >
          <div class="filter-input">
            <ng-select
              [placeholder]="
                ('filter.custom' | translate) + ' ' + property.name
              "
              [items]="['general.yes' | translate, 'general.no' | translate]"
              [notFoundText]="'hiring.form.placeholder_notfound' | translate"
              [clearable]="true"
              [closeOnSelect]="false"
              (change)="changeElement(property.name, $event, false)"
            >
            </ng-select>
          </div>
        </div>

        <div
          *ngIf="property.type === dynamicPropertyTypeRef.Select"
          romaProSelect
          [feature]="customParamsFeatureByType"
          class="filter-item wrapper-pro-feature"
        >
          <div class="filter-input">
            <ng-select
              [items]="property.options"
              [multiple]="true"
              [placeholder]="
                ('filter.custom' | translate) + ' ' + property.name
              "
              (change)="changeElement(property.name, $event, false)"
              [notFoundText]="'hiring.form.placeholder_notfound' | translate"
            >
            </ng-select>
          </div>
        </div>
      </ng-container>
    </div>
    <!--/ pro feature -->
    <!-- <p class="check-label">{{ 'filter.viewServices' | translate }}</p> -->
    <div class="checkbox-row-container">
      <mat-checkbox
        *ngIf="showToday"
        color="primary"
        class="pt-0 inline-check"
        [checked]="takeToday"
        (change)="onChangeTodayMobile($event)"
      >
        <small>{{ viewToday }}</small>
      </mat-checkbox>

      <mat-checkbox
        *ngIf="showCancelled"
        color="primary"
        class="pt-0 inline-check"
        [checked]="takeCancelled"
        (change)="onChangeCancelledMobile($event)"
      >
        <small>{{ viewCanceled }}</small>
      </mat-checkbox>

      <mat-checkbox
        *ngIf="showNotified"
        color="primary"
        class="pt-0 inline-check"
        [checked]="takeNotified"
        (change)="onChangeNotifiedMobile($event)"
      >
        <small>{{ 'filter.see_performed' | translate }}</small>
      </mat-checkbox>

      <mat-checkbox
        *ngIf="showDeleted"
        color="primary"
        class="pt-0 inline-check"
        [checked]="takeDeleted"
        (change)="onChangeDeletedMobile($event)"
      >
        <small>{{ viewDelete }}</small>
      </mat-checkbox>

      <div
        *ngIf="showRestore"
        class="container-restore"
        [checked]="restoreStatus"
        (click)="onChangeRestore()"
      >
        <mat-icon> arrow_upward </mat-icon>
        <small>{{ deleteMessage | translate }}</small>
      </div>

      <mat-checkbox
        *ngIf="showPublicProfile"
        color="primary"
        class="pt-0 inline-check"
        [checked]="activeProfile"
        (change)="onChangePublicProfile($event)"
      >
        <small>{{ 'filter.profileOnline' | translate }}</small>
      </mat-checkbox>
    </div>
    <!-- sub filters -->
    <div *ngIf="openSubfilter" class="mobile-subfilters-wrapper">
      <!-- status filters -->
      <div *ngIf="selectedFilter === 'status'">
        <div class="subfilter-title" (click)="openSubfilter = false">
          <img
            class="arrow"
            src="/assets/icons/gl_arrow-full-left.svg"
            alt="link"
          />
          {{ 'table.colum.status' | translate }}
        </div>
        <ng-container *ngIf="!statusLoading">
          <div class="link-search">
            <input
              type="search"
              placeholder="{{ 'general.search' | translate }}"
              [(ngModel)]="statusSearchItem"
              (search)="clearInputFilters('status')"
              (keyup)="searchInputFilters($event, 'status')"
            />
          </div>
          <div class="link-list">
            <div *ngFor="let item of status; let i = index" class="list-item">
              <mat-checkbox
                (change)="
                  onChangeFilterMobile('status', $event, {
                    key: item.key,
                    value: item.value
                  })
                "
                [checked]="searchCheckFilters(item, 'status') ? true : false"
              >
                {{ item.key }}
              </mat-checkbox>
            </div>
          </div>
        </ng-container>
        <mat-progress-spinner
          *ngIf="statusLoading"
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="60"
          style="margin: 10rem auto"
        ></mat-progress-spinner>
      </div>
      <!-- reporter filters -->
      <div *ngIf="selectedFilter === 'reporter'">
        <div class="subfilter-title" (click)="openSubfilter = false">
          <img
            class="arrow"
            src="/assets/icons/gl_arrow-full-left.svg"
            alt="link"
          />
          {{ 'table.colum.reporter' | translate }}
        </div>
        <ng-container *ngIf="!authorsLoading">
          <div class="link-search">
            <input
              type="search"
              placeholder="{{ 'general.search' | translate }}"
              [(ngModel)]="reporterSearchItem"
              (search)="clearInputFilters('reporter')"
              (keyup)="searchInputFilters($event, 'reporter')"
              class=""
            />
          </div>
          <div class="link-list">
            <div
              *ngFor="let item of authors"
              class="list-item mobile-filter-item"
            >
              <mat-checkbox
                class="mobile-filter-checkbox"
                (change)="
                  onChangeFilterMobile('reporter', $event, {
                    key: item.key,
                    value: item.value
                  })
                "
                [checked]="searchCheckFilters(item, 'reporter') ? true : false"
              >
                {{ item.key }}
              </mat-checkbox>
            </div>
          </div>
        </ng-container>
        <mat-progress-spinner
          *ngIf="authorsLoading"
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="60"
          style="margin: 10rem auto"
        ></mat-progress-spinner>
      </div>
      <!-- assigneds filters -->
      <div *ngIf="selectedFilter === 'assigned'">
        <div class="subfilter-title" (click)="openSubfilter = false">
          <img
            class="arrow"
            src="/assets/icons/gl_arrow-full-left.svg"
            alt="link"
          />
          {{ assignedsPlaceholder | translate }}
        </div>
        <ng-container *ngIf="!authorsLoading">
          <div class="link-search">
            <input
              type="search"
              placeholder="{{ 'general.search' | translate }}"
              [(ngModel)]="assignedSearchItem"
              (search)="clearInputFilters('assigned')"
              (keyup)="searchInputFilters($event, 'reporter')"
            />
          </div>
          <div class="link-list">
            <div
              *ngFor="let item of authors"
              class="list-item mobile-filter-item"
            >
              <mat-checkbox
                class="mobile-filter-checkbox"
                (change)="
                  onChangeFilterMobile('assigned', $event, {
                    key: item.key,
                    value: item.value
                  })
                "
                [checked]="searchCheckFilters(item, 'assigned') ? true : false"
              >
                {{ item.key }}
              </mat-checkbox>
            </div>
          </div>
        </ng-container>
        <mat-progress-spinner
          *ngIf="authorsLoading"
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="60"
          style="margin: 10rem auto"
        ></mat-progress-spinner>
      </div>
      <!-- projects filters -->
      <div *ngIf="selectedFilter === 'project'">
        <div class="subfilter-title" (click)="openSubfilter = false">
          <img
            class="arrow"
            src="/assets/icons/gl_arrow-full-left.svg"
            alt="link"
          />
          {{ 'general.project' | translate }}
        </div>
        <ng-container *ngIf="!projectsLoading">
          <div class="link-search">
            <input
              type="search"
              placeholder="{{ 'general.search' | translate }}"
              [(ngModel)]="projectSearchItem"
              (search)="clearInputFilters('project')"
              (keyup)="searchInputFilters($event, 'project')"
            />
          </div>
          <div class="link-list">
            <div
              *ngFor="let item of items"
              class="list-item mobile-filter-item"
            >
              <mat-checkbox
                class="mobile-filter-checkbox"
                (change)="
                  onChangeFilterMobile('project', $event, {
                    key: item.key,
                    value: item.value
                  })
                "
                [checked]="searchCheckFilters(item, 'project') ? true : false"
              >
                {{ item.key }}
              </mat-checkbox>
            </div>
          </div>
        </ng-container>
        <mat-progress-spinner
          *ngIf="projectsLoading"
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="60"
          style="margin: 10rem auto"
        ></mat-progress-spinner>
      </div>
      <!-- finals filters -->
      <div *ngIf="selectedFilter === 'final'">
        <div class="subfilter-title" (click)="openSubfilter = false">
          <img
            class="arrow"
            src="/assets/icons/gl_arrow-full-left.svg"
            alt="link"
          />
          {{ 'general.final' | translate }}
        </div>
        <ng-container *ngIf="!finalsLoading">
          <div class="link-search">
            <input
              type="search"
              placeholder="{{ 'general.search' | translate }}"
              [(ngModel)]="finalSearchItem"
              (search)="clearInputFilters('final')"
              (keyup)="searchInputFilters($event, 'final')"
            />
          </div>
          <div class="link-list">
            <div
              *ngFor="let item of customers"
              class="list-item mobile-filter-item"
            >
              <mat-checkbox
                class="mobile-filter-checkbox"
                (change)="
                  onChangeFilterMobile('final', $event, {
                    key: item.key,
                    value: item.value
                  })
                "
                [checked]="searchCheckFilters(item, 'final') ? true : false"
              >
                {{ item.key }}
              </mat-checkbox>
            </div>
          </div>
        </ng-container>
        <mat-progress-spinner
          *ngIf="finalsLoading"
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="60"
          style="margin: 10rem auto"
        ></mat-progress-spinner>
      </div>
      <!-- deals filters -->
      <div *ngIf="selectedFilter === 'deals'">
        <div class="subfilter-title" (click)="openSubfilter = false">
          <img
            class="arrow"
            src="/assets/icons/gl_arrow-full-left.svg"
            alt="link"
          />
          {{ 'services.services' | translate }}
        </div>
        <ng-container *ngIf="!dealsLoading">
          <div class="link-list">
            <div
              *ngFor="let item of deals"
              class="list-item mobile-filter-item"
            >
              <mat-checkbox
                class="mobile-filter-checkbox"
                (change)="
                  onChangeFilterMobile('deals', $event, {
                    key: item.key,
                    value: item.value
                  })
                "
                [checked]="searchCheckFilters(item, 'deals') ? true : false"
              >
                {{ item.key }}
              </mat-checkbox>
            </div>
          </div>
        </ng-container>
        <mat-progress-spinner
          *ngIf="dealsLoading"
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="60"
          style="margin: 10rem auto"
        ></mat-progress-spinner>
      </div>
      <!-- deals types filters -->
      <div *ngIf="selectedFilter === 'dealType'">
        <div class="subfilter-title" (click)="openSubfilter = false">
          <img
            class="arrow"
            src="/assets/icons/gl_arrow-full-left.svg"
            alt="link"
          />
          {{ 'table.colum.type' | translate }}
        </div>
        <div class="link-list">
          <div *ngFor="let item of types" class="list-item mobile-filter-item">
            <mat-checkbox
              class="mobile-filter-checkbox"
              (change)="
                onChangeFilterMobile('dealType', $event, {
                  key: item.key,
                  value: item.value
                })
              "
              [checked]="searchCheckFilters(item, 'dealType') ? true : false"
            >
              {{ item.key }}
            </mat-checkbox>
          </div>
        </div>
      </div>
      <!-- finals types filters -->
      <div *ngIf="selectedFilter === 'finalType'">
        <div class="subfilter-title" (click)="openSubfilter = false">
          <img
            class="arrow"
            src="/assets/icons/gl_arrow-full-left.svg"
            alt="link"
          />
          {{ statusPlaceholder | translate }}
        </div>
        <div *ngIf="!statusLoading" class="link-list">
          <div *ngFor="let item of status" class="list-item mobile-filter-item">
            <mat-checkbox
              class="mobile-filter-checkbox"
              (change)="
                onChangeFilterMobile('finalType', $event, {
                  key: item.key,
                  value: item.value
                })
              "
              [checked]="searchCheckFilters(item, 'finalType') ? true : false"
            >
              {{ item.key }}
            </mat-checkbox>
          </div>
        </div>
        <mat-progress-spinner
          *ngIf="statusLoading"
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="60"
          style="margin: 10rem auto"
        ></mat-progress-spinner>
      </div>
      <!-- authors filters -->
      <div *ngIf="selectedFilter === 'authors'">
        <div class="subfilter-title" (click)="openSubfilter = false">
          <img
            class="arrow"
            src="/assets/icons/gl_arrow-full-left.svg"
            alt="link"
          />
          {{ authorsPlaceholder | translate }}
        </div>
        <div class="link-list" *ngIf="!authorsLoading">
          <div
            *ngFor="let item of authors"
            class="list-item mobile-filter-item"
          >
            <mat-checkbox
              class="mobile-filter-checkbox"
              (change)="
                onChangeFilterMobile('authors', $event, {
                  key: item.key,
                  value: item.value
                })
              "
              [checked]="searchCheckFilters(item, 'authors') ? true : false"
            >
              {{ item.key }}
            </mat-checkbox>
          </div>
        </div>
        <mat-progress-spinner
          *ngIf="authorsLoading"
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="60"
          style="margin: 10rem auto"
        ></mat-progress-spinner>
      </div>
      <!-- budgetStatus filters -->
      <div *ngIf="selectedFilter === 'statusBudgets'">
        <div class="subfilter-title" (click)="openSubfilter = false">
          <img
            class="arrow"
            src="/assets/icons/gl_arrow-full-left.svg"
            alt="link"
          />
          {{ statusBudgetsPlaceholder | translate }}
        </div>
        <div class="link-list" *ngIf="statusBudgetslist.length">
          <div
            *ngFor="let item of statusBudgetslist"
            class="list-item mobile-filter-item"
          >
            <mat-checkbox
              class="mobile-filter-checkbox"
              (change)="
                onChangeFilterMobile('statusBudgets', $event, {
                  key: item.key,
                  value: item.value
                })
              "
              [checked]="
                searchCheckFilters(item, 'statusBudgets') ? true : false
              "
            >
              {{ item.name | translate }}
            </mat-checkbox>
          </div>
        </div>
        <mat-progress-spinner
          *ngIf="!statusBudgetslist.length"
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="60"
          style="margin: 10rem auto"
        ></mat-progress-spinner>
      </div>
      <!-- showExpenseCategory filters -->
      <div *ngIf="selectedFilter === 'expenseCategories'">
        <div class="subfilter-title" (click)="openSubfilter = false">
          <img
            class="arrow"
            src="/assets/icons/gl_arrow-full-left.svg"
            alt="link"
          />
          {{ statusPlaceholder | translate }}
        </div>
        <div class="link-list" *ngIf="!statusLoading">
          <div *ngFor="let item of status" class="list-item mobile-filter-item">
            <mat-checkbox
              class="mobile-filter-checkbox"
              (change)="
                onChangeFilterMobile('status', $event, {
                  key: item.key,
                  value: item.value
                })
              "
              [checked]="searchCheckFilters(item, 'status') ? true : false"
            >
              {{ item.key | translate }}
            </mat-checkbox>
          </div>
        </div>
        <mat-progress-spinner
          *ngIf="statusLoading"
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="60"
          style="margin: 10rem auto"
        ></mat-progress-spinner>
      </div>
      <!-- categories filters -->
      <div *ngIf="selectedFilter === 'categories'">
        <div class="subfilter-title" (click)="openSubfilter = false">
          <img
            class="arrow"
            src="/assets/icons/gl_arrow-full-left.svg"
            alt="link"
          />
          {{ categoriesPlaceholder | translate }}
        </div>
        <div class="link-list" *ngIf="categorys.length">
          <div
            *ngFor="let item of categorys"
            class="list-item mobile-filter-item"
          >
            <mat-checkbox
              class="mobile-filter-checkbox"
              (change)="
                onChangeFilterMobile('categories', $event, {
                  key: item.key,
                  value: item.value
                })
              "
              [checked]="searchCheckFilters(item, 'categories') ? true : false"
            >
              {{ item.key }}
            </mat-checkbox>
          </div>
        </div>
        <mat-progress-spinner
          *ngIf="!categorys.length"
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="60"
          style="margin: 10rem auto"
        ></mat-progress-spinner>
      </div>
      <!-- chanels filters -->
      <div *ngIf="selectedFilter === 'chanels'">
        <div class="subfilter-title" (click)="openSubfilter = false">
          <img
            class="arrow"
            src="/assets/icons/gl_arrow-full-left.svg"
            alt="link"
          />
          {{ elementsPlaceholder | translate }}
        </div>
        <div class="link-list" *ngIf="elements.length">
          <div
            *ngFor="let item of elements"
            class="list-item mobile-filter-item"
          >
            <mat-checkbox
              class="mobile-filter-checkbox"
              (change)="
                onChangeFilterMobile('chanels', $event, {
                  key: item.key,
                  value: item.value
                })
              "
              [checked]="searchCheckFilters(item, 'chanels') ? true : false"
            >
              {{ item.key }}
            </mat-checkbox>
          </div>
        </div>
        <mat-progress-spinner
          *ngIf="!elements.length"
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="60"
          style="margin: 10rem auto"
        ></mat-progress-spinner>
      </div>
      <!-- address filters -->
      <div *ngIf="selectedFilter === 'address'">
        <div class="subfilter-title" (click)="openSubfilter = false">
          <img
            class="arrow"
            src="/assets/icons/gl_arrow-full-left.svg"
            alt="link"
          />
          {{ 'table.colum.address' | translate }}
        </div>
        <ng-container *ngIf="!assetsLoading">
          <div class="link-search">
            <input
              type="search"
              placeholder="{{ 'general.search' | translate }}"
              [(ngModel)]="addressSearchItem"
              (search)="clearInputFilters('address')"
              (keyup)="searchInputFilters($event, 'address')"
            />
          </div>
          <div class="link-list">
            <div
              *ngFor="let item of assets"
              class="list-item mobile-filter-item"
            >
              <mat-checkbox
                class="mobile-filter-checkbox"
                (change)="
                  onChangeFilterMobile('address', $event, {
                    key: item.key,
                    value: item.value
                  })
                "
                [checked]="searchCheckFilters(item, 'address') ? true : false"
              >
                {{ item.key }}
              </mat-checkbox>
            </div>
          </div>
        </ng-container>
        <mat-progress-spinner
          *ngIf="assetsLoading"
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="60"
          style="margin: 10rem auto"
        ></mat-progress-spinner>
      </div>
      <!-- rol filters -->
      <div *ngIf="selectedFilter === 'rol'">
        <div class="subfilter-title" (click)="openSubfilter = false">
          <img
            class="arrow"
            src="/assets/icons/gl_arrow-full-left.svg"
            alt="link"
          />
          {{ 'general.role' | translate }}
        </div>
        <div class="link-list" *ngIf="roles.length">
          <div *ngFor="let item of roles" class="list-item mobile-filter-item">
            <mat-checkbox
              class="mobile-filter-checkbox"
              (change)="
                onChangeFilterMobile('rol', $event, {
                  key: item.key,
                  value: item.value
                })
              "
              [checked]="searchCheckFilters(item, 'rol') ? true : false"
            >
              {{ item.key }}
            </mat-checkbox>
          </div>
        </div>
        <mat-progress-spinner
          *ngIf="!roles.length"
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="60"
          style="margin: 10rem auto"
        ></mat-progress-spinner>
      </div>
    </div>
  </div>
  <button
    *ngIf="activeFilters || activeCustomFilters"
    class="apply-filters mat-primary text-white"
    (click)="applyFilters()"
  >
    {{ 'filter.apply' | translate }}
  </button>
</div>

<div class="mobile-actions-row">
  <button
    class="mat-raised-button mat-secondary"
    (click)="openMobileFilters = true"
  >
    <img class="arrow" src="/assets/icons/gl_filter.svg" alt="filters" />
    {{ 'general.filters' | translate }}
  </button>
  <button
    *ngIf="showBtnAddCreate"
    class="mat-raised-button mat-primary text-white justify-content-center"
    romaProFeature
    [featureUser]="userFeatureCreate"
    (clicked)="onAddClick($event)"
  >
    {{ 'general.create' | translate }}
    <mat-icon style="vertical-align: middle">add</mat-icon>
  </button>
</div>

<!-- desktop filters -->

<div class="filter-item" *ngIf="showInputSearch" class="mobile-hidden">
  <div class="search-input">
    <div class="filter-input">
      <img class="left" src="assets/icons/gl_search.svg" />
      <input
        #inputFilter
        type="text"
        class="form-control"
        [placeholder]="inputPlaceholder"
      />
    </div>
  </div>
</div>

<div class="filters-selector-container mobile-hidden">
  <button
    class="filters-toggle"
    [ngClass]="{ 'filters-toggle-active': showFiltersMenu }"
    (click)="showFiltersMenuEvent(!showFiltersMenu)"
  >
    <img
      *ngIf="!showFiltersMenu"
      class="arrow"
      src="/assets/icons/gl_filter.svg"
      alt="filters"
    />
    <img
      *ngIf="showFiltersMenu"
      class="arrow"
      src="/assets/icons/gl_filter_active.svg"
      alt="filters"
    />
    {{ 'general.filters' | translate }}
  </button>
  <div
    id="FiltersMenu"
    class="menu-filters-list"
    [ngClass]="{ 'to-left': FiltersMenuToLeft, 'to-right': !FiltersMenuToLeft }"
    [ngStyle]="
      showFiltersMenu ? { visibility: 'visible' } : { visibility: 'hidden' }
    "
  >
    <div class="subfilter-title">
      {{ 'general.filters' | translate }}
      <!--<span
        *ngIf="!checkIfEmptyFilters()"
        class="clean-filters"
        (click)="cleanFilters()"
      >
        {{ 'general.cleanFilters' | translate }}
      </span>-->
    </div>
    <div class="filters-list-container">
      <!-- status -->
      <rm-select
        [selectId]="'showStatus'"
        *ngIf="showStatus"
        class="select-input users"
        [placeholderText]="'table.colum.status' | translate"
        [items]="status"
        [isLoading]="statusLoading"
        mode="MULTIPLE"
        listWidth="287px"
        inputHeight="36px"
        [activeItems]="selectedStatus"
        (click)="onClickSelect('select', 'status')"
        (changeItems)="onChangeStatus($event)"
      >
      </rm-select>
      <!-- authors -->
      <rm-select
        [selectId]="'showAuthors'"
        *ngIf="showAuthors"
        class="select-input users"
        [placeholderText]="authorsPlaceholder | translate"
        [items]="authors"
        [isLoading]="authorsLoading"
        mode="MULTIPLE"
        listWidth="287px"
        inputHeight="36px"
        [activeItems]="selectedAuthors"
        (click)="onClickSelect('select', 'authors')"
        (changeItems)="onChangeAuthor($event)"
      >
      </rm-select>
      <!-- managers -->
      <rm-select
        [selectId]="'showManagers'"
        *ngIf="showManagers"
        class="select-input users"
        [placeholderText]="authorsPlaceholder | translate"
        [items]="authors"
        [isLoading]="authorsLoading"
        mode="MULTIPLE"
        listWidth="287px"
        inputHeight="36px"
        [activeItems]="selectedReporter"
        (click)="onClickSelect('select', 'authors')"
        (changeItems)="onChangeManager($event)"
      >
      </rm-select>
      <!-- assigneds -->
      <rm-select
        [selectId]="'showAssigneds'"
        *ngIf="showAssigneds"
        class="select-input users"
        [placeholderText]="assignedsPlaceholder | translate"
        [items]="authors"
        [isLoading]="authorsLoading"
        mode="MULTIPLE"
        listWidth="287px"
        inputHeight="36px"
        [activeItems]="selectedAssigneds"
        (click)="onClickSelect('select', 'authors')"
        (changeItems)="onChangeAssigned($event)"
      >
      </rm-select>
      <!-- deals -->
      <rm-select
        [selectId]="'showDeals'"
        *ngIf="showDeals"
        class="select-input users"
        [placeholderText]="dealsPlaceholder | translate"
        [items]="deals"
        [isLoading]="dealsLoading"
        mode="MULTIPLE"
        listWidth="287px"
        inputHeight="36px"
        [activeItems]="selectedDeals"
        (click)="onClickSelect('select', 'deals')"
        (changeItems)="onChangeDeals($event)"
      >
      </rm-select>
      <!-- showFinalTypes -->
      <rm-select
        [selectId]="'showFinalTypes'"
        *ngIf="showFinalTypes"
        class="select-input users"
        [placeholderText]="statusPlaceholder | translate"
        [items]="status"
        [isLoading]="statusLoading"
        mode="MULTIPLE"
        listWidth="287px"
        inputHeight="36px"
        [activeItems]="selectedStatus"
        (click)="onClickSelect('select', 'finalTypes')"
        (changeItems)="onChangeStatus($event)"
      >
      </rm-select>
      <!-- showExpenseCategory -->
      <rm-select
        [selectId]="'showExpenseCategory'"
        *ngIf="showExpenseCategory"
        class="select-input users"
        [placeholderText]="statusPlaceholder | translate"
        [items]="status"
        [isLoading]="statusLoading"
        mode="MULTIPLE"
        listWidth="287px"
        inputHeight="36px"
        [activeItems]="selectedStatus"
        (click)="onClickSelect('select', 'expenseCategory')"
        (changeItems)="onChangeStatus($event)"
      >
      </rm-select>
      <!-- showProjects -->
      <rm-select
        [selectId]="'showProjects'"
        *ngIf="showProjects"
        class="select-input users"
        [placeholderText]="itemsPlaceholder | translate"
        [items]="items"
        [isLoading]="projectsLoading"
        mode="MULTIPLE"
        listWidth="287px"
        inputHeight="36px"
        [activeItems]="selectedProjects"
        (click)="onClickSelect('select', 'projects')"
        (changeItems)="onChangeItems($event)"
      >
      </rm-select>
      <!-- showAssets -->
      <rm-select
        [selectId]="'showAssets'"
        *ngIf="showAssets"
        class="select-input users"
        [placeholderText]="assetPlaceholder | translate"
        [items]="assets"
        [isLoading]="assetsLoading"
        mode="MULTIPLE"
        listWidth="287px"
        inputHeight="36px"
        [activeItems]="selectedAsset"
        (click)="onClickSelect('select', 'assets')"
        (changeItems)="onChangeAsset($event)"
      >
      </rm-select>
      <!-- showCustomers -->
      <rm-select
        [selectId]="'showCustomers'"
        *ngIf="showCustomers"
        class="select-input users"
        [placeholderText]="customersPlaceholder | translate"
        [items]="customers"
        [isLoading]="finalsLoading"
        mode="MULTIPLE"
        listWidth="287px"
        inputHeight="36px"
        [activeItems]="selectedCustomers"
        (click)="onClickSelect('select', 'finals')"
        (changeItems)="onChangeCustomer($event)"
      >
      </rm-select>
      <!-- chanels -->
      <rm-select
        [selectId]="'showElements'"
        *ngIf="elements.length > 0"
        class="select-input users"
        [placeholderText]="elementsPlaceholder | translate"
        [items]="_elementsRm"
        mode="MULTIPLE"
        listWidth="287px"
        inputHeight="36px"
        [activeItems]="selectedElements"
        (changeItems)="onChangeElements($event)"
      >
      </rm-select>
      <!-- showCategorys -->
      <rm-select
        [selectId]="'showCategorys'"
        *ngIf="showCategorys"
        class="select-input users"
        [placeholderText]="categoriesPlaceholder | translate"
        [items]="categorys"
        mode="MULTIPLE"
        listWidth="287px"
        inputHeight="36px"
        [activeItems]="selectedCategories"
        (changeItems)="onChangeCategory($event)"
        (click)="rmSelectRequest = true"
      >
      </rm-select>
      <!-- showTypes -->
      <rm-select
        [selectId]="'showTypes'"
        *ngIf="showTypes"
        class="select-input users"
        [placeholderText]="typePlaceholder | translate"
        [items]="types"
        mode="MULTIPLE"
        listWidth="287px"
        inputHeight="36px"
        [activeItems]="selectedType"
        (changeItems)="onChangeType($event)"
        (click)="rmSelectRequest = true"
      >
      </rm-select>
      <!-- showRol -->
      <rm-select
        [selectId]="'showRol'"
        *ngIf="showRol"
        class="select-input users"
        [placeholderText]="'general.role' | translate"
        [items]="roles"
        mode="SINGLE"
        listWidth="287px"
        inputHeight="36px"
        [activeItems]="selectedRol"
        (changeItems)="onChangeRol($event)"
        (click)="rmSelectRequest = true"
      >
      </rm-select>
      <!-- showStatusBudget -->
      <rm-select
        [selectId]="'showStatusBudget'"
        *ngIf="showStatusBudget"
        class="select-input users"
        [placeholderText]="statusBudgetsPlaceholder | translate"
        [items]="statusBudgetslist"
        mode="SINGLE"
        listWidth="287px"
        inputHeight="36px"
        [activeItems]="selectedstatusBudgets"
        (changeItems)="onChangeStatusbudget($event)"
        (click)="rmSelectRequest = true"
      >
      </rm-select>
      <!-- showCharged -->
      <div *ngIf="showCharged" class="filter-item">
        <div class="filter-input">
          <mat-checkbox
            *ngIf="showCharged"
            color="primary"
            class="pt-0"
            (change)="onChangeCharged($event)"
          >
            <small>{{ 'filter.charged' | translate }}</small>
          </mat-checkbox>
        </div>
      </div>
      <!-- showDatePicker -->
      <div *ngIf="showDatePicker" class="filter-item">
        <div class="filter-input" style="position: relative">
          <mat-form-field
            *ngIf="showDatePicker"
            appearance="fill"
            style="width: 100%"
          >
            <mat-date-range-input [rangePicker]="picker" [formGroup]="range">
              <input
                #startDateInput
                matStartDate
                formControlName="start"
                [placeholder]="startPlaceholder | translate"
                (dateChange)="onStartDateChange($event, startDateInput.value)"
              />
              <input
                #endDateInput
                matEndDate
                formControlName="end"
                [placeholder]="endPlaceholder | translate"
                (dateChange)="onEndDateChange($event, endDateInput.value)"
              />
            </mat-date-range-input>

            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
              style="height: 35px"
            >
              <mat-icon
                matDatepickerToggleIcon
                style="height: 35px; position: relative"
              >
                <img class="calendar-icon" src="assets/icons/gl_calendar.svg" />
              </mat-icon>
            </mat-datepicker-toggle>

            <mat-datepicker-toggle
              matSuffix
              style="height: 35px"
              (click)="cleanRange()"
              *ngIf="activeRangeDate"
            >
              <mat-icon
                matDatepickerToggleIcon
                style="height: 36px; position: relative"
              >
                <img
                  class="calendar-icon"
                  src="assets/icons/gl_close-input.svg"
                />
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <div
              *ngIf="!activeRangeDate"
              class="fake-placeholder"
              (click)="picker.open()"
            >
              {{ 'filter.dateRange' | translate }}
            </div>
            <div
              class="fake-inputs-values"
              *ngIf="activeRangeDate"
              (click)="picker.open()"
            >
              {{ startDateInput.value + ' - ' + endDateInput.value }}
            </div>
          </mat-form-field>
          <div [ngClass]="{ 'h-36': showAddUserButton }"></div>
        </div>
      </div>
      <!-- showSingleDatePicker -->
      <div *ngIf="showSingleDatePicker" class="filter-item">
        <div class="filter-input">
          <div class="form-group">
            <input
              #inputDateElement
              type="date"
              class="form-control"
              (blur)="changeSingleDatePicker(inputDateElement)"
            />
          </div>
        </div>
      </div>
      <!-- web custom properties -->
      <!-- pro feature -->
      <ng-container *ngFor="let property of customProperties">
        <div
          *ngIf="property.type === dynamicPropertyTypeRef.String"
          romaProSelect
          [feature]="customParamsFeatureByType"
          class="filter-item wrapper-pro-feature"
        >
          <input
            #propertyInput
            class="form-control lbl-place-holder form-control-alt"
            type="text"
            [placeholder]="('filter.custom' | translate) + ' ' + property.name"
            (keyup)="changeElement(property.name, $event.target.value)"
          />
          <img
            *ngIf="propertyInput.value !== ''"
            (click)="propertyInput.value = ''; changeElement(property.name, '')"
            class="clean-icon"
            src="assets/icons/gl_close-input.svg"
          />
        </div>

        <div
          *ngIf="property.type === dynamicPropertyTypeRef.Number"
          romaProSelect
          [feature]="customParamsFeatureByType"
          class="filter-item wrapper-pro-feature-hideButton"
        >
          <input
            #propertyInput
            class="form-control form-control-alt"
            type="number"
            [placeholder]="('filter.custom' | translate) + ' ' + property.name"
            (keyup)="changeInputNumber(property, $event.target.value)"
          />
          <img
            *ngIf="propertyInput.value !== ''"
            (click)="propertyInput.value = ''; changeInputNumber(property, '')"
            class="clean-icon"
            style="right: 50px"
            src="assets/icons/gl_close-input.svg"
          />
          <mat-menu #appMenu="matMenu" class="numeric-menu">
            <mat-checkbox
              (change)="changeCustomNumberOrder(property, '=')"
              [checked]="property.order === null || property.order === '='"
            >
              <button
                mat-menu-item
                (click)="changeCustomNumberOrder(property, '=')"
              >
                {{ 'filter.equal' | translate }}
              </button>
            </mat-checkbox>
            <mat-checkbox
              (change)="changeCustomNumberOrder(property, '<')"
              [checked]="property.order === '<'"
            >
              <button
                mat-menu-item
                (click)="changeCustomNumberOrder(property, '<')"
                aria-checked="true"
              >
                {{ 'filter.minor' | translate }}
              </button>
            </mat-checkbox>
            <mat-checkbox
              (change)="changeCustomNumberOrder(property, '>')"
              [checked]="property.order === '>'"
            >
              <button
                mat-menu-item
                (click)="changeCustomNumberOrder(property, '>')"
                aria-checked="true"
              >
                {{ 'filter.major' | translate }}
              </button>
            </mat-checkbox>
            <mat-checkbox
              (change)="changeCustomNumberOrder(property, '<=')"
              [checked]="property.order === '<='"
            >
              <button
                mat-menu-item
                (click)="changeCustomNumberOrder(property, '<=')"
                aria-checked="true"
              >
                {{ 'filter.minorOrEqual' | translate }}
              </button>
            </mat-checkbox>
            <mat-checkbox
              (change)="changeCustomNumberOrder(property, '>=')"
              [checked]="property.order === '>='"
            >
              <button
                mat-menu-item
                (click)="changeCustomNumberOrder(property, '>=')"
                aria-checked="true"
              >
                {{ 'filter.majorOrEqual' | translate }}
              </button>
            </mat-checkbox>
          </mat-menu>
          <button mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>

        <div
          *ngIf="property.type === dynamicPropertyTypeRef.Date"
          romaProSelect
          [feature]="customParamsFeatureByType"
          class="filter-item wrapper-pro-feature"
        >
          <div class="filter-input" style="position: relative">
            <mat-form-field appearance="fill" style="width: 100%">
              <input
                #singleDate
                style="padding-left: 12px"
                matInput
                [matDatepicker]="singlePicker"
                (dateChange)="
                  changeElement(property.name, $event.target.value || '')
                "
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="singlePicker"
                style="height: 35px"
              >
                <mat-icon
                  matDatepickerToggleIcon
                  style="height: 35px; position: relative"
                >
                  <img
                    class="calendar-icon"
                    src="assets/icons/gl_calendar.svg"
                  />
                </mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #singlePicker></mat-datepicker>
              <div
                *ngIf="singleDate.value === ''"
                class="fake-placeholder"
                (click)="singlePicker.open()"
              >
                {{ ('filter.custom' | translate) + ' ' + property.name }}
              </div>
            </mat-form-field>
            <div
              class="external-clean-icon"
              (click)="singleDate.value = ''; changeElement(property.name, '')"
              *ngIf="singleDate.value"
            >
              <mat-icon
                matDatepickerToggleIcon
                style="height: 36px; position: relative"
              >
                <img
                  class="calendar-icon"
                  src="assets/icons/gl_close-input.svg"
                />
              </mat-icon>
            </div>
          </div>
        </div>

        <div
          *ngIf="property.type === dynamicPropertyTypeRef.Boolean"
          romaProSelect
          [feature]="customParamsFeatureByType"
          class="filter-item wrapper-pro-feature"
        >
          <div class="filter-input">
            <ng-select
              [placeholder]="
                ('filter.custom' | translate) + ' ' + property.name
              "
              [items]="['general.yes' | translate, 'general.no' | translate]"
              [notFoundText]="'hiring.form.placeholder_notfound' | translate"
              [clearable]="true"
              [closeOnSelect]="false"
              (change)="changeElement(property.name, $event)"
            >
            </ng-select>
          </div>
        </div>

        <div
          *ngIf="property.type === dynamicPropertyTypeRef.Select"
          romaProSelect
          [feature]="customParamsFeatureByType"
          class="filter-item wrapper-pro-feature"
        >
          <div class="filter-input">
            <ng-select
              [items]="property.options"
              [multiple]="true"
              [placeholder]="
                ('filter.custom' | translate) + ' ' + property.name
              "
              (change)="changeElement(property.name, $event)"
              [notFoundText]="'hiring.form.placeholder_notfound' | translate"
            >
            </ng-select>
          </div>
        </div>
      </ng-container>
      <!--/ pro feature -->
      <div
        *ngIf="
          (showCancelled ||
            showNotified ||
            showDeleted ||
            showToday ||
            showRestore ||
            showPublicProfile) &&
          type != 'activities'
        "
        class="checkbox-container"
      >
        <mat-checkbox
          *ngIf="showCancelled"
          color="primary"
          class="pt-0"
          [checked]="activeCancelled"
          (change)="onChangeCancelled($event)"
        >
          <small>{{ viewCanceled }}</small>
        </mat-checkbox>

        <mat-checkbox
          *ngIf="showNotified"
          color="primary"
          class="pt-0"
          [checked]="activeNotified"
          (change)="onChangeNotified($event)"
        >
          <small>{{ 'filter.see_performed' | translate }}</small>
        </mat-checkbox>

        <mat-checkbox
          *ngIf="showDeleted"
          color="primary"
          class="pt-0"
          [checked]="activeDeleted"
          (change)="onChangeDeleted($event)"
        >
          <small>{{ viewDelete }}</small>
        </mat-checkbox>

        <mat-checkbox
          *ngIf="showToday"
          color="primary"
          class="pt-0"
          [checked]="activeToday"
          (change)="onChangeToday($event)"
        >
          <small>{{ viewToday }}</small>
        </mat-checkbox>

        <mat-checkbox
          *ngIf="showFinished"
          color="primary"
          class="pt-0"
          [checked]="activeFinished"
          (change)="onChangeFinished($event)"
        >
          <small>{{ viewFinished }}</small>
        </mat-checkbox>

        <div
          *ngIf="showRestore"
          class="container-restore"
          [checked]="restoreStatus"
          (click)="onChangeRestore()"
        >
          <mat-icon> arrow_upward </mat-icon>
          <small>{{ deleteMessage | translate }}</small>
        </div>

        <div *ngIf="showPublicProfile">
          <mat-checkbox
            color="primary"
            class="pt-0"
            [checked]="activeProfile"
            (change)="onChangePublicProfile($event)"
          >
            <small>{{ 'filter.profileOnline' | translate }}</small>
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="showFiltersMenu"
    class="fake-close-filters-menu-overlay"
    (click)="showFiltersMenuEvent(false)"
  ></div>
</div>
<div
  class="filters-selector-container mobile-hidden"
  *ngIf="type === 'activities'"
>
  <button
    class="filters-toggle"
    (click)="showFiltersActivityMenuEvent(!showFiltersActivityMenu)"
  >
    {{ 'general.activityFilter' | translate }}
  </button>
  <div
    class="menu-filters-list adjust"
    [ngStyle]="
      showFiltersActivityMenu
        ? { visibility: 'visible' }
        : { visibility: 'hidden' }
    "
  >
    <div
      *ngIf="
        (showCancelled ||
          showNotified ||
          showDeleted ||
          showToday ||
          showRestore ||
          showPublicProfile) &&
        type == 'activities'
      "
      class="checkbox-container"
    >
      <mat-checkbox
        *ngIf="showCancelled"
        color="primary"
        class="pt-0"
        [checked]="activeCancelled"
        (change)="onChangeCancelled($event)"
      >
        <small>{{ viewCanceled }}</small>
      </mat-checkbox>

      <mat-checkbox
        *ngIf="showNotified"
        color="primary"
        class="pt-0"
        [checked]="activeNotified"
        (change)="onChangeNotified($event)"
      >
        <small>{{ 'filter.see_performed' | translate }}</small>
      </mat-checkbox>

      <mat-checkbox
        *ngIf="showDeleted"
        color="primary"
        class="pt-0"
        [checked]="activeDeleted"
        (change)="onChangeDeleted($event)"
      >
        <small>{{ viewDelete }}</small>
      </mat-checkbox>

      <mat-checkbox
        *ngIf="showToday"
        color="primary"
        class="pt-0"
        [checked]="activeToday"
        (change)="onChangeToday($event)"
      >
        <small>{{ viewToday }}</small>
      </mat-checkbox>
      <div
        *ngIf="showRestore"
        class="container-restore"
        [checked]="restoreStatus"
        (click)="onChangeRestore()"
      >
        <mat-icon> arrow_upward </mat-icon>
        <small>{{ deleteMessage | translate }}</small>
      </div>

      <div *ngIf="showPublicProfile">
        <mat-checkbox
          color="primary"
          class="pt-0"
          [checked]="activeProfile"
          (change)="onChangePublicProfile($event)"
        >
          <small>{{ 'filter.profileOnline' | translate }}</small>
        </mat-checkbox>
      </div>
    </div>
  </div>
  <div
    *ngIf="showFiltersActivityMenu"
    class="fake-close-filters-menu-overlay"
    (click)="showFiltersActivityMenuEvent(false)"
  ></div>
</div>
<div class="filters-selector-container mobile-hidden">
  <div class="subfilter-title">
    <span
      *ngIf="!checkIfEmptyFilters()"
      class="clean-filters"
      (click)="cleanFilters()"
    >
      {{ 'general.cleanFilters' | translate }}
    </span>
  </div>
</div>
