import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import {
  RmStatusSelectComponent,
  SearchFilterPipe,
} from './rm-status-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RomaToolTipModule } from '@web-frontend/shared/directives/roma-tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { RmBadgeModule } from '../rm-badge';

@NgModule({
  declarations: [RmStatusSelectComponent, SearchFilterPipe],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatProgressSpinnerModule,
    MatCheckboxModule,
    RomaToolTipModule,
    MatMenuModule,
    RmBadgeModule,
  ],
  providers: [TitleCasePipe],
  exports: [RmStatusSelectComponent],
})
export class RmStatusSelectModule {}
