import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Sale } from '@tacliatech/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  SaleStorageService,
  SaleStoreService,
} from '../create-sale.store.service';

@Component({
  selector: 'roma-sale-preview-card',
  templateUrl: './sale-preview-card.component.html',
  styleUrls: ['./sale-preview-card.component.scss'],
})
export class SalePreviewCardComponent implements OnInit {
  @Input() type;
  @Input() isLoading;
  @Input() selectedPaymentMethod;
  @Input() salePaymentMethodRef;
  @Input() refundedSale: boolean;
  @Input() refundText;
  @Input() refundStart;
  @Output()
  submitSaleForm = new EventEmitter<unknown>();
  @Output()
  generatePayment = new EventEmitter<unknown>();
  @Output()
  finishRefund = new EventEmitter<unknown>();
  @Output()
  saveUnPaid = new EventEmitter<unknown>();

  showToolTip = false;
  printing = false;
  status: 'danger' | 'warning' | 'success';

  saleSteps$: Observable<SaleStorageService.SaleStepType> = this.saleStorageService.sale$.pipe(
    map((res) => res.steps)
  );

  saleStorage$ = this.saleStorageService.sale$;

  amountCheckRefund$ = (amount: number) =>
    this.saleSteps$.pipe(
      map((res) => {
        if (res.paymentRefund || res.paymentRefundFinish) {
          return '-' + amount;
        } else {
          return amount;
        }
      })
    );

  translatePaymentMethod$ = () => {
    return this.saleStorage$.pipe(
      map((res: SaleStorageService.Storage) => {
        return res.sale;
      }),
      map((res: Sale.Output) => {
        const selectedPaymentMethod = res.paymentMethod;
        if (selectedPaymentMethod === Sale.PaymentMethodEnum.Cash) {
          return 'sales.salesCard.cash';
        }

        if (selectedPaymentMethod === Sale.PaymentMethodEnum.Card) {
          return 'sales.salesCard.card';
        }

        if (selectedPaymentMethod === Sale.PaymentMethodEnum.Others) {
          return 'sales.salesCard.others';
        }

        if (selectedPaymentMethod === Sale.PaymentMethodEnum.PaymentLink) {
          return 'sales.salesCard.paymentLink';
        }

        return this.i18n.instant('sales.salesCard.others');
      })
    );
  };

  constructor(
    private i18n: TranslateService,
    private saleStorageService: SaleStoreService
  ) {}

  ngOnInit(): void {
    this.saleSteps$.subscribe((saleSteps) => {
      if (
        saleSteps.paymentRefundFinish ||
        saleSteps.paymentCancelFinish ||
        saleSteps.paymentRejected
      ) {
        this.status = 'danger';
      } else if (saleSteps.paymentUnpaid || saleSteps.paymentPending) {
        this.status = 'warning';
      } else if (saleSteps.paymentFinish) {
        this.status = 'success';
      }
    });
  }

  openSale(object) {
    const refundedId = object.sale.refund._id;
    if (refundedId) {
      window.open(`/admin/sales?type=sale&id=${refundedId}`, '_blank');
    }
  }

  getPaymentDateInLocale(paymentDate: Date, mode: string) {
    const date = new Date(paymentDate);
    let locale = '';

    if (this.i18n.currentLang === 'es') {
      locale = 'es-ES';
    } else {
      locale = 'en-US';
    }

    let options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };

    if (mode === 'hour') {
      delete options.weekday;
      options = {
        ...options,
        hour: 'numeric',
        minute: 'numeric',
      };
    }

    return date.toLocaleDateString(locale, options);
  }

  showDialog(): void {
    if (this.saleStorageService.getSale().items.length === 0) {
      const isMobile = window.screen.width < 768;
      if (!isMobile) {
        this.showToolTip = !this.showToolTip;
      } else {
      }
    }
  }

  getStatusStyles(): { [key: string]: string } {
    switch (this.status) {
      case 'danger':
        return { color: '#b80006', 'background-color': '#ffebeb' };
      case 'warning':
        return { color: '#a36a00', 'background-color': '#fff1db' };
      case 'success':
        return { color: '#247f47', 'background-color': '#dff6e8' };
      default:
        return {};
    }
  }
}
