import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  BudgetCommonData,
  IBudgetsSetting,
  IMailSend,
  TypeAmplitudeBudget,
  TypeBudget,
  ValidateAllFormFields,
} from '@tacliatech/types';
import { StorageService, UserService } from '@web-frontend/shared/services';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import { TranslateService } from '@ngx-translate/core';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { InvoiceService } from '../../../invoice/invoice.service';
import { UserflowService } from '@web-frontend/shared/services/userflow/userflow.service';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
@Component({
  selector: 'roma-modal-send',
  templateUrl: './modal-send.component.html',
  styleUrls: ['./modal-send.component.scss'],
})
export class ModalSendComponent implements OnInit {
  sppiner = 'Enviando correo';

  budget: BudgetCommonData = new BudgetCommonData();
  AllSettings: IBudgetsSetting;
  type: string;
  email: string;
  user: any;

  form: FormGroup = this.fb.group({
    message: new FormControl(null, []),
    to: new FormControl(null, [Validators.required]),
    subject: new FormControl(null, [Validators.required]),
    document: new FormControl(null),
    type: new FormControl(null),
    id: new FormControl(null),
    company: new FormControl(null),
    user: new FormControl(null),
  });

  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  destinations: string[] = [];

  isLoading = false;
  loadingBudget = true;
  messagePersonalizado: string;
  budgetTypeAmplitude: TypeAmplitudeBudget;

  company_date_format = this.budgetService.date_format
    ? this.budgetService.date_format
    : 'dd/MM/yyyy';

  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private budgetService: BudgetService,
    private dialogRef: MatDialogRef<ModalSendComponent>,
    private alertService: AlertService,
    public i18n: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly brazeService: BrazeService,
    private amplitudeService: AmplitudeService,
    private readonly invoiceService: InvoiceService,
    private readonly userService: UserService,
    private readonly userflowService: UserflowService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.budget = this.data.budget;
    this.AllSettings = this.data.settings;
    this.type = this.data.type;
    if (Array.isArray(this.data.email)) {
      this.destinations.push(...this.data.email);
    } else {
      this.email = this.data.email;
    }
    this.user = this.data.user;
    this.budgetTypeAmplitude = this.data.budgetTypeAmplitude;

    this.getData();
  }

  get isMXandTypeBill(): boolean {
    return (
      this.userService.validateCountryMX() && this.type === TypeBudget.BILL
    );
  }

  handleResponse(res: BudgetCommonData): void {
    this.budget = res;
    this.form.patchValue({
      document: this.budget?.header?.numberDoc,
      type: this.type,
      id: this.budget?._id,
      to: this.email,
      company: StorageService.CompanyId,
      user: this.user,
    });

    if (this.email) {
      this.destinations.push(this.email);
      this.patchTo();
    }

    this.draw();
    this.loadingBudget = false;
  }

  getData(): void {
    if (this.isMXandTypeBill) {
      this.invoiceService
        .findOne(this.budget?._id || this.budget?.id)
        .then((res) => {
          this.handleResponse(res);
        });
    } else
      this.budgetService
        .findBudgetDetail(this.budget?._id, this.type)
        .subscribe((resp) => {
          this.handleResponse(resp);
        });
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  ok() {
    this.dialogRef.close();
  }

  convertMesage() {
    const m: string = this.form.get('message').value;
    this.messagePersonalizado = m.replace(/(?:\r\n|\r|\n)/g, '<br>');
    this.form.patchValue({ message: this.messagePersonalizado });
  }

  payload(): IMailSend {
    const m = this.form.get('message').value || '';
    this.messagePersonalizado = m.replace(/(?:\r\n|\r|\n)/g, '<br>');
    const value: IMailSend = Object.assign(this.form.value, {
      message: this.messagePersonalizado,
      lang: this.i18n.currentLang,
    });
    return value;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (
      value &&
      this.destinations.indexOf(value) === -1 &&
      value.match?.(EMAIL_REGEX)
    ) {
      this.destinations.push(value);
      this.patchTo();
    } else {
      // this.alertService.success("Correo invalido");
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(to: string): void {
    const index = this.destinations.indexOf(to);

    if (index >= 0) {
      this.destinations.splice(index, 1);
      this.patchTo();
    }
  }

  patchTo() {
    this.form.patchValue({ to: this.destinations });
  }

  send(): void {
    this.form.controls['to'].setValue(this.destinations);
    this.form.controls['to'].updateValueAndValidity();
    const isValid = this.checkValidators();

    if (isValid) {
      this.isLoading = true;
      if (this.isMXandTypeBill) {
        this.invoiceService
          .sendEmail(this.payload())
          .then(() => {
            this.handleEmailSent();
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else
        this.budgetService.sendDocument(this.payload()).subscribe(() => {
          this.handleEmailSent();
        });

      this.amplitudeService.sendEvent({
        event: `${this.budgetTypeAmplitude}_view_emailSend`,
      });
    }
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);

    return this.form.valid;
  }

  handleEmailSent(): void {
    // this.alertService.success(this.i18n.instant('general.mailSent'));

    const eventMap = {
      [TypeBudget.BILL]: BrazeEventType.bill_send_document,
      [TypeBudget.BUDGET]: BrazeEventType.budget_send_document,
      [TypeBudget.PROFORM]: BrazeEventType.proform_send_document,
      [TypeBudget.WAL]: BrazeEventType.waybill_send_document,
    };

    const eventType = eventMap[this.data.type];

    if (eventType) {
      this.analyticsService.trackEvent({
        sources: ['amplitude', 'braze', 'userflow'],
        eventName: eventType,
        eventProperties: {
          finalEmails: this.destinations,
        },
      });
    }
    this.isLoading = false;
    this.ok();
  }
}
