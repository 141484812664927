<div class="container-account-data">
  <div class="title-card">
    {{ 'budgets.settings.title.invoice-setting-all' | translate }}
  </div>

  <div class="global-container">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div>
        <div class="b50">
          <div class="form-group">
            <label for="title" class="lbl">
              {{ 'budgets.settings.create.form.standard-rate' | translate }}
            </label>

            <ng-select
              [multiple]="false"
              [ngModel]="selectedTax"
              [items]="taxes"
              (ngModelChange)="submitBudget($event)"
              [searchable]="false"
              [clearable]="true"
              [notFoundText]="'hiring.form.placeholder_notfound' | translate"
            >
              <ng-template ng-option-tmp ng-label-tmp let-item="item">
                {{ item.translate | translate }}
                {{ changeComma(item.value) }}%
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="b50">
          <button class="right-button" (click)="addSequence()">
            {{ 'sequence.add' | translate }} +
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-5 mt-lg-4">
      <div
        class="b65 mt-1 mb-2"
        [ngClass]="{ 'mb-4': (resize$ | async)?.payload?.mobile }"
      >
        <div class="form-group">
          <label for="title" class="lbl"
            >{{ 'budgets.settings.create.form.taxesAvailable' | translate }}
            <div class="custom-tooltip top is__disabled">
              <img
                src="assets/icons/gl_information-fill-gray.svg"
                class="help-icon"
                [ngStyle]="
                  (resize$ | async)?.payload?.mobile
                    ? { 'padding-bottom': '4px' }
                    : ''
                "
                (click)="showDefaultTaxesListDialog()"
              />
              <div *ngIf="!(resize$ | async)?.payload?.mobile" class="tiptext">
                {{
                  'budgets.settings.create.form.selectedTaxesTooltip'
                    | translate
                }}
              </div>
            </div>
          </label>

          <roma-rm-select-v3
            class="select-input"
            [placeholderText]="'budgets.settings.create.form.selectedTaxes'"
            [items]="defaultTaxes"
            [isLoading]="false"
            [isAbsolute]="true"
            [addButtonText]="'sales.salesCard.addItem'"
            [activeItems]="selectedTaxes$ | async"
            mode="MULTIPLE"
            inputHeight="44px"
            type="taxes"
            (changeItems)="changeTaxes($event)"
            [amplitudeEvent]="'settings_selectTaxes'"
          >
          </roma-rm-select-v3>
        </div>
      </div>
    </div>

    <div class="for-sequences">
      <div class="wrapper-div">
        <p class="description sub-description">
          <strong>{{ 'sequence.name' | translate }}</strong>
        </p>
        <p class="description sub-description">
          <strong>{{ 'sequence.typeTitle' | translate }}</strong>
        </p>
        <p class="actions"></p>
      </div>
      <div *ngFor="let sequence of sequences; let i = index">
        <div class="wrapper-div">
          <p class="description sub-description">
            {{ sequence.name }}
          </p>
          <p class="description sub-description">
            {{ getTranslationBySequenceType(sequence.type) }}
          </p>
          <p class="actions">
            <span
              class="pointer panel-header-icon"
              title="Editar"
              (click)="editSequence(sequence)"
            >
              <img src="assets/icons/gl_edit-gray.svg" />
            </span>
            <span
              *ngIf="showSequenceTrash(sequence)"
              romaClickDelete
              class="pointer panel-header-icon"
              title="Eliminar"
              [item]="sequence"
              subtitle="deleteModal.body_final"
              (wantDelete)="deleteSequence(sequence)"
            >
              <img src="assets/icons/gl_trash.svg" />
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
