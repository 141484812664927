import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

interface DayWeek {
  label: string;
  value: string;
}

@Component({
  selector: 'roma-day-select',
  templateUrl: './day-select.component.html',
  styleUrls: ['./day-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DaySelectComponent implements OnInit {
  @Output() workingDaysChange = new EventEmitter<string[]>();
  @Input() defaultValue? = [] as string[];

  readonly days: DayWeek[] = [
    { label: 'person.create.form.monday', value: 'monday' },
    { label: 'person.create.form.tuesday', value: 'tuesday' },
    { label: 'person.create.form.wednesday', value: 'wednesday' },
    { label: 'person.create.form.thursday', value: 'thursday' },
    { label: 'person.create.form.friday', value: 'friday' },
    { label: 'person.create.form.saturday', value: 'saturday' },
    { label: 'person.create.form.sunday', value: 'sunday' },
  ];

  selectedDays: string[] = [];
  innerWidth = 0;
  isResponsive = false;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.updateResponsiveStatus();
  }

  constructor(
    private cd: ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.updateResponsiveStatus();
    this.setDefaultValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultValue) {
      this.setDefaultValues();
    }
  }

  private setDefaultValues(): void {
    if (this.defaultValue) {
      this.selectedDays = this.defaultValue;
    }
    this.cd.detectChanges();
  }

  private updateResponsiveStatus(): void {
    this.innerWidth = window.innerWidth;
    this.isResponsive = this.innerWidth < 768;
    this.cd.markForCheck();
  }

  toggleDaySelection(dayIndex: number): void {
    const day = this.days[dayIndex].value;

    if (this.selectedDays.includes(day)) {
      this.selectedDays = this.selectedDays.filter(
        (selectedDay) => selectedDay !== day
      );
    } else {
      this.selectedDays = [...this.selectedDays, day];
    }

    this.workingDaysChange.emit(this.selectedDays);

    this.cd.markForCheck();
  }

  getDayLabel(day: DayWeek): string {
    const translatedLabel = this.translate.instant(day.label);
    return this.isResponsive
      ? translatedLabel.slice(0, 1)?.toUpperCase()
      : translatedLabel.slice(0, 3)?.toUpperCase();
  }
}
