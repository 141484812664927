import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

import { BehaviorSubject, from, Observable } from 'rxjs';
import { concatMap, finalize } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/compat/storage';

import { FileUploadBody, FileUploadOptions } from './file-upload.interface';
import { EFileType } from '@tacliatech/types';

import * as uuid from 'uuid';
import { environment } from '@web-frontend/environments';

import { FileUploadRes } from '@web-frontend/shared/helpers/file-upload';
import { StorageService } from '@web-frontend/shared/services';

export const basePath = '/uploads';

@Injectable({ providedIn: 'root' })
export class FileUploadService {
  private uploadProgressSource = new BehaviorSubject<{
    type: 'UPDATE_STATUS' | 'HIDDEN';
    value: number;
    // @ts-ignore
  }>({ type: 'HIDDEN', value: null });

  public uploadProgress$ = this.uploadProgressSource.asObservable();

  constructor(private http: HttpClient, private storage: AngularFireStorage) {}

  testFileSubido() {
    const ref = this.storage.ref(`${{ basePath }}/budget-logo/logocopado.png`);
    return ref.getDownloadURL();
  }

  getFile(module: EFileType, fileName: string) {
    const url = `${basePath}/${module}/${fileName}`;
    const ref = this.storage.ref(url);
    return ref.getDownloadURL();
  }

  getStorageReference(module: EFileType, fileName: string) {
    const url = `${basePath}/${module}/${fileName}`;
    const ref = this.storage.ref(url);
    return ref;
  }

  test = (module: EFileType, file: File) => {
    const url = `${basePath}/${module}/${file.name}`;
    const fileRef = this.storage.ref(url);
    const task = this.storage.upload(url, file);

    return new Observable<string>((observer) => {
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe((url: string) => {
              observer.next(url);
            });
          })
        )
        .subscribe();
    });
  };

  /**
   * extract just name of file with token
   * @param {Function} url? url fullname
   * @return {string} urlname
   */
  private splitUrl(url: string) {
    const urlWithAltMedia = url.split('%2F')[2].split('&')[0];
    return urlWithAltMedia;
  }

  uploadFileToAssetsAPI(
    module: EFileType,
    file: File,
    path: string
  ): Observable<FileUploadRes[]> {
    const formData: FormData = new FormData();
    formData.append('path', path);
    formData.append('type', module);
    formData.append('file', file);

    return this.http.post<FileUploadRes[]>(
      `${environment.api.urlPlain}assets/files`,
      formData,
      { headers: { Authorization: `Bearer ${StorageService.Token}` } }
    );
  }

  uploadFile(module: EFileType, file: File): Observable<string> {
    const randomId = uuid.v4();
    const extension = file.name ? file.name?.split('.').pop() : '';
    const url = `${basePath}/${module}/${randomId}.${extension}`;
    const fileRef = this.storage.ref(url);
    const task = this.storage.upload(url, file);

    return new Observable<string>((observer) => {
      const percentageSub$ = task
        .percentageChanges()
        .pipe(
          finalize(() => {
            setTimeout(() => {
              this.uploadProgressSource.next({
                type: 'HIDDEN',
                // @ts-ignore
                value: null,
              });
            }, 1000);
          })
        )
        .subscribe((val) => {
          this.uploadProgressSource.next({
            type: 'UPDATE_STATUS',
            // @ts-ignore
            value: val,
          });
        });

      const task$ = task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(
            (url: string) => {
              const result = this.splitUrl(url);
              observer.next(result);
            },
            (error) => {
              observer.error(error);
            },
            () => {
              observer.complete();
            }
          );
        })
      );

      const taskSub$ = task$.subscribe();

      return {
        unsubscribe() {
          percentageSub$.unsubscribe();
          taskSub$.unsubscribe();
          task.cancel();
          observer.error();
          observer.complete();
        },
      };
    });
  }

  upload(module: EFileType, file: File) {
    const randomId = uuid.v4();
    const extension = file.name.split('.').pop();
    const url = `${basePath}/${module}/${randomId}.${extension}`;
    const fileRef = this.storage.ref(url);
    const task = this.storage.upload(url, file);
    return { ref: fileRef, task: task };
  }

  deleteFirebaseStorageFile(module: EFileType, nameFile: string) {
    const downloadUrl =
      environment.firebaseConfig.storageUrl +
      `${basePath}%2F${module}%2F${nameFile}`;
    return this.storage.storage.refFromURL(downloadUrl).delete();
  }

  private async createFormData(files: Array<File>, pathKey: string) {
    const formData = new FormData();

    for (const file of Array.from(files)) {
      try {
        formData.append(pathKey, file, file.name);
      } catch (error) {}
    }

    return formData;
  }

  private insertToFormData(formData: FormData) {
    return (body: FileUploadBody) => {
      for (const key in body) {
        if (body.hasOwnProperty(key)) {
          const value = body[key];

          formData.set(key, value);
        }
      }

      return formData;
    };
  }

  private createRequest<T = any>(url: string, options: FileUploadOptions) {
    return (formData: FormData) => {
      const req = new HttpRequest(options.method || 'POST', url, formData, {
        reportProgress: true,
        headers: new HttpHeaders(options.headers as any),
      });

      return this.http.request<T>(req);
    };
  }

  private preparer(files: Array<File>, options: FileUploadOptions) {
    return new Promise<FormData>(async (resolve, reject) => {
      try {
        let formData: FormData;
        const filesToResolve: Array<File> = [];

        for (const file of files) {
          filesToResolve.push(file);
        }

        // @ts-ignore
        formData = await this.createFormData(filesToResolve, options.pathKey);

        if (options.body) {
          formData = this.insertToFormData(formData)(options.body);
        }

        resolve(formData);
      } catch (error) {
        reject(error);
      }
    });
  }

  send<T>(url: string) {
    return (files: Array<File>, options: FileUploadOptions = {}) => {
      options = Object.assign({}, { pathKey: 'file' }, options);

      return from(this.preparer(files, options)).pipe(
        concatMap(this.createRequest<T>(url, options))
      );
    };
  }
}
