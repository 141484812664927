import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { FileWriteService } from '@web-frontend/shared/helpers/file-write';
import { from, Observable, Subscription } from 'rxjs';

import { concatMap } from 'rxjs/operators';

import { ISale, ISaleDownload, Sale } from '@tacliatech/types';
import { StorageService } from '../storage/storage.service';
import { environment } from '@web-frontend/environments';
import { replaceNonEnglishCharacters } from '@web-frontend/shared/utils/replace-non-english-characters';
import { FileWriteOutput } from '../../helpers/file-write/file-write.types';

@Injectable({
  providedIn: 'root',
})
export class SalesService {
  private sub$ = new Subscription();
  constructor(
    private http: HttpClient,
    private fileWriteService: FileWriteService
  ) {}

  updateOne(id: string, params: Sale.UpdateSaleDto) {
    return this.http.put<ISale>(`:API_URL/sales/${id}`, { ...params });
  }

  createOne(data: Sale.CreateSaleDto) {
    return this.http.post<Sale.Output>(`:API_URL/sales/`, data);
  }

  createSaleAndPaymentLink(data: Sale.CreateSaleDto) {
    return this.http.post<Sale.Output>(
      `:API_URL/sales/create-sale-and-payment-link`,
      data
    );
  }

  createSaleAndTransfer(data: Sale.CreateSaleDto) {
    return this.http.post<Sale.Output>(
      `:API_URL/sales/create-sale-and-transfer`,
      data
    );
  }

  createRefund(idSale: string) {
    return this.http.post<Sale.Output>(`:API_URL/sales/refund/${idSale}`, {});
  }

  sendEmail(idSale: string, data) {
    return this.http.post(`:API_URL/sales/send-email/${idSale}`, data, {
      headers: {
        // @ts-ignore
        timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || null,
      },
    });
  }

  sendPaymentLinkEmail(idSale: string, data) {
    return this.http.post(
      `:API_URL/sales/send-payment-link-email/${idSale}`,
      data
    );
  }

  getPdf(data: ISaleDownload): Observable<Blob> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${StorageService.Token}`,
        timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || null,
      }),
      responseType: 'blob' as const,
    };

    return this.http.post(
      `${environment.cloudFunctionDownloadSaleTicket}`,
      data,
      httpOptions
    );
  }

  downloadSale(data: ISaleDownload): Observable<FileWriteOutput> {
    const { finalName } = data;
    return this.getPdf(data).pipe(
      concatMap((res) => {
        if (data.ids.length === 1) {
          return from(
            this.fileWriteService.writeFile({
              path: `Ticket_#${data.ticketNumber}${
                finalName ? '_' + replaceNonEnglishCharacters(finalName) : ''
              }.pdf`,
              blob: res,
            })
          );
        }
        return from(
          this.fileWriteService.writeFile({
            path: `${data.ticketNumber}.zip`,
            blob: res,
          })
        );
      })
    );
  }
}
