import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  App,
  IProject,
  IProjectOut,
  PaginateResponse,
} from '@tacliatech/types';
import { BehaviorSubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private sub$ = new Subscription();

  private projectsCache = new BehaviorSubject<App.ResourceCached<IProject[]>>([
    'INIT',
    [],
  ]);

  projects$ = this.projectsCache.asObservable();

  public get projects() {
    return this.projectsCache.value[1];
  }

  init() {
    this.projectsCache.next(['LOADING', []]);
    this.sub$.add(
      this.findAllDirect().subscribe((res) => {
        this.projectsCache.next(['LOADED', res]);
      })
    );
  }

  constructor(private http: HttpClient) {}

  search(query: { [key: string]: any } = {}) {
    const params = new HttpParams({
      fromObject: {
        ...query,
      },
    });

    return this.http.get<PaginateResponse<IProjectOut[]>>(
      `:API_URL/search/projects`,
      {
        params,
      }
    );
  }

  findAll() {
    return this.http.get<PaginateResponse<IProjectOut[]>>(
      `:API_URL/projects/all`
    );
  }

  findAllDirect() {
    return this.http.get<IProject[]>(`:API_URL/projects/`);
  }

  create(data: { [key: string]: unknown } = {}) {
    return this.http
      .post<unknown>(`:API_URL/projects`, { ...data })
      .pipe(
        tap(() => {
          this.init();
        })
      );
  }

  updateOne(id: string, data: { [key: string]: unknown } = {}) {
    return this.http.put<IProject>(`:API_URL/projects/${id}`, { ...data });
  }

  deleteOne(id: string) {
    return this.http.delete<IProject>(`:API_URL/projects/${id}`);
  }

  findProjectsByCompany(id: string) {
    return this.http.get<IProject[]>(`:API_URL/companies/${id}/projects`);
  }

  findAllProjectsByCompany_SELECT() {
    return this.http.get<IProject[]>(':API_URL/projects/company/select');
  }

  restoreMany(data) {
    return this.http.post(`:API_URL/projects/restore-many`, data);
  }

  deleteMany(data) {
    return this.http.post(`:API_URL/projects/delete-many`, data);
  }
}
