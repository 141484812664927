<roma-modal-template
  [title]="'sales.salesCard.sharePaymentLinkModalTitle' | translate"
  [size]="'sm'"
  [contentMinHeight]="false"
  [headerBottomMargin]="'16px'"
  (cancel)="close()"
  type="sales"
  [showCancelBtn]="false"
  [showSuccessBtn]="false"
  [responsiveFull]="(resize$ | async)?.payload?.mobile ? false : true"
>
  <div class="modal__wrapper">
    <p
      class="subtitle"
      [innerHTML]="'sales.salesCard.sharePaymentLinkModalSubtitle' | translate"
    ></p>

    <button
      class="btn-download"
      romaProFeature
      [feature]="featureRef.Sale.SendBudgetEmail"
      mat-menu-item
      (clicked)="sharePaymentLinkModalService.sendSaleEmail()"
    >
      <img class="mx-1" src="assets/icons/gl_mail-line-gray.svg" />
      {{ 'deal_part_of_service.sendByEmail' | translate }}

      <div class="icon-star-pro-feature centeredImage">
        <div class="roma-label-2 warning right-center">
          <div class="icon"><img src="assets/icons/star.svg" /></div>
          PRO
        </div>
      </div>
    </button>

    <br />
    <button
      class="btn-download"
      mat-menu-item
      (click)="sharePaymentLinkModalService.copyLink()"
    >
      <img class="mx-1" src="assets/icons/gl_link-black.svg" />
      {{ 'general.shareLink' | translate }}
    </button>
  </div>
</roma-modal-template>
