<div *ngIf="showButton">
  <form
    class="equipment-notes-footer"
    [formGroup]="form"
    (ngSubmit)="addNotes()"
  >
    <div
      class="textarea__container"
      [ngClass]="{ textarea__focus: file?.length }"
    >
      <textarea
        #commentText
        name=""
        id=""
        cols="30"
        rows="1"
        class="note-input"
        [placeholder]="'equipments.create.notePh' | translate"
        formControlName="text"
        maxlength="255"
        (blur)="blurTextArea()"
      >
      </textarea>
      <roma-image-selector
        [ngClass]="{ has__file: file?.length }"
        [type]="'part-of-service'"
        [quantity]="1"
        [feature]="featureRef.Deal.UploadFiles"
        [singleMode]="true"
        [customContent]="
          (resize$ | async)?.payload?.mobile
            ? null
            : {
                feature: featureRef.Deal.UploadFiles,
                title: 'pro.deal.files.title',
                description: determineCopy('pro.deal.description'),
                items: [],
                successText: 'pro.successText',
                cancelText: 'pro.cancelText'
              }
        "
        [fileArray]="file || []"
        (changeFileArray)="changeFileArray($event)"
      >
      </roma-image-selector>
    </div>
    <div class="actions" *ngIf="commentText.value || file?.length">
      <button
        type="submit"
        class="roma-button save-btn"
        [disabled]="!commentText.value && !file?.length"
        (mouseover)="overSubmit = true"
        (mouseout)="overSubmit = false"
      >
        {{ 'general.save' | translate }}
        <!-- <mat-icon>{{ editMode.active ? 'edit' : 'add' }}</mat-icon> -->
      </button>
      <button class="cancel-btn" (click)="commentText.value = ''">
        {{ 'activity.create.cancel' | translate }}
      </button>
    </div>
  </form>
</div>
<div *ngIf="notes.length" class="equipment-notes-body">
  <div *ngFor="let note of notes; let i = index" class="note-item">
    <div>
      <img
        [src]="getUserImgByName(note.name)"
        style="border-radius: 50%; margin-right: 8px"
      />
      <span class="name"
        ><b>{{ note.name }}</b></span
      >&nbsp;
      <span class="dateText"
        >{{ note.createdAt | date: company_date_format }} -
        {{ getCommentTime(note.createdAt) }}</span
      >
    </div>

    <div>
      <p class="note-item-text">
        <span *ngIf="note.text" style="margin-bottom: 10px; display: block">{{
          note.text
        }}</span>
        <ng-container *ngIf="note.file?.length">
          <div class="file__container">
            <img
              class="note__file"
              [ngClass]="{ file__img: !resolveImageFormat(note.file[0]) }"
              [src]="resolveFile(note.file[0])"
            />
            <div
              class="image-preview-over"
              (click)="downloadFile(note.file[0])"
            >
              <div class="image-preview-over-image">
                <img src="assets/icons/gl_download_v2.svg" />
              </div>
              <div class="image-preview-over-text">
                {{ 'general.downloadExcel' | translate }}
              </div>
            </div>
          </div>
        </ng-container>
      </p>
    </div>
    <div class="container-actions" *ngIf="note.edit">
      <span (click)="editNote(i, note)">{{ 'general.edit' | translate }}</span>
      <span (click)="deleteNote(i)">{{ 'general.delete' | translate }}</span>
    </div>
  </div>
</div>
