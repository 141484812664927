import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICategoryExpense } from '@tacliatech/types';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CategoryExpensesService {
  private sub$ = new Subscription();

  private categoryExpenseCache = new BehaviorSubject<ICategoryExpense[]>([]);

  categoriesExpenses$ = this.categoryExpenseCache.asObservable();

  constructor(private http: HttpClient) {}

  init() {
    this.sub$.add(
      this.findAll().subscribe((res) => {
        this.categoryExpenseCache.next(res);
      })
    );
  }

  public get categoriesExpenses() {
    return this.categoryExpenseCache.value;
  }

  findAll() {
    return this.http.get<ICategoryExpense[]>(`:API_URL/category-expenses/`);
  }

  findAllByType(type: string) {
    return this.http.get<ICategoryExpense[]>(
      `:API_URL/category-expenses/type/${type}`
    );
  }

  deleteOne(id: string) {
    return this.http.delete<unknown>(`:API_URL/category-expenses/${id}`);
  }

  createOne(data: { [key: string]: any }) {
    return this.http.post(`:API_URL/category-expenses`, { ...data });
  }

  updateOne(id: string, data: { [key: string]: any }) {
    return this.http.put(`:API_URL/category-expenses/${id}`, { ...data });
  }

  //
  findAllCategoryExpensesByCompany_SELECT() {
    return this.http.get<{ _id: string; name: string }[]>(
      `:API_URL/category-expenses/company/select`
    );
  }
}
