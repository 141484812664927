import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SendEmailPartOfServiceComponent } from './send-email-part-of-service.component';
import { SendEmailPartOfService } from './send-email-part-of-service.types';

@Injectable({
  providedIn: 'root',
})
export class SendEmailPartOfServiceProvider {
  private refreshListSource = new Subject<boolean>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(config?: MatDialogConfig<SendEmailPartOfService.ModalParams>) {
    const dialogRef = this.dialog.open(SendEmailPartOfServiceComponent, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }
}
