import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PictureV2Component } from './picture-v2.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingModule } from '../loading';
import { DragAndDropDirective } from './drag-and-drop.directive';

@NgModule({
  declarations: [PictureV2Component, DragAndDropDirective],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    LoadingModule,
    TranslateModule.forChild(),
  ],
  exports: [PictureV2Component],
})
export class PictureV2Module {}
