<roma-modal-template
  [title]="'logo-crop.title' | translate"
  [subtitle]="'logo-crop.subtitle-modal' | translate"
  [footerTemplate]="footerButtons"
  [showDefaultButtons]="false"
  (cancel)="close()"
>
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="m-15 col-md-10">
        <ng-container *ngIf="deletedFile; else imageCropper">
          <roma-picture-v2
            [id]="data.id"
            (emitEvent)="fileChangeEvent($event)"
            [injectModal]="false"
          >
          </roma-picture-v2>
          <p class="logo-crop-text">{{ 'logo-crop.logo-text' | translate }}</p>
        </ng-container>

        <ng-template #imageCropper>
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="false"
            [imageURL]="
              data.rawLogo ? (data.rawLogo | imageFirebase: 'budget-logo') : ''
            "
            format="png"
            crossOrigin="Anonymous"
            (imageCropped)="imageCropped($event)"
            class="border max-height-width"
          ></image-cropper>
          <br />
          <div class="actions-container">
            <platform-disable-content
              allowedPlatforms="['web', 'android']"
              [disableForMobileWebBrowser]="true"
            >
              <label class="action replace">
                {{ 'logo-crop.replace-button' | translate }}
                <input
                  hidden
                  #fileInput
                  type="file"
                  (change)="fileChangeEvent($event)"
                  accept="image/png, image/jpeg, image/jpg"
                />
              </label>

              <div class="separator"></div>
            </platform-disable-content>
            <span class="action delete" (click)="deleteImage()">
              {{ 'logo-crop.delete-button' | translate }}
            </span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</roma-modal-template>

<ng-template #footerButtons>
  <div *ngIf="params?.previewMode" class="container-buttons">
    <div class="roma-disclaimer warning">
      <div class="icon">
        <img src="assets/icons/circle-with-star.svg" />
      </div>
      <div class="body">
        {{ 'pro.clockAbsence.disclaimer.title' | translate }}&nbsp;
        <span class="highlight">
          {{
            determineCopy('pro.clockAbsence.disclaimer.highlight') | translate
          }}
        </span>
      </div>
    </div>
    <!-- ⭐️ pro feature -->
    <button
      romaProFeature
      [feature]="featureRef.ClockAbsence.AddNewClockAbsenceMine"
      class="roma-button dark big full-width"
    >
      <img src="assets/icons/star.svg" />
      {{ 'pro.successText' | translate }}
    </button>
    <!-- ⭐️ pro feature -->
  </div>
  <div *ngIf="!params?.previewMode" class="modalFooter">
    <div class="groupEndDiv">
      <base-button
        type="submit"
        [isLoading]="isLoading"
        buttonClass="tertiary"
        (click)="close()"
      >
        {{ 'logo-crop.cancel' | translate }}
      </base-button>
      <base-button type="submit" [isLoading]="isLoading" (click)="saveImage()">
        {{ 'logo-crop.save' | translate }}
      </base-button>
    </div>
  </div>
</ng-template>
