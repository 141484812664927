import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import {
  BDG_PREFERENCES,
  BudgetPreferences,
  BudgetsSetting,
  FIRST_DAY_CALENDAR,
  ValidateAllFormFields,
} from '@tacliatech/types';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { StorageService } from '@web-frontend/shared/services';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { PermissionService } from '@web-frontend/shared/services/permissions';

import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { BudgetUtilisService } from '../../../core/admin/budgets/budget-edit/budgets-edit.component';

@Component({
  selector: 'roma-budget-preferences',
  templateUrl: './budget-preferences.component.html',
  styleUrls: ['./budget-preferences.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetPreferencesComponent implements OnInit {
  @Input()
  id: string;

  @Input()
  budget: BudgetsSetting;

  @Input()
  mode: 'ADD' | 'EDIT' = 'ADD';

  zones = this.budgetService.findAllZones();
  currencys = this.budgetService
    .findAllCurrencys()
    .sort((a, b) => a.text.localeCompare(b.text));
  numbers_format = this.budgetService.findAllNumberFormats();
  decimals = this.budgetService.findAllDecimals();
  dates_formats = this.budgetService.findAllDateFormats();
  langs = this.budgetService.findAllLangs();
  days = this.budgetService.findAllDays();

  private sub$ = new Subscription();

  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private budgetService: BudgetService,
    private budgetUtilisService: BudgetUtilisService,
    private toastService: ToastService,
    private i18n: TranslateService,
    private permissionService: PermissionService
  ) {}

  form: FormGroup = this.fb.group({
    currency: new FormControl('', [Validators.required]),
    date_format: new FormControl(null, [Validators.required]),
    decimals: new FormControl(null, [Validators.required]),
    language: new FormControl(null, [Validators.required]),
    number_format: new FormControl(null, [Validators.required]),
    zone: new FormControl(StorageService.UserId, [Validators.required]),
    owner_id: new FormControl(StorageService.CompanyId, [Validators.required]),
    message: new FormControl(null),
    day: new FormControl(null),
  });

  ngOnInit(): void {
    this.checkMode();
    for (let i = 0; i < this.days.length; i++) {
      this.days[i].text = this.i18n.instant(this.days[i].text);
    }
  }

  generateLabel(currency: any): string {
    if (!currency || !currency.text || !currency.value) {
      return '';
    }
    return `${currency.text}: ${currency.value}`;
  }

  checkMode() {
    if (this.mode === 'ADD') {
      this.budget = new BudgetsSetting();
      this.form.patchValue({
        currency: 1,
        date_format: 1,
        decimals: 2,
        language: 1,
        number_format: 1,
        zone: 'Europe/Madrid',
        day: 1,
      });
    } else {
      this.budget.budgetPreferences =
        this.budget.budgetPreferences === undefined
          ? (this.budget.budgetPreferences = new BudgetPreferences())
          : this.budget.budgetPreferences;

      this.form.patchValue({
        currency: this.budget.budgetPreferences.currency,
        date_format: this.budget.budgetPreferences.date_format,
        decimals: this.budget.budgetPreferences.decimals,
        language: this.budget.budgetPreferences.language,
        number_format: this.budget.budgetPreferences.number_format,
        zone: this.budget.budgetPreferences.zone,
        message: this.budget.budgetPreferences.message,
        day: this.budget.budgetPreferences.day
          ? this.budget.budgetPreferences.day
          : 1,
      });
    }
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);
    return this.form.valid;
  }

  submit() {
    const isValid = this.checkValidators();

    if (isValid) {
      const values = this.form.value;
      this.setObject(values);

      const obs$ =
        this.mode === 'EDIT'
          ? this.budgetService.updateOneSettings(this.id, this.budget)
          : this.budgetService.createOneSetting(this.budget);

      this.sub$.add(
        obs$.subscribe(
          (res) => {
            this.budgetUtilisService.refreshBudgetSettings.emit(res);
            this.draw();
            const firstDay = this.budget.budgetPreferences?.day || 0;
            StorageService.SetItem(FIRST_DAY_CALENDAR, firstDay);
            StorageService.SetItem(
              BDG_PREFERENCES,
              JSON.stringify(this.budget.budgetPreferences)
            );

            if (this.mode === 'EDIT') {
              // Updating moneySymbol cache for chargebee modals
              this.permissionService.getMoneySymbol(res);
              this.toastService.show({
                text: this.i18n.instant('budgets.settings.successEdit'),
                type: 'success',
                msDuration: 4000,
              });
            } else {
              this.mode = 'EDIT';
              this.toastService.show({
                text: this.i18n.instant('budgets.settings.successCreated'),
                type: 'success',
                msDuration: 4000,
              });
            }
          },
          (error) => {
            this.toastService.show({
              text: this.i18n.instant('activity.error'),
              type: 'error',
              msDuration: 4000,
            });
          }
        )
      );
    }
  }

  setObject(values) {
    this.budget.budgetPreferences.currency = values.currency;
    this.budget.budgetPreferences.date_format = values.date_format;
    this.budget.budgetPreferences.decimals = values.decimals;
    this.budget.budgetPreferences.language = values.language;
    this.budget.budgetPreferences.number_format = values.number_format;
    this.budget.budgetPreferences.zone = values.zone;
    this.budget.idOwner = values.owner_id;
    this.budget.budgetPreferences.message = values.message;
    this.budget.budgetPreferences.day = values.day;
  }

  changeZone(channels: MatSelectChange) {
    this.sub$.add(
      this.budgetService.updateOneSettings(this.id, this.budget).subscribe(
        (res) => {
          this.budgetUtilisService.refreshBudgetSettings.emit(res);
          this.draw();

          if (this.mode === 'EDIT') {
            this.toastService.show({
              text: this.i18n.instant('budgets.settings.successEdit'),
              type: 'success',
              msDuration: 4000,
            });
          } else {
            this.mode = 'EDIT';
            this.toastService.show({
              text: this.i18n.instant('budgets.settings.successCreated'),
              type: 'success',
              msDuration: 4000,
            });
          }
        },
        (error) => {
          this.toastService.show({
            text: this.i18n.instant('activity.error'),
            type: 'error',
            msDuration: 4000,
          });
        }
      )
    );
  }
}
