import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { RomaToolTipDirective } from './roma-tool-tip.directive';

@NgModule({
  declarations: [RomaToolTipDirective],
  imports: [CommonModule, TranslateModule.forChild()],
  exports: [RomaToolTipDirective],
})
export class RomaToolTipModule {}
