<div>
  <div
    id="selectorMultiple"
    *ngIf="!isLoading && mode === 'MULTIPLE'"
    (click)="focusOutA = false; clickPanel($event, null)"
    class="users-list"
    [ngStyle]="listWidth !== '' && { width: listWidth }"
    [ngClass]="{
      'is-absolute': isAbsolute,
      'taxes-list width-255': type === 'taxes'
    }"
  >
    <div class="search-input">
      <input
        #inputFilterSearch
        [ngStyle]="{ height: inputHeight }"
        class="filter-input"
        type="text"
        [placeholder]="placeholderText | translate"
        (click)="emitAmplitudeEvent()"
      />
      <span class="triangle" [class.rotate180]="showPanelElement"></span>
    </div>
    <div class="list-wrapper" *ngIf="showPanelElement">
      <div
        *ngFor="let item of items | searchFilter: filterArgs; let i = index"
        class="user-item"
        (click)="focusOutA = false; clickPanel($event, null)"
      >
        <div *ngIf="type === 'taxes'" (click)="anuleFocusOutTimeout()">
          <div class="control control__checkbox">
            <mat-checkbox
              color="primary"
              class="ml-2 checkbox-search"
              [checked]="searchCheckFilters(item) ? true : false"
              (click)="
                changeCheckBox({ checked: !searchCheckFilters(item) }, item);
                $event.stopPropagation()
              "
            >
              {{ item.translate | translate }} {{ item.value }}%
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
