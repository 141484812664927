import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxMaskModule } from 'ngx-mask';
import { AlertModule } from '@web-frontend/shared/helpers/alert';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatButtonModule } from '@angular/material/button';

import { AddClockHourComponent } from './add-clock-hour.component';
import { ModalTemplateModule } from '../modal-template';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldErrorDisplayModule } from '../field-error-display';
import { EquipmentNotesModule } from '../equipments-notes';
import { AddTimeModule } from '../add-time/add-time.module';
import { CreatePersonModule } from '../create-person/create-person.module';
import { RmSelectModule } from '../globals/rm-select';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { DateDayMonthModule } from '@web-frontend/shared/pipes/date-day-month/date-day-month.module';
import { ClockHoursDurationModule } from '../clock-hours/clock-hours-duration';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionsModule } from '../permissions';
@NgModule({
  declarations: [AddClockHourComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forChild(),
    PermissionsModule,
    FieldErrorDisplayModule,
    EquipmentNotesModule,
    NgxMaskModule,
    AlertModule,
    AddTimeModule,
    NgSelectModule,
    MatButtonModule,
    CreatePersonModule,
    RmSelectModule,
    ClickDeleteModule,
    DateDayMonthModule,
    ClockHoursDurationModule,
  ],
  exports: [AddClockHourComponent],
})
export class AddClockHourModule {}
