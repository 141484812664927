import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PaymentPrototipeComponent } from './payment-prototipe.component';
import { CreatePaymentCardComponent } from './create-payment-card/create-payment-card.component';
import { CreateConnectedAccountComponent } from './create-connected-account/create-connected-account.component';
import { CreatePaymentsLinkComponent } from './create-payments-link/create-payments-link.component';
import { CreateLoginLinkComponent } from './create-login-link/create-login-link.component';
import { CreateRecurrentPaymentsLinkComponent } from './create-recurrent-payments-link/create-recurrent-payments-link.component';
import { RequestReadersComponent } from './request-readers/request-readers.component';
import { CreateLocationComponent } from './create-location/create-location.component';
import { RegisterReaderComponent } from './register-reader/register-reader.component';
import { PaymentDashboardComponent } from '../payments/payment-dashboard/payment-dashboard.component';
import { CreateRefundComponent } from './create-refund/create-refund.components';
import { CreateTransferComponent } from './create-transfer/create-transfer.component';

const routes: Routes = [
  {
    path: '',
    component: PaymentPrototipeComponent,
  },
  {
    path: 'card',
    component: CreatePaymentCardComponent,
  },
  {
    path: 'accounts',
    component: CreateConnectedAccountComponent,
  },
  {
    path: 'payments-link',
    component: CreatePaymentsLinkComponent,
  },
  {
    path: 'login-link',
    component: CreateLoginLinkComponent,
  },
  {
    path: 'payments-recurrent-link',
    component: CreateRecurrentPaymentsLinkComponent,
  },
  {
    path: 'request-readers',
    component: RequestReadersComponent,
  },
  {
    path: 'create-location',
    component: CreateLocationComponent,
  },
  {
    path: 'register-reader',
    component: RegisterReaderComponent,
  },
  {
    path: 'dashboard',
    component: PaymentDashboardComponent,
  },
  {
    path: 'refunds',
    component: CreateRefundComponent,
  },
  {
    path: 'transfer',
    component: CreateTransferComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PaymentPrototipeRoutingModule {}
