import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatesSelectTableModule } from '../../rates-select-table';
import { SalePreviewCardComponent } from './sale-preview-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyFormatModule } from '@web-frontend/shared/pipes/currency-format/currency-format.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RomaToolTipModule } from '@web-frontend/shared/directives/roma-tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [SalePreviewCardComponent],
  imports: [
    CommonModule,
    RatesSelectTableModule,
    CurrencyFormatModule,
    TranslateModule.forChild(),
    MatTooltipModule,
    RomaToolTipModule,
    MatProgressBarModule,
  ],
  exports: [SalePreviewCardComponent],
})
export class SalePreviewCardModule {}
