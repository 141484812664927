import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'roma-chrono-longtime',
  templateUrl: './chrono-longtime.component.html',
  styleUrls: ['./chrono-longtime.component.scss'],
})
export class ChronoLongtimeComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ChronoLongtimeComponent>,
    private i18n: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.translate();
  }

  translate() {
    this.data.work = this.i18n
      .instant('clockHour.chronoDialog.work')
      .replace('{arg}', this.data.hour);
  }

  submit() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }
}
