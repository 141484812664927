import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RmFilter } from '../rm-filter.types';

@Pipe({
  name: 'filterByParams',
})
export class FilterByParamsPipe implements PipeTransform {
  constructor(private i18n: TranslateService) {}

  transform(value: RmFilter.SelectMultipleItem[], keyword: string): unknown {
    if (!keyword) {
      return value;
    }

    return value.filter((el) =>
      this.i18n.instant(el.title).match(new RegExp(keyword, 'i'))
    );
  }
}
