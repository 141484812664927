import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QRCodeModule } from 'angularx-qrcode';

import { I18nChild } from '@web-frontend/shared/i18n';

import { QrComponent } from './qr.component';
import { ModalTemplateModule } from '../modal-template';
import { QrInfoComponent } from './qr-info/qr-info.component';
import { QrLegendComponent } from './qr-legend/qr-legend.component';

@NgModule({
  declarations: [QrComponent, QrInfoComponent, QrLegendComponent],
  imports: [CommonModule, QRCodeModule, ModalTemplateModule, I18nChild],
  exports: [QrComponent, QrLegendComponent],
})
export class QrModule {}
