import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertModule } from '@web-frontend/shared/helpers/alert';
import { OnlyNumberModule } from '@web-frontend/shared/directives/onlyNumber';

import { NgSelectModule } from '@ng-select/ng-select';

import { ModalTemplateModule } from '../modal-template';
import { FieldErrorDisplayModule } from '../field-error-display';
import { LoadingModule } from '../loading';

import { ModalInfoModule } from '../modal-info';

import { CreateEquipmentComponent } from './create-equipment.component';
import { EquipmentNotesComponent } from './equipment-notes/equipment-notes.component';
import { AppendCustomPropertiesModule } from '../append-custom-properties';
import { ImageSelectorModule } from '../image-selector';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionsModule } from '../permissions';
import { ObligatoryFieldSignModule } from '../obligatory-field-sign/obligatory-field-sign.module';
@NgModule({
  declarations: [CreateEquipmentComponent, EquipmentNotesComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    TranslateModule.forChild(),
    FieldErrorDisplayModule,
    ReactiveFormsModule,
    FormsModule,
    AlertModule,
    MatIconModule,
    MatButtonModule,
    LoadingModule,
    NgSelectModule,
    OnlyNumberModule,
    ModalInfoModule,
    AppendCustomPropertiesModule,
    ImageSelectorModule,
    PermissionsModule,
    ObligatoryFieldSignModule,
  ],
  exports: [CreateEquipmentComponent, EquipmentNotesComponent],
})
export class CreateEquipmentModule {}
