<div class="body">
  <div class="body-text">
    <div *ngIf="!messages && html" [innerHTML]="html | translate"></div>
    <ng-container *ngIf="messages">
      <ng-container *ngFor="let message of messages">
        <div class="message-text" *ngIf="message.type === 'text'">{{message.content | translate}}</div>
        <div class="message-text" *ngIf="message.type === 'html'" [innerHTML]="message.content | translate"></div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="link && tooltipUrl">
      <br />
      <a *ngIf="!internal; else internalLink" [href]="link" target="_blank">
        {{ tooltipUrl | translate }}
      </a>
      <ng-template #internalLink>
        <span class="anchor" (click)="goTo(link)">{{
          tooltipUrl | translate
        }}</span>
      </ng-template>
    </ng-container>
  </div>
  <div class="body-button">
    <button
      class="roma-button add-button-3"
      style="width: 100%; height: 50px"
      (click)="close()"
    >
      {{ buttonText | translate }}
    </button>
  </div>
</div>
