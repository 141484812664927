import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Feature, TypeBudget } from '@tacliatech/types';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'roma-budget-sidenav',
  templateUrl: './budget-sidenav.component.html',
  styleUrls: ['./budget-sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetSidenavComponent implements OnInit, OnDestroy {
  private sub$ = new Subscription();
  templateSelected = 1;
  title: string;

  featuresProEnabled$ = this.permissionService.hasFeatureFn(
    Feature.Activity.DownloadExcel
  );

  haveChanges = false;

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private i18n: TranslateService,
    private dialogRef: MatDialogRef<BudgetSidenavComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.title = this.getTitle(this.data.type);
    this.haveChanges = this.data.haveChanges;
  }

  getTitle(type: TypeBudget): string {
    switch (type) {
      case TypeBudget.BUDGET:
        return 'budgets.sidenav.templateTitleBudget';
        break;
      case TypeBudget.PROFORM:
        return 'budgets.sidenav.templateTitleProform';
        break;
      case TypeBudget.BILL:
        return 'budgets.sidenav.templateTitleBill';
      case TypeBudget.WAL:
        return 'budgets.sidenav.templateTitleWaybills';
      default:
        return 'budgets.sidenav.templateTitleMobile';
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  close(closeParent) {
    this.dialogRef.close(closeParent);
  }

  closeDialogBudget() {
    console.log('test');
  }

  goTo(url, type) {
    this.close(true);
    this.router.navigate([url], {
      queryParams: { type },
    });
  }
}
