import PlaceResult = google.maps.places.PlaceResult;

export class LocationClass {
  name: string;
  cityId: string;
  countryId: string;
  codeCountry: string;
  stateId: string;

  country: string;
  state: string;
  city: string;
  sector: string;
  postCode: string;
  latitude: number;
  longitude: number;
  complement: string;

  latLng: {
    coordinates: number[];
  };

  streetName: string;
  businessNumer: string;
  businessComplement: string;
  formattedAddress: string;

  constructor(query?: PlaceResult) {
    this.formattedAddress = query ? query.formatted_address : '';

    Object.assign(this, query);
  }

  isComplete() {
    return (
      !!this.country &&
      !!this.codeCountry &&
      !!this.city &&
      !!this.streetName &&
      !!this.sector
    );
  }

  debugComplete() {}
}
