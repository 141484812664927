<button
  *ngIf="this.loginLink"
  [disabled]="this.isLoading"
  class="roma-button"
  (click)="gotoLink()"
>
  <img
    *ngIf="!isLoading"
    src="/assets/img/icons/arrow-right.svg"
    alt="Iniciar sesión"
  />
  <!-- acct_1NXbYtQ2oTBBkpOu -->
  <span class="mx-2">Login en Stripe</span>

  <mat-progress-spinner
    *ngIf="this.isLoading"
    class="loading-spinner"
    mode="indeterminate"
    [diameter]="20"
  ></mat-progress-spinner>
</button>
