import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CreateConnectedAccountService {
  constructor(private http: HttpClient) {}

  activatePayments(connectedAccountExists = false) {
    if (connectedAccountExists) {
      return this.http.post(':API_URL/payments/generate-onboarding', {});
    } else {
      return this.http.post(':API_URL/payments/create-connected-account', {});
    }
  }
}
