import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DealStatusColors } from '../../deals/deal-status-manager/deal-status-manager-.const';

@Component({
  selector: 'roma-rm-badge',
  templateUrl: './rm-badge.component.html',
  styleUrls: ['./rm-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmBadgeComponent implements OnInit {
  @Input()
  statusName?: string;

  @Input()
  ellipsis = false;

  @Input()
  set color(value: string) {
    this._color = value;
    this.resolveSelectedColor();
  }
  get color() {
    return this._color;
  }

  colors = DealStatusColors;

  selectedColor!: { lightColor: string; darkColor: string };

  private _color: string;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  private resolveSelectedColor(): void {
    const colors = DealStatusColors;

    const selectedDefaultColor = () => {
      this.selectedColor = colors[0];
    };

    if (!this.color) {
      selectedDefaultColor();
    }

    const color = colors.find(
      (color) => color.lightColor.toLowerCase() === this.color?.toLowerCase()
    );

    if (color) {
      this.selectedColor = color;
    } else {
      selectedDefaultColor();
    }

    this.draw();
  }

  private draw() {
    this.cdRef.detectChanges();
  }
}
