import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';

import {
  DynamicPropertyRef,
  DynamicPropertyType,
  Feature,
  FeatureUser,
  ICustomProperty,
  TypeRol,
} from '@tacliatech/types';
import {
  AuthService,
  DynamicPropertyService,
  StorageService,
} from '@web-frontend/shared/services';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EditableFieldComponent } from '../editable-field';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { User } from '@web-frontend/shared/class';
import { Router } from '@angular/router';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import AmplitudeEvents from 'src/types/amplitude.enum';

class customProperty {
  _id: string;
  name: string;
  value: any;
  type: string;
}

@Component({
  selector: 'roma-edit-custom-properties',
  templateUrl: './edit-custom-properties.component.html',
  styleUrls: ['./edit-custom-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCustomPropertiesComponent implements OnInit, OnDestroy {
  @ViewChildren(EditableFieldComponent)
  EditableFieldComponent: QueryList<EditableFieldComponent>;

  @Input()
  smSize = false;

  @Input()
  customProperties: customProperty[] = [];

  @Input()
  rowLayout = false;

  @Input()
  idSchema: string;

  @Input()
  typeRef: DynamicPropertyRef;

  @Input()
  feature: Feature.Purchasable = Feature.SystemPermission.DefaultAllow;

  @Input()
  featureUser: Feature.Purchasable | FeatureUser.Purchasable =
    Feature.SystemPermission.DefaultAllow;

  @Input()
  options: { showAlertMessage: boolean } = { showAlertMessage: true };

  @Input()
  showCustomProperties = true;

  @Input()
  updateOnEdit = true;

  @Input()
  parentForm?: FormGroup;

  @Output()
  changeSuccess = new EventEmitter<unknown>();

  @Output()
  propertiesRequired = new EventEmitter();

  @Output()
  propertiesLoading = new EventEmitter();

  @Output()
  updateLoading = new EventEmitter();

  @Output()
  onValueChanged = new EventEmitter();

  @Output()
  closeModal = new EventEmitter();

  @Input()
  showPropertiesMessage = false;

  isLoading = true;
  isAvailable: any = [];
  dynamicProperties: ICustomProperty[] = [];
  dynamicPropertyTypeRef = DynamicPropertyType;
  featureRef = Feature;
  featureRefUser = FeatureUser;
  user: User;
  roles = TypeRol;

  private sub$ = new Subscription();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dynamicPropertyService: DynamicPropertyService,
    private i18n: TranslateService,
    private amplitudeService: AmplitudeService,
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService
  ) {
    this.setActiveUser();
  }

  featureClass = 'wrapper-container';
  ngOnInit() {
    this.requestDynamicProperties();
    if (this.feature !== Feature.SystemPermission.DefaultAllow) {
      this.featureClass += ' wrapper-pro-feature-none ';
    }
    if (this.featureUser !== Feature.SystemPermission.DefaultAllow) {
      this.featureClass += ' wrapper-pro-feature-user';
    }
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  private requestDynamicProperties() {
    this.isLoading = true;
    this.propertiesLoading.next(true);
    this.draw();

    this.sub$.add(
      this.dynamicPropertyService
        .findDynamicProperties(StorageService.CompanyId, this.typeRef)
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.propertiesLoading.next(false);
            this.draw();
          })
        )
        .subscribe((res) => {
          this.dynamicProperties = res;
          this.draw();
        })
    );
  }

  setActiveUser() {
    this.authService.user$.subscribe((res: User) => {
      this.user = res;
    });
  }

  valueCustomProperty(propertyId: string) {
    if (this.customProperties?.length === undefined) {
      return null;
    }

    const currentProperty = this.customProperties?.find(
      (property) => property._id === propertyId
    );

    if (this?.customProperties && currentProperty != null) {
      return Array.isArray(currentProperty?.value) &&
        currentProperty?.value?.includes(undefined || 'undefined')
        ? null
        : currentProperty.value;
    }

    return null;
  }

  typeCustomProperty(type: DynamicPropertyType) {
    if (type === DynamicPropertyType.String) {
      return 'text';
    } else if (type === DynamicPropertyType.Select) {
      return 'array-string-multiple';
    }

    return type?.toLowerCase();
  }

  changeProperty() {
    this.changeSuccess.emit();
  }

  eventValueChanged(property: ICustomProperty, evt) {
    const { name, type, _id } = property;
    const propertyIndex = this.customProperties?.findIndex(
      (element) => element._id === property._id
    );

    if (!this.customProperties) {
      this.customProperties = [];
    }

    const propertyValues = {
      _id,
      name,
      value: evt,
      type,
    };

    if (propertyIndex < 0) {
      this.customProperties.push(propertyValues);
    } else {
      this.customProperties[propertyIndex] = propertyValues;
    }

    this.onValueChanged.emit(this.customProperties);
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  checkInvalidField(value) {
    this.propertiesRequired.next(value);
  }

  isAvailableFeature(isAvailable: boolean) {
    this.isAvailable.push({ isAvailable: isAvailable });
  }

  isLoadingUpdate(evt) {
    this.updateLoading.next(evt);
  }

  goTo(url: string, params: any) {
    let event = {
      event: AmplitudeEvents.deal_card_customField,
    };

    if (this.typeRef === 'FINAL') {
      event = {
        event: AmplitudeEvents.final_card_CustomFields,
      };
    }

    this.amplitudeService.sendEvent(event);

    if (this.user.role.includes(this.roles.ADMIN_ROLE)) {
      localStorage.setItem('customView', 'CUSTOM_FIELDS');
      this.router.navigate([url], {
        queryParams: params,
      });
      this.closeModal.next();
    } else {
      this.alertService.error(this.i18n.instant('general.adminRole'));
    }
  }
}
