/* eslint-disable @typescript-eslint/member-ordering */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { BudgetPreferences, IBudgetsSetting } from '@tacliatech/types';
import { BudgetService } from '@web-frontend/shared/services/budgets';

import { Subscription } from 'rxjs';
import { BudgetCalculationsService } from '../../budget-calculations.service';
import { TypeOfCRUD } from '@web-frontend/shared/enums/crud.enum';
import { BudgetUtilisService } from '../../budget-util.service';

@Component({
  selector: 'roma-total-footer',
  templateUrl: './budget-total.component.html',
  styleUrls: ['./budget-total.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetTotalComponent implements OnInit {
  /*   INPUTS
 ..............................................*/

  @Input()
  settings: IBudgetsSetting;
  preferences = new BudgetPreferences();

  @Input()
  form: FormGroup;
  budgetCategories: AbstractControl;

  @Input()
  mode: 'ADD' | 'EDIT' = 'ADD';

  @HostListener('window:resize', [])
  private onResize() {
    this.detectScreenSize();
  }

  /*   PROPERTIES
 ..............................................*/

  private sub$ = new Subscription();

  mobile = false;

  totals: {
    rEt: number;
    rEq: number;
    ivas: number;
    subTotal: number;
    total: number;
    discount: number;
  };

  zones = this.budgetService.findAllZones();
  currencys = this.budgetService.findAllCurrencys();
  numbers_format = this.budgetService.findAllNumberFormats();
  decimals = this.budgetService.findAllDecimals();
  dates_formats = this.budgetService.findAllDateFormats();
  langs = this.budgetService.findAllLangs();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private budgetService: BudgetService,
    private budgetCalculationsService: BudgetCalculationsService,
    private budgetUtilService: BudgetUtilisService
  ) {}

  set format_decimals(num) {
    num;
  }
  get format_decimals() {
    const format = this.numbers_format.find(
      (format) => format.id === this.preferences?.number_format
    );
    return format;
  }

  get currencySimbol() {
    const format = this.currencys.find(
      (format) => format.id === this.preferences?.currency
    );
    return format?.value;
  }

  ngOnInit(): void {
    this.preferences = this.settings
      ? this.settings.budgetPreferences
      : this.preferences;

    if (this.mode === TypeOfCRUD.UPDATE) {
      this.budgetCategories = this.form.controls.budgetCategories;
      const items = this.budgetCategories.value.flat();
      this.totals = this.budgetCalculationsService.calculateTotalsRealTime(
        items,
        this.settings
      );
    } else {
      if (this.form.controls.budgetCategories) {
        this.budgetCategories = this.form.controls.budgetCategories;
      }
      if (this.budgetCategories.value.flat()) {
        this.totals = this.budgetCalculationsService.calculateTotalsRealTime(
          this.budgetCategories.value.flat(),
          this.settings
        );
      }
    }
    this.draw();
    this.detectScreenSize();
    this.watchRefreshItems();
  }

  detectScreenSize(): void {
    this.mobile = window.screen.width < 768;
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  formatCustomNumber(paramNumber: number): string {
    const formattedNumber = this.budgetUtilService.formatNumber(
      this.settings,
      paramNumber
    );
    return formattedNumber;
  }

  private watchRefreshItems() {
    this.sub$.add(
      this.form.controls['budgetCategories'].valueChanges.subscribe((items) => {
        this.totals = this.budgetCalculationsService.calculateTotalsRealTime(
          items.flat(),
          this.settings
        );
        this.draw();
      })
    );
  }

  calculateItemDiscount($item) {
    let total = $item.quantity * $item.price;

    if ($item?.discount && $item?.discount !== null) {
      total = $item.quantity * $item.price * (1 - $item?.discount / 100);
    }
    return total;
  }
}
