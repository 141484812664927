import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PaymentInfoBudget } from '@tacliatech/types';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';

import { translatePaymentsName } from '@web-frontend/shared/helpers/translations/translate-util';
import { StorageService } from '@web-frontend/shared/services';
import { BudgetService } from '@web-frontend/shared/services/budgets';

import { Subscription } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';
import { CreateBudgetPaymentMethodService } from '../create-create-budget-payment';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import AmplitudeEvents from 'src/types/amplitude.enum';

@Component({
  selector: 'roma-budget-payment-method',
  templateUrl: './budget-payment-method.component.html',
  styleUrls: ['./budget-payment-method.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetPaymentMethodComponent implements OnInit, OnDestroy {
  @Input() list: PaymentInfoBudget[] = [];
  @Input() paymentsMethods = [];

  private sub$ = new Subscription();

  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private budgetService: BudgetService,
    private createBudgetPaymentMethodService: CreateBudgetPaymentMethodService,
    private toastService: ToastService,
    private i18n: TranslateService,
    private amplitude: AmplitudeService
  ) {
    // this.resolvePaymentsMethods();
  }

  form: FormGroup = this.fb.group({
    name: new FormControl(null, [Validators.required]),
    textShow: new FormControl(null, [Validators.required]),
    icon: new FormControl(null, [Validators.required]),
    idOwner: new FormControl(StorageService.CompanyId, [Validators.required]),
  });

  ngOnInit(): void {
    this.watchRefreshList();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  private watchRefreshList() {
    this.sub$.add(
      this.createBudgetPaymentMethodService.refreshList$
        .pipe(
          map((res) => !!res),
          filter((res) => res)
        )
        .subscribe(() => {
          this.resolvePaymentsMethods();
        })
    );
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  addMethod() {
    this.draw();
  }

  resolvePaymentsMethods() {
    // this.budgetService.findAllPaymentsMethods(StorageService.CompanyId)
    this.sub$.add(
      this.budgetService
        .findAllPaymentMethodsByCompany_SELECT()
        .pipe(
          finalize(() => {
            this.draw();
          })
        )
        .subscribe((data: PaymentInfoBudget[]) => {
          // this.paymentsMethods = data;
          this.list = data;
        })
    );
  }

  createPayment($event) {
    this.amplitude.sendEvent({
      event: AmplitudeEvents.settingsAddPayMethodStart,
    });
    this.sub$.add(this.createBudgetPaymentMethodService.open().subscribe());
  }

  translateName(item: string) {
    if (item) {
      const result = translatePaymentsName(item);
      return result;
    }
    return '';
  }

  deletePayment(payment: PaymentInfoBudget) {
    this.sub$.add(
      this.budgetService.deletaPaymentMethod(payment._id).subscribe((data) => {
        this.resolvePaymentsMethods();
        this.toastService.show({
          text: this.i18n.instant('budgets.settings.successDeleted'),
          type: 'success',
          msDuration: 4000,
        });
        this.draw();
      })
    );
  }

  editPayment(payment: PaymentInfoBudget) {
    this.sub$.add(
      this.createBudgetPaymentMethodService
        .open({ data: { type: 'EDIT', data: payment } })
        .subscribe()
    );
  }
}
