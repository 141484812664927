import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'roma-modal-template',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalTemplateComponent implements OnInit {
  @Input()
  size: 'lg' | 'md' | 'sm' | 'mobile-50h' | 'mobile-70h';

  @Input()
  headerBottomMargin = '20px';

  @Input()
  title = '';

  @Input()
  subtitle = '';

  @Input()
  disableSuccess: boolean;

  @Input()
  askBeforeClose = false;

  @Input()
  responsiveFull = true;

  @Input()
  showDefaultButtons = true;

  @Input()
  textSuccess = this.i18n.instant('profile.submit');

  @Input()
  textCancel = this.i18n.instant('profile.cancel');

  @Input()
  textSecondary = '';

  @Input()
  contentMinHeight = true;

  @Input()
  showCancelBtn = true;

  @Input()
  showSecondaryBtn = false;

  @Input()
  showSuccessBtn = true;

  @Input()
  showCloseBtn = true;

  @Input()
  showHeader = true;

  @Input()
  disableSecondary = false;

  @Input()
  disableCancel = false;

  @Input()
  footerTemplate: TemplateRef<any>;

  @Input()
  isLoading = false;

  @Input()
  type = '';

  @Input()
  showInfo = false;

  @Input()
  BtnSucces = '';

  @Input()
  actionsAlign: 'flex-end' | 'flex-start' | 'center' = 'flex-end';

  @Input()
  back = false;

  @Input()
  customStyles: { [klass: string]: any } = null;

  @Output()
  info = new EventEmitter();

  @Output()
  cancel = new EventEmitter();

  @Output()
  secondary = new EventEmitter();

  @Output()
  edit = new EventEmitter();

  @Output()
  success = new EventEmitter();

  constructor(private i18n: TranslateService) {}

  disableSubmit = false;

  ngOnInit(): void {
    this.scrollToTop();
  }

  scrollToTop() {
    window.scroll(0, 0);
  }

  abort() {
    this.cancel.emit();
  }

  onSecondaryClick() {
    this.secondary.emit();
  }

  successEvt() {
    this.success.emit();
  }

  infoEvt() {
    this.info.emit();
  }
}
