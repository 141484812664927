import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

import { from, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { FeatureService } from '@web-frontend/shared/services';

export class EmailCustomValidator {
  static checkIfIsRepeat(
    otherService: FeatureService,
    emailToIgnore: string[] = []
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (!control.valueChanges || control.pristine) {
        return of(null);
      }

      if (emailToIgnore.includes(control.value)) {
        return of(null);
      }

      return otherService.checkIfEmailIsRepeat(control.value).pipe(
        catchError(() => of(false)),
        map((response) => {
          return !response ? null : { invalidAsync: true };
        })
      );
    };
  }

  static checkEmailDomain(
    otherService: FeatureService,
    emailToIgnore: string[] = []
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (!control.valueChanges || control.pristine) {
        return of(null);
      }

      if (emailToIgnore.includes(control.value)) {
        return of(null);
      }

      return from(otherService.isValidDomain(control.value)).pipe(
        catchError(() => of(false)),
        map((isValid) => {
          return isValid ? null : { invalidDomain: true };
        })
      );
    };
  }

  static validate(): ValidatorFn {
    return (control: AbstractControl) => {
      const { value } = control;

      if (value) {
        const regex = new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]/);

        return !regex.test(value) ? { emailValidation: true } : null;
      }

      return null;
    };
  }
}
