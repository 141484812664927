export const AuthBusinessErrors = {
  WrongCredentials: {
    apiErrorCode: 'AUTH_0001_0001',
    errorMessage: 'Wrong credentials provided',
    reason: `Wrong credentials provided`,
  },

  RoleExpeption: {
    apiErrorCode: 'AUTH_0001_0002',
    errorMessage: 'Role not authorized to perform this action',
    reason: `insufficient permissions`,
  },

  UserEmpty: {
    apiErrorCode: 'AUTH_0001_0003',
    errorMessage: 'User is empty in request',
    reason: `User empty`,
  },

  PermissionDenied: {
    apiErrorCode: 'AUTH_0001_0004',
    errorMessage: 'Permission denied to perfmon this operation',
    reason: `Permission denied`,
  },

  CompanyDisabled: {
    apiErrorCode: 'AUTH_0001_0005',
    errorMessage: 'This company is disabled',
    reason: `Company disabled`,
  },
};
