import {
  ClockAbsence,
  Deal,
  DealType,
  Final,
  ICustomProperty,
  IStatus,
  IUser,
  Product,
  Project,
  RecurrenceGlobal,
} from '@tacliatech/types';

import {
  addDays,
  addMonths,
  addWeeks,
  differenceInDays,
  endOfYear,
  format,
  isAfter,
  isWithinRange,
  startOfWeek,
  startOfYear,
} from 'date-fns';

import { v4 as uuidv4 } from 'uuid';

export namespace CalendarApi {
  export interface Response {
    deals: CalendarApi.ApiDeal[];
    activities: any;
    recurrences: CalendarApi.DealOutput[];
    absences?: ClockAbsence.Output[];
  }

  export interface ResponseExtended extends CalendarApi.Response {
    recurrences: CalendarApi.DealOutputExtended[];
  }

  export function GenerateGhostDeals(
    initialDate: Date,
    endDate: Date,
    res: CalendarApi.ResponseExtended
  ): {
    recurrences: CalendarApi.GhostDeal[];
    deals: ApiDeal[];
    activities: [];
  } {
    return {
      ...res,
      // recurrences: [],
      recurrences: GenerateRecurrences(initialDate, endDate, res.recurrences),
    };
  }

  export interface ApiDeal {
    _id: string;
    name: string;
    initDate: string;
    endDate: string;
    status: {
      color: string;
    };
    final: {
      _id: string;
      name: string;
    };
    recurrenceExceptions: {
      type: Deal.TypeException;
      id: string;
      date: string;
    }[];
  }
  export interface DealWithRecurrence extends CalendarApi.ApiDeal {
    recurrence: RecurrenceGlobal.Storage;
    recurrenceEnd: {
      type: Deal.TypeEndRecurrence;
      date?: Date;
      amount?: number;
    };
  }

  export interface GhostDeal extends CalendarApi.DealOutputExtended {
    isGhostDeal: boolean;
    id: string;
    // initDateStr: string; // format DD/MM/YYYYTHH:MM
  }

  export interface DealOutput {
    name: string;
    status: IStatus;
    customer: string;
    type: DealType;
    createdBy: string;
    createdByObj: IUser;

    company?: {
      _id: string;
      name: string;
    };

    initDate?: string;
    finishDate?: Date;

    recurrence?: RecurrenceGlobal.Storage;
    recurrenceHistory?: RecurrenceGlobal.History;
    recurrenceEnd?: {
      type: Deal.TypeEndRecurrence;
      date?: Date;
      amount?: number;
    };
    recurrenceExceptions: {
      type: Deal.TypeException;
      id: string;
      date: string;
    }[];

    description?: string;
    dateCreated?: Date;
    address?: string;
    address2?: string;
    latitude?: string;
    longitude?: string;
    times?: Array<string>;
    imgs?: Array<string>;
    stage?: string;
    contactName?: string;
    contactPhone?: string;
    contactEmail?: string;
    comment?: string;
    cancellationReason?: any;
    jobDescription?: string;

    budget?: Deal.Budget;
    bill?: Deal.Budget;

    updatedBy?: string;
    isUpdated?: boolean;
    updatedAt?: Date;
    deleted?: boolean;
    cancelled?: boolean;
    urgency?: boolean;
    customProperties?: ICustomProperty;
    members?: Array<string>;

    asset?: string;
    assetObj?: {
      _id: string;
      name: string;
      createdBy: string;
      customer_id: string;
      deleted: boolean;
    };

    billingProfile?: string;

    product_id?: string;
    product?: Product.Schema;

    reporter?: string;
    reporterObj?: IUser;
    owner?: string;
    partialTime?: {
      duration: number;
      parserTime: string;
    };
    auctioneers?: string[];
    idEquipments?: string[];

    idFinal?: string;
    final?: Final.Schema;
    idProject?: string;
    project?: Project.Schema;
    idBudget?: string;
    idProform?: string;
    idBill?: string;
    idGroup?: string;
    taskerTeam?: IUser[];
    notes?: any[];
    _id?: string;
    internalVendorObj?: {
      _id: string;
      name?: string;
      fiscalName?: string;
    };
  }

  export interface DealOutputExtended extends CalendarApi.DealOutput {
    initDateStr: string; // <---- format  DD/MM/YYYYTHH:MM
    endDate: Date;
    hidden?: boolean;
  }

  export enum DealEventDeleteType {
    ThisEvent = 'THIS_EVENT',
    ThisEventAndSubsequent = 'THIS_EVENT_AND_SUBSEQUENT',
  }
}

export function GenerateRecurrences(
  initialDate: Date,
  endDate: Date,
  deals: CalendarApi.DealOutputExtended[]
): CalendarApi.GhostDeal[] {
  let generated = [];

  for (const deal of deals) {
    const transformedDeals = wrapperByTypeDeal(initialDate, endDate, deal).map(
      (res) => {
        return {
          ...res,
        };
      }
    );

    // @ts-ignore
    generated = [...generated, ...transformedDeals];
  }

  return generated;
}

export function wrapperByTypeDeal(
  initialDate: Date,
  endDate: Date,
  deal: CalendarApi.DealOutputExtended
): CalendarApi.GhostDeal[] {
  // @ts-ignore
  if (deal.recurrenceEnd.type === Deal.TypeEndRecurrence.Indefinitely) {
    return transformDealIndefinitelyRecurrence(initialDate, endDate, deal);
  }

  // @ts-ignore
  if (deal.recurrenceEnd.type === Deal.TypeEndRecurrence.SpecificDay) {
    return transformDealIndefinitelyRecurrence(
      initialDate,
      // @ts-ignore
      deal.recurrenceEnd.date,
      deal
    );
  }

  // @ts-ignore
  if (deal.recurrenceEnd.type === Deal.TypeEndRecurrence.SpecificAmount) {
    // @ts-ignore
    const init = startOfYear(deal.initDate);
    const end = endOfYear(endDate);

    return transformDealBySpecificAmount(init, end, deal);
  }

  return [];
}

export function transformDealIndefinitelyRecurrence(
  initialDate: Date,
  endDate: Date,
  deal: CalendarApi.DealOutputExtended
): CalendarApi.GhostDeal[] {
  let deals: CalendarApi.GhostDeal[] = [];

  const initialFields = {
    jobDescription: '',
    notes: [],
    operationNotes: [],
    partialTime: null,
    cancelled: false,
    cancellationReason: null,
  };

  if (!deal?.hidden) {
    // append original deal
    deals.push({
      ...deal,
      isGhostDeal: false,
      // @ts-ignore
      id: null,
    });
  }

  // @ts-ignore
  if (['MONTH', 'DAY', 'YEAR'].includes(deal?.recurrence?.periodicity)) {
    // @ts-ignore
    let initDate = new Date(deal.initDate);
    const copyDeals: CalendarApi.GhostDeal[] = [];

    while (differenceInDays(endDate, initDate) >= 0) {
      const daysAdded = generateDayAdded(
        // @ts-ignore
        deal?.recurrence?.periodicity,
        initDate,
        // @ts-ignore
        deal.recurrence.repeatEach
      );

      // @ts-ignore
      const copyDeal: CalendarApi.GhostDeal = {
        ...deal,
        id: uuidv4(),
        ...initialFields,
        ...generateInitDateCloneDeal(daysAdded),
        isGhostDeal: true,
      };

      // @ts-ignore
      const date = new Date(copyDeal.initDate);
      const day = date.getDate();

      const endDate = new Date(copyDeal.endDate);
      endDate.setDate(day);

      copyDeal.endDate = endDate;

      initDate = daysAdded;

      const exceptionsByCreateDeal = deal.recurrenceExceptions
        .filter(
          (exception) => exception.type === Deal.TypeException.ByCreateDeal
        )
        .map((exception) => exception.date);

      const exceptionsByDeleteCurrentDeal = deal.recurrenceExceptions
        .filter(
          (exception) =>
            exception.type === Deal.TypeException.ByDeleteDealCurrent
        )
        .map((exception) => exception.date);

      const exceptionsByDeleteSubsequentDeal = deal.recurrenceExceptions
        .filter(
          (exception) =>
            exception.type === Deal.TypeException.ByDeleteDealSubsequent
        )
        .map((exception) => exception.date);

      const isWithinException = [
        ...exceptionsByCreateDeal,
        ...exceptionsByDeleteCurrentDeal,
      ].includes(copyDeal.initDateStr);

      const isWithinRangeB = isWithinRange(
        // @ts-ignore
        copyDeal.initDate,
        initialDate,
        endDate
      );

      const isAfterSubsequentException =
        exceptionsByDeleteSubsequentDeal
          .map((exception) => {
            const [day, month, year] = exception.split('T')[0].split('/');

            return `${year}-${month}-${day}T00:00`;
          })
          .filter((exception) => {
            // @ts-ignore
            return isAfter(copyDeal.initDate, exception);
          }).length > 0;

      if (!isWithinException && isWithinRangeB && !isAfterSubsequentException) {
        copyDeals.push(copyDeal);
      }
    }

    deals = [...deals, ...copyDeals];
  }

  if (deal?.recurrence?.periodicity === 'WEEK') {
    let initDate = deal.initDate;
    const endDateHour = deal.endDate;
    let dealsToReferenceDate = deals;
    let copyDeals: CalendarApi.GhostDeal[] = [];

    // @ts-ignore
    while (differenceInDays(endDate, initDate) >= 0) {
      const lastDeal = dealsToReferenceDate[dealsToReferenceDate.length - 1];
      const weekHandle = handleWeekDate(lastDeal);

      initDate = weekHandle[weekHandle.length - 1].initDate;

      for (const weekCloned of weekHandle) {
        copyDeals = [...copyDeals, weekCloned];
        dealsToReferenceDate = [...dealsToReferenceDate, weekCloned];
      }
    }

    for (const copyDeal of copyDeals) {
      const exceptionsDate = deal.recurrenceExceptions.map(
        (exception) => exception.date
      );

      // const isWithinException = exceptionsDate.includes(copyDeal.initDateStr);

      // const isWithinRangeB = isWithinRange(
      //   copyDeal.initDate,
      //   initialDate,
      //   endDate
      // );

      // if (!isWithinException && isWithinRangeB) {
      //   deals = [...deals, copyDeal];
      // }

      const exceptionsByCreateDeal = deal.recurrenceExceptions
        .filter(
          (exception) => exception.type === Deal.TypeException.ByCreateDeal
        )
        .map((exception) => exception.date);

      const exceptionsByDeleteCurrentDeal = deal.recurrenceExceptions
        .filter(
          (exception) =>
            exception.type === Deal.TypeException.ByDeleteDealCurrent
        )
        .map((exception) => exception.date);

      const exceptionsByDeleteSubsequentDeal = deal.recurrenceExceptions
        .filter(
          (exception) =>
            exception.type === Deal.TypeException.ByDeleteDealSubsequent
        )
        .map((exception) => exception.date);

      const isWithinException = [
        ...exceptionsByCreateDeal,
        ...exceptionsByDeleteCurrentDeal,
      ].includes(copyDeal.initDateStr);

      const isWithinRangeB = isWithinRange(
        // @ts-ignore
        copyDeal.initDate,
        initialDate,
        endDate
      );

      const isAfterSubsequentException =
        exceptionsByDeleteSubsequentDeal
          .map((exception) => {
            const [day, month, year] = exception.split('T')[0].split('/');

            return `${year}-${month}-${day}T00:00`;
          })
          .filter((exception) => {
            // @ts-ignore
            return isAfter(copyDeal.initDate, exception);
          }).length > 0;

      if (!isWithinException && isWithinRangeB && !isAfterSubsequentException) {
        deals = [...deals, copyDeal];
      }
    }
  }

  return deals;
}

function generateDayAdded(
  type: RecurrenceGlobal.Periodicity,
  initDate: Date,
  repeatEach: number
) {
  if (type === 'DAY') {
    return addDays(initDate, repeatEach);
  }

  if (type === 'MONTH') {
    return addMonths(initDate, repeatEach);
  }

  if (type === 'YEAR') {
    return addDays(initDate, 366);
  }

  return initDate;
}

function generateInitDateCloneDeal(date: Date) {
  return {
    initDate: date.toISOString().replaceAll('Z', ''),
    initDateStr: transformDateToString(date.toISOString()),
  };
}

export function handleWeekDate(
  deal: CalendarApi.DealOutputExtended
): CalendarApi.GhostDeal[] {
  let dealsRelease: CalendarApi.GhostDeal[] = [];
  // 19/04/2023 -> 26/04/23
  // @ts-ignore
  const projectWeekDate = addWeeks(deal.initDate, deal.recurrence.repeatEach);
  // 26/04/23 -> 23/04/23
  const initWeek = startOfWeek(projectWeekDate);

  const transform = initWeek
    .toLocaleString('en-GB')
    .split(',')[0]
    .replaceAll('/', '-')
    .split('-')
    .reverse()
    .join('-');

  const transformInitWeek =
    transform + 'T' + projectWeekDate.toISOString().split('T')[1];

  // @ts-ignore
  for (let i = 0; i < deal.recurrence.dayToRepeat.length; i++) {
    // @ts-ignore
    const date = addDays(transformInitWeek, deal.recurrence.dayToRepeat[i]);

    const clone: CalendarApi.GhostDeal = {
      ...deal,
      id: uuidv4(),
      ...generateInitDateCloneDeal(date),
      isGhostDeal: i === 0 ? false : true,
    };

    const exceptionsDate = deal.recurrenceExceptions.map(
      (exception) => exception.date
    );

    if (!exceptionsDate.includes(clone.initDateStr)) {
      dealsRelease = [...dealsRelease, clone];
    }
  }

  return dealsRelease;
}

export function transformDealBySpecificAmount(
  initialDate: Date,
  endDate: Date,
  deal: CalendarApi.DealOutputExtended
): CalendarApi.GhostDeal[] {
  const deals: CalendarApi.GhostDeal[] = [];

  const [originalDeal, ...dealsGenerated] = transformDealIndefinitelyRecurrence(
    initialDate,
    endDate,
    deal
  );

  deals.push(originalDeal);

  for (let i = 0; i < dealsGenerated.length; i++) {
    // @ts-ignore
    if (i < deal.recurrenceEnd.amount) {
      deals.push(dealsGenerated[i]);
    }
  }

  return deals;
}

export function contentDateException(
  exceptions: string[],
  copyDeal: CalendarApi.GhostDeal
): boolean {
  const getHour = (dateString: string) => {
    const [date, time] = dateString.split('T');
    const clear = time.replaceAll('Z', '').replaceAll('z', '');
    const [hour, minutes] = clear.split(':');

    return `${hour}:${minutes}`;
  };

  const hasExceptionDay = exceptions.find((exception) => {
    return (
      // @ts-ignore
      format(exception.replaceAll('Z', ''), 'DD/MM/YYYY') ===
        // @ts-ignore
        format(copyDeal.initDate.replaceAll('Z', ''), 'DD/MM/YYYY') &&
      // @ts-ignore
      getHour(copyDeal.initDate) === getHour(exception)
    );
  });

  //console.log(hasExceptionDay);

  return Boolean(hasExceptionDay);
}

export function transformDateToString(transform: string) {
  // remove timezone
  const initDate = transform.replaceAll('Z', '');
  // split date, position 0 = 2023-01-01 and
  // position 1 = time 08:00:000
  const [date, time] = initDate.split('T');
  // split minutes position 0 = 08 and
  // position 1 = 00
  const [hour, minutes] = time.split(':');
  // transform to format DD/MM/YYYY in localeDateString
  const result = new Date(initDate).toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  return `${result}T${hour}:${minutes}`;
}

export const getDateArray = function (start: Date, end: Date) {
  const arr = [];
  const dt = new Date(start);

  while (dt <= end) {
    // @ts-ignore
    arr.push(new Date(dt));
    dt.setDate(dt.getDate() + 1);
  }

  return arr;
};
