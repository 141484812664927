import { PaginateSort } from '@tacliatech/types';

export const INIT_RATES_SEARCH_PARAMS = {
  limit: 50,
  page: 1,
  sort: PaginateSort.DESC,
  applyPaginate: true,
  keyword: null,
  company_id: null,
  owner: null,
  author: null,
  [`sortOptions[created_at]`]: PaginateSort.DESC,
  deal: null,
  takeDeleted: false,
  takeToday: false,
  customProperties: null,
  'authors[]': [],
  categories: [],
};
