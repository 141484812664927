import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ChooseColorComponent } from './choose-color.component';
import { ChooseModalComponent } from './choose-modal/choose-modal.component';

@NgModule({
  declarations: [ChooseColorComponent, ChooseModalComponent],
  imports: [CommonModule, MatBottomSheetModule],
  exports: [ChooseColorComponent, ChooseModalComponent],
})
export class ChooseColorModule {}
