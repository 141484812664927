import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { ModalChoose } from './modal-choose.type';
import { ModalChooseComponent } from './modal-choose.component';

@Injectable({
  providedIn: 'root',
})
export class ModalChooseService {
  constructor(private dialog: MatDialog) {}

  open(params: ModalChoose.Params) {
    const modalConfig = new MatDialogConfig();
    modalConfig.data = params;

    const dialogRef = this.dialog.open(ModalChooseComponent, modalConfig);

    return dialogRef.afterClosed().pipe(
      map((res) => {
        const modalResponse: ModalChoose.Response = res;

        return modalResponse;
      })
    );
  }
}
