import { formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  IBudgetsSetting,
  IItemBudget,
  TypeTax,
  numbers_format,
} from '@tacliatech/types';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BudgetCalculationsService {
  calculateTotalsRealTime(
    itemsParam: IItemBudget[],
    settings: IBudgetsSetting
  ) {
    let subTotal = 0;
    let rEq = 0;
    let rEt = 0;
    let ivas = 0;
    let total = 0;
    let discount = 0;

    itemsParam.forEach((item) => {
      if (item.price > 0) {
        const subTotalItem = item.quantity * item.price;
        const itemDiscount =
          item?.discount > 0 || item?.discount != undefined
            ? (item.quantity * item.price * item?.discount) / 100
            : 0;
        subTotal += subTotalItem - itemDiscount;
        discount += itemDiscount;

        if (item?.taxes?.length > 0) {
          item?.taxes.forEach((tax) => {
            if (tax !== null) {
              const value = ((subTotalItem - itemDiscount) * tax.value) / 100;
              if (tax.type === TypeTax.IVA) {
                ivas += value;
              }

              if (tax.type === TypeTax.REC) {
                rEq += value;
              }

              if (tax.type === TypeTax.RET) {
                rEt += value;
              }
            }
          });
        }
      }
    });
    total += subTotal + ivas + rEq - rEt;
    if (settings) {
      total = this.parseNumberBySetting(settings, total);
      subTotal = this.parseNumberBySetting(settings, subTotal);
      ivas = this.parseNumberBySetting(settings, ivas);
      rEq = this.parseNumberBySetting(settings, rEq);
      rEt = this.parseNumberBySetting(settings, rEt);
      discount = this.parseNumberBySetting(settings, discount);
    }
    const totalTaxes = ivas + rEq - rEt;
    return { rEt, rEq, ivas, subTotal, total, discount, totalTaxes };
  }

  parseNumberBySetting(settings: IBudgetsSetting, number: number): number {
    number = parseFloat(number.toFixed(settings?.budgetPreferences?.decimals));
    return number;
  }

  calculateRetentions(items) {
    let Aiva: any[] = [];
    let Aret: any[] = [];
    let Arec: any[] = [];
    items?.forEach((item) => {
      // @ts-ignore
      let totalItem = item.price * item.quantity;
      // @ts-ignore
      if (item.discount > 0) {
        // @ts-ignore
        totalItem = totalItem * (1 - item.discount / 100);
      }

      // @ts-ignore
      if (item.taxes?.length > 0) {
        // @ts-ignore
        item.taxes.forEach((tax) => {
          if (tax !== null) {
            // @ts-ignore
            const value = (totalItem * tax.value) / 100;
            if (tax.type === TypeTax.IVA) {
              Aiva = this.findKey(tax.value, value, Aiva);
            }

            if (tax.type === TypeTax.REC) {
              Arec = this.findKey(tax.value, value, Arec);
            }

            if (tax.type === TypeTax.RET) {
              Aret = this.findKey(tax.value, value, Aret);
            }
          }
        });
      }
    });
    return of({ Arec, Aret, Aiva });
  }

  findKey(key, value, object: any[]) {
    let found = false;
    for (const item of object) {
      if (item.key === key) {
        item.value = item.value + value;
        found = true;
        break;
      }
    }
    if (!found) {
      object.push({ key: key, value: value });
    }
    return object;
  }
}
