import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CreateSaleComponent } from './create-sale.component';

@Injectable({
  providedIn: 'root',
})
export class CreateSaleService {
  private refreshListSource = new Subject<boolean>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(config?: MatDialogConfig) {
    config.position = { right: '0px' };

    let disableClose = false;

    config.data?.mode === 'EDIT' || config.data?.mode === 'ADD'
      ? (disableClose = false)
      : (disableClose = false);

    const dialogRef = this.dialog.open(CreateSaleComponent, {
      ...config,
      autoFocus: false,
      disableClose: disableClose,
      width: '920px',
      panelClass: ['no-border-container', 'modal__v2', 'create-sale-modal'],
    });

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }
}
