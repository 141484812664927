import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BudgetService } from './budgets.service';


@Injectable({
  providedIn: 'root'
})
export class BudgetValidationService {
  constructor(private i18n: TranslateService, private budgetService: BudgetService) { }

  validateBDGData(showSnackbarMexico: boolean, dataSnackbar: any): { showSnackbarMexico: boolean, dataSnackbar: any } {
      const isCompanyValid = this.budgetService.validateBDGSettings();
      if (!isCompanyValid) {
        showSnackbarMexico = true;
        this.i18n
          .get([
            'budgets.error.warning_bdgsettings',
            'budgets.error.warning_bdg_link',
          ])
          .subscribe((res) => {
            dataSnackbar = {
              type: 'warning',
              message: res['budgets.error.warning_bdgsettings'],
              textLink: res['budgets.error.warning_bdg_link'],
              error: 'data',
            };
          });
      } else {
        showSnackbarMexico = false;
      }

    return { showSnackbarMexico, dataSnackbar };
  }
}
