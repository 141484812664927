import {
  NotificationEventType,
  NotificationSchema,
  NotificationSchemaMock,
} from './notification.types';
import { format } from 'date-fns';
import { Lang } from '../../enum';

export const SortByCreatedAtNotificationSchema = (
  notifications: NotificationSchema[]
): NotificationSchema[] => {
  return notifications.sort((a, b) =>
    new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
  );
};

export const GetNotificationMocks = ({
  notifications,
  language,
}: {
  notifications: NotificationSchema[];
  language: Lang;
}) => {
  return notifications
    .map((notification) => GetNotificationMock({ notification, language }))
    .filter((notification) =>
      Object.prototype.hasOwnProperty.call(notification, 'title')
    );
};

export const GetNotificationMock = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
}): NotificationSchemaMock => {
  const module = notification?.sector;

  if (module.length === 0 || module === undefined || !module) {
    module.push({ module: 'b' });
  }

  notification.sector = module;

  let titleNotification = { es: 'Servicio', en: 'Service' };

  //Translate copy for email or FCM push notification
  switch (module[0].module) {
    case 'a':
      titleNotification = { es: 'Trabajo', en: 'Jobs' };
      break;
    case 'b':
      titleNotification = { es: 'Cita', en: 'Appointments' };
      break;
    case 'c':
      titleNotification = { es: 'Cliente', en: 'Clients' };
      break;
    case 'd':
      titleNotification = { es: 'Pacientes', en: 'Rates' };
      break;
    case 'e':
      titleNotification = { es: 'Catálogo', en: 'Catalog' };
      break;
  }

  notification.customer.copyTrans = titleNotification;

  const types = {
    [NotificationEventType.NewActivityAssign]: newActivityAssign,
    [NotificationEventType.NewTaskAssign]: newTaskAssign,
    [NotificationEventType.BiddingAccepted]: biddingAccepted,
    [NotificationEventType.NewMessage]: newMessage,
    [NotificationEventType.NewBiddingReceived]: newBidding,
    [NotificationEventType.DealPendingApproval]: dealPending,
    [NotificationEventType.DealUpdatedStatus]: dealUpdateStatus,
    [NotificationEventType.DealCancelled]: dealCancelled,
    [NotificationEventType.ChangeInitDate]: changeInitDate,
    [NotificationEventType.ChangeAddress]: changeAddress,
    [NotificationEventType.ChangeInvoice]: changeInvoice,
    [NotificationEventType.AddNote]: addNote,
    [NotificationEventType.NewDealInZone]: newDealInZone,
    [NotificationEventType.DealAssigned]: dealAssigned,
    [NotificationEventType.ActivityUpdated]: activityUpdated,
    [NotificationEventType.RequestSubmitCurrentPosition]: requestSubmitCurrentPosition,
    [NotificationEventType.DealOwnerAssigned]: dealOwnerAssigned,
    [NotificationEventType.NewAExpenseAssign]: expenseOwnerAssigned,
    default: ({
      notification,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      language,
    }: {
      notification: NotificationSchemaMock;
      language: Lang;
      callback?: () => void;
    }) => {
      return { ...notification };
    },
  };

  return (types[notification.type] || types.default)({
    notification,
    language,
  });
};

const newActivityAssign = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
  callback?: () => void;
}): NotificationSchemaMock => {
  const info = {
    [Lang.En]: {
      title: `Activity assigned`,
      description: `Activity assigned ${notification.payload?.title}`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
    [Lang.Es]: {
      title: `Se te ha asignado la tarea`,
      description: `Se te ha asignado la tarea ${notification.payload?.title}`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
  };

  return {
    ...notification,
    ...info[language],
  };
};

const newTaskAssign = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
  callback?: () => void;
}): NotificationSchemaMock => {
  const info = {
    [Lang.En]: {
      title: 'New task',
      description: `New task ${notification.payload?.title}`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
    [Lang.Es]: {
      title: 'Nueva tarea',
      description: `Nueva tarea ${notification.payload?.title}`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
  };

  return {
    ...notification,
    ...info[language],
  };
};

const biddingAccepted = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
  callback?: () => void;
}): NotificationSchemaMock => {
  const total = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(notification?.payload?.total);

  const info = {
    [Lang.En]: {
      title: 'Your budget has been accepted',
      description: `Your budget of ${total} has been accepted`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
    [Lang.Es]: {
      title: 'Se ha aceptado tu presupuesto',
      description: `Se ha aceptado tu presupuesto de ${total}`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
  };

  return {
    ...notification,
    ...info[language],
  };
};

const newMessage = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
  callback?: () => void;
}): NotificationSchemaMock => {
  const info = {
    [Lang.En]: {
      title: 'New message recieved:',
      description: `${notification.payload?.text}`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
    [Lang.Es]: {
      title: 'Has recibido un nuevo mensaje:  ',
      description: `Mensaje: ${notification.payload?.text}`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
  };

  return {
    ...notification,
    ...info[language],
  };
};

const newBidding = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
  callback?: () => void;
}): NotificationSchemaMock => {
  const total = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(notification?.payload?.total);

  const info = {
    [Lang.En]: {
      title: 'New budget',
      description: `You have received a new budget for ${total}.`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
    [Lang.Es]: {
      title: 'Nuevo presupuesto',
      description: `Has recibido un nuevo presupuesto por ${total}.`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
  };

  return {
    ...notification,
    ...info[language],
  };
};

const dealPending = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
  callback?: () => void;
}): NotificationSchemaMock => {
  const info = generateTextNotification(notification, language).dealPending;

  return {
    ...notification,
    ...info,
  };
};

const dealUpdateStatus = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
  callback?: () => void;
}): NotificationSchemaMock => {
  const info = generateTextNotification(notification, language)
    .dealUpdateStatus;

  return {
    ...notification,
    ...info,
  };
};

const dealCancelled = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
  callback?: () => void;
}): NotificationSchemaMock => {
  const info = generateTextNotification(notification, language).dealCancelled;

  return {
    ...notification,
    ...info,
  };
};

const dealOwnerAssigned = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
  callback?: () => void;
}): NotificationSchemaMock => {
  const info = generateTextNotification(notification, language)
    .dealOwnerAssigned;

  return {
    ...notification,
    ...info,
  };
};

const expenseOwnerAssigned = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
  callback?: () => void;
}): NotificationSchemaMock => {
  const info = {
    [Lang.En]: {
      title: 'You have been assigned to the expense as responsible',
      description: `You have been assigned to expense ${
        notification?.payload?.name
          ? notification?.payload?.name.length <= 44
            ? notification?.payload?.name
            : notification?.payload?.name.slice(0, 45) + '...'
          : ''
      }`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
    [Lang.Es]: {
      title: 'Has sido asignado a un gasto como responsable',
      description: `Gasto asignado ${
        notification?.payload?.name
          ? notification?.payload?.name.length < 29
            ? notification?.payload?.name
            : notification?.payload?.name.slice(0, 45) + '...'
          : ''
      }`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
  };

  return {
    ...notification,
    ...info[language],
  };
};

const changeInitDate = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
  callback?: () => void;
}): NotificationSchemaMock => {
  const info = generateTextNotification(notification, language).changeInitDate;

  return {
    ...notification,
    ...info,
  };
};

const changeAddress = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
  callback?: () => void;
}): NotificationSchemaMock => {
  const info = generateTextNotification(notification, language).changeAddress;

  return {
    ...notification,
    ...info,
  };
};

const changeInvoice = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
  callback?: () => void;
}): NotificationSchemaMock => {
  const info = {
    [Lang.En]: {
      title: 'The invoice has been modified',
      description: `The invoice for the deal has been modified ${notification?.payload?.name}`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
    [Lang.Es]: {
      title: 'Se ha modificado la factura',
      description: `Se ha modificado la factura para el servicio ${notification?.payload?.name}`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
  };

  return {
    ...notification,
    ...info[language],
  };
};

const addNote = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
  callback?: () => void;
}): NotificationSchemaMock => {
  const info = {
    [Lang.En]: {
      title: 'Note added',
      description: `Note: ${notification?.payload?.note}`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
    [Lang.Es]: {
      title: 'Se ha agregado una nota:',
      description: `Nota: ${notification?.payload?.note}`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
  };

  return {
    ...notification,
    ...info[language],
  };
};

const newDealInZone = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
  callback?: () => void;
}): NotificationSchemaMock => {
  const info = {
    [Lang.En]: {
      title: 'A new service has been added in your area',
      description: `The service has been added: ${notification?.payload?.name} in ${notification?.payload?.city}`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
    [Lang.Es]: {
      title: 'Se ha agregado un nuevo servicio en tu zona',
      description: `Se ha  agregado el servicio: ${notification?.payload?.name} en ${notification?.payload?.city}`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
  };

  return {
    ...notification,
    ...info[language],
  };
};

const dealAssigned = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
  callback?: () => void;
}): NotificationSchemaMock => {
  const info = generateTextNotification(notification, language).dealAssigned;

  return {
    ...notification,
    ...info,
  };
};

const activityUpdated = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
  callback?: () => void;
}): NotificationSchemaMock => {
  const info = {
    [Lang.En]: {
      title: 'Activity updated',
      description: `Task has been updated ${notification?.payload?.title}`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
    [Lang.Es]: {
      title: 'Tarea actualizada',
      description: `Se ha actualizado la tarea ${notification?.payload?.title}`,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
  };

  return {
    ...notification,
    ...info[language],
  };
};

const requestSubmitCurrentPosition = ({
  notification,
  language,
}: {
  notification: NotificationSchema;
  language: Lang;
  callback?: () => void;
}) => {
  const info = {
    [Lang.En]: {
      title: 'The administrator asks you to submit your position',
      description: ``,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
    [Lang.Es]: {
      title: 'El administrador solicita que envíes tu posición',
      description: ``,
      date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
    },
  };

  return {
    ...notification,
    ...info[language],
  };
};

const generateTextNotification = (
  notification: NotificationSchema,
  lang: string
): any => {
  if (notification.sector === 'null' || notification.sector === null) {
    if (lang === 'es') {
      return {
        dealPending: {
          title: 'Servicio pendiente de aprobación',
          description: `Se ha creado un nuevo servicio y está a la espera de aprobación`,
          date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
        },
        dealUpdateStatus: {
          title: 'Servicio actualizado',
          description: `Estado del servicio ha sido actualizado a ${notification?.payload?.status?.name}`,
          date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
        },
        dealCancelled: {
          title: 'Se ha cancelado el servicio.',
          description: `Motivo: ${notification?.payload?.cancellationReason?.reason}`,
          date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
        },
        changeAddress: {
          title: 'Se ha cambiado la dirección del servicio.',
          description: ` Se ha cambiado la dirección del servicio ${notification?.payload?.asset?.name}`,
          date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
        },
        changeInitDate: {
          title: 'Se ha cambiado la fecha de inicio del servicio',
          description: `Se ha cambiado la fecha de inicio del servicio ${
            notification?.payload?.name
          } a  ${format(
            notification?.payload?.initDate,
            'DD/MM/YYYY hh:mm aa'
          )}`,
          date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
        },
        dealAssigned: {
          title: 'Servicio asignado.',
          description: `Se te ha asignado el servicio ${notification?.payload?.name}`,
          date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
        },
        dealOwnerAssigned: {
          title: 'Has sido asignado a un servicio como supervisor',
          description: `Servicio asignado ${notification?.payload?.name}`,
          date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
        },
      };
    } else {
      return {
        dealPending: {
          title: 'Deal pending approval',
          description: `A new deal has been created and is awaiting approval`,
          date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
        },
        dealUpdateStatus: {
          title: 'Deal status updated',
          description: `The deal status has been updated to ${notification?.payload?.status?.name}`,
          date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
        },
        dealCancelled: {
          title: 'The service has been canceled.',
          description: `Reason: ${notification?.payload?.cancellationReason?.reason}`,
          date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
        },
        changeAddress: {
          title: 'Service address changed.',
          description: `Service address has been changed ${notification?.payload?.asset?.name} to ${notification?.payload?.asset?.name}`,
          date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
        },
        changeInitDate: {
          title: 'Service start date has been changed',
          description: `Service ${notification?.payload?.name} to ${format(
            notification?.payload?.initDate,
            'DD/MM/YYYY hh:mm aa'
          )}`,
          date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
        },
        dealAssigned: {
          title: 'Deal assigned.',
          description: `The deal ${notification?.payload?.name} has been assigned`,
          date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
        },
        dealOwnerAssigned: {
          title: 'You have been assigned to the service as responsible',
          description: `You have been assigned to service ${notification?.payload?.name}`,
          date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
        },
      };
    }
  }

  const module = notification.sector[0].module;

  if (lang === 'es') {
    switch (module) {
      case 'a':
        return {
          dealPending: {
            title: 'Trabajo pendiente de aprobación',
            description: `Se ha creado un nuevo trabajo y está a la espera de aprobación`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealUpdateStatus: {
            title: 'Trabajo actualizado',
            description: `Estado del trabajo ha sido actualizado a ${notification?.payload?.status?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealCancelled: {
            title: 'Se ha cancelado el trabajo.',
            description: `Motivo: ${notification?.payload?.cancellationReason?.reason}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeAddress: {
            title: 'Se ha cambiado la dirección del trabajo.',
            description: ` Se ha cambiado la dirección del trabajo ${notification?.payload?.asset?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeInitDate: {
            title: 'Se ha cambiado la fecha de inicio del trabajo',
            description: `Se ha cambiado la fecha de inicio del trabajo ${
              notification?.payload?.name
            } a  ${format(
              notification?.payload?.initDate,
              'DD/MM/YYYY hh:mm aa'
            )}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealAssigned: {
            title: 'trabajo asignado.',
            description: `Se te ha asignado el trabajo ${notification?.payload?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealOwnerAssigned: {
            title: 'Has sido asignado a un trabajo como supervisor',
            description: `Trabajo asignado ${notification?.payload?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
        };
      case 'b':
        return {
          dealPending: {
            title: 'Cita pendiente de aprobación',
            description: `Se ha creado una nueva cita y está a la espera de aprobación`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealUpdateStatus: {
            title: 'Cita actualizada',
            description: `Estado de la cita a sido actualizada a ${notification?.payload?.status?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealCancelled: {
            title: 'Se ha cancelado la cita.',
            description: `Motivo: ${notification?.payload?.cancellationReason?.reason}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeAddress: {
            title: 'Se ha cambiado la dirección de la cita.',
            description: ` Se ha cambiado la dirección de la cita ${notification?.payload?.asset?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeInitDate: {
            title: 'Se ha cambiado la fecha de inicio de la cita',
            description: `Se ha cambiado la fecha de inicio de la cita ${
              notification?.payload?.name
            } a  ${format(
              notification?.payload?.initDate,
              'DD/MM/YYYY hh:mm aa'
            )}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealAssigned: {
            title: 'Cita asignada.',
            description: `Se te ha asignado la cita ${notification?.payload?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealOwnerAssigned: {
            title: 'Has sido asignado a una cita como supervisor',
            description: `Cita asignada ${notification?.payload?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
        };
      case 'c':
        return {
          dealPending: {
            title: 'Cita pendiente de aprobación',
            description: `Se ha creado una nueva cita y está a la espera de aprobación`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealUpdateStatus: {
            title: 'Cita actualizada',
            description: `Estado de la cita a sido actualizada a ${notification?.payload?.status?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealCancelled: {
            title: 'Se ha cancelado la cita.',
            description: `Motivo: ${notification?.payload?.cancellationReason?.reason}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeAddress: {
            title: 'Se ha cambiado la dirección de la cita.',
            description: ` Se ha cambiado la dirección de la cita ${notification?.payload?.asset?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeInitDate: {
            title: 'Se ha cambiado la fecha de inicio de la cita',
            description: `Se ha cambiado la fecha de inicio de la cita ${
              notification?.payload?.name
            } a  ${format(
              notification?.payload?.initDate,
              'DD/MM/YYYY hh:mm aa'
            )}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealAssigned: {
            title: 'cita asignadoa.',
            description: `Se te ha asignado la cita ${notification?.payload?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealOwnerAssigned: {
            title: 'Has sido asignado a una cita como supervisor',
            description: `Cita asignada ${notification?.payload?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
        };
      case 'd':
        return {
          dealPending: {
            title: 'Trabajo pendiente de aprobación',
            description: `Se ha creado un nuevo trabajo y está a la espera de aprobación`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealUpdateStatus: {
            title: 'Trabajo actualizado',
            description: `Estado del trabajo ha sido actualizado a ${notification?.payload?.status?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealCancelled: {
            title: 'Se ha cancelado el trabajo.',
            description: `Motivo: ${notification?.payload?.cancellationReason?.reason}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeAddress: {
            title: 'Se ha cambiado la dirección del trabajo.',
            description: ` Se ha cambiado la dirección del trabajo ${notification?.payload?.asset?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeInitDate: {
            title: 'Se ha cambiado la fecha de inicio del trabajo',
            description: `Se ha cambiado la fecha de inicio del trabajo ${
              notification?.payload?.name
            } a  ${format(
              notification?.payload?.initDate,
              'DD/MM/YYYY hh:mm aa'
            )}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealAssigned: {
            title: 'trabajo asignado.',
            description: `Se te ha asignado el trabajo ${notification?.payload?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealOwnerAssigned: {
            title: 'Has sido asignado a un trabajo como supervisor',
            description: `Trabajo asignado ${notification?.payload?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
        };
      case 'e':
        return {
          dealPending: {
            title: 'Cita pendiente de aprobación',
            description: `Se ha creado una nueva cita y está a la espera de aprobación`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealUpdateStatus: {
            title: 'Cita actualizada',
            description: `Estado de la cita a sido actualizada a ${notification?.payload?.status?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealCancelled: {
            title: 'Se ha cancelado la cita.',
            description: `Motivo: ${notification?.payload?.cancellationReason?.reason}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeAddress: {
            title: 'Se ha cambiado la dirección de la cita.',
            description: ` Se ha cambiado la dirección de la cita ${notification?.payload?.asset?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeInitDate: {
            title: 'Se ha cambiado la fecha de inicio de la cita',
            description: `Se ha cambiado la fecha de inicio de la cita ${
              notification?.payload?.name
            } a  ${format(
              notification?.payload?.initDate,
              'DD/MM/YYYY hh:mm aa'
            )}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealAssigned: {
            title: 'Cita asignada.',
            description: `Se te ha asignado la cita ${notification?.payload?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealOwnerAssigned: {
            title: 'Has sido asignado a una cita como supervisor',
            description: `Cita asignada ${notification?.payload?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
        };
    }
  } else {
    switch (module) {
      case 'a':
        return {
          dealPending: {
            title: 'Pending Approval Job',
            description: 'A new job has been created and is awaiting approval',
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealUpdateStatus: {
            title: 'Job Updated',
            description: `The job's status has been updated to ${notification?.payload?.status?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealCancelled: {
            title: 'Job Cancelled',
            description: `Reason: ${notification?.payload?.cancellationReason?.reason}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeAddress: {
            title: 'Job Address Changed',
            description: `The job's address has been changed to ${notification?.payload?.asset?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeInitDate: {
            title: 'Job Start Date Changed',
            description: `The start date of the job ${
              notification?.payload?.name
            } has been changed to ${format(
              notification?.payload?.initDate,
              'DD/MM/YYYY hh:mm aa'
            )}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealAssigned: {
            title: 'Job Assigned',
            description: `You have been assigned the job ${notification?.payload?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealOwnerAssigned: {
            title: 'Assigned as Supervisor to a Job',
            description: `You have been assigned as a supervisor to the job ${notification?.payload?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
        };
      case 'b':
        return {
          dealPending: {
            title: 'Pending Approval Appointment',
            description:
              'A new appointment has been created and is awaiting approval',
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealUpdateStatus: {
            title: 'Appointment Updated',
            description: `The appointment's status has been updated to ${notification?.payload?.status?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealCancelled: {
            title: 'Appointment Cancelled',
            description: `Reason: ${notification?.payload?.cancellationReason?.reason}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeAddress: {
            title: 'Appointment Address Changed',
            description: `The appointment's address has been changed to ${notification?.payload?.asset?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeInitDate: {
            title: 'Appointment Start Date Changed',
            description: `The start date of the appointment ${
              notification?.payload?.name
            } has been changed to ${format(
              notification?.payload?.initDate,
              'DD/MM/YYYY hh:mm aa'
            )}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealAssigned: {
            title: 'Appointment Assigned',
            description: `You have been assigned the appointment ${notification?.payload?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealOwnerAssigned: {
            title: 'Assigned as Supervisor to an Appointment',
            description: `You have been assigned as a supervisor to the appointment ${notification?.payload?.name}`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
        };
      case 'c':
        return {
          dealPending: {
            title: 'Pending Approval Appointment',
            description:
              'A new appointment has been created and is awaiting approval.',
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealUpdateStatus: {
            title: 'Updated Appointment',
            description: `Appointment status has been updated to ${notification?.payload?.status?.name}.`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealCancelled: {
            title: 'Appointment Cancelled',
            description: `Appointment has been cancelled. Reason: ${notification?.payload?.cancellationReason?.reason}.`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeAddress: {
            title: 'Appointment Address Changed',
            description: `The address of the appointment has been changed to ${notification?.payload?.asset?.name}.`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeInitDate: {
            title: 'Appointment Start Date Changed',
            description: `The start date of the appointment ${
              notification?.payload?.name
            } has been changed to ${format(
              notification?.payload?.initDate,
              'DD/MM/YYYY hh:mm aa'
            )}.`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealAssigned: {
            title: 'Assigned Appointment',
            description: `You have been assigned the appointment ${notification?.payload?.name}.`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealOwnerAssigned: {
            title: 'Assigned as Supervisor for an Appointment',
            description: `You have been assigned as a supervisor for the appointment ${notification?.payload?.name}.`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
        };
      case 'd':
        return {
          dealPending: {
            title: 'Pending Approval Job',
            description: 'A new job has been created and is awaiting approval.',
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealUpdateStatus: {
            title: 'Updated Job',
            description: `The job's status has been updated to ${notification?.payload?.status?.name}.`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealCancelled: {
            title: 'Job Cancelled',
            description: `The job has been cancelled. Reason: ${notification?.payload?.cancellationReason?.reason}.`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeAddress: {
            title: 'Job Address Changed',
            description: `The job's address has been changed to ${notification?.payload?.asset?.name}.`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeInitDate: {
            title: 'Job Start Date Changed',
            description: `The start date of the job ${
              notification?.payload?.name
            } has been changed to ${format(
              notification?.payload?.initDate,
              'DD/MM/YYYY hh:mm aa'
            )}.`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealAssigned: {
            title: 'Job Assigned',
            description: `You have been assigned the job ${notification?.payload?.name}.`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealOwnerAssigned: {
            title: 'Assigned as Supervisor for a Job',
            description: `You have been assigned as a supervisor for the job ${notification?.payload?.name}.`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
        };
      case 'e':
        return {
          dealPending: {
            title: 'Pending Approval Appointment',
            description:
              'A new appointment has been created and is awaiting approval.',
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealUpdateStatus: {
            title: 'Updated Appointment',
            description: `The appointment's status has been updated to ${notification?.payload?.status?.name}.`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealCancelled: {
            title: 'Appointment Cancelled',
            description: `The appointment has been cancelled. Reason: ${notification?.payload?.cancellationReason?.reason}.`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeAddress: {
            title: 'Appointment Address Changed',
            description: `The appointment's address has been changed to ${notification?.payload?.asset?.name}.`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          changeInitDate: {
            title: 'Appointment Start Date Changed',
            description: `The start date of the appointment ${
              notification?.payload?.name
            } has been changed to ${format(
              notification?.payload?.initDate,
              'DD/MM/YYYY hh:mm aa'
            )}.`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealAssigned: {
            title: 'Assigned Appointment',
            description: `You have been assigned the appointment ${notification?.payload?.name}.`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
          dealOwnerAssigned: {
            title: 'Assigned as Supervisor for an Appointment',
            description: `You have been assigned as a supervisor for the appointment ${notification?.payload?.name}.`,
            date: format(notification.createdAt, 'DD/MM/YYYY hh:mm aa'),
          },
        };
    }
  }
};
