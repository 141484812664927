<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="">
  <h1>Prototipo de pagos</h1>

  <div class="container">
    <div class="row">
      <div class="col-2">
        <h2>Mis Lectores</h2>
      </div>
      <div class="col-2">
        <button class="roma-button" (click)="findReaders()">
          Buscar Lectores
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-10">
        <ng-container>
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? masterToggle($event) : null"
                  [checked]="selection.hasValue() && isAllSelected() == true"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selectRow($event, row) : null"
                  (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row) == true"
                >
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>ID</th>
              <td mat-cell *matCellDef="let element">{{ element.id }}</td>
            </ng-container>

            <ng-container matColumnDef="label">
              <th mat-header-cell *matHeaderCellDef>Nombre</th>
              <td mat-cell *matCellDef="let element">{{ element.label }}</td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Estado</th>
              <td mat-cell *matCellDef="let element">
                <mat-chip-list>
                  <mat-chip class="online-chip"> {{ element.status }}</mat-chip>
                </mat-chip-list>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </ng-container>
      </div>
    </div>
    <br />
    <br />

    <!-- if  reader was selected -->
    <ng-container *ngIf="this.selectedReader">
      <div class="row">
        <div class="col-3">
          <button
            class="roma-button"
            *ngIf="!this.connectedToReader"
            [disabled]="this.connectingToReader"
            [ngClass]="{ 'is-disable': this.connectingToReader }"
            (click)="connectToReader()"
          >
            <p class="mb-0 mr-6">Conectarse al lector</p>
            <mat-progress-spinner
              *ngIf="this.connectingToReader"
              [diameter]="20"
              mode="indeterminate"
            ></mat-progress-spinner>
          </button>
          <button
            class="roma-button bg-red"
            *ngIf="this.connectedToReader"
            (click)="disConnectToReader()"
          >
            Desconectarse del lector
          </button>
        </div>
        <div class="col-3 d-flex justify-content-end">
          <mat-chip-list>
            <mat-chip class="online-chip" *ngIf="this.connectedToReader">
              Conectado</mat-chip
            >
            <mat-chip *ngIf="!this.connectedToReader"> Desconectado</mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="this.connectedToReader">
      <div class="row col-12 m-15">
        <mat-radio-group
          class="mr-6"
          [(ngModel)]="creditCardType"
          aria-label="Select an option"
        >
          <mat-radio-button class="mr-6" value="4242424242424242"
            >Tarjeta Correcta</mat-radio-button
          >
          <mat-radio-button class="mr-6" value="4000002500003155"
            >Tarjeta con autenticacion</mat-radio-button
          >
          <mat-radio-button class="mr-6" value="4000000000009995"
            >Tarjeta con rechazo</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div class="row col-12 m-15">
        <div class="col-3">
          <input
            matInput
            currencyMask
            class="form-control d-block"
            [options]="{ prefix: '€ ', thousands: ',', decimal: '.' }"
            placeholder="Introduce el valor"
            [(ngModel)]="amount"
          />
        </div>
        <div class="col-3">
          <button
            class="roma-button"
            [ngClass]="{ 'is-disable': this.sendingToReader }"
            (click)="generatePaymentOfCreditCard()"
            type="submit"
          >
            <p class="mb-0 mr-6">Cobrar</p>
            <mat-progress-spinner
              *ngIf="this.sendingToReader"
              [diameter]="20"
              mode="indeterminate"
            ></mat-progress-spinner>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="space"></div>
  <hr>
  <div class="row col-3">
    <button class="roma-button h-50" (click)="back()">Volver</button>
  </div>
</div>
