import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  private networkStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    navigator.onLine
  );

  constructor() {
    this.listenNetworkStatus();
  }

  private listenNetworkStatus() {
    merge(fromEvent(window, 'online'), fromEvent(window, 'offline'))
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        this.networkStatus$.next(status);
      });
  }

  public get isOnline$(): Observable<boolean> {
    return this.networkStatus$.asObservable();
  }
}
