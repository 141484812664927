<div *ngIf="showEnablePaymentsBanner && isCountryValid">
  <section
    class="banner"
    [ngClass]="{ 'half-banner': !large }"
    [ngStyle]="{ 'margin-top': marginTop }"
  >
    <img
      class="close-icon"
      src="assets/icons/gl_close_v2.svg"
      (click)="hideBanner()"
    />
    <div class="banner-description">
      <div class="text">
        <span class="title">{{
          'payments.enable-payments-banner-title' | translate
        }}</span>
        {{ 'payments.enable-payments-banner-description' | translate }}
      </div>
      <button class="roma-button" (click)="goToPayments()">
        {{ 'payments.landing-button' | translate }}
      </button>
    </div>
    <div class="banner-style">
      <img
        [src]="
          'assets/images/enable-payments-banner-asset-' + userLanguage + '.svg'
        "
      />
    </div>
  </section>
</div>
