import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AddClockAbsenceComponent } from './add-clock-absence.component';
import { AddClockAbsence } from './add-clock-absence.types';

@Injectable({
  providedIn: 'root',
})
export class AddClockAbsenceService {
  private refreshListSource = new Subject<AddClockAbsence.Result>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(
    config: MatDialogConfig<AddClockAbsence.Params> = new MatDialogConfig()
  ) {
    const dialogRef = this.dialog.open<
      AddClockAbsenceComponent,
      AddClockAbsence.Params,
      AddClockAbsence.Result
    >(AddClockAbsenceComponent, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }
}
