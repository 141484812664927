import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingModule } from '@web-frontend/shared/components/loading';
import { EmptyStateComponent } from './empty-state.component';
import { BaseButtonModule } from '../button/baseButton.module';
import { YouTubePlayerModule } from '@angular/youtube-player';

@NgModule({
  declarations: [EmptyStateComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    LoadingModule,
    BaseButtonModule,
    YouTubePlayerModule,
  ],
  providers: [],
  exports: [EmptyStateComponent],
})
export class EmptyStateModule {}
