import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nChild } from '@web-frontend/shared/i18n';
import { ConfirmationModalComponent } from './confirmation-modal.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [ConfirmationModalComponent],
  imports: [CommonModule, I18nChild, MatDialogModule],
  exports: [ConfirmationModalComponent],
})
export class ConfirmationModalModule {}
