import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Subscription } from 'rxjs';

import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';

import {
  ConvertToKeyValue,
  DynamicPropertyRef,
  FeatureUser,
  IBudgetsSetting,
  ICatalogue,
  ICategoryExpense,
  ICustomProperty,
  IProduct,
  IUser,
  Tax,
  ValidateAllFormFields,
} from '@tacliatech/types';

import { UserService } from '@web-frontend/shared/services/users';

import { AssetService, StorageService } from '@web-frontend/shared/services';
import { DataParamsCatalogue } from './create-catalogue.types';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { CatalogueService } from '@web-frontend/shared/services/catalogue';
import { TaxService } from '@web-frontend/shared/services/tax';
import { ProductService } from '@web-frontend/shared/services/products';
import { FilterItems } from '../filter';
import { UserflowService } from '@web-frontend/shared/services/userflow/userflow.service';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { DeleteBySelectionModalComponent } from '../delete-by-selection-modal/delete-by-selection-modal.component';
import InvoiceAdapter from '../../../invoice/adapter/invoice-adapter';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import AmplitudeEvents from '../../../../types/amplitude.enum';
import { PermissionService } from '../../services/permissions';

export const typesCatalogue = [
  {
    key: 1,
    value: 'product',
    translate: 'catalogue.product',
  },
  {
    key: 2,
    value: 'service',
    translate: 'catalogue.service',
  },
];

@Component({
  selector: 'roma-create-catalogue',
  templateUrl: './create-catalogue.component.html',
  styleUrls: ['./create-catalogue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateCatalogueComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('customerVendor')
  customerVendorSelect: NgSelectComponent;

  @ViewChild('dealSelect')
  dealSelect: NgSelectComponent;

  @Input()
  type2: '';

  @Input()
  type: 'ADD' | 'EDIT' = 'ADD';

  types = typesCatalogue;

  initialForm: any = {};
  featureRefUser = FeatureUser;
  hasPermissionToEdit = true;
  originalCatalogue: ICatalogue;

  form: FormGroup = this.fb.group({
    name: new FormControl(null, [Validators.required]),
    description: new FormControl(null),
    type: new FormControl(null),
    category: new FormControl(null),
    internalReference: new FormControl(null),
    company_id: new FormControl(StorageService.CompanyId),
    sellPrice: new FormControl(null),
    owner: new FormControl(null),
    idUsers: [],
    taxes: new FormControl(null),
    buyPrice: new FormControl(null),
    createdBy: new FormControl(StorageService.UserId, [Validators.required]),
    customProperties: [[]],
    measurementUnit: new FormControl(null),
    productKey: new FormControl(null),
    minutesDuration: new FormControl(null),
  });

  measurementUnits: Array<{ id: string; description: string }>;
  productAndServices: Array<{ id: string; description: string }>;

  users: IUser[] = [];
  typePropertyRef = DynamicPropertyRef;
  disableSubmit = false;
  customProperties: ICustomProperty[] = [];
  initChildForm = false;
  parentFormSubmit = false;
  invalidEditCustomFields = false;
  isMobile = innerWidth <= 768 || innerHeight <= 600;

  private sub$ = new Subscription();
  private refreshModalOnClose = false;
  isLoading = false;
  settings: IBudgetsSetting;
  productKeySelected: string;
  showAdditionalFields = false;

  @ViewChild('selectRef') selectRef!: NgSelectComponent;
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight;
    this.isMobile = innerWidth <= 768 || innerHeight <= 600;
  }

  constructor(
    private fb: FormBuilder,
    private toastService: ToastService,
    private catalogueService: CatalogueService,
    private changeDetectionRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<CreateCatalogueComponent>,
    private i18n: TranslateService,
    private userService: UserService,
    private taxService: TaxService,
    private productService: ProductService,
    private assetService: AssetService,
    @Inject(MAT_DIALOG_DATA) public data: DataParamsCatalogue,
    private dialog: MatDialog,
    private readonly budgetService: BudgetService,
    private readonly invoiceAdapter: InvoiceAdapter,
    private analyticsService: AnalyticsService,
    private permissionService: PermissionService
  ) {}

  get haveChanges() {
    return Object.entries(this.form.value).some(
      ([key, value]) => this.initialForm[key] !== value
    );
  }

  taxes: Tax[] = [];
  owners: FilterItems = [];

  get controls() {
    return this.form.controls;
  }

  get returnDateOnClose() {
    return this.data?.returnData;
  }

  get isNeededInCountry() {
    return this.userService.validateCountryMX();
  }

  ngOnInit(): void {
    this.resolveTaxes();
    this.resolveOwners();
    this.getCategories();
    this.getUnitMeasurement();
    this.initialForm = { ...this.form.value };
    this.resolveEdit();
    this.getProductAndServices();
    this.dialogRef.disableClose = true;
    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onBackdropClick());
  }

  public toggleFields(): void {
    this.showAdditionalFields = !this.showAdditionalFields;
    if (this.showAdditionalFields) {
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: AmplitudeEvents.catalogue_newCatalogue_view_more,
      });
    }
  }

  public getUnitMeasurement(): void {
    if (!this.isNeededInCountry) {
      return;
    }

    this.invoiceAdapter
      .getUnits()
      .then((res) => {
        this.measurementUnits = res;
      })
      .catch(() => {
        this.measurementUnits = [];
      });
  }

  onSatSelectedItemsChange($event) {
    this.form.controls['productKey'].patchValue($event);
    this.productKeySelected = $event;
  }

  onDurationChange(value: { hour?: number; minutes?: number }) {
    this.form.controls['minutesDuration'].patchValue(value);
  }

  public getProductAndServices(): void {
    if (!this.isNeededInCountry) {
      return;
    }

    this.invoiceAdapter
      .getProductAndServices()
      .then((res) => {
        this.productAndServices = res;

        this.draw();
      })
      .catch(() => {
        this.productAndServices = [];
      });
  }

  public async onBackdropClick(result?: string): Promise<void> {
    if (this.haveChanges) {
      const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
        panelClass: 'delete-by-selection-modal',
        data: {
          title: this.i18n.instant('general.withoutSave'),
          confirmLabel: this.i18n.instant('general.buttonExit'),
          showBody: false,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'EXECUTE') {
          this.close();
        }
      });
    } else {
      this.close();
    }
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  resolveTaxes() {
    this.sub$.add(
      this.budgetService.settings$.subscribe((s) => {
        if (s?.invoiceSettings?.rateDefault) {
          const tax = s?.invoiceSettings?.rateDefault['_id'];
          if (tax) {
            this.form.controls['taxes'].patchValue([
              s?.invoiceSettings?.rateDefault['_id'],
            ]);
          }
        }
      })
    );
    this.taxes = this.taxService.findAll();
  }

  private resolveOwners() {
    this.sub$.add(
      this.assetService.findUsersByAssetsShared().subscribe((res) => {
        this.owners = ConvertToKeyValue(res, 'name', '_id');

        this.draw();
      })
    );
  }

  ngAfterViewInit() {}

  durationToMinutes(duration: { hour?: number; minutes?: number }) {
    if (!duration) {
      return null;
    }
    return (duration.hour || 0) * 60 + (duration.minutes || 0);
  }

  propertiesRequired(value) {
    this.invalidEditCustomFields = value;
  }

  eventValueChanged(evt) {
    this.form.patchValue({ customProperties: [...evt] });
  }

  submit() {
    this.parentFormSubmit = true;
    const isValid = this.checkValidators();

    if (isValid) {
      this.isLoading = true;
      const values = this.form.value;

      if (typeof values.minutesDuration === 'object') {
        values.minutesDuration = this.durationToMinutes(values.minutesDuration);
      }
      const obs$ =
        this.type === 'ADD'
          ? this.catalogueService.create(values)
          : this.catalogueService.updateOne(this.data?.catalogue?._id, values);

      this.sub$.add(
        obs$.subscribe(
          (res) => {
            this.refreshModalOnClose = true;
            this.isLoading = false;
            this.close(res as ICatalogue);

            const message =
              this.type === 'ADD'
                ? 'catalogue.create.success'
                : 'catalogue.edit.success';

            this.toastService.show({
              text: this.i18n.instant(message),
              type: 'success',
              msDuration: 4000,
            });
            this.analyticsService.trackEvent({
              sources: ['amplitude', 'userflow', 'braze'],
              eventName:
                this.type === 'ADD'
                  ? AmplitudeEvents.catalogue_create
                  : AmplitudeEvents.catalogue_edit,
            });
          },
          () => {
            this.isLoading = false;
            this.toastService.show({
              text: this.i18n.instant('activity.error'),
              type: 'error',
              msDuration: 4000,
            });
          }
        )
      );
    } else {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      this.draw();
    }
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);

    return this.form.valid;
  }

  close(catalogue: ICatalogue = null): void {
    const refresh = this.refreshModalOnClose ? true : null;
    if (this.returnDateOnClose === true && catalogue) {
      this.dialogRef.close(catalogue);
    } else {
      this.dialogRef.close(refresh);
    }
    if (this.type === 'EDIT') {
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: AmplitudeEvents.catalogueViewReturn,
      });
    }
  }

  get durationHours(): number {
    if (this.type === 'EDIT') {
      return Math.floor(this.data.catalogue.minutesDuration / 60);
    } else {
      return 0;
    }
  }

  get durationMinutes(): number {
    if (this.type === 'EDIT') {
      return this.data.catalogue.minutesDuration % 60;
    } else {
      return 0;
    }
  }

  private resolveEdit() {
    if (this.data?.catalogue) {
      this.type = 'EDIT';

      this.originalCatalogue = JSON.parse(JSON.stringify(this.data.catalogue));
      const { catalogue } = this.data;
      this.form.patchValue({
        ...catalogue,
        category: catalogue.category?._id ?? null,
      });

      this.permissionService
        .getFeaturePermissionValues([this.featureRefUser.Catalogue.update])
        .subscribe((res) => {
          if (!res[this.featureRefUser.Catalogue.update]) {
            this.form.disable();
            this.hasPermissionToEdit = false;
          }
        });

      this.productKeySelected = catalogue?.productKey;

      this.draw();
    }
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  categories: IProduct[] | ICategoryExpense = [];
  private getCategories() {
    this.sub$.add(
      this.productService.findAll().subscribe((res) => {
        this.categories = res;
        this.draw();
      })
    );
  }

  customPropertiesFormChange($event) {
    this.customProperties = $event;
    this.setcustomProperties();
  }

  initialChargeEvent() {
    this.initialForm = { ...this.form.value };
  }

  setcustomProperties() {
    let i = 0;
    for (i = 0; i < this.customProperties.length; i++) {
      if (this.customProperties[i].isRequired) {
        this.form.addControl(
          this.customProperties[i]._id,
          new FormControl('', Validators.required)
        );
      }
    }
    this.initChildForm = true;
    this.parentFormSubmit = false;
  }

  changeComma(value: number) {
    const valueSttring = String(value);
    return valueSttring.replaceAll('.', ',');
  }
}
