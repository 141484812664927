import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CreateLocationService } from './services/create-location.service';
import { Stripe } from 'stripe';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'roma-create-location',
  templateUrl: './create-location.component.html',
  styleUrls: ['./create-location.component.scss'],
})
export class CreateLocationComponent implements OnInit {
  locationForm: FormGroup = new FormGroup({
    displayName: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    line1: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    postalCode: new FormControl('', [Validators.required]),
  });

  isLoading = false;
  location: Stripe.Response<Stripe.Terminal.Location>;
  locations: Stripe.Terminal.Location[] = [];

  constructor(
    private router: Router,
    private createLocationService: CreateLocationService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.createLocationService
      .getLocations()
      .pipe(
        finalize(() => {
          this.draw();
        })
      )
      .subscribe((res) => {
        this.locations = res.data;
      });
  }

  back() {
    this.router.navigateByUrl('/admin/payments');
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  onSubmit() {
    this.isLoading = true;
    const { displayName, ...rest } = this.locationForm.value;
    this.createLocationService
      .create({
        displayName,
        address: rest,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.draw();
        })
      )
      .subscribe((res) => {
        this.location = res;
      });
  }
}
