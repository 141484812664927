import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CalendarApi, transformDateToString } from './calendar.types';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(private http: HttpClient) {}

  search(query: {
    [key: string]: any;
  }): Observable<{
    recurrences: CalendarApi.GhostDeal[];
    deals: CalendarApi.ApiDeal[];
    activities: unknown[];
  }> {
    const params = new HttpParams({
      fromObject: {
        ...query,
      },
    });

    // @ts-ignore
    return this.http
      .get<CalendarApi.Response>(`:API_URL/search/calendars`, {
        params,
        headers: {
          ['X-API-Version']: '2',
        },
      })
      .pipe(
        // @ts-ignore
        map((res) => {
          const recurrences = res.recurrences.map((recurrence) => {
            return {
              ...recurrence,
              initDateStr: recurrence?.initDate
                ? transformDateToString(recurrence.initDate)
                : null,
            };
          });
          return { ...res, recurrences };
        }),
        // @ts-ignore
        map((res: CalendarApi.ResponseExtended) => {
          if (query?.to && query?.from) {
            const recurrences: CalendarApi.GhostDeal[] = [];
            return {
              recurrences,
              activities: [],
              deals: res.deals,
            };
            return CalendarApi.GenerateGhostDeals(
              new Date(query.from + 'T00:00'),
              new Date(query.to + 'T00:00'),
              res
            );
          }

          res;
        })
      );
  }

  create(data: { idDealRecurrence: string; newDate: string }) {
    return this.http.post<CalendarApi.ApiDeal>(':API_URL/calendars', {
      idDealRecurrence: data.idDealRecurrence,
      newDate: data.newDate,
    });
  }

  updateGhostPosition(data: {
    idDealRecurrence: string;
    newDate: string;
    newDateException: string;
  }) {
    return this.http.post<CalendarApi.ApiDeal>(
      ':API_URL/calendars/update-ghost-position',
      {
        idDealRecurrence: data.idDealRecurrence,
        newDate: data.newDate,
        newDateException: data.newDateException,
      }
    );
  }

  deleteDealRecurrence(data: {
    idDealRecurrence: string;
    deleteType: CalendarApi.DealEventDeleteType;
    date: string;
  }) {
    return this.http.post(`:API_URL/calendars/delete-recurrences`, data);
  }

  deleteDealParentRecurrence(data: {
    idDealParentRecurrence: string;
    deleteType: CalendarApi.DealEventDeleteType;
    date: string;
  }) {
    return this.http.post(`:API_URL/calendars/delete-parent-recurrences`, data);
  }
}
