import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { FormBuilder, Validators } from '@angular/forms';
import {
  Feature,
  IBudgetPreferences,
  IFileNote,
  IUser,
  MembershipSchema,
  TypeRol,
} from '@tacliatech/types';
import {
  AuthService,
  SandboxService,
  StorageService,
  UserService,
} from '@web-frontend/shared/services';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { filter } from 'rxjs/operators';
import { environment } from '@web-frontend/environments';
import { Subscription } from 'rxjs';
import * as moment from 'moment-timezone';
import { FileSelectService } from '@web-frontend/shared/helpers/file-select';
import { fileData, ImageSelectorComponent } from '../image-selector-v2';

@Component({
  selector: 'roma-notes-comments-v2',
  templateUrl: './notes-comments-v2.component.html',
  styleUrls: ['./notes-comments-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesCommentsComponent implements OnInit, OnDestroy {
  @ViewChild(ImageSelectorComponent)
  imageSelectorComponent: ImageSelectorComponent;

  resize$ = this.sandBoxService.screenBusChannel$;

  @Input()
  mode: 'ADD' | 'EDIT' = 'ADD';

  settings: IBudgetPreferences;

  @Input()
  set data(value: IFileNote[]) {
    this._data = value;
    this.patchParams();
  }

  get data() {
    return this._data;
  }

  @Input()
  showButton = true;

  @Output()
  changeNotes = new EventEmitter<IFileNote[]>();

  notes: IFileNote[] = [];

  form = this.fb.group({
    createdAt: [new Date(), Validators.required],
    text: [''],
    idAuthor: [StorageService.UserId],
  });

  editMode = {
    index: null,
    active: false,
  };

  private userRole: TypeRol[];
  private _data: IFileNote[] = [];
  overSubmit = false;
  private sub$ = new Subscription();
  allUsers: IUser[] = [];

  company_date_format = this.budgetService.date_format
    ? this.budgetService.date_format
    : 'dd/MM/yyyy';

  file: fileData[] = []; // Variable to store file

  featureRef = Feature;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private changeDetectionRef: ChangeDetectorRef,
    private budgetService: BudgetService,
    private fileSelect: FileSelectService,
    private sandBoxService: SandboxService
  ) {
    this.resolveUserRole();
  }

  async ngOnInit() {
    this.patchParams();
    this.getUsers();
    await this.getSettings();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  private resolveUserRole() {
    this.authService.user$
      .pipe(filter((res) => Boolean(res)))
      .subscribe((data) => {
        this.userRole = data.role;
      });
  }

  async addNotes() {
    if (this.form.valid) {
      if (!this.editMode.active) {
        await this.handleAddNote();
      } else {
        await this.handleEditNote();
      }
      //add actual data from user that creates the note
      this.notes[this.notes.length - 1].name = JSON.parse(
        StorageService.userData
      )?.name;
      this.notes[this.notes.length - 1].edit = false;
      this.notes[this.notes.length - 1].role = JSON.parse(
        StorageService.userData
      )?.role;

      this.changeNotes.emit(this.notes);
      this.file = [];
      this.form.get('text').reset();
      this.overSubmit = false;

      this.draw();
    }
  }

  async blurTextArea() {
    if (this.overSubmit == true) {
      this.addNotes();
    }
  }

  private async handleAddNote() {
    if (this.file) {
      this.form.value.file = this.file;
    }

    this.notes = [
      ...this.notes,
      {
        ...this.form.value,
      },
    ];
    this.setUserNote();
  }

  private async handleEditNote() {
    this.notes = this.notes.map((note, index) => {
      return this.editMode.index === index
        ? {
            ...note,
            text: this.form.get('text').value,
            file: this.file,
          }
        : note;
    });
    this.setUserNote();
    this.resetEditMode();
  }

  private resetEditMode() {
    this.editMode.index = null;
    this.editMode.active = false;
    this.draw();
  }

  deleteNote(index: number) {
    this.notes = this.notes.filter((item, i) => index !== i);

    this.changeNotes.emit(this.notes);

    this.resetEditMode();
  }

  editNote(index: number, value: IFileNote) {
    this.form.get('text').reset();
    this.form.patchValue({
      ...value,
    });

    this.editMode.index = index;
    this.editMode.active = true;
    this.file = this.notes[index].file;
    this.draw();
  }

  private patchParams() {
    this.notes = [...this.data];
    this.setUserNote();
  }

  private setUserNote() {
    for (let i = 0; i < this.notes.length; i++) {
      this.getAuthorData(this.notes[i].idAuthor, i);
    }
  }

  private getAuthorData(id: string, i: number) {
    let authorData: { name: string; role: TypeRol[]; edit: boolean };
    let enableEdit = true;

    this.userService.findOne(id).subscribe((data) => {
      if (this.checkRole(data.role) && this.checkRole(this.userRole)) {
        enableEdit = true;
      }

      if (this.checkRole(data.role) && !this.checkRole(this.userRole)) {
        enableEdit = false;
      }

      authorData = {
        name: data.name,
        role: data.role,
        edit: enableEdit,
      };

      this.notes[i] = { ...this.notes[i], ...authorData };
      this.draw();
    });
  }

  getUsers() {
    this.sub$.add(
      this.userService
        .findAllByCompany(StorageService.CompanyId)
        .subscribe((res) => {
          this.allUsers = res;
          this.draw();
        })
    );
  }

  private checkRole(roles: TypeRol[]) {
    return roles.includes(TypeRol.ADMIN_ROLE);
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  getUserImgByName(
    name?: string,
    defaultIcon = '/assets/icons/table-user-icon.svg'
  ): string {
    const userData = JSON.parse(StorageService.userData ?? '{}');
    name = name ?? userData?.name;

    const img = this.allUsers?.find((it) => it.name === name)?.img;

    return img
      ? `${environment.firebaseConfig.storageUrl}/uploads%2Fuser%2F${img}`
      : defaultIcon;
  }

  getCommentTime(date) {
    const zone = this.settings?.zone || 'Europe/Madrid';
    return moment(date).tz(zone).format('hh:mm a');
  }

  //File
  changeFileArray(evt: fileData[]) {
    this.file = evt;
  }

  determineCopy(copy: string): string {
    if (environment.MembershipSchema === MembershipSchema.OneProPlan) {
      return `${copy}-new`;
    } else {
      return copy;
    }
  }

  resolveFile(file: fileData) {
    return this.imageSelectorComponent.resolveFile(file);
  }

  downloadFile(file: fileData) {
    this.imageSelectorComponent.downloadFile(file);
  }

  resolveImageFormat(file: fileData) {
    return ['png', 'jpg', 'jpeg', 'svg', 'tif', 'tiff', 'bmp'].includes(
      file.extension.toLowerCase()
    );
  }

  async getSettings() {
    const id = StorageService.CompanyId;
    const res = await this.budgetService.findSettingsByCompany(id).toPromise();
    this.settings = res.budgetPreferences;
  }
}
