import { Injectable } from '@angular/core';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DownloadBtnService {
  isLoading = new Subject<boolean>();
  isLoading$ = this.isLoading.asObservable();

  constructor(private toastService: ToastService) {}

  downloadedSuccessfully() {
    this.isLoading.next(false);

    this.toastService.show({
      text: 'general.downloadedSuccessfully',
      type: 'success',
      msDuration: 4000,
      icon: 'assets/icons/kanban/checkbox-selected.svg',
    });
  }
}
