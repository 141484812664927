import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  BudgetPreferences,
  BudgetsSetting,
  FeatureUser,
  ICatalogue,
} from '@tacliatech/types';
import { SandboxService, StorageService } from '@web-frontend/shared/services';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { CatalogueSearchService } from '@web-frontend/shared/services/catalogue';
import { finalize, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { EditCatalogueModalService } from './edit-catalogue-modal/edit-catalogue-modal.service';
import { CreateCatalogueService } from '../create-catalogue/create-catalogue.service';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { RatesSelect } from './rates-select-table.types';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';

@Component({
  selector: 'roma-rates-select-table',
  templateUrl: './rates-select-table.component.html',
  styleUrls: ['./rates-select-table.component.scss'],
})
export class RatesSelectTableComponent implements OnInit {
  @Input() settings: BudgetPreferences = null;

  @Input()
  set dealProducts(value: any[]) {
    this._dealProducts = value;
    if (this._dealProducts.length) {
      this.selectedCatalogues = this._dealProducts.map((item) => {
        return {
          id: item._id,
          title: `${item.name}`,
          value: item._id,
          price: item.sellPrice || 0,
          stock: item.stock || 1,
        };
      });
    } else {
      this.selectedCatalogues = [];
    }
  }

  get dealProducts() {
    return this._dealProducts;
  }

  _dealProducts: any[] = [];

  @Input()
  amplitudeEvents: { catalogueRemove: boolean } = { catalogueRemove: false };

  @Input()
  showTotals = true;

  @Input()
  showActions = true;

  @Input()
  canEdit = true;

  @Input()
  showStock = true;

  @Input()
  profileOnline? = false;

  @Output()
  changeProducts = new EventEmitter<any[]>();

  catalogues: ICatalogue[] = [];
  cataloguesList: RatesSelect.Items = [];
  selectedCatalogues: RatesSelect.Items = [];
  isCataloguesLoading = false;
  cataloguesCharged = false;

  featureRefUser = FeatureUser;

  defaultSettings = new BudgetsSetting();
  defaultPreferences = this.defaultSettings.budgetPreferences;
  currencys = this.budgetService.findAllCurrencys();
  DEFAULT_CURRENCY_SYMBOL = '€';
  private sub$ = new Subscription();
  showSelectInput = false;
  resize$ = this.sandBoxService.screenBusChannel$;

  constructor(
    private cdRef: ChangeDetectorRef,
    private catalogueSearchService: CatalogueSearchService,
    private createCatalogueService: CreateCatalogueService,
    private amplitudeService: AmplitudeService,
    private budgetService: BudgetService,
    private editCatalogueModalService: EditCatalogueModalService,
    private sandBoxService: SandboxService,
    private toastService: ToastService,
    private readonly brazeService: BrazeService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {}

  requestCatalogues() {
    this.isCataloguesLoading = true;

    this.sub$.add(
      this.catalogueSearchService
        .search({
          company_id: StorageService.CompanyId,
        })
        .pipe(
          finalize(() => {
            this.isCataloguesLoading = false;
            this.cataloguesCharged = true;
            this.draw();
          })
        )
        .subscribe((res) => {
          const docs = res.docs?.map((item) => {
            return {
              id: item._id,
              title: `${item.name}`,
              value: item._id,
              price: item.sellPrice || 0,
              stock: 1,
            };
          });
          this.cataloguesList = docs;
        })
    );
  }

  addCatalogue() {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.sale_card_catalogue_start,
    });
    this.brazeService.sendEvent({
      event: BrazeEventType.catalogue_start,
    });
    this.createCatalogueService
      .open({
        data: {
          deal: null,
        },
      })
      .pipe(map((res) => !res))
      .subscribe((data) => {
        this.requestCatalogues();
      });
  }

  getTotalPrice(items) {
    return items.reduce(function (res, item) {
      return res + item.sellPrice * item.stock;
    }, 0);
  }

  changeCalatogueList(event) {
    this.dealProducts = event.map((catalogue) => {
      return {
        _id: catalogue.value,
        stock: catalogue.stock,
        sellPrice: catalogue.price,
        name: catalogue.title,
      };
    });

    this.changeProducts.next(this._dealProducts);
    //this.showSelectInput = false;
  }

  deleteCatalogue(id: string) {
    if (this.profileOnline && this.dealProducts.length <= 1) {
      this.toastService.show({
        text: 'booking.toastNoDeleteItems',
        type: 'error',
        class: 'medium',
        msDuration: 4000,
      });
      return;
    }
    this.dealProducts = this._dealProducts.filter((item) => item._id !== id);
    this.changeProducts.next(this._dealProducts);
    if (this.amplitudeEvents.catalogueRemove) {
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: AmplitudeEvents.sale_card_catalogue_remove,
      });
    }
  }

  openEditModal(catalogue): void {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.deal_card_addCatalogue_edit,
    });

    this.editCatalogueModalService
      .open({
        data: {
          catalogue,
        },
      })
      .subscribe((res) => {
        if (res._id) {
          const objIndex = this.dealProducts.findIndex(
            (obj) => obj?._id === res?._id
          );
          this.dealProducts[objIndex] = res;
          this.selectedCatalogues = this._dealProducts.map((item) => {
            return {
              id: item?._id,
              title: `${item?.name}`,
              value: item?._id,
              price: item?.sellPrice || 0,
              stock: item?.stock || 1,
            };
          });
          this.changeProducts.next(this._dealProducts);
          this.draw();
        }
      });
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  openRmSelectPanel() {
    this.showSelectInput = true;
    !this.cataloguesCharged ? this.requestCatalogues() : null;
  }
}
