import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { EditCustomPropertiesComponent } from './edit-custom-properties.component';
import { EditableFieldModule } from '../editable-field';
import { PermissionsModule } from '../permissions';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ObligatoryFieldSignModule } from '../obligatory-field-sign/obligatory-field-sign.module';

@NgModule({
  declarations: [EditCustomPropertiesComponent],
  imports: [
    CommonModule,
    EditableFieldModule,
    TranslateModule.forChild(),
    MatProgressSpinnerModule,
    PermissionsModule,
    ObligatoryFieldSignModule,
  ],
  exports: [EditCustomPropertiesComponent],
})
export class EditCustomPropertiesModule {}
