import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { countryCodes } from './countryCodes';
import { debounce, distinctUntilChanged, map } from 'rxjs/operators';
import { fromEvent, Subscription, timer } from 'rxjs';
import { StorageService } from '@web-frontend/shared/services';
import { HttpClient } from '@angular/common/http';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import AmplitudeEvents from 'src/types/amplitude.enum';

interface country {
  name: string;
  dial_code: string;
  code: string;
}

@Component({
  selector: 'roma-rm-phone-select-input',
  templateUrl: './rm-phone-select-input.component.html',
  styleUrls: ['./rm-phone-select-input.component.scss'],
})
export class RmPhoneSelectInputComponent implements OnInit, AfterViewInit {
  public innerWidth = 0;
  isResponsive = false;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.isResponsive = this.innerWidth < 768;
  }

  selectedPrefix = '';
  lastValidPrefix = undefined;
  selectedNumber = '';
  @HostBinding('class.is__active') showPrefixList = false;

  @Input()
  @HostBinding('class.block')
  block = false;

  @Input()
  set phoneValue(value: { number?: string; pre?: string; prefix?: string }) {
    if (!value) {
      this.setInitPrefixByCountry();
      return;
    }
    this.selectedPrefix = value.pre || value.prefix;
    if (!this.lastValidPrefix) {
      this.lastValidPrefix = this.selectedPrefix;
    }
    this.selectedNumber = value.number;
  }
  private sub$ = new Subscription();
  private _input: ElementRef<HTMLInputElement>;
  private _inputMobile: ElementRef<HTMLInputElement>;

  @Input()
  inputClass = '';

  @Input()
  autoPrefix: boolean;

  @ViewChild('inputFilterSearch')
  set input(value: ElementRef<HTMLInputElement>) {
    if (value) {
      this._input = value;
      this.watchInputSearch();
    }
  }

  @ViewChild('mobileInputFilterSearch')
  set mobileSearchInput(value: ElementRef<HTMLInputElement>) {
    if (value) {
      this._inputMobile = value;
      this.watchInputSearch();
    }
  }
  filterArgs: { key: string } = { key: '' };

  @Output()
  phoneChanges = new EventEmitter();

  @Output()
  onPrefixChange = new EventEmitter();

  constructor(
    private cdr: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private HttpClient: HttpClient
  ) {}

  countryCodes: country[] = [];

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.isResponsive = this.innerWidth < 768;
    this.initCountryCodes();
  }

  ngAfterViewInit(): void {
    this.setInitPrefixByCountry();
  }

  getLang() {
    return StorageService.GetItem('USER_LANG');
  }

  initCountryCodes() {
    const countryC = countryCodes;
    const language = this.getLang();

    this.countryCodes = countryC
      .map((country) => ({
        code: country?.code,
        dial_code: country?.dial_code,
        name: country?.name[language],
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  selectPrefix(prefix: string) {
    if (prefix === this.selectedPrefix) {
      this.selectedPrefix = '';
      this.filterArgs = {
        key: '',
      };
    } else {
      this.selectedPrefix = prefix;
    }

    this.showPrefixList = false;
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.final_card_editPrefix,
    });
    this.phoneChangesEvent();

    this.draw();
  }

  private setFocusInputSearch() {
    if (this._input) {
      this._input.nativeElement.focus();
    }
  }

  private watchInputSearch() {
    this.sub$.add(
      fromEvent(
        this.isResponsive && this.showPrefixList
          ? this._inputMobile.nativeElement
          : this._input.nativeElement,
        'input'
      )
        .pipe(
          distinctUntilChanged(),
          debounce(() => timer(450)),
          map((res) => (res.target as HTMLTextAreaElement).value as string)
        )
        .subscribe(this.changeKeyword.bind(this))
    );
  }

  private changeKeyword(keyword: string) {
    this.filterArgs = {
      key: keyword.toString().toLocaleLowerCase(),
    };
    this.draw();
  }

  omitSpecialChar(event): boolean {
    const regex = /[a-zA-Z0-9\s\b+]/;
    return regex.test(String.fromCharCode(event.charCode));
  }

  numberOnly(event): boolean {
    const charCode = event.which || event.keyCode;
    const regex = /[^0-9]/g;
    return !regex.test(String.fromCharCode(charCode));
  }

  setInitPrefixByCountry(): void {
    if (!this.autoPrefix || this.phoneValue?.pre) {
      return;
    }
    this.HttpClient.get(
      'https://geolocation.onetrust.com/cookieconsentpub/v1/geo/location'
    ).subscribe(
      (resp: {
        country: string;
        state?: string;
        stateName?: string;
        continent?: string;
      }) => {
        const filter: country[] = this.countryCodes.filter((e) =>
          e.code?.includes(resp?.country)
        );
        if (!filter?.length) {
          return;
        }
        this.phoneValue = {
          pre: filter[0].dial_code,
          number: this.selectedNumber || '',
        };

        this.onPrefixChange.emit({
          pre: filter[0].dial_code,
          code: filter[0].code,
          name: filter[0].name,
        });
        if (this.selectedNumber) {
          const out = {
            pre: filter[0].dial_code,
            code: filter[0].code,
            name: filter[0].name,
            number: this.selectedNumber,
          };
          this.phoneChanges.next(out);
        }
      },
      (error) => {
        console.warn('Failed to fetch geolocation data:', error);
      }
    );
  }

  prefixListToggle(): void {
    if (this.isResponsive) {
      this.filterArgs = { key: '' };
    }

    this.showPrefixList = !this.showPrefixList;
    this.selectedPrefix = this.showPrefixList
      ? this.filterArgs.key
      : this.lastValidPrefix;

    if (this.showPrefixList) {
      this.setFocusInputSearch();
    }
  }

  onPhoneChange(phoneNumber: string): void {
    if (this.selectedNumber === phoneNumber) {
      return;
    }
    this.selectedNumber = phoneNumber;
    this.phoneChangesEvent(true);
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.final_card_editPhoneNumber,
    });
  }
  resetPrefixIfNecessary(): void {
    //starts with "+" and contains only numbers
    if (!/^\+\d+$/.test(this.selectedPrefix)) {
      this.selectedPrefix = this.lastValidPrefix;
    }

    const countryCode = this.countryCodes.find(
      (fil) => fil.dial_code === this.selectedPrefix
    );
    if (!countryCode) {
      this.selectedPrefix = this.lastValidPrefix;
    }
  }

  phoneChangesEvent(sendAlways = false): void {
    this.resetPrefixIfNecessary();

    if (this.selectedPrefix === this.lastValidPrefix && !sendAlways) {
      return;
    }
    const country = this.countryCodes.find(
      (fil) => fil.dial_code === this.selectedPrefix
    );
    if (this.lastValidPrefix !== this.selectedPrefix) {
      this.onPrefixChange.emit({
        pre: this.selectedPrefix,
        code: country?.code,
        name: country?.name,
      });
    }
    this.lastValidPrefix = this.selectedPrefix;
    const out = {
      pre: this.selectedPrefix,
      code: country?.code,
      name: country?.name,
      number: this.selectedNumber,
    };
    this.phoneChanges.next(out);
  }

  private draw() {
    this.cdr.detectChanges();
  }
}
