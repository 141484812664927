import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { CertificateStatus } from 'src/types-legacy/lib/enum/certificate-status.enum';
import AmplitudeEvents from 'src/types/amplitude.enum';

@Component({
  selector: 'digital-certificate-status',
  templateUrl: './digital-certificate-status.component.html',
  styleUrls: ['./digital-certificate-status.component.scss'],
})
export class DigitalCertificateStatusComponent implements OnInit {
  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private amplitudeService: AmplitudeService
  ) {}

  _status: CertificateStatus;
  @Input()
  set status(status: CertificateStatus) {
    this._status = status;
    this.addStatusText();
  }

  get status(): CertificateStatus {
    return this._status;
  }

  @Output()
  toBill = new EventEmitter();

  @Output()
  retrySendCertificate = new EventEmitter();

  statusObj: {
    icon: string;
    header: string;
    showLink: boolean;
    linkHref: string;
    linkText: string;
    text: string;
    showButton: boolean;
    buttonText: string;
  };

  ngOnInit(): void {}

  addStatusText() {
    switch (this.status) {
      case CertificateStatus.JustSend:
        this.statusObj = {
          icon:
            '/assets/icons/bill-digital-certificate/certificate-progress.svg',
          header: 'billDigitalCerficiate.progressHeader',
          showLink: false,
          linkHref: '',
          linkText: '',
          text: 'billDigitalCerficiate.progressText',
          showButton: false,
          buttonText: '',
        };
        break;
      case CertificateStatus.Progress:
        this.statusObj = {
          icon:
            '/assets/icons/bill-digital-certificate/certificate-expired.svg',
          header: 'billDigitalCerficiate.progressHeader2',
          showLink: false,
          linkHref: '',
          linkText: '',
          text: 'billDigitalCerficiate.progressText',
          showButton: false,
          buttonText: '',
        };
        break;
      case CertificateStatus.Accepted:
        this.statusObj = {
          icon:
            '/assets/icons/bill-digital-certificate/certificate-approved.svg',
          header: 'billDigitalCerficiate.approvedHeader',
          showLink: false,
          linkHref: '',
          linkText: '',
          text: 'billDigitalCerficiate.approvedtext',
          showButton: true,
          buttonText: 'billDigitalCerficiate.createBill',
        };
        break;
      case CertificateStatus.Expired:
        this.statusObj = {
          icon:
            '/assets/icons/bill-digital-certificate/certificate-expired.svg',
          header: 'billDigitalCerficiate.expiredHeader',
          showLink: true,
          linkHref: 'https://academy.taclia.com/ventas#facturas-m%C3%A9xico',
          linkText: 'billDigitalCerficiate.expiredlinkText',
          text: 'billDigitalCerficiate.expiredtext',
          showButton: true,
          buttonText: 'billDigitalCerficiate.tryAgain',
        };
        break;
      case CertificateStatus.Rejected:
        this.statusObj = {
          icon:
            '/assets/icons/bill-digital-certificate/certificate-rejected.svg',
          header: 'billDigitalCerficiate.rejectedHeader',
          showLink: true,
          linkHref: 'https://academy.taclia.com/ventas#facturas-m%C3%A9xico',
          linkText: 'billDigitalCerficiate.rejectedlinkText',
          text: 'billDigitalCerficiate.rejectedtext',
          showButton: true,
          buttonText: 'billDigitalCerficiate.tryAgain',
        };
        break;
    }
    this.draw();
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  onClickLink() {
    switch (this.status) {
      case CertificateStatus.Expired:
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.bill_certificateExpired_whatToDo,
        });
        break;
      case CertificateStatus.Rejected:
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.bill_certificateRejected_whatToDo,
        });
        break;
    }
  }

  onClickButton() {
    if (
      this.status === CertificateStatus.Expired ||
      this.status === CertificateStatus.Rejected
    ) {
      switch (this.status) {
        case CertificateStatus.Expired:
          this.amplitudeService.sendEvent({
            event: AmplitudeEvents.bill_certificateExpired_tryAgain,
          });
          break;
        case CertificateStatus.Rejected:
          this.amplitudeService.sendEvent({
            event: AmplitudeEvents.bill_certificateRejected_tryAgain,
          });
          break;
      }
      this.retrySendCertificate.emit();
    }
    if (this.status === CertificateStatus.Accepted) {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.bill_certificateApproved_createBill,
      });
      this.toBill.emit();
    }
  }

  contactUs() {
    switch (this.status) {
      case CertificateStatus.Expired:
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.bill_certificateExpired_contactUs,
        });
        break;
      case CertificateStatus.Rejected:
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.bill_certificateRejected_contactUs,
        });
        break;
      case CertificateStatus.Accepted:
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.bill_certificateApproved_contactUs,
        });
        break;
    }
  }
}
