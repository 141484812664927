import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../../shared/services';
import { VacationReport } from '../../../../../types-legacy/lib/interfaces/vacation-report.interface';

@Component({
  selector: 'roma-absence-counter',
  templateUrl: './absence-counter.component.html',
  styleUrls: ['./absence-counter.component.scss'],
})
export class AbsenceCounterComponent {
  @Input() vacationReport: VacationReport;
  isAdmin$ = this.authService.isAdmin$;

  constructor(private readonly authService: AuthService) {}
}
