/* tslint:disable */
import { DealsMockType } from '../enum/deal.enum';
import { ICustomProperty } from '../interfaces';

export const DealsColumnsCustomer = [
  'select',
  'name',
  'final',
  'initDate',
  'vendor',
  //'dealCategory',
  //'status',
  //'reporter',
  //'addressAsset',
  //'project',
  // 'typeDeal',
  //'bills',
  //'budget',
];

export const DealsColumnsVendor = [
  'name',
  'initDate',
  'address',
  'status',
  'customer',
];

export const DealsColumnsOp = [
  '_id',
  'name',
  // 'typeDeal',
  'initDate',
  'status',
  'customer',
  'vendor',
  'city',
  'address',
  'biddings_count',
  'actions',
];

export const DealsWrapperPropertySort = (key: string) => {
  let res = key;

  if (['customer', 'vendor', 'createdBy', 'status'].includes(key)) {
    res = `${key}.name`;
  }

  if (key === 'typeDeal') {
    res = `type`;
  }

  return res;
};

export const DealsWrapperCustomPropertySort = (
  properties: ICustomProperty[],
  key: string,
) => {
  const keyNames = properties.map((property) => property.name);

  return keyNames.includes(key) ? `customProperties.${key}` : null;
};

export const ConvertToKeyValue = <T = any>(
  items: T[],
  nameKey = 'name',
  valueKey = 'id',
) => {
  return items.map((item) => {
    return {
      key: item[nameKey],
      value: item[valueKey],
    };
  });
};

export const ConvertToIdName = <T = any>(
  items: T[],
  nameKey = 'name',
  valueKey = 'id',
) => {
  return items.map((item) => {
    return {
      name: item[nameKey],
      id: item[valueKey],
    };
  });
};

export const ConvertTo_IdName = <T = any>(
  items: T[],
  nameKey = 'name',
  valueKey = '_id',
) => {
  return items.map((item) => {
    return {
      name: item[nameKey],
      id: item[valueKey],
    };
  });
};

export const ConvertTo_IdNameLatLong = <T = any>(
  items: T[],
  nameKey = 'name',
  valueKey = '_id',
  latitudeKey = 'asset.latitude',
  longitudeKey = 'asset.longitude',
) => {
  return items.map((item) => {
    items;
    return {
      name: item[nameKey],
      id: item[valueKey],
      latitude: item[latitudeKey],
      longitude: item[longitudeKey],
    };
  });
};

export const GetColorsDeals = {
  [DealsMockType.REQUESTED]: {
    primary: '#e63946',
    secondary: '#FAE3E3',
    third: '#e66a73',
  },
  [DealsMockType.BUDGETED]: {
    primary: '#2a9d8f',
    secondary: '#9cccc6',
    third: '#52c0b2',
  },
  [DealsMockType.IN_PROCESS]: {
    primary: '#100a6e',
    secondary: '#ebdebf',
    third: '#7592fa',
  },
  [DealsMockType.FINALIZED]: {
    primary: '#54e687',
    secondary: '#EEE',
    third: '#065525',
  },
  [DealsMockType.PENDING]: {
    primary: '#e9c46a',
    secondary: '#ebdebf',
    third: '#f8db94',
  },
  [DealsMockType.NEW]: {
    primary: '#14b4ac',
    secondary: '#EEE',
    third: '#afffec',
  },
  [DealsMockType.CANCELED]: {
    primary: '#6e0a0a',
    secondary: '#EEE',
    third: '#fa7575',
  },
  [DealsMockType.DEFAULT]: {
    primary: '#4d4c4c',
    secondary: '#EEE',
    third: '#cfd0d6',
  },
  [DealsMockType.TODAY]: {
    primary: '#4d58eb',
    secondary: '#FFF',
    third: '#4d58eb',
  },
  [DealsMockType.URGENCY]: {
    primary: '#ff5260',
    secondary: '#fff',
    third: '#ff5260',
  },
  [DealsMockType.PAST]: {
    primary: '#d3dbf2',
    secondary: '#6a79a0',
    third: '#d3dbf2',
  },
};
