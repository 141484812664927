import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PrivacyPoliciesComponent } from './privacy-policies.component';
import { ModalDeleteAccountModule } from '../modal-delete-account/modal-delete-account.module';

@NgModule({
  declarations: [PrivacyPoliciesComponent],
  imports: [CommonModule, TranslateModule.forChild(), ModalDeleteAccountModule],
  exports: [PrivacyPoliciesComponent],
})
export class PrivacyPoliciesModule {}
