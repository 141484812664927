<div class="wrapper-pagination">
  <button
    class="btn arrow-btn prev"
    (click)="
      viewPeriod === clockHourViewRef.Week
        ? changeWeek(firstWeekDay, 'prev')
        : changeMonth(selectedMonth - 1, 'prev')
    "
  >
    <img src="assets/icons/gl_arrow_left.svg" />
  </button>
  <div *ngIf="viewPeriod === clockHourViewRef.Week" class="filter-info">
    {{ firstWeekDay }}
    {{
      lastWeekDay < firstWeekDay
        ? stringRemainingMonth
        : (stringMonth | titlecase)
    }}
    - {{ lastWeekDay }} {{ stringMonth | titlecase }}
  </div>
  <div *ngIf="viewPeriod === clockHourViewRef.Month" class="filter-info">
    {{ stringMonth | titlecase }} {{ currentYear }}
  </div>
  <button
    class="btn arrow-btn next"
    (click)="
      viewPeriod === clockHourViewRef.Week
        ? changeWeek(lastWeekDay + 1, 'next')
        : changeMonth(selectedMonth + 1, 'next')
    "
  >
    <img src="assets/icons/gl_arrow_left.svg" />
  </button>
</div>
