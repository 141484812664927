import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@web-frontend/environments';

@Pipe({
  name: 'imageFirebase',
})
export class ImageFirebasePipe implements PipeTransform {
  transform(img: string, type: string): string {
    let url = `${environment.firebaseConfig.storageUrl}/uploads`;
    if (!img && type === 'budget-logo') {
      // @ts-ignore
      return null;
    }

    if (!img || !type) {
      return './assets/images/default-user.png';
    }

    //Retrocompatibility
    if (!img.includes('alt=media')) {
      img = img + '?alt=media';
    }

    switch (type) {
      case 'customer':
        url += `%2Fcustomer%2F${img}`;
        break;
      case 'deal':
        url += `%2Fdeal%2F${img}`;
        break;
      case 'evidence':
        url += `%2Fevidence%2F${img}`;
        break;
      case 'expense':
        url += `%2Fexpense%2F${img}`;
        break;
      case 'user':
        url += `%2Fuser%2F${img}`;
        break;
      case 'own-team':
        url += `%2Fown-team%2F${img}`;
        break;
      case 'notes':
        url += `%2Fnotes%2F${img}`;
        break;
      case 'checklist':
        url += `%2Fchecklist%2F${img}`;
        break;
      case 'equipment':
        url += `%2Fequipment%2F${img}`;
        break;
      case 'final':
        url += `%2Ffinal%2F${img}`;
        break;
      case 'address':
        url += `%2Faddress%2F${img}`;
        break;

      case 'vendor':
        url += `%2Fvendor%2F${img}`;
        break;
      case 'project':
        url += `%2Fproject%2F${img}`;
        break;

      case 'budget-logo':
        url += `%2Fbudget-logo%2F${img}`;
        break;

      case 'internal-vendor':
        url += `%2Finternal-vendor%2F${img}`;
        break;
      case 'part-of-service':
        url += `%2Fpart-of-service%2F${img}`;
        break;
      default:
        url = './assets/images/default-user.png';
        break;
    }

    return url;
  }
}
