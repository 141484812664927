import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SelectItemComponent } from './select-item.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SelectItemComponent],
  imports: [
    CommonModule,
    MatSlideToggleModule,
    TranslateModule.forChild(),
    MatProgressSpinnerModule,
  ],
  exports: [SelectItemComponent],
})
export class SelectItemModule {}
