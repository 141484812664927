import { BillLineDTO } from '../interfaces';

export class BillLine {
  _id: string;
  name: string;
  price_bool?: boolean;
  tax: number;
  price: number;
  transport?: number;
  quantity?: number;
  priceClient?: number;
  priceSubTotalTaxClient?: number;
  priceSupplier?: number;
  priceSubTotalTaxSupplier?: number;
  priceTotalSupplier?: number;
  priceTotalClient?: number;
  retention: number;
  service: string;
  tasker_id?: string;
  marginClient: number;
  marginSupplier: number;
  firstLine: boolean;
  description?: string;

  static fromArray(attributes: BillLineDTO[]) {
    if (attributes?.length) {
      attributes = attributes.map((attribute, index) => {
        if (index === 0) {
          attribute.firstLine = true;
        }
        return attribute;
      });
    }
    return attributes.map((attribute) => new BillLine(attribute));
  }

  constructor(attribute: BillLineDTO) {
    const marginClient = attribute?.marginClient
      ? attribute.marginClient
      : attribute?.firstLine
      ? 0.8
      : null;

    const marginSupplier = attribute?.marginSupplier
      ? attribute.marginSupplier
      : attribute?.firstLine
      ? 0.8
      : null;

    Object.assign(this, attribute, { marginClient, marginSupplier });
  }

  costTransport() {
    return this.firstLine ? this.transport : 0;
  }

  priceWithTax() {
    const servicePrice = this.price * this.quantity + this.costTransport();

    const taxes = (servicePrice * this.tax) / 100;

    return taxes;
  }

  priceValueWithTax() {
    const servicePrice = this.price * this.quantity + this.costTransport();

    return servicePrice;
  }

  priceCustomerTax() {
    let taxes = 0;
    taxes = this.priceClient * (this.tax / 100);

    return taxes;
  }
}
