import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LogoCropComponent } from './logo-crop-preview/logo-crop.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LogoCropService {
  private refreshListSource = new Subject<boolean>();
  refreshList$ = this.refreshListSource.asObservable();
  MEGABYTES = 0.000001;
  maxSize = 5;

  constructor(private dialog: MatDialog, private i18n: TranslateService) {}

  open(config: MatDialogConfig = new MatDialogConfig()) {
    config.width = '600px';
    config.minWidth = '380px';
    config.height = 'auto';

    const dialogRef = this.dialog.open(LogoCropComponent, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }

  allowedDimensions(file: File) {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject('fileUpload.uploadError');
      }

      if (
        !['image/jpeg', 'image/jpg', 'image/png'].some((type) =>
          file.type.startsWith(type)
        )
      ) {
        reject('logo-crop.wrongFormat');
      }

      if (file.size * this.MEGABYTES > this.maxSize) {
        const error = this.i18n
          .instant('fileUpload.maxSize')
          .replace('{arg1}', this.maxSize.toString());
        reject(error);
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target?.result as string;
        image.onload = () => {
          const width = image.width;
          const height = image.height;

          if (width > 1500 || height > 1500) {
            reject('logo-crop.max-size');
          }

          resolve(true);
        };
      };
    });
  }
}
