<div class="col-sm-12 col-md-12">
  <div class="form-group">
    <label for="contractHours">
      {{ 'person.create.form.contractHours' | translate }}
    </label>
    <roma-hour-select
      (workingHoursChange)="onWorkingHoursChange($event)"
      (workingHoursPeriodChange)="onWorkingHoursPeriodChange($event)"
      (validationError)="handleValidationHoursError($event)"
      (updateErrorHoursMin)="updateErrorHours($event)"
      [hasExternalError]="!!workingHoursError"
      [errorHoursMin]="errorHoursMin"
      [defaultValueHours]="form.get('contract.workingHours').value"
      [defaultValuePeriod]="form.get('contract.workingHoursPeriod').value"
      maxLength="6"
      maxDecimals="2"
    ></roma-hour-select>
    <ng-container *ngIf="!!workingHoursError">
      <div class="error-message">
        {{ workingHoursError | translate }}
      </div>
    </ng-container>
  </div>
</div>

<div class="col-sm-12 col-md-12">
  <div class="form-group">
    <label for="workingDays">
      {{ 'person.create.form.workingDays' | translate }}
    </label>
    <roma-day-select
      (workingDaysChange)="onWorkdaysChange($event)"
      [defaultValue]="form.get('contract.workdays').value"
    ></roma-day-select>
  </div>
</div>
