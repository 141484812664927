<button
  [ngClass]="[buttonClass, size]"
  [disabled]="isDisabled"
  [ngStyle]="style"
>
  <roma-loading
    *ngIf="isLoading"
    [size]="loadingSize"
    [loadingClass]="loadingClass"
  ></roma-loading>
  <img *ngIf="!isLoading && icon" [src]="icon" class="icon" />
  <ng-content *ngIf="!isLoading"></ng-content>
</button>
