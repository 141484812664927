import { AbstractControl, ValidatorFn } from '@angular/forms';

export function FormatEmailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    // eslint-disable-next-line no-useless-escape
    const regex = /^[\w\+\-\.]+@(?:[\w-]+\.)+[a-z]{2,13}$/;
    const valid = regex.test(value);
    return valid ? null : { email: { value: control.value } };
  };
}

export function FormatEmailValidatorAllowNull(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (
      control.value === null ||
      control.value === '' ||
      control.value === undefined
    ) {
      return null;
    }
    const value = control.value;
    // eslint-disable-next-line no-useless-escape
    const regex = /^[\w\+\-\.]+@(?:[\w-]+\.)+[a-z]{2,13}$/;
    const valid = regex.test(value);
    return valid ? null : { email: { value: control.value } };
  };
}
