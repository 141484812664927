export interface FileSelectOptions {
  extensions?: string[];
  multiple?: boolean;
  maxSize?: number;
}

export enum ExtensionsAdmit {
  // example .mp3
  Audio,
  // example .mp4
  Video,
  // example .xls
  Excel,
  // example .png .jpg .jpeg
  Images,
  // example .pdf
  Pdf,
  // example .zip .word. .ppt
  Files,
}

export interface LibraryResponse {
  paths: string[];
  original_names: string[];
  extensions: string[];
  size: string[];
}

export const FileSelectDefaultOptions: FileSelectOptions = {
  extensions: ['.pdf', 'pdf', '.jpg', 'jpg', '.png', 'png', '.jpeg', 'jpeg'],
  multiple: false,
};

export enum FileSelectError {
  MaxSizeOverflow = 'MAX_SIZE_OVERFLOW',
}
