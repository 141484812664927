<mat-progress-bar
  class="progress-bar"
  *ngIf="printing"
  mode="indeterminate"
></mat-progress-bar>

<div
  id="sale-preview-container"
  style="position: relative; display: flex; flex-direction: column; width: 100%; height: 100%; border-radius: 8px; border: 1px solid #e3ebfa; background: #fff; padding: 16px;"
  [ngClass]="{
    opacity: !(saleStorage$ | async)?.items?.length || isLoading,
    finish__card:
      (saleSteps$ | async)?.paymentRefundFinish ||
      (saleSteps$ | async)?.paymentFinish ||
      (saleSteps$ | async)?.paymentUnpaid ||
      (saleSteps$ | async)?.paymentPending ||
      (saleSteps$ | async)?.paymentCancelFinish ||
      (saleSteps$ | async)?.paymentRejected
  }"
>
  <div
    *ngIf="
      (saleSteps$ | async)?.paymentUnpaid ||
      (saleSteps$ | async)?.paymentPending ||
      (saleSteps$ | async)?.paymentRefundFinish ||
      (saleSteps$ | async)?.paymentFinish ||
      (saleSteps$ | async)?.paymentCancelFinish ||
      (saleSteps$ | async)?.paymentRejected
    "
    [ngStyle]="getStatusStyles()" style="border-radius: 6px; text-transform: uppercase; letter-spacing: 0.6px; font-size: 11px; font-weight: 600; text-align: center; height: 20px; width: fit-content; padding: 0 8px; margin: 12px auto;">
    <ng-container *ngIf="(saleSteps$ | async)?.paymentUnpaid">
      {{ 'sales.salesCard.unpaid' | translate }}
    </ng-container>
    <ng-container *ngIf="(saleSteps$ | async)?.paymentPending">
      {{ 'status.paymentPending' | translate }}
    </ng-container>
    <ng-container *ngIf="(saleSteps$ | async)?.paymentRefundFinish">
      {{ 'sales.salesCard.refunded' | translate }}
    </ng-container>
    <ng-container *ngIf="(saleSteps$ | async)?.paymentFinish">
      {{ 'sales.salesCard.paid' | translate }}
    </ng-container>
    <ng-container *ngIf="(saleSteps$ | async)?.paymentCancelFinish">
      {{ 'status.cancelled' | translate }}
    </ng-container>
    <ng-container *ngIf="(saleSteps$ | async)?.paymentRejected">
      {{ 'status.paymentRejected' | translate }}
    </ng-container>
  </div>
  <h3
    class="preview-title"
    [ngClass]="{
      bold__text:
        (saleSteps$ | async)?.paymentRefundFinish ||
        (saleSteps$ | async)?.paymentUnpaid ||
        (saleSteps$ | async)?.paymentPending ||
        (saleSteps$ | async)?.paymentFinish ||
        (saleSteps$ | async)?.paymentCancelFinish ||
        (saleSteps$ | async)?.paymentRejected
    }"
    style="
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    font-weight: 600;
    "
  >
    {{
      (saleStorage$ | async)?.final?.title
        ? (saleStorage$ | async)?.final?.title
        : ('sales.salesCard.clientNotAssigned' | translate)
    }}
  </h3>
  <ng-container
    *ngIf="
      (saleSteps$ | async)?.paymentUnpaid ||
      (saleSteps$ | async)?.paymentPending ||
      (saleSteps$ | async)?.paymentRefundFinish ||
      (saleSteps$ | async)?.paymentFinish ||
      (saleSteps$ | async)?.paymentCancelFinish ||
      (saleSteps$ | async)?.paymentRejected
    "
  >
    <p class="payment__date payment__info__text" style="font-size: 12px; text-align: center; color: #202046; line-height: 16px; margin-bottom: 4px;">
      {{
        getPaymentDateInLocale(
          (saleStorage$ | async)?.sale?.paymentDate,
          'none'
        )
      }}
    </p>
    <p class="payment__number payment__info__text" style="font-size: 12px; text-align: center; color: #202046; line-height: 16px; margin-bottom: 4px;">
      {{ 'sales.salesCard.sale' | translate }} #{{
        (saleStorage$ | async)?.sale?.number
      }}
    </p>

    <p
      *ngIf="refundedSale"
      class="payment__number payment__info__text" style="font-size: 12px; text-align: center; color: #202046; line-height: 16px; margin-bottom: 4px;"
    >
      {{ refundText | translate }}
      <span (click)="openSale(saleStorage$.source.value)"
        >#{{ (saleStorage$ | async)?.sale?.refund?.number }}</span
      >
    </p>
  </ng-container>

  <hr />

  <div class="preview-table" style="flex: 1; overflow-y: auto; overflow-x: hidden;">
    <roma-rates-select-table
      [dealProducts]="(saleStorage$ | async)?.items"
      [showTotals]="false"
      [showActions]="false"
    ></roma-rates-select-table>
    <div class="amounts__wrapper" style="margin-top: auto; padding-top: 36px;">
      <!-- subtotal -->
      <ng-container>
        <div class="row__col" style="display: flex; justify-content: space-between; border-top: 1px solid #e3ebfa;">
          <div class="col__box" style="">
            <p>Subtotal</p>
          </div>
 
          <div
            class="col__box col-right"
            style=""
            *ngIf="(saleStorage$ | async)?.subtotal"

            >
            <p>{{
              amountCheckRefund$((saleStorage$ | async)?.subtotal)
                | async
                | currencyFormat
            }}
            </p>
          </div>
        </div>
      </ng-container>
      <!--/ subtotal -->

      <!-- discount -->
      <ng-container *ngIf="(saleStorage$ | async)?.discount">
        <div class="row__col" style="display: flex; justify-content: space-between;">
          <div class="col__box" style="align-self: flex-start;">
            <p>
              {{ 'sales.salesCard.discount' | translate }}
              {{ (saleStorage$ | async)?.discount }}%
            </p>
          </div>

          <div class="col__box col-right">
            <p>-{{
              amountCheckRefund$((saleStorage$ | async)?.discountAmount)
                | async
                | currencyFormat
            }}
            </p>
          </div>
        </div>
      </ng-container>
      <!--/ discount -->

      <!-- taxes -->
      <ng-container *ngIf="(saleStorage$ | async)?.taxes?.length">
        <ng-container *ngFor="let tax of (saleStorage$ | async)?.taxes">
          <div class="row__col" style="display: flex; justify-content: space-between;">
            <div class="col__box"  style="align-self: flex-start;">
              <p>{{ tax?.translate | translate }} {{ tax?.value }}%</p>
            </div>

            <div class="col__box col-right">
              <p>{{ amountCheckRefund$(tax?.amount) | async | currencyFormat }}</p>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <!--/ taxes -->
    </div>
  </div>

  <div class="preview-totals"  style=" bottom: 16px; width: 100%; background-color: #ffffff;">
    <div
      *ngIf="
        !(saleSteps$ | async)?.paymentFinish &&
        (!(saleSteps$ | async)?.paymentUnpaid ||
          !(saleSteps$ | async)?.paymentPending)
      "
      class="wrapper__amounts ticket__total__amount"
      style="display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;"
    >
      <div class="wrapper__amounts__left" style="width: 50%;  text-align: left;">
        <strong style="font-weight: 600; font-size: 14px;">Total</strong>
      </div>

      <div
        *ngIf="(saleStorage$ | async)?.total"
        class="wrapper__amounts__right"
        style="width: 50%;  text-align: right;"
      >
        <strong>
          {{
            amountCheckRefund$((saleStorage$ | async)?.total)
              | async
              | currencyFormat
          }}
        </strong>
      </div>
    </div>

    <hr
      [ngClass]="{
        dashed:
          (saleSteps$ | async)?.paymentFinish ||
          (saleSteps$ | async)?.paymentUnpaid ||
          (saleSteps$ | async)?.paymentPending
      }"
    />

    <div class="row__col payment__total__amount" style="display: flex; justify-content: space-between">
      <div class="col__box">
        <h2 style="">
          <ng-container
            *ngIf="
              (saleSteps$ | async)?.saleForm ||
              (saleSteps$ | async)?.paymentMethods
            "
          >
            {{ 'sales.salesCard.pay' | translate }}
          </ng-container>
          <ng-container *ngIf="(saleSteps$ | async)?.paymentRefund">
            {{ 'sales.salesCard.refund2' | translate }}
          </ng-container>
          <ng-container
            *ngIf="
              (saleSteps$ | async)?.paymentFinish ||
              (saleSteps$ | async)?.paymentUnpaid ||
              (saleSteps$ | async)?.paymentPending
            "
          >
            Total
          </ng-container>
        </h2>
      </div>

      <div class="col__box col-right">
        <h2 *ngIf="(saleStorage$ | async)?.total">
          {{
            amountCheckRefund$((saleStorage$ | async)?.total)
              | async
              | currencyFormat
          }}
        </h2>
      </div>
    </div>

    <div
      *ngIf="
        (saleSteps$ | async)?.paymentFinish ||
        (saleSteps$ | async)?.paymentRefundFinish
      "
      class="payment__date__info"
      style="text-align: center;
      font-size: 12px;
      margin-top: 16px;
      color: #202046;"
    >
      {{ 'sales.salesCard.paidWith' | translate }}
      {{ translatePaymentMethod$() | async | translate | lowercase }}:
      {{
        getPaymentDateInLocale(
          (saleStorage$ | async)?.sale?.paymentDate,
          'hour'
        )
      }}

      <p class="secondary-color mt-1" style="color: #97a3c5; margin-top: 16px">
        {{ (saleStorage$ | async)?.sale?.paymentIntentId || null }}
      </p>
    </div>

    <div class="row actions__row">
      <div class="col-12">
        <!-- {{ (saleStorage$ | async)?.items?.length }} -
      {{ (saleStorage$ | async)?.total === 0 }} -
      {{ (saleStorage$ | async)?.final?.title }} -->
        <button
          *ngIf="(saleSteps$ | async)?.saleForm"
          [ngClass]="{
            disabled:
              !(saleStorage$ | async)?.items?.length ||
              (saleStorage$ | async)?.total === 0
          }"
          [disabled]="(saleStorage$ | async)?.total === 0"
          class="roma-button add-button-3"
          (click)="submitSaleForm.next()"
          (mouseenter)="showDialog()"
          (mouseleave)="showDialog()"
        >
          {{ 'sales.salesCard.paymentContinue' | translate }}
        </button>

        <div class="tooltip-container">
          <div
            *ngIf="showToolTip"
            class="title-tooltip-wrapper"
            (click)="toggleTooltip(false)"
          ></div>
          <div
            class="help-tooltip"
            *ngIf="showToolTip"
            romaRomaToolTip
            [showToolTip]="showToolTip"
          >
            <div>{{ 'sales.salesCard.paymentTooltip' | translate }}</div>
          </div>
        </div>

        <button
          *ngIf="
            (saleSteps$ | async)?.paymentMethods &&
            !(saleSteps$ | async)?.paymentRefund
          "
          [disabled]="
            selectedPaymentMethod === salePaymentMethodRef.None || isLoading
          "
          class="roma-button add-button-3"
          (click)="generatePayment.next()"
        >
          {{
            [selectedPaymentMethod].includes(
              salePaymentMethodRef.PaymentLink,
              salePaymentMethodRef.Transfer
            )
              ? ('sales.salesCard.chargeAndShareLink' | translate)
              : ('sales.salesCard.charge' | translate)
          }}
        </button>

        <button
          *ngIf="(saleSteps$ | async)?.paymentRefund"
          [disabled]="
            selectedPaymentMethod === salePaymentMethodRef.None ||
            isLoading ||
            refundStart
          "
          class="roma-button add-button-3 danger-button"
          (click)="finishRefund.next()"
        >
          <mat-progress-spinner
            *ngIf="refundStart"
            class="loading-spinner"
            mode="indeterminate"
            [diameter]="20"
          ></mat-progress-spinner>
          {{ 'sales.salesCard.refund' | translate }}
        </button>

        <button
          *ngIf="
            (saleSteps$ | async)?.paymentMethods &&
            selectedPaymentMethod === salePaymentMethodRef.None &&
            type !== 'EDIT'
          "
          class="roma-button alt-button"
          (click)="saveUnPaid.next()"
        >
          {{ 'sales.salesCard.saveAsUnpaid' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
