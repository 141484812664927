import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ClockHour } from '@tacliatech/types';
import { MobileClockHourAdmin } from '@web-frontend/shared/components/clock-hours';
import { format } from 'date-fns';

@Component({
  selector: 'roma-list-mobile-clock-hour-admin',
  templateUrl: './list-mobile-clock-hour-admin.component.html',
  styleUrls: ['./list-mobile-clock-hour-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListMobileClockHourAdminComponent implements OnInit {
  _data: ClockHour.Output[] = [];
  @Input()
  set data(data: ClockHour.Output[]) {
    this._data = data;
    this.source = data.map((item) => {
      return {
        title: item.owner.name,
        description: item.owner?.position || '',
        id: item._id,
        img: '',
        status: item.status,
        startDate: format(item.dateFormatted, 'DD/MM/YYYY'),
        endDate: this.getClockHoursDuration(item),
        hour: '',
      };
    });
    this.draw();
  }

  get data(): ClockHour.Output[] {
    return this._data;
  }

  source: MobileClockHourAdmin.Content[] = [];

  @Output()
  actions = new EventEmitter<MobileClockHourAdmin.Action>();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.draw();
  }

  makeActions(action: MobileClockHourAdmin.Action): void {
    this.actions.next(action);
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  private getClockHoursDuration(clockHour: ClockHour.Output): string {
    try {
      let hourDuration = ClockHour.getTotalHourFromClockHours([clockHour], 0);
      while (hourDuration.charAt(0) === '0' && hourDuration.charAt(2) === ':') {
        hourDuration = hourDuration.substring(1);
      }
      return hourDuration.replace(':', 'hr ') + 'm';
    } catch (error) {
      return '0hr 00m';
    }
  }
}
