import { BudgetStatus } from '../enum';
import { IStatus } from '../interfaces';

export const STATUS_BUDGET_CREATED = {
  id: BudgetStatus.Created,
  name: 'Creado',
  translate: 'status.budget.created',
  order: 1,
  allowEdit: false,
  color: '#E8EDFF',
};

export const STATUS_BUDGET_SENT = {
  id: BudgetStatus.Sent,
  name: 'Enviado',
  translate: 'status.budget.sent',
  order: 2,
  allowEdit: false,
  color: '#F3EFFF',
};

export const STATUS_BUDGET_ACCEPTED = {
  id: BudgetStatus.Accepted,
  name: 'Aceptado',
  translate: 'status.budget.accepted',
  order: 3,
  allowEdit: false,
  color: '#C2E6F8',
};

export const STATUS_BUDGET_REJECTED = {
  id: BudgetStatus.Rejected,
  name: 'Cobrado',
  translate: 'status.budget.rejected',
  order: 4,
  allowEdit: false,
  color: '#FEEBED',
};

export const STATUS_BUDGET_PAID = {
  id: BudgetStatus.Paid,
  name: 'Pagado',
  translate: 'status.budget.paid',
  order: 5,
  allowEdit: false,
  color: '#E1F7EB',
};

export const STATUS_BUDGET_INVOICED = {
  id: BudgetStatus.Invoiced,
  name: 'Facturado',
  translate: 'status.budget.invoiced',
  order: 6,
  allowEdit: false,
  color: '#C2E6F8',
};

export const STATUS_BUDGET_CANCELLED = {
  id: BudgetStatus.Cancelled,
  name: 'Cancelado',
  translate: 'status.budget.cancelled',
  order: 7,
  allowEdit: false,
  color: '#FEEBED',
};

export const STATUS_BUDGET_OVERDUE = {
  id: BudgetStatus.Overdue,
  name: 'Vencido',
  translate: 'status.budget.overdue',
  order: 8,
  allowEdit: false,
  color: '#FEE7DA',
};

export const statusBudget: IStatus[] = [
  STATUS_BUDGET_CREATED,
  STATUS_BUDGET_SENT,
  STATUS_BUDGET_ACCEPTED,
  STATUS_BUDGET_REJECTED,
  STATUS_BUDGET_PAID,
  STATUS_BUDGET_INVOICED,
  STATUS_BUDGET_CANCELLED,
  STATUS_BUDGET_OVERDUE,
].sort((a, b) => (a.order > b.order ? 1 : -1));

export const STATUS_BUDGET_SORT = [0, 10, 100, 20, 30, 60, 95, 90, 50];
