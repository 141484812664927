import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalTemplateModule } from '../modal-template/modal-template.module';
import { TranslateModule } from '@ngx-translate/core';
import { ModalDeleteAccountComponent } from './modal-delete-account.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [ModalDeleteAccountComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    TranslateModule.forChild(),
    MatProgressBarModule,
  ],
  exports: [ModalDeleteAccountComponent],
})
export class ModalDeleteAccountModule {}
