import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  BDG_SETTINGS,
  BudgetsSetting,
  IBudgetsSetting,
  ISequence,
  SequenceTypes,
  Tax,
} from '@tacliatech/types';
import { SandboxService, StorageService } from '@web-frontend/shared/services';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { SequenceService } from '@web-frontend/shared/services/sequence';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CreateSequenceComponent } from '../create-sequence/create-sequence.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalMobileInfoComponent } from '@web-frontend/shared/components/modal-mobile-info';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';

@Component({
  selector: 'roma-budget-sequence-settings',
  templateUrl: './budget-sequence-settings.component.html',
  styleUrls: ['./budget-sequence-settings.component.scss'],
})
export class BudgetSequenceSettingsComponent implements OnInit, OnDestroy {
  constructor(
    private sequenceService: SequenceService,
    private changeDetectorRef: ChangeDetectorRef,
    private budgetService: BudgetService,
    private toastService: ToastService,
    private i18n: TranslateService,
    public dialog: MatDialog,
    private sandBoxService: SandboxService,
    private amplitudeService: AmplitudeService
  ) {}

  @Input()
  taxes: Tax[] = [];

  defaultTaxes: Tax[] = [];
  selectedTaxes: Tax[] = [];
  selectedTaxes$ = new BehaviorSubject<Tax[]>(null);
  resize$ = this.sandBoxService.screenBusChannel$;

  _budget: BudgetsSetting;
  @Input()
  set budget(budget: BudgetsSetting) {
    this._budget = budget;
    this.selectedTax = this.budget?.invoiceSettings?.rateDefault;
  }
  get budget() {
    return this._budget;
  }

  selectedTax: any;
  sequences: ISequence[] = [];
  name: string;

  settings: IBudgetsSetting;
  isLoading = false;
  expandedAccordion = false;

  private sub$ = new Subscription();
  showTaxesMobileModalList = false;

  ngOnInit(): void {
    this.getAllSequences();

    //
    this.resolveTaxes();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  getAllSequences() {
    this.isLoading = true;
    this.sub$.add(
      this.sequenceService.getAllSequences().subscribe((res) => {
        this.sequences = res.results;
        this.draw();
      })
    );
  }

  getSettings() {
    this.sub$.add(
      this.budgetService
        .findSettingsByCompany(StorageService.CompanyId)
        .subscribe((res) => {
          this.settings = res;
          if (
            this.settings.invoiceSettings.starterNumber != null &&
            this.sequences?.length == 0
          ) {
            //create first sequence and delete invoiceSettings
            this.addFirstSequence();
          } else {
            this.isLoading = false;
          }
        })
    );
  }

  addFirstSequence() {
    //create the first sequence with actual data, delete actual data this.settings.invoiceSettings.starterNumber...
  }

  addSequence() {
    // Send amplitude event
    this.amplitudeService.sendEvent({
      event: 'settings_addSerie_start',
    });

    //open component add/edit
    const dialog = this.dialog.open(CreateSequenceComponent, {
      data: null,
    });
    dialog.afterClosed().subscribe((result) => {
      this.getAllSequences();
    });
  }

  editSequence(sequence) {
    //open component add/edit
    const dialog = this.dialog.open(CreateSequenceComponent, {
      data: sequence,
    });

    dialog.afterClosed().subscribe((result) => {
      this.getAllSequences();
    });
  }

  deleteSequence(sequence) {
    this.sub$.add(
      this.sequenceService.deleteSequence(sequence.id).subscribe((res) => {
        this.toastService.show({
          msDuration: 2000,
          type: 'error',
          text: 'sequence.deletedSuccess',
          icon: 'assets/icons/kanban/delete.svg',
          class: '',
        });
        this.getAllSequences();
      })
    );
  }

  afterExpansionPanelCollapse() {
    this.expandedAccordion = false;
    this.draw();
  }

  submitBudget(value: Tax = null) {
    if (!value || value === undefined) {
      return;
    }
    const prevTax = this.selectedTaxes.findIndex(
      (tax) => tax._id === this.selectedTax._id
    );
    if (prevTax >= 0) {
      this.selectedTaxes.splice(prevTax, 1);
    }
    const index = this.selectedTaxes.findIndex((tax) => tax._id === value._id);
    if (index < 0) {
      const taxes = [...this.selectedTaxes];
      taxes.push(value);
      this.submitTaxes(taxes);
    }

    this.selectedTax = value;

    this.budget = {
      ...this.budget,
      invoiceSettings: {
        ...this.budget?.invoiceSettings,
        rateDefault: this.selectedTax,
      },
    };

    this.budget.invoiceSettings.rateDefault = this.selectedTax;
    const obs$ = this.budgetService.updateOneSettings(
      this.budget._id,
      this.budget
    );

    this.sub$.add(
      obs$.subscribe(
        (res) => {
          this.toastService.show({
            text: 'budgets.settings.successEdit',
            type: 'success',
            msDuration: 4000,
            class: '',
          });
        },
        (error) => {
          this.toastService.show({
            text: 'activity.error',
            type: 'error',
            msDuration: 4000,
            icon: 'assets/icons/kanban/delete.svg',
            class: '',
          });
        }
      )
    );
  }

  getTranslationBySequenceType(sequenceType: string) {
    if (sequenceType === SequenceTypes.ADD_BUDGETS) {
      return this.i18n.instant('sequence.types.allBudgets');
    }
    return this.i18n.instant(`sequence.types.${sequenceType.toLowerCase()}`);
  }

  changeComma(value: number) {
    const valueSttring = String(value);
    return valueSttring.replaceAll('.', ',');
  }

  resolveTaxes() {
    this.selectedTaxes = this.budget.invoiceSettings?.defaultTaxesList || [];
    this.selectedTaxes$.next(this.selectedTaxes);

    this.defaultTaxes = this.taxes.map((tax) => {
      return {
        title: tax.name,
        value: tax.value,
        _id: tax._id,
        translate: tax.translate,
        type: tax.type,
      };
    });
  }

  changeTaxes(evt: Tax[]) {
    this.selectedTaxes = evt;
    this.submitTaxes(evt);
  }

  submitTaxes(taxes: Tax[]) {
    this.selectedTaxes$.next(taxes);
    this.budget = {
      ...this.budget,
      invoiceSettings: {
        ...this.budget?.invoiceSettings,
        defaultTaxesList: taxes,
      },
    };

    const obs$ = this.budgetService.updateOneSettings(
      this.budget._id,
      this.budget
    );

    this.sub$.add(
      obs$.subscribe(
        (res) => {
          this.toastService.show({
            text: 'budgets.settings.successEdit',
            type: 'success',
            msDuration: 4000,
            class: '',
          });
          const budgetSettingsData = JSON.parse(StorageService.budgetSettings);
          StorageService.SetItem(BDG_SETTINGS, {
            ...budgetSettingsData,
            invoiceSettings: this.budget?.invoiceSettings,
          });
        },
        (error) => {
          this.toastService.show({
            text: 'activity.error',
            type: 'error',
            msDuration: 4000,
            icon: 'assets/icons/kanban/delete.svg',
            class: '',
          });
        }
      )
    );

    this.draw();
  }

  activeTaxes(taxes: Tax[] = this.selectedTaxes): Tax[] {
    return taxes;
  }

  showDefaultTaxesListDialog(): void {
    const isMobile = window.screen.width < 768;

    if (isMobile) {
      const dialog = this.dialog.open(ModalMobileInfoComponent, {
        data: {
          html: 'budgets.settings.create.form.selectedTaxesTooltip',
          buttonText: 'kanban.dialog.confirmButton',
        },
      });
    }
  }

  showSequenceTrash(sequence: ISequence): boolean {
    const sequenceTypesWithMinOneRegister = [
      SequenceTypes.BILL,
      SequenceTypes.BUDGET,
      SequenceTypes.PROFORM,
      SequenceTypes.WAYBILLS,
      SequenceTypes.CREDIT_NOTE,
    ];

    return (
      this.sequences.filter((it) => it.type === sequence.type).length > 1 ||
      !sequenceTypesWithMinOneRegister.includes(sequence.type)
    );
  }
}
