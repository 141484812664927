import { PaginateSort } from '@tacliatech/types';

export const INIT_DEAL_SEARCH_PARAMS = () => {
  return {
    limit: 50,
    page: 1,
    sort: PaginateSort.DESC,
    applyPaginate: true,
    keyword: null,
    status: null,
    author: null,
    from: null,
    to: null,
    'sortOptions[dateCreated]': PaginateSort.DESC,
    takeAll: false,
    takeCancelled: false,
    takeToday: null,
    takeNotified: false,
    takeFinished: 'false',
    customProperties: null,
    'vendors[]': [],
    'customers[]': [],
    'dealTypes[]': [],
    'authors[]': [],
    'status[]': [],
    'assets[]': [],
    'finals[]': [],
    'projects[]': [],
    'managers[]': [],
    'taskers[]': [],
    optimize: true,
    emptyParentRecurrence: true,
  };
};
