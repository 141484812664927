import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { COMPANY_ID_KEY, PartOfServiceGlobal } from '@tacliatech/types';
import { tap } from 'rxjs/operators';

import { FileWriteService } from '@web-frontend/shared/helpers/file-write';

import { v4 as uuidv4 } from 'uuid';

import { FileDownloadService } from '@web-frontend/shared/helpers/file-download';
import { StorageService } from '../storage';
import { AuthService } from '@web-frontend/shared/services/auth/auth.service';
import { environment } from '@web-frontend/environments';

@Injectable({
  providedIn: 'root',
})
export class PartOfServiceProvider {
  constructor(
    private httpClient: HttpClient,
    private fileWriteService: FileWriteService,
    private fileDownloadService: FileDownloadService,
    private authService: AuthService
  ) {}

  findByDeal(idDeal: string) {
    return this.httpClient.get<PartOfServiceGlobal.PartOfServiceResponse>(
      `:API_URL/deals/${idDeal}/part-of-services`
    );
  }

  findOneAndUpdate(id: string, data: PartOfServiceGlobal.PartOfServiceUpdate) {
    return this.httpClient.put(`:API_URL/part-of-services/${id}`, { ...data });
  }

  downloadDocument(data: { id: string; lang?: string; idCompany: string }) {
    return this.httpClient
      .post(`:API_URL/part-of-services/${data.id}/reports/`, data, {
        responseType: 'blob',
      })
      .pipe(
        tap((res: any) => {
          this.fileWriteService
            .writeFile({
              path: `${uuidv4()}.pdf`,
              blob: res,
            })
            .then()
            .catch((err) => {});
        })
      );
  }

  download(data: { id: string; lang?: string; idCompany: string }) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${StorageService.Token}`,
      }),
      responseType: 'blob' as const,
    };

    const module = localStorage.getItem('sectorModule');

    return this.httpClient
      .post(
        `${environment.cloudFunctionCreatePartOfServicePDF}?dealId=${data.id}`,
        {
          lang: data.lang,
          module,
          user: {
            _id: StorageService.UserId,
            company: {
              _id: StorageService.CompanyId,
              // @ts-ignore
              modules: this.authService.user.company.modules.map((m) => {
                return {
                  active: m.active,
                  id: m.id,
                };
              }),
            },
          },
        },
        httpOptions
      )
      .pipe(
        tap((res: any) => {
          this.fileWriteService
            .writeFile({
              path: `${uuidv4()}.pdf`,
              blob: res,
            })
            .then()
            .catch((err) => {
              console.log(err);
            });
        })
      );
  }

  getReportData(id: string) {
    return this.httpClient.post<PartOfServiceGlobal.PartOfServiceReportFormat>(
      `:API_URL/part-of-services/${id}/get-reports-data`,
      {}
    );
  }

  sendByEmail(data: {
    emails: string[];
    idDeal: string;
    subject: string;
    message: string;
    module?: string;
  }) {
    const module = localStorage.getItem('sectorModule');
    data.module = module;
    return this.httpClient.post(`:API_URL/part-of-services/send-by-email`, {
      ...data,
      idCompany: StorageService.GetItem(COMPANY_ID_KEY),
    });
  }
}
