import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { ModalErrorMaintenanceComponent } from './modal-error-maintenance.component';

@Injectable()
export class ModalErrorMaintenanceService {
  private matDialogRef?: MatDialogRef<ModalErrorMaintenanceComponent, any>;

  constructor(private matDialog: MatDialog) {}

  public show(): void {
    if (this.matDialogRef) return;

    this.matDialogRef = this.matDialog.open(ModalErrorMaintenanceComponent, {
      disableClose: true,
      data: {
        canRetry: false,
      },
      panelClass: 'modal-error-maintenance',
    });

    this.matDialogRef
      .afterClosed()
      .pipe(
        tap(() => {
          this.matDialogRef = undefined;
        })
      )
      .subscribe();
  }

  public dismiss(): void {
    if (!this.matDialogRef) return;

    this.matDialogRef.close();
  }
}
