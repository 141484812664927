<roma-modal-template
  [title]="'sales.salesCard.saleDeal' | translate"
  [size]="'sm'"
  [contentMinHeight]="false"
  [textSuccess]="'general.confirm'"
  [headerBottomMargin]="'16px'"
  [disableSuccess]="selectedDeals?.length === 0"
  (cancel)="close()"
  (success)="submit()"
>
  <div class="modal__wrapper">
    <p class="subtitle">
      {{ 'sales.salesCard.saleDealDetail' | translate }}
    </p>
    <rm-deal-select
      [isClearable]="false"
      [placeholderText]="'general.select' | translate"
      [items]="userDealsList"
      [isLoading]="isDealListLoading"
      [activeItems]="selectedDeals"
      [isAbsolute]="false"
      [mode]="'SINGLE'"
      inputHeight="44px"
      type="users"
      [showAddButton]="true"
      (click)="!userDealsListCharged ? requestDeals() : null"
      (changeItems)="changeCalatogueList($event)"
      (addElement)="addDeal()"
      style="width: 100%"
    >
    </rm-deal-select>
  </div>
</roma-modal-template>
