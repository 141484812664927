import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TacliaPayments } from '@tacliatech/types';
import { Stripe } from 'stripe';

@Injectable({
  providedIn: 'root',
})
export class ReaderService {
  constructor(private http: HttpClient) {}

  register(payload: TacliaPayments.Reader.CreateRequest) {
    return this.http.post<Stripe.Response<Stripe.Terminal.Reader>>(
      ':API_URL/terminal/readers',
      payload
    );
  }

  getReaders() {
    return this.http.get<
      Stripe.Response<Stripe.ApiList<Stripe.Terminal.Reader>>
    >(':API_URL/terminal/readers');
  }
}
