import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BaseButtonComponent } from './baseButton.component';
import { LoadingModule } from '@web-frontend/shared/components/loading';

@NgModule({
  declarations: [BaseButtonComponent],
  imports: [CommonModule, TranslateModule.forChild(), LoadingModule],
  providers: [],
  exports: [BaseButtonComponent],
})
export class BaseButtonModule {}
