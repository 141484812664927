import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'hour-input',
  templateUrl: './hour-input.component.html',
  styleUrls: ['./hour-input.component.scss'],
})
export class HourInputComponent implements OnInit {
  @Input()
  hour: number | undefined = undefined;

  @Input()
  minutes: number | undefined = undefined;

  @Input()
  editMode = false;

  @Input()
  disabled = false;

  @Output()
  onChangeHour = new EventEmitter<number>();

  @Output()
  onChangeMinutes = new EventEmitter<number>();

  @Output()
  onChange = new EventEmitter<{ hour: number; minutes: number }>();

  @Output()
  onAccept = new EventEmitter<{ hour: number; minutes: number }>();

  showEditButtons = false;

  initialHour = 0;
  initialMinutes = 0;

  ngOnInit(): void {
    this.initialHour = this.hour;
    this.initialMinutes = this.minutes;
  }

  onChangeInputHour(): void {
    this.onChangeHour.emit(this.hour);
    this.onChange.emit({
      hour: this.hour,
      minutes: this.minutes,
    });
  }

  onChangeInputMinute(): void {
    this.onChangeMinutes.emit(this.minutes);
    this.onChange.emit({
      hour: this.hour,
      minutes: this.minutes,
    });
  }

  validateKeyPress(key: 'hour' | 'minutes', event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    const currentValue = event.target['value'] + inputChar;

    const maxValue = key === 'hour' ? 23 : 59;

    if (!/[0-9]/.test(inputChar)) {
      event.preventDefault();
    } else if (Number(currentValue) > maxValue) {
      event.preventDefault();
    }
  }

  onClickAccept() {
    this.onAccept.emit({
      hour: this.hour,
      minutes: this.minutes,
    });
    this.initialHour = this.hour;
    this.initialMinutes = this.minutes;
    this.close();
  }

  onFocus() {
    if (this.editMode) {
      this.showEditButtons = true;
    }
  }

  close() {
    this.showEditButtons = false;
    this.hour = this.initialHour;
    this.minutes = this.initialMinutes;
  }
}
