<div class="safe-area-padding no-overflow-y">
  <div
    class="custom-modal"
    [ngClass]="[
      responsiveFull ? 'custom-modal--mobile-full' : 'non-full',
      size ? size : ''
    ]"
    [ngStyle]="customStyles"
  >
    <div *ngIf="isLoading" class="overlay-loading absolute">
      <div class="container-spinner">
        <div class="spinner-cdis"></div>
      </div>
    </div>

    <div
      class="back-function"
      *ngIf="back && askBeforeClose"
      romaClickDelete
      [message]="'general.withoutSave'"
      [buttonDelete]="'general.buttonExit'"
      (wantDelete)="abort()"
    >
      <img src="assets/icons/chevron-left-black.svg" alt="" />
      <span>Volver</span>
    </div>

    <div
      class="back-function"
      *ngIf="back && !askBeforeClose"
      (click)="abort()"
    >
      <img src="assets/icons/chevron-left-black.svg" alt="" />
      <span>Volver</span>
    </div>

    <div
      class="custom-modal__header"
      [ngClass]="{ 'non-title': !showHeader }"
      #header
      [ngStyle]="{ 'margin-bottom': headerBottomMargin }"
    >
      <ng-content select="[header]"></ng-content>
      <div
        *ngIf="showCloseBtn && askBeforeClose"
        class="custom-modal__close"
        romaClickDelete
        [message]="'general.withoutSave'"
        [buttonDelete]="'general.buttonExit'"
        (wantDelete)="abort()"
      >
        <img [src]="'assets/icons/gl_close.svg'" />
      </div>
      <div
        *ngIf="showCloseBtn && !askBeforeClose"
        class="custom-modal__close"
        (click)="abort()"
      >
        <img [src]="'assets/icons/gl_close.svg'" />
      </div>
      <div *ngIf="title" class="custom-modal__header__title">
        {{ title | translate }}
      </div>
      <p *ngIf="subtitle" class="custom-modal__subtitle">{{ subtitle }}</p>
    </div>

    <div class="custom-modal__body">
      <ng-content></ng-content>
    </div>

    <div
      class="modalFooter"
      [ngStyle]="{ 'justify-content': actionsAlign }"
      *ngIf="showDefaultButtons; else footerTemplate"
    >
      <base-button
        *ngIf="showSecondaryBtn"
        [isLoading]="isLoading"
        [disabled]="disableSecondary"
        buttonClass="tertiary"
        (click)="onSecondaryClick()"
      >
        {{ textSecondary | translate }}
      </base-button>
      <div class="groupEndDiv">
        <base-button
          *ngIf="showCancelBtn && askBeforeClose"
          [disabled]="disableCancel || isLoading"
          romaClickDelete
          [message]="'general.withoutSave'"
          buttonClass="tertiary"
          [buttonDelete]="'general.buttonExit'"
          (wantDelete)="abort()"
        >
          {{ textCancel | translate }}
        </base-button>

        <base-button
          *ngIf="showCancelBtn && !askBeforeClose"
          [disabled]="disableCancel"
          [isLoading]="isLoading"
          buttonClass="tertiary"
          (click)="abort()"
        >
          {{ textCancel | translate }}
        </base-button>

        <base-button
          *ngIf="showSuccessBtn"
          [disabled]="disableSuccess"
          [isLoading]="isLoading"
          (click)="successEvt()"
        >
          {{ textSuccess | translate }}
        </base-button>
      </div>
    </div>
  </div>
</div>
