import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';

import { FormBuilder, Validators } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  COMPANY_ID_KEY,
  TypeCategory,
  ValidateAllFormFields,
} from '@tacliatech/types';
import { StorageService } from '@web-frontend/shared/services';
import { CategoryExpensesService } from '@web-frontend/shared/services/category-expenses';
import { ProductService } from '@web-frontend/shared/services/products';
import { TypeExpenseService } from '@web-frontend/shared/services/type-expense';
import { Subscription } from 'rxjs';
import { DataParamsAddCommonCategory } from './add-categories-common.types';

@Component({
  selector: 'roma-add-categories-common',
  templateUrl: './add-categories-common.component.html',
  styleUrls: ['./add-categories-common.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCategoriesCommonComponent implements OnInit {
  form = this.fb.group({
    name: ['', [Validators.required]],
    type: ['A'],
    owner: [StorageService.GetItem(COMPANY_ID_KEY), Validators.required],
  });

  typeCategory = TypeCategory;
  categoryTypeTitle =
    this.data.typeCategory === TypeCategory.SALES
      ? '"' + this.i18n.instant('general.sales') + '"'
      : '"' + this.i18n.instant('general.expenses') + '"';
  typeExpenseSelected = 1;
  private sub$ = new Subscription();

  typeExpenses = this.typeExpenseService.findAll();
  isLoading = false;

  setTypeCategoryExpense() {
    if (this.data.typeCategory === TypeCategory.EXPENSES) {
      const value = this.typeExpenses.find(
        (s) => s.id === this.data.object?.typeExpense
      )?.id;
      this.typeExpenseSelected = value;
    }
  }

  constructor(
    private fb: FormBuilder,
    private productService: ProductService,
    private typeExpenseService: TypeExpenseService,
    private categoryExpensesService: CategoryExpensesService,
    private dialogRef: MatDialogRef<AddCategoriesCommonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataParamsAddCommonCategory,
    private i18n: TranslateService
  ) {}

  get params() {
    return this.data;
  }

  get typeExpense() {
    return this.form.get('typeExpense');
  }

  ngOnInit(): void {
    this.resolveParams();
  }

  submit() {
    const isValid = this.checkValidators();
    if (isValid) {
      if (this.data.type === 'ADD') {
        this.handleAddCategory();
      } else {
        this.handleEditCategory();
      }
    }
  }

  private handleEditCategory() {
    this.isLoading = true;

    if (this.data.typeCategory === TypeCategory.SALES) {
      this.sub$.add(
        this.productService
          .updateOne(this.data.object._id, { ...this.form.value })
          .subscribe((res) => {
            this.isLoading = false;
            this.dialogRef.close(res);
          })
      );
    }

    if (this.data.typeCategory === TypeCategory.EXPENSES) {
      this.sub$.add(
        this.categoryExpensesService
          .updateOne(this.data.object._id, { ...this.form.value })
          .subscribe((res) => {
            this.isLoading = false;
            this.dialogRef.close(res);
          })
      );
    }
  }

  private handleAddCategory() {
    this.isLoading = true;

    if (this.data.typeCategory === TypeCategory.SALES) {
      this.sub$.add(
        this.productService.createOne(this.form.value).subscribe((res) => {
          this.isLoading = false;
          this.dialogRef.close(res);
        })
      );
    }

    if (this.data.typeCategory === TypeCategory.EXPENSES) {
      this.sub$.add(
        this.categoryExpensesService
          .createOne(this.form.value)
          .subscribe((res) => {
            this.isLoading = false;
            this.dialogRef.close(res);
          })
      );
    }
  }

  private resolveParams() {
    if (this.data.type === 'EDIT') {
      this.form.patchValue({
        ...this.data.object,
      });
      //this.setTypeCategoryExpense();
    }
  }

  close() {
    this.dialogRef.close(null);
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);
    return this.form.valid;
  }
}
