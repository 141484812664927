import { DealsMockType, GlobalStatus } from '../enum';
import { IStatus } from '../interfaces';

export const StatusAll: IStatus[] = [
  {
    id: GlobalStatus.All,
    name: 'Todos',
    allowEdit: false,
    order: 0,
  },
];

export const STATUS_REQUESTED_BUDGET = {
  id: GlobalStatus.RequestedBudget,
  name: 'Presupuesto solicitado',
  translate: 'status.requested_budget',
  allowEdit: false,
  order: 3,
  color: DealsMockType.REQUESTED,
};

export const STATUS_BUDGETED = {
  id: GlobalStatus.Budgeted,
  name: 'Presupuestado',
  translate: 'status.budgeted',
  allowEdit: false,
  order: 4,
  color: DealsMockType.BUDGETED,
};

export const STATUS_IN_PROCESS = {
  id: GlobalStatus.InProcess,
  name: 'En proceso',
  translate: 'status.in_process',
  allowEdit: false,
  order: 5,
  color: DealsMockType.IN_PROCESS,
};

export const STATUS_FINALIZED = {
  id: GlobalStatus.Finalized,
  name: 'Finalizado',
  translate: 'status.finished',
  allowEdit: false,
  order: 7,
  color: DealsMockType.FINALIZED,
};

export const STATUS_PENDING_APPROVAL = {
  id: GlobalStatus.PendingApproval,
  name: 'Pendiente de aprobación',
  translate: 'status.pending_aprove',
  allowEdit: false,
  order: 6,
  color: DealsMockType.PENDING,
};

export const STATUS_DEAL_CANCELED = {
  id: GlobalStatus.DealCanceled,
  name: 'Cancelado',
  translate: 'status.canceled',
  allowEdit: false,
  order: 8,
  color: DealsMockType.CANCELED,
};

export const STATUS_DEAL_NEW = {
  id: GlobalStatus.New,
  name: 'Nuevos',
  translate: 'status.new',
  allowEdit: false,
  order: 1,
  color: DealsMockType.NEW,
};

export const STATUS_PENDING = {
  id: GlobalStatus.Pending,
  name: 'Pendientes',
  translate: 'status.pending',
  allowEdit: false,
  order: 2,
  color: DealsMockType.NEW,
};

export const Status: IStatus[] = [
  {
    ...STATUS_REQUESTED_BUDGET,
  },
  {
    ...STATUS_BUDGETED,
  },
  {
    ...STATUS_IN_PROCESS,
  },
  {
    ...STATUS_FINALIZED,
  },
  {
    ...STATUS_DEAL_NEW,
  },
].sort((a, b) => (a.order > b.order ? 1 : -1));

export const StatusSorted: IStatus[] = [
  {
    ...STATUS_DEAL_NEW,
  },
  {
    ...STATUS_PENDING,
  },
].sort((a, b) => (a.order > b.order ? 1 : -1));

export const CreateNewOrderArrayObject = (elements: IStatus[]) => {
  return elements.map((el, index) => {
    return {
      id: el.allowEdit ? el._id : el.id,
      order: index + 1,
    };
  });
};

export const CAN_NOT_SEND_BIDDING = [GlobalStatus.Finalized];
