import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { PaymentsService } from '../payments.service';
import { CompanyService } from '@web-frontend/shared/services/company';
import { StorageService } from '@web-frontend/shared/services';
import { finalize } from 'rxjs/operators';
import { ICompany } from '@tacliatech/types';
import Stripe from 'stripe';

@Component({
  selector: 'roma-create-login-link',
  templateUrl: './create-login-link.component.html',
  styleUrls: ['./create-login-link.component.scss'],
})
export class CreateLoginLinkComponent implements OnInit {
  @Output() loginLinkCreated = new EventEmitter<
    Stripe.Response<Stripe.LoginLink>
  >();

  loginLink: string;
  isLoading = false;
  connectedAcount: string;

  constructor(
    private paymentsService: PaymentsService,
    private cdRef: ChangeDetectorRef,
    private companyService: CompanyService
  ) {
    this.getConnectedAccount();
  }

  ngOnInit(): void {
    const connectedAccount = 'acct_1NWLRjQ1uVnkstPF';
  }

  getConnectedAccount() {
    this.companyService
      .findOne(StorageService.CompanyId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.draw();
        })
      )
      .subscribe((res: ICompany) => {
        this.connectedAcount = res.stripe?.connectedAccountId;

        this.paymentsService
          .createLoginLink(this.connectedAcount)
          .subscribe((data: Stripe.Response<Stripe.LoginLink>) => {
            this.loginLink = data.url;
            this.loginLinkCreated.emit(data);
            this.draw();
          });
      });
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  gotoLink() {
    const winRef = window.open(this.loginLink);
    // if the "target" window was just opened, change its url

    return winRef;
  }
}
