import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DealStatusColors } from '../deal-status-manager-.const';

@Component({
  selector: 'roma-choose-color',
  templateUrl: './choose-color.component.html',
  styleUrls: ['./choose-color.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseColorComponent implements OnInit {
  @Input()
  color!: string;

  @Output()
  change = new EventEmitter<{ lightColor: string; darkColor: string }>();

  colors = DealStatusColors;

  // this property is used outside of the component
  public selectedColor!: { lightColor: string; darkColor: string };

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.resolveSelectedColor();
  }

  ngAfterViewInit(): void {}

  private resolveSelectedColor(): void {
    const colors = DealStatusColors;

    const selectedDefaultColor = () => {
      this.selectedColor = colors[0];
    };

    if (!this.color) {
      selectedDefaultColor();
    }

    const color = colors.find(
      (color) => color.lightColor.toLowerCase() === this.color?.toLowerCase()
    );

    if (color) {
      this.selectedColor = color;
    } else {
      selectedDefaultColor();
    }

    this.draw();
  }

  selectColor(color: { lightColor: string; darkColor: string }): void {
    this.selectedColor = color;
    this.change.next(this.selectedColor);
    this.draw();
  }

  private draw(): void {
    this.cdRef.detectChanges();
  }
}
