import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private scrollDown = new BehaviorSubject<boolean>(true);

  scrollDown$ = this.scrollDown.asObservable();

  set scroll(value: boolean) {
    this.scrollDown.next(value);
  }
}
