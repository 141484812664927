import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Feature } from '@tacliatech/types';
import { SandboxService } from '@web-frontend/shared/services';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { Subscription } from 'rxjs';
import { DownloadBtnMobileComponent } from './mobile-version/download-btn-mobile.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { DownloadBtnService } from './download-btn.service';
import { MatDialog } from '@angular/material/dialog';
import { DownloadBySelectionModalComponent } from '../download-by-selection-modal/download-by-selection-modal.component';

export enum DownloadType {
  EXCEL = 'excel',
  PDF = 'pdf',
}

@Component({
  selector: 'roma-shared-download-btn',
  templateUrl: './download-btn.component.html',
  styleUrls: ['./download-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadBtnComponent implements OnInit, OnDestroy {
  featureRef = Feature;
  @Input() featureExcel: Feature.Purchasable = this.featureRef.SystemPermission
    .DefaultAllow;
  @Input() featurePdf: Feature.Purchasable = this.featureRef.SystemPermission
    .DefaultAllow;
  @Input() showDownloadExcel = false;
  @Input() showDownloadPdf = false;
  @Input() style = 'default';
  @Input() xPosition: 'before' | 'after' = 'before';
  @Input() yPosition: 'above' | 'below' = 'below';
  @Input() showArrowIcon: 'up' | 'down' | 'none' = 'none';
  @Input() showDownloadIcon = true;
  @Input() askPdfDownloadConfirmation = false;
  @Input() quantityToDownload = 0;
  @Output() download = new EventEmitter<DownloadType>();
  @Input() flex = false;
  resize$ = this.sandBoxService.screenBusChannel$;
  sub$ = new Subscription();
  type = DownloadType;
  @ViewChild('trigger') trigger: MatMenuTrigger;
  isLoading = false;

  constructor(
    private permissionService: PermissionService,
    private sandBoxService: SandboxService,
    private bottomSheet: MatBottomSheet,
    public downloadBtnService: DownloadBtnService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.sub$.add(
      this.downloadBtnService.isLoading$.subscribe((res) => {
        this.isLoading = res;
        this.draw();
      })
    );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  draw() {
    this.changeDetectorRef.detectChanges();
  }

  get hasFeaturePdf() {
    return this.permissionService.hasFeatureFn(this.featurePdf);
  }

  get hasFeatureExcel() {
    return this.permissionService.hasFeatureFn(this.featureExcel);
  }

  emitDownload(type: DownloadType) {
    this.download.emit(type);
    this.downloadBtnService.isLoading.next(true);
    this.trigger?.closeMenu();
  }

  triggerDownload(type: DownloadType) {
    if (type === DownloadType.EXCEL || !this.askPdfDownloadConfirmation) {
      this.emitDownload(type);
      return;
    }

    const dialogRef = this.dialog.open(DownloadBySelectionModalComponent, {
      panelClass: 'delete-by-selection-modal',
      data: {
        quantity: this.quantityToDownload,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'EXECUTE') {
        this.emitDownload(type);
      }
    });
  }

  openMobileMenu() {
    const dialogRef = this.bottomSheet.open(DownloadBtnMobileComponent, {
      panelClass: ['sheet-pro'],
      data: {
        featureExcel: this.featureExcel,
        featurePdf: this.featurePdf,
        showDownloadExcel: this.showDownloadExcel,
        showDownloadPdf: this.showDownloadPdf,
      },
    });

    this.sub$.add(
      dialogRef.afterDismissed().subscribe((res) => {
        if (res) {
          this.triggerDownload(res);
        }
      })
    );
  }
}
