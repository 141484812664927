import { DatePipe } from '@angular/common';
import { DATE_FORMAT } from '@tacliatech/types';
import { StorageService } from '../services';

export function hasProperty(item: any = {}, propertyName: string) {
  try {
    if (
      Object.prototype.hasOwnProperty.call(item?.customProperties, propertyName)
    ) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

export function isDate(value: string) {
  // FORMAT: string YYYY-MM-DD
  const regex = /[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
  return !!regex.exec(value);
}

export function isBoolean(value: string) {
  return typeof value === 'boolean' || ['true', 'false'].includes(value);
}

export function getCustomValue(item: any = {}, propertyName: string) {
  // Transform from array to object, to maintain old logic
  if (item.customProperties?.constructor.name === 'Array') {
    const customPropertiesAux = {};
    for (const cp of item.customProperties) {
      customPropertiesAux[cp.name] = cp.value;
    }

    item.customProperties = customPropertiesAux;
  }

  if (hasProperty(item, propertyName)) {
    if (
      !Array.isArray(item?.customProperties[propertyName]) &&
      !isNaN(+item?.customProperties[propertyName])
    ) {
      if (
        item?.customProperties[propertyName] === undefined ||
        item?.customProperties[propertyName] === ''
      ) {
        return 'No Disponible';
      } else {
        // Is not a number? then return that value
        if (Number.isNaN(item?.customProperties[propertyName])) {
          return item?.customProperties[propertyName];
        }

        // Is a number? so cast at runtime because
        // sometimes the property is a number but comes as a string value
        // this way we always convert to numeric values
        return Number(item?.customProperties[propertyName]);
      }
    } else if (isDate(item?.customProperties[propertyName])) {
      return new DatePipe('es').transform(
        item?.customProperties[propertyName],
        StorageService.GetItem(DATE_FORMAT)
      );
    } else if (isBoolean(item?.customProperties[propertyName])) {
      if (item?.customProperties[propertyName] === 'true') {
        return 'Si';
      } else {
        return 'No';
      }
    } else if (Array.isArray(item?.customProperties[propertyName])) {
      return item?.customProperties[propertyName].toString();
    } else {
      return item?.customProperties[propertyName];
    }
  } else {
    return 'No Disponible';
  }
}
