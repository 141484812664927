import { Component, Input, OnInit } from '@angular/core';
import { Deal } from '@tacliatech/types';

('@ngx-translate/core');

@Component({
  selector: 'roma-wrapper-expenses',
  templateUrl: './wrapper-expenses.component.html',
  styleUrls: ['./wrapper-expenses.component.scss'],
})
export class WrapperExpensesComponent implements OnInit {
  @Input()
  dealData: Deal.Output;

  @Input()
  type: 'ADD' | 'EDIT' = 'ADD';

  @Input()
  isLoading = false;

  ngOnInit(): void {}
}
