import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Subscription } from 'rxjs';

export interface data {
  html: string;
  download: boolean;
  name: string;
}

@Component({
  selector: 'roma-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnInit, AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: data,
    private dialogRef: MatDialogRef<PreviewComponent>
  ) {}

  @Input()
  html?: string;

  @Output() finishLoading = new EventEmitter<boolean>();

  download = false;
  name = '';

  ngOnInit(): void {
    this.html = this.data.html;
    this.download = this.data.download;
    this.name = this.data.name;
  }

  ngAfterViewInit() {
    if (this.download) {
      this.createPDF();
    } else {
      this.finishLoading.emit();
    }
  }

  close() {
    this.dialogRef.close();
  }

  createPDF() {
    const DATA: any = document.getElementById('htmlData');
    DATA.ownerDocument.defaultView.innerHeight = 5000;
    DATA.ownerDocument.defaultView.innerWidth = 2600;
    this.close();
    html2canvas(DATA).then((canvas) => {
      const fileWidth = 210;
      const fileHeight = (5000 * 210) / 2600;
      const FILEURI = canvas.toDataURL('image/png');
      const PDF = new jsPDF('p', 'mm', 'a4', true);
      const positionX = -10;
      const positionY = -10;
      PDF.addImage(FILEURI, 'PNG', positionX, positionY, fileWidth, fileHeight);
      PDF.save(`${this.name}.pdf`);
      this.finishLoading.emit();
      //var img = canvas.toDataURL();
      //window.open(img);
      //
    });
  }
}
