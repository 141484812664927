import { Component, Input } from '@angular/core';
import { PlatformService } from './platform.service';

@Component({
  selector: 'platform-disable-content',
  templateUrl: './platform-disable-content.component.html',
})
export class PlatformDisableContentComponent {
  @Input()
  allowedPlatforms: ('ios' | 'web' | 'android')[] = [];

  @Input()
  disableForMobileWebBrowser = false;

  constructor(private readonly platformService: PlatformService) {}

  fail(): boolean {
    return (
      this.allowedPlatforms.indexOf(this.platformService.platform) === -1 ||
      (this.disableForMobileWebBrowser === true &&
        this.platformService.isMobileWebBrowser())
    );
  }
}
