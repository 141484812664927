<roma-modal-template
  size="sm"
  [title]="'sequence.select' | translate"
  [headerBottomMargin]="'24px'"
  [showDefaultButtons]="true"
  (success)="submit()"
  (cancel)="close()"
  [textSuccess]="'sequence.next' | translate"
  [contentMinHeight]="false"
>
  <ng-select
    class="select"
    [items]="sequences"
    [(ngModel)]="selectedSequence"
    bindLabel="name"
    bindValue="_id"
    [searchable]="false"
    [clearable]="false"
    [appendTo]="isResponsive ? '' : 'body'"
  >
    <ng-template ng-label-tmp let-item="item">
      {{ item.name }} - {{ item.prefix }}
    </ng-template>
  </ng-select>
</roma-modal-template>
