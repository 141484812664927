<div class="mat-dialog-header">
  <svg
    class="mat-dialog-header_logo"
    width="80"
    height="25"
    viewBox="0 0 80 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.16375 9.91429H0V13.7714H2.16375V19.4C2.16375 22 3.1579 25 7.28072 25C9.41522 25 11.0819 24.0857 11.0819 24.0857L9.79534 20C9.79534 20 9.1813 20.4571 8.50879 20.4571C7.69007 20.4571 7.3392 19.8 7.3392 18.4286V13.7714H10.4971V9.91429H7.3392V4.45714H2.16375V9.91429Z"
      fill="#3C48EC"
    />
    <path
      d="M11.4842 17.2571C11.4842 21.5429 14.5544 25 18.9404 25C20.9872 25 22.7416 24.1143 23.8527 22.9143H23.9112V24.5714H29.0281V9.91429H23.8527V11.3143C22.6246 10.1429 20.9579 9.48571 18.9404 9.48571C14.5544 9.48571 11.4842 12.9714 11.4842 17.2571ZM16.9813 17.2571C16.9813 15.2286 18.531 13.8 20.4901 13.8C22.4199 13.8 23.9696 15.2286 23.9696 17.2571C23.9696 19.2571 22.5076 20.6857 20.4901 20.6857C18.3848 20.6857 16.9813 19.2571 16.9813 17.2571Z"
      fill="#3C48EC"
    />
    <path
      d="M40.3088 13.6286C41.8585 13.6286 42.8234 14.3143 42.8234 14.3143L44.5778 10.8571C44.5778 10.8571 42.8819 9.48571 39.5486 9.48571C35.1918 9.48571 31.2445 12.9714 31.2445 17.2857C31.2445 21.5714 35.2211 25 39.5486 25C42.8819 25 44.5778 23.6857 44.5778 23.6857L42.8234 20.1714C42.8234 20.1714 41.8585 20.8571 40.3088 20.8571C38.3498 20.8571 36.7416 19.2571 36.7416 17.2286C36.7416 15.2286 38.3498 13.6286 40.3088 13.6286Z"
      fill="#3C48EC"
    />
    <path d="M46.4328 24.5714H51.6082V0H46.4328V24.5714Z" fill="#3C48EC" />
    <path
      d="M57.5748 0.857142C55.8789 0.857142 54.5046 2.2 54.5046 3.85714C54.5046 5.51428 55.8789 6.85714 57.5748 6.85714C59.2707 6.85714 60.645 5.51428 60.645 3.85714C60.645 2.2 59.2707 0.857142 57.5748 0.857142ZM54.914 9.91429H60.0895V24.5714H54.914V9.91429Z"
      fill="#3C48EC"
    />
    <path
      d="M62.4561 17.2571C62.4561 21.5429 65.5263 25 69.9123 25C71.9591 25 73.7134 24.1143 74.8246 22.9143H74.883V24.5714H80V9.91429H74.8246V11.3143C73.5965 10.1429 71.9298 9.48571 69.9123 9.48571C65.5263 9.48571 62.4561 12.9714 62.4561 17.2571ZM67.9532 17.2571C67.9532 15.2286 69.5029 13.8 71.462 13.8C73.3918 13.8 74.9415 15.2286 74.9415 17.2571C74.9415 19.2571 73.4795 20.6857 71.462 20.6857C69.3567 20.6857 67.9532 19.2571 67.9532 17.2571Z"
      fill="#3C48EC"
    />
  </svg>
</div>
<div class="mat-dialog-body">
  <div mat-dialog-content>
    <svg
      class="mat-dialog-content_icon"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        opacity="0.7"
        cx="100.943"
        cy="179.904"
        rx="70"
        ry="7.54286"
        fill="#E8EDFF"
      />
      <path
        d="M42.1 77.96C42.1 72.161 46.801 67.46 52.6 67.46H146.25C152.049 67.46 156.75 72.161 156.75 77.96V149.66C156.75 155.459 152.049 160.16 146.25 160.16H52.6C46.801 160.16 42.1 155.459 42.1 149.66V77.96Z"
        fill="#F2F5FF"
        stroke="#848CF3"
        stroke-width="3"
      />
      <path
        d="M41.8336 75.1767C41.8336 70.7585 45.4154 67.1768 49.8336 67.1768H148.1C152.518 67.1768 156.1 70.7585 156.1 75.1768V93.4537H41.8336V75.1767Z"
        fill="#848CF3"
      />
      <path
        d="M52.5683 79.7606C52.5683 78.051 53.9543 76.665 55.6639 76.665H79.5546C81.2643 76.665 82.6502 78.051 82.6502 79.7606C82.6502 81.4703 81.2643 82.8562 79.5546 82.8562H55.6639C53.9543 82.8562 52.5683 81.4703 52.5683 79.7606Z"
        fill="#E8EDFF"
      />
      <path
        d="M110.426 84.3108C112.848 84.3108 114.812 82.3507 114.812 79.9328C114.812 77.5148 112.848 75.5547 110.426 75.5547C108.004 75.5547 106.04 77.5148 106.04 79.9328C106.04 82.3507 108.004 84.3108 110.426 84.3108Z"
        fill="#E8EDFF"
      />
      <path
        d="M126.503 84.3108C128.925 84.3108 130.889 82.3507 130.889 79.9328C130.889 77.5148 128.925 75.5547 126.503 75.5547C124.081 75.5547 122.117 77.5148 122.117 79.9328C122.117 82.3507 124.081 84.3108 126.503 84.3108Z"
        fill="#E8EDFF"
      />
      <path
        d="M142.588 84.3108C145.01 84.3108 146.974 82.3507 146.974 79.9328C146.974 77.5148 145.01 75.5547 142.588 75.5547C140.166 75.5547 138.202 77.5148 138.202 79.9328C138.202 82.3507 140.166 84.3108 142.588 84.3108Z"
        fill="#E8EDFF"
      />
      <path
        d="M83.0286 119C85.1115 119 86.8 117.257 86.8 115.107C86.8 112.958 85.1115 111.215 83.0286 111.215C80.9457 111.215 79.2572 112.958 79.2572 115.107C79.2572 117.257 80.9457 119 83.0286 119Z"
        fill="#848CF3"
      />
      <path
        d="M115.62 119C117.703 119 119.392 117.257 119.392 115.107C119.392 112.958 117.703 111.215 115.62 111.215C113.538 111.215 111.849 112.958 111.849 115.107C111.849 117.257 113.538 119 115.62 119Z"
        fill="#848CF3"
      />
      <path
        d="M91.6739 130.065C98.2861 129.693 103.796 129.693 109.674 133.045"
        stroke="#848CF3"
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        d="M75.3574 22.1571C74.3286 23.0779 74.241 24.6584 75.1618 25.6872C76.0827 26.716 77.6631 26.8036 78.692 25.8827L75.3574 22.1571ZM123.238 25.8827C124.267 26.8036 125.847 26.716 126.768 25.6872C127.689 24.6584 127.601 23.0779 126.573 22.1571L123.238 25.8827ZM100.965 48.2865C99.5843 48.2865 98.465 49.4057 98.465 50.7865C98.465 52.1672 99.5843 53.2865 100.965 53.2865V48.2865ZM100.989 53.2865C102.37 53.2865 103.489 52.1672 103.489 50.7865C103.489 49.4057 102.37 48.2865 100.989 48.2865V53.2865ZM107.335 43.7786C108.356 44.7076 109.937 44.6328 110.866 43.6114C111.795 42.59 111.721 41.0089 110.699 40.0798L107.335 43.7786ZM115.387 34.9213C116.408 35.8504 117.989 35.7755 118.918 34.7542C119.848 33.7328 119.773 32.1516 118.751 31.2226L115.387 34.9213ZM91.2307 40.0798C90.2093 41.0089 90.1345 42.59 91.0636 43.6114C91.9926 44.6328 93.5738 44.7076 94.5952 43.7786L91.2307 40.0798ZM83.1787 31.2226C82.1573 32.1516 82.0825 33.7328 83.0115 34.7542C83.9406 35.7755 85.5217 35.8504 86.5431 34.9213L83.1787 31.2226ZM78.692 25.8827C84.6046 20.5908 92.4064 17.376 100.965 17.376V12.376C91.129 12.376 82.1513 16.0764 75.3574 22.1571L78.692 25.8827ZM100.965 17.376C109.524 17.376 117.325 20.5908 123.238 25.8827L126.573 22.1571C119.779 16.0764 110.801 12.376 100.965 12.376V17.376ZM100.965 53.2865H100.989V48.2865H100.965V53.2865ZM100.965 41.3163C103.42 41.3163 105.651 42.2468 107.335 43.7786L110.699 40.0798C108.131 37.7436 104.712 36.3163 100.965 36.3163V41.3163ZM100.965 29.3461C106.52 29.3461 111.577 31.4555 115.387 34.9213L118.751 31.2226C114.057 26.9523 107.812 24.3461 100.965 24.3461V29.3461ZM94.5952 43.7786C96.2791 42.2468 98.5105 41.3163 100.965 41.3163V36.3163C97.2183 36.3163 93.7991 37.7436 91.2307 40.0798L94.5952 43.7786ZM86.5431 34.9213C90.3532 31.4555 95.4099 29.3461 100.965 29.3461V24.3461C94.1176 24.3461 87.8732 26.9523 83.1787 31.2226L86.5431 34.9213Z"
        fill="#DCE3FB"
      />
      <rect
        x="76.9053"
        y="7.23926"
        width="5.04007"
        height="60.4808"
        rx="2.52003"
        transform="rotate(-40 76.9053 7.23926)"
        fill="#848CF3"
      />
    </svg>
    <h1 class="mat-dialog-content_title">
      {{ 'modalErrorConnection.title' | translate }}
    </h1>
    <p class="mat-dialog-content_body">
      {{ 'modalErrorConnection.body' | translate }}
    </p>
  </div>
  <ng-container *ngIf="data && data.canRetry">
    <div mat-dialog-actions>
      <button class="confirm" mat-button (click)="retry()" cdkFocusInitial>
        {{ 'modalErrorConnection.confirm' | translate }}
      </button>
    </div>
  </ng-container>
</div>
