<!-- TOTAL AREA -->
<div class="section-one-right">
  <div class="section-one-right-title">
    {{ 'budgets.settings.create.form.detail' | translate }}
  </div>
  <div class="section-one-right-items">
    <div class="section-one-right-item" *ngIf="totals?.discount > 0">
      <div>{{ 'budget.totalDiscount' | translate }}</div>
      <div>-{{ totals?.discount | currencyFormat: 'currencyPosition' }}</div>
    </div>
    <div class="section-one-right-item">
      <div>{{ 'budgets.settings.create.form.lblsubtotal' | translate }}</div>
      <div>
        {{ totals?.subTotal | currencyFormat: 'currencyPosition' }}
      </div>
    </div>
    <div class="section-one-right-item">
      <div>{{ 'budgets.settings.create.form.lbiva' | translate }}</div>
      <div>
        {{ totals?.ivas | currencyFormat: 'currencyPosition' }}
      </div>
    </div>
    <div class="section-one-right-item" *ngIf="totals?.rEq">
      <div>{{ 'budgets.settings.create.form.lblrec-eq' | translate }}</div>
      <div>
        {{ totals?.rEq | currencyFormat: 'currencyPosition' }}
      </div>
    </div>
    <div class="section-one-right-item" *ngIf="totals?.rEt">
      <div>{{ 'budgets.settings.create.form.lbretention' | translate }}</div>
      <div>-{{ totals?.rEt | currencyFormat: 'currencyPosition' }}</div>
    </div>
  </div>
  <div class="separator"></div>
  <div class="section-one-right-total">
    <div>{{ 'budgets.settings.create.form.lbtotal' | translate }}</div>
    <div>
      {{ totals?.total | currencyFormat: 'currencyPosition' }}
    </div>
  </div>
</div>
