import { NgModule } from '@angular/core';
import { PlatformDisableContentComponent } from './platform-disable-content.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [PlatformDisableContentComponent],
  imports: [ 
    CommonModule
  ],
  exports: [PlatformDisableContentComponent],
})
export class PlatformDisableContentModule {}
