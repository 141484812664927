<div [ngClass]="{
        'background-danger': type === 'error',
        'background-success': type === 'success',
        'background-warning': type === 'warning' }"
     class="container"
>
  <div class="flex-item">
    <div class="relative-div">
      <div [ngClass]="{
              'background-danger-soft': type === 'error',
              'background-success-soft': type === 'success',
              'background-warning-soft': type === 'warning' }"
           class="absolute-div"
      ></div>
      <div class="inner-div">
        <img
          [src]="type === 'error' ? 'assets/icons/gl_error.svg' : type === 'success' ? 'assets/icons/gl_check-fill' : 'assets/icons/gl_alert_yellow.svg'"
        />
      </div>
    </div>
    <div [ngClass]="{
        'text-danger': type === 'error',
        'text-success': type === 'success',
        'text-warning': type === 'warning' }"
         class="text-div">
      {{ message | translate}}
    </div>
  </div>
  <div class="relative-div-small">
    <img class="close_button"
         [ngClass]="{
         'filter-danger': type === 'error',
          'filter-success': type === 'success',
          'filter-warning': type === 'warning' }"
         [src]="'assets/icons/gl_close.svg'"
         alt="close"
        (click)="close()"
    />
  </div>
</div>
