<div class="fileImport" [ngClass]="{ error: fileFormatError, dragOver: dragOver, noBorder: files?.length > 0 }">
    <div class="uploadImageDiv" *ngIf="!files || files?.length == 0">
        <img class="upload-image" [src]="uploadImage" />
    </div>
    <div class="uploadDiv" [disabled]="disable" *ngIf="!files || files?.length == 0" (click)="openFileSelector()">
        {{ label | translate }}
    </div>
    <div class="filesDiv" *ngIf="files?.length > 0">
        <img
            class="fileImage"
            src="/assets/icons/file-alt.svg"/>
        <div class="text">
            {{ filesNames }}
        </div>
        <img
            class="checkImage"
            src="/assets/icons/gl_check-circle.svg"/>
        <div class="right">
            <img
                [disabled]="disable"
                (click)="removeFiles()"
                class="close-btn"
                src="/assets/icons/gl_close.svg"
                alt="close"/>
        </div>
    </div>
</div>
<div class="errorText" *ngIf="fileFormatError">
    *{{'imports.fileUpload.errorFormat' | translate}}
</div>