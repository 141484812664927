import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { DealStatusColors } from '../../deal-status-manager-.const';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { ChooseColorModal } from './choose-modal.types';

@Component({
  selector: 'roma-choose-modal',
  templateUrl: './choose-modal.component.html',
  styleUrls: ['./choose-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseModalComponent implements OnInit {
  selectedColor!: { lightColor: string; darkColor: string };

  constructor(
    private cdRef: ChangeDetectorRef,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    private data: ChooseColorModal.Params,
    private _bottomSheetRef: MatBottomSheetRef<
      ChooseModalComponent,
      ChooseColorModal.Result
    >
  ) {}

  ngOnInit(): void {
    this.resolveSelectedColor();
  }

  close() {
    this._bottomSheetRef.dismiss(this.selectedColor);
  }

  changeColor(evt: { darkColor: string; lightColor: string }) {
    this.selectedColor = evt;
    this.draw();
  }

  private resolveSelectedColor(): void {
    const colors = DealStatusColors;

    const selectedDefaultColor = () => {
      this.selectedColor = colors[0];
    };

    if (!this.data.color) {
      selectedDefaultColor();
    }

    const color = colors.find(
      (color) =>
        color.lightColor.toLowerCase() === this.data.color?.toLowerCase()
    );

    if (color) {
      this.selectedColor = color;
    } else {
      selectedDefaultColor();
    }

    this.draw();
  }

  private draw() {
    this.cdRef.detectChanges();
  }
}
