import { Workbook } from 'exceljs';

function createWorkbook(
  headerData: string[],
  rowData: (string | number | boolean)[][]
): Workbook {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  const headerRow = worksheet.addRow(headerData);

  headerRow.font = {
    color: { argb: '003c48ec' },
    bold: true,
    size: 12,
  };
  headerRow.alignment = {
    vertical: 'middle',
    horizontal: 'center',
  };

  rowData.forEach((row) => {
    worksheet.addRow(row);
  });

  worksheet.columns.forEach((column) => {
    column.width = 30;
    column.alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };
  });

  return workbook;
}

export { createWorkbook };
