import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TacliaPayments, TacliaPayments as payments } from '@tacliatech/types';
import { CompanyService } from '@web-frontend/shared/services/company';
import { PaymentsService } from './services/payments.service';
import { Subscription } from 'rxjs';
import { SandboxService } from '@web-frontend/shared/services';

@Component({
  selector: 'roma-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit {
  isLoading = true;
  isCreatingAcount = false;
  connectedAcountExists = false;
  statusAccount = payments.StatusAccount;
  onboardingDetail: payments.Onboarding = null;
  linkOnboarding: string;
  sub$ = new Subscription();
  stripeObject: TacliaPayments.IStripe;
  resize$ = this.sandBoxService.screenBusChannel$;

  constructor(
    private cdRef: ChangeDetectorRef,
    private companyService: CompanyService,
    private paymentsService: PaymentsService,
    private sandBoxService: SandboxService
  ) {
    this.wachEvent();
  }

  draw() {
    this.cdRef.detectChanges();
  }
  ngOnInit(): void {
    this.verifyExistAccountStripe();
  }

  wachEvent() {
    this.paymentsService.stripeStatus$.subscribe(
      (status: payments.Onboarding) => {
        this.onboardingDetail = status;
        this.draw();
      }
    );

    this.paymentsService.acountCreatedStatusOnbearding$.subscribe(
      (onboardingObject: payments.Onboarding) => {
        this.onboardingDetail = onboardingObject;
        this.draw();
      }
    );

    this.paymentsService.linkOnboarding$.subscribe((link) => {
      this.linkOnboarding = link;
      this.draw();
    });
  }

  watchStripeObject() {
    this.sub$.add(
      this.paymentsService.stripeObject$.subscribe(
        (data: TacliaPayments.IStripe) => {
          this.stripeObject = data;
        }
      )
    );
  }

  verifyExistAccountStripe() {
    this.isLoading = true;

    this.paymentsService.getStripeObject().subscribe(
      (res) => {
        if (res?.onboarding) {
          this.onboardingDetail = res.onboarding;
        }
        this.isLoading = false;
        this.draw();
      },
      (err) => {
        console.error(err);
      },
      () => {
        this.isLoading = false;
        this.draw();
      }
    );
  }

  public refresh() {
    return this.draw();
  }

  get isLowerThan1450px() {
    return window.innerWidth < 1450;
  }
}
