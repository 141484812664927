<div class="wrapper-elements">
  <span
    *ngFor="let day of days"
    class="wrapper-item"
    [ngClass]="{ active: activeDaysArray.includes(day) }"
    (click)="changeActiveDayEvt(day)"
  >
    {{ day | split: '1':false }}
  </span>
</div>
