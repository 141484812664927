import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicMessageComponent } from './basic-message.component';

@NgModule({
  declarations: [BasicMessageComponent],
  imports: [CommonModule],
  exports: [BasicMessageComponent],
})
export class BasicMessageModule {}
