<div class="mat-dialog-header">
  <img
    class="mat-dialog-header_logo"
    src="/assets/img/logo-taclia.svg"
    alt="taclia"
  />
</div>
<div class="mat-dialog-body">
  <div mat-dialog-content>
    <img
      src="assets/icons/illu_error-maintenance.svg"
      class="mat-dialog-content_icon"
    />
    <h1 class="mat-dialog-content_title">
      {{ 'modalErrorMaintenance.title' | translate }}
    </h1>
    <p class="mat-dialog-content_body">
      {{ 'modalErrorMaintenance.body' | translate }}
    </p>
  </div>
  <div mat-dialog-actions>
    <button
      class="confirm"
      mat-button
      [mat-dialog-close]="true"
      cdkFocusInitial
    >
      {{ 'modalErrorMaintenance.confirm' | translate }}
    </button>
    <div class="go_nav" (click)="navToHomePage()">
      <p class="go_nav_text">{{ 'modalErrorMaintenance.goNav' | translate }}</p>
      <img src="/assets/icons/chevron-right-2.svg" alt="right arrow" />
    </div>
  </div>
</div>
