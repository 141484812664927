export enum DynamicPropertyType {
  Date = 'DATE',
  Number = 'NUMBER',
  String = 'STRING',
  Boolean = 'BOOLEAN',
  Select = 'SELECT',
}

export enum DynamicPropertyRef {
  Deal = 'DEAL',
  Final = 'FINAL',
  InternalVendor = 'INT_VENDOR',
  Project = 'PROJECT',
  Equipment = 'EQUIPMENT',
  Activities = 'ACTIVITIES',
  Expenses = 'EXPENSES',
  Asset = 'ASSET',
  Rates = 'RATES',
  Users = 'USERS',
}
