import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';

import { ModalTemplateModule } from '../modal-template';
import { ModalInfoComponent } from './modal-info.component';
import { ImageModule } from '../../pipes/image/image.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ModalInfoComponent],
  imports: [
    CommonModule,
    MatChipsModule,
    MatButtonModule,
    ModalTemplateModule,
    ImageModule,
    TranslateModule.forChild(),
  ],
  exports: [ModalInfoComponent],
})
export class ModalInfoModule {}
