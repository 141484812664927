import { Component, Input, OnInit } from '@angular/core';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IDeal } from '@tacliatech/types';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { DealService } from '@web-frontend/shared/services';
import { Subscription } from 'rxjs';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import AmplitudeEvents from 'src/types/amplitude.enum';

@Component({
  selector: 'roma-deal-cancel',
  templateUrl: './deal-cancel.component.html',
  styleUrls: ['./deal-cancel.component.scss'],
})
export class DealCancelComponent implements OnInit {
  deal: IDeal;

  @Input()
  set data(value: IDeal) {
    this.deal = value;
  }

  @Input()
  deleteOnSubmit = true;

  @Input()
  deleteMultiple = false;

  form: FormGroup = this.formBuilder.group({
    actions: [''],
    cancel_reason_select: new FormControl('', [Validators.maxLength(250)]),
    cancel_reason_input: new FormControl('', [Validators.maxLength(250)]),
  });

  selected_options = [];

  actions: 'CURRENT' | 'NEXT' = 'CURRENT';
  btn_pressed = false;

  private sub$ = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    public activeModal: MatDialogRef<DealCancelComponent>,
    private amplitudeService: AmplitudeService,
    private dealService: DealService,
    private i18n: TranslateService,
    private toastService: ToastService
  ) {}

  get controls() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.selected_options = Object.values(
      this.i18n.instant('deals.cancel.options_select')
    );
  }

  async submit() {
    //Verificar motivo != null
    if (!this.deal?.cancelled) {
      if (this.deal?.recurrence) {
        this.form.patchValue({ actions: this.actions });
      }
      this.btn_pressed = true;

      let cancel_reason = this.form.get('cancel_reason_select').value;

      if (cancel_reason === 'Other') {
        cancel_reason = this.form.get('cancel_reason_input').value;
      }

      if (cancel_reason == '') {
        this.toastService.show({
          text: this.i18n.instant('deals.cancel.insert_motive'),
          type: 'error',
          msDuration: 4000,
          class: '',
        });
        this.btn_pressed = false;
        return;
      }

      const newDataForm = {
        actions: this.form.get('actions').value,
        cancel_reason,
      };

      if (this.deleteMultiple) {
        this.activeModal.close(newDataForm);
      } else {
        this.sub$.add(
          this.dealService
            .delete(this.deal._id, newDataForm)
            .subscribe((res) => {
              this.activeModal.close(res as IDeal);
            })
        );
      }

      const eventData = {
        event: AmplitudeEvents.deal_card_delete,
      };
      this.amplitudeService.sendEvent(eventData);
    } else {
      this.activeModal.close();
    }
  }

  close() {
    this.activeModal.close();
  }
}
