import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BusinessType, TacliaPayments } from '@tacliatech/types';
import { HttpClient } from '@angular/common/http';
import Stripe from 'stripe';
import { finalize } from 'rxjs/operators';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  stripeAccount: TacliaPayments.IStripe | null = null;
  stripeAccountStatus: TacliaPayments.Onboarding | null = null;
  private stripeObject = new BehaviorSubject<TacliaPayments.IStripe | null>(
    null
  );
  public stripeObject$ = this.stripeObject.asObservable();

  private stripeStatus = new Subject<TacliaPayments.Onboarding>();
  public stripeStatus$ = this.stripeStatus.asObservable();

  private acountCreatedStatusOnbearding = new Subject<TacliaPayments.Onboarding>();
  acountCreatedStatusOnbearding$ = this.acountCreatedStatusOnbearding.asObservable();
  private link = new Subject<string>();
  linkOnboarding$ = this.link.asObservable();

  constructor(private http: HttpClient, private readonly injector: Injector) {}

  sendAcountCreatedEventStatus(value: TacliaPayments.Onboarding) {
    this.acountCreatedStatusOnbearding.next(value);
  }
  sendLink(value: string) {
    this.link.next(value);
  }

  setStripeStatus(onboarding: TacliaPayments.Onboarding) {
    this.stripeAccountStatus = onboarding;
    this.stripeStatus.next(onboarding);
  }

  setStripeObject(obj: TacliaPayments.IStripe) {
    if (obj) {
      this.stripeAccount = obj;
      this.stripeObject.next(obj);
      // this.stripeObject.complete();
    }
  }

  getStripeObject() {
    return this.stripeObject$;
  }

  getConnectionToken(
    location: string
  ): Observable<Stripe.Response<Stripe.Terminal.ConnectionToken>> {
    return this.http.post<Stripe.Response<Stripe.Terminal.ConnectionToken>>(
      ':API_URL/payments/connection_token',
      { location }
    );
  }

  captureIntent(
    paymentIntent: Stripe.PaymentIntent
  ): Observable<Stripe.Response<Stripe.PaymentIntent>> {
    return this.http.post<Stripe.Response<Stripe.PaymentIntent>>(
      ':API_URL/payments/capture_payment_intent',
      paymentIntent
    );
  }

  createIntent(
    payload: TacliaPayments.Intent.Request
  ): Observable<Stripe.Response<Stripe.PaymentIntent>> {
    return this.http.post<Stripe.Response<Stripe.PaymentIntent>>(
      ':API_URL/payments/create_payment_intent',
      payload
    );
  }

  createLoginLink(connectedAccount?: string) {
    return this.http.get<Stripe.Response<Stripe.LoginLink>>(
      `:API_URL/payments/login-link/${connectedAccount}`
    );
  }
  refreshOnboardingLink() {
    return this.http.post<Stripe.Response<Stripe.AccountLink>>(
      `:API_URL/payments/onboarding-link`,
      {}
    );
  }

  createConnectedAcount(
    businessType: BusinessType | string = BusinessType.AUTONOMOUS
  ) {
    return this.http.post(':API_URL/payments/create-connected-account', {
      businessType,
    });
  }

  init() {
    // this.isLoadingData = true;
    this.requestStripeObject()
      .pipe(
        finalize(() => {
          // this.isLoadingData = false;
        })
      )
      .subscribe((res) => {
        // this.stripeObject = res;
        this.setStripeObject(res);
        this.setStripeStatus(res.onboarding);

        const statusMap = {
          [TacliaPayments.StatusAccount.PENDING]:
            BrazeEventType.payment_request_pending,
          [TacliaPayments.StatusAccount.APPROVED]:
            BrazeEventType.payment_request_approved,
          [TacliaPayments.StatusAccount.REJECTED]:
            BrazeEventType.payment_request_rejected,
        };

        const status = res?.onboarding?.status;
        if (status && statusMap[status]) {
          this.injector.get<AnalyticsService>(AnalyticsService).trackEvent({
            sources: ['braze', 'userflow'],
            eventName: statusMap[status],
          });
        }

        // this.stripeObject.next(res);
      });
  }

  requestStripeObject() {
    return this.http.get<TacliaPayments.IStripe>(
      ':API_URL/payments/stripe-account-status'
    );
  }

  createAccountSession() {
    return this.http.post<TacliaPayments.AccountSession>(
      ':API_URL/payments/account-session',
      {}
    );
  }

  generateUpdateSession() {
    return this.http.post(':API_URL/payments/generate-update-session', {});
  }
}
