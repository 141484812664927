import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadBySelectionModalComponent } from './download-by-selection-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DownloadBySelectionModalComponent],
  imports: [CommonModule, TranslateModule.forChild(), MatDialogModule],
  exports: [DownloadBySelectionModalComponent],
})
export class DownloadBySelectionModalModule {}
