/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import { Subscription } from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Output,
  OnDestroy,
  OnInit,
  SimpleChanges,
  DoCheck,
  OnChanges,
  AfterViewInit,
  EventEmitter,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  Feature,
  FeatureUser,
  IBudgetsSetting,
  ICatalogue,
  Tax,
  TypeAmplitudeBudget,
  TypeTax,
} from '@tacliatech/types';
import { RmSelect } from '../../globals/rm-select/rm-select.types';
import { TypeOfCRUD } from '@web-frontend/shared/enums/crud.enum';
import { INIT_RATES_SEARCH_PARAMS } from '@web-frontend/core/admin/rates/rates.types';
import { BudgetCategoryFields } from '../budget.enum';
import { IBdgCategFieldToDisplay } from '../budget.interface';
import { CreateCatalogueService } from '../../create-catalogue';
import { MatDialogConfig } from '@angular/material/dialog';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { UserService } from '@web-frontend/shared/services';

interface catalogFormData {
  price: number | string;
  quantity: number | string;
  total: number | string;
  unit: string;
  discount: number | string;
  description: string;
  concept?: string;
  idConcept?: string;
}

@Component({
  selector: 'roma-budget-category',
  templateUrl: './budget-category.component.html',
  styleUrls: ['./budget-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetCategoryComponent
  implements OnInit, OnDestroy, OnChanges, DoCheck, AfterViewInit {
  private sub$ = new Subscription();
  private changeDetected = false;

  @Input()
  fieldsToDisplay: IBdgCategFieldToDisplay[] = [
    {
      name: BudgetCategoryFields.QUANTITY,
      nameRef: 'asd',
      checked: false,
      showFieldAsActivable: true,
    },
  ];

  @Input()
  settings: IBudgetsSetting;

  oldFieldsObject: IBdgCategFieldToDisplay[];
  public get CategoryFields(): typeof BudgetCategoryFields {
    return BudgetCategoryFields;
  }

  @Input()
  categoryForm: FormGroup;

  get formTaxes(): FormArray {
    return this.categoryForm.controls['taxes'] as FormArray;
  }

  @Input()
  groupIndex: number;

  @Input()
  mode = TypeOfCRUD.CREATE;

  @Input()
  taxes: Tax[] = [];
  @Input()
  allTaxes: Tax[] = [];
  taxList: RmSelect.Items = [];
  allTaxList: RmSelect.Items = [];
  taxSelected: RmSelect.Items = [];

  @Input()
  catalogue: ICatalogue[] = [];
  selectedCatalogue: ICatalogue;
  rates: RmSelect.Items = [];
  selectedRate: RmSelect.Items;

  @Output()
  onChange = new EventEmitter();

  @Output()
  deleteCategoryEvent = new EventEmitter();

  @Output()
  refreshCatalogueEvent = new EventEmitter<boolean>();

  @Input()
  budgetTypeAmplitude: TypeAmplitudeBudget;

  isLoadingRates = false;
  @Input()
  units: RmSelect.Items = [];
  @Input()
  isLoadingUnits: boolean;

  selectedUnit: RmSelect.Items;

  conceptPlaceholder = 'budgets.settings.create.form.conceptPlaceholder';
  conceptDescriptionPlaceholder =
    'budgets.settings.create.form.conceptAndDescription';
  descriptionPlaceholder =
    'budgets.settings.create.form.descriptionPlaceholder';
  quantityPlaceholder = 'budgets.settings.create.form.quantityPlaceholder';
  unitPlaceholder = 'budgets.settings.create.form.unit';
  discountPlaceholder = 'budgets.settings.create.form.discountPlaceholder';
  discountPlaceholderMobile =
    'budgets.settings.create.form.discountPlaceholderMobile';
  pricePlaceholder = 'budgets.settings.create.form.pricePlaceholder';
  taxPlaceholder = 'budgets.settings.create.form.taxPlaceholder';
  totalPlaceholder = 'budgets.settings.create.form.totalPlaceholder';

  selectListStyle = {
    'max-height': '170px',
  };

  featureRef = Feature;
  featureRefUser = FeatureUser;

  private storeSearchParams = INIT_RATES_SEARCH_PARAMS;

  get displayedTaxes(): RmSelect.Items {
    if (this.taxSelected.length > 0) {
      const taxes = this.allTaxList.filter((tax) => {
        const hastax =
          this.taxList.map((t) => t.id).includes(tax.id) ||
          this.taxSelected.map((t) => t.id).includes(tax.id);
        return hastax;
      });
      return taxes;
    } else {
      return this.taxList;
    }
  }

  get isResponsive(): boolean {
    return window.screen.width <= 992;
  }

  @ViewChild('total')
  totalTarget: ElementRef;

  constructor(
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly _createCatalogueService: CreateCatalogueService,
    private amplitudeService: AmplitudeService,
    private userService: UserService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    if (!this.isEmptyForm) {
      this.loadTaxes();
      this.oldFieldsObject = structuredClone(this.fieldsToDisplay);
      this.loadData();
      this.draw();
    }

    if (this.mode === TypeOfCRUD.UPDATE) {
      this.fillTaxes(this.formTaxes.value);
    }

    this.sub$.add(
      this.categoryForm.valueChanges.subscribe(() => {
        this.draw();
      })
    );
  }

  ngAfterViewInit(): void {
    this.oldFieldsObject = structuredClone(this.fieldsToDisplay);
    if (!this.categoryForm.get('quantity').value) {
      this.categoryForm.patchValue({
        quantity: 1,
      });
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.catalogue) {
      this.setCatalogue();
      const lineRate = this.categoryForm.get('concept').value;
      if (lineRate) {
        const rate = this.rates.find((c) => c.title === lineRate);
        this.selectedRate = rate
          ? [rate]
          : [{ title: lineRate, id: lineRate, value: lineRate }];
      }
    }

    if (changes.units) this.fillUnit();

    if (this.mode === TypeOfCRUD.UPDATE) {
      this.fillRate();
      this.fillTextArea();
    }
  }

  loadTaxes(): void {
    this.taxList = this.resolveTaxes(this.taxes);
    this.allTaxList = this.resolveTaxes(this.allTaxes);
  }

  handleTextareaInput(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    this.resizeTextarea(textarea);
  }

  fillTextArea(): void {
    const textarea = document.getElementById(
      `description-textarea-${this.groupIndex}`
    ) as HTMLTextAreaElement;
    if (textarea) this.resizeTextarea(textarea);
  }

  resizeTextarea(textarea: HTMLTextAreaElement): void {
    this.renderer.setStyle(textarea, 'height', 'auto');
    this.renderer.setStyle(
      textarea,
      'height',
      `${Math.min(!textarea.value ? 0 : textarea.scrollHeight, 104)}px`
    );
  }

  loadData(): void {
    const lineTaxes = this.categoryForm.get('taxes').value;
    if (lineTaxes && lineTaxes.length > 0) {
      this.taxSelected = this.resolveTaxes(lineTaxes);
    }
  }

  public fillUnit(): void {
    const { unit } = this.categoryForm.value;
    if (!unit) return;
    const inv = this.units.find((u) => u.id === unit);
    if (inv) this.selectedUnit = [inv];
  }

  get isMX(): boolean {
    return this.userService.validateCountryMX();
  }

  get invalidMXCategory(): boolean {
    return (
      this.isMX &&
      this.selectedCatalogue &&
      (!this.selectedCatalogue.measurementUnit ||
        !this.selectedCatalogue.productKey)
    );
  }

  resetValues(keepConcept: boolean): void {
    let values: catalogFormData = {
      price: '',
      quantity: 1,
      total: '',
      unit: '',
      discount: '',
      description: '',
    };

    if (!keepConcept) {
      values = { ...values, concept: null, idConcept: '' };
      this.selectedRate = [];
      this.selectedCatalogue = null;
    }

    this.categoryForm.patchValue(values);
    this.taxSelected = [];
    this.formTaxes.clear();
    this.fillTextArea();
  }

  changeRate(rate: RmSelect.Items, catalogue = this.catalogue): void {
    this.onChange.emit();
    let selectedRate = rate;
    const previousCategories = this.selectedCatalogue
      ? { ...this.selectedCatalogue }
      : null;

    this.amplitudeService.sendEvent({
      event: `${this.budgetTypeAmplitude}_card_editConcept`,
    });

    if (!selectedRate || selectedRate.length === 0) {
      this.resetValues(false);
      return;
    }

    selectedRate = rate.map((el) => {
      return { id: el.id, title: el.title, value: el.value };
    });

    this.selectedCatalogue = catalogue.find(
      (c) => c._id === selectedRate[0].id
    );

    const [first] = selectedRate;
    this.categoryForm.patchValue({
      concept: first.title,
      idConcept: first?.value,
    });

    if (!this.selectedCatalogue && previousCategories) {
      this.resetValues(true);
    } else {
      this.autoCompleteRate(this.selectedCatalogue);
    }

    this.categoryForm.patchValue({ validCategory: !this.invalidMXCategory });

    this.setFormRawValue();
    this.draw();
  }

  changeTax(evt: RmSelect.Items): void {
    const ids = <string[]>evt.map((el) => el.id);
    if (ids.length) {
      this.formTaxes.clear();
      for (const id of ids) {
        const currentTax = this.allTaxes.find((t) => t._id == id);
        this.formTaxes.push(new FormControl(currentTax));
      }
    } else {
      this.taxSelected = [];
      this.formTaxes.clear();
    }
    this.calculateTotal();
    this.draw();
    this.amplitudeService.sendEvent({
      event: `${this.budgetTypeAmplitude}_card_editTaxes`,
    });
  }

  valueChange(e: Event, name: string): void {
    this.onChange.emit();
    const target = e.target as HTMLInputElement;
    const value = target.value || '0';
    let event: string;

    switch (name) {
      case 'total':
        this.calculatePrice(parseFloat(value.replace(',', '.')));
        break;
      case 'quantity':
        event = `${this.budgetTypeAmplitude}_card_editQuantity`;
        this.calculateTotal();
        break;
      case 'price':
        event = `${this.budgetTypeAmplitude}_card_editPrice`;
        this.calculateTotal();
        break;
      case 'discount':
        event = `${this.budgetTypeAmplitude}_card_editDiscount`;
        this.calculateTotal();
        break;
      case 'unit':
        event = `${this.budgetTypeAmplitude}_card_editUnit`;
        this.setFormRawValue();
        break;
      case 'description':
        event = `${this.budgetTypeAmplitude}_card_editDescription`;
        break;
      default:
        this.calculateTotal();
        break;
    }

    if (event) {
      this.amplitudeService.sendEvent({ event });
    }
  }

  changeUnit(evt: RmSelect.Items) {
    if (!evt || evt.length === 0) return;
    this.selectedUnit = evt;
    this.onChange.emit();
    this.categoryForm.patchValue({ unit: evt[0].id });

    this.amplitudeService.sendEvent({
      event: `${this.budgetTypeAmplitude}_card_editUnit`,
    });
  }

  autoCompleteRate(catalogue: ICatalogue): void {
    this.categoryForm.patchValue({
      price: catalogue.sellPrice || '',
      quantity: 1,
      description: catalogue.description || '',
    });
    this.fillTextArea();
    if (this.isMX) {
      this.categoryForm.patchValue({
        unit: catalogue.measurementUnit,
        productKey: catalogue.productKey,
      });
      this.selectedUnit = this.units.filter(
        (unit) => unit.id === catalogue.measurementUnit
      );
    }

    if (catalogue.taxes && catalogue.taxes.length > 0) {
      const catalogueTaxes = catalogue.taxes as string[];
      const currentTaxes = this.allTaxList.filter((t) => {
        return catalogueTaxes.includes(t.id);
      });
      this.taxSelected = currentTaxes;
    } else {
      this.taxSelected = [];
    }
    this.changeTax(this.taxSelected);
  }

  addRate(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      returnData: true,
    };

    this.sub$.add(
      this._createCatalogueService
        .open(dialogConfig)
        .subscribe((res: ICatalogue) => {
          this.refreshCatalogueEvent.emit(true);
          const createdRate = {
            title: res.name,
            value: res._id,
            id: res._id,
          };

          this.catalogue.push(res);
          this.rates.push(createdRate);
          this.selectedRate = [createdRate];

          this.changeRate([createdRate]);
        })
    );
  }

  onClickSnackbarLink(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      catalogue: this.selectedCatalogue,
      returnData: true,
    };

    this.sub$.add(
      this._createCatalogueService
        .edit(dialogConfig)
        .subscribe((res: ICatalogue) => {
          this.refreshCatalogueEvent.emit(true);
          this.updateCatalogue(res);
          this.updateSelectedCatalogue(res);
          this.draw();
        })
    );
  }

  updateCatalogue(res: ICatalogue): void {
    this.catalogue = this.catalogue.map((rate) =>
      rate._id === res._id ? res : rate
    );
  }

  updateSelectedCatalogue(res: ICatalogue): void {
    this.selectedCatalogue = res;
    this.categoryForm.patchValue({ unit: res?.measurementUnit });
    this.selectedUnit = this.units.filter(
      (unit) => unit.id === res?.measurementUnit
    );
  }

  get subtotal(): number {
    const quantity = this.categoryForm.controls['quantity'].value;
    const price = this.categoryForm.controls['price'].value;
    return quantity * price;
  }

  valueToPorcentage(value: number): number {
    return value / 100 || 0;
  }

  get discount(): number {
    const discount = this.categoryForm.controls['discount'].value;
    return (discount * this.subtotal) / 100;
  }

  calculateTotal(): void {
    let ret = 0;
    let gtax = 0;
    let total = 0;
    const taxes = this.categoryForm.controls['taxes'].value as Tax[];
    const subtotalDiscount = this.subtotal - this.discount;
    if (taxes.length > 0) {
      taxes.map((tax) => {
        const value = (subtotalDiscount * tax.value) / 100;
        if (tax.type === TypeTax.RET) {
          ret -= value;
        } else {
          gtax += value;
        }
      });
      total = subtotalDiscount + ret + gtax;
    } else {
      total = subtotalDiscount;
    }
    this.categoryForm.controls['total'].patchValue(
      parseFloat(total.toFixed(this.settings?.budgetPreferences?.decimals))
    );
    this.setFormRawValue();
    this.draw();
  }

  calculatePrice(total: number): void {
    let price = 0;
    let quantity = this.categoryForm.get('quantity').value;
    if (!quantity) {
      quantity = 1;
      this.categoryForm.get('quantity').patchValue(quantity);
    }
    const taxes = this.categoryForm.controls['taxes'].value as Tax[];
    const subprice = total / quantity;
    const discount = this.valueToPorcentage(
      this.categoryForm.controls['discount'].value || 0
    );
    price = subprice / (1 - discount);
    if (total === 0) {
      this.categoryForm.controls['price'].patchValue(price);
      return;
    }
    if (taxes.length > 0) {
      let totalTaxes = 0;
      taxes.map((tax) => {
        if (tax.type === TypeTax.RET) {
          totalTaxes -= tax.value;
          return;
        }
        totalTaxes += tax.value;
      });
      totalTaxes = this.valueToPorcentage(totalTaxes);
      price = price / (1 + totalTaxes);
    }
    this.categoryForm.controls['price'].patchValue(
      parseFloat(price.toFixed(this.settings.budgetPreferences?.decimals))
    );
  }

  resolveShowField(fieldName: BudgetCategoryFields): boolean {
    const fieldObj = this.fieldsToDisplay.find((f) => f.name === fieldName);
    return fieldObj.checked;
  }

  resolveTaxes(taxToParse: Tax[]): RmSelect.Items {
    return taxToParse?.map((tax: Tax) => {
      return {
        title: tax.name,
        value: tax.value,
        id: tax._id,
        translate: tax.translate,
        type: tax.type,
      };
    });
  }

  private setCatalogue() {
    this.isLoadingRates = true;

    if (this.catalogue?.length > 0) {
      this.rates = this.catalogue.map((res) => {
        return {
          title: res.name,
          value: res._id,
          id: res._id,
        };
      });
    }
    this.isLoadingRates = false;
    this.draw();
  }

  removeCategory(): void {
    this.deleteCategoryEvent.emit();
  }

  get isEmptyForm(): boolean {
    const controlLength = Object.keys(this.categoryForm.controls).length;
    return !(controlLength > 1);
  }

  fieldIsInvalid(field: string): boolean {
    return (
      this.categoryForm.controls[field].touched &&
      this.categoryForm.controls[field].invalid
    );
  }

  ngDoCheck(): void {
    if (!this.isEmptyForm) {
      for (let i = 0; i < this.fieldsToDisplay.length; i++) {
        const newField = this.fieldsToDisplay[i];
        const oldField = this.oldFieldsObject[i];
        if (oldField.checked !== newField.checked) {
          oldField.checked = newField.checked;
          this.changeDetected = true;
        }
      }

      if (this.changeDetected) {
        this.draw();
      }
      this.changeDetected = false;
    }
  }

  fillTaxes(taxes: Tax[]): void {
    if (!taxes || taxes.length === 0) {
      return;
    }
    const RmTaxes = taxes.map((tax) => {
      return {
        title: tax.name,
        value: tax.value,
        id: tax._id,
        translate: tax.translate,
        type: tax.type,
      };
    });

    this.taxSelected = [];

    for (const tax of RmTaxes) {
      this.taxSelected.push(tax);
    }

    this.draw();
  }

  fillRate(): void {
    const concept = this.categoryForm.controls.concept?.value;

    if (!concept) {
      return;
    }

    this.selectedCatalogue = this.catalogue?.filter(
      (c) => c.name === concept
    )[0];

    if (this.selectedCatalogue) {
      this.selectedRate = this.rates?.filter(
        (c) => c.id === this.selectedCatalogue._id
      );
    }

    this.isLoadingRates = false;
    this.draw();
  }

  private setFormRawValue() {
    this.categoryForm.parent.setValue(this.categoryForm.parent.getRawValue(), {
      emitEvent: false,
    });

    this.formTaxes.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
  }

  private draw() {
    this._changeDetector.detectChanges();
  }
}
