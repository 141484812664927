import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { ImageSelectorComponent } from './image-selector-v2.component';

import { ImageModule } from '@web-frontend/shared/pipes/image';
import { TranslateModule } from '@ngx-translate/core';
import { FileSelectModule } from '@web-frontend/shared/helpers/file-select';
import { PermissionsModule } from '../permissions';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ToastModule } from '@web-frontend/shared/services/toast/toast.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [ImageSelectorComponent],
  exports: [ImageSelectorComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    ImageModule,
    TranslateModule.forChild(),
    FileSelectModule,
    PermissionsModule,
    MatTooltipModule,
    ToastModule.forRoot(),
    MatProgressSpinnerModule,
  ],
})
export class ImageSelectorModule {}
