import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DeleteDialogData {
  title?: string;
  body?: string;
  showIcon?: boolean;
  showBody?: boolean;
  btn_cancelLabel?: string;
  confirmLabel?: string;
  isPlural?: boolean;
}

@Component({
  selector: 'roma-delete-by-selection-modal',
  templateUrl: './delete-by-selection-modal.component.html',
  styleUrls: ['./delete-by-selection-modal.component.scss'],
})
export class DeleteBySelectionModalComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteBySelectionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: DeleteDialogData
  ) {
    if (!data) {
      this.data = {
        showBody: true,
        showIcon: true,
      };
    }
  }

  onNoClick(): void {
    this.dialogRef.close('CANCEL');
  }

  exit(): void {
    this.dialogRef.close('CANCEL-X');
  }

  execute(): void {
    this.dialogRef.close('EXECUTE');
  }
}
