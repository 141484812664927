import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { App, IDeal, IStatus, IUser } from '@tacliatech/types';

import {
  DealDTO,
  MessageDTO,
  MessageFormDTO,
  WelcomeMessage,
  WelcomeNotes,
} from '@web-frontend/shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class DealService {
  private sub$ = new Subscription();

  private dealsCache = new BehaviorSubject<App.ResourceCached<IDeal[]>>([
    'INIT',
    [],
  ]);

  deals$ = this.dealsCache.asObservable();

  constructor(private http: HttpClient) {
    // this.init();
  }

  init() {
    this.dealsCache.next(['LOADING', []]);
    this.sub$.add(
      this.findAll().subscribe((res) => {
        this.dealsCache.next(['LOADED', res]);
      })
    );
  }

  public get deals() {
    return this.dealsCache.value[1];
  }

  findAll() {
    return this.http.get<IDeal[]>(`:API_URL/deals`);
  }

  findAuthors(id: string) {
    return this.http.get<IUser[]>(`:API_URL/deals/authors/${id}`);
  }

  findDealsByUser(id: string) {
    return this.http.get<IDeal[]>(`:API_URL/deals/users/${id}`, {
      headers: {
        ['X-API-Version']: '2',
      },
    });
  }

  findDealsByProject(id: string) {
    return this.http.get<IDeal[]>(`:API_URL/deals/projects/${id}`);
  }

  findDealsByEquipment(id: string) {
    return this.http.get<IDeal[]>(`:API_URL/deals/equipments/${id}`);
  }

  findDealsByFinal(id: string) {
    return this.http.get<IDeal[]>(`:API_URL/deals/finals/${id}`);
  }

  findDealsByVendor(id: string) {
    return this.http.get<IDeal[]>(`:API_URL/deals/vendors/${id}`);
  }

  findDealsByAsset(id: string) {
    return this.http.get<IDeal[]>(`:API_URL/deals/assets/${id}`);
  }

  delete(id: string, reason: any) {
    return this.http.put(`:API_URL/deals/delete/${id}`, reason);
  }

  deleteMany(data) {
    return this.http.post(`:API_URL/deals/delete-many`, data);
  }

  logicalDelete(data) {
    return this.http.post(`:API_URL/deals/delete-many-definitely`, data);
  }

  updateStatus(id: string, status: IStatus) {
    return this.http.put<IDeal>(`:API_URL/deals/status/${id}`, { status });
  }

  updateVendor(id: string, status: any) {
    return this.http.put<IDeal>(`:API_URL/deals/status/${id}`, { ...status });
  }

  updateOne(id: string, params = {}) {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-API-Version': '2',
      }),
    };
    return this.http.put<IDeal>(`:API_URL/deals/${id}`, params, httpOptions);
  }

  getMessagesService(id: string) {
    return this.http.get<MessageDTO[]>(`:API_URL/comments/${id}`);
  }

  updateMessageService(
    id: string,
    data: MessageFormDTO
  ): Observable<{ ok: boolean; service: DealDTO }> {
    return this.http.put<{ ok: boolean; service: DealDTO }>(
      `:API_URL/comment/${id}`,
      data
    );
  }

  createMessageService(
    id: string,
    data: MessageFormDTO
  ): Observable<{ ok: boolean; body: MessageDTO }> {
    return this.http.post<{ ok: boolean; body: MessageDTO }>(
      `:API_URL/comment/${id}`,
      data
    );
  }

  deleteMessageService(id: string): Observable<WelcomeMessage> {
    return this.http.delete<WelcomeMessage>(`:API_URL/comment/${id}`);
  }

  findNotes(id: string) {
    return this.http.get<WelcomeNotes>(`:API_URL/deals/notes/${id}`);
  }

  updateNotes(id: string, note: string): Observable<IDeal> {
    return this.http.put<IDeal>(`:API_URL/deals/update-notes/${id}`, { note });
  }

  editNote(id: string, data: any): Observable<WelcomeNotes> {
    return this.http.put<WelcomeNotes>(`:API_URL/deals/edit-notes/${id}`, data);
  }

  findOne(id: string) {
    return this.http.get<IDeal>(`:API_URL/deals/${id}`);
  }

  public addImgToNotesService(id: string, note: string) {
    return this.http.put<IDeal>(`:API_URL/deals/image-notes/${id}/`, { note });
  }

  findAuctioneers(id: string) {
    return this.http.get<Array<{ _id: string; name: string; email: string }>>(
      `:API_URL/deals/${id}/auctioneers`
    );
  }

  quietDeal(id: string) {
    return this.http.get(`:API_URL/deals/${id}/quiet-deal`);
  }

  /*OPERATION NOTES*/
  findOpNotes(id: string) {
    return this.http.get<WelcomeNotes>(`:API_URL/deals/opnotes/${id}`);
  }

  updateOpNotes(id: string, note: string): Observable<IDeal> {
    return this.http.put<IDeal>(`:API_URL/deals/update-opnotes/${id}`, {
      note,
    });
  }

  editOpNote(id: string, data: any): Observable<WelcomeNotes> {
    return this.http.put<WelcomeNotes>(
      `:API_URL/deals/edit-opnotes/${id}`,
      data
    );
  }

  //
  findAllDealsByCompany_SELECT() {
    return this.http.get<{ _id: string; name: string }[]>(
      `:API_URL/deals/company/select`
    );
  }

  restoreMany(data) {
    return this.http.post(`:API_URL/deals/restore-many`, data);
  }

  updateMany(ids: string[], params = {}) {
    return this.http.post(`:API_URL/deals/update-many`, {
      ids: ids,
      data: params,
    });
  }

  updateStatusMany(ids: string[], status = {}) {
    return this.http.post(`:API_URL/deals/status/edit-many`, {
      ids: ids,
      status: status,
    });
  }

  cancelMany(ids: string[]) {
    return this.http.post(`:API_URL/deals/cancel-multiple`, {
      ids: ids,
    });
  }

  finishedMany(ids: string[]) {
    return this.http.post(`:API_URL/deals/mark-as-finished`, {
      ids: ids,
    });
  }

  restoreFinished(ids: string[]) {
    return this.http.post(`:API_URL/deals/restore-finished`, {
      ids: ids,
    });
  }
}
