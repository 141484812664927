import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MobileClockHourAdmin } from './mobile-clock-hour-admin.types';
import { FeatureUser } from '@tacliatech/types';

@Component({
  selector: 'roma-mobile-clock-hour-admin',
  templateUrl: './mobile-clock-hour-admin.component.html',
  styleUrls: ['./mobile-clock-hour-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileClockHourAdminComponent implements OnInit {
  @HostBinding('class.card__hour')
  hostClass = true;

  @Input()
  content: MobileClockHourAdmin.Content;

  @Input()
  actionOptions = MobileClockHourAdmin.defaultActionsOptions;

  @Output()
  actions = new EventEmitter<MobileClockHourAdmin.Action>();

  featureRefUser = FeatureUser;

  constructor() {}

  ngOnInit(): void {}

  @HostListener('click', ['$event'])
  click() {
    this.actions.emit({ action: 'VIEW', id: this.content.id });
  }

  makeAction(action: 'EDIT' | 'DELETE') {
    this.actions.emit({ action, id: this.content.id });
  }
}
