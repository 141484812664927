<div class="row">
  <!-- Stripe Snackbar -->
  <roma-shared-stripe-snackbar></roma-shared-stripe-snackbar>

  <!-- Trial Disclaimer -->
  <roma-pro-disclaimer-banner
    *ngIf="isLowerThan1450px"
    [rootModule]="'timetracking2'"
    (emitRefresh)="refresh()"
  ></roma-pro-disclaimer-banner>

  <div class="header" style="width: 100%">
    <div class="header__title">
      <h1 class="page-title">
        {{ 'clockHour.adminTitle' | translate }}
        <div class="tooltip-container">
          <img
            width="32px"
            class="help-icon"
            [src]="iconDialog"
            (click)="showDialog()"
          />
          <div
            *ngIf="showToolTip"
            class="title-tooltip-wrapper"
            (click)="toggleTooltip(false)"
          ></div>
          <div
            class="help-tooltip 22"
            *ngIf="showToolTip"
            romaRomaToolTip
            [showToolTip]="showToolTip"
            (clicked)="toggleTooltip($event)"
          >
            <div [innerHTML]="'clockHourAdmin.tooltip' | translate"></div>
            <a
              *ngIf="academyLink?.length"
              [href]="academyLink"
              target="_blank"
              >{{ 'clockHourAdmin.tooltipUrl' | translate }}</a
            >
          </div>
        </div>
      </h1>
      <!-- Videotutorial button -->
      <button
        *ngIf="getLang() === 'es'"
        class="tutorial"
        (click)="openVideo('clock-hours-team')"
      >
        <strong>{{ 'tutorial.tutorial' | translate }}</strong>
        <img class="video" src="assets/icons/gl_play-tutorial.svg" />
      </button>
      <!-- Videotutorial button -->

      <button
        class="create-user-button mobile-hidden"
        (clicked)="showCreateUser()"
        romaProFeature
        [featureUser]="featureUserRef.User.create"
      >
        <strong>{{ 'general.addUserBtn' | translate }}</strong>
        <img class="plus-svg" src="/assets/icons/user-plus.svg" />
      </button>

      <!-- Download excel button -->
      <roma-shared-download-btn
        *ngIf="!(resize$ | async)?.payload?.mobile"
        [featureExcel]="featureRef.ClockHour.DownloadExcel"
        [showDownloadExcel]="true"
        [showDownloadPdf]="false"
        (download)="download($event)"
        style="margin-right: 16px"
        [ngClass]="{ 'is-hidden': isLoading }"
        [flex]="true"
      ></roma-shared-download-btn>
      <!-- Download excel button -->

      <button
        *ngIf="!(resize$ | async)?.payload?.mobile"
        class="roma-button add-button-3 mobile-hidden"
        style="display: inline-block"
        romaProFeature
        [featureUser]="featureUserRef.ClockHour.create"
        (clicked)="launchModalClockHour()"
      >
        {{ 'general.addButton' | translate }}
        <img src="assets/icons/plus.svg" />
      </button>
    </div>
  </div>
</div>

<div class="container-table-header">
  <roma-clock-table-header
    (viewTypeChange)="changeViewType($event)"
    (filterCoreChanges)="rmFilterCoreChanges($event)"
    (downloadBtnClick)="download($event)"
    (launchModal)="launchModalClockHour()"
    (changeDateRange)="changeDateRange($event)"
    [viewTypeAct]="viewTypeActive"
  ></roma-clock-table-header>
</div>

<div *ngIf="viewTypeActive === viewTypeRef.TEAM; else viewAll">
  <roma-list-team-view
    [dateRange]="dateRange"
    [teamMember]="searchTeamMember"
    [updateMetrics]="updateMetrics"
  ></roma-list-team-view>
</div>

<ng-template #viewAll>
  <div class="hours-info">
    <div class="table-total">
      {{ clockHours?.total || 0 }} {{ 'general.results' | translate }}
    </div>
    <div class="total-hours">
      Total <strong>{{ clockHoursTotal.replace(':', 'h ') }}m</strong>
    </div>
    <ng-container *ngIf="!(resize$ | async)?.payload?.mobile">
      <div *ngIf="clockHours?.docs?.length" class="pagination">
        <button
          class="arrow prev"
          [attr.disabled]="clockHours.page === 1 || null"
          (click)="previousPage()"
        >
          <img src="assets/icons/gl_arrow_left.svg" />
        </button>
        <ng-container *ngFor="let page of totalPages">
          <div
            class="page-item"
            *ngIf="page.page < 6"
            [ngClass]="{ 'current-page': page.page === clockHours.page }"
            (click)="goToPage(page.page)"
          >
            {{ page.page }}
          </div>
          <div
            class="page-item"
            *ngIf="
              clockHours.countPages > 5 && page.page === clockHours.countPages
            "
            [ngClass]="{ 'current-page': page.page === clockHours.page }"
            (click)="goToPage(clockHours.countPages)"
          >
            ...
            {{ clockHours.countPages }}
          </div>
        </ng-container>
        <button
          class="arrow next"
          [disabled]="!clockHours.hasNextPage"
          (click)="nextPage()"
        >
          <img src="assets/icons/gl_arrow_right-pagination.svg" />
        </button>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="!(resize$ | async)?.payload?.mobile">
    <div
      id="containerTable"
      class="containerTable"
      [ngStyle]="{ 'margin-top': tableMarginTop }"
      (scroll)="checkScroll($event)"
    >
      <div class="my-4">
        <div *ngIf="isLoading" class="col-12">
          <roma-loading></roma-loading>
        </div>
        <div
          *ngIf="!data.length && !isLoading"
          class="no-results-wrapper text-center"
        >
          <img class="no-results-ilu" src="assets/icons/no-results-ilu.svg" />
          <h4>{{ 'general.ohNo' | translate }}</h4>
          <p>{{ 'general.noResults' | translate }}</p>
          <button class="btn-secondary clean-filters" (click)="cleanFilters()">
            {{ 'general.cleanFilters' | translate }}
            <img src="assets/icons/gl_trash-blue.svg" />
          </button>
        </div>

        <div
          *ngIf="!isLoading"
          class="col-12"
          [ngClass]="{
            'is-hidden': isLoading || !data.length,
            'margin-bottom-message': deleteMessage
          }"
        >
          <roma-table
            class="table"
            type="adminClockHours"
            idModule="CLOCK_HOUR_MODULE"
            [appendSettings]="'NO'"
            [data]="data"
            [displayedColumns]="[
              'select',
              'userName',
              'position',
              'date',
              'entrance',
              'duration',
              'status',
              'note',
              'entrees',
            ]"
            [showActions]="true"
            (wantEdit)="launchModalClockHourEdit($event)"
            (wantDelete)="deleteClockHour([$event])"
            (checkRowChanges)="deleteMany($event)"
            (wantApprove)="launchModalClockHourEdit($event)"
          >
          </roma-table>
        </div>
      </div>
    </div>
    <div
      *ngIf="deleteMessage"
      class="row justify-content-center justify-content-lg-start"
    >
      <div class="col-12 col-md-11 container__float delete-message-modal">
        <div
          class="delete-message"
          [ngClass]="{ alt: !canApprovalOrRejectAll }"
        >
          <div class="container-left">
            <strong>
              ({{ entriesForDelete.length }})
              {{ 'general.selected' | translate }}
            </strong>
          </div>
          <div class="container-buttons-actions">
            <button
              *ngIf="canApprovalOrRejectAll"
              class="btn info"
              romaProFeature
              [featureUser]="featureUserRef.ClockHourCompany.approve"
              (clicked)="updateStatusClockHour(statusRef.Approved)"
            >
              <img src="assets/icons/gl_check_white.svg" />
              {{ 'general.approve' | translate }}
            </button>
            <button
              *ngIf="canApprovalOrRejectAll"
              class="btn error"
              romaProFeature
              [featureUser]="featureUserRef.ClockHourCompany.approve"
              (clicked)="updateStatusClockHour(statusRef.Rejected)"
            >
              <img src="assets/icons/gl_close_red.svg" />
              {{ 'general.decline' | translate }}
            </button>
          </div>
          <button
            class="btn"
            romaProFeature
            [featureUser]="featureUserRef.ClockHour.delete"
            (clicked)="openDeleteManyDialog()"
          >
            <img src="assets/icons/gl_trash_red.svg" />
            {{ 'general.deleteAlt' | translate }}
          </button>
          <div class="btn-close" (click)="unselectItems()"></div>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="(resize$ | async)?.payload?.mobile" class="container-mobile">
    <roma-list-mobile-clock-hour-admin
      *ngIf="!isLoading && data"
      [data]="data"
      (actions)="mobileActions($event)"
    ></roma-list-mobile-clock-hour-admin>
  </div>
</ng-template>
