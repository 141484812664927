import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import {
  BudgetsSetting,
  EFileType,
  Feature,
  MembershipSchema,
} from '@tacliatech/types';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { BudgetService } from '@web-frontend/shared/services/budgets';

import { Subscription } from 'rxjs';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BudgetUtilisService } from '@web-frontend/core/admin/budgets/budget-edit/budgets-edit.component';
import * as uuid from 'uuid';
import { FileUploadService } from '@web-frontend/shared/helpers/file-upload';
import { environment } from '@web-frontend/environments';
import { LogoCropService } from '../logo-crop.service';
import imageCompression from 'browser-image-compression';
import { StorageService } from '@web-frontend/shared/services';

type ImageFileType = 'original' | 'cropped';

@Component({
  selector: 'roma-logo-crop',
  templateUrl: './logo-crop.component.html',
  styleUrls: ['./logo-crop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoCropComponent implements OnInit {
  logo = '';
  croppedImage = '';
  fileName = '';

  extension = '';

  @ViewChild('fileInput')
  fileInput: ElementRef<HTMLInputElement> | undefined;

  @Input()
  mode: string;

  @Input()
  budget: BudgetsSetting;

  private sub$ = new Subscription();
  isLoading = false;

  canEditBasic = true;
  featureRef = Feature;
  deletedFile = false;
  originalLogo: File;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private fileUploadService: FileUploadService,
    private budgetService: BudgetService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<LogoCropComponent>,
    private budgetUtilisService: BudgetUtilisService,
    private i18n: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private logoCropService: LogoCropService
  ) {}

  get params() {
    return this.data;
  }

  imageChangedEvent: any = '';

  urlLogo: string;

  ngOnInit(): void {
    if (this.data.logo) {
      this.urlLogo = this.data.logo;
      // Estas lineas no estaban cuando se hizo el stash
      if (this.data.settings?.originalLogo) {
        this.data.rawLogo = this.data.settings?.originalLogo;
      }
      // this.extension = this.data.logo.split('.')[1].split('?')[0];
    }

    if ('changeImageEvent' in this.data) {
      this.imageChangedEvent = this.data.changeImageEvent;
      this.originalLogo = this.imageChangedEvent.target.files[0];
    }
  }

  async fileChangeEvent(event?: Event) {
    try {
      this.deletedFile = false;

      if (!event) {
        this.imageChangedEvent = null;
        this.data.rawLogo = '';
        return;
      }

      const file: File | undefined = event?.target?.['files']?.[0];
      await this.logoCropService.allowedDimensions(file);

      this.imageChangedEvent = event;
      this.originalLogo = file;

      this.draw();
    } catch (error) {
      if (typeof error === 'string') {
        this.toastService.show({
          text: this.i18n.instant(error),
          type: 'error',
          msDuration: 4000,
          icon: 'assets/icons/kanban/delete.svg',
        });
      }
    }
  }

  async imageCropped(event: ImageCroppedEvent): Promise<void> {
    this.fileName = uuid.v4();
    const imageFile = await this.dataUrlToFile(
      event.base64,
      this.extension || 'png'
    );

    const options = {
      maxSizeMB: 1, // (max file size in MB)
      maxWidthOrHeight: 500, // compress the image if it's width or height is larger than this value
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      this.croppedImage = await imageCompression.getDataUrlFromFile(
        compressedFile
      );
    } catch (error) {
      this.croppedImage = event.base64;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  async prepareImage(): Promise<File> {
    return this.dataUrlToFile(this.croppedImage, this.extension || 'png');
  }

  async deleteFile(
    fileType: EFileType,
    filePath: string,
    fileName: 'original' | 'cropped'
  ): Promise<void> {
    if (filePath) {
      await this.fileUploadService.deleteFirebaseStorageFile(
        fileType,
        filePath
      );
      await this.updateFileName('', fileName);
    }
  }

  async saveImage(): Promise<void> {
    this.isLoading = true;
    if (this.deletedFile) {
      await Promise.all([
        this.deleteFile(
          EFileType.BUDGET_LOGO,
          this.urlLogo?.split('budget-logo%2F')[1],
          'cropped'
        ),
        this.deleteFile(
          EFileType.BUDGET_LOGO,
          this.data.settings?.originalLogo,
          'original'
        ),
      ]);
      this.draw();
      this.isLoading = false;
      this.close();
      return;
    }

    await this.handleImageFile(await this.prepareImage(), 'cropped');
    if (this.originalLogo) {
      await this.handleImageFile(this.originalLogo, 'original');
    }

    this.draw();
    this.isLoading = false;
    this.close();
  }

  async dataUrlToFile(base64: string, ext: string): Promise<File> {
    const res: Response = await fetch(base64);
    const blob: Blob = await res.blob();

    return new File([blob], this.fileName + '.' + ext, {
      type: 'image/' + ext,
    });
  }

  async handleImageFile(file: File, type: ImageFileType): Promise<void> {
    const fileName = `${uuid.v4()}.${file.name?.split('.').pop()}`;
    const path = `uploads/${EFileType.BUDGET_LOGO}/${fileName}`;

    try {
      await this.fileUploadService
        .uploadFileToAssetsAPI(EFileType.BUDGET_LOGO, file, path)
        .toPromise();
      await this.updateFileName(`${fileName}?alt=media`, type);
    } catch (e) {
      this.toastService.show({
        text: 'activity.error',
        type: 'error',
        msDuration: 4000,
      });
    }
  }

  async updateFileName(
    img: string,
    type: 'original' | 'cropped'
  ): Promise<void> {
    const data = await this.budgetService
      .updateOneSettings(this.data.id, {
        [type === 'cropped' ? 'logo' : 'originalLogo']: img,
      })
      .toPromise();

    if (type === 'cropped') {
      this.data.logo = img;
      this.toastService.show({
        text: this.i18n.instant('general.updatePropertySuccess'),
        type: 'success',
        msDuration: 4000,
      });
    } else if (type === 'original') {
      this.data.originalLogo = img;
    }

    this.budgetUtilisService.refreshBudgetSettings.emit(data);
    this.draw();
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  determineCopy(copy: string): string {
    if (environment.MembershipSchema === MembershipSchema.OneProPlan) {
      return `${copy}-new`;
    } else {
      return copy;
    }
  }

  async deleteImage(): Promise<void> {
    await this.fileChangeEvent();
    this.deletedFile = true;
  }

  removeDuplicatePrefix(str: string) {
    const prefix =
      'https://firebasestorage.googleapis.com/v0/b/taclia.appspot.com/o/uploads%2Fbudget-logo%2F';
    if (str?.startsWith(prefix)) return str.replace(prefix, '');
    return str;
  }

  //getBase64FromUrl('https://lh3.googleusercontent.com/i7cTyGnCwLIJhT1t2YpLW-zHt8ZKalgQiqfrYnZQl975-ygD_0mOXaYZMzekfKW_ydHRutDbNzeqpWoLkFR4Yx2Z2bgNj2XskKJrfw8').then(console.log)
}
