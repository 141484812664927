import { ChangeDetectorRef, Component } from '@angular/core';
import { BillDigitalCertificateService } from '@web-frontend/shared/services/bill-digital-certificate/bill-digital-certificate.service';
import { FileHandle } from '../../file-upload/file-upload.component';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'send-digital-certificate',
  templateUrl: './send-digital-certificate.component.html',
  styleUrls: ['./send-digital-certificate.component.scss'],
})
export class SendDigitalCertificateComponent {
  fileCer: FileHandle;
  fileKey: FileHandle;

  constructor(
    private billDigitalCertificateService: BillDigitalCertificateService,
    private amplitudeService: AmplitudeService,
    private changeDetectionRef: ChangeDetectorRef,
    private toastService: ToastService
  ) {}

  password = '';
  isLoading = false;
  sentOk = false;

  get isMobile(): boolean {
    return ['android', 'ios'].includes(Capacitor.getPlatform());
  }

  get isButtonDisabled(): boolean {
    return !this.fileKey || !this.fileCer || !this.password;
  }

  onCerFileChange($event: FileHandle[]): void {
    if ($event.length > 0) {
      this.fileCer = $event[0];
      this.amplitudeService.sendEvent({
        event: 'bill_activation_uploadFile',
      });
    } else {
      this.fileCer = null;
    }
  }

  onKeyFileChange($event: FileHandle[]): void {
    if ($event.length > 0) {
      this.fileKey = $event[0];
      this.amplitudeService.sendEvent({
        event: 'bill_activation_uploadFile',
      });
    } else {
      this.fileKey = null;
    }
  }

  onPasswordChange($event): void {
    this.amplitudeService.sendEvent({
      event: 'bill_activation_password',
    });
    this.password = $event;
  }

  async onContinue(): Promise<void> {
    this.isLoading = true;
    if (!this.fileKey || !this.fileCer || !this.password) {
      this.isLoading = false;
      return;
    }
    this.amplitudeService.sendEvent({
      event: 'bill_activation_continue',
    });
    try {
      const response = await this.billDigitalCertificateService.sendApplication(
        this.fileCer,
        this.fileKey,
        this.password
      );
      this.sentOk = true;
      if (response?.status !== 201) {
        this.sentOk = false;
        this.toastService.show({
          text: 'fileUpload.uploadError',
          type: 'error',
          msDuration: 4000,
        });
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
    this.draw();
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  contactUs(): void {
    this.amplitudeService.sendEvent({ event: 'bill_activation_contactUs' });
  }
}
