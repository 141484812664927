<roma-modal-template
  size="md"
  [showDefaultButtons]="false"
  [showHeader]="false"
  [responsiveFull]="false"
  [contentMinHeight]="false"
  [footerTemplate]="footerCustomTemplate"
  (cancel)="close()"
>
  <div class="row text-center">
    <div class="col-12">
      <img class="modal-image" src="assets/icons/clockhour-illu.svg" />
      <h2 class="modal-title" *ngIf="data.showFooter">{{ data.work }}</h2>
      <h2 class="modal-title" *ngIf="!data.showFooter">
        {{ 'clockHour.chronoDialog.maxTimeTitle' | translate }}
      </h2>
      <p class="modal-content" *ngIf="data.showFooter">
        {{ 'clockHour.chronoDialog.probably' | translate }}
      </p>
      <p class="modal-content" *ngIf="!data.showFooter">
        {{ 'clockHour.chronoDialog.maxTime' | translate }}
      </p>
    </div>
  </div>
</roma-modal-template>

<ng-template #footerCustomTemplate>
  <div *ngIf="data.showFooter" class="modalFooter center">
    <base-button (click)="submit()">
      {{ 'clockHour.chronoDialog.yes' | translate }}
    </base-button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <base-button buttonClass="tertiary" (click)="close()">
      {{ 'clockHour.chronoDialog.no' | translate }}
    </base-button>
  </div>
</ng-template>
