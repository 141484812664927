import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RmBadgeComponent } from './rm-badge.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [RmBadgeComponent],
  imports: [CommonModule, TranslateModule.forChild()],
  exports: [RmBadgeComponent],
})
export class RmBadgeModule {}
