import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  CompanyRegisterRequestDto,
  ICategorySector,
  ISector,
} from '@tacliatech/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientRegisterService implements OnInit {
  constructor(private http: HttpClient) {}

  ngOnInit() {}

  public register(user: CompanyRegisterRequestDto) {
    return this.http.post(`:API_URL/auth/register`, user);
  }

  public getByEmail(email: string) {
    return this.http.get(`:API_URL/users/repeated-email/${email}`);
  }

  public getByPhone(phone: string) {
    return this.http.get(`:API_URL/users/repeated-phone/${phone}`);
  }

  public getCategories(): Observable<ICategorySector[]> {
    return this.http.get<ICategorySector[]>(`:API_URL/categorysectors`);
  }

  public getSectors(): Observable<ISector[]> {
    return this.http.get<ISector[]>(`:API_URL/sectors`);
  }

  public getSectorById(sector: string): Observable<ISector> {
    return this.http.get<ISector>(`:API_URL/sectors/${sector}`);
  }

  public getSectorByIdModule(sector: string): Observable<ISector> {
    return this.http.get<ISector>(`:API_URL/sectors/${sector}/module`);
  }
}
