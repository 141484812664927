<mat-chip-list
  *ngIf="phones?.length"
  class="phone-container"
  aria-label="Phones lists"
>
  <mat-chip
    *ngFor="let phone of phones"
    class="phone-item"
    color="primary"
    [removable]="true"
    [selectable]="true"
    (removed)="remove(phone)"
  >
    <mat-icon *ngIf="showPhoneIcon"> phone </mat-icon>
    {{ phone }}
    <mat-icon matChipRemove>cancel</mat-icon>
  </mat-chip>
</mat-chip-list>

<form class="container-footer" [formGroup]="form">
  <ng-container *ngIf="type === 'tel'">
    <input
      romaPhoneDirective
      formControlName="phone"
      class="phone-input"
      [type]="type"
      [placeholder]="inputPh | translate"
    />
  </ng-container>
  <ng-container *ngIf="type === 'email'">
    <input
      formControlName="phone"
      class="phone-input"
      [type]="type"
      [placeholder]="inputPh | translate"
    />
  </ng-container>
  <button
    class="taclia"
    mat-mini-fab
    color="accent"
    [disabled]="!form.valid"
    (click)="submit()"
  >
    <mat-icon>add</mat-icon>
  </button>
</form>
