import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { UserDealsSelectModalComponent } from './user-deals-select-modal.component';

@Injectable({
  providedIn: 'root',
})
export class UserDealsSelectModalService {
  constructor(private dialog: MatDialog) {}

  open(config: MatDialogConfig<any> = new MatDialogConfig()) {
    const dialogRef = this.dialog.open(UserDealsSelectModalComponent, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        //this.refreshListSource.next(res);
      })
    );
  }
}
