import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapacitorUpdateComponent } from './capacitor-update.component';
import { ModalTemplateModule } from '@web-frontend/shared/components/modal-template';
import { TranslateModule } from '@ngx-translate/core';
import { BaseButtonModule } from '../../../../common/components/button/baseButton.module';

@NgModule({
  declarations: [CapacitorUpdateComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    TranslateModule.forChild(),
    BaseButtonModule,
  ],
  exports: [CapacitorUpdateComponent],
})
export class CapacitorUpdateModule {}
