export enum EFileType {
  ADDRESS = 'address',
  BILL = 'bil',
  BUDGET_LOGO = 'budget-logo',
  BUDGETS = 'budgets',
  DEAL = 'deal',
  EQUIPMENT = 'equipment',
  EVIDENCE = 'evidence',
  FINAL = 'final',
  INTERNAL_VENDOR = 'internal-vendor',
  NOTES = 'notes',
  PROJECT = 'project',
  USER = 'user',
  ABSENCE = 'absence',
  ACTIVITY = 'activity',
}

export enum FILETYPE {
  PDF = 'pdf',
  HTML = 'html',
}

export default EFileType;
