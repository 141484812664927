import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  BudgetsSetting,
  EFileType,
  Feature,
  FeatureUser,
  ValidateAllFormFields,
} from '@tacliatech/types';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import { StorageService } from '@web-frontend/shared/services';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { FilesUploadService } from '@web-frontend/shared/services/file-upload';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BudgetUtilisService } from '../../../core/admin/budgets/budget-edit/budgets-edit.component';
import { LogoCropService } from './logo-crop.service';
import { FileUploadService } from '@web-frontend/shared/helpers/file-upload';
import { PermissionService } from '@web-frontend/shared/services/permissions';

@Component({
  selector: 'roma-budget-logo',
  templateUrl: './budget-logo.component.html',
  styleUrls: ['./budget-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetLogoComponent implements OnInit, OnDestroy {
  logo: any = '';

  @Input()
  mode: string;

  @Input()
  center = true;

  @Input()
  editable = true;

  @Input()
  budget: BudgetsSetting;

  /* This parameter allow show/hide all labels, is use in budget-edit component */
  @Input()
  showLabels = true;

  private sub$ = new Subscription();

  canEditBasic = true;
  isLoadingModalCropImage = false;

  featureRef = Feature;
  featureUserRef = FeatureUser;
  hasFeatureSeeLogo$ = this.permissionService.hasFeaturesFn([
    // Feature.Deal.SeeLogo,
    // Feature.Sale.SeeBillLogo,
    Feature.SystemPermission.DefaultAllow,
  ]);

  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private filesUploadService: FilesUploadService,
    private fileUploadService: FileUploadService,
    private budgetService: BudgetService,
    private budgetUtilisService: BudgetUtilisService,
    private alertService: AlertService,
    private i18n: TranslateService,
    private logoCropService: LogoCropService,
    private permissionService: PermissionService
  ) {}

  form: FormGroup = this.fb.group({
    logo: new FormControl(null, [Validators.required]),
    createdBy: new FormControl(StorageService.UserId, [Validators.required]),
  });

  imageChangedEvent: any = '';
  croppedImage: any = '';

  testSPlit() {
    const url =
      'https://firebasestorage.googleapis.com/v0/b/taclia.appspot.com/o/uploads%2Fbudget-logo%2Flogocopado.png?alt=media&token=68cacb01-b544-44a9-9c80-6d427cc0d9cd';

    const urlWithAltMedia = url.split('%2F')[2].split('&')[0];
    return urlWithAltMedia;
  }

  ngOnInit(): void {
    if (this.budget === undefined) {
      this.mode = 'ADD';
      if (this.budget?.logo === undefined || this.budget?.logo === null) {
        this.mode = 'ADD';
      } else {
        this.mode = 'EDIT';

        this.updateForm(this.budget.logo);
      }
    } else {
      if (this.budget?.logo === undefined || this.budget?.logo === null) {
        this.mode = 'ADD';
      } else {
        this.mode = 'EDIT';
      }
    }
  }

  updateForm(param: string) {
    this.form.patchValue({
      logo: param,
    });
  }

  watchChanges() {
    this.logoCropService.refreshList$.subscribe((data) => {
      //console.log('refreshList');
    });
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  changeImage() {}

  handleImageFile(files: FileList) {
    if (this.mode === 'EDIT' || this.budget?.logo !== null) {
      this.fileUploadService
        .deleteFirebaseStorageFile(EFileType.BUDGET_LOGO, this.budget?.logo)
        .then((result) => {})
        .catch((error) => {});
    }

    const file: File = files.item(0);
    const uploadObject = this.fileUploadService
      .uploadFile(EFileType.BUDGET_LOGO, file)
      .subscribe((url) => {
        this.budget.logo = url;
        this.logo = url;
        this.draw();
        this.updateFileName(url);
      });

    /*     this.fileUploadService
      .uploadFiles('budget-logo', files)
      .subscribe((resp: any) => {
        if (resp) {
          const fileName = resp[0].filename;

          this.budget.logo = fileName;
          this.logo = fileName;

          this.draw();
          this.updateFileName(fileName);
        }
      }); */
  }

  openPreview() {
    const config = {
      data: {
        logo: this.budget?.logo,
        id: this.budget?._id,
        previewMode: true,
      },
    };
    if (this.editable) {
      this.isLoadingModalCropImage = true;
      this.sub$.add(
        this.logoCropService
          .open(config)
          .pipe(
            finalize(() => {
              this.isLoadingModalCropImage = false;
            })
          )
          .subscribe()
      );
    }
  }

  open() {
    if (this.editable) {
      const config = {
        data: { logo: this.budget?.logo, id: this.budget?._id },
      };
      this.sub$.add(
        this.logoCropService
          .open(config)
          .pipe(
            finalize(() => {
              this.isLoadingModalCropImage = false;
            })
          )
          .subscribe()
      );
    } else {
      this.alertService.error(this.i18n.instant('general.adminRole'));
    }
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);
    return this.form.valid;
  }

  updateFileName(img: string) {
    this.sub$.add(
      this.budgetService
        .updateOneSettings(this.budget._id, { logo: img })
        .subscribe((data) => {
          this.budget.logo = img;
          this.updateForm(img);
          this.budgetUtilisService.refreshBudgetSettings.emit(data);
          this.draw();
          this.alertService.success(
            this.i18n.instant('general.updatePropertySuccess')
          );
        })
    );
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }
}
