<roma-modal-template
  [title]="'general.sendByEmailLong' | translate"
  [contentMinHeight]="false"
  size="sm"
  [footerTemplate]="footerButtons"
  [showDefaultButtons]="false"
  (cancel)="close()"
  [back]="true"
  [responsiveFull]="(resize$ | async)?.payload?.mobile ? false : true"
>
  <div class="form-body">
    <form [formGroup]="form">
      <div class="col-12">
        <div class="row">
          <!-- <div class="col-12 pr-10">
            <span>{{ 'general.emailTag' | translate }}</span>
          </div> -->

          <div class="form-group">
            <label class="my-2 d-flex" for="to"
              ><span class="font-weight-bold">{{
                'general.emailTag' | translate
              }}</span>
              &nbsp;
              <mat-progress-spinner
                *ngIf="isLoading"
                class="loading-spinner"
                mode="indeterminate"
                [diameter]="20"
              ></mat-progress-spinner
            ></label>

            <input
              *ngIf="!isLoading"
              id="to"
              formControlName="to"
              type="text"
              class="form-control"
              [ngClass]="{ 'invalid-border': form.invalid && form.touched }"
              placeholder="ejemplo@mail.com"
            />
            <p class="invalid-text" *ngIf="form.invalid && form.touched">
              <!-- {{ form.get('to').value }} -->
              {{ 'register.error.valid_email' | translate }}
            </p>
          </div>

          <!-- <div class="col-10">
            <mat-chip-list
              class="email-chips"
              #chipList
              aria-label="Destinatarios"
            >
              <mat-chip
                *ngFor="let to of destinations"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(to)"
              >
                {{ to }}
                <button matChipRemove *ngIf="removable">
                  <img [src]="'assets/icons/gl_close-input.svg'" />
                </button>
              </mat-chip>
              <input
                matInput
                #toInput="matInput"
                [class.is-focus]="toInput.focused"
                [placeholder]="'general.exampleEmail' | translate"
                type="email"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)"
              />
            </mat-chip-list>
            <roma-field-error-display
              style="font-size: 12px !important"
              #fieldTo
              fieldName="to"
              [errorMsg]="'billing.emailRequired' | translate"
              [form]="form"
            >
            </roma-field-error-display>
          </div> -->
        </div>
        <!-- <div class="row">
          <input id="inputEmail" type="text" placeholder="correo@gmail.com" />
        </div> -->
      </div>

      <!-- <div class="col-12">
        <div class="row input-row inputArea">
          <div class="col-2 pr-10">
            <span>{{ 'general.subject' | translate }}</span>
          </div>
          <div class="col-10">
            <input
              matInput
              #subjectInput="matInput"
              [class.is-focus]="subjectInput.focused"
              formControlName="subject"
              name="Asunto"
              [placeholder]="'billing.placeholderSubject' | translate"
              [ngClass]="{
                'is-invalid': fieldSubject?.displayError || false
              }"
            />
            <roma-field-error-display
              style="font-size: 12px !important"
              #fieldSubject
              fieldName="subject"
              [errorMsg]="'billing.subjectRequired' | translate"
              [form]="form"
            >
            </roma-field-error-display>
          </div>
        </div>
      </div>

      <div class="form-group">
        <textarea
          rows="6"
          class="inputArea"
          cols="40"
          [maxLength]="500"
          matInput
          formControlName="message"
          [placeholder]="'billing.placeholderMessage' | translate"
        >
        </textarea>
      </div> -->
    </form>

    <!-- <div class="col-12 info-box">
      <img [src]="'assets/icons/gl_information-fill.svg'" />
      {{ 'deal_part_of_service.sendMessage' | translate }}
    </div> -->
  </div>
</roma-modal-template>

<ng-template #footerButtons>
  <div class="modalFooter">
    <div class="groupEndDiv">
      <base-button color="link" buttonClass="tertiary" (click)="close()">
        {{ 'activity.create.cancel' | translate }}
      </base-button>
      <base-button
        (click)="send()"
        [disabled]="!this.destinations.length"
        [isLoading]="isLoading"
      >
        {{ 'general.send' | translate }}
      </base-button>
    </div>
  </div>
</ng-template>
