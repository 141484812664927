import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import Swal from 'sweetalert2';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private _snackBar: MatSnackBar) {}

  sure(title: string, text: string) {
    const dialog = Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok',
    });

    return from(dialog);
  }

  success(message: string, options?) {
    if (!options) {
      options = {};
    }
    this.showMessage(message, 'OK', options);
  }

  error(message: string, options?) {
    if (!options) {
      options = {};
    }
    options.panelClass = 'warning-snackbar';
    options.duration = 5000;
    this.showMessage(message, ' X ', options);
  }

  private showMessage(message: string, type, options?) {
    if (!options) {
      options = {};
    }
    if (!options.duration) {
      options.duration = 2000;
    }
    this._snackBar.open(message, type, options);
  }
}
