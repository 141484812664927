import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Injectable,
  Input,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { User } from '@web-frontend/shared/class';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelect } from '@angular/material/select';

import { fromEvent, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { FilterItem, FilterItems } from './filter-types';
import {
  Company,
  ConvertToKeyValue,
  DynamicPropertyType,
  Feature,
  FeatureUser,
  FIRST_DAY_CALENDAR,
  IBudgetsSetting,
  ICustomProperty,
  IVertical,
  TypeFinalsClients,
} from '@tacliatech/types';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FormControl, FormGroup } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
  NativeDateAdapter,
} from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AssetSearchService,
  AssetService,
  DealService,
  FinalService,
  ProjectService,
  SandboxService,
  StatusService,
  StorageService,
  UserService,
} from '@web-frontend/shared/services';
import { CompanyModuleService } from '@web-frontend/shared/services/company';
import { CategoryExpensesService } from '@web-frontend/shared/services/category-expenses';
import { getDateStrResum } from '@web-frontend/shared/utils';
import { Platform } from '@angular/cdk/platform';
import { BudgetService } from '@web-frontend/shared/services/budgets';

//Format date range input DD/MM/YY
@Injectable({
  providedIn: 'root',
})
export class AppDateAdapter extends NativeDateAdapter {
  private dayInit = StorageService.FirstDayCalendar;
  lang: string;

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string,
    platform: Platform,
    private budgetService: BudgetService
  ) {
    super(matDateLocale, platform);
  }

  private getCompanySettings() {
    const id = StorageService.CompanyId;
    this.budgetService
      .findSettingsByCompany(id)
      .subscribe((resp: IBudgetsSetting) => {
        if (resp.budgetPreferences?.day) {
          this.dayInit = resp.budgetPreferences.day;
          StorageService.SetItem(FIRST_DAY_CALENDAR, this.dayInit);
        }
      });
  }

  getFirstDayOfWeek(): number {
    this.dayInit = StorageService.FirstDayCalendar;
    this.getCompanySettings();
    return this.dayInit;
  }

  format(date: Date, displayFormat: any): string {
    this.lang = localStorage.getItem('currentLang');
    if (!this.lang || this.lang === undefined) {
      localStorage.setItem('currentLang', 'es-ES');
      this.lang = 'es-ES';
    }
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      const year = date.getFullYear().toString().slice(-2);
      return `${day}/${month}/${year}`;
    }

    return Intl.DateTimeFormat(this.lang, {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }).format(date);
  }

  // getFirstDayOfWeek(): number {
  //   return 1;
  // }
}
//Bind format date range input DD/MM/YY
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

@Component({
  selector: 'roma-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
  ],
})
export class FilterComponent implements AfterViewInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calcFiltersMenuPosition();
  }

  FiltersMenuToLeft = false;

  range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });

  @ViewChild('inputFilter')
  input: ElementRef<HTMLInputElement>;

  @ViewChild('startDateInput')
  startDateInput: ElementRef<HTMLInputElement>;

  @ViewChild('endDateInput')
  endDateInput: ElementRef<HTMLInputElement>;

  @ViewChild('selectAuthor')
  selectAuthor: NgSelectComponent;

  @ViewChild('statusSelectComponent')
  statusSelect: MatSelect;

  @ViewChildren(NgSelectComponent)
  propertySelect: QueryList<NgSelectComponent>;

  @ViewChildren('propertyInput')
  propertyInput: QueryList<HTMLInputElement>;

  @ViewChildren('singleDate')
  propertyDateInput: QueryList<HTMLInputElement>;

  @Input()
  set status(els: FilterItems) {
    this._statusOrg = els;
    this._status = els;
  }

  get status() {
    return this._status;
  }

  @Input()
  set authors(els: FilterItems) {
    this._authorsOrg = els;
    this._authors = els;
  }

  get authors() {
    return this._authors;
  }

  @Input()
  finals: FilterItems = [];

  @Input()
  deals: FilterItems = [];

  @Input()
  set types(els: FilterItems) {
    this._types = els;
  }

  get types() {
    if (this.rmSelectRequest) {
      return (this._types = this.convertToRmSelectObject(this._types));
    }
    return this._types;
  }

  @Input()
  criteries: FilterItems = [];

  @Input()
  set assets(els: FilterItems) {
    this._addressOrg = els;
    this._address = els;
  }

  get assets() {
    return this._address;
  }

  @Input()
  set roles(els: FilterItems) {
    this._roles = els;
  }

  get roles() {
    if (this.rmSelectRequest) {
      return (this._roles = this.convertToRmSelectObject(this._roles));
    }
    return this._roles;
  }

  _elementsRm;
  @Input()
  set elements(els: FilterItems) {
    this._elementsOrg = els;
    this._elements = els;
    this._elementsRm = this.convertToRmSelectObject(this._elements);
  }

  get elements() {
    return this._elements;
  }

  @Input()
  set items(els: FilterItems) {
    this._itemsOrg = els;
    this._items = els;
  }

  get items() {
    return this._items;
  }

  @Input()
  set categorys(els: FilterItems) {
    this._categorys = els;
  }

  get categorys() {
    if (this.rmSelectRequest) {
      return (this._categorys = this.convertToRmSelectObject(this._categorys));
    }
    return this._categorys;
  }

  @Input()
  customers: FilterItems = [];

  @Input()
  set statusBudgetslist(els: FilterItems) {
    this._statusBudgetslist = els;
  }

  get statusBudgetslist() {
    if (this.rmSelectRequest) {
      return (this._statusBudgetslist = this.convertToRmSelectObject(
        this._statusBudgetslist
      ));
    }
    return this._statusBudgetslist;
  }

  @Input()
  inputPlaceholder: string;

  @Input()
  criteriesPlaceholder: string;

  @Input()
  typePlaceholder = 'filter.type_service';

  @Input()
  viewDelete = '';

  @Input()
  viewToday = '';

  @Input()
  viewPerformed = '';

  @Input()
  viewCanceled = '';

  @Input()
  user: User;

  @Input()
  authorsPlaceholder = 'asset.managersPh';

  @Input()
  assignedsPlaceholder = 'filter.assigned';

  @Input()
  statusBudgetsPlaceholder = 'filter.status-budgets';

  @Input()
  chargedsPlaceholder = 'filter.charged';

  @Input()
  customersPlaceholder = 'filter.final';

  @Input()
  categoriesPlaceholder = 'filter.categories-budget';

  @Input()
  finalsPlaceholder = 'filter.final';

  @Input()
  statusPlaceholder = 'filter.status';

  @Input()
  assetPlaceholder = 'filter.address';

  @Input()
  startPlaceholder = 'filter.start_date';

  @Input()
  dealsPlaceholder;

  @Input()
  endPlaceholder = 'filter.end_date';

  @Input()
  elementsPlaceholder: string;

  @Input()
  itemsPlaceholder: string;

  @Input()
  showDeleted = false;

  @Input()
  showToday = false;

  @Input()
  showFinished = false;

  @Input()
  showDatePicker = true;

  @Input()
  showSingleDatePicker = false;

  @Input()
  showVerticals = false;

  @Input()
  showStatus = true;

  @Input()
  showAuthors = true;

  @Input()
  showDeals = false;

  @Input()
  showFinalTypes = false;

  @Input()
  showExpenseCategory = false;

  @Input()
  showAssigneds = false;

  @Input()
  authorSelectMultiple = true;

  @Input()
  authorClearable = true;

  @Input()
  showManagers = false;

  @Input()
  showProjects = false;

  @Input()
  showFinals = false;

  @Input()
  showInputSearch = true;

  @Input()
  showTypes = false;

  @Input()
  showAssets = false;

  @Input()
  showRol = false;

  @Input()
  showCancelled = false;

  @Input()
  showNotified = false;

  @Input()
  showPublicProfile = false;

  @Input()
  showStatusEdit = false;

  @Input()
  showStatusAdd = false;

  @Input()
  showCategorys = false;

  @Input()
  showCustomers = false;

  @Input()
  showCharged = false;

  @Input()
  showStatusBudget = false;

  @Input()
  showDateBudget = false;

  @Input()
  showCriteries = false;

  @Input()
  deleteMessage = 'filter.see_deleted';

  @Input()
  todayMessage = 'filter.see_today';

  @Input()
  showRestore = false;

  @Input()
  verticals: Array<IVertical>;

  @Input()
  idsSelectVertical: string[];

  @Input()
  customProperties: ICustomProperty[] = [];

  @Input()
  disabled = false;

  @Input()
  expanded = false;

  @Input()
  buttom = true;

  @Input()
  childComponent: any = null;

  @Output()
  OnAddClick = new EventEmitter<MouseEvent>();

  @Input()
  tag = '';

  @Input()
  type = '';

  @Input()
  ifRangeActive = false;

  @Input()
  showAddUserButton = false;

  @Input()
  showAddButton = true;

  @Input()
  showBtnAddCreate = true;

  @Output()
  changeSingleDatePickerBus = new EventEmitter<HTMLInputElement>(null);

  @Output()
  saveFilters = new EventEmitter();

  @Output()
  changeVertical = new EventEmitter<Array<unknown>>();

  @Output()
  changeStatus = new EventEmitter<FilterItems>();

  @Output()
  changeAuthor = new EventEmitter<FilterItems>();

  @Output()
  changeManager = new EventEmitter<FilterItems>();

  @Output()
  changeAssigned = new EventEmitter<FilterItems>();

  @Output()
  changeFinal = new EventEmitter<FilterItems>();

  @Output()
  changeDeal = new EventEmitter<FilterItems>();

  @Output()
  changeTypes = new EventEmitter<FilterItems>();

  @Output()
  changeAssets = new EventEmitter<FilterItems>();

  @Output()
  changeCriteries = new EventEmitter<FilterItem>();

  @Output()
  changeRol = new EventEmitter<FilterItems>();

  @Output()
  changeElements = new EventEmitter<FilterItems>();

  @Output()
  changeItems = new EventEmitter<FilterItems>();

  @Output()
  inputChange = new EventEmitter<string>();

  @Output()
  startDateChange = new EventEmitter<Date>();

  @Output()
  resetRangeFilter = new EventEmitter<void>();

  @Output()
  endDateChange = new EventEmitter<Date>();

  @Output()
  addStatus = new EventEmitter<MouseEvent>();

  @Output()
  editStatus = new EventEmitter<FilterItem>();

  @Output()
  addClick = new EventEmitter<MouseEvent>();

  @Output()
  changeCustomer = new EventEmitter<FilterItems>();

  @Output()
  changeCharged = new EventEmitter<boolean>();

  @Output()
  changeStatusBudget = new EventEmitter<FilterItems>();

  @Output()
  startChangeDateBudget = new EventEmitter<Date>();

  @Output()
  endChangeDateBudget = new EventEmitter<Date>();

  @Output()
  changeCancelled = new EventEmitter<boolean>();

  @Output()
  changeNotified = new EventEmitter<boolean>();

  @Output()
  changeDeleted = new EventEmitter<boolean>();

  @Output()
  changeToday = new EventEmitter<boolean>();

  @Output()
  changeFinished = new EventEmitter<boolean>();

  @Output()
  changePublicProfile = new EventEmitter<boolean>();

  @Output()
  changeCategory = new EventEmitter<FilterItems>();

  @Output()
  changeCustomProperties = new EventEmitter<{ [key: string]: any }>();

  openMobileFilters = false;
  selectedStatus: FilterItems = [];
  selectedDeals: FilterItems = [];
  selectedElements: FilterItems = [];
  selectedCategories: FilterItems = [];
  selectedRol: FilterItems = [];
  selectedstatusBudgets: FilterItems = [];
  selectedReporter: FilterItems = [];
  selectedProjects: FilterItems = [];
  selectedFinals: FilterItems = [];
  selectedAuthors: FilterItems = [];
  selectedAssigneds: FilterItems = [];
  selectedCustomers: FilterItems = [];
  selectedFinalType: FilterItems = [];
  customParamsNumberFilter!: 'MINOR' | 'MAJOR' | 'EQUAL';
  customParamsNumberFilterActive = false;
  hoursLimit!: string;
  featureRef = Feature;
  featureRefUser = FeatureUser;

  selectedType: FilterItems = [];
  selectedAsset: FilterItems = [];
  selectedChanel: FilterItems = [];
  selectedStartDate;
  selectedEndDate;
  takeToday = false;
  takeCancelled = false;
  takeDeleted = false;
  takeNotified = false;
  selectedFilter: string;
  selectedFilters: any = {};
  openSubfilter = false;
  activeFilters = false;

  dynamicPropertyTypeRef = DynamicPropertyType;

  private _items: FilterItems = [];
  private _itemsOrg: FilterItems = [];

  private _authors: FilterItems = [];
  private _authorsOrg: FilterItems = [];

  private _elements: FilterItems = [];
  private _elementsOrg: FilterItems = [];

  private _status: FilterItems = [];
  private _statusOrg: FilterItems = [];

  private _address: FilterItems = [];
  private _addressOrg: FilterItems = [];

  private _categorys: FilterItems = [];
  private _types: FilterItems = [];
  private _roles: FilterItems = [];
  private _statusBudgetslist: FilterItems = [];

  private customPropertiesParams: { [key: string]: any } = {};

  private restoreStatus = false;

  private sub$ = new Subscription();

  projectSearchItem = '';
  reporterSearchItem = '';
  assignedSearchItem = '';
  statusSearchItem = '';
  addressSearchItem = '';
  finalSearchItem = '';
  currentLang = 'en-US';

  resize$ = this.sandBoxService.screenBusChannel$;

  authorsLoading = false;
  authorsCharged = false;
  finalsLoading = false;
  finalsCharged = false;
  projectsLoading = false;
  projectCharged = false;
  assetsLoading = false;
  assetsCharged = false;
  usersLoading = false;
  usersCharged = false;
  statusLoading = false;
  statusCharged = false;
  dealsLoading = false;
  dealsCharged = false;
  rmSelectRequest = false;
  showFiltersMenu = false;
  showFiltersActivityMenu = false;
  activeRangeDate = false;
  activeCustomFilters = false;
  activeCancelled = false;
  activeDeleted = false;
  activeRestore = false;
  activeToday = false;
  activeFinished = false;
  activeProfile = false;
  activeNotified = false;
  startDateInputValue = '';
  endDateInputValue = '';

  constructor(
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private translate: TranslateService,
    private sandBoxService: SandboxService,
    private changeDetectorRef: ChangeDetectorRef,
    private assetSearchService: AssetSearchService,
    private companyModuleService: CompanyModuleService,
    private projectService: ProjectService,
    private userService: UserService,
    private assetService: AssetService,
    private statusService: StatusService,
    private dealService: DealService,
    private categoryExpensesService: CategoryExpensesService,
    private finalService: FinalService
  ) {
    translate.onLangChange.subscribe((event) => {
      this.setCalendarsLang(event.lang);
    });
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  get userFeatureCreate(): string {
    if (!this.type) {
      return this.featureRef.SystemPermission.DefaultAllow;
    }
    switch (this.type.toLowerCase()) {
      case 'deal':
        return this.featureRefUser.Deal.create;
      case 'activities':
        return this.featureRefUser.Activity.create;
      case 'finals':
        return this.featureRefUser.Final.create;
      case 'project':
        return this.featureRefUser.Project.create;
      case 'address':
        return this.featureRefUser.Address.create;
      case 'expenses':
        return this.featureRefUser.Expense.create;
      case 'internalvendor':
        return this.featureRefUser.InternalVendor.create;
      case 'equipment':
        return this.featureRefUser.Equipment.create;
      case 'bill':
        return this.featureRefUser.Bill.create;
      case 'bdg':
        return this.featureRefUser.Quote.create;
      case 'profm':
        return this.featureRefUser.Proform.create;
      case 'catalogues':
        return this.featureRefUser.Catalogue.create;
      default:
        return this.featureRef.SystemPermission.DefaultAllow;
    }
  }
  /******************************* NGSELECT ONCLICK LOAD START ******************************************* */

  onClickSelect(type: string, item: string) {
    if (type === 'select') {
      status;
      this.rmSelectRequest = true;
    } else {
      this.rmSelectRequest = false;
    }

    switch (item) {
      case 'authors':
        if (!this.authorsCharged) {
          this.authorsCharged = true;
          this.requestAuthors();
        }
        break;
      case 'finals':
        if (!this.finalsCharged) {
          this.finalsCharged = true;
          this.requestFinals();
        }
        break;
      case 'projects':
        if (!this.projectCharged) {
          this.projectCharged = true;
          this.requestProjects();
        }
        break;
      case 'assets':
        if (!this.assetsCharged) {
          this.assetsCharged = true;
          this.requestAssets();
        }
        break;
      case 'users':
        if (!this.usersCharged) {
          this.usersCharged = true;
          this.requestUsers();
        }
        break;
      case 'status':
        if (!this.statusCharged) {
          this.statusCharged = true;
          this.requestStatus();
        }
        break;
      case 'deals':
        if (!this.dealsCharged) {
          this.dealsCharged = true;
          this.requestDeals();
        }
        break;
      case 'finalTypes':
        if (!this.statusCharged) {
          this.statusCharged = true;
          this.resolveTypeFinalsClients();
        }
        break;
      case 'expenseCategory':
        if (!this.statusCharged) {
          this.statusCharged = true;
          this.requestCategoryExpenses();
        }
        break;
    }
    this.draw();
  }

  private requestCategoryExpenses() {
    this.statusLoading = true;
    this.sub$.add(
      this.categoryExpensesService.findAll().subscribe((res) => {
        this.status = ConvertToKeyValue(res, 'name', '_id');
        if (this.rmSelectRequest) {
          this.status = this.convertToRmSelectObject(this.status);
        }
        this.statusLoading = false;
        this.draw();
      })
    );
  }

  private resolveTypeFinalsClients() {
    const items = ConvertToKeyValue(
      TypeFinalsClients,
      StorageService.GetItem('USER_LANG'),
      'id'
    );

    this.status = items;
    if (this.rmSelectRequest) {
      this.status = this.convertToRmSelectObject(this.status);
    }
    this.draw();
  }

  private requestDeals() {
    this.dealsLoading = true;

    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Deals)))
        .subscribe(() => {
          this.dealService
            .findAllDealsByCompany_SELECT()
            .pipe(filter((res) => !!res))
            .subscribe((resp) => {
              this.deals = ConvertToKeyValue(resp, 'name', '_id');
              if (this.rmSelectRequest) {
                this.deals = this.convertToRmSelectObject(this.deals);
              }
              this.dealsLoading = false;
              this.draw();
            });
        })
    );
  }

  private requestFinals() {
    this.finalsLoading = true;
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Finals)))
        .subscribe(() => {
          this.sub$.add(
            this.finalService
              .search({ applyPaginate: false })
              .pipe(map((res) => res.docs))
              .subscribe((res) => {
                this.customers = ConvertToKeyValue(res, 'name', '_id');
                if (this.rmSelectRequest) {
                  this.customers = this.convertToRmSelectObject(this.customers);
                }
                this.finalsLoading = false;
                this.draw();
              })
          );
        })
    );
  }

  private requestAssets() {
    this.assetsLoading = true;
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Assets)))
        .subscribe(() => {
          this.sub$.add(
            this.assetSearchService
              .search({
                applyPaginate: false,
                customer: StorageService.CustomerId,
              })
              .subscribe((res) => {
                this.assets = ConvertToKeyValue(res?.docs, 'name', '_id');
                if (this.rmSelectRequest) {
                  this.assets = this.convertToRmSelectObject(this.assets);
                }
                this.assetsLoading = false;
                this.draw();
              })
          );
        })
    );
  }

  private requestProjects() {
    this.projectsLoading = true;
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Projects)))
        .subscribe(() => {
          this.sub$.add(
            this.projectService
              .search({ applyPaginate: false })
              .pipe(map((res) => res.docs))
              .subscribe((res) => {
                this.items = ConvertToKeyValue(res, 'name', '_id');
                if (this.rmSelectRequest) {
                  this.items = this.convertToRmSelectObject(this.items);
                }
                this.projectsLoading = false;
                this.draw();
              })
          );
        })
    );
  }

  private requestAuthors() {
    this.authorsLoading = true;
    this.sub$.add(
      this.assetService.findUsersByAssetsShared().subscribe((res) => {
        this.authors = ConvertToKeyValue(res, 'name', '_id');
        if (this.rmSelectRequest) {
          this.authors = this.convertToRmSelectObject(this.authors);
        }
        this.authorsLoading = false;
        this.draw();
      })
    );
  }

  private requestUsers() {
    this.usersLoading = true;
    this.userService
      .findAllByCompany(StorageService.CompanyId)
      .subscribe((res) => {
        this.authors = ConvertToKeyValue(res, 'name', '_id');
        if (this.rmSelectRequest) {
          this.authors = this.convertToRmSelectObject(this.authors);
        }
        this.usersLoading = false;
        this.draw();
      });
  }

  private requestStatus() {
    this.statusLoading = true;

    this.sub$.add(
      this.statusService
        .findByUser({ id: StorageService.CompanyId })
        .pipe(map((res) => [...res]))
        .subscribe((res) => {
          this.status = ConvertToKeyValue(res);
          if (this.rmSelectRequest) {
            this.status = this.convertToRmSelectObject(this.status);
          }
          this.statusLoading = false;
          this.draw();
        })
    );
  }

  /******************************* NGSELECT ONCLICK LOAD END ******************************************* */
  private draw() {
    this.changeDetectorRef.markForCheck();
  }

  get customParamsFeatureByType() {
    switch (this.type) {
      case 'deal':
        return this.featureRef.Deal.CustomPropertiesFilter;
      case 'activities':
        return this.featureRef.Activity.CustomPropertiesFilter;
      case 'finals':
        return this.featureRef.Final.CustomPropertiesFilter;
      default:
        return this.featureRef.SystemPermission.DefaultAllow;
    }
  }

  ngAfterViewInit() {
    this.calcFiltersMenuPosition();
    this.setCalendarsLang(this.translate.currentLang);
    if (this.showInputSearch) {
      this.sub$.add(
        fromEvent(this.input.nativeElement, 'input')
          .pipe(
            distinctUntilChanged(),
            debounce(() => timer(450)),
            map((res) => (res.target as HTMLTextAreaElement).value as string)
          )
          .subscribe((res) => {
            this.inputChange.next(res);
          })
      );
    }
  }

  calcFiltersMenuPosition() {
    const filtersMenu = document.getElementById('FiltersMenu');
    const distanceToLeft = Math.ceil(filtersMenu?.getBoundingClientRect().left);
    const filtersMenuWidth = filtersMenu?.offsetWidth;
    const windowWidth = window.innerWidth;

    if (
      distanceToLeft + filtersMenuWidth < windowWidth &&
      distanceToLeft + (filtersMenuWidth + filtersMenuWidth) < windowWidth
    ) {
      this.FiltersMenuToLeft = true;
    } else {
      this.FiltersMenuToLeft = false;
    }
  }

  showFiltersMenuEvent(evt) {
    this.showFiltersMenu = evt;
    setTimeout(() => {
      if (this.startDateInput) {
        this.startDateInput.nativeElement.value = this.startDateInputValue;
      }
      if (this.endDateInput) {
        this.endDateInput.nativeElement.value = this.endDateInputValue;
      }
      this.draw();
    }, 2000);
  }
  showFiltersActivityMenuEvent(evt) {
    this.showFiltersActivityMenu = evt;
  }

  setCalendarsLang(lang: string) {
    if (lang === 'en') {
      this.currentLang = 'en-US';
    } else {
      this.currentLang = 'es-ES';
    }
    localStorage.setItem('currentLang', this.currentLang);
    this._adapter.setLocale(this.currentLang);
  }

  hasItems<T = unknown>(items: T[]) {
    return items.length;
  }

  onChangeStatus(evt: FilterItems) {
    this.selectedStatus = evt;
    this.changeStatus.next(evt);
  }

  onChangeDeals(evt: FilterItems) {
    this.selectedDeals = evt;
    this.changeDeal.next(evt);
  }

  changeCustomNumberOrder(custom: ICustomProperty, value: string) {
    custom.order = value;
    this.changeElement(custom.name, custom.value);
  }

  setSelectedFilters() {
    this.selectedFilters = {
      status:
        this.type === 'budgets'
          ? this.selectedstatusBudgets
          : this.selectedStatus,
      categories:
        this.type === 'expense' ? this.selectedStatus : this.selectedCategories,
      customers: this.selectedFinals,
      managers:
        this.type === 'assets' ? this.selectedAssigneds : this.selectedReporter,
      authors: this.selectedAuthors,
      taskers: this.selectedAssigneds,
      users:
        this.type === 'expense' || this.type === 'assets'
          ? this.selectedAuthors
          : this.selectedAssigneds,
      finals: this.selectedFinals,
      rol: this.selectedRol,
      clients: this.selectedFinals,
      projects: this.selectedProjects,
      deals: this.selectedDeals,
      dealTypes: this.selectedType,
      types: this.selectedFinalType,
      owners: this.selectedAuthors,
      catchmentChannels: this.selectedChanel,
      assets: this.selectedAsset,
      idAssets: this.selectedAsset,
      startDate: this.selectedStartDate,
      endDate: this.selectedEndDate,
      takeToday: this.takeToday,
      takeCancelled: this.takeCancelled,
      takeDeleted: this.takeDeleted,
      takeNotified: this.takeNotified,
      customProperties: this.customPropertiesParams,
    };

    if (Object.values(this.selectedFilters).length == 0) {
      this.activeFilters = false;
    }

    this.saveFilters.next(this.selectedFilters);
  }

  cleanCustomProperties() {
    const propertyInput: any = this.propertyInput.toArray();
    const propertyDateInput: any = this.propertyDateInput.toArray();
    const propertySelect: any = this.propertySelect.toArray();

    if (propertyInput.length) {
      for (let i = 0; i < propertyInput.length; i++) {
        propertyInput[i].nativeElement.value = '';
      }
    }

    if (propertyDateInput.length) {
      for (let i = 0; i < propertyDateInput.length; i++) {
        propertyDateInput[i].nativeElement.value = '';
      }
    }

    if (propertySelect.length) {
      for (let i = 0; i < propertySelect.length; i++) {
        propertySelect[i].handleClearClick();
      }
    }
    this.customPropertiesParams = null;

    if (this.activeCustomFilters) {
      this.activeCustomFilters = false;
      this.changeCustomProperties.emit(this.customPropertiesParams);
    }
  }

  cleanFilters() {
    this.selectedAsset = [];
    this.selectedChanel = [];
    this.selectedProjects = [];
    this.selectedReporter = [];
    this.selectedFinals = [];
    this.selectedAuthors = [];
    this.selectedAssigneds = [];
    this.selectedFinalType = [];
    this.selectedCustomers = [];
    this.selectedDeals = [];
    this.selectedElements = [];
    this.selectedCategories = [];
    this.selectedRol = [];
    this.selectedstatusBudgets = [];
    this.selectedStatus = [];
    this.selectedType = [];
    this.selectedStartDate = '';
    this.selectedEndDate = '';
    this.takeToday = false;
    this.takeCancelled = false;
    this.takeDeleted = false;
    this.takeNotified = false;
    this.activeFilters = false;
    this.activeRangeDate = false;
    this.finalSearchItem = '';
    this.projectSearchItem = '';
    this.reporterSearchItem = '';
    this.assignedSearchItem = '';
    this.cleanCustomProperties();
    this.cleanChecks();
    this.clearInputFilters('final');
    this.clearInputFilters('project');
    this.clearInputFilters('reporter');
    this.clearInputFilters('assigned');
    this.clearInputFilters('status');
    this.clearInputFilters('address');
    this.setSelectedFilters();
    this.draw();
  }

  closeFilter() {
    if (this.activeFilters) {
      this.cleanFilters();
      this.openMobileFilters = false;
    } else {
      this.cleanFilters();
      this.openMobileFilters = false;
    }
  }

  applyFilters() {
    if (
      this.checkIfEmptyFilters() &&
      Object.values(this.selectedFilters).length
    ) {
      this.cleanFilters();
      this.openMobileFilters = false;
    } else {
      this.setSelectedFilters();
      this.openMobileFilters = false;
    }
  }

  clearInputFilters(
    el: 'final' | 'project' | 'reporter' | 'status' | 'address' | 'assigned'
  ) {
    if (el === 'final') {
      this._elements = this._elementsOrg;
      this.finalSearchItem = '';
    }
    if (el === 'project') {
      this._items = this._itemsOrg;
      this.projectSearchItem = '';
    }
    if (el === 'reporter' || el === 'assigned') {
      this._authors = this._authorsOrg;
      this.reporterSearchItem = '';
    }
    if (el === 'status') {
      this._status = this._statusOrg;
      this.statusSearchItem = '';
    }
    if (el === 'address') {
      this._address = this._addressOrg;
      this.addressSearchItem = '';
    }
  }

  searchInputFilters(
    evt: KeyboardEvent,
    el: 'final' | 'project' | 'reporter' | 'assigned' | 'status' | 'address'
  ) {
    if (el === 'final') {
      const value = (evt.target as any).value as string;

      this._elements = this._elementsOrg.filter((el) =>
        el.key.match(new RegExp(value, 'i'))
      );
    }
    if (el === 'project') {
      const value = (evt.target as any).value as string;

      this._items = this._itemsOrg.filter((el) =>
        el.key.match(new RegExp(value, 'i'))
      );
    }
    if (el === 'reporter') {
      const value = (evt.target as any).value as string;

      this._authors = this._authorsOrg.filter((el) =>
        el.key.match(new RegExp(value, 'i'))
      );
    }
    if (el === 'status') {
      const value = (evt.target as any).value as string;

      this._status = this._statusOrg.filter((el) =>
        el.key.match(new RegExp(value, 'i'))
      );
    }
    if (el === 'address') {
      const value = (evt.target as any).value as string;

      this._address = this._addressOrg.filter((el) =>
        el.key.match(new RegExp(value, 'i'))
      );
    }
  }

  // @ts-ignore
  searchCheckFilters(item, type) {
    switch (type) {
      case 'status':
        if (
          this.selectedStatus.filter((element) => element.value == item.value)
            .length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case 'reporter':
        if (
          this.selectedReporter.filter((element) => element.value == item.value)
            .length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case 'assigned':
        if (
          this.selectedAssigneds.filter(
            (element) => element.value == item.value
          ).length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case 'final':
        if (
          this.selectedFinals.filter((element) => element.value == item.value)
            .length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case 'project':
        if (
          this.selectedProjects.filter((element) => element.value == item.value)
            .length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case 'dealType':
        if (
          this.selectedType.filter((element) => element.value == item.value)
            .length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case 'finalType':
        if (
          this.selectedFinalType.filter(
            (element) => element.value == item.value
          ).length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case 'chanels':
        if (
          this.selectedChanel.filter((element) => element.value == item.value)
            .length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case 'authors':
        if (
          this.selectedAuthors.filter((element) => element.value == item.value)
            .length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case 'address':
        if (
          this.selectedAsset.filter((element) => element.value == item.value)
            .length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case 'deals':
        if (
          this.selectedDeals.filter((element) => element.value == item.value)
            .length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case 'categories':
        if (
          this.selectedCategories.filter(
            (element) => element.value == item.value
          ).length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case 'statusBudgets':
        if (
          this.selectedstatusBudgets.filter(
            (element) => element.value == item.value
          ).length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case 'rol':
        if (
          this.selectedRol.filter((element) => element.value == item.value)
            .length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      default:
        break;
    }
  }

  onChangeFilterMobile(filter, evt, item) {
    switch (filter) {
      case 'status':
        if (evt.checked) {
          //add status
          this.selectedStatus.push(item);
        } else {
          //remove status
          this.selectedStatus.splice(
            this.selectedStatus.findIndex(function (i) {
              i.value === item.value;
            }),
            1
          );
          if (this.selectedStatus.length == 0) {
            this.clearInputFilters('status');
          }
        }
        //this.onChangeStatus(this.selectedStatus);
        break;
      case 'reporter':
        if (evt.checked) {
          this.selectedReporter.push(item);
        } else {
          this.selectedReporter.splice(
            this.selectedReporter.findIndex(function (i) {
              i.value === item.value;
            }),
            1
          );
          if (this.selectedReporter.length == 0) {
            this.clearInputFilters('reporter');
          }
        }
        //this.onChangeManager(this.selectedReporter);
        break;
      case 'assigned':
        if (evt.checked) {
          this.selectedAssigneds.push(item);
        } else {
          this.selectedAssigneds.splice(
            this.selectedAssigneds.findIndex(function (i) {
              i.value === item.value;
            }),
            1
          );
          if (this.selectedAssigneds.length == 0) {
            this.clearInputFilters('assigned');
          }
        }
        break;
      case 'final':
        if (evt.checked) {
          this.selectedFinals.push(item);
        } else {
          this.selectedFinals.splice(
            this.selectedFinals.findIndex(function (i) {
              i.value === item.value;
            }),
            1
          );
          if (this.selectedFinals.length == 0) {
            this.clearInputFilters('final');
          }
        }
        //this.onChangeManager(this.selectedReporter);
        break;
      case 'project':
        if (evt.checked) {
          this.selectedProjects.push(item);
        } else {
          this.selectedProjects.splice(
            this.selectedProjects.findIndex(function (i) {
              i.value === item.value;
            }),
            1
          );
          if (this.selectedFinals.length == 0) {
            this.clearInputFilters('project');
          }
        }
        //this.onChangeItems(this.selectedProjects);
        break;
      case 'dealType':
        if (evt.checked) {
          this.selectedType.push(item);
        } else {
          this.selectedType.splice(
            this.selectedType.findIndex(function (i) {
              i.value === item.value;
            }),
            1
          );
        }
        //this.onChangeType(this.selectedType);
        break;
      case 'finalType':
        if (evt.checked) {
          this.selectedFinalType.push(item);
        } else {
          this.selectedFinalType.splice(
            this.selectedFinalType.findIndex(function (i) {
              i.value === item.value;
            }),
            1
          );
        }
        break;
      case 'address':
        if (evt.checked) {
          this.selectedAsset.push(item);
        } else {
          this.selectedAsset.splice(
            this.selectedAsset.findIndex(function (i) {
              i.value === item.value;
            }),
            1
          );
          if (this.selectedAsset.length == 0) {
            this.clearInputFilters('address');
          }
        }
        //this.onChangeAsset(this.selectedAsset);
        break;
      case 'chanels':
        if (evt.checked) {
          this.selectedChanel.push(item);
        } else {
          this.selectedChanel.splice(
            this.selectedChanel.findIndex(function (i) {
              i.value === item.value;
            }),
            1
          );
        }
        break;
      case 'authors':
        if (evt.checked) {
          this.selectedAuthors.push(item);
        } else {
          this.selectedAuthors.splice(
            this.selectedAuthors.findIndex(function (i) {
              i.value === item.value;
            }),
            1
          );
        }
        break;
      case 'deals':
        if (evt.checked) {
          this.selectedDeals.push(item);
        } else {
          this.selectedDeals.splice(
            this.selectedDeals.findIndex(function (i) {
              i.value === item.value;
            }),
            1
          );
        }
        break;
      case 'statusBudgets':
        if (evt.checked) {
          this.selectedstatusBudgets.push(item);
        } else {
          this.selectedstatusBudgets.splice(
            this.selectedstatusBudgets.findIndex(function (i) {
              i.value === item.value;
            }),
            1
          );
        }
        break;
      case 'categories':
        if (evt.checked) {
          this.selectedCategories.push(item);
        } else {
          this.selectedCategories.splice(
            this.selectedCategories.findIndex(function (i) {
              i.value === item.value;
            }),
            1
          );
        }
        break;
      case 'rol':
        if (evt.checked) {
          this.selectedRol.push(item);
        } else {
          this.selectedRol.splice(
            this.selectedRol.findIndex(function (i) {
              i.value === item.value;
            }),
            1
          );
        }
        break;
      default:
        break;
    }

    if (
      this.checkIfEmptyFilters() &&
      Object.values(this.selectedFilters).length == 0
    ) {
      this.activeFilters = false;
    } else {
      this.activeFilters = true;
    }
  }

  checkIfEmptyFilters() {
    if (
      this.selectedStatus.length == 0 &&
      this.selectedReporter.length == 0 &&
      this.selectedProjects.length == 0 &&
      this.selectedType.length == 0 &&
      this.selectedFinals.length == 0 &&
      this.selectedAuthors.length == 0 &&
      this.selectedAssigneds.length == 0 &&
      this.selectedFinalType.length == 0 &&
      this.selectedCustomers.length == 0 &&
      this.selectedDeals.length == 0 &&
      this.selectedElements.length == 0 &&
      this.selectedCategories.length == 0 &&
      this.selectedRol.length == 0 &&
      this.selectedstatusBudgets.length == 0 &&
      this.selectedAsset.length == 0 &&
      this.selectedChanel.length == 0 &&
      (this.selectedStartDate === '' ||
        this.selectedStartDate === undefined ||
        this.selectedStartDate === null ||
        !this.selectedStartDate) &&
      (this.selectedEndDate === '' ||
        this.selectedEndDate === undefined ||
        this.selectedEndDate === null ||
        !this.selectedEndDate) &&
      !this.activeRangeDate &&
      !this.activeCustomFilters &&
      !this.activeCancelled &&
      !this.activeDeleted &&
      !this.takeDeleted &&
      !this.takeToday &&
      !this.takeCancelled &&
      !this.takeNotified &&
      !this.activeNotified &&
      !this.activeProfile &&
      !this.activeToday &&
      !this.activeFinished &&
      !this.activeRestore &&
      !this.restoreStatus
    ) {
      return true;
    } else {
      return false;
    }
  }
  openSubFilters(selectedFilter) {
    let onSelectFilter = selectedFilter;
    if (onSelectFilter == 'project') {
      onSelectFilter = 'projects';
    }
    if (onSelectFilter == 'expenseCategories') {
      onSelectFilter = 'expenseCategory';
    }
    if (onSelectFilter == 'final') {
      onSelectFilter = 'finals';
    }
    if (onSelectFilter == 'reporter') {
      onSelectFilter = 'authors';
    }
    if (onSelectFilter == 'assigned') {
      onSelectFilter = 'authors';
    }
    if (onSelectFilter == 'authors') {
      onSelectFilter = 'authors';
    }
    if (onSelectFilter == 'address') {
      onSelectFilter = 'assets';
    }
    if (onSelectFilter == 'finalType') {
      onSelectFilter = 'finalTypes';
    }
    this.onClickSelect('list', onSelectFilter);
    this.selectedFilter = selectedFilter;
    this.openSubfilter = true;
  }

  onChangeCategory(evt: FilterItems) {
    this.selectedCategories = evt;
    this.changeCategory.next(evt);
  }

  onChangeCustomer(evt: FilterItems) {
    this.selectedCustomers = evt;
    this.changeCustomer.next(evt);
  }

  onChangeAuthor(evt: FilterItems) {
    this.selectedAuthors = evt;
    this.changeAuthor.next(evt);
  }

  onChangeManager(evt: FilterItems) {
    this.selectedReporter = evt;
    this.changeManager.next(evt);
  }

  onChangeAssigned(evt: FilterItems) {
    this.selectedAssigneds = evt;
    this.changeAssigned.next(evt);
  }

  onChangeStatusbudget(evt: FilterItems) {
    this.selectedstatusBudgets = evt;
    this.changeStatusBudget.next(evt);
  }

  onChangeFinal(evt: FilterItems) {
    this.changeFinal.next(evt);
  }

  onChangeType(evt: FilterItems) {
    // const types = this.types.filter((state) => evt.value.includes(state.value));
    this.selectedType = evt;
    this.changeTypes.emit(evt);
  }

  onChangeAsset(evt: FilterItems) {
    /*const assets = this.assets.filter((asset) =>
      evt.value.includes(asset.value)
    );*/
    this.selectedAsset = evt;
    this.changeAssets.emit(evt);
  }

  onChangeRol(evt: FilterItems) {
    /*const assets = this.assets.filter((asset) =>
      evt.value.includes(asset.value)
    );*/
    this.selectedRol = evt;
    this.changeRol.emit(evt);
  }

  onChangeCancelled(evt: MatCheckboxChange) {
    this.changeCancelled.next(evt?.checked);
    if (evt?.checked) {
      this.activeCancelled = true;
    } else {
      this.activeCancelled = false;
    }
  }

  onChangeCancelledMobile(evt: MatCheckboxChange) {
    this.takeCancelled = evt?.checked;
    this.activeFilters = true;
  }

  onChangeNotified(evt: MatCheckboxChange) {
    this.changeNotified.next(evt?.checked);
    if (evt?.checked) {
      this.activeNotified = true;
    } else {
      this.activeNotified = false;
    }
  }

  onChangeDeleted(evt: MatCheckboxChange) {
    this.changeDeleted.next(evt?.checked);
    if (evt?.checked) {
      this.activeDeleted = true;
    } else {
      this.activeDeleted = false;
    }
  }

  onChangeDeletedMobile(evt: MatCheckboxChange) {
    this.takeDeleted = evt?.checked;
    this.activeFilters = true;
  }

  onChangeNotifiedMobile(evt: MatCheckboxChange) {
    this.takeNotified = evt?.checked;
    this.activeFilters = true;
  }

  onChangeCharged(evt: MatCheckboxChange) {
    this.changeCharged.next(evt?.checked);
  }

  onChangeToday(evt: MatCheckboxChange) {
    this.changeToday.next(evt?.checked);
    if (evt?.checked) {
      this.activeToday = true;
    } else {
      this.activeToday = false;
    }
  }

  onChangeFinished(evt: MatCheckboxChange) {
    this.changeFinished.next(evt?.checked);
    if (evt?.checked) {
      this.activeFinished = true;
    } else {
      this.activeFinished = false;
    }
  }

  onChangeTodayMobile(evt: MatCheckboxChange) {
    this.takeToday = evt?.checked;
    this.activeFilters = true;
  }

  onChangeRestore() {
    this.restoreStatus = !this.restoreStatus;
    this.changeDeleted.next(this.restoreStatus);
  }

  onChangePublicProfile(evt: MatCheckboxChange) {
    this.changePublicProfile.next(evt?.checked);
    if (evt?.checked) {
      this.activeProfile = true;
    } else {
      this.activeProfile = false;
    }
  }

  cleanChecks() {
    if (this.activeProfile) {
      this.activeProfile = false;
      this.changePublicProfile.next(false);
    }
    if (this.restoreStatus) {
      this.activeRestore = false;
      this.changeDeleted.next(false);
    }
    if (this.activeToday) {
      this.activeToday = false;
      this.changeToday.next(false);
    }
    if (this.activeDeleted) {
      this.activeDeleted = false;
      this.changeDeleted.next(false);
    }
    if (this.activeNotified) {
      this.activeNotified = false;
      this.changeNotified.next(false);
    }
    if (this.activeCancelled) {
      this.activeCancelled = false;
      this.changeCancelled.next(false);
    }
  }

  onStartDateChange(date: MatDatepickerInputEvent<Date>, formatDate) {
    this.startDateChange.next(date.value);
    this.startDateInputValue = formatDate;
    if (!date.value) {
      this.startDateInputValue = '';
      this.range = new FormGroup({
        start: new FormControl(null),
        end: new FormControl(null),
      });
    }
  }

  onEndDateChange(date: MatDatepickerInputEvent<Date>, formatDate) {
    this.endDateChange.next(date.value);
    this.endChangeDateBudget.next(date.value);
    this.endDateInputValue = formatDate;
    this.activeRangeDate = true;
    if (!date.value) {
      this.endDateInputValue = '';
      this.activeRangeDate = false;
      this.range = new FormGroup({
        start: new FormControl(null),
        end: new FormControl(null),
      });
    }
  }

  cleanRange() {
    this.clearRangeDateInput();
    this.resetRangeFilter.emit();
  }

  onStartDateBudgetChange(date: MatDatepickerInputEvent<Date>) {
    this.startChangeDateBudget.next(date.value);
  }

  onEndDateBudgetChange(date: MatDatepickerInputEvent<Date>) {
    this.endChangeDateBudget.next(date.value);
    this.endDateChange.next(date.value);
  }

  onAddClick(evt: MouseEvent) {
    this.addClick.next(evt);
    this.OnAddClick.next(evt);

    if (this.childComponent) {
      this.childComponent.open().subscribe((res: any) => {});
    }
  }

  onAddStatus(evt: MouseEvent) {
    this.addStatus.next(evt);
  }

  onEditStatus() {
    try {
      const [item] = this.selectedStatus;

      if (item) {
        this.editStatus.next(item);
      }
    } catch (e) {}
  }

  onChangeElements(evt: FilterItems) {
    /*const item = this.elements.filter((element) =>
      evt.value.includes(element.value)
    );*/
    this.selectedElements = evt;
    this.changeElements.next(evt);
  }

  verticalChanges(evt: IVertical[]) {
    const verticals = [];

    evt.forEach((element) => {
      verticals.push(element._id);
    });

    this.changeVertical.emit(verticals);
  }

  changeSingleDatePicker(evt: HTMLInputElement) {
    this.changeSingleDatePickerBus.emit(evt);
  }

  onChangeItems(evt: FilterItems) {
    this.selectedProjects = evt;
    this.changeItems.next(evt);
  }

  onChangeCriteries(evt: FilterItem) {
    this.changeCriteries.next(evt);
  }

  // custom property
  changeElement(name: string, value: any, desktop = true) {
    // If value is a date
    if (value instanceof Date) {
      value = getDateStrResum(value).formattedDate;
    }
    // If value comes from a boolean custom property
    if (value === 'Si' || value === 'Yes') {
      value = 'true';
    } else if (value === 'No') {
      value = 'false';
    }

    const cProps: ICustomProperty[] = this.customProperties.filter(
      (fil) => fil.name == name
    );
    let cProp: ICustomProperty;
    if (cProps.length > 0) cProp = cProps[0];

    if (cProp?.order) {
      if (value != null) {
        this.customPropertiesParams = {
          ...this.customPropertiesParams,
          [name]: { order: cProp.order, value: value },
        };
      }
    } else {
      this.customPropertiesParams = {
        ...this.customPropertiesParams,
        [name]: value,
      };
    }

    if (typeof value !== 'number' && typeof value !== 'object') {
      if (!value?.length) {
        delete this.customPropertiesParams[name];
      }
    }

    for (let i = 0; i < Object.keys(this.customPropertiesParams).length; i++) {
      if (
        // existe valor, el valor es un array y dicho array.length == 0
        this.customPropertiesParams[
          Object.keys(this.customPropertiesParams)[i]
        ] &&
        Array.isArray(
          this.customPropertiesParams[
            Object.keys(this.customPropertiesParams)[i]
          ]
        ) &&
        this.customPropertiesParams[Object.keys(this.customPropertiesParams)[i]]
          .length == 0
      ) {
        //borramos el parametro de la lista
        delete this.customPropertiesParams[
          Object.keys(this.customPropertiesParams)[i]
        ];
      }
    }

    if (JSON.stringify(this.customPropertiesParams) === '{}') {
      this.activeCustomFilters = false;
    } else {
      this.activeCustomFilters = true;
    }

    if (desktop) {
      this.changeCustomProperties.emit(this.customPropertiesParams);
    }
  }

  changeInputNumber(prop: ICustomProperty, value: string) {
    prop.value = Number(value);
    this.changeElement(prop.name, value != '' ? prop.value : '');
  }

  clearRangeDateInput() {
    this.startDateChange.next(null);

    this.startDateInput.nativeElement.value = '';
    this.endDateInput.nativeElement.value = '';
    this.startDateInputValue = '';
    this.endDateInputValue = '';
    this.activeRangeDate = false;
    this.range.reset();
    this.range = new FormGroup({
      start: new FormControl(null),
      end: new FormControl(null),
    });
    this.range.value.start = null;
    this.range.value.end = null;
    this.endDateChange.next(null);
  }

  convertToRmSelectObject(array) {
    if (array.length) {
      return array
        .map((res) => {
          return {
            title: res.key
              ? res.key?.toString().toLocaleLowerCase()
              : res.title?.toString().toLocaleLowerCase(),
            value: res.value,
            id: res.value,
          };
        })
        .sort((a, b) => (a.title > b.title ? 1 : -1));
    } else {
      return array;
    }
  }
}
