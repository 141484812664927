<roma-modal-template
  [showHeader]="false"
  [size]="'sm'"
  [contentMinHeight]="false"
  [textSuccess]="'general.yesConfirm'"
  [headerBottomMargin]="'16px'"
  [actionsAlign]="'center'"
  [responsiveFull]="false"
  (cancel)="close()"
  (success)="submit()"
>
  <div class="modal__wrapper">
    <img class="__icon" src="/assets/icons/gl_loop-icon.svg" />
    <h2>{{ 'sales.salesCard.selectedDealConfirm' | translate }}</h2>
  </div>
</roma-modal-template>
