<div *ngIf="loadingPaymentActiveButton" class="col-12">
  <roma-loading></roma-loading>
</div>

<div class="container">
  <div class="principal row justify-content-center">
    <div class="wrapper-content-text col-12 col-xl-6">
      <div class="title">{{ 'payments.landing-title' | translate }}</div>
      <div class="content-text">
        {{ 'payments.landing-body' | translate }}
      </div>

      <ul class="text-list">
        <li>{{ 'payments.landing-list-1' | translate }}</li>
        <li>
          {{ 'payments.landing-list-2' | translate }}
        </li>
        <li>{{ 'payments.landing-list-3' | translate }}</li>
      </ul>

      <div class="text-taxes">{{ 'payments.landing-taxes' | translate }}</div>
      <div class="row-buttons">
        <div class="button-activate">
          <button
            class="roma-button btn-activate"
            (click)="isAdmin ? activatePayment() : null"
          >
            <span class="mx-2">{{
              'payments.landing-button' | translate
            }}</span>
          </button>
        </div>

        <a href="https://academy.taclia.com/es/collections/304857-pagos-online" class="text-link" target="_blank">
          <span class="more-info">{{
            'payments.landing-more-info' | translate
          }}</span>
          <img src="/assets/icons/chevron-right-2.svg" alt="more-info" />
        </a>
      </div>
    </div>
    <div class="wrapper-content-image col-12 col-xl-6">
      <img
        [hidden]="loadingImage"
        [src]="'assets/icons/' + ('wrp_img-logos-alt-' + ('payments.language' | translate)) + '.svg'"
        fetchpriority="high"
        loading="eager"
        (load)="isLoading(false)"
      />
      <roma-loading *ngIf="loadingImage"></roma-loading>
    </div>
  </div>
</div>
