import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CertificateStatus } from 'src/types-legacy/lib/enum/certificate-status.enum';


@Component({
  selector: 'digital-certificate-status-dialog',
  templateUrl: './digital-certificate-status-dialog.component.html',
  styleUrls: ['./digital-certificate-status-dialog.component.scss'],
  providers: [
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {status:CertificateStatus.Accepted} }
  ]
})
export class DigitalCertificateStatusDialogComponent implements OnInit {

  constructor(private changeDetectionRef: ChangeDetectorRef, 
    private dialogRef: MatDialogRef<DigitalCertificateStatusDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) private data: any,
    @Inject(MAT_BOTTOM_SHEET_DATA) private dataSheet: any,
    private _bottomSheetRef?: MatBottomSheetRef<DigitalCertificateStatusDialogComponent>) { }

  _status: CertificateStatus;
  @Input()
  set status(status: CertificateStatus) {
    this._status = status;
  };

  get status(): CertificateStatus {
    return this._status;
  }

  ngOnInit(): void {
    this.status = this.data?.status 
    if (!this.status) this.status = this.dataSheet?.status;
  }

  get isMobile(){
    return window.screen.width < 768;
  }

  close(){
    if (!this.isMobile){
      this.dialogRef.close();
    }else {
      this._bottomSheetRef.dismiss();
    }
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  onRetrySendCertificate(){
    if (!this.isMobile){
      this.dialogRef.close('retrySendCertificate');
    }else {
      this._bottomSheetRef.dismiss('retrySendCertificate');
    }
  }

  onToBill(){
    if (!this.isMobile){
      this.dialogRef.close('toBill');
    }else {
      this._bottomSheetRef.dismiss('toBill');
    }
  }
}
