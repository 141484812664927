import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import { Subject, Subscription } from 'rxjs';
import { filter, finalize, map, take } from 'rxjs/operators';

import {
  Activity,
  Company,
  ConvertToKeyValue,
  Deal,
  DealsWrapperCustomPropertySort,
  DealsWrapperPropertySort,
  DynamicPropertyRef,
  Feature,
  FeatureUser,
  IActivity,
  ICreateActivityDto,
  ICustomProperty,
  IFinal,
  IStatus,
  IUser,
  KanbanCard,
  PaginateResponse,
  PaginateSort,
  TypeRol,
} from '@tacliatech/types';

import { TranslateService } from '@ngx-translate/core';

import {
  FilterItem,
  FilterItems,
} from '@web-frontend/shared/components/filter';

import { CreateActivityService } from '@web-frontend/shared/components/create-activity';
import { TableSortEvent } from '@web-frontend/shared/components/table';

import {
  ActivitySearchService,
  ActivityService,
  AssetSearchService,
  AssetService,
  DealSearchService,
  DealService,
  DynamicPropertyService,
  FinalService,
  ProjectService,
  StatusService,
  StorageService,
} from '@web-frontend/shared/services';

import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { DealStatusColors } from '@web-frontend/shared/components/deals/deal-status-manager/deal-status-manager-.const';
import { DeleteBySelectionModalComponent } from '@web-frontend/shared/components/delete-by-selection-modal/delete-by-selection-modal.component';
import { FilterComponent } from '@web-frontend/shared/components/filter/filter.component';
import { RmFilter } from '@web-frontend/shared/components/globals/rm-filter';
import { RmFilterClearComponent } from '@web-frontend/shared/components/globals/rm-filter/rm-filter-clear/rm-filter-clear.component';
import { ModalMobileInfoComponent } from '@web-frontend/shared/components/modal-mobile-info';
import { VideoService } from '@web-frontend/shared/components/modal-video/video.service';
import { TableComponent } from '@web-frontend/shared/components/table/table.component';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import { FileWriteService } from '@web-frontend/shared/helpers/file-write';
import { AuthService } from '@web-frontend/shared/services/auth/auth.service';
import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { CompanyModuleService } from '@web-frontend/shared/services/company';
import { TutorialService } from '@web-frontend/shared/services/tutorial';
import { SandboxService } from '@web-frontend/shared/services/sandbox/sandbox.service';
import { ScrollService } from '@web-frontend/shared/services/scroll';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { ToastData } from '@web-frontend/shared/services/toast/utils/toast-config';
import {
  CompareObjects,
  getCustomValue,
  getDateStrResumStr,
  RemoveEmpty,
} from '@web-frontend/shared/utils';
import { Workbook } from 'exceljs';
import {
  SOURCE_OPTIONS_TASKS,
  SOURCE_WEB,
  SOURCE_WEB_OPTIONS,
} from './activities.const';
import { INIT_ACTIVITY_SEARCH_PARAMS } from './activities.types';
import { ListMobileActivitiesComponent } from './roma-list-mobile-activities/list-mobile-activities.component';
import { DownloadType } from '@web-frontend/shared/components/download-btn/download-btn.component';
import { DownloadBtnService } from '@web-frontend/shared/components/download-btn/download-btn.service';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import AmplitudeEvents from 'src/types/amplitude.enum';

@Component({
  selector: 'roma-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivitiesComponent implements OnInit, AfterViewInit {
  tableMarginTop = '0px';
  distanceToTop = 0;

  @Input()
  customer: IFinal;

  @Input()
  showCalendar = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.tableMarginTop = '0px';
    this.calcTableDistance();
  }

  @HostBinding('class.roma-page')
  hostClass = true;
  @ViewChild(FilterComponent)
  filterComponent!: FilterComponent;

  @ViewChild(TableComponent)
  tableComponent!: TableComponent;

  @ViewChild(ListMobileActivitiesComponent)
  listMobileActivitiesComponent!: ListMobileActivitiesComponent;

  @ViewChild(RmFilterClearComponent)
  rmFilterClearComponent!: RmFilterClearComponent;

  showMore = false;

  @Input()
  showTitle = true;

  @Input()
  showFilter = true;

  @Input()
  showAddBtn = false;

  @Input()
  hiddenNoData = false;

  @Input()
  dealId!: string;

  deal!: Deal.Output;

  columns: string[] = [];
  academyLink?: string = undefined;
  activities?: MatTableDataSource<Activity.Output>;
  resultSearch?: PaginateResponse<Activity.Output[]>;
  activityMap = new Map<string, Activity.Output>();
  featureRef = Feature;
  featureRefUser = FeatureUser;
  isDataLoaded = false;
  isLoading = false;
  isLoadingPaginate = false;
  isUpdatingMultiple = false;
  isMovingMultiple = false;

  authors: FilterItems = [];
  assets: FilterItems = [];
  deals: FilterItems = [];
  totalPages: any[] = [];

  customProperties: ICustomProperty[] = [];
  viewTypeActive: 'ROW' | 'PIPELINE' | 'CALENDAR' = 'ROW';
  allowShowButtonDownTakeMore = false;

  user!: IUser;

  private sub$ = new Subscription();
  private storeSearchParams = INIT_ACTIVITY_SEARCH_PARAMS;
  firstSearchParams;
  private busSearchParamsChanges = new Subject();
  private selectedColor: { darkColor: string; lightColor: string } =
    DealStatusColors[0];
  showToolTip = false;
  iconDialog = 'assets/icons/gl_title-information-outline.svg';

  searchParamsHasChange$ = this.busSearchParamsChanges
    .asObservable()
    // @ts-ignore
    .pipe(map((res) => !CompareObjects(INIT_ACTIVITY_SEARCH_PARAMS, res)));

  company_date_format = this.budgetService.date_format
    ? this.budgetService.date_format
    : 'dd/MM/yyyy';

  sourceSearch = SOURCE_WEB;
  sourceOptions!: RmFilter.Filter;
  openMobileViewType = false;
  roles = TypeRol;

  sourceOptionsForActivities = null;
  moreTableFields = [
    {
      key: 'dealObj',
      title: this.i18n.instant('table.colum.deal'),
      module: Company.IdModule.Deals,
    },
    {
      key: 'expense_project',
      title: this.i18n.instant('table.colum.project'),
      module: Company.IdModule.Projects,
    },
    {
      key: 'assetObj',
      title: this.i18n.instant('table.colum.asset'),
      module: Company.IdModule.Assets,
    },
    { key: 'hour', title: this.i18n.instant('table.colum.hour') },
  ];
  showMoveCardMobileMenu = false;
  videoIframeURL: SafeResourceUrl = '';

  scrollDown$ = this.scrollService.scrollDown$;
  showVideoIframe = false;

  constructor(
    private activityService: ActivityService,
    private activitySearchService: ActivitySearchService,
    private createActivityService: CreateActivityService,
    private changeDetectionRef: ChangeDetectorRef,
    private i18n: TranslateService,
    private alertService: AlertService,
    private authService: AuthService,
    private assetSearchService: AssetSearchService,
    private assetService: AssetService,
    private activatedRouter: ActivatedRoute,
    private dynamicPropertyService: DynamicPropertyService,
    private dealSearchService: DealSearchService,
    private dealService: DealService,
    private projectService: ProjectService,
    private video: VideoService,
    private fileWrite: FileWriteService,
    private datePipe: DatePipe,
    public sandBoxService: SandboxService,
    private amplitudeService: AmplitudeService,
    private domSanitzer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private budgetService: BudgetService,
    private companyModuleService: CompanyModuleService,
    private statusService: StatusService,
    public dialog: MatDialog,
    private toastService: ToastService,
    public tutorialService: TutorialService,
    private finalService: FinalService,
    private scrollService: ScrollService,
    private readonly brazeService: BrazeService,
    private readonly downloadBtnService: DownloadBtnService,
    private analyticsService: AnalyticsService
  ) {
    this.showTitle = true;
    this.showFilter = true;

    if (this.dealId) {
      this.searchParams = {
        ...this.searchParams,
        deal: this.dealId as any,
      };
    }

    this.matIconRegistry.addSvgIcon(
      'activity_calendar_icon',
      this.domSanitzer.bypassSecurityTrustResourceUrl(
        'assets/icons/calendar.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'table_icon',
      this.domSanitzer.bypassSecurityTrustResourceUrl(
        'assets/icons/table-horizontal.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'pipeline_icon',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/trello.svg')
    );
  }

  get searchParamsHasChange() {
    return !CompareObjects(INIT_ACTIVITY_SEARCH_PARAMS, this.searchParams);
  }

  getLang() {
    return this.i18n.currentLang;
  }

  get searchParams() {
    return this.storeSearchParams;
  }

  set searchParams(value) {
    this.busSearchParamsChanges.next(value);
    this.storeSearchParams = value;
  }

  async ngOnInit() {
    if (this.customer?._id) {
      this.searchParams = {
        ...this.searchParams,
        'finals[]': [this.customer?._id],
      };
    }
    if (localStorage.getItem('activities.lastViewType')) {
      let value = localStorage.getItem('activities.lastViewType') as
        | 'ROW'
        | 'CALENDAR'
        | 'PIPELINE';

      if (!this.showCalendar) {
        value = 'ROW';
      }

      this.changeViewType(value);
    }
    this.getModuleResources();
    this.resolveColumnsView();
    this.watchRouteParams();
    await this.resolveInputParams();
    this.requestActivities({ loadingGlobal: true });
    this.watchRefreshList();
    this.authService.user$.subscribe((res) => (this.user = res));
    this.requestDynamicProperties();
    this.requestStatus();

    const id = this.activatedRouter.snapshot.paramMap.get('id');

    if (id) {
      this.requestOpenActivity(id);
    }
  }
  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  toggleTooltip(evt) {
    this.showToolTip = evt;
    if (!this.showToolTip) {
      this.iconDialog = 'assets/icons/gl_title-information-outline.svg';
    } else {
      this.iconDialog = 'assets/icons/gl_information-clicked.svg';
    }
  }

  showDialog(): void {
    const isMobile = window.screen.width < 768;
    if (!isMobile) {
      this.showToolTip = !this.showToolTip;
      this.toggleTooltip(this.showToolTip);
    } else {
      const dialog = this.dialog.open(ModalMobileInfoComponent, {
        data: {
          html: 'activity.tooltip',
          buttonText: 'kanban.dialog.confirmButton',
        },
      });
    }
  }

  ngAfterViewInit() {
    this.resolveInputFilterParams();
    this.loadDeal();
  }

  private resolveColumnsView() {
    this.sub$.add(
      this.companyModuleService.idActiveModules$.subscribe((res) => {
        if (res?.length) {
          this.columns = Company.FilterColumnReports({
            columnsReports: [
              'select',
              'title',
              'ownerObj',
              'expiration',
              'status',
              'supervisor',
              'description',
              // 'dealObj',
              'finalObj',
              // 'assetObj',
            ],
            nameColumnsReport: ['dealObj', 'finalObj', 'assetObj'],
            idModules: [
              Company.IdModule.Deals,
              Company.IdModule.Finals,
              Company.IdModule.Assets,
            ],
            idActivesModules: res,
          });

          this.resolveMoreFieldsColumns(res);

          this.draw();
        }
      })
    );
  }

  private resolveMoreFieldsColumns(modules: Company.IdModule[]) {
    let moreFields: any[] = [];
    if (modules.includes(Company.IdModule.Deals)) {
      moreFields = [
        ...moreFields,
        { key: 'dealObj', title: this.i18n.instant('table.colum.deal') },
      ];
    }
    if (modules.includes(Company.IdModule.Projects)) {
      moreFields = [
        ...moreFields,
        {
          key: 'expense_project',
          title: this.i18n.instant('table.colum.project'),
        },
      ];
    }
    if (modules.includes(Company.IdModule.Assets)) {
      moreFields = [
        ...moreFields,
        { key: 'assetObj', title: this.i18n.instant('table.colum.asset') },
      ];
    }

    moreFields = [
      ...moreFields,
      { key: 'hour', title: this.i18n.instant('table.colum.hour') },
    ];
    this.moreTableFields = [...moreFields];
    this.draw();
  }

  getActivity(id: string) {
    this.isLoading = true;
    this.activityService.findOne(id).subscribe((data) => {
      const activity = data;
      this.isLoading = false;
      if (activity) {
        this.editActivity(activity);
      }
      this.draw();
    });
  }

  loadDeal() {
    if (this.dealId) {
      this.dealSearchService
        .search({ 'ids[]': [this.dealId] })
        .subscribe((res) => {
          if (res.docs.length) {
            const [deal] = res.docs;
            this.deal = deal;
            this.draw();
          }
        });
    }
  }

  hasQuerySearch() {
    return (
      this.searchParams.author ||
      this.searchParams['deals[]'] ||
      this.searchParams.owner ||
      this.searchParams.keyword
    );
  }

  changeViewType(evt: 'ROW' | 'CALENDAR' | 'PIPELINE') {
    this.openMobileViewType = false;

    const eventMap = new Map<string, string>([
      ['CALENDAR', AmplitudeEvents.task_calendar],
      ['ROW', AmplitudeEvents.task_list],
      ['PIPELINE', AmplitudeEvents.task_board],
    ]);

    const event = eventMap.get(evt);
    if (event) {
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: event,
      });
    }

    localStorage.setItem('activities.lastViewType', evt);

    this.viewTypeActive = evt;

    if (evt === 'ROW') {
      this.requestActivities({ loadingGlobal: true });
    }

    this.draw();
  }

  changeCustomProperties(customProperties: { [key: string]: any }) {
    this.searchParams = {
      ...this.searchParams,
      customProperties: JSON.stringify(customProperties),
    };
    this.clear();
    if (this.sub$) {
      this.sub$.unsubscribe();

      this.sub$ = new Subscription();
    }

    this.requestActivities({ loadingGlobal: true });
  }

  private resolveInputFilterParams() {
    if (this.searchParams?.keyword) {
      try {
        this.filterComponent.input.nativeElement.value = this.searchParams?.keyword;
        this.draw();
      } catch (err) {}
    }
  }

  private watchRouteParams() {
    this.sub$.add(
      this.activatedRouter.queryParams
        .pipe(filter((res) => Object.keys(res)?.length > 0))
        .subscribe(() => {
          this.resolveRouteParams();
          this.clear();
          this.resolveInputFilterParams();
          this.requestActivities({ loadingGlobal: true });
        })
    );
  }

  private resolveRouteParams() {
    const paramsMap = this.activatedRouter.snapshot.queryParamMap;

    const id = paramsMap.get('id');
    const open = paramsMap.get('open');

    if (id && open) {
      this.getActivity(id);
      return;
    }

    if (id) {
      this.searchParams = {
        ...this.searchParams,
        // @ts-ignore
        'ids[]': [id],
      };

      this.draw();
    }
  }

  private watchRefreshList() {
    this.sub$.add(
      this.createActivityService.refreshList$
        .pipe(
          map((res) => !!res),
          filter((res) => res)
        )
        .subscribe(() => {
          this.clear();

          this.searchParams = {
            ...this.searchParams,
            page: 1,
          };

          this.requestActivities({ loadingGlobal: true });
        })
    );
  }

  changeFilters(evt: RmFilter.Changes) {
    if (evt.type === 'CHANGE' || evt.type === 'INPUT_CHANGE') {
      const { queryParams } = evt;

      if (
        JSON.stringify(queryParams) === JSON.stringify({}) &&
        evt.type === 'INPUT_CHANGE'
      ) {
        // @ts-ignore
        delete this.searchParams.keyword;
      }

      if (
        JSON.stringify(queryParams) === JSON.stringify({}) &&
        evt.type === 'CHANGE'
      ) {
        this.searchParams = { ...INIT_ACTIVITY_SEARCH_PARAMS };
      }

      this.searchParams = {
        ...INIT_ACTIVITY_SEARCH_PARAMS,
        ...this.searchParams,
        ...queryParams,
      };

      if (queryParams?.customProperties) {
        this.searchParams = {
          ...this.searchParams,
          customProperties: JSON.stringify(queryParams.customProperties),
        };
      }

      this.requestActivities({ loadingGlobal: true });
    }
  }

  private resetSelections() {
    this.entriesForDelete = [];
    this.deleteMessage = false;
    this.draw();
  }

  private requestDynamicProperties() {
    this.sub$.add(
      this.dynamicPropertyService
        .findDynamicProperties(
          StorageService.CompanyId,
          DynamicPropertyRef.Activities
        )
        .subscribe((res) => {
          this.customProperties = res;

          this.sandBoxService.screenBusChannel$.subscribe((r) => {
            this.sourceOptions = SOURCE_WEB_OPTIONS({
              assetService: this.assetService,
              assetSearchService: this.assetSearchService,
              customProperties: res,
              dealService: this.dealService,
              projectService: this.projectService,
              i18n: this.i18n,
              companyModuleService: this.companyModuleService,
              isMobile: r.payload.mobile,
              finalService: this.finalService,
            });

            // Filters to "View tasks" button
            // @ts-ignore
            this.sourceOptionsForActivities = new RmFilter.Filter(
              SOURCE_OPTIONS_TASKS
            );

            this.draw();
          });
        })
    );
  }

  requestActivities({ loadingGlobal = false, loadingPagination = false }) {
    this.searchParams.applyPaginate = this.viewTypeActive !== 'PIPELINE';

    if (loadingGlobal) {
      this.isLoading = true;
      this.draw();
    }

    if (loadingPagination) {
      this.isLoadingPaginate = true;
      this.draw();
    }

    const query = RemoveEmpty(this.searchParams);

    this.sub$.add(
      this.activitySearchService
        .search({
          ...query,
          optimized: true,
        })
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.isLoadingPaginate = false;
            this.calcTableDistance();
            this.resetTableMarginTop();
            this.draw();
          })
        )
        .subscribe((res) => {
          this.setActivities(res);
        })
    );
  }

  nextPage() {
    this.searchParams = {
      ...this.searchParams,
      page: this.searchParams.page + 1,
    };

    this.requestActivities({ loadingGlobal: true, loadingPagination: true });
  }

  goToPage(page) {
    this.searchParams = {
      ...this.searchParams,
      page: page,
    };

    this.requestActivities({ loadingGlobal: true, loadingPagination: true });
  }

  previousPage() {
    this.searchParams = {
      ...this.searchParams,
      page: this.searchParams.page - 1,
    };

    this.requestActivities({ loadingGlobal: true, loadingPagination: true });
  }

  changeOwner(evt: FilterItem) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      owner: evt.value,
    };

    this.clear();
    this.requestActivities({ loadingGlobal: true });
  }

  changeAuthor(evt: FilterItems) {
    const authors = evt.map((author) => author.value);

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      // @ts-ignore
      'authors[]': authors,
    };
    this.clear();
    this.requestActivities({ loadingGlobal: true });
  }

  changeDeal(evt: FilterItems) {
    const deals = evt.map((deal) => deal.value);

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      // @ts-ignore
      'deals[]': deals,
    };
    this.clear();
    this.requestActivities({ loadingGlobal: true });
  }

  changeAssets(evt: FilterItems) {
    const assets = evt.map((type) => type.value);

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      // @ts-ignore
      'assets[]': assets,
    };

    this.clear();
    this.requestActivities({ loadingGlobal: true });
  }

  changeInput(keyword: string) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      // @ts-ignore
      keyword: keyword ? keyword : null,
    };

    this.clear();
    this.requestActivities({ loadingGlobal: true });
  }

  changeNotified(evt: boolean) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      takeNotified: evt,
    };

    this.clear();
    this.requestActivities({ loadingGlobal: true });
  }

  changeDeleted(evt: boolean) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      takeDeleted: evt,
    };

    this.clear();
    this.requestActivities({ loadingGlobal: true });
  }

  changeToday(evt: boolean) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      takeToday: evt,
    };

    this.clear();
    this.requestActivities({ loadingGlobal: true });
  }

  changeDate(type: 'START' | 'END', value: Date) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      [type === 'START' ? 'from' : 'to']: value,
    };

    this.clear();
    this.requestActivities({ loadingGlobal: true });
  }

  addActivity() {
    this.analyticsService.trackEvent({
      sources: ['amplitude', 'userflow', 'braze'],
      eventName: AmplitudeEvents.task_start,
    });
    this.createActivityService
      .open({
        position: { right: '0px' },
        data: {
          deal: this.deal,
          customer: this.customer,
        },
      })
      .pipe(map((res) => !res))
      .subscribe();
  }

  sortChange(evt: TableSortEvent) {
    evt.active = evt.active === 'expiration' ? 'dateTime' : evt.active;
    let resCustomProperty = DealsWrapperCustomPropertySort(
      this.customProperties,
      evt.active
    );

    if (!resCustomProperty) {
      resCustomProperty = DealsWrapperPropertySort(evt.active);
    }
    this.clearSortOptions();

    this.searchParams = {
      ...this.searchParams,
      page: 1,
    };

    if (evt.direction) {
      this.searchParams = {
        ...this.searchParams,
        [`sortOptions[${resCustomProperty}]`]: evt.direction,
      };
    } else {
      this.searchParams = {
        ...this.searchParams,
        'sortOptions[created_at]': PaginateSort.DESC,
      };
    }

    this.clear();

    this.requestActivities({ loadingGlobal: true, loadingPagination: false });
  }

  editActivity(value: IActivity) {
    this.entriesForDelete = [];
    this.deleteMessage = false;
    this.createActivityService
      .open({
        position: { right: '0px' },
        data: { activity: value },
      })
      .subscribe(() =>
        this.requestActivities({
          loadingGlobal: true,
          loadingPagination: false,
        })
      );
  }

  requestOpenActivity(id: string) {
    this.isLoading = true;
    this.sub$.add(
      this.activitySearchService
        .search({
          'ids[]': [id],
        })
        .subscribe((res) => {
          const [activity] = res.docs;
          this.isLoading = false;
          this.draw();

          if (activity) {
            this.editActivity(activity);
          }
        })
    );
  }

  requestFinishedActivity(id: string) {
    this.isLoading = true;
    this.sub$.add(
      this.activitySearchService
        .search({
          'ids[]': [id],
          takeNotified: true,
        })
        .subscribe((res) => {
          const [activity] = res.docs;
          this.isLoading = false;
          this.draw();

          if (activity) {
            this.editActivity(activity);
          }
        })
    );
  }

  requestDeletedActivity(id: string) {
    this.isLoading = true;
    this.sub$.add(
      this.activitySearchService
        .search({
          'ids[]': [id],
          takeDeleted: true,
        })
        .subscribe((res) => {
          const [activity] = res.docs;
          this.isLoading = false;
          this.draw();

          if (activity) {
            this.editActivity(activity);
          }
        })
    );
  }

  markActivity(value: IActivity) {
    this.amplitudeService.sendEvent({
      event: 'task_list_check',
    });
    this.sub$.add(
      this.activityService.markDoneOne(value._id).subscribe(() => {
        this.requestActivities({ loadingGlobal: true });
        this.activityMap.delete(value._id);
        this.source = Array.from(this.activityMap.values());
        this.alertService.success(this.i18n.instant('activity.done'));
      })
    );
  }

  removeActivity(value: Activity.Output) {
    this.isLoading = true;
    this.sub$.add(
      this.activityService.removeOne(value._id).subscribe(() => {
        // this.activityMap.delete(value._id);
        this.requestActivities({ loadingGlobal: true });
        this.source = Array.from(this.activityMap.values());
        this.toastService.show({
          text: 'activity.deleteTaskMessage',
          type: 'error',
          msDuration: 4000,
          icon: 'assets/icons/kanban/delete.svg',
        });
        const eventData = {
          event: 'task_list_delete',
        };
        this.amplitudeService.sendEvent(eventData);
        this.isLoading = false;
      })
    );
  }

  restoreActivity(value: Activity.Output) {
    this.sub$.add(
      this.activityService.removeOne(value._id).subscribe(() => {
        this.activityMap.delete(value._id);
        this.source = Array.from(this.activityMap.values());
        this.alertService.success(this.i18n.instant('activity.restore'));

        this.toastService.show({
          msDuration: 2000,
          type: 'success',
          text: this.i18n.instant('activity.taskRestored'),
        });
        const eventData = {
          event: 'task_list_delete',
        };
        this.amplitudeService.sendEvent(eventData);
      })
    );
  }

  restoreActivities() {
    const ids = this.entriesForDelete.map((el) => el?._id);

    this.sub$.add(
      this.activityService.updateMany(ids, { deleted: false }).subscribe(() => {
        this.toastService.show({
          text: this.i18n
            .instant('kanban.toast.restoreMany')
            .replace('{qtty}', ids.length)
            .replace('{arg1}', this.i18n.instant('kanban.cardsNameTask')),
          type: 'success',
          msDuration: 4000,
        });

        this.entriesForDelete = [];
        this.deleteMessage = false;
        this.draw();

        this.requestActivities({ loadingGlobal: true });
      })
    );
  }

  takeMore() {
    this.searchParams = {
      ...this.searchParams,
      page: this.searchParams.page + 1,
    };

    this.requestActivities({ loadingPagination: true });
  }

  programmaticClearFilters() {
    this.rmFilterClearComponent.clean();
  }

  private setActivities(response: PaginateResponse<Activity.Output[]>) {
    this.clear();

    // If there are no activities trigger the query
    // to request videoTutorial URL
    if (!response.docs.length) {
      this.showVideoIframe = true;
    }

    this.resultSearch = response;
    let i;
    this.totalPages = [];
    for (i = 1; i <= this.resultSearch?.countPages; i++) {
      this.totalPages.push({
        page: i,
      });
    }

    for (const deal of response.docs) {
      this.activityMap.set(deal?._id, deal);
    }

    if (response.hasNextPage) {
      this.allowShowButtonDownTakeMore = true;
    }

    this.source = Array.from(this.activityMap.values());
    this.setCards(response);
    this.entriesForDelete = [];
    this.deleteMessage = false;
  }

  private set source(activities: Activity.Output[]) {
    this.activities = new MatTableDataSource(activities);
    this.draw();
  }

  private requestAuthors() {
    this.sub$.add(
      this.assetService.findUsersByAssetsShared().subscribe((res) => {
        this.authors = ConvertToKeyValue(res, 'name', '_id');
        this.draw();
      })
    );
  }

  private requestDeals() {
    this.companyModuleService.idActiveModules$
      .pipe(filter((res) => res.includes(Company.IdModule.Deals)))
      .subscribe(() => {
        this.dealService.deals$
          .pipe(filter((res) => !!res))
          .subscribe((resp) => {
            this.deals = ConvertToKeyValue(resp, 'name', '_id');
            this.draw();
          });
      });
  }

  private requestAssets() {
    this.sub$.add(
      this.assetSearchService
        .search({
          applyPaginate: false,
          customer: StorageService.CustomerId,
        })
        .subscribe((res) => {
          this.assets = ConvertToKeyValue(res?.docs, 'name', '_id');
          this.draw();
        })
    );
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  private clearSortOptions() {
    const searchParams = {
      ...this.searchParams,
    };

    for (const prop in searchParams) {
      if (searchParams[prop]) {
        if (prop.match(/sortOptions/)) {
          delete searchParams[prop];
        }
      }
    }

    this.searchParams = searchParams;
  }

  private clear() {
    this.activityMap.clear();

    this.source = [];
  }

  private resolveInputParams() {
    return new Promise((res) => {
      this.searchParams = {
        ...this.searchParams,
        deal: this.dealId,
      };

      res(null);
    });
  }

  openVideo(type: string) {
    this.amplitudeService.sendEvent({
      event: 'task_TutorialVideo',
    });
    this.video.open(type);
  }

  async downloadExcel() {
    this.amplitudeService.sendEvent({
      event: 'task_excelDownload',
    });
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Activities');

    let header = [
      this.i18n.instant('table.colum.title'),
      this.i18n.instant('table.colum.in_charge'),
      this.i18n.instant('table.colum.expiration'),
      this.i18n.instant('table.colum.status'),
      this.i18n.instant('table.colum.supervisor'),
      this.i18n.instant('table.colum.description'),
      this.i18n.instant('table.colum.deal'),
      this.i18n.instant('table.colum.project'),
      this.i18n.instant('table.colum.asset'),
      //this.i18n.instant('table.colum.hour'),
    ];

    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(take(1))
        .subscribe((res) => {
          if (res?.length) {
            header = Company.FilterColumnReports({
              columnsReports: header,
              nameColumnsReport: [
                this.i18n.instant('table.colum.deal'),
                this.i18n.instant('table.colum.project'),
                this.i18n.instant('table.colum.asset'),
              ],
              idModules: [
                Company.IdModule.Deals,
                Company.IdModule.Projects,
                Company.IdModule.Assets,
              ],
              idActivesModules: res,
            });
            const customShow = JSON.parse(
              StorageService.CustomProperty('ACTIVITY_MODULE')
            ) as ICustomProperty[];

            if (customShow?.length > 0) {
              for (const param of customShow) {
                header.push(param.name);
              }
            }

            const headerRow = worksheet.addRow(header);
            headerRow.font = {
              color: { argb: '003c48ec' },
              bold: true,
              size: 12,
            };
            headerRow.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            };
            const query: any = RemoveEmpty(this.searchParams);
            const notAvailable = this.i18n.instant(
              'generateDocument.notAvailable'
            );
            this.sub$.add(
              this.activitySearchService
                .search({
                  ...query,
                  limit: 999999999,
                  optimized: true,
                })
                .subscribe((res2) => {
                  for (const x1 of res2.docs) {
                    const temp: string[] = [];

                    // title
                    temp.push(x1.title);
                    // owner
                    if (Array.isArray(x1.ownerObj)) {
                      temp.push(x1?.ownerObj?.map((it) => it.name).toString());
                    } else {
                      temp.push(
                        x1?.ownerObj?.name ? x1?.ownerObj?.name : notAvailable
                      );
                    }
                    // expiration

                    const date = x1.dateTime
                      ? this.datePipe.transform(
                          x1.dateTime.toString().replace('Z', ''),
                          this.company_date_format
                        )
                      : notAvailable;
                    const hour = x1?.hour ? ' / ' + x1.hour + 'hr' : '';
                    temp.push(date + hour);

                    // status
                    temp.push(
                      x1?.statusObj?.name ? x1?.statusObj?.name : notAvailable
                    );

                    // supervisor
                    if (Array.isArray(x1.supervisorObj)) {
                      temp.push(
                        x1?.supervisorObj?.map((it) => it.name).toString()
                      );
                    } else {
                      temp.push(
                        x1?.supervisorObj?.name
                          ? x1?.supervisorObj?.name
                          : notAvailable
                      );
                    }

                    // description
                    temp.push(x1?.description ? x1.description : notAvailable);

                    // deal
                    if (res.includes(Company.IdModule.Deals)) {
                      temp.push(x1?.dealObj ? x1?.dealObj.name : notAvailable);
                    }
                    // project
                    if (res.includes(Company.IdModule.Projects)) {
                      temp.push(
                        x1.projectObj ? x1.projectObj?.name : notAvailable
                      );
                    }
                    // asset
                    if (res.includes(Company.IdModule.Assets)) {
                      temp.push(
                        x1?.assetObj ? x1?.assetObj.name : notAvailable
                      );
                    }

                    //temp.push(x1?.hour ? x1.hour : notAvailable);

                    if (customShow?.length > 0) {
                      for (const param of customShow) {
                        temp.push(getCustomValue(x1, param.name));
                      }
                    }

                    const row = worksheet.addRow(temp);
                    row.alignment = {
                      vertical: 'middle',
                      horizontal: 'center',
                    };
                  }

                  worksheet.columns.forEach(function (column) {
                    column.width = 27;
                  });

                  //add data and file name and download
                  workbook.xlsx.writeBuffer().then(async (data) => {
                    const blob = new Blob([data], {
                      type:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });

                    this.fileWrite
                      .writeFile({
                        path: `activities-${this.datePipe.transform(
                          new Date(),
                          'dd-MM-yyyy'
                        )}.xlsx`,
                        blob,
                      })
                      .then(() => {
                        // this.alertService.success(
                        //   this.i18n.instant('general.savedFile')
                        // );
                        this.downloadBtnService.downloadedSuccessfully();
                      });
                  });
                })
            );
          }
        })
    );
  }

  public refresh() {
    this.draw();
  }

  applyFilters(evt: {
    authors: Array<{ value: string }>;
    deals: Array<{ value: string }>;
    assets: Array<{ value: string }>;
    takeDeleted: boolean;
    takeNotified: boolean;
    takeToday: boolean;
    startDate: string;
    endDate: string;
    customProperties: { [key: string]: any };
  }) {
    const authors = evt?.authors?.length
      ? evt.authors.map((type) => type.value)
      : [];
    const deals = evt?.deals?.length ? evt.deals.map((type) => type.value) : [];
    const assets = evt?.assets?.length
      ? evt.assets.map((type) => type.value)
      : [];
    const takeDeleted = evt.takeDeleted;
    const takeNotified = evt.takeNotified;
    const takeToday = evt.takeToday;
    const startDate = evt.startDate;
    const endDate = evt.endDate;

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      // @ts-ignore
      'assets[]': assets,
      // @ts-ignore
      'deals[]': deals,
      // @ts-ignore
      'authors[]': authors,
      // @ts-ignore
      ['from']: startDate,
      // @ts-ignore
      ['to']: endDate,
      takeDeleted: takeDeleted,
      takeNotified: takeNotified,
      takeToday: takeToday,
      customProperties: JSON.stringify(evt.customProperties),
    };

    this.clear();

    this.requestActivities({ loadingGlobal: true, loadingPagination: false });
  }

  /********************************************KANBAN********************************************************** */
  typeOfStatus = 'activity';
  status: IStatus[] = [];
  statusNames: { _id?: string; name: string; color?: string }[] = [];
  kanbanCards: KanbanCard[] = [];

  private requestStatus() {
    const id = StorageService.CompanyId;
    this.sub$.add(
      this.statusService
        .findByUser({ id: id, type: this.typeOfStatus })
        .pipe(map((res) => [...res]))
        .subscribe((res) => {
          this.status = res;
          this.statusNames = this.status.map((x) => ({
            _id: x._id,
            name: x.name,
            color: x?.color ? x.color : DealStatusColors[0].lightColor,
          }));
          this.draw();
        })
    );
  }

  private setCards(response: PaginateResponse<Activity.Output[]>) {
    // @ts-ignore
    this.kanbanCards = response.docs?.map((document) => {
      return {
        _id: document._id,
        selected: false,
        title: document.title,
        finished: document.hasBeenNotified,
        deleted: document.deleted,
        date: document.dateTime
          ? // @ts-ignore
            getDateStrResumStr(document.dateTime?.toString().split('T')[0])
              .formattedDate
          : null,
        column: document.idStatus,
        responsable: Array.isArray(document.supervisorObj)
          ? document.supervisorObj[0]?.name
          : document.supervisorObj?.name,
        assignedTo: Array.isArray(document.ownerObj)
          ? document.ownerObj.map((it) => ({ name: it.name, img: it.img }))
          : document.ownerObj?.name
          ? [{ name: document.ownerObj?.name, img: document.ownerObj?.img }]
          : [],
        comments: document.comments,
        onCreate: false,
      };
    });
  }

  eventCreateFirstKanban() {
    this.sub$.add(
      this.statusService
        .findByUser({ id: StorageService.CompanyId, type: this.typeOfStatus })
        .pipe(map((res) => [...res]))
        .subscribe((res) => {
          const status = res?.map((x) => x.name);
          if (
            status.includes(this.i18n.instant('kanban.column1')) ||
            status.includes(this.i18n.instant('kanban.column2'))
          ) {
            return;
          }

          this.sub$.add(
            this.statusService
              .create({
                name: this.i18n.instant('kanban.column1'),
                type: this.typeOfStatus,
              })
              .subscribe((res) => {
                this.sub$.add(
                  this.statusService
                    .create({
                      name: this.i18n.instant('kanban.column2'),
                      type: this.typeOfStatus,
                    })
                    .subscribe((res) => this.requestStatus())
                );
              })
          );
        })
    );
  }

  eventOnCardClick($event) {
    if ($event.deleted) {
      this.requestDeletedActivity($event._id);
    } else {
      if ($event.finished) {
        this.requestFinishedActivity($event._id);
      } else {
        this.requestOpenActivity($event._id);
      }
    }
  }
  eventCardDblClick($event) {}

  eventChangeColor($event: { darkColor: string; lightColor: string }) {
    this.selectedColor = $event;
  }

  eventAddColumn($event: string) {
    this.sub$.add(
      this.statusService
        .create({
          name: $event,
          type: this.typeOfStatus,
          color: this.selectedColor.lightColor,
        })
        .subscribe((res) => this.requestStatus())
    );
  }

  eventEditColumn($event: { _id: string; name: string; color: string }) {
    this.sub$.add(
      this.statusService
        .updateOne({ id: $event._id, name: $event.name, color: $event.color })
        .subscribe((res) => this.requestStatus())
    );
  }

  eventDeleteColumn($event) {
    this.sub$.add(
      this.statusService.removeOne({ id: $event.column._id }).subscribe(
        (res) => this.requestStatus(),
        (error) => {}
      )
    );
    this.sub$.add(
      this.activityService
        .removeMany($event.cards.map((x) => x._id))
        .subscribe(() => {
          this.requestActivities({ loadingGlobal: true });
        })
    );
  }

  eventCardColumnChange($event: {
    previousColumn?: { _id?: string; name: string };
    column?: { _id?: string; name: string };
    card: KanbanCard;
  }) {
    this.sub$.add(
      this.activityService
        // TODO make sure $event.card._id is always a string
        .updateOne($event.card._id as string, {
          idStatus: $event.column ? $event.column._id : null,
        })
        .subscribe(() => {})
    );
  }

  eventRestoreCard($event) {
    this.sub$.add(
      this.activityService.removeOne($event._id).subscribe((res) => {
        this.requestActivities({ loadingGlobal: true });
      })
    );
  }

  finishToastParams: ToastData = {
    text: 'activity.finishTaskMessage',
    type: 'success',
    msDuration: 4000,
  };
  eventFinishCard($event) {
    this.sub$.add(
      this.activityService
        .updateOne($event._id, {
          hasBeenNotified: true,
        })
        .subscribe(() => {
          this.requestActivities({ loadingGlobal: true });
        })
    );
  }

  unfinishToastParams: ToastData = {
    text: 'activity.unfinishTaskMessage',
    type: 'success',
    link: 'activity.openTaskLink',
    msDuration: 4000,
  };
  eventUnfinishCard($event) {
    this.sub$.add(
      this.activityService
        .updateOne($event._id, {
          hasBeenNotified: false,
        })
        .subscribe(() => {
          this.requestActivities({ loadingGlobal: true });
        })
    );
  }
  eventUnfinishToastLinkClick($event) {
    this.requestOpenActivity($event?._id);
  }

  restoreToastParams: ToastData = {
    text: 'activity.restoreTaskMessage',
    type: 'success',
    link: 'activity.openTaskLink',
    msDuration: 4000,
  };
  eventRestoreToastLinkClick($event) {
    this.requestOpenActivity($event?._id);
  }

  eventEditCard($event: KanbanCard) {
    // TODO we're using $event._id but it could be undefined!
    if ($event.deleted) {
      this.requestDeletedActivity($event._id as string);
    } else {
      if ($event.finished) {
        this.requestFinishedActivity($event._id as string);
      } else {
        this.requestOpenActivity($event._id as string);
      }
    }
  }

  deleteToastParams: ToastData = {
    text: 'activity.deleteTaskMessage',
    type: 'error',
    msDuration: 4000,
    icon: 'assets/icons/kanban/delete.svg',
  };
  eventDeleteCard($event) {
    this.sub$.add(
      this.activityService.removeOne($event._id).subscribe(() => {
        this.requestActivities({ loadingGlobal: true });
      })
    );
  }

  eventMoveAllCards($event) {
    this.sub$.add(
      this.activityService
        .updateMany(
          $event.cards.map((x) => x._id),
          { idStatus: $event.column?._id ? $event.column._id : null }
        )
        .subscribe(() => {})
    );
  }

  private calcTableDistance() {
    setTimeout(() => {
      const containerTable = document.getElementById('containerTable');
      // @ts-ignore
      this.distanceToTop = containerTable?.getBoundingClientRect().top - 74;
    }, 1000);
  }

  checkScroll(e) {
    if (e.target.scrollHeight > 1080) {
      if (e.target.scrollTop <= this.distanceToTop) {
        this.tableMarginTop = '-' + e.target.scrollTop + 'px';
      } else {
        this.tableMarginTop = '-' + this.distanceToTop + 'px';
      }
    }
  }

  private resetTableMarginTop() {
    this.tableMarginTop = '0px';
  }

  /**********************************SELECTION********************************* */
  entriesForDelete!: any[];
  deleteMessage = false;

  deleteMany(evt: any[]) {
    this.entriesForDelete = evt;
    if (evt?.length > 0) {
      this.deleteMessage = true;
    } else {
      this.deleteMessage = false;
    }
  }

  unselectItems() {
    this.entriesForDelete = [];
    this.deleteMessage = false;

    try {
      this.tableComponent.clearChecks();
    } catch (err) {}

    try {
      this.listMobileActivitiesComponent.clearChecks();
    } catch (err) {}
  }

  openDeleteManyDialog(): void {
    const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
      panelClass: 'delete-by-selection-modal',
      data: {
        isPlural: this.entriesForDelete?.length > 1,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'EXECUTE') {
        this.deleteSelection(this.entriesForDelete);
      }
    });
  }

  markMultipleAsFinished() {
    if (!this.entriesForDelete?.length) return;

    this.isUpdatingMultiple = true;
    this.draw();

    const hasBeenNotified = !this.searchParams?.takeNotified;

    this.activityService
      .updateMany(
        this.entriesForDelete.map((i) => i._id),
        { hasBeenNotified: hasBeenNotified }
      )
      .pipe(
        finalize(() => {
          this.isUpdatingMultiple = false;
          this.draw();
          this.unselectItems();
        })
      )
      .subscribe(() => {
        this.toastService.show({
          text: this.i18n
            .instant(
              hasBeenNotified
                ? 'activity.finishedMany'
                : 'activity.unfinishedMany'
            )
            .replace('{qty}', this.entriesForDelete?.length),
          type: 'success',
          msDuration: 4000,
        });

        this.entriesForDelete = [];
        this.draw();

        this.requestActivities({ loadingGlobal: true });
      });
  }

  // Change statuses of multiple activities at the same time
  // through selection on table
  moveMultipleToAnotherStatus_TABLE(status) {
    if (!this.entriesForDelete?.length) return;

    this.isMovingMultiple = true;

    this.sub$.add(
      this.activityService
        .updateMany(
          this.entriesForDelete.map((i) => i._id),
          {
            idStatus: status._id ? status._id : null,
          }
        )
        .pipe(
          finalize(() => {
            this.unselectItems();
            this.isMovingMultiple = false;
          })
        )
        .subscribe(() => {
          this.requestActivities({ loadingGlobal: true });

          let toastText = '';

          if (this.entriesForDelete?.length > 1) {
            toastText = this.i18n
              .instant('kanban.toast.moveMany')
              .replace('{qtty}', this.entriesForDelete?.length)
              .replace(
                '{arg1}',
                this.i18n.instant('kanban.toast.pluralTask').toLowerCase()
              )
              .replace('{column}', status.name);
          } else {
            toastText = this.i18n
              .instant('kanban.toast.moveOne')
              .replace(
                '{arg1}',
                this.i18n.instant('kanban.toast.singularTask').toLowerCase()
              )
              .replace('{column}', status.name);
          }

          this.toastService.show({
            text: toastText,
            type: 'success',
            msDuration: 4000,
          });
        })
    );
  }

  deleteSelection(evt: any[]): void {
    const ids = evt.map((entry) => entry._id);
    this.sub$.add(
      this.activityService
        .deleteMany({
          ids: ids,
        })
        .pipe(
          finalize(() => {
            this.deleteMessage = false;
            this.entriesForDelete = [];
          })
        )
        .subscribe(() => {
          this.toastService.show({
            text: 'general.deleteManyCorrect',
            type: 'error',
            msDuration: 4000,
            icon: 'assets/icons/kanban/delete.svg',
          });
          this.amplitudeService.sendEvent({
            event: 'task_list_delete',
          });
          this.requestActivities({ loadingGlobal: true });
        })
    );
  }

  eventAddCard($event) {
    // TODO making forced conversion here, it might be smoother
    const newActivity = ({
      title: $event.card.title,
      description: '',
      owner: null,
      createdBy: StorageService.UserId,
      customer: null,
      idFinal: this.customer?._id || null,
      vendor: null,
      deal: null,
      deleted: false,
      hasBeenNotified: false,
      dateTime: null,
      asset: null,
      idStatus: $event.column?._id ? $event.column._id : null,
    } as unknown) as ICreateActivityDto;
    this.isLoading = true;
    this.sub$.add(
      this.activityService.create(newActivity).subscribe(() => {
        this.requestActivities({ loadingGlobal: true });
      })
    );
  }

  eventFinishSelectedCards($event) {
    this.sub$.add(
      this.activityService
        .updateMany(
          $event.map((x) => x._id),
          { hasBeenNotified: true }
        )
        .subscribe(() => {
          this.requestActivities({ loadingGlobal: true });
        })
    );
  }

  evenRestoreSelectedCards($event) {
    this.sub$.add(
      this.activityService
        .updateMany(
          $event.map((x) => x._id),
          { deleted: false }
        )
        .subscribe(() => {
          this.requestActivities({ loadingGlobal: true });
        })
    );
  }

  eventUnfinishSelectedCards($event) {
    this.sub$.add(
      this.activityService
        .updateMany(
          $event.map((x) => x._id),
          { hasBeenNotified: false }
        )
        .subscribe(() => {
          this.requestActivities({ loadingGlobal: true });
        })
    );
  }

  eventMoveSelectedCards($event) {
    this.sub$.add(
      this.activityService
        .updateMany(
          $event.cards.map((x) => x._id),
          { idStatus: $event.column?._id ? $event.column._id : null }
        )
        .subscribe(() => {
          this.requestActivities({ loadingGlobal: true });
        })
    );
  }

  eventDeleteSelectedCards($event) {
    this.sub$.add(
      this.activityService
        .removeMany($event.map((x) => x._id))
        .subscribe(() => {
          this.requestActivities({ loadingGlobal: true });
        })
    );
  }

  /************************************************************************************************************ */
  toggleViewType(open: boolean) {
    this.openMobileViewType = open;
    this.deleteMessage = false;
    this.showMoveCardMobileMenu = false;
    this.draw();
  }

  async downloadExcelSelection() {
    this.amplitudeService.sendEvent({
      event: 'task_excelDownload',
    });
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Activities');
    let header = [
      'Título',
      'Descripción',
      'Servicio',
      'Asignado',
      'Dirección',
      'Fecha',
      'Cliente',
    ];

    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(take(1))
        .subscribe((res) => {
          if (res?.length) {
            header = Company.FilterColumnReports({
              columnsReports: header,
              nameColumnsReport: ['Servicio', 'Dirección', 'Cliente'],
              idModules: [
                Company.IdModule.Deals,
                Company.IdModule.Assets,
                Company.IdModule.Finals,
              ],
              idActivesModules: res,
            });
            const customShow = JSON.parse(
              StorageService.CustomProperty('ACTIVITY_MODULE')
            ) as ICustomProperty[];

            if (customShow?.length > 0) {
              for (const param of customShow) {
                header.push(param.name);
              }
            }

            const headerRow = worksheet.addRow(header);
            headerRow.font = {
              color: { argb: '003c48ec' },
              bold: true,
              size: 12,
            };
            headerRow.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            };

            for (const x1 of this.entriesForDelete) {
              const temp: string[] = [];
              temp.push(x1.title);
              temp.push(x1.description);
              if (res.includes(Company.IdModule.Deals)) {
                temp.push(x1?.dealObj ? x1?.dealObj.name : 'No Disponible');
              }

              temp.push(x1?.ownerObj ? x1?.ownerObj.name : 'No Disponible');

              if (res.includes(Company.IdModule.Assets)) {
                temp.push(x1?.assetObj ? x1?.assetObj.name : 'No Disponible');
              }
              temp.push(
                x1.dateTime
                  ? (this.datePipe.transform(
                      x1.dateTime.toString().replace('Z', ''),
                      this.company_date_format
                    ) as string)
                  : 'No Disponible'
              );

              if (res.includes(Company.IdModule.Finals)) {
                temp.push(x1.finalObj ? x1.finalObj?.name : 'No Disponible');
              }

              if (customShow?.length > 0) {
                for (const param of customShow) {
                  temp.push(getCustomValue(x1, param.name));
                }
              }

              const row = worksheet.addRow(temp);
              row.alignment = {
                vertical: 'middle',
                horizontal: 'center',
              };
            }

            worksheet.columns.forEach(function (column) {
              column.width = 27;
            });

            //add data and file name and download
            workbook.xlsx.writeBuffer().then(async (data) => {
              const blob = new Blob([data], {
                type:
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });

              this.fileWrite
                .writeFile({
                  path: `activities-${this.datePipe.transform(
                    new Date(),
                    'dd-MM-yyyy'
                  )}.xlsx`,
                  blob,
                })
                .then(() => {
                  this.downloadBtnService.downloadedSuccessfully();
                });
            });
          }
        })
    );
  }

  //
  sourceFilter(isMobile: boolean) {
    if (!isMobile) {
      //console.log(this.sourceOptions);
    }
  }

  changeActivityStatus(evt: { _id: string; name: string; item: IActivity }) {
    this.isLoading = true;
    const status = this.status.find((status) => evt._id === status._id);
    const idStatus = status ? status?._id : '';
    const statusName = evt.name ? evt.name : 'kanban.withoutColumnTextTask';

    this.activityService.updateOne(evt.item._id, { idStatus }).subscribe(() => {
      this.requestActivities({ loadingGlobal: false });
      this.toastService.show({
        text: this.i18n
          .instant('kanban.toast.moveMany')
          .replace('{qtty}', 1)
          .replace('{arg1}', this.i18n.instant('kanban.cardsName'))
          .replace('{column}', this.i18n.instant(statusName)),
        type: 'success',
        msDuration: 4000,
      });

      this.isLoading = false;
    });
  }

  finishTask(item: IActivity) {
    const newStatus = !item?.hasBeenNotified;
    this.isLoading = true;
    this.activityService
      .updateOne(item._id, { hasBeenNotified: newStatus })
      .subscribe(() => {
        const message = newStatus
          ? 'activity.finishTaskMessage'
          : 'activity.unfinishTaskMessage';
        this.toastService.show({
          text: this.i18n.instant(message),
          type: 'success',
          msDuration: 4000,
        });

        this.isLoading = false;
        this.requestActivities({ loadingGlobal: true });
      });
  }

  _searchValue;
  onKeySearch($event) {
    this._searchValue = $event.target.value;
  }

  getModuleResources() {
    this.sub$.add(
      this.tutorialService
        .get('activities')
        .pipe(
          finalize(() => {
            this.draw();
          })
        )
        .subscribe((res) => {
          this.videoIframeURL = res.source;
          this.academyLink = res?.academy;
        })
    );
  }

  get isLowerThan1450px() {
    return window.innerWidth < 1450;
  }

  download(type: DownloadType) {
    switch (type) {
      case DownloadType.EXCEL:
        if (this.entriesForDelete?.length) {
          this.downloadExcelSelection();
        } else {
          this.downloadExcel();
        }
        break;
      case DownloadType.PDF:
        break;
      default:
        break;
    }
  }
}
