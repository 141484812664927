import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { IDeal } from '@tacliatech/types';
import { DealAproveComponent } from '@web-frontend/shared/components/deal-aprove/deal-aprove.component';
import { AlertService } from '@web-frontend/shared/helpers/alert';

@Directive({
  selector: '[romaClickAproveDeal]',
})
export class ClickAproveDealDirective {
  @Input()
  item?: IDeal;

  @Input()
  type = '';

  @Input()
  approve?: boolean;

  @Output()
  wantAproveDeal = new EventEmitter();

  constructor(
    private alertService: AlertService,
    private i18n: TranslateService,
    private modalService: MatDialog
  ) {}

  @HostListener('click', ['$event'])
  async click(evt: MouseEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    const modalRef = this.modalService.open(DealAproveComponent);

    // @ts-ignore
    (modalRef.componentInstance as DealAproveComponent).dealId = this.item?._id;
    (modalRef.componentInstance as DealAproveComponent).aproveValue = this
      .approve as boolean;

    modalRef.afterClosed().subscribe((res) => {
      if (res) {
        this.item = res;
        this.wantAproveDeal.next(this.item);
      }
    });
  }
}
