import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { ClickRestoreDirective } from './click-restore.directive';

@NgModule({
  declarations: [ClickRestoreDirective],
  imports: [CommonModule, TranslateModule.forChild()],
  exports: [ClickRestoreDirective],
})
export class ClickRestoreModule {}
