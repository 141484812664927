import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalMobileInfoComponent } from './modal-mobile-info.component';
import { ModalTemplateModule } from '../modal-template/modal-template.module';
import { MatChipsModule } from '@angular/material/chips';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ModalMobileInfoComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    MatChipsModule,
    TranslateModule.forChild(),
  ],
  exports: [ModalMobileInfoComponent],
})
export class ModalMobileInfoModule {}
