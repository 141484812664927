import { NgModule } from '@angular/core';
import { AuthGuard } from './auth.guard';
import { AutoLogoutGuard } from './auto-logout.guard';
import { CompanyModuleGuard } from './company-module.guard';
import { PermissionFeatureGuard } from './permision-feature.guard';
import { CountryGuard } from './country.guard';
import { SettingsGuard } from '../settings/settings.guard';

@NgModule({
  providers: [
    AuthGuard,
    PermissionFeatureGuard,
    AutoLogoutGuard,
    CompanyModuleGuard,
    CountryGuard,
    SettingsGuard,
  ],
})
export class AuthGuardModule {}
