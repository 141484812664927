import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RmPhoneSelectInputComponent } from './rm-phone-select-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SearchFilterPipe } from './rm-phone-share.pipe';
import { OnlyNumberModule } from '../../../directives/onlyNumber';

@NgModule({
  declarations: [RmPhoneSelectInputComponent, SearchFilterPipe],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FormsModule,
    OnlyNumberModule,
  ],
  exports: [RmPhoneSelectInputComponent],
})
export class RmPhoneSelectInputModule {}
