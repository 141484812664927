import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'roma-request-readers',
  templateUrl: './request-readers.component.html',
  styleUrls: ['./request-readers.component.scss'],
})
export class RequestReadersComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
