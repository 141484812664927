import { ProformStatus } from '../enum';
import { IStatus } from '../interfaces';

export const STATUS_PROFORM_CREATED = {
  id: ProformStatus.Created,
  name: 'Creado',
  translate: 'status.proform.created',
  order: 1,
  allowEdit: false,
  color: '#E8EDFF',
};

export const STATUS_PROFORM_SENT = {
  id: ProformStatus.Sent,
  name: 'Enviado',
  translate: 'status.proform.sent',
  order: 2,
  allowEdit: false,
  color: '#F3EFFF',
};

export const STATUS_PROFORM_ACCEPTED = {
  id: ProformStatus.Accepted,
  name: 'Aceptado',
  translate: 'status.proform.accepted',
  order: 3,
  allowEdit: false,
  color: '#C2E6F8',
};

export const STATUS_PROFORM_REJECTED = {
  id: ProformStatus.Rejected,
  name: 'Cobrado',
  translate: 'status.proform.rejected',
  order: 4,
  allowEdit: false,
  color: '#FEEBED',
};

export const STATUS_PROFORM_PAID = {
  id: ProformStatus.Paid,
  name: 'Pagado',
  translate: 'status.proform.paid',
  order: 5,
  allowEdit: false,
  color: '#E1F7EB',
};

export const STATUS_PROFORM_INVOICED = {
  id: ProformStatus.Invoiced,
  name: 'Facturado',
  translate: 'status.proform.invoiced',
  order: 6,
  allowEdit: false,
  color: '#C2E6F8',
};

export const STATUS_PROFORM_CANCELLED = {
  id: ProformStatus.Cancelled,
  name: 'Cancelado',
  translate: 'status.proform.cancelled',
  order: 7,
  allowEdit: false,
  color: '#FEEBED',
};

export const STATUS_PROFORM_OVERDUE = {
  id: ProformStatus.Overdue,
  name: 'Vencido',
  translate: 'status.proform.overdue',
  order: 8,
  allowEdit: false,
  color: '#FEE7DA',
};

export const statusProform: IStatus[] = [
  STATUS_PROFORM_CREATED,
  STATUS_PROFORM_SENT,
  STATUS_PROFORM_ACCEPTED,
  STATUS_PROFORM_REJECTED,
  STATUS_PROFORM_PAID,
  STATUS_PROFORM_INVOICED,
  STATUS_PROFORM_CANCELLED,
  STATUS_PROFORM_OVERDUE,
].sort((a, b) => (a.order > b.order ? 1 : -1));

export const STATUS_PROFORM_SORT = [0, 10, 100, 20, 30, 60, 95, 90, 50];
