import { Types } from 'mongoose';
import { Company } from '../companies';
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace FreeLimitType {
  export enum AllowedTypes {
    CustomProperty = 'customProperties',
    File = 'files',
    FirstWayBillCreated = 'firstWayBillCreated',
  }

  export type CustomProperty = Types.ObjectId;

  export interface File {
    id: string;
    module: string;
  }

  export interface Response {
    _id: Types.ObjectId;
    customProperties?: CustomProperty[];
    files?: File[];
    firstWayBillCreated?: boolean;
    consumed: number;
  }

  export interface FileDto {
    id: string;
    module: Company.IdModule;
  }

  export interface UpdateLimitDto {
    file?: FileDto;
    customProperty?: string;
    firstWayBillCreated?: boolean;
    onInsert: boolean;
  }
}
