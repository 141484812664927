import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnboardingModalService } from '../services/onboarding-modal.service';
import { BudgetsSetting, FeatureUser, TacliaPayments } from '@tacliatech/types';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PaymentsService } from '../services/payments.service';
import { ActiveModalModuleService } from './modal-module';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { AuthService } from '@web-frontend/shared/services';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';

@Component({
  selector: 'roma-payment-activate',
  templateUrl: './payment-active.component.html',
  styleUrls: ['./payment-active.component.scss'],
})
export class PaymentActiveComponent implements OnInit {
  loadingPaymentActiveButton = false;
  loadingImage = true;
  businessType = false;
  companyData$ = this.budgetService.settings$;
  featureRefUser = FeatureUser;
  userActive$ = this.authService.user$;

  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private authService: AuthService,
    private paymentsService: PaymentsService,
    private onboardingModalService: OnboardingModalService,
    private activeModalModuleService: ActiveModalModuleService,
    private budgetService: BudgetService,
    public dialog: MatDialog,
    private toastService: ToastService
  ) {}

  companyData: BudgetsSetting;

  ngOnInit(): void {
    this.companyData$.subscribe((data) => {
      this.businessType = data?.data_account?.business_type ? true : false;
      this.companyData = data;
      this.featureRefUser.Payment.show;
    });
    this.budgetService.init();
  }

  activatePayment() {
    if (
      [null, undefined, ''].includes(
        this.companyData?.data_account?.business_type
      )
    ) {
      this.openActiveModal({
        title: 'payments.activate.title',
        content: 'payments.activate.content',
      });
      return;
    }
    //  this.router.navigateByUrl('/admin/payments/onboarding');
    //Params TicketID
    const config = new MatDialogConfig<TacliaPayments.CreditCard.ModalRequest>();
    const params: TacliaPayments.CreditCard.ModalRequest = {
      idSaleTicket: '111012312312',
    };
    config.data = params;
    config.height = '100vh';
    config.width = '100vw';
    config.disableClose = false;
    config.panelClass = 'full-screen-modal';

    try {
      // show modal to confirm operation
      this.onboardingModalService
        .open(config)
        .subscribe((response: boolean) => {
          this.draw();
        });
    } catch (error) {
      console.log('error', error);
      // this.handleHttpErrorResponse(error);
    }
  }

  isLoading(value: boolean) {
    // console.log('fafafa');
    this.loadingImage = value;
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  get isAdmin() {
    return this.authService.isAdmin$;
  }

  openActiveModal(data: { title: string; content: string }) {
    this.activeModalModuleService.open({ data: data }).subscribe((res) => {
      if (res.complete === true) {
        this.router.navigate(['/admin/settings/general']);
      }
    });
  }

  openAskUpdateCompanyAdjust() {
    if (
      [null, undefined, ''].includes(
        this.companyData?.billing_addres?.country
      ) &&
      [null, undefined, ''].includes(
        this.companyData?.data_account?.business_type
      )
    ) {
      this.openActiveModal({
        title: 'payments.activate.0country0type_title',
        content: 'payments.activate.0country0type_content',
      });
    } else if (
      [null, undefined, ''].includes(this.companyData?.billing_addres?.country)
    ) {
      this.openActiveModal({
        title: 'payments.activate.0country1type_title',
        content: 'payments.activate.0country1type_content',
      });
    } else if (
      [null, undefined, ''].includes(
        this.companyData?.data_account?.business_type
      )
    ) {
      this.openActiveModal({
        title: 'payments.activate.1country0type_title',
        content: 'payments.activate.1country0type_content',
      });
    } else {
      if (this.isAdmin) {
        this.activatePayment();
      } else {
        this.toastService.show({
          text: 'general.adminRole',
          type: 'error',
          msDuration: 4000,
        });
      }
    }
  }
}
