<mat-chip-list *ngIf="items?.length === 1">
  <mat-chip *ngFor="let time of items" class="mat-chip-home">
    {{ time?.number ? time?.pre + '' + time?.number : time }}
  </mat-chip>
</mat-chip-list>

<mat-chip-list *ngIf="items?.length > 1">
  <mat-chip class="mat-chip-home clickeable" (click)="viewAll()">
    {{ 'table.colum.asset_see_all' | translate }} ({{ items?.length }})
  </mat-chip>
</mat-chip-list>

<mat-chip-list *ngIf="data?.length === 1">
  <mat-chip *ngFor="let item of data" class="mat-chip-home">
    {{ item.name }}
  </mat-chip>
</mat-chip-list>

<mat-chip-list *ngIf="data?.length > 1">
  <mat-chip class="mat-chip-home clickeable" (click)="viewAll()">
    {{ 'table.colum.asset_see_all' | translate }} ({{ data?.length }})
  </mat-chip>
</mat-chip-list>

<ng-container *ngIf="!items?.length && !data?.length">
  {{ tagAvailable | translate }}
</ng-container>
