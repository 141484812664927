export enum InvoiceType {
  SALES = 'standard',
  FEE = 'fee',
  LEASE = 'lease',
}

export const INVOICE_TYPE_LIST: InvoiceType[] = [
  InvoiceType.SALES,
  InvoiceType.FEE,
  InvoiceType.LEASE,
];
