import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Company } from '@tacliatech/types';
import { AuthService } from '@web-frontend/shared/services';
import { CompanyModuleService } from '@web-frontend/shared/services/company';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompanyModuleGuard implements CanLoad {
  constructor(
    private router: Router,
    private authService: AuthService,
    private companyModuleService: CompanyModuleService
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    // @ts-ignore
    const { modules } = route.data;

    const MapModules = (actives: Company.IdModule[]): boolean => {
      for (const module of actives) {
        if (modules.includes(module)) {
          return true;
        }
      }

      return false;
    };

    return this.authService.handleUserRequest().pipe(
      map((res) => {
        // @ts-ignore
        const activeModules = res.company.modules
          .filter((el) => el.active)
          .map((el) => el.id);

        return MapModules(activeModules);
      }),
      tap((res) => {
        if (!res) {
          this.companyModuleService.goFirstRouteActive();
        }
      })
    );
  }
}
