import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { FormBuilder, Validators } from '@angular/forms';
import { IEquipmentNote } from '@tacliatech/types';
import { StorageService } from '@web-frontend/shared/services';
import { BudgetService } from '../../../services/budgets/budgets.service';

@Component({
  selector: 'roma-equipment-notes',
  templateUrl: './equipment-notes.component.html',
  styleUrls: ['./equipment-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentNotesComponent implements OnInit {
  @Input()
  mode: 'ADD' | 'EDIT' = 'ADD';

  @Input()
  data: IEquipmentNote[] = [];

  @Output()
  addingNotes = new EventEmitter<IEquipmentNote[]>();

  notes: IEquipmentNote[] = [];

  form = this.fb.group({
    createdAt: [new Date(), Validators.required],
    text: ['', Validators.required],
    idAuthor: [StorageService.UserId],
  });

  company_date_format = this.budgetService.date_format
    ? this.budgetService.date_format
    : 'dd/MM/yyyy';

  constructor(
    private fb: FormBuilder,
    private changeDetectionRef: ChangeDetectorRef,
    private budgetService: BudgetService
  ) {}

  ngOnInit(): void {
    this.patchParams();
  }

  addNotes() {
    if (this.form.valid) {
      this.notes = [
        ...this.notes,
        {
          ...this.form.value,
        },
      ];

      this.addingNotes.emit(this.notes);
      this.form.get('text').reset();
      this.draw();
    }
  }

  private patchParams() {
    if (this.mode === 'EDIT') {
      this.notes = [...this.data];

      this.draw();
    }
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }
}
