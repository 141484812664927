import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { TacliaCustomStepperComponent } from './taclia-custom-stepper/taclia-custom-stepper.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [TacliaCustomStepperComponent],
  imports: [CommonModule, CdkStepperModule, FormsModule],
  exports: [TacliaCustomStepperComponent],
})
export class TacliaStepperModule {}
