import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export abstract class GenericComponent {
  subscription: Subscription;
  subscriptions: Subscription[] = [];
  name: string;

  constructor(n?: string) {
    if (n) {
      this.name = n;
    }
  }

  pushSubcription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  destroySubcriptions() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
