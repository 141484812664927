import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Feature } from '@tacliatech/types';
import { BuyModalComponent } from '../buy-modal/buy-modal.component';

@Component({
  selector: 'roma-pro-warning-info',
  templateUrl: './pro-warning-info.component.html',
  styleUrls: ['./pro-warning-info.component.scss'],
})
export class ProWarningInfoComponent implements OnInit {
  @Input() iconUrl = 'assets/icons/pro-disclaimer-star.svg';
  @Input() warning = false;
  @Input() message = 'pro.buyModal.tableColumn1';
  @Input() button = 'pro.disclaimer.button';

  constructor(
    private i18n: TranslateService,
    private dialogRef: MatDialogRef<BuyModalComponent>,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  getTranslation(value: string): string {
    const message = this.i18n.instant(value) || '';
    return message;
  }

  tryPro(center = true): void {
    const feature = Feature.Deal.DownloadExcel;
    this.dialog.open(BuyModalComponent, {
      panelClass: ['sheet-pro', center ? 'centered' : 'default'],
      height: '100vh',
      width: '100vw',
      minWidth: '100vw',
      minHeight: '100vh',
      data: {
        feature: feature,
        showTrialBtn: false,
      },
      disableClose: true,
    });
  }
}
