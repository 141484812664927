import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { EditableFieldModule } from '../editable-field';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { FieldErrorDisplayModule } from '../field-error-display';
import { BudgetLogoComponent } from './budget-logo.component';
import { ImageModule } from '@web-frontend/shared/pipes/image';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ModalTemplateModule } from '@web-frontend/shared/components/modal-template';
import { LogoCropService } from './logo-crop.service';
import { TranslateModule } from '@ngx-translate/core';
import { LogoCropComponent } from './logo-crop-preview/logo-crop.component';
import { PermissionsModule } from '../permissions';
import { PictureV2Module } from '../picture-v2/picture-v2.module';
import { BaseButtonModule } from '@web-frontend/common/components/button/baseButton.module';
import { PlatformDisableContentModule } from '../platform-disable-content/platform-disable-content.module';

@NgModule({
  declarations: [BudgetLogoComponent, LogoCropComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    EditableFieldModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatCardModule,
    FieldErrorDisplayModule,
    ImageModule,
    PictureV2Module,
    TranslateModule,
    ImageCropperModule,
    ModalTemplateModule,
    PermissionsModule,
    BaseButtonModule,
    PlatformDisableContentModule,
  ],
  exports: [BudgetLogoComponent, LogoCropComponent],
  providers: [LogoCropService],
})
export class BudgetLogoModule {}
