import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { ModalInfoComponent } from '../modal-info';
import { UsersListResumeComponent } from '../users-list-resume/users-list-resume.component';

@Component({
  selector: 'app-chips-resume',
  templateUrl: './chips-resume.component.html',
  styleUrls: ['./chips-resume.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsResumeComponent implements OnInit {
  @Input()
  items: string[] = [];

  @Input()
  data: { name: string; img: string }[] = [];

  @Input()
  title = '';

  @Input()
  mode: 'ModalInfoComponent' | 'UsersListResumeComponent' =
    'ModalInfoComponent';

  @Input()
  tagAvailable = 'general.propertyNotAvailable';

  constructor(private modalService: MatDialog) {}

  ngOnInit(): void {}

  viewAll() {
    if (this.mode == 'ModalInfoComponent') {
      const modalRef = this.modalService.open(ModalInfoComponent);

      (modalRef.componentInstance as ModalInfoComponent).elements = this.items;
      (modalRef.componentInstance as ModalInfoComponent).title = this.title;
    } else {
      const modalRef = this.modalService.open(UsersListResumeComponent);

      (modalRef.componentInstance as UsersListResumeComponent).data = this.data;
    }
  }
}
