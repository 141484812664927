import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CreateFinalV2Component } from './create-final-v2.component';
import { CreateFinalPartiallyComponent } from './create-final-partially/create-final-partially.component';

@Injectable({
  providedIn: 'root',
})
export class CreateFinalV2Service {
  private refreshListSource = new Subject<boolean>();
  private currentDialogRef: MatDialogRef<CreateFinalV2Component> | null = null;

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(config: MatDialogConfig = new MatDialogConfig()): Observable<string> {
    config.position = { right: '0px' };

    const disableClose = config.data?.mode === 'EDIT';
    this.currentDialogRef = this.dialog.open(CreateFinalV2Component, {
      ...config,
      autoFocus: false,
      disableClose,
    });

    return this.currentDialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
        this.currentDialogRef = null;
      })
    );
  }

  openCreatePartially(
    config: MatDialogConfig = new MatDialogConfig()
  ): Observable<string> {
    const dialogRef = this.dialog.open(CreateFinalPartiallyComponent, {
      ...config,
      autoFocus: false,
      disableClose: false,
    });

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }

  openEdit(
    id: string,
    config: MatDialogConfig = new MatDialogConfig()
  ): Observable<string> {
    config.data = { idCustomer: id, mode: 'EDIT', takeDeleted: false };

    this.currentDialogRef = this.dialog.open(CreateFinalV2Component, {
      ...config,
      autoFocus: false,
      disableClose: true,
    });

    return this.currentDialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
        this.currentDialogRef = null;
      })
    );
  }

  close(): void {
    if (this.currentDialogRef) {
      this.currentDialogRef.close();
      this.currentDialogRef = null;
    }
  }
}
