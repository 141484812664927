import { ICustomer } from '@tacliatech/types';

export class Customer {
  sector: string;
  company_size: string;
  company_type: string;
  _id: string;
  name: string;
  owner: string;
  __v: number;
  serviceHours?: boolean;
  city?: string;
  deleted?: boolean;

  static fromArray(attributes: ICustomer[]) {
    return attributes.map((attribute) => new Customer(attribute));
  }

  constructor(attribute: ICustomer) {
    Object.assign(this, attribute);
  }
}
