<roma-modal-template
  [title]="'Confirmar Pago' | translate"
  [showDefaultButtons]="true"
  (success)="submit()"
  (cancel)="close()"
>
  <div class="row">
    <label>ID ticket</label>
    <p>{{ data.idSaleTicket }}</p>
  </div>
</roma-modal-template>
