import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { QrInfoComponent } from './qr-info/qr-info.component';

@Component({
  selector: 'roma-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrComponent implements OnInit {
  @Input()
  url?: string;

  @Input()
  size = 40;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  @HostListener('click', ['$event'])
  click(evt: MouseEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    this.open({
      data: {
        url: this.url,
      },
    });
  }

  private open(config: MatDialogConfig = new MatDialogConfig()) {
    config.data = {
      ...config.data,
    };

    const dialogRef = this.dialog.open(QrInfoComponent, config);

    return dialogRef;
  }
}
