import { TranslateService } from '@ngx-translate/core';
import {
  Company,
  Feature,
  FeatureUser,
  ICustomProperty,
} from '@tacliatech/types';
import { RmFilter } from '@web-frontend/shared/components/globals/rm-filter';
import {
  AssetSearchService,
  AssetService,
  DealService,
  FinalService,
  ProjectService,
  StorageService,
} from '@web-frontend/shared/services';
import { CompanyModuleService } from '@web-frontend/shared/services/company';
import { from } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export const SOURCE_WEB = new RmFilter.Filter([
  new RmFilter.Input({
    type: 'SEARCH',
    keyRequest: 'keyword',
    content: {
      label: '',
      placeholder: 'activity.placeholder',
    },
  }),
]);

const requestAuthors$ = (assetService: AssetService) =>
  assetService.findUsersByAssetsShared().pipe(
    map((res) =>
      res.map((el) => {
        return {
          title: el.name.toString().toLocaleLowerCase(),
          value: el._id,
          id: el._id,
          img: el.img,
        };
      })
    )
  );

const requestFinals$ = (finalService: FinalService) =>
  finalService.search({ applyPaginate: false }).pipe(
    map((res) => res.docs),
    map((res) => {
      return res.map((el) => {
        return {
          title: `${el.name} ${el.lastName ? el.lastName : ''}`,
          id: el._id,
          value: el._id,
          img: el.img,
        };
      });
    })
  );

const showModule$ = (
  companyModuleService: CompanyModuleService,
  idModule: Company.IdModule
) =>
  companyModuleService.idActiveModules$.pipe(
    map((res) => res.includes(idModule))
  );

const requestDeals$ = (dealService: DealService) =>
  dealService.findAllDealsByCompany_SELECT().pipe(
    filter((res) => !!res),

    map((res) => {
      return res.map((deal) => {
        return {
          title: deal.name,
          id: deal._id,
          value: deal._id,
        };
      });
    })
  );

const requestProjects$ = (projectService: ProjectService) =>
  projectService.search({ applyPaginate: false }).pipe(
    map((res) =>
      res.docs.map((el) => {
        return {
          title: el.name,
          id: el._id,
          value: el._id,
        };
      })
    )
  );

const requestAssets$ = (assetSearchService: AssetSearchService) =>
  assetSearchService
    .search({
      applyPaginate: false,
      customer: StorageService.CustomerId,
    })
    .pipe(
      map((res) => {
        return res.docs.map((res) => {
          return {
            title: res.name,
            id: res._id,
            value: res._id,
          };
        });
      })
    );

export const SOURCE_OPTIONS_TASKS = [
  new RmFilter.Checkbox({
    keyRequest: 'takeMine',
    class: 'checkboxQuad',
    content: {
      label: 'activity.takeMine',
    },
  }),
  new RmFilter.Checkbox({
    keyRequest: 'takeToday',
    class: 'checkboxQuad',
    content: {
      label: 'activity.viewToday',
    },
  }),
  new RmFilter.Checkbox({
    keyRequest: 'takeNotified',
    class: 'checkboxQuad',
    content: {
      label: 'activity.finished',
    },
  }),
  new RmFilter.Checkbox({
    keyRequest: 'takeDeleted',
    class: 'checkboxQuad',
    content: {
      label: 'activity.viewDelete',
    },
  }),
];

export const SOURCE_WEB_OPTIONS = (data: {
  assetService: AssetService;
  assetSearchService: AssetSearchService;
  customProperties: ICustomProperty[];
  i18n: TranslateService;
  dealService: DealService;
  projectService: ProjectService;
  companyModuleService: CompanyModuleService;
  isMobile?: boolean;
  finalService: FinalService;
}) => {
  const controls: Array<
    | RmFilter.Control
    | RmFilter.Select
    | RmFilter.RangeDate
    | RmFilter.InputDate
    | RmFilter.InputRange
  > = [
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'supervisors[]',
      source$: requestAuthors$(data.assetService) as any,
      content: {
        label: 'filter.supervisor',
        placeholder: 'filter.supervisor',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'finals[]',
      source$: requestFinals$(data.finalService),
      show$: showModule$(data.companyModuleService, Company.IdModule.Finals),
      featureUser: FeatureUser.Final.show,
      content: {
        label: 'filter.final',
        placeholder: 'filter.final',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'authors[]',
      source$: requestAuthors$(data.assetService) as any,
      content: {
        label: 'filter.assigned',
        placeholder: 'filter.assigned',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'deals[]',
      source$: requestDeals$(data.dealService),
      show$: showModule$(data.companyModuleService, Company.IdModule.Deals),
      featureUser: FeatureUser.Deal.show,
      content: {
        label: 'expense.create.form.deal',
        placeholder: 'expense.create.form.deal',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'projects[]',
      source$: requestProjects$(data.projectService),
      show$: showModule$(data.companyModuleService, Company.IdModule.Projects),
      featureUser: FeatureUser.Project.show,
      content: {
        label: 'deals.projectsPh',
        placeholder: 'deals.projectsPh',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'assets[]',
      source$: requestAssets$(data.assetSearchService),
      show$: showModule$(data.companyModuleService, Company.IdModule.Assets),
      featureUser: FeatureUser.Address.show,
      content: {
        label: 'filter.address',
        placeholder: 'filter.address',
      },
    }),
    new RmFilter.RangeDate({
      keyRequest: ['from', 'to'],
      content: {
        label: '',
        startPlaceholder: '',
        endPlaceholder: '',
      },
    }),
  ];

  for (const property of data.customProperties) {
    if (['SELECT'].includes(property.type)) {
      const options = property.options.map((el) => {
        return {
          id: el,
          title: el,
          value: el,
        };
      });
      controls.push(
        new RmFilter.Select({
          type: 'MULTIPLE',
          keyRequest: property.name,
          feature: Feature.Activity.CustomPropertiesFilter,
          keyRequestParent: 'customProperties',
          source$: from([options]),
          content: {
            label: `${data.i18n.instant('filter.custom')} ${property.name}`,
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }

    if (['BOOLEAN'].includes(property.type)) {
      const options = [
        {
          id: 'true',
          title: 'general.yes',
          value: 'true',
        },
        {
          id: 'false',
          title: 'general.no',
          value: 'false',
        },
      ];
      controls.push(
        new RmFilter.Select({
          type: 'SINGLE',
          keyRequest: property.name,
          keyRequestParent: 'customProperties',
          feature: Feature.Activity.CustomPropertiesFilter,
          source$: from([options]),
          content: {
            label: `${data.i18n.instant('filter.custom')} ${property.name}`,
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  for (const property of data.customProperties) {
    if (['DATE'].includes(property.type)) {
      controls.push(
        new RmFilter.InputDate({
          keyRequest: property.name,
          keyRequestParent: 'customProperties',
          feature: Feature.Activity.CustomPropertiesFilter,
          content: {
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  for (const property of data.customProperties) {
    if (property.type === 'STRING') {
      controls.push(
        new RmFilter.Input({
          type: 'STRING',
          keyRequest: property.name,
          keyRequestParent: 'customProperties',
          feature: Feature.Activity.CustomPropertiesFilter,
          content: {
            label: '',
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  for (const property of data.customProperties) {
    if (property.type === 'NUMBER') {
      controls.push(
        new RmFilter.InputRange({
          keyRequest: property.name,
          keyRequestParent: 'customProperties',
          feature: Feature.Activity.CustomPropertiesFilter,
          content: {
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  const controlsToReturn = data.isMobile
    ? new RmFilter.Filter([...SOURCE_OPTIONS_TASKS, ...controls] as any)
    : new RmFilter.Filter([...controls] as any);

  return controlsToReturn;
};
