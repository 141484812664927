import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BillDigitalCertificateService } from '@web-frontend/shared/services/bill-digital-certificate/bill-digital-certificate.service';
import { CertificateStatus } from 'src/types-legacy/lib/enum/certificate-status.enum';

@Component({
  selector: 'bill-digital-certificate',
  templateUrl: './bill-digital-certificate.component.html',
  styleUrls: ['./bill-digital-certificate.component.scss']
})
export class BillDigitalCertificateComponent implements OnInit {

  constructor(private billDigitalCertificateService: BillDigitalCertificateService, private changeDetectionRef: ChangeDetectorRef) { }

  certificateStatus:CertificateStatus;
  isLoading:boolean = true;

  async ngOnInit() {  
    try{
      this.isLoading = true;
      const applications = await this.billDigitalCertificateService.findApplications();
      if (!applications?.results || applications?.results?.length === 0){
        this.certificateStatus = CertificateStatus.NoStatus;
      }else{
        this.certificateStatus = applications.results[0].status;
      }
      this.isLoading = false;
      this.draw();
    }catch(e){
      this.certificateStatus = CertificateStatus.NoStatus;
    }
  }

  showSendCertificate(){
    return this.certificateStatus === CertificateStatus.NoStatus;
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  onRetrySendCertificate(){
    this.certificateStatus = CertificateStatus.NoStatus;
  }

}
