/* eslint-disable @typescript-eslint/no-namespace */

import {
  CustomProperty,
  IChatReview,
  IFinalPrice,
  IStatus,
  IUser,
  IVendor,
} from '../../interfaces';
import { DealType } from '../../enum';
import { RecurrenceGlobal } from '../recurrences';
import { Equipment } from '../equipments';
import { Final } from '../finals';
import { Project } from '../projects';
import { Activity } from '../activities';
import { Product } from '../products';

export namespace Deal {
  export enum TypeEndRecurrence {
    SpecificDay = 'SPECIFIC_DAY',
    SpecificAmount = 'SPECIFIC_AMOUNT',
    Indefinitely = 'INDEFINITELY',
  }

  export enum TypeException {
    ByCreateDeal = 'C_D',
    ByDeleteDealSubsequent = 'D_D_S',
    ByDeleteDealCurrent = 'D_D_C',
  }

  export interface Schema {
    name: string;
    title?: string;
    status?: IStatus;
    customer: string;
    type: DealType;
    createdBy: string;
    createdByObj: IUser;

    company?: {
      _id: string;
      name: string;
    };
    dates?: { initTimestamp: number; endTimestamp: number };
    finishDate?: Date;
    isAllDay?: boolean;

    recurrence?: RecurrenceGlobal.Storage;
    recurrenceHistory?: RecurrenceGlobal.History;
    recurrenceEnd?: {
      type: Deal.TypeEndRecurrence;
      date?: string;
      amount?: number;
    };
    recurrenceExceptions?: {
      id: string;
      date: string; //  date in string format Date ISO_8601
      type: Deal.TypeException;
    }[];

    billLines?: Array<any>;
    pipedrive?: string;
    description?: string;
    dateCreated?: Date;
    visitation?: boolean;
    visitationDate?: Date;
    city?: string;
    address?: string;
    address2?: string;
    location?: { type: { type: string }; coordinates: [number] };
    latitude?: string;
    longitude?: string;
    times?: Array<string>;
    imgs?: any[];
    files?: any;
    stage?: string;
    contactName?: string;
    contactPhone?: string;
    contactEmail?: string;
    comment?: string;
    cancellationReason?: any;
    jobDescription?: string;

    budget?: Deal.Budget;
    bill?: Deal.Budget;

    updatedBy?: string;
    isUpdated?: boolean;
    updatedAt?: Date;
    // eslint-disable-next-line @typescript-eslint/ban-types
    operationNotes?: Array<Object>;
    evidences?: Array<string>;
    deleted?: boolean;
    cancelled?: boolean;
    urgency?: boolean;
    chatReview?: IChatReview;
    customProperties?: CustomProperty[];
    taskers?: string[];
    invoice?: IFinalPrice;
    members?: Array<string>;

    asset?: string;
    assetObj?: {
      _id: string;
      name: string;
      createdBy: string;
      customer_id: string;
      deleted: boolean;
    };

    billingProfile?: string;

    product_id?: string;
    product?: Product.Schema;

    vendor?: IVendor;
    reporter?: string;
    reporterObj?: IUser;
    owner?: string;
    partialTime?: {
      duration: number;
      parserTime: string;
    };
    auctioneers?: string[];
    idEquipments?: string[];
    equipments?: Equipment.Schema[];

    idFinal?: string;
    final?: Final.Schema;
    idProject?: string;
    project?: Project.Schema;
    idBudget?: string;
    idProform?: string;
    idBill?: string;
    idGroup?: string;
    taskerTeam?: IUser[];
    notes?: any[];
    products?: any[];
    _id?: string;
    internalVendorObj?: {
      _id: string;
      name?: string;
      fiscalName?: string;
    };

    isFinished?: boolean;
    tasks?: string[];
    activities?: Activity.IActivitySchema[];
  }

  export interface Output extends Deal.Schema {
    id?: string;
    idAsset?: string;
    initDateStr?: string;
    initDate?: Date;
    dates?: { initTimestamp: number; endTimestamp: number };
    parentRecurrence?: string;
    isAllDay?: boolean;
  }

  export interface GhostDeal extends Deal.Output {
    isGhostDeal: boolean;
  }

  export interface Budget {
    _id: string;
    total: number;
    header: {
      numberDoc: string;
    };
  }

  export const isAllDay = (initHourValue: string, endHourValue: string) => {
    if (
      (initHourValue === '00:00' && endHourValue === '23:59') ||
      (initHourValue === null && endHourValue === null) ||
      (initHourValue === '00:00' && endHourValue === '00:00')
    ) {
      return true;
    } else {
      return false;
    }
  };

  export const IsDealRecurrence = (deal: unknown) => {
    if (Object.prototype.hasOwnProperty.call(deal, 'isGhostDeal')) {
      if (deal?.['isGhostDeal']) {
        return true;
      }
    }

    if (deal?.['recurrenceHistory']?.['hasBeenCloned']) {
      return true;
    }

    if (deal?.['parentRecurrence']) {
      return true;
    }

    return Boolean(deal?.['recurrenceEnd']?.['type']);
  };

  export const GetDealTitle = ({
    name,
    final,
  }: {
    name?: string;
    final?: { name: string };
  }) => {
    let title = '';

    if (!name && final?.name) {
      title = `${title} ${final?.name}`;
    }

    if (!final?.name && name) {
      title = `${title} ${name}`;
    }

    if (final?.name && name) {
      title = `${title} ${name}`;
    }

    return title;
  };
}
