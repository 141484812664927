import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  ClockHour,
  COMPANY_ID_KEY,
  FeatureUser,
  TypeRol,
  ValidateAllFormFields,
} from '@tacliatech/types';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { USER_ID_KEY } from '@web-frontend/shared/constants';
import {
  AuthService,
  ClockHourService,
  StorageService,
  clockHourPaginateResponse,
} from '@web-frontend/shared/services';
import { Subscription } from 'rxjs';
import { AddClockHour } from './add-clock-hour.types';
import { AddClockValidators } from './add-clock-hour.validators';

import { addHours } from 'date-fns';
import { finalize } from 'rxjs/operators';
import { UserflowService } from '@web-frontend/shared/services/userflow/userflow.service';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { DeleteBySelectionModalComponent } from '../delete-by-selection-modal/delete-by-selection-modal.component';
import moment from 'moment-timezone';

@Component({
  selector: 'roma-add-clock-hour',
  templateUrl: './add-clock-hour.component.html',
  styleUrls: ['./add-clock-hour.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddClockHourComponent implements OnInit {
  mode: 'EDIT' | 'ADD' = 'ADD';
  dataParams!: ClockHour.Output;
  statusRef = ClockHour.Status;

  form = this.fb.group({
    idOwner: new FormControl(StorageService.GetItem(USER_ID_KEY)),
    idCreatedBy: new FormControl(StorageService.GetItem(USER_ID_KEY), [
      Validators.required,
    ]),
    idCompany: new FormControl(StorageService.GetItem(COMPANY_ID_KEY), [
      Validators.required,
    ]),
    date: new FormControl('', [Validators.required]),
    histories: [[]],
    note: new FormControl(''),
  });

  histories: ClockHour.Histories = [];
  lastDate: { year: number; month: number; day: number };
  refreshOnClose = false;
  totalHoursClock = '00:00';
  loading = false;
  wrongTime = false;
  equalTime = false;
  blockEdition = false;
  hasOverlapError = false;

  initialForm: any = {};

  featureUserRef = FeatureUser;
  private id!: string;
  private sub$ = new Subscription();

  continueHistories: ClockHour.Histories = [];

  constructor(
    private fb: FormBuilder,
    private i18n: TranslateService,
    private cdRef: ChangeDetectorRef,
    private authService: AuthService,
    private clockHourService: ClockHourService,
    private amplitudeService: AmplitudeService,
    private dialogRef: MatDialogRef<AddClockHourComponent, AddClockHour.Params>,
    private userflowService: UserflowService,
    private toastService: ToastService,
    private brazeService: BrazeService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: AddClockHour.Params
  ) {}

  ngOnInit(): void {
    this.initFormData(this.data);
    this.dialogRef.disableClose = true;
    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onBackdropClick());
  }

  initFormData(data: AddClockHour.Params): void {
    console.log('DATA', data);

    this.attachParams(data);
    this.calculateTotalHoursClock(data);
    this.initialForm = { ...this.form.value };
  }

  isToday = false;
  refreshToday(date: Date): void {
    this.isToday = moment(date.toUTCString()).isSame(moment(), 'day');
  }

  public async onBackdropClick(): Promise<void> {
    if (this.haveChanges) {
      const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
        panelClass: 'delete-by-selection-modal',
        data: {
          title: this.i18n.instant('general.withoutSave'),
          confirmLabel: this.i18n.instant('general.buttonExit'),
          showBody: false,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'EXECUTE') {
          this.close();
        }
      });
    } else {
      this.close();
    }
  }

  get haveChanges(): boolean {
    return Object.entries(this.form.value).some(
      ([key, value]) => this.initialForm[key] !== value
    );
  }

  get featureUser(): string {
    if (this.mode == 'EDIT') {
      return this.featureUserRef.ClockHour.update;
    }
    return this.featureUserRef.ClockHour.create;
  }

  close(params = null): void {
    this.dialogRef.close(params || this.refreshOnClose);
  }

  changeClockHistories(evt: ClockHour.Histories): void {
    for (let i = 0; i < evt.length; i++) {
      let calculateDuration = false;

      if (isNaN(evt[i].startHour.hour)) {
        try {
          const hour = parseInt(evt[i].startDate);
          evt[i].startHour.hour = hour;
          evt[i].startHour.minute = 0;
          evt[i].startDate =
            evt[i].startHour.hour.toString().padStart(2, '0') +
            ':' +
            evt[i].startHour.minute.toString().padStart(2, '0');
        } catch (e) {
          evt[i].startHour.hour = 0;
          evt[i].startHour.minute = 0;
          evt[i].startDate =
            evt[i].startHour.hour.toString().padStart(2, '0') +
            ':' +
            evt[i].startHour.minute.toString().padStart(2, '0');
        }
        calculateDuration = true;
      } else {
        if (isNaN(evt[i].startHour.minute)) {
          evt[i].startHour.minute = 0;
          evt[i].startDate =
            evt[i].startHour.hour.toString().padStart(2, '0') +
            ':' +
            evt[i].startHour.minute.toString().padStart(2, '0');
          calculateDuration = true;
        }
      }

      if (isNaN(evt[i].endHour.hour)) {
        try {
          const hour = parseInt(evt[i].endDate);
          evt[i].endHour.hour = hour;
          evt[i].endHour.minute = 0;
          evt[i].endDate =
            evt[i].endHour.hour.toString().padStart(2, '0') +
            ':' +
            evt[i].endHour.minute.toString().padStart(2, '0');
        } catch (e) {
          evt[i].endHour.hour = 0;
          evt[i].endHour.minute = 0;
          evt[i].endDate =
            evt[i].endHour.hour.toString().padStart(2, '0') +
            ':' +
            evt[i].endHour.minute.toString().padStart(2, '0');
        }
        calculateDuration = true;
      } else {
        if (isNaN(evt[i].endHour.minute)) {
          evt[i].endHour.minute = 0;
          evt[i].endDate =
            evt[i].endHour.hour.toString().padStart(2, '0') +
            ':' +
            evt[i].endHour.minute.toString().padStart(2, '0');
          calculateDuration = true;
        }
      }

      if (evt[i].startDate.length < 5) {
        evt[i].startDate =
          evt[i].startHour.hour.toString().padStart(2, '0') +
          ':' +
          evt[i].startHour.minute.toString().padStart(2, '0');
        calculateDuration = true;
      }

      if (evt[i].endDate.length < 5) {
        evt[i].endDate =
          evt[i].endHour.hour.toString().padStart(2, '0') +
          ':' +
          evt[i].endHour.minute.toString().padStart(2, '0');
        calculateDuration = true;
      }

      if (calculateDuration) {
        evt[i].duration = ClockHour.getDifferenceHours(
          evt[i].endDate,
          evt[i].startDate
        );
      }
    }

    this.form.patchValue({
      histories: [...evt],
    });

    this.histories = evt;

    this.wrongTime = this.checkHistories();
    this.hasOverlapError = this.checkOverlapHistories();

    this.calculateTotalHoursClock(this.data);
  }

  addingNotes(evt: ClockHour.Note[]): void {
    this.form.patchValue({
      notes: [...evt],
    });
  }

  checkHistories(): boolean {
    if (!this.histories || this.histories.length == 0) {
      return false;
    }
    let wrongTime = false;
    this.equalTime = false;
    for (let i = 0; i < this.histories.length; i++) {
      if (this.histories[i].startHour.hour > this.histories[i].endHour.hour) {
        wrongTime = true;
        break;
      }
      if (
        this.histories[i].startHour.hour == this.histories[i].endHour.hour &&
        this.histories[i].startHour.minute > this.histories[i].endHour.minute
      ) {
        wrongTime = true;
        break;
      }
      if (
        this.histories[i].startHour.hour == this.histories[i].endHour.hour &&
        this.histories[i].startHour.minute == this.histories[i].endHour.minute
      ) {
        wrongTime = true;
        this.equalTime = true;
        this.toastService.show({
          text: this.i18n.instant('clockHour.timeTracking.error'),
          type: 'error',
          msDuration: 4000,
        });
        break;
      }
    }

    return wrongTime;
  }

  toMinutes = (hour: number, minute: number): number => hour * 60 + minute;

  areIntervalsOverlapping = (
    firstStart: number,
    firstEnd: number,
    secondStart: number,
    secondEnd: number
  ): boolean => {
    return firstStart < secondEnd && secondStart < firstEnd;
  };

  checkOverlapHistories(): boolean {
    if (!this.histories || this.histories.length < 2) {
      return false;
    }

    const intervals = this.histories.map((history) => ({
      startMinutes: this.toMinutes(
        history.startHour.hour,
        history.startHour.minute
      ),
      endMinutes: this.toMinutes(history.endHour.hour, history.endHour.minute),
    }));

    return intervals.some((interval, i) =>
      intervals
        .slice(i + 1)
        .some(({ startMinutes, endMinutes }) =>
          this.areIntervalsOverlapping(
            interval.startMinutes,
            interval.endMinutes,
            startMinutes,
            endMinutes
          )
        )
    );
  }

  submit(avoidClose = false): void {
    const isValid = this.checkValidators();
    if (!isValid) return;

    const data = this.fromFormValuesToServerValues();

    if (data.histories.length == 0) {
      this.toastService.show({
        text: this.i18n.instant('clockHour.timeTracking.error'),
        type: 'error',
        msDuration: 4000,
      });
      return;
    }

    if (this.wrongTime) return;

    this.loading = true;
    this.draw();

    if (this.mode === 'ADD') {
      this.resolveAddSubmit(avoidClose);
    } else {
      this.resolveEditSubmit(avoidClose);
    }
  }

  getClockHoursDuration(clockHour: ClockHour.Output): string {
    let hourDuration = ClockHour.getTotalHourFromClockHours([clockHour], 0);
    while (hourDuration.charAt(0) === '0') {
      hourDuration = hourDuration.substring(1);
    }
    return hourDuration.replace(':', 'hr ') + 'm';
  }

  private checkValidators(): boolean {
    ValidateAllFormFields(this.form);
    return this.form.valid;
  }

  private resolveAddSubmit(avoidClose = false): void {
    const data = this.fromFormValuesToServerValues();
    const eventData = {
      event: 'trackingTime_create',
    };

    this.sub$.add(
      this.clockHourService
        .create(data)
        .pipe(
          finalize(() => {
            this.loading = false;
            this.draw();
          })
        )
        .subscribe(
          (res) => {
            if (!avoidClose) {
              this.close(res);
            }
            this.toastService.show({
              text: this.i18n.instant('clockHour.createdClock'),
              type: 'success',
              msDuration: 4000,
            });
            this.amplitudeService.sendEvent(eventData);
            this.userflowService.sendEvent(eventData);
            this.brazeService.sendEvent(eventData);
          },
          (error) => {
            if (error.status == 409) {
              this.toastService.show({
                text: this.i18n.instant('pro.clockHour.errorQuantityRegister'),
                type: 'error',
                msDuration: 4000,
              });
            }
          }
        )
    );
  }

  private resolveEditSubmit(avoidClose = false): void {
    const data = this.fromFormValuesToServerValues();

    this.sub$.add(
      this.clockHourService
        .updateOne(this.id, data)
        .pipe(
          finalize(() => {
            this.loading = false;
            this.draw();
          })
        )
        .subscribe(
          (res) => {
            this.loading = false;
            this.toastService.show({
              text: this.i18n.instant('clockHour.editedClock'),
              type: 'success',
              msDuration: 4000,
            });
            // Sending event to amplitude
            this.amplitudeService.sendEvent({
              event: 'trackingTime_edit',
            });

            if (!avoidClose) {
              this.close(res);
            }
          },
          (error) => {
            if (error.status == 409) {
              this.toastService.show({
                text: this.i18n.instant('pro.clockHour.errorQuantityRegister'),
                type: 'error',
                msDuration: 4000,
              });
            }
          }
        )
    );
  }

  private fromFormValuesToServerValues(): ClockHour.Schema {
    const notes: ClockHour.Note[] = this.form.get('note').value
      ? [
          {
            _id: ``,
            text: this.form.get('note').value,
            createdAt: new Date(),
            idAuthor: StorageService.GetItem(USER_ID_KEY),
          },
        ]
      : [];

    const res: ClockHour.Schema = {
      idCreatedBy: this.form.get('idCreatedBy').value as string,
      idOwner: this.form.get('idOwner').value as string,
      idCompany: this.form.get('idCompany').value as string,
      date: ClockHour.fromStringDateToObjectDate(this.form.get('date').value),
      histories: this.form.get('histories').value as ClockHour.Histories,
      notes,
      deleted: false,
      approved: false,
      status: this.getStatus(),
    };

    return res;
  }

  private fromOutputParamToFormValue(params: ClockHour.Output) {
    return {
      ...params,
      date: addHours(
        new Date(params.date.year, params.date.month, params.date.day, 1),
        6
      )
        .toISOString()
        .split('T')[0],
    };
  }

  private attachParams(data: AddClockHour.Params) {
    this.mode = data.mode;
    this.dataParams = data.params;

    const day = data.params?.date.day || new Date().getDate();
    const month = data.params?.date.month || new Date().getMonth();
    const year = data.params?.date.year || new Date().getFullYear();

    this.lastDate = {
      year: year,
      month: month,
      day: day,
    };
    this.refreshToday(
      new Date(this.lastDate.year, this.lastDate.month, this.lastDate.day)
    );
    this.draw();

    if (this.data.idOwner !== StorageService.GetItem(USER_ID_KEY)) {
      this.form.get('idOwner').setValue(this.data.idOwner);
    }

    if (this.data?.params) {
      this.form.patchValue({
        ...this.fromOutputParamToFormValue(data.params),
      });

      if (this.data?.params?.notes?.length) {
        const [firstNote] = this.data.params.notes;

        this.form.patchValue({
          note: firstNote.text,
        });
      }

      this.histories = data.params.histories || [];
      this.id = data.params._id;
      this.calculateTotalHoursClock(data);
      this.resolveBlockEdition();
    } else {
      this.histories = [];
      this.form.patchValue({
        date: new Date().toISOString().split('T')[0],
      });
    }

    this.setDateValidators(data);

    this.draw();

    this.cdRef.markForCheck();
  }

  private setDateValidators(data: AddClockHour.Params): void {
    this.form.get('date').clearValidators();
    this.form.get('date').setValidators([Validators.required]);
    this.form
      .get('date')
      .setAsyncValidators([
        AddClockValidators.checkIfIsRepeat(
          this.clockHourService,
          data?.params?.idOwner || '',
          data?.params?._id || ''
        ),
      ]);
    this.form.get('date').updateValueAndValidity();
  }

  private calculateTotalHoursClock(data: AddClockHour.Params): void {
    if (data.mode === 'EDIT') {
      this.totalHoursClock = ClockHour.getTotalHourFromClockHours(
        [
          {
            histories: this.histories,
          },
        ],
        0
      );
      this.draw();
    }
  }

  private getStatus(): ClockHour.Status {
    if (this.mode === 'ADD') {
      return ClockHour.Status.Pending;
    }

    const user = this.authService.user;

    if (user.role.includes(TypeRol.ADMIN_ROLE)) {
      return ClockHour.Status.Pending;
    }

    if (this.dataParams.status === ClockHour.Status.Approved) {
      return ClockHour.Status.Approved;
    }

    return ClockHour.Status.Pending;
  }

  private resolveBlockEdition(): void {
    const user = this.authService.user;

    if (user.role.includes(TypeRol.USER_ROLE)) {
      const { status } = this.dataParams;

      if (status === ClockHour.Status.Approved) {
        this.blockEdition = true;
        this.draw();
      }
    }
  }

  private draw(): void {
    this.cdRef.detectChanges();
  }

  addDayToDate(date: Date): Date {
    const newDate = new Date(date);
    newDate.setTime(newDate.getTime() + 24 * 60 * 60 * 1000);
    return newDate;
  }

  async searchNextDay(nextDay: Date): Promise<clockHourPaginateResponse> {
    const date = {
      year: nextDay.getFullYear(),
      month: nextDay.getMonth(),
      day: nextDay.getDate(),
    };

    return await this.clockHourService
      .search({
        'idCompanies[]': [this.data.idCompany],
        'idOwners[]': [this.data.idOwner],
        from: JSON.stringify(date),
        to: JSON.stringify(date),
        limit: 1,
      })
      .toPromise();
  }

  getNextDay(date: { year: number; month: number; day: number }): Date {
    const nextDay = this.addDayToDate(
      new Date(date.year, date.month, date.day)
    );

    return nextDay;
  }

  concatHistories(
    oldHistories: ClockHour.Histories,
    newHistories: ClockHour.Histories
  ): ClockHour.Histories {
    if (oldHistories?.length === 0) {
      return newHistories;
    }

    if (newHistories?.length === 0) {
      return oldHistories;
    }

    const combinedHistories: ClockHour.Histories = [...oldHistories];

    newHistories.forEach((newHistory) => {
      const isDuplicate = oldHistories.some(
        (oldHistory) =>
          oldHistory.startDate === newHistory.startDate &&
          oldHistory.endDate === newHistory.endDate
      );

      if (!isDuplicate) {
        combinedHistories.push(newHistory);
      }
    });

    return combinedHistories;
  }

  async onSaveAndContinueClick(): Promise<void> {
    const isValid = this.checkValidators();
    if (!isValid || this.wrongTime) return;

    this.continueHistories = [...this.histories];
    this.submit(true);
    this.refreshOnClose = true;
    const nextDay = this.getNextDay(this.lastDate);
    const nextDayData = await this.searchNextDay(nextDay);
    this.initFormData({
      mode: nextDayData.docs[0]?._id ? 'EDIT' : 'ADD',
      idCompany: this.data.idCompany,
      idOwner: this.data.idOwner,
      params: {
        _id: nextDayData.docs[0]?._id,
        dateFormatted: nextDay,
        date: {
          year: nextDay.getFullYear(),
          month: nextDay.getMonth(),
          day: nextDay.getDate(),
        },
        histories: this.concatHistories(
          this.continueHistories,
          nextDayData.docs[0]?.histories || []
        ),
        idCompany: this.data.idCompany,
        idOwner: this.data.idOwner,
        averageHoursWorked: 0,
        idCreatedBy:
          nextDayData.docs[0]?.idCreatedBy ||
          StorageService.GetItem(USER_ID_KEY),
        deleted: false,
        approved: false,
        status: ClockHour.Status.Pending,
      },
    });
  }
}
