<div
  (click)="openWithImage()"
  class="image-container"
  *ngIf="src; else uploadFile"
>
  <img class="preview-image" [src]="src" alt="Company logo" />

  <mat-icon *ngIf="showIcon && src" class="absolute-center pointer edit-icon">
    <img src="assets/icons/gl_edit.svg" />
  </mat-icon>
</div>

<ng-template #uploadFile>
  <div class="text-icon-container">
    <input
      #fileInput
      id="file"
      type="file"
      (change)="openWithoutImage($event)"
      [disabled]="!canClick"
      dragAndDrop
      (fileDropped)="openWithoutImage($event)"
      accept="image/png, image/jpeg, image/jpg"
    />
    <img src="assets/icons/upload-file-icon.svg" alt="Upload file" />
    <p class="upload-text">{{ 'logo-crop.upload-button' | translate }}</p>
  </div>
</ng-template>
