<!-- Just download excel -->
<ng-container
  *ngIf="showDownloadExcel && !showDownloadPdf; else justDownloadPdf"
>
  <button
    romaProFeature
    class="btn-download"
    [disabled]="isLoading"
    [feature]="featureExcel"
    (clicked)="triggerDownload(type.EXCEL)"
    [ngClass]="{ budgets: style === 'budgets' }"
    [ngClass]="{ flex: flex }"
  >
    <img
      *ngIf="!isLoading && showDownloadIcon; else isLoading && spinner"
      class="download-icon"
      src="assets/icons/gl_download.svg"
    />
    <span>
      {{ 'general.downloadExcel' | translate }}
    </span>
    <img class="icon-star-pro-feature" src="assets/icons/star.svg" />

    <ng-template #spinner>
      <mat-progress-spinner
        class="loading-spinner"
        mode="indeterminate"
        [diameter]="14"
      ></mat-progress-spinner>
    </ng-template>
  </button>
</ng-container>

<ng-template #justDownloadPdf>
  <ng-container
    *ngIf="!showDownloadExcel && showDownloadPdf; else downloadBoth"
  >
    <button
      romaProFeature
      class="btn-download"
      [disabled]="isLoading"
      [feature]="featurePdf"
      (clicked)="triggerDownload(type.PDF)"
      [ngClass]="{ budgets: style === 'budgets' }"
    >
      <img
        *ngIf="!isLoading && showDownloadIcon; else isLoading && spinner"
        class="download-icon"
        src="assets/icons/gl_download.svg"
      />
      <span>
        {{ 'general.downloadPdf' | translate }}
      </span>
      <img class="icon-star-pro-feature" src="assets/icons/star.svg" />

      <ng-template #spinner>
        <mat-progress-spinner
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="14"
        ></mat-progress-spinner>
      </ng-template>
    </button>
  </ng-container>
</ng-template>

<ng-template #downloadBoth>
  <!-- Desktop -->
  <ng-container *ngIf="!(resize$ | async)?.payload?.mobile; else mobileView">
    <button
      *ngIf="
        !(hasFeaturePdf | async) && !(hasFeatureExcel | async);
        else mat_menu
      "
      romaProFeature
      class="btn-download"
      [disabled]="isLoading"
      [feature]="featureExcel"
      [ngClass]="{ budgets: style === 'budgets' }"
    >
      <img
        *ngIf="!isLoading && showDownloadIcon"
        class="download-icon"
        src="assets/icons/gl_download.svg"
      />
      <span>
        {{ 'general.downloadExcel' | translate }}
      </span>
      <img
        style="margin-right: 0"
        class="icon-star-pro-feature ml-7px"
        src="assets/icons/star.svg"
      />
    </button>
    <!--  -->

    <ng-template #mat_menu>
      <button
        class="btn-download"
        [matMenuTriggerFor]="menu"
        [disabled]="isLoading"
        #trigger="matMenuTrigger"
        [ngClass]="{ budgets: style === 'budgets' }"
      >
        <img
          *ngIf="!isLoading && showDownloadIcon; else isLoading && spinner"
          class="download-icon"
          src="assets/icons/gl_download.svg"
        />
        <span>
          {{ 'general.downloadExcel' | translate }}
        </span>
        <img
          *ngIf="showArrowIcon === 'down'"
          class="icon-arrow"
          src="assets/icons/gl_arrow_down.svg"
        />
        <img
          *ngIf="showArrowIcon === 'up'"
          class="icon-arrow"
          src="assets/icons/gl_arrow_up.svg"
        />

        <ng-template #spinner>
          <mat-progress-spinner
            class="loading-spinner"
            mode="indeterminate"
            [diameter]="14"
          ></mat-progress-spinner>
        </ng-template>
      </button>

      <mat-menu
        #menu="matMenu"
        class="download-documents"
        [xPosition]="xPosition"
        [yPosition]="yPosition"
      >
        <button
          *ngIf="showDownloadPdf"
          mat-menu-item
          (click)="triggerDownload(type.PDF)"
        >
          <span>{{ 'general.downloadPdf' | translate }}</span>
        </button>
        <button
          *ngIf="showDownloadExcel"
          mat-menu-item
          romaProFeature
          [feature]="featureExcel"
          (clicked)="triggerDownload(type.EXCEL)"
        >
          <span>{{ 'general.downloadExcel2' | translate }}</span>
          <img
            class="icon-star-pro-feature ml-7px pb-4px"
            src="assets/icons/star.svg"
          />
        </button>
      </mat-menu>
    </ng-template>
  </ng-container>
  <!-- Desktop -->

  <!-- Mobile -->
  <ng-template #mobileView>
    <button
      *ngIf="
        !(hasFeaturePdf | async) && !(hasFeatureExcel | async);
        else mobile_button
      "
      romaProFeature
      class="btn-download"
      [disabled]="isLoading"
      [feature]="featureExcel"
      [ngClass]="{ budgets: style === 'budgets' }"
    >
      <img
        *ngIf="!isLoading && showDownloadIcon"
        class="download-icon"
        src="assets/icons/gl_download.svg"
      />
      <span>
        {{ 'general.downloadExcel' | translate }}
      </span>
      <img
        style="margin-right: 0"
        class="icon-star-pro-feature ml-7px"
        src="assets/icons/star.svg"
      />
    </button>

    <ng-template #mobile_button>
      <button
        class="btn-download"
        (click)="openMobileMenu()"
        [ngClass]="{ budgets: style === 'budgets' }"
      >
        <img
          *ngIf="!isLoading && showDownloadIcon; else isLoading && spinner"
          class="download-icon"
          src="assets/icons/gl_download.svg"
        />
        <span>
          {{ 'general.downloadExcel' | translate }}
        </span>

        <ng-template #spinner>
          <mat-progress-spinner
            class="loading-spinner"
            mode="indeterminate"
            [diameter]="14"
          ></mat-progress-spinner>
        </ng-template>
      </button>
    </ng-template>
  </ng-template>
  <!-- Mobile -->
</ng-template>
