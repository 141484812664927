import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';

import { TranslateModule } from '@ngx-translate/core';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { DealCalendarComponent } from './deal-calendar.component';
import { LoadingModule } from '../loading';

@NgModule({
  declarations: [DealCalendarComponent],
  imports: [
    CommonModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    MatChipsModule,
    MatIconModule,
    TranslateModule.forChild(),
    LoadingModule,
  ],
  exports: [DealCalendarComponent],
})
export class DealCalendarModule {}
