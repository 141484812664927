import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectSequenceComponent } from './select-sequence.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nChild } from '@web-frontend/shared/i18n';
import { ModalTemplateModule } from '../modal-template';

@NgModule({
  declarations: [SelectSequenceComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    I18nChild,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
  ],
  exports: [SelectSequenceComponent],
})
export class SelectSequenceModule {}
