export const DealStatusColors = [
  {
    lightColor: '#E3EBFA',
    darkColor: '#6A79A0',
  },
  {
    lightColor: '#FFF1DB',
    darkColor: '#A36A00',
  },
  {
    lightColor: '#FDE8D7',
    darkColor: '#F57200',
  },
  {
    lightColor: '#DFF6E8',
    darkColor: '#247F47',
  },
  {
    lightColor: '#D4EAFC',
    darkColor: '#007BE0',
  },
  {
    lightColor: '#F3EFFF',
    darkColor: '#8861FA',
  },
  {
    lightColor: '#FAEEFC',
    darkColor: '#9723AE',
  },
  {
    lightColor: '#FFEBEB',
    darkColor: '#FF474E',
  },
  {
    lightColor: '#E9EAFD',
    darkColor: '#333DC9',
  },
  {
    lightColor: '#FFF1D9',
    darkColor: '#A36A00',
  },
  {
    lightColor: '#FEE7DA',
    darkColor: '#D66325',
  },
  {
    lightColor: '#E1F7EB',
    darkColor: '#066D38',
  },
  {
    lightColor: '#E8EDFF',
    darkColor: '#5D6B98',
  },
  {
    lightColor: '#C2E6F8',
    darkColor: '#006A9F',
  },
  {
    lightColor: '#FEEBED',
    darkColor: '#FF474E',
  },
];

export const GetDefaultColor = (color: string) => {
  const colors = DealStatusColors;

  if (!color) {
    return colors[0];
  }

  const currentColor = colors.find(
    (el) => el.lightColor.toLowerCase() === color?.toLowerCase()
  );

  if (currentColor) {
    return currentColor;
  }

  return colors[0];
};
