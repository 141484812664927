<div class="budget-category-header">
  <div class="rates-group__header">
    {{ conceptDescriptionPlaceholder | translate }}
  </div>

  <span *ngIf="resolveShowField(CategoryFields.QUANTITY)" class="quantity">
    {{ quantityPlaceholder | translate }}
  </span>

  <span *ngIf="resolveShowField(CategoryFields.UNIT)" [class]="getUnitClass()">
    {{ unitPlaceholder | translate }}
  </span>

  <span class="price">
    {{ pricePlaceholder | translate }}
  </span>

  <span *ngIf="resolveShowField(CategoryFields.DISCOUNT)" class="discount">
    {{ discountPlaceholder | translate }}
  </span>

  <span class="tax">
    {{ taxPlaceholder | translate }}
    <div class="custom-tooltip right">
      <img
        src="/assets/icons/gl_information-fill-gray.svg"
        alt="{{ 'budgets.settings.create.form.taxTooltip' | translate }} info"
      />
      <div class="tiptext tiptext-payments">
        {{ 'budgets.settings.create.form.taxTooltip' | translate }}
      </div>
    </div>
  </span>
  <span class="line_total">
    {{ totalPlaceholder | translate }}
  </span>

  <span class="close_button"></span>
</div>
