<div class="container-billing-data">
  <div class="title-card">
    {{ 'budgets.settings.title.preferences' | translate }}
  </div>

  <form [formGroup]="form">
    <div class="global-container">
      <div class="row h-100">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="form-group">
            <label class="lbl" for="currency">
              {{ 'budgets.settings.create.form.currency' | translate }}
            </label>
            <ng-select
              id="currency"
              bindValue="id"
              [items]="currencys"
              [multiple]="false"
              formControlName="currency"
              [loading]="false"
              [notFoundText]="'hiring.form.placeholder_notfound' | translate"
              [placeholder]="
                'budgets.settings.create.form.currency' | translate
              "
              [ngClass]="{ 'is-invalid': fieldCurrency?.displayError || false }"
              [virtualScroll]="true"
            >
              <ng-template ng-label-tmp let-item="item">
                <span >{{ generateLabel(item) }}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                {{generateLabel(item)}}
              </ng-template>
            </ng-select>

            <roma-field-error-display                                 
              #fieldCurrency
              fieldName="currency"
              [errorMsg]="'general.requiredField'"
              [form]="form"
            >
            </roma-field-error-display>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="form-group">
            <label class="lbl" for="format">
              {{ 'budgets.settings.create.form.number_format' | translate }}
            </label>
            <ng-select
              id="format"
              bindLabel="text"
              bindValue="id"
              [items]="numbers_format"
              [multiple]="false"
              formControlName="number_format"
              [loading]="false"
              [notFoundText]="'hiring.form.placeholder_notfound' | translate"
              [placeholder]="
                'budgets.settings.create.form.number_format' | translate
              "
              [notFoundText]="'hiring.form.placeholder_notfound' | translate"
              [ngClass]="{ 'is-invalid': fieldFormat?.displayError || false }"
            >
            </ng-select>

            <roma-field-error-display
              #fieldFormat
              fieldName="number_format"
              [errorMsg]="'general.requiredField'"
              [form]="form"
            >
            </roma-field-error-display>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="form-group">
            <label class="lbl" for="decimals">
              {{ 'budgets.settings.create.form.decimals' | translate }}
            </label>
            <ng-select
              id="decimals"
              bindLabel="text"
              bindValue="id"
              [items]="decimals"
              [multiple]="false"
              formControlName="decimals"
              [loading]="false"
              [notFoundText]="'hiring.form.placeholder_notfound' | translate"
              [placeholder]="
                'budgets.settings.create.form.decimals' | translate
              "
              [ngClass]="{ 'is-invalid': fieldDecimals?.displayError || false }"
            >
            </ng-select>

            <roma-field-error-display
              #fieldDecimals
              fieldName="decimals"
              [errorMsg]="'general.requiredField'"
              [form]="form"
            >
            </roma-field-error-display>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="form-group">
            <label class="lbl" for="zone">
              {{ 'budgets.settings.create.form.zone' | translate }}
            </label>
            <ng-select
              id="zone"
              [items]="zones"
              [multiple]="false"
              formControlName="zone"
              [loading]="false"
              [placeholder]="'budgets.settings.create.form.zone' | translate"
              [notFoundText]="'hiring.form.placeholder_notfound' | translate"
              [ngClass]="{ 'is-invalid': fieldZone?.displayError || false }"
            >
            </ng-select>

            <roma-field-error-display
              #fieldZone
              fieldName="zone"
              [errorMsg]="'general.requiredField'"
              [form]="form"
            >
            </roma-field-error-display>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <label class="lbl" for="language">
            {{ 'budgets.settings.create.form.languaje' | translate }}
          </label>
          <ng-select
            id="language"
            bindLabel="simbol"
            bindValue="id"
            [items]="langs"
            [multiple]="false"
            formControlName="language"
            [loading]="false"
            [placeholder]="'budgets.settings.create.form.languaje' | translate"
            [notFoundText]="'hiring.form.placeholder_notfound' | translate"
            [ngClass]="{ 'is-invalid': fieldLanguage?.displayError || false }"
          >
          </ng-select>

          <roma-field-error-display
            #fieldLanguage
            fieldName="language"
            [errorMsg]="'general.requiredField'"
            [form]="form"
          >
          </roma-field-error-display>
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="form-group">
            <label class="lbl" for="date">
              {{ 'budgets.settings.create.form.date_format' | translate }}
            </label>
            <ng-select
              id="date"
              bindLabel="text"
              bindValue="id"
              [items]="dates_formats"
              [multiple]="false"
              formControlName="date_format"
              [loading]="false"
              [notFoundText]="'hiring.form.placeholder_notfound' | translate"
              [placeholder]="
                'budgets.settings.create.form.date_format' | translate
              "
              [ngClass]="{ 'is-invalid': fieldDate?.displayError || false }"
            >
            </ng-select>

            <roma-field-error-display
              #fieldDate
              fieldName="date_format"
              [errorMsg]="'general.requiredField'"
              [form]="form"
            >
            </roma-field-error-display>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="form-group">
            <label class="lbl" for="calendarDay">
              {{ 'budgets.settings.create.form.calendarDay' | translate }}
            </label>
            <ng-select
              id="calendarDay"
              bindLabel="text"
              bindValue="id"
              [items]="days"
              [multiple]="false"
              formControlName="day"
              [loading]="false"
              [notFoundText]="'hiring.form.placeholder_notfound' | translate"
              [placeholder]="
                'budgets.settings.create.form.calendarDay' | translate
              "
            >
            </ng-select>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="form-group">
            <label class="lbl" for="message">
              {{ 'budgets.settings.create.form.message' | translate }}
            </label>
            <textarea
              class="form-control"
              placeholder=" {{
                'budgets.settings.create.form.message' | translate
              }}"
              id="message"
              maxlength="1200"
              style="resize: none"
              rows="3"
              formControlName="message"
            ></textarea>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="form-group">
            <button
              type="submit"
              class="custom-btn-save mt22"
              mat-button
              color="primary"
              (click)="submit()"
              [disabled]="form.pristine"
            >
              {{
                mode === 'ADD'
                  ? ('activity.create.submit' | translate)
                  : ('activity.edit.submit' | translate)
              }}
              <i class="fa fa-check"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
