import {
  IBIllBudget,
  IBudgetCommonData,
  PaginateResponse,
  TypeBudget,
  ISummaryTotals,
} from '@tacliatech/types';
import IInvoiceAdapter from '../types/invoice-adapter.type';
import InvoiceBudgetParams from '../types/invoice-budget-params.type';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { BudgetService } from '@web-frontend/shared/services/budgets';

@Injectable({
  providedIn: 'root',
})
export default class InvoiceLegacyAdapter implements IInvoiceAdapter {
  constructor(
    private http: HttpClient,
    private readonly budgetService: BudgetService
  ) {}

  sendEmail(body): Promise<any> {
    throw new Error('Method not implemented.');
  }

  getTaxRegimeList?(): Promise<{ id: string; description: string }[]> {
    throw new Error('Method not implemented.');
  }

  findPdf(params: Record<string, any>): Promise<any> {
    return this.budgetService.seeDocument(params).toPromise();
  }

  findOne(id: string): Promise<IBIllBudget> {
    return this.http
      .get<IBIllBudget>(`:API_URL/budgets/bills/${id}`)
      .toPromise();
  }

  async create(params: InvoiceBudgetParams): Promise<void> {
    const typeEndpoints = {
      [TypeBudget.BUDGET]: `:API_URL/budgets/budget-new/`,
      [TypeBudget.PROFORM]: `:API_URL/proforms/new/`,
      [TypeBudget.BILL]: `:API_URL/billsb/new/`,
      [TypeBudget.WAL]: `:API_URL/waybillsb/new/`,
    };

    const endpoint = typeEndpoints[params.type];

    if (endpoint) {
      return this.http.post<any>(endpoint, params).toPromise();
    }
  }

  async createV2(params: InvoiceBudgetParams): Promise<HttpResponse<any>> {
    const typeEndpoints = {
      [TypeBudget.BUDGET]: `:API_URL/v2/budgets/`,
      [TypeBudget.PROFORM]: `:API_URL/v2/proforms/`,
      [TypeBudget.BILL]: `:API_URL/v2/bills/`,
      [TypeBudget.WAL]: `:API_URL/v2/waybills/`,
    };

    const endpoint = typeEndpoints[params.type];

    if (endpoint) {
      return this.http
        .post<HttpResponse<any>>(endpoint, params, { observe: 'response' })
        .toPromise();
    }
    return null;
  }

  async update(
    params: InvoiceBudgetParams & { _id?: string; duplicate: boolean }
  ): Promise<void> {
    if (params.duplicate) {
      delete params._id;
      return this.create(params);
    } else {
      const updateEndpoints = {
        [TypeBudget.BUDGET]: `:API_URL/budgets/budget-new/${params._id}`,
        [TypeBudget.PROFORM]: `:API_URL/proforms/new/${params._id}`,
        [TypeBudget.BILL]: `:API_URL/billsb/new/${params._id}`,
        [TypeBudget.WAL]: `:API_URL/waybillsb/new/${params._id}`,
      };
      const endpoint = updateEndpoints[params.type];
      if (endpoint) {
        return this.http.put<any>(endpoint, params).toPromise();
      }
    }
  }

  async updateV2(
    params: InvoiceBudgetParams & { _id?: string; duplicate: boolean }
  ): Promise<HttpResponse<any>> {
    if (params.duplicate) {
      delete params._id;
      return this.createV2(params);
    }

    const updateEndpoints = {
      [TypeBudget.BUDGET]: `:API_URL/v2/budgets/${params._id}`,
      [TypeBudget.PROFORM]: `:API_URL/v2/proforms/${params._id}`,
      [TypeBudget.BILL]: `:API_URL/v2/bills/${params._id}`,
      [TypeBudget.WAL]: `:API_URL/v2/waybills/${params._id}`,
    };
    const endpoint = updateEndpoints[params.type];
    if (endpoint) {
      return this.http
        .put<HttpResponse<any>>(endpoint, params, { observe: 'response' })
        .toPromise();
    }
    return null;
  }

  delete(id: string, type: TypeBudget): Promise<any> {
    return this.http
      .delete<IBudgetCommonData>(`:API_URL/budgets/${type}/${id}`)
      .toPromise();
  }

  deleteV2(id: string, type: TypeBudget): Promise<any> {
    const deleteEndpoings = {
      [TypeBudget.BUDGET]: `:API_URL/v2/budgets/${id}`,
      [TypeBudget.PROFORM]: `:API_URL/v2/proforms/${id}`,
      [TypeBudget.BILL]: `:API_URL/v2/bills/${id}`,
      [TypeBudget.WAL]: `:API_URL/v2/waybills/${id}`,
    };
    const endpoint = deleteEndpoings[type];
    return this.http.delete<IBudgetCommonData>(endpoint).toPromise();
  }

  async find(type: TypeBudget, query?: any): Promise<any> {
    const params = new HttpParams({
      fromObject: {
        ...query,
        type: type,
      },
    });

    return await this.http
      .get<PaginateResponse<IBudgetCommonData[]>>(`:API_URL/budgets/search`, {
        params,
      })
      .toPromise();
  }

  async findSummary(
    type: TypeBudget,
    query?: Record<string, any>
  ): Promise<ISummaryTotals> {
    const params = new HttpParams({
      fromObject: {
        ...query,
      },
    });
    return await this.http
      .get<ISummaryTotals>(`:API_URL/billsb/summary`, {
        params,
      })
      .toPromise();
  }
}
