import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CreateRefundService } from './services/create-refund.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'roma-create-refund',
  templateUrl: './create-refund.component.html',
  styleUrls: ['./create-refund.component.scss'],
})
export class CreateRefundComponent {
  public form: FormGroup = new FormGroup({
    ticketId: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]),
  });

  isLoading = false;
  refund = null;

  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private refundsService: CreateRefundService
  ) {}

  ngOnInit(): void {}

  back() {
    this.router.navigateByUrl('/admin/payments-prototype');
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  onSubmit() {
    if (this.form.invalid) return;

    this.isLoading = true;
    this.refundsService
      .createRefund(this.form.value.ticketId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.draw();
        })
      )
      .subscribe((res) => {
        this.refund = res;
        this.draw();
      });
  }
}
