import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePipe } from './image.pipe';
import { ImageFirebasePipe } from './image-firebase.pipe';

@NgModule({
  declarations: [ImagePipe, ImageFirebasePipe],
  imports: [CommonModule],
  exports: [ImagePipe, ImageFirebasePipe],
})
export class ImageModule {
  static forRoot() {
    return {
      ngModule: ImageModule,
      providers: [],
    };
  }
}
