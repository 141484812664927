import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TacliaPayments } from '@tacliatech/types';
import { PaymentsService } from '../services/payments.service';
import { finalize } from 'rxjs/operators';
import { OnboardingModalService } from '../services/onboarding-modal.service';
import { Subscription } from 'rxjs';
import { AuthService, SandboxService } from '@web-frontend/shared/services';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import AmplitudeEvents from 'src/types/amplitude.enum';

@Component({
  selector: 'roma-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss'],
})
export class PaymentStatusComponent implements OnInit, OnDestroy {
  @Input()
  status: TacliaPayments.StatusAccount;

  @Input()
  linkOnboarding: string;

  @Input()
  showCloseBtn = false;

  sub$: Subscription = new Subscription();

  @Output()
  closedButton = new EventEmitter<boolean>();
  StatusAccount: TacliaPayments.StatusAccount;
  readonly StatusAccounteEnumRef = TacliaPayments.StatusAccount;
  alreadyOpen = false;

  isLoadingGetNewLink = false;
  resize$ = this.sandBoxService.screenBusChannel$;
  isLandscapeMode: boolean;

  constructor(
    private paymentService: PaymentsService,
    private cdRef: ChangeDetectorRef,
    private onboardingModalService: OnboardingModalService,
    private sandBoxService: SandboxService,
    private analyticsService: AnalyticsService,
    private authService: AuthService
  ) {
    this.watchLinkEvent();
  }

  ngOnInit(): void {
    // console.log('linkOnboarding ngOnInit ', this.linkOnboarding);
    this.watchLandscapeMode();
    if (
      this.status === TacliaPayments.StatusAccount.NOT_STARTED ||
      this.status === TacliaPayments.StatusAccount.NOT_COMPLETED ||
      this.status === TacliaPayments.StatusAccount.SUSPENDED ||
      this.status === TacliaPayments.StatusAccount.APPROVED_WITH_WARNING
    ) {
      this.isAdmin.subscribe((data) => {
        if (data === true) {
          this.findLoginLink();
        } else {
          this.isLoadingGetNewLink = false;
          this.linkOnboarding = '1';
        }
      });
    } /*  else if (
      this.status === TacliaPayments.StatusAccount.NOT_STARTED
      // ||
      // this.status === TacliaPayments.StatusAccount.NOT_COMPLETED
    ) {
      this.findLoginLink(true);
    } */

    // this.watchModalClose();
  }

  sendHelpEvent() {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.payment_help,
    });
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  watchLandscapeMode() {
    this.sub$.add(
      this.sandBoxService.screenBusChannel$.subscribe(() => {
        this.isLandscapeMode = window.innerWidth > window.innerHeight;
      })
    );
  }

  watchLinkEvent() {
    this.sub$.add(
      this.paymentService.linkOnboarding$.subscribe((link: string) => {
        this.linkOnboarding = link;
        // console.log('linkOnboarding en observable ', this.linkOnboarding);
      })
    );
  }

  watchModalClose() {
    this.sub$.add(
      this.onboardingModalService.refreshOnboardingLinkFromModal$.subscribe(
        (isClosed) => {
          if (isClosed) {
            this.isLoadingGetNewLink = true;
            this.draw();
            this.findLoginLink();
          }
        }
      )
    );
  }

  draw() {
    this.cdRef.detectChanges();
  }

  closeModal() {
    this.closedButton.emit(true);
  }

  hasValue(type: TacliaPayments.StatusAccount) {}

  // openLink() {
  //   console.log('openLink', this.linkOnboarding);
  //   window.open(this.linkOnboarding);
  //   // this.linkOnboarding = null;
  //   this.findLoginLink();
  // }

  get isAdmin() {
    return this.authService.isAdmin$;
  }

  findLoginLink(open = false) {
    this.isLoadingGetNewLink = true;
    this.sub$.add(
      this.paymentService
        .refreshOnboardingLink()
        .pipe(
          finalize(() => {
            this.isLoadingGetNewLink = false;
            this.draw();
          })
        )
        .subscribe(
          (link) => {
            this.linkOnboarding = link?.url;
            this.isLoadingGetNewLink = false;
            this.draw();
            /*   if (open && !this.alreadyOpen) {
          window.open(this.linkOnboarding);
          // this.linkOnboarding = null;
          this.alreadyOpen = true;
        } */
          },
          (error) => {
            console.log(error);
            this.isLoadingGetNewLink = false;
            this.draw();
          }
        )
    );
  }
}
