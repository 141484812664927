import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'roma-unbind-deal-sale-modal',
  templateUrl: './unbind-deal-sale-modal.component.html',
  styleUrls: ['./unbind-deal-sale-modal.component.scss'],
})
export class UnbindDealSaleModalComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<UnbindDealSaleModalComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  close(params = { confirm: false }) {
    this.dialogRef.close(params);
  }

  submit() {
    this.close({ confirm: true });
  }
}
