import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';

import { NgSelectModule } from '@ng-select/ng-select';

import { ModalTemplateModule } from '../modal-template';
import { FieldErrorDisplayModule } from '../field-error-display';
import { AppendCustomPropertiesModule } from '../append-custom-properties';
import { EditCustomPropertiesModule } from '../edit-custom-properties';
import { CreateCatalogueComponent } from './create-catalogue.component';
import { CreateCatalogueService } from './create-catalogue.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { SelectLongDataFilteredModule } from '../select-long-data-filtered/select-long-data-filtered.module';
import { TooltipModule } from '@web-frontend/shared/directives/tooltip/tooltip.module';
import { ObligatoryFieldSignModule } from '../obligatory-field-sign/obligatory-field-sign.module';
import { BaseButtonModule } from '@web-frontend/common/components/button/baseButton.module';
import { HourInputModule } from '@web-frontend/common/components/hour-input/hour-input.module';

@NgModule({
  declarations: [CreateCatalogueComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    MatDialogModule,
    MatIconModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatCheckboxModule,
    MatButtonModule,
    FieldErrorDisplayModule,
    AppendCustomPropertiesModule,
    EditCustomPropertiesModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    ClickDeleteModule,
    SelectLongDataFilteredModule,
    TooltipModule,
    ObligatoryFieldSignModule,
    BaseButtonModule,
    HourInputModule,
  ],
  exports: [CreateCatalogueComponent],
  providers: [CreateCatalogueService],
})
export class CreateCatalogueModule {}
