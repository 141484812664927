<!-- Owners Modal -->
<div class="list-modal">
  <h1>{{ 'task.owners' | translate }}</h1>
  <img
    mat-dialog-close
    width="32px"
    height="32px"
    class="close"
    src="assets/icons/gl_close.svg"
  />
  <div class="items-list">
    <div class="list-item" *ngFor="let item of data">
      <img
        [src]="item.img ? firebaseURL + item?.['img'] : '/assets/icons/table-user-icon.svg'"
      />
      {{ item.name }}
    </div>
  </div>
</div>
