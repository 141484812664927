<div class="snackbar-container">
  <div
    class="snackbar"
    [ngStyle]="{
      visibility: displaySnackbar ? 'visible' : 'hidden',
      opacity: displaySnackbar ? 1 : 0
    }"
    [ngClass]="type"
  >
    <img [src]="icon" alt="" />
    <span>{{ message | translate }}</span>
    <mat-icon (click)="close()" class="close">close</mat-icon>
  </div>
</div>
