import { NgModule } from '@angular/core';
import { TotalSummaryComponent } from './total-summary.component';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyFormatModule } from '../../../../shared/pipes/currency-format/currency-format.module';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [TotalSummaryComponent],
  imports: [
    TranslateModule.forChild(),
    CurrencyFormatModule,
    MatIconModule,
    CommonModule,
  ],
  exports: [TotalSummaryComponent],
})
export class TotalSummaryModule {}
