import { Injectable } from '@angular/core';
import { PlatformService } from '@web-frontend/shared/components/platform-disable-content/platform.service';

import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';

@Injectable({
  providedIn: 'root',
})
export class IosPermissionService {
  private allowTracking = true;

  constructor(private platformService: PlatformService) {}

  public getPrivacyPermissionStatus(): Promise<AppTrackingStatusResponse> {
    return AppTrackingTransparency.getStatus();
  }

  public async requestPrivacyPermission(): Promise<void> {
    if (this.platformService.isIos()) {
      const response = await AppTrackingTransparency.requestPermission();
      this.allowTracking =
        response.status === 'authorized' || response.status === 'notDetermined';
    }
  }

  public canTrackEvents(): boolean {
    return (
      !this.platformService.isIos() ||
      (this.platformService.isIos() && this.allowTracking)
    );
  }
}
