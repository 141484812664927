import { IPovTab } from '../interfaces';

export class PovTab {
  type: string;
  columns: IPovTab[] = [];

  constructor(t?: string, col?: IPovTab[]) {
    this.type = t;
    this.columns = col;
  }

  getColumns() {
    return this.columns;
  }

  setColumns(col) {
    this.columns = col;
  }
}
