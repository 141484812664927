import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { PermissionService } from '../../services/permissions';
import { CompanyModuleService } from '../../services/company';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SettingsGuard implements CanActivate {
  private isInitialNavigation = true;

  constructor(
    private readonly router: Router,
    private readonly permissionService: PermissionService,
    private readonly companyModuleService: CompanyModuleService,
    private toastService: ToastService,
    private i18n: TranslateService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    const featureUser = route.data['featureUser'];
    const canLoad = this.permissionService.permissions.includes(featureUser);

    const isFirstLoad =
      this.router.navigated === false && this.isInitialNavigation;

    if (!canLoad) {
      this.toastService.show({
        text: this.i18n.instant('permissions.errors.permissionStr'),
        type: 'error',
        msDuration: 5000,
      });
      if (!isFirstLoad) {
        return false;
      }
      this.companyModuleService.goFirstRouteActive();
    }

    this.isInitialNavigation = false;
    return canLoad;
  }
}
