import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IBudgetsSetting, TacliaPayments as payments } from '@tacliatech/types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PaymentsService } from '../services/payments.service';
import Stripe from 'stripe';
import { StorageService } from '@web-frontend/shared/services';
import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';

@Component({
  selector: 'roma-payment-onbarding-process',
  templateUrl: './payment-onbarding-process.component.html',
  styleUrls: ['./payment-onbarding-process.component.scss'],
})
export class PaymentOnbardingProcessComponent implements OnInit {
  step1 = false;
  step2 = false;
  step3 = false;

  statusInprocess: payments.Onboarding = {
    status: payments.StatusAccount.PENDING,
    statusDetail: 'PENDING',
  };
  statusRejected: payments.Onboarding = {
    status: payments.StatusAccount.REJECTED,
    statusDetail: 'REJECTED',
  };

  statusNotCompleted: payments.Onboarding = {
    status: payments.StatusAccount.NOT_COMPLETED,
    statusDetail: 'NOT_COMPLETED',
  };

  readonly StatusAccounteEnumRef = payments.StatusAccount;

  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    public data: payments.CreditCard.ModalRequest,
    private paymentsService: PaymentsService,
    private dialogRef: MatDialogRef<PaymentOnbardingProcessComponent>,
    private analyticsService: AnalyticsService
  ) {}

  gotoPayments() {
    this.dialogRef.close();
    //this.router.navigateByUrl('/admin/payments');
  }

  ngOnInit(): void {
    this.step1 = true;
    this.step2 = false;
  }

  goToStep1() {
    this.step1 = true;
    this.step2 = false;
  }

  goToStep2() {
    this.step1 = false;
    this.step2 = true;
  }
  createOnboarding() {
    this.step1 = false;
    this.step2 = false;
    this.step3 = true;

    // Use this property to get the company data and business_type
    const companyData: IBudgetsSetting =
      JSON.parse(StorageService.GetItem('companyData')) || null;

    this.paymentsService.sendAcountCreatedEventStatus(this.statusNotCompleted);
    this.paymentsService
      .createConnectedAcount(companyData.data_account?.business_type)
      .subscribe(
        (stripeLink: Stripe.Response<Stripe.AccountLink>) => {
          this.analyticsService.trackEvent({
            sources: ['braze', 'userflow'],
            eventName: BrazeEventType.payment_onboarding_pending,
          });
          this.paymentsService.sendLink(stripeLink.url);
          //this.openPageOnboarding(stripeLink);
          this.gotoPayments();
        },
        (error) => {
          this.analyticsService.trackEvent({
            sources: ['braze', 'userflow'],
            eventName: BrazeEventType.payment_onboarding_rejected,
          });
          if (
            error?.error?.message ===
            'This company has already a connected account of Stripe'
          ) {
            this.paymentsService.sendAcountCreatedEventStatus(
              this.statusNotCompleted
            );
          } else {
            this.paymentsService.sendAcountCreatedEventStatus(
              this.statusRejected
            );
          }
        },
        () => {
          this.analyticsService.trackEvent({
            sources: ['braze', 'userflow'],
            eventName: BrazeEventType.payment_onboarding_incomplete,
          });
          this.paymentsService.sendAcountCreatedEventStatus(
            this.statusNotCompleted
          );
        }
      );
  }

  openPageOnboarding(link: Stripe.Response<Stripe.AccountLink>) {
    window.open(link.url, '_blank');
  }
}
