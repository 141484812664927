<div *ngIf="isOpen" class="mobile-viewtype-wrapper">
  <div class="viewtype-list">
    <h4>
      {{ titleText }}
      <div
        *ngIf="showAddButton"
        class="list-item add-item"
        (click)="addElementHandle($event); scrollToTop(); _searchValue = ''"
      >
        <img src="/assets/icons/gl_plus.svg" />
        {{ addButtonText | translate }}
      </div>
    </h4>
    <mat-progress-spinner
      *ngIf="isLoading"
      class="loading-spinner"
      mode="indeterminate"
      [diameter]="40"
      style="margin: auto"
    ></mat-progress-spinner>
    <div class="search-input" *ngIf="!isLoading">
      <img class="left" src="assets/icons/gl_search.svg" />
      <input
        #inputFilterSearch
        class="filter-input"
        type="text"
        [value]="_searchValue || ''"
        [placeholder]="'clockHour.inputPlaceholder' | translate"
        (keyup)="onKeySearch($event)"
      />
    </div>
    <div #itemsList class="link-list" *ngIf="!isLoading">
      <ng-container *ngFor="let item of _items">
        <div
          class="list-item"
          (click)="selectItem(item)"
          *ngIf="
            _searchValue
              ? item.title.toLowerCase().includes(_searchValue.toLowerCase())
              : true
          "
        >
          <!--Taxes-->
          <div *ngIf="item?.translate; else rates">
            {{ item.translate | translate }} {{ item.value }}%
          </div>
          <!--Taxes-->

          <!--Rates-->
          <ng-template #rates>
            <span class="icon-text"> {{ item?.title }} </span>
          </ng-template>

          <div class="item-price" *ngIf="item.price">
            {{ item.price || 0 | currencyFormat }}
          </div>

          <!--Rates-->
          <img
            *ngIf="checkSelectedItem(item)"
            class="prev-icon"
            src="/assets/icons/ic_check.svg"
            alt="link"
            style="left: 5px; top: 20px"
          />
        </div>
      </ng-container>
    </div>
    <div class="text-center close-btn-wrapper">
      <img
        (click)="_searchValue = ''; onClose.next()"
        class="close-btn"
        src="/assets/icons/btn_close.svg"
        alt="close"
      />
    </div>
  </div>
</div>

<div
  *ngIf="selectedItems.length && showSelectedItems"
  class="active-items-list"
>
  <div *ngFor="let item of selectedItems" class="active-item">
    <!--Taxes-->
    <div *ngIf="item?.translate; else rates">
      {{ item.translate | translate }} {{ item.value }}%
    </div>
    <!--Taxes-->

    <!--Rates-->
    <ng-template #rates>
      <span class="icon-text"> {{ item?.title }} </span>
    </ng-template>

    <img
      class="clean-input"
      src="assets/icons/gl_close-input.svg"
      (click)="selectItem(item)"
    />
  </div>
</div>
