import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CUSTOMER_ID_KEY,
  DealType,
  HiringRegisterDto,
  IHiringRegisterDto,
} from '@tacliatech/types';
import { tap } from 'rxjs/operators';
import { DealService } from '../deals';
import { StorageService } from '../storage';
import { USER_ID_KEY } from '@web-frontend/shared/constants';

@Injectable({
  providedIn: 'root',
})
export class HiringService {
  constructor(private http: HttpClient, private dealService: DealService) {}

  public createOne(data: HiringRegisterDto) {
    return this.http
      .post<HiringRegisterDto>(`:API_URL/hiring/`, data, {
        headers: new HttpHeaders({
          'X-API-Version': '2',
        }),
      })
      .pipe(
        tap(() => {
          this.dealService.init();
        })
      );
  }

  public createExampleDeal() {
    const current = new Date();

    current.setHours(0, 0, 0, 0);

    const initDate = new Date(
      Date.UTC(
        current.getFullYear(),
        current.getMonth(),
        current.getDate(),
        current.getHours(),
        current.getMinutes(),
        current.getSeconds(),
        current.getMilliseconds()
      )
    );

    const hiringRegisterDto: IHiringRegisterDto = {
      dealContactInfo: {
        createdBy: StorageService.GetItem(USER_ID_KEY),
        contactName: '',
        contactPhone: '',
      },
      // @ts-ignore
      dealDetailInfo: {
        title: 'general.example_deal',
        type: DealType.Team,
        name: null,
        project_id: null,
        product_id: null,
        description: null,
        jobDescription: null,
        vertical_id: null,
        urgency: false,
        imgs: [],
        files: [],
        notes: [],
        stage: null,
        quantity: null,
      },
      // @ts-ignore
      dealProfileInfo: {
        owner: StorageService.GetItem(USER_ID_KEY),
        customer: StorageService.GetItem(CUSTOMER_ID_KEY),
        taskers: [StorageService.GetItem(USER_ID_KEY)],
        asset: null,
        vendor: null,
        reporter: null,
        members: [],
        billingProfile: null,
      },
      // @ts-ignore
      dealTemporalInfo: {
        timeStatus: undefined,
        offsetTimeStatus: 0,
        visitation: false,
        visitationDate: undefined,
      },
      dealLocationInfo: {
        city: null,
        address: null,
        address2: null,
        latitude: null,
        longitude: null,
      },
    };

    return this.http
      .post<HiringRegisterDto>(`:API_URL/hiring/`, hiringRegisterDto)
      .pipe(
        tap(() => {
          this.dealService.init();
        })
      );
  }
}
