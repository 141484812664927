<h1>Enlace de pago</h1>

<div>
  <form
    *ngIf="!paymentLink; else paymentButton"
    [formGroup]="paymentLinkForm"
    (ngSubmit)="onSubmit()"
    class="form-group mx-auto"
    style="max-width: 35%"
  >
    <label for="price">Precio: </label>
    <input
      id="price"
      type="number"
      min="0"
      formControlName="price"
      class="form-control"
    />

    <label for="quantity">Cantidad: </label>
    <input
      id="quantity"
      type="number"
      min="1"
      formControlName="quantity"
      class="form-control"
    />

    <label for="name" class="mt-3">Nombre: </label>
    <input id="name" type="text" formControlName="name" class="form-control" />

    <label for="ticketId" class="mt-3">Código de ticket: </label>
    <input
      id="ticketId"
      type="text"
      formControlName="ticketId"
      class="form-control"
    />

    <div class="d-flex justify-content-center">
      <button
        *ngIf="!isLoading; else spinner"
        type="submit"
        class="roma-button h-50 mt-3 mx-3"
        [disabled]="isLoading"
        [ngClass]="{ 'disable-button': isLoading }"
      >
        Generar enlace de cobro
      </button>

      <ng-template #spinner>
        <mat-progress-spinner
          *ngIf="isLoading"
          class="loading-spinner mt-3"
          mode="indeterminate"
          [diameter]="20"
        ></mat-progress-spinner>
      </ng-template>
    </div>
  </form>

  <!-- Payment link -->

  <ng-template #paymentButton>
    <button
      type="button"
      (click)="shareLink()"
      class="rounded-button share-button"
    >
      <img src="assets/icons/gl_link-black.svg" />
      {{ 'general.shareLink' | translate }}
    </button>
  </ng-template>
</div>

<hr />
<div class="space"></div>
<div class="row col-3">
  <button class="roma-button h-50" (click)="back()">Volver</button>
</div>
