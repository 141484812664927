import { RecurrenceGlobal } from '@tacliatech/types';

export const DefaultPeriodicity = (date: Date): RecurrenceGlobal.Storage => {
  return {
    periodicity: 'WEEK',
    repeatEach: 1,
    dayToRepeat: [date.getDay()],
    date,
  };
};

export interface CreatePeriodicityParams {
  type: 'ADD' | 'EDIT';
  params: {
    date: Date;
    recurrence?: RecurrenceGlobal.Storage;
    recurrenceEnd?: RecurrenceGlobal.RecurrenceEnd;
  };
}
