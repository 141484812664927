import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { LogoCropService } from '../budget-logo/logo-crop.service';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { Subscription } from 'rxjs';
import { Feature } from '@tacliatech/types';
import { TranslateService } from '@ngx-translate/core';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import { BudgetLogoService } from '../../services/budget-logo';

@Component({
  selector: 'roma-picture-v2',
  templateUrl: './picture-v2.component.html',
  styleUrls: ['./picture-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PictureV2Component implements OnDestroy {
  @ViewChild('fileInput')
  fileInput: ElementRef<HTMLInputElement> | undefined;

  @Input()
  src = '';

  @Input()
  @HostBinding('class.is-xs')
  isXs = false;

  @Input()
  @HostBinding('class.is-md')
  isMd = false;

  @Input()
  @HostBinding('class.is-lg')
  isLg = false;

  @Input()
  @HostBinding('class.rounded')
  rounded = true;

  @Input()
  @HostBinding('class.can-click')
  canClick = true;

  @Input()
  multiSelect = false;

  @Input()
  isLoading = false;

  @Input()
  showIcon = true;

  @Output()
  selectFiles = new EventEmitter<FileList>();

  previewFile: string | ArrayBuffer = '';

  @Input()
  id = '';

  @Input()
  injectModal = true;

  @Output()
  emitEvent = new EventEmitter<Event>();

  @Input()
  rawLogo = '';

  @Input()
  settings: any;

  sub$ = new Subscription();

  featureRef = Feature;

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private logoCropService: LogoCropService,
    private toastService: ToastService,
    private i18n: TranslateService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  openInputExternal() {
    this.fileInput.nativeElement.click();
  }

  handleImageFile(files: FileList) {
    this.previewUrl(files);
    this.selectFiles.next(files);
  }

  private previewUrl(files: FileList) {
    this.isLoading = true;
    if (!this.multiSelect) {
      const file = files.item(0);

      const reader = new FileReader();

      reader.onload = () => {
        this.previewFile = reader.result;
        this.isLoading = false;
        this.draw();
      };

      reader.readAsDataURL(file);
    }
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  async openWithoutImage(event: Event) {
    let changeEvent: Event;

    if (event.type === 'drop') {
      changeEvent = new Event('change', { bubbles: true });
      Object.defineProperty(changeEvent, 'target', {
        writable: false,
        value: { files: event?.['dataTransfer']?.files },
      });
    } else {
      changeEvent = event;
    }

    try {
      const file: File = changeEvent?.target?.['files']?.[0];
      await this.logoCropService.allowedDimensions(file);
    } catch (error) {
      if (typeof error === 'string') {
        this.toastService.show({
          text: this.i18n.instant(error),
          type: 'error',
          msDuration: 4000,
          icon: 'assets/icons/kanban/delete.svg',
        });
      }

      return;
    }

    if (this.injectModal) {
      if (this.canClick && this.id && changeEvent) {
        const config = {
          data: { logo: this.src, id: this.id, changeImageEvent: changeEvent },
        };
        this.sub$.add(this.logoCropService.open(config).subscribe());
        this.analyticsService.trackEvent({
          sources: ['amplitude'],
          eventName: AmplitudeEvents.settings_editLogo,
        });
      }
    } else {
      this.emitEvent.emit(changeEvent);
    }
  }

  openWithImage() {
    if (this.canClick && this.id) {
      const config = {
        data: {
          logo: this.src,
          id: this.id,
          rawLogo: this.rawLogo,
          settings: this.settings,
        },
      };

      this.sub$.add(this.logoCropService.open(config).subscribe());
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: AmplitudeEvents.settings_editLogo,
      });
    }
  }
}
