<div class="mat-dialog-header">
  <img
    *ngIf="data.showCancelX"
    (click)="exit()"
    width="24px"
    height="24px"
    src="assets/icons/gl_close.svg"
    class="mat-dialog-header_close"
  />
</div>
<div mat-dialog-content>
  <img
    *ngIf="data.icon"
    [src]="data.icon"
    class="mat-dialog-content_icon"
  />
  <h1 *ngIf="data.title" class="mat-dialog-content_title" [innerHTML]="data.title | translate">
  </h1>
  <p
    *ngIf="data.body"
    class="mat-dialog-content_body"
    [innerHTML]="data.body | translate"
  >
  </p>
</div>
<div mat-dialog-actions>
  <button *ngIf="data.showCancel" class="cancel" mat-button (click)="onNoClick()">
    {{data.cancelLabel ??  'confirmationModal.cancel' | translate  }}
  </button>
  <button
    [class]="data.confirmClass"
    mat-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    (click)="execute()"
  >
    {{data.confirmLabel ??  'confirmationModal.confirm' | translate  }}
  </button>
</div>
