<roma-basic-snackbar
  [message]="'budgets.settings.create.form.update-contact-warning'"
  [type]="'error'"
  [icon]="'gl_danger-icon.svg'"
  [displaySnackbar]="snackbar"
></roma-basic-snackbar>
<h3>{{ title | translate }}</h3>
<ng-container [formGroup]="formHeader">
  <fieldset class="budget-header" formGroupName="budgetHeader">
    <div
      romaProSelect
      class="form-field contact wrapper-pro-feature-user"
      [featureUser]="featureRefUser.Final.show"
    >
      <label for="contact"
        >{{ contactPlaceholder | translate
        }}<obligatory-field-sign></obligatory-field-sign
      ></label>
      <rm-select-register
        [isClearable]="true"
        [placeholderText]="selectPlaceHolder | translate"
        [items]="contacts"
        [isLoading]="isLoadingFinals"
        [isClearable]="true"
        [disabled]="contactDisabled()"
        [activeItems]="selectedContact"
        [isAbsolute]="true"
        [fullWidthList]="true"
        [listStyle]="selectListStyle"
        [allOptionsEnabled]="false"
        [listWidth]="'100%'"
        [loadDefaultItem]="false"
        inputHeight="44px"
        (changeItems)="changeContact($event)"
        [addButtonText]="'general.createFinal'"
        [addButtonFeatureUser]="featureRefUser.Final.create"
        (addElement)="addFinals()"
        (disabledClickEvent)="displaySnackbar()"
        [hasError]="fieldIsInvalid('contact')"
        type="users"
        [disableOpenPanelOnLoading]="true"
      >
      </rm-select-register>
      <span
        *ngIf="fieldIsInvalid('contact') && contactSelectValid"
        class="error-text"
        >*{{ 'budgets.settings.create.form.contactInvalid' | translate }}</span
      >
      <roma-snackbar-mexico
        *ngIf="showContactSnackbar"
        [message]="
          'budgets.settings.create.form.incompleteCompanyData' | translate
        "
        [type]="'warning'"
        [textLink]="'budgets.settings.create.form.openCustomer' | translate"
        (onClickLink)="editFinals()"
      ></roma-snackbar-mexico>
    </div>

    <div class="serial-group">
      <div class="form-field serial">
        <label for="serial"
          >{{ serial | translate
          }}<obligatory-field-sign></obligatory-field-sign
        ></label>
        <rm-select-register
          [isClearable]="false"
          [placeholderText]="
            'budgets.settings.create.form.serialPlaceHolder' | translate
          "
          [activeItems]="selectedSequence"
          [isLoading]="isLoadingSequences"
          [disabled]="isLoadingSequences"
          [items]="sequences"
          [listWidth]="'160px'"
          [borderLeft]="showDocumentInput"
          type="users"
          [hasError]="fieldIsInvalid('prefix') || fieldIsInvalid('sequence_id')"
          inputHeight="44px"
          [fullWidthList]="true"
          [listStyle]="selectListStyle"
          [isAbsolute]="true"
          [addButtonText]="'general.addSecuence'"
          [addButtonFeatureUser]="featureRefUser.CompanyAdjust.update"
          (changeItems)="changeSequence($event)"
          (addElement)="addSequence()"
        >
        </rm-select-register>
        <span
          *ngIf="
            (fieldIsInvalid('prefix') || fieldIsInvalid('sequence_id')) &&
            !fieldIsInvalid('numberDoc')
          "
          class="error-text"
          >*{{ 'budgets.settings.create.form.prefixInvalid' | translate }}</span
        >
        <span *ngIf="fieldIsInvalid('numberDoc')" class="error-text-docNum">
        </span>
      </div>
      <div class="form-field doc-number">
        <label
          >{{ numberDoc | translate
          }}<obligatory-field-sign></obligatory-field-sign
        ></label>
        <input
          romaOnlyNumber
          [maxDecimals]="0"
          [onlyPositive]="true"
          class="form-control s-number"
          type="number"
          placeholder="{{ nDocPlaceholder | translate }}"
          formControlName="numberDoc"
          [ngClass]="{ invalid: fieldIsInvalid('numberDoc') }"
          (change)="onChangeNumberDoc()"
        />
        <span *ngIf="fieldIsInvalid('numberDoc')" class="error-text-docNum"
          >{{ serialNumberError | translate }}*
        </span>
      </div>
    </div>

    <div class="date_group">
      <div class="form-field date">
        <label for="date"
          >{{ 'table.colum.date' | translate
          }}<obligatory-field-sign></obligatory-field-sign
        ></label>
        <!-- <input class="form-control" type="date" formControlName="date" /> -->
        <mat-form-field (click)="picker.open()" appearance="outline">
          <!-- [(ngModel)]="date" -->
          <input
            class="date__field"
            #input
            matInput
            [matDatepicker]="picker"
            formControlName="date"
            placeholder="--/--/----"
            [disabled]="isMexicanCompany"
          />
          <img
            matSuffix
            class="date__icon"
            [src]="'assets/icons/calendar-border.svg'"
            alt="calendar"
            role="button"
          />
          <mat-datepicker #picker (closed)="input.blur()"></mat-datepicker>
        </mat-form-field>
        <span *ngIf="fieldIsInvalid('date')" class="error-text"
          >*{{ 'budgets.settings.create.form.dateInvalid' | translate }}</span
        >
      </div>

      <div class="form-field expires" *ngIf="budgetType !== 'WAL'">
        <label for="expires">{{
          'budgets.settings.create.form.dueDatePlaceholder' | translate
        }}</label>
        <rm-select-register
          [isClearable]="true"
          [placeholderText]="selectPlaceHolder | translate"
          [activeItems]="expiresSelected"
          [items]="expiresList"
          type="users"
          inputHeight="44px"
          [fullWidthList]="true"
          [isAbsolute]="true"
          [addButtonText]="'budgets.settings.create.form.select-date'"
          (changeItems)="changeExpires($event)"
          (addElement)="addElementDueDate()"
        >
        </rm-select-register>
        <input
          class="expires__input"
          #dueInput
          matInput
          [matDatepicker]="dueDatePicker"
          [formControl]="datePicker"
          (ngModelChange)="selectDueDate()"
          (click)="dueDatePicker.open()"
        />
        <mat-datepicker
          class="expires__datepicker"
          #dueDatePicker
          (closed)="input.blur()"
        ></mat-datepicker>
      </div>
    </div>
  </fieldset>
</ng-container>
