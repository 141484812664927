import { Injectable } from '@angular/core';
import InvoiceLegacyAdapter from './adapter/invoice-legacy';
import IInvoiceAdapter from './types/invoice-adapter.type';
import InvoiceAdapter from './adapter/invoice-adapter';
import { UserService } from '@web-frontend/shared/services';
import InvoiceBudgetParams from './types/invoice-budget-params.type';
import { IBudgetSearch, ISummaryTotals, TypeBudget } from '@tacliatech/types';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService implements IInvoiceAdapter {
  constructor(
    private readonly userService: UserService,
    private readonly invoiceLegacy: InvoiceLegacyAdapter,
    private readonly invoiceAdapter: InvoiceAdapter
  ) {}

  findPdf(params: Record<string, any>): Promise<any> {
    return this.getAdapter(params.type).findPdf(params);
  }

  private getAdapter(type: TypeBudget = TypeBudget.BILL): IInvoiceAdapter {
    return !this.userService.validateCountryMX() || type !== TypeBudget.BILL
      ? this.invoiceLegacy
      : this.invoiceAdapter;
  }

  create(params: InvoiceBudgetParams): Promise<any> {
    return this.getAdapter(params.type).create(params);
  }

  createV2(params: InvoiceBudgetParams): Promise<any> {
    return this.getAdapter(params.type).createV2(params);
  }

  update(
    params: InvoiceBudgetParams & { _id?: string; duplicate: boolean }
  ): Promise<any> {
    return this.getAdapter(params.type).update(params);
  }

  updateV2(
    params: InvoiceBudgetParams & { _id?: string; duplicate: boolean }
  ): Promise<any> {
    return this.getAdapter(params.type).updateV2(params);
  }

  delete(id: string, type: TypeBudget): Promise<any> {
    return this.getAdapter(type).delete(id, type);
  }

  deleteV2(id: string, type: TypeBudget): Promise<any> {
    return this.getAdapter(type).deleteV2(id, type);
  }

  find(type: TypeBudget, params?: any): Promise<IBudgetSearch> {
    return this.getAdapter(type).find(type, params);
  }

  findSummary(
    type: TypeBudget,
    params: Record<string, any>
  ): Promise<ISummaryTotals> {
    return this.getAdapter(type).findSummary(type, params);
  }

  findOne(id: string) {
    return this.getAdapter().findOne(id);
  }

  sendEmail(body) {
    return this.getAdapter().sendEmail(body);
  }

  getTaxRegimeList(): Promise<{ id: string; description: string }[]> {
    return this.invoiceAdapter.getTaxRegimeList();
  }
}
