import { WaybillStatus } from '../enum/statusWaybill';
import { IStatus } from '../interfaces';

export const STATUS_WAYBILL_CREATED = {
  id: WaybillStatus.Created,
  name: 'Creado',
  translate: 'status.waybill.created',
  order: 1,
  allowEdit: false,
  color: '#E8EDFF',
};

export const STATUS_WAYBILL_PENDING_DELIVERY = {
  id: WaybillStatus.Pending_Delivery,
  name: 'Pendiente de entrega',
  translate: 'status.waybill.pending_delivery',
  order: 2,
  allowEdit: false,
  color: '#FEE7DA',
};

export const STATUS_WAYBILL_DELIVERED = {
  id: WaybillStatus.Delivered,
  name: 'Entregado',
  translate: 'status.waybill.delivered',
  order: 3,
  allowEdit: false,
  color: '#E1F7EB',
};

export const STATUS_WAYBILL_INVOICED = {
  id: WaybillStatus.Invoiced,
  name: 'Facturado',
  translate: 'status.waybill.invoiced',
  order: 4,
  allowEdit: false,
  color: '#C2E6F8',
};

export const STATUS_WAYBILL_CANCELLED = {
  id: WaybillStatus.Cancelled,
  name: 'Cancelado',
  translate: 'status.waybill.cancelled',
  order: 5,
  allowEdit: false,
  color: '#FEEBED',
};

export const statusWaybill: IStatus[] = [
  STATUS_WAYBILL_CREATED,
  STATUS_WAYBILL_PENDING_DELIVERY,
  STATUS_WAYBILL_DELIVERED,
  STATUS_WAYBILL_INVOICED,
  STATUS_WAYBILL_CANCELLED,
].sort((a, b) => (a.order > b.order ? 1 : -1));

export const STATUS_WAYBILL_SORT = [0, 10, 100, 20, 30, 60, 95, 90, 50];
