import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [FileUploadComponent],
  imports: [
    CommonModule, TranslateModule.forChild()
  ],
  exports:[FileUploadComponent]
})
export class FileUploadModule { }
