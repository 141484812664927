import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Activity, PaginateResponse } from '@tacliatech/types';

@Injectable({
  providedIn: 'root',
})
export class ActivitySearchService {
  constructor(private http: HttpClient) {}

  search(query: { [key: string]: any } = {}) {
    const params = new HttpParams({
      fromObject: {
        ...query,
      },
    });

    return this.http.get<PaginateResponse<Activity.Output[]>>(
      `:API_URL/search/activities`,
      {
        params,
      }
    );
  }
}
