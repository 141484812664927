import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminClockHourComponent } from './admin-clock-hour/admin-clock-hour.component';
import { ClockHourComponent } from './clock-hour.component';
import { ClockHourUserComponent } from './clock-hour-user/clock-hour-user.component';

const routes: Routes = [
  {
    path: '',
    component: ClockHourComponent,
  },
  {
    path: 'admin',
    canActivate: [],
    component: AdminClockHourComponent,
  },
  {
    path: 'detail/:userId',
    canActivate: [],
    component: ClockHourUserComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClockHourRoutingModule {}
