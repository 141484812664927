<!-- <roma-modal-template
  [title]="'Enviar email'"
  (success)="submit()"
  (cancel)="close()"
>
  <roma-phone-manager
    [phones]="viewData?.emailsPreLoading"
    [showPhoneIcon]="false"
    [inputPh]="'Agregar email'"
    [type]="'email'"
    (changePhones)="changeEmails($event)"
  ></roma-phone-manager>
</roma-modal-template> -->

<roma-modal-template
  [title]="'Enviar email'"
  [showDefaultButtons]="true"
  [textSuccess]="'general.send'"
  [isLoading]="isLoading"
  (cancel)="close()"
  (success)="submit()"
>
  <div class="row">
    <div class="col-12" *ngIf="isLoading">
      <roma-loading class="loading"> </roma-loading>
    </div>
    <div class="col-12">
      <form [formGroup]="form">
        <div class="row col-12">
          <div class="row">
            <div class="col-2 pr-10">
              <span>Para:</span>
            </div>

            <div class="col-10">
              <mat-chip-list #chipList aria-label="Destinatarios">
                <mat-chip
                  *ngFor="let to of destinations"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(to)"
                >
                  {{ to }}
                  <button matChipRemove *ngIf="removable">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input
                  matInput
                  [placeholder]="'general.exampleEmail' | translate"
                  type="email"
                  name="email"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="add($event)"
                />
              </mat-chip-list>
              <div
                *ngIf="!this.destinations.length"
                class="invalid-feedback-email"
              >
                {{ 'billing.emailRequired' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="row col-12">
          <div class="row inputArea">
            <div class="col-2 pr-10">
              <span>{{ 'general.subject' | translate }}</span>
            </div>

            <div class="col-10">
              <input
                matInput
                formControlName="subject"
                name="Asunto"
                [placeholder]="'billing.placeholderSubject' | translate"
                [ngClass]="{
                  'is-invalid': fieldSubject?.displayError || false
                }"
              />
              <roma-field-error-display
                style="font-size: 12px !important"
                #fieldSubject
                fieldName="subject"
                [errorMsg]="'billing.subjectRequired' | translate"
                [form]="form"
              >
              </roma-field-error-display>
            </div>
          </div>
        </div>

        <div class="form-group inputArea">
          <textarea
            rows="6"
            cols="40"
            [maxLength]="500"
            matInput
            formControlName="message"
            [placeholder]="'billing.placeholderMessage' | translate"
          >
          </textarea>
        </div>
        <div class="col-12 background">
          {{ 'deal_part_of_service.sendMessage' | translate }}
        </div>
      </form>
    </div>
  </div>
</roma-modal-template>
