import { IFinalNote, IProject } from '@tacliatech/types';

export class Project implements IProject {
  _id?: string;
  name: string;
  description?: string;
  notes?: IFinalNote[];
  files?: string[];
  idClient?: string;
  idOwner?: string;
  idUsers?: string[];
  idCreatedBy?: string;
  deleted?: boolean;

  static fromArray(attributes: IProject[]) {
    return attributes.map((attribute) => new Project(attribute));
  }

  constructor(attribute: IProject) {
    Object.assign(this, attribute);
  }
}
