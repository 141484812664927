import { COMPANY_DATA } from '../../../types-legacy';

export const checkCountryCodeInLocalStorage = (
  countryCode: string
): boolean => {
  const companyDataString = localStorage.getItem(COMPANY_DATA);
  if (companyDataString) {
    const companyData = JSON.parse(companyDataString);
    return companyData.locationCountry === countryCode;
  }

  return countryCode === 'ES'; // if no country is set in localstorage, return true for legacy users from Spain
};

export const getCountryCodeByCompanyFromLocalStorage = (): string => {
  const companyDataString = localStorage.getItem(COMPANY_DATA);
  if (companyDataString) {
    const companyData = JSON.parse(companyDataString);
    return companyData.locationCountry;
  }

  return 'ES'; // if no country is set in localstorage, return ES for legacy users
};
