<roma-modal-template [showSuccessBtn]="false" (cancel)="cancelEvt()">
  <div class="">
    <div class="row" *ngIf="tipoModal === 'info'">
      <div class="col-12" style="text-align: center">
        <div class="info">
          <img src="../../../../assets/images/info.png" />
        </div>
      </div>
      <div class="col-12" style="text-align: center">
        <span>
          {{ 'info.customize' | translate }}
        </span>

        <p>
          {{ 'info.description' | translate }}
        </p>
      </div>
    </div>

    <div class="row" *ngIf="tipoModal === 'pipeline'">
      <div class="col-12" style="text-align: center">
        <div class="info">
          <img src="../../../../assets/images/columnas.jpg" />
        </div>
      </div>
      <div class="col-12" style="text-align: center">
        <span>
          {{ 'info.title1' | translate }}
        </span>

        <p>
          {{ 'info.description2' | translate }}
        </p>
      </div>
    </div>

    <div class="row" *ngIf="tipoModal === 'info2'">
      <div class="col-12" style="text-align: center">
        <div class="info">
          <img src="../../../../assets/images/info2.png" />
        </div>
      </div>
      <div class="col-12" style="text-align: center">
        <span>
          {{ 'info.title2' | translate }}
        </span>

        <p>
          {{ 'info.description3' | translate }}
        </p>
      </div>
    </div>
  </div>
</roma-modal-template>
