import { BusinessType } from '../enum';

export const BUSINESS_TYPES = [
  {
    title: 'register.business_autonomous',
    value: BusinessType.AUTONOMOUS,
    id: BusinessType.AUTONOMOUS,
  },
  {
    title: 'register.business_company',
    value: BusinessType.COMPANY,
    id: BusinessType.COMPANY,
  },
  {
    title: 'register.business_ong',
    value: BusinessType.ONG,
    id: BusinessType.ONG,
  },
];
