/* eslint-disable @typescript-eslint/no-namespace */
import { Injectable } from '@angular/core';
import { ITaxSale, Sale, StatusSalesId, TypeTax } from '@tacliatech/types';
import { BehaviorSubject } from 'rxjs';

export namespace SaleStorageService {
  export const GetInitialStorage = (): SaleStorageService.Storage => {
    return {
      final: null,
      deal: null,
      items: [],
      taxes: [],
      subtotal: 0,
      discount: 0,
      discountAmount: 0,
      total: 0,
      idStatus: StatusSalesId.NotPaid,
      paymentDate: new Date(),
      paymentMethod: null,
      cancelReason: '',
      cancelDate: null,
      steps: {
        saleForm: true,
        paymentMethods: false,
        paymentFinish: false,
        paymentUnpaid: false,
        paymentRefund: false,
        paymentRefundFinish: false,
        paymentCancelFinish: false,
        paymentPending: false,
        paymentRejected: false,
      },
      sale: null,
      paymentLink: null,
    };
  };

  export type SaleStepType = {
    saleForm: boolean;
    paymentMethods: boolean;
    paymentFinish: boolean;
    paymentUnpaid: boolean;
    paymentRefund: boolean;
    paymentRefundFinish: boolean;
    paymentCancelFinish: boolean;
    paymentPending: boolean;
    paymentRejected: boolean;
  };

  export interface Storage {
    final: {
      _id: string;
      img?: string;
      title?: string;
      value?: string;
    };
    deal: {
      _id: string;
      name?: string;
    };
    items: Array<{
      _id: string;
      stock: number;
      sellPrice: number;
      name: string;
    }>;
    taxes: ITaxSale[];
    subtotal: number; // example 8700
    discount: number; // example 20
    discountAmount: number; // example 1740
    total: number; // example 7308
    idStatus: number; // example 1
    paymentDate: Date;
    paymentMethod: Sale.PaymentMethodEnum;
    cancelReason: string;
    steps: SaleStepType;
    sale: Sale.Output;
    cancelDate: Date;
    paymentLink?: {
      id: string;
      url: string;
    };
    paymentIntentId?: string;
  }

  export const GenerateSaleSteps = (statusId?: StatusSalesId): SaleStepType => {
    const newSaleStep: SaleStepType = {
      saleForm: false,
      paymentFinish: false,
      paymentUnpaid: false,
      paymentRefund: false,
      paymentRefundFinish: false,
      paymentMethods: false,
      paymentCancelFinish: false,
      paymentPending: false,
      paymentRejected: false,
    };

    if (statusId === StatusSalesId.Paid) {
      newSaleStep.paymentFinish = true;
    }

    if (statusId === StatusSalesId.NotPaid) {
      newSaleStep.paymentUnpaid = true;
    }

    if (statusId === StatusSalesId.Cancelled) {
      newSaleStep.paymentCancelFinish = true;
    }

    if (statusId === StatusSalesId.Refunded) {
      newSaleStep.paymentRefundFinish = true;
    }

    if (statusId === StatusSalesId.PaymentPending) {
      newSaleStep.paymentPending = true;
    }

    if (statusId === StatusSalesId.PaymentRejected) {
      newSaleStep.paymentRejected = true;
    }

    return newSaleStep;
  };

  export const CalculateItemsSubTotal = (data: {
    items: Array<{
      _id: string;
      stock: number;
      sellPrice: number;
      name: string;
    }>;
  }) => {
    let subTotal = 0;

    for (const sale of data.items) {
      subTotal += sale.sellPrice * sale.stock;
    }

    return subTotal;
  };

  export const CalculateDiscount = (data: {
    discount: number;
    items: Array<{
      _id: string;
      stock: number;
      sellPrice: number;
      name: string;
    }>;
  }) => {
    const subTotal = SaleStorageService.CalculateItemsSubTotal({
      items: data.items,
    });

    return subTotal * (data.discount / 100);
  };

  export const CalculateTaxes = (data: {
    discount: number;
    taxes: Array<{
      _id: string;
      name: string;
      translate: string;
      value: number; // 4
      type: string;
      amount?: number; // 348
    }>;
    items: Array<{
      _id: string;
      stock: number;
      sellPrice: number;
      name: string;
    }>;
  }) => {
    const subTotal = SaleStorageService.CalculateItemsSubTotal({
      items: data.items,
    });

    const discount = SaleStorageService.CalculateDiscount({
      discount: data.discount,
      items: data.items,
    });

    const copyTaxes: Array<{
      _id: string;
      name: string;
      translate: string;
      value: number;
      type: string;
      amount?: number;
    }> = [];

    const differenceSubTotalAndDiscount = subTotal - discount;

    for (const tax of data.taxes) {
      let amount: number = differenceSubTotalAndDiscount;

      if ([TypeTax.IVA, TypeTax.REC].includes(tax.type as TypeTax)) {
        amount = differenceSubTotalAndDiscount * (tax.value / 100);
      }

      if (tax.type === TypeTax.RET) {
        amount = differenceSubTotalAndDiscount * ((tax.value / 100) * -1);
      }

      copyTaxes.push({
        ...tax,
        amount,
      });
    }

    return copyTaxes;
  };

  export const CalculateTotal = (data: {
    items: Array<{
      _id: string;
      stock: number;
      sellPrice: number;
      name: string;
    }>;
    discount: number;
    taxes: Array<{
      _id: string;
      name: string;
      translate: string;
      value: number; // 4
      type: string;
      amount?: number; // 348
    }>;
  }) => {
    const subTotal = SaleStorageService.CalculateItemsSubTotal({
      items: data.items,
    });

    const discountAmount = CalculateDiscount({
      items: data.items,
      discount: data.discount,
    });

    const taxes = SaleStorageService.CalculateTaxes({
      discount: data.discount,
      taxes: data.taxes,
      items: data.items,
    });

    const taxAmount = taxes
      .map((tax) => tax.amount)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    return subTotal - discountAmount + taxAmount;
  };
}

@Injectable({
  providedIn: 'root',
})
export class SaleStoreService {
  private saleStorage = new BehaviorSubject<SaleStorageService.Storage>(
    SaleStorageService.GetInitialStorage()
  );

  storageImage!: string;

  public sale$ = this.saleStorage.asObservable();

  public setSale(value: SaleStorageService.Storage) {
    const current: SaleStorageService.Storage = {
      ...value,
      subtotal: SaleStorageService.CalculateItemsSubTotal({
        items: value.items,
      }),
      discountAmount: SaleStorageService.CalculateDiscount({
        items: value.items,
        discount: value.discount,
      }),
      taxes: SaleStorageService.CalculateTaxes({
        discount: value.discount,
        taxes: value.taxes,
        items: value.items,
      }),
      total: SaleStorageService.CalculateTotal({
        taxes: value.taxes,
        items: value.items,
        discount: value.discount,
      }),
    };

    this.saleStorage.next(current);
  }

  public getSale() {
    return this.saleStorage.getValue();
  }

  public reset() {
    this.saleStorage.next(SaleStorageService.GetInitialStorage());
  }

  public commit() {
    this.storageImage = JSON.stringify(this.getSale());
  }
}
