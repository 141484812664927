import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICrudService, IOwnTeam } from '@tacliatech/types';

@Injectable({
  providedIn: 'root',
})
export class GenericService<T> {
  endpoint: string;
  model: T | undefined;

  constructor(public http: HttpClient, private endp: string) {
    this.endpoint = endp;
  }

  // FINDS
  // --------------------------------
  findAll() {
    return this.http.get<T[]>(`:API_URL/${this.endpoint}/`);
  }

  findOne(id: string) {
    return this.http.get<T>(`:API_URL/${this.endpoint}/${id}`);
  }

  // CRUD SERVICES
  // --------------------------------

  createOne(data: T | any) {
    return this.http.post<T>(`:API_URL/${this.endpoint}/`, data);
  }

  removeOne(id: string) {
    return this.http.delete(`:API_URL/${this.endpoint}/${id}`);
  }

  updateOne(id: string, data: T | any) {
    if (!id) {
      // @ts-ignore
      return;
    }

    return this.http.put<T>(`:API_URL/${this.endpoint}/${id}`, data);
  }

  updateImg(id: string, data: any) {
    return this.http.put<TexImageSource>(
      `:API_URL/${this.endpoint}/${id}`,
      data
    );
  }

  // GENERIC
  // -------------------------------
  get client() {
    return this.http;
  }
}
