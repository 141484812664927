import { ICatalogue } from '@tacliatech/types';

export class Catalogue implements ICatalogue {
  _id?: string;
  name: string;
  internalReference: string;
  description: string;
  type: string;
  notes: [];
  files: [];
  taxes: [];
  sellPrice: number;
  buyPrice: number;
  category: string;
  customer_id: string;
  owner: any;
  idUsers: any;
  createdBy: string;
  updatedBy: string;
  categoryObj?: { _id: string; name: string };
  customProperties: { [key: string]: any };
  deleted?: boolean;

  static fromArray(attributes) {
    return attributes.map((attribute) => new Catalogue(attribute));
  }

  constructor(attribute: ICatalogue) {
    Object.assign(this, attribute);
  }
}
