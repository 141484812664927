/* eslint-disable @typescript-eslint/no-namespace */

import { IUser } from '../../interfaces';

export namespace ClockHour {
  export interface Schema {
    idCreatedBy: string;
    idOwner: string;
    idCompany: string;
    notes?: ClockHour.Note[];
    date: ClockHour.ClockDate;
    histories: ClockHour.Histories;
    deleted: boolean;
    approved: boolean;
    status: ClockHour.Status;
    owner?: IUser;
    reasonRejected?: {
      description: string;
      idCreatedBy: string;
    };
    averageHoursWorked?: number;
    averageTimeWorked?: {
      hour: number;
      minute: number;
    };
  }

  export enum Status {
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
    Pending = 'PENDING',
  }

  export type Histories = ClockHour.History[];
  export interface History {
    startDate: string;
    endDate: string;
    duration: ClockHour.Hour;
    startHour: ClockHour.Hour;
    endHour: ClockHour.Hour;
  }

  export interface Output extends ClockHour.Schema {
    _id: string;
    dateFormatted: Date;
    averageHoursWorked: number;
    totalHours?: number;
    totalMinutes?: number;
  }

  export interface ClockDate {
    year: number;
    month: number;
    day: number;
  }

  export interface Hour {
    hour: number;
    minute: number;
    seconds?: number;
  }

  export interface Note {
    _id: string;
    text: string;
    createdAt: Date;
    idAuthor: string;
  }

  export interface Query {
    idCompanies: string[];
    idOwners: string[];
    keyword?: string;
    date?: string;
    to?: string;
    from?: string;
    page?: number;
    limit?: number;
    applyPaginate?: boolean;
    avgHoursWorked?: ['MINOR' | 'MAJOR' | 'EQUAL', number];
  }

  export const fromStringHourToObjectHour = (
    hourString: string
  ): ClockHour.Hour => {
    const hour = parseInt(hourString.split(':')[0]);
    const minute = parseInt(hourString.split(':')[1]);

    return {
      hour,
      minute,
    };
  };

  export const fromStringDateToObjectDate = (
    dateString: string
  ): ClockHour.ClockDate => {
    const date = new Date(dateString);

    return {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth(),
      day: date.getUTCDate(),
    };
  };

  export const fromHourToTimeString = (clockObj: ClockHour.Hour): string => {
    let hours = `${clockObj.hour}`;
    let minutes = `${clockObj.minute}`;

    if (clockObj.hour < 10) hours = '0' + clockObj.hour;
    if (clockObj.minute < 10) minutes = '0' + clockObj.minute;

    return '' + hours + ':' + minutes;
  };

  export const getTotalHourFromClockHours = (
    clockHours: Array<{ histories: ClockHour.Histories }>,
    media: number
  ) => {
    const histories = clockHours.map((clockHour) => clockHour.histories);

    return getTotalHourFromHistories(histories, media);
  };

  export const getNotes = (clockHours: ClockHour.Output) => {
    const notes = clockHours.notes.map((note) => note.text);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const result = notes.length ? notes[0] : '';
    return notes[0];
  };

  const getEndByRangeHour = (endHour: ClockHour.Hour) => {
    return endHour
      ? hourMinuteToMilliseconds(`${endHour.hour}:${endHour.minute}`)
      : null;
  };

  const calculateTotalMillisecondsOfRange = (range: History) => {
    const start = hourMinuteToMilliseconds(
      `${range.startHour.hour}:${range.startHour.minute}`
    );
    const end = getEndByRangeHour(range.endHour) || start;
    return end - start;
  };

  const sortHistories = (day: History[]) => {
    return day.sort(
      (a, b) =>
        hourMinuteToMilliseconds(a.startHour.hour + ':' + a.startHour.minute) -
        hourMinuteToMilliseconds(b.startHour.hour + ':' + b.startHour.minute)
    );
  };

  const getEndHour = (range: History) => {
    return range.endHour
      ? hourMinuteToMilliseconds(
          `${range.endHour.hour}:${range.endHour.minute}`
        )
      : null;
  };

  const updateMergedRanges = (
    ranges: History[],
    currentHistory: History,
    historyStartHour: number,
    historyEndHour: number
  ) => {
    const hour = ranges[ranges.length - 1].endHour.hour;
    const minute = ranges[ranges.length - 1].endHour.minute;
    const mergedEndHour = hourMinuteToMilliseconds(`${hour}:${minute}`);

    if (historyStartHour > mergedEndHour) {
      ranges.push(currentHistory);
    } else if (historyEndHour > mergedEndHour) {
      ranges[ranges.length - 1].endHour = currentHistory.endHour;
    }
  };

  const processHistory = (
    sortedHistories: History[],
    i: number,
    mergedRanges: History[]
  ) => {
    const hour = sortedHistories[i].startHour.hour;
    const minute = sortedHistories[i].startHour.minute;
    const historyStartHour = hourMinuteToMilliseconds(`${hour}:${minute}`);
    const historyEndHour = getEndHour(sortedHistories[i]);

    if (historyEndHour) {
      updateMergedRanges(
        mergedRanges,
        sortedHistories[i],
        historyStartHour,
        historyEndHour
      );
    }
  };

  export const getTotalHourFromHistories = (
    clockHours: Array<Array<ClockHour.History>>,
    media: number
  ): string => {
    const totalMilliseconds = calculateTotalMillisecondsFromClockHours(
      clockHours
    );

    return calculateAndFormatHoursMinutes(totalMilliseconds, media);
  };

  const calculateTotalMillisecondsFromClockHours = (
    clockHours: Array<Array<ClockHour.History>>
  ) => {
    let totalMilliseconds = 0;

    for (const day of clockHours) {
      totalMilliseconds += calculateTotalMillisecondsFromDay(day);
    }

    return totalMilliseconds;
  };

  const calculateTotalMillisecondsFromDay = (day: Array<ClockHour.History>) => {
    let totalMilliseconds = 0;
    const mergedRanges = [];
    const sortedHistories = sortHistories(day);
    mergedRanges.push(sortedHistories[0]);

    for (let i = 0; i < sortedHistories.length; i++) {
      processHistory(sortedHistories, i, mergedRanges);
    }

    for (const range of mergedRanges) {
      totalMilliseconds += calculateTotalMillisecondsOfRange(range);
    }

    return totalMilliseconds;
  };

  const calculateAndFormatHoursMinutes = (
    totalMilliseconds: number,
    media: number
  ) => {
    let totalHours = millisecondsToHour(totalMilliseconds);
    let totalMinutes = millisecondsToMinutes(totalMilliseconds);

    if (media > 0) {
      const averageMilliseconds = totalMilliseconds / media;
      totalHours = millisecondsToHour(averageMilliseconds);
      totalMinutes = millisecondsToMinutes(averageMilliseconds);
    }

    return formatHoursMinutes(totalHours, totalMinutes);
  };

  export const millisecondsToHour = (milliseconds: number): number => {
    return Math.floor(milliseconds / (60 * 60 * 1000));
  };

  export const millisecondsToMinutes = (milliseconds: number): number => {
    return Math.floor((milliseconds / (60 * 1000)) % 60);
  };

  export const hourMinuteToMilliseconds = (time: string): number => {
    const [hour, minute] = time.split(':').map(Number);
    return (hour * 60 + minute) * 60 * 1000;
  };

  /**
   * The function `formatHoursMinutes` takes in total hours and total minutes as parameters and returns
   * a formatted string in the format "HH:MM".
   * @param {number} totalHours - The total number of hours.
   * @param {number} totalMinutes - The `totalMinutes` parameter represents the total number of minutes.
   * @returns a formatted string in the format "HH:MM", where HH represents the total hours and MM
   * represents the total minutes.
   */
  export const formatHoursMinutes = (
    totalHours: number,
    totalMinutes: number
  ): string => {
    const formattedHours = Math.trunc(totalHours).toString().padStart(2, '0');
    const formattedMinutes = Math.trunc(totalMinutes)
      .toString()
      .padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  };

  export const getDifferenceHours = (
    endDate: string,
    startDate: string,
    options: 'ADD' | 'SUB' = 'SUB',
    startDay = '01/01/2007',
    endDay = '01/01/2007'
  ): ClockHour.Hour => {
    const timeStart = new Date(`${startDay} ${startDate}`).getTime();
    const timeEnd = new Date(`${endDay} ${endDate}`).getTime();

    const hourDiff =
      options === 'SUB' ? timeEnd - timeStart : timeEnd + timeStart;

    const minDiff = hourDiff / 60 / 1000;
    const hDiff = hourDiff / 3600 / 1000;
    const sDiff = hourDiff / 1000;

    const humanReadable: ClockHour.Hour = {
      hour: 0,
      minute: 0,
      seconds: 0,
    };

    humanReadable.hour = Math.floor(hDiff);
    humanReadable.minute = Math.round(minDiff - 60 * humanReadable.hour);
    humanReadable.seconds = Math.round(sDiff - 60 * humanReadable.minute);

    return humanReadable;
  };

  export const millisecondsToMinutesAndSeconds = (milliseconds: number) => {
    const d = new Date(Date.UTC(0, 0, 0, 0, 0, 0, milliseconds));
    const parts = [d.getUTCHours(), d.getUTCMinutes()];
    const formatted = parts.map((s) => String(s).padStart(2, '0')).join(':');

    return formatted;
  };

  export const fromClockDateToNativeDate = (date: ClockHour.ClockDate) => {
    const setMonth = date?.month + 1;
    const newMonth = setMonth < 10 ? `0${setMonth}` : `${setMonth}`;
    const newDay =
      date?.day?.toString().length > 1 ? date?.day : `0${date.day}`;

    return new Date(`${date?.year}-${newMonth}-${newDay}T03:24:00`);
  };

  export const getClockHoursHistoriesDuration = (
    historiesCollection: Array<ClockHour.Histories>
  ) => {
    let hourDuration = ClockHour.getTotalHourFromHistories(
      historiesCollection,
      0
    );
    while (hourDuration.charAt(0) === '0') {
      hourDuration = hourDuration.substring(1);
    }
    return hourDuration.replace(':', 'hr ') + 'm';
  };
}
