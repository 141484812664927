import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PreviewPartOfServiceReportComponent } from './preview-part-of-service-report.component';
import { PreviewPartOfService } from './preview-part-of-service.types';

@Injectable({
  providedIn: 'root',
})
export class PreviewPartOfServiceReportProvider {
  private refreshListSource = new Subject<boolean>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(config?: MatDialogConfig<PreviewPartOfService.ModalParams>) {
    const dialogRef = this.dialog.open(
      PreviewPartOfServiceReportComponent,
      config
    );

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }
}
