import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IStatus } from '@tacliatech/types';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  constructor(private http: HttpClient) {}

  findByUser(query: { [key: string]: any } = {}) {
    // Sometimes comes of type array, so convert to object to
    // send the queryParam correctly
    if (query?.length && query.constructor.name === 'Array') {
      query = {
        id: query[0],
      };
    }

    const params = new HttpParams({
      fromObject: {
        ...query,
      },
    });

    return this.http.get<IStatus[]>(`:API_URL/status/users/`, {
      params,
    });
  }

  create({
    name,
    type,
    color,
  }: {
    name: string;
    type?: string;
    color?: string;
  }) {
    const save = {
      name,
      type,
      color,
    };

    return this.http.post<IStatus>(`:API_URL/status`, {
      ...save,
    });
  }

  updateOne({ id, name, color }: { id: string; name: string; color?: string }) {
    const save = {
      name,
      color,
    };

    return this.http.put<unknown>(`:API_URL/status/${id}`, {
      ...save,
    });
  }

  updateColor({ id, color }: { id: string; color: string }) {
    const save = {
      color,
    };

    return this.http.put<unknown>(`:API_URL/status/${id}`, {
      ...save,
    });
  }

  removeOne({ id }: { id: string }) {
    return this.http.delete<unknown>(`:API_URL/status/${id}`);
  }
}
