import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AddCategoriesCommonComponent } from './add-categories-common.component';
import { DefaultConfig } from './add-categories-common.types';

@Injectable({
  providedIn: 'root',
})
export class AddCategoryCommonService {
  private refreshListSource = new Subject<boolean>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(config?: MatDialogConfig) {
    const settings = config ? config : DefaultConfig();

    const dialogRef = this.dialog.open(AddCategoriesCommonComponent, settings);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }

  emitRefresh(data) {
    this.refreshListSource.next(data);
  }
}
