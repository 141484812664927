import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { COMPANY_ID_KEY, USER_ID_KEY } from '@tacliatech/types';
import { AlertService } from '@web-frontend/shared/helpers/alert';

import {
  GeolocationService,
  StorageService,
} from '@web-frontend/shared/services';
import { TrackingService } from '@web-frontend/shared/services/tracking';
import { IconColor } from '../tracking-objects/tracking-objects.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-map-request-location.component.html',
  styleUrls: ['./modal-map-request-location.component.scss'],
})
export class ModalMapRequestLocationComponent implements OnInit {
  @Input()
  title = '';

  @Input()
  form: FormGroup;

  @Input()
  type = 'info';

  @Input()
  module = '';

  center: any;
  zoom = 8;
  markers = [];

  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: false,
    scrollwheel: false,
    fullscreenControl: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };
  position = false;

  latitude = 0;
  longitude = 0;

  constructor(
    private activeModal: MatDialogRef<ModalMapRequestLocationComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private alertService: AlertService,
    private i18n: TranslateService,
    private trackingService: TrackingService,
    private geolocationService: GeolocationService
  ) {}

  ngOnInit(): void {
    this.getCurrentPosition();
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  async getCurrentPosition() {
    this.alertService.success(
      this.i18n.instant('geolocation.waitForGeolocation')
    );
    this.position = false;
    this.geolocationService
      .getGeolocation()
      .then((position) => {
        if (position.lat !== 0 && position.lng !== 0) {
          this.center = {
            lat: position.lat,
            lng: position.lng,
          };
          this.latitude = position.lat;
          this.longitude = position.lng;
          this.addMarker(
            ' ',
            '',
            IconColor.BLUE,
            this.latitude,
            this.longitude
          );
          this.draw();
          this.position = true;
        }
        this.position = true;
      })
      .catch(() => {
        this.alertService.error(this.i18n.instant('geolocation.noPermissions'));
        this.activeModal.close();
      });
  }

  addMarker(
    title: string,
    label: string,
    color: string,
    latitude: number,
    longitude: number
  ) {
    let url = 'https://maps.google.com/mapfiles/ms/icons/';
    url += color + '-dot.png';
    this.markers.push({
      position: {
        lat: latitude,
        lng: longitude,
      },
      options: { animation: google.maps.Animation.DROP, icon: url },
    });
  }

  cancel() {
    this.activeModal.close();
  }

  success() {
    this.addCurrentLocation();
  }

  can() {
    return this.form?.valid;
  }

  getMarkers() {
    return this.markers;
  }

  addCurrentLocation() {
    if (this.latitude !== 0 && this.longitude !== 0) {
      this.sendPosition(this.latitude, this.longitude);
    } else {
      this.alertService.error(this.i18n.instant('geolocation.noPermissions'));
      this.cancel();
    }
  }

  sendPosition(lat, lng) {
    this.alertService.success(
      this.i18n.instant('geolocation.sendingGeolocation')
    );
    this.trackingService.addOrUpdate({
      user_id: StorageService.GetItem(USER_ID_KEY),
      company_id: StorageService.GetItem(COMPANY_ID_KEY),
      instant: new Date(),
      lat: lat,
      long: lng,
    });
    this.alertService.success(
      this.i18n.instant('geolocation.sendGeolocationSuccess')
    );
    this.activeModal.close({});
  }
}
