import { Component } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { TranslateService } from '@ngx-translate/core';
import { ModalDeleteAccountComponent } from '../modal-delete-account/modal-delete-account.component';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@web-frontend/shared/services';
@Component({
  selector: 'privacy-policies',
  templateUrl: './privacy-policies.component.html',
  styleUrls: ['./privacy-policies.component.scss'],
})
export class PrivacyPoliciesComponent {
  isAdmin = false;

  constructor(
    private readonly translateService: TranslateService,
    private readonly dialog: MatDialog,
    private readonly amplitudeService: AmplitudeService,
    private readonly authService: AuthService
  ) {
    this.authService.isAdmin$.subscribe((data) => (this.isAdmin = data));
  }

  private browserOpen(url: string) {
    return Browser.open({
      presentationStyle: 'popover', // only iOS
      url,
    });
  }

  onClickPrivacy() {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.settingsPoliticsStart,
    });
    return this.browserOpen(
      this.translateService.instant('privacyPolicy.privacyPolicyUrl')
    );
  }

  onClickTermsOfUse() {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.settingsConditionsStart,
    });
    return this.browserOpen(
      this.translateService.instant('privacyPolicy.termsOfUseUrl')
    );
  }

  onClickDeleteAccount() {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.settingsDeleteAccount,
    });
    this.dialog.open(ModalDeleteAccountComponent, {
      maxWidth: '432px',
    });
  }
}
