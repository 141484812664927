import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { RmSelect } from '../../globals/rm-select/rm-select.types';
import { Subscription } from 'rxjs';
import { CompanyModuleService } from '@web-frontend/shared/services/company';
import {
  distinctUntilChanged,
  filter,
  finalize,
  pairwise,
} from 'rxjs/operators';
import {
  Company,
  Feature,
  FeatureUser,
  IBudgetCommonData,
  SequenceTypes,
  TypeAmplitudeBudget,
  TypeBudget,
} from '@tacliatech/types';
import {
  FinalService,
  StorageService,
  UserService,
} from '@web-frontend/shared/services';
import { TypeOfCRUD } from '@web-frontend/shared/enums/crud.enum';
import { TranslateService } from '@ngx-translate/core';
import { BUDGET_EXPIRATION_LIST, BudgetExpiration } from '../budget.enum';
import { SequenceService } from '@web-frontend/shared/services/sequence';
import { MatDialog } from '@angular/material/dialog';
import { CreateSequenceComponent } from '../../create-sequence/create-sequence.component';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  APP_DATE_FORMATS,
  AppDateAdapter,
} from '../../filter/filter.component';
import {
  DEFAULT_STATUS_BY_BUDGET_TYPE,
  getDateStrResum,
  OVERDUE_STATUS_BY_BUDGET_TYPE,
  STATUSES_BY_BUDGET_TYPE,
} from '@web-frontend/shared/utils';
import { BudgetUtilisService } from '../budget-util.service';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { CreateFinalV2Service } from '../../create-final-v2';
import { MatDatepicker } from '@angular/material/datepicker';
import { ToastService } from '../../../services/toast/toast.service';
import { MsSalesService } from '../../../../sales/sales.service';

@Component({
  selector: 'roma-budget-header',
  templateUrl: './budget-header.component.html',
  styleUrls: ['./budget-header.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetHeaderComponent implements OnInit, OnDestroy {
  _formHeader: FormGroup;
  _initialSequence: { sequence_id: string; numberDoc: string };
  @Input()
  formHeader: FormGroup;

  budgetHeader: AbstractControl;

  featureRef = Feature;
  featureRefUser = FeatureUser;

  contacts: RmSelect.Items;
  auxContacts: any[] = [];
  isLoadingFinals = false;
  selectedContact: RmSelect.Items;

  sequences: RmSelect.Items;
  selectedSequence: RmSelect.Items;
  isLoadingSequences = false;

  snackbar = false;

  private sub$ = new Subscription();
  idActiveModules$ = this.companyModuleService.idActiveModules$;

  _mode: TypeOfCRUD;
  @Input()
  set mode(mode: TypeOfCRUD) {
    this._mode = mode;
    // Add default value of pending if is creation mode
    if (this.mode === TypeOfCRUD.CREATE) {
      const status = DEFAULT_STATUS_BY_BUDGET_TYPE[this.budgetType].id;

      this.updateSelectedStatus(status);
      this.formHeaderControl.get('status')?.markAsTouched();
      this.formHeaderControl.get('status')?.setValue(status);
    } else if (this.mode === TypeOfCRUD.UPDATE) {
      const dueDate = this.budgetHeader?.value.dueDate;
      const status = this.budgetHeader?.value?.status
        ? this.budgetHeader?.value?.status
        : null;
      if (status) {
        this.updateSelectedStatus(status);
      }

      if (dueDate) {
        this.datePicker.setValue(dueDate);
      }
    }
  }

  get mode(): TypeOfCRUD {
    return this._mode;
  }

  @Input()
  budget: IBudgetCommonData;

  @Input()
  budgetType: TypeBudget;

  @Input()
  duplicate = false;

  @Input()
  convert = false;

  @Input()
  budgetTypeAmplitude: TypeAmplitudeBudget;

  @Input()
  canEditContact = true;

  @ViewChild('dueDatePicker') dueDatePicker!: MatDatepicker<Date>;

  currentLang = 'en-US';

  statusSelected: RmSelect.Items;
  statusBudgetList: RmSelect.Items;
  statusBudgetsPlaceholder = 'filter.status-budgets';
  selectPlaceHolder = 'budgets.settings.create.form.selectPlaceHolder';

  expiresList: RmSelect.Items = [];
  expiresSelected: RmSelect.Items = [];
  expiration = BUDGET_EXPIRATION_LIST;

  contactPlaceholder = 'budgets.settings.create.form.contactsPlaceholder';
  serialPlaceHolder = 'budgets.settings.create.form.serialNumber';
  serial = 'budgets.settings.create.form.serial';
  nDocPlaceholder = 'budgets.settings.create.form.nDocPlaceholder';
  numberDoc = 'budgets.settings.create.form.numberDoc';

  get formHeaderControl(): FormGroup {
    return this.formHeader.controls['budgetHeader'] as FormGroup;
  }

  get modeIsCreate() {
    return this.mode === TypeOfCRUD.CREATE;
  }

  datePicker = new FormControl();

  selectListStyle = {
    'max-height': '210px',
  };

  title: string;

  contactSelectValid = true;
  selectedContactId: string;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly companyModuleService: CompanyModuleService,
    private readonly finalService: FinalService,
    private readonly sequenceService: SequenceService,
    private readonly createFinalService: CreateFinalV2Service,
    private readonly translateService: TranslateService,
    private readonly budgetUtilis: BudgetUtilisService,
    private _adapter: DateAdapter<any>,
    private readonly dialog: MatDialog,
    private amplitudeService: AmplitudeService,
    private userService: UserService,
    private toastService: ToastService,
    private readonly msSalesService: MsSalesService,
    private readonly i18n: TranslateService
  ) {
    translateService.onLangChange.subscribe((lang) => {
      this.setCalendarsLang(lang.lang);
    });
  }

  get isMexicanCompany() {
    return this.userService.validateCountryMX();
  }

  get showDocumentInput(): boolean {
    if (this.isMexicanCompany) {
      return this.budgetType !== TypeBudget.BILL;
    }
    return true;
  }

  get serialNumberError(): string {
    if (this.formHeaderControl.controls['numberDoc'].value) {
      return `${this.i18n.instant(
        'budgets.settings.create.form.serialNumberError'
      )}`;
    }
    return `${this.i18n.instant(
      'budgets.settings.create.form.serialNumberError2'
    )}`;
  }

  async ngOnInit(): Promise<void> {
    this.budgetHeader = this.formHeader.controls.budgetHeader;
    const date = this.budgetHeader?.get('date') as FormControl;

    const title = {
      [TypeBudget.BILL]: 'billHeader',
      [TypeBudget.BUDGET]: 'budgetHeader',
      [TypeBudget.PROFORM]: 'proformHeader',
      [TypeBudget.WAL]: 'waybillHeader',
      default: 'defaultHeader',
    };

    this.title = `budgets.settings.create.form.${
      title[this.budgetType] || title.default
    }`;

    this.setCalendarsLang(this.translateService.currentLang);
    this.requestFinals();
    this.resolveStatus();
    this.resolveExpiration();
    this.sub$
      .add(
        this.formHeader.controls['budgetHeader'].valueChanges.subscribe(() =>
          this.draw()
        )
      )
      .add(
        date.valueChanges
          .pipe(distinctUntilChanged(), pairwise())
          .subscribe(([oldDate, newDate]) => {
            if (
              this.formHeaderControl.get('dueDate').value &&
              newDate > this.formHeaderControl.get('dueDate').value
            ) {
              this.formHeaderControl.get('date').patchValue(oldDate);
              this.toastService.show({
                text: 'budgets.settings.inconsistentDates',
                type: 'error',
                msDuration: 4000,
              });
            }
            if (this.budgetTypeAmplitude) {
              this.amplitudeService.sendEvent({
                event: `${this.budgetTypeAmplitude}_card_editDate`,
              });
            }
          })
      );

    await this.requestSequences();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  resolveStatus(): void {
    const statusList = STATUSES_BY_BUDGET_TYPE[this.budgetType];

    this.statusBudgetList = statusList.map((s) => {
      return {
        title: s.translate,
        value: s.id,
        id: String(s.id),
      };
    });

    if (this.budgetType !== TypeBudget.WAL && !this.isMexicanCompany) {
      this.statusBudgetList = this.statusBudgetList.filter(
        (status) =>
          status.value !== OVERDUE_STATUS_BY_BUDGET_TYPE[this.budgetType]?.id
      );
    }
  }

  resolveExpiration(): void {
    this.expiresList = this.expiration.map((e) => {
      return {
        title: this.translateService.instant(
          `budgets.settings.create.form.${e}`
        ),
        value: e,
        id: e,
      };
    });

    if (this.mode === TypeOfCRUD.UPDATE) {
      this.datePicker.setValue(this.budgetHeader?.value.dueDate);
    }
  }

  private requestFinals() {
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Finals)))
        .subscribe(() => {
          this.isLoadingFinals = true;
          this.draw();

          this.finalService
            .findAllFinalsByCompany_SELECT()
            .pipe(
              finalize(() => {
                this.isLoadingFinals = false;
                let final;
                final = this.budgetHeader?.value.contact
                  ? this.budgetHeader?.value.contact
                  : null;

                if (!final) {
                  final = this.budgetHeader?.value.contact_id
                    ? this.budgetHeader?.value.contact_id
                    : null;
                }

                if (final) {
                  this.updateSelectedContact(final);
                }
                this.draw();
              })
            )
            .subscribe((res) => {
              this.contacts = res.map((res) => {
                return {
                  title: `${res.name} ${res.lastName ? res.lastName : ''}`,
                  value: res._id,
                  id: res._id,
                };
              });
            });
        })
    );
  }

  get actualSequenceType(): SequenceTypes {
    const budgetTypeMapping = {
      [TypeBudget.BILL]: SequenceTypes.BILL,
      [TypeBudget.BUDGET]: SequenceTypes.BUDGET,
      [TypeBudget.PROFORM]: SequenceTypes.PROFORM,
      [TypeBudget.WAL]: SequenceTypes.WAYBILLS,
    };
    const sequenceType =
      budgetTypeMapping[this.budgetType] || SequenceTypes.ADD_BUDGETS;
    return sequenceType;
  }

  async onChangeNumberDoc(): Promise<void> {
    const numberDoc = this.formHeaderControl.controls['numberDoc'].value;
    const sequence = this.sequences.find(
      (s) => s.id === this.formHeaderControl.controls['sequence_id'].value
    );

    if (!sequence) return;

    if (
      sequence.id === this._initialSequence?.sequence_id &&
      numberDoc == this._initialSequence?.numberDoc
    ) {
      this.formHeaderControl.controls['numberDoc'].setErrors(null);
      return;
    }

    const invalidResult = await this.msSalesService.isValidSequenceNumber(
      sequence.id,
      numberDoc,
      this.actualSequenceType
    );
    this.formHeaderControl.controls['numberDoc'].setErrors(null);
    if (!invalidResult.isAvailable) {
      this.formHeaderControl.controls['numberDoc'].setErrors({
        invalid: true,
      });
    }
    this.draw();
  }

  async requestSequences(): Promise<void> {
    this.isLoadingSequences = true;

    const sequences = await this.sequenceService.getAllDocumentSequences(
      {
        type: this.actualSequenceType,
      },
      this.actualSequenceType
    );

    this.sequences = sequences.map((seq) => {
      return {
        title: seq.name,
        value: seq.prefix,
        type: seq.type,
        id: seq.id,
      };
    });

    this.isLoadingSequences = false;
    this.draw();
    if (this.mode === TypeOfCRUD.UPDATE && !this.duplicate) {
      const prefix = this.budgetHeader.value.prefix
        ? this.budgetHeader.value.prefix
        : null;

      const sequence_id = this.budgetHeader.value.sequence_id
        ? this.budgetHeader.value.sequence_id
        : this.sequences.find((s) => s.value === '0')?.id;
      this.updateSelectedSequence(sequence_id, prefix);
      this.saveInitialSequence();
    }

    if (this.sequences?.length !== 1) {
      return;
    }

    if (this.mode === TypeOfCRUD.CREATE || this.convert || this.duplicate) {
      this.changeSequence(this.sequences);
    }
  }

  private async refreshFinals(idAdded: string) {
    const activesModules = this.companyModuleService.idActiveModules.filter(
      (module) => module === Company.IdModule.Finals
    );

    if (activesModules.length) {
      this.isLoadingFinals = true;
      this.draw();
      try {
        const res = await this.finalService
          .findAllFinalsByCompany_SELECT()
          .toPromise();

        this.contacts = res.map((res) => {
          return {
            title: `${res.name} ${res.lastName ? res.lastName : ''}`,
            value: res._id,
            id: res._id,
          };
        });
        this.updateSelectedContact(idAdded);
      } catch (err) {
      } finally {
        this.isLoadingFinals = false;
        this.draw();
      }
    }
  }

  setCalendarsLang(lang: string): void {
    this.currentLang = lang === 'en' ? 'en-US' : 'es-ES';
    localStorage.setItem('currentLang', this.currentLang);
    this._adapter.setLocale(this.currentLang);
  }

  fieldIsInvalid(fieldName: string): boolean {
    if (this.formHeaderControl) {
      return (
        this.formHeaderControl.controls[fieldName]?.invalid &&
        this.formHeaderControl.controls[fieldName]?.touched
      );
    }
    return false;
  }

  private updateSelectedContact(id: string): void {
    const contact = this.contacts.filter((c) => c.id === id);
    this.selectedContact = contact;
    this.changeContact(this.selectedContact);
    this.draw();
  }

  updateSelectedSequence(sequence_id: string, prefix = '0'): void {
    let sequence = [];

    if (sequence_id) {
      sequence = this.sequences?.filter((s) => s.id === sequence_id);
    } else {
      sequence = this.sequences?.filter((s) => s.value === prefix);
    }

    this.formHeader.patchValue({
      budgetHeader: {
        prefix,
        sequence_id,
      },
    });

    this.selectedSequence = sequence;
    this.draw();
  }

  updateSelectedStatus(idStatusAdded: number): void {
    const item = this.statusBudgetList?.find((s) => s.value === idStatusAdded);
    this.statusSelected = item ? [item] : [];
    this.draw();
  }

  getNextSequenceNumber(): Promise<void> {
    return this.msSalesService
      .getNextSequenceNumber(
        this.formHeaderControl.controls['sequence_id'].value
      )
      .then((response) => {
        this.formHeaderControl.controls['numberDoc'].patchValue(
          response.currentNumber
        );
      });
  }

  validateContact(id: any): void {
    if (!id) {
      this.contactSelectValid = true;
      this.draw();
      return;
    }

    this.finalService.findOne(id).subscribe(
      (res) => {
        const contactSelected = res;
        this.contactSelectValid = !!(
          contactSelected.fiscalName &&
          contactSelected.rfc &&
          contactSelected.fiscalRegime &&
          contactSelected.address
        );

        if (!this.isMexicanCompany) {
          this.draw();
          return;
        }

        const contactControl = this.formHeaderControl.controls['contact'];
        if (this.contactSelectValid || this.budgetType !== TypeBudget.BILL) {
          contactControl.setErrors(null);
        } else {
          contactControl.setErrors({ invalid: true });
          contactControl.markAsUntouched();
        }

        this.draw();
      },
      (error) => {
        console.log('validateContact error', error);
      }
    );
  }

  get showContactSnackbar(): boolean {
    return (
      this.isMexicanCompany &&
      !this.contactSelectValid &&
      this.budgetType === TypeBudget.BILL
    );
  }

  changeContact(evt: RmSelect.Items): void {
    const ids = evt.map((el) => el.value);
    this.validateContact(ids[0]);
    this.selectedContactId = ids[0] as string;
    if (ids.length) {
      const [first] = ids;
      this.formHeader.patchValue({
        budgetHeader: {
          contact: first,
          contact_id: first,
        },
      });

      this.amplitudeService.sendEvent({
        event: `${this.budgetTypeAmplitude}_card_editFinal`,
      });
    } else {
      this.selectedContact = [];
      this.formHeader.patchValue({
        budgetHeader: {
          contact: '',
          contact_id: '',
        },
      });
    }
    this.formHeaderControl.controls['contact'].markAsTouched();
    this.draw();
  }

  saveInitialSequence(): void {
    if (!this._initialSequence && this.mode === TypeOfCRUD.UPDATE) {
      this._initialSequence = {
        sequence_id: this.formHeaderControl.controls['sequence_id'].value,
        numberDoc: this.formHeaderControl.controls['numberDoc'].value,
      };
      this.formHeaderControl.controls['numberDoc'].setErrors(null);
    }
  }

  async changeSequence(evt: RmSelect.Items): Promise<void> {
    let prefix;
    let sequence_id;

    if (evt.length) {
      const [first] = evt;
      prefix = first.value;
      sequence_id = first.id;
    }

    this.formHeader.patchValue({
      budgetHeader: {
        prefix,
        sequence_id,
      },
    });

    if (
      sequence_id === this._initialSequence?.sequence_id &&
      this._initialSequence?.numberDoc
    ) {
      this.formHeaderControl.controls['numberDoc'].patchValue(
        this._initialSequence?.numberDoc
      );
    } else {
      await this.getNextSequenceNumber();
    }
    this.selectedSequence = evt;

    this.onChangeNumberDoc();
    this.formHeaderControl.controls['prefix'].markAsTouched();
    this.formHeaderControl.controls['numberDoc'].markAsTouched();
    this.formHeaderControl.controls['numberDoc'].setErrors(null);
    this.draw();
  }

  changeStatus(evt: RmSelect.Items): void {
    const ids = evt.map((el) => el.value);

    if (ids.length) {
      const [first] = ids;
      this.formHeader.patchValue({
        budgetHeader: {
          status: first,
        },
      });
      this.amplitudeService.sendEvent({
        event: `${this.budgetTypeAmplitude}_card_editStatus`,
      });
    }
    this.draw();
  }

  changeDate() {
    // console.log('changes!');
    // const headerDate = this.formHeaderControl.controls['date'].value;
    // if (this.areDatesCorrect()) {
    //   const date = new Date(headerDate);
    //   // this.header.dueDate = this.dueDate;
    //   const dateObj = getDateStrResum(date);
    //   console.log({ dateObj });
    //   // this.form.updateValueAndValidity();
    //   // this.isValid.emit(this.form.valid);
    //   //let h = this.form.value;
    //   //this.budget.header = h;
    //   // this.date = {dateObj}.formattedDate;
    // }
  }

  changeExpires(evt: RmSelect.Items = null): void {
    if (!evt) {
      const dueDate = (this.formHeader.controls['budgetHeader'] as FormGroup)
        .controls['dueDate'].value;
      evt = [
        {
          title: dueDate,
          value: dueDate,
          id: dueDate,
        },
      ];
      this.expiresSelected = evt;
    }
    const ids = evt.map((el) => el.value);
    if (ids.length) {
      const [first] = ids as BudgetExpiration[];
      this.formHeader.patchValue({
        budgetHeader: {
          dueDate: first,
        },
      });
      this.amplitudeService.sendEvent({
        event: `${this.budgetTypeAmplitude}_card_editExpiration`,
      });
    }

    if (evt.length === 0) {
      this.formHeader.patchValue({
        budgetHeader: {
          dueDate: '',
        },
      });
    }
    // this.budgetUtilis.formatDates(this.formHeaderControl);
    this.draw();
  }

  selectDueDate(): void {
    const date = new Date(this.formHeaderControl.value.date);
    const selectedDueDate = this.datePicker.value
      ? new Date(this.datePicker.value)
      : null;

    const dateObj = getDateStrResum(date);
    if (
      !(selectedDueDate instanceof Date && !isNaN(selectedDueDate.getTime()))
    ) {
      return;
    }
    const dueDateObj = getDateStrResum(selectedDueDate);

    if (new Date(dueDateObj.formattedDate) < new Date(dateObj.formattedDate)) {
      this.toastService.show({
        text: 'budgets.settings.inconsistentDates',
        type: 'error',
        msDuration: 4000,
      });
      return;
    }

    this.formHeaderControl.get('dueDate')?.patchValue(this.datePicker.value);
    const dueDate = this.formHeaderControl.controls['dueDate'].value;
    if (typeof dueDate !== 'string') {
      const dueDateObj = getDateStrResum(dueDate);
      const RmDate = [
        {
          title: dueDateObj.formattedDateStorage,
          value: dueDateObj.formattedDateStorage,
          id: dueDateObj.formattedDateStorage,
        },
      ];
      this.expiresSelected = RmDate;
    }
    this.formHeaderControl.updateValueAndValidity({ emitEvent: true });
  }

  addElementDueDate(): void {
    this.dueDatePicker.open();
  }

  contactDisabled(): boolean {
    return this.isLoadingFinals || !this.canEditContact;
  }

  displaySnackbar(): void {
    if (!this.snackbar && !this.isLoadingFinals) {
      this.snackbar = true;
      setTimeout(() => {
        this.snackbar = false;
        this.draw();
      }, 4000);
    }
  }

  translate(status) {
    return status.map((item) => {
      return {
        ...item,
        name: item?.key ? this.translateService.instant(item?.key) : item.key,
      };
    });
  }

  async editFinals() {
    try {
      const id = await this.createFinalService
        .openEdit(this.selectedContactId)
        .toPromise();

      if (id) {
        this.formHeaderControl.controls['contact'].setErrors(null);
        await this.refreshFinals(id);
        this.changeContact(this.selectedContact);
      }
    } catch (err) {}
  }

  async addFinals(): Promise<void> {
    this.amplitudeService.sendEvent({
      event: `${this.budgetTypeAmplitude}_card_editFinal_start`,
    });

    try {
      const id = await this.createFinalService
        .openCreatePartially()
        .toPromise();

      if (id) {
        await this.refreshFinals(id);
        this.changeContact(this.selectedContact);
      }
    } catch (err) {}
  }

  addSequence(): void {
    const seqType = this.budgetUtilis.budgetTypeToSequenceType(this.budgetType);
    const data = {
      name: '',
      type: seqType,
      incrementValue: 1,
      currentNumber: 1,
      prefix: '',
      idCompany: StorageService.CompanyId,
      disableBudgetList: true,
    };

    this.amplitudeService.sendEvent({
      event: `${this.budgetTypeAmplitude}_card_editSerie_addSerie_start`,
    });

    const dialog = this.dialog.open(CreateSequenceComponent, {
      data: data,
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.requestSequences();
        this.amplitudeService.sendEvent({
          event: `${this.budgetTypeAmplitude}_card_editSerie_addSerie_add`,
        });
      }
    });
  }

  private draw() {
    this.changeDetector.detectChanges();
  }
}
