import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[romaRomaToolTip]',
})
export class RomaToolTipDirective {
  @Input()
  showToolTip = false;

  @Output()
  clicked = new EventEmitter<boolean>();

  isOpen = false;
  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event', '$event.target']) public onClick(
    evt: MouseEvent,
    targetElement: HTMLElement
  ) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside && this.isOpen) {
      this.clicked.emit(false);
    } else {
      this.isOpen = this.showToolTip;
      this.clicked.emit(true);
    }
  }
}
