<roma-modal-template
  [title]="'table.colum.vendors' | translate"
  [showSuccessBtn]="false"
  [showCancelBtn]="false"
  (cancel)="ok()"
>
  <div class="container-item">
    <div class="grid">
      <div class="row">
        <mat-chip-list>
          <mat-chip
          class="example-box michip"
          cdkDrag
          *ngFor="let user of users"
        >
          {{ user.name }}
        </mat-chip>
        </mat-chip-list>
     
      </div>
    </div>
  </div>
</roma-modal-template>
