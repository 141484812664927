export enum BudgetCategoryFields {
  CATEGORY = 'showCategory',
  QUANTITY = 'quantity',
  PRICE = 'showPrice',
  TAX = 'showTax',
  TOTAL = 'showTotal',
  UNIT = 'unit',
  DISCOUNT = 'discount',
}

export enum BudgetExpiration {
  ONE_WEEK = 'one_week',
  TWO_WEEK = 'two_week',
  ONE_MONTH = 'one_month',
  TWO_MONTH = 'two_month',
}

export const BUDGET_EXPIRATION_LIST: BudgetExpiration[] = [
  BudgetExpiration.ONE_WEEK,
  BudgetExpiration.TWO_WEEK,
  BudgetExpiration.ONE_MONTH,
  BudgetExpiration.TWO_MONTH,
];
