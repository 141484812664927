import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { I18nChild } from '@web-frontend/shared/i18n';
import { BasicMessageModule } from '@web-frontend/shared/components/basic-message';
import { LoadingModule } from '@web-frontend/shared/components/loading';
import { FilterModule } from '@web-frontend/shared/components/filter';
import { TableModule } from '@web-frontend/shared/components/table';

import { TranslateModule } from '@ngx-translate/core';
import { MatGridListModule } from '@angular/material/grid-list';

import {
  BudgetsEditComponent,
  BudgetUtilisService,
} from './budgets-edit.component';

import { BudgetHeaderModule } from '@web-frontend/shared/components/budget-header/budget-header.module';
import { BudgetItemsModule } from '@web-frontend/shared/components/budget-items/budget-items.module';
import { BudgetFooterModule } from '@web-frontend/shared/components/budget-footer/budget-footer.module';
import { BudgetTotalModule } from '@web-frontend/shared/components/budget-total/budget-total.module';
import { BudgetPreviewerModule } from '@web-frontend/shared/components/budget-previewer/budget-previewer.module';

import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';

import { MatMenuModule } from '@angular/material/menu';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { ModalSendModule } from '@web-frontend/shared/components/modal-send';
import { PermissionsModule } from '@web-frontend/shared/components/permissions';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BudgetLogoComponent } from '@web-frontend/shared/components/budget-logo/budget-logo.component';
import { BudgetLogoModule } from '@web-frontend/shared/components/budget-logo/budget-logo.module';
import { ClickSequenceModule } from '@web-frontend/shared/directives/click-sequence/click-sequence.module';

const providers = [
  BudgetUtilisService,
  { provide: MAT_DIALOG_DATA, useValue: {} },
  { provide: MatDialogRef, useValue: {} },
];

@NgModule({
  declarations: [BudgetsEditComponent],
  imports: [
    CommonModule,
    FilterModule,
    LoadingModule,
    BasicMessageModule,
    TableModule,
    I18nChild,
    TranslateModule.forChild(),
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    BudgetHeaderModule,
    BudgetItemsModule,
    BudgetFooterModule,
    BudgetTotalModule,
    MatDialogModule,
    MatMenuModule,
    ClickDeleteModule,
    ModalSendModule,
    PermissionsModule,
    ClickSequenceModule,
    MatProgressSpinnerModule,
    BudgetLogoModule,
    BudgetPreviewerModule,
  ],
  providers: [...providers, DatePipe],
})
export class BudgetsEditModule {}
