<div class="main-container">
  <div class="row">
    <h1 class="page-title">{{ title | translate }}</h1>
  </div>

  <div *ngIf="!isLoading && !isLoadingSettings" class="container-logo">
    <div class="logo-component">
      <roma-budget-logo
        [id]="defaultSettings?._id"
        [budget]="defaultSettings"
        [mode]="'EDIT'"
        [center]="false"
        [editable]="isAdmin$ | async"
        [showLabels]="false"
      >
      </roma-budget-logo>
    </div>
    <div class="btn-settings">
      <button
        class="roma-button add-button-3"
        (click)="
          goToUrl(isAdmin, '/admin/settings/general')
        "
      >
        {{ 'settings.edit_settings' | translate }}
      </button>
    </div>
    <div class="btn-options">
      <button class="btn-download" [matMenuTriggerFor]="addMenu">
        {{ 'filter.options' | translate }}
      </button>

      <button class="btn-download" (click)="exit()">
        {{ 'filter.save' | translate }}
      </button>
    </div>
  </div>

  <div *ngIf="isLoading || isLoadingSettings" class="col-12">
    <roma-loading></roma-loading>
  </div>
  <div class="col-12" style="text-align: center">
    <div *ngIf="isLoadingFile" class="loading">
      <roma-loading [size]="80"></roma-loading>
      <h3 class="animate__flash animate__delay-2s messages">
        {{ 'budget.pdf' | translate }}
      </h3>
    </div>
  </div>

  <!-- HEADER -->
  <div class="row mt-10" *ngIf="!isLoading && !isLoadingSettings">
    <roma-budget-header
      *ngIf="header"
      [type]="type"
      [header]="header"
      [budget]="budget"
      [mode]="'EDIT'"
      [idDeal]="idDeal"
      [customerId]="customerId"
      [featureUser]="featureUserScreen"
      (isValid)="onChangeHeaderValid($event)"
      (changedHeader)="changedModel($event, 'header')"
    ></roma-budget-header>
  </div>

  <!-- ITEMLIST -->
  <div class="row mt-10" *ngIf="!isLoading && !isLoadingSettings">
    <roma-budget-items
      [settings]="this.AllSettings"
      (changedItems)="changedModel($event, 'items')"
      [mode]="'EDIT'"
      [type]="type"
      [id]="idBudget"
      [budget]="budget"
      [taxes]="taxes"
      [validForm]="validheader"
      [featureUser]="featureUserScreen"
      (addClick)="OnAddClick($event)"
      (deleteClick)="OnDeleteClick($event)"
      (changeDiscount)="OnAddDiscount($event)"
    ></roma-budget-items>
  </div>

  <!-- FOOTTER -->
  <div class="row mt-10" *ngIf="!isLoading && !isLoadingSettings">
    <roma-budget-footer
      [mode]="'EDIT'"
      [id]="idBudget"
      [budget]="budget"
      [type]="type"
      [messages]="messages"
      [addicionalInfo]="addicionalInfo"
      [typePayments]="typePayments"
      [settings]="settings"
      [validForm]="validheader"
      [featureUser]="featureUserScreen"
      (changedFooter)="changedModel($event, 'footer')"
    ></roma-budget-footer>
  </div>

  <!-- TOTAL -->
  <div class="row mt-10" *ngIf="!isLoading && !isLoadingSettings">
    <roma-total-footer
      [mode]="'EDIT'"
      [budget]="budget"
      [settings]="settings"
      [show]="showTotal"
    ></roma-total-footer>
  </div>
</div>

<mat-menu #addMenu="matMenu" xPosition="before" [class]="'budget-menu'">
  <button mat-menu-item [disabled]="downloadingPreview" (click)="showPreview()">
    <mat-icon class="material-icons"> visibility </mat-icon>
    {{ 'budget.preview' | translate }}

    <mat-progress-spinner
      *ngIf="downloadingPreview"
      class="loading-spinner"
      mode="indeterminate"
      [diameter]="20"
    ></mat-progress-spinner>
  </button>

  <div
    romaProSelect
    class="wrapper-pro-feature-user"
    [featureUser]="featureRefUser.Proform.create"
  >
    <button
      *ngIf="canConvertToProform()"
      mat-menu-item
      romaClickSequence
      [type]="this.type"
      [typeToConvert]="typeRef.PROFORM"
      [idBudgetToConvert]="this.idBudget"
    >
      <mat-icon class="material-icons"> description </mat-icon>
      {{ 'budget.convertProforma' | translate }}
    </button>
  </div>

  <div
    romaProSelect
    class="wrapper-pro-feature-user"
    [featureUser]="featureRefUser.Bill.create"
  >
    <button
      *ngIf="canConvertToBill()"
      mat-menu-item
      romaClickSequence
      [type]="this.type"
      [typeToConvert]="typeRef.BILL"
      [idBudgetToConvert]="this.idBudget"
    >
      <mat-icon class="material-icons"> description </mat-icon>
      {{ 'budget.convertInvoice' | translate }}
    </button>
  </div>
  <!-- Send by email -->
  <button
    *ngIf="type === 'BDG'"
    romaProFeature
    [feature]="featureRef.Sale.SendBudgetEmail"
    mat-menu-item
    (clicked)="sendDocument()"
  >
    <mat-icon class="material-icons"> send </mat-icon>
    {{ 'budget.sendEmail' | translate }}
    <div class="roma-label warning icon-star-pro-feature">
      <div class="icon">
        <img src="assets/icons/star.svg" />
      </div>
      PRO
    </div>
  </button>
  <button
    *ngIf="type === 'BILL'"
    romaProFeature
    [feature]="featureRef.Sale.SendBillEmail"
    mat-menu-item
    (clicked)="sendDocument()"
  >
    <mat-icon class="material-icons"> send </mat-icon>
    {{ 'budget.sendEmail' | translate }}
    <div class="roma-label warning icon-star-pro-feature">
      <div class="icon">
        <img src="assets/icons/star.svg" />
      </div>
      PRO
    </div>
  </button>
  <button
    *ngIf="type === 'PROFM'"
    romaProFeature
    [feature]="featureRef.Sale.SendProformEmail"
    mat-menu-item
    (clicked)="sendDocument()"
  >
    <mat-icon class="material-icons"> send </mat-icon>
    {{ 'budget.sendEmail' | translate }}
    <div class="roma-label warning icon-star-pro-feature">
      <div class="icon">
        <img src="assets/icons/star.svg" />
      </div>
      PRO
    </div>
  </button>

  <button mat-menu-item [disabled]="downloading" (click)="downloadDocument()">
    <mat-icon class="material-icons"> download </mat-icon>
    {{ 'budget.donwload' | translate }}
    <mat-progress-spinner
      *ngIf="downloading"
      class="loading-spinner"
      mode="indeterminate"
      [diameter]="20"
    ></mat-progress-spinner>
  </button>
</mat-menu>

<mat-menu #menuConvert="matMenu">
  <!-- <button mat-menu-item (click)="convertToBill()">Facturas</button> -->
</mat-menu>
