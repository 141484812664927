import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[romaOnlyNumber]',
})
export class OnlyNumberDirective {
  private specialKeys: Array<string> = [
    'Backspace',
    'ArrowLeft',
    'ArrowRight',
    'Tab',
    'Enter',
    'Escape',
    'Delete',
  ];

  @Input()
  maxDecimals = 2;

  @Input()
  onlyPositive = false;

  @Input()
  allowDecimalSeparators = true;

  constructor() {
    if (this.maxDecimals > 0) {
      this.specialKeys.push('.');
      this.specialKeys.push(',');
    } else {
      this.maxDecimals = 0;
    }
  }

  checkRegex(value: string): boolean {
    let regex = /^[0-9]*$/;
    if (this.maxDecimals > 0 && this.allowDecimalSeparators) {
      regex = /^[0-9.,]*$/;
    }
    return regex.test(value);
  }

  hasValidDecimals(value: string): boolean {
    const parts = value.split(/[.,]/);
    return (
      parts.length <= 2 && (!parts[1] || parts[1].length <= this.maxDecimals)
    );
  }

  checkOnlyPositive(value: string): boolean {
    const number = parseFloat(value);
    if (!this.onlyPositive) {
      return true;
    }
    return this.maxDecimals > 0 ? number >= 0 : number > 0;
  }

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    if (value && !this.checkOnlyPositive(value)) {
      input.value = '1';
    }
  }

  @HostListener('keypress', ['$event'])
  onKeypress(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    const currentValue = input.value;
    const newValue = currentValue + event.key;

    if (
      !this.checkRegex(newValue) ||
      !this.hasValidDecimals(newValue) ||
      !this.checkOnlyPositive(newValue)
    ) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const pasteData = event.clipboardData.getData('text');
    const input = event.target as HTMLInputElement;
    const currentValue = input.value;
    const newValue = currentValue + pasteData;

    if (
      !this.checkRegex(pasteData) ||
      !this.hasValidDecimals(newValue) ||
      !this.checkOnlyPositive(newValue)
    ) {
      event.preventDefault();
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    const dropData = event.dataTransfer.getData('text');
    const input = event.target as HTMLInputElement;
    const currentValue = input.value;
    const newValue = currentValue + dropData;

    if (
      !this.checkRegex(dropData) ||
      !this.hasValidDecimals(newValue) ||
      !this.checkOnlyPositive(newValue)
    ) {
      event.preventDefault();
    }
  }
}
