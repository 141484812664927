import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CreateRecurrentPaymentsLinkService {
  constructor(private http: HttpClient) {}

  generate(payload: {
    ticketId: string;
    products: { price: number; quantity: number; name: string }[];
    interval: 'week' | 'month' | 'year';
  }) {
    return this.http.post(':API_URL/payments/create-recurrent-payment-link', {
      ...payload,
    });
  }
}
