import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ModalErrorMaintenanceComponent } from './modal-error-maintenance.component';
import { ModalErrorMaintenanceService } from './modal-error-maintenance.service';

@NgModule({
  declarations: [ModalErrorMaintenanceComponent],
  imports: [CommonModule, TranslateModule.forChild(), MatDialogModule],
  providers: [ModalErrorMaintenanceService],
})
export class ModalErrorMaintenanceModule {}
