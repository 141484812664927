<div class="row">
  <div *ngIf="!parameterized" class="col-12">
    <roma-filter-tracking
      (dateChanged)="setDateTracking($event)"
      (markersDealsChanged)="setMarkersDeals($event)"
      (markersUsersChanged)="setMarkersUsers($event)"
      (selectedUsers)="setUsersSelected($event)"
      (polyLinesChanged)="setPolyLines($event)"
      (usersListLoaded)="setUsersList($event)"
      (dealsListLoaded)="setDealsList($event)"
    >
    </roma-filter-tracking>
  </div>

  <div class="col-12">
    <div class="btn-container">
      <button
        mat-button
        class="btn button m-10"
        [disabled]="!enableReload"
        (click)="reloadMap()"
      >
        {{ 'general.refresh' | translate }}
      </button>
      <button
        mat-button
        class="btn button m-10"
        [ngClass]="{ hidden: !(isAdmin$ | async) }"
        [disabled]="!enableNotification"
        (click)="sendNotification()"
      >
        {{ 'general.sendPushNotif' | translate }}
      </button>
      <button
        mat-button
        class="btn button m-10"
        [ngClass]="{ hidden: (isAdmin$ | async) }"
        (click)="askCurrentLocation()"
      >
        {{ 'general.sendNotif' | translate }}
      </button>
    </div>
    <div class="map-container">
      <google-map width="100%" height="500px" [center]="center" [zoom]="zoom">
        <ng-container *ngIf="users.length > 0">
          <map-marker
            #markerElem="mapMarker"
            *ngFor="let marker of this.getMarkersUsers()"
            [position]="marker.position"
            [label]="marker.label"
            [title]="marker.title"
            [options]="marker.options"
            (mapClick)="openInfoUser(markerElem, marker)"
          >
          </map-marker>
        </ng-container>

        <ng-container *ngIf="dealsItems.length > 0">
          <map-marker
            #markerDeal="mapMarker"
            *ngFor="let marker of this.getMarkersDeals()"
            [position]="marker.position"
            [label]="marker.label"
            [title]="marker.title"
            [options]="marker.options"
            (mapClick)="openInfoDeal(markerDeal, marker.id)"
          >
          </map-marker>
        </ng-container>

        <map-info-window>
          <div class="pill" [innerHTML]="infoContent"></div>
        </map-info-window>

        <map-polyline *ngFor="let option of linesMap" [options]="option">
        </map-polyline>
      </google-map>

      <!--  <button mat-button class="btn button m-10" (click)="addRandomMarker()">agregar marcardor</button> -->
    </div>
  </div>
</div>
