import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  FileUploadRes,
  FileUploadService,
} from '@web-frontend/shared/helpers/file-upload';

@Injectable({
  providedIn: 'root',
})
export class FilesUploadService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    private fileUploadService: FileUploadService
  ) {
    this.baseUrl = ':API_URL';
  }

  // TODO JOSE THIS PARAMS FILES IS TYPE File
  uploadFiles(type: string, files): Observable<FileUploadRes[]> {
    const formData: FormData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }

    return this.http.post<FileUploadRes[]>(
      `${this.baseUrl}/file-upload/${type}`,
      formData
    );
  }

  uploadFile(type: string, file): Observable<FileUploadRes[]> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post<FileUploadRes[]>(
      `${this.baseUrl}/file-upload/${type}`,
      formData
    );
  }

  deleteFile(type: string, img: string) {
    return this.http.delete(`${this.baseUrl}/file-upload/${type}/${img}`);
  }

  deleteFiles(toDelete: { type: string; img: string }) {
    return this.http.post(`${this.baseUrl}/file-upload/delete`, toDelete);
  }

  convertImages(files: []) {
    const result = [];
    files.forEach((file) => {
      if (Array.isArray(file)) {
        file = file[0];
      }
      result.push(file);
    });
    return result;
  }
}
