import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CreateBudgetService } from '../create-budget/create-budget.service';
import { tooltipMessage } from '@web-frontend/shared/directives/tooltip/tooltip.component';

@Component({
  selector: 'roma-modal-mobile-info',
  templateUrl: './modal-mobile-info.component.html',
  styleUrls: ['./modal-mobile-info.component.scss'],
})
export class ModalMobileInfoComponent implements OnInit {
  @Input()
  link = '';

  @Input()
  title = '';

  @Input()
  tooltipUrl = '';

  @Input()
  html: any;

  @Input()
  messages: tooltipMessage[] = null;

  @Input()
  buttonText: string;

  @Input()
  internal = false;

  constructor(
    private dialogRef: MatDialogRef<ModalMobileInfoComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly router: Router,
    private readonly createBudgetService: CreateBudgetService
  ) {}

  ngOnInit(): void {
    this.html = this.data?.html;
    this.messages = this.data?.messages;
    this.link = this.data?.link;
    this.tooltipUrl = this.data?.tooltipUrl;
    this.buttonText = this.data?.buttonText;
    this.internal = this.data?.internal;
  }

  goTo(link: string) {
    this.router.navigateByUrl(link).then(() => {
      this.close();
      this.createBudgetService.closeModalSource.next(true);
    });
  }

  close() {
    this.dialogRef.close();
  }
}
