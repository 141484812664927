import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ImageModule } from '@web-frontend/shared/pipes/image';
import { AlertModule } from '@web-frontend/shared/helpers/alert';

import { DealCancelComponent } from './deal-cancel.component';
import { ModalTemplateModule } from '../modal-template';
import { PictureModule } from '../picture/picture.module';

@NgModule({
  declarations: [DealCancelComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    MatIconModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatCheckboxModule,
    MatButtonModule,
    PictureModule,
    ImageModule,
    MatDialogModule,
    AlertModule,
  ],
  exports: [DealCancelComponent],
})
export class DealCancelModule {}
