import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TacliaPayments } from '@tacliatech/types';

@Component({
  selector: 'roma-modal-confirm-payment',
  templateUrl: './modal-confirm-payment.component.html',
  styleUrls: ['./modal-confirm-payment.component.scss'],
})
export class ModalConfirmPaymentComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: TacliaPayments.CreditCard.ModalRequest,
    private dialogRef: MatDialogRef<ModalConfirmPaymentComponent>
  ) {}
  response: TacliaPayments.CreditCard.ModalResponse;
  ngOnInit(): void {
    this.response = { confirmPayment: false };
  }

  close() {
    this.response.confirmPayment = false;
    this.dialogRef.close(this.response);
  }

  submit() {
    this.response.confirmPayment = true;
    this.dialogRef.close(this.response);
  }
}
