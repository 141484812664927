import { ElementRef, Renderer2 } from '@angular/core';

export const injectScript = (
  renderer: Renderer2,
  elementRef: ElementRef,
  scriptSrc: string, // Script source, can be a URL or a plain script
  plain?: boolean
) => {
  const script = renderer.createElement('script');

  if (plain) {
    script.innerText = scriptSrc;
  } else {
    script.src = scriptSrc;
  }

  renderer.appendChild(elementRef.nativeElement, script);
};
