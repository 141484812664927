import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  loadConnectAndInitialize,
  StripeConnectInstance,
} from '@stripe/connect-js';
import { PaymentsService } from '../services/payments.service';
import { SandboxService } from '@web-frontend/shared/services';
import { Lang } from '@tacliatech/types';
import { finalize, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from '@web-frontend/environments';
import { VideoService } from '@web-frontend/shared/components/modal-video/video.service';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { TutorialService } from '@web-frontend/shared/services/tutorial';
import { MatDialog } from '@angular/material/dialog';
import { ModalMobileInfoComponent } from '@web-frontend/shared/components/modal-mobile-info';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';

@Component({
  selector: 'roma-payment-dashboard',
  templateUrl: './payment-dashboard.component.html',
  styleUrls: ['./payment-dashboard.component.scss'],
})
export class PaymentDashboardComponent
  implements OnInit, AfterViewInit, OnDestroy {
  // stripeConnect: StripeConnectWrapper;
  dashboardInstance: StripeConnectInstance;
  locale: string;
  sub$ = new Subscription();

  @ViewChild('dashboard', { read: ElementRef })
  dashboardRef: ElementRef;

  stripeErrorObject: {
    error: string;
    message: string;
    statusCode: number;
  };
  resize$ = this.sandBoxService.screenBusChannel$;
  showVideoTutorialButton = false;
  isLoading = false;
  academyLink = '';
  showToolTip = false;
  iconDialog = 'assets/icons/gl_title-information-outline.svg';

  constructor(
    private paymentsService: PaymentsService,
    private i18n: TranslateService,
    private renderer: Renderer2,
    private video: VideoService,
    private sandBoxService: SandboxService,
    public tutorialService: TutorialService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.resolveLanguage();
    this.watchTranslation();
    this.sub$.add(
      this.tutorialService
        .get('payments')
        .pipe(
          finalize(() => {
            this.draw();
          })
        )
        .subscribe((res) => {
          this.academyLink = res?.academy;
          if (res?.source) {
            this.showVideoTutorialButton = !!res.source;
          } else {
            this.showVideoTutorialButton = false;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.loadAccountSession();
  }

  async fetchClientSecret() {
    const ret = await this.paymentsService.createAccountSession().toPromise();
    return ret.client_secret;
  }

  loadAccountSession() {
    this.dashboardInstance = loadConnectAndInitialize({
      publishableKey: environment.stripeConfig.public_key,
      fetchClientSecret: () => this.fetchClientSecret(),
      fonts: [
        {
          cssSrc: `${environment.cdn}/styles/fonts.css`,
        },
      ],
      appearance: {
        variables: {
          colorPrimary: '#3C48EC',
          colorBackground: '#FFFFFF',
          buttonPrimaryColorBackground: '#3C48EC',
          buttonPrimaryColorBorder: '#3C48EC',
          buttonPrimaryColorText: '#FFFFFF',
          buttonSecondaryColorBackground: '#FFFFFF',
          buttonSecondaryColorBorder: '#3C48EC',
          buttonSecondaryColorText: '#3C48EC ',
          colorText: '#15152E',
          colorSecondaryText: '#343E5E',
          actionPrimaryColorText: '#3C48EC',
          actionSecondaryColorText: '#5D6B98',
          colorBorder: '#C1CDF5',
          formHighlightColorBorder: '#15152E',
          formAccentColor: '#0BBF62 ',
          colorDanger: '#F73949',
          offsetBackgroundColor: '#F7F9FF',
          formBackgroundColor: '#FFFFFF',
          badgeNeutralColorText: '#5D6B98',
          badgeNeutralColorBackground: '#E8EDFF',
          badgeNeutralColorBorder: '#E8EDFF',
          badgeSuccessColorText: '#066D38',
          badgeSuccessColorBackground: '#E1F7EB',
          badgeSuccessColorBorder: '#E1F7EB',
          badgeWarningColorText: '#905E09',
          badgeWarningColorBackground: '#FFF1D9',
          badgeWarningColorBorder: '#FFF1D9',
          badgeDangerColorText: '#F73949',
          badgeDangerColorBackground: '#FEEBED',
          badgeDangerColorBorder: '#FEEBED ',
          // sizing
          borderRadius: '12px',
          buttonBorderRadius: '24px',
          formBorderRadius: '8px',
          badgeBorderRadius: '6px',
          spacingUnit: '12px',
          fontFamily: 'Metropolis-Regular',
          overlayBorderRadius: '8px',
        },
      },
      locale: this.locale,
    });
    this.restoreDashboard();
    const dashboardElement = this.dashboardRef.nativeElement;
    dashboardElement.appendChild(this.dashboardInstance.create('payments'));
  }

  restoreDashboard() {
    try {
      const dashboardElement = this.dashboardRef.nativeElement;
      this.renderer.removeChild(dashboardElement, dashboardElement.firstChild);
    } catch (err) {}
  }

  private resolveLanguage() {
    const lang = this.i18n.currentLang as Lang;

    this.locale = lang;
  }

  private watchTranslation() {
    this.sub$.add(
      this.i18n.onLangChange.subscribe(() => {
        this.resolveLanguage();
        this.loadAccountSession();
      })
    );
  }

  generateUpdateSession() {
    this.isLoading = true;
    this.draw();

    this.sub$.add(
      this.paymentsService
        .generateUpdateSession()
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          if ('url' in res) {
            window.open(res['url'] as string, '_blank');
          }
        })
    );
  }

  openVideo(type: string) {
    if (this.showVideoTutorialButton) {
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: AmplitudeEvents.payment_play_tutorial,
      });
      this.video.open(type);
    }
  }

  getLang() {
    return this.i18n.currentLang;
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  /********************************************TOOLTIP************************************************** */
  toggleTooltip(evt) {
    this.showToolTip = evt;
    if (!this.showToolTip) {
      this.iconDialog = 'assets/icons/gl_title-information-outline.svg';
    } else {
      this.iconDialog = 'assets/icons/gl_information-clicked.svg';
    }
  }

  showDialog(): void {
    const isMobile = window.innerWidth < 768;
    if (!isMobile) {
      this.showToolTip = !this.showToolTip;
      this.toggleTooltip(this.showToolTip);
    } else {
      const dialog = this.dialog.open(ModalMobileInfoComponent, {
        panelClass: 'payment-info-mobile',
        data: {
          html: 'payments.tooltip',
          link: this.academyLink,
          tooltipUrl: 'payments.tooltipUrl',
          buttonText: 'kanban.dialog.confirmButton',
        },
      });
    }
  }
}
