import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileModalListComponent } from './mobile-modal-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyFormatModule } from '@web-frontend/shared/pipes/currency-format/currency-format.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [MobileModalListComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    CurrencyFormatModule,
    MatProgressSpinnerModule,
  ],
  exports: [MobileModalListComponent],
})
export class MobileModalListModule {}
