import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TacliaPayments } from '@tacliatech/types';
import { PaymentOnbardingProcessComponent } from '../payment-onbarding-process/payment-onbarding-process.component';

@Injectable({
  providedIn: 'root',
})
export class OnboardingModalService {
  private resultSource = new Subject<boolean>();

  result$ = this.resultSource.asObservable();

  constructor(private dialog: MatDialog) {}

  private refreshOnboardingLink = new Subject<boolean>();
  refreshOnboardingLinkFromModal$ = this.refreshOnboardingLink.asObservable();

  open(config?: MatDialogConfig<TacliaPayments.CreditCard.ModalRequest>) {
    const dialogRef = this.dialog.open(
      PaymentOnbardingProcessComponent,
      config
    );

    return dialogRef.afterClosed().pipe(
      tap((res: any) => {
        this.resultSource.next(res);
        this.refreshOnboardingLink.next(true);
      })
    );
  }
}
