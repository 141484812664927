import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheet,
  MatBottomSheetConfig,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { Feature } from '@tacliatech/types';
import { SandboxService } from '@web-frontend/shared/services';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { Subscription } from 'rxjs';
import { DownloadType } from '../download-btn.component';
import { DownloadBtnService } from '../download-btn.service';

@Component({
  selector: 'roma-shared-download-mobile-btn',
  templateUrl: './download-btn-mobile.component.html',
  styleUrls: ['./download-btn-mobile.component.scss'],
})
export class DownloadBtnMobileComponent implements OnInit, OnDestroy {
  featureRef = Feature;
  featureExcel: Feature.Purchasable = this.featureRef.SystemPermission
    .DefaultAllow;
  featurePdf: Feature.Purchasable = this.featureRef.SystemPermission
    .DefaultAllow;
  showDownloadExcel = false;
  showDownloadPdf = false;

  sub$ = new Subscription();
  type = DownloadType;

  constructor(
    private permissionService: PermissionService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<DownloadBtnMobileComponent>,
    private readonly downloadBtnService: DownloadBtnService
  ) {
    this.featureExcel = data.featureExcel;
    this.featurePdf = data.featurePdf;
    this.showDownloadExcel = data.showDownloadExcel;
    this.showDownloadPdf = data.showDownloadPdf;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  get hasFeaturePdf() {
    return this.permissionService.hasFeatureFn(this.featurePdf);
  }

  get hasFeatureExcel() {
    return this.permissionService.hasFeatureFn(this.featureExcel);
  }

  closeBottomSheet(type: DownloadType | null = null) {
    this.bottomSheetRef.dismiss(type);
  }

  triggerDownload(type: DownloadType) {
    this.downloadBtnService.isLoading.next(true);
    this.closeBottomSheet(type);
  }
}
