/* eslint-disable @typescript-eslint/no-namespace */
import { IUser } from '../../interfaces';
import { differenceInCalendarDays } from 'date-fns';

export namespace ClockAbsence {
  export interface Schema {
    dateCreated: Date;
    idCreatedBy: string;
    idOwner: string;
    idCompany: string;
    type: ClockAbsence.AbsenceType;
    typeDetail: ClockAbsence.TypeDetail;
    dateDetail: ClockAbsence.dateDetail;
    status: ClockAbsence.Status;
    note: string;
    deleted: boolean;
    reasonRejected: {
      description: string;
      idCreatedBy: string;
    };
  }

  export enum Status {
    Pending,
    Rejected,
    Approved,
    Other,
  }

  export interface dateDetail {
    startDate: string;
    startDateUTC: Date;
    endDate: string;
    endDateUTC: Date;
  }

  export interface TypeDetail {
    type: ClockAbsence.Type;
    halfDayType?: ClockAbsence.HalfDayType;
    files?: string[];
  }

  export enum Type {
    HalfDay,
    OneDay,
    SeveralDays,
  }

  export enum HalfDayType {
    FirstHalfOfTheDay,
    SecondHalfOfTheDay,
  }

  export enum AbsenceType {
    Vacation,
    Sickness,
    MaternityOrPaternity,
    SicknessOfAFamilyMember,
    Other,
  }

  export interface Output extends ClockAbsence.Schema {
    _id: string;
    owner: IUser;
    totalDays?: number;
  }

  export interface ClockDate {
    year: number;
    month: number;
    day: number;
  }

  export interface Query {
    idCompanies: string[];
    idOwners?: string[];
    keyword?: string;
    status?: ClockAbsence.Status[];
    types?: ClockAbsence.AbsenceType[];
    to?: string;
    from?: string;
    page?: number;
    limit?: number;
    ids: string[];
    applyPaginate?: boolean;
  }

  export const GetTotalDays = ({
    startDate,
    endDate,
    type,
  }: {
    startDate: string;
    endDate: string;
    type: ClockAbsence.Type;
  }) => {
    if (type === ClockAbsence.Type.HalfDay) {
      return 0.5;
    }

    if (type === ClockAbsence.Type.OneDay) {
      return 1;
    }

    if (!endDate) {
      return 1;
    }

    const totalDays =
      differenceInCalendarDays(new Date(endDate), new Date(startDate)) + 1;
    return totalDays < 0 ? 0 : totalDays;
  };
}
