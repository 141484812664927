<div class="hour-input">
  <div class="wrapper">
    <input
      [(ngModel)]="hour"
      placeholder="0"
      type="number"
      class="form-control hour editMode"
      [ngClass]="{ editMode: editMode }"
      [disabled]="disabled"
      max="23"
      min="0"
      inputmode="numeric"
      pattern="[0-9]*"
      (ngModelChange)="onChangeInputHour()"
      (keypress)="validateKeyPress('hour', $event)"
      (focus)="onFocus()"
    />
    <span class="">h</span>
  </div>

  <div class="wrapper">
    <input
      [(ngModel)]="minutes"
      type="number"
      class="form-control minutes editMode"
      [ngClass]="{ editMode: editMode }"
      [disabled]="disabled"
      placeholder="0"
      pattern="[0-9]*"
      min="0"
      max="59"
      inputmode="numeric"
      (ngModelChange)="onChangeInputMinute()"
      (keypress)="validateKeyPress('minutes', $event)"
      (focus)="onFocus()"
    />
    <span class="">min</span>
  </div>

  <div *ngIf="showEditButtons" class="container-options">
    <button mat-icon-button color="primary" (click)="onClickAccept()">
      <mat-icon>done</mat-icon>
    </button>
    <button mat-icon-button color="primary" (click)="close()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
