import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { Feature, FeatureUser } from '@tacliatech/types';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { Subscription } from 'rxjs';
import { CreateFinalV2Component } from '../create-final-v2.component';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';

@Component({
  selector: 'roma-wrapper-sales',
  templateUrl: './wrapper-sales.component.html',
  styleUrls: ['./wrapper-sales.component.scss'],
})
export class WrapperSalesComponent implements OnInit {
  @Input()
  type: 'ADD' | 'EDIT' = 'ADD';

  @Input()
  customerId = '';

  @Input()
  customerName = '';

  @Input()
  isLoading = false;

  isClickedBtnBill = false;
  isClickedBtnBudget = false;
  currentTabIndex = 0;

  featureRefUser = FeatureUser;
  private sub$ = new Subscription();

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<CreateFinalV2Component>,
    private permissionService: PermissionService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  havePermission(permission: Feature.Purchasable | FeatureUser.Purchasable) {
    return this.permissionService.allowedFeature(permission);
  }
  getSelectedIndex(): number {
    return this.currentTabIndex;
  }

  onTabChange(event: MatTabChangeEvent) {
    let eventTracking = '';
    if (event.index == 0) {
      eventTracking = AmplitudeEvents.deal_card_sale_budget_view;
    }
    if (event.index == 1) {
      eventTracking = AmplitudeEvents.final_card_sale_bill_view;
    }
    if (event.index == 2) {
      eventTracking = AmplitudeEvents.final_card_waybill_tab;
    }
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: eventTracking,
    });

    setTimeout(() => {
      this.currentTabIndex = event.index;
    });
  }

  addBudget(type: string, deal: string) {
    let eventTracking = '';
    if (type == 'BDG') {
      eventTracking = AmplitudeEvents.deal_card_sale_budget_start;
    } else {
      if (type == 'BILL') {
        eventTracking = AmplitudeEvents.deal_card_sale_bill_start;
      } else {
        eventTracking = AmplitudeEvents.deal_card_sale_waybill_start;
      }
    }
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: eventTracking,
    });
    this.router.navigate(['/admin/budgets/edit'], {
      queryParams: { type, customerId: this.customerId },
    });
  }

  disableButtonBill(value: boolean) {
    this.isClickedBtnBill = value;
  }

  disableButtonBudget(value: boolean) {
    this.isClickedBtnBudget = value;
  }

  close() {
    this.dialogRef.close(true);
  }
}
