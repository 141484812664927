import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  AdditionalInfoBudget,
  BudgetCommonData,
  BudgetPreferences,
  Company,
  Feature,
  FeatureUser,
  IAdditionalInfoBudget,
  IBudgetCommonData,
  IBudgetsSetting,
  IMessageBudget,
  IPaymentInfoBudget,
  IProduct,
  IProject,
  IProjectOut,
  MessageBudget,
  PaginateSort,
  PaymentInfoBudget,
  TypeBudget,
} from '@tacliatech/types';
import { Product, Project } from '@web-frontend/shared/class';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import { translatePaymentsName } from '@web-frontend/shared/helpers/translations/translate-util';
import { ProjectService, StorageService } from '@web-frontend/shared/services';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { CompanyModuleService } from '@web-frontend/shared/services/company';
import { ProductService } from '@web-frontend/shared/services/products';
import { RemoveEmpty } from '@web-frontend/shared/utils';

import { Subscription } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';
import { BudgetUtilisService } from '../../../core/admin/budgets/budget-edit/budgets-edit.component';
import { CreateProjectService } from '../create-project';
import { SelectSearch } from '../select-search';

@Component({
  selector: 'roma-budget-footer',
  templateUrl: './budget-footer.component.html',
  styleUrls: ['./budget-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetFooterComponent implements OnInit, OnDestroy {
  /*   INPUTS
 ..............................................*/

  @Input()
  id: string;

  @Input()
  messages: IMessageBudget;

  @Input()
  settings: BudgetPreferences = new BudgetPreferences();

  @Input()
  typePayments: IPaymentInfoBudget[];

  @Input()
  addicionalInfo: IAdditionalInfoBudget;

  @Input()
  mode: 'ADD' | 'EDIT' = 'ADD';

  @Input()
  budget: BudgetCommonData = new BudgetCommonData();

  @Input()
  featureUser: Feature.Purchasable | FeatureUser.Purchasable =
    Feature.SystemPermission.DefaultAllow;
  @Output()
  changedFooter = new EventEmitter<unknown>();

  @Input()
  type: string;

  @Input()
  validForm: boolean;

  /*   PROPERTIES
 ..............................................*/

  private sub$ = new Subscription();
  canEditBasic = true;
  paymentsMethods: PaymentInfoBudget[];
  paymentAssigned: PaymentInfoBudget = new PaymentInfoBudget();
  projectAssigned: IProject;
  categoryAssigned: Product = new Product();
  projects: IProject[];
  producsList: { _id: string; name: string }[] = [];
  isLoading = true;
  isProductsLoading = true;
  selectType = SelectSearch.DatabaseAdd;
  selectedPayment: string;
  featureRef = Feature;
  featureRefUser = FeatureUser;

  idActiveModules$ = this.companyModuleService.idActiveModules$;
  idModuleRef = Company.IdModule;

  private searchParams = {
    page: 1,
    sort: PaginateSort.ASC,
    applyPaginate: false,
    keyword: null,
    'sortOptions[created_at]': 'desc',
    takeDeleted: null,
    'ids[]': [],
    'clients[]': [],
    'owners[]': [],
    'users[]': [],
  };

  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private budgetService: BudgetService,
    private projectService: ProjectService,
    private alertService: AlertService,
    private i18n: TranslateService,
    private createProjectService: CreateProjectService,
    public productService: ProductService,
    private budgetUtilisService: BudgetUtilisService,
    private companyModuleService: CompanyModuleService
  ) {
    this.fillLists();
  }

  form = this.fb.group({
    messages: new FormControl(null),
    payment_method: new FormControl(null, [Validators.required]),
    category_id: new FormControl(null, [Validators.required]),
    project_id: new FormControl(null, [Validators.required]),
  });

  ngOnInit(): void {
    this.resolveMode();
    this.watchRefreshList();
    //this.createPaymentMethod();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  resolvePaymentsMethods() {
    this.budgetService
      .findAllPaymentsMethods(StorageService.CompanyId)
      // .findAllPaymentMethodsByCompany_SELECT()
      .subscribe((data: PaymentInfoBudget[]) => {
        this.paymentsMethods = data;

        if (this.mode === 'EDIT') {
          const paymentMethod = this.paymentsMethods.find(
            (payment) => payment._id === this.budget?.typePayments[0]?._id
          );

          if (paymentMethod && this.budget?.typePayments?.length == 1) {
            const method = this.paymentsMethods.filter(
              (fil) => fil._id == this.budget?.typePayments[0]._id
            );
            if (
              method.length > 0 &&
              this.budget?.typePayments[0].textShow != method[0].textShow
            ) {
              this.changePayment(paymentMethod);
            }
          }
          if (paymentMethod) {
            this.paymentAssigned = paymentMethod;
          }
        }
      });
  }

  translateName(item: string) {
    if (item) {
      const result = translatePaymentsName(item);
      return result;
    }
    return '';
  }

  createPaymentMethod() {
    const payment = new PaymentInfoBudget();
    payment.icon = 'account_balance';
    payment.name = 'Paypal';
    payment.textShow = 'Pagar via paypal a acasals@taclia.com';

    this.budgetService.createPaymentMethod(payment).subscribe((data) => {});
  }

  changePayment(event: PaymentInfoBudget) {
    this.paymentAssigned = event;
    this.typePayments = [];
    this.typePayments.push(this.paymentAssigned);

    this.budget.typePayments = this.typePayments;
    if (event) {
      if (this.validForm) {
        if (this.mode === 'EDIT') {
          this.updateBudget(this.budget);
        } else {
          // ADD
          this.updatePaymentModel(this.typePayments);
        }
      } else {
        this.alertService.errorOptions(
          this.i18n.instant('budgets.settings.create.form.headerCreateError'),
          { duration: 4500 }
        );
      }
    }
  }

  updatePaymentModel(p: IPaymentInfoBudget[]) {
    this.budget.typePayments = p;
    this.changedFooter.emit(this.budget);
    //this.budgetUtilisService.refreshBudget.emit(this.budget);
  }

  isDisabled(control: any) {
    const result = this.validForm === false ? true : false;
    if (result) {
      this.form.controls[control].disable();
    } else {
      this.form.controls[control].enable();
    }

    return result;
  }

  changeProject(p: IProjectOut) {
    this.projectAssigned = p;

    if (p) {
      if (!this.budget?.addicionalInfo) {
        this.budget.addicionalInfo = new AdditionalInfoBudget();
      }
      this.budget.addicionalInfo.project_id = p._id;
      // this.budgetUtilisService.refreshBudget.emit(this.budget);

      if (this.validForm) {
        if (this.mode === 'EDIT') {
          this.updateBudget(this.budget);
        } else {
          this.updateCategoryModel();
        }
      } else {
        this.alertService.errorOptions(
          this.i18n.instant('budgets.settings.create.form.headerCreateError'),
          { duration: 4500 }
        );
      }
    }
  }

  changeCategory(p: any) {
    this.categoryAssigned = p;

    if (p) {
      if (!this.budget?.addicionalInfo) {
        this.budget.addicionalInfo = new AdditionalInfoBudget();
      }
      this.budget.addicionalInfo.category_id = p._id;
      //this.budgetUtilisService.refreshBudget.emit(this.budget);

      if (this.validForm) {
        if (this.mode === 'EDIT') {
          this.updateBudget(this.budget);
        } else {
          this.updateCategoryModel();
        }
      } else {
        this.alertService.errorOptions(
          this.i18n.instant('budgets.settings.create.form.headerCreateError'),
          { duration: 4500 }
        );
      }
    }
  }

  updateCategoryModel() {
    this.changedFooter.emit(this.budget);

    //this.budgetUtilisService.refreshBudget.emit(this.budget);
  }

  getProjects() {
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Projects)))
        .subscribe(() => {
          const query = RemoveEmpty(this.searchParams);

          this.sub$.add(
            this.projectService
              // .search({
              //   ...query,
              // })
              .findAllProjectsByCompany_SELECT()
              .pipe(
                finalize(() => {
                  this.isLoading = false;
                  this.draw();
                })
              )
              .subscribe((data) => {
                // this.projects = data.docs;
                this.projects = data;
              })
          );
        })
    );
  }

  resolveMode() {
    if (this.budget !== undefined) {
      if (this.mode === 'EDIT') {
        const idPaymentMethod =
          typeof this.budget.typePayments[0] === 'string'
            ? this.budget.typePayments[0]
            : this.budget.typePayments[0]?._id;

        this.form.patchValue({
          messages: this.budget?.messages?.line1
            ? this.budget?.messages?.line1
            : this.settings.message,
          payment_method: idPaymentMethod,
          category_id: this.budget?.addicionalInfo?.category_id,
          project_id: this.budget?.addicionalInfo?.project_id,
        });

        this.categoryAssigned._id = this.budget.addicionalInfo?.category_id;
      } else {
        this.budget = new BudgetCommonData();
        this.form.patchValue({
          messages: this.settings.message,
        });
        this.updateMessages();
      }

      this.draw();
    }
    /*     if (
      this.messages !== undefined &&
      this.typePayments !== undefined &&
      this.addicionalInfo.category_id !== undefined &&
      this.addicionalInfo.project_id !== undefined
    ) {
      if (this.mode === 'EDIT') {
        this.form.patchValue({
          messages: this.messages.line1,
          payment_method: this.typePayments[0]._id,
          category_id: this.addicionalInfo?.category_id,
          project_id: this.addicionalInfo?.project_id,
        });

        this.categoryAssigned._id = this.addicionalInfo?.category_id;
      } else {
      }
    } */

    this.draw();
  }

  changeMessages() {
    this.updateMessages();
  }

  updateMessages() {
    const m = {
      messages: {
        line1: this.form.value.messages,
      },
    };

    if (this.budget.messages) {
      this.budget.messages.line1 = this.form.value.messages;
    } else {
      this.budget.messages = new MessageBudget();
      this.budget.messages.line1 = this.form.value.messages;
    }
    this.changedFooter.emit(this.budget);

    if (this.validForm) {
      if (this.mode === 'EDIT') {
        this.updateBudget(this.budget);
      } else {
        this.updateMessage(m.messages);
      }
    } else {
      this.alertService.errorOptions(
        this.i18n.instant('budgets.settings.create.form.headerCreateError'),
        { duration: 4500 }
      );
    }
  }
  updateMessage(m: IMessageBudget) {
    this.changedFooter.emit(this.budget);

    // this.budgetUtilisService.refreshBudget.emit(this.budget);
  }

  updateBudget(data: any) {
    if (this.type == TypeBudget.BUDGET) {
      if (this.id) {
        this.sub$.add(
          this.budgetService.updateOne(this.id, data).subscribe((data) => {
            this.budget = data;
            this.resolveMode();
            this.budgetUtilisService.refreshBudget.emit(this.budget);
            /*     this.alertService.success(
              this.i18n.instant('budgets.settings.edit.successUpdated')
            ); */
            this.draw();
          })
        );
      }
    }

    if (this.type == TypeBudget.PROFORM) {
      if (this.id) {
        this.sub$.add(
          this.budgetService
            .updateOneProform(this.id, data)
            .subscribe((data) => {
              this.budget = data;
              this.budgetUtilisService.refreshBudget.emit(this.budget);
              /*    this.alertService.success(
              this.i18n.instant('budgets.settings.edit.successUpdated')
            ); */
              this.draw();
            })
        );
      }
    }

    if (this.type == TypeBudget.BILL) {
      if (this.id) {
        this.sub$.add(
          this.budgetService.updateOneBill(this.id, data).subscribe((data) => {
            this.budget = data;
            this.budgetUtilisService.refreshBudget.emit(this.budget);
            /*     this.alertService.success(
              this.i18n.instant('budgets.settings.edit.successUpdated')
            ); */
            this.draw();
          })
        );
      }
    }
  }

  async showCreateProject() {
    this.sub$.add(this.createProjectService.open().subscribe());
  }

  private watchRefreshList() {
    this.sub$.add(
      this.createProjectService.refreshList$
        .pipe(
          map((res) => !!res),
          filter((res) => res)
        )
        .subscribe(() => {
          this.searchParams = {
            ...this.searchParams,
            page: 1,
          };

          this.getProjects();
        })
    );

    this.sub$.add(
      this.budgetUtilisService.refreshBudget.subscribe(
        (data: IBudgetCommonData) => {
          this.budget = data;
          this.resolveMode();
        }
      )
    );
  }

  fillLists() {
    this.getProjects();
    this.resolvePaymentsMethods();
    this.findCategories();
  }

  findCategories() {
    this.isProductsLoading = true;
    this.sub$.add(
      this.productService
        // .findAll()
        .findAllProductsByCompany_SELECT()
        .subscribe((res) => {
          this.producsList = res;
          this.isProductsLoading = false;
          this.draw();
        })
    );
  }
}
