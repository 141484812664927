import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Period } from '@web-frontend/shared/interfaces/period';

@Component({
  selector: 'roma-contract-form',
  templateUrl: './contract-form.component.html',
  styleUrls: ['./contract-form.component.scss'],
})
export class ContractFormComponent {
  @Input() form: FormGroup;
  @Input() errorHoursMin: boolean;
  @Input() workingHoursError: string;
  @Output() workingHoursChange = new EventEmitter<number>();
  @Output() workingHoursPeriodChange = new EventEmitter<Period>();
  @Output() workdaysChange = new EventEmitter<string[]>();
  @Output() validationError = new EventEmitter<boolean>();
  @Output() updateErrorHoursMin = new EventEmitter<boolean>();

  constructor(private cd: ChangeDetectorRef) {}

  onWorkingHoursChange(hours: number): void {
    this.form.get('contract.workingHours')?.setValue(hours);
    this.validateErrorHours();
    this.draw();
  }

  onWorkingHoursPeriodChange(period: Period): void {
    this.form.get('contract.workingHoursPeriod')?.setValue(period);
    this.draw();
  }

  onWorkdaysChange(workdays: string[]): void {
    this.form.get('contract.workdays')?.setValue(workdays);
    this.validateErrorHours();
    this.draw();
  }

  validateErrorHours(): void {
    const workdays = this.form.get('contract.workdays')?.value;
    const workingHoursControl = this.form.get('contract.workingHours')?.value;
    

    if (this.shouldMarkError(workdays, workingHoursControl)) {
      this.handleValidationHoursError(true);
      this.workingHoursError = 'person.create.form.hourRequired';
    } else {
      this.handleValidationHoursError(false);
    }
    this.draw();
  }

  isInvalidWorkingHoursControl(workingHoursControl: number | null): boolean {
    return !workingHoursControl;
  }

  shouldMarkError(workdays: string[], workingHoursControl: number | null): boolean {
    return (
      workdays.length > 0 &&
      this.isInvalidWorkingHoursControl(workingHoursControl)
    );
  }

  handleValidationHoursError(error: boolean): void {
    if (error) {
      this.setWorkingHoursError();
    } else if (!this.errorHoursMin) {
      this.clearWorkingHoursError();
    }
    this.draw();
  }

  setWorkingHoursError(): void {
    this.form.get('contract.workingHours').setErrors({ invalid: true });
    this.draw();
  }

  clearWorkingHoursError(): void {
    const workdays = this.form.get('contract.workdays')?.value;
    const workingHoursControl = this.form.get('contract.workingHours')?.value;
    if (
      workdays.length === 0 ||
      !this.isInvalidWorkingHoursControl(workingHoursControl)
    ) {
      this.form.get('contract.workingHours')?.setErrors(null);
      this.workingHoursError = null;
    } else {
      this.setWorkingHoursError();
      this.workingHoursError = 'person.create.form.hourRequired';
    }
  }

  updateErrorHours(evt: boolean): void {
    this.errorHoursMin = evt;
    this.draw();
  }

  draw(): void {
    this.cd.detectChanges();
  }
}
