import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { App, CITIES, IAsset, IUser } from '@tacliatech/types';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  private sub$ = new Subscription();

  private citiesCache = new BehaviorSubject<{ id: string; nm: string }[]>([]);

  cities$ = this.citiesCache.asObservable();

  private assetsCache = new BehaviorSubject<App.ResourceCached<IAsset[]>>([
    'INIT',
    [],
  ]);

  assets$ = this.assetsCache.asObservable();

  constructor(private http: HttpClient) {}

  public get assets() {
    return this.assetsCache.value[1];
  }

  get cities() {
    return this.citiesCache.value;
  }

  init() {
    this.assetsCache.next(['LOADING', []]);
    this.sub$.add(
      this.findAll().subscribe((res) => {
        this.assetsCache.next(['LOADED', res]);
      })
    );
    this.sub$.add(this.getListCity().subscribe());
  }

  findAll() {
    return this.http.get<IAsset[]>(`:API_URL/assets/allme`);
  }

  findOne(id: string) {
    return this.http.get<IAsset>(`:API_URL/assets/${id}`);
  }

  createOne(data: IAsset) {
    return this.http.post<IAsset>(`:API_URL/assets/`, data);
  }

  updateOne(id: string, data: any) {
    return this.http.put<IAsset>(`:API_URL/assets/${id}`, data);
  }

  deleteOne(id: string) {
    return this.http.delete<IAsset>(`:API_URL/assets/${id}`);
  }

  getListCity(): Observable<{ id: string; nm: string }[]> {
    return of(CITIES).pipe(
      tap((res) => {
        this.citiesCache.next(res);
      })
    );
  }

  getAssetsCustomer(id: string) {
    return this.http.get<IAsset[]>(`:API_URL/assets/customer/${id}`);
  }

  getAssetsUser(id: string) {
    return this.http.get<IAsset[]>(`:API_URL/assets/user/${id}`);
  }

  findUsersByAssetsShared() {
    return this.http.post<IUser[]>(`:API_URL/assets/find-by-shared-assets`, {});
  }

  findAssetsByCompany(id: string) {
    return this.http.get<IAsset[]>(`:API_URL/company/${id}/assets`);
  }

  //
  findAllAssetsByCompany_SELECT() {
    return this.http.get<IAsset[]>(`:API_URL/assets/company/select`);
  }

  restoreMany(data) {
    return this.http.post(`:API_URL/assets/restore-many`, data);
  }

  deleteMany(data) {
    return this.http.post(`:API_URL/assets/delete-many`, data);
  }
}
