import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsModalComponent } from './settings-modal.component';

@Directive({
  selector: '[clickOpenSettings]',
})
export class OpenSettingsDirective {
  @Output()
  closeModal = new EventEmitter();

  constructor(private readonly dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  click(evt: MouseEvent): void {
    evt.stopPropagation();
    const dialogRef = this.dialog.open(SettingsModalComponent, {
      id: 'settings-modal',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.closeModal.next();
    });
  }
}
