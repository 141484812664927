<div
  class="container-items"
  romaProSelect
  class="wrapper-pro-feature-user"
  [featureUser]="featureUser"
>  <mat-card class="">
    <div class="add-lines-form" [formGroup]="form">
      <div
        formArrayName="lines"
        cdkDropList
        class="example-list"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          *ngFor="let lineForm of lines?.controls; let i = index"
          class="example-box"
          cdkDrag
        >
          <div class="row-fluid line-items-row" [formGroup]="lineForm">
            <!-- BEGIN MAIN ROW -->
            <div class="example-handle" cdkDragHandle>
              <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                <path
                  d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                ></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </div>
            <div class="col-2">
              <div class="input-item">
                <label
                  class="lbl-totals"
                  for="name"
                  [ngClass]="{ 'is-disabled': !this.validForm }"
                >
                  {{ conceptPlaceholder | translate }}
                </label>
                <ng-select
                  appendTo="body"
                  formControlName="concept"
                  placeholder="{{ 'budget.findOrNewConcept' | translate }}"
                  [notFoundText]="
                    'hiring.form.placeholder_notfound' | translate
                  "
                  [addTag]="true"
                  addTagText="{{ 'budget.addConcept' | translate }}"
                  (change)="setItemData($event, lineForm, i)"
                  (blur)="setItemData($event, lineForm, i)"
                  (keyup)="onKeyUp($event)"
                >
                  <ng-option *ngFor="let cat of catalogues" [value]="cat._id">
                    {{ cat.name }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="col-3" style="width: 22%">
              <div class="input-item">
                <label
                  class="lbl-totals"
                  for="name"
                  [ngClass]="{ 'is-disabled': !this.validForm }"
                >
                  {{ descriptionPlaceholder | translate }}
                </label>
                <textarea
                  type="text"
                  class="form-control"
                  formControlName="description"
                  [readonly]="isDisabled('lines')"
                  (change)="itemsChanged(lineForm, i)"
                  [placeholder]="descriptionPlaceholder | translate"
                ></textarea>
              </div>
            </div>
            <div class="col-1" style="width: 7%">
              <div class="input-item">
                <label
                  class="lbl-totals"
                  for="name"
                  [ngClass]="{ 'is-disabled': !this.validForm }"
                >
                  {{ quantityPlaceholder | translate }}
                </label>
                <input
                  type="number"
                  formControlName="quantity"
                  class="form-control"
                  [readonly]="isDisabled('lines')"
                  (change)="itemsChanged(lineForm, i)"
                  [placeholder]="quantityPlaceholder | translate"
                />
              </div>
            </div>
            <div class="col-1">
              <div class="input-item">
                <label
                  class="lbl-totals"
                  for="name"
                  [ngClass]="{ 'is-disabled': !this.validForm }"
                >
                  {{ pricePlaceholder | translate }}
                </label>
                <input
                  type="number"
                  class="form-control"
                  formControlName="price"
                  (change)="itemsChanged(lineForm, i)"
                  [placeholder]="pricePlaceholder | translate"
                />
              </div>
            </div>

            <!---->
            <div
              class="col-1"
              *ngIf="this.budget?.proFeatures?.hasDiscount"
              style="width: 8%"
            >
              <div class="input-item">
                <label
                  class="lbl-totals"
                  for="discount"
                  [ngClass]="{ 'is-disabled': !this.validForm }"
                >
                  {{ discountPlaceholder | translate }}
                </label>
                <input
                  type="number"
                  class="form-control"
                  formControlName="discount"
                  min="0"
                  max="100"
                  (change)="itemsChanged(lineForm, i)"
                  [placeholder]="discountPlaceholder | translate"
                />
              </div>
            </div>

            <div class="col-2" style="width: 25%">
              <div class="input-item">
                <label
                  class="lbl-totals"
                  for="name"
                  [ngClass]="{ 'is-disabled': !this.validForm }"
                >
                  {{ taxPlaceholder | translate }}
                </label>
                <ng-select
                  appendTo="body"
                  [multiple]="true"
                  formControlName="taxes"
                  (change)="changeIvaAssigned($event, i)"
                  [placeholder]="taxPlaceholder | translate"
                  [notFoundText]="
                    'hiring.form.placeholder_notfound' | translate
                  "
                >
                  <ng-option *ngFor="let item of taxes" [value]="item._id">
                    {{ item.translate | translate }}
                    {{ changeComma(item.value) }}%
                  </ng-option>
                </ng-select>
              </div>

              <!-- [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="selectedIvaAssigned[i]" -->
            </div>

            <div class="col-2" style="width: 12%">
              <div class="input-item">
                <label
                  class="lbl-totals"
                  for="name"
                  [ngClass]="{ 'is-disabled': !this.validForm }"
                >
                  {{ totalPlaceholder | translate }}
                </label>
                <input
                  type="number"
                  readonly
                  formControlName="total"
                  class="form-control"
                  [placeholder]="totalPlaceholder | translate"
                />
              </div>
            </div>

            <div class="col-2" style="width: 8%">
              <div class="delete-btn">
                <button
                  class="taclia"
                  mat-mini-fab
                  color="accent"
                  [disabled]="!this.validForm"
                  (click)="deleteLine(i)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>

            <!--   <div class="col-md-5">
                <div class="row text-center">
                  <div class="col-md-6">
                    <div class="input-item">
                      <label class="lbl-totals" for="name">
                        {{ conceptPlaceholder | translate }}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="item-{{ i }}"
                        formControlName="concept"
                        (change)="itemsChanged(lineForm, i)"
                        [placeholder]="conceptPlaceholder | translate"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">

                    <div class="input-item">
                      <label class="lbl-totals" for="name">
                        {{ descriptionPlaceholder | translate }}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="description"
                        (change)="itemsChanged(lineForm, i)"
                        [placeholder]="descriptionPlaceholder | translate"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="row text-center">
                  <div class="col-md-6">
                    <div class="input-item">
                      <label class="lbl-totals" for="name">
                        {{ quantityPlaceholder | translate }}
                      </label>
                      <input
                        type="number"
                        formControlName="quantity"
                        class="form-control"
                        (change)="itemsChanged(lineForm, i)"
                        [placeholder]="quantityPlaceholder | translate"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-item">
                      <label class="lbl-totals" for="name">
                        {{ pricePlaceholder | translate }}
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        formControlName="price"
                        (change)="itemsChanged(lineForm, i)"
                        [placeholder]="pricePlaceholder | translate"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="row text-center">
                  <div class="col-md-6">
                    <div class="input-item">
                      <label class="lbl-totals" for="name">
                        {{ taxPlaceholder | translate }}
                      </label>
                      <ng-select
                        bindLabel="name"
                        bindValue="_id"
                        [items]="taxes"
                        [multiple]="true"
                        formControlName="taxes"
                        (change)="changeIvaAssigned($event, i)"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="selectedIvaAssigned[i]"
                        [placeholder]="taxPlaceholder | translate"
                        [notFoundText]="'hiring.form.placeholder_notfound' | translate"
                      >
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-item">
                      <label class="lbl-totals" for="name">
                        {{ totalPlaceholder | translate }}
                      </label>
                      <input
                        type="number"
                        readonly
                        formControlName="total"
                        class="form-control"
                        [placeholder]="totalPlaceholder | translate"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <div class="row text-center">
                  <div class="col-md-12">
                    <div class="actions-item">
                      <button
                        class="taclia btn-delete"
                        mat-mini-fab
                        color="accent"
                        (click)="deleteLine(i)"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>

                </div>
              </div> -->

            <!-- <div class="col-2 control-item">
                <div class="input-item">
                  <label class="lbl-totals" for="name">
                    {{ conceptPlaceholder | translate }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="item-{{ i }}"
                    formControlName="concept"
                    (change)="itemsChanged(lineForm, i)"
                    [placeholder]="conceptPlaceholder | translate"
                  />
                </div>
              </div>

              <div class="col-2 control-item">
                <div class="input-item">
                  <label class="lbl-totals" for="name">
                    {{ descriptionPlaceholder | translate }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="description"
                    (change)="itemsChanged(lineForm, i)"
                    [placeholder]="descriptionPlaceholder | translate"
                  />
                </div>
              </div>

              <div class="col-2 control-item">
                <div class="input-item">
                  <label class="lbl-totals" for="name">
                    {{ quantityPlaceholder | translate }}
                  </label>
                  <input
                    type="number"
                    formControlName="quantity"
                    class="form-control"
                    (change)="itemsChanged(lineForm, i)"
                    [placeholder]="quantityPlaceholder | translate"
                  />
                </div>
              </div>

              <div class="col-2 control-item">
                <div class="input-item">
                  <label class="lbl-totals" for="name">
                    {{ pricePlaceholder | translate }}
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    formControlName="price"
                    (change)="itemsChanged(lineForm, i)"
                    [placeholder]="pricePlaceholder | translate"
                  />
                </div>
              </div>

              <div class="col-2 control-item">
                <div class="input-item">
                  <label class="lbl-totals" for="name">
                    {{ taxPlaceholder | translate }}
                  </label>
                  <ng-select
                    bindLabel="name"
                    bindValue="_id"
                    [items]="taxes"
                    [multiple]="true"
                    formControlName="taxes"
                    (change)="changeIvaAssigned($event, i)"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="selectedIvaAssigned[i]"
                    [placeholder]="taxPlaceholder | translate"
                    [notFoundText]="'hiring.form.placeholder_notfound' | translate"
                  >
                  </ng-select>
                </div>
              </div>

              <div class="col-2 control-item">
                <div class="input-item">
                  <label class="lbl-totals" for="name">
                    {{ totalPlaceholder | translate }}
                  </label>
                  <input
                    type="number"
                    readonly
                    formControlName="total"
                    class="form-control"
                    [placeholder]="totalPlaceholder | translate"
                  />
                </div>
              </div>

              <div class="col-1 control-item">
                <div class="actions-item">
                  <button
                    class="taclia btn-delete"
                    mat-mini-fab
                    color="accent"
                    (click)="deleteLine(i)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div> -->
          </div>
          <!-- END MAIN ROW -->
        </div>
      </div>
    </div>

    <div class="add-item mt-10">
      <button
        class="taclia btn-addLine"
        [disabled]="!this.validForm"
        mat-raised-button
        color="accent"
        (click)="addLine()"
      >
        {{ 'budget.addLine' | translate }}
      </button>
      <button
        class="btn-addDiscount"
        mat-raised-button
        color="accent"
        [disabled]="
          this.budget.items.length === 0 ||
          this.budget?.proFeatures?.hasDiscount
        "
        (click)="addDiscount()"
      >
        {{ 'budget.addDiscount' | translate }}
      </button>
      <!--[feature]="featureRef.Sale.AddDiscount"-->
    </div>
  </mat-card>
</div>
