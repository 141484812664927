import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalErrorConnectionComponent } from './modal-error-connection.component';

@Injectable()
export class ModalErrorConnectionService {
  private matDialogRef?: MatDialogRef<ModalErrorConnectionComponent, any>;

  constructor(private matDialog: MatDialog) {}

  public show(): void {
    this.matDialogRef = this.matDialog.open(ModalErrorConnectionComponent, {
      disableClose: true,
      data: {
        canRetry: true,
      },
      panelClass: 'modal-error-connection',
    });
  }

  public dismiss(): void {
    if (!this.matDialogRef) return;

    this.matDialogRef.close();
  }
}
