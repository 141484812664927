import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PeriodNamePipe } from './period-name.pipe';

@NgModule({
  declarations: [PeriodNamePipe],
  imports: [CommonModule, TranslateModule.forChild()],
  exports: [PeriodNamePipe],
})
export class PeriodNameModule {}
