import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { App, IProduct } from '@tacliatech/types';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private sub$ = new Subscription();

  private productsCache = new BehaviorSubject<App.ResourceCached<IProduct[]>>([
    'INIT',
    [],
  ]);

  products$ = this.productsCache.asObservable();

  constructor(private http: HttpClient) {}

  init() {
    this.productsCache.next(['LOADING', []]);
    this.sub$.add(
      this.findAll().subscribe((res) => {
        this.productsCache.next(['LOADED', res]);
        this.productsCache.complete();
      })
    );
  }

  public get products() {
    return this.productsCache.value[1];
  }

  findAll() {
    return this.http.get<IProduct[]>(`:API_URL/products/`);
  }

  deleteOne(id: string) {
    return this.http.delete<unknown>(`:API_URL/products/${id}`);
  }

  createOne(data: { [key: string]: any }) {
    return this.http.post(`:API_URL/products`, { ...data });
  }

  updateOne(id: string, data: { [key: string]: any }) {
    return this.http.put(`:API_URL/products/${id}`, { ...data });
  }

  //
  findAllProductsByCompany_SELECT() {
    return this.http.get<{ _id: string; name: string }[]>(
      `:API_URL/products/company/select`
    );
  }

  findOneCategory(id: string) {
    return this.http.get<IProduct>(`:API_URL/products/find-one-category/${id}`);
  }
}
