import { Pipe, PipeTransform } from '@angular/core';
import { ClockHour } from '@tacliatech/types';

@Pipe({
  name: 'clockHoursDuration',
})
export class ClockHoursDurationPipe implements PipeTransform {
  transform(value: Array<ClockHour.Histories>, ...args: unknown[]): unknown {
    if (!value.length || !value[0].length) {
      return '00hr 00m';
    }

    let hourDuration = ClockHour.getTotalHourFromHistories(value, 0);
    while (hourDuration.charAt(0) === '0') {
      hourDuration = hourDuration.substring(1);
    }
    let finalText = hourDuration.replace(':', 'hr ') + 'm';
    if (finalText.startsWith('hr')) {
      finalText = '0' + finalText;
    }
    return finalText;
  }
}
