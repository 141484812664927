import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalTemplateModule } from '../modal-template';
import { CreateSequenceComponent } from './create-sequence.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from '@web-frontend/shared/directives/tooltip/tooltip.module';

@NgModule({
  declarations: [CreateSequenceComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    TranslateModule.forChild(),
    FormsModule,
    NgSelectModule,
    TooltipModule
  ],
  exports: [CreateSequenceComponent],
})
export class CreateSequenceModule {}
