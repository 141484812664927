import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SignatureModalComponent } from './signature-modal.component';
import { SignatureModal } from './signature-modal.types';

@Injectable({
  providedIn: 'root',
})
export class CreateSignatureService {
  private refreshListSource = new Subject<boolean>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(
    config?: MatDialogConfig<SignatureModal.ModalParams>
  ): Observable<SignatureModal.ModalParamsRes> {
    const dialogRef = this.dialog.open(SignatureModalComponent, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        // @ts-ignore
        this.refreshListSource.next(res);
      })
    );
  }
}
