import { Injectable } from '@angular/core';
import { ISequence, TypeBudget } from '@tacliatech/types';
import { MsSalesApiService } from './sales-api.service';

@Injectable({
  providedIn: 'root',
})
export class MsSalesService {
  constructor(private readonly salesApiService: MsSalesApiService) {}

  async getNextSequenceNumber(sequenceId: string): Promise<ISequence> {
    return this.salesApiService.get(`/serial-numbers/${sequenceId || `null`}`);
  }

  async isValidSequenceNumber(
    sequenceId: string,
    number: number,
    type: string
  ): Promise<{ isAvailable: boolean }> {
    return this.salesApiService.get(
      `/serial-numbers/${sequenceId}?available=${number}&type=${type}`
    );
  }
}
