import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';

import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

import { CreateSaleComponent } from './create-sale.component';
import { CreateSaleService } from './create-sale.service';
import { ModalTemplateModule } from '../modal-template';
import { FieldErrorDisplayModule } from '../field-error-display';
import { AppendCustomPropertiesModule } from '../append-custom-properties';
import { EditCustomPropertiesModule } from '../edit-custom-properties';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SelectorRecurrenceModule } from '../selector-recurrence';
import { PermissionsModule } from '../permissions';
import { RmSelectModule } from '../globals/rm-select';
import { EquipmentNotesModule } from '../equipments-notes';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SplitModule } from '@web-frontend/shared/pipes/split';
import { NgxMaskModule } from 'ngx-mask';
import { RmResponsiveSelectModule } from '../globals/rm-responsive-select';
import { RmStatusSelectModule } from '../globals/rm-status-select';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { ImageSelectorModule } from '../image-selector-v2';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RatesSelectTableModule } from '../rates-select-table';
import { CurrencyFormatModule } from '@web-frontend/shared/pipes/currency-format/currency-format.module';
import { CancelSaleModalModule } from './cancel-sale-modal/cancel-sale-modal.module';
import { RmSelectV2Module } from '../globals/rm-select-v2';
import { SendEmailSaleModalModule } from '../send-email-sale-modal';
import { SharePaymentLinkModalComponent } from './share-payment-link-modal/share-payment-link-modal.component';
import { RomaToolTipModule } from '@web-frontend/shared/directives/roma-tooltip';
import { PaymentsModule } from '../../../core/admin/payments/payments.module';
import { SalePreviewCardModule } from './sale-preview-card/sale-preview-card.module';
import { MobileModalListModule } from '../mobile-modal-list/mobile-modal-list.module';
import { StripeSnackbarSharedModule } from '../snackbar/stripe-snackbar.module';
import { SaleErrorWarningComponent } from './sale-error-warning/sale-error-warning.component';
import { ObligatoryFieldSignModule } from '../obligatory-field-sign/obligatory-field-sign.module';

@NgModule({
  declarations: [
    CreateSaleComponent,
    SharePaymentLinkModalComponent,
    SaleErrorWarningComponent,
  ],
  imports: [
    CommonModule,
    ModalTemplateModule,
    MatDialogModule,
    MatIconModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatCheckboxModule,
    MatButtonModule,
    FieldErrorDisplayModule,
    AppendCustomPropertiesModule,
    EditCustomPropertiesModule,
    MatProgressSpinnerModule,
    PermissionsModule,
    SelectorRecurrenceModule,
    RmSelectModule,
    RmStatusSelectModule,
    RmResponsiveSelectModule,
    MatMenuModule,
    EquipmentNotesModule,
    SplitModule,
    NgxMaskModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    ClickDeleteModule,
    ImageSelectorModule,
    MatTooltipModule,
    RatesSelectTableModule,
    CurrencyFormatModule,
    CancelSaleModalModule,
    RmSelectV2Module,
    SendEmailSaleModalModule,
    RomaToolTipModule,
    PaymentsModule,
    SalePreviewCardModule,
    MobileModalListModule,
    StripeSnackbarSharedModule,
    ObligatoryFieldSignModule,
  ],
  exports: [CreateSaleComponent],
  providers: [CreateSaleService],
})
export class CreateSaleModule {}
