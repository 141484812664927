import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AlertService } from './alert.service';
import { SnackbarModule } from '@web-frontend/shared/services/snackbar/snackbar.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, MatSnackBarModule, SnackbarModule],
  providers: [AlertService],
})
export class AlertModule {}
