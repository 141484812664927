import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { SnackbarData } from './utils/snackbar-config';
import { SnackbarMexicoComponent } from '@web-frontend/shared/services/snackbar/snackbar-mexico/snackbar-mexico.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [SnackbarComponent, SnackbarMexicoComponent],
  imports: [CommonModule, TranslateModule, RouterModule],
  providers: [SnackbarData],
  exports: [SnackbarComponent, SnackbarMexicoComponent],
})
export class SnackbarModule {}
