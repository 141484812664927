import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'periodName',
})
export class PeriodNamePipe implements PipeTransform {
  transform(value: string): string {
    if (new RegExp('year', 'i').test(value)) {
      return 'pro.period.yearly';
    } else if (new RegExp('month', 'i').test(value)) {
      return 'pro.period.monthly';
    }
    return value;
  }
}
