import { Injectable } from '@angular/core';

import {
  APP_VERSION,
  BDG_PREFERENCES,
  BDG_SETTINGS,
  COMPANY_DATA,
  COMPANY_ID_KEY,
  CUSTOM_PROPERTIES_KEY,
  CUSTOMER_DATA,
  CUSTOMER_ID_KEY,
  DATE_FORMAT,
  FIRST_DAY_CALENDAR,
  HIRING_FORM_KEY,
  HiringRegisterDto,
  TOKEN_KEY,
  USER_DATA,
  USER_ID_KEY,
  USER_KEY,
  USER_MODE_KEY,
  VENDOR_ID_KEY,
} from '@tacliatech/types';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  static remember = true;

  static get STORE() {
    return StorageService.remember ? localStorage : sessionStorage;
  }

  static Parse(data: string): any {
    try {
      return JSON.parse(data);
    } catch (error) {
      return null;
    }
  }

  static Stringify<T = unknown>(data: T): string | null {
    try {
      const parseData = JSON.stringify(data);

      return parseData;
    } catch (error) {
      return null;
    }
  }

  static GetItem<T = any>(key: string, parse = false): T {
    const storage = StorageService.STORE.getItem(key);

    if (parse && storage) {
      const storageParse = StorageService.Parse(storage);
      return storageParse;
    } else {
      return storage as any;
    }
  }

  static SetItem(key: string, data: any): void {
    if (typeof data === 'object') {
      data = StorageService.Stringify(data);
    }

    StorageService.STORE.setItem(key, data);
  }

  static SetNotReplace(key: string, data: any): void {
    if (StorageService.GetItem(key) === null) {
      StorageService.SetItem(key, data);
    }
  }

  static get Token() {
    return StorageService.GetItem(TOKEN_KEY) as string;
  }

  static get UserId() {
    return StorageService.GetItem(USER_ID_KEY) as string;
  }

  static get CompanyId() {
    return StorageService.GetItem(COMPANY_ID_KEY) as string;
  }

  static get CustomerId() {
    return StorageService.GetItem(CUSTOMER_ID_KEY) as string;
  }

  static get VendorId() {
    return StorageService.GetItem(VENDOR_ID_KEY) as string;
  }

  static get DateFormat(): string {
    const dateFormat = StorageService.GetItem(DATE_FORMAT) as string;
    return dateFormat?.toUpperCase() || 'DD/MM/YYYY';
  }

  static get FirstDayCalendar() {
    return StorageService.GetItem(FIRST_DAY_CALENDAR) as number;
  }

  static get userMode() {
    return StorageService.GetItem(USER_MODE_KEY) as string;
  }

  static get User() {
    return StorageService.GetItem(USER_KEY, true) as any;
  }

  static get userData() {
    return StorageService.GetItem(USER_DATA) as string;
  }
  static get customerData() {
    return StorageService.GetItem(CUSTOMER_DATA) as string;
  }
  static get companyData() {
    return StorageService.GetItem(COMPANY_DATA) as string;
  }
  static get appVersion() {
    return StorageService.GetItem(APP_VERSION) as string;
  }

  static get budgetPreferences() {
    return StorageService.GetItem(BDG_PREFERENCES) as string;
  }

  static get budgetSettings() {
    return StorageService.GetItem(BDG_SETTINGS) as string;
  }

  static get HiringForm() {
    return StorageService.GetItem(HIRING_FORM_KEY, true) as
      | HiringRegisterDto
      | any;
  }

  static Remove(key: string) {
    StorageService.STORE.removeItem(key);
  }

  static CustomProperty(idModule: string) {
    return StorageService.GetItem(StorageService.CustomPropertyKey(idModule));
  }

  static CustomPropertyKey(idModule: string) {
    return `${idModule}_${StorageService.UserId}_${CUSTOM_PROPERTIES_KEY}`;
  }

  static MoreFieldKey(idModule: string) {
    return `${idModule}_${StorageService.UserId}_MORE_FIELD`;
  }

  static isMe(id: string | number) {
    const meId = StorageService.UserId;

    return String(meId) === String(id);
  }

  static ClearAll() {
    StorageService.STORE.clear();
  }
}
