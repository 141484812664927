import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { CreateBudgetComponent } from './create-budget.component';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CreateBudgetService {
  DEFAUL_CONFIG = {
    maxWidth: '100vw',
    maxHeight: '100vh',
    height: '100%',
    width: '100%',
    panelClass: 'full-screen-modal',
    disableClose: true
  };
  private refreshListSource = new Subject<boolean>();

  refreshList$ = this.refreshListSource.asObservable();

  closeModalSource = new Subject<boolean>();
  closeModal$ = this.closeModalSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(data: any = {}) {
    const config = {
      ...this.DEFAUL_CONFIG,
      data,
    };

    config.data.duplicate = 'duplicate' in data ? data.duplicate : false;

    const dialogRef = this.dialog.open(CreateBudgetComponent, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }
}
