export const translationsPaymentsDictionary = (name) => {
  const dicc = {
    ['Transferencia']: 'payments-methods.account_balance',
    ['Recibo domiciliado']: 'payments-methods.payments',
    ['Efectivo']: 'payments-methods.euro_symbol',
    ['Tarjeta de crédito o débito']: 'payments-methods.credit_card',
    default: name,
  };

  const resut = dicc[name || 'default'];
  return resut ? resut : name;
};

export const translationsExpensesDictionary = (name) => {
  const dicc = {
    ['Dietas']: 'expense-cat.Dietas',
    ['Combustible']: 'expense-cat.Combustible',
    ['Aparcamiento']: 'expense-cat.Aparcamiento',
    ['Peajes']: 'expense-cat.Peajes',
    ['Transporte']: 'expense-cat.Transporte',
    ['Kilometraje']: 'expense-cat.Kilometraje',
    ['Hotel']: 'expense-cat.Hotel',
    default: name,
  };

  const resut = dicc[name || 'default'];
  return resut ? resut : name;
};

export const translateExpensesName = (item: string) => {
  if (item) {
    const result = translationsExpensesDictionary(item);
    return result;
  }
  return '';
};

export const translatePaymentsName = (item: string) => {
  if (item) {
    const result = translationsPaymentsDictionary(item);
    return result;
  }
  return '';
};
