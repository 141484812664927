import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Feature, MembershipSchema } from '@tacliatech/types';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { SignatureWaybillComponent } from '../signature-waybill';
import { SignatureWaybillModal } from './signature-waybill-modal.types';
import { environment } from '@web-frontend/environments';

@Component({
  selector: 'roma-signature-waybill-modal',
  templateUrl: './signature-waybill-modal.component.html',
  styleUrls: ['./signature-waybill-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatureWaybillModalComponent implements OnInit {
  @ViewChild('signature')
  signatureRefComponent!: SignatureWaybillComponent;

  source!: string;
  signatureData!: string;
  signatureWidth = 413;

  featureRef = Feature;
  hasFeatureSeeLogo$ = this.permissionService.hasFeatureFn(
    Feature.Deal.SignDocuments
  );
  showDisclaimer = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SignatureWaybillModal.ModalParams,
    private dialogRef: MatDialogRef<
      SignatureWaybillModalComponent,
      SignatureWaybillModal.ModalParamsRes
    >,
    private cdRef: ChangeDetectorRef,
    private permissionService: PermissionService
  ) {}

  get params() {
    return this.data;
  }

  ngOnInit(): void {
    if (this.data.source) {
      this.source = this.data.source;
      this.draw();
    }

    this.resolveSignatureWidth();
  }

  submit(): void {
    const empty = this.signatureRefComponent.signaturePad.isEmpty();
    const source = empty
      ? ''
      : this.signatureRefComponent.signaturePad.toDataURL();

    this.dialogRef.close({ source, success: true });
  }

  close(): void {
    this.dialogRef.close({ success: false });
  }

  delete() {
    try {
      this.signatureRefComponent.signaturePad.clear();
      this.signatureRefComponent.resolveIsEmpty();
    } catch (err) {}
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  private resolveSignatureWidth() {
    if (window.innerWidth <= 768) {
      this.signatureWidth = window.innerWidth - 65;
      this.draw();
    }
  }

  determineCopy(copy: string): string {
    if (environment.MembershipSchema === MembershipSchema.OneProPlan) {
      return `${copy}-new`;
    } else {
      return copy;
    }
  }
}
