import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import {
  RmSelectV2Component,
  SearchFilterPipe,
} from './rm-select-v2.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RomaToolTipModule } from '@web-frontend/shared/directives/roma-tooltip';
import { ImageModule } from '@web-frontend/shared/pipes/image';
import { CurrencyFormatModule } from '@web-frontend/shared/pipes/currency-format/currency-format.module';
import { PermissionsModule } from '../../permissions';

@NgModule({
  declarations: [RmSelectV2Component, SearchFilterPipe],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatProgressSpinnerModule,
    MatCheckboxModule,
    RomaToolTipModule,
    CurrencyFormatModule,
    ImageModule.forRoot(),
    PermissionsModule,
  ],
  providers: [TitleCasePipe],
  exports: [RmSelectV2Component],
})
export class RmSelectV2Module {}
