import { CalendarView } from 'angular-calendar';

export namespace ChecklistGlobal {
  export const SchedulesTypes = [
    {
      value: CalendarView.Day,
      name: 'checklist.day',
    },
    {
      value: CalendarView.Month,
      name: 'checklist.month',
    },
    {
      value: CalendarView.Week,
      name: 'checklist.week',
    },
  ];
}
