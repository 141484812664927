import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ImageFirebasePipe } from '@web-frontend/shared/pipes/image/image-firebase.pipe';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss'],
})
export class ModalInfoComponent implements OnInit {
  @Input()
  title = '';

  @Input()
  form: FormGroup;

  @Input()
  type = 'info';

  @Input()
  module = '';

  @Input()
  elements: string[] = [];

  @Input()
  images: Array<{ img: string; type: string }> = [];

  constructor(private activeModal: MatDialogRef<ModalInfoComponent>) {}

  ngOnInit(): void {}

  ok() {
    this.activeModal.close();
  }

  action() {
    this.activeModal.close({ ...this.form.value });
  }

  can() {
    return this.form?.valid;
  }

  download(item: { img: string; type: string }) {
    const filterPipe = new ImageFirebasePipe();
    const imageTransform = filterPipe.transform(item.img, item.type);

    window.open(imageTransform, '_blank');
  }
}
