import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatBottomSheetModule,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

import { ProDirective } from './pro.directive';
import { I18nChild } from '@web-frontend/shared/i18n';
import { ProSelectDirective } from './pro-select.directive';
import {
  MAT_RADIO_DEFAULT_OPTIONS,
  MatRadioModule,
} from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { ModalTemplateModule } from '../modal-template';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { LoadingModule } from '../loading';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProDisclaimerBannerComponent } from './pro-disclaimer-banner/pro-disclaimer-banner.component';
import { PeriodNameModule } from '@web-frontend/shared/pipes/period-name/period-name.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { ManagePermissionComponent } from './manage-permission/manage-permission.component';
import {
  MatCheckboxDefaultOptions,
  MatCheckboxModule,
  MAT_CHECKBOX_DEFAULT_OPTIONS,
} from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProWarningInfoComponent } from './pro-warning-info/pro-warning-info.component';
import { PlatformDisableContentModule } from '../platform-disable-content/platform-disable-content.module';
import { TranslateModule } from '@ngx-translate/core';
import { BuyModalComponent } from './buy-modal/buy-modal.component';
import { BaseButtonModule } from '../../../common/components/button/baseButton.module';

@NgModule({
  declarations: [
    ProDirective,
    ProSelectDirective,
    ProDisclaimerBannerComponent,
    ManagePermissionComponent,
    ProWarningInfoComponent,
    BuyModalComponent,
  ],
  imports: [
    CommonModule,
    MatBottomSheetModule,
    I18nChild,
    MatRadioModule,
    FormsModule,
    MatDialogModule,
    ModalTemplateModule,
    LoadingModule,
    MatProgressSpinnerModule,
    PeriodNameModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatBottomSheetModule,
    PlatformDisableContentModule,
    TranslateModule.forChild(),
    BaseButtonModule,
  ],
  exports: [
    ProDirective,
    ProSelectDirective,
    ProDisclaimerBannerComponent,
    ManagePermissionComponent,
    ProWarningInfoComponent,
    BuyModalComponent,
  ],
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'accent' },
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions,
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: MatBottomSheetRef,
      useValue: {},
    },
  ],
})
export class PermissionsModule {}
