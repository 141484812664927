export class UtilImage {
  createArray(quantity: number) {
    const auxArray = [];

    for (let index = 0; index < quantity; index++) {
      auxArray.push(index + 1);
    }

    return auxArray;
  }

  convertToArray(map: Map<number, any>) {
    const auxArray = [];

    for (const value of map.values()) {
      auxArray.push(value);
    }

    return auxArray;
  }
}
