import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BudgetMobileListComponent } from './budget-mobile-list.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { CurrencyFormatModule } from '@web-frontend/shared/pipes/currency-format/currency-format.module';
import { RmBadgeModule } from '../globals/rm-badge';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [BudgetMobileListComponent],
  imports: [
    CommonModule,
    CurrencyFormatModule,
    MatCheckboxModule,
    MatDividerModule,
    MatListModule,
    RmBadgeModule,
    TranslateModule.forChild(),
  ],
  exports: [BudgetMobileListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BudgetMobileListModule {}
