import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'roma-field-error-display',
  templateUrl: './field-error-display.component.html',
  styleUrls: ['./field-error-display.component.scss'],
})
export class FieldErrorDisplayComponent implements OnInit {
  @HostBinding('class.invalid-feedback')
  hostClass = true;

  @Input()
  errorMsg: string;

  @Input()
  form: FormGroup;

  @Input()
  fieldName: string;

  @Input()
  errorName = 'required';

  constructor() {}

  get displayError(): boolean {
    let status = false;
    const field = this.form?.get(this.fieldName) as FormControl;
    if (field) {
      field.markAsDirty();
      status =
        field.invalid &&
        field.touched &&
        field.dirty &&
        Object.keys(field?.errors || {})?.includes(this.errorName);
    }
    return status;
  }

  ngOnInit(): void {}
}
