import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { App, IActivity, ICreateActivityDto, IUser } from '@tacliatech/types';
import { BehaviorSubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  private sub$ = new Subscription();

  private activitiesCache = new BehaviorSubject<
    App.ResourceCached<ICreateActivityDto[]>
  >(['INIT', []]);

  activities$ = this.activitiesCache.asObservable();

  constructor(private http: HttpClient) {}

  init() {
    this.activitiesCache.next(['LOADING', []]);
    this.sub$.add(
      this.findAll().subscribe((res) => {
        this.activitiesCache.next(['LOADED', res]);
      })
    );
  }

  public get activities() {
    return this.activitiesCache.value[1];
  }

  findAll() {
    return this.http.get<ICreateActivityDto[]>(`:API_URL/activities/`);
  }

  findOne(id: string) {
    return this.http.get<IActivity>(`:API_URL/activities/${id}`);
  }

  findOwners(id: string) {
    return this.http.get<IUser[]>(`:API_URL/activities/owners/${id}`);
  }

  findByUser(id: string) {
    return this.http.get<IActivity[]>(`:API_URL/activities/users/${id}`);
  }

  findByEquipment(id: string) {
    return this.http.get<IActivity[]>(`:API_URL/activities/equipment/${id}`);
  }

  findByFinal(id: string) {
    return this.http.get<IActivity[]>(`:API_URL/activities/final/${id}`);
  }

  findByProject(id: string) {
    return this.http.get<IActivity[]>(`:API_URL/activities/project/${id}`);
  }

  findByAsset(id: string) {
    return this.http.get<IActivity[]>(`:API_URL/activities/assets/${id}`);
  }

  findByVendor(id: string) {
    return this.http.get<IActivity[]>(`:API_URL/activities/vendor/${id}`);
  }

  create(data: ICreateActivityDto) {
    return this.http.post(`:API_URL/activities`, { ...data }).pipe(
      tap(() => {
        this.init();
      })
    );
  }

  updateOne(id: string, params = {}) {
    return this.http.put(`:API_URL/activities/${id}`, { ...params });
  }

  updateMany(ids: string[], params = {}) {
    return this.http.put(`:API_URL/activities/many`, {
      ids: ids,
      data: params,
    });
  }

  removeOne(id: string) {
    return this.http.delete(`:API_URL/activities/${id}`);
  }

  removeMany(ids: string[]) {
    return this.http.put(`:API_URL/activities/many`, {
      ids: ids,
      data: { deleted: true },
    });
  }

  deleteMany(data) {
    return this.http.post(`:API_URL/activities/delete-many`, data);
  }

  markDoneOne(id: string) {
    return this.http.delete(`:API_URL/activities/markasdone/${id}`);
  }
}
