export const countryCodes = [
  {
    name: {
      es: 'Afganistán',
      en: 'Afghanistan',
    },
    dial_code: '+93',
    code: 'AF',
  },
  {
    name: {
      es: 'Islas Aland',
      en: 'Aland Islands',
    },
    dial_code: '+35818',
    code: 'AX',
  },
  {
    name: {
      es: 'Albania',
      en: 'Albania',
    },
    dial_code: '+355',
    code: 'AL',
  },
  {
    name: {
      es: 'Argelia',
      en: 'Algeria',
    },
    dial_code: '+213',
    code: 'DZ',
  },
  {
    name: {
      es: 'Samoa Americana',
      en: 'American Samoa',
    },
    dial_code: '+1684',
    code: 'AS',
  },
  {
    name: {
      es: 'Andorra',
      en: 'Andorra',
    },
    dial_code: '+376',
    code: 'AD',
  },
  {
    name: {
      es: 'Angola',
      en: 'Angola',
    },
    dial_code: '+244',
    code: 'AO',
  },
  {
    name: {
      es: 'Anguila',
      en: 'Anguilla',
    },
    dial_code: '+1264',
    code: 'AI',
  },
  {
    name: {
      es: 'Antártida',
      en: 'Antarctica',
    },
    dial_code: '+672',
    code: 'AQ',
  },
  {
    name: {
      es: 'Antigua y Barbuda',
      en: 'Antigua and Barbuda',
    },
    dial_code: '+1268',
    code: 'AG',
  },
  {
    name: {
      es: 'Argentina',
      en: 'Argentina',
    },
    dial_code: '+54',
    code: 'AR',
  },
  {
    name: {
      es: 'Armenia',
      en: 'Armenia',
    },
    dial_code: '+374',
    code: 'AM',
  },
  {
    name: {
      es: 'Aruba',
      en: 'Aruba',
    },
    dial_code: '+297',
    code: 'AW',
  },
  {
    name: {
      es: 'Australia',
      en: 'Australia',
    },
    dial_code: '+61',
    code: 'AU',
  },
  {
    name: {
      es: 'Austria',
      en: 'Austria',
    },
    dial_code: '+43',
    code: 'AT',
  },
  {
    name: {
      es: 'Azerbaiyán',
      en: 'Azerbaijan',
    },
    dial_code: '+994',
    code: 'AZ',
  },
  {
    name: {
      es: 'Bahamas',
      en: 'Bahamas',
    },
    dial_code: '+1242',
    code: 'BS',
  },
  {
    name: {
      es: 'Bahréin',
      en: 'Bahrain',
    },
    dial_code: '+973',
    code: 'BH',
  },
  {
    name: {
      es: 'Bangladesh',
      en: 'Bangladesh',
    },
    dial_code: '+880',
    code: 'BD',
  },
  {
    name: {
      es: 'Barbados',
      en: 'Barbados',
    },
    dial_code: '+1246',
    code: 'BB',
  },
  {
    name: {
      es: 'Bielorrusia',
      en: 'Belarus',
    },
    dial_code: '+375',
    code: 'BY',
  },
  {
    name: {
      es: 'Bélgica',
      en: 'Belgium',
    },
    dial_code: '+32',
    code: 'BE',
  },
  {
    name: {
      es: 'Belice',
      en: 'Belize',
    },
    dial_code: '+501',
    code: 'BZ',
  },
  {
    name: {
      es: 'Benín',
      en: 'Benin',
    },
    dial_code: '+229',
    code: 'BJ',
  },
  {
    name: {
      es: 'Bermudas',
      en: 'Bermuda',
    },
    dial_code: '+1441',
    code: 'BM',
  },
  {
    name: {
      es: 'Bután',
      en: 'Bhutan',
    },
    dial_code: '+975',
    code: 'BT',
  },
  {
    name: {
      es: 'Bolivia',
      en: 'Bolivia, Plurinational State of',
    },
    dial_code: '+591',
    code: 'BO',
  },
  {
    name: {
      es: 'Bosnia y Herzegovina',
      en: 'Bosnia and Herzegovina',
    },
    dial_code: '+387',
    code: 'BA',
  },
  {
    name: {
      es: 'Botsuana',
      en: 'Botswana',
    },
    dial_code: '+267',
    code: 'BW',
  },
  {
    name: {
      es: 'Brasil',
      en: 'Brazil',
    },
    dial_code: '+55',
    code: 'BR',
  },
  {
    name: {
      es: 'Territorio Británico del Océano Índico',
      en: 'British Indian Ocean Territory',
    },
    dial_code: '+246',
    code: 'IO',
  },
  {
    name: {
      es: 'Brunei Darussalam',
      en: 'Brunei Darussalam',
    },
    dial_code: '+673',
    code: 'BN',
  },
  {
    name: {
      es: 'Bulgaria',
      en: 'Bulgaria',
    },
    dial_code: '+359',
    code: 'BG',
  },
  {
    name: {
      es: 'Burkina Faso',
      en: 'Burkina Faso',
    },
    dial_code: '+226',
    code: 'BF',
  },
  {
    name: {
      es: 'Burundi',
      en: 'Burundi',
    },
    dial_code: '+257',
    code: 'BI',
  },
  {
    name: {
      es: 'Camboya',
      en: 'Cambodia',
    },
    dial_code: '+855',
    code: 'KH',
  },
  {
    name: {
      es: 'Camerún',
      en: 'Cameroon',
    },
    dial_code: '+237',
    code: 'CM',
  },
  {
    name: {
      es: 'Canadá',
      en: 'Canada',
    },
    dial_code: '+1',
    code: 'CA',
  },
  {
    name: {
      es: 'Cabo Verde',
      en: 'Cape Verde',
    },
    dial_code: '+238',
    code: 'CV',
  },
  {
    name: {
      es: 'Islas Caimán',
      en: 'Cayman Islands',
    },
    dial_code: '+1345',
    code: 'KY',
  },
  {
    name: {
      es: 'República Centroafricana',
      en: 'Central African Republic',
    },
    dial_code: '+236',
    code: 'CF',
  },
  {
    name: {
      es: 'Chad',
      en: 'Chad',
    },
    dial_code: '+235',
    code: 'TD',
  },
  {
    name: {
      es: 'Chile',
      en: 'Chile',
    },
    dial_code: '+56',
    code: 'CL',
  },
  {
    name: {
      es: 'China',
      en: 'China',
    },
    dial_code: '+86',
    code: 'CN',
  },
  {
    name: {
      es: 'Isla de Navidad',
      en: 'Christmas Island',
    },
    dial_code: '+61',
    code: 'CX',
  },
  {
    name: {
      es: 'Cocos (Keeling) Islands',
      en: 'Cocos (Keeling) Islands',
    },
    dial_code: '+61',
    code: 'CC',
  },
  {
    name: {
      es: 'Colombia',
      en: 'Colombia',
    },
    dial_code: '+57',
    code: 'CO',
  },
  {
    name: {
      es: 'Comoras',
      en: 'Comoros',
    },
    dial_code: '+269',
    code: 'KM',
  },
  {
    name: {
      es: 'Congo',
      en: 'Congo',
    },
    dial_code: '+242',
    code: 'CG',
  },
  {
    name: {
      es: 'República Democrática del Congo',
      en: 'Congo, The Democratic Republic of the',
    },
    dial_code: '+243',
    code: 'CD',
  },
  {
    name: {
      es: 'Islas Cook',
      en: 'Cook Islands',
    },
    dial_code: '+682',
    code: 'CK',
  },
  {
    name: {
      es: 'Costa Rica',
      en: 'Costa Rica',
    },
    dial_code: '+506',
    code: 'CR',
  },
  {
    name: {
      es: 'Costa de Marfil',
      en: "Cote d'Ivoire",
    },
    dial_code: '+225',
    code: 'CI',
  },
  {
    name: {
      es: 'Croacia',
      en: 'Croatia',
    },
    dial_code: '+385',
    code: 'HR',
  },
  {
    name: {
      es: 'Cuba',
      en: 'Cuba',
    },
    dial_code: '+53',
    code: 'CU',
  },
  {
    name: {
      es: 'Chipre',
      en: 'Cyprus',
    },
    dial_code: '+357',
    code: 'CY',
  },
  {
    name: {
      es: 'República Checa',
      en: 'Czech Republic',
    },
    dial_code: '+420',
    code: 'CZ',
  },
  {
    name: {
      es: 'Dinamarca',
      en: 'Denmark',
    },
    dial_code: '+45',
    code: 'DK',
  },
  {
    name: {
      es: 'Yibuti',
      en: 'Djibouti',
    },
    dial_code: '+253',
    code: 'DJ',
  },
  {
    name: {
      es: 'Dominica',
      en: 'Dominica',
    },
    dial_code: '+1767',
    code: 'DM',
  },
  {
    name: {
      es: 'República Dominicana',
      en: 'Dominican Republic',
    },
    dial_code: '+1849',
    code: 'DO',
  },
  {
    name: {
      es: 'Ecuador',
      en: 'Ecuador',
    },
    dial_code: '+593',
    code: 'EC',
  },
  {
    name: {
      es: 'Egipto',
      en: 'Egypt',
    },
    dial_code: '+20',
    code: 'EG',
  },
  {
    name: {
      es: 'El Salvador',
      en: 'El Salvador',
    },
    dial_code: '+503',
    code: 'SV',
  },
  {
    name: {
      es: 'Guinea Ecuatorial',
      en: 'Equatorial Guinea',
    },
    dial_code: '+240',
    code: 'GQ',
  },
  {
    name: {
      es: 'Eritrea',
      en: 'Eritrea',
    },
    dial_code: '+291',
    code: 'ER',
  },
  {
    name: {
      es: 'Estonia',
      en: 'Estonia',
    },
    dial_code: '+372',
    code: 'EE',
  },
  {
    name: {
      es: 'Etiopía',
      en: 'Ethiopia',
    },
    dial_code: '+251',
    code: 'ET',
  },
  {
    name: {
      es: 'Islas Malvinas',
      en: 'Falkland Islands (Malvinas)',
    },
    dial_code: '+500',
    code: 'FK',
  },
  {
    name: {
      es: 'Islas Faroe',
      en: 'Faroe Islands',
    },
    dial_code: '+298',
    code: 'FO',
  },
  {
    name: {
      es: 'Fiyi',
      en: 'Fiji',
    },
    dial_code: '+679',
    code: 'FJ',
  },
  {
    name: {
      es: 'Finlandia',
      en: 'Finland',
    },
    dial_code: '+358',
    code: 'FI',
  },
  {
    name: {
      es: 'Francia',
      en: 'France',
    },
    dial_code: '+33',
    code: 'FR',
  },
  {
    name: {
      es: 'Guayana Francesa',
      en: 'French Guiana',
    },
    dial_code: '+594',
    code: 'GF',
  },
  {
    name: {
      es: 'Polinesia Francesa',
      en: 'French Polynesia',
    },
    dial_code: '+689',
    code: 'PF',
  },
  {
    name: {
      es: 'Gabón',
      en: 'Gabon',
    },
    dial_code: '+241',
    code: 'GA',
  },
  {
    name: {
      es: 'Gambia',
      en: 'Gambia',
    },
    dial_code: '+220',
  },
  {
    name: {
      es: 'Georgia',
      en: 'Georgia',
    },
    dial_code: '+995',
    code: 'GE',
  },
  {
    name: {
      es: 'Alemania',
      en: 'Germany',
    },
    dial_code: '+49',
    code: 'DE',
  },
  {
    name: {
      es: 'Ghana',
      en: 'Ghana',
    },
    dial_code: '+233',
    code: 'GH',
  },
  {
    name: {
      es: 'Gibraltar',
      en: 'Gibraltar',
    },
    dial_code: '+350',
    code: 'GI',
  },
  {
    name: {
      es: 'Grecia',
      en: 'Greece',
    },
    dial_code: '+30',
    code: 'GR',
  },
  {
    name: {
      es: 'Groenlandia',
      en: 'Greenland',
    },
    dial_code: '+299',
    code: 'GL',
  },
  {
    name: {
      es: 'Granada',
      en: 'Grenada',
    },
    dial_code: '+1473',
    code: 'GD',
  },
  {
    name: {
      es: 'Guadalupe',
      en: 'Guadeloupe',
    },
    dial_code: '+590',
    code: 'GP',
  },
  {
    name: {
      es: 'Guam',
      en: 'Guam',
    },
    dial_code: '+1671',
    code: 'GU',
  },
  {
    name: {
      es: 'Guatemala',
      en: 'Guatemala',
    },
    dial_code: '+502',
    code: 'GT',
  },
  {
    name: {
      es: 'Guernsey',
      en: 'Guernsey',
    },
    dial_code: '+44',
    code: 'GG',
  },
  {
    name: {
      es: 'Guinea',
      en: 'Guinea',
    },
    dial_code: '+224',
    code: 'GN',
  },
  {
    name: {
      es: 'Guinea-Bissau',
      en: 'Guinea-Bissau',
    },
    dial_code: '+245',
    code: 'GW',
  },
  {
    name: {
      es: 'Guyana',
      en: 'Guyana',
    },
    dial_code: '+592',
    code: 'GY',
  },
  {
    name: {
      es: 'Haití',
      en: 'Haiti',
    },
    dial_code: '+509',
    code: 'HT',
  },
  {
    name: {
      es: 'Islas Heard y McDonald',
      en: 'Heard Island and McDonald Islands',
    },
    dial_code: '+0',
    code: 'HM',
  },
  {
    name: {
      es: 'Santa Sede',
      en: 'Holy See (Vatican City State)',
    },
    dial_code: '+39',
    code: 'VA',
  },
  {
    name: {
      es: 'Honduras',
      en: 'Honduras',
    },
    dial_code: '+504',
    code: 'HN',
  },
  {
    name: {
      es: 'Hong Kong',
      en: 'Hong Kong',
    },
    dial_code: '+852',
    code: 'HK',
  },
  {
    name: {
      es: 'Hungría',
      en: 'Hungary',
    },
    dial_code: '+36',
    code: 'HU',
  },
  {
    name: {
      es: 'Islandia',
      en: 'Iceland',
    },
    dial_code: '+354',
    code: 'IS',
  },
  {
    name: {
      es: 'India',
      en: 'India',
    },
    dial_code: '+91',
    code: 'IN',
  },
  {
    name: {
      es: 'Indonesia',
      en: 'Indonesia',
    },
    dial_code: '+62',
    code: 'ID',
  },
  {
    name: {
      es: 'Irán, República Islámica de',
      en: 'Iran, Islamic Republic of',
    },
    dial_code: '+98',
    code: 'IR',
  },
  {
    name: {
      es: 'Iraq',
      en: 'Iraq',
    },
    dial_code: '+964',
    code: 'IQ',
  },
  {
    name: {
      es: 'Irlanda',
      en: 'Ireland',
    },
    dial_code: '+353',
    code: 'IE',
  },
  {
    name: {
      es: 'Isla de Man',
      en: 'Isle of Man',
    },
    dial_code: '+44',
    code: 'IM',
  },
  {
    name: {
      es: 'Israel',
      en: 'Israel',
    },
    dial_code: '+972',
    code: 'IL',
  },
  {
    name: {
      es: 'Italia',
      en: 'Italy',
    },
    dial_code: '+39',
    code: 'IT',
  },
  {
    name: {
      es: 'Jamaica',
      en: 'Jamaica',
    },
    dial_code: '+1876',
    code: 'JM',
  },
  {
    name: {
      es: 'Japón',
      en: 'Japan',
    },
    dial_code: '+81',
    code: 'JP',
  },
  {
    name: {
      es: 'Jersey',
      en: 'Jersey',
    },
    dial_code: '+44',
    code: 'JE',
  },
  {
    name: {
      es: 'Jordania',
      en: 'Jordan',
    },
    dial_code: '+962',
    code: 'JO',
  },
  {
    name: {
      es: 'Kazajistán',
      en: 'Kazakhstan',
    },
    dial_code: '+7',
    code: 'KZ',
  },
  {
    name: {
      es: 'Kenia',
      en: 'Kenya',
    },
    dial_code: '+254',
    code: 'KE',
  },
  {
    name: {
      es: 'Kiribati',
      en: 'Kiribati',
    },
    dial_code: '+686',
    code: 'KI',
  },
  {
    name: {
      es: 'Corea, República Popular Democrática de',
      en: "Korea, Democratic People's Republic of",
    },
    dial_code: '+850',
    code: 'KP',
  },
  {
    name: {
      es: 'Corea, República de',
      en: 'Korea, Republic of',
    },
    dial_code: '+82',
    code: 'KR',
  },
  {
    name: {
      es: 'Kuwait',
      en: 'Kuwait',
    },
    dial_code: '+965',
    code: 'KW',
  },
  {
    name: {
      es: 'República Kirguisa',
      en: 'Kyrgyzstan',
    },
    dial_code: '+996',
    code: 'KG',
  },
  {
    name: {
      es: 'República Democrática Popular de Laos',
      en: "Lao People's Democratic Republic",
    },
    dial_code: '+856',
    code: 'LA',
  },
  {
    name: {
      es: 'Letonia',
      en: 'Latvia',
    },
    dial_code: '+371',
    code: 'LV',
  },
  {
    name: {
      es: 'Líbano',
      en: 'Lebanon',
    },
    dial_code: '+961',
    code: 'LB',
  },
  {
    name: {
      es: 'Lesoto',
      en: 'Lesotho',
    },
    dial_code: '+266',
    code: 'LS',
  },
  {
    name: {
      es: 'Liberia',
      en: 'Liberia',
    },
    dial_code: '+231',
    code: 'LR',
  },
  {
    name: {
      es: 'Jamahiriya Árabe Libia',
      en: 'Libyan Arab Jamahiriya',
    },
    dial_code: '+218',
    code: 'LY',
  },
  {
    name: {
      es: 'Liechtenstein',
      en: 'Liechtenstein',
    },
    dial_code: '+423',
    code: 'LI',
  },
  {
    name: {
      es: 'Lituania',
      en: 'Lithuania',
    },
    dial_code: '+370',
    code: 'LT',
  },
  {
    name: {
      es: 'Luxemburgo',
      en: 'Luxembourg',
    },
    dial_code: '+352',
    code: 'LU',
  },
  {
    name: {
      es: 'Macao',
      en: 'Macao',
    },
    dial_code: '+853',
    code: 'MO',
  },
  {
    name: {
      es: 'Macedonia, la Antigua República Yugoslava de',
      en: 'Macedonia, The Former Yugoslav Republic of',
    },
    dial_code: '+389',
    code: 'MK',
  },
  {
    name: {
      es: 'Madagascar',
      en: 'Madagascar',
    },
    dial_code: '+261',
    code: 'MG',
  },
  {
    name: {
      es: 'Malawi',
      en: 'Malawi',
    },
    dial_code: '+265',
    code: 'MW',
  },
  {
    name: {
      es: 'Malasia',
      en: 'Malaysia',
    },
    dial_code: '+60',
    code: 'MY',
  },
  {
    name: {
      es: 'Maldivas',
      en: 'Maldives',
    },
    dial_code: '+960',
    code: 'MV',
  },
  {
    name: {
      es: 'Malí',
      en: 'Mali',
    },
    dial_code: '+223',
    code: 'ML',
  },
  {
    name: {
      es: 'Malta',
      en: 'Malta',
    },
    dial_code: '+356',
    code: 'MT',
  },
  {
    name: {
      es: 'Islas Marshall',
      en: 'Marshall Islands',
    },
    dial_code: '+692',
    code: 'MH',
  },
  {
    name: {
      es: 'Martinica',
      en: 'Martinique',
    },
    dial_code: '+596',
    code: 'MQ',
  },
  {
    name: {
      es: 'Mauritania',
      en: 'Mauritania',
    },
    dial_code: '+222',
    code: 'MR',
  },
  {
    name: {
      es: 'Mauricio',
      en: 'Mauritius',
    },
    dial_code: '+230',
    code: 'MU',
  },
  {
    name: {
      es: 'Mayotte',
      en: 'Mayotte',
    },
    dial_code: '+262',
    code: 'YT',
  },
  {
    name: {
      es: 'México',
      en: 'Mexico',
    },
    dial_code: '+52',
    code: 'MX',
  },
  {
    name: {
      es: 'Micronesia, Estados Federados de',
      en: 'Micronesia, Federated States of',
    },
    dial_code: '+691',
    code: 'FM',
  },
  {
    name: {
      es: 'Moldova, República de',
      en: 'Moldova, Republic of',
    },
    dial_code: '+373',
    code: 'MD',
  },
  {
    name: {
      es: 'Mónaco',
      en: 'Monaco',
    },
    dial_code: '+377',
    code: 'MC',
  },
  {
    name: {
      es: 'Mongolia',
      en: 'Mongolia',
    },
    dial_code: '+976',
    code: 'MN',
  },
  {
    name: {
      es: 'Montenegro',
      en: 'Montenegro',
    },
    dial_code: '+382',
    code: 'ME',
  },
  {
    name: {
      es: 'Montserrat',
      en: 'Montserrat',
    },
    dial_code: '+1664',
    code: 'MS',
  },
  {
    name: {
      es: 'Marruecos',
      en: 'Morocco',
    },
    dial_code: '+212',
    code: 'MA',
  },
  {
    name: {
      es: 'Mozambique',
      en: 'Mozambique',
    },
    dial_code: '+258',
    code: 'MZ',
  },
  {
    name: {
      es: 'Myanmar',
      en: 'Myanmar',
    },
    dial_code: '+95',
    code: 'MM',
  },
  {
    name: {
      es: 'Namibia',
      en: 'Namibia',
    },
    dial_code: '+264',
    code: 'NA',
  },
  {
    name: {
      es: 'Nauru',
      en: 'Nauru',
    },
    dial_code: '+674',
    code: 'NR',
  },
  {
    name: {
      es: 'Nepal',
      en: 'Nepal',
    },
    dial_code: '+977',
    code: 'NP',
  },
  {
    name: {
      es: 'Países Bajos',
      en: 'Netherlands',
    },
    dial_code: '+31',
    code: 'NL',
  },
  {
    name: {
      es: 'Antillas Neerlandesas',
      en: 'Netherlands Antilles',
    },
    dial_code: '+599',
    code: 'AN',
  },
  {
    name: {
      es: 'Nueva Caledonia',
      en: 'New Caledonia',
    },
    dial_code: '+687',
    code: 'NC',
  },
  {
    name: {
      es: 'Nueva Zelanda',
      en: 'New Zealand',
    },
    dial_code: '+64',
    code: 'NZ',
  },
  {
    name: {
      es: 'Nicaragua',
      en: 'Nicaragua',
    },
    dial_code: '+505',
    code: 'NI',
  },
  {
    name: {
      es: 'Níger',
      en: 'Niger',
    },
    dial_code: '+227',
    code: 'NE',
  },
  {
    name: {
      es: 'Nigeria',
      en: 'Nigeria',
    },
    dial_code: '+234',
    code: 'NG',
  },
  {
    name: {
      es: 'Niue',
      en: 'Niue',
    },
    dial_code: '+683',
    code: 'NU',
  },
  {
    name: {
      es: 'Isla de Norfolk',
      en: 'Norfolk Island',
    },
    dial_code: '+672',
    code: 'NF',
  },
  {
    name: {
      es: 'Macedonia del Norte',
      en: 'North Macedonia',
    },
    dial_code: '+389',
    code: 'MK',
  },
  {
    name: {
      es: 'Islas Marianas del Norte',
      en: 'Northern Mariana Islands',
    },
    dial_code: '+1670',
    code: 'MP',
  },
  {
    name: {
      es: 'Noruega',
      en: 'Norway',
    },
    dial_code: '+47',
    code: 'NO',
  },
  {
    name: {
      es: 'Omán',
      en: 'Oman',
    },
    dial_code: '+968',
    code: 'OM',
  },
  {
    name: {
      es: 'Pakistán',
      en: 'Pakistan',
    },
    dial_code: '+92',
    code: 'PK',
  },
  {
    name: {
      es: 'Palaos',
      en: 'Palau',
    },
    dial_code: '+680',
    code: 'PW',
  },
  {
    name: {
      es: 'Palestina, Estado de',
      en: 'Palestine, State of',
    },
    dial_code: '+970',
    code: 'PS',
  },
  {
    name: {
      es: 'Panamá',
      en: 'Panama',
    },
    dial_code: '+507',
    code: 'PA',
  },
  {
    name: {
      es: 'Papúa Nueva Guinea',
      en: 'Papua New Guinea',
    },
    dial_code: '+675',
    code: 'PG',
  },
  {
    name: {
      es: 'Paraguay',
      en: 'Paraguay',
    },
    dial_code: '+595',
    code: 'PY',
  },
  {
    name: {
      es: 'Perú',
      en: 'Peru',
    },
    dial_code: '+51',
    code: 'PE',
  },
  {
    name: {
      es: 'Filipinas',
      en: 'Philippines',
    },
    dial_code: '+63',
    code: 'PH',
  },
  {
    name: {
      es: 'Islas Pitcairn',
      en: 'Pitcairn',
    },
    dial_code: '+870',
    code: 'PN',
  },
  {
    name: {
      es: 'Polonia',
      en: 'Poland',
    },
    dial_code: '+48',
    code: 'PL',
  },
  {
    name: {
      es: 'Portugal',
      en: 'Portugal',
    },
    dial_code: '+351',
    code: 'PT',
  },
  {
    name: {
      es: 'Puerto Rico',
      en: 'Puerto Rico',
    },
    dial_code: '+1939',
    code: 'PR',
  },
  {
    name: {
      es: 'Qatar',
      en: 'Qatar',
    },
    dial_code: '+974',
    code: 'QA',
  },
  {
    name: {
      es: 'Reunión',
      en: 'Réunion',
    },
    dial_code: '+262',
    code: 'RE',
  },
  {
    name: {
      es: 'Rumania',
      en: 'Romania',
    },
    dial_code: '+40',
    code: 'RO',
  },
  {
    name: {
      es: 'Federación Rusa',
      en: 'Russian Federation',
    },
    dial_code: '+7',
    code: 'RU',
  },
  {
    name: {
      es: 'Ruanda',
      en: 'Rwanda',
    },
    dial_code: '+250',
    code: 'RW',
  },
  {
    name: {
      es: 'San Bartolomé',
      en: 'Saint Barthélemy',
    },
    dial_code: '+590',
    code: 'BL',
  },
  {
    name: {
      es: 'Santa Elena, Ascensión y Tristán de Acuña',
      en: 'Saint Helena, Ascension and Tristan Da Cunha',
    },
    dial_code: '+290',
    code: 'SH',
  },
  {
    name: {
      es: 'San Cristóbal y Nieves',
      en: 'Saint Kitts and Nevis',
    },
    dial_code: '+1869',
    code: 'KN',
  },
  {
    name: {
      es: 'Santa Lucía',
      en: 'Saint Lucia',
    },
    dial_code: '+1758',
    code: 'LC',
  },
  {
    name: {
      es: 'San Martín (parte francesa)',
      en: 'Saint Martin (French part)',
    },
    dial_code: '+590',
    code: 'MF',
  },
  {
    name: {
      es: 'San Pedro y Miquelón',
      en: 'Saint Pierre and Miquelon',
    },
    dial_code: '+508',
    code: 'PM',
  },
  {
    name: {
      es: 'San Vicente y las Granadinas',
      en: 'Saint Vincent and the Grenadines',
    },
    dial_code: '+1784',
    code: 'VC',
  },
  {
    name: {
      es: 'Samoa',
      en: 'Samoa',
    },
    dial_code: '+685',
    code: 'WS',
  },
  {
    name: {
      es: 'San Marino',
      en: 'San Marino',
    },
    dial_code: '+378',
    code: 'SM',
  },
  {
    name: {
      es: 'Santo Tomé y Príncipe',
      en: 'Sao Tome and Principe',
    },

    dial_code: '+239',
    code: 'ST',
  },
  {
    name: {
      es: 'Arabia Saudita',
      en: 'Saudi Arabia',
    },
    dial_code: '+966',
    code: 'SA',
  },
  {
    name: {
      es: 'Senegal',
      en: 'Senegal',
    },
    dial_code: '+221',
    code: 'SN',
  },
  {
    name: {
      es: 'Serbia',
      en: 'Serbia',
    },
    dial_code: '+381',
    code: 'RS',
  },
  {
    name: {
      es: 'Seychelles',
      en: 'Seychelles',
    },
    dial_code: '+248',
    code: 'SC',
  },
  {
    name: {
      es: 'Sierra Leona',
      en: 'Sierra Leone',
    },
    dial_code: '+232',
    code: 'SL',
  },
  {
    name: {
      es: 'Singapur',
      en: 'Singapore',
    },
    dial_code: '+65',
    code: 'SG',
  },
  {
    name: {
      es: 'Eslovaquia',
      en: 'Slovakia',
    },
    dial_code: '+421',
    code: 'SK',
  },
  {
    name: {
      es: 'Eslovenia',
      en: 'Slovenia',
    },
    dial_code: '+386',
    code: 'SI',
  },
  {
    name: {
      es: 'Islas Salomón',
      en: 'Solomon Islands',
    },
    dial_code: '+677',
    code: 'SB',
  },
  {
    name: {
      es: 'Somalia',
      en: 'Somalia',
    },
    dial_code: '+252',
    code: 'SO',
  },
  {
    name: {
      es: 'Sudáfrica',
      en: 'South Africa',
    },
    dial_code: '+27',
    code: 'ZA',
  },
  {
    name: {
      es: 'Sudán del Sur',
      en: 'South Sudan',
    },
    dial_code: '+211',
    code: 'SS',
  },
  {
    name: {
      es: 'España',
      en: 'Spain',
    },
    dial_code: '+34',
    code: 'ES',
  },
  {
    name: {
      es: 'Sri Lanka',
      en: 'Sri Lanka',
    },
    dial_code: '+94',
    code: 'LK',
  },
  {
    name: {
      es: 'Sudán',
      en: 'Sudan',
    },
    dial_code: '+249',
    code: 'SD',
  },
  {
    name: {
      es: 'Surinam',
      en: 'Suriname',
    },
    dial_code: '+597',
    code: 'SR',
  },
  {
    name: {
      es: 'Svalbard y Jan Mayen',
      en: 'Svalbard and Jan Mayen',
    },
    dial_code: '+47',
    code: 'SJ',
  },
  {
    name: {
      es: 'Suazilandia',
      en: 'Swaziland',
    },
    dial_code: '+268',
    code: 'SZ',
  },
  {
    name: {
      es: 'Suecia',
      en: 'Sweden',
    },
    dial_code: '+46',
    code: 'SE',
  },
  {
    name: {
      es: 'Suiza',
      en: 'Switzerland',
    },
    dial_code: '+41',
    code: 'CH',
  },
  {
    name: {
      es: 'República Árabe Siria',
      en: 'Syrian Arab Republic',
    },
    dial_code: '+963',
    code: 'SY',
  },
  {
    name: {
      es: 'Taiwán, Provincia de China',
      en: 'Taiwan, Province of China',
    },
    dial_code: '+886',
    code: 'TW',
  },
  {
    name: {
      es: 'Tayikistán',
      en: 'Tajikistan',
    },
    dial_code: '+992',
    code: 'TJ',
  },
  {
    name: {
      es: 'Tanzania, República Unida de',
      en: 'Tanzania, United Republic of',
    },
    dial_code: '+255',
    code: 'TZ',
  },
  {
    name: {
      es: 'Tailandia',
      en: 'Thailand',
    },
    dial_code: '+66',
    code: 'TH',
  },
  {
    name: {
      es: 'Timor-Leste',
      en: 'Timor-Leste',
    },
    dial_code: '+670',
    code: 'TL',
  },
  {
    name: {
      es: 'Togo',
      en: 'Togo',
    },
    dial_code: '+228',
    code: 'TG',
  },
  {
    name: {
      es: 'Tokelau',
      en: 'Tokelau',
    },
    dial_code: '+690',
    code: 'TK',
  },
  {
    name: {
      es: 'Tonga',
      en: 'Tonga',
    },
    dial_code: '+676',
    code: 'TO',
  },
  {
    name: {
      es: 'Trinidad y Tobago',
      en: 'Trinidad and Tobago',
    },
    dial_code: '+1868',
    code: 'TT',
  },
  {
    name: {
      es: 'Túnez',
      en: 'Tunisia',
    },
    dial_code: '+216',
    code: 'TN',
  },
  {
    name: {
      es: 'Turquía',
      en: 'Turkey',
    },
    dial_code: '+90',
    code: 'TR',
  },
  {
    name: {
      es: 'Turkmenistán',
      en: 'Turkmenistan',
    },
    dial_code: '+993',
    code: 'TM',
  },
  {
    name: {
      es: 'Islas Turcas y Caicos',
      en: 'Turks and Caicos Islands',
    },
    dial_code: '+1649',
    code: 'TC',
  },
  {
    name: {
      es: 'Tuvalu',
      en: 'Tuvalu',
    },
    dial_code: '+688',
    code: 'TV',
  },
  {
    name: {
      es: 'Uganda',
      en: 'Uganda',
    },
    dial_code: '+256',
    code: 'UG',
  },
  {
    name: {
      es: 'Ucrania',
      en: 'Ukraine',
    },
    dial_code: '+380',
    code: 'UA',
  },
  {
    name: {
      es: 'Emiratos Árabes Unidos',
      en: 'United Arab Emirates',
    },
    dial_code: '+971',
    code: 'AE',
  },
  {
    name: {
      es: 'Reino Unido',
      en: 'United Kingdom',
    },
    dial_code: '+44',
    code: 'GB',
  },
  {
    name: {
      es: 'Estados Unidos',
      en: 'United States',
    },
    dial_code: '+1',
    code: 'US',
  },
  {
    name: {
      es: 'Uruguay',
      en: 'Uruguay',
    },
    dial_code: '+598',
    code: 'UY',
  },
  {
    name: {
      es: 'Uzbekistán',
      en: 'Uzbekistan',
    },
    dial_code: '+998',
    code: 'UZ',
  },
  {
    name: {
      es: 'Vanuatu',
      en: 'Vanuatu',
    },
    dial_code: '+678',
    code: 'VU',
  },
  {
    name: {
      es: 'Ciudad del Vaticano',
      en: 'Vatican City State',
    },
    dial_code: '+39',
    code: 'VA',
  },
  {
    name: {
      es: 'Venezuela',
      en: 'Venezuela, Bolivarian Republic of',
    },
    dial_code: '+58',
    code: 'VE',
  },
  {
    name: {
      es: 'Viet Nam',
      en: 'Viet Nam',
    },
    dial_code: '+84',
    code: 'VN',
  },
  {
    name: {
      es: 'Islas Vírgenes Británicas',
      en: 'Virgin Islands, British',
    },
    dial_code: '+1284',
    code: 'VG',
  },
  {
    name: {
      es: 'Islas Vírgenes de los Estados Unidos',
      en: 'Virgin Islands, U.S.',
    },
    dial_code: '+1340',
    code: 'VI',
  },
  {
    name: {
      es: 'Wallis y Futuna',
      en: 'Wallis and Futuna',
    },
    dial_code: '+681',
    code: 'WF',
  },
  {
    name: {
      es: 'Sahara Occidental',
      en: 'Western Sahara',
    },
    dial_code: '+212',
    code: 'EH',
  },
  {
    name: {
      es: 'Yemen',
      en: 'Yemen',
    },
    dial_code: '+967',
    code: 'YE',
  },
  {
    name: {
      es: 'Zambia',
      en: 'Zambia',
    },
    dial_code: '+260',
    code: 'ZM',
  },
  {
    name: {
      es: 'Zimbabwe',
      en: 'Zimbabwe',
    },
    dial_code: '+263',
    code: 'ZW',
  },
];
