import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputPasswordComponent } from './input-password.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    InputPasswordComponent
  ],
  imports: [
    CommonModule, TranslateModule.forChild(), FormsModule
  ],
  exports:[InputPasswordComponent]
})
export class InputPasswordModule { }
