<roma-modal-template
  size="md"
  [title]="'sequence.add' | translate"
  [headerBottomMargin]="'24px'"
  [showDefaultButtons]="true"
  [contentMinHeight]="false"
  [isLoading]="isSubmitting"
  size="md"
  (success)="submit()"
  (cancel)="close()"
>
  <div class="col-lg-12 col-md-12 col-sm-12" style="width: 100%">
    <div class="form-group">
      <label for="name"> {{ 'sequence.name' | translate }} </label>
      <img
        style="padding-left: 5px"
        tooltipDir
        [tooltipBackground]="'blue'"
        [tooltipMessages]="['sequence.tooltips.numberingTitle']"
        [tooltipPosition]="'bottom'"
        [tooltipShowOn]="'any'"
        [tooltipHideOn]="'any'"
        src="/assets/icons/gl_information-fill-gray.svg"
      />
      <input
        type="text"
        class="form-control"
        id="name"
        aria-describedby="emailHelp"
        maxLength="50"
        [placeholder]="'sequence.namePlaceholder' | translate"
        [(ngModel)]="sequence.name"
        [ngClass]="{ 'is-invalid': submitError && sequence.name === '' }"
      />
      <div *ngIf="submitError && sequence.name === ''" class="invalid-feedback">
        {{ 'sequence.nameRequired' | translate }}
      </div>
    </div>
    <div class="form-group">
      <label for="prefix"> {{ 'sequence.prefix' | translate }} </label>
      <img
        style="padding-left: 5px"
        tooltipDir
        [tooltipBackground]="'blue'"
        [tooltipMessages]="['sequence.tooltips.prefix']"
        [tooltipPosition]="'right'"
        [tooltipShowOn]="'any'"
        [tooltipHideOn]="'any'"
        src="/assets/icons/gl_information-fill-gray.svg"
      />
      <input
        type="text"
        class="form-control"
        id="prefix"
        aria-describedby="emailHelp"
        maxLength="50"
        [placeholder]="'sequence.prefixPlaceholder' | translate"
        [(ngModel)]="sequence.prefix"
        [ngClass]="{ 'is-invalid': submitError && sequence.prefix === '' }"
      />
      <div
        *ngIf="submitError && sequence.prefix === ''"
        class="invalid-feedback"
      >
        {{ 'sequence.prefixRequired' | translate }}
      </div>
    </div>
    <div class="form-group">
      <label for="currentNumber">
        {{ 'sequence.actualValue' | translate }}
      </label>
      <img
        style="padding-left: 5px"
        tooltipDir
        [tooltipBackground]="'blue'"
        [tooltipMessages]="['sequence.tooltips.startNumber1']"
        [tooltipPosition]="'bottom'"
        [tooltipShowOn]="'any'"
        [tooltipHideOn]="'any'"
        src="/assets/icons/gl_information-fill-gray.svg"
      />
      <input
        type="number"
        class="form-control"
        id="currentNumber"
        aria-describedby="emailHelp"
        maxLength="20"
        [(ngModel)]="sequence.currentNumber"
        (paste)="onPaste($event)"
        (keypress)="onKeyPress($event)"
        (change)="onChangeCurrentNumber()"
        pattern="[0-9]*"
      />
    </div>
    <div class="form-group" *ngIf="showIncrement">
      <label for="increment"> {{ 'sequence.increment' | translate }} </label>
      <input
        type="text"
        class="form-control"
        id="increment"
        aria-describedby="emailHelp"
        maxLength="50"
        [(ngModel)]="sequence.incrementValue"
      />
    </div>
    <div class="form-group">
      <label for="type"> {{ 'sequence.type' | translate }} </label>
      <ng-select
        [(ngModel)]="sequence.type"
        [clearable]="false"
        [disabled]="disableList"
        id="type"
      >
        <ng-container *ngFor="let item of types">
          <ng-option
            *ngIf="item !== sequenceTypeRef.ADD_BUDGETS"
            [value]="item"
          >
            <!-- {{ item }} - {{ sequenceTypeRef.ADD_BUDGETS }} -->
            {{ getTranslationBySequenceType(item) }}
          </ng-option>
        </ng-container>
      </ng-select>
    </div>
  </div>
</roma-modal-template>
