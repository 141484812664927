import { Injectable } from '@angular/core';

import { Capacitor } from '@capacitor/core';

import { saveAs } from 'file-saver';

import { FileWriteData, FileWriteOutput } from './file-write.types';
import { FileOpener } from '@capacitor-community/file-opener';
import { Directory, Filesystem } from '@capacitor/filesystem';

@Injectable({
  providedIn: 'root',
})
export class FileWriteService {
  constructor() {}

  async writeFile(data: FileWriteData): Promise<FileWriteOutput> {
    return new Promise(async (res, rej) => {
      try {
        if (Capacitor.getPlatform() === 'web') {
          await this.writeWeb(data);

          res({
            // @ts-ignore
            uri: null,
          });
        } else {
          const dataMobile = await this.writeMobile(data);

          res({
            uri: dataMobile.uri,
          });
        }
      } catch (err) {
        rej(JSON.stringify(err));
      }
    });
  }

  private async writeMobile(data: FileWriteData): Promise<{ uri: string }> {
    await this.writeAndOpenFile(data.blob, data.path);

    // @ts-ignore
    return { uri: null };
  }

  private async writeAndOpenFile(data: Blob, fileName: string) {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onloadend = async function () {
      const base64data = reader.result;
      try {
        const result = await Filesystem.writeFile({
          path: fileName,
          data: <string>base64data,
          directory: Directory.Data,
          recursive: true,
        });
        FileOpener.open({ filePath: result.uri })
          .then(() => {})
          .catch((e) => {});
      } catch (e) {
        console.error('Unable to write file', e);
      }
    };
  }

  private async writeWeb(data: FileWriteData) {
    saveAs(data.blob, data.path);
  }

  private convertBlobToBase64(blob: Blob) {
    return new Promise((res, rej) => {
      const reader = new FileReader();

      reader.onerror = rej;
      reader.onload = () => {
        res(reader.result);
      };

      reader.readAsDataURL(blob);
    });
  }
}
