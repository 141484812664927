import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ModalTemplateComponent } from './modal-template.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { BaseButtonModule } from '@web-frontend/common/components/button/baseButton.module';

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}
@NgModule({
  declarations: [ModalTemplateComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule.forChild(),
    MatDialogModule,
    MatMenuModule,
    ScrollingModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    ClickDeleteModule,
    BaseButtonModule,
  ],
  exports: [ModalTemplateComponent],
  providers: [],
})
export class ModalTemplateModule {}
