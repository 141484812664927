import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  ViewChild,
} from '@angular/core';

import { fromEvent, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map } from 'rxjs/operators';
import { RmSelect } from '../rm-select/rm-select.types';
import { Feature, FeatureUser } from '@tacliatech/types';

@Component({
  selector: 'roma-rm-select-v2',
  templateUrl: './rm-select-v2.component.html',
  styleUrls: ['./rm-select-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmSelectV2Component implements OnInit {
  public innerWidth = 0;
  isResponsive = false;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 768) {
      this.isResponsive = true;
    } else {
      this.isResponsive = false;
    }
  }

  @ViewChild('inputFilterSearch')
  set input(value: ElementRef<HTMLInputElement>) {
    if (value) {
      this._input = value;
      this.setFocusInputSearch();
      this.patchFilterArgParams();
      this.watchInputSearch();
    }
  }

  @Input()
  placeholderText = '';

  @Input()
  mode: RmSelect.Type = 'SINGLE';

  @Input()
  isAbsolute = false;

  @Input()
  isClearable = false;

  @Input()
  listWidth = '';

  @Input()
  addButtonText = 'general.addUser';

  @Input()
  addButtonFeatureUser: Feature.Purchasable | FeatureUser.Purchasable =
    Feature.SystemPermission.DefaultAllow;

  @Input()
  hasError = false;

  _panelClicked = false;
  _isLoading = false;
  _firstLoad = true;
  @Input()
  @HostBinding('class.is-loading')
  set isLoading(value: boolean) {
    this._isLoading = value;
    //if is changed is loaded to false for second time (latter than load), we open the panel
    if (this._isLoading == false) {
      if (this._firstLoad) {
        this._firstLoad = false;
        this._panelClicked = false;
      } else {
        if (this._panelClicked) {
          this.showPanelElement = true;
        }
        this._panelClicked = false;
      }
    }
  }

  get isLoading() {
    return this._isLoading;
  }

  @Input()
  @HostBinding('class.is-disabled')
  disabled!: boolean;

  @Input()
  items: RmSelect.Items = [];

  @Input()
  activeItems: RmSelect.Items = [];

  @Input()
  inputHeight = '44px';

  @Input()
  type = '';

  @Input()
  showAddButton = false;

  @Output()
  changeItems = new EventEmitter<RmSelect.Items>();

  @Output()
  addElement = new EventEmitter<MouseEvent>();

  @Input()
  showPanelElement = false;

  checkBoxItems: any[] = [];
  filterArgs: { title: string } = { title: '' };

  private _input: ElementRef<HTMLInputElement>;
  private sub$ = new Subscription();

  firstLoad = true;
  focusOutA = false;
  focusOutB = false;
  anuleFocusOut = false;

  constructor(private cdr: ChangeDetectorRef, private elRef: ElementRef) {}

  ngOnInit(): void {
    /* if (this.type === 'taxes') {
      this.listWidth = '300px';
    }*/
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 768) {
      this.isResponsive = true;
    } else {
      this.isResponsive = false;
    }
  }

  clickPanel(evt: MouseEvent, open?: boolean, from?: string) {
    this._panelClicked = true;
    if (evt) {
      if (open === true || open === false) {
        this.showPanelElement = open;
      } else {
        this.showPanelElement = !this.showPanelElement;
      }

      if (this.showPanelElement) {
        this.focusOutA = true;
        this.focusOutB = false;
      }
      this.draw();
    }
  }

  async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  anuleFocusOutTimeout() {
    this.anuleFocusOut = true;
    setTimeout(() => {
      this.anuleFocusOut = false;
    }, 200);
  }

  async focusOut(from: string) {
    await this.delay(100);

    if (this.anuleFocusOut == true) {
      return;
    }

    if (from === 'users') {
      this.focusOutA = true;
    }

    if (from === 'status') {
      this.focusOutB = true;
    }

    if (this.focusOutA && this.focusOutB) {
      this.showPanelElement = false;
    }
    this.draw();
  }

  changeSelect(item: RmSelect.Item) {
    this.activeItems = [item];
    this.showPanelElement = false;
    this.changeItems.next(this.activeItems);
    this.draw();
  }

  changeCheckBox(evt, item) {
    if (item.stock || item.price) {
      item = {
        title: item.title,
        value: item.value,
        price: item.price,
        stock: item.stock,
      };
    } else {
      item = {
        title: item.title,
        value: item.value,
        translate: item.translate,
      };
    }

    if (evt.checked) {
      //this.checkBoxItems.push(item.value);
      this.activeItems.push(item);
    } else {
      this.activeItems.splice(
        this.activeItems.findIndex(function (i) {
          return i.value === item.value;
        }),
        1
      );
    }

    this.changeItems.next(this.activeItems);
    this.draw();
  }

  searchCheckFilters(item) {
    if (
      this.activeItems.filter((element) => element.value == item.value).length >
      0
    ) {
      return true;
    } else {
      return false;
    }
  }

  addElementHandle(evt: MouseEvent) {
    if (evt) {
      this.addElement.next(evt);
    }
  }

  clearInput(mode: 'in' | 'out') {
    this.activeItems = [];
    this.filterArgs.title = '';
    this.draw();

    if (mode === 'in') {
      this.changeItems.next(this.activeItems);
    }
  }

  removeItem(item) {
    const index = this.activeItems.findIndex((it) => it.title === item.title);
    this.activeItems.splice(index, 1);
    this.filterArgs.title = '';
    this.changeItems.next(this.activeItems);

    if (!this.activeItems.length) {
      this.showPanelElement = !this.showPanelElement;
    }
    this.draw();
  }

  private draw() {
    this.cdr.detectChanges();
  }

  private setFocusInputSearch() {
    if (this._input) {
      this._input.nativeElement.focus();
    }
  }

  private patchFilterArgParams() {
    if (this._input && this.filterArgs.title) {
      this._input.nativeElement.value = this.filterArgs.title;
    }
  }

  private watchInputSearch() {
    this.sub$.add(
      fromEvent(this._input.nativeElement, 'input')
        .pipe(
          distinctUntilChanged(),
          debounce(() => timer(450)),
          map((res) => (res.target as HTMLTextAreaElement).value as string)
        )
        .subscribe(this.changeKeyword.bind(this))
    );
  }

  private changeKeyword(keyword: string) {
    this.filterArgs = {
      title: keyword.toString().toLocaleLowerCase(),
    };
    this.draw();
  }
}

@Pipe({
  name: 'searchFilter',
  pure: false,
})
export class SearchFilterPipe implements PipeTransform {
  transform(items: RmSelect.Items, filter: { title: string }): any {
    if (!items?.length || !filter?.title) {
      return items;
    }

    return items.filter((item) =>
      item.title.match(new RegExp(filter.title, 'i'))
    );
  }
}
