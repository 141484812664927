import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { FileSelectService } from '@web-frontend/shared/helpers/file-select';
import { UtilImage } from './utils/utils';
import {
  Company,
  EFileType,
  Feature,
  FreeLimitType,
  SharedBusinessErrors,
} from '@tacliatech/types';
import { FileUploadService } from '@web-frontend/shared/helpers/file-upload';
import { ImageFirebasePipe } from '@web-frontend/shared/pipes/image/image-firebase.pipe';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { FileWriteService } from '@web-frontend/shared/helpers/file-write';
import { TooltipPosition } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { ProFeatureService } from '../permissions/pro-feature.service';
import { FreeLimitService } from '@web-frontend/shared/services';
import { finalize } from 'rxjs/operators';
import { environment } from '@web-frontend/environments';
import { TranslateService } from '@ngx-translate/core';

export const MEGABYTES = 0.000001;

class ImageSnippet {
  pending = false;
  status = 'init';

  constructor(public src: string, public file: File, public p?: boolean) {
    this.pending = p;
  }
}

export class fileData {
  fileName: string;
  extension: string;
  file?: any;
  fullFile?: File;
  fileFirebaseUrl?: string;
  hover?: boolean = false;
  markForDelete?: boolean = false;
}

@Component({
  selector: 'roma-image-selector',
  templateUrl: './image-selector-v2.component.html',
  styleUrls: ['./image-selector-v2.component.scss'],
})
export class ImageSelectorComponent
  implements OnInit, AfterViewInit, OnDestroy {
  private fileAdmitData = ['*'];
  accept: string;
  filesReceived;
  overPreview = false;
  isLoading = false;
  util: UtilImage = new UtilImage();
  selectedFile: fileData[] = [];

  _title: string;
  @Input()
  set title(title: string) {
    this._title = title.replace(
      '{arg1}',
      (this.fileSize * MEGABYTES).toString()
    );
  }

  get title() {
    return this._title;
  }

  @Input('save-files-service')
  saveFiles$: Observable<boolean>;

  @Input('background-color')
  backgroundColor = '#3c48ec';

  @Input()
  color = '#fff';

  @Input('quantity')
  quantityFiles = 3;

  @Input()
  fileArrayOld: Array<fileData[]>;

  _fileArray = [];
  @Input()
  set fileArray(fileArray: fileData[]) {
    if (fileArray != null) {
      this._fileArray = fileArray.map((file) => {
        return { ...file, markForDelete: file.markForDelete ? true : false };
      });
    } else {
      this._fileArray = [];
    }
  }

  get fileArray() {
    return this._fileArray;
  }

  @Input()
  feature = Feature.SystemPermission.DefaultAllow;

  @Input()
  featureUser = Feature.SystemPermission.DefaultAllow;

  @Input()
  fileSize = 10000000;

  @Input()
  multiple = true;

  @Input()
  img = 'assets/images/icon-add-file.svg';

  @Input()
  type: string;

  @Input()
  mode: string;

  @Input()
  singleMode = false;

  @Input()
  edit = false;

  @Input()
  showCloseIcon = true;

  @Output('images-changed')
  imagesChanged = new EventEmitter<Array<fileData>>();

  @Output()
  onAllDataSaved = new EventEmitter<fileData[]>();

  @Output()
  changeFileArray = new EventEmitter<fileData[]>();

  @Output()
  cantUploadMoreFiles = new EventEmitter<void>();

  error = SharedBusinessErrors;

  @Input('file-admit')
  set fileAdmit(value: Array<string>) {
    this.fileAdmitData = value;
    this.getAccept();
  }

  @Input()
  module: Company.IdModule = Company.IdModule.Unknown;

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get fileAdmit() {
    return this.fileAdmitData;
  }

  private sub$ = new Subscription();
  permissions$ = this.permissionService.permissions$;
  permissions: string[] = [];
  featureRef = Feature;
  screenWidth = window.screen.width;
  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[0]);
  freeLimit: FreeLimitType.Response;
  filesLimit = environment.freeLimits.files;
  numFilesAdded = 0;

  constructor(
    private fileUploadService: FileUploadService,
    private changeDetectionRef: ChangeDetectorRef,
    private fileSelect: FileSelectService,
    private permissionService: PermissionService,
    private fileWriteService: FileWriteService,
    private toastService: ToastService,
    private proFeatureService: ProFeatureService,
    private freeLimitService: FreeLimitService,
    private i18n: TranslateService
  ) {
    this.fileArray = [];
  }

  get customContentWeb() {
    return null;
  }

  get customContentMobile() {
    return null;
  }

  ngOnInit(): void {
    this.watchSaveImages();

    this.sub$.add(
      // Look for permissions if change and update permissions local property
      this.permissions$.subscribe((permissions) => {
        this.permissions = permissions;
      })
    );

    this.watchFreeLimit();
  }

  ngAfterViewInit(): void {
    this.loadImages();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  async watchSaveImages() {
    if (this.saveFiles$) {
      this.saveFiles$.subscribe(() => {
        this.uploadFiles();
      });
    }
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  loadImages() {}

  private getAccept() {
    const fileAdmit = this.fileAdmit.map((elem) => `.${elem}`);
    this.accept = fileAdmit.join(',');
  }

  hasPermissionFeature$ = this.permissionService.hasFeatureFn(
    Feature.SystemPermission.AllowAll
  );

  async openFileSelector() {
    const response = await this.fileSelect.openSelector({
      multiple: this.multiple,
      extensions: this.fileAdmitData,
    });
    const files = response.getAll('file[]') as File[];

    const isValidExtension = (file: File) => {
      const fileSplit = file.name.split('.');
      if (fileSplit.length <= 1) {
        this.toastService.show({
          text: this.i18n
            .instant('fileUpload.errorUploadFileWithoutExtension')
            .replace('{arg1}', file.name),
          type: 'error',
        });
      }
      return fileSplit.length > 1;
    };

    const isValidFileSize = (file: File) => {
      if (file.size > this.fileSize) {
        this.toastService.show({
          text: this.i18n
            .instant('fileUpload.errorUploadMegabytesArg')
            .replace('{arg1}', file.name),
          type: 'error',
        });
        return false;
      }
      return true;
    };

    // verify if files extension are valid
    const validFiles = files.filter((file) => {
      return isValidExtension(file) && isValidFileSize(file);
    });

    if (validFiles.length === 0) {
      return;
    }

    await this.readAsDataURL(validFiles);
    this.changeFileArray.emit(this.fileArray);
    this.numFilesAdded += validFiles.length;
    if (
      this.fileArray.filter((fil) => fil.markForDelete == false).length >
      this.quantityFiles
    ) {
      this.fileArray.length = this.quantityFiles;
    }
    this.draw();
  }

  _deleteLoading = true;

  fileToDataURL(file) {
    this._deleteLoading = true;
    const self = this;
    const deleteLoading = this._deleteLoading;
    const fileArray = this.fileArray;
    const maxSize = this.fileSize * MEGABYTES;
    const reader = new FileReader();
    const toastService = this.toastService;
    return new Promise(function (resolve, reject) {
      reader.onload = function (event) {
        const fileSplit = file?.name?.split('.');

        const imageData: fileData = {
          fileName: fileSplit
            .join('.')
            .replaceAll(' ', '_')
            .replaceAll('-', '_')
            .replace(`.${fileSplit[fileSplit.length - 1]}`, ''),
          extension: fileSplit[fileSplit.length - 1],
          file: event?.target?.result,
          fullFile: file,
          fileFirebaseUrl: null,
          markForDelete: false,
        };
        //delete the toast loading
        if (self._deleteLoading) {
          self._deleteLoading = false;
          toastService.close();
        }

        if (file.size * MEGABYTES > maxSize) {
          //ERROR OF SIZE
          toastService.show({
            text: 'fileUpload.errorUploadMegabytes',
            type: 'error',
          });
        } else {
          fileArray.push(imageData);
          toastService.show({
            text: 'fileUpload.uploadFileOk',
            type: 'success',
          });
        }

        resolve(true);
      };
      reader.readAsDataURL(file);
    });
  }

  readAsDataURL(target) {
    this.toastService.show({
      text: 'fileUpload.loadingFile',
      type: 'info',
      icon: 'assets/icons/toast/loading.svg',
    });
    return Promise.all(target.map((file) => this.fileToDataURL(file)));
  }

  async downloadFile(file: fileData) {
    this.isLoading = true;
    this.draw();

    let blob;

    if (file.file) {
      blob = file.file;
    }

    if (file.fileFirebaseUrl && !blob) {
      const filterPipe = new ImageFirebasePipe();
      const filePath = filterPipe.transform(file.fileFirebaseUrl, this.type);

      const res = await fetch(filePath);
      blob = await res.blob();
    }

    this.fileWriteService
      .writeFile({ blob, path: `${file.fileName}.${file.extension || 'txt'}` })
      .then()
      .finally(() => {
        this.isLoading = false;
        this.draw();
      });
  }

  cantUploadMoreFilesEvent() {
    this.cantUploadMoreFiles.emit();
  }

  public async uploadFiles2() {
    for (let i = 0; i < this.fileArray.length; i++) {
      if (
        this.fileArray[i].file &&
        !this.fileArray[i].fileFirebaseUrl &&
        this.fileArray[i].markForDelete == false
      ) {
        await this.uploadFile(this.fileArray[i], i);
        if (this.freeLimit?.consumed) {
          this.freeLimit.consumed += 1;
        }
        this.updateFreeLimit(this.fileArray[i].fileFirebaseUrl, i);
      }

      if (this.fileArray[i].markForDelete == true) {
        await this.deleteFile(this.fileArray[i]);
        this.updateFreeLimit(this.fileArray[i].fileFirebaseUrl, i, false);
      }
    }

    const response = this.fileArray
      .filter((fil) => fil.markForDelete == false)
      .map((file) => {
        return {
          extension: file.extension,
          fileName: file.fileName,
          fileFirebaseUrl: file.fileFirebaseUrl,
        };
      });

    return response;
  }

  async uploadFiles() {
    for (let i = 0; i < this.fileArray.length; i++) {
      if (
        this.fileArray[i].file &&
        !this.fileArray[i].fileFirebaseUrl &&
        this.fileArray[i].markForDelete == false
      ) {
        await this.uploadFile(this.fileArray[i], i);
        if (this.freeLimit?.consumed) {
          this.freeLimit.consumed += 1;
        }
        this.updateFreeLimit(this.fileArray[i].fileFirebaseUrl, i);
      }

      if (this.fileArray[i].markForDelete == true) {
        await this.deleteFile(this.fileArray[i]);
        this.updateFreeLimit(this.fileArray[i].fileFirebaseUrl, i, false);
      }
    }
    const out: fileData = JSON.parse(JSON.stringify(this.fileArray));

    this.onAllDataSaved.emit(
      this.fileArray
        .filter((fil) => fil.markForDelete == false)
        .map((file) => {
          return {
            extension: file.extension,
            fileName: file.fileName,
            fileFirebaseUrl: file.fileFirebaseUrl,
          };
        })
    );
  }

  async uploadFile(file: fileData, arrayIndex: number) {
    const fileUploadService = this.fileUploadService;
    const type = this.type;
    const fileArray = this.fileArray;
    const sub$ = this.sub$;
    return new Promise(function (resolve, reject) {
      sub$.add(
        fileUploadService
          .uploadFile(type as EFileType, file.fullFile as File)
          .subscribe(
            (res) => {
              fileArray[arrayIndex].fileFirebaseUrl = res;
              resolve(res);
            },
            (err) => {
              reject(err);
            }
          )
      );
    });
  }

  deleteFileInternal(file: fileData) {
    if (file.fileFirebaseUrl !== null) {
      this.fileArray[this.fileArray.indexOf(file)].markForDelete = true;
      // Update free limit consumed local property
      if (this.freeLimit?.consumed) {
        this.freeLimit.consumed -= 1;
      }
      this.numFilesAdded = this.fileArray.filter(
        (file) => !file.markForDelete
      ).length;
    } else {
      this.fileArray.splice(this.fileArray.indexOf(file), 1);
      this.numFilesAdded = this.fileArray.length;
    }

    this.changeFileArray.emit(this.fileArray);
    this.draw();
  }

  async deleteFile(file: fileData) {
    const sub$ = this.sub$;
    const type = this.type;
    const fileUploadService = this.fileUploadService;
    return new Promise(function (resolve, reject) {
      fileUploadService
        .deleteFirebaseStorageFile(type as EFileType, file.fileFirebaseUrl)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  onSuccess(positionImage: number, response: any) {
    this.selectedFile = [];
    this.filesReceived = response.map((elem) => elem.filename);
    this.emitChangesImages();
    this.draw();
  }

  onError(img: fileData) {
    this.emitChangesImages();
    this.draw();
  }

  emitChangesImages() {
    this.imagesChanged.emit(this.fileArray);
  }

  resolveFile(file: fileData) {
    try {
      if (['pdf'].includes(file.extension.toLowerCase())) {
        return 'assets/images/file_pdf.svg';
      }

      if (['ods', 'csv'].includes(file.extension.toLowerCase())) {
        return 'assets/images/file_csv.svg';
      }

      if (['xls', 'xlsx', 'xsl'].includes(file.extension.toLowerCase())) {
        return 'assets/images/file_xsl.svg';
      }

      if (['txt', 'odt', 'rtf'].includes(file.extension.toLowerCase())) {
        return 'assets/images/document.svg';
      }

      if (['docx'].includes(file.extension.toLowerCase())) {
        return 'assets/images/file_docx.svg';
      }

      if (['doc'].includes(file.extension.toLowerCase())) {
        return 'assets/images/file_doc.svg';
      }

      if (
        ['png', 'jpg', 'jpeg', 'svg', 'tif', 'tiff', 'bmp'].includes(
          file.extension.toLowerCase()
        )
      ) {
        if (file.file) {
          return file.file;
        }
        if (file.fileFirebaseUrl) {
          const filterPipe = new ImageFirebasePipe();
          const filePath = filterPipe.transform(
            file.fileFirebaseUrl,
            this.type
          );
          return filePath;
        }
        return 'assets/images/image.svg';
      }

      if (
        ['mp3', 'wav', 'aiff', 'ogg', 'wma', 'flac'].includes(
          file.extension.toLowerCase()
        )
      ) {
        return 'assets/images/audio.png';
      }

      if (
        ['mp4' || 'mov' || 'avi' || 'wmv'].includes(
          file.extension.toLowerCase()
        )
      ) {
        return 'assets/images/videoicon.png';
      }
    } catch (err) {}

    return 'assets/images/archive.svg';
  }

  isFile(items: string[]) {
    let item: string;

    if (Array.isArray(items)) {
      item = items[0];
    } else {
      item = items;
    }

    if (
      item.toLowerCase().includes('jpg') ||
      item.toLowerCase().includes('jpeg') ||
      item.toLowerCase().includes('png')
    ) {
      return false;
    }

    return true;
  }

  watchFreeLimit() {
    this.isLoading = true;
    this.sub$.add(
      this.freeLimitService
        .getLimit(FreeLimitType.AllowedTypes.File)
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          this.freeLimit = res;
          this.draw();
        })
    );
  }

  updateFreeLimit(fileId: string, positionImage: number, onInsert = true) {
    this.sub$.add(
      this.freeLimitService
        .updateLimit(FreeLimitType.AllowedTypes.File, {
          onInsert,
          file: {
            id: fileId,
            module: this.module,
          },
        })
        .subscribe((freeLimit) => {
          this.freeLimit = freeLimit;
          // if (onInsert) {
          //   this.onSuccess(positionImage, fileId);
          // }
          this.draw();
        })
    );
  }

  isImageInFreeLimit(file: fileData) {
    return this.freeLimit.files?.some((f) =>
      f.id.includes(file.fileFirebaseUrl)
    );
  }

  determineIfOpen(file: fileData) {
    if (this.isLoading) {
      return null;
    }

    if (this.isImageInFreeLimit(file)) {
      return Feature.SystemPermission.DefaultAllow;
    }

    return this.feature;
  }
}
