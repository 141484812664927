import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {
  downloadByUrl(url: string): Promise<void> {
    return Browser.open({ url, presentationStyle: 'popover' });
  }
}
