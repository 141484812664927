import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerInputEvent,
  MatDateRangeInput,
  MatDateRangePicker,
} from '@angular/material/datepicker';
import { fromEvent, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged, filter } from 'rxjs/operators';
import { RmSelectComponent } from '../../rm-select';

import { RmSelect } from '../../rm-select/rm-select.types';
import { RmFilterInputRangeOptions } from '../rm-filter.const';
import { RmFilterService } from '../rm-filter.service';
import { RmFilter } from '../rm-filter.types';
import { getDateStrResum } from '@web-frontend/shared/utils';
import { MatRadioChange } from '@angular/material/radio';
import { MatMenuTrigger } from '@angular/material/menu';
import { Feature } from '@tacliatech/types';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'roma-rm-filter-core',
  templateUrl: './rm-filter-core.component.html',
  styleUrls: ['./rm-filter-core.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmFilterCoreComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  @HostBinding('class.is-column')
  columnMode = false;

  @Input()
  source: RmFilter.Filter;

  @Input()
  @HostBinding('style.height')
  minHeight = 'auto';

  @Input()
  @HostBinding('style.width')
  minWidth = 'auto';

  @ViewChildren('input')
  viewInputChildren: QueryList<ElementRef<HTMLInputElement>>;

  @ViewChildren('hourInput')
  viewInputHourChildren: QueryList<ElementRef<HTMLInputElement>>;

  @ViewChildren('singlePicker')
  viewSinglePickerChildren: QueryList<MatDatepicker<unknown>>;

  @ViewChildren(RmSelectComponent)
  viewRmSelectChildren: QueryList<RmSelectComponent>;

  @ViewChildren(MatDateRangeInput)
  viewDateRangeInputChildren: QueryList<MatDateRangeInput<unknown>>;

  @ViewChild('matMenuForHour')
  matMenuForHour: MatMenuTrigger;

  @Output()
  changes = new EventEmitter<RmFilter.Changes>();

  inputRangeOptions = RmFilterInputRangeOptions;
  focusInputElValue!: string;

  feature = Feature;
  hoursTypes = [
    { title: 'general.equal', value: 'EQUAL' },
    { title: 'general.major2', value: 'MAJOR' },
    { title: 'general.minor2', value: 'MINOR' },
  ];

  private sub$ = new Subscription();

  constructor(
    private cdRef: ChangeDetectorRef,
    private rmFilterService: RmFilterService,
    private dateAdapter: DateAdapter<Date>,
    private i18n: TranslateService
  ) {}

  ngOnInit(): void {
    this.watchEvents();
    this.dateAdapter.setLocale(this.i18n.currentLang);
    this.dateAdapter.getFirstDayOfWeek = () => 1;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.watchInputSearch();
    }, 1000);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  private watchInputSearch() {
    let index = 0;

    const listenChanges = (input: HTMLInputElement, item: RmFilter.Input) => {
      this.sub$.add(
        fromEvent(input, 'input')
          .pipe(
            distinctUntilChanged(),
            debounce(() => timer(1000))
          )
          .subscribe((res) => {
            this.changeInputItem(res as any, item, 'INPUT_CHANGE');
          })
      );
    };

    for (const el of this.viewInputChildren) {
      const ATTRIBUTE_APPENDED = 'keyRequest';
      const attribute = el.nativeElement.attributes.getNamedItem(
        ATTRIBUTE_APPENDED
      );

      if (attribute?.value) {
        const item = this.source
          .get()
          .find((el) =>
            el.getKeyRequest().includes(attribute.value)
          ) as RmFilter.Input;

        listenChanges(el.nativeElement, item);
      }
      index++;
    }
  }

  private watchEvents() {
    this.sub$.add(
      this.rmFilterService.bus$
        .pipe(filter((res) => !!res))
        .subscribe((res) => {
          if (res.type === 'CLEAN_ALL') {
            this.clean();
          }
        })
    );
  }

  private clean() {
    try {
      for (const el of this.viewInputChildren) {
        el.nativeElement.value = '';
      }
    } catch (err) {}

    try {
      for (const el of this.viewRmSelectChildren) {
        el.clearInput('out');
      }
    } catch (err) {}

    try {
      for (const el of this.viewDateRangeInputChildren) {
        (el as any)._model.selection.end = '';
        (el as any)._model.selection.start = '';

        const fakeInitialValue = {
          value: null,
          targetElement: null,
          target: null,
        };

        el._startInput.dateChange.emit(fakeInitialValue);
        el._endInput.dateChange.emit(fakeInitialValue);
      }
    } catch (err) {}

    try {
      for (const el of this.viewSinglePickerChildren) {
        (el as any)._model.selection.end = '';
        (el.datepickerInput as MatDatepickerInput<unknown>).value = '';
      }
    } catch (err) {}

    try {
      for (const el of this.viewInputHourChildren) {
        el.nativeElement.value = '';
      }
    } catch (err) {}

    this.draw();
  }

  clickSelectItem(
    evt: MouseEvent,
    select: RmSelectComponent,
    item: RmFilter.Select
  ) {
    // close all rm-select opened,
    // except the current rm-select
    for (const el of this.viewRmSelectChildren) {
      if (el !== select) {
        el.clickPanel(evt, false);
      }
    }

    item.load();
    evt.stopPropagation();
  }

  changeSelectItems(items: RmSelect.Items, select: RmFilter.Select) {
    select.setValue(items);
    this.emitChanges();
  }

  changeInputItem(
    evt: KeyboardEvent,
    input: RmFilter.Input,
    change: 'CHANGE' | 'INPUT_CHANGE' = 'CHANGE'
  ): void {
    input.setValue((evt.target as HTMLTextAreaElement).value);
    this.emitChanges(change);
  }

  rangeDateChange(
    type: 'START' | 'END',
    evt: MatDatepickerInputEvent<Date>,
    item: RmFilter.RangeDate
  ): void {
    const update = item.getValue();
    const value = evt.value;

    if (type === 'START') {
      update[0] = value ? getDateStrResum(value).formattedDate : '';
    }
    if (type === 'END') {
      update[1] = value ? getDateStrResum(value).formattedDate : '';
    }
    if (type === 'START' && item.getValue()[1]) {
      update[1] = null;
    }

    item.setValue({
      from: update[0],
      to: update[1],
    });

    if (item.isComplete()) {
      this.emitChanges();
    }
  }

  cleanDateRangeDate(
    evt: MouseEvent,
    picker: MatDateRangePicker<unknown>,
    inputStart: HTMLInputElement,
    inputEnd: HTMLInputElement,
    item: RmFilter.RangeDate
  ): void {
    evt.stopPropagation();

    try {
      (picker as any)._model.selection.end = '';
      (picker as any)._model.selection.start = '';
    } catch {}

    inputStart.value = '';
    inputEnd.value = '';
    item.setValue({
      from: null,
      to: null,
    });
    this.emitChanges();
  }

  changeCheckbox(item: RmFilter.Checkbox) {
    item.setValue(!item.value());
    this.emitChanges();
  }

  changeInputRange(evt: KeyboardEvent, item: RmFilter.InputRange) {
    try {
      const value = (evt.target as HTMLTextAreaElement).value;
      const storage = item.value();

      storage.value = value ? +value : null;

      item.setValue(storage);

      this.emitChanges();
    } catch (err) {}
  }

  changeCheckboxInputRange(
    evt: MouseEvent,
    item: RmFilter.InputRange,
    order: '<' | '>' | '<=' | '=' | '>='
  ) {
    const storage = item.value();

    storage.order = storage.order !== order ? order : null;

    item.setValue(storage);

    this.emitChanges();

    evt.stopPropagation();
    evt.preventDefault();
  }

  changeDate(evt: MatDatepickerInputEvent<Date>, item: RmFilter.InputDate) {
    const value = evt.value;

    item.setValue(value);
    this.emitChanges();
  }

  changeRadioGroup(evt: MatRadioChange, item: RmFilter.Hour) {
    try {
      if (evt instanceof MatRadioChange) {
        const input = document.getElementById(evt.value) as HTMLInputElement;
        const value = input?.value ? +input?.value : null;
        const newValue: [string, number] = [evt.value, value];

        item.setValue(newValue);

        setTimeout(() => {
          input.focus();
        });

        this.setInputFocus(evt.value);
      }
    } catch (err) {
      console.log(err);
    }
  }

  changeInputHour(evt: InputEvent, item: RmFilter.Hour) {
    try {
      const targetValue = (evt.target as any).value;
      const value = targetValue === '' ? null : +(evt.target as any).value;
      const newValue: [string, number] = [item.getValue()[0][0], value];
      item.setValue(newValue);
    } catch (err) {}
  }

  applyItemHour(evt: MouseEvent): void {
    try {
      // evt.stopPropagation();
      // evt.stopImmediatePropagation();
      this.matMenuForHour.closeMenu();
      this.emitChanges();
    } catch (err) {}
  }

  cleanDate(
    evt: MouseEvent,
    picker: MatDateRangePicker<unknown>,
    input: HTMLInputElement,
    item: RmFilter.InputDate
  ): void {
    evt.stopPropagation();

    try {
      (picker as any)._model.selection = '';
    } catch {}

    input.value = '';
    item.setValue(null);

    this.emitChanges();
  }

  private setInputFocus(value: string) {
    this.focusInputElValue = value;
    this.draw();
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  private emitChanges(type: 'CHANGE' | 'INPUT_CHANGE' = 'CHANGE') {
    this.changes.next({
      type,
      queryParams: this.source.toQueryParams(),
    });
  }
}
