<div class="options no-padding">
  <div class="action-element">
    <div class="filter-wrapper bg-light-blue">
      <button
        class="btn btn-align"
        [ngClass]="{ active: viewPeriod === clockHourViewRef.Week }"
        (click)="changeClockHourView(clockHourViewRef.Week)"
      >
        <span class="align-text">{{ 'clockHour.weekly' | translate }}</span>
      </button>
      <button
        class="btn btn-align"
        [ngClass]="{ active: viewPeriod === clockHourViewRef.Month }"
        (click)="changeClockHourView(clockHourViewRef.Month)"
      >
        <span class="align-text">{{ 'clockHour.monthly' | translate }}</span>
      </button>
    </div>
  </div>
</div>
