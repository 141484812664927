import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MobileClockHour } from './mobile-clock-hour.types';
import { FeatureUser } from '@tacliatech/types';

@Component({
  selector: 'roma-mobile-clock-hour',
  templateUrl: './mobile-clock-hour.component.html',
  styleUrls: ['./mobile-clock-hour.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileClockHourComponent implements OnInit {
  @HostBinding('class.card__clock__hour')
  hostClass = true;

  @HostBinding('class.is-disabled')
  @Input()
  disabled = false;

  @Input()
  content: MobileClockHour.Content;

  @Input()
  actionOptions = MobileClockHour.defaultActionsOptions;

  @Output()
  actions = new EventEmitter<MobileClockHour.Action>();

  featureRefUser = FeatureUser;

  constructor() {}

  ngOnInit(): void {}

  @HostListener('click', ['$event'])
  click(evt: MouseEvent) {
    if (!this.disabled) {
      this.actions.emit({
        action: 'SIGN',
        date: this.content.date,
        id: this.content.id,
      });
    }
  }

  makeAction(action: 'SIGN' | 'DELETE') {
    this.actions.emit({ action, date: this.content.date, id: this.content.id });
  }
}
