<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="page-header row">
  <!-- Stripe Snackbar -->
  <roma-shared-stripe-snackbar></roma-shared-stripe-snackbar>

  <!-- Trial Disclaimer -->
  <roma-pro-disclaimer-banner
    *ngIf="isLowerThan1450px"
    (emitRefresh)="refresh()"
    [rootModule]="'service'"
  ></roma-pro-disclaimer-banner>

  <div class="col-8 col-md-5">
    <h1 class="page-title">
      {{ customer?.name ? customer?.name : ('deals.title' | translate) }}
      <div class="tooltip-container">
        <img
          class="help-icon"
          [ngStyle]="{ width: '32px' }"
          [src]="iconDialog"
          (click)="showDialog()"
        />
        <div
          *ngIf="showToolTip"
          class="title-tooltip-wrapper"
          (click)="toggleTooltip(false)"
        ></div>
        <div
          class="help-tooltip"
          *ngIf="showToolTip"
          romaRomaToolTip
          [showToolTip]="showToolTip"
          (clicked)="toggleTooltip($event)"
        >
          <div [innerHTML]="'deals.tooltip' | translate"></div>
          <a
            *ngIf="academyLink?.length"
            [href]="academyLink"
            (click)="sendEventAcademy()"
            target="_blank"
            >{{ 'deals.tooltipUrl' | translate }}</a
          >
        </div>
      </div>
    </h1>
  </div>
  <div class="col-4 col-md-7 video-section">
    <div
      *ngIf="getLang() === 'es'"
      class="tutorial"
      (click)="openVideo('deals')"
    >
      <strong>{{ 'tutorial.tutorial' | translate }}</strong>
      <img class="video" src="assets/icons/gl_play-tutorial.svg" />
    </div>
    <button
      class="roma-button add-button-3 mobile-hidden"
      style="display: inline-block"
      romaProFeature
      [featureUser]="featureRefUser.Deal.create"
      (clicked)="addClick()"
    >
      {{ 'general.addButton' | translate }}
      <img class="icon-option" src="assets/icons/gl_plus-btn.svg" />
    </button>
  </div>
</div>

<div *ngIf="openMobileViewType" class="mobile-viewtype-wrapper">
  <div class="viewtype-list">
    <h4>{{ 'general.view' | translate }}</h4>
    <div class="link-list">
      <div class="list-item" (click)="changeViewType('ROW')">
        <mat-icon class="prev-icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 8H2"
              stroke="#9DA9C9"
              stroke-width="2"
              stroke-linecap="square"
            />
            <path
              d="M14 12.5H2"
              stroke="#9DA9C9"
              stroke-width="2"
              stroke-linecap="square"
            />
            <path
              d="M14 3.5H2"
              stroke="#9DA9C9"
              stroke-width="2"
              stroke-linecap="square"
            />
          </svg>
        </mat-icon>
        <span class="icon-text">{{ 'dashboard.list' | translate }}</span>
        <p>{{ 'dashboard.list_text' | translate }}</p>
        <img
          *ngIf="viewTypeActive === 'ROW'"
          class="arrow"
          src="/assets/icons/ic_check.svg"
          alt="link"
        />
      </div>
      <div class="list-item" (click)="changeViewType('PIPELINE')">
        <mat-icon class="prev-icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 3.1001C2 2.27167 2.67157 1.6001 3.5 1.6001H12.5C13.3284 1.6001 14 2.27167 14 3.1001V12.9001C14 13.7285 13.3284 14.4001 12.5 14.4001H3.5C2.67157 14.4001 2 13.7285 2 12.9001V3.1001ZM4.56015 4H6.96015V11.2H4.56015V4ZM11.3601 4H8.96014V9.6H11.3601V4Z"
              fill="#9DA9C9"
            />
          </svg>
        </mat-icon>
        <span class="icon-text">{{ 'dashboard.board' | translate }}</span>
        <p>{{ 'dashboard.board_text' | translate }}</p>
        <img
          *ngIf="viewTypeActive === 'PIPELINE'"
          class="arrow"
          src="/assets/icons/ic_check.svg"
          alt="link"
        />
      </div>
      <div
        class="list-item"
        romaProFeature
        [featureUserHide]="featureRefUser.Calendar.show"
        (clicked)="goToCalendar()"
      >
        <mat-icon class="prev-icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.9602 0.800049V3.68005"
              stroke="#9DA9C9"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
            <path
              d="M5.20001 0.800049V3.68005"
              stroke="#9DA9C9"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.04001 2.23999H13.12C13.9153 2.23999 14.56 2.8847 14.56 3.67999L14.56 5.56006H1.60001V3.67999C1.60001 2.8847 2.24472 2.23999 3.04001 2.23999ZM1.60001 7.56006V12.96C1.60001 13.7553 2.24472 14.4 3.04001 14.4H13.12C13.9153 14.4 14.56 13.7553 14.56 12.96L14.56 7.56006H1.60001Z"
              fill="#9DA9C9"
            />
          </svg>
        </mat-icon>
        <span class="icon-text">{{ 'dashboard.calendar' | translate }}</span>
        <p>{{ 'dashboard.calendar_text' | translate }}</p>
        <img
          *ngIf="viewTypeActive === 'CALENDAR'"
          class="arrow"
          src="/assets/icons/ic_check.svg"
          alt="link"
        />
      </div>
      <div class="text-center close-btn-wrapper">
        <img
          (click)="toggleViewType(false)"
          class="close-btn"
          src="/assets/icons/btn_close.svg"
          alt="close"
        />
      </div>
    </div>
  </div>
</div>
<!-- desketop-page-options -->
<div class="page-options row">
  <div class="col-12 options" style="padding: 0px">
    <div class="action-element">
      <div
        class="filter-wrapper mobile-hidden"
        style="background-color: #f2f6fc"
        *ngIf="user?.mode === 'customer'"
      >
        <!-- Calendar button -->
        <button
          *ngIf="showCalendar"
          class="btn btn-align"
          [ngClass]="{ active: viewTypeActive === 'CALENDAR' }"
          romaProFeature
          [featureUserHide]="featureRefUser.Calendar.show"
          (clicked)="goToCalendar()"
        >
          <mat-icon class="align-icon" svgIcon="calendar_icon"></mat-icon>

          <span class="align-text">{{ 'dashboard.calendar' | translate }}</span>
        </button>
        <!-- Table button -->
        <button
          class="btn btn-align"
          [ngClass]="{ active: viewTypeActive === 'ROW' }"
          (click)="changeViewType('ROW')"
        >
          <mat-icon class="align-icon" svgIcon="table_icon"></mat-icon>
          <span class="align-text">{{ 'dashboard.list' | translate }}</span>
        </button>
        <!-- Table button -->

        <!-- Board button -->
        <button
          class="btn btn-align"
          [ngClass]="{ active: viewTypeActive === 'PIPELINE' }"
          (click)="changeViewType('PIPELINE')"
        >
          <mat-icon class="align-icon" svgIcon="pipeline_icon"></mat-icon>
          <span class="align-text">{{ 'dashboard.board' | translate }}</span>
        </button>
        <!-- Board button -->
        <!-- <button
          class="btn"
          [ngClass]="{ active: viewTypeActive === 'PIPELINE' }"
          (click)="changeViewType('PIPELINE')"
        >
          <mat-icon svgIcon="pipeline_icon"></mat-icon>
          <span class="icon-text">{{ 'dashboard.board' | translate }}</span>
        </button> -->
      </div>
    </div>

    <div class="filter-element">
      <div
        *ngIf="!(resize$ | async)?.payload?.mobile"
        class="container__filters"
      >
        <roma-rm-filter-core
          *ngIf="sourceSearch"
          [source]="sourceSearch"
          (changes)="changeFilterKeyword($event)"
        ></roma-rm-filter-core>
        <roma-rm-filter-button
          *ngIf="sourceOptions"
          [source]="sourceOptions"
          (changes)="changeFilters($event)"
        ></roma-rm-filter-button>

        <!--comment-->
        <roma-rm-filter-button
          *ngIf="quickOptions"
          class="filter-button"
          filterHeight="fit-content"
          filterWidth="fit-content"
          [source]="quickOptions"
          [title]="'filter.viewServices'"
          (changes)="changeQuickFilters($event)"
        ></roma-rm-filter-button>
        <!--comment-->

        <roma-rm-filter-clear
          *ngIf="sourceOptions"
          [sources]="[sourceSearch, sourceOptions, quickOptions]"
          (changes)="clearFilter($event)"
        ></roma-rm-filter-clear>
      </div>
      <div
        *ngIf="(resize$ | async)?.payload?.mobile"
        class="container__filters"
      >
        <roma-rm-filter-button
          *ngIf="sourceOptions"
          [source]="sourceOptions"
          (changes)="changeFilters($event)"
        ></roma-rm-filter-button>

        <roma-rm-filter-button
          *ngIf="quickOptions"
          class="filter-button"
          filterHeight="fit-content"
          filterWidth="fit-content"
          [source]="quickOptions"
          [title]="'filter.viewServices'"
          (changes)="changeQuickFilters($event)"
        ></roma-rm-filter-button>

        <button
          class="mobile-button-add"
          romaProFeature
          [featureUser]="featureRefUser.Deal.create"
          (clicked)="addClick()"
        >
          <img
            src="/assets/icons/gl_plus-1.svg"
            alt="{{ 'deals.createDeals' | translate }}"
          />
        </button>
      </div>
      <!-- mobile type view -->
      <button
        *ngIf="!isLoading"
        class="mat-raised-button mat-secondary list-select"
        (click)="toggleViewType(true)"
      >
        <ng-container *ngIf="viewTypeActive === 'ROW'">
          <mat-icon svgIcon="table_icon"></mat-icon>
          <span class="icon-text">{{ 'dashboard.list' | translate }}</span>
        </ng-container>
        <ng-container *ngIf="viewTypeActive === 'PIPELINE'">
          <mat-icon svgIcon="pipeline_icon"></mat-icon>
          <span class="icon-text">{{ 'dashboard.board' | translate }}</span>
        </ng-container>

        <!-- <ng-container *ngIf="viewTypeActive === 'CALENDAR'">
          <mat-icon svgIcon="calendar_icon"></mat-icon>
          <span class="icon-text">{{ 'dashboard.calendar' | translate }}</span>
        </ng-container> -->
        <img class="arrow" src="/assets/icons/gl_arrow_down.svg" alt="link" />
      </button>
      <!-- roma filter end's -->
    </div>
  </div>
  <div
    class="page-options-col"
    [ngClass]="
      viewTypeActive === 'TRACKING' ? 'col-12 col-lg-10' : 'col-12 col-lg-7'
    "
  >
    <roma-filter-tracking
      *ngIf="user && viewTypeActive === 'TRACKING'"
      (dateChanged)="setDateTracking($event)"
      (markersDealsChanged)="setMarkersDeals($event)"
      (markersUsersChanged)="setMarkersUsers($event)"
      (selectedUsers)="setUsersSelected($event)"
      (polyLinesChanged)="setPolyLines($event)"
    >
    </roma-filter-tracking>
  </div>

  <div class="col-12 justify-content-end page-options-col">
    <div class="page-options-right">
      <div
        *ngIf="viewTypeActive === 'ROW' && !(resize$ | async)?.payload?.mobile"
        class="table-total md"
        style="float: left"
      >
        {{ resultSearch?.total || 0 }} {{ 'general.results' | translate }}
      </div>
      <roma-shared-download-btn
        *ngIf="
          !(resize$ | async)?.payload?.mobile &&
          viewTypeActive !== 'TRACKING' &&
          deals?.data?.length
        "
        [featureExcel]="featureRef.Deal.DownloadExcel"
        [showDownloadExcel]="true"
        [showDownloadPdf]="false"
        (download)="download($event)"
      ></roma-shared-download-btn>
      <!-- <a
        *ngIf="
          !(resize$ | async)?.payload?.mobile && viewTypeActive !== 'TRACKING'
        "
        class="btn-download"
        romaProFeature
        [feature]="featureRef.Deal.DownloadExcel"
        [ngClass]="{ 'is-hidden': isLoading || !deals?.data?.length }"
        style="display: inline-block"
        (clicked)="downloadExcel()"
      >
        <img class="icon-star-pro-feature" src="assets/icons/star.svg" />
        {{ 'general.downloadExcel' | translate }}
        <img class="download-icon" src="assets/icons/gl_download.svg" />
      </a> -->
    </div>
  </div>
</div>

<!-- Table list -->
<div
  id="containerTable"
  class="containerTable"
  *ngIf="viewTypeActive === 'ROW'"
  [ngClass]="{
    'video-tutorial-box':
      !isLoading && !(searchParamsHasChange$ | async) && !deals?.data?.length
  }"
  [ngStyle]="{ 'margin-top': tableMarginTop }"
  (scroll)="checkScroll($event)"
>
  <ng-container>
    <div class="my-4">
      <div *ngIf="isLoading" class="col-12">
        <roma-loading></roma-loading>
      </div>

      <div
        *ngIf="
          !isLoading && (searchParamsHasChange$ | async) && !deals?.data?.length
        "
        class="col-12"
      >
        <roma-not-result-found
          (changes)="($event.type === 'CLEAR' ? null : null)"
        ></roma-not-result-found>
      </div>
      <div
        class="col-12"
        [ngClass]="{ 'is-hidden': isLoading || !deals?.data?.length }"
      >
        <roma-list-mobile-deals
          *ngIf="(resize$ | async)?.payload?.mobile"
          [data]="deals?.data"
          (actions)="deleteMany($event)"
          (onClickRow)="toDetail($event)"
          style="margin-top: 1rem"
        >
        </roma-list-mobile-deals>
        <roma-table
          *ngIf="!(resize$ | async)?.payload?.mobile"
          type="deal"
          class="table"
          idModule="DEAL_MODULE"
          [displayedColumns]="columns"
          [moreFields]="moreTableFields"
          [customFields]="customProperties"
          [data]="deals"
          [appendSettings]="'YES'"
          [appendInfo]="'YES'"
          [showActions]="true"
          [showOpenIcon]="true"
          [settings]="settings"
          (wantEdit)="toDetail($event)"
          (openNewTab)="toNewTab($event)"
          (wantDeleteDeal)="removeDeal($event)"
          (wantDeleteDealNoModal)="cancelDealNoModal($event)"
          (wantFinishDeal)="finishDeal($event)"
          (wantRestoreFinishDeal)="restoreFinishDeal($event)"
          (wantChangeStateDeal)="changeStateDeal($event)"
          (wantAproveDeal)="approveRejectDeal($event)"
          (wantRestoreCancelDeal)="restore($event)"
          (wantOpenModealDeleteDeal)="openDeleteManyDialog($event)"
          (checkRowChanges)="deleteMany($event)"
          (changeSort)="sortChange($event)"
          [statusData]="statusToDealPipeline"
        >
        </roma-table>
      </div>
      <div
        class="no-registers"
        *ngIf="
          customer?._id &&
          !isLoading &&
          !(searchParamsHasChange$ | async) &&
          !deals?.data?.length
        "
      >
        <img src="assets/icons/empty-list-icon.svg" />
        <div class="no-registers__title">{{ 'general.ohNo' | translate }}</div>
        <div class="no-registers__text">
          {{ 'deals.noFinalDeals' | translate }}
        </div>
        <button
          class="no-registers__button"
          romaProFeature
          [featureUser]="featureRefUser.Deal.create"
          (clicked)="addClick()"
        >
          {{ 'calendar.cellTooltip' | translate }}
          <img src="assets/icons/gl_plus-btn.svg" />
        </button>
      </div>
      <div
        *ngIf="
          !customer?._id &&
          !isLoading &&
          !(searchParamsHasChange$ | async) &&
          !deals?.data?.length
        "
      >
        <div
          style="text-align: center; align-self: center"
          *ngIf="getLang() === 'es'"
        >
          <div>
            <div class="align-self-center">
              <h2>{{ 'clearState.se1' | translate }}</h2>
              <p>{{ 'clearState.se2' | translate }}</p>
            </div>
            <div class="col-12" style="text-align: center; align-self: center">
              <button
                class="button"
                mat-button
                romaProFeature
                [featureUser]="featureRefUser.Deal.create"
                (clicked)="addClick()"
              >
                {{ 'deals.createDeals' | translate }}
              </button>
            </div>
            <div class="col p-2">
              <iframe
                *ngIf="showVideoIframe"
                width="640"
                height="360"
                [src]="videoIframeURL"
                frameborder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
              >
              </iframe>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!isLoading && isLoadingPaginate" class="col-12">
        <roma-loading></roma-loading>
      </div>
    </div>
  </ng-container>
</div>

<!-- table pagination -->
<div *ngIf="deals?.data?.length && viewTypeActive === 'ROW'" class="pagination">
  <button
    class="arrow prev"
    [attr.disabled]="resultSearch.page === 1 ? true : null"
    (click)="previousPage()"
  >
    <img src="assets/icons/gl_arrow_left.svg" />
  </button>
  <ng-container *ngFor="let page of totalPages">
    <div
      class="page-item"
      *ngIf="page.page < 6"
      [ngClass]="{ 'current-page': page.page == resultSearch.page }"
      (click)="goToPage(page.page)"
    >
      {{ page.page }}
    </div>
    <div
      class="page-item"
      *ngIf="
        resultSearch.countPages > 5 && page.page == resultSearch.countPages
      "
      [ngClass]="{ 'current-page': page.page == resultSearch.page }"
      (click)="goToPage(resultSearch.countPages)"
    >
      ...
      {{ resultSearch.countPages }}
    </div>
  </ng-container>
  <button
    class="arrow next"
    [attr.disabled]="!resultSearch.hasNextPage ? true : null"
    (click)="nextPage()"
  >
    <img src="assets/icons/gl_arrow_right-pagination.svg" />
  </button>
</div>

<roma-kanban
  *ngIf="viewTypeActive === 'PIPELINE' && kanbanCards.length > 0"
  [modalIn]="customer?._id ? true : false"
  [type]="typeOfStatus"
  [active]="viewTypeActive == 'PIPELINE'"
  [columns]="statusNames"
  [cards]="kanbanCards"
  [cardsName]="'kanban.cardsNameDeal'"
  [columnsName]="'kanban.columnsNameTask'"
  [showCardId]="false"
  [showFinishCard]="true"
  [showDeleteCard]="true"
  [showCancelCard]="true"
  [showRestoreCard]="true"
  [isLoading]="isLoading"
  [allowCreateColumn]="true"
  [allowDeleteColumn]="true"
  [cardDropPlaceHolderType]="'line'"
  [deleteColumnMessage]="'kanban.deleteDealMessage'"
  [placeholderAddCard]="'kanban.placeHolderAddCardDeal'"
  [cardCreateText]="'kanban.cardCreateTextDeal'"
  [withoutColumnText]="'kanban.withoutColumnTextTask'"
  [deleteCardMessage]="'kanban.deleteCardMessageDeal'"
  [restoreCardMessage]="'deals.restoreDeal'"
  [tooltipDeleteSystemColumn]="'kanban.tooltipSystemColumnDeal'"
  [unfinishToastParms]="unfinishToastParams"
  [finishToastParms]="finishToastParams"
  [deleteToastParms]="deleteToastParams"
  [restoreToastParms]="restoreToastParams"
  [statusData]="statusNames"
  [featureUserCreate]="featureRefUser.Deal.create"
  [featureUserUpdate]="featureRefUser.Deal.update"
  [featureUserDelete]="featureRefUser.Deal.delete"
  (onCreateFirstKanban)="eventCreateFirstKanban()"
  (onCardClick)="eventOnCardClick($event)"
  (onCardDblClick)="eventCardDblClick($event)"
  (onAddCard)="eventAddCard($event)"
  (onAddColumn)="eventAddColumn($event)"
  (onChangeColor)="eventChangeColor($event)"
  (onEditColumn)="eventEditColumn($event)"
  (onDeleteColumn)="eventDeleteColumn($event)"
  (onCardColumnChange)="eventCardColumnChange($event)"
  (onRestoreCard)="eventRestoreCard($event)"
  (onFinishCard)="finishDeal($event)"
  (onChangeStatus)="changeStateDeal($event)"
  (onUnfinishCard)="restoreFinishDeal($event)"
  (onEditCard)="eventEditCard($event)"
  (onDeleteCard)="eventDeleteCard($event)"
  (onCancelCard)="cancelDealNoModal($event)"
  (onMoveAllCards)="eventMoveAllCards($event)"
  (onFinishSelectedCards)="eventFinishSelectedCards($event)"
  (onUnfinishSelectedCards)="eventUnfinishSelectedCards($event)"
  (onMoveSelectedCards)="eventMoveSelectedCards($event)"
  (onDeleteSelectedCards)="eventDeleteSelectedCards($event)"
  (onRestoreSelectedCards)="evenRestoreSelectedCards($event)"
  (onUnfinishToastLinkClick)="eventUnfinishToastLinkClick($event)"
  (onRestoreToastLinkClick)="eventRestoreToastLinkClick($event)"
></roma-kanban>
<div
  class="no-registers"
  *ngIf="viewTypeActive === 'PIPELINE' && kanbanCards.length === 0"
>
  <img src="assets/icons/empty-list-icon.svg" />
  <div class="no-registers__title">{{ 'general.ohNo' | translate }}</div>
  <div class="no-registers__text">
    {{ 'deals.noFinalDeals' | translate }}
  </div>
  <button
    class="no-registers__button"
    romaProFeature
    [featureUser]="featureRefUser.Deal.create"
    (clicked)="addClick()"
  >
    {{ 'calendar.cellTooltip' | translate }}
    <img src="assets/icons/gl_plus-btn.svg" />
  </button>
</div>
<!--<roma-deal-pipeline
  *ngIf="viewTypeActive === 'PIPELINE'"
  [searchParams]="searchParams"
  [active]="viewTypeActive == 'PIPELINE'"
  [status]="statusToDealPipeline"
  [deals]="resultSearch?.docs"
  [isLoading]="isLoading"
>
</roma-deal-pipeline>-->

<roma-deal-calendar
  *ngIf="viewTypeActive === 'CALENDAR'"
  [searchParams]="searchParams"
>
</roma-deal-calendar>

<roma-tracking-object
  *ngIf="viewTypeActive === 'TRACKING'"
  [parameterized]="'true'"
  [markersOfUsers]="markersUsers"
  [markersOfDeals]="markersDeals"
  [usersSelected]="usersSelected"
  [dateStorage]="trackingDate"
  [linesMap]="linesMap"
>
</roma-tracking-object>

<div
  *ngIf="deleteMessage"
  class="row justify-content-center justify-content-lg-start"
>
  <div class="col-12 col-md-11" style="position: relative">
    <div class="delete-message" [ngClass]="{ modal__in: customer?._id }">
      <strong>
        ({{ entriesForDelete.length }})
        {{ 'kanban.selected' | translate }}
      </strong>

      <div class="container-wrapper-delete">
        <ng-container>
          <!-- restore option   -->
          <button
            class="btn-download btn-restore"
            (clicked)="restore()"
            *ngIf="searchParams?.takeCancelled"
            romaProFeature
            [featureUser]="featureRefUser.Deal.update"
          >
            <img src="assets/icons/gl_trash_restore.svg" />
            {{ 'general.restoreButton' | translate }}
          </button>

          <ng-container *ngIf="!searchParams?.takeCancelled">
            <!-- Mark multiple as finished -->
            <button
              *ngIf="searchParams?.takeFinished === 'false'"
              class="btn-download border-7px-rounded"
              [disabled]="isFinishedMultiple"
              [ngClass]="{ 'opacity-50': isFinishedMultiple }"
              (clicked)="markMultipleAsFinished()"
              romaProFeature
              [featureUser]="featureRefUser.Deal.update"
            >
              <mat-progress-spinner
                *ngIf="isFinishedMultiple"
                style="margin-right: 5px"
                class="loading-spinner"
                mode="indeterminate"
                [diameter]="20"
              ></mat-progress-spinner>
              <img
                *ngIf="!isFinishedMultiple"
                src="assets/icons/kanban/check.svg"
                class="check-icon"
              />
              {{
                (!this.searchParams?.takeNotified
                  ? 'deals.markAsFinished'
                  : 'kanban.buttonMarkAsUnFinished'
                ) | translate
              }}
            </button>

            <!-- Mark multiple as unfinished -->
            <button
              *ngIf="searchParams?.takeFinished !== 'false'"
              class="btn-download unfinished"
              [disabled]="isUnFinishedMultiple"
              [ngClass]="{ 'opacity-50': false }"
              (clicked)="markMultipleAsUnfinished()"
              romaProFeature
              [featureUser]="featureRefUser.Deal.update"
            >
              <mat-progress-spinner
                *ngIf="isUnFinishedMultiple"
                style="margin-right: 5px"
                class="loading-spinner"
                mode="indeterminate"
                [diameter]="20"
              ></mat-progress-spinner>
              <img
                *ngIf="!isUnFinishedMultiple"
                src="assets/icons/gl_check.svg"
                class="check-icon"
              />
              {{ 'deals.markAsUnfinished' | translate }}
            </button>

            <!-- Move multiple to another status -->
            <button
              *ngIf="!(resize$ | async)?.payload?.mobile"
              class="btn-download mobile-hidden"
              [disabled]="isUpdatingMultiple || isMovingMultiple"
              [ngClass]="{
                'opacity-50': isMovingMultiple
              }"
              [matMenuTriggerFor]="listOfStatuses"
            >
              <img
                [src]="
                  listOfStatuses.menuOpen
                    ? 'assets/icons/kanban/gl_move-blue.svg'
                    : 'assets/icons/kanban/gl_move-black.svg'
                "
              />
              {{ 'kanban.move' | translate }}

              <mat-progress-spinner
                style="margin-left: 7px"
                *ngIf="isMovingMultiple"
                class="loading-spinner"
                mode="indeterminate"
                [diameter]="20"
              ></mat-progress-spinner>
            </button>

            <!-- Mark multiple as Cancel -->
            <button
              class="btn-download border-7px-rounded"
              [disabled]="isCancelMultiple"
              [ngClass]="{ 'opacity-50': isCancelMultiple }"
              (clicked)="markMultipleAsCancel()"
              romaProFeature
              [featureUser]="featureRefUser.Deal.update"
            >
              <mat-progress-spinner
                *ngIf="isCancelMultiple"
                style="margin-right: 5px"
                class="loading-spinner"
                mode="indeterminate"
                [diameter]="20"
              ></mat-progress-spinner>
              <img
                *ngIf="!isCancelMultiple"
                src="assets/icons/gl_dialog_close.svg"
                class="check-icon"
              />
              {{ 'deals.cancelTitle' | translate }}
            </button>

            <!-- Download multiple
            <button
              class="btn-download mb-2"
              romaProFeature
              [feature]="featureRef.Deal.DownloadExcel"
              (clicked)="downloadExcelSelection()"
            >
              <img
                class="check-icon icon-star-pro-feature"
                src="assets/icons/star.svg"
              />
              <img class="download-icon" src="assets/icons/gl_download.svg" />
              {{ 'general.downloadExcel' | translate }}
            </button>
            -->

            <!-- Delete multiple -->
            <button
              class="btn"
              (clicked)="openDeleteManyDialog()"
              romaProFeature
              [featureUser]="featureRefUser.Deal.delete"
            >
              <img src="assets/icons/gl_trash_red.svg" />
              {{ 'general.deleteAlt' | translate }}
            </button>

            <!-- List of statuses -->
            <mat-menu
              class="panel-choose panel-move"
              #listOfStatuses="matMenu"
              xPosition="before"
            >
              <ng-container *ngFor="let stat of this.status">
                <button
                  *ngIf="
                    this.entriesForDelete?.length === 1 &&
                    stat.key !== this.entriesForDelete[0]?.status?.name
                  "
                  mat-menu-item
                  style="text-align: left"
                  romaProFeature
                  [featureUser]="featureRefUser.Deal.update"
                  (clicked)="moveMultipleToAnotherStatus_TABLE(stat)"
                >
                  {{ stat.key }}
                </button>

                <button
                  *ngIf="this.entriesForDelete?.length > 1"
                  mat-menu-item
                  style="text-align: left"
                  romaProFeature
                  [featureUser]="featureRefUser.Deal.update"
                  (clicked)="moveMultipleToAnotherStatus_TABLE(stat)"
                >
                  {{ stat.key }}
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>
        </ng-container>
      </div>

      <div class="btn-close" (click)="unselectItems()"></div>
    </div>
  </div>
</div>
