<div (focusout)="focusOut('users')">
  <div
    class="fake-select-overlay"
    *ngIf="(!showPanelElement && !disabled) || isLoading"
    (click)="focusOutA = false; clickPanel($event, null)"
  ></div>
  <div
    class="fake-clean-overlay"
    *ngIf="
      (!isLoading && activeItems?.length && mode === 'MULTIPLE') ||
      (!isLoading && activeItems?.length && isClearable)
    "
    (click)="focusOutA = false; clearInput('in')"
  ></div>
  <div
    class="fake-select"
    *ngIf="!showPanelElement || isLoading"
    [ngClass]="{ active: showPanelElement, invalid: hasError }"
  >
    <div class="fake-select-content">
      <span *ngIf="!activeItems?.length">
        {{ placeholderText | translate }}
      </span>
      <ng-container *ngIf="activeItems?.length">
        <ng-container *ngIf="mode === 'SINGLE'">
          <div class="deal__info__card" *ngFor="let item of activeItems">
            <div class="deal__info__card__name">
              {{ item.name | translate }}
            </div>
            <div class="deal__info__card__final__name">
              {{ item.final?.name }}
            </div>
            <div class="deal__info__card__date">
              <ng-container *ngIf="item?.initDate">
                <img src="/assets/icons/gl_calendar-input.svg" />
                {{
                  item?.initDate
                    ? (item?.initDate | date: 'd MMM':'':locale)
                    : '-'
                }}
                <ng-container *ngIf="!isEmptyHour(item.initDate)">
                  <img
                    src="/assets/icons/gl_clock-outline-gray.svg"
                    style="margin-left: 8px"
                  />
                  {{ item?.initDate ? (item?.initDate | date: 'HH:mm') : '-' }}
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="mode === 'MULTIPLE'">
          {{ placeholderText }}
          <span class="fake-item">({{ activeItems?.length }})</span>
        </ng-container>
      </ng-container>
    </div>
    <div class="fake-select-options">
      <img
        *ngIf="!isLoading && !disabled && showPanelElement"
        [src]="'assets/icons/active-up-arrow.svg'"
        width="20px"
      />
      <img
        *ngIf="!isLoading && !disabled && !showPanelElement"
        [src]="'assets/icons/gl_arrow_down.svg'"
        width="20px"
      />
      <img
        *ngIf="disabled"
        width="20px"
        [src]="'assets/icons/disable-down-arrow.svg'"
      />
      <img
        class="clean-input"
        *ngIf="
          (!isLoading && activeItems?.length && mode === 'MULTIPLE') ||
          (!isLoading && activeItems?.length && isClearable)
        "
        src="assets/icons/gl_close-input.svg"
      />
      <mat-progress-spinner
        *ngIf="isLoading && !disabled"
        mode="indeterminate"
        [diameter]="20"
      ></mat-progress-spinner>
    </div>
  </div>
</div>
<div>
  <!--(focusout)="focusOut('status')"-->
  <div
    *ngIf="!isLoading && showPanelElement && mode === 'SINGLE'"
    romaRomaToolTip
    [showToolTip]="showPanelElement"
    (clicked)="showPanelElement = $event"
    class="users-list"
    [ngStyle]="
      !isAbsolute
        ? listWidth !== '' && { width: listWidth }
        : panelWidth !== '' && { width: panelWidth }
    "
    [ngClass]="{ 'is-absolute': isAbsolute }"
    (focusin)="focusInternal = true"
  >
    <div class="search-input">
      <input
        #inputFilterSearch
        class="filter-input"
        [ngStyle]="{ height: inputHeight }"
        type="text"
        [placeholder]="'general.search' | translate"
      />
      <img
        [src]="'assets/icons/gl_arrow_down.svg'"
        (click)="focusOutA = false; clickPanel($event, null)"
      />
    </div>
    <div class="list-wrapper">
      <div
        *ngFor="let item of items | searchFilter: filterArgs; let i = index"
        class="user-item"
        [ngClass]="{ active: activeItems[0]?._id === item._id }"
        (click)="changeSelect(item)"
      >
        <div class="user-item__name">{{ item.name | translate }}</div>
        <div class="user-item__final__name">{{ item.final?.name }}</div>
        <div class="user-item__date">
          <ng-container *ngIf="item?.initDate">
            <img src="/assets/icons/gl_calendar-input.svg" />
            {{
              item?.initDate ? (item?.initDate | date: 'd MMM':'':locale) : '-'
            }}
            <ng-container *ngIf="!isEmptyHour(item.initDate)">
              <img
                src="/assets/icons/gl_clock-outline-gray.svg"
                style="margin-left: 8px"
              />
              {{ item?.initDate ? (item?.initDate | date: 'HH:mm') : '-' }}
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div
      *ngIf="showAddButton"
      class="add-item"
      (click)="addElementHandle($event)"
    >
      <img src="/assets/icons/gl_plus.svg" />
      {{ addButtonText | translate }}
    </div>
  </div>
</div>
