import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@web-frontend/environments';

@Pipe({
  name: 'image',
})
export class ImagePipe implements PipeTransform {
  transform(img: string, type: string): any {
    let url = `${environment.api.url}/file-upload`;

    if (!img || !type) {
      return (url += `/user/xxx`);
    }

    switch (type) {
      case 'customer':
        url += `/customer/${img}`;
        break;
      case 'deal':
        url += `/deal/${img}`;
        break;
      case 'evidence':
        url += `/evidence/${img}`;
        break;
      case 'expense':
        url += `/expense/${img}`;
        break;
      case 'user':
        url += `/user/${img}`;
        break;
      case 'own-team':
        url += `/own-team/${img}`;
        break;
      case 'notes':
        url += `/notes/${img}`;
        break;
      case 'checklist':
        url += `/checklist/${img}`;
        break;
      case 'equipment':
        url += `/equipment/${img}`;
        break;
      case 'final':
        url += `/final/${img}`;
        break;
      case 'address':
        url += `/address/${img}`;
        break;

      case 'vendor':
        url += `/vendor/${img}`;
        break;
      case 'project':
        url += `/project/${img}`;
        break;

      case 'budget-logo':
        url += `/budget-logo/${img}`;
        break;

      case 'internal-vendor':
        url += `/internal-vendor/${img}`;
        break;
      case 'part-of-service':
        url += `/part-of-service/${img}`;
        break;
      default:
        url + '/user/xxx';
        break;
    }

    return url;
  }
}
