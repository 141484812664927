import { TranslateService } from '@ngx-translate/core';
import { ICustomProperty } from '@tacliatech/types';
import { RmFilter } from '@web-frontend/shared/components/globals/rm-filter';
import { AssetService } from '@web-frontend/shared/services';
import { ProductService } from '@web-frontend/shared/services/products';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

const requestAuthors$ = (assetService: AssetService) =>
  assetService.findUsersByAssetsShared().pipe(
    map((res) =>
      res.map((el) => {
        return {
          title: el.name.toString().toLocaleLowerCase(),
          value: el._id,
          id: el._id,
          img: el.img,
        };
      })
    )
  );

const requestCategories$ = (productService: ProductService) =>
  productService.findAll().pipe(
    map((res) =>
      res.map((el) => {
        return {
          title: el.name.toString().toLocaleLowerCase(),
          value: el._id,
          id: el._id,
        };
      })
    )
  );

export const SOURCE_WEB = new RmFilter.Filter([
  new RmFilter.Input({
    type: 'SEARCH',
    keyRequest: 'keyword',
    content: {
      label: '',
      placeholder: 'rates.searchPlaceholder',
    },
  }),
]);

export const SOURCE_WEB_OPTIONS = (data: {
  assetService: AssetService;
  productService: ProductService;
  customProperties: ICustomProperty[];
  i18n: TranslateService;
}) => {
  const controls: RmFilter.Control[] = [
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'authors[]',
      source$: requestAuthors$(data.assetService),
      content: {
        label: 'task.owner',
        placeholder: 'task.owner',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'categories',
      source$: requestCategories$(data.productService),
      content: {
        label: 'filter.categories-budget',
        placeholder: 'filter.categories-budget',
      },
    }),
  ];

  for (const property of data.customProperties) {
    if (['SELECT'].includes(property.type)) {
      const options = property.options.map((el) => {
        return {
          id: el,
          title: el,
          value: el,
        };
      });
      controls.push(
        new RmFilter.Select({
          type: 'MULTIPLE',
          keyRequest: property.name,
          keyRequestParent: 'customProperties',
          source$: from([options]),
          content: {
            label: `${data.i18n.instant('filter.custom')} ${property.name}`,
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }

    if (['BOOLEAN'].includes(property.type)) {
      const options = [
        {
          id: 'true',
          title: 'general.yes',
          value: 'true',
        },
        {
          id: 'false',
          title: 'general.no',
          value: 'false',
        },
      ];
      controls.push(
        new RmFilter.Select({
          type: 'SINGLE',
          keyRequest: property.name,
          keyRequestParent: 'customProperties',
          source$: from([options]),
          content: {
            label: `${data.i18n.instant('filter.custom')} ${property.name}`,
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  for (const property of data.customProperties) {
    if (['DATE'].includes(property.type)) {
      controls.push(
        new RmFilter.InputDate({
          keyRequest: property.name,
          keyRequestParent: 'customProperties',
          content: {
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  for (const property of data.customProperties) {
    if (property.type === 'STRING') {
      controls.push(
        new RmFilter.Input({
          type: 'STRING',
          keyRequest: property.name,
          keyRequestParent: 'customProperties',
          content: {
            label: '',
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  for (const property of data.customProperties) {
    if (property.type === 'NUMBER') {
      controls.push(
        new RmFilter.InputRange({
          keyRequest: property.name,
          keyRequestParent: 'customProperties',
          content: {
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  return new RmFilter.Filter([
    ...controls,
    new RmFilter.Checkbox({
      keyRequest: 'takeDeleted',
      content: {
        label: 'finals.viewDelete',
      },
    }),
  ]);
};
