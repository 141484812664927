import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RmFilterModalDashboardDetail } from './rm-filter-modal-dashboard.types';

import { RmFilterModalDhbdDetailComponent } from './rm-filter-modal-dhbd-detail.component';

@Injectable({
  providedIn: 'root',
})
export class RmFilterModalDashboardDetailService {
  private refreshListSource = new Subject<RmFilterModalDashboardDetail.Result>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(
    config: MatDialogConfig<RmFilterModalDashboardDetail.Params> = new MatDialogConfig()
  ) {
    config.height = '100vh';
    config.width = '100vw';
    config.maxWidth = '100vw';
    config.hasBackdrop = false;

    const dialogRef = this.dialog.open<
      RmFilterModalDhbdDetailComponent,
      RmFilterModalDashboardDetail.Params,
      RmFilterModalDashboardDetail.Result
    >(RmFilterModalDhbdDetailComponent, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }
}
