<div class="password-input">
    <input
    [disabled]="disable"
    [type]="showPassword ? 'text' : 'password'"
    maxlength="24"
    class="form-control general-form-control md"
    aria-label="Contraseña"
    placeholder="{{ 'input-password.passwordPlaceHolder' | translate }}"
    autocomplete="new-password"
    [ngClass]="{ nginvalid: errorLength || errorObligatory }"
    [(ngModel)]="password"
    (change)="onChangeEvent($event)"
    (keydown)="onKeyDown($event)"
    />
    <img *ngIf="isPasswordOk" class="checkImage" src="/assets/icons/gl_check-circle.svg"/>
    <img
    class="showIcon"
    (click)="showPassword = true"
    src="/assets/img/icons/gl_eye-off-new.svg"
    alt="Mostrar Contraseña"
    *ngIf="!showPassword"
    style="margin-top: 2px"
    />
    <img
    class="showIcon"
    (click)="showPassword = false"
    *ngIf="showPassword"
    src="/assets/img/icons/gl_eye-on.svg"
    alt="Mostrar Contraseña"
    style="margin-top: 2px"
    />
    <div
    class="general-invalid-feedback"
    *ngIf="errorLength"
    >
    *{{ 'input-password.errorLength' | translate }}
    </div>
    <div
    class="general-invalid-feedback"
    *ngIf="errorObligatory"
    >
    *{{ 'input-password.errorObligatory' | translate }}
    </div>
</div>
