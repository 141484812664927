<div class="">
  <!-- MIAN ROW WITH TABLE -->
  <div class="row">
    <div *ngIf="isLoading" class="col-12">
      <roma-loading></roma-loading>
    </div>

    <!-- EMPTY MESSAGE -->
    <div
      *ngIf="!isLoading && hasQuerySearch() && !source?.length"
      class="col-12"
    >
      <roma-basic-message
        [message]="'activity.empty' | translate"
      ></roma-basic-message>
    </div>

    <!-- NO RESULT MESSAGE -->
    <div
      *ngIf="!isLoading && !hasQuerySearch() && !source?.length"
      class="col-12"
    >
      <roma-basic-message
        [message]="'activity.notResult' | translate"
      ></roma-basic-message>
    </div>

    <!-- BEGIN OF IS LOADING -->
    <div>
      <div
        class="col-12"
        [ngClass]="{ 'is-hidden': isLoading || !data?.data?.length }"
      >
        <!-- BUDGET TABLE BEGIN -->

        <roma-budget-table
          class="table"
          [displayedColumns]="columns"
          [_datasource]="data"
          [data]="data"
          [type]="type"
          [selectedItem]="selectedItem"
          [settings]="settings"
          [showOpenIcon]="showOpenIcon"
          [amplitudePrefix]="amplitudePrefix"
          (openNewTab)="toNewTab($event)"
          (wantEdit)="edit($event)"
          (wantDelete)="delete($event)"
          (wantWatch)="preview($event)"
          (wantConvertProform)="toProform($event)"
          (wantConvertBill)="toBill($event)"
          (checkRowChanges)="eventCheckRowChanges($event)"
          (columnsChange)="columnsChange($event)"
          (totalSelected)="totalSelectedChanges($event)"
          (sortChange)="sortChange($event)"
          [totalPages]="totalPages"
        >
        </roma-budget-table>
        <!-- BUDGET TABLE END -->
      </div>
    </div>

    <!-- END OF IS LOADING -->
  </div>
  <!-- MIAN ROW WITH TABLE END -->
</div>
