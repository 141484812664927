export enum GlobalStatus {
  All = 0,
  RequestedBudget = 1,
  Budgeted = 2,
  InProcess = 3,
  Finalized = 4,
  PendingApproval = 5,
  DealCanceled = 6,
  New = 7,
  Pending = 8,
}
