import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ClockHourView } from '../../../../core/admin/clock-hour/clock-hour.type';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { getDateStrWithoutUTC } from '../../../utils';

@Component({
  selector: 'roma-date-paginator',
  templateUrl: './date-paginator.component.html',
  styleUrls: ['./date-paginator.component.scss'],
})
export class DatePaginatorComponent implements OnInit, OnChanges {
  @Input() viewPeriod: ClockHourView;
  @Output() changeDateRange = new EventEmitter<{
    startDate: string;
    endDate: string;
  }>();
  @Input() defaultDate?: { startDate: string; endDate: string };

  clockHourViewRef = ClockHourView;

  currentMonth: number;
  currentDay: number;
  currentYear: number;
  selectedMonth: number;
  remainingMonth: number;

  firstWeekDay: number;
  lastWeekDay: number;
  firstMonthDay: number;
  lastMonthDay: number;
  remainingWeekLength: number;

  stringMonth: string;
  stringRemainingMonth: string;

  weekLimit: number;
  weekRemaining: number;
  private isMultiMonthRequest = false;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly i18n: TranslateService
  ) {}

  ngOnInit() {
    this.resolveDateCurrent();
    if (!this.defaultDate) {
      this.resolveLoadData();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.viewPeriod) {
      this.viewPeriod = changes.viewPeriod.currentValue;
      this.resolveLoadData();
    }
  }

  private initializeWithDefaultDate(dateRange: {
    startDate: string;
    endDate: string;
  }) {
    const startDate = new Date(dateRange.startDate);
    const endDate = new Date(dateRange.endDate);

    this.currentYear = startDate.getUTCFullYear();
    this.selectedMonth = startDate.getUTCMonth();
    this.currentDay = startDate.getUTCDate();

    this.firstMonthDay = startDate.getUTCDate();
    this.lastMonthDay = endDate.getUTCDate();

    if (startDate.getUTCMonth() !== endDate.getUTCMonth()) {
      this.isMultiMonthRequest = true;
      this.remainingMonth = endDate.getUTCMonth();
      this.remainingWeekLength = this.lastMonthDay - this.firstMonthDay;
    } else {
      this.isMultiMonthRequest = false;
    }

    this.resolveLoadData();
  }

  private resolveWeekFilter(currentDay: number) {
    if (currentDay == -6) {
      currentDay = this.adjustDayForNegativeSix(currentDay);
    }

    const weekDate = new Date(this.currentYear, this.selectedMonth, currentDay);
    const weekDay = weekDate.toLocaleDateString('en-US', { weekday: 'long' });

    this.setWeekLimits(weekDay);

    if (currentDay - this.weekRemaining < 1) {
      this.handleWeekSpanningMonths(currentDay);
    } else {
      this.handleWeekWithinMonth(currentDay);
    }

    this.getMonthString();
  }

  private adjustDayForNegativeSix(currentDay: number): number {
    currentDay = new Date(
      this.currentYear,
      this.selectedMonth,
      currentDay
    ).getDate();
    if (this.selectedMonth == 0) {
      this.selectedMonth = 11;
      this.currentYear -= 1;
    } else {
      this.selectedMonth -= 1;
      this.getMonthString();
    }
    return currentDay;
  }

  private setWeekLimits(weekDay: string) {
    const weekDayMapping = {
      Monday: { weekLimit: 6, weekRemaining: 0 },
      Tuesday: { weekLimit: 5, weekRemaining: 1 },
      Wednesday: { weekLimit: 4, weekRemaining: 2 },
      Thursday: { weekLimit: 3, weekRemaining: 3 },
      Friday: { weekLimit: 2, weekRemaining: 4 },
      Saturday: { weekLimit: 1, weekRemaining: 5 },
      Sunday: { weekLimit: 0, weekRemaining: 6 },
    };
    const { weekLimit = 0, weekRemaining = 0 } = weekDayMapping[weekDay] || {};
    this.weekLimit = weekLimit;
    this.weekRemaining = weekRemaining;
  }

  private calculateFirstWeekDay(currentDay: number): number {
    return new Date(
      this.currentYear,
      this.selectedMonth,
      currentDay - this.weekRemaining
    ).getDate();
  }

  private calculateRemainingLastDay(monthOffset: number): number {
    return new Date(
      this.currentYear,
      this.selectedMonth + monthOffset,
      0
    ).getDate();
  }

  private handleWeekSpanningMonths(currentDay: number) {
    this.firstWeekDay = this.calculateFirstWeekDay(currentDay);
    this.lastWeekDay = this.weekLimit + currentDay;

    const remainingLastDay = this.calculateRemainingLastDay(0);
    this.remainingMonth = new Date(
      this.currentYear,
      this.selectedMonth,
      currentDay - this.weekRemaining
    ).getMonth();
    this.remainingWeekLength = remainingLastDay - this.firstWeekDay;
    this.isMultiMonthRequest = true;

    this.changeDate(
      new Date(
        this.selectedMonth == 0 ? this.currentYear - 1 : this.currentYear,
        this.remainingMonth,
        this.firstWeekDay
      ),
      new Date(this.currentYear, this.selectedMonth, this.lastWeekDay)
    );
  }

  private handleWeekWithinMonth(currentDay: number) {
    this.firstWeekDay = currentDay - this.weekRemaining;
    this.lastWeekDay = currentDay + this.weekLimit;
    const remainingLastDay = this.calculateRemainingLastDay(1);

    if (this.lastWeekDay > remainingLastDay) {
      this.isMultiMonthRequest = true;
      this.remainingMonth = this.selectedMonth;
      this.remainingWeekLength = remainingLastDay - this.firstWeekDay;
      this.lastWeekDay -= remainingLastDay;

      this.changeDate(
        new Date(
          this.currentYear,
          this.selectedMonth,
          currentDay - this.weekRemaining
        ),
        new Date(this.currentYear, this.selectedMonth + 1, this.lastWeekDay)
      );
      this.selectedMonth += 1;
      this.lastMonthDay = this.calculateRemainingLastDay(1);
    } else {
      this.remainingWeekLength = 0;
      this.isMultiMonthRequest = false;
      this.changeDate(
        new Date(
          this.currentYear,
          this.selectedMonth,
          currentDay - this.weekRemaining
        ),
        new Date(this.currentYear, this.selectedMonth, this.lastWeekDay)
      );
    }
  }

  private resolveMonthFilter() {
    this.changeDate(
      new Date(this.currentYear, this.selectedMonth, this.firstMonthDay),
      new Date(this.currentYear, this.selectedMonth, this.lastMonthDay)
    );
  }

  private getMonthString() {
    moment.locale(this.i18n.currentLang);
    this.stringMonth = moment(
      new Date(this.currentYear, this.selectedMonth + 1, 0)
    )
      .format('MMM')
      .replace('.', '');
    this.stringRemainingMonth = moment(
      new Date(this.currentYear, this.remainingMonth + 1, 0)
    )
      .format('MMM')
      .replace('.', '');
  }

  private updateMonthAndYear(month: number, year: number) {
    this.selectedMonth = month;
    this.currentYear = year;
    this.getMonthString();
  }

  private updateMonthAndYearForNext(day: number) {
    if (day > this.lastMonthDay) {
      this.updateMonthAndYear(
        this.selectedMonth == 11 ? 0 : this.selectedMonth + 1,
        this.selectedMonth == 11 ? this.currentYear + 1 : this.currentYear
      );
      this.resolveWeekFilter(1);
    } else {
      this.resolveWeekFilter(day);
    }
  }

  private handleMultiMonthRequest(day: number) {
    this.updateMonthAndYear(
      this.selectedMonth == 0 ? 11 : this.selectedMonth - 1,
      this.selectedMonth == 0 ? this.currentYear - 1 : this.currentYear
    );
    this.resolveWeekFilter(Math.abs(day - (this.weekLimit + 1)));
  }

  private handleSingleMonthRequest(day: number) {
    if (day <= this.weekLimit + 1) {
      this.resolveWeekFilter(day == 1 ? -6 : this.firstMonthDay);
    } else {
      this.resolveWeekFilter(Math.abs(day - (this.weekLimit + 1)));
    }
  }

  private updateMonthAndYearForPrev(day: number) {
    if (this.isMultiMonthRequest) {
      this.handleMultiMonthRequest(day);
    } else {
      this.handleSingleMonthRequest(day);
    }
  }

  changeWeek(day: number, action: 'next' | 'prev') {
    if (action === 'next') {
      this.updateMonthAndYearForNext(day);
    } else {
      this.updateMonthAndYearForPrev(day);
    }
  }

  private changeToNextMonth(month: number) {
    this.selectedMonth = this.selectedMonth == 11 ? 0 : month;
    this.currentYear =
      this.selectedMonth == 0 ? this.currentYear + 1 : this.currentYear;
  }

  private changeToPrevMonth(month: number) {
    this.selectedMonth = this.selectedMonth == 0 ? 11 : month;
    this.currentYear =
      this.selectedMonth == 11 ? this.currentYear - 1 : this.currentYear;
  }

  changeMonth(month: number, action: 'next' | 'prev') {
    if (action === 'next') {
      this.changeToNextMonth(month);
    } else {
      this.changeToPrevMonth(month);
    }
    this.getMonthString();
    this.resolveLoadData();
  }

  private resolveDateCurrent() {
    if (this.defaultDate) {
      this.initializeWithDefaultDate(this.defaultDate);
    } else {
      const currentDate = new Date();
      this.currentMonth = currentDate.getMonth();
      this.currentDay = currentDate.getDate();
      this.currentYear = currentDate.getFullYear();
      this.selectedMonth = this.currentMonth;
    }
    this.cd.detectChanges();
  }

  private resolveLoadData() {
    const firstDay = new Date(this.currentYear, this.selectedMonth, 1);
    const lastDay = new Date(this.currentYear, this.selectedMonth + 1, 0);

    this.getMonthString();
    this.firstMonthDay = firstDay.getDate();
    this.lastMonthDay = lastDay.getDate();

    if (this.viewPeriod === ClockHourView.Week) {
      this.resolveWeekFilter(this.currentDay);
    }
    if (this.viewPeriod === ClockHourView.Month) {
      this.resolveMonthFilter();
    }
  }

  private changeDate(startDate: Date, endDate: Date) {
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      return;
    }

    const startDateFormatted = getDateStrWithoutUTC(startDate);
    const endDateFormatted = getDateStrWithoutUTC(endDate);

    this.changeDateRange.emit({
      startDate: startDateFormatted,
      endDate: endDateFormatted,
    });
  }
}
