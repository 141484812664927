import { Injectable } from '@angular/core';

import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from '@web-frontend/shared/services';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  ADMIN_ROUTES = ['settings'];

  constructor(private router: Router, private authService: AuthService) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const user = await this.authService.handleUserRequest().toPromise();

    if (user.deleted) {
      this.authService.logout();
      this.router.navigate(['/login']);
    }

    return true;
  }
}
