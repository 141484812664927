import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Deal, RecurrenceGlobal } from '@tacliatech/types';
import {
  CreatePeriodicityParams,
  DefaultPeriodicity,
} from './create-periodicity.types';

import { addDays, format } from 'date-fns';

@Component({
  selector: 'roma-create-periodicity',
  templateUrl: './create-periodicity.component.html',
  styleUrls: ['./create-periodicity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatePeriodicityComponent implements OnInit {
  // data to emit output
  recurrence: RecurrenceGlobal.Storage;

  // date to emit output
  recurrenceEnd: {
    type: Deal.TypeEndRecurrence;
    date?: string;
    amount?: number;
  } = {
    type: Deal.TypeEndRecurrence.Indefinitely,
    date: format(addDays(new Date(), 1), 'YYYY-MM-DD'),
    amount: 1,
  };

  periodicities = RecurrenceGlobal.PeriodicitiesSelectors;
  recurrenceIsValid = false;

  typeEndRecurrenceRef = Deal.TypeEndRecurrence;
  dateMin = format(addDays(new Date(), 1), 'YYYY-MM-DD');

  constructor(
    private cdRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<CreatePeriodicityComponent>,
    @Inject(MAT_DIALOG_DATA) private data: CreatePeriodicityParams
  ) {}

  ngOnInit(): void {
    this.resolveDataParams();
  }

  close() {
    this.dialogRef.close(null);
  }

  submit() {
    this.dialogRef.close({
      recurrence: this.recurrence,
      recurrenceEnd: this.recurrenceEnd,
    });
  }

  deleteRecurrence() {
    this.dialogRef.close({ wantDeleteRecurrence: true });
  }

  changeActiveDayWeek(indexElements: number[]) {
    if (indexElements.length) {
      this.updateRecurrence('dayToRepeat', indexElements);
    } else {
      this.updateRecurrence(
        'dayToRepeat',
        DefaultPeriodicity(this.data.params.date).dayToRepeat
      );
    }
  }

  changeRepeatEach() {
    // @ts-ignore
    this.updateRecurrence('repeatEach', +this.recurrence?.repeatEach);
  }

  changePeriodicity(evt: RecurrenceGlobal.Periodicity) {
    if (evt === 'EMPTY') {
      // @ts-ignore
      this.recurrence?.repeatEach = 0;
    }
  }

  private resolveDataParams() {
    if (this.data.type === 'ADD') {
      this.attachAddParams();
    } else if (this.data.type === 'EDIT') {
      this.attachEditParams();
    }
  }

  private attachAddParams() {
    this.recurrence = DefaultPeriodicity(this.data.params.date);
    this.recurrenceIsValid = RecurrenceGlobal.isValidRecurrence(
      this.recurrence
    );

    this.draw();
  }

  private attachEditParams() {
    this.recurrence = this.data.params.recurrence;
    this.recurrenceIsValid = RecurrenceGlobal.isValidRecurrence(
      // @ts-ignore
      this.recurrence
    );
    // @ts-ignore
    this.recurrenceEnd = this.data.params?.recurrenceEnd;

    this.draw();
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  private updateRecurrence(
    property: keyof RecurrenceGlobal.Storage,
    value: RecurrenceGlobal.Storage[keyof RecurrenceGlobal.Storage]
  ) {
    // @ts-ignore
    this.recurrence = {
      ...this.recurrence,
      [property]: value,
    };

    this.recurrenceIsValid = RecurrenceGlobal.isValidRecurrence(
      // @ts-ignore
      this.recurrence
    );

    this.draw();
  }
}
