import {
  Equipment as EquipmentNamespace,
  IAsset,
  IFinal,
  IUser,
} from '@tacliatech/types';

export class Equipment implements EquipmentNamespace.Schema {
  _id?: string;
  ref: string;
  title: string;
  description: string;
  brand: string;
  model: string;
  number: string;
  revisionDate: Date;
  revisionFrequency: string;
  units: number;
  annualAmortization: number;
  files: string[];
  warrantyDate: Date;
  notes: any;
  qr: string;
  owner: IUser;
  createdBy: string;

  idAssets: string[];
  assets: IAsset[];

  final: IFinal;
  deleted?: boolean;

  static fromArray(attributes: EquipmentNamespace.Schema[]) {
    return attributes.map((attribute) => new Equipment(attribute));
  }

  constructor(attribute: EquipmentNamespace.Schema) {
    Object.assign(this, attribute);
  }

  assetsObj: IAsset[];
  finalObj?: IFinal;
  customProperties?: { [key: string]: any };
  idUsers?: string[];
}
