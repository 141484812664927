<roma-modal-template
  [title]="title | translate"
  [size]="'sm'"
  [disableSuccess]="true"
  [showSuccessBtn]="false"
  [showCancelBtn]="false"
  [contentMinHeight]="false"
  [showDefaultButtons]="false"
  (cancel)="ok()"
  (success)="ok()"
>
  <div class="items-list" *ngIf="!isLoading">
    <div class="list-item" *ngFor="let item of _internalData">
      <img
        [src]="
          item.img
            ? (item.img | imageFirebase: 'user')
            : '/assets/icons/table-user-icon.svg'
        "
      />
      {{ item.name }}
    </div>
  </div>
  <mat-progress-spinner
    *ngIf="isLoading"
    class="loading-spinner"
    mode="indeterminate"
    [diameter]="40"
  ></mat-progress-spinner>
</roma-modal-template>
