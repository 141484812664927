export enum BudgetStatus {
  Created = 9,
  Sent = 10,
  Accepted = 11,
  Rejected = 12,
  Paid = 13,
  Invoiced = 14,
  Cancelled = 15,
  Overdue = 16,
}
