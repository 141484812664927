<!-- neded for preview -->
<roma-modal-template
  [title]="'budgets.title.preview' | translate"
  (cancel)="close()"
  [showDefaultButtons]="false"
  *ngIf="!download"
>
  <html id="htmlData" class="formPreview" [innerHTML]="html"></html>
</roma-modal-template>

<!-- neded for print -->
<html id="htmlData" *ngIf="download" class="form" [innerHTML]="html"></html>
