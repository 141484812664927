import {
  Directive,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { ProFeatureService } from '../permissions/pro-feature.service';
import { Feature } from '@tacliatech/types';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[dragAndDrop]',
})
export class DragAndDropDirective implements OnInit, OnDestroy {
  fileOver = false;
  @Output() fileDropped: EventEmitter<Event> = new EventEmitter<Event>();

  sub$ = new Subscription();

  constructor(
    private permissionService: PermissionService,
    private proFeatureService: ProFeatureService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event']) onDrop(evt: DragEvent) {
    this.sub$.add(
      this.permissionService
        .hasFeaturesFn([Feature.Deal.SeeLogo, Feature.Sale.SeeBillLogo])
        .subscribe((haveFeature) => {
          evt.preventDefault();
          evt.stopPropagation();

          if (haveFeature) {
            this.fileOver = false;

            const files = evt.dataTransfer?.files;
            if (files.length > 0) {
              this.fileDropped.emit(evt);
            }
          } else {
            this.proFeatureService.open(Feature.Sale.SeeBillLogo);
          }
        })
    );
  }
}
