<ng-container *ngIf="!isLoading">
  <div
    *ngFor="let item of data.slice(0, totalItems); let i = index"
    class="select__item"
  >
    <div class="select__item__option">
      <mat-slide-toggle
        class="example-margin"
        [color]="'primary'"
        [checked]="item.active"
        (change)="changeItem(item, i, $event)"
      >
      </mat-slide-toggle>
    </div>
    <div class="select__item__content">
      <h4 class="select__item__content__title">
        {{ item.title | translate }}
      </h4>
      <div class="select__item__content__description">
        {{ item.description }}
      </div>
    </div>
  </div>
  <button
    *ngIf="data?.length > 5 && totalItems != data?.length"
    type="button"
    class="add-field-btn"
    style="width: fit-content"
    (click)="totalItems = data?.length"
  >
    {{ 'general.seeMore2' | translate }}
    <img src="assets/icons/gl_plus.svg" style="margin-top: -3px" />
  </button>
</ng-container>
<mat-progress-spinner
  *ngIf="isLoading"
  mode="indeterminate"
  [diameter]="50"
  style="margin: 0px auto"
></mat-progress-spinner>
