import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { UnbindDealSaleModalComponent } from './unbind-deal-sale-modal.component';

@Injectable({
  providedIn: 'root',
})
export class UnbindDealSaleModalService {
  constructor(private dialog: MatDialog) {}

  open(config: MatDialogConfig<any> = new MatDialogConfig()) {
    const dialogRef = this.dialog.open(UnbindDealSaleModalComponent, config);

    return dialogRef.afterClosed().pipe(tap((res) => {}));
  }
}
