import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ModalErrorNotFoundComponent } from './modal-error-not-found.component';

@NgModule({
  declarations: [ModalErrorNotFoundComponent],
  imports: [CommonModule, TranslateModule.forChild(), MatDialogModule],
  exports: [ModalErrorNotFoundComponent],
})
export class ModalErrorNotFoundModule {}
