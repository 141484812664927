import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from '@web-frontend/shared/helpers/alert';
import { OnlyNumberModule } from '@web-frontend/shared/directives/onlyNumber';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';

import { PhoneManagerComponent } from './phone-manager.component';
import { PhoneDirectiveModule } from '@web-frontend/shared/directives/phone';

@NgModule({
  declarations: [PhoneManagerComponent],
  imports: [
    CommonModule,
    FormsModule,
    AlertModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    OnlyNumberModule,
    PhoneDirectiveModule,
    TranslateModule.forChild(),
  ],
  exports: [PhoneManagerComponent],
})
export class PhoneManagerModule {}
