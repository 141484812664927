import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ISequence, SequenceTypes, SequenceResponse } from '@tacliatech/types';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class SequenceService {
  constructor(
    private readonly http: HttpClient,
    private readonly toastService: ToastService
  ) {}

  getAllSequences(query: { [key: string]: any } = {}) {
    const params = new HttpParams({
      fromObject: {
        ...query,
      },
    });

    return this.http.get<SequenceResponse>(
      `:API_BASE_URLsales/serial-numbers/`,
      {
        params,
      }
    );
  }

  async getAllDocumentSequences(
    query: { [key: string]: any } = {},
    type: SequenceTypes
  ): Promise<ISequence[]> {
    const params = new HttpParams({
      fromObject: {
        ...query,
      },
    });

    const response = await this.http
      .get<SequenceResponse>(`:API_BASE_URLsales/serial-numbers/`, {
        observe: 'response',
        params,
      })
      .toPromise();

    const firstStatusDigit = response?.status?.toString()[0];

    if (['4', '5'].includes(firstStatusDigit)) {
      this.toastService.show({
        text: 'general.apiError',
        type: 'error',
        msDuration: 4000,
      });
      return [];
    }

    if (response.body.results.length !== 0) {
      return response.body.results;
    }

    const newSequence = await this.http
      .post<ISequence>(':API_BASE_URLsales/serial-numbers/default', {
        type: type,
      })
      .toPromise();
    return [
      {
        ...newSequence,
        id: newSequence.id,
        currentNumber: newSequence.currentNumber,
      },
    ];
  }

  getSequence(query: any = {}) {
    return this.http.post<ISequence>(`:API_BASE_URLsales/serial-numbers/`, {
      ...query,
    });
  }

  addSequence(body: ISequence) {
    return this.http.post(`:API_BASE_URLsales/serial-numbers/`, body, {
      observe: 'response',
    });
  }

  editSequence(body: ISequence) {
    return this.http.patch(
      `:API_BASE_URLsales/serial-numbers/${body.id}`,
      body,
      { observe: 'response' }
    );
  }

  deleteSequence(id: string) {
    return this.http.delete(`:API_BASE_URLsales/serial-numbers/${id}`);
  }
}
