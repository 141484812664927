// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Permission {
  export interface Item {
    feature: string;
  }

  export type List = Permission.Item[];
}

export enum MembershipSchema {
  OneProPlan = 'ONE_PRO_PLAN',
  MultipleProPlans = 'MULTIPLE_PRO_PLANS',
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Feature {
  export type Purchasable =
    | Deal
    | Final
    | Activity
    | Sale
    | Geolocation
    | ClockHour
    | ClockAbsence
    | Asset
    | Expense
    | InternalVendor
    | Report
    | SystemPermission
    | User;

  export enum User {
    Payroll = 'User_payroll',
    Contracts = 'User_contract',
    Documents = 'User_document',
  }
  export const UserList = Object.values(User) as User[];

  export enum Deal {
    DownloadExcel = 'deal_list_downloadExcel',
    DownloadPartOfService = 'Service_card_proof_download',
    SendEmail = 'Service_card_proof_send',
    SignDocuments = 'deal_card_proof_sign',
    SeeLogo = 'Service_card_proof_logo',
    HandleCustomProperties = 'customFields_add',
    UploadFiles = 'Service_images',
    SettingsGroupTemplate = 'deal_template_add',
    CustomPropertiesFilter = 'Service_list_filter_customFields',
    CustomPropertiesTable = 'Service_list_show_customFields',
  }

  export const DealList = Object.values(Deal) as Deal[];

  export enum Activity {
    DownloadExcel = 'Task_list_downloadExcel',
    HandleCustomProperties = 'Task_customFields',
    CustomPropertiesFilter = 'Task_list_filter_customFields',
    CustomPropertiesTable = 'Task_list_show_customFields',
    UploadFiles = 'Task_images',
  }

  export const ActivityList = Object.values(Activity) as Activity[];

  export enum Final {
    DownloadExcel = 'Final_list_downloadExcel',
    Import = 'Final_list_import',
    UploadFiles = 'Final_images',
    HandleCustomProperties = 'Final_customFields',
    CustomPropertiesFilter = 'Final_list_filter_customFields',
    CustomPropertiesTable = 'Final_list_show_customFields',
  }

  export const FinalList = Object.values(Final) as Final[];

  export enum Sale {
    DownloadExcel = 'Task_list_downloadExcel',
    SendEmail = 'SaleSendEmail',
    SeeLogo = 'SeeSaleLogo',

    DownloadBudgetExcel = 'budget_list_excelDownload',
    SendBudgetEmail = 'budget_view_send',
    SeeBudgetLogo = 'Sale_budget_logo',
    AddDiscount = 'Sale_budget_discount',

    DownloadBillExcel = 'bill_list_excelDownload',
    SendBillEmail = 'Sale_bill_send',
    SeeBillLogo = 'Sale_bill_logo',

    DownloadProformExcel = 'proform_list_excelDownload',
    SendProformEmail = 'Sale_proform_send',
    SeeProformLogo = 'Sale_proform_logo',

    WayBillDownload = 'waybill_download',
  }
  export const SaleList = Object.values(Sale) as Sale[];

  export enum Geolocation {
    Default = 'DEFAULT',
  }
  export const GeolocationList = Object.values(Geolocation) as Geolocation[];

  export enum ClockHour {
    ViewTimer = 'TimeTracking_timer',
    DownloadExcel = 'TimeTracking_all_downloadExcel',
  }
  export const ClockHourList = Object.values(ClockHour) as ClockHour[];

  export enum ClockAbsence {
    AddNewClockAbsenceMine = 'Timetracking_absence_create',
  }
  export const ClockAbsenceList = Object.values(ClockAbsence) as ClockAbsence[];

  export enum Asset {
    Default = 'DEFAULT',
    UploadFiles = 'Asset_images',
  }
  export const AssetList = Object.values(Asset) as Asset[];

  export enum Expense {
    Default = 'DEFAULT',
  }
  export const ExpenseList = Object.values(Expense) as Expense[];

  export enum InternalVendor {
    Default = 'DEFAULT',
  }

  export const InternalVendorList = Object.values(
    InternalVendor
  ) as InternalVendor[];

  export enum Report {
    Default = 'DEFAULT',
  }

  export enum SystemPermission {
    DefaultAllow = 'DefaultAllow',
    AllowAll = 'full-access',
  }

  export const ReportList = Object.values(Report) as Report[];

  export const getPurchasableListByFeature = (feature: Feature.Purchasable) => {
    const feat: any = feature;

    if (
      Feature.ClockHourList.includes(feat) ||
      Feature.ClockAbsenceList.includes(feat)
    ) {
      return [...Feature.ClockHourList, ...Feature.ClockAbsenceList];
    }

    if (Feature.DealList.includes(feat)) {
      return Feature.DealList;
    }

    if (Feature.SaleList.includes(feat)) {
      return Feature.SaleList;
    }

    if (Feature.FinalList.includes(feat)) {
      return Feature.FinalList;
    }

    if (Feature.ActivityList.includes(feat)) {
      return Feature.ActivityList;
    }

    if (Feature.UserList.includes(feat)) {
      return Feature.UserList;
    }

    return [];
  };
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace FeatureUser {
  export type Purchasable =
    | Deal
    | Final
    | Activity
    | Expense
    | Address
    | InternalVendor
    | Project
    | Equipment
    | Bill
    | Proform
    | Quote
    | Catalogue
    | Geolocation
    | Report
    | Sale
    | Payment
    | User
    | CompanyAdjust
    | Functionalitie
    | Calendar
    | OnlineProfile
    | Waybills
    | ClockHour
    | ClockHourAbsence
    | ClockHourAbsenceCompany
    | ClockHourCompany;

  export enum Deal {
    show = 'p_deal_show',
    create = 'p_deal_create',
    update = 'p_deal_update',
    delete = 'p_deal_delete',
  }

  export const DealList = Object.values(Deal) as Deal[];

  export enum Final {
    show = 'p_final_show',
    create = 'p_final_create',
    update = 'p_final_update',
    delete = 'p_final_delete',
  }

  export const FinalList = Object.values(Final) as Final[];

  export enum Activity {
    show = 'p_activity_show',
    create = 'p_activity_create',
    update = 'p_activity_update',
    delete = 'p_activity_delete',
  }

  export const ActivityList = Object.values(Activity) as Activity[];

  export enum InternalVendor {
    show = 'p_internal_vendor_show',
    create = 'p_internal_vendor_create',
    update = 'p_internal_vendor_update',
    delete = 'p_internal_vendor_delete',
  }

  export const InternalVendorList = Object.values(
    InternalVendor
  ) as InternalVendor[];

  export enum Project {
    show = 'p_project_show',
    create = 'p_project_create',
    update = 'p_project_update',
    delete = 'p_project_delete',
  }

  export const ProjectList = Object.values(Project) as Project[];

  export enum Equipment {
    show = 'p_equimpent_show',
    create = 'p_equimpent_create',
    update = 'p_equimpent_update',
    delete = 'p_equimpent_delete',
  }

  export const EquipmentList = Object.values(Equipment) as Equipment[];

  export enum Address {
    show = 'p_address_show',
    create = 'p_address_create',
    update = 'p_address_update',
    delete = 'p_address_delete',
  }

  export const AddressList = Object.values(Address) as Address[];

  export enum Expense {
    show = 'p_expense_show',
    create = 'p_expense_create',
    update = 'p_expense_update',
    delete = 'p_expense_delete',
  }

  export const ExpenseList = Object.values(Expense) as Expense[];

  export enum Bill {
    show = 'p_bill_show',
    create = 'p_bill_create',
    update = 'p_bill_update',
    delete = 'p_bill_delete',
  }

  export const BillList = Object.values(Bill) as Bill[];

  export enum Proform {
    show = 'p_proform_show',
    create = 'p_proform_create',
    update = 'p_proform_update',
    delete = 'p_proform_delete',
  }

  export const ProformList = Object.values(Proform) as Proform[];

  export enum Quote {
    show = 'p_quote_show',
    create = 'p_quote_create',
    update = 'p_quote_update',
    delete = 'p_quote_delete',
  }

  export const QuoteList = Object.values(Quote) as Quote[];

  export enum Catalogue {
    show = 'p_catalogue_show',
    create = 'p_catalogue_create',
    update = 'p_catalogue_update',
    delete = 'p_catalogue_delete',
  }

  export const CatalogueList = Object.values(Catalogue) as Catalogue[];

  export enum Geolocation {
    show = 'p_geolocation_show',
  }

  export const GeolocationList = Object.values(Geolocation) as Geolocation[];

  export enum Report {
    show = 'p_report_show',
  }

  export const ReportList = Object.values(Report) as Report[];

  export enum Sale {
    show = 'p_sale_show',
    create = 'p_sale_create',
  }

  export const SaleList = Object.values(Sale) as Sale[];

  export enum Payment {
    show = 'p_payment_show',
  }

  export const PaymentList = Object.values(Payment) as Payment[];

  export enum ClockHour {
    show = 'p_clockhour_show',
    create = 'p_clockhour_create',
    update = 'p_clockhour_update',
    delete = 'p_clockhour_delete',
  }

  export const ClockHourList = Object.values(ClockHour) as ClockHour[];

  export enum ClockHourCompany {
    show = 'p_clockhourcompany_show',
    approve = 'p_clockhourcompany_approve',
  }

  export const ClockHourCompanyList = Object.values(
    ClockHourCompany
  ) as ClockHourCompany[];

  export enum ClockHourAbsence {
    show = 'p_clockhourabsence_show',
    create = 'p_clockhourabsence_create',
    update = 'p_clockhourabsence_update',
    delete = 'p_clockhourabsence_delete',
  }

  export const ClockHourAbsenceList = Object.values(
    ClockHourAbsence
  ) as ClockHourAbsence[];

  export enum ClockHourAbsenceCompany {
    show = 'p_clockhourabsencecompany_show',
    approve = 'p_clockhourabsencecompany_approve',
  }

  export const ClockHourAbsenceCompanyList = Object.values(
    ClockHourAbsenceCompany
  ) as ClockHourAbsenceCompany[];

  export enum User {
    show = 'p_user_show',
    create = 'p_user_create',
    update = 'p_user_update',
    delete = 'p_user_delete',
  }

  export const UserList = Object.values(User) as User[];

  export enum CompanyAdjust {
    show = 'p_comapanyadjust_show',
    update = 'p_comapanyadjust_update',
  }

  export const CompanyAdjustList = Object.values(
    CompanyAdjust
  ) as CompanyAdjust[];

  export enum Functionalitie {
    show = 'p_functionalitie_show',
    update = 'p_functionalitie_update',
  }

  export const FunctionalitieList = Object.values(
    Functionalitie
  ) as Functionalitie[];

  export enum Calendar {
    show = 'p_calendar_show',
  }

  export const CalendarList = Object.values(Calendar) as Calendar[];

  export enum OnlineProfile {
    show = 'p_onlineprofile_show',
    update = 'p_onlineprofile_update',
  }

  export const OnlineProfileList = Object.values(
    OnlineProfile
  ) as OnlineProfile[];

  export enum Waybills {
    show = 'p_waybills_show',
    create = 'p_waybills_create',
    update = 'p_waybills_update',
    delete = 'p_waybills_delete',
  }

  export const WaybillsList = Object.values(Waybills) as Waybills[];
}
