import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppendCustomPropertiesComponent } from './append-custom-properties.component';
import { BasicMessageModule } from '../basic-message';
import { PermissionsModule } from '../permissions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { ObligatoryFieldSignModule } from '../obligatory-field-sign/obligatory-field-sign.module';

@NgModule({
  declarations: [AppendCustomPropertiesComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    NgSelectModule,
    BasicMessageModule,
    PermissionsModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    ObligatoryFieldSignModule,
  ],
  exports: [AppendCustomPropertiesComponent],
})
export class AppendCustomPropertiesModule {}
