<div>
  <div class="title-card">
    {{ 'privacyPolicy.title' | translate }}
  </div>

  <div class="d-flex flex-column gap-4">
    
    <div class="action text-primary border-bottom" (click)="onClickPrivacy()">
      {{ 'privacyPolicy.privacyPolicy' | translate }}
    </div>

    <div class="action text-primary border-bottom" (click)="onClickTermsOfUse()">
      {{ 'privacyPolicy.termsOfUse' | translate }}
    </div>

    <div *ngIf="isAdmin" class="action text-danger" (click)="onClickDeleteAccount()">
      {{ 'privacyPolicy.deleteAccount' | translate }}
    </div>
  </div>
</div>