<div class="container-body">
  <canvas
    *ngIf="id"
    [attr.id]="id"
    class="signature-pad"
    [width]="width"
    [height]="height"
  ></canvas>

  <div *ngIf="isEmpty" class="container-body-placeholder">
    {{ 'general.sign2' | translate }}
  </div>

  <ng-container *ngIf="tagBottomCanvas">
    <div class="container-body-title">
      {{ tagBottomCanvas | translate }}
    </div>
  </ng-container>
</div>

<div class="container-options">
  <button
    *ngIf="!isEmpty"
    romaProFeature
    [feature]="featureRef.Sale.WayBillDownload"
    [featureUser]="featureRefUser.Bill.create"
    (clicked)="clear()"
    class="btn-delete"
    [disabled]="isLoading"
  >
    <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
    <!-- <img
      *ngIf="!(hasFeatureSignDocuments$ | async)"
      src="assets/icons/star.svg"
    /> -->
    {{ 'general.delete' | translate }}
  </button>

  <button
    *ngIf="dataFromUrl"
    romaProFeature
    [feature]="featureRef.Sale.WayBillDownload"
    [featureUser]="featureRefUser.Bill.create"
    (clicked)="update()"
    class="btn-sign"
    [disabled]="isLoading"
  >
    <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
    {{ 'general.edit' | translate }}
  </button>

  <button
    *ngIf="!dataFromUrl"
    (click)="update()"
    class="btn-sign"
    [disabled]="isLoading"
  >
    <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
    {{ 'general.sign' | translate }}
  </button>
</div>
