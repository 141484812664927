<roma-modal-template
  [title]="
    params.type === 'ADD'
      ? ('projects.addModal' | translate)
      : ('projects.editModal' | translate)
  "
  (success)="submit()"
  (cancel)="close($event)"
  [showInfo]="true"
  [isLoading]="isLoading"
  [askBeforeClose]="haveChanges"
  (info)="info('info')"
>
  <form [formGroup]="form" class="global-container">
    <div class="global-item">
      <div class="form-group">
        <label class="field-label" for="name">
          {{ 'projects.create.name' | translate
          }}<obligatory-field-sign></obligatory-field-sign>
        </label>

        <input
          type="text"
          class="form-control"
          id="name"
          aria-describedby="name"
          formControlName="name"
          [placeholder]="'projects.create.namePh' | translate"
          [ngClass]="{ 'is-invalid': fieldName.displayError || false }"
        />

        <roma-field-error-display
          #fieldName
          fieldName="name"
          [errorMsg]="'projects.create.nameError'"
          [form]="form"
        >
        </roma-field-error-display>
      </div>

      <div class="form-group">
        <label class="field-label" for="description">
          {{ 'projects.create.description' | translate }}
        </label>
        <textarea
          type="text"
          cols="30"
          rows="2"
          class="form-control custom-textarea"
          formControlName="description"
          maxLength="155"
        >
        </textarea>
      </div>

      <div class="form-group">
        <label class="field-label" for="idOwner">
          {{ 'projects.create.owner' | translate }}
        </label>

        <ng-select
          id="idOwner"
          bindLabel="key"
          bindValue="value"
          formControlName="idOwner"
          [items]="owners"
          [multiple]="false"
          [loading]="false"
          [placeholder]="'projects.create.ownerPh' | translate"
          [notFoundText]="'hiring.form.placeholder_notfound' | translate"
          [loading]="users2Loading"
          (click)="onClickSelect('users2')"
        >
        </ng-select>
      </div>

      <div
        *ngIf="(idActiveModules$ | async).includes(idModuleRef.Finals)"
        class="form-group wrapper-pro-feature-user"
        romaProSelect
        [featureUser]="featureRefUser.Final.show"
      >
        <label class="field-label" for="idClient">
          {{ 'projects.create.client' | translate }}
        </label>

        <ng-select
          id="idClient"
          bindLabel="key"
          bindValue="value"
          formControlName="idClient"
          [items]="clients"
          [multiple]="false"
          [loading]="false"
          [placeholder]="'projects.create.clientPh' | translate"
          [notFoundText]="'hiring.form.placeholder_notfound' | translate"
          [loading]="clientsLoading"
          (click)="onClickSelect('clients')"
        >
        </ng-select>
      </div>

      <div class="form-group">
        <label class="field-label" for="idUsers">
          {{ 'projects.create.users' | translate }}
        </label>

        <ng-select
          id="idUsers"
          bindLabel="key"
          bindValue="value"
          formControlName="idUsers"
          [items]="users"
          [multiple]="true"
          [loading]="false"
          [placeholder]="'projects.create.usersPh' | translate"
          [notFoundText]="'hiring.form.placeholder_notfound' | translate"
          [loading]="usersLoading"
          (click)="onClickSelect('users')"
        >
        </ng-select>
      </div>
    </div>

    <div class="global-item">
      <div class="images-container">
        <roma-image-selector
          [type]="'project'"
          [quantity]="5"
          [mode]="this.params.type"
          [title]="'hiring.form.add-images-lbl' | translate"
          [accept-files]="true"
          [filesServer]="this.params.data?.files"
          (images-changed)="updateImages($event)"
          [delete-images-service]="deleteImages$"
          (OnErrorEvent)="showAlert($event)"
          [feature]="featureRef.Final.UploadFiles"
          [module]="idModuleRef.Projects"
        >
        </roma-image-selector>
      </div>

      <label class="field-label" for="notes">
        {{ 'projects.create.notes' | translate }}
      </label>
      <roma-equipment-notes
        [mode]="params?.type"
        [data]="params?.data?.notes"
        (addingNotes)="addingNotes($event)"
      >
      </roma-equipment-notes>
    </div>
    <div class="global-item">
      <div class="global-item">
        <roma-append-custom-properties
          *ngIf="params.type === 'ADD'"
          [typeRef]="typePropertyRef.Project"
          [rowLayout]="true"
          size="lg"
          [control]="form.get('customProperties')"
          [parentForm]="form"
          [initForm]="initChildForm"
          [parentFormSubmit]="parentFormSubmit"
          (customPropertiesForm)="customPropertiesFormChange($event)"
          (initialCharge)="initialChargeEvent()"
          (closeModal)="close($event)"
        ></roma-append-custom-properties>

        <roma-edit-custom-properties
          *ngIf="params.type === 'EDIT'"
          [idSchema]="projectEditRef?._id"
          [typeRef]="typePropertyRef.Project"
          [customProperties]="projectEditRef?.customProperties"
          (propertiesRequired)="propertiesRequired($event)"
          (changeSuccess)="changeCustomProperty()"
        ></roma-edit-custom-properties>
      </div>
    </div>
  </form>
</roma-modal-template>
