import { Platform } from '@angular/cdk/platform';
import { Inject, Injectable, Optional } from '@angular/core';
import { MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { BudgetService } from '../services/budgets';
import { StorageService } from '../services';
import { FIRST_DAY_CALENDAR, IBudgetsSetting } from '@tacliatech/types';

@Injectable({
  providedIn: 'root',
})
export class CustomDateAdapter extends NativeDateAdapter {
  private dayInit = StorageService.FirstDayCalendar ?? 1;
  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string,
    platform: Platform,
    private budgetService: BudgetService
  ) {
    super(matDateLocale, platform);
  }

  private getCompanySettings() {
    const id = StorageService.CompanyId;
    this.budgetService
      .findSettingsByCompany(id)
      .subscribe((resp: IBudgetsSetting) => {
        if (resp.budgetPreferences?.day) {
          this.dayInit = resp.budgetPreferences.day;
          StorageService.SetItem(FIRST_DAY_CALENDAR, this.dayInit);
        }
      });
  }

  getFirstDayOfWeek(): number {
    this.dayInit = StorageService.FirstDayCalendar;
    this.getCompanySettings();
    return this.dayInit;
  }
}
