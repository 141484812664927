import { formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import { BudgetService } from '@web-frontend/shared/services/budgets';

@Injectable({
  providedIn: 'root',
})
export class BudgetPreviewerService {
  constructor(private budgetService: BudgetService) {}

  numbers_format = this.budgetService.findAllNumberFormats();
  currencys = this.budgetService.findAllCurrencys();
  DEFAULT_CURRENCY_SYMBOL = '€';

  formatCustomNumber(
    paramNumber: number,
    format_decimals,
    settingsDecimals
  ): string {
    let locale = '';

    if (format_decimals?.decimal_separator == ',') {
      locale = 'es-AR';
    } else {
      locale = 'en-US';
    }

    const decimals = settingsDecimals.toString()
      ? settingsDecimals.toString()
      : '1';

    let digitsInfo = '1.';

    digitsInfo = digitsInfo + decimals + '-' + decimals;

    const formattedNumber = formatNumber(paramNumber, locale, digitsInfo);
    return formattedNumber;
  }

  getCurrencySimbol(settingsCurrency) {
    const format = this.currencys.find(
      (format) => format.id === settingsCurrency
    );

    return format ? format.value : this.DEFAULT_CURRENCY_SYMBOL;
  }
}
