<div class="wrapper__body">
  <div class="wrapper__body__content">
    <form [formGroup]="form">
      <span class="reference__text">
        {{ 'deals.dealDetail.ref' | translate }}
        <span class="reference__number">{{ dealData?._id }}</span>
      </span>
      <h1 class="title">{{ dealData.name }}</h1>
      <!-- Actions Row -->
      <div class="actions__row">
        <button
          class="action__element btn download"
          (click)="showPreview()"
          [disabled]="downloadingPreview"
        >
          <img src="assets/icons/gl_eye-on.svg" />
          {{ 'deal_part_of_service.preview' | translate }}
          <div class="spinner-container" *ngIf="downloadingPreview">
            <mat-progress-spinner
              class="loading-spinner"
              mode="indeterminate"
              [diameter]="40"
            ></mat-progress-spinner>
          </div>
        </button>

        <button
          romaProFeature
          [feature]="featureRef.Deal.SendEmail"
          class="action__element"
          (clicked)="sendEmail()"
        >
          <div class="action__element__content">
            <p class="action__element__content__text">
              <img src="assets/icons/gl_mail-line.svg" />
              <ng-container *ngIf="(resize$ | async)?.payload?.tablet">
                {{ 'general.send' | translate }}
              </ng-container>
              <ng-container *ngIf="!(resize$ | async)?.payload?.tablet">
                {{ 'deal_part_of_service.sendByEmail' | translate }}
              </ng-container>
            </p>
            <img class="icon-star-pro-feature" src="assets/icons/star.svg" />
          </div>
        </button>

        <button
          romaProFeature
          [feature]="featureRef.Deal.DownloadPartOfService"
          class="action__element btn download"
          (clicked)="download()"
          [disabled]="downloading"
        >
          <div class="action__element__content">
            <p class="action__element__content__text">
              <img src="assets/icons/gl_download.svg" />
              {{ 'deal_part_of_service.download' | translate }}
            </p>
            <img class="icon-star-pro-feature" src="assets/icons/star.svg" />
          </div>
          <div class="spinner-container" *ngIf="downloading">
            <mat-progress-spinner
              class="loading-spinner"
              mode="indeterminate"
              [diameter]="40"
            ></mat-progress-spinner>
          </div>
        </button>
      </div>
      <h2 class="subtitle subtitle__h2" style="margin-bottom: 40px">
        {{ 'deal_part_of_service.showOnPart' | translate }}
      </h2>
      <!-- Description -->
      <h4 class="subtitle">
        {{ 'deal_part_of_service.descriptionOfPart' | translate }}
      </h4>
      <button
        *ngIf="!partOfService?.description && !showDescription"
        type="button"
        class="add-field-btn"
        style="width: fit-content"
        romaProFeature
        [featureUser]="featureRefUser.Deal.update"
        (clicked)="showDescription = !showDescription"
      >
        {{ 'deal_part_of_service.addDrescription' | translate }}
        <img src="assets/icons/gl_plus.svg" style="margin-top: -3px" />
      </button>
      <textarea
        *ngIf="partOfService?.description || showDescription"
        formControlName="description"
        class="description__textarea"
        (focusout)="changeShowDescription()"
        [value]="partOfService?.description || ''"
      >
      </textarea>
      <div class="wrapper__body__content__divider"></div>
      <!-- Data Select -->
      <h4 class="subtitle" style="margin-bottom: 8px">
        {{ 'deal_part_of_service.visibleData' | translate }}
      </h4>
      <p class="subtitle subtitle__sm">
        {{ 'deal_part_of_service.visibleDataSubtitle' | translate }}
      </p>
      <div
        romaProSelect
        class="wrapper-pro-feature-user"
        [featureUser]="featureRefUser.Deal.update"
      >
        <roma-select-item
          [data]="elements"
          [isLoading]="isLoading"
          (changeData)="changeSelectedItems($event)"
        ></roma-select-item>
      </div>
      <div
        romaProSelect
        class="wrapper-pro-feature-user"
        [featureUser]="featureRefUser.Deal.update"
      >
        <roma-select-item
          [data]="elementsCustomProperties"
          [isLoading]="elementsCustomPropertiesLoading"
          (changeData)="changeSelectedCustomPropertyItems($event)"
        ></roma-select-item>
      </div>
      <div
        romaProSelect
        class="wrapper-pro-feature-user"
        [featureUser]="featureRefUser.Deal.update"
      >
        <roma-select-item
          [data]="elementsCustomGroupProperties"
          [isLoading]="elementsCustomGroupPropertiesLoading"
          (changeData)="changeSelectedCustomGroupPropertyItems($event)"
        ></roma-select-item>
      </div>
      <div class="wrapper__body__content__divider"></div>
      <!-- Files -->
      <h4 class="subtitle" style="margin-bottom: 8px">
        {{ 'deal_part_of_service.images' | translate }}
      </h4>
      <p class="subtitle subtitle__sm" style="margin-bottom: 0">
        {{ 'deal_part_of_service.mediaLabel' | translate }}
      </p>
      <div *ngIf="imagesParsed" class="container-pro-image">
        <roma-image-selector
          [type]="'part-of-service'"
          [quantity]="hasFeatureHandleDealCustomProperty ? 5 : 1"
          [mode]="'EDIT'"
          [multiple]="false"
          [feature]="featureRef.Deal.UploadFiles"
          [fileArray]="imagesParsed"
          [customContent]="
            (resize$ | async)?.payload?.tablet
              ? null
              : {
                  feature: featureRef.Deal.UploadFiles,
                  title: 'pro.deal.files.title',
                  description: determineCopy('pro.deal.description'),
                  items: [],
                  successText: 'pro.successText',
                  cancelText: 'pro.cancelText'
                }
          "
          [featureUser]="featureRefUser.Deal.update"
          (changeFileArray)="changeFileArray($event)"
          [module]="idModuleRef.Deals"
        >
        </roma-image-selector>
      </div>

      <div class="wrapper__body__content__divider"></div>
      <h4 class="subtitle">
        {{ 'generateDocument.signature' | translate }}
      </h4>
      <!-- Signs -->
      <div
        class="item__content wrapper-pro-feature-user"
        romaProSelect
        [featureUser]="featureRefUser.Deal.update"
      >
        <roma-editable-field
          [value]="partOfService?.date?.replace('Z', '') || ''"
          [type]="'date'"
          [placeholderDate]="'deal_part_of_service.dateSign' | translate"
          [updateOnEdit]="false"
          (onValueChanged)="changeDateField($event)"
        >
        </roma-editable-field>
      </div>

      <div
        class="wrapper__signature wrapper-pro-feature-user"
        style="margin-bottom: 40px"
        romaProSelect
        [featureUser]="featureRefUser.Deal.update"
      >
        <div
          *ngIf="(idActiveModules$ | async).includes(idModuleRef.Finals)"
          class="wrapper__signature__item"
          [ngClass]="{ 'signature-hidden-canvas': !partOfService?.signClient }"
        >
          <roma-signature
            [id]="'client-signature'"
            [block]="true"
            [source]="form?.value?.signClient || ''"
            [width]="200"
            [height]="128"
            [isLoading]="uploadClientSignature"
            [tagBottomCanvas]="'general.customer' | translate"
            (changeSignature)="changeSignature($event, 'CLIENT')"
          >
          </roma-signature>
        </div>

        <div
          class="wrapper__signature__item"
          [ngClass]="{ 'signature-hidden-canvas': !partOfService?.signCompany }"
        >
          <roma-signature
            [id]="'company-signature'"
            [block]="true"
            [source]="form?.value?.signCompany || ''"
            [width]="200"
            [height]="128"
            [isLoading]="uploadCompanySignature"
            [tagBottomCanvas]="'dashboard.enterprise' | translate"
            (changeSignature)="changeSignature($event, 'COMPANY')"
          ></roma-signature>
        </div>
      </div>
    </form>
  </div>
  <div class="wrapper__body__footer">
    <!-- footer of body -->
    <!-- [matTooltip]="showTooltipSave()" -->
    <button
      type="button"
      role="button"
      class="footer-button roma-button save-btn"
      (click)="editSubmit()"
    >
      <mat-progress-spinner
        *ngIf="isLoading || isSubmitLoading"
        class="loading-spinner"
        mode="indeterminate"
        [diameter]="20"
      ></mat-progress-spinner>
      <ng-container *ngIf="!isLoading && !isSubmitLoading">
        {{ 'activity.edit.submit' | translate }}
      </ng-container>
    </button>
  </div>
</div>
<div
  class="wrapper__sidebar wrapper-pro-feature-user"
  romaProSelect
  [featureUser]="featureRefUser.Deal.update"
>
  <h4 class="subtitle" style="margin-bottom: 4px">
    {{ 'general.notes2' | translate }}
  </h4>
  <p class="subtitle subtitle__sm">
    {{ 'deal_part_of_service.noteOfPart' | translate }}
  </p>
  <div>
    <roma-notes-comments-v2
      [data]="partOfService?.notes || []"
      (changeNotes)="addingNotes($event)"
    ></roma-notes-comments-v2>
  </div>
</div>
