import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { TypeBudget } from '@tacliatech/types';
import {
  DeleteBySelectionModalComponent,
  DeleteDialogData,
} from '@web-frontend/shared/components/delete-by-selection-modal/delete-by-selection-modal.component';
import { PermissionService } from '../../services/permissions';

@Directive({
  selector: '[romaClickDelete]',
})
export class ClickDeleteDirective {
  @Input()
  item: unknown;

  @Input()
  type?: string;

  @Input()
  allowClick = true;

  @Input()
  message?: string;

  @Input()
  featureUser?: string = null;

  @Input()
  subtitle?: string;

  @Input()
  buttonDelete = 'general.confirmDelete';

  @Output()
  wantDelete = new EventEmitter();

  @Output()
  wantCancel = new EventEmitter();

  @Output()
  wantCancelX = new EventEmitter();

  constructor(
    private readonly i18n: TranslateService,
    private dialog: MatDialog,
    private readonly permissionService: PermissionService
  ) {}

  @HostListener('click', ['$event'])
  click(evt: MouseEvent): void {
    if (
      !this.allowClick ||
      (this.featureUser &&
        !this.permissionService.permissions.includes(this.featureUser))
    )
      return;

    let data: DeleteDialogData = {};

    evt.preventDefault();
    evt.stopPropagation();

    data.confirmLabel = this.buttonDelete;

    if (this.subtitle) data.body = this.i18n.instant(this.subtitle);
    if (this.message) {
      data.showBody = this.subtitle?.length > 0;
      data.title = this.message;
    }

    if (
      [TypeBudget.PROFORM, TypeBudget.BUDGET, TypeBudget.BILL].includes(
        this.type as TypeBudget
      )
    ) {
      data = {
        showBody: false,
        title: 'budgets.modal.wantExit',
        confirmLabel: 'budgets.modal.btnConfirmExit',
      };
    }

    const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
      panelClass: 'delete-by-selection-modal',
      data,
    });

    const actions = {
      EXECUTE: this.wantDelete,
      CANCEL: this.wantCancel,
      'CANCEL-X': this.wantCancelX,
    };

    dialogRef.afterClosed().subscribe((res) => {
      const action = actions[res];
      if (action) {
        action.next(this.item);
      }
    });
  }
}
