<roma-back-button
  *ngIf="showGoBackButton"
  (backClicked)="backSettings()"
></roma-back-button>
<div class="row">
  <div class="col-6">
    <h1 class="page-title">
      {{ 'section_settings.account.title' | translate }}
    </h1>
  </div>
</div>
<div class="container mt">
  <div class="card-item account-data">
    <roma-loading [size]="40" *ngIf="isLoading"></roma-loading>
    <roma-budget-account-data
      *ngIf="!isLoading"
      [id]="id"
      [budget]="budget"
      [mode]="mode"
      romaProSelect
      class="wrapper-pro-feature-user"
      [featureUser]="featureUserRef.CompanyAdjust.update"
    ></roma-budget-account-data>
  </div>
  <div class="card-item">
    <roma-loading [size]="40" *ngIf="isLoading"></roma-loading>
    <roma-budget-logo
      *ngIf="!isLoading"
      [id]="id"
      [budget]="budget"
      [mode]="mode"
    >
    </roma-budget-logo>
  </div>
  <div class="card-item">
    <roma-loading [size]="40" *ngIf="isLoading"></roma-loading>
    <roma-budget-sequence-settings
      *ngIf="!isLoading"
      [budget]="budget"
      [taxes]="taxes"
      romaProSelect
      class="wrapper-pro-feature-user"
      [featureUser]="featureUserRef.CompanyAdjust.update"
    >
    </roma-budget-sequence-settings>
  </div>
  <div class="card-item">
    <roma-loading [size]="40" *ngIf="isLoading"></roma-loading>
    <roma-budget-billing-data
      *ngIf="!isLoading"
      [id]="id"
      [budget]="budget"
      [mode]="mode"
      romaProSelect
      class="wrapper-pro-feature-user"
      [featureUser]="featureUserRef.CompanyAdjust.update"
    >
    </roma-budget-billing-data>
  </div>
  <div class="card-item budget-preferences">
    <roma-loading [size]="40" *ngIf="isLoading"></roma-loading>
    <roma-budget-preferences
      *ngIf="!isLoading"
      [id]="id"
      [budget]="budget"
      [mode]="mode"
      romaProSelect
      class="wrapper-pro-feature-user"
      [featureUser]="featureUserRef.CompanyAdjust.update"
    ></roma-budget-preferences>
  </div>
  <div class="card-item payment-method">
    <roma-loading [size]="40" *ngIf="isLoading"></roma-loading>
    <roma-budget-payment-method
      *ngIf="!isLoading"
      [id]="id"
      [list]="paymentMethods"
      romaProSelect
      class="wrapper-pro-feature-user"
      [featureUser]="featureUserRef.CompanyAdjust.update"
    ></roma-budget-payment-method>
  </div>
  <div class="card-item">
    <privacy-policies></privacy-policies>
  </div>
</div>
