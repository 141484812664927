<roma-modal-template
  [title]="'general.setRecurrency' | translate"
  [disableSuccess]="!recurrenceIsValid"
  [showDefaultButtons]="false"
  [footerTemplate]="footerCustomTemplate"
  (cancel)="close()"
>
  <div class="container__label">
    {{ 'general.repeat' | translate }}
  </div>

  <div class="container__selectors">
    <div class="container__selectors__left">
      <input
        type="number"
        class="form-control"
        [disabled]="recurrence.periodicity === 'EMPTY'"
        [(ngModel)]="recurrence.repeatEach"
        (ngModelChange)="changeRepeatEach()"
        min="0"
      />
    </div>
    <div class="container__selectors__right">
      <div class="container__selectors__right__select">
        <select
          class="form-control custom__select__control"
          [(ngModel)]="recurrence.periodicity"
          (ngModelChange)="changePeriodicity($event)"
        >
          <option *ngFor="let element of periodicities" [value]="element.value">
            {{
              recurrence.repeatEach > 1
                ? (element.tag + 'S' | translate)
                : (element.tag | translate)
            }}
          </option>
        </select>

        <img src="assets/icons/gl_arrow_down.svg" />
      </div>
    </div>
  </div>
  <ng-container *ngIf="recurrence.periodicity === 'WEEK'">
    <div class="container__label">
      {{ 'Se repite el' | translate }}
    </div>

    <div class="wrapper-elements">
      <roma-choose-week
        [actives]="recurrence.dayToRepeat"
        (changeActiveDay)="changeActiveDayWeek($event)"
      ></roma-choose-week>
    </div>
  </ng-container>

  <div class="container__label">
    {{ 'Finaliza' | translate }}
  </div>

  <mat-radio-group [(ngModel)]="recurrenceEnd.type">
    <mat-radio-button value="SPECIFIC_DAY">
      <span class=""> El </span>

      <input
        type="date"
        [disabled]="recurrenceEnd.type !== typeEndRecurrenceRef.SpecificDay"
        [(ngModel)]="recurrenceEnd.date"
        [min]="dateMin"
        class="form-control radio-control input-date"
      />
    </mat-radio-button>
    <mat-radio-button value="SPECIFIC_AMOUNT">
      Después de

      <input
        [disabled]="recurrenceEnd.type !== typeEndRecurrenceRef.SpecificAmount"
        [(ngModel)]="recurrenceEnd.amount"
        type="number"
        class="form-control radio-control input"
        min="1"
        placeholder="00 "
      />

      Veces
    </mat-radio-button>

    <mat-radio-button value="INDEFINITELY"> Nunca </mat-radio-button>
  </mat-radio-group>

  <ng-template #footerCustomTemplate>
    <div class="custom-modal__footer">
      <div class="custom-content--btns mobile-row">
        <button
          class="roma-button transparent"
          style="color: #ff474e; border: none; margin-right: 10px"
          (click)="deleteRecurrence()"
        >
          <img src="assets/icons/gl_trash_red.svg" style="margin-right: 10px" />
          {{ 'general.deleteAlt' | translate }}
        </button>
        <button class="custom-btn-save" (click)="submit()">
          {{ 'filter.save' | translate }}
        </button>
      </div>
    </div>
  </ng-template>
</roma-modal-template>
