<div class="container-body">
  <canvas
    *ngIf="id"
    [attr.id]="id"
    class="signature-pad"
    [width]="width"
    [height]="height"
  ></canvas>

  <div *ngIf="isEmpty" class="container-body-placeholder">
    {{ 'general.sign2' | translate }}
  </div>

  <ng-container *ngIf="tagBottomCanvas">
    <div class="container-body-title">
      {{ tagBottomCanvas | translate }}
    </div>
  </ng-container>
</div>

<div class="container-options">
  <button
    *ngIf="!isEmpty"
    class="btn-sign danger"
    [disabled]="isLoading"
    (click)="clear()"
  >
    <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
    <img
      *ngIf="!(hasFeatureSignDocuments$ | async)"
      src="assets/icons/star.svg"
    />
    {{ 'general.delete' | translate }}
  </button>

  <button
    class="btn-sign"
    [disabled]="isLoading"
    (click)="update()"
    [ngStyle]="
      !dataFromUrl && { width: 'calc(100% - 20px)', 'margin-left': '10px' }
    "
  >
    <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
    <img
      *ngIf="!(hasFeatureSignDocuments$ | async)"
      src="assets/icons/star.svg"
    />
    <ng-container *ngIf="dataFromUrl">
      {{ 'general.edit' | translate }}</ng-container
    >
    <ng-container *ngIf="!dataFromUrl">
      {{ 'general.sign' | translate }}</ng-container
    >
  </button>
</div>
