import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { SharePaymentLinkModalComponent } from './share-payment-link-modal.component';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { SendEmailSaleModalService } from '../../send-email-sale-modal';
import { SaleStoreService } from '../create-sale.store.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { BudgetStatus, IBudgetCommonData } from '@tacliatech/types';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';

@Injectable({
  providedIn: 'root',
})
export class SharePaymentLinkModalService {
  dialogRef: MatDialogRef<SharePaymentLinkModalComponent, any>;
  paymentLinkUrl = '';
  idSale = '';
  private modalClosed = new BehaviorSubject<boolean>(false);
  modalClosed$ = this.modalClosed.asObservable();
  module = 'sales';
  finalEmail = null;
  finalId = '';
  billUpdated$ = new Subject<IBudgetCommonData>();
  updatingBill$ = new Subject<boolean>();
  type = 'sales';

  constructor(
    private dialog: MatDialog,
    private clipboard: Clipboard,
    private toastService: ToastService,
    private sendEmailSaleModalService: SendEmailSaleModalService,
    private saleStorageService: SaleStoreService,
    private readonly brazeService: BrazeService,
    private readonly budgetsService: BudgetService,
    private amplitudeService: AmplitudeService
  ) {}

  open(config: MatDialogConfig = new MatDialogConfig()) {
    this.paymentLinkUrl =
      config.data?.url ||
      config.data.saleStorage?.sale?.paymentLink?.url ||
      config.data.saleStorage?.paymentLink?.url;

    this.idSale = config.data?.idSale;

    if (config.data?.module) {
      this.module = config.data.module;
      if (this.module === 'budgets' && config.data?.type) {
        this.type = config.data.type;
      }
    }

    this.dialogRef = this.dialog.open(SharePaymentLinkModalComponent, config);

    return this.dialogRef.afterClosed().pipe(
      tap((res) => {
        //this.modalClosed.next(true);
      })
    );
  }

  closeModal() {
    this.dialogRef.close();
  }

  copyLink() {
    if (!this.paymentLinkUrl) return;

    this.clipboard.copy(this.paymentLinkUrl);
    this.toastService.show({
      text: 'activity.copyLink',
      type: 'success',
      msDuration: 4000,
      class: '',
    });

    this.amplitudeService.sendEvent({
      event: `${this.type}_view_charge_shareLink`,
    });

    this.dialogRef.close();
  }

  sendSaleEmail() {
    this.amplitudeService.sendEvent({
      event: `${this.type}_view_charge_emailStart`,
    });

    return this.sendEmailSaleModalService
      .openLightVersion({
        data: {
          idSale: this.idSale,
          saleStorage: this.saleStorageService.getSale(),
          module: this.module,
        },
      })
      .subscribe((res) => {
        if (res === 'emailSent') {
          this.brazeService.sendEvent({
            event: BrazeEventType.sale_payment_link,
          });

          this.amplitudeService.sendEvent({
            event: `${this.type}_view_charge_emailSend`,
          });

          this.dialogRef.close();
        }
      });
  }

  updateBillStatus(statusId: BudgetStatus) {
    this.updatingBill$.next(true);
    this.budgetsService
      .updateOneBill(this.idSale, {
        status: statusId,
      })
      .subscribe((res) => {
        this.billUpdated$.next(res);
        this.updatingBill$.next(false);
      });
  }
}
