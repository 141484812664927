import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';

import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import {
  ConvertToKeyValue,
  IEquipmentNote,
  MapperFinal,
  ValidateAllFormFields,
} from '@tacliatech/types';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { RemoveEmpty } from '@web-frontend/shared/utils';

import { StorageService } from '@web-frontend/shared/services';

import { FilterItems } from '../filter';

import { DataProjectModalParams } from './create-budget-payment-method.types';
import { CreateBudgetPaymentMethodService } from './create-budget-payment-method.service';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';

@Component({
  selector: 'roma-create-budget-payment-method',
  templateUrl: './create-budget-payment-method.component.html',
  styleUrls: ['./create-budget-payment-method.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateBudgetMethodPaymentComponent implements OnInit {
  form = this.fb.group({
    name: ['', Validators.required],
    textShow: ['', Validators.required],
    icon: [''],
    idOwner: [StorageService.CompanyId, Validators.required],
  });

  owners: FilterItems = [];
  clients: FilterItems = [];
  users: FilterItems = [];
  icons = [
    {
      value: 'account_balance',
      icon: 'account_balance',
      viewValue: 'Account Balance',
    },
    { value: 'euro_symbol', icon: 'euro_symbol', viewValue: 'Euro' },
    { value: 'payments', icon: 'payments', viewValue: 'Pagos' },
    { value: 'credit_card', icon: 'credit_card', viewValue: 'Credit Card' },
    { value: 'attach_money', icon: 'attach_money', viewValue: 'Attach Money' },
    {
      value: 'account_balance_wallet',
      icon: 'account_balance_wallet',
      viewValue: 'Acount balance wallet',
    },
  ];
  isSubmitting = false;

  public selectedIcon = {
    value: 'account_balance',
    icon: 'account_balance',
    viewValue: 'Account Balance',
  };

  compareFn(f1: any, f2: any): boolean {
    return f1 && f2 ? f1.value === f2.value : f1 === f2;
  }

  compareFn3(f1: any, f2: any): boolean {
    return f1 && f2 ? f1 === f2 : f1 === f2;
  }

  private paramsCached: DataProjectModalParams;
  private preRequestImages = new Set<File>();
  private sub$ = new Subscription();

  constructor(
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private toastService: ToastService,
    private i18n: TranslateService,
    private budgetService: BudgetService,
    private dialogRef: MatDialogRef<CreateBudgetMethodPaymentComponent>,
    private createBudgetPaymentMethodService: CreateBudgetPaymentMethodService,
    @Inject(MAT_DIALOG_DATA) public modalParams: DataProjectModalParams,
    private amplitude: AmplitudeService
  ) {}

  ngOnInit(): void {
    this.patchParams();
  }

  get params() {
    return this.paramsCached;
  }

  get icon() {
    return this.form.value.icon;
  }

  cancel() {
    this.amplitude.sendEvent({
      event: AmplitudeEvents.settingsAddPayMethodCancel,
    });
    this.dialogRef.close();
  }

  close() {
    this.amplitude.sendEvent({
      event: AmplitudeEvents.settingsAddPayMethodClose,
    });
    this.dialogRef.close();
  }

  changeIcon($event: any) {
    this.form.patchValue({
      icon: $event.icon,
    });
  }

  async submit() {
    this.isSubmitting = true;
    const isValid = this.checkValidators();

    if (!isValid) {
      this.isSubmitting = false;
      return;
    }

    const isAdd = this.params.type === 'ADD';

    if (isAdd) {
      await this.submitCreate();

      this.amplitude.sendEvent({
        event: AmplitudeEvents.settingsAddPayMethodAdd,
      });
    } else {
      await this.submitUpdate();
    }
  }

  private async submitCreate() {
    const value = RemoveEmpty(this.form.value);
    const payment = this.form.value;
    payment.icon = this.selectedIcon.icon;

    this.sub$.add(
      this.budgetService
        .createPaymentMethod(payment)
        .pipe(finalize(() => (this.isSubmitting = false)))
        .subscribe((res) => {
          this.toastService.show({
            text: this.i18n.instant('budgets.settings.successCreated'),
            type: 'success',
            msDuration: 4000,
          });
          this.dialogRef.close(res);
        })
    );
  }

  private async submitUpdate() {
    const value = this.form.value;
    //  value.icon = value.icon.value;

    this.sub$.add(
      this.budgetService
        .updateOnePaymentMethod(this.params.data?._id, {
          ...value,
        })
        .pipe(finalize(() => (this.isSubmitting = false)))
        .subscribe((res) => {
          this.toastService.show({
            text: this.i18n.instant('budgets.settings.successEdit'),
            type: 'success',
            msDuration: 4000,
          });
          this.dialogRef.close(res);
          this.draw();
        })
    );
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);

    return this.form.valid;
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  private patchParams() {
    this.paramsCached = this.modalParams;

    if (this.params.type === 'ADD') {
      this.selectedIcon = this.icons.find(
        (icon) => icon.value == 'account_balance'
      );
    } else {
      this.selectedIcon = this.icons.find(
        (icon) => icon.value == this.params.data.icon
      );
    }

    if (this.params.type === 'EDIT') {
      this.form.patchValue({
        ...this.params.data,
      });
    }
  }
}
