import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmationModalComponent, ConfirmationOutput } from './confirmation-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationModalService {
  private refreshListSource = new Subject<unknown>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(config: MatDialogConfig = new MatDialogConfig()) {
    config.panelClass = 'confirmation-modal',
    config.minWidth = 'auto';
    config.minHeight = 'auto';
    config.height = 'auto';
    config.width = '444px';

    const dialogRef = this.dialog.open(ConfirmationModalComponent, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
        this.emitRefresh(res);
      })
    );
  }

  emitRefresh(data: ConfirmationOutput) {
    this.refreshListSource.next(data);
  }
}
