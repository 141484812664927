import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { AlertService } from '@web-frontend/shared/helpers/alert';

import { filter, map } from 'rxjs/operators';

@Directive({
  selector: '[romaClickRestore]',
})
export class ClickRestoreDirective {
  @Input()
  item: unknown;

  @Input()
  type: string | undefined;

  @Input()
  message = 'general.wantRestore';

  @Input()
  featureUser?: string = null;
  @Output()
  wantRestore = new EventEmitter();

  constructor(
    private alertService: AlertService,
    private i18n: TranslateService
  ) {}

  @HostListener('click', ['$event'])
  click(evt: MouseEvent) {
    if (!this.featureUser) {
      evt.preventDefault();
      evt.stopPropagation();

      if (this.type === 'deal') {
        this.message = `general.wantRestoreDeal`;
      }

      this.alertService
        .sure(this.i18n.instant(this.message), '')
        .pipe(
          map((res) => res.value),
          filter((res) => !!res)
        )
        .subscribe(() => {
          this.wantRestore.next(this.item);
        });
    }
  }

  @HostListener('clicked', ['$event'])
  clicked(evt: MouseEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    if (this.type === 'deal') {
      this.message = `general.wantRestoreDeal`;
    }

    this.alertService
      .sure(this.i18n.instant(this.message), '')
      .pipe(
        map((res) => res.value),
        filter((res) => !!res)
      )
      .subscribe(() => {
        this.wantRestore.next(this.item);
      });
  }
}
