import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { ModalYesOrNoComponent } from './modal-yes-or-no.component';
import { ModalYesOrNo } from './modal-yes-or-no.type';

@Injectable({
  providedIn: 'root',
})
export class LaunchModalYesOrNoService {
  constructor(private dialog: MatDialog) {}

  open(params: ModalYesOrNo.Params) {
    const modalConfig = new MatDialogConfig();
    modalConfig.data = params;

    const dialogRef = this.dialog.open(ModalYesOrNoComponent, modalConfig);

    return dialogRef.afterClosed().pipe(
      map((res) => {
        const modalResponse: ModalYesOrNo.Response = {
          action: res,
        };

        return modalResponse;
      })
    );
  }
}
