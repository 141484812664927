<ng-container *ngIf="showAlert">
  <div class="container-snackbar" [ngClass]="'background-' + type">
    <div class="flex-item">
      <div class="relative-div">
        <div class="absolute-div" [ngClass]="'background-' + type"></div>
        <div class="inner-div">
          <img [src]="getIconPath()" />
        </div>
      </div>
      <div class="text-div" [ngClass]="'text-' + type">
        {{ message | translate}}
        <button *ngIf="textLink" class="link-button" (click)="clickLink()">{{ textLink | translate }}</button>
      </div>
    </div>
  </div>
</ng-container>
