import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  BudgetPreferences,
  IBudgetCommonData,
  PaginateSort,
} from '@tacliatech/types';
import { AssetService, DealService } from '@web-frontend/shared/services';
import { ProductService } from '@web-frontend/shared/services/products';
import { Subscription } from 'rxjs';
import { FilterItems } from '../filter';
import { BudgetTableComponent } from '@web-frontend/shared/components/budget-table/budget-table.component';

@Component({
  selector: 'roma-budget-list',
  templateUrl: './budget-list.component.html',
  styleUrls: ['./budget-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetListComponent implements OnInit {
  @ViewChild(BudgetTableComponent)
  tableComponent: BudgetTableComponent;

  @Input()
  source: IBudgetCommonData[];

  @Input()
  data: MatTableDataSource<any>;

  @Input()
  columns: any;

  @Input()
  showOpenIcon: boolean;

  @Input()
  type: string;

  @Input()
  selectedItem = null;

  @Input()
  totalPages: any[];

  @Input()
  amplitudePrefix = '';

  @Input() settings: BudgetPreferences = null;

  @Output()
  editBudget = new EventEmitter<unknown>();

  @Output()
  deleteBudget = new EventEmitter<unknown>();

  @Output()
  previewBudget = new EventEmitter<unknown>();

  @Output()
  toProformBudget = new EventEmitter<unknown>();

  @Output()
  toBillBudget = new EventEmitter<unknown>();

  @Output()
  openNewTab = new EventEmitter<unknown>();

  @Output()
  checkRowChanges = new EventEmitter<unknown>();

  @Output()
  columnsDisplayedChanges = new EventEmitter<Array<string>>();

  @Output()
  totalSelected = new EventEmitter<Array<number>>();

  @Output()
  detectSortChange = new EventEmitter<unknown>();

  sourceMap = new Map<string, any>();

  private sub$ = new Subscription();

  isDataLoaded = false;
  isLoading = false;
  isLoadingPaginate = false;

  authors: FilterItems = [];
  owners: FilterItems = [];
  assets: FilterItems = [];

  private searchParams = {
    limit: 50,
    page: 1,
    sort: PaginateSort.ASC,
    applyPaginate: true,
    keyword: null,
    owner: null,
    author: null,
    sortOptions: null,
    deal: null,
    takeNotified: null,
    takeToday: null,
    'authors[]': [],
    'assets[]': [],
  };

  constructor(
    private dealService: DealService,
    private changeDetectorRef: ChangeDetectorRef,
    public productService: ProductService,
    private router: Router,
    private assetService: AssetService
  ) {}

  ngOnInit(): void {}

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  hasQuerySearch() {
    return (
      this.searchParams.author ||
      this.searchParams.owner ||
      this.searchParams.keyword
    );
  }

  edit(budget: IBudgetCommonData) {
    this.editBudget.next(budget);
  }

  delete(budget: IBudgetCommonData) {
    this.deleteBudget.next(budget);
  }

  preview(budget: IBudgetCommonData) {
    this.previewBudget.next(budget);
  }

  toProform(budget: IBudgetCommonData) {
    this.toProformBudget.next(budget);
  }

  toBill(budget: IBudgetCommonData) {
    this.toBillBudget.next(budget);
  }

  eventCheckRowChanges($event) {
    this.checkRowChanges.emit($event);
  }

  columnsChange(event: Array<string>) {
    this.columnsDisplayedChanges.emit(event);
  }

  sortChange(event: { active: PaginateSort; direction: string }) {
    this.detectSortChange.emit(event);
  }

  clear() {
    this.tableComponent.clearChecks();
  }

  async toNewTab(id) {
    this.openNewTab.next(id);
  }

  totalSelectedChanges($event: Array<number>) {
    this.totalSelected.emit($event);
  }
}
