/**
 * Genera Expresiones regulares para escapar los caracteres acentuados en las palabras de los
 *
 * 1. Cómo usar:

 ```js

  import { toRegex, toString } from 'diacritic-regex';

  toRegex()('résumé') // => /r[eEÉéÈèÊêëË]s[úùÚÙüÜuU]m[eEÉéÈèÊêëË]/i;
  toRegex({flags: 'mu'})('résumé') // => /r[eEÉéÈèÊêëË]s[úùÚÙüÜuU]m[eEÉéÈèÊêëË]/mu;
  toRegex({
    flags: '',
    mappings: {
      'e': 'eéÉ'
    }
  })('résumé') // => /r[eéÉ]s[úùÚÙüÜuU]m[eéÉ]/;

  toString({
    mappings: {
        '*': ['\\S+'] // literals, won't try to wrap in []'s,
        'u': ['u']
    }
  })('résumé*') // => 'r[eEÉéÈèÊêëË]sum[eEÉéÈèÊêëË]\S+'
  ```

 *
 * @class AccentRegexp
 */
export class AccentRegexp {
  mappings = {
    a: String.fromCharCode(
      65,
      97,
      192,
      224,
      193,
      225,
      194,
      226,
      195,
      227,
      196,
      228
    ),
    e: String.fromCharCode(69, 101, 200, 232, 201, 233, 202, 234, 203, 235),
    i: String.fromCharCode(73, 105, 204, 236, 205, 237, 206, 238, 207, 239),
    o: String.fromCharCode(
      79,
      111,
      210,
      242,
      211,
      243,
      212,
      244,
      213,
      245,
      214,
      246
    ),
    n: String.fromCharCode(78, 110, 209, 241),
    u: String.fromCharCode(85, 117, 217, 249, 218, 250, 219, 251, 220, 252),
    c: String.fromCharCode(67, 99, 199, 231),
    y: String.fromCharCode(89, 121, 221, 253, 159, 255),
  };

  mergeMappings(innerMappings) {
    const base = {};

    for (const mapping in this.mappings) {
      base[mapping] = this.mappings[mapping];
    }

    if (innerMappings) {
      for (const mapping in innerMappings) {
        base[mapping] = innerMappings[mapping];
      }
    }
    return base;
  }

  replacer(input, mappings) {
    return input
      .split('')
      .map((letter) => {
        for (const mapping in mappings) {
          if (
            mapping &&
            mapping !== mappings[mapping] &&
            (mapping === letter || mappings[mapping].indexOf(letter) !== -1)
          ) {
            letter = Array.isArray(mappings[mapping])
              ? mappings[mapping].join('')
              : '[' + mappings[mapping] + ']';
            break;
          }
        }
        return letter;
      })
      .join('');
  }

  toRegex(options?) {
    if (options === void 0) {
      options = {};
    }
    const innerMappings = this.mergeMappings(
      typeof options.mappings === 'object' ? options.mappings : null
    );
    return (input) => {
      return new RegExp(
        this.replacer(input, innerMappings),
        typeof options.flags === 'string' ? options.flags : 'i'
      );
    };
  }

  toString(options) {
    if (options === void 0) {
      options = {};
    }
    const innerMappings = this.mergeMappings(
      typeof options.mappings === 'object' ? options.mappings : null
    );
    return (input) => {
      return this.replacer(input, innerMappings);
    };
  }
}
