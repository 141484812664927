<roma-modal-template
  size="md"
  [showDefaultButtons]="false"
  [showHeader]="false"
  [responsiveFull]="false"
  (cancel)="close()"
>
  <div class="row text-center">
    <qrcode
      #qrElement
      *ngIf="url"
      [qrdata]="url"
      [width]="size"
      id="qrElement"
      [title]="'Haz click para descargar sdfsdf'"
      [errorCorrectionLevel]="'H'"
      [elementType]="'img'"
      (click)="download(qrElement)"
    >
    </qrcode>
    <div class="custom-content--btns text-center">
      <button
        class="custom-btn-save qrcode-download"
        (click)="downloadFile($event)"
      >
        {{ 'qrcode.download' | translate }}
      </button>
    </div>
  </div>
</roma-modal-template>
