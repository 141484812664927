import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'roma-select-deal-confirm-modal',
  templateUrl: './select-deal-confirm-modal.component.html',
  styleUrls: ['./select-deal-confirm-modal.component.scss'],
})
export class SelectDealConfirmModalComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<SelectDealConfirmModalComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close({ confirm: false });
  }

  submit() {
    this.dialogRef.close({ confirm: true });
  }
}
