<!-- TOTAL AREA -->
<div class="row col-12 justify-content-end mt-10">
  <div
    [ngClass]="
      show
        ? 'total-row col-12 fixed-bottom show'
        : 'total-row col-12 fixed-bottom hide'
    "
  >
    <!--   <div class="d-flex justify-content-end total-footer-contaner"> -->
    <div class="container total-footer-contaner">
      <div class="row justify-content-end hub hubspot-space">
        <!-- req-eq -->
        <!-- col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 -->
        <div class="col-4 col-sm-6 col-md-4 col-lg-2 col-xl-2" *ngIf="reqEq">
          <div class="row text-end">
            <label class="lbl-totals" for="name">
              {{ formatCustomNumber(reqEq) }}{{ currencySimbol }}
            </label>
          </div>
          <div class="row text-end mb-7 lbl-totals">
            <label for="name">
              <ng-container *ngIf="mobile; else noMobile"> Rec. </ng-container>
              <ng-template #noMobile>
                {{ 'budgets.settings.create.form.lblrec-eq' | translate }}
              </ng-template>
            </label>
          </div>
        </div>

        <!-- retention -->
        <div
          class="col-4 col-sm-6 col-md-4 col-lg-2 col-xl-2"
          *ngIf="retention"
        >
          <div class="row text-end">
            <label class="lbl-totals" for="name">
              -{{ formatCustomNumber(retention) }}{{ currencySimbol }}
            </label>
          </div>
          <div class="row text-end mb-7 lbl-totals">
            <label for="name">
              {{ 'budgets.settings.create.form.lbretention' | translate }}
            </label>
          </div>
        </div>

        <!-- discount -->
        <div
          class="col-4 col-sm-6 col-md-4 col-lg-2 col-xl-2"
          *ngIf="discount > 0"
        >
          <div class="row text-end">
            <label class="lbl-totals" for="name">
              - {{ formatCustomNumber(discount) }}{{ currencySimbol }}
            </label>
          </div>
          <div class="row text-end mb-7 lbl-totals">
            <label for="name"> {{ 'budget.totalDiscount' | translate }} </label>
          </div>
        </div>

        <!-- subtotal -->
        <div class="col-4 col-sm-6 col-md-4 col-lg-2 col-xl-2">
          <div class="row text-end">
            <label class="lbl-totals" for="name">
              {{ formatCustomNumber(subtotal) }}{{ currencySimbol }}
            </label>
          </div>
          <div class="row text-end mb-7 lbl-totals">
            <label for="name">
              {{ 'budgets.settings.create.form.lblsubtotal' | translate }}
            </label>
          </div>
        </div>

        <!-- iva -->
        <div class="col-4 col-sm-6 col-md-4 col-lg-2 col-xl-2">
          <div class="row text-end">
            <label class="lbl-totals" for="name">
              {{ formatCustomNumber(iva) }}{{ currencySimbol }}
            </label>
          </div>
          <div class="row text-end mb-7 lbl-totals">
            <label for="name">
              {{ 'budgets.settings.create.form.lbiva' | translate }}
            </label>
          </div>
        </div>

        <!--  total -->
        <div class="col-4 col-sm-6 col-md-4 col-lg-2 col-xl-2">
          <div class="row text-end">
            <label class="lbl-totals" for="name">
              {{ formatCustomNumber(total) }}{{ currencySimbol }}
            </label>
          </div>
          <div class="row text-end mb-7 lbl-totals">
            <label for="name">
              {{ 'budgets.settings.create.form.lbtotal' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
