import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { IDeal } from '@tacliatech/types';

import { DealCancelComponent } from '@web-frontend/shared/components/deal-cancel/deal-cancel.component';

@Directive({
  selector: '[romaClickDeleteDeal]',
})
export class ClickDeleteDealDirective {
  @Input()
  item: unknown;

  @Input()
  type = '';

  @Input()
  featureUser?: string = null;
  @Output()
  wantDeleteDeal = new EventEmitter();

  constructor(
    private modalService: MatDialog,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  @HostListener('click', ['$event'])
  async click(evt: MouseEvent) {
    if (!this.featureUser) {
      this.draw();
      evt.preventDefault();
      evt.stopPropagation();
      this.draw();

      const modalRef = this.modalService.open(DealCancelComponent);
      (modalRef.componentInstance as DealCancelComponent).data = this
        .item as IDeal;
      modalRef.afterClosed().subscribe((res) => {
        if (res) {
          this.item = res;
          this.wantDeleteDeal.next(this.item);
        }
      });
    }
  }

  @HostListener('clicked', ['$event'])
  async clicked(evt: MouseEvent) {
    this.draw();
    evt.preventDefault();
    evt.stopPropagation();
    this.draw();

    const modalRef = this.modalService.open(DealCancelComponent);
    (modalRef.componentInstance as DealCancelComponent).data = this
      .item as IDeal;
    modalRef.afterClosed().subscribe((res) => {
      if (res) {
        this.item = res;
        this.wantDeleteDeal.next(this.item);
      }
    });
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }
}
