import { IUser } from '@tacliatech/types';

export class User implements IUser {
  _id: string;
  name: string;
  email: string;
  phone: string;
  role: any;
  created_at: Date;
  img: string;
  profile_id: any;
  mode: 'vendor' | 'customer';
  deleted?: boolean;
  company: any;
  bannerDemo?: Date;

  static fromArray(attributes: IUser[]) {
    return attributes.map((attribute) => new User(attribute));
  }

  static Roles() {
    return [UserRole.Admin, UserRole.User];
  }

  static Types() {
    return ['STAFF', 'CUSTOMER', 'VENDOR'];
  }

  constructor(attribute: IUser) {
    Object.assign(this, attribute);
  }
}

export enum UserRole {
  Admin = 'ADMIN_ROLE',
  User = 'USER_ROLE',
}
