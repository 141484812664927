import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';

import { ClickDeleteDealDirective } from './click-delete-deal.directive';
import { DealCancelModule } from '../../components/deal-cancel/deal-cancel.module';

@NgModule({
  declarations: [ClickDeleteDealDirective],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    DealCancelModule,
    MatDialogModule,
  ],
  exports: [ClickDeleteDealDirective],
})
export class ClickDeleteDealModule {}
