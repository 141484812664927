<div
  class="container-header"
  romaProSelect
  class="wrapper-pro-feature-user"
  [featureUser]="featureUser"
>
  <mat-card class="">
    <div class="header-items" [formGroup]="form">
      <div class="row">
        <!-- BEGIN 3 CONTROLS SMALL DEVICES -->

        <!-- NUMDOC -->
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
          <label class="lbl-totals" for="name" style="width: 150px">
            {{ numberDocPlaceholder | translate }}
          </label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1"
                >{{ form.get('prefix').value }} -
              </span>
            </div>
            <input
              mask="0*"
              class="form-control doc-number"
              type="text"
              required
              formControlName="numberDoc"
              [placeholder]="numberDocPlaceholder | translate"
              [ngClass]="{ 'is-invalid': fieldNumber.displayError || false }"
              (change)="numDocChanged()"
            />
            <roma-field-error-display
              #fieldNumber
              fieldName="numberDoc"
              [errorMsg]="'budgets.settings.create.form.numberDocError'"
              [form]="form"
            >
            </roma-field-error-display>
          </div>

          <!--  <label class="lbl-totals" for="name">
            {{ numberDocPlaceholder | translate }}
          </label>
          <input
            #inputFilter
            class="form-control"
            type="text"
            required
            formControlName="numberDoc"
            (change)="numDocChanged()"
            [placeholder]="numberDocPlaceholder | translate"
            [ngClass]="{ 'is-invalid': fieldNumber.displayError || false }"
          />
 -->
          <!--   <roma-field-error-display
            #fieldNumber
            fieldName="numberDoc"
            [errorMsg]="'budgets.settings.create.form.numberDocError'"
            [form]="form"
          >
          </roma-field-error-display> -->
        </div>

        <div class="mb-10 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
          <label
            class="lbl-totals"
            for="name"
            [ngClass]="{ 'is-disabled': !form.valid }"
          >
            {{ statusBudgetsPlaceholder | translate }}
          </label>
          <ng-select
            appendTo="body"
            bindLabel="name"
            bindValue="value"
            [readonly]="isDisabled('', false)"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="statusSelected"
            [placeholder]="statusBudgetsPlaceholder | translate"
            [notFoundText]="'hiring.form.placeholder_notfound' | translate"
            [items]="statusBudgetList"
            (change)="onChangeStatusbudget($event)"
          >
          </ng-select>
        </div>

        <div
          *ngIf="(idActiveModules$ | async)?.includes(idModuleRef.Finals)"
          class="mb-10 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 wrapper-pro-feature-user"
          romaProSelect
          [featureUser]="featureRefUser.Final.show"
        >
          <label
            class="lbl-totals"
            for="name"
            [ngClass]="{ 'is-disabled': !form.valid }"
          >
            {{ contactPlaceholder | translate }}
          </label>
          <rm-select
            [isClearable]="true"
            [placeholderText]="'general.select' | translate"
            [items]="contacts"
            [isLoading]="isLoadingContacts"
            [disabled]="isLoadingContacts"
            [activeItems]="selectedContact"
            [isAbsolute]="true"
            [hasError]="onSubmit && form.controls['contact'].value === ''"
            inputHeight="44px"
            type="users"
            [addButtonFeatureUser]="featureRefUser.Final.create"
            [addButtonText]="'general.addFinal'"
            (changeItems)="changeContact($event)"
            (addElement)="addFinals()"
          >
          </rm-select>
          <!-- errores -->
          <ng-container
            *ngIf="
              form.get('contact_id').invalid && form.get('contact_id').touched
            "
            class="is-invalid"
          >
            <div
              class="label-invalid"
              *ngIf="!!form.get('contact_id').hasError('contact_id')"
            >
              {{ 'asset.create.form.cityError' | translate }}
            </div>
          </ng-container>
        </div>

        <div
          *ngIf="(idActiveModules$ | async)?.includes(idModuleRef.Deals)"
          class="mb-10 col-12 col-sm-4 col-md-4 col-lg-4 col-xl-2 wrapper-pro-feature-user"
          romaProSelect
          [featureUser]="{
            type: 'every',
            features: [featureRefUser.Deal.show]
          }"
        >
          <label
            class="lbl-totals"
            for="name"
            [ngClass]="{ 'is-disabled': !form.valid }"
          >
            {{ 'budgets.settings.create.form.dealsPlaceholder' | translate }}
          </label>
          <rm-select
            [isClearable]="true"
            [placeholderText]="'general.select' | translate"
            [items]="deals"
            [isLoading]="isLoadingDeals"
            [disabled]="isLoadingDeals"
            [activeItems]="selectedDeal"
            [isAbsolute]="true"
            [hasError]="onSubmit && form.controls['idDeal'].value === ''"
            inputHeight="44px"
            [addButtonText]="'general.addNewDeal'"
            (changeItems)="onChangeDeals($event)"
          >
          </rm-select>
        </div>
        <!-- END 3 CONTROLS  //[readonly]="disabledSelect"-->

        <!-- BEGIN 3 CONTROLS -->

        <!-- DATE -->

        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-2">
          <label
            class="lbl-totals"
            for="name"
            [ngClass]="{ 'is-disabled': !form.valid }"
          >
            {{ 'table.colum.date' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              [matDatepicker]="pickerDate"
              formControlName="date"
              (dateChange)="dateChanged()"
              [readonly]="isDisabled('date', true)"
              (click)="pickerDate.open()"
              onkeydown="return false;"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerDate></mat-datepicker>
            <div
              class="calendar-clean-icon"
              matSuffix
              style="height: 35px"
              (click)="date = ''; form.get('date').setValue(''); dateChanged()"
              *ngIf="date"
            >
              <mat-icon
                matDatepickerToggleIcon
                style="height: 36px; position: relative"
              >
                <img
                  class="calendar-icon"
                  src="assets/icons/gl_close-input.svg"
                />
              </mat-icon>
            </div>
          </mat-form-field>
        </div>

        <!-- DUEDATE -->
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-2">
          <label
            class="lbl-totals"
            for="name"
            [ngClass]="{ 'is-disabled': !form.valid }"
          >
            {{ 'table.colum.dueDate' | translate }}
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              [matDatepicker]="pickerDueDate"
              formControlName="dueDate"
              (dateChange)="dueDateChanged()"
              [readonly]="isDisabled('dueDate', true)"
              (click)="pickerDueDate.open()"
              onkeydown="return false;"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerDueDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerDueDate></mat-datepicker>
            <div
              class="calendar-clean-icon"
              matSuffix
              style="height: 35px"
              (click)="
                dueDate = ''; form.get('dueDate').setValue(''); dueDateChanged()
              "
              *ngIf="dueDate"
            >
              <mat-icon
                matDatepickerToggleIcon
                style="height: 36px; position: relative"
              >
                <img
                  class="calendar-icon"
                  src="assets/icons/gl_close-input.svg"
                />
              </mat-icon>
            </div>
          </mat-form-field>
        </div>
        <!-- END 3 CONTROLS -->
      </div>
    </div>
  </mat-card>
</div>
