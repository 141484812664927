import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalAddComponent } from '../add-custom-property/modal-add/modal-add.component';

@Component({
  selector: 'roma-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ModalAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  @Input() tipoModal = 'info' || 'pipeline' || 'info2';

  ngOnInit(): void {
    this.tipoModal = this.data.tipoModal;
  }

  cancelEvt() {
    this.dialogRef.close();
  }
}
