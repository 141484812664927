import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { GenericModalService } from '@web-frontend/shared/services';
import { ContractFormModalComponent } from '../contract-form-modal';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import AmplitudeEvents from 'src/types/amplitude.enum';

@Component({
  selector: 'roma-time-display',
  templateUrl: './time-display.component.html',
  styleUrls: ['./time-display.component.scss'],
})
export class TimeDisplayComponent implements OnInit {
  @Input() clockHours: number | null = null;
  @Input() clockHoursLabel = 'Total';
  @Input() icon = 'clock-fill';
  @Input() clickable = false;
  @Input() fullWidthMobile = false;
  @Input() totalHours: number | null = null;
  @Output() updateMetrics = new EventEmitter<void>();
  @Input() userId?: string | null = null;
  disabled = false;
  hourFormatted = '--:--';
  totalHoursFormatted: number | null = null;
  iconDialog = 'assets/icons/gl_information-fill-gray.svg';

  constructor(
    private readonly genericModalService: GenericModalService,
    private readonly cd: ChangeDetectorRef,
    private readonly analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.disabled = !this.clockHours;
    this.formatClockHours();
    this.formatTotalHours();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clockHours || changes.totalHours) {
      this.formatClockHours();
    }
  }

  formatClockHours() {
    if (this.clockHours) {
      const totalMinutes = this.clockHours / 60000;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = Math.floor(totalMinutes % 60);
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');

      this.hourFormatted = `${formattedHours}:${formattedMinutes}`;
    } else if (this.clockHours === 0) {
      this.hourFormatted = '00:00';
    } else {
      this.hourFormatted = '--:--';
    }
    this.draw();
  }

  formatTotalHours() {
    if (this.totalHours) {
      this.totalHoursFormatted = parseFloat(
        (this.totalHours / 3600000).toFixed(2)
      );
    } else {
      this.totalHoursFormatted = null;
    }
  }

  openModal(): void {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.trackingTime_schedule_missing,
    });
    this.genericModalService
      .open(ContractFormModalComponent, {
        data: {
          userId: this.userId,
        },
      })
      .subscribe((result: boolean) => {
        if (result) {
          this.analyticsService.trackEvent({
            sources: ['amplitude'],
            eventName: AmplitudeEvents.trackingTime_schedule_missing_add,
          });
          this.updateMetrics.emit();
        }
      });
  }

  get isInvalidTime(): boolean {
    return this.hourFormatted === '--:--';
  }

  draw() {
    this.cd.detectChanges();
  }
}
