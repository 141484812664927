import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Stripe from 'stripe';

@Injectable({
  providedIn: 'root',
})
export class CreateRefundService {
  constructor(private http: HttpClient) {}

  createRefund(ticketId?: string) {
    return this.http.post<Stripe.Response<Stripe.Refund>>(
      `:API_URL/payments/refunds/${ticketId}`,
      {}
    );
  }
}
