<roma-modal-template
  [showSuccessBtn]="false"
  [showCancelBtn]="true"
  [textCancel]="'Cerrar'"
  (success)="close()"
  (cancel)="close()"
>
  <img
    src="assets/images/qr-legend.png"
    width="350"
    height="auto"
    alt="qr legend image"
    class="qr-legend-image"
  />

  <div class="qr-legend-title">
    {{ 'qrLegend.title' | translate }}
  </div>

  <div class="qr-legend-description">
    {{ 'qrLegend.description' | translate }}
  </div>
</roma-modal-template>
