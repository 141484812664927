<div class="pipeline-column-header">
  <roma-rm-badge
    [statusName]="
      status?.translate ? (status?.translate | translate) : status?.name
    "
    [color]="status?.color"
  ></roma-rm-badge>
</div>
<div class="pipeline-column-body">
  <roma-loading *ngIf="isLoading" size="50"></roma-loading>

  <ng-container *ngIf="!isLoading">
    <roma-deal-pipeline-card *ngFor="let deal of deals" cdkDrag [deal]="deal">
    </roma-deal-pipeline-card>
  </ng-container>
</div>
