<input type="text" style="opacity: 0; width: 0px; height: 0" />
<form [formGroup]="form">
  <div class="half-modal-wrapper">
    <div class="modal-container">
      <div class="actions-header">
        <!--
          BTN en doble
        <!--
          BTN en doble
        <button
          type="button"
          class="square-button done-button"
          [ngClass]="{ active: markAsDone }"
          *ngIf="form.get('deleted').value"
          (click)="restore()"
        >
          <img src="assets/icons/restore.svg" />
          {{ 'activity.buttonRestore' | translate }}
        </button>

        -->
        <div class="close-container">
          <img
            class="close"
            [src]="'assets/icons/gl_close.svg'"
            width="32px"
            height="32px"
            *ngIf="haveChanges"
            romaClickDelete
            [message]="'general.withoutSave'"
            [buttonDelete]="'general.buttonExit'"
            (wantDelete)="close()"
          />
          <img
            class="close"
            [src]="'assets/icons/gl_close.svg'"
            width="32px"
            height="32px"
            *ngIf="!haveChanges"
            (click)="close()"
          />
        </div>
        <button
          type="button"
          *ngIf="type === 'EDIT'"
          class="rounded-button share-button"
          style="float: right; margin-right: 16px"
          (click)="shareLink()"
        >
          <img src="assets/icons/gl_link-black.svg" />
          {{ 'general.shareLink' | translate }}
        </button>

        <button
          type="button"
          class="square-button done-button"
          [ngClass]="{ active: markAsDone }"
          *ngIf="!form.get('deleted').value"
          romaProFeature
          [featureUserHide]="featureUserScreen"
          (click)="changeMarkAsDone(!markAsDone)"
        >
          <img
            [src]="
              !markAsDone
                ? 'assets/icons/gl_check-black.svg'
                : 'assets/icons/gl_check-fill.svg'
            "
          />
          {{
            !markAsDone
              ? ('activity.markAsDone' | translate)
              : ('status.finished2' | translate)
          }}
        </button>
        <button
          type="button"
          class="square-button done-button"
          [ngClass]="{ active: markAsDone }"
          *ngIf="form.get('deleted').value"
          (click)="restore()"
        >
          <img src="assets/icons/restore.svg" />
          {{ 'activity.buttonRestore' | translate }}
        </button>
        <div
          *ngIf="type === 'EDIT' && (resize$ | async)?.payload?.mobile"
          class="icon-options-dots"
        >
          <img
            src="assets/icons/kanban/menu.svg"
            #menuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="menu"
          />
        </div>
        <mat-menu #menu="matMenu" class="menu-mobile-activity-edit">
          <div class="list-wrapper">
            <div class="list-item" (click)="shareLink()">
              <img src="assets/icons/gl_link-black.svg" />
              {{ 'general.shareLink' | translate }}
            </div>
          </div>
        </mat-menu>
      </div>
      <div #leftCol class="left-col">
        <!-- task title -->
        <div
          romaProSelect
          class="wrapper-pro-feature-user"
          [featureUser]="featureUserScreen"
        >
          <input
            type="text"
            class="title-input"
            id="title"
            aria-describedby="titleHelp"
            formControlName="title"
            [placeholder]="'activity.create.form.titlePlaceholder' | translate"
            [ngClass]="{ 'is-invalid': fieldTitle.displayError || false }"
          />
          <roma-field-error-display
            #fieldTitle
            fieldName="title"
            [errorMsg]="'activity.create.form.titleError'"
            [form]="form"
          >
          </roma-field-error-display>
        </div>
        <!-- task description -->
        <div
          romaProSelect
          class="wrapper-pro-feature-user"
          [featureUser]="featureUserScreen"
        >
          <textarea
            type="text"
            class="description-input"
            formControlName="description"
            [placeholder]="
              'activity.create.form.descriptionPlaceholder' | translate
            "
            maxLength="1000"
          ></textarea>
        </div>
        <!-- status -->
        <div
          romaProSelect
          class="wrapper-pro-feature-user"
          [featureUser]="featureUserScreen"
        >
          <div class="row">
            <div class="col-5">
              <label class="field-label">
                <img src="assets/icons/gl_kanban-outline.svg" />
                {{ 'general.status' | translate | uppercase }}
              </label>
            </div>
            <div class="col-7 container__select">
              <rm-status-select
                (click)="onClickSelect('status')"
                [items]="statusNames"
                [activeItems]="selectedStatus"
                [placeholderText]="selectedStatusTitle"
                [titleText]="'general.status' | translate"
                [isLoading]="statusLoading"
                mode="SINGLE"
                inputHeight="36px"
                listWidth="260px"
                (changeItems)="changeSelectItem($event, 'idStatus')"
              ></rm-status-select>
              <input type="hidden" formControlName="idStatus" />
            </div>
          </div>
        </div>
        <!-- date -->
        <div
          romaProSelect
          class="wrapper-pro-feature-user"
          [featureUser]="featureUserScreen"
        >
          <div class="row">
            <div class="col-5">
              <label class="field-label">
                <img src="assets/icons/gl_calendar-outline.svg" />
                {{ 'activity.create.form.dateTime' | translate | uppercase }}
              </label>
            </div>
            <div class="col-7">
              <button
                type="button"
                *ngIf="!showDateInput"
                class="add-field-btn"
                (click)="showDateInput = true"
              >
                {{ 'general.add2' | translate }}
                <img src="assets/icons/gl_plus.svg" style="margin-top: -5px" />
              </button>
              <roma-selector-recurrence
                *ngIf="showDateInput"
                [showHoursInput]="false"
                [dateStorage]="inputDateTime"
                (changeRecurrence)="changeDatetime($event)"
                [showSelectorRecurrence]="false"
              >
              </roma-selector-recurrence>
              <input #dateTimeInput type="hidden" name="dateTime" />
            </div>
          </div>
        </div>

        <!-- hours -->
        <ng-container *ngIf="showDateInput" class="form-group">
          <div
            romaProSelect
            class="row wrapper-pro-feature-user"
            [featureUser]="featureUserScreen"
          >
            <div class="col-5">
              <label class="field-label">
                <img src="assets/icons/gl_control-horario.svg" />
                {{ 'general.hour' | translate | uppercase }}
              </label>
            </div>
            <div class="col-7">
              <input
                formControlName="hour"
                class="form-control"
                #timeDate
                id="timeDate"
                [placeholder]="
                  'clockHour.create.form.startDatePlaceholder' | translate
                "
                matInput
                mask="Hh:m0"
                [showMaskTyped]="true"
                [dropSpecialCharacters]="false"
                [(ngModel)]="selectedHour"
                (change)="hourChange(timeDate.value)"
                (blur)="hourChange(timeDate.value)"
                style="width: 100px"
              />
            </div>
          </div>
        </ng-container>
        <!-- owner -->
        <div
          romaProSelect
          class="wrapper-pro-feature-user"
          [featureUser]="featureUserScreen"
        >
          <div class="row">
            <div class="col-5">
              <label class="field-label">
                <img src="assets/icons/gl_contacto.svg" />
                {{ 'activity.create.form.owner' | translate | uppercase }}
              </label>
            </div>
            <div class="col-7">
              <rm-responsive-select
                class="alt-dropdown"
                [activeItems]="selectedOwner"
                [isLoading]="allUsersLoading"
                [placeholderText]="
                  'activity.create.form.searchOwner' | translate
                "
                [items]="allUsersSelectItems"
                [primaryDesign]="false"
                mode="MULTIPLE"
                inputHeight="36px"
                listWidth="260px"
                (click)="onClickSelect('owners')"
                (changeItems)="changeSelectItem($event, 'owner')"
                [addButtonText]="'general.addUser'"
                [addButtonFeatureUser]="featureRefUser.User.create"
                (addElement)="addOwner()"
              >
              </rm-responsive-select>
              <input type="hidden" formControlName="owner" />
              <ng-container *ngIf="controls?.owner?.errors">
                <div
                  *ngIf="controls?.owner?.errors?.required"
                  class="invalid-feedback"
                >
                  {{ 'activity.create.form.ownerError' | translate }}
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <!-- idSupervisor -->
        <div
          romaProSelect
          class="wrapper-pro-feature-user"
          [featureUser]="featureUserScreen"
        >
          <div class="row">
            <div class="col-5">
              <label class="field-label">
                <img src="assets/icons/gl_user-outline.svg" />
                {{ 'activity.create.form.supervisor' | translate | uppercase }}
              </label>
            </div>
            <div class="col-7">
              <rm-responsive-select
                class="alt-dropdown"
                [activeItems]="selectedSupervisor"
                [isLoading]="allUsersLoading"
                [placeholderText]="
                  'activity.create.form.supervisor' | translate
                "
                [items]="allUsersSelectItems"
                [primaryDesign]="false"
                mode="MULTIPLE"
                inputHeight="36px"
                listWidth="260px"
                (click)="onClickSelect('owners')"
                (changeItems)="changeSelectItem($event, 'idSupervisor')"
                [addButtonText]="'general.addUser'"
                [addButtonFeatureUser]="featureRefUser.User.create"
                (addElement)="addSupervisor()"
              >
              </rm-responsive-select>
              <input type="hidden" formControlName="idSupervisor" />
              <ng-container *ngIf="controls?.idSupervisor?.errors">
                <div
                  *ngIf="controls?.idSupervisor?.errors?.required"
                  class="invalid-feedback"
                >
                  {{ 'activity.create.form.ownerError' | translate }}
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <!-- idEquipment -->
        <ng-container
          *ngIf="(idActiveModules$ | async).includes(idModuleRef.Equipments)"
          class="form-group"
        >
          <div
            romaProSelect
            class="row wrapper-pro-feature-user"
            [featureUser]="{
              type: 'every',
              features: [featureUserScreen, featureRefUser.Equipment.show]
            }"
          >
            <div class="col-5">
              <label class="field-label">
                <img src="assets/icons/gl_activos.svg" />
                {{ 'activity.create.form.idEquipment' | translate | uppercase }}
              </label>
            </div>
            <div class="col-7">
              <rm-responsive-select
                class="alt-dropdown"
                [activeItems]="selectedEquipment"
                [isLoading]="equipmentsLoading"
                [placeholderText]="
                  'activity.create.form.idEquipment' | translate
                "
                [items]="equipmentsSelectItems"
                [primaryDesign]="false"
                mode="SINGLE"
                inputHeight="36px"
                listWidth="260px"
                (click)="onClickSelect('equipments')"
                (changeItems)="changeSelectItem($event, 'idEquipment')"
              >
              </rm-responsive-select>
              <input type="hidden" formControlName="idEquipment" />
              <ng-container *ngIf="controls?.idEquipment?.errors">
                <div
                  *ngIf="controls?.idEquipment?.errors?.required"
                  class="invalid-feedback"
                >
                  {{ 'activity.create.form.idEquipmentError' | translate }}
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <!-- idInternalVendor -->
        <ng-container
          *ngIf="
            (idActiveModules$ | async).includes(idModuleRef.InternalVendors) &&
            showVendorInput
          "
          class="form-group"
        >
          <div
            romaProSelect
            class="row wrapper-pro-feature-user"
            [featureUser]="{
              type: 'every',
              features: [featureUserScreen, featureRefUser.InternalVendor.show]
            }"
          >
            <div class="col-5">
              <label class="field-label">
                <img src="assets/icons/gl_proveedores.svg" />
                {{ 'hiring.type.vendor' | translate | uppercase }}
              </label>
            </div>
            <div class="col-7">
              <rm-responsive-select
                class="alt-dropdown"
                [activeItems]="selectedInternalVendor"
                [isLoading]="internalVendorLoading"
                [placeholderText]="'hiring.type.vendor' | translate"
                [items]="internalVendorSelectItems"
                [primaryDesign]="false"
                mode="SINGLE"
                inputHeight="36px"
                listWidth="260px"
                (click)="onClickSelect('internalVendors')"
                (changeItems)="changeSelectItem($event, 'idInternalVendor')"
              >
              </rm-responsive-select>
              <input type="hidden" formControlName="idInternalVendor" />
            </div>
          </div>
        </ng-container>
        <!-- idFinal -->
        <ng-container
          *ngIf="(idActiveModules$ | async).includes(idModuleRef.Finals)"
          class="form-group"
        >
          <div
            romaProSelect
            class="row wrapper-pro-feature-user"
            [featureUser]="{
              type: 'every',
              features: [featureUserScreen, featureRefUser.Final.show]
            }"
          >
            <div class="col-5">
              <label class="field-label">
                <img src="assets/icons/gl_user-outline.svg" />
                {{ 'activity.create.form.idFinal' | translate | uppercase }}
              </label>
            </div>
            <div class="col-7">
              <button
                type="button"
                class="add-field-btn"
                *ngIf="!showFinalsSelect && !selectedFinal.length"
                (click)="showFinalsSelect = true"
              >
                {{ 'general.add2' | translate }}
                <img src="assets/icons/gl_plus.svg" style="margin-top: -5px" />
              </button>
              <rm-select
                *ngIf="selectedFinal.length || showFinalsSelect"
                [isClearable]="true"
                [placeholderText]="'activity.create.form.idFinal' | translate"
                [items]="finalsSelectItems"
                [isLoading]="finalsLoading"
                [disabled]="finalsLoading"
                [activeItems]="selectedFinal"
                [isAbsolute]="true"
                [hasError]="
                  controls?.idFinal?.errors?.required &&
                  controls?.idFinal?.touched &&
                  controls?.idFinal?.dirty &&
                  !selectedFinal?.length
                "
                inputHeight="36px"
                type="users"
                [addButtonText]="'general.addFinal'"
                [addButtonFeatureUser]="featureRefUser.Final.create"
                (changeItems)="changeSelectItem($event, 'idFinal')"
                (click)="onClickSelect('finals')"
                (addElement)="addFinals()"
                style="width: 100%"
              >
              </rm-select>

              <input type="hidden" formControlName="idFinal" />
            </div>
          </div>
        </ng-container>
        <!-- deal -->
        <ng-container
          *ngIf="
            (idActiveModules$ | async).includes(idModuleRef.Deals) &&
            showDealsInput
          "
        >
          <div
            romaProSelect
            class="row wrapper-pro-feature-user"
            [featureUser]="{
              type: 'every',
              features: [featureUserScreen, featureRefUser.Deal.show]
            }"
          >
            <div class="col-5">
              <label class="field-label">
                <img src="assets/icons/gl_services.svg" />
                {{ 'activity.create.form.deal' | translate | uppercase }}
              </label>
            </div>
            <div class="col-7">
              <rm-responsive-select
                class="alt-dropdown"
                [activeItems]="selectedDeal"
                [isLoading]="dealsLoading"
                [placeholderText]="'activity.create.form.deal' | translate"
                [items]="dealsSelectItems"
                [primaryDesign]="false"
                mode="SINGLE"
                inputHeight="36px"
                listWidth="260px"
                (changeItems)="
                  changeDeal(mapDealsSelectOutput($event));
                  changeSelectItem($event, 'deal')
                "
                (click)="onClickSelect('deals')"
              >
              </rm-responsive-select>
              <input type="hidden" formControlName="deal" />
              <ng-container *ngIf="controls?.deal?.errors">
                <div
                  *ngIf="controls?.deal?.errors?.required"
                  class="invalid-feedback"
                >
                  {{ 'activity.create.form.dealError' | translate }}
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <!-- idProject -->
        <ng-container
          *ngIf="
            (idActiveModules$ | async).includes(idModuleRef.Projects) &&
            showProjectInput
          "
          class="form-group"
        >
          <div
            romaProSelect
            class="row wrapper-pro-feature-user"
            [featureUser]="{
              type: 'every',
              features: [featureUserScreen, featureRefUser.Project.show]
            }"
          >
            <div class="col-5">
              <label class="field-label">
                <img src="assets/icons/gl_proyectos.svg" />
                {{ 'activity.create.form.idProject' | translate | uppercase }}
              </label>
            </div>
            <div class="col-7">
              <rm-responsive-select
                class="alt-dropdown"
                [activeItems]="selectedProject"
                [isLoading]="projectsLoading"
                [placeholderText]="'activity.create.form.idProject' | translate"
                [items]="projectsSelectItems"
                [primaryDesign]="false"
                mode="SINGLE"
                inputHeight="36px"
                listWidth="260px"
                (click)="onClickSelect('projects')"
                (changeItems)="changeSelectItem($event, 'idProject')"
              >
              </rm-responsive-select>
              <input type="hidden" formControlName="idProject" />
              <ng-container *ngIf="controls?.idProject?.errors">
                <div
                  *ngIf="controls?.idProject?.errors?.required"
                  class="invalid-feedback"
                >
                  {{ 'activity.create.form.idProjectError' | translate }}
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <!-- asset -->
        <ng-container
          *ngIf="
            (idActiveModules$ | async).includes(idModuleRef.Assets) &&
            showAssetInput
          "
          class="form-group"
        >
          <div
            romaProSelect
            class="row wrapper-pro-feature-user"
            [featureUser]="{
              type: 'every',
              features: [featureUserScreen, featureRefUser.Address.show]
            }"
          >
            <div class="col-5">
              <label class="field-label">
                <img src="assets/icons/gl_direcciones.svg" />
                {{ 'activity.create.form.asset' | translate | uppercase }}
              </label>
            </div>
            <div class="col-7">
              <rm-responsive-select
                class="alt-dropdown"
                [activeItems]="selectedAsset"
                [isLoading]="assetsLoading"
                [placeholderText]="'activity.create.form.asset' | translate"
                [items]="assetSelectItems"
                [primaryDesign]="false"
                mode="SINGLE"
                inputHeight="36px"
                listWidth="260px"
                (click)="onClickSelect('assets')"
                (changeItems)="changeSelectItem($event, 'asset')"
              >
              </rm-responsive-select>
              <input type="hidden" formControlName="asset" />
              <ng-container *ngIf="controls?.asset?.errors">
                <div
                  *ngIf="controls?.asset?.errors?.required"
                  class="invalid-feedback"
                >
                  {{ 'activity.create.form.assetError' | translate }}
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <!-- show more -->
        <button
          type="button"
          class="add-field-btn see-more-btn"
          [matMenuTriggerFor]="inputsMenu"
          #seeMore="matMenuTrigger"
          [ngClass]="{ 'is-open': seeMore.menuOpen }"
          romaProFeature
          [featureUserHide]="{
            type: 'some',
            features: [
              featureRefUser.Deal.show,
              featureRefUser.Project.show,
              featureRefUser.Address.show,
              featureRefUser.InternalVendor.show
            ]
          }"
        >
          {{ 'general.seeMore' | translate }}
          <img
            src="assets/icons/gl_arrow-down-blue.svg"
            style="margin-top: -5px"
          />
        </button>
        <mat-menu
          #inputsMenu="matMenu"
          class="dropdown-wrapper bordered-wrapper"
        >
          <div class="list-wrapper">
            <div
              class="list-item"
              *ngIf="(idActiveModules$ | async).includes(idModuleRef.Deals)"
              romaProFeature
              [featureUserHide]="featureRefUser.Deal.show"
            >
              <mat-checkbox
                (click)="changeInputsCheckBox($event, 'showDealsInput')"
                [checked]="showDealsInput"
              >
                {{ 'activity.create.form.deal' | translate }}
              </mat-checkbox>
            </div>
            <div
              class="list-item"
              *ngIf="(idActiveModules$ | async).includes(idModuleRef.Projects)"
              romaProFeature
              [featureUserHide]="featureRefUser.Project.show"
            >
              <mat-checkbox
                (click)="changeInputsCheckBox($event, 'showProjectInput')"
                [checked]="showProjectInput"
              >
                {{ 'activity.create.form.idProject' | translate }}
              </mat-checkbox>
            </div>
            <div
              class="list-item"
              *ngIf="(idActiveModules$ | async).includes(idModuleRef.Assets)"
              romaProFeature
              [featureUserHide]="featureRefUser.Project.show"
            >
              <mat-checkbox
                (click)="changeInputsCheckBox($event, 'showAssetInput')"
                [checked]="showAssetInput"
              >
                {{ 'activity.create.form.asset' | translate }}
              </mat-checkbox>
            </div>
            <div
              class="list-item"
              *ngIf="(idActiveModules$ | async).includes(idModuleRef.Assets)"
              romaProFeature
              [featureUserHide]="featureRefUser.InternalVendor.show"
            >
              <mat-checkbox
                (click)="changeInputsCheckBox($event, 'showVendorInput')"
                [checked]="showVendorInput"
              >
                {{ 'hiring.type.vendor' | translate }}
              </mat-checkbox>
            </div>
            <!--<div class="list-item">
              <mat-checkbox
                (change)="changeInputsCheckBox($event, 'showHourInput')"
                [checked]="showHourInput"
              >
                {{ 'general.hour' | translate }}
              </mat-checkbox>
            </div>-->
          </div>
        </mat-menu>
        <hr />
        <!-- custom-properties -->
        <h4
          class="subtitle"
          (click)="showCustomProperties = !showCustomProperties"
          style="cursor: pointer"
        >
          {{ 'settings.home-customization.customFieldsTitle' | translate }}
          <img
            *ngIf="!showCustomProperties"
            src="assets/icons/gl_up-arrow.svg"
          />
          <img
            *ngIf="showCustomProperties"
            src="assets/icons/gl_down_arrow.svg"
          />
        </h4>

        <roma-edit-custom-properties
          *ngIf="type === 'EDIT'"
          [showCustomProperties]="showCustomProperties"
          [idSchema]="activityEditRef?._id"
          [typeRef]="typePropertyRef.Activities"
          [parentForm]="form"
          [customProperties]="activityEditRef?.customProperties"
          [feature]="featureRef.Activity.HandleCustomProperties"
          [featureUser]="featureRefUser.Activity.update"
          [options]="{ showAlertMessage: false }"
          [updateOnEdit]="false"
          (propertiesRequired)="propertiesRequired($event)"
          (updateLoading)="updateLoading($event)"
          (propertiesLoading)="propertiesLoading($event)"
          (onValueChanged)="eventValueChanged($event)"
          (closeModal)="close()"
          [showPropertiesMessage]="
            customPropertiesLoaded && customProperties.length === 0
          "
        ></roma-edit-custom-properties>

        <!-- Add custom properties -->
        <roma-append-custom-properties
          *ngIf="type === 'ADD'"
          [typeRef]="typePropertyRef.Activities"
          [control]="form.get('customProperties')"
          [feature]="featureRef.Activity.HandleCustomProperties"
          [showCustomProperties]="showCustomProperties"
          [parentForm]="form"
          [initForm]="initChildForm"
          [parentFormSubmit]="parentFormSubmit"
          [showIsLoading]="type === 'ADD'"
          (customPropertiesForm)="customPropertiesFormChange($event)"
          (initialCharge)="initialChargeEvent()"
          (closeModal)="close()"
          (propertiesLoading)="type !== 'EDIT' && propertiesLoading($event)"
          [showPropertiesMessage]="
            customPropertiesLoaded && customProperties.length === 0
          "
        ></roma-append-custom-properties>
        <!-- Add custom properties -->
      </div>
      <div class="right-col">
        <h4
          class="subtitle"
          [ngClass]="{ middle: !showDivImg }"
          (click)="toggleDivImg()"
        >
          {{ 'general.fileAttach' | translate }}
          <img *ngIf="!showDivImg" src="assets/icons/gl_up-arrow.svg" />
          <img *ngIf="showDivImg" src="assets/icons/gl_down_arrow.svg" />
        </h4>

        <div *ngIf="showDivImg">
          <roma-image-selector
            [type]="'deal'"
            [quantity]="!hasFeatureHandleActivityCustomProperty ? 1 : 10"
            [feature]="featureRef.Activity.UploadFiles"
            [featureUser]="featureUserScreen"
            [title]="'hiring.form.add-images-lbl' | translate"
            [mode]="this.type"
            [fileArray]="form.get('files').value"
            [save-files-service]="saveFiles$"
            (onAllDataSaved)="eventAllDataSaved($event)"
            [module]="idModuleRef.Activities"
          >
          </roma-image-selector>
        </div>

        <h4 class="subtitle" (click)="toggleDivComments()">
          {{ 'equipments.create.notes' | translate }}
          <img *ngIf="!showDivComments" src="assets/icons/gl_up-arrow.svg" />
          <img *ngIf="showDivComments" src="assets/icons/gl_down_arrow.svg" />
        </h4>
        <div>
          <roma-equipment-notes
            [ngStyle]="
              !showDivComments && { visibility: 'hidden', height: '0' }
            "
            [mode]="type"
            [data]="activityComments || []"
            romaProSelect
            class="wrapper-pro-feature-user"
            [featureUser]="featureUserScreen"
            (changeNotes)="addingNotes($event)"
          ></roma-equipment-notes>
        </div>
      </div>
      <div
        class="actions-footer"
        [ngClass]="{ 'edit-footer': type === 'EDIT' }"
      >
        <button
          type="button"
          role="button"
          class="roma-button save-btn"
          [matTooltip]="showTooltipSave()"
          [disabled]="
            isLoading || propertiesIsLoading || invalidEditCustomFields
          "
          romaProFeature
          [featureUser]="featureUserScreen"
          (click)="type === 'ADD' ? submit() : editSubmit()"
        >
          <mat-progress-spinner
            *ngIf="isLoading"
            class="loading-spinner"
            mode="indeterminate"
            [diameter]="20"
          ></mat-progress-spinner>
          <ng-container *ngIf="!isLoading">
            {{
              type === 'ADD'
                ? ('activity.create.submit' | translate)
                : ('activity.edit.submit' | translate)
            }}
          </ng-container>
        </button>
        <button
          type="button"
          *ngIf="type === 'EDIT'"
          class="delete-btn"
          romaProFeature
          [featureUser]="featureRefUser.Activity.delete"
          romaClickDelete
          title="Eliminar"
          romaProFeature
          (wantDelete)="eventDelete()"
        >
          <img src="assets/icons/gl_trash_red.svg" />
          {{ 'general.deleteAlt' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
