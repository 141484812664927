import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';

import { TranslateModule } from '@ngx-translate/core';
import { OnlyNumberModule } from '@web-frontend/shared/directives/onlyNumber';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { RmSelectModule } from '../rm-select';
import { RmFilterButtonComponent } from './rm-filter-button.component';
import { RmFilterCoreComponent } from './rm-filter-core/rm-filter-core.component';
import { RmFilterModalDashboardComponent } from './rm-filter-modal-dashboard/rm-filter-modal-dashboard.component';
import { RmFilterModalDhbdDetailComponent } from './rm-filter-modal-dhbd-detail/rm-filter-modal-dhbd-detail.component';
import { FilterByParamsPipe } from './rm-filter-modal-dhbd-detail/filter-by-params.pipe';
import { RmFilterClearComponent } from './rm-filter-clear/rm-filter-clear.component';
import { PermissionsModule } from '../../permissions';

@NgModule({
  declarations: [
    RmFilterButtonComponent,
    RmFilterCoreComponent,
    RmFilterModalDashboardComponent,
    RmFilterModalDhbdDetailComponent,
    FilterByParamsPipe,
    RmFilterClearComponent,
  ],
  imports: [
    CommonModule,
    RmSelectModule,
    TranslateModule.forChild(),
    OnlyNumberModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatMenuModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,

    PermissionsModule,
  ],
  exports: [
    RmFilterButtonComponent,
    RmFilterCoreComponent,
    RmFilterModalDashboardComponent,
    RmFilterModalDhbdDetailComponent,
    FilterByParamsPipe,
    RmFilterClearComponent,
  ],
})
export class RmFilterModule {}
