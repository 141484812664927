import { ChecklistMockType, TaskHistoryStatus, TaskType } from '../enum';

import {
  CheckHistoriesParams,
  GenerateMocksParams,
  ITaskByChecklist,
  ITaskByChecklistMock,
  ITaskHistoryMin,
  ITaskMin,
  ITaskMinMock,
} from '../interfaces';

import { eachDay, format, isAfter, isSameDay } from 'date-fns';

import { v4 as uuidv4 } from 'uuid';

export const GenerateMocks = (params: GenerateMocksParams) => {
  const mockSet = new Set<ITaskByChecklistMock>();

  const { startDate, endDate, checklists } = params;

  for (const date of eachDay(startDate, endDate)) {
    for (const checklist of checklists) {
      const tasksPending = GetChecklistPending(date)(checklist);
      const tasksChecked = GetChecklistChecked(date)(checklist);
      const tasksError = GetChecklistError(date)(checklist);

      const transformTaskPending = TransformTasksCheckedMock(date)(
        tasksPending,
        checklist,
      );

      const transformTaskChecked = TransformTasksCheckedMock(date)(
        tasksChecked,
        checklist,
      );

      const transformTaskError = TransformTasksCheckedMock(date)(
        tasksError,
        checklist,
      );

      if (tasksPending.length || tasksChecked.length || tasksError.length) {
        const status = GetChecklistStatus(date)(checklist);

        mockSet.add({
          ...checklist,
          status,
          date,
          tasksChecked: transformTaskChecked,
          tasksPending: transformTaskPending,
          tasksError: transformTaskError,
        });
      }
    }
  }

  return mockSet;
};

export const GetChecklistPending = (date: Date) => {
  return (checklist: ITaskByChecklist) => {
    const checklistPending: ITaskMin[] = [];

    const tasksPending = GetTasksByDate(date, checklist.tasks);

    for (const task of tasksPending) {
      const type = GetTaskType(task);

      if (TaskType.Daily === type) {
        const taskIsChecked = GetTasks({
          date,
          histories: checklist.histories,
          task,
          status: TaskHistoryStatus.Checked,
        });

        const taskIsError = GetTasks({
          date,
          histories: checklist.histories,
          task,
          status: TaskHistoryStatus.Error,
        });

        if (!taskIsChecked && !taskIsError) {
          checklistPending.push(task);
        }
      }
    }

    return checklistPending;
  };
};

export const GetChecklistChecked = (date: Date) => {
  return (checklist: ITaskByChecklist) => {
    const tasksChecked = checklist.tasks.filter(isTaskDaily).filter((task) =>
      GetTasks({
        date,
        histories: checklist.histories,
        task,
        status: TaskHistoryStatus.Checked,
      }),
    );

    return tasksChecked;
  };
};

export const GetChecklistError = (date: Date) => {
  return (checklist: ITaskByChecklist) => {
    const tasksError = checklist.tasks.filter(isTaskDaily).filter((task) =>
      GetTasks({
        date,
        histories: checklist.histories,
        task,
        status: TaskHistoryStatus.Error,
      }),
    );

    return tasksError;
  };
};

export const isTaskDaily = (task: ITaskMin) => {
  return GetTaskType(task) === TaskType.Daily;
};

export const GetChecklistStatus = (date: Date) => {
  return (checklist: ITaskByChecklist): ChecklistMockType => {
    const { histories, tasks } = checklist;

    const tasksChecked = tasks.filter(isTaskDaily).filter((task) =>
      GetTasks({
        date,
        histories,
        task,
        status: TaskHistoryStatus.Checked,
      }),
    );

    const tasksError = tasks.filter(isTaskDaily).filter((task) =>
      GetTasks({
        date,
        histories,
        task,
        status: TaskHistoryStatus.Error,
      }),
    );

    const tasksPending = GetTasksByDate(date, checklist.tasks);

    if (tasksError.length) {
      return ChecklistMockType.Danger;
    }

    if (isAfter(date, new Date())) {
      if (tasksChecked.length) {
        if (tasksPending.length === tasksChecked.length) {
          return ChecklistMockType.Success;
        }

        if (tasksChecked.length < tasksPending.length) {
          return ChecklistMockType.Warning;
        }
      }

      return ChecklistMockType.Default;
    }

    if (tasksPending.length === tasksChecked.length) {
      return ChecklistMockType.Success;
    }

    return ChecklistMockType.Warning;
  };
};

export const TransformTasksCheckedMock = (date: Date) => {
  return (tasks: ITaskMin[], checklist: ITaskByChecklist) => {
    return tasks
      .map((task) => transformTaskMock(date)(task))
      .map((task) => transformTaskCheckedMock(date)(task, checklist));
  };
};

export const transformTaskCheckedMock = (date: Date) => {
  return (task: ITaskMinMock, checklist: ITaskByChecklist): ITaskMinMock => {
    const histories = GetHistoriesByDate(date, checklist.histories)?.filter(
      (history) => history?.idTask === task?._id,
    );

    return {
      ...task,
      history: histories.length ? histories[0] : null,
    };
  };
};

export const TransformTasksMock = (date: Date) => {
  return (tasks: ITaskMin[]) => {
    return tasks.map((task) => transformTaskMock(date)(task));
  };
};

export const transformTaskMock = (date: Date) => {
  return (task: ITaskMin) => {
    const newDate = new Date(
      `${format(date, 'MM/DD/YYYY')} ${format(
        task.startDate.replace('Z', ''),
        'hh:mm:ss A',
      )}`,
    );

    return {
      ...task,
      date: newDate,
      idFake: uuidv4(),
    };
  };
};

export const getDateTime = (inputDate: string, inputTime: string) => {
  const date = inputDate.split('T').slice(0, 1);
  const time = inputTime.split('T').slice(1);

  return date + 'T' + time;
};

export const GetTasks = (params: CheckHistoriesParams) =>
  GetTaskHistory(params).length > 0;

export const GetTaskHistory = (params: CheckHistoriesParams) => {
  return params.histories.filter(
    (history) =>
      history.idTask === params.task._id &&
      history?.status === params.status &&
      isSameDay(params.date, new Date(history.timestamp)),
  );
};

export const GetTaskType = (task: ITaskMin) => {
  const { isRecurring, startDate, endDate } = task;

  const isDaily = isRecurring && Boolean(startDate) && !endDate;

  if (isDaily) {
    return TaskType.Daily;
  }

  return null;
};

export const GetHistoriesByDate = (
  date: Date,
  histories: ITaskHistoryMin[],
) => {
  return histories.filter((history) =>
    isSameDay(new Date(history.timestamp), date),
  );
};

export const GetTasksByDate = (date: Date, tasks: ITaskMin[]) => {
  return tasks.filter(
    (task) =>
      isSameDay(date, new Date(task.startDate)) ||
      isAfter(date, new Date(task.startDate)),
  );
};

export const GetColors = {
  [ChecklistMockType.Danger]: {
    primary: '#e63946',
    secondary: '#FAE3E3',
    third: '#e66a73',
  },
  [ChecklistMockType.Success]: {
    primary: '#2a9d8f',
    secondary: '#9cccc6',
    third: '#52c0b2',
  },
  [ChecklistMockType.Warning]: {
    primary: '#e9c46a',
    secondary: '#ebdebf',
    third: '#f8db94',
  },
  [ChecklistMockType.Default]: {
    primary: '#CCC',
    secondary: '#EEE',
    third: '#ece1e1',
  },
};
