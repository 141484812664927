<ng-container [formGroup]="formFooter">
  <fieldset
    class="budget-footer"
    [ngClass]="{ mobile: (resize$ | async)?.payload?.mobile }"
    formGroupName="budgetFooter"
  >
    <!-- Final document settings (Mobile) -->
    <div
      class="section-two mobile"
      *ngIf="type === 'WAL' && (resize$ | async)?.payload?.mobile"
    >
      <div class="section-two-title">
        {{
          'budgets.settings.create.form.finalDocumentConfiguration' | translate
        }}
      </div>
      <div class="internal-data">
        <div class="internal-data-options">
          <div
            class="internal-data-options-item-50 mobile"
            style="margin-top: 20px"
          >
            <roma-select-item
              [data]="showPrices"
              (changeData)="changeSelectedItems($event)"
            ></roma-select-item>
            <ng-container *ngIf="showPrices[0].active">
              {{
                'budgets.settings.create.form.showPricesDescription' | translate
              }}
            </ng-container>
            <ng-container *ngIf="!showPrices[0].active">
              {{
                'budgets.settings.create.form.showPricesDescriptionDis'
                  | translate
              }}
            </ng-container>
          </div>
          <div
            class="internal-data-options-item-50 mobile"
            style="margin-bottom: 20px"
          >
            <roma-select-item
              [data]="showSignatures"
              (changeData)="changeSelectedItems($event)"
            ></roma-select-item>
          </div>
        </div>
      </div>
      <div class="separator2"></div>
    </div>
    <div class="section-one">
      <div class="section-one-left">
        <!-- PAYMENT INFO AREA -->
        <div class="payment-method">
          <label for="payment_method">
            {{ 'budgets.settings.create.form.paymentsMethods' | translate }}
            <div *ngIf="!showInvoiceTypeUsage" class="custom-tooltip right">
              <img
                src="/assets/icons/gl_information-fill-gray.svg"
                alt="{{
                  'budgets.settings.create.form.paymentsMethods' | translate
                }} info"
              />
              <div class="tiptext tiptext-payments">
                {{
                  'budgets.settings.create.form.paymentsMethodsTooltip'
                    | translate
                }}
              </div>
            </div>
            <div
              *ngIf="!showInvoiceTypeUsage"
              class="tooltip-mobile"
              (click)="
                showTooltipDialog(
                  'budgets.settings.create.form.paymentsMethodsTooltip',
                  'kanban.dialog.confirmButton'
                )
              "
            >
              <img
                src="/assets/icons/gl_information-fill-gray.svg"
                alt="{{
                  'budgets.settings.create.form.paymentsMethods' | translate
                }} info"
              />
            </div>
          </label>
          <rm-select-register
            class="select-payments"
            [isClearable]="true"
            [placeholderText]="
              'budgets.settings.create.form.selectPlaceHolder' | translate
            "
            [items]="paymentsMethodsList"
            [isLoading]="isLoadingpaymentsMethods"
            [disabled]="isLoadingpaymentsMethods"
            [activeItems]="selectedPayment"
            [isAbsolute]="true"
            [fullWidthList]="true"
            [allOptionsEnabled]="false"
            [listWidth]="'100%'"
            [loadDefaultItem]="false"
            inputHeight="44px"
            (changeItems)="changePayment($event)"
            [hasError]="fieldIsInvalid('payment_method')"
          >
          </rm-select-register>
          <span *ngIf="fieldIsInvalid('payment_method')" class="error-text"
            >*{{ 'budgets.settings.create.form.mandatory' | translate }}</span
          >
        </div>
        <!-- MESSAGES AREA -->
        <div class="messages">
          <!-- <label for="messages"> -->

          <!-- label + tooltip container  -->
          <div class="label-tooltip-container">
            <span id="messagesLabel">
              {{ 'budgets.settings.create.form.messages' | translate }}
            </span>

            <!-- tooltip -->
            <ng-container>
              <div class="custom-tooltip right">
                <img
                  src="/assets/icons/gl_information-fill-gray.svg"
                  alt="{{
                    'budgets.settings.create.form.messages' | translate
                  }} info"
                />
                <div class="tiptext tiptext-messages">
                  {{
                    'budgets.settings.create.form.messagesTooltip' | translate
                  }}
                </div>
              </div>
              <div
                class="tooltip-mobile"
                (click)="
                  showTooltipDialog(
                    'budgets.settings.create.form.messagesTooltip',
                    'kanban.dialog.confirmButton'
                  )
                "
              >
                <img
                  src="/assets/icons/gl_information-fill-gray.svg"
                  alt="{{
                    'budgets.settings.create.form.paymentsMethods' | translate
                  }} info"
                />
              </div>
            </ng-container>
          </div>

          <!-- </label> -->
          <textarea
            id="messages"
            rows="8"
            cols="40"
            formControlName="messages"
            placeholder="{{
              'budgets.settings.create.form.messagesPlaceholder' | translate
            }}"
            aria-labelledby="messagesLabel"
          >
          </textarea>
        </div>
      </div>
      <div class="section-one-right total">
        <roma-total-footer
          [mode]="mode"
          [form]="formFooter"
          [settings]="settings"
        ></roma-total-footer>
      </div>
    </div>
    <div
      class="section-two"
      *ngIf="type === 'WAL' && !(resize$ | async)?.payload?.mobile"
    >
      <div class="section-two-title">
        {{
          'budgets.settings.create.form.finalDocumentConfiguration' | translate
        }}
      </div>
      <div class="internal-data">
        <div class="internal-data-options">
          <div class="internal-data-options-item-50">
            <roma-select-item
              [data]="showPrices"
              (changeData)="changeSelectedItems($event)"
            ></roma-select-item>
            <ng-container *ngIf="showPrices[0].active">
              {{
                'budgets.settings.create.form.showPricesDescription' | translate
              }}
            </ng-container>
            <ng-container *ngIf="!showPrices[0].active">
              {{
                'budgets.settings.create.form.showPricesDescriptionDis'
                  | translate
              }}
            </ng-container>
          </div>
          <div class="internal-data-options-item-50">
            <roma-select-item
              [data]="showSignatures"
              (changeData)="changeSelectedItems($event)"
            ></roma-select-item>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showInvoiceTypeUsage" class="section-invoice-type">
      <div class="selector-item">
        <label style="width: fit-content" for="invoice-type">
          {{ 'budgets.settings.create.form.invoice-type' | translate }}
          <img
            tooltipDir
            [tooltipBackground]="'blue'"
            [tooltipMessages]="[
              'budgets.tooltip.createBudget.typeBudget1',
              {
                type: 'html',
                content: 'budgets.tooltip.createBudget.typeBudget2'
              },
              {
                type: 'html',
                content: 'budgets.tooltip.createBudget.typeBudget3'
              },
              {
                type: 'html',
                content: 'budgets.tooltip.createBudget.typeBudget4'
              }
            ]"
            [tooltipPosition]="'right'"
            [tooltipShowOn]="'any'"
            src="/assets/icons/gl_information-fill-gray.svg"
          />
        </label>
        <rm-select-register
          id="invoice-type"
          class="select-payments"
          [isClearable]="true"
          [placeholderText]="
            'budgets.settings.create.form.selectPlaceHolder' | translate
          "
          [items]="invoiceTypes"
          [activeItems]="selectedType"
          [isAbsolute]="true"
          [fullWidthList]="true"
          [allOptionsEnabled]="false"
          [listWidth]="'100%'"
          [loadDefaultItem]="false"
          inputHeight="44px"
          (changeItems)="changeInvoiceType($event)"
          [hasError]="fieldIsInvalid('invoiceType')"
        >
        </rm-select-register>
        <span *ngIf="fieldIsInvalid('invoiceType')" class="error-text"
          >*{{ 'budgets.settings.create.form.mandatory' | translate }}</span
        >
      </div>
      <div class="selector-item">
        <label style="width: fit-content" for="invoice-usage">
          {{ 'budgets.settings.create.form.invoice-usage' | translate }}
          <img
            tooltipDir
            [tooltipBackground]="'blue'"
            [tooltipMessages]="[
              { type: 'html', content: 'budgets.tooltip.createBudget.usage' }
            ]"
            [tooltipPosition]="'right'"
            [tooltipShowOn]="'any'"
            [tooltipHideOn]="'any'"
            src="/assets/icons/gl_information-fill-gray.svg"
          />
        </label>
        <rm-select-register
          id="invoice-usage"
          class="select-payments"
          [isClearable]="true"
          [placeholderText]="
            'budgets.settings.create.form.selectPlaceHolder' | translate
          "
          [items]="usages"
          [isLoading]="isLoadingUsage"
          [disabled]="isLoadingUsage"
          [activeItems]="selectedUsage"
          [isAbsolute]="true"
          [fullWidthList]="true"
          [allOptionsEnabled]="false"
          [listWidth]="'100%'"
          [loadDefaultItem]="false"
          inputHeight="44px"
          [hasError]="fieldIsInvalid('purposeUse')"
          (changeItems)="changeUsage($event)"
        >
        </rm-select-register>
        <span *ngIf="fieldIsInvalid('purposeUse')" class="error-text"
          >*{{ 'budgets.settings.create.form.mandatory' | translate }}</span
        >
      </div>
    </div>
    <ng-container *ngIf="validForBillLegacy">
      <div class="separator2"></div>
      <div class="section-two">
        <div class="section-two-title">
          {{ 'budgets.settings.create.form.sectionTitle' | translate }}
          <span>{{
            'budgets.settings.create.form.sectionTitleAdditional' | translate
          }}</span>
        </div>
        <!-- ADDITIONAL INFO (CATEGORY AND PROJECT) AREA -->
        <div class="internal-data">
          <div class="internal-data-options">
            <div class="internal-data-options-item">
              <label for="category_id">
                {{ 'budgets.settings.create.form.categorization' | translate }}
                <div class="custom-tooltip right">
                  <img
                    src="/assets/icons/gl_information-fill-gray.svg"
                    alt="{{
                      'budgets.settings.create.form.categorization' | translate
                    }} info"
                  />
                  <div class="tiptext tiptext-payments">
                    {{
                      'budgets.settings.create.form.categorizationTooltip'
                        | translate
                    }}
                  </div>
                </div>
                <div
                  class="tooltip-mobile"
                  (click)="
                    showTooltipDialog(
                      'budgets.settings.create.form.categorizationTooltip',
                      'kanban.dialog.confirmButton'
                    )
                  "
                >
                  <img
                    src="/assets/icons/gl_information-fill-gray.svg"
                    alt="{{
                      'budgets.settings.create.form.paymentsMethods' | translate
                    }} info"
                  />
                </div>
              </label>
              <rm-select-register
                id="category_id"
                class="select-payments"
                [isClearable]="true"
                [placeholderText]="
                  'budgets.settings.create.form.selectPlaceHolder' | translate
                "
                [items]="categories"
                [isLoading]="isLoadingCategories"
                [disabled]="isLoadingCategories"
                [activeItems]="selectedCategory"
                [isAbsolute]="true"
                [fullWidthList]="true"
                [allOptionsEnabled]="false"
                [listWidth]="'100%'"
                [loadDefaultItem]="false"
                inputHeight="44px"
                (changeItems)="changeCategory($event)"
              >
              </rm-select-register>
            </div>
            <div
              *ngIf="(idActiveModules$ | async)?.includes(idModuleRef.Deals)"
              romaProSelect
              class="internal-data-options-item wrapper-pro-feature-user"
              [featureUser]="featureRefUser.Deal.show"
            >
              <label for="category_id">
                {{
                  'budgets.settings.create.form.dealsPlaceholder' | translate
                }}
              </label>
              <rm-select-register
                id="category_id"
                class="select-payments"
                [isClearable]="true"
                [placeholderText]="
                  'budgets.settings.create.form.selectPlaceHolder' | translate
                "
                [items]="deals"
                [isLoading]="isLoadingDeals"
                [disabled]="isLoadingDeals"
                [activeItems]="selectedDeal"
                [isAbsolute]="true"
                [fullWidthList]="true"
                [allOptionsEnabled]="false"
                [listWidth]="'100%'"
                [loadDefaultItem]="false"
                inputHeight="44px"
                (changeItems)="changeDeals($event)"
              >
              </rm-select-register>
            </div>
            <div
              *ngIf="(idActiveModules$ | async)?.includes(idModuleRef.Projects)"
              romaProFeature
              [featureUserHide]="featureRefUser.Project.show"
              class="internal-data-options-item wrapper-pro-feature-user"
            >
              <label for="project_id">
                {{ 'budgets.settings.create.form.projects' | translate }}
              </label>
              <rm-select-register
                id="project_id"
                class="select-payments"
                [isClearable]="true"
                [placeholderText]="
                  'budgets.settings.create.form.selectPlaceHolder' | translate
                "
                [items]="projects"
                [isLoading]="isLoadingProjects"
                [disabled]="isLoadingProjects"
                [activeItems]="selectedProject"
                [isAbsolute]="true"
                [fullWidthList]="true"
                [allOptionsEnabled]="false"
                [listWidth]="'100%'"
                [loadDefaultItem]="false"
                inputHeight="44px"
                (changeItems)="changeProject($event)"
              >
              </rm-select-register>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </fieldset>
</ng-container>
