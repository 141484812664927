import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClockAbsence } from '@tacliatech/types';
import { MobileClockHourAdmin } from '@web-frontend/shared/components/clock-hours';
import { DateParsePipe } from '@web-frontend/shared/pipes/date-parse/date-parse.pipe';
import { ListMobileClockAbsenceAction } from './list-mobile-clock-absence.types';

@Component({
  selector: 'roma-list-mobile-clock-absence',
  templateUrl: './list-mobile-clock-absence.component.html',
  styleUrls: ['./list-mobile-clock-absence.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListMobileClockAbsenceComponent implements OnInit {
  @Input()
  private data: ClockAbsence.Output[];

  source: MobileClockHourAdmin.Content[] = [];

  @Output()
  actions = new EventEmitter<ListMobileClockAbsenceAction>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private i18n: TranslateService
  ) {}

  ngOnInit(): void {
    for (const item of this.data) {
      const startDate = item?.dateDetail?.startDate
        ? new DateParsePipe(this.i18n).transform(
            item.dateDetail.startDate,
            'AvoidLocaleDate'
          )
        : '';

      const endDate = item?.dateDetail?.endDate
        ? new DateParsePipe(this.i18n).transform(
            item.dateDetail.endDate,
            'AvoidLocaleDate'
          )
        : '';

      const hasEndDate = item.typeDetail.type === ClockAbsence.Type.SeveralDays;

      const date = `${startDate} ${hasEndDate ? ' - ' + endDate : ''}`;

      this.source = [
        ...this.source,
        {
          title: item.owner.name,
          description: null,
          id: item._id,
          img: '',
          status: this.wrapperStatus(item.status),
          startDate: date,
          endDate: null,
          hour: `${item.totalDays} ${this.i18n.instant('general.days')}`,
          data: item,
        },
      ];
    }

    this.draw();
  }

  private wrapperStatus(status: ClockAbsence.Status) {
    switch (status) {
      case ClockAbsence.Status.Pending:
        return 'PENDING';
      case ClockAbsence.Status.Rejected:
        return 'REJECTED';
      case ClockAbsence.Status.Approved:
        return 'APPROVED';
      default:
        return 'PENDING';
    }
  }

  makeActions(
    action: MobileClockHourAdmin.Action,
    content: MobileClockHourAdmin.Content
  ) {
    const item = (content?.data || null) as ClockAbsence.Output;
    this.actions.next({ ...action, item });
  }

  private draw() {
    this.cdRef.detectChanges();
  }
}
