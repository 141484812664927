import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RmFilterService {
  private busComponents = new Subject<{ type: 'CLEAN_ALL' }>();
  bus$ = this.busComponents.asObservable();

  private removeActiveFilters = new Subject<boolean>();
  cleanActiveFilters$ = this.removeActiveFilters.asObservable();

  constructor() {}

  emit(data: { type: 'CLEAN_ALL' }) {
    this.busComponents.next(data);
  }

  emitCleanFilters(data: boolean) {
    this.removeActiveFilters.next(data);
  }
}
