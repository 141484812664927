import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';
import { Stripe } from 'stripe';
import { ReaderService } from './services/reader.service';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { TacliaPayments } from '@tacliatech/types';
import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'roma-register-reader',
  templateUrl: './register-reader.component.html',
  styleUrls: ['./register-reader.component.scss'],
})
export class RegisterReaderComponent {
  readerForm: FormGroup = new FormGroup({
    registrationCode: new FormControl('', [Validators.required]),
    locationId: new FormControl('', [Validators.required]),
    label: new FormControl('', [Validators.required]),
  });

  isLoading = false;
  reader: Stripe.Response<Stripe.Terminal.Reader>;
  readers: Stripe.Terminal.Reader[] = [];

  constructor(
    private router: Router,
    private readerService: ReaderService,
    private cdRef: ChangeDetectorRef,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.readerService
      .getReaders()
      .pipe(
        finalize(() => {
          this.draw();
        })
      )
      .subscribe((res) => {
        this.readers = res.data;
      });
  }

  back() {
    this.router.navigateByUrl('/admin/payments');
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  onSubmit() {
    this.isLoading = true;

    this.readerService
      .register({
        ...this.readerForm.value,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.draw();
        }),
        catchError((error: HttpErrorResponse) => this.handleError(error))
      )
      .subscribe((res) => {
        this.reader = res;
      });
  }

  showErrorMessage(error: string) {
    this.toastService.show({
      text: TacliaPayments.Errors.getStripeError(error),
      type: 'error',
      msDuration: 4000,
      class: '',
    });
  }

  private handleError(error: HttpErrorResponse) {
    // Your error handling logic goes here
    if (error.error instanceof ErrorEvent) {
      // Client-side error occurred
      console.error('Client-side error:', error.error.message);
    } else {
      // Server-side error occurred
      console.error(
        `Server-side error: Status ${error.status}, ` + `Error: ${error.error}`
      );
    }

    // Return an observable with a user-facing error message
    return throwError('Something went wrong; please try again later.');
  }
}
