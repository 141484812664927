import { Component, Input, OnInit } from '@angular/core';

import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import {
  IDeal,
  STATUS_DEAL_CANCELED,
  STATUS_REQUESTED_BUDGET,
} from '@tacliatech/types';

import { DealService } from '@web-frontend/shared/services';
import { AuthService } from '@web-frontend/shared/services/auth/auth.service';

@Component({
  selector: 'roma-deal-aprove',
  templateUrl: './deal-aprove.component.html',
  styleUrls: ['./deal-aprove.component.scss'],
})
export class DealAproveComponent implements OnInit {
  @Input()
  dealId: string;

  @Input()
  aproveValue: boolean;

  private sub$ = new Subscription();

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    public activeModal: MatDialogRef<DealAproveComponent>,
    private dealService: DealService
  ) {}

  ngOnInit(): void {}

  submit() {
    const status = this.aproveValue
      ? STATUS_REQUESTED_BUDGET
      : STATUS_DEAL_CANCELED;

    this.sub$.add(
      this.dealService.updateStatus(this.dealId, status).subscribe((res) => {
        this.activeModal.close(res as IDeal);
      })
    );
  }

  close() {
    this.activeModal.close();
  }
}
