<button
  *ngIf="(resize$ | async)?.payload?.mobile"
  class="control__toggle control__toggle--mobile"
  (click)="open()"
  style="width: fit-content"
>
  {{ title || 'general.filters' | translate }}
  <span *ngIf="activeFilters" style="margin-right: 4px"
    >({{ activeFilters }})</span
  >
  <img class="arrow" src="/assets/icons/gl_filter.svg" alt="filters" />
</button>

<button
  *ngIf="!(resize$ | async)?.payload?.mobile"
  class="control__toggle"
  [matMenuTriggerFor]="menu"
  (click)="sendOpenFilter()"
>
  {{ title || 'general.filters' | translate }}
  <span *ngIf="activeFilters" style="margin-right: 4px"
    >({{ activeFilters }})</span
  >
  <img
    *ngIf="title !== 'activity.viewTasks' && title !== 'filter.viewServices'"
    class="arrow"
    src="/assets/icons/gl_filter.svg"
    alt="filters"
  />
  <img
    *ngIf="title === 'activity.viewTasks' || title === 'filter.viewServices'"
    src="/assets/icons/arrow.svg"
    alt="filters"
    style="max-width: 12px"
  />
</button>

<mat-menu
  #menu="matMenu"
  [class]="'panel__rm-filter'"
  [xPosition]="'after'"
  [hasBackdrop]="true"
  (click)="$event.stopPropagation()"
>
  <div class="container__header__filters">
    <div
      *ngIf="title !== 'filter.viewServices'"
      class="container__header__filters__label"
    >
      {{ 'general.filters2' | translate }}
    </div>
    <div class="container__header__filters__options">
      <roma-rm-filter-clear
        [sources]="[source]"
        (changes)="emitChanges($event)"
      >
      </roma-rm-filter-clear>
    </div>
  </div>
  <roma-rm-filter-core
    *ngIf="!(resize$ | async)?.payload?.mobile"
    [minHeight]="filterHeight"
    [minWidth]="filterWidth"
    [source]="source"
    [columnMode]="true"
    (changes)="emitChanges($event)"
    (click)="$event.stopPropagation()"
  >
  </roma-rm-filter-core>
</mat-menu>
