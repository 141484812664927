import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Subscription } from 'rxjs';

import { IVertical } from '@tacliatech/types';

@Injectable({
  providedIn: 'root',
})
export class VerticalService {
  private sub$ = new Subscription();

  private verticalsCache = new BehaviorSubject<IVertical[]>([]);

  verticals$ = this.verticalsCache.asObservable();

  constructor(private http: HttpClient) {}

  get verticals() {
    return this.verticalsCache.value;
  }

  init() {
    this.sub$.add(
      this.findAll().subscribe((res) => {
        this.verticalsCache.next(res);
      })
    );
  }

  findAll() {
    return this.http.get<IVertical[]>(':API_URL/verticals');
  }
}
