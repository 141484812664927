<div class="modal__sidebar">
  <!-- details button -->
  <div
    class="modal__sidebar__item"
    [ngClass]="{ 'modal__sidebar__item--active': activeTab === 'DETAIL' }"
    (click)="changeTab('DETAIL')"
  >
    <div class="modal__sidebar__item__panel">
      <svg
        *ngIf="activeTab !== 'DETAIL'"
        width="37"
        height="36"
        viewBox="0 0 37 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.5 14.5H20.8333M25.5 18H20.8333M18.5 22.0833C18.1691 20.752 16.7278 19.75 15 19.75C13.2722 19.75 11.8309 20.752 11.5 22.0833M11.7333 26.1667H25.2667C26.5735 26.1667 27.2269 26.1667 27.726 25.9124C28.165 25.6886 28.522 25.3317 28.7457 24.8926C29 24.3935 29 23.7401 29 22.4333V13.5667C29 12.2599 29 11.6065 28.7457 11.1074C28.522 10.6683 28.165 10.3114 27.726 10.0877C27.2269 9.83334 26.5735 9.83334 25.2667 9.83334H11.7333C10.4265 9.83334 9.77315 9.83334 9.27402 10.0877C8.83498 10.3114 8.47802 10.6683 8.25432 11.1074C8 11.6065 8 12.2599 8 13.5667V22.4333C8 23.7401 8 24.3935 8.25432 24.8926C8.47802 25.3317 8.83498 25.6886 9.27402 25.9124C9.77315 26.1667 10.4265 26.1667 11.7333 26.1667ZM16.1667 15.0833C16.1667 15.7277 15.6443 16.25 15 16.25C14.3557 16.25 13.8333 15.7277 13.8333 15.0833C13.8333 14.439 14.3557 13.9167 15 13.9167C15.6443 13.9167 16.1667 14.439 16.1667 15.0833Z"
          stroke="#97A3C5"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <img *ngIf="activeTab === 'DETAIL'" src="assets/icons/detail.svg" />

      <p class="modal__sidebar__item__panel__text">
        {{ 'reports.subtitle' | translate }}
      </p>
    </div>
  </div>
  <!-- deals button -->
  <ng-container *ngIf="(idActiveModules$ | async).includes(idModuleRef.Deals) && type !== 'ADD'">
    <div
      class="modal__sidebar__item"
      [ngClass]="{
        'modal__sidebar__item--active': activeTab === 'DEALS'
      }"
      [ngStyle]="{ 'pointer-events': customer ? 'auto' : 'none' }"
      romaProFeature
      [featureUser]="featureRefUser.Deal.show"
      (clicked)="changeTab('DEALS')"
    >
      <div class="modal__sidebar__item__panel">
        <img
          *ngIf="activeTab === 'DEALS'"
          src="assets/icons/gl_deal_modal_sidebar-active.svg"
        />
        <img
          *ngIf="activeTab !== 'DEALS'"
          src="assets/icons/gl_deal_modal_sidebar.svg"
        />

        <p class="modal__sidebar__item__panel__text">
          {{ 'deals.title' | translate }}
        </p>
      </div>
    </div>
  </ng-container>
  <!-- activities button -->
  <ng-container
    *ngIf="(idActiveModules$ | async).includes(idModuleRef.Activities) && type !== 'ADD'"
  >
    <div
      class="modal__sidebar__item"
      [ngClass]="{
        'modal__sidebar__item--active': activeTab === 'TASKS'
      }"
      [ngStyle]="{ 'pointer-events': customer ? 'auto' : 'none' }"
      romaProFeature
      [featureUser]="featureRefUser.Activity.show"
      (clicked)="changeTab('TASKS')"
    >
      <div class="modal__sidebar__item__panel">
        <svg
          *ngIf="activeTab !== 'TASKS'"
          width="37"
          height="36"
          viewBox="0 0 37 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5667 7.5V9.83333M24.2334 7.5V9.83333M11.6334 28.5H25.1667C26.4735 28.5 27.1269 28.5 27.626 28.2457C28.065 28.022 28.422 27.665 28.6457 27.226C28.9 26.7269 28.9 26.0735 28.9 24.7667V13.5667C28.9 12.2599 28.9 11.6065 28.6457 11.1074C28.422 10.6683 28.065 10.3114 27.626 10.0877C27.1269 9.83333 26.4735 9.83333 25.1667 9.83333H11.6334C10.3266 9.83333 9.67317 9.83333 9.17405 10.0877C8.735 10.3114 8.37805 10.6683 8.15434 11.1074C7.90002 11.6065 7.90002 12.2599 7.90002 13.5667V24.7667C7.90002 26.0735 7.90002 26.7269 8.15434 27.226C8.37805 27.665 8.735 28.022 9.17405 28.2457C9.67317 28.5 10.3266 28.5 11.6334 28.5ZM13.7334 23.25L16.0958 22.7775C16.3018 22.7363 16.4048 22.7157 16.5009 22.678C16.5861 22.6446 16.6671 22.6012 16.7422 22.5489C16.8269 22.4898 16.9011 22.4156 17.0497 22.267L21.9 17.4167C22.5444 16.7723 22.5444 15.7277 21.9 15.0833C21.2557 14.439 20.211 14.439 19.5667 15.0833L14.7163 19.9337C14.5678 20.0822 14.4935 20.1565 14.4345 20.2411C14.3821 20.3162 14.3388 20.3973 14.3053 20.4825C14.2676 20.5785 14.247 20.6815 14.2058 20.8875L13.7334 23.25Z"
            stroke="#97A3C5"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <img
          *ngIf="activeTab === 'TASKS'"
          src="assets/icons/wrp_icon_azul.svg"
        />

        <p class="modal__sidebar__item__panel__text">
          {{ 'sidebar.tasks' | translate }}
        </p>
      </div>
    </div>
  </ng-container>
  <!-- sales button -->
  <ng-container *ngIf="(idActiveModules$ | async).includes(idModuleRef.Sales) && type !== 'ADD'">
    <div
      class="modal__sidebar__item"
      [ngClass]="{ 'modal__sidebar__item--active': activeTab === 'SALES' }"
      romaProFeature
      [featureUser]="{
        type: 'some',
        features: [
          featureRefUser.Quote.show,
          featureRefUser.Bill.show,
        ]
      }"
      (clicked)="changeTab('SALES')"
      [ngStyle]="{ 'pointer-events': customer ? 'auto' : 'none' }"
    >
      <div class="modal__sidebar__item__panel">
        <svg
          *ngIf="activeTab !== 'SALES'"
          width="37"
          height="36"
          viewBox="0 0 37 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.3333 19.1667H29V26.1667C29 27.4553 27.9553 28.5 26.6667 28.5M24.3333 19.1667V26.1667C24.3333 27.4553 25.378 28.5 26.6667 28.5M24.3333 19.1667V10.7166C24.3333 9.66031 24.3333 9.13216 24.1114 8.82589C23.9179 8.55872 23.6202 8.38599 23.2922 8.35048C22.9162 8.30978 22.4577 8.57181 21.5405 9.09588L21.1761 9.30412C20.8386 9.49701 20.6698 9.59346 20.4905 9.63122C20.3319 9.66464 20.1681 9.66464 20.0095 9.63122C19.8302 9.59346 19.6614 9.49701 19.3239 9.30412L17.0928 8.02921C16.7552 7.83632 16.5864 7.73987 16.4072 7.7021C16.2486 7.66869 16.0847 7.66869 15.9261 7.7021C15.7469 7.73987 15.5781 7.83632 15.2405 8.02921L13.0095 9.30412C12.6719 9.49701 12.5031 9.59346 12.3239 9.63122C12.1653 9.66464 12.0014 9.66464 11.8428 9.63122C11.6636 9.59346 11.4948 9.49701 11.1572 9.30412L10.7928 9.09588C9.87567 8.57181 9.41712 8.30978 9.0411 8.35048C8.7131 8.38599 8.41546 8.55872 8.2219 8.82589C8 9.13216 8 9.66031 8 10.7166V22.9C8 24.8602 8 25.8403 8.38148 26.589C8.71703 27.2475 9.25247 27.783 9.91103 28.1185C10.6597 28.5 11.6398 28.5 13.6 28.5H26.6667M18.5 16.25C17.9167 16.1053 16.9659 16.1 16.1667 16.1053C15.8994 16.1071 16.061 16.0958 15.7 16.1053C14.758 16.1347 13.8353 16.5262 13.8333 17.6354C13.8313 18.8171 15 19.1667 16.1667 19.1667C17.3333 19.1667 18.5 19.4364 18.5 20.6979C18.5 21.646 17.5579 22.0614 16.3838 22.1989C15.4504 22.1989 15 22.2292 13.8333 22.0834M16.1667 22.6667V23.8333M16.1667 14.5V15.6667"
            stroke="#97A3C5"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <img
          *ngIf="activeTab === 'SALES'"
          src="assets/icons/receipt-alt-3.svg"
        />

        <p class="modal__sidebar__item__panel__text">
          {{ 'general.sales' | translate }}
        </p>
      </div>
    </div>
  </ng-container>
</div>

<div class="modal__body safe-area-padding">
  <div class="modal__body__top">
    <h2 *ngIf="type === 'ADD'" class="modal__body__top__title">
      {{ 'finals.create.addNewCustomer' | translate }}
    </h2>

    <button
      *ngIf="
        !(resize$ | async)?.payload?.mobile && data?.mode === 'EDIT' && !loading
      "
      type="button"
      class="rounded-button"
      (click)="shareLink()"
    >
      <img src="assets/icons/gl_link-black.svg" />
      {{ 'general.shareLink' | translate }}
    </button>

    <!-- Close button -->
    <ng-container>
      <img
        *ngIf="!haveChanges; else withChanges"
        [src]="'assets/icons/gl_close.svg'"
        class="close-button"
        width="32px"
        height="32px"
        (click)="close()"
      />

      <ng-template #withChanges>
        <img
          [src]="'assets/icons/gl_close.svg'"
          class="close-button"
          width="32px"
          height="32px"
          romaClickDelete
          [message]="'general.withoutSave'"
          [buttonDelete]="'general.buttonExit'"
          (wantDelete)="close()"
        />
      </ng-template>
    </ng-container>

    <ng-container
      *ngIf="
        (resize$ | async)?.payload?.mobile && data?.mode === 'EDIT' && customer
      "
    >
      <img
        class="more-v"
        [matMenuTriggerFor]="topActiosMenu"
        src="assets/icons/gl_more-vertical.svg"
      />
      <mat-menu
        #topActiosMenu="matMenu"
        xPosition="before"
        class="top__actions__menu actions-menu"
      >
        <button
          *ngIf="data?.mode === 'EDIT' && !loading"
          type="button"
          (click)="shareLink()"
        >
          <img src="assets/icons/gl_link-black.svg" />
          {{ 'general.shareLink' | translate }}
        </button>
      </mat-menu>
    </ng-container>
  </div>
  <div class="modal__body__content">
    <roma-final-wrapper-detail
      *ngIf="shouldShowDetail()"
      [type]="type"
      [customerData]="customer"
      (updateFormStatus)="handleHaveChanges($event)"
    >
    </roma-final-wrapper-detail>
    <roma-wrapper-deals
      *ngIf="activeTab === 'DEALS'"
      [type]="type"
      [customer]="customer"
    ></roma-wrapper-deals>
    <roma-wrapper-activities
      *ngIf="activeTab === 'TASKS'"
      [type]="type"
      [customer]="customer"
    ></roma-wrapper-activities>
    <roma-wrapper-sales
      *ngIf="activeTab === 'SALES'"
      [type]="type"
      [customerId]="customer?._id"
      [customerName]="customer?.name"
    ></roma-wrapper-sales>
    <div class="spinner-container">
      <mat-spinner *ngIf="loading" [diameter]="80"></mat-spinner>
    </div>
  </div>
</div>
