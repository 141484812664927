import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

import { throttle as _throttle } from 'lodash';

enum ScrollDirection {
  UP = 'up',
  DOWN = 'down',
}

@Component({
  selector: 'roma-scroll-container',
  templateUrl: './scroll-container.component.html',
  styleUrls: ['./scroll-container.component.css'],
})
export class ScrollContainerComponent implements OnInit, OnChanges {
  private _element: Element;
  private _window: Element;
  public scrollTop = 0;

  @Input()
  more = true;

  @Input()
  scrollDelay = 500;

  @Input()
  scrollOffset = 1000;

  @Output()
  scrolled = new EventEmitter<boolean>();

  @HostListener('scroll')
  _scroll!: () => void;

  @HostListener('window:scroll')
  _windowScroll!: () => void;

  constructor(private elRef: ElementRef) {
    this._element = this.elRef.nativeElement;
    this._window = document.documentElement as Element;
  }

  ngOnInit() {
    this.setThrottle();
  }

  ngOnChanges(changes: { scrollDelay: any }) {
    if (changes.scrollDelay) {
      this.setThrottle();
    }
  }

  setThrottle() {
    this._scroll = this._windowScroll = _throttle(
      this.handleScroll,
      this.scrollDelay
    );
  }

  getListener = () => {
    return this.elRef.nativeElement.clientHeight ===
      this.elRef.nativeElement.scrollHeight
      ? 'window:scroll'
      : 'scroll';
  };

  roundTo = (from: number, to: number = this.scrollOffset) =>
    Math.floor(from / to) * to;

  getScrollDirection = (st: number) =>
    this.scrollTop <= st ? ScrollDirection.DOWN : ScrollDirection.UP;

  canScroll(e: Element): boolean {
    const scrolled =
      this.more &&
      this.getScrollDirection(e.scrollTop) === ScrollDirection.DOWN &&
      this.roundTo(e.clientHeight) ===
        this.roundTo(e.scrollHeight - e.scrollTop);

    this.scrollTop = e.scrollTop;

    return scrolled;
  }

  handleScroll = () =>
    this.getListener() === 'scroll'
      ? this.scrolled.emit(this.canScroll(this._element))
      : this.scrolled.emit(this.canScroll(this._window));
}
