<roma-modal-template
  *ngIf="!isMobile"
  (cancel)="close()"
  [showDefaultButtons]="false"
>
    <digital-certificate-status [status]="status" (retrySendCertificate)="onRetrySendCertificate()" (toBill)="onToBill()"></digital-certificate-status>
</roma-modal-template>

<div *ngIf="isMobile" class="mobile">
  <digital-certificate-status [status]="status" (retrySendCertificate)="onRetrySendCertificate()" (toBill)="onToBill()"></digital-certificate-status>
  <div class="text-center close-btn-wrapper">
    <img
      (click)="close()"
      class="close-btn"
      src="/assets/icons/btn_close.svg"
      alt="close"
    />
  </div>
</div>
