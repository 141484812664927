import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'roma-edit-catalogue-modal',
  templateUrl: './edit-catalogue-modal.component.html',
  styleUrls: ['./edit-catalogue-modal.component.scss'],
})
export class EditCatalogueModalComponent implements OnInit {
  catalogue;

  form: FormGroup = this.fb.group({
    name: new FormControl(),
    _id: new FormControl(),
    stock: new FormControl(),
    sellPrice: new FormControl(),
  });

  constructor(
    private dialogRef: MatDialogRef<EditCatalogueModalComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: { catalogue },
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.catalogue = this.data?.catalogue;
    this.form.patchValue({
      name: this.catalogue?.name,
      _id: this.catalogue?._id,
      stock: this.catalogue?.stock,
      sellPrice: this.catalogue?.sellPrice,
    });
  }

  get totalAmount() {
    // @ts-ignore
    return this.form.get('sellPrice').value * this.form.get('stock').value;
  }

  submit() {
    this.close(this.form.value);
  }

  close(params = null) {
    // @ts-ignore
    this.dialogRef.close(params);
  }

  formatValue(input) {
    return this.form.value[input]
      ? (this.form.value[input] = Math.abs(this.form.value[input]))
      : null;
  }
}
