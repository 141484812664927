import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';

import { DeleteBySelectionModalModule } from '@web-frontend/shared/components/delete-by-selection-modal';
import { ClickDeleteDirective } from './click-delete.directive';
import { ModalChooseComponent } from './modal-choose/modal-choose.component';
import { ModalYesOrNoComponent } from './modal-yes-or-no/modal-yes-or-no.component';

@NgModule({
  declarations: [
    ClickDeleteDirective,
    ModalYesOrNoComponent,
    ModalChooseComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatCheckboxModule,
    MatRadioModule,
    DeleteBySelectionModalModule,
  ],
  exports: [ClickDeleteDirective, ModalYesOrNoComponent, ModalChooseComponent],
})
export class ClickDeleteModule {}
