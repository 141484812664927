import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickSequenceDirective } from './click-sequence.directive';

@NgModule({
  declarations: [ClickSequenceDirective],
  imports: [CommonModule],
  exports: [ClickSequenceDirective],
})
export class ClickSequenceModule {}
