<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'budgets'">
    <img src="assets/icons/no-results-budgets.svg" class="result__image" />
    <div class="result__description">
      {{ 'budgets.noResults' | translate }}
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <img src="assets/icons/no-results-ilu.svg" class="result__image" />
    <div class="result__title">
      {{ 'general.ohNo' | translate }}
    </div>
    <div class="result__description">
      {{ 'general.noResults' | translate }}
    </div>
  </ng-container>
</ng-container>
