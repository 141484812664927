<roma-modal-template
  size="md"
  [title]="
    adminMode
      ? ('person.create.form.modalTitleAdmin' | translate)
      : ('person.create.form.modalTitle' | translate)
  "
  [footerTemplate]="footerButtons"
  [showDefaultButtons]="false"
  (success)="submit()"
  (cancel)="close()"
>
  <p>
    {{
      adminMode
        ? ('person.create.form.modalSubtitleAdmin' | translate)
        : ('person.create.form.modalSubtitle' | translate)
    }}
  </p>
  <div class="form-container">
    <roma-contract-form [form]="form"></roma-contract-form>
  </div>
</roma-modal-template>

<ng-template #footerButtons>
  <div class="modalFooter mobile-footer">
    <div class="groupEndDiv">
      <base-button
        type="submit"
        (click)="submit()"
        [disabled]="form.invalid || !form.get('contract.workingHours')?.value"
        [isLoading]="isLoading"
      >
        {{ 'person.create.form.modalCta' | translate }}
      </base-button>
    </div>
  </div>
</ng-template>
