import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CancelSaleModalComponent } from './cancel-sale-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ModalTemplateModule } from '../../modal-template';
import { RmDealSelectModule } from '../../globals/rm-deal-select';
import { FormsModule } from '@angular/forms';
import { BaseButtonModule } from '@web-frontend/common/components/button/baseButton.module';

@NgModule({
  declarations: [CancelSaleModalComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ModalTemplateModule,
    MatDialogModule,
    RmDealSelectModule,
    FormsModule,
    BaseButtonModule,
  ],
})
export class CancelSaleModalModule {}
