import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  ViewChild,
} from '@angular/core';

import { RmSelect } from './rm-select.types';
import { fromEvent, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map } from 'rxjs/operators';
import { Feature, FeatureUser } from '@tacliatech/types';

@Component({
  selector: 'rm-select',
  templateUrl: './rm-select.component.html',
  styleUrls: ['./rm-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmSelectComponent implements OnInit {
  @ViewChild('inputFilterSearch')
  set input(value: ElementRef<HTMLInputElement>) {
    if (value) {
      this._input = value;
      this.setFocusInputSearch();
      this.patchFilterArgParams();
      this.watchInputSearch();
    }
  }

  @Input()
  placeholderText = '';

  @Input()
  mode: RmSelect.Type = 'SINGLE';

  @Input()
  displayMultipleActive = false;

  @Input()
  isAbsolute = false;

  @Input()
  isClearable = false;

  @Input()
  listWidth = '';

  @Input()
  borderLeft = false;

  @Input()
  addButtonText = 'general.addUser';

  @Input()
  addButtonFeatureUser: Feature.Purchasable | FeatureUser.Purchasable =
    Feature.SystemPermission.DefaultAllow;

  @Input()
  hasError = false;

  _panelClicked = false;
  _isLoading = false;
  _firstLoad = true;
  @Input()
  @HostBinding('class.is-loading')
  set isLoading(value: boolean) {
    this._isLoading = value;
    //if is changed is loaded to false for second time (latter than load), we open the panel
    if (this._isLoading == false) {
      if (this._firstLoad) {
        this._firstLoad = false;
        this._panelClicked = false;
      } else {
        if (this._panelClicked) {
          this.showPanelElement = true;
        }
        this._panelClicked = false;
      }
    }
  }

  get isLoading() {
    return this._isLoading;
  }

  @Input()
  @HostBinding('class.is-disabled')
  disabled!: boolean;

  @Input()
  items: RmSelect.Items = [];

  @Input()
  activeItems: RmSelect.Items = [];

  @Input()
  inputHeight = '44px';

  @Input()
  type = '';

  @Input()
  showAddButton = false;

  @Output()
  changeItems = new EventEmitter<RmSelect.Items>();

  @Output()
  addElement = new EventEmitter<MouseEvent>();

  @Input()
  showPanelElement = false;

  checkBoxItems: any[] = [];
  filterArgs: { title: string } = { title: '' };

  private _input: ElementRef<HTMLInputElement>;
  private sub$ = new Subscription();

  firstLoad = true;
  focusOutA = false;
  focusOutB = false;
  anuleFocusOut = false;

  constructor(private cdr: ChangeDetectorRef, private elRef: ElementRef) {}

  ngOnInit(): void {}

  clickPanel(evt: MouseEvent, open?: boolean, from?: string) {
    this._panelClicked = true;
    if (evt) {
      if (open === true || open === false) {
        this.showPanelElement = open;
      } else {
        this.showPanelElement = !this.showPanelElement;
      }

      if (this.showPanelElement) {
        this.focusOutA = true;
        this.focusOutB = false;
      }
      this.draw();
    }
  }

  async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  anuleFocusOutTimeout() {
    this.anuleFocusOut = true;
    setTimeout(() => {
      this.anuleFocusOut = false;
    }, 200);
  }

  async focusOut(from: string) {
    await this.delay(100);

    if (this.anuleFocusOut == true) {
      return;
    }

    if (from === 'users') {
      this.focusOutA = true;
    }

    if (from === 'status') {
      this.focusOutB = true;
    }

    if (this.focusOutA && this.focusOutB) {
      this.showPanelElement = false;
    }
    this.draw();
  }

  changeSelect(item: RmSelect.Item) {
    this.activeItems = [item];
    this.showPanelElement = false;
    this.changeItems.next(this.activeItems);
    this.draw();
  }

  changeCheckBox(evt, item) {
    if (item.stock || item.price) {
      item = {
        title: item.title,
        value: item.value,
        price: item.price,
        stock: item.stock,
      };
    } else {
      item = { title: item.title, value: item.value };
    }

    if (evt.checked) {
      //this.checkBoxItems.push(item.value);
      this.activeItems.push(item);
    } else {
      this.activeItems.splice(
        this.activeItems.findIndex(function (i) {
          return i.value === item.value;
        }),
        1
      );
    }

    this.changeItems.next(this.activeItems);
    this.draw();
  }

  searchCheckFilters(item) {
    if (
      this.activeItems.filter((element) => element.value == item.value).length >
      0
    ) {
      return true;
    } else {
      return false;
    }
  }

  addElementHandle(evt: MouseEvent) {
    if (evt) {
      this.addElement.next(evt);
    }
  }

  changeCheckBoxByClick(evt, item) {
    if (this.searchCheckFilters(item)) {
      this.activeItems.splice(this.activeItems.indexOf(item), 1);
    } else {
      this.activeItems.push(item);
    }
    this.changeItems.next(this.activeItems);
    this.draw();
  }
  clearInput(mode: 'in' | 'out') {
    this.activeItems = [];
    this.filterArgs.title = '';
    this.draw();

    if (mode === 'in') {
      this.changeItems.next(this.activeItems);
    }
  }

  sliceTitle(title: string, quantity: number): string {
    const titleType = title.split(' ');
    let parsedTitle = titleType[0].slice(0, quantity);
    for (let i = 0; i < titleType.length; i++) {
      if (i > 0) {
        const element = titleType[i];
        parsedTitle += ` ${element}`;
      }
    }
    return parsedTitle;
  }

  private draw() {
    this.cdr.detectChanges();
  }

  private setFocusInputSearch() {
    if (this._input) {
      this._input.nativeElement.focus();
    }
  }

  private patchFilterArgParams() {
    if (this._input && this.filterArgs.title) {
      this._input.nativeElement.value = this.filterArgs.title;
    }
  }

  private watchInputSearch() {
    this.sub$.add(
      fromEvent(this._input.nativeElement, 'input')
        .pipe(
          distinctUntilChanged(),
          debounce(() => timer(450)),
          map((res) => (res.target as HTMLTextAreaElement).value as string)
        )
        .subscribe(this.changeKeyword.bind(this))
    );
  }

  private changeKeyword(keyword: string) {
    this.filterArgs = {
      title: keyword.toString().toLocaleLowerCase(),
    };
    this.draw();
  }
}

@Pipe({
  name: 'searchFilter',
  pure: false,
})
export class SearchFilterPipe implements PipeTransform {
  transform(items: RmSelect.Items, filter: { title: string }): any {
    if (!items?.length || !filter?.title) {
      return items;
    }

    return items.filter((item) =>
      item.title.match(new RegExp(filter.title, 'i'))
    );
  }
}
