<div
  class="hour__select__wrapper"
  [ngClass]="{
    error: showError || hasExternalError
  }"
>
  <div class="input__wrapper">
    <div
      class="hour__wrapper"
      [ngClass]="{
        is__active: showPeriodList,
        error: showError || hasExternalError
      }"
    >
      <div class="input-suffix-container">
        <input
          romaOnlyNumber
          name="hour"
          [maxDecimals]="maxDecimals"
          inputmode="numeric"
          class="hour__input"
          [maxLength]="maxLength"
          [onlyPositive]="false"
          [allowDecimalSeparators]="maxDecimals"
          [ngClass]="inputClass"
          [placeholder]="0"
          [value]="selectedNumber"
          [max]="maxHours"
          (input)="validateMaxHours($event)"
          (blur)="onHourChange($event.target.value)"
        />
        <span class="input-suffix">h</span>
      </div>
    </div>
    <div
      class="period__wrapper"
      [ngClass]="{
        is__active: showPeriodList,
        error: showError || hasExternalError
      }"
      (click)="periodListToggle()"
    >
      <input
        #inputFilterSearch
        id="periodInput"
        type="text"
        class="period__input"
        [ngClass]="inputClass"
        [value]="translatedPeriodLabel"
        (keypress)="omitSpecialChar($event)"
        readonly
      />
      <img
        class="period__arrow"
        [ngClass]="inputClass"
        src="assets/icons/gl_down_arrow.svg"
      />
      <div class="period__list" *ngIf="showPeriodList">
        <div
          class="period__list__item single-check"
          *ngFor="let period of periods"
          (click)="selectPeriod(period)"
          [ngClass]="{
            active: selectedPeriod === period.value
          }"
        >
          <img
            *ngIf="selectedPeriod === period.value"
            class="active-check"
            src="assets/icons/gl_check.svg"
          />
          {{ period.label | translate }}
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!isResponsive">
    <div
      *ngIf="showPeriodList"
      class="close__wrapper"
      (click)="periodListToggle()"
    ></div>
  </ng-container>
</div>

<!-- mobile display -->
<ng-container *ngIf="isResponsive">
  <div class="list-dropdown-select safe-area-padding no-overflow-y">
    <div class="mobile-display">
      <div
        *ngIf="showPeriodList"
        class="mobile-list-wrapper"
        (click)="showPeriodList = !showPeriodList"
      ></div>
      <div *ngIf="showPeriodList" class="list-box">
        <h4>{{ 'person.create.form.select' | translate }}</h4>
        <div *ngIf="isLoading" style="text-align: center; width: 100%">
          <mat-progress-spinner
            mode="indeterminate"
            [diameter]="60"
            style="margin: 40px auto"
          ></mat-progress-spinner>
        </div>
        <div class="list-wrapper">
          <div
            class="list-item"
            *ngFor="let period of periods"
            [ngClass]="{ active: selectedPeriod === period.value }"
            (click)="selectPeriod(period); periodListToggle()"
            style="padding-left: 28px"
          >
            <img
              *ngIf="selectedPeriod === period.value"
              class="active-check"
              style="visibility: visible"
              src="assets/icons/gl_check.svg"
            />
            {{ period.label | translate }}
          </div>
        </div>
        <div class="text-center close-btn-wrapper">
          <img
            (click)="periodListToggle()"
            class="close-btn"
            src="/assets/icons/btn_close.svg"
            alt="close"
          />
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!!errorMessage">
  <div class="error-message">
    {{ errorMessage | translate }}
  </div>
</ng-container>
