import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClockAbsenceComponent } from './clock-absence.component';

const routes: Routes = [
  {
    path: '',
    component: ClockAbsenceComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClockAbsenceRoutingModule {}
