import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentsRoutingModule } from './payments-routing.module';
import { PaymentsComponent } from './payments.component';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentActiveComponent } from './payment-active/payment-active.component';
import { PaymentOnbardingProcessComponent } from './payment-onbarding-process/payment-onbarding-process.component';
import { TacliaStepperModule } from './payment-onbarding-process/taclia-stepper/taclia-stepper.module';
import { ModalTemplateModule } from '@web-frontend/shared/components/modal-template';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { PaymentDashboardComponent } from './payment-dashboard/payment-dashboard.component';
import { LoadingModule } from '@web-frontend/shared/components/loading';
import { EnablePaymentsBannerComponent } from './enable-payments-banner/enable-payments-banner.component';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PermissionsModule } from '@web-frontend/shared/components/permissions';

@NgModule({
  declarations: [
    PaymentsComponent,
    PaymentActiveComponent,
    PaymentOnbardingProcessComponent,
    PaymentStatusComponent,
    PaymentDashboardComponent,
    EnablePaymentsBannerComponent,
  ],
  imports: [
    CommonModule,
    PaymentsRoutingModule,
    TacliaStepperModule,
    TranslateModule,
    LoadingModule,
    ModalTemplateModule,
    RouterModule,
    MatProgressSpinnerModule,
    // PaymentPrototipeModule,
    PermissionsModule,
  ],
  exports: [EnablePaymentsBannerComponent],
})
export class PaymentsModule {}
