import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';

import { CreatePaymentsLinkService } from './services/create-payments-link.component.service';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { finalize } from 'rxjs/operators';

interface PaymentLinkData {
  ticketId: string;
  products: { price: number; quantity: number; name: string }[];
}

@Component({
  selector: 'roma-create-payments-link',
  templateUrl: './create-payments-link.component.html',
  styleUrls: ['./create-payments-link.component.scss'],
})
export class CreatePaymentsLinkComponent implements OnInit {
  public paymentLinkForm: FormGroup = new FormGroup({
    price: new FormControl(0, [Validators.required, Validators.min(0)]),
    quantity: new FormControl(1, [Validators.required, Validators.min(1)]),
    name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    ticketId: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]),
  });

  paymentLink = '';
  isLoading = false;

  constructor(
    private router: Router,
    private createPaymentsLink: CreatePaymentsLinkService,
    private clipboard: Clipboard,
    private toastService: ToastService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  back() {
    this.router.navigateByUrl('/admin/payments');
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  onSubmit() {
    if (this.paymentLinkForm.invalid) return;

    const payload: PaymentLinkData = {
      ticketId: this.paymentLinkForm.value.ticketId,
      products: [
        {
          price: this.paymentLinkForm.value.price,
          name: this.paymentLinkForm.value.name,
          quantity: this.paymentLinkForm.value.quantity,
        },
      ],
    };

    this.isLoading = true;
    this.createPaymentsLink
      .generate(payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.draw();
        })
      )
      .subscribe((res) => {
        if ('url' in res) {
          this.paymentLink = res['url'] as string;
        }
      });
  }

  shareLink() {
    this.clipboard.copy(this.paymentLink);
    this.toastService.show({
      text: 'activity.copyLink',
      type: 'success',
      msDuration: 4000,
      class: '',
    });
  }
}
