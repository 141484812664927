import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import {
  BDG_PREFERENCES,
  BudgetPreferences,
  BudgetsSetting,
  numbers_format,
} from '@tacliatech/types';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { Subscription } from 'rxjs';
import { StorageService } from '@web-frontend/shared/services';

@Pipe({
  name: 'currencyFormat',
})
export class CurrencyFormatPipe implements PipeTransform {
  currencys = this.budgetService.findAllCurrencys();
  DEFAULT_CURRENCY_SYMBOL = '€';
  settings?: BudgetPreferences;
  defaultSettings = new BudgetsSetting();
  defaultPreferences = this.defaultSettings.budgetPreferences;
  private sub$ = new Subscription();

  constructor(private budgetService: BudgetService) {
    const bdgPreferences = JSON.parse(StorageService.GetItem(BDG_PREFERENCES));

    if (bdgPreferences && bdgPreferences !== 'undefined') {
      this.settings = bdgPreferences;
    }
  }

  transform(value: number, position = 'suffix'): string {
    let format;
    const number = this.getFormatedCustomerNumber(value);

    switch (position) {
      case 'prefix':
        format = `${this.getCurrencySimbol()}${number}`;
        break;
      case 'suffix':
        format = `${number}${this.getCurrencySimbol()}`;
        break;
      case 'currencyPosition':
        switch (this.getCurrencySimbol()) {
          case '€':
            format = `${number}${this.getCurrencySimbol()}`;
            break;
          case '$':
            format = `${this.getCurrencySimbol()}${number}`;
            break;
          default:
            format = `${this.getCurrencySimbol()}${number}`;
        }
        break;
      default:
        format = `${number}${this.getCurrencySimbol()}`;
        break;
    }
    return format;
  }

  getFormatedCustomerNumber(num: number): string {
    if (!this.settings) {
      this.settings = this.defaultPreferences;
    }
    // @ts-ignore
    const number_format = numbers_format.find(
      (item) => item.id === this.settings?.number_format
    ).decimal_separator;
    const decimals = this.settings?.decimals.toString();
    let locale = '';

    if (number_format === ',') {
      locale = 'es-AR';
    } else {
      locale = 'en-US';
    }

    const digitsInfo = '1.' + decimals + '-' + decimals;

    return formatNumber(num, locale, digitsInfo);
  }

  getCurrencySimbol(): string {
    const format = this.currencys.find(
      (format) => format.id === this.settings?.currency
    );
    return format ? format.value : this.DEFAULT_CURRENCY_SYMBOL;
  }
}
