import { Component, EventEmitter, Input, Output } from '@angular/core';

import {
  IBudgetCommonData,
  TypeBudget,
  TypeAmplitudeBudget,
  IStatus,
} from '@tacliatech/types';
import { TranslateService } from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { UserService } from '@web-frontend/shared/services';
import { STATUSES_BY_BUDGET_TYPE } from '@web-frontend/shared/utils';

@Component({
  selector: 'roma-budget-mobile-list',
  templateUrl: './budget-mobile-list.component.html',
  styleUrls: ['./budget-mobile-list.component.scss'],
})
export class BudgetMobileListComponent {
  _data: IBudgetCommonData[];

  @Input()
  set data(data: MatTableDataSource<IBudgetCommonData>) {
    if (!data) {
      return;
    }
    this._data = data?.data || [];
  }

  @Input() type: TypeBudget;

  @Input()
  budgetTypeAmplitude: TypeAmplitudeBudget; // to use on amplitude events

  @Output()
  checkRowChanges = new EventEmitter<unknown>();

  @Output()
  totalSelected = new EventEmitter<Array<number>>();

  @Output()
  closeServiceCard = new EventEmitter<unknown>();

  selection = new SelectionModel(true, []);

  constructor(
    private i18n: TranslateService,
    private router: Router,
    private amplitudeService: AmplitudeService,
    private userService: UserService
  ) {}

  get isMX(): boolean {
    return this.userService.validateCountryMX();
  }

  getStatus(id: number): string {
    const statusByType: IStatus[] = STATUSES_BY_BUDGET_TYPE[this.type];
    const statusText = statusByType.find((status) => status.id === id)
      ?.translate;
    return statusText ? this.i18n.instant(statusText) : '';
  }

  getStatusColor(id: number): string {
    const statusByType: IStatus[] = STATUSES_BY_BUDGET_TYPE[this.type];
    return statusByType.find((status) => status.id === id)?.color || '#E3EBFA';
  }

  emitTotal(): void {
    const totalSelected = this.selection.selected.map((s) => s.total);
    this.totalSelected.emit(totalSelected);
  }

  public clear() {
    this.selection.clear();
  }

  selectRow(row: IBudgetCommonData): void {
    let event = `${this.budgetTypeAmplitude}_list_select`;

    this.selection.toggle(row);
    this.checkRowChanges.next(this.selection.selected);
    this.emitTotal();

    const check = this.selection.selected.filter((i) => i?._id === row?._id);

    if (check.length === 0) {
      event = null;
    }

    if (event) {
      this.amplitudeService.sendEvent({ event });
    }
  }

  redirect(id: string): void {
    this.closeServiceCard.emit(true);
    this.router.navigate(['/admin/budgets/show'], {
      queryParams: { type: this.type, budget: id },
    });
  }

  getNumberDoc(itemHeader) {
    let numDoc = '';

    // Android null bug
    if (itemHeader && itemHeader.numberDoc && itemHeader.numberDoc !== 'null') {
      numDoc = '-' + itemHeader.numberDoc;
    }

    if (this.isMX) {
      if (!itemHeader.prefix) {
        return this.i18n.instant('billing.notAvailable');
      }
      return `${itemHeader.prefix}${numDoc}`;
    } else {
      return `${itemHeader.prefix ? itemHeader.prefix : '0'}${numDoc}`;
    }
  }
}
