import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';

import { TableModule } from '@web-frontend/shared/components/table/table.module';
import { LoadingModule } from '@web-frontend/shared/components/loading';
import { CreateExpenseModule } from '@web-frontend/shared/components/create-expense';
import { BudgetsModule } from '../../../core/admin/budgets/budgets.module';

import { WrapperExpensesComponent } from './wrapper-expenses/wrapper-expenses.component';
import { ImageSelectorModule } from '../image-selector-v2';
import { EquipmentNotesModule } from '../equipments-notes';
import { RmResponsiveSelectModule } from '../globals/rm-responsive-select';
import { CreateDealComponent } from './create-deal.component';
import { WrapperDetailComponent } from './wrapper-detail/wrapper-detail.component';
import { FieldErrorDisplayModule } from '../field-error-display';
import { AddInternalVendorModule } from '../../../core/admin/internal-vendor/add-internal-vendor';
import { AppendCustomPropertiesModule } from '../append-custom-properties';
import { EditCustomPropertiesModule } from '../edit-custom-properties';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RmStatusSelectModule } from '../globals/rm-status-select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { WrapperSalesComponent } from './wrapper-sales/wrapper-sales.component';
import { RouterModule } from '@angular/router';
import { ClickSequenceModule } from '@web-frontend/shared/directives/click-sequence/click-sequence.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgSelectModule } from '@ng-select/ng-select';
import { RomaNgSelectModule } from '@web-frontend/shared/components/ng-select/roma-ng-select.module';
import { EditableFieldModule } from '../editable-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { WrapperPartOfServiceComponent } from './wrapper-part-of-service/wrapper-part-of-service.component';
import { ImageModule } from '@web-frontend/shared/pipes/image';
import { PreviewPartOfServiceReportModule } from './wrapper-part-of-service/preview-part-of-service-report';
import { SendEmailPartOfServiceModule } from './wrapper-part-of-service/send-email-part-of-service';
import { PermissionsModule } from '../permissions';
import { SignatureModule } from '../signature';
import { SignatureModalModule } from '../signature-modal';
import { ImageFirebasePipe } from '@web-frontend/shared/pipes/image/image-firebase.pipe';

import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { ClickDeleteDealModule } from '@web-frontend/shared/directives/click-delete-deal';
import { SelectItemModule } from './wrapper-part-of-service/select-item/select-item.module';
import { NotesCommentsModule } from '../notes-comments-v2';
import { NgxMaskModule } from 'ngx-mask';
import { RatesSelectTableModule } from '../rates-select-table';
import { RmSelectModule } from '../globals/rm-select';
import { SelectorRecurrenceModule } from '../selector-recurrence';
import { WrapperTasksComponent } from './wrapper-tasks/wrapper-tasks.component';
import { ActivitiesModule } from '../../../core/admin/activities/activities.module';
import { CreateActivityService } from '../create-activity';
import { ExpensesModule } from '../../../core/admin/expenses/expenses.module';
import { TranslateModule } from '@ngx-translate/core';
import { ObligatoryFieldSignModule } from '../obligatory-field-sign/obligatory-field-sign.module';

@NgModule({
  declarations: [
    CreateDealComponent,
    WrapperDetailComponent,
    WrapperExpensesComponent,
    WrapperSalesComponent,
    WrapperPartOfServiceComponent,
    WrapperTasksComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatIconModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FieldErrorDisplayModule,
    ImageSelectorModule,
    EquipmentNotesModule,
    TableModule,
    LoadingModule,
    RmResponsiveSelectModule,
    CreateExpenseModule,
    AddInternalVendorModule,
    RmResponsiveSelectModule,
    AppendCustomPropertiesModule,
    EditCustomPropertiesModule,
    MatMenuModule,
    MatCheckboxModule,
    RmStatusSelectModule,
    MatTooltipModule,
    MatTabsModule,
    BudgetsModule,
    RouterModule,
    ClickSequenceModule,
    NgSelectModule,
    RomaNgSelectModule,
    EditableFieldModule,
    MatDatepickerModule,
    MatFormFieldModule,
    SelectItemModule,
    EditableFieldModule,
    SignatureModule,
    MatIconModule,
    ImageSelectorModule,
    ImageModule,
    SignatureModalModule,
    PreviewPartOfServiceReportModule,
    SendEmailPartOfServiceModule,
    PermissionsModule,
    MatProgressSpinnerModule,
    ClickDeleteModule,
    ClickDeleteDealModule,
    NotesCommentsModule,
    NgxMaskModule,
    RatesSelectTableModule,
    RmSelectModule,
    SelectorRecurrenceModule,
    ExpensesModule,
    ActivitiesModule,
    ObligatoryFieldSignModule,
  ],
  providers: [
    DatePipe,
    ImageFirebasePipe,
    ActivitiesModule,
    CreateActivityService,
  ],
})
export class CreateDealModule {}
