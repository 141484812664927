import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { LoadingModule } from '@web-frontend/shared/components/loading';

import { MatButtonModule } from '@angular/material/button';
import { SelectSearchModule } from '@web-frontend/shared/components/select-search/select-search.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { TrackingService } from '@web-frontend/shared/services/tracking';
import { FilterTrackingComponent } from './filter-tracking.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [FilterTrackingComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild(),
    LoadingModule,
    MatButtonModule,
    SelectSearchModule,
    GoogleMapsModule,
  ],
  providers: [TrackingService],
  exports: [FilterTrackingComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FilterTrackingModule {}
