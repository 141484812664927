import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { LoadingModule } from '../loading';
import { AddressMapComponent } from './component/address-map/address-map.component';
import { GeocodeApiService } from './service/geocode-api.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AddressMapComponent],
  imports: [
    CommonModule,
    GoogleMapsModule,
    LoadingModule,
    TranslateModule.forChild(),
  ],
  providers: [GeocodeApiService],
  exports: [AddressMapComponent],
})
export class AddressMapModule {}
