import { BillStatus } from '../enum';
import { IStatus } from '../interfaces';

export const STATUS_BILL_PENDING = {
  id: BillStatus.Pending,
  name: 'Pendiente de pago',
  translate: 'status.bill.pending',
  order: 1,
  allowEdit: false,
  color: '#C2E6F8',
};

export const STATUS_BILL_PAID = {
  id: BillStatus.Paid,
  name: 'Pagada',
  translate: 'status.bill.paid',
  order: 2,
  allowEdit: false,
  color: '#E1F7EB',
};

export const STATUS_BILL_OVERDUE = {
  id: BillStatus.Overdue,
  name: 'Vencida',
  translate: 'status.bill.overdue',
  order: 3,
  allowEdit: false,
  color: '#FEE7DA',
};

export const statusBill: IStatus[] = [
  STATUS_BILL_PENDING,
  STATUS_BILL_PAID,
  STATUS_BILL_OVERDUE,
].sort((a, b) => (a.order > b.order ? 1 : -1));

export const STATUS_BILL_SORT = [0, 10, 100, 20, 30, 60, 95, 90, 50];
