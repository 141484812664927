import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NgSelectConfig {
  placeholder = '';
  notFoundText = 'No se ha encontrado ningún resultado';
  typeToSearchText = 'Type to search';
  addTagText = 'Add item';
  loadingText = 'Loading...';
  clearAllText = 'Clear all';
  disableVirtualScroll = true;
  openOnEnter = true;
  appendTo = '';
  bindValue = '';
  bindLabel = '';
  appearance = 'underline';
}
