import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private hasOverflowSource = new BehaviorSubject<boolean>(false);

  hasOverflow$ = this.hasOverflowSource.asObservable();

  set overflow(value: boolean) {
    this.hasOverflowSource.next(value);
  }
}
