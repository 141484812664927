import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionsModule } from '@web-frontend/shared/components/permissions';
import { TranslateModule } from '@ngx-translate/core';
import { AddButtonComponent } from './add-button.component';

@NgModule({
  declarations: [AddButtonComponent],
  imports: [CommonModule, TranslateModule.forChild(), PermissionsModule],
  providers: [],
  exports: [AddButtonComponent],
})
export class AddButtonModule {}
