import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { I18nChild } from '@web-frontend/shared/i18n';
import { SelectorRecurrenceComponent } from './selector-recurrence.component';
import { CreatePeriodicityComponent } from './create-periodicity/create-periodicity.component';
import { ModalTemplateModule } from '../modal-template';
import { ChooseWeekComponent } from './choose-week/choose-week.component';
import { SplitModule } from '@web-frontend/shared/pipes/split';
import { NgxMaskModule } from 'ngx-mask';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [
    SelectorRecurrenceComponent,
    CreatePeriodicityComponent,
    ChooseWeekComponent,
  ],
  imports: [
    CommonModule,
    ModalTemplateModule,
    FormsModule,
    I18nChild,
    SplitModule,
    NgxMaskModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
  ],
  exports: [
    SelectorRecurrenceComponent,
    CreatePeriodicityComponent,
    ChooseWeekComponent,
  ],
})
export class SelectorRecurrenceModule {}
