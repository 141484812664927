import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ModalErrorConnectionData {
  canRetry: boolean;
}

@Component({
  selector: 'roma-modal-error-connection',
  templateUrl: './modal-error-connection.component.html',
  styleUrls: ['./modal-error-connection.component.scss'],
})
export class ModalErrorConnectionComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalErrorConnectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: ModalErrorConnectionData
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public retry(): void {
    location.reload();
  }
}
