import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { App, ICreateExpenseDto, IExpense, IUser } from '@tacliatech/types';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExpenseService {
  private sub$ = new Subscription();

  private expensesCache = new BehaviorSubject<App.ResourceCached<IExpense[]>>([
    'INIT',
    [],
  ]);

  expenses$ = this.expensesCache.asObservable();

  constructor(private http: HttpClient) {}

  public get expenses() {
    return this.expensesCache.value[1];
  }

  init() {
    this.expensesCache.next(['LOADING', []]);

    this.sub$.add(
      this.generalSearchEngine().subscribe((res) => {
        this.expensesCache.next(['LOADED', res]);
      })
    );
  }

  generalSearchEngine() {
    return this.http.get<IExpense[]>(`:API_URL/expenses/general-search-engine`);
  }

  findAll() {
    return this.http.get<IExpense[]>(`:API_URL/expenses/`);
  }

  findAuthors(id: string) {
    return this.http.get<IUser[]>(`:API_URL/expenses/authors/${id}`);
  }

  findOne(id: string) {
    return this.http.get<IExpense>(`:API_URL/expenses/${id}`);
  }

  findOwners(id: string) {
    return this.http.get<IUser[]>(`:API_URL/expenses/owners/${id}`);
  }

  findByUser(id: string) {
    return this.http.get<IExpense[]>(`:API_URL/expenses/users/${id}`);
  }

  findByEquipment(id: string) {
    return this.http.get<IExpense[]>(`:API_URL/expenses/equipment/${id}`);
  }

  findByFinal(id: string) {
    return this.http.get<IExpense[]>(`:API_URL/expenses/final/${id}`);
  }

  findByProject(id: string) {
    return this.http.get<IExpense[]>(`:API_URL/expenses/project/${id}`);
  }

  findByAsset(id: string) {
    return this.http.get<IExpense[]>(`:API_URL/expenses/assets/${id}`);
  }

  findByDeals(id: string, deals: any) {
    return this.http.post<IExpense[]>(`:API_URL/expenses/deals/${id}`, deals);
  }

  findByVendor(id: string) {
    return this.http.get<IExpense[]>(`:API_URL/expenses/vendor/${id}`);
  }

  create(data: ICreateExpenseDto) {
    return this.http.post(`:API_URL/expenses`, { ...data });
  }

  updateOne(id: string, params = {}) {
    return this.http.put(`:API_URL/expenses/${id}`, { ...params });
  }

  removeOne(id: string) {
    return this.http.delete(`:API_URL/expenses/${id}`);
  }

  markDoneOne(id: string) {
    return this.http.delete(`:API_URL/expenses/markasdone/${id}`);
  }

  restoreMany(data) {
    return this.http.post(`:API_URL/expenses/restore-many`, data);
  }

  deleteMany(data) {
    return this.http.post(`:API_URL/expenses/delete-many`, data);
  }
}
