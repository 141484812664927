export class PartOfServiceTemplate {
  translations = [
    'client',
    'notAvailable',
    'partOfService',
    'title',
    'description',
    'project',
    'address',
    'adicionalAddress',
    'date',
    'urgent',
    'active',
    'photos',
    'partOfServiceDescription',
    'signature',
  ];
  template = `<style>
  .mat-body,
  .mat-body-1,
  .mat-typography {
    font-size: 12px;
    letter-spacing: normal;
  }
  body {
    display: block;
    margin: 8px;
    font-size: 15px;
    height: 100%;
    overflow-x: hidden;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-size: 10px;
  }
  *,
  ::after,
  ::before {
    box-sizing: border-box;
    font-size: 10px;
  }
  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
  }
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    width: 100%;
    padding: 5%;
  }
  .header-line1 {
    height: 130px;
  }
  .header-line2 {
    height: 150px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .w-100 {
    width: 100% !important;
  }
  .d-flex {
    display: flex !important;
  }
  .justify-content-center {
    justify-content: center !important;
  }
  .line1 {
    background-color: white;
    border-color: black;
  }
  .line2 {
    background-color: white;
    border-color: black;
  }
  .p-3 {
    padding: 1rem !important;
  }
  img {
    border-style: none;
    vertical-align: middle;
    width: 100px;
    aspect-ratio: auto 100 / 100;
    height: 100px;
  }
  .taclia-color {
    color: #3c48ec;
  }
  .justify-content-end {
    justify-content: flex-end !important;
  }
  .gray-line {
    color: darkgray;
  }
  .black-line {
    color: black;
  }
  .justify-content-start {
    justify-content: flex-start !important;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6;
    caption-side: bottom;
    border-collapse: collapse;
  }
  .table-primary {
    color: #000;
    border-color: #bacbe6;
  }
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
  th {
    color: white;
    background-color: #3c48ec;
    text-align: center;
  }
  tr {
    text-align: center;
  }
  .table-bordered > :not(caption) > * {
    border-width: 1px 0;
  }
  .table-header {
    width: 100%;
  }
  .image-bordered {
    border: 1px solid #dee2e6;
  }
  .container-part-item strong,
  .container-part-item p {
    margin: 0;
  }

  .container-part-item strong {
    margin-bottom: 5px;
    font-weight: bold;
  }

  .container-part-item {
    padding-bottom: 10px;
  }

  .element-title {
    font-size: 15px;
    font-weight: bold;
  }
</style>
<div id="htmlData">
  <div class="d-flex w-100 red mt-10">
    <div class="p-3 line1 w-100">
      {{? it.clientLogo }}
        <div class="logo">
          <img
            class="mr-3"
            width="150"
            height="150"
            src="{{=it.clientLogo}}"
            alt="Imagen del servicio"
          />
        </div>
      {{?}}
    </div>
    <div class="p-3 line2 w-100">
    </div>
    <div class="p-3 line3 w-100">
    </div>
    <div class="p-3 line4 w-100">
      <div class="d-flex justify-content-start taclia-color">
      {{? it.companyName}}{{=it.companyName}}{{?}}
      </div>
      <div class="d-flex justify-content-start gray-line">
      {{? it.companyTradeName}}{{=it.companyTradeName}}{{?}}
      </div>
      <div class="d-flex justify-content-start gray-line">
      {{? it.companyCode}}{{=it.companyCode}}{{?}}
      </div>
      <div class="d-flex justify-content-start gray-line">
      {{? it.companyPhone}}{{=it.companyPhone}}{{?}}
      </div>
      <div class="d-flex justify-content-start gray-line">
      {{? it.companyEmail}}{{=it.companyEmail}}{{?}}
      </div>
    </div>
  </div>
  <div class="d-flex w-100">
    <table class="table-header">
      <tr>
        <td style="width: 70%; text-align: left">
          <div class="p-3 line2 w-100">
            {{? it.clientName }}
              <div class="d-flex justify-content-start taclia-color">{{=it.translations.client}}</div>
              <div class="d-flex justify-content-start black-line">
                {{=it.clientName}}
              </div>

              <div class="d-flex justify-content-start black-line">
              {{? it.clientFiscalName }}{{=it.clientFiscalName}}{{?}}
              </div>

              <div class="d-flex justify-content-start black-line">
              {{? it.clientEmail }}{{=it.clientEmail}}{{?}}
              </div>

              <div class="d-flex justify-content-start black-line">
              {{? it.clientAddress }}{{=it.clientAddress}}{{?}}
              </div>
            {{??}}
            <div class="d-flex justify-content-start black-line">
              {{=it.translations.notAvailable}}
            </div>
            {{?}}
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div class="d-flex w-100">
    <table class="table-header">
      <tr>
        <td style="width: 100%; text-align: left">
          {{? it.dealTitle != null}}
          <h2 class="element-title">{{=it.translations.partOfService}}</h2>
          <div class="container-part-item">
            <strong>{{=it.translations.title}}</strong>
            <p>{{=it.dealTitle}}</p>
          </div>

          {{?}} {{? it.dealDescription != null}}
          <div class="container-part-item">
            <strong>{{=it.translations.description}}</strong>
            <p>{{=it.dealDescription}}</p>
          </div>
          {{?}} {{? it.dealClient != null}}
          <div class="container-part-item">
            <strong>{{=it.translations.client}}</strong>
            <p>{{=it.dealClient}}</p>
          </div>
          {{?}} {{? it.dealProject != null}}
          <div class="container-part-item">
            <strong>{{=it.translations.project}}</strong>
            <p>{{=it.dealProject}}</p>
          </div>
          {{?}} {{? it.dealAddress != null}}
          <div class="container-part-item">
            <strong>{{=it.translations.address}}</strong>
            <p>{{=it.dealAddress}}</p>
          </div>
          {{?}} {{? it.dealAddressAdditional != null}}
          <div class="container-part-item">
            <strong>{{=it.translations.adicionalAddress}}</strong>
            <p>{{=it.dealAddressAdditional}}</p>
          </div>
          {{?}} {{? it.dealDate != null}}
          <div class="container-part-item">
            <strong>{{=it.translations.date}}</strong>
            <p>{{=it.dealDate}}</p>
          </div>
          {{?}} {{? it.dealEquipment != null && it.dealEquipment.length != null && it.dealEquipment.length != 0}}
          <div class="container-part-item">
            <strong>{{=it.translations.active}}</strong>
            <p>{{=it.dealEquipment}}</p>
          </div>
          {{?}}{{? it.customProperties != null && it.customProperties != ' ' && it.customProperties.length > 0}} {{~ it.customProperties:property }}
          <div class="container-part-item">
            <strong>{{=property.title}}</strong>
            <p>{{=property.value}}</p>
          </div>
          {{~}} {{?}} {{? it.customGroupProperties != null && it.customGroupProperties != ' '  && it.customGroupProperties.length > 0}}  {{~ it.customGroupProperties:property }}
          <div class="container-part-item">
            <strong>{{=property.title}}</strong>
            <p>{{=property.value}}</p>
          </div>
          {{~}} {{?}}
        </td>
      </tr>
      {{? it.partOfServiceImages }}

      <!-- Imagenes del servicio -->
      <tr>
        <td style="width: 100%; text-align: left">
          <h2 class="element-title">{{=it.translations.photos}}</h2>
          <div class="image-medias" style="display: flex">
            {{~ it.partOfServiceImages:image  }}
            <img
              class="mr-3"
              width="50"
              height="50"
              src="{{=image}}"
              alt="Imagen del servicio"
            />
            {{~}}
          </div>
        </td>
      </tr>
      <!-- FIN Imagenes del servicio -->
      {{?}}

      <tr>
        <td style="width: 100%; text-align: left">
          <h2 class="element-title">{{=it.translations.partOfServiceDescription}}</h2>
          <p>{{=it.partOfServiceDescription}}</p>
        </td>
      </tr>
      
      {{? it.partOfServiceSignatureClient }}
      <tr>
        <td style="width: 100%; text-align: left">
          <h2 class="element-title">{{=it.translations.signature}}</h2>
        </td>
      </tr>
      <tr style="text-align: left; width: 100%">
        <td style="width: 100%">
          <div style="display: inline-block">
            <div class="image" style="display: block; text-align: left">
              <img
                class="mr-3 image-bordered"
                width="100"
                height="100"
                src="{{=it.partOfServiceSignatureClient}}"
                alt="Firma cliente"
              />
              <p>Cliente</p>
            </div>
          </div>
          <div style="display: inline-block; margin-left: 10px">
            <div class="image" style="display: block; text-align: left">
              <img
                class="image-bordered"
                width="100 "
                height="100"
                src="{{=it.partOfServiceSignatureCompany}}"
                alt="Empresa"
              />
              <p>Empresa</p>
            </div>
          </div>
        </td>
      </tr>
      {{?}}

      <tr style="text-align: left">
        <td style="width: 100%">
          <strong> {{=it.partOfServiceDate }} </strong>
        </td>
      </tr>
    </table>
  </div>
</div>
`;
}
