/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const replacementsMX = {
  'gestionar el <b>control horario</b> y las ausencias':
    'Checar <b>asistencia</b> y gestionar ausencias',
  'control horario': 'Checador de asistencia',
  'fichajes de equipo': 'Asistencias de equipo',
  'fichajes del equipo': 'Asistencias de equipo',
  'todos los fichajes': 'todas las asistencias',
  'los fichajes': 'las asistencias',
  'ficha tus horas trabajadas en tiempo real o añádelas':
    'Haz tu primera checada en tiempo real o añádela',
  fichajes: 'asistencias',
  fichado: 'Checado',
  fichados: 'checados',
  fichaje: 'Asistencia',
  fichar: 'checar',
};

export const replaceTextsInObject = (obj, replacements) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const newObj = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    let value: string = obj[key];

    if (typeof value === 'string') {
      for (const replaceKey in replacements) {
        const lowerCaseValue = value.toLowerCase();
        const lowerCaseKey = replaceKey.toLowerCase();

        if (lowerCaseValue.includes(lowerCaseKey)) {
          const regex = new RegExp(lowerCaseKey, 'gi');
          value = value.replace(regex, replacements[replaceKey]);
        }
      }
    } else if (typeof value === 'object') {
      value = replaceTextsInObject(value, replacements);
    }
    newObj[key] = value;
  }

  return newObj;
};
