import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  IBudgetsSetting,
  IUser,
  StatusSalesId,
  ValidateAllFormFields,
} from '@tacliatech/types';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import { CurrencyFormatPipe } from '@web-frontend/shared/pipes/currency-format/currency-format.pipe';
import {
  AuthService,
  SalesService,
  StorageService,
} from '@web-frontend/shared/services';
import * as moment from 'moment-timezone';
import { forkJoin, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';

@Component({
  selector: 'roma-send-email-sale-modal',
  templateUrl: './send-email-sale-modal.component.html',
  styleUrls: ['./send-email-sale-modal.component.scss'],
  providers: [CurrencyFormatPipe],
})
export class SendEmailSaleModalComponent implements OnInit {
  form: FormGroup = this.fb.group({
    message: new FormControl(null, []),
    to: new FormControl(null, [Validators.required]),
    subject: new FormControl(null),
  });
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  destinations: string[] = [];
  isLoading = false;
  customMessage: string;
  removable = true;
  userActive$ = this.authService.user$;
  userActive: IUser;
  userAddress = null;
  private sub$ = new Subscription();
  public innerWidth: number;
  title = '';
  companyData: any;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.title = this.i18n.instant('general.sendByEmailLong');
    } else {
      this.title = this.i18n.instant('deal_part_of_service.sendByEmail');
    }
  }

  constructor(
    private dialogRef: MatDialogRef<SendEmailSaleModalComponent>,
    private modalService: MatDialog,
    public i18n: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private salesService: SalesService,
    private alertService: AlertService,
    private currencyFormatPipe: CurrencyFormatPipe,
    private authService: AuthService,
    private budgetService: BudgetService,
    private toastService: ToastService,
    private readonly brazeService: BrazeService
  ) {}

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.title = this.i18n.instant('general.sendByEmailLong');
    } else {
      this.title = this.i18n.instant('deal_part_of_service.sendByEmail');
    }

    this.userActive$.subscribe((res) => {
      this.userActive = res;
    });

    const id = StorageService.CompanyId;

    this.sub$.add(
      forkJoin([this.budgetService.findSettingsByCompany(id)])
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(
          (result) => {
            const settings = result[0] as IBudgetsSetting;
            // @ts-ignore
            this.userAddress = settings.billing_addres;
            this.companyData = settings.data_account;
          },
          (error) => {
            this.isLoading = false;
          }
        )
    );
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (
      value &&
      this.destinations.indexOf(value) === -1 &&
      value.match?.(EMAIL_REGEX)
    ) {
      this.destinations.push(value);
      this.patchTo();
    }
    // Clear the input value
    event.chipInput!.clear();
  }

  remove(to: string): void {
    const index = this.destinations.indexOf(to);

    if (index >= 0) {
      this.destinations.splice(index, 1);
      this.patchTo();
    }
  }

  patchTo() {
    this.form.patchValue({ to: this.destinations });
  }

  close() {
    this.dialogRef.close();
  }

  send() {
    this.form.controls['to'].setValue(this.destinations);
    this.form.controls['to'].updateValueAndValidity();
    const isValid = this.checkValidators();
    if (isValid) {
      this.isLoading = true;
      const saleData = this.data.saleStorage;

      const messageBody = this.formatEmailMessage(this.form.value.message);
      const companyInfo = JSON.parse(StorageService.companyData);
      const bussinessName =
        this.companyData?.company_name ||
        this.companyData?.tradename ||
        this.companyData?.fiscalName ||
        companyInfo?.name;
      // @ts-ignore
      const data = {
        emails: this.form.value.to,
        template: {
          subject: '',
          Client_Name: saleData?.sale?.final?.title
            ? this.capitalize(saleData?.sale?.final?.title)
            : 'Estimado cliente',
          Total_Sale: this.currencyFormatPipe.transform(saleData?.sale?.total),
          Sale_Number: saleData?.sale?.number,
          Sale_Date: moment
            .tz(
              saleData?.sale?.paymentDate,
              Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ||
                'Europe/Madrid'
            )
            .format('DD/MM/YYYY'),
          Sale_Hour: moment
            .tz(
              saleData?.sale?.paymentDate,
              Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ||
                'Europe/Madrid'
            )
            .format('HH:mm'),
          // @ts-ignore
          Bussiness_Address1: this.userAddress?.address
            ? // @ts-ignore
              `${this.userAddress?.address}`
            : '',
          Bussiness_Address2:
            // @ts-ignore
            this.userAddress?.postalcode && this.userAddress?.state
              ? // @ts-ignore
                `${this.userAddress?.city}, ${this.userAddress?.state}, ${this.userAddress?.country} ${this.userAddress?.postalcode}`
              : '',

          Bussiness_Name: this.capitalize(bussinessName),
          Email_Body: messageBody,
          Empty_Var: '',
        },
      };

      const statusMap = {
        [StatusSalesId.NotPaid]: BrazeEventType.sale_ticket_not_payed,
        [StatusSalesId.PaymentPending]: BrazeEventType.sale_payment_link,
        [StatusSalesId.Paid]: BrazeEventType.sale_ticket_payed,
      };

      const status = saleData?.sale?.idStatus;
      if (status && statusMap[status]) {
        this.brazeService.sendEvent({
          event: statusMap[status],
        });
      }

      this.salesService.sendEmail(this.data.idSale, data).subscribe((resp) => {
        console.log(
          '🚀 ~ file: send-email-sale-modal.component.ts:102 ~ SendEmailSaleModalComponent ~ this.salesService.sendEmail ~ resp:',
          resp
        );
        // this.alertService.success(this.i18n.instant('general.mailSent'));
        this.toastService.show({
          text: 'general.mailSent',
          type: 'success',
          msDuration: 4000,
        });

        this.isLoading = false;
        this.close();
      });
    }
  }

  private formatEmailMessage($message) {
    if ($message && $message !== null) {
      return $message.replaceAll('\n', '<br/>');
    } else {
      return '';
    }
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);
    return this.form.valid;
  }

  private capitalize(string) {
    return string ? string.charAt(0)?.toUpperCase() + string.slice(1) : '';
  }
}
