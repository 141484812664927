import { IProduct } from '@tacliatech/types';
import { SkillDTO } from '../interfaces';

export class Product {
  extra1: string;
  extra2: unknown;
  extra3: unknown;
  marginClient: number;
  marginSupplier: number;
  minim_hours: unknown;
  mo_hour: unknown;
  name: string;
  price: number;
  price2: number;
  transport: unknown;
  type: string;
  _id: string;
  skills?: SkillDTO;
  deleted?: boolean;

  static fromArray(attributes: IProduct[]) {
    return attributes.map((attribute) => new Product(attribute));
  }

  static skills() {
    return [
      'Reparaciones generales',

      'Climatización',

      'Pintura',

      'Electricidad',

      'Fontanería',

      'Montaje',

      'Mudanzas',

      'Limpieza',
    ];
  }

  constructor(attribute?: IProduct) {
    const price2 = attribute?.price2 ? attribute.price2 : 0;

    Object.assign(this, attribute, { price2 });
  }

  isEqual(product: Product) {
    return this._id === product?._id;
  }

  copy(product: Product) {
    Object.assign(this, product);
  }
}
