export const SharedBusinessErrors = {
  GeneralError: {
    apiErrorCode: 'SHA_0001_0001',
    errorMessage: `Oops! Something went wrong! Help us improve your experience by sending an error report`,
    reason: 'Internal server error',
  },
  DbSaveError: {
    apiErrorCode: 'SHA_0001_0002',
    errorMessage: `Error when trying to save resource into the DB`,
    reason: 'Internal server error',
  },

  DbConnError: {
    apiErrorCode: 'SHA_0001_0003',
    errorMessage: 'Error when trying to connect into the mongo db',
    reason: 'Internal server error',
  },
  FileMaxSizeError: {
    apiErrorCode: 'SHA_0001_0004',
    apiErrorCode_10: 'SHA_0001_0004_1',
    errorMessage: '[Image selector] file size exceeds 20MB',
    reason: 'Internal server error',
  },
  ConvertDTOError: (param) => {
    return {
      apiErrorCode: 'SHA_0001_0005',
      errorMessage: `${param}`,
      reason: `Error convirtiendo DTO object`,
    };
  },
  generateInternalError: (param) => {
    return {
      apiErrorCode: 'SHA_0001_0006',
      errorMessage: `${param}`,
      reason: `Internal Server error`,
    };
  },

  // ... other errors
};
