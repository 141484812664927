export class BudgetTemplate {
  translations = [
    'client',
    'budgetMayus',
    'proformMayus',
    'billMayus',
    'expiration',
    'concept',
    'quantity',
    'price',
    'subtotal',
    'tax',
    'retention',
    'recEq',
    'total',
  ];
  template = `
    <div id="pageHeader" style="padding-bottom: 50px;" class="d-flex w-100">
        <div class="p-3 line1 w-100">
            {{? it.logo !== null }}
            <div class="logo">
                <img
                    class="mr-3"
                    width="150"
                    height="150"
                    src="{{=it.logo}}"
                    alt="Imagen del servicio"
                />
            {{??}}
            <div class="mr-3" width="100" height="100"></div>
            {{?}}
            </div>
        </div>
        <div class="p-3 line2 w-100">
        </div>
        <div class="p-3 line3 w-100">
        </div>
        <div class="p-3 line4 w-100">
            <div class="d-flex justify-content-end">
            <strong>{{=it.company_name}}</strong>
            </div>
            <div class="d-flex justify-content-end black">{{=it.fiscalName}}</div>
            <div class="d-flex justify-content-end black">{{=it.tradename}}</div>
            <div class="d-flex justify-content-end black">{{=it.phone}}</div>
            <div class="d-flex justify-content-end black">{{=it.email}}</div>
            <div class="d-flex justify-content-end black">{{=it.address}}</div>
        </div>
    </div>
    <div class="d-flex w-100" style="padding: 0 30px">
        <div class="">
            <div class="d-flex justify-content-start">
            <strong>{{=it.translations.client}}</strong>
            </div>
            <div class="d-flex justify-content-start black-line">
            <strong> {{=it.final_name}}</strong>
            </div>
            <div class="d-flex justify-content-start black-line">
            {{=it.final_fiscalName}}
            </div>
            <div class="d-flex justify-content-start black-line">
            {{=it.final_nif}}
            </div>
            <div class="d-flex justify-content-start black-line">
            {{=it.final_email}}
            </div>
            <div class="d-flex justify-content-start black-line">
            {{=it.final_address}}
            </div>
            <div class="d-flex justify-content-start black">
            {{~ it.phones:phone }}
            <span>{{=phone.p}}</span>
            {{~}}
            </div>
        </div>
        <div class="p-3 line2 w-100">
            <div class="d-flex justify-content-end">
            {{? it.type == 'BDG' }}
            <span class="black">
                <strong>{{=it.translations.budgetMayus}}</strong>
            </span>
            {{?? it.type == 'PROFM' }}
            <span class="black">
                <strong>{{=it.translations.proformMayus}}</strong>
            </span>
            {{?? it.type == 'BILL' }}
            <span class="black">
                <strong>{{=it.translations.billMayus}}</strong>
            </span>
            {{?}}&nbsp{{=it.prefix}} - {{=it.numberDoc }}
            </div>
            <div class="d-flex justify-content-end gray-line">
            {{? it.date.length > 0 }}
            <span style="color: black">{{=it.translations.date}}:</span>
            <span style="color: black">{{=it.date }}</span>
            {{?}}
            </div>
            <div class="d-flex justify-content-end gray-line">
            {{? it.dueDate.length > 0 }}
            <span style="color: black">{{=it.translations.expiration}}:</span>
            <span style="color: black"> {{=it.dueDate }}</span>
            {{?}}
            </div>
        </div>
    </div>
    <br>
    <div class="d-flex w-100" style="padding: 0 30px">
        <div class="w-100">
        <table id="table-preview" class="table table-bordered">
            <tr class="table-primary">
            <th style="text-align: left; padding-left: 1rem" class="description">
            {{=it.translations.concept}}
            </th>
            <th style="text-align: center" style="width: 100px">{{=it.translations.quantity}}</th>
            <th style="text-align: center; width: 71px">{{=it.translations.price}}</th>
            <th style="text-align: center; width: 90px">{{=it.translations.subtotal}}</th>
            {{? it.ivap.length > 0 }}
            <th style="text-align: center; width: 95px">{{=it.translations.tax}}</th>
            {{?}} {{? it.retp.length > 0 }}
            <th style="text-align: center; width: 100px">{{=it.translations.retention}}</th>
            {{?}} {{? it.reqp.length > 0 }}
            <th style="text-align: center; width: 90px">{{=it.translations.recEq}}</th>
            {{?}}
            <th style="text-align: right; padding-right: 1rem">{{=it.translations.total}}</th>
            </tr>
            {{~ it.items:item }}
            <tr>
            <td class="description">
                <div class="desc-line-1" style="text-align: left; padding-left: 1rem">
                {{=item.concept }}
                </div>
                <div class="desc-line-2" style="text-align: left; padding-left: 1rem">
                {{? item.description }}{{=item.description }}{{?}}
                </div>
            </td>
            <td style="text-align: center; width: 156px">{{=item.quantity }}</td>
            <td style="text-align: center; width: 71px">{{=item.price }}</td>
            <td style="text-align: center; width: 90px">{{=item.subtotal }}</td>
            {{? it.ivap.length > 0 }}
            <td style="text-align: center; width: 95px">
                {{~ item.taxes:tax }}
                <div>{{? tax.type == 'IVA' }} {{=tax.value }}%</div>
                {{?}} {{~}}
            </td>
            {{?}} {{? it.retp.length > 0 }}
            <td style="text-align: center; width: 100px">
                {{~ item.taxes:tax }}
                <div>{{? tax.type == 'RETENTION' }} -{{=tax.value }}%</div>
                {{?}} {{~}}
            </td>
            {{?}} {{? it.reqp.length > 0 }}
            <td style="text-align: center; width: 90px">
                {{~ item.taxes:tax }}
                <div>{{? tax.type == 'REC' }} {{=tax.value }}%</div>
                {{?}} {{~}}
            </td>
            {{?}}
            <td style="text-align: right; padding-right: 1rem">{{=item.total }}</td>
            </tr>
            {{~}}
        </table>
        <table class="table table-bordered">
            <tr class="base">
                <td width="90%" style="text-align: right; padding-right: 1rem">
                    {{=it.translations.subtotal}}
                </td>
                <td width="10%" style="text-align: right; padding-right: 1rem">
                    {{=it.subtotal}}
                </td>
            </tr>
            {{~ it.ivap:i }}
            <tr class="base">
                <td width="90%" style="text-align: right; padding-right: 1rem">
                    {{=it.translations.tax}} {{=i.key}}%
                </td>
                <td width="10%" style="text-align: right; padding-right: 1rem">
                    {{=i.value}}
                </td>
            </tr>
            {{~}} {{~ it.reqp:i }}
            <tr class="base">
                <td width="90%" style="text-align: right; padding-right: 1rem">
                    {{=it.translations.recEq}} {{=i.key}}%
                </td>
                <td width="10%" style="text-align: right; padding-right: 1rem">
                    {{=i.value}}
                </td>
            </tr>
            {{~}} {{~ it.retp:i }}
            <tr class="base">
                <td width="90%" style="text-align: right; padding-right: 1rem">
                    {{=it.translations.retention}} {{=i.key}}%
                </td>
                <td width="10%" style="text-align: right; padding-right: 1rem">
                    {{=i.value}}
                </td>
            </tr>
            {{~}}
            <tr>
                <td width="90%" style="text-align: right; padding-right: 1rem">
                    <b>{{=it.translations.total}}</b>
                </td>
                <td width="10%" style="text-align: right; padding-right: 1rem">
                    <b>{{=it.total}}</b>
                </td>
            </tr>
        </table>
        </div>
    </div>
    
    <!-- MESSAGES -->
    <div class="comments-footer" style="text-align:center">
        <wbr />
        {{=it.line1}}
        <wbr />
    </div>
    <div class="comments-footer">
        {{=it.textShow}}
    </div>
    `;
}
