import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  DateAdapter as AdapterLang,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { RecurrenceGlobal } from '@tacliatech/types';
import { DateAdapter } from 'angular-calendar';
import { filter } from 'rxjs/operators';
import { APP_DATE_FORMATS, AppDateAdapter } from '../filter/filter.component';
import { CreatePeriodicityService } from './create-periodicity/create-periodicity.service';
import { CreatePeriodicityParams } from './create-periodicity/create-periodicity.types';
import { SelectRecurrenceOutput } from './select-recurrence.types';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { format } from 'date-fns';

@Component({
  selector: 'roma-selector-recurrence',
  templateUrl: './selector-recurrence.component.html',
  styleUrls: ['./selector-recurrence.component.scss'],
  providers: [
    { provide: DateAdapter, useExisting: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectorRecurrenceComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  dateStorage: Date;

  @Input()
  recurrence!: RecurrenceGlobal.Storage;

  @Input()
  fromCalendar = false;

  @Input()
  disabled = false;

  @Input()
  showSelectorRecurrence = true;

  @Input()
  showHoursInput = true;

  @Output()
  changeRecurrence = new EventEmitter<SelectRecurrenceOutput>();

  selectedHour = '';
  formatedDate;

  private sub$ = new Subscription();

  constructor(
    private cdRef: ChangeDetectorRef,
    private createPeriodicityService: CreatePeriodicityService,
    //private datePipe: DatePipe,
    private adapterLng: AdapterLang<Date>,
    private i18n: TranslateService
  ) {}

  ngOnInit(): void {
    this.setDateLang();
  }

  ngAfterViewInit(): void {
    this.attachSelectedHour();

    if (this.dateStorage && this.fromCalendar) {
      this.emitChanges();
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  private setDateLang() {
    const resolveLangDatePicker = () => {
      const lang = this.i18n.currentLang;
      const pickerLang = lang === 'es' ? 'es-ES' : 'en-EN';
      this.adapterLng.setLocale(pickerLang);
    };

    resolveLangDatePicker();

    this.sub$.add(
      this.i18n.onLangChange.subscribe(() => {
        resolveLangDatePicker();
      })
    );
  }

  private attachSelectedHour() {
    try {
      if (this.dateStorage.toString() == 'Invalid Date') {
        // @ts-ignore
        this.dateStorage = null;
      }
      if (
        this.dateStorage &&
        (this.dateStorage.getHours() ||
          this.dateStorage.getMinutes() ||
          this.dateStorage.getSeconds())
      ) {
        const def = `${this.completeDigits(
          this.dateStorage.getHours()
        )}:${this.completeDigits(this.dateStorage.getMinutes())}`;

        if (def?.match('00:00')) {
          this.selectedHour = '';
        } else {
          this.selectedHour = def;
        }
      }
    } catch (err) {}
  }

  private completeDigits(current: number) {
    return ('0' + current).slice(-2);
  }

  launchModalCreate() {
    const params = this.builderParams();

    this.createPeriodicityService
      .open({
        data: {
          ...params,
        },
      })
      .pipe(filter((res) => !!res))
      .subscribe((res) => {
        this.recurrence = res;
        this.emitChanges();
        this.draw();
      });
  }

  private builderParams() {
    const params: CreatePeriodicityParams = !this.recurrence
      ? {
          type: 'ADD',
          params: {
            // @ts-ignore
            date: new Date(this.dateStorage),
          },
        }
      : {
          type: 'EDIT',
          params: {
            // @ts-ignore
            date: new Date(this.dateStorage),
            recurrence: this.recurrence,
          },
        };

    return params;
  }

  emitChanges() {
    let endDate = null;
    if (this.dateStorage) {
      this.formatedDate = format(this.dateStorage, 'YYYY-MM-DD');
      /*this.formatedDate = this.datePipe.transform(
        this.dateStorage,
        'yyyy-MM-dd'
      );*/
      // @ts-ignore
      endDate = `${this.formatedDate}T${
        this.selectedHour ? this.selectedHour : '00:00'
      }`;
    }

    this.changeRecurrence.emit({
      // @ts-ignore
      date: endDate ? String(endDate) : endDate,
      recurrence: this.recurrence,
    });
  }

  hourChange(hour) {
    // FORMAT HOUR
    this.selectedHour = hour;
    const hourString = this.selectedHour.substring(
      0,
      this.selectedHour.indexOf(':')
    );

    if (hourString.length === 1 || hourString == '2_' || hourString == '1_') {
      this.selectedHour = '0' + this.selectedHour;
    }

    //FORMAT MINUTES
    /**
     *check that the time is not sent with an invalid character, '__:__' or 'XX:__'
     * this happens if the input is touched but the time is not written
     */

    if (hour === '__:__') {
      this.selectedHour = '00:00';
    } else if (this.selectedHour.includes('__')) {
      this.selectedHour = this.selectedHour.split(':')[0] + ':00';
    } else if (this.selectedHour.includes('_')) {
      this.selectedHour = this.selectedHour.split(':')[0] + ':00';
    }
    this.emitChanges();
  }

  private draw() {
    this.cdRef.detectChanges();
  }
}
