/* eslint-disable @typescript-eslint/no-namespace */

import { Deal } from '../deals';
import { format } from 'date-fns';

export namespace RecurrenceGlobal {
  export interface Storage {
    periodicity: Periodicity;
    // this property is to storage the recurrence of period
    // example 1 day, 4 days or 5 month, 2 year
    repeatEach: number;
    // this property is to storage index day of week
    // to repeat de `element`
    // example: sunday and monday: [0, 1]
    dayToRepeat: number[];
    // dateTime Event
    date: Date;
  }

  export interface RecurrenceEnd {
    type: Deal.TypeEndRecurrence;
    date?: string;
    amount?: number;
  }

  export interface History {
    hasBeenCloned: boolean;
  }

  export type Periodicity = 'MONTH' | 'WEEK' | 'DAY' | 'YEAR' | 'EMPTY';

  export const PeriodicitiesSelectors: Array<{
    tag: string;
    value: Periodicity;
  }> = [
    {
      tag: 'general.LDAY',
      value: 'DAY',
    },
    {
      tag: 'general.LWEEK',
      value: 'WEEK',
    },
    {
      tag: 'general.LMONTH',
      value: 'MONTH',
    },
    {
      tag: 'general.LYEAR',
      value: 'YEAR',
    },
  ];

  export const isValidRecurrence = (recurrence: Storage) => {
    if (recurrence.repeatEach <= 0) {
      return false;
    }

    return true;
  };

  export const BuilderGeneratorOfTagRecurrenceEnd = (
    recurrence: RecurrenceGlobal.Storage,
    recurrenceEnd: RecurrenceGlobal.RecurrenceEnd,
  ): any => {
    if (!recurrence || !recurrenceEnd) {
      return 'No se repite';
    }

    if (recurrenceEnd.type === Deal.TypeEndRecurrence.SpecificDay) {
      let periodicity = 'día';

      if (recurrence.periodicity === 'MONTH') {
        periodicity = 'mes';
      } else if (recurrence.periodicity === 'YEAR') {
        periodicity = 'año';
      } else if (recurrence.periodicity === 'WEEK') {
        periodicity = 'semana';
      }

      if (recurrence.repeatEach > 1) {
        periodicity = 'días';

        if (recurrence.periodicity === 'MONTH') {
          periodicity = 'meses';
        } else if (recurrence.periodicity === 'YEAR') {
          periodicity = 'años';
        } else if (recurrence.periodicity === 'WEEK') {
          periodicity = 'semanas';
        }
      }

      return `Cada ${recurrence.repeatEach} ${periodicity}, hasta el ${format(
        recurrenceEnd.date,
        'DD/MM/YYYY',
      )}`;
    }

    if (recurrenceEnd.type === Deal.TypeEndRecurrence.Indefinitely) {
      return `Finaliza nunca`;
    }

    if (recurrenceEnd.type === Deal.TypeEndRecurrence.SpecificAmount) {
      return `Finaliza después de ${recurrenceEnd.amount} ${
        recurrenceEnd.amount === 1 ? 'vez' : 'veces'
      }`;
    }
  };
}
