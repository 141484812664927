<div class="form-group">
    <label class="field-label">
      {{ labelText | translate }}
      <img
          class="ignore-field-label"
          *ngIf="showInfoTooltip"
          tooltipDir 
          [tooltipBackground]="'blue'"
          [tooltipMessages]="tooltipMessages"
          [tooltipPosition]="'right'"
          [tooltipShowOn]="'any'"
          [tooltipHideOn]="'click'"
          src="/assets/icons/gl_information-fill-gray.svg"
          style="height: 16px;width: 16px;"
          />
    </label>

    <ng-select
      #selectRef
      [multiple]="multiple"
      [loading]="loading"
      [placeholder]="placeHolder | translate"
      [notFoundText]="notFoundText | translate"
      [(ngModel)]="selectedItems"
      [ngModelOptions]="{ standalone: true }"
      (change)="onChange($event)"
      (keydown)="onKeyPress($event)"
    >
      <ng-container *ngFor="let item of filteredItemsSelect">
        <ng-option [value]="getValue(item)">
          {{ getFiltered(item) | translate }}
        </ng-option>
      </ng-container>
    </ng-select>
    <div class="invoiceWarning" *ngIf="showWarning">
      {{ warningText| translate }}
    </div>
  </div>
