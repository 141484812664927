import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';

import { fromEvent, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { Deal, IDeal, IStatus } from '@tacliatech/types';

import {
  DealService,
  StatusService,
  StorageService,
  UserService,
} from '@web-frontend/shared/services';

import { DealPipelineService } from './deal-pipeline.service';
import { InfoService } from '../info/info.service';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import AmplitudeEvents from 'src/types/amplitude.enum';

@Component({
  selector: 'roma-deal-pipeline',
  templateUrl: './deal-pipeline.component.html',
  styleUrls: ['./deal-pipeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealPipelineComponent implements OnInit {
  @ViewChild('dragContainer', { static: true })
  dragContainer: ElementRef<HTMLDivElement>;

  @Input()
  set searchParams(value: { [key: string]: any }) {
    this.params = value;
  }

  get searchParams() {
    return this.params;
  }

  @Input()
  set active(value: boolean) {
    this.activePipeline = value;
  }

  get active() {
    return this.activePipeline;
  }

  @Input()
  status: IStatus[] = [];

  private activePipeline = false;
  private params: { [key: string]: any } = {};
  private SCROLL_DISTANCE_BOTTOM = 10;
  private lastScrollTop = 0;
  private sub$ = new Subscription();

  // These properties comes from parent (deals.component)
  @Input()
  deals: Deal.Output[] = [];

  @Input()
  isLoading = true;
  //

  constructor(
    private dealService: DealService,
    private statusService: StatusService,
    private dealPipelineService: DealPipelineService,
    private cdRef: ChangeDetectorRef,
    private infoService: InfoService,
    private userService: UserService,
    private amplitudeService: AmplitudeService,
    private elRef: ElementRef
  ) {}

  ngOnInit(): void {
    // this.watchChangeParams();

    this.sub$.add(
      fromEvent(this.elRef.nativeElement, 'scroll')
        .pipe(
          debounce(() => timer(100)),
          distinctUntilChanged(),
          map((res) => (res as Event).target as HTMLDivElement),
          filter((res) => res.scrollTop >= this.lastScrollTop),
          filter(
            (element) =>
              element.scrollHeight - element.clientHeight - element.scrollTop <=
              this.SCROLL_DISTANCE_BOTTOM
          )
        )
        .subscribe((element) => {
          this.lastScrollTop = element.scrollTop;
          this.dealPipelineService.requestPagination();
        })
    );
  }

  private requestStatus() {
    this.sub$.add(
      this.statusService
        .findByUser({ id: StorageService.CompanyId })
        .subscribe((res) => {
          this.status = res;
          this.draw();
        })
    );
  }

  drop(event: CdkDragDrop<IDeal[]>, status: IStatus) {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_boardMove,
    });
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      const dealUpdated = event.container.data[event.currentIndex] as IDeal;

      this.dealService.updateStatus(dealUpdated?._id, status).subscribe();
    }
  }

  changeParams() {
    this.dealPipelineService.changeParams(this.searchParams);
  }

  searchInView(value: { [key: string]: any }) {
    this.dealPipelineService.changeParams(value);
  }

  private draw() {
    this.cdRef.detectChanges();
  }
}
