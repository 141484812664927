import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AddClockHourComponent } from './add-clock-hour.component';
import { AddClockHour } from './add-clock-hour.types';

@Injectable({
  providedIn: 'root',
})
export class AddClockHourService {
  private refreshListSource = new Subject<boolean>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(config: MatDialogConfig<AddClockHour.Params> = new MatDialogConfig()) {
    const dialogRef = this.dialog.open(AddClockHourComponent, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }
}
