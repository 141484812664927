import { PaginateSort } from '@tacliatech/types';

export const INIT_ACTIVITY_SEARCH_PARAMS = {
  limit: 50,
  page: 1,
  sort: PaginateSort.ASC,
  applyPaginate: true,
  keyword: null,
  owner: null,
  author: null,
  'sortOptions[created_at]': PaginateSort.DESC,
  deal: null,
  takeNotified: false,
  takeDeleted: false,
  takeToday: false,
  customProperties: null,
  from: null,
  to: null,
  'authors[]': [],
  'assets[]': [],
  'deals[]': [],
  'users[]': [],
  'categories[]': [],
};
