import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { PaymentsService } from '../services/payments.service';
import { CompanyService } from '@web-frontend/shared/services/company';
import { StorageService, UserService } from '@web-frontend/shared/services';
import { HIDE_PAYMENTS_BANNER, TacliaPayments } from '@tacliatech/types';
import { Subscription } from 'rxjs';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import AmplitudeEvents from 'src/types/amplitude.enum';

@Component({
  selector: 'roma-enable-payments-banner',
  templateUrl: './enable-payments-banner.component.html',
  styleUrls: ['./enable-payments-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnablePaymentsBannerComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter(false);
  @Input() large = false;
  @Input() marginTop = '20px';
  sub$ = new Subscription();
  stripeObject: TacliaPayments.IStripe;
  isCountryValid = false;

  constructor(
    private router: Router,
    private paymentsService: PaymentsService,
    private companyService: CompanyService,
    private changeDetectionRef: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private userService: UserService
  ) {
    this.validateCountry();
  }

  validateCountry() {
    this.isCountryValid = this.userService.validateCountryES();
  }

  ngOnInit(): void {
    // this.stripeObject = this.paymentsService.stripeAccount;
    this.watchStripeObject();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  watchStripeObject() {
    this.sub$.add(
      this.paymentsService.stripeObject$.subscribe((res) => {
        this.stripeObject = res;
        this.draw();
      })
    );
  }

  draw() {
    this.changeDetectionRef.detectChanges();
  }

  goToPayments() {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.sale_card_payment_activate,
    });
    this.router.navigate(['admin', 'payments']);
    this.close.emit(true);
  }

  hideBanner(): void {
    StorageService.SetItem(HIDE_PAYMENTS_BANNER, true);
  }

  get showEnablePaymentsBanner(): boolean {
    // hidden for release 2.15

    const hideBanner = JSON.parse(StorageService.GetItem(HIDE_PAYMENTS_BANNER));
    if (hideBanner === true) return false;

    return ![
      TacliaPayments.StatusAccount.APPROVED,
      TacliaPayments.StatusAccount.APPROVED_WITH_WARNING,
      TacliaPayments.StatusAccount.REJECTED,
      TacliaPayments.StatusAccount.SUSPENDED,
    ].includes(this.stripeObject?.onboarding?.status);
  }

  get userLanguage(): string {
    return StorageService.GetItem('USER_LANG');
  }
}
