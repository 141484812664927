import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Injectable,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  BudgetsSetting,
  COMPANY_ID_KEY,
  Deal,
  FIRST_DAY_CALENDAR,
  GetColorsDeals,
  IBudgetsSetting,
  IStatus,
  Lang,
  PaginateResponse,
  PaginateSort,
} from '@tacliatech/types';

import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarMonthViewComponent,
  CalendarNativeDateFormatter,
  CalendarView,
  DateFormatterParams,
} from 'angular-calendar';

import { Router } from '@angular/router';

import { Subject, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { addMonths, endOfDay, startOfDay, subMonths } from 'date-fns';

import {
  DealSearchService,
  DealService,
  StatusService,
  StorageService,
} from '@web-frontend/shared/services';

import { getDateStrResum, RemoveEmpty } from '@web-frontend/shared/utils';

import { TranslateService } from '@ngx-translate/core';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { CreateDealService } from '@web-frontend/shared/components/create-deal';
import { CustomDateAdapter } from '@web-frontend/shared/utils/custom-date-adapter';
import AmplitudeEvents from 'src/types/amplitude.enum';

@Injectable()
class CustomDateFormatter extends CalendarNativeDateFormatter {
  dayViewHour({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, {
      hour: 'numeric',
      minute: 'numeric',
    }).format(date);
  }

  weekViewHour({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, {
      hour: 'numeric',
      minute: 'numeric',
    }).format(date);
  }
}
@Component({
  selector: 'roma-deal-calendar',
  templateUrl: './deal-calendar.component.html',
  styleUrls: ['./deal-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class DealCalendarComponent implements OnInit, OnDestroy {
  @ViewChild('monthly')
  monthly: CalendarMonthViewComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.displayWidth = window.innerWidth;
  }

  @Input()
  set searchParams(value: { [key: string]: any }) {
    this.params = {
      ...value,
      limit: 200,
      page: 1,
      sort: PaginateSort.ASC,
    };

    this.requestStatus();
    this.changeParams();
  }

  get searchParams() {
    return this.params;
  }

  defaultSettings = new BudgetsSetting();

  events: CalendarEvent[] = [];

  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();

  CalendarView = CalendarView;
  schedulesTypes = [
    {
      value: CalendarView.Day,
      name: 'checklist.day',
    },
    {
      value: CalendarView.Month,
      name: 'checklist.month',
    },
    {
      value: CalendarView.Week,
      name: 'checklist.week',
    },
  ];
  colors = GetColorsDeals;

  locale = StorageService.GetItem('USER_LANG') as Lang;
  refresh: Subject<any> = new Subject();

  deals: Deal.Output[] = [];
  resultSearch: PaginateResponse<Deal.Output[]>;
  dealsMap = new Map<string, Deal.Output>();
  eventLimit = 5;
  loading = false;

  private sub$ = new Subscription();
  private params: { [key: string]: any } = {};
  private flightRequest: Subscription;
  private status: IStatus[] = [];
  calendarDay: number;
  displayWidth = 0;

  constructor(
    private cdRef: ChangeDetectorRef,
    private dealSearchService: DealSearchService,
    private dealService: DealService,
    private router: Router,
    private ngZone: NgZone,
    private amplitudeService: AmplitudeService,
    private i18n: TranslateService,
    private statusService: StatusService,
    private budgetService: BudgetService,
    private createDealService: CreateDealService,
    private dateAdapter: CustomDateAdapter
  ) {
    this.displayWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.getSettings();
    this.watchTranslations();
    // this.getSettings();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
    this.flightRequest.unsubscribe();
  }

  getSettings() {
    const id = StorageService.CompanyId;
    let dayInit = StorageService.FirstDayCalendar;
    this.defaultSettings.budgetPreferences.day = dayInit;
    this.sub$.add(
      this.budgetService
        .findSettingsByCompany(id)
        .pipe(finalize(() => this.draw()))
        .subscribe((resp: IBudgetsSetting) => {
          this.defaultSettings = resp;
          if (resp.budgetPreferences?.day) {
            dayInit = resp.budgetPreferences.day;
            StorageService.SetItem(FIRST_DAY_CALENDAR, dayInit);
          } else {
            this.defaultSettings.budgetPreferences.day = 1;
          }
        })
    );
  }

  private requestDeals({ loadingGlobal = false }) {
    this.generateDate();

    const query = RemoveEmpty(this.searchParams);

    const request$ = this.dealSearchService.search(query);

    if (this.flightRequest) {
      this.flightRequest.unsubscribe();
    }

    if (loadingGlobal) {
      this.loading = true;
      this.draw();
    }

    this.flightRequest = request$
      .pipe(
        finalize(() => {
          this.loading = false;
          this.draw();
        })
      )
      .subscribe((res) => {
        this.setDeals(res);
        this.generateEvents();
      });
  }

  setData() {
    this.generateDate();
    this.requestDeals({ loadingGlobal: true });
    this.draw();
  }

  changeView() {
    this.setData();
  }

  changeTypeViews(view: CalendarView) {
    if (this.view !== view) {
      this.view = view;

      this.generateDate();
      this.requestDeals({ loadingGlobal: true });
      this.draw();
    }
  }

  eventClick({ id }: CalendarEvent) {
    this.createDealService
      .open({
        data: {
          mode: 'EDIT',
          idDeal: id,
        },
      })
      .pipe(map((res) => !res))
      .subscribe();
    this.draw();
    /*this.ngZone.run(() => {
      this.router.navigateByUrl(`/admin/deals/${id}`);

    });*/
  }

  dateDragEnter(
    drag: { dropData: { event: CalendarEvent } },
    day: { date: Date }
  ) {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_calendarMove,
    });
    this.loading = true;
    const origin = drag.dropData.event.start;
    const destination = day.date;

    const originData = getDateStrResum(origin);
    const destinationData = getDateStrResum(day.date);

    const transformInitWeek =
      destinationData.formattedDate +
      ` ${originData.hour}:${originData.minute}`;

    const newEndDate: any = new Date(drag.dropData.event.end);
    newEndDate.setDate(destinationData.day);

    const endResume = getDateStrResum(newEndDate);

    this.dealService
      .updateOne(drag.dropData.event.id.toString(), {
        initDate: transformInitWeek,
        endDate: endResume.formattedFullMinutes,
      })
      .subscribe(() => {
        this.loading = false;
        this.requestDeals({ loadingGlobal: false });
      });
  }

  removeTimeOffset(dateString) {
    const timeOffset = startOfDay(dateString).getTimezoneOffset();

    if (Math.sign(timeOffset) > 0) {
      return new Date(dateString.getTime() - timeOffset * 60 * 1000);
    } else {
      return dateString;
    }
  }

  private generateDate() {
    switch (this.view) {
      case CalendarView.Month:
        this.params = {
          ...this.params,
          from: subMonths(this.viewDate, 1),
          to: addMonths(this.viewDate, 1),
        };
        break;

      case CalendarView.Week:
        this.params = {
          ...this.params,
          from: this.removeTimeOffset(startOfDay(this.viewDate)),
          to: this.removeTimeOffset(endOfDay(this.viewDate)),
        };
        break;

      case CalendarView.Day:
        this.params = {
          ...this.params,
          from: this.removeTimeOffset(startOfDay(this.viewDate)),
          to: this.removeTimeOffset(endOfDay(this.viewDate)),
        };
        break;
    }
  }

  changeToViewEvent(date: Date) {
    if (window.innerWidth < 770) {
      this.loadDayEvents(date);
    } else {
      this.loadWeekEvents(date);
    }
  }

  loadDayEvents(date: Date) {
    this.viewDate = date;
    this.changeTypeViews(CalendarView.Day);
  }

  loadWeekEvents(date: Date) {
    this.viewDate = date;
    this.changeTypeViews(CalendarView.Week);
  }

  eventDroppedDay(evt: any) {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_calendarMove,
    });
    this.loading = true;
    const dateResum = getDateStrResum(evt.newStart);
    const endResume = getDateStrResum(evt.newEnd);

    this.dealService
      .updateOne(evt.event.id, {
        initDate: dateResum.formattedFullMinutes,
        endDate: endResume.formattedFullMinutes,
      })
      .subscribe(() => {
        this.loading = false;
        this.requestDeals({ loadingGlobal: false });
      });
  }

  private setDeals(response: PaginateResponse<Deal.Output[]>) {
    this.resultSearch = response;

    for (const deal of response.docs) {
      this.dealsMap.set(deal?._id, deal);
    }

    this.deals = Array.from(this.dealsMap.values());
  }

  private generateEvents() {
    this.events = [];

    let localEvents: CalendarEvent[] = [];

    for (const deal of this.dealsMap.values()) {
      if (this.status && deal.status?.name) {
        const stat = this.status.filter(
          (fil) =>
            fil.name == deal.status?.name ||
            fil.translate == deal.status?.translate
        );
        if (stat.length > 0) {
          deal.status.color = stat[0].color;
        }
      }
      localEvents = [
        ...localEvents,
        {
          id: deal._id,
          start: new Date(deal?.dates?.initTimestamp),
          end: new Date(deal?.dates?.endTimestamp),
          title: deal?.name,
          color: deal.status?.color?.startsWith('#')
            ? this.getStatusColor(deal.status?.color)
            : this.colors[this.setStatusColor(deal)],
          draggable: true,
        },
      ];
    }

    localEvents = localEvents.sort(
      (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
    );
    this.events = [...localEvents];
    this.draw();
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  private changeParams() {
    this.clear();

    this.requestDeals({ loadingGlobal: true });
  }

  searchInView(value: { [key: string]: any }) {
    this.clear();
    this.searchParams = value;
    this.requestDeals({ loadingGlobal: true });
  }

  private clear() {
    this.dealsMap.clear();

    this.events = [];
    this.deals = [];

    this.draw();
  }

  private setStatusColor(deal: Deal.Output) {
    const today = new Date();

    if (new Date(deal?.dates?.initTimestamp) < today) {
      return 'PAST';
    } else if (deal.urgency) {
      return 'URGENCY';
    } else {
      return 'TODAY';
    }
  }

  private requestStatus() {
    if (!this.status) {
      this.sub$.add(
        this.statusService
          .findByUser({ id: StorageService.GetItem(COMPANY_ID_KEY) })
          .subscribe((res) => {
            this.status = res;
            this.draw();
          })
      );
    }
  }

  private getStatusColor(status: string) {
    return {
      primary: status,
      secondary: '#000000',
      third: status,
    };
  }

  async redirectToDealDetail(value: CalendarEvent) {
    console.log('HERES');
    //this.router.navigateByUrl(`/admin/deals/${value.id}`);
    this.createDealService
      .open({
        data: {
          mode: 'EDIT',
          idDeal: value.id,
        },
      })
      .pipe(map((res) => !res))
      .subscribe();
  }

  private watchTranslations() {
    this.sub$.add(
      this.i18n.onLangChange.subscribe((res) => {
        const lang = res?.lang as Lang;
        StorageService.SetItem('USER_LANG', lang);
        this.locale = lang;
        this.refresh.next();
        this.draw();
      })
    );
  }

  showTitleInMobile(weekEvent) {
    const eventTitle = weekEvent.event.title;

    if (this.displayWidth <= 1240) {
      return eventTitle.length > 14
        ? eventTitle.substr(0, 14) + '...'
        : eventTitle;
    }

    if (this.displayWidth >= 1240 && this.displayWidth <= 1600) {
      return eventTitle.length > 30
        ? eventTitle.substr(0, 30) + '...'
        : eventTitle;
    }

    return weekEvent.event.title;
  }
}
