export class SnackbarData implements ISnackbarData {
  type?: SnackbarType;
  icon?: string;
  showClose?: boolean;
  text?: string;
  class?: string;
  msDuration?: number;
  link?: string;
  linkData?: any;
}

export interface ISnackbarData {
  type?: SnackbarType;
  icon?: string;
  showClose?: boolean;
  text?: string;
  class?: string;
  msDuration?: number;
  link?: string;
  linkData?: any;
}

export type SnackbarType = 'info' | 'success' | 'error';

export interface SnackbarConfig {
  position?: {
    top: number;
    right: number;
  };
  animation?: {
    fadeOut: number;
    fadeIn: number;
  };
}
