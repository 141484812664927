import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateFinalV2Component } from './create-final-v2.component';
import { TranslateModule } from '@ngx-translate/core';
import { WrapperDetailComponent } from './wrapper-detail-component/wrapper-detail-component';
import { EquipmentNotesModule } from '../equipments-notes';
import { PermissionsModule } from '../permissions';
import { ImageSelectorModule } from '../image-selector-v2';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FieldErrorDisplayModule } from '../field-error-display';
import { RmPhoneSelectInputModule } from '../globals/rm-phone-select-input/rm-phone-select-input.module';
import { EditCustomPropertiesModule } from '../edit-custom-properties';
import { AppendCustomPropertiesModule } from '../append-custom-properties';
import { WrapperDealsComponent } from './wrapper-deals/wrapper-deals.component';
import { DealsModule } from '@web-frontend/core/admin/deals/deals.module';
import { WrapperActivitiesComponent } from './wrapper-activities/wrapper-activities.component';
import { ActivitiesModule } from '@web-frontend/core/admin/activities/activities.module';
import { WrapperSalesComponent } from './wrapper-sales/wrapper-sales.component';
import { BudgetsModule } from '@web-frontend/core/admin/budgets/budgets.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AddressMapModule } from '../address-map/address-map.module';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { RmSelectV2Module } from '../globals/rm-select-v2';
import { TooltipModule } from '@web-frontend/shared/directives/tooltip/tooltip.module';
import { ObligatoryFieldSignModule } from '../obligatory-field-sign/obligatory-field-sign.module';
import { CreateFinalPartiallyComponent } from './create-final-partially/create-final-partially.component';
import { ModalTemplateModule } from '../modal-template';
import { BaseButtonModule } from '@web-frontend/common/components/button/baseButton.module';

@NgModule({
  declarations: [
    CreateFinalV2Component,
    CreateFinalPartiallyComponent,
    WrapperDetailComponent,
    WrapperDealsComponent,
    WrapperActivitiesComponent,
    WrapperSalesComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    EquipmentNotesModule,
    PermissionsModule,
    ImageSelectorModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatMenuModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FieldErrorDisplayModule,
    RmPhoneSelectInputModule,
    EditCustomPropertiesModule,
    AppendCustomPropertiesModule,
    ActivitiesModule,
    DealsModule,
    BudgetsModule,
    AddressMapModule,
    MatGoogleMapsAutocompleteModule,
    ClickDeleteModule,
    RmSelectV2Module,
    TooltipModule,
    ObligatoryFieldSignModule,
    ModalTemplateModule,
    BaseButtonModule,
  ],
})
export class CreateFinalV2Module {}
