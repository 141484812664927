import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddStatusComponent } from './add-status.component';

import { ModalTemplateModule } from '@web-frontend/shared/components/modal-template';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FieldErrorDisplayModule } from '@web-frontend/shared/components/field-error-display';
import { AlertModule } from '@web-frontend/shared/helpers/alert';
import { ChooseColorModule } from '../choose-color/choose-color.module';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BaseButtonModule } from '@web-frontend/common/components/button/baseButton.module';

@NgModule({
  declarations: [AddStatusComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forChild(),
    FieldErrorDisplayModule,
    AlertModule,
    ChooseColorModule,
    ClickDeleteModule,
    MatProgressSpinnerModule,
    BaseButtonModule,
  ],
  exports: [AddStatusComponent],
})
export class AddStatusModule {}
