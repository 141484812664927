import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { ModalChoose } from './modal-choose.type';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'roma-modal-choose',
  templateUrl: './modal-choose.component.html',
  styleUrls: ['./modal-choose.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalChooseComponent implements OnInit {
  @Input()
  params!: ModalChoose.Params;

  optionSelected!: unknown;

  constructor(
    private dialogRef: MatDialogRef<ModalChooseComponent, ModalChoose.Response>,
    @Inject(MAT_DIALOG_DATA)
    public data: ModalChoose.Params,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.attachParams();
  }

  cancel(evt: MouseEvent) {
    if (evt) {
      this.dialogRef.close({ action: 'CANCEL-X' });
    }
  }

  changeParamsOptions(evt: MatRadioChange) {
    this.optionSelected = evt.value;
    this.draw();
  }

  makeAction() {
    this.dialogRef.close({
      action: 'EXECUTE',
      option: this.optionSelected,
    });
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  private attachParams() {
    if (this.data) {
      this.params = this.data;
    }
  }
}
