import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from '@web-frontend/shared/helpers/alert';

import { ClickLogoutDirective } from './click-logout.directive';

@NgModule({
  declarations: [ClickLogoutDirective],
  imports: [CommonModule, TranslateModule.forChild(), AlertModule],
  exports: [ClickLogoutDirective],
})
export class ClickLogoutModule {}
