import { IEquipment } from '@tacliatech/types';

export const PARSER_EQUIPMENT = (item: IEquipment) => {
  return {
    title:
      item?.ref && item?.title ? `${item?.ref} - ${item?.title}` : item?.ref,
    name:
      item?.ref && item?.title ? `${item?.ref} - ${item?.title}` : item?.ref,
    id: item?._id,
    value: item?._id,
    img: '',
  };
};
