import { TypeBudget } from '@tacliatech/types';

import {
  STATUS_BILL_PAID,
  STATUS_BILL_PENDING,
  STATUS_BILL_OVERDUE,
} from '@tacliatech/types';

import {
  STATUS_BUDGET_CREATED,
  STATUS_BUDGET_SENT,
  STATUS_BUDGET_ACCEPTED,
  STATUS_BUDGET_REJECTED,
  STATUS_BUDGET_PAID,
  STATUS_BUDGET_INVOICED,
  STATUS_BUDGET_CANCELLED,
  STATUS_BUDGET_OVERDUE,
} from '@tacliatech/types';

import {
  STATUS_PROFORM_CREATED,
  STATUS_PROFORM_SENT,
  STATUS_PROFORM_ACCEPTED,
  STATUS_PROFORM_REJECTED,
  STATUS_PROFORM_PAID,
  STATUS_PROFORM_INVOICED,
  STATUS_PROFORM_CANCELLED,
  STATUS_PROFORM_OVERDUE,
} from '@tacliatech/types';

import {
  STATUS_WAYBILL_CREATED,
  STATUS_WAYBILL_PENDING_DELIVERY,
  STATUS_WAYBILL_DELIVERED,
  STATUS_WAYBILL_INVOICED,
  STATUS_WAYBILL_CANCELLED,
} from '@tacliatech/types';

export const STATUSES_BY_BUDGET_TYPE = {
  [TypeBudget.BILL]: [
    STATUS_BILL_PENDING,
    STATUS_BILL_PAID,
    STATUS_BILL_OVERDUE,
  ],
  [TypeBudget.PROFORM]: [
    STATUS_PROFORM_CREATED,
    STATUS_PROFORM_SENT,
    STATUS_PROFORM_ACCEPTED,
    STATUS_PROFORM_REJECTED,
    STATUS_PROFORM_PAID,
    STATUS_PROFORM_INVOICED,
    STATUS_PROFORM_CANCELLED,
    STATUS_PROFORM_OVERDUE,
  ],
  [TypeBudget.BUDGET]: [
    STATUS_BUDGET_CREATED,
    STATUS_BUDGET_SENT,
    STATUS_BUDGET_ACCEPTED,
    STATUS_BUDGET_REJECTED,
    STATUS_BUDGET_PAID,
    STATUS_BUDGET_INVOICED,
    STATUS_BUDGET_CANCELLED,
    STATUS_BUDGET_OVERDUE,
  ],
  [TypeBudget.WAL]: [
    STATUS_WAYBILL_CREATED,
    STATUS_WAYBILL_PENDING_DELIVERY,
    STATUS_WAYBILL_DELIVERED,
    STATUS_WAYBILL_INVOICED,
    STATUS_WAYBILL_CANCELLED,
  ],
};

export const INVOICED_STATUS_BY_BUDGET_TYPE = {
  [TypeBudget.PROFORM]: STATUS_PROFORM_INVOICED,
  [TypeBudget.BUDGET]: STATUS_BUDGET_INVOICED,
  [TypeBudget.WAL]: STATUS_WAYBILL_INVOICED,
};

export const OVERDUE_STATUS_BY_BUDGET_TYPE = {
  [TypeBudget.BILL]: STATUS_BILL_OVERDUE,
  [TypeBudget.PROFORM]: STATUS_PROFORM_OVERDUE,
  [TypeBudget.BUDGET]: STATUS_BUDGET_OVERDUE,
};

export const DEFAULT_STATUS_BY_BUDGET_TYPE = {
  [TypeBudget.BILL]: STATUS_BILL_PENDING,
  [TypeBudget.PROFORM]: STATUS_PROFORM_CREATED,
  [TypeBudget.BUDGET]: STATUS_BUDGET_CREATED,
  [TypeBudget.WAL]: STATUS_WAYBILL_CREATED,
};

export const CANCELLED_STATUS_BY_BUDGET_TYPE = {
  [TypeBudget.PROFORM]: STATUS_PROFORM_CANCELLED,
  [TypeBudget.BUDGET]: STATUS_BUDGET_CANCELLED,
  [TypeBudget.WAL]: STATUS_WAYBILL_CANCELLED,
};

export const PAID_STATUS_BY_BUDGET_TYPE = {
  [TypeBudget.BILL]: STATUS_BILL_PAID,
  [TypeBudget.PROFORM]: STATUS_PROFORM_PAID,
  [TypeBudget.BUDGET]: STATUS_BUDGET_PAID,
};

export const ACCEPTED_STATUS_BY_BUDGET_TYPE = {
  [TypeBudget.PROFORM]: STATUS_PROFORM_ACCEPTED,
  [TypeBudget.BUDGET]: STATUS_BUDGET_ACCEPTED,
};

export const REJECTED_STATUS_BY_BUDGET_TYPE = {
  [TypeBudget.PROFORM]: STATUS_PROFORM_REJECTED,
  [TypeBudget.BUDGET]: STATUS_BUDGET_REJECTED,
};
