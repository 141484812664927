import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { EditableFieldModule } from '../editable-field';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { FieldErrorDisplayModule } from '../field-error-display';
import { BudgetPaymentMethodComponent } from './budget-payment-method.component';
import { MatMenuModule } from '@angular/material/menu';
import { LoadingModule } from '../loading';
import { TranslateModule } from '@ngx-translate/core';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';

@NgModule({
  declarations: [BudgetPaymentMethodComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    EditableFieldModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatCardModule,
    FieldErrorDisplayModule,
    MatMenuModule,
    LoadingModule,
    ClickDeleteModule,
  ],
  exports: [BudgetPaymentMethodComponent],
})
export class BudgetPaymentMethodDataModule {}
