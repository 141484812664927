import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@web-frontend/shared/services';

@Injectable({
  providedIn: 'root',
})
export class ClickLogoutService {
  constructor(
    private router: Router,
    private readonly authService: AuthService
  ) {}

  public logout(
    { redirect }: { redirect: boolean } = { redirect: true }
  ): void {
    this.authService.logout();
    if (redirect) {
      this.router.navigateByUrl('/auth/login');
    }
  }
}
