import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ClockHourService,
  StorageService,
} from '@web-frontend/shared/services';
import { ClockHour, COMPANY_ID_KEY } from '@tacliatech/types';

export class AddClockValidators {
  static checkIfIsRepeat(
    clockHourService: ClockHourService,
    idOwner: string,
    idToIgnore = ''
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (control.pristine) {
        return of(null);
      }

      const date = ClockHour.fromStringDateToObjectDate(control.value);

      const searchParams = {
        date: JSON.stringify(date),
        'idCompanies[]': [StorageService.GetItem(COMPANY_ID_KEY)],
        'idOwners[]': [idOwner],
      };

      return clockHourService.search(searchParams).pipe(
        map((response) => {
          const ids = response.docs.map((res) => res._id);

          if (idToIgnore) {
            if (ids.length && !ids.includes(idToIgnore)) {
              return {
                clockHourRepeatDate: true,
              };
            }
          } else {
            if (ids.length) {
              return {
                clockHourRepeatDate: true,
              };
            }
          }

          return null;
        })
      );
    };
  }
}
