export const RemoveEmpty = (obj: any) =>
  Object.entries(obj).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});

export const CompareObjects = (
  initial: { [key: string]: any },
  current: { [key: string]: any }
) => {
  const valuesInitial = Object.values(initial);
  const valuesCurrent = Object.values(current);
  for (let i = 0; i < valuesInitial.length; i++) {
    if (valuesInitial[i] === undefined || valuesInitial[i] === '') {
      valuesInitial[i] = null;
    }
  }

  for (let i = 0; i < valuesCurrent.length; i++) {
    if (valuesCurrent[i] === undefined || valuesCurrent[i] === '') {
      valuesCurrent[i] = null;
    }
  }

  for (let i = 0; i < valuesInitial.length; i++) {
    if (JSON.stringify(valuesInitial[i]) !== JSON.stringify(valuesCurrent[i])) {
      return false;
    }
  }

  return true;
};
