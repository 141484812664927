<div class="mat-dialog-header">
  <img
    (click)="exit()"
    width="24px"
    height="24px"
    src="assets/icons/gl_close.svg"
    class="mat-dialog-header_close"
  />
</div>
<div class="mat-dialog-content">
  <img
    *ngIf="data.showIcon === undefined || data.showIcon"
    src="assets/icons/download-modal-icon.svg"
    class="mat-dialog-content_icon"
  />
  <h1 class="mat-dialog-content_title">
    {{ data.title ?? (downloadQuantity > 1 ? getTranslation('downloadModal.title_plural') : getTranslation('downloadModal.title'))}}
  </h1>
</div>
<div mat-dialog-actions>
  <button class="cancel" mat-button (click)="onNoClick()">
    {{data.btn_cancelLabel ??  'downloadModal.cancel' | translate  }}
  </button>
  <button
    class="roma-button"
    mat-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    (click)="execute()"
  >
    {{data.confirmLabel ??  'downloadModal.confirm' | translate  }}
  </button>
</div>
