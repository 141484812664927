<section class="budget_container" *ngIf="!isEmptyForm">
  <fieldset [formGroup]="categoryForm">
    <div class="budget-category">
      <div
        romaProSelect
        class="rates-group wrapper-pro-feature-user"
        [featureUser]="featureRefUser.Catalogue.show"
      >
        <label>{{ conceptPlaceholder | translate }}</label>
        <div class="category-concept">
          <rm-select-register
            [isClearable]="true"
            [placeholderText]="'budgets.settings.create.form.selectConceptPlaceholder' | translate"
            [items]="rates"
            [isLoading]="isLoadingRates"
            [disabled]="isLoadingRates"
            [activeItems]="selectedRate"
            [isAbsolute]="true"
            inputHeight="44px"
            [listStyle]="selectListStyle"
            [fullWidthList]="true"
            [isLowerCase]="false"
            type="users"
            [onlySelect]="false"
            [addButtonText]="'catalogue.addCatalogue2'"
            [addButtonFeatureUser]="featureRefUser.Catalogue.create"
            (changeItems)="changeRate($event)"
            (addElement)="addRate()"
            [hasError]="fieldIsInvalid('concept')"
            [maxLength]="100"
          >
          </rm-select-register>
          <span *ngIf="fieldIsInvalid('concept')" class="error-text"
            >*{{ 'budgets.settings.create.form.mandatory' | translate }}</span
          >
        </div>
        <roma-snackbar-mexico
          *ngIf="invalidMXCategory"
          [message]="'snackbar.categoryMXSAT'"
          [type]="'warning'"
          [textLink]="'snackbar.categoryMXSATLink'"
          (onClickLink)="onClickSnackbarLink()"
        ></roma-snackbar-mexico>
        <div *ngIf="isResponsive" class="budget-description-container">
          <textarea
            type="text"
            maxlength="1500"
            [id]="'description-textarea-' + groupIndex"
            (input)="handleTextareaInput($event)"
            class="budget-description"
            placeholder="{{
              'budgets.settings.create.form.concept-description' | translate
            }}"
            formControlName="description"
            (change)="valueChange($event, 'description')"
          ></textarea>
          <div *ngIf="categoryForm.value.description.length === 1500">
            <mat-error class="warning-text">{{
              'budgets.settings.create.form.concept-description' | translate
            }}</mat-error>
          </div>
        </div>
      </div>
      <div class="quantity-unit-group">
        <div
          *ngIf="resolveShowField(CategoryFields.QUANTITY)"
          class="form-field quantity"
        >
          <label>{{ quantityPlaceholder | translate }}</label>
          <input
            class="form-control"
            type="number"
            formControlName="quantity"
            placeholder="00"
            (change)="valueChange($event, 'quantity')"
          />
        </div>
        <div
          *ngIf="!isMX && resolveShowField(CategoryFields.UNIT)"
          class="form-field unit"
        >
          <label>{{ unitPlaceholder | translate }}</label>
          <input
            class="form-control"
            type="text"
            placeholder="--"
            formControlName="unit"
            (change)="valueChange($event, 'unit')"
          />
        </div>
        <div *ngIf="isMX" class="form-field select-unit-mx">
          <label>{{ unitPlaceholder | translate }}</label>
          <rm-select-register
            [placeholderText]="'general.select' | translate"
            [isLoading]="isLoadingUnits"
            [disabled]="isLoadingUnits"
            [items]="units"
            [activeItems]="selectedUnit"
            [isAbsolute]="true"
            [fullWidthList]="true"
            [allOptionsEnabled]="false"
            [listWidth]="isResponsive ? '100%' : '220%'"
            [loadDefaultItem]="false"
            inputHeight="44px"
            [listStyle]="selectListStyle"
            [type]="'unit'"
            (changeItems)="changeUnit($event)"
          >
          </rm-select-register>
        </div>
      </div>

      <div class="price-discount-group">
        <div class="form-field price">
          <label>{{ pricePlaceholder | translate }}</label>
          <input
            class="form-control"
            type="number"
            placeholder="0"
            formControlName="price"
            (change)="valueChange($event, 'price')"
          />
        </div>

        <div
          *ngIf="resolveShowField(CategoryFields.DISCOUNT)"
          class="form-field discount"
        >
          <label>{{ discountPlaceholderMobile | translate }}</label>
          <input
            class="form-control"
            [ngClass]="{ 'is-invalid': fieldIsInvalid('discount') }"
            formControlName="discount"
            type="number"
            placeholder="00"
            (change)="valueChange($event, 'discount')"
          />
          <span *ngIf="fieldIsInvalid('discount')" class="error-text"
            >*{{
              'budgets.settings.create.form.discountInvalid' | translate
            }}</span
          >
        </div>
      </div>

      <div class="form-field tax">
        <label>{{ taxPlaceholder | translate }}</label>
        <rm-select-register
          [isClearable]="true"
          [placeholderText]="'general.select' | translate"
          [isLoading]="!taxList?.length > 0"
          [disabled]="!taxList?.length > 0"
          [items]="displayedTaxes"
          [activeItems]="taxSelected"
          [isAbsolute]="true"
          [displayMultipleActive]="true"
          [mode]="'MULTIPLE'"
          inputHeight="44px"
          [fullWidthList]="true"
          [listStyle]="selectListStyle"
          [type]="'taxes'"
          (click)="(taxRefWidth)"
          (changeItems)="changeTax($event)"
          [listWidth]="isResponsive ? '100%' : '150%'"
        >
        </rm-select-register>
      </div>

      <div class="form-field total">
        <label>{{ totalPlaceholder | translate }}</label>
        <input
          #total
          class="form-control"
          type="number"
          formControlName="total"
          placeholder="0"
          (change)="valueChange($event, 'total')"
        />
      </div>

      <div class="close_button">
        <span (click)="removeCategory()">{{
          'budgets.settings.create.form.deleteConcept' | translate
        }}</span>
        <img
          [src]="'assets/icons/gl_close.svg'"
          alt="close"
          (click)="removeCategory()"
        />
      </div>
    </div>
    <div *ngIf="!isResponsive" class="budget-description-container">
      <textarea
        type="text"
        maxlength="1500"
        [id]="'description-textarea-' + groupIndex"
        (input)="handleTextareaInput($event)"
        class="budget-description"
        placeholder="{{
          'budgets.settings.create.form.concept-description' | translate
        }}"
        formControlName="description"
        (change)="valueChange($event, 'description')"
      ></textarea>
      <div *ngIf="categoryForm.value.description.length === 1500">
        <mat-error class="warning-text">{{
          'budgets.settings.create.form.concept-description' | translate
        }}</mat-error>
      </div>
    </div>
  </fieldset>
</section>
