import { Component, Input, OnInit } from '@angular/core';
import { CreateActivityService } from '../../create-activity';
import { IFinal } from '@tacliatech/types';

@Component({
  selector: 'roma-wrapper-activities',
  templateUrl: './wrapper-activities.component.html',
  styleUrls: ['./wrapper-activities.component.scss'],
  providers: [CreateActivityService],
})
export class WrapperActivitiesComponent implements OnInit {
  @Input()
  type: 'ADD' | 'EDIT' = 'ADD';

  @Input()
  customer: IFinal;

  @Input()
  isLoading = false;
  constructor() {}

  ngOnInit(): void {}
}
