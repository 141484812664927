import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split',
})
export class SplitPipe implements PipeTransform {
  transform(value = '', count = 10, reverse = true): unknown {
    if (!value) {
      return null;
    }

    return reverse
      ? [...value].reverse().splice(0, count).reverse().join('')
      : [...value].splice(0, count).join('');
  }
}
