import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { checkCountryCodeInLocalStorage } from '@web-frontend/shared/utils/country-validator';
import { text } from '@fortawesome/fontawesome-svg-core';
import { Router } from '@angular/router';

@Component({
  selector: 'roma-snackbar-mexico',
  templateUrl: './snackbar-mexico.component.html',
  styleUrls: ['./snackbar-mexico.component.scss'],
})
export class SnackbarMexicoComponent implements OnInit {
  @Output()
  onClickLink = new EventEmitter();

  @Input()
  message: string;

  @Input()
  type: 'success' | 'error' | 'warning' = 'success';

  @Input()
  textLink?: string;

  @Input()
  urlLink?: string;

  showAlert = false;

  constructor(private router: Router) {
    this.validateCountryMX();
  }

  validateCountryMX() {
    this.showAlert = checkCountryCodeInLocalStorage('MX');
  }

  getIconPath(): string {
    const icons = {
      success: 'gl_check-fill.svg',
      error: 'gl_error.svg',
      warning: 'gl_alert_yellow.svg',
    };

    return `assets/icons/${icons[this.type]}`;
  }

  ngOnInit() {}

  clickLink() {
    if (this.urlLink) {
      this.router.navigate([this.urlLink], {});
    }
    this.onClickLink.emit();
  }

  protected readonly text = text;
}
