<div class="customization-sidenav">
  <div class="customization-sidenav-header">
    <div class="customization-sidenav-title">
      {{ 'budgets.sidenav.title' | translate }}
    </div>
    <div class="close-container">
      <img
        class="close"
        [src]="'assets/icons/gl_close.svg'"
        width="32px"
        height="32px"
        (click)="close(false)"
      />
    </div>
  </div>
  <div class="customization-sidenav-body">
    <div class="customization-sidenav-templates">
      <div class="customization-sidenav-templates-title">
        {{
          data?.isResponsive
            ? ('budgets.sidenav.templateTitleMobile' | translate)
            : (title | translate)
        }}
      </div>
      <div class="customization-sidenav-templates-list">
        <div class="customization-sidenav-template">
          <mat-radio-group
            aria-labelledby="radio-group-label"
            class="radio-group"
            [(ngModel)]="templateSelected"
          >
            <div class="template-container" (click)="close(false)">
              <mat-radio-button class="radio-button" [value]="templateSelected">
                <div class="customization-sidenav-template-image">
                  <img
                    src="/assets/images/budgetsTemplates/plantilla1.svg"
                    alt="Plantilla 1"
                  />
                </div>
              </mat-radio-button>
              <div class="customization-sidenav-template-text">
                {{ 'budgets.sidenav.template1' | translate }}
              </div>
            </div>
          </mat-radio-group>
        </div>
      </div>
    </div>
    <div class="separator"></div>
    <div class="customization-sidenav-banner">
      <img src="/assets/icons/gl_exclamation-circle.svg" />
      <div>
        {{ 'budgets.sidenav.banner1' | translate }}
      </div>
    </div>
  </div>

  <div class="customization-sidenav-footer">
    <roma-pro-warning-info
      *ngIf="!(featuresProEnabled$ | async)"
      [warning]="true"
      [button]="'pro.disclaimer.button2'"
      [message]="'pro.disclaimer.budgets.noFooter'"
    ></roma-pro-warning-info>
  </div>
</div>
