import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  constructor(private http: HttpClient) {}

  checkIfPhoneIsRepeat(
    type: 'CUSTOMER' | 'VENDOR' | 'USER',
    phone: string
  ): Observable<{ hasPhone: boolean }> {
    const params = new HttpParams({
      fromObject: {
        type,
        phone,
      },
    });

    return this.http.get<{ hasPhone: boolean }>(`:API_URL/others/check-phone`, {
      params,
    });
  }

  checkIfEmailIsRepeat(email: string): Observable<boolean> {
    const emailEncoded = encodeURIComponent(email.toLowerCase());

    return this.http.get<boolean>(
      `:API_URL/others/check-email?email=${emailEncoded}`
    );
  }

  async isValidDomain(email: string): Promise<boolean> {
    const emailEncoded = encodeURIComponent(email.toLowerCase());
    try {
      const response = await this.http
        .head(`:API_BASE_URLuser/validate-email?email=${emailEncoded}`, {
          observe: 'response',
        })
        .toPromise();
      return response.status === 200;
    } catch {
      return false;
    }
  }
}
