<h1>Crear locación</h1>

<div>
  <form
    *ngIf="!location; else createdLocation"
    [formGroup]="locationForm"
    (ngSubmit)="onSubmit()"
    class="form-group mx-auto"
    style="max-width: 35%"
  >
    <label for="displayName">Nombre: </label>
    <input
      id="displayName"
      type="text"
      formControlName="displayName"
      class="form-control"
      placeholder="Mi locación 1"
    />

    <label for="country">País: </label>
    <input
      id="country"
      type="text"
      formControlName="country"
      class="form-control"
      placeholder="US"
    />

    <label for="city" class="mt-3">Ciudad: </label>
    <input
      id="city"
      type="text"
      formControlName="city"
      class="form-control"
      placeholder="San Francisco"
    />

    <label for="line1" class="mt-3">Línea 1: </label>
    <input
      id="line1"
      type="text"
      formControlName="line1"
      class="form-control"
      placeholder="1234 Main Street"
    />

    <label for="state" class="mt-3">Estado: </label>
    <input
      id="state"
      type="text"
      formControlName="state"
      class="form-control"
      placeholder="CA"
    />

    <label for="postalCode" class="mt-3">Código postal: </label>
    <input
      id="postalCode"
      type="text"
      formControlName="postalCode"
      class="form-control"
      placeholder="94111"
    />

    <div class="d-flex justify-content-center">
      <button
        *ngIf="!isLoading; else spinner"
        type="submit"
        class="roma-button h-50 mt-3 mx-3"
        [disabled]="isLoading || locationForm.invalid"
        [ngClass]="{
          'disable-button': isLoading || locationForm.invalid
        }"
      >
        Crear locación
      </button>

      <ng-template #spinner>
        <mat-progress-spinner
          *ngIf="isLoading"
          class="loading-spinner mt-3"
          mode="indeterminate"
          [diameter]="20"
        ></mat-progress-spinner>
      </ng-template>
    </div>
  </form>

  <ng-template #createdLocation>
    <div>
      <p>Se ha creado la locación:</p>
      <pre>
        {{ location | json }}
      </pre>
    </div>
  </ng-template>
</div>

<hr />

<h2>Locaciones creadas</h2>
<pre *ngFor="let l of locations">
  {{ l | json }}
  -----------
</pre>

<hr />
<div class="space"></div>
<div class="row col-3">
  <button class="roma-button h-50" (click)="back()">Volver</button>
</div>
