import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CreateDealComponent } from './create-deal.component';

@Injectable({
  providedIn: 'root',
})
export class CreateDealService {
  private refreshListSource = new Subject<boolean>();
  private currentDialogRef: MatDialogRef<CreateDealComponent> | null = null;

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(config: MatDialogConfig = new MatDialogConfig()) {
    config.position = { right: '0px' };

    this.currentDialogRef = this.dialog.open(CreateDealComponent, {
      ...config,
      autoFocus: false,
      disableClose: config.data?.mode === 'EDIT',
    });

    return this.currentDialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
        this.currentDialogRef = null;
      })
    );
  }

  close(): void {
    if (this.currentDialogRef) {
      this.currentDialogRef.close();
      this.currentDialogRef = null;
    }
  }
}
