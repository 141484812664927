<div class="row">
  <div class="col-12 col-lg-6 date-container">
    <label class="pflex-title">{{ 'general.date' | translate }}</label>
    <input
      id="dateTime"
      type="date"
      class="form-control"
      [(ngModel)]="completeDate"
      (ngModelChange)="emitChanges()"
    />
  </div>
  <div class="col-12 col-lg-6 user-container mb-10">
    <div *ngIf="isLoadingUsers; else usersLoaded" class="h-50">
      <roma-loading class="h-50" size="50"></roma-loading>
    </div>

    <ng-template #usersLoaded>
      <div class="">
        <div class="pflex">
          <!--    <label class="pflex-title">
            Usuarios
       </label> -->
          <ng-container *ngIf="!isLoadingUsers && isAdmin">
            <roma-select-search
              [isLoading]="isLoadingUsers"
              [type]="selectType"
              [style]="'my-6'"
              [tag]="'person.title' | translate"
              [controlName]="'idUsers'"
              [datasource]="teamsItems"
              [childComponent]="null"
              [isMultiple]="true"
              [isClearable]="true"
              [showAddButton]="false"
              [notFoundText]="'hiring.form.placeholder_notfound'"
              [maxSelectedItems]="10"
              [deepLink]="true"
              [setValue]="idUsers"
              (isClearItems)="clearUserMarkers($event)"
              (onRemove)="removeUser($event)"
              (itemSelect)="testItemSelect($event)"
              (OnSelectObject)="changeUsers($event)"
            >
            </roma-select-search>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </div>
  <!-- <div class="col-12 col-lg-4 deals-container">
    <div *ngIf="isLoadingDeals; else dealsLoaded" class="h-50">
      <roma-loading class="h-50" size="50"></roma-loading>
    </div>

    <ng-template #dealsLoaded>
      <div class="">
        <div class="pflex">

          <ng-container *ngIf="!isLoadingDeals">
            <roma-select-search
              [isLoading]="isLoadingDeals"
              [type]="selectType"
              [style]="'my-6'"
              [tag]="'sidebar.services1' | translate"
              [controlName]="'idDeals'"
              [datasource]="dealsItems"
              [childComponent]="null"
              [isMultiple]="true"
              [isClearable]="true"
              [showAddButton]="false"
              [notFoundText]="'hiring.form.placeholder_notfound'"
              [maxSelectedItems]="10"
              [deepLink]="true"
              [name]="'deals'"
              (isClearItems)="clearDealMarkers($event)"
              (onRemove)="removeDeal($event)"
              (itemSelect)="testItemSelect($event)"
              (OnSelectObject)="changeDeal($event)"
            >
            </roma-select-search>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </div>
 -->
</div>
