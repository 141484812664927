import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IUser } from '@tacliatech/types';
import { UserService } from '@web-frontend/shared/services';

@Component({
  selector: 'roma-users-list-resume',
  templateUrl: './users-list-resume.component.html',
  styleUrls: ['./users-list-resume.component.scss'],
})
export class UsersListResumeComponent implements OnInit {
  @Input()
  data: { _id?: string; name?: string }[] = [];

  @Input()
  title: string = this.i18n.instant('table.colum.vendors');

  // @ts-ignore
  users: IUser[];
  _internalData: { _id?: string; name?: string; img: string }[] = [];

  isLoading = false;

  constructor(
    private activeModal: MatDialogRef<UsersListResumeComponent>,
    private i18n: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.findAllUsers().subscribe((resp) => {
      this.users = resp.docs;
      for (let i = 0; i < this.data.length; i++) {
        const urs = this.users.filter(
          (it) => it._id == this.data[i]._id || it.name == this.data[i].name
        );
        if (urs.length > 0) {
          this._internalData.push({
            _id: urs[0]._id,
            name: urs[0].name,
            // @ts-ignore
            img: urs[0].img,
          });
        }
      }
      this.isLoading = false;
    });
  }

  ok() {
    this.activeModal.close();
  }
}
