<div class="custom-modal__close" (click)="cancel($event)">
  <img [src]="'assets/icons/gl_close.svg'" />
</div>
<img src="/assets/icons/info-icon.svg" />
<h4 [innerHTML]="params!.title | translate"></h4>
<p *ngIf="params?.description">
  {{ params!.description | translate }}
</p>
<div class="modal-actions">
  <button
    *ngIf="params?.labelButtonCancel"
    class="cancel-btn"
    (click)="cancelButton($event)"
  >
    {{ params!.labelButtonCancel | translate }}
  </button>
  <button
    *ngIf="params?.labelButtonAction"
    class="success-btn"
    (click)="execute($event)"
  >
    {{ params!.labelButtonAction | translate }}
  </button>
</div>
