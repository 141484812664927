<div *ngIf="isImpersonating" class="alert fixed-bottom m-0 text-center" role="alert"
  style="background-color: #ff4d4d; color: white; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); padding: 1rem; font-size: 1.2em;">
  <mat-icon class="mr-2">⚠️</mat-icon>
  <span>
    Actualmente estás suplantando a un cliente. Cualquier acción que realices será en nombre de este cliente.
  </span>
</div>

<div *ngIf="isLoading" class="router-spinner col-12">
  <roma-loading></roma-loading>
</div>
<router-outlet></router-outlet>

<ng-template #template>
  <div class="flex-row">
    <div class="col-12 d-flex justify-content-center">
      <h4>Hay una nueva version</h4>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-center">
    <button mat-button color="primary" (click)="reloadSite()">
      Actualizar
    </button>
  </div>
</ng-template>