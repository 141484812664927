import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Feature, FeatureUser } from '@tacliatech/types';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { Subscription } from 'rxjs';
import { ProFeatureService } from './pro-feature.service';

interface featureParam {
  type: 'some' | 'every';
  features: [];
}

@Directive({
  selector: '[romaProFeature]',
})
export class ProDirective implements OnInit, OnDestroy {
  @Input()
  @HostBinding('class.pro-feature')
  feature!: Feature.Purchasable;

  _featureUser: Feature.Purchasable | FeatureUser.Purchasable | featureParam;
  @Input()
  @HostBinding('class.user-feature')
  set featureUser(
    value: Feature.Purchasable | FeatureUser.Purchasable | featureParam
  ) {
    //FeatureUser.Purchasable | [FeatureUser.Purchasable]
    this._featureUser = value;
    this.watchPermissions();
  }

  get featureUser() {
    return this._featureUser;
  }

  _featureUserHide: string | featureParam;
  @Input()
  @HostBinding('class.user-feature-hide')
  set featureUserHide(value: string | featureParam) {
    //FeatureUser.Purchasable | [FeatureUser.Purchasable]
    this._featureUserHide = value;
    this.watchPermissions();
  }

  get featureUserHide() {
    return this._featureUserHide;
  }

  @Output()
  onDenyPermission = new EventEmitter<boolean>();

  @Input()
  isImproveButton = false;

  @HostBinding('class.is-available')
  isAvailableFeature = false;

  @HostBinding('class.is-user-available')
  isAvailableUserFeature = false;

  @Input()
  @HostBinding('class.is-checkable')
  checkFeatureWhen = true;

  @Input() tooltip = 'permissions.errors.permissionStr'; // The text for the tooltip to display
  @Input() delay? = 190; // Optional delay input, in ms

  @Output()
  clicked = new EventEmitter<MouseEvent>();

  @Output()
  isAvailable = new EventEmitter<boolean>();

  private sub$ = new Subscription();
  private myPopup;
  private timer;

  constructor(
    private proFeatureService: ProFeatureService,
    private permissionService: PermissionService,
    private cdRef: ChangeDetectorRef,
    private host: ElementRef,
    private i18n: TranslateService,
    private amplitudeService: AmplitudeService
  ) {}

  get errorMessageUser() {
    if (!this.featureUser) {
      return this.i18n.instant(this.tooltip);
    }

    let featureSelected;
    let action;

    for (let i = 0; i < Object.keys(FeatureUser).length; i++) {
      if (Object.keys(FeatureUser)[i].endsWith('List')) {
        continue;
      }

      if (!FeatureUser[Object.keys(FeatureUser)[i]]) {
        continue;
      }

      if (FeatureUser[Object.keys(FeatureUser)[i]].show == this.featureUser) {
        featureSelected = Object.keys(FeatureUser)[i].toLowerCase();
        action = 'show';
      }
      if (FeatureUser[Object.keys(FeatureUser)[i]].create == this.featureUser) {
        featureSelected = Object.keys(FeatureUser)[i].toLowerCase();
        action = 'create';
      }
      if (FeatureUser[Object.keys(FeatureUser)[i]].update == this.featureUser) {
        featureSelected = Object.keys(FeatureUser)[i].toLowerCase();
        action = 'update';
      }
      if (FeatureUser[Object.keys(FeatureUser)[i]].delete == this.featureUser) {
        featureSelected = Object.keys(FeatureUser)[i].toLowerCase();
        action = 'delete';
      }
      if (
        FeatureUser[Object.keys(FeatureUser)[i]].approve == this.featureUser
      ) {
        featureSelected = Object.keys(FeatureUser)[i].toLowerCase();
        action = 'approve';
      }
    }

    if (!featureSelected || !action) {
      return this.i18n.instant(this.tooltip);
    }

    let translation = this.i18n.instant('permissions.errors.permissionStr');
    if (translation.includes('{type}')) {
      translation = translation.replace(
        '{type}',
        this.i18n.instant(`permissions.errors.{type}${featureSelected}`)
      );
    }
    if (translation.includes('{action}')) {
      translation = translation.replace(
        '{action}',
        this.i18n.instant(`permissions.errors.{action}${action}`)
      );
    }

    return translation;
  }

  ngOnInit(): void {
    this.watchPermissions();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  @HostListener('click', ['$event', '$event.target']) onClick(
    evt: MouseEvent,
    targetElement: HTMLElement
  ) {
    evt.stopPropagation();

    if (!targetElement) {
      return;
    }

    if (this.feature && this.checkFeatureWhen && !this.isAvailableFeature) {
      if (!this.isImproveButton) {
        this.amplitudeService.sendEvent({ event: `planPro_${this.feature}` });
      }

      if (this.isImproveButton) {
        this.amplitudeService.sendEvent({ event: 'planImprove_start' });
      }

      this.proFeatureService.open(this.feature);
      return;
    }

    if (
      (this.featureUser || this.featureUserHide) &&
      !this.isAvailableUserFeature
    ) {
      this.proFeatureService.openUser(this.errorMessageUser);
      return;
    }

    this.clicked.emit(evt);
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.featureUser || this.isAvailableUserFeature || !this.tooltip) {
      return;
    }
    this.timer = setTimeout(() => {
      const x =
        this.host.nativeElement.getBoundingClientRect().left +
        this.host.nativeElement.offsetWidth / 2; // Get the middle of the element
      const y =
        this.host.nativeElement.getBoundingClientRect().top +
        this.host.nativeElement.offsetHeight +
        6; // Get the bottom of the element, plus a little extra
      //this.createTooltipPopup(x, y);
    }, this.delay);
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (!this.featureUser || this.isAvailableUserFeature || !this.tooltip) {
      return;
    }
    if (this.timer) clearTimeout(this.timer);
    if (this.myPopup) {
      this.myPopup.remove();
    }
  }

  private createTooltipPopup(x: number, y: number) {
    const popup = document.createElement('div');
    popup.innerHTML = this.errorMessageUser;
    popup.setAttribute('class', 'tooltip-directive');
    popup.style.top = y.toString() + 'px';
    popup.style.left = x.toString() + 'px';
    document.body.appendChild(popup);
    this.myPopup = popup;
    setTimeout(() => {
      if (this.myPopup) this.myPopup.remove();
    }, 5000); // Remove tooltip after 5 seconds
  }

  private watchPermissions() {
    if (this.feature) {
      this.sub$.add(
        this.permissionService.permissions$.subscribe((res) => {
          this.isAvailableFeature =
            res.includes(this.feature) ||
            res.includes(Feature.SystemPermission.AllowAll);
          this.isAvailable.emit(this.isAvailableFeature);
          this.draw();
        })
      );
    }

    if (this.featureUser) {
      if (typeof this.featureUser == 'string') {
        this.sub$.add(
          this.permissionService.permissions$.subscribe((res) => {
            this.isAvailableUserFeature = res.includes(
              this.featureUser.toString()
            );
            this.draw();
          })
        );
      } else {
        if (this.featureUser.type == 'some') {
          const feature = this.featureUser;
          this.sub$.add(
            this.permissionService.permissions$.subscribe((res) => {
              this.isAvailableUserFeature = feature.features.some((item) =>
                res.includes(item)
              );
              this.draw();
            })
          );
        }
        if (this.featureUser.type == 'every') {
          const feature = this.featureUser;
          this.sub$.add(
            this.permissionService.permissions$.subscribe((res) => {
              this.isAvailableUserFeature = feature.features.every((item) =>
                res.includes(item)
              );
              this.draw();
            })
          );
        }
      }
      if (!this.isAvailableUserFeature) {
        this.onDenyPermission.emit();
      }
    }

    if (this.featureUserHide) {
      if (typeof this.featureUserHide == 'string') {
        this.sub$.add(
          this.permissionService.permissions$.subscribe((res) => {
            this.isAvailableUserFeature = res.includes(
              this.featureUserHide.toString()
            );
            this.draw();
          })
        );
      } else {
        if (this.featureUserHide.type == 'some') {
          const feature = this.featureUserHide;
          this.sub$.add(
            this.permissionService.permissions$.subscribe((res) => {
              this.isAvailableUserFeature = feature.features.some((item) =>
                res.includes(item)
              );
              this.draw();
            })
          );
        }
        if (this.featureUserHide.type == 'every') {
          const feature = this.featureUserHide;
          this.sub$.add(
            this.permissionService.permissions$.subscribe((res) => {
              this.isAvailableUserFeature = feature.features.every((item) =>
                res.includes(item)
              );
              this.draw();
            })
          );
        }
      }
      if (!this.isAvailableUserFeature) {
        this.onDenyPermission.emit();
      }
    }
  }

  private draw() {
    this.cdRef.detectChanges();
  }
}
