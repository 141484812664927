import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'roma-sale-error-warning',
  templateUrl: './sale-error-warning.component.html',
  styleUrls: ['./sale-error-warning.component.scss'],
})
export class SaleErrorWarningComponent implements OnInit {
  message = 'payments.errors.unknown';

  // it can be TacliaError type
  @Input()
  error: {
    i18n: string;
  };

  constructor() {}

  ngOnInit(): void {
    if (this.error?.i18n) {
      this.message = this.error.i18n;
    }
  }
}
