<ng-container *ngIf="stripeErrorObject">
  <div>{{ stripeErrorObject.message }}</div>
</ng-container>

<!--Header title row-->
<div class="page-header row">
  <div class="col-sm-12 col-md-4">
    <h1 class="page-title">
      {{ 'company-modules.modules.payments' | translate }}
      <div class="tooltip-container">
        <img
          class="help-icon"
          [ngStyle]="{ width: '32px' }"
          [src]="iconDialog"
          (click)="showDialog()"
        />
        <div
          *ngIf="showToolTip"
          class="title-tooltip-wrapper"
          (click)="toggleTooltip(false)"
        ></div>
        <div
          class="help-tooltip"
          *ngIf="showToolTip"
          romaRomaToolTip
          [showToolTip]="showToolTip"
          (clicked)="toggleTooltip($event)"
        >
          <div [innerHTML]="'payments.tooltip' | translate"></div>
          <a *ngIf="academyLink?.length" [href]="academyLink" target="_blank">
            {{ 'payments.tooltipUrl' | translate }}
          </a>
        </div>
      </div>
    </h1>
  </div>

  <div class="col-sm-12 col-md-8">
    <div class="buttons-container">
      <button
        [disabled]="isLoading"
        [ngClass]="{ disabled: isLoading }"
        class="roma-button-outline-light edit-info"
        (click)="generateUpdateSession()"
      >
        <img src="assets/icons/gl_edit-button.svg" class="btn-icon" />
        <span>{{ 'payments.edit-info' | translate }}</span>
      </button>

      <div
        class="tutorial"
        (click)="openVideo('payments')"
        *ngIf="showVideoTutorialButton"
      >
        <strong>{{ 'tutorial.tutorial' | translate }}</strong>
        <img class="video" src="assets/icons/gl_play-tutorial.svg" />
      </div>
    </div>
  </div>
</div>

<section class="dashboard" #dashboard></section>
