<mat-progress-spinner
  *ngIf="isLoading && showIsLoading"
  class="loading-spinner"
  mode="indeterminate"
  [diameter]="40"
  style="margin: 0px auto"
></mat-progress-spinner>
<ng-container *ngIf="!isLoading">
  <div
    class="custom__properties__wrapper"
    [ngClass]="
      showCustomProperties
        ? 'show__custom__properties'
        : 'hidden__custom__properties'
    "
  >
    <div
      romaProSelect
      [feature]="i < 2 ? featureRef.SystemPermission.DefaultAllow : feature"
      [options]="{
        className: 'roma-label warning right-center'
      }"
      class="wrapper-pro-feature-none"
      *ngFor="let property of customProperties; let i = index"
      [attr.data-index]="i"
      (isAvailableFeature)="isAvailableFeature($event, property._id)"
    >
      <!--<div
    romaProSelect
    [feature]="i < 2 ? featureRef.SystemPermission.DefaultAllow : feature"
    class="wrapper-pro-feature"
  >-->
      <form *ngIf="initForm" [formGroup]="parentForm" [ngClass]="size">
        <ng-container *ngIf="property?.isRequired">
          <input type="hidden" [formControlName]="property._id" />
        </ng-container>

        <ng-container *ngIf="property.type === dynamicPropertyTypeRef.String">
          <div>
            <div class="row">
              <div [ngClass]="rowLayout ? 'col-12' : 'col-5'">
                <label class="field-label" for="name">
                  {{ property.name | translate
                  }}<obligatory-field-sign
                    *ngIf="property.isRequired"
                  ></obligatory-field-sign>
                </label>
              </div>
              <div [ngClass]="rowLayout ? 'col-12' : 'col-7'">
                <div>
                  <!-- [ngClass]="
              property.isRequired &&
              parentForm.controls[property._id].invalid &&
              (parentForm.controls[property._id].dirty ||
                parentForm.controls[property._id].touched) &&
              parentFormSubmit
                ? 'error-border'
                : null
            " -->
                  <button
                    class="add-field-btn"
                    [id]="'btn-' + property._id"
                    (click)="showPropertyInput(property._id)"
                    [ngStyle]="
                      !rowLayout ? { display: 'block' } : { display: 'none' }
                    "
                  >
                    {{ 'general.add2' | translate }}
                    <img
                      src="assets/icons/gl_plus.svg"
                      style="margin-top: -5px"
                    />
                  </button>
                  <input
                    #stringInput
                    [id]="property._id"
                    class="form-control"
                    [ngStyle]="
                      rowLayout ? { display: 'block' } : { display: 'none' }
                    "
                    type="text"
                    placeholder="{{ 'activity.enterText' | translate }}"
                    [ngClass]="{
                      'is-invalid':
                        property.isRequired &&
                        parentForm.controls[property._id].invalid &&
                        parentFormSubmit
                    }"
                    (keyup)="
                      changeElement(property, $event.target.value);
                      setCustomPropertyValue(
                        property.isRequired,
                        property._id,
                        $event.target.value
                      )
                    "
                  />
                  <img
                    [id]="'error-' + property._id"
                    *ngIf="
                      property.isRequired &&
                      parentForm.controls[property._id].invalid &&
                      parentFormSubmit
                    "
                    class="input__error__icon"
                    src="assets/icons/gl_input-error.svg"
                  />
                </div>

                <div
                  *ngIf="
                    property.isRequired &&
                    parentForm.controls[property._id].invalid &&
                    parentFormSubmit
                  "
                >
                  <mat-error
                    class="error-label"
                    *ngIf="parentForm.controls[property._id].errors.required"
                    >{{ 'general.requiredField' | translate }}</mat-error
                  >
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="property.type === dynamicPropertyTypeRef.Number">
          <div>
            <div class="row">
              <div [ngClass]="rowLayout ? 'col-12' : 'col-5'">
                <label class="field-label" for="name">
                  {{ property.name | translate
                  }}<obligatory-field-sign
                    *ngIf="property.isRequired"
                  ></obligatory-field-sign>
                </label>
              </div>
              <div [ngClass]="rowLayout ? 'col-12' : 'col-7'">
                <button
                  class="add-field-btn"
                  [id]="'btn-' + property._id"
                  (click)="showPropertyInput(property._id)"
                  [ngStyle]="
                    !rowLayout ? { display: 'block' } : { display: 'none' }
                  "
                >
                  {{ 'general.add2' | translate }}
                  <img
                    src="assets/icons/gl_plus.svg"
                    style="margin-top: -5px"
                  />
                </button>
                <input
                  #numberInput
                  [id]="property._id"
                  class="form-control"
                  [ngStyle]="
                    rowLayout ? { display: 'block' } : { display: 'none' }
                  "
                  type="number"
                  placeholder="{{ 'activity.enterValue' | translate }}"
                  [ngClass]="{
                    'is-invalid':
                      property.isRequired &&
                      parentForm.controls[property._id].invalid &&
                      parentFormSubmit
                  }"
                  (change)="
                    changeElement(property, +$event.target.value);
                    setCustomPropertyValue(
                      property.isRequired,
                      property._id,
                      +$event.target.value
                    )
                  "
                />
                <img
                  [id]="'error-' + property._id"
                  *ngIf="
                    property.isRequired &&
                    parentForm.controls[property._id].invalid &&
                    parentFormSubmit
                  "
                  class="input__error__icon"
                  src="assets/icons/gl_input-error.svg"
                />
                <div
                  *ngIf="
                    property.isRequired &&
                    parentForm.controls[property._id].invalid &&
                    parentFormSubmit
                  "
                >
                  <mat-error
                    class="error-label"
                    *ngIf="parentForm.controls[property._id].errors.required"
                    >{{ 'general.requiredField' | translate }}</mat-error
                  >
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="property.type === dynamicPropertyTypeRef.Date">
          <div>
            <div class="row">
              <div [ngClass]="rowLayout ? 'col-12' : 'col-5'">
                <label class="field-label" for="name">
                  {{ property.name | translate
                  }}<obligatory-field-sign
                    *ngIf="property.isRequired"
                  ></obligatory-field-sign>
                </label>
              </div>
              <div [ngClass]="rowLayout ? 'col-12' : 'col-7'">
                <button
                  class="add-field-btn"
                  [id]="'btn-' + property._id"
                  (click)="showPropertyInput(property._id)"
                  [ngStyle]="
                    !rowLayout ? { display: 'block' } : { display: 'none' }
                  "
                >
                  {{ 'general.add2' | translate }}
                  <img
                    src="assets/icons/gl_plus.svg"
                    style="margin-top: -5px"
                  />
                </button>
                <mat-form-field
                  appearance="fill"
                  class="full-field"
                  [ngClass]="{
                    'is-invalid':
                      property.isRequired &&
                      parentForm.controls[property._id].invalid &&
                      parentFormSubmit
                  }"
                  [ngStyle]="
                    rowLayout ? { display: 'block' } : { display: 'none' }
                  "
                  [id]="property._id"
                  style="margin-bottom: 16px !important"
                >
                  <input
                    #dateInput
                    matInput
                    [matDatepicker]="picker"
                    class="input-editable date-input"
                    placeholder="dd/mm/aaaa"
                    (focus)="picker.open(); inputFocus()"
                    (click)="picker.open()"
                    (dateChange)="dateInputChange(property, dateInput.value)"
                    onkeydown="return false;"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                    style="height: 35px; margin-right: 8px"
                    (click)="picker.open(); inputFocus()"
                  >
                    <mat-icon
                      matDatepickerToggleIcon
                      style="
                        height: 35px;
                        position: relative;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <img
                        class="calendar-icon"
                        src="assets/icons/gl_calendar.svg"
                      />
                    </mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <img
                    [id]="'error-' + property._id"
                    *ngIf="
                      property.isRequired &&
                      parentForm.controls[property._id].invalid &&
                      parentFormSubmit
                    "
                    class="input__error__icon"
                    src="assets/icons/gl_input-error.svg"
                    style="
                      top: -1px;
                      right: -27px;
                      z-index: 1;
                      background-color: #fff;
                    "
                  />
                </mat-form-field>
                <div
                  class="calendar-clean-icon"
                  matSuffix
                  [disableRipple]="true"
                  (click)="clearDate(); dateInputChange(property, '')"
                  *ngIf="dateInput.value !== ''"
                  style="margin-right: 10px"
                >
                  <mat-icon matDatepickerToggleIcon style="position: relative">
                    <img
                      class="calendar-icon"
                      src="assets/icons/gl_close-input.svg"
                    />
                  </mat-icon>
                </div>
                <div
                  *ngIf="
                    property.isRequired &&
                    parentForm.controls[property._id].invalid &&
                    parentFormSubmit
                  "
                >
                  <mat-error
                    class="error-label"
                    *ngIf="parentForm.controls[property._id].errors.required"
                    >{{ 'general.requiredField' | translate }}</mat-error
                  >
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="property.type === dynamicPropertyTypeRef.Boolean">
          <div class="row">
            <div [ngClass]="rowLayout ? 'col-12' : 'col-5'">
              <label class="field-label" for="name">
                {{ property.name | translate
                }}<obligatory-field-sign
                  *ngIf="property.isRequired"
                ></obligatory-field-sign>
              </label>
            </div>
            <div [ngClass]="rowLayout ? 'col-12' : 'col-7'">
              <button
                *ngIf="!parentForm?.controls?.[property._id]?.value
              "
                class="add-field-btn"
                [id]="'btn-' + property._id"
                (click)="showPropertyInput(property._id)"
                [ngStyle]="
                  !rowLayout ? { display: 'block' } : { display: 'none' }
                "
              >
                {{ 'general.add2' | translate }}
                <img src="assets/icons/gl_plus.svg" style="margin-top: -5px" />
              </button>

              <select
                #booleanInput
                [id]="property._id"
                class="select-editable form-control"
                placeholder="{{ 'activity.edit.selectOption' | translate }}"
                [ngClass]="{
                  'is-invalid':
                    property.isRequired &&
                    parentForm.controls[property._id].invalid &&
                    parentFormSubmit
                }"
                [ngStyle]="rowLayout ? {'display': 'block'} : (parentForm?.controls?.[property._id]?.value ? {'display': 'block'} : {'display': 'none'})"
                (change)="
                  changeElement(property, $event.target.value);
                  setCustomPropertyValue(
                    property.isRequired,
                    property._id,
                    $event.target.value
                  )
                "
              >
                <option *ngIf="property.isRequired === false" value="">
                  Sin asignar
                </option>

                <option
                  value="true"
                  [selected]="isBooleanDefault(property, true)"
                >
                  {{ 'general.yes' | translate }}
                </option>
                <option
                  value="false"
                  [selected]="isBooleanDefault(property, false)"
                >
                  {{ 'general.no' | translate }}
                </option>
              </select>
              <img
                [id]="'error-' + property._id"
                *ngIf="
                  property.isRequired &&
                  parentForm.controls[property._id].invalid &&
                  parentFormSubmit
                "
                class="input__error__icon"
                src="assets/icons/gl_input-error.svg"
              />
              <div
                *ngIf="
                  property.isRequired &&
                  parentForm.controls[property._id].invalid &&
                  parentFormSubmit
                "
              >
                <mat-error
                  class="error-label"
                  *ngIf="parentForm.controls[property._id].errors.required"
                >
                  {{ 'general.requiredField' | translate }}
                </mat-error>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="property.type === dynamicPropertyTypeRef.Select">
          <div>
            <div class="row">
              <div [ngClass]="rowLayout ? 'col-12' : 'col-5'">
                <label class="field-label" for="name">
                  {{ property.name | translate
                  }}<obligatory-field-sign
                    *ngIf="property.isRequired"
                  ></obligatory-field-sign>
                </label>
              </div>
              <div [ngClass]="rowLayout ? 'col-12' : 'col-7'">
                <button
                  class="add-field-btn"
                  [id]="'btn-' + property._id"
                  (click)="showPropertyInput(property._id)"
                  [ngStyle]="
                    !rowLayout ? { display: 'block' } : { display: 'none' }
                  "
                >
                  {{ 'general.add2' | translate }}
                  <img
                    src="assets/icons/gl_plus.svg"
                    style="margin-top: -5px"
                  />
                </button>
                <ng-select
                  #selectInput
                  [id]="property._id"
                  [items]="property.options"
                  [multiple]="true"
                  placeholder="{{ 'activity.edit.selectOption' | translate }}"
                  [notFoundText]="
                    'hiring.form.placeholder_notfound' | translate
                  "
                  [ngClass]="{
                    'is-invalid':
                      property.isRequired &&
                      parentForm.controls[property._id].invalid &&
                      parentFormSubmit
                  }"
                  [ngStyle]="
                    rowLayout ? { display: 'block' } : { display: 'none' }
                  "
                  (change)="
                    changeElement(property, $event);
                    setCustomPropertyValue(
                      property.isRequired,
                      property._id,
                      $event
                    )
                  "
                >
                </ng-select>
                <img
                  [id]="'error-' + property._id"
                  *ngIf="
                    property.isRequired &&
                    parentForm.controls[property._id].invalid &&
                    parentFormSubmit
                  "
                  class="input__error__icon"
                  src="assets/icons/gl_input-error.svg"
                />
                <div
                  *ngIf="
                    property.isRequired &&
                    parentForm.controls[property._id].invalid &&
                    parentFormSubmit
                  "
                >
                  <mat-error
                    class="error-label"
                    *ngIf="parentForm.controls[property._id].errors.required"
                    >{{ 'general.requiredField' | translate }}</mat-error
                  >
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</ng-container>

<div class="custom-fields-message" *ngIf="showPropertiesMessage && !isLoading">
  <span class="material-icons pointer info ng-star-inserted">info</span>
  <h4>{{ 'expense.messageTitle' | translate }}</h4>
  <p [innerHTML]="'expense.message' | translate"></p>
  <a (click)="goTo('/admin/customization', {})">
    {{ 'expense.messageButton' | translate }}
  </a>
</div>
