import { InjectionToken } from '@angular/core';

export class ToastData implements IToastData {
  type?: ToastType;
  icon?: string;
  showClose?: boolean;
  text?: string;
  description?: string;
  class?: string;
  msDuration?: number;
  link?: string;
  linkData?: any;
  typeComponent?: ToastComponentType;
}

export interface IToastData {
  type?: ToastType;
  icon?: string;
  showClose?: boolean;
  text?: string;
  description?: string;
  class?: string;
  msDuration?: number;
  link?: string;
  linkData?: any;
  typeComponent?: ToastComponentType;
}

export type ToastComponentType = 'toast' | 'snackbar';

export type ToastType = 'info' | 'success' | 'error';

export interface ToastConfig {
  position?: {
    top: number;
    right: number;
  };
  animation?: {
    fadeOut: number;
    fadeIn: number;
  };
}

export const TOAST_CONFIG_TOKEN = new InjectionToken('toast-config');
