import { ICreateActivityDto } from '@tacliatech/types';
import * as moment from 'moment-timezone';

import { Asset } from './asset';
import { Customer } from './customer.class';
import { Deal } from './deal.class';
import { User } from './user.class';
import { InternalVendor } from './vendor.class';

export class Activity {
  _id: string;
  asset_id: Asset;
  company_id: Customer;
  dateTime: Date;
  description: string;
  notified: boolean;
  owner: User;
  service_id: Deal;
  title: string;
  vendor_id: InternalVendor;
  createdBy: User;
  deleted?: boolean;

  static fromArray(attributes: ICreateActivityDto[]) {
    return attributes.map((attribute) => new Activity(attribute));
  }

  constructor(attributes?: ICreateActivityDto) {
    /*const asset_id =
      typeof attributes?.asset_id === 'string'
        ? new Asset({ _id: attributes?.asset_id })
        : new Asset(attributes?.asset_id);*/

    const customer =
      typeof attributes?.customer === 'string'
        ? null
        : new Customer(attributes?.customer);

    const deal =
      typeof attributes?.deal === 'string' ? null : new Deal(attributes?.deal);

    const owner =
      typeof attributes?.owner === 'string'
        ? null
        : new User(attributes?.owner);

    const createdBy =
      typeof attributes?.createdBy === 'string'
        ? null
        : new User(attributes.createdBy);

    const dateTime = attributes?.dateTime
      ? new Date(attributes.dateTime)
      : null;

    Object.assign(this, attributes, {
      customer,
      owner,
      createdBy,
      deal,
      dateTime,
    });
  }

  private static completeValue(value) {
    if (value < 10) {
      return '0' + value;
    } else {
      return value;
    }
  }

  localTime() {
    return this.dateTime ? this.dateTime?.toISOString().replace('Z', '') : null;
  }

  toLocalTimeFormatted() {
    let formattedDate = null;

    if (this.dateTime) {
      formattedDate =
        this.dateTime.getUTCDate() +
        '/' +
        Activity.completeValue(this.dateTime.getUTCMonth() + 1) +
        '/' +
        Activity.completeValue(this.dateTime.getUTCFullYear()) +
        ' ' +
        Activity.completeValue(this.dateTime.getUTCHours()) +
        ':' +
        Activity.completeValue(this.dateTime.getUTCMinutes());
    }

    return formattedDate;
  }

  isToday() {
    return moment(this.dateTime.toUTCString()).isSame(moment(), 'day');
  }

  public isExpired() {
    return moment(this.toLocalTimeFormatted(), 'DD/MM/YYYY hh:mm').isBefore(
      new Date()
    );
  }

  public test() {
    return 'esto es un mensaje';
  }

  isAfter() {
    return !this.isToday() && this.dateTime > new Date();
  }

  isNow() {
    return this.isToday() && !this.isExpired();
  }
}
