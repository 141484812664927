import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Deal, Feature, FeatureUser } from '@tacliatech/types';
import { CreateDealComponent } from '../create-deal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';

@Component({
  selector: 'roma-wrapper-sales',
  templateUrl: './wrapper-sales.component.html',
  styleUrls: ['./wrapper-sales.component.scss'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WrapperSalesComponent implements OnInit, OnDestroy {
  @Input()
  dealData: Deal.Output;

  @Input()
  isLoading = false;

  @Input()
  type: 'ADD' | 'EDIT' = 'ADD';

  isClickedBtnBill = false;
  isClickedBtnBudget = false;
  currentTabIndex = 0;

  featureRefUser = FeatureUser;
  private sub$ = new Subscription();

  constructor(
    private router: Router,
    private analyticsService: AnalyticsService,
    private dialogRef: MatDialogRef<CreateDealComponent>,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  havePermission(permission: Feature.Purchasable | FeatureUser.Purchasable) {
    return this.permissionService.allowedFeature(permission);
  }
  getSelectedIndex(): number {
    return this.currentTabIndex;
  }

  onTabChange(event: MatTabChangeEvent) {
    //this.resolveColumns(event.tab.textLabel );
    let eventTracking = '';

    if (event.index == 0) {
      eventTracking = AmplitudeEvents.deal_card_sale_budget_tab;
    }
    if (event.index == 1) {
      eventTracking = AmplitudeEvents.deal_card_sale_bill_tab;
    }
    if (event.index == 2) {
      eventTracking = AmplitudeEvents.deal_card_sale_waybill_tab;
    }
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: eventTracking,
    });
    this.currentTabIndex = event.index;
  }

  addBudget(type: string, deal: string) {
    let eventTracking = '';
    if (type == 'BDG') {
      eventTracking = AmplitudeEvents.deal_card_sale_budget_start;
    } else {
      if (type == 'BILL') {
        eventTracking = AmplitudeEvents.deal_card_sale_bill_start;
      } else {
        eventTracking = AmplitudeEvents.deal_card_sale_waybill_start;
      }
    }
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: eventTracking,
    });
    this.router.navigate(['/admin/budgets/edit'], {
      queryParams: { type, idDeal: deal },
    });
  }

  disableButtonBill(value: boolean) {
    this.isClickedBtnBill = value;
  }

  disableButtonBudget(value: boolean) {
    this.isClickedBtnBudget = value;
  }

  close() {
    this.dialogRef.close(true);
  }
}
