<div class="mat-dialog-header">
  <img
    (click)="exit()"
    width="24px"
    height="24px"
    src="assets/icons/gl_close.svg"
    class="mat-dialog-header_close"
  />
</div>
<div mat-dialog-content>
  <img
    *ngIf="data.showIcon === undefined || data.showIcon"
    src="assets/icons/delete-icon.svg"
    class="mat-dialog-content_icon"
  />
  <h1 class="mat-dialog-content_title">
    {{ data.title ?? (data.isPlural ? 'deleteModal.title_plural' : 'deleteModal.title') | translate }}
  </h1>
  <p
    *ngIf="data.showBody === undefined || data.showBody"
    class="mat-dialog-content_body"
  >
    {{ data.body ??  (data.isPlural ? 'deleteModal.body_plural' : 'deleteModal.body') | translate }}
  </p>
</div>
<div mat-dialog-actions>
  <button class="cancel" mat-button (click)="onNoClick()">
    {{data.btn_cancelLabel ??  'deleteModal.cancel' | translate  }}
  </button>
  <button
    class="confirm-delete"
    mat-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    (click)="execute()"
  >
    {{data.confirmLabel ??  'deleteModal.confirm' | translate  }}
  </button>
</div>
