<roma-modal-template
  [title]="
    params?.type === 'ADD'
      ? ('add-categories-common.add' | translate: { type: categoryTypeTitle })
      : ('add-categories-common.edit' | translate: { type: categoryTypeTitle })
  "
  [contentMinHeight]="false"
  [isLoading]="isLoading"
  (success)="submit()"
  (cancel)="close()"
>
  <form [formGroup]="form" class="global-container">
    <div class="form-group mb-10">
      <label for="name">
        {{ 'add-categories-common.name' | translate }}
      </label>
      <input
        type="text"
        class="form-control"
        id="name"
        aria-describedby="nameHelp"
        formControlName="name"
        maxLength="50"
        [placeholder]="'add-categories-common.placeholder' | translate"
        [ngClass]="{ 'is-invalid': fieldName.displayError || false }"
      />

      <roma-field-error-display
        #fieldName
        fieldName="name"
        [errorMsg]="'general.requiredField' | translate"
        [form]="form"
      >
      </roma-field-error-display>
    </div>
    <ng-template #noExpense> </ng-template>
  </form>
</roma-modal-template>
