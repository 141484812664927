import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@web-frontend/shared/services';
import {
  getDateStrResum,
  getDateStrResumStr,
} from '@web-frontend/shared/utils';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'dateParse',
})
export class DateParsePipe implements PipeTransform {
  constructor(private i18n: TranslateService) {}

  transform(value: string, format = ''): any {
    return this.getDate(value, format);
  }

  private getDate(date: string, format: string) {
    moment.locale(this.i18n.currentLang);
    let dateFormatted;

    if (!date) {
      return '';
    }

    switch (format) {
      case '':
        // @ts-ignore
        dateFormatted = getDateStrResumStr(date).formattedDateStorage;
        break;
      case '12Hours':
        dateFormatted = getDateStrResum(new Date(date))
          .formattedDateStorageHours;
        break;
      case 'AvoidLocaleDate':
        dateFormatted = moment(
          getDateStrResum(new Date(date)).parseToISOStringLocal
        ).format(StorageService.DateFormat);
        break;
      case 'AvoidLocaleDateWithTimestamp':
        dateFormatted = moment(
          getDateStrResum(new Date(date)).parseToISOStringLocal
        ).format('DD/MM/YYYY - hh:mm A');
        break;
      default:
        dateFormatted = moment(date).utc().format(StorageService.DateFormat);
    }
    return dateFormatted;
  }
}
