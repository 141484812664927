import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignatureWaybillComponent } from './signature-waybill.component';
import { MatButtonModule } from '@angular/material/button';
import { I18nChild } from '@web-frontend/shared/i18n';
import { PermissionsModule } from '../permissions';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [SignatureWaybillComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    I18nChild,
    PermissionsModule,
    MatProgressSpinnerModule,
  ],
  exports: [SignatureWaybillComponent],
})
export class SignatureWaybillModule {}
