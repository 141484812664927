import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { CreateDealService } from '@web-frontend/shared/components/create-deal';
import { CreateSaleService } from '@web-frontend/shared/components/create-sale';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { ChargebeeService } from './shared/components/permissions/chargebee.service';
import { injectScript } from './shared/helpers/inject-script/inject-script.service';
import { I18nService } from './shared/i18n';
import { StorageService } from './shared/services';

import { map, tap } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';
import { Capacitor } from '@capacitor/core';
import { CreateFinalV2Service } from '@web-frontend/shared/components/create-final-v2';
import { ModalErrorConnectionService } from './shared/components/modal-error-connection/modal-error-connection.service';
import { ConnectionService } from './shared/services/connection';
import { RouterService } from './shared/services/router/router.service';
import { IosPermissionService } from './shared/services/ios-permission/ios-permission.service';
import { PlatformService } from './shared/components/platform-disable-content/platform.service';
import { IMPERSONATED } from '@tacliatech/types';

@Component({
  selector: 'roma-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'web-frontend';
  hasUpdate = false;
  private sub$ = new Subscription();
  isLoading = false;
  isImpersonating = false;

  constructor(
    private i18n: I18nService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private readonly elementRef: ElementRef,
    private renderer: Renderer2,
    private chargebeeService: ChargebeeService,
    private createDealService: CreateDealService,
    @Inject(DOCUMENT) private document: Document,
    private createSaleService: CreateSaleService,
    private createFinalV2Service: CreateFinalV2Service,
    private connectionService: ConnectionService,
    private modalErrorConnectionService: ModalErrorConnectionService,
    private routerService: RouterService,
    private readonly iosPermissions: IosPermissionService,
    private readonly platformService: PlatformService
  ) {
    this.injectedScriptMap();
    this.sub$.add(
      this.router.events.subscribe((event: RouterEvent) => {
        if (event instanceof NavigationStart) {
          this.isLoading = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.isLoading = false;
        }

        this.isImpersonating = StorageService.GetItem(IMPERSONATED, true);
      })
    );
  }

  @ViewChild('template', { static: true }) template;
  @HostListener('gesturestart', ['$event'])
  gestureStart(evt: Event) {
    evt.preventDefault();
  }

  async ngOnInit() {
    this.watchParams();
    this.i18n.initializeTranslate();

    // Inject Cookieyes only on non iOS devices
    if (!this.platformService.isIos()) {
      injectScript(
        this.renderer,
        this.elementRef,
        'https://cdn-cookieyes.com/client_data/4b05c3cddd4a66b4cfbe6e18/script.js',
        false
      );
    }

    this.iosPermissions.requestPrivacyPermission();

    // Injecting chargebee script
    this.chargebeeService.injectScript(this.renderer, this.elementRef);

    // Injecting hotjar script
    if (environment.production) {
      injectScript(
        this.renderer,
        this.elementRef,
        `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:2541012,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
        true
      );
    }

    this.connectionService.isOnline$
      .pipe(
        tap((x) => {
          !x
            ? this.modalErrorConnectionService.show()
            : this.modalErrorConnectionService.dismiss();
        })
      )
      .subscribe();

    this.routerService.startListen();
  }

  injectedScriptMap() {
    let API_KEY_MAP = environment.apiKeyMapWeb;

    if (Capacitor.getPlatform() !== 'web') {
      API_KEY_MAP = environment.apiKeyMapAndroid;
      console.log(`[ANDROID] - ${API_KEY_MAP}`);
    }

    const script = this.renderer.createElement('script');

    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY_MAP}&libraries=places&callback=Function.prototype`;
    script.defer = true;

    this.renderer.appendChild(this.document.head, script);
  }

  serviceWorkerRemover() {
    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          //console.log
          registration.unregister();
        }
      });
    }

    if ('caches' in window) {
      caches.keys().then(function (keyList) {
        return Promise.all(
          keyList.map(function (key) {
            return caches.delete(key);
          })
        );
      });
    }
  }

  reloadSite(): void {
    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href;
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  private watchParams() {
    this.sub$.add(
      this.activatedRouter.queryParamMap.subscribe((res) => {
        const type = res.get('type');
        const id = res.get('id');

        if (StorageService.Token && type && id) {
          switch (type) {
            case 'EQUIPMENT':
              this.router.navigateByUrl(`admin/equipments?id=${id}`);
              break;

            case 'deal':
              this.createDealService
                .open({
                  data: {
                    mode: 'EDIT',
                    idDeal: id,
                  },
                })
                .pipe(map((res) => !res))
                .subscribe();
              break;

            case 'sale':
              this.createSaleService
                .open({
                  position: { right: '0px' },
                  data: {
                    mode: 'EDIT',
                    idSale: id,
                  },
                })
                .pipe(map((res) => !res))
                .subscribe();
              break;

            case 'customer':
              this.createFinalV2Service
                .open({
                  data: {
                    mode: 'EDIT',
                    idCustomer: id,
                  },
                })
                .pipe(map((res) => !res))
                .subscribe();
              break;

            default:
              break;
          }
        }

        /*if (type === 'activity' && id) {
          if (StorageService.Token) {
            this.createActivityService.open({
              position: { right: '0px' },
              data: { activity: id },
            });
          }
        }*/
      })
    );
  }
}
