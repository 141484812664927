import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { ClickStopPropagation } from './click-prevent-default.directive';

@NgModule({
  declarations: [ClickStopPropagation],
  imports: [CommonModule, TranslateModule.forChild()],
  exports: [ClickStopPropagation],
})
export class ClickStopPropagationModule {}
