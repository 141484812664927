import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FileSelectService } from '@web-frontend/shared/helpers/file-select';
import { FileUploadService } from '@web-frontend/shared/helpers/file-upload';
import { AlertService } from '@web-frontend/shared/services/alert/alert.service';
import { EFileType } from '@tacliatech/types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'roma-rm-file-selector',
  templateUrl: './rm-file-selector.component.html',
  styleUrls: ['./rm-file-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmFileSelectorComponent implements OnInit {
  @Input()
  maxMbSize?: number;

  @Input()
  resources: string[] = [];

  @Output()
  newResources = new EventEmitter();

  uploadProgress$ = this.fileUploadService.uploadProgress$;

  private uploadFileSub$!: Subscription;

  listOfFiles = [];

  constructor(
    private i18n: TranslateService,
    private cdRef: ChangeDetectorRef,
    private fileSelectService: FileSelectService,
    private fileUploadService: FileUploadService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {}

  select() {
    this.fileSelectService
      .openSelector()
      .then((res) => {
        if (res.getAll('file[]').length) {
          const file = res.get('file[]');

          if (file instanceof File) {
            if (this.maxMbSize && this.maxMbSize * 1000000 > file.size) {
              this.uploadFileSub$ = this.fileUploadService
                .uploadFile(EFileType.ABSENCE, file)
                .subscribe((res) => {
                  this.resources = [...this.resources, res];

                  this.listOfFiles.push({
                    name: file['name'],
                    remoteName: res,
                  });

                  this.newResources.emit(this.resources);

                  this.draw();
                });
            } else {
              if (this.maxMbSize) {
                //error of size
                this.alertService.error(
                  this.i18n.instant('general.fileExceedsSize')
                );
              }
            }
          }
        }
      })
      .catch(() => {
        console.warn('Pick file aborted');
      });
  }

  deleteResource(evt: MouseEvent, index: number) {
    if (evt) {
      this.resources = this.resources.filter(
        (res, position) => position !== index
      );

      this.listOfFiles = this.listOfFiles.filter((item, idx) => {
        return idx !== index;
      });

      this.newResources.emit(this.resources);

      this.draw();
    }
  }

  cancelResource(evt: MouseEvent) {
    if (evt) {
      if (this.uploadFileSub$) {
        this.uploadFileSub$.unsubscribe();
      }
    }
  }

  private draw() {
    this.cdRef.detectChanges();
  }
}
