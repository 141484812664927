import { Injectable, inject } from '@angular/core';
import { Tracking } from '@tacliatech/types';

import { v4 as uuidv4 } from 'uuid';

import { Observable, Subject } from 'rxjs';
import {
  collection,
  collectionChanges,
  CollectionReference,
  DocumentChange,
  Firestore,
  query,
  where,
} from '@angular/fire/firestore';

import {
  AngularFirestore,
  AngularFirestoreDocument,
  DocumentChangeAction,
} from '@angular/fire/compat/firestore';

import { map } from 'rxjs/operators';
import { StorageService } from '../storage';
import {
  Auth,
  authState,
  signInAnonymously,
  getAuth,
} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  trackingDB$: any;
  item?: Observable<Tracking>;

  private refreshListSource = new Subject<boolean>();
  refreshList$ = this.refreshListSource.asObservable();

  private auth: Auth = inject(Auth);
  authState$ = authState(this.auth);

  constructor(private firestore: Firestore, private afs: AngularFirestore) {}

  get DB() {
    return this.firestore;
  }

  refreshAll() {
    this.refreshListSource.next(true);
  }

  getTracking(userID: string) {
    return collectionChanges<Tracking>(
      query<Tracking>(
        collection(this.DB, 'tracking') as CollectionReference<Tracking>,
        where('user_id', '==', userID)
      )
    ).pipe(
      map((changes: DocumentChange<Tracking>[]) => {
        return (changes ?? []).map(
          (c) =>
            ({
              $key: c.doc.id,
              ...(c.doc.data() as any),
            } as Tracking)
        );
      })
    );
  }

  getTrackingByUsers(users: string[], mydate: any) {
    const parts = mydate.split('-');
    const dateFilter = new Date(parts[0], parts[1] - 1, parts[2], 23, 59, 59);
    const dateFilterBefore = new Date(
      parts[0],
      parts[1] - 1,
      parts[2] - 1,
      23,
      59,
      59
    );
    //   where('instant', '==', dateFilter)

    //where('instant', '>=', dateFilterBefore),
    // where('instant', '<', dateFilter)
    return collectionChanges<Tracking>(
      query<Tracking>(
        collection(this.DB, 'tracking') as CollectionReference<Tracking>,
        where('user_id', 'array-contains-any', users)
      )
    ).pipe(
      map((changes: DocumentChange<Tracking>[]) => {
        return (changes ?? []).map(
          (c) =>
            ({
              $key: c.doc.id,
              ...(c.doc.data() as any),
            } as Tracking)
        );
      })
    );
  }

  async addOrUpdate(tracking: Tracking) {
    const auth = getAuth();
    await signInAnonymously(auth);

    const doc = this.afs.doc<Tracking>(`tracking/${uuidv4()}`);

    return doc.set(tracking);
  }

  groupByUsers(userIds, mydate: any, first = null) {
    const parts = mydate.split('-');
    const dateFilterAfter = new Date(
      parts[0],
      parts[1] - 1,
      parts[2],
      23,
      59,
      59
    );
    const dateFilterBefore = new Date(
      parts[0],
      parts[1] - 1,
      parts[2] - 1,
      23,
      59,
      59
    );

    if (first) {
      return this.getAllUserPositionByCompany(
        dateFilterBefore,
        dateFilterAfter
      );
    } else {
      return this.getUsersSelectedPosition(
        userIds,
        dateFilterBefore,
        dateFilterAfter
      );
    }

    //return data;
  }

  getUsersSelectedPosition(
    userIds: any,
    dateFilterBefore: Date,
    dateFilterAfter: Date
  ) {
    return this.afs
      .collectionGroup('tracking', (ref) =>
        ref
          .where('user_id', 'in', userIds)
          .where('instant', '>=', dateFilterBefore)
          .where('instant', '<=', dateFilterAfter)
      )
      .snapshotChanges()
      .pipe(
        map((changes: DocumentChangeAction<any>[]) => {
          return (changes ?? []).map(
            (c) =>
              ({
                $key: c.payload.doc.id,
                ...(c.payload.doc.data() as any),
              } as Tracking)
          );
        })
      );
  }

  getAllUserPositionByCompany(dateFilterBefore: Date, dateFilterAfter: Date) {
    return this.afs
      .collectionGroup('tracking', (ref) =>
        ref
          .where('company_id', '==', StorageService.CompanyId)
          .where('instant', '>=', dateFilterBefore)
          .where('instant', '<=', dateFilterAfter)
      )
      .snapshotChanges()
      .pipe(
        map((changes: DocumentChangeAction<any>[]) => {
          return (changes ?? []).map(
            (c) =>
              ({
                $key: c.payload.doc.id,
                ...(c.payload.doc.data() as any),
              } as Tracking)
          );
        })
      );
  }
}
