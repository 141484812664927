import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

export interface tooltipMessage{
  type: 'text' | 'html';
  content: string;
}

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

  constructor() { }

  @Input()
  background: 'black' | 'white' | 'blue' = 'black';

  @Input()
  messages: tooltipMessage[] = [];

  @Input()
  position: 'mouse' | 'bottom' | 'right' = 'mouse';

  @Output()
  onMouseLeave = new EventEmitter();

  ngOnInit(): void {
  }

  @HostListener('mouseleave', ['$event']) leave(event: MouseEvent) {
    this.onMouseLeave.emit();
  }

  setPosition(x: number, y: number) {
    const tooltipElement = document.querySelector('.toolTipBody') as HTMLElement;
    tooltipElement.style.left = x + 'px';
    tooltipElement.style.top = y + 'px';
    tooltipElement.style.transform = 'translate(-50%, 0)'; // Centra horizontalmente
  }

}
