<section class="address_map">
  <ng-container *ngIf="layoutObs$ | async as layout">
    <ng-container
      *ngIf="
        address !== '' && !layout.isSearching && addressResult;
        else noData
      "
    >
      <google-map
        height="250px"
        width="100%"
        class="address_map_map"
        [center]="{ lat: addressResult.lat, lng: addressResult.lng }"
        [options]="MAP_OPTIONS"
      >
        <map-marker
          #markerElem="mapMarker"
          [position]="{ lat: addressResult.lat, lng: addressResult.lng }"
          [options]="MARKER_OPTIONS"
        >
        </map-marker>
      </google-map>
    </ng-container>
    <ng-template #noData>
      <div
        *ngIf="!layout.isSearching && layout.notFoundAddress"
        class="address_map__not_found"
      >
        <img
          src="assets/icons/illu_no-search-result.svg"
          class="address_map__not_found_img"
        />
        <p class="address_map__not_found_title">
          {{ 'addressMap.ohWow' | translate }}
        </p>
        <p class="address_map__not_found_body">
          {{ 'addressMap.notFound' | translate }}
        </p>
      </div>
    </ng-template>
    <ng-container *ngIf="layout.isSearching">
      <roma-loading></roma-loading>
    </ng-container>
  </ng-container>
</section>
