import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ISequence } from '@tacliatech/types';

@Component({
  selector: 'roma-select-sequence',
  templateUrl: './select-sequence.component.html',
  styleUrls: ['./select-sequence.component.scss'],
})
export class SelectSequenceComponent implements OnInit {
  public innerWidth = 0;
  isResponsive = false;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 768) {
      this.isResponsive = true;
    } else {
      this.isResponsive = false;
    }
  }

  _sequences: ISequence[] = [];
  selectedSequence?: string;
  @Input()
  set sequences(sequences: ISequence[]) {
    this._sequences = sequences;
    if (this._sequences?.length > 0) {
      this.selectedSequence = this._sequences[0].id;
    }
  }
  get sequences() {
    return this._sequences;
  }

  constructor(
    private dialogRef: MatDialogRef<SelectSequenceComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: any
  ) {}

  ngOnInit(): void {
    this.sequences = this.data?.sequences;
    this.onResize();
  }

  submit() {
    this.dialogRef.close(this.selectedSequence);
  }

  close() {
    this.dialogRef.close();
  }
}
