/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { AmplitideNs } from './namespaces/amplitude';
import * as amplitude from '@amplitude/analytics-browser';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { environment } from '@web-frontend/environments';
import { USER_ID_KEY } from '@tacliatech/types';
import { IosPermissionService } from './services/ios-permission/ios-permission.service';
import { CheckTrialResponse, PermissionService } from './services/permissions';
import { StorageService } from './services';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  private identifyObj: amplitude.Identify;

  constructor(
    private readonly iosPermissions: IosPermissionService,
    private permissionService: PermissionService
  ) {
    this.updateIdentity();
  }

  get keyService() {
    return environment.amplitudeConfig.key;
  }

  public init(): void {
    amplitude.init(this.keyService, localStorage.getItem(USER_ID_KEY), {
      defaultTracking: false,
    });
  }

  public async trackEvent(
    eventName: string,
    eventProperties = {}
  ): Promise<void> {
    if (!this.iosPermissions.canTrackEvents()) return;

    const userID = localStorage.getItem(USER_ID_KEY);
    const commonData = this.commonData();
    const _eventProperties = eventProperties;
    const eventData = {
      ...commonData,
      ...{ planStatus: await this.getPlanStatus() },
      ..._eventProperties,
      user_id: userID,
    };
    amplitude.track({
      event_type: eventName,
      event_properties: eventData,
      groups: eventData.groups,
    });
  }

  public updateIdentity() {
    try {
      const identifyObj: amplitude.Identify = this.setObjectProperties();
      amplitude.identify(identifyObj);
    } catch (error) {
      console.error('Error updating identity', error);
    }
  }

  public logout() {
    amplitude.reset();
  }

  async getPlanStatus(): Promise<string> {
    let planStatus = 'unknown';
    try {
      const isInTrial = (await this.checkTrial()).proPlanActivated;
      planStatus = isInTrial ? 'fullpro' : 'trial';
    } catch (e) {}
    return planStatus;
  }

  public async sendEvent(data: {
    event: string | AmplitudeEvents;
    eventProperties?: any;
  }) {
    if (!this.iosPermissions.canTrackEvents()) return;
    const userID = localStorage.getItem('USER_ID');
    const commonData = this.commonData();
    const _eventProperties = data.eventProperties || {};
    const event = {
      ...commonData,
      ...{ planStatus: await this.getPlanStatus() },
      ..._eventProperties,
      user_id: userID,
    };
    amplitude.track({
      event_type: data.event,
      event_properties: event,
      groups: event.groups,
    });
    return;
  }

  /**
   * @deprecated
   * @param data {Object}
   * @returns {void}
   */
  public async sendPageEvent(data: any): Promise<void> {
    console.warn('amplitude.servive -> sendPageEvent is DEPRECATED');
    return await this.sendEvent({
      event: data.event,
      eventProperties: { page: data?.page },
    });
  }

  /**
   * @deprecated
   * @param data {Object}
   * @returns {void}
   */
  public async sendHelpEvent(
    data: { event: string | AmplitudeEvents },
    route: string
  ): Promise<void> {
    console.warn('amplitude.servive -> sendHelpEvent is DEPRECATED');
    return await this.sendEvent({
      event: data.event,
      eventProperties: { route },
    });
  }

  /**
   * @deprecated
   * @param data {Object}
   * @returns {void}
   */
  public async sendTutorialEvent(
    data: { event: string | AmplitudeEvents },
    type: string
  ): Promise<void> {
    console.warn('amplitude.servive -> sendTutorialEvent is DEPRECATED');
    return await this.sendEvent({
      event: data.event,
      eventProperties: { tutorial_type: type },
    });
  }

  /**
   * @deprecated
   * @param data {Object}
   * @returns {void}
   */
  public newUserEvent(data: any): void {
    console.warn('amplitude.servive -> newUserEvent is DEPRECATED');
    const identifyObj = this.setObjectProperties(data.user);
    const commonData = this.commonData(data.user);
    amplitude.identify(identifyObj);
    amplitude.track(data.event, commonData);
  }

  private getDateFields = (obj: any): AmplitideNs.RegisterFields => {
    const dateString: string = obj.created_at;
    const dateParsed = this.convertTZ(dateString, 'Europe/Madrid');
    const spainDate = new Date(dateParsed);
    const weekNumber = this.getWeek(spainDate, 0);

    const minutes =
      spainDate.getMinutes() <= 9
        ? '0' + spainDate.getMinutes()
        : spainDate.getMinutes();
    return {
      registerWeek: weekNumber,
      registerMonth: spainDate.getMonth() + 1,
      registerYear: spainDate.getFullYear(),
      registerDate: `${spainDate.getDate()}/${
        spainDate.getMonth() + 1
      }/${spainDate.getFullYear()} ${spainDate.getHours()}:${minutes}`,
    };
  };

  private convertTZ = (date, tzString) => {
    return new Date(new Date(date)).toLocaleString('en-ES', {
      timeZone: tzString,
    });
  };

  private getWeek = (date: Date, dowOffset: number): number => {
    dowOffset = typeof dowOffset == 'number' ? dowOffset : 0; //default dowOffset to zero
    const newYear = new Date(date.getFullYear(), 0, 1);
    let day = newYear.getDay() - dowOffset; //the day of week the year begins on
    day = day >= 0 ? day : day + 7;
    const daynum =
      Math.floor(
        (date.getTime() -
          newYear.getTime() -
          (date.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) /
          86400000
      ) + 1;
    let weeknum;
    //if the year starts before the middle of a week
    if (day < 4) {
      weeknum = Math.floor((daynum + day - 1) / 7) + 1;
      if (weeknum > 52) {
        const nYear = new Date(date.getFullYear() + 1, 0, 1);
        let nday = nYear.getDay() - dowOffset;
        nday = nday >= 0 ? nday : nday + 7;
        /*if the next year starts before the middle of
              the week, it is week #1 of that year*/
        weeknum = nday < 4 ? 1 : 53;
      }
    } else {
      weeknum = Math.floor((daynum + day - 1) / 7);
    }
    return weeknum;
  };

  private extractCompanyFields(
    company: any
  ): AmplitideNs.CompanyRegisterFields {
    const fields: AmplitideNs.RegisterFields = this.getDateFields(company);

    const companyFields: AmplitideNs.CompanyRegisterFields = new AmplitideNs.CompanyRegisterFields(
      fields.registerWeek,
      fields.registerMonth,
      fields.registerYear,
      fields.registerDate
    );
    return companyFields;
  }

  private extractUserFields(user: any): AmplitideNs.UserRegisterFields {
    const fields: AmplitideNs.RegisterFields = this.getDateFields(user);

    const userFields: AmplitideNs.UserRegisterFields = new AmplitideNs.UserRegisterFields(
      fields.registerWeek,
      fields.registerMonth,
      fields.registerYear,
      fields.registerDate
    );
    return userFields;
  }

  private getCustomerFields(customer: any, customerId) {
    return {
      customerId: customer?._id ? customer?._id : customerId,
      company_size: customer?.company_size,
      company_type: customer?.company_type,
      channel: customer?.description
        ? customer?.description
        : customer?.channel
        ? customer?.channel
        : 'No disponible',
    };
  }
  private getCompanyFields(company: any, companyID) {
    const groups = {
      company_id: company?._id || companyID,
    };
    if (company?.name) {
      groups['company'] = company?.name;
    }
    if (company?.data_account?.company_name) {
      groups['company'] = company?.data_account?.company_name;
    }
    return {
      companyId: company?._id ? company?._id : companyID,
      company_name: company?.name,
      customerId: company?.customer,
      user_plans: company?.plans,
      user_entitlements: company?.entitlements,
      user_modules: company?.modules,
      groups,
    };
  }

  private getUserFields(user: any) {
    return {
      user_id: user?._id,
      user_name: user?.name,
      user_role: user?.role,
      user_email: user?.email,
      user_phone: user?.phone,
    };
  }

  private setObjectProperties(
    user = null,
    company = null,
    customer = null
  ): amplitude.Identify {
    amplitude.init(this.keyService, localStorage.getItem('USER_ID'), {
      defaultTracking: false,
    });

    user = user || JSON.parse(localStorage.getItem('userData'));
    company = company || JSON.parse(localStorage.getItem('companyData'));
    customer = customer || JSON.parse(localStorage.getItem('customerData'));

    const identifyObj = new amplitude.Identify();

    const setCompanyFields = () => {
      if (!company) return;
      const registerCompanyDateFields = this.extractCompanyFields(company);

      identifyObj.set(
        'user_company_register_month',
        registerCompanyDateFields?.company_register_month
      );
      identifyObj.set(
        'user_company_register_week',
        registerCompanyDateFields?.company_register_week
      );
      identifyObj.set(
        'user_company_register_yearr',
        registerCompanyDateFields?.company_register_year
      );
    };

    const setUserFields = () => {
      if (!user) return;

      const userFields = this.getUserFields(user);
      const registerUserDateFields = this.extractUserFields(user);

      identifyObj.set('user_id', userFields.user_id);
      identifyObj.set('user_name', userFields.user_name);
      identifyObj.set('user_email', userFields.user_email);
      identifyObj.set('user_phone', userFields.user_phone);
      identifyObj.set(
        'user_register_date',
        registerUserDateFields.user_register_date
      );
      identifyObj.set(
        'user_register_month',
        registerUserDateFields.user_register_month
      );
      identifyObj.set(
        'user_register_week',
        registerUserDateFields.user_register_week
      );
      identifyObj.set(
        'user_register_year',
        registerUserDateFields.user_register_year
      );
    };

    const setCompanyUserFields = () => {
      if (!company || !user) return;

      const companyFields = this.getCompanyFields(company, user.company);

      identifyObj.set(
        'user_company_id',
        companyFields.companyId || user.company
      );
      identifyObj.set('user_company_name', companyFields.company_name);
      identifyObj.set('user_plans', companyFields.user_plans);
      identifyObj.set('user_modules', companyFields.user_modules);
      identifyObj.set('user_entitlements', companyFields.user_entitlements);
      identifyObj.set('location_country', company?.locationCountry);
      identifyObj.set('first_choice', company?.stChoice);
      identifyObj.set('company_sector', company?.sector);
      identifyObj.set('company_utm_campaign', company?.company_utm_campaign);
    };

    const setCustomerCompanyFields = () => {
      if (!customer || !company) return;

      const customerFields = this.getCustomerFields(customer, company.customer);

      identifyObj.set('user_customerId', customerFields.customerId);
      identifyObj.set('user_company_size', customerFields.company_size);
      identifyObj.set('user_company_type', customerFields.company_type);
      identifyObj.set('user_channel', customerFields.channel);
    };

    setCompanyFields();
    setUserFields();
    setCompanyUserFields();
    setCustomerCompanyFields();

    return identifyObj;
  }

  private commonData(user = null, company = null, customer = null) {
    //Constraints
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    if (!userStorage?._id && userStorage?.id) {
      userStorage._id = userStorage.id;
    }

    user = { ...(user ? user : {}), ...(userStorage ? userStorage : {}) };
    company = company || JSON.parse(localStorage.getItem('companyData'));
    customer = customer || JSON.parse(localStorage.getItem('customerData'));

    let userFiels;
    let registerUserDateFields;
    let companyFields;
    let customerFields;
    let registerCompanyDateFields;

    if (user) {
      userFiels = this.getUserFields(user);
      registerUserDateFields = this.extractUserFields(user);
    }
    if (company) {
      registerCompanyDateFields = this.extractCompanyFields(company);
    }

    if (company && user) {
      companyFields = this.getCompanyFields(company, user.company);
    }

    if (customer && company) {
      customerFields = this.getCustomerFields(customer, company.customer);
    }

    const data = {
      ...userFiels,
      ...companyFields,
      ...customerFields,
      ...registerUserDateFields,
      ...registerCompanyDateFields,
    };
    return data;
  }

  private async checkTrial(): Promise<CheckTrialResponse> {
    return await this.permissionService.checkTrialSubscription(
      StorageService.CompanyId,
      'taclia'
    );
  }
}
