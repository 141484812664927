<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="">
  <div class="row">
    <div class="col-3">
      <button class="roma-button h-50" (click)="gotoConnectedAccount()">
        Creacion de cuentas conectadas
      </button>
    </div>
    <div class="col-3">
      <button class="roma-button h-50" (click)="gotoPaymentCard()">
        Pagos con tarjetas
      </button>
    </div>
    <div class="col-3">
      <button class="roma-button h-50" (click)="gotoPaymentLink()">
        Link de cobro
      </button>
    </div>
    <div class="col-3">
      <button class="roma-button h-50" (click)="gotoRecurrentPaymentLink()">
        Pagos recurrentes
      </button>
    </div>
  </div>
  <div class="row col-12 mt-50">
    <div class="col-3">
      <button class="roma-button h-50">Pedidos de terminales</button>
    </div>
    <div class="col-3">
      <button class="roma-button h-50" (click)="gotoLoginLink()">
        Login link
      </button>
    </div>
    <div class="col-3">
      <button class="roma-button h-50" (click)="gotoRequestReaders()">
        Request readers
      </button>
    </div>
    <div class="col-3">
      <button class="roma-button h-50" (click)="gotoCreateLocation()">
        Locación
      </button>
    </div>
  </div>

  <div class="row col-12 mt-50">
    <div class="col-3">
      <button class="roma-button h-50" (click)="gotoRegisterReader()">
        Lector
      </button>
    </div>
    <div class="col-3">
      <button class="roma-button h-50" (click)="gotoPaymentsDashboard()">
        Dashboard
      </button>
    </div>
    <div class="col-3">
      <button class="roma-button h-50" (click)="gotoRefunds()">
        Reembolsos
      </button>
    </div>
  </div>
</div>
