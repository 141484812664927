import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@web-frontend/shared/services';

@Component({
  selector: 'roma-modal-logout',
  templateUrl: './modal-logout.component.html',
  styleUrls: ['./modal-logout.component.scss'],
})
export class ModalLogoutComponent implements OnInit {
  title = '';

  @Input()
  type: any;

  messages = new Map();

  constructor(
    private dialogRef: MatDialogRef<ModalLogoutComponent>,
    private router: Router,
    private authService: AuthService
  ) {}

  getMessage() {
    return 'general.auto-logout';
  }

  ngOnInit(): void {}

  ok() {
    this.dialogRef.close();
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
