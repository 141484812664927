import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClockAbsenceRoutingModule } from './clock-absence-routing.module';
import { ClockAbsenceComponent } from './clock-absence.component';
import { TableModule } from '@web-frontend/shared/components/table';
import { FilterModule } from '@web-frontend/shared/components/filter';
import { RmSelectModule } from '@web-frontend/shared/components/globals/rm-select';
import { LoadingModule } from '@web-frontend/shared/components/loading';
import { DeleteBySelectionModalModule } from '@web-frontend/shared/components/delete-by-selection-modal/delete-by-selection-modal.module';
import { PermissionsModule } from '@web-frontend/shared/components/permissions';
import { FormsModule } from '@angular/forms';
import { ListMobileClockAbsenceComponent } from './list-mobile-clock-absence/list-mobile-clock-absence.component';
import { MobileClockHourAdminModule } from '@web-frontend/shared/components/clock-hours';
import { RmFilterModule } from '@web-frontend/shared/components/globals/rm-filter';
import { RomaToolTipModule } from '@web-frontend/shared/directives/roma-tooltip';
import { RmSelectRegisterModule } from '@web-frontend/shared/components/globals/rm-select-register';
import { StripeSnackbarSharedModule } from '@web-frontend/shared/components/snackbar/stripe-snackbar.module';
import { TranslateModule } from '@ngx-translate/core';
import { DownloadBtnModule } from '@web-frontend/shared/components/download-btn/download-btn.module';
import { AddButtonModule } from '@web-frontend/shared/components/add-button/add-button.module';
import { MfCalendarModule } from '../../../microfrontends/mf-calendar/mf-calendar.module';
import { AbsenceCounterComponent } from './absence-counter/absence-counter.component';
import { AbsenceSummaryComponent } from './absence-summary/absence-summary.component';
import { AbsenceSummaryCounterComponent } from './absence-summary-counter/absence-summary-counter.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    ClockAbsenceComponent,
    ListMobileClockAbsenceComponent,
    AbsenceCounterComponent,
    AbsenceSummaryComponent,
    AbsenceSummaryCounterComponent,
  ],
  imports: [
    CommonModule,
    ClockAbsenceRoutingModule,
    TableModule,
    TranslateModule.forChild(),
    RmSelectModule,
    RmSelectRegisterModule,
    FilterModule,
    LoadingModule,
    DeleteBySelectionModalModule,
    PermissionsModule,
    FormsModule,
    MobileClockHourAdminModule,
    RmFilterModule,
    RomaToolTipModule,
    StripeSnackbarSharedModule,
    DownloadBtnModule,
    AddButtonModule,
    MfCalendarModule,
    MatIconModule,
  ],
  exports: [ClockAbsenceComponent, ListMobileClockAbsenceComponent],
})
export class ClockAbsenceModule {}
