import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'dateDayMonthConvert',
})
export class DateDayMonthPipe implements PipeTransform {
  constructor(private i18n: TranslateService) {}

  transform(value: Date): any {
    return this.getDate(value);
  }

  private getDate(date: Date = new Date()) {
    moment.locale(this.i18n.currentLang);

    if (!date) {
      date = new Date();
    }

    const dateFormatted = moment(date).format('DD/MM/YY').replace('.', '');

    return dateFormatted;
  }
}
