import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Company,
  Deal,
  DynamicPropertyRef,
  Feature,
  FeatureUser,
  IFileNote,
  MembershipSchema,
  PartOfServiceGlobal,
} from '@tacliatech/types';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import {
  DynamicPropertyService,
  SandboxService,
  StorageService,
} from '@web-frontend/shared/services';
import { PartOfServiceProvider } from '@web-frontend/shared/services/part-of-services';
import { PreviewPartOfServiceReportProvider } from './preview-part-of-service-report';
import { filter, finalize, map, tap } from 'rxjs/operators';
import { SendEmailPartOfServiceProvider } from '../../deals/deal-part-of-service/send-email-part-of-service';
import { Subscription } from 'rxjs';
import { SelectItem } from './select-item/select-item.types';
import { DealPartOfService } from './deal-part-of-service.types';
import { environment } from '@web-frontend/environments';
import { CompanyModuleService } from '@web-frontend/shared/services/company';
import { CreateSignatureService } from '../../signature-modal';
import { Signature } from '../../signature';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ImageSelectorComponent, fileData } from '../../image-selector-v2';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { CreateDealComponent } from '../create-deal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import AmplitudeEvents from 'src/types/amplitude.enum';

@Component({
  selector: 'roma-wrapper-part-of-service',
  templateUrl: './wrapper-part-of-service.component.html',
  styleUrls: [
    './wrapper-part-of-service.component.scss',
    '../wrapper-detail/wrapper-detail.component.scss',
  ],
})
export class WrapperPartOfServiceComponent implements OnInit {
  @ViewChild(ImageSelectorComponent)
  imageSelector: ImageSelectorComponent;

  @Input()
  set dealData(value: Deal.Output) {
    this._dealData = value;
  }

  get dealData() {
    return this._dealData;
  }

  _dealData: Deal.Output;

  @Input()
  finalLoading: boolean;

  @Output()
  updateFormStatus = new EventEmitter<boolean>();

  private sub$ = new Subscription();
  resize$ = this.sandBoxService.screenBusChannel$;

  idActiveModules$ = this.companyModuleService.idActiveModules$;
  idModuleRef = Company.IdModule;
  imagesParsed: fileData[] = [];
  private imagesParsedCopy: fileData[] = [];

  elements: SelectItem.Items = [];
  elementsCustomProperties: SelectItem.Items = [];
  elementsCustomGroupProperties: SelectItem.Items = [];
  partOfService!: PartOfServiceGlobal.PartOfServiceResponse;
  downloadingPreview = false;
  downloading = false;
  featureRef = Feature;
  featureRefUser = FeatureUser;
  showDescription = false;
  uploadClientSignature = false;
  uploadCompanySignature = false;
  isLoading = false;
  isSubmitLoading = false;
  elementsCustomGroupPropertiesLoading = false;
  elementsCustomPropertiesLoading = false;
  editNotes = false;
  private isLoadedPartOfService = false;
  private refreshModalOnClose = false;

  form: FormGroup = this.fb.group({
    description: new FormControl(),
    files: new FormControl([]),
    fields: new FormControl({}),
    signCompany: new FormControl(),
    signClient: new FormControl(),
    fieldsCustomProperties: new FormControl(),
    fieldCustomGroupProperties: new FormControl(),
    date: new FormControl(),
    notes: new FormControl(),
  });

  private GET_INITIAL_FORM = () => {
    return StorageService.GetItem('INITIAL_FORM_PART_OF_DEAL');
  };
  initialForm: any = {};

  hasFeatureHandleDealCustomProperty = false;

  constructor(
    private partOfServiceProvider: PartOfServiceProvider,
    private previewPartOfServiceReportProvider: PreviewPartOfServiceReportProvider,
    private sendEmailParOfServiceProvider: SendEmailPartOfServiceProvider,
    private dynamicPropertyService: DynamicPropertyService,
    private amplitudeService: AmplitudeService,
    public i18n: TranslateService,
    private alertService: AlertService,
    private cdRef: ChangeDetectorRef,
    private sandBoxService: SandboxService,
    private companyModuleService: CompanyModuleService,
    private createSignature: CreateSignatureService,
    private fb: FormBuilder,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CreateDealComponent>,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.watchPermissions();
    this.requestPartOfServices();
  }

  private watchPermissions() {
    this.sub$.add(
      this.permissionService
        .hasFeatureFn(Feature.Deal.HandleCustomProperties)
        .subscribe((hasFeatureHandleDealCustomProperty) => {
          this.hasFeatureHandleDealCustomProperty = hasFeatureHandleDealCustomProperty;
        })
    );
  }

  public haveChanges() {
    if (this.isLoading && Object.keys(this.initialForm).length === 0) {
      return false;
    }
    for (let i = 0; i < Object.keys(this.form.value)?.length; i++) {
      if (
        this.form.value[Object.keys(this.form.value)[i]] !=
        this.initialForm[Object.keys(this.form.value)[i]]
      ) {
        console.log('falla en', Object.keys(this.form.value)[i]);
        return true;
      }
    }
    if (this.imagesParsedCopy.length) {
      return true;
    }
    return false;
  }

  async download() {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_card_proof_download,
    });

    await this.editSubmit({ close: false });
    this.downloading = true;
    this.partOfServiceProvider
      .download({
        id: this.partOfService.idDeal,
        lang: this.i18n.currentLang,
        idCompany: StorageService.CompanyId,
      })
      .pipe(
        finalize(() => {
          this.downloading = false;
          this.draw();
        })
      )
      .subscribe();
  }

  async showPreview() {
    await this.editSubmit({ close: false });

    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_card_proof_preview,
    });
    this.previewPartOfServiceReportProvider
      .open({
        data: {
          idDeal: this.partOfService.idDeal,
          fields: this.partOfService.fields,
        },
      })
      .subscribe();
  }

  sendEmail() {
    if (!this.finalLoading && this.partOfService) {
      const clientEmail = [];
      if (this.dealData?.final?.email)
        clientEmail.push(this.dealData?.final?.email);

      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_card_proof_emailStart,
      });

      this.sendEmailParOfServiceProvider
        .open({
          data: {
            idDeal: this.partOfService.idDeal,
            emailsPreLoading: clientEmail,
          },
        })
        .subscribe();
    } else {
      this.alertService.success(this.i18n.instant('deals.noUpdateClient'));
    }
  }

  requestPartOfServices() {
    this.isLoading = true;
    this.sub$.add(
      this.partOfServiceProvider
        .findByDeal(this.dealData._id)
        .pipe(
          tap((partRes) => {
            this.elementsCustomPropertiesLoading = true;
            this.dynamicPropertyService
              .findDynamicProperties(
                StorageService.CompanyId,
                DynamicPropertyRef.Deal
              )
              .subscribe((res) => {
                this.elementsCustomProperties = DealPartOfService.GetCustomPropertiesElements(
                  partRes,
                  res
                );
                this.elementsCustomPropertiesLoading = false;
                this.draw();
              });
          }),
          tap((partRes) => {
            if (this.dealData?.idGroup) {
              this.elementsCustomGroupPropertiesLoading = true;
              this.dynamicPropertyService
                .findByGroup(this.dealData.idGroup)
                .subscribe((res) => {
                  this.elementsCustomGroupProperties = DealPartOfService.GetCustomGroupPropertiesElements(
                    partRes,
                    res
                  );
                  this.elementsCustomGroupPropertiesLoading = false;
                  this.draw();
                });
            }
          })
        )
        .subscribe((res) => {
          this.partOfService = res;
          this.elements = DealPartOfService.GetElements(res);

          this.resolveImageParsed();

          if (this.partOfService?.description) {
            this.showDescription = true;
            this.form.patchValue({
              description: this.partOfService?.description,
            });
          }

          if (this.partOfService?.signCompany) {
            this.form.patchValue({
              signCompany: this.partOfService?.signCompany,
            });
          }

          if (this.partOfService?.signClient) {
            this.form.patchValue({
              signClient: this.partOfService?.signClient,
            });
          }

          if (this.partOfService?.fields) {
            this.form.patchValue({
              fields: this.partOfService?.fields,
            });
          }

          if (this.partOfService?.fieldsCustomProperties) {
            this.form.patchValue({
              fieldsCustomProperties: this.partOfService
                ?.fieldsCustomProperties,
            });
          }

          if (this.partOfService?.fieldCustomGroupProperties) {
            this.form.patchValue({
              fieldCustomGroupProperties: this.partOfService
                ?.fieldCustomGroupProperties,
            });
          }

          if (this.partOfService?.notes?.length > 0) {
            this.editNotes = true;
            this.form.patchValue({
              notes: this.partOfService?.notes,
            });
          }
          this.isLoading = false;
          this.isLoadedPartOfService = true;
          this.initialForm = { ...this.form.value };
          this.draw();
          this.form.valueChanges.subscribe((x) => {
            this.haveChanges();
          });
        })
    );
  }

  changeFileArray(evt: fileData[]) {
    evt.forEach((item) => {
      if (item?.markForDelete) {
        this.imagesParsed = this.imagesParsed.filter((image) => {
          return image.fileName !== item.fileName;
        });
      }
    });

    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_card_proof_file,
    });

    this.imagesParsedCopy = evt;
    this.draw();
  }

  changeShowDescription() {
    if (
      this.form.value.description &&
      this.form.value.description.length >= 1
    ) {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_card_proof_description,
      });
    }
    this.draw();
  }

  /**
   * This function is used in create-deal,
   * it is important that all the transactions that are made here
   * are carried out Synchronously
   */
  public async editSubmit(options: { close: boolean } = { close: true }) {
    // console.log(this._dealData);

    if (!this.dealData.name) {
      this.toastService.show({
        text: this.i18n.instant('deals.errorTitle'),
        type: 'error',
        msDuration: 4000,
        class: '',
      });

      return null;
    }
    this.isSubmitLoading = true;
    this.draw();

    const images = (await this.imageSelector.uploadFiles2()).map(
      (fileObj) => fileObj.fileFirebaseUrl
    );

    this.form.patchValue({
      files: images,
    });

    try {
      await this.partOfServiceProvider
        .findOneAndUpdate(this.partOfService._id, this.form.value)
        .toPromise();

      this.toastService.show({
        text: 'general.updatePropertySuccess',
        type: 'success',
        class: '',
      });
      this.isSubmitLoading = false;
      if (options.close) {
        this.close();
      }
      this.draw();
    } catch (err) {}
  }

  descriptionChange() {
    this.partOfServiceProvider
      .findOneAndUpdate(this.partOfService._id, {
        description: this.form.value.description,
      })
      .subscribe();
  }

  changeSelectedItems(evt: SelectItem.ItemsChanged) {
    this.amplitudeService.sendEvent({
      event:
        'deal_card_proof_show_' +
        evt?.changed?.refField +
        (evt?.changed?.active ? 'ON' : 'OFF'),
    });
    const fields = DealPartOfService.GetObject(evt.items);
    this.form.patchValue({
      fields: fields,
    });

    // this.partOfServiceProvider
    // .findOneAndUpdate(this.partOfService._id, { fields })
    // .subscribe();
  }

  changeSelectedCustomPropertyItems(evt: SelectItem.ItemsChanged) {
    const fieldsCustomProperties: { [key: string]: boolean } = {};
    const event: string = evt.changed.active
      ? AmplitudeEvents.deal_card_proof_show_customON
      : AmplitudeEvents.deal_card_proof_show_customOFF;
    this.amplitudeService.sendEvent({
      event: event,
    });
    for (const item of evt.items) {
      Object.assign(fieldsCustomProperties, { [item.refField]: item.active });
    }

    this.form.patchValue({
      fieldsCustomProperties: fieldsCustomProperties,
    });

    // this.partOfServiceProvider
    //   .findOneAndUpdate(this.partOfService._id, { fieldsCustomProperties })
    //   .subscribe();
  }

  changeSelectedCustomGroupPropertyItems(evt: SelectItem.ItemsChanged) {
    const fieldCustomGroupProperties: { [key: string]: boolean } = {};
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_card_proof_show_customGroup,
    });
    for (const item of evt.items) {
      Object.assign(fieldCustomGroupProperties, {
        [item.refField]: item.active,
      });
    }

    this.form.patchValue({
      fieldCustomGroupProperties: fieldCustomGroupProperties,
    });

    // this.partOfServiceProvider
    //   .findOneAndUpdate(this.partOfService._id, { fieldCustomGroupProperties })
    //   .subscribe();
  }

  determineCopy(copy: string): string {
    if (environment.MembershipSchema === MembershipSchema.OneProPlan) {
      return `${copy}-new`;
    } else {
      return copy;
    }
  }

  private resolveImageParsed() {
    this.imagesParsed = this.partOfService?.files?.map((file) => {
      return {
        extension: file
          .substring(0, file.indexOf('?'))
          .slice(file.indexOf('.'))
          .replaceAll('.', ''),
        fileFirebaseUrl: file,
        fileName: file.substring(0, file.indexOf('.')),
      };
    });

    this.form.patchValue({
      files: this.imagesParsed,
    });

    this.draw();
  }

  changeRomaEditableField(field: string): void {
    switch (field) {
      case 'note':
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_proof_internalNotes,
        });
        break;
      case 'description':
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_proof_description,
        });
        break;
      case 'date':
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_proof_date,
        });
        break;
    }
  }

  private resolveAmplitudeInChangeSignature(
    evt: Signature.SignatureChange,
    type: 'COMPANY' | 'CLIENT'
  ) {
    const getEventName = () => {
      if (type === 'COMPANY') {
        const event = evt?.data
          ? AmplitudeEvents.deal_card_proof_companySign_create
          : AmplitudeEvents.deal_card_proof_companySign_delete;

        return event;
      }

      if (type === 'CLIENT') {
        const event = evt?.data
          ? AmplitudeEvents.deal_card_proof_finalSign_create
          : AmplitudeEvents.deal_card_proof_finalSign_delete;

        return event;
      }
      return '';
    };

    const event = getEventName();

    if (event) {
      this.amplitudeService.sendEvent({
        event,
      });
    }
  }

  changeSignature(evt: Signature.SignatureChange, type: 'COMPANY' | 'CLIENT') {
    const key: keyof PartOfServiceGlobal.PartOfServiceSchema =
      type === 'COMPANY' ? 'signCompany' : 'signClient';

    const updateSignatureState = (value: boolean) => {
      if (type == 'COMPANY') {
        this.uploadCompanySignature = value;
      }

      if (type === 'CLIENT') {
        this.uploadClientSignature = value;
      }
      this.draw();
    };

    this.resolveAmplitudeInChangeSignature(evt, type);
    updateSignatureState(true);

    if (!evt.data) {
      this.form.patchValue({
        [key]: '',
      });

      updateSignatureState(false);

      return;
    }

    this.createSignature
      .open({
        data: {
          source: this.partOfService[key],
          type,
          previewMode: !evt?.featureOn,
        },
      })
      .pipe(
        filter((res) => !!res.source),
        map((res) => {
          this.form.patchValue({
            [key]: res.source,
          });
        }),
        finalize(() => {
          updateSignatureState(false);
        })
      )
      .subscribe(() => {
        updateSignatureState(false);
      });
  }

  changeDateField(date: Date) {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_card_proof_signDate,
    });
    this.form.patchValue({ date });
  }

  addingNotes(evt: IFileNote[]) {
    const notes = evt;
    let isEditing = false;
    let isAddingFile = false;
    let event = '';
    if (notes) {
      if (evt.length < this.form.value.notes?.length) {
        event = AmplitudeEvents.deal_card_proof_deleteInternalNotes;
      } else {
        notes.map((note, index) => {
          if (this.form.value.notes && this.form.value.notes[index]) {
            if (note.text !== this.form.value.notes[index].text) {
              isEditing = true;
            }
          }
          if (note.file.length > 0) {
            if (this.form.value.notes && this.form.value.notes[index]) {
              if (
                note.file.length !== this.form.value.notes[index].file.length
              ) {
                isAddingFile = true;
              }
            } else {
              isAddingFile = true;
            }
          }
        });
        event = isEditing
          ? AmplitudeEvents.deal_card_proof_editInternalNotes
          : AmplitudeEvents.deal_card_proof_addInternalNotes;
        if (isAddingFile) {
          this.amplitudeService.sendEvent({
            event: AmplitudeEvents.deal_card_proof_fileInternalNotes,
          });
        }
      }
    }

    this.amplitudeService.sendEvent({
      event: event,
    });

    this.form.patchValue({
      notes,
    });

    this.draw();
  }

  showAlert(message: string) {
    this.alertService.error(this.i18n.instant(`errors.${message}`));
  }

  close() {
    const refresh = this.refreshModalOnClose ? true : null;
    this.dialogRef.close(refresh);
  }

  private draw() {
    this.cdRef.detectChanges();
  }
}
