<roma-modal-template
  [title]="'table.colum.attention' | translate"
  [showSuccessBtn]="false"
  [showCancelBtn]="false"
  (cancel)="ok()"
>
  <div class="container-item">
    <div class="grid">
      <div class="row">
      <strong>{{getMessage()| translate}}</strong>     
      </div>
    </div>
  </div>
</roma-modal-template>
