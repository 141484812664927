<h1>Registrar lector</h1>

<div>
  <form
    *ngIf="!reader; else createdReader"
    [formGroup]="readerForm"
    (ngSubmit)="onSubmit()"
    class="form-group mx-auto"
    style="max-width: 35%"
  >
    <label for="registrationCode">Código de registro: </label>
    <input
      id="registrationCode"
      type="text"
      formControlName="registrationCode"
      class="form-control"
      placeholder="simulated-wpe"
    />

    <label for="locationId"
      >ID de ubicación (locación previamente creada):
    </label>
    <input
      id="locationId"
      type="text"
      formControlName="locationId"
      class="form-control"
      placeholder="tml_FL9CtQ4EluvUVL"
    />

    <label for="label" class="mt-3">Label: </label>
    <input
      id="label"
      type="text"
      formControlName="label"
      class="form-control"
      placeholder="Mi primer lector"
    />

    <div class="d-flex justify-content-center">
      <button
        *ngIf="!isLoading; else spinner"
        type="submit"
        class="roma-button h-50 mt-3 mx-3"
        [disabled]="isLoading || readerForm.invalid"
        [ngClass]="{
          'disable-button': isLoading || readerForm.invalid
        }"
      >
        Crear locación
      </button>

      <ng-template #spinner>
        <mat-progress-spinner
          *ngIf="isLoading"
          class="loading-spinner mt-3"
          mode="indeterminate"
          [diameter]="20"
        ></mat-progress-spinner>
      </ng-template>
    </div>
  </form>

  <ng-template #createdReader>
    <div>
      <p>Se ha registrado el lector:</p>
      <pre>
        {{ reader | json }}
      </pre>
    </div>
  </ng-template>
</div>

<hr />

<h2>Lectores registrados</h2>
<pre *ngFor="let r of readers">
  {{ r | json }}
  -----------
</pre>

<hr />
<div class="space"></div>
<div class="row col-3">
  <button class="roma-button h-50" (click)="back()">Volver</button>
</div>
