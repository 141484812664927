import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Activity, Deal, IUser } from '@tacliatech/types';
import { environment } from '@web-frontend/environments';
import { StorageService, UserService } from '@web-frontend/shared/services';
import { Subscription } from 'rxjs';

interface idItem {
  _id: string;
}

@Component({
  selector: 'roma-list-mobile-deals',
  templateUrl: './list-mobile-deals.component.html',
  styleUrls: ['./list-mobile-deals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListMobileDealsComponent implements OnInit {
  _data: Deal.Output[];
  @ViewChildren(MatCheckbox)
  childrenMatCheckbox: QueryList<MatCheckbox>;

  @Input()
  set data(data: Deal.Output[]) {
    this._data = data;
    //console.log({ data });
    this.ids = [];
  }

  get data() {
    return this._data;
  }

  ids: idItem[] = [];

  @Output()
  actions = new EventEmitter<idItem[]>();

  @Output()
  onClickRow = new EventEmitter<Activity.Output>();
  showOwnersModal = false;

  allUsers: IUser[];

  private sub$ = new Subscription();

  constructor(
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.requestUsers();
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  private requestUsers() {
    this.sub$.add(
      this.userService
        .findAllByCompany(StorageService.CompanyId)
        .subscribe((res) => {
          this.allUsers = res;
          this.draw();
        })
    );
  }

  onCheckboxChange(id: string) {
    if (!this.isChecked({ _id: id })) {
      this.ids.push({ _id: id });
    } else {
      const index = this.ids.findIndex((item) => item._id == id);
      this.ids.splice(index, 1);
    }
    this.actions.next(this.ids);
  }

  eventClickRow(item: Activity.Output) {
    this.onClickRow.emit(item);
  }

  isChecked(item) {
    return this.ids.map((it) => it._id).includes(item._id);
  }

  public clearChecks() {
    for (const checkbox of this.childrenMatCheckbox) {
      checkbox.checked = false;
    }

    this.ids = [];
    this.actions.next([]);
  }

  mapArray(items, field) {
    return items?.map((it) => it[field]);
  }

  isArray(value: any) {
    return Array.isArray(value);
  }

  openDealOwnersDialog(owners) {
    this.dialog.open(DealOwnersDialog, {
      data: owners,
    });
  }

  getUserImgByName(
    name?: string,
    defaultIcon = '/assets/icons/gl_contacto_fill.svg'
  ) {
    try {
      let img;
      if (this.allUsers?.length > 0) {
        img = this.allUsers?.find((it) => it.name == name)?.img;
      }
      if (!img) {
        return defaultIcon;
      }
      return `${environment.firebaseConfig.storageUrl}/uploads%2Fuser%2F${img}`;
    } catch (err) {
      return '';
    }
  }
}

export interface DealOwnersDialogData {
  img?: string;
  name: string;
}

@Component({
  selector: 'deal-owners-dialog',
  templateUrl: 'deal-owners-dialog-dialog.html',
  styleUrls: ['./deal-owners-dialog-dialog.scss'],
})
export class DealOwnersDialog {
  get firebaseURL() {
    return environment.firebaseConfig.storageUrl + '/uploads%2Fuser%2F';
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: DealOwnersDialogData) {}
}
