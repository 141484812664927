import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Period,
  PeriodOption,
  PeriodMaxHours,
} from '@web-frontend/shared/interfaces/period';

@Component({
  selector: 'roma-hour-select',
  templateUrl: './hour-select.component.html',
  styleUrls: ['./hour-select.component.scss'],
})
export class HourSelectComponent implements OnInit {
  @Output() workingHoursChange = new EventEmitter<number>();
  @Output() workingHoursPeriodChange = new EventEmitter<Period>();
  @Output() validationError = new EventEmitter<boolean | null>();
  @Output() updateErrorHoursMin = new EventEmitter<boolean>();
  @Input() hasExternalError = false;
  @Input() errorHoursMin = false;
  @Input() defaultValueHours?: number = 0;
  @Input() defaultValuePeriod?: Period = Period.Week;
  @Input() maxDecimals?: number = 0;
  @Input() maxLength?: number = 3;

  selectedTime: number;
  selectedPeriod: Period = Period.Week;
  periods: PeriodOption[] = [
    { value: Period.Day, label: 'person.create.form.day' },
    { value: Period.Week, label: 'person.create.form.week' },
    { value: Period.Month, label: 'person.create.form.month' },
  ];
  periodMaxHours: PeriodMaxHours[] = [
    { period: Period.Day, maxHours: 24 },
    { period: Period.Week, maxHours: 168 },
    { period: Period.Month, maxHours: 672 },
  ];
  showPeriodList = false;
  inputClass = '';
  selectedPeriodLabel = 'person.create.form.week';
  selectedNumber = '';
  innerWidth = 0;
  isResponsive = false;
  maxHours = 24;
  showError = false;
  errorMessage = '';

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.isResponsive = this.innerWidth < 768;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.isResponsive = this.innerWidth < 768;
    this.checkDefaultValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultValueHours || changes.defaultValuePeriod) {
      this.checkDefaultValues();
    }
  }

  checkDefaultValues(): void {
    if (this.defaultValueHours) {
      this.selectedTime = this.defaultValueHours;
      this.selectedNumber = this.selectedTime.toString();
    }
    if (this.defaultValuePeriod) {
      this.selectedPeriod = this.defaultValuePeriod;
      this.selectedPeriodLabel = this.periods.find(
        (p) => p.value === this.selectedPeriod
      ).label;
      this.updateMaxHours(this.selectedPeriod);
    }
    this.draw();
  }

  periodListToggle(): void {
    this.showPeriodList = !this.showPeriodList;
    this.draw();
  }

  omitSpecialChar(event: KeyboardEvent): boolean {
    const regex = /[a-zA-Z0-9\s\b+]/;
    return regex.test(String.fromCharCode(event.charCode));
  }

  periodChangesEvent(): void {
    this.showPeriodList = false;
    this.draw();
  }

  validateMaxHours(event: Event): void {
    const defaultValue = (event.target as HTMLInputElement).value;
    const value = parseFloat(defaultValue.replace(',', '.'));

    if (value > this.maxHours) {
      this.showError = true;
      this.errorMessage = 'person.create.form.minHourError';
      this.updateErrorHoursMin.emit(true);
    } else {
      this.updateErrorHoursMin.emit(false);
      this.showError = false;
      this.errorMessage = null;
    }
    if (isNaN(value)) {
      this.workingHoursChange.emit(0);
    } else {
      this.workingHoursChange.emit(parseFloat(defaultValue));
    }
    this.validationError.emit(this.showError);
  }

  onHourChange(value: string): void {
    const numValue = parseFloat(value.replace(',', '.'));
    if (numValue > this.maxHours) {
      this.showError = true;
      this.errorMessage = 'person.create.form.minHourError';
      this.updateErrorHoursMin.emit(true);
    } else {
      this.updateErrorHoursMin.emit(false);
      this.showError = false;
      this.errorMessage = null;
    }
    if (isNaN(numValue)) {
      this.selectedNumber = '0';
      this.workingHoursChange.emit(0);
    } else {
      this.selectedNumber = value;
      this.workingHoursChange.emit(numValue);
    }
    this.validationError.emit(this.showError);
    this.draw();
  }

  selectPeriod(period: PeriodOption): void {
    this.selectedPeriod = period.value;
    this.selectedPeriodLabel = period.label;
    this.updateMaxHours(period.value);
    this.workingHoursPeriodChange.emit(this.selectedPeriod);
  }

  private updateMaxHours(period: Period): void {
    const periodInfo = this.periodMaxHours.find((p) => p.period === period);
    if (periodInfo) {
      this.maxHours = periodInfo.maxHours;
    }
    this.adjustSelectedNumber();
    this.draw();
  }

  private adjustSelectedNumber(): void {
    const value = parseFloat(this.selectedNumber);
    if (value > this.maxHours) {
      this.showError = true;
      this.errorMessage = 'person.create.form.minHourError';
      this.updateErrorHoursMin.emit(true);
    } else {
      this.updateErrorHoursMin.emit(false);
      this.showError = false;
      this.errorMessage = null;
    }
    this.validationError.emit(this.showError);
  }

  get translatedPeriodLabel(): string {
    return this.translate.instant(this.selectedPeriodLabel);
  }

  private draw(): void {
    this.cd.detectChanges();
  }
}
