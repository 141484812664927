import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'select-long-data-filtered',
  templateUrl: './select-long-data-filtered.component.html',
  styleUrls: ['./select-long-data-filtered.component.scss']
})
export class SelectLongDataFilteredComponent implements OnInit {

  constructor(private changeDetectionRef: ChangeDetectorRef) { }

  @Input()
  multiple:boolean = false;

  @Input()
  loading:boolean = false;

  @Input()
  placeHolder:string;

  @Input()
  notFoundText:string;

  @Input()
  maxDropDownItems:number = 100;

  _items:any;
  @Input()
  set items(items:any){
    this._items = items;
    this.getFilteredItems();
  };

  get items():any {
    return this._items;
  }

  @Input()
  showWarning:boolean = false;

  @Input()
  warningText:string;

  @Input()
  labelText:string;

  @Input()
  itemValue:string;

  @Input()
  itemFilter:string;

  @Input()
  showInfoTooltip:boolean = false;

  @Input()
  tooltipMessages: any[];

  _selectedItems:any;
  @Input()
  set selectedItems(selectedItems:any){
    this._selectedItems = selectedItems;
    this.getFilteredItems();
  };

  get selectedItems():any {
    return this._selectedItems;
  }

  @Output()
  onSelectedItemsChange = new EventEmitter<any>();
  filteredItemsSelect:any[] = [];

  @ViewChild('selectRef') selectRef!: NgSelectComponent;

  ngOnInit(): void {

  }

  getFilteredItems() {
    if (!this.items){
      return;
    }
    this.filteredItemsSelect = this.items?.filter((item) => {
      return item[this.itemFilter]?.toLowerCase().includes(this.selectRef.searchTerm?.toLowerCase() || '');
    });
    this.filteredItemsSelect = this.filteredItemsSelect?.splice(0, this.maxDropDownItems);
    if (!this.filteredItemsSelect){
      this.filteredItemsSelect = [];
    }
    const selected = this.items?.find((item) => { item[this.itemValue] === this.selectedItems })
    if (selected){
      this.filteredItemsSelect.unshift(selected);
    }
    this.draw();
  }

  getValue(item:any) {
    if (item && this.itemValue) {
      return item[this.itemValue];
    }
    return item;
  }

  getFiltered(item:any){
    if (item && this.itemFilter){
      return item[this.itemFilter];
    }
    return item;
  }

  onKeyPress(event: KeyboardEvent) {
    this.getFilteredItems();
  }

  onChange(event: any) {
    this.onSelectedItemsChange.emit(this.selectedItems);
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

}
