import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { FieldErrorDisplayComponent } from './field-error-display.component';

@NgModule({
  declarations: [FieldErrorDisplayComponent],
  imports: [CommonModule, TranslateModule.forChild()],
  exports: [FieldErrorDisplayComponent],
})
export class FieldErrorDisplayModule {}
