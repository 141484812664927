import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Feature, IBudgetsSetting, IUser, PlanType } from '@tacliatech/types';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { ChargebeeService } from '../chargebee.service';
import { PlatformService } from '../../platform-disable-content/platform.service';

interface planResponse {
  id: string;
  name: string;
  external_name: string;
  status: string;
  planTypes?: PlanType[];
}
enum Currencies {
  USD = 'USD',
  EUR = 'EUR',
}

enum CurrenciesSymbols {
  USD = '$',
  EUR = '€',
}

@Component({
  selector: 'roma-buy-modal',
  templateUrl: './buy-modal.component.html',
  styleUrls: ['./buy-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuyModalComponent implements OnInit {
  @Input()
  feature: Feature.Purchasable;

  isResponsive = window.innerWidth <= 768;
  height = window.innerHeight;
  public innerWidth = 0;
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.innerWidth = window.innerWidth;
    this.isResponsive = this.innerWidth <= 768;
  }

  get mobileHeight(): string {
    return window.innerHeight < 820 ? 'mobile-70h' : 'mobile-50h';
  }

  isLoading = true;
  user: IUser;
  planResponse?: planResponse;
  actualPlanType: PlanType = null;
  companySettings: IBudgetsSetting;
  currencies: any;
  moneySymbol: string;
  showTrialBtn = true;

  constructor(
    private cdRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<BuyModalComponent>,
    private permissionService: PermissionService,
    private readonly analyticsService: AnalyticsService,
    private readonly chargebee: ChargebeeService,
    private readonly platformService: PlatformService
  ) {}

  ngOnInit(): void {
    this.getItemPrices();
    this.draw();
  }

  get isIos(): boolean {
    return this.platformService.isIos();
  }

  close(): void {
    this.dialogRef.close();
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  async getItemPrices(): Promise<void> {
    this.isLoading = true;
    const response = await this.permissionService.getItemPrices();

    if (response.planTypes.length === 0) return;

    this.planResponse = response;
    const actual = this.planResponse.planTypes.filter(
      (fil) => fil.default == true
    );

    if (actual.length > 0) {
      this.actualPlanType = actual[0];
    } else {
      this.actualPlanType =
        this.planResponse.planTypes.length > 0
          ? this.planResponse.planTypes[0]
          : null;
    }

    this.moneySymbol =
      this.actualPlanType?.currency_code?.toLowerCase() ===
      Currencies.EUR.toLowerCase()
        ? CurrenciesSymbols.EUR
        : CurrenciesSymbols.USD;

    this.isLoading = false;
    this.draw();
  }

  changePlan(plan: PlanType): void {
    if (plan.period_unit === 'year') {
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: 'planImprove_plans_yearly',
      });
    } else {
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: 'planImprove_plans_monthly',
      });
    }
  }

  splitThousands(num: number, useComma = true): string {
    const parts = num.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, useComma ? ',' : '.');
    return parts.join(useComma ? '.' : ',');
  }

  twoDecimals(number: number): string {
    let text: string = number?.toFixed(2);
    text = text?.replace('.00', '');

    if (this.moneySymbol == CurrenciesSymbols.EUR) {
      return this.splitThousands(parseFloat(text), false);
    }

    if (this.moneySymbol == CurrenciesSymbols.USD) {
      return this.splitThousands(parseFloat(text), true);
    }

    return text;
  }

  buy(): void {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: 'planImprove_plans_becomePro',
    });
    this.isLoading = true;

    this.chargebee
      .openCheckout(this.user?.email, this.actualPlanType.id)
      .then((res) => {
        if (!res?.success) return;
        const PurchasableList = Feature.getPurchasableListByFeature(
          this.feature
        ).map((el) => {
          return {
            feature: el,
          };
        });

        this.permissionService.setPermissions(PurchasableList);
      })
      .finally(() => {
        this.isLoading = false;
        this.draw();
      });
  }
}
