import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FinalService, SandboxService } from '@web-frontend/shared/services';
import { CreateFinal } from './create-final.types';
import { finalize } from 'rxjs/operators';
import { Company, FeatureUser, IFinal } from '@tacliatech/types';
import { WrapperDetailComponent } from './wrapper-detail-component/wrapper-detail-component';
import { LaunchModalYesOrNoService } from '@web-frontend/shared/directives/click-delete/modal-yes-or-no/launch-modal-yes-or-no.service';
import { CompanyModuleService } from '@web-frontend/shared/services/company';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { DeleteBySelectionModalComponent } from '../delete-by-selection-modal/delete-by-selection-modal.component';
import { TranslateService } from '@ngx-translate/core';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
@Component({
  selector: 'roma-create-final-v2',
  templateUrl: './create-final-v2.component.html',
  styleUrls: ['./create-final-v2.component.scss'],
})
export class CreateFinalV2Component implements OnInit {
  @ViewChild(WrapperDetailComponent, { static: false })
  detailComponent: WrapperDetailComponent;

  type: 'ADD' | 'EDIT' = 'ADD';
  activeTab: CreateFinal.TabType = 'DETAIL';
  resize$ = this.sandBoxService.screenBusChannel$;
  loading = false;
  private middlewareExecute = false;
  customer!: IFinal;
  idModuleRef = Company.IdModule;
  idActiveModules$ = this.companyModuleService.idActiveModules$;
  featureRefUser = FeatureUser;
  haveChanges = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: CreateFinal.Params,
    private sandBoxService: SandboxService,
    private dialogRef: MatDialogRef<CreateFinalV2Component>,
    private launchModalYesOrNoService: LaunchModalYesOrNoService,
    private finalService: FinalService,
    private cdRef: ChangeDetectorRef,
    private companyModuleService: CompanyModuleService,
    private clipboard: Clipboard,
    private toastService: ToastService,
    private dialog: MatDialog,
    private i18n: TranslateService,
    private analyticsService: AnalyticsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    if (this.data?.mode === 'EDIT') {
      this.type = this.data.mode;
      await this.requestCustomer();
    }
    this.dialogRef.disableClose = true;
    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onBackdropClick());
  }

  public async onBackdropClick(result?: string): Promise<void> {
    const component = this.detailComponent;

    const hasChanges = component.haveChanges();

    if (hasChanges) {
      const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
        panelClass: 'delete-by-selection-modal',
        data: {
          title: this.i18n.instant('general.withoutSave'),
          confirmLabel: this.i18n.instant('general.buttonExit'),
          showBody: false,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'EXECUTE') {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  private async requestCustomer() {
    this.loading = true;
    try {
      const params = {
        'ids[]': [this.data.idCustomer],
        takeDeleted: Boolean(this.data?.takeDeleted),
      };

      const res = await this.finalService
        .search(params)
        .pipe(
          finalize(() => {
            this.draw();
          })
        )
        .toPromise();
      if (res.docs.length) {
        const [customer] = res.docs;
        this.customer = customer;
        this.loading = false;
      }

      this.draw();
    } catch (error) {}
  }

  async changeTab(tab: CreateFinal.TabType): Promise<void> {
    if (this.type !== 'EDIT' || this.middlewareExecute) {
      return;
    }

    this.middlewareExecute = true;
    const changeTab = await this.middlewareChangeTab();
    this.middlewareExecute = false;
    this.trackEvent(tab);

    if (!changeTab) {
      return;
    }

    this.activeTab = tab;
    this.draw();
  }

  trackEvent(tab: CreateFinal.TabType): void {
    const eventMap = {
      DETAIL: AmplitudeEvents.final_card_detail_tab,
      DEALS: AmplitudeEvents.final_card_deal_tab,
      TASKS: AmplitudeEvents.final_card_task_tab,
      SALES: AmplitudeEvents.final_card_sale_tab,
    };

    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: eventMap[tab],
    });
  }

  private async middlewareChangeTab(): Promise<boolean> {
    if (this.activeTab !== 'DETAIL') {
      return Promise.resolve(true);
    }

    const component = this.detailComponent;
    const hasChanges = component.haveChanges();

    if (!hasChanges) {
      this.draw();
      return Promise.resolve(true);
    }

    const { action } = await this.launchModalYesOrNoService
      .open({
        title: 'general.wantContinueWithoutSave',
        description: 'general.wantContinueWithoutSaveDescription',
        labelButtonAction: 'general.saveAndContinue',
        labelButtonCancel: 'general.nextWithoutSave',
      })
      .toPromise();

    if (action === 'EXECUTE') {
      await this.detailComponent.editSubmit({ close: false });
      await this.requestCustomer();
    }

    return Promise.resolve(Boolean(action && action !== 'CANCEL-X'));
  }

  shareLink() {
    let url = window.location.href.split('admin')[0];

    url += 'admin/finals?type=customer&id=' + this.customer?._id;
    this.clipboard.copy(url);
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.final_card_share,
    });
    this.toastService.show({
      text: 'activity.copyLink',
      type: 'success',
      msDuration: 4000,
      class: '',
    });
  }

  shouldShowDetail(): boolean {
    return (
      this.activeTab === 'DETAIL' &&
      (this.data?.mode !== 'EDIT' ||
        (this.data?.mode === 'EDIT' && !!this.customer))
    );
  }

  close(): void {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.final_card_exit,
    });
    this.dialogRef.close(false);
  }

  handleHaveChanges(haveChanges: boolean): void {
    this.haveChanges = haveChanges;
  }

  private draw() {
    this.cdRef.detectChanges();
  }
}
