export enum Period {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export interface PeriodOption {
  value: Period;
  label: string;
}

export interface PeriodMaxHours {
  period: Period;
  maxHours: number;
}
