import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { BasicMessageModule } from '@web-frontend/shared/components/basic-message';
import { TableModule } from '@web-frontend/shared/components/table';
import { NotResultFoundModule } from '@web-frontend/shared/components/not-result-found';
import { RmFilterModule } from '@web-frontend/shared/components/globals/rm-filter';
import { RomaToolTipModule } from '@web-frontend/shared/directives/roma-tooltip';
import { PaymentPrototipeRoutingModule } from './payment-prototipe-routing.module';
import { PaymentPrototipeComponent } from './payment-prototipe.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CreateConnectedAccountComponent } from './create-connected-account/create-connected-account.component';
import { CreatePaymentCardComponent } from './create-payment-card/create-payment-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreatePaymentsLinkComponent } from './create-payments-link/create-payments-link.component';
import { ModalConfirmPaymentComponent } from './create-payment-card/services/modal-confirm-payment/modal-confirm-payment.component';
import { ModalTemplateModule } from '@web-frontend/shared/components/modal-template';
import { MatRadioModule } from '@angular/material/radio';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CreateLoginLinkComponent } from './create-login-link/create-login-link.component';
import { CreateRecurrentPaymentsLinkComponent } from './create-recurrent-payments-link/create-recurrent-payments-link.component';
import { RequestReadersComponent } from './request-readers/request-readers.component';
import { CreateLocationComponent } from './create-location/create-location.component';
import { RegisterReaderComponent } from './register-reader/register-reader.component';
import { CreateRefundComponent } from './create-refund/create-refund.components';
import { CreateTransferComponent } from './create-transfer/create-transfer.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    PaymentPrototipeComponent,
    CreateConnectedAccountComponent,
    CreatePaymentCardComponent,
    CreatePaymentsLinkComponent,
    ModalConfirmPaymentComponent,
    CreateRecurrentPaymentsLinkComponent,
    RequestReadersComponent,
    CreateLocationComponent,
    RegisterReaderComponent,
    CreateLoginLinkComponent,
    CreateRefundComponent,
    CreateTransferComponent,
  ],
  imports: [
    CommonModule,
    PaymentPrototipeRoutingModule,
    BasicMessageModule,
    TranslateModule.forChild(),
    TableModule,
    MatButtonModule,
    MatIconModule,
    NotResultFoundModule,
    MatTableModule,
    MatChipsModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    RmFilterModule,
    RomaToolTipModule,
    ModalTemplateModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    CurrencyMaskModule,
  ],
  providers: [DatePipe],
})
export class PaymentPrototipeModule {}
