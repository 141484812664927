import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ValidateAllFormFields } from '@tacliatech/types';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import {
  FinalService,
  SalesService,
  SandboxService,
} from '@web-frontend/shared/services';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { FormatEmailValidator } from '@web-frontend/shared/validators/email';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'roma-send-email-sale-modal-light',
  templateUrl: './send-email-sale-modal-light.component.html',
  styleUrls: ['./send-email-sale-modal-light.component.scss'],
})
export class SendEmailSaleModalLightComponent implements OnInit, OnDestroy {
  form: FormGroup = this.fb.group({
    // message: new FormControl(null, []),
    to: new FormControl(null, [Validators.required, FormatEmailValidator()]),
    // subject: new FormControl(null),
  });
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  destinations: string[] = [];
  isLoading = false;
  customMessage: string;
  removable = true;
  emailFinal = '';
  sub$ = new Subscription();
  resize$ = this.sandBoxService.screenBusChannel$;

  constructor(
    private dialogRef: MatDialogRef<SendEmailSaleModalLightComponent>,
    private modalService: MatDialog,
    public i18n: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private salesService: SalesService,
    private alertService: AlertService,
    private toastService: ToastService,
    private finalService: FinalService,
    private sandBoxService: SandboxService,
    private budgetService: BudgetService
  ) {}

  ngOnInit(): void {
    const finalId = this.data.saleStorage?.final?._id || null;

    if (finalId) {
      this.isLoading = true;
      this.sub$.add(
        this.finalService
          .findOne(finalId)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
          .subscribe((res) => {
            const email = res.email || '';
            this.form.setValue({
              to: email,
            });
          })
      );
    }

    // this.finalService.findOne()
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (
      value &&
      this.destinations.indexOf(value) === -1 &&
      value.match?.(EMAIL_REGEX)
    ) {
      this.destinations.push(value);
      this.patchTo();
    }
    // Clear the input value
    event.chipInput!.clear();
  }

  remove(to: string): void {
    const index = this.destinations.indexOf(to);

    if (index >= 0) {
      this.destinations.splice(index, 1);
      this.patchTo();
    }
  }

  patchTo() {
    this.form.patchValue({ to: this.destinations });
  }

  close(param = null) {
    this.dialogRef.close(param);
  }

  send() {
    // this.form.controls['to'].setValue(this.destinations);
    // this.form.controls['to'].updateValueAndValidity();
    // const isValid = this.checkValidators();

    // console.log('entrando');

    // if (isValid) {
    this.isLoading = true;
    const saleData = this.data.saleStorage;
    const data = {
      emails: [this.form.value.to],
    };

    switch (this.data.module) {
      case 'sales':
        this.salesService
          .sendPaymentLinkEmail(this.data.idSale, data)
          .subscribe((resp) => {
            this.toastService.show({
              text: 'general.mailSent',
              type: 'success',
              msDuration: 4000,
            });
            this.isLoading = false;
            // @ts-ignore
            this.close('emailSent');
          });
        break;
      case 'budgets':
        this.budgetService
          .sendPaymentLinkEmail(this.data.idSale, data)
          .subscribe((resp) => {
            this.toastService.show({
              text: 'general.mailSent',
              type: 'success',
              msDuration: 4000,
            });
            this.isLoading = false;
            // @ts-ignore
            this.close('emailSent');
          });
        break;
      default:
        break;
    }
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);
    return this.form.valid;
  }

  validateEmail() {
    return (control: AbstractControl): ValidationErrors | null => {
      const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const forbidden = EMAIL_REGEX.test(control.value);
      return forbidden ? { forbiddenName: { value: control.value } } : null;
    };
  }
}
