<ul class="buttons-container">
  <li *ngIf="showDownloadPdf">
    <button (click)="triggerDownload(type.PDF)" class="download-btn">
      <span>{{ 'general.downloadPdf' | translate }}</span>
    </button>
  </li>
  <li *ngIf="showDownloadExcel">
    <button
      romaProFeature
      [feature]="featureExcel"
      (clicked)="triggerDownload(type.EXCEL)"
      class="download-btn"
    >
      <span>{{ 'general.downloadExcel2' | translate }}</span>
      <!-- <img class="icon-star-pro-feature ml-7px" src="assets/icons/star.svg" /> -->
      <img
        class="icon-star-pro-feature pro-badge ml-7px"
        src="assets/images/pro-badge.svg"
      />
    </button>
  </li>
  <br />
  <div class="footer-container">
    <div class="close-dialog">
      <img
        (click)="closeBottomSheet()"
        src="assets/icons/gl_close-black.svg"
        alt=""
      />
    </div>
  </div>
</ul>
