import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nChild } from '@web-frontend/shared/i18n';
import { DownloadBtnComponent } from './download-btn.component';
import { MatMenuModule } from '@angular/material/menu';
import { PermissionsModule } from '../permissions';
import { DownloadBtnMobileComponent } from './mobile-version/download-btn-mobile.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DownloadBySelectionModalModule } from '../download-by-selection-modal';

@NgModule({
  declarations: [DownloadBtnComponent, DownloadBtnMobileComponent],
  imports: [
    CommonModule,
    I18nChild,
    MatMenuModule,
    PermissionsModule,
    MatProgressSpinnerModule,
    DownloadBySelectionModalModule,
  ],
  exports: [DownloadBtnComponent, DownloadBtnMobileComponent],
})
export class DownloadBtnModule {}
