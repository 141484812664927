<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<roma-modal-template
  [title]="title"
  (cancel)="cancel()"
  (success)="success()"
  [footerTemplate]="footerButtons"
  [showDefaultButtons]="false"
>
  <ng-container>
    <div class="map-container">
      <google-map
        height="250px"
        width="100%"
        [options]="options"
        [center]="center"
        [zoom]="zoom"
      >
        <map-marker
          #markerElem="mapMarker"
          *ngFor="let marker of this.getMarkers()"
          [position]="marker.position"
          [label]="marker.label"
          [title]="marker.title"
          [options]="marker.options"
        >
        </map-marker>
      </google-map>
    </div>
  </ng-container>
</roma-modal-template>
<!--  [center]="center"
        [zoom]="zoom" -->

<ng-template #footerButtons>
  <div class="modalFooter">
    <div class="groupEndDiv">
      <base-button type="submit" buttonClass="tertiary" (click)="cancel()">
        {{ 'geolocation.cancel' | translate }}
      </base-button>

      <base-button type="submit" (click)="success()" [disabled]="!position">
        {{ 'geolocation.send' | translate }}
      </base-button>
    </div>
  </div>
</ng-template>
