<section>
  <form
    class="form-container"
    [formGroup]="transferForm"
    (ngSubmit)="submitTransfer()"
  >
    <section formGroupName="customerData" class="customer">
      <h3>Customer Data</h3>
      <label class="form-label">Name </label>
      <input class="form-control" type="text" formControlName="name" />

      <label class="form-label">Email </label>
      <input class="form-control" type="email" formControlName="email" />

      <label class="form-label">Descripcion </label>
      <input class="form-control" type="text" formControlName="description" />
    </section>

    <section class="transfer-data">
      <div class="group-control">
        <label class="form-label">Amount </label>
        <input class="form-control" type="text" formControlName="amount" />
      </div>
      <div class="group-control">
        <label class="form-label">Currency </label>
        <input class="form-control" type="text" formControlName="currency" />
      </div>
      <div class="group-control">
        <label class="form-label">Description </label>
        <input class="form-control" type="text" formControlName="description" />
      </div>
      <!-- <div class="group-control">
        <label class="form-label">Statement descriptor </label>
        <input
          class="form-control"
          type="text"
          formControlName="statement_descriptor"
        />
      </div> -->
    </section>

    <button class="roma-button" type="submit">Submit</button>
  </form>

  <ng-container *ngIf="transferUrl">
    {{ transferUrl }}
  </ng-container>
</section>
