import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum ConfirmationOutput {
  Cancel = 'CANCEL',
  CancelX = 'CANCEL-X',
  Execute = 'EXECUTE'
}

export enum ConfirmationButtonClass {
  primary = 'primary',
  danger = 'danger'
}
export interface ConfirmationDialogData {
  title?: string;
  body?: string;
  icon?: string;
  showCancel?:boolean;
  showCancelX?:boolean;
  cancelLabel?: string;
  confirmLabel?: string;
  confirmClass?: ConfirmationButtonClass
}

@Component({
  selector: 'roma-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: ConfirmationDialogData
  ) {
    if (!data) this.data = {};
    if (!data.confirmClass) data.confirmClass = ConfirmationButtonClass.primary;
  }

  onNoClick(): void {
    this.dialogRef.close(ConfirmationOutput.Cancel);
  }

  exit(): void {
    this.dialogRef.close(ConfirmationOutput.CancelX);
  }

  execute(): void {
    this.dialogRef.close(ConfirmationOutput.Execute);
  }
}
