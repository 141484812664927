import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { BudgetPreviewerComponent } from './budget-previewer.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { BudgetPreviewerMenuComponent } from './budget-previewer-menu/budget-previewer-menu.component';
import { ModalTemplateModule } from '../modal-template';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ImageModule } from '@web-frontend/shared/pipes/image';
import { LoadingModule } from '../loading';
import { PermissionsModule } from '../permissions';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BudgetPreviewerService } from './budget-previewer.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
@NgModule({
  declarations: [BudgetPreviewerComponent, BudgetPreviewerMenuComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCardModule,
    FormsModule,
    TranslateModule,
    TranslateModule.forChild(),
    NgxExtendedPdfViewerModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    ModalTemplateModule,
    LoadingModule,
    PermissionsModule,
    ImageModule.forRoot(),
    MatProgressSpinnerModule,
    PdfViewerModule,
  ],
  providers: [BudgetPreviewerService],
  exports: [BudgetPreviewerComponent, BudgetPreviewerMenuComponent],
})
export class BudgetPreviewerModule {}
