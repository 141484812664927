export enum CHANNEL_NOTIFICATIONS {
  ALL = 'ALL',
  EMAIL_SENDER = 'EMAIL',
  SMS_SENDER = 'SMS',
  WSP_SENDER = 'WhatsApp',
  VIBER_SENDER = 'Viber',
  PUSH_MESSAGE = 'PushNotif',
  APPLEBC_SENDER = 'Apple Business Chat',
}

export enum RECIPIENTS_NOTIFICATIONS {
  ALL = 'ALL',
  VENDOR = 'VENDOR',
  CUSTOMER = 'CUSTOMER',
  OPERATIONS = 'OPERATIONS',
}

export enum NotificationFeatureFlagKeys {
  NotificationAssignedDealUser = 'notification_assigned_deal_user',
  NotificationCanceledDeletedUser = 'notification_canceled_deleted_user',
  NotificationRescheduledDealUser = 'notification_rescheduled_deal_user',
  NotificationReminderEmployeeUser = 'notification_reminder_employee_user',
  NotificationNewOnlineDealEmployeeUser = 'notification_new_online_deal_employee_user',
}

export enum NotificationEnum {
  Deals = 'deals',
  OnlineBooking = 'onlineBooking',
  TimeTracking = 'timeTracking',
  Tasks = 'tasks',
  Sales = 'sales',
}
