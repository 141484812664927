import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { DealStatusManagerComponent } from './deal-status-manager.component';
import { AddStatusModule } from './add-status/add-status.module';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from '../../loading';
import { MatMenuModule } from '@angular/material/menu';
import { ClickStopPropagationModule } from '@web-frontend/shared/directives/click-prevent-default';
import { IconColorComponent } from './icon-color/icon-color.component';
import { ChooseColorComponent } from './choose-color/choose-color.component';
import { ChooseColorModule } from './choose-color/choose-color.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [DealStatusManagerComponent, IconColorComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    DragDropModule,
    AddStatusModule,
    TranslateModule.forChild(),
    ClickDeleteModule,
    LoadingModule,
    FormsModule,
    ClickStopPropagationModule,
    MatMenuModule,
    ChooseColorModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    DealStatusManagerComponent,
    IconColorComponent,
    ChooseColorComponent,
  ],
})
export class DealStatusManagerModule {}
