import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

enum LoadingClass {
  BLUE = 'blue',
  WHITE = 'white',
}
@Component({
  selector: 'roma-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
  @Input()
  size = 100;

  @Input()
  number = 123;

  @Input()
  loadingClass: LoadingClass = LoadingClass.BLUE;
}
