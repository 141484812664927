import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@web-frontend/environments';
import { StorageService } from '@web-frontend/shared/services';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
  ApiCodeInvalidAmount,
  ApiCodeResourceMissing,
} from '@tacliatech/types';
import { ModalEmptyObjectComponent } from '@web-frontend/shared/components/modal-empty-object';
import { ModalErrorMaintenanceService } from '@web-frontend/shared/components/modal-error-maintenance/modal-error-maintenance.service';
import { ClickLogoutService } from '@web-frontend/shared/directives/click-logout';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ApiInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private logOutService: ClickLogoutService,
    private modalErrorMaintenanceService: ModalErrorMaintenanceService
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let request = req;
    let authorization = null;

    if (req.url.indexOf(':API_URL') > -1 || req.url.indexOf(':API_BASE_URL') > -1) {
      if (req.headers.get('Authorization') === null) {
        // @ts-ignore
        authorization = StorageService.Token;
      } else {
        // @ts-ignore
        authorization = req.headers.get('Authorization').split(' ')[1];
      }

      request = authorization
        ? req.clone({
          url: this.parseURL(req.url),
          setHeaders: {
            Authorization: authorization ? `Bearer ${authorization}` : '',
            language: StorageService.GetItem('USER_LANG'),
            timestamp: new Date().toISOString(),
            apiUrl: req.url.indexOf(':API_URL') > -1? environment.api.url: environment.api.urlPlain,
          },
        })
        : req.clone({
          url: this.parseURL(req.url),
        });
    }

    return next.handle(request).pipe(
      tap(
        (e) => {
          if (
            e instanceof HttpResponse &&
            e.status === 256 &&
            !localStorage.getItem('register')
          ) {
            this.logOutService.logout();
            this.router.navigateByUrl('auth/login');
          }
        },
        (res) => {
          //TODO: IMPRROVE LOGIC ERRORS
          if (
            res instanceof HttpErrorResponse &&
            res.error?.apiErrorCode === ApiCodeInvalidAmount
          ) {
            console.log('stripe error', ApiCodeInvalidAmount);
          } else if (
            res instanceof HttpErrorResponse &&
            res.error?.apiErrorCode === ApiCodeResourceMissing
          ) {
            console.log('stripe error', ApiCodeResourceMissing);
          }
          // ToDo: review modal maintenance
          // else if (
          //   res instanceof HttpErrorResponse &&
          //   [
          //     500,
          //     501,
          //     502,
          //     503,
          //     504,
          //     504,
          //     505,
          //     506,
          //     507,
          //     508,
          //     510,
          //     511,
          //   ].includes(res.status) &&
          //   res.name === 'HttpErrorResponse'
          // ) {
          //   this.modalErrorMaintenanceService.show();
          // }

          this.checkEmptyObjects(res);
        }
      )
    );
  }

  private parseURL(url: string): string {
    const apiUrl = environment.api.url;
    const apiBaseUrl = environment.api.urlPlain;

    url = url.replace(':API_URL', apiUrl);
    url = url.replace(':API_BASE_URL', apiBaseUrl);

    return url;
  }

  private checkEmptyObjects(err: any) {
    if (err instanceof HttpErrorResponse && err.status === 400) {
      switch (err?.error?.message) {
        case 'DEAL_ID_NOT_EXIST':
          this.showModalEmptyObject('deal');
          break;

        case 'ACTIVITY_ID_NOT_EXIST':
          this.showModalEmptyObject('activity');
          break;

        case 'ASSET_ID_NOT_EXIST':
          this.showModalEmptyObject('asset');
          break;
        case 'FINAL_ID_NOT_EXIST':
          this.showModalEmptyObject('final');
          break;
        case 'VENDOR_ID_NOT_EXIST':
          this.showModalEmptyObject('vendor');
          break;

        case 'EQUIPMENT_ID_NOT_EXIST':
          this.showModalEmptyObject('equipment');
          break;

        case 'PROJECT_ID_NOT_EXIST':
          this.showModalEmptyObject('project');
          break;

        default:
          break;
      }
    }
  }

  private showModalEmptyObject(type) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
    };
    const modalRef = this.modalService.open(
      ModalEmptyObjectComponent,
      ngbModalOptions
    );

    (modalRef.componentInstance as ModalEmptyObjectComponent).type = type;
  }
}
