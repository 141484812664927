import * as moment from 'moment-timezone';

import {
  AssetDTO,
  CancellationReasonDTO,
  ContactsDTO,
  CustomerDTO,
  MembersDTO,
  StatusDTO,
  SupplierDTO,
} from '../interfaces';

import { BillLine } from './bill.class';
import { ImageResource } from './imageResource';
import { Activity } from './activity.class';
import { Product } from './product.class';
import { UserDTO } from '../interfaces/global';
import { BillingProfileDTO, DATE_FORMAT, IDeal } from '@tacliatech/types';
import { StorageService } from '../services/storage/storage.service';

export class Deal {
  billLines: BillLine[];
  description: string;
  dateCreated: Date;
  times: string[];
  img: ImageResource[];
  products: Product[];
  notes: string[];
  _id: string;
  members: MembersDTO[];
  name: string;
  company_id: CustomerDTO;
  supplier_id?: SupplierDTO;
  visitation: boolean;
  city: string;
  address: string;
  address2?: string;

  initDate: Date;
  contact_name: string;
  contact_phone: string;
  contact_email: string;
  contacts?: ContactsDTO[];
  // TODO JS
  asset_id: string;
  status_id: StatusDTO;
  created_by: UserDTO;
  // TODO JS
  __v: number;
  visitation_date: string;
  expiration_date?: string;
  job_description?: string;

  billingProfile?: BillingProfileDTO;
  assetId?: AssetDTO;
  urgency?: boolean;
  dto: IDeal;

  chatReview: {
    lastUpdated: null;
    checkByOperator: true;
  };

  localTimeStatus: Date;
  deleted?: boolean;

  activities?: Activity[];

  activitiesTimes?: Activity[] = [];

  expireTimeStatus?: boolean;
  cancellation_reason?: CancellationReasonDTO;

  static fromArray(attributes: IDeal[]) {
    return attributes.map((attribute) => new Deal(attribute));
  }

  constructor(attribute: IDeal) {
    const billLines = attribute?.billLines
      ? BillLine.fromArray(
          attribute?.billLines.filter((lines) => Boolean(lines))
        )
      : [];

    /*const img = attribute?.img
      ? ImageResource.fromArray(
          attribute?.img.filter((image) => Boolean(image))
        )
      : [];*/
    const img = attribute?.img;

    const initDate = attribute?.initDate
      ? moment(attribute?.initDate, 'YYYY-MM-DD').toDate()
      : null;

    const dateCreated = attribute?.dateCreated
      ? moment(attribute?.dateCreated, 'YYYY-MM-DD').toDate()
      : null;

    const name = attribute?.name ? attribute?.name : '';

    const billingProfile =
      typeof attribute?.billingProfile !== 'string'
        ? attribute?.billingProfile
        : null;

    /*const assetId =
      typeof attribute?.asset_id !== 'string' ? attribute?.asset_id : null;*/

    const urgency = attribute?.urgency ? attribute?.urgency : false;

    const localTimeStatus =
      attribute?.timeStatus && attribute?.offsetTimeStatus
        ? new Date(attribute.timeStatus)
        : null;

    /*const activities = attribute?.activities
      ? Activity.fromArray(attribute?.activities)
      : [];

    const activitiesTimes = activities.length
      ? activities.sort((first, last) =>
          first.dateTime > last.dateTime ? 1 : -1
        )
      : [];*/

    /*const expireTimeStatus = attribute?.hasOwnProperty('expireTimeStatus')
      ? attribute?.expireTimeStatus
      : false;*/

    const dto = attribute;

    /*const products = attribute?.products
      ? Product.fromArray(attribute.products)
      : [];*/

    Object.assign(this, attribute, {
      billLines,
      initDate,
      name,
      img,
      dateCreated,
      billingProfile,
      //assetId,
      urgency,
      dto,
      localTimeStatus,
      //activities,
      //activitiesTimes,
      //expireTimeStatus,
      //products,
    });
  }

  addLine(line: BillLine) {
    this.billLines.push(line);
  }

  setLines(lines) {
    this.billLines = lines;
  }

  editLine(id: string) {
    return (line: BillLine) => {
      const findIndex = this.billLines.findIndex((line) => line._id === id);
      if (findIndex !== -1) {
        this.billLines[findIndex] = line;
      }
    };
  }

  deleteLine(id: string) {
    const findIndex = this.billLines.findIndex((line) => line._id === id);
    if (findIndex !== -1) {
      this.billLines.splice(findIndex, 1);
    }
  }

  subTotal() {
    let total = 0;
    for (const line of this.billLines) {
      total += +line.price;
    }
    return +total?.toFixed(2);
  }

  cantidadTotal() {
    let total = 0;
    for (const line of this.billLines) {
      if (line.quantity > 0) {
        total += line.quantity;
      }
    }
    return total;
  }

  marginClientTotal() {
    let total = 0;
    for (const line of this.billLines) {
      if (line.marginClient > 0) {
        total += line.marginClient;
      }
    }
    return total.toFixed(2);
  }

  marginSupplierTotal() {
    let total = 0;
    for (const line of this.billLines) {
      if (line.marginSupplier > 0) {
        total += line.marginSupplier;
      }
    }
    return total.toFixed(2);
  }

  transportTotal() {
    let total = 0;

    for (const line of this.billLines) {
      if (line.firstLine && line.transport > 0) {
        total += line.transport;
      }
    }

    return total.toFixed(2);
  }

  taxTotal() {
    let total = 0;
    for (const line of this.billLines) {
      if (line.tax > 0) {
        total += line.tax;
      }
    }
    if (total > 0) {
      return total.toFixed(2);
    } else {
      return total;
    }
  }

  priceSupplierTotal() {
    let total = 0;
    for (const line of this.billLines) {
      if (line.priceSupplier > 0) {
        total += line.priceSupplier;
      }
    }
    return total.toFixed(2);
  }

  priceClientTotal() {
    let total = 0;
    for (const line of this.billLines) {
      if (line.priceClient > 0) {
        total += line.priceClient;
      }
    }
    return total;
  }

  priceWithTaxTotal() {
    let total = 0;

    for (const line of this.billLines) {
      if (line.priceWithTax() > 0) {
        total += line.priceWithTax();
      }
    }

    return total.toFixed(2);
  }

  ClientTaxTotal() {
    let total = 0;
    for (const line of this.billLines) {
      if (line.priceCustomerTax() > 0) {
        total += line.priceCustomerTax();
      }
    }
    return total.toFixed(2);
  }

  priceTotalClientTotal() {
    let total = 0;
    for (const line of this.billLines) {
      if (line.priceSubTotalTaxClient > 0) {
        total += line.priceSubTotalTaxClient;
      }
    }
    return total.toFixed(2);
  }

  priceTotalSupplierTotal() {
    let total = 0;
    for (const line of this.billLines) {
      if (line.priceSubTotalTaxSupplier > 0) {
        total += line.priceSubTotalTaxSupplier;
      }
    }
    return total.toFixed(2);
  }

  initDateFormatted() {
    return moment(this.initDate).format(StorageService.GetItem(DATE_FORMAT));
  }

  isTypeA() {
    return this.products?.[0].type === 'A' ? true : false;
  }

  isTypeD() {
    return this.products?.[0].type === 'D' ? true : false;
  }

  isTypeC() {
    return this.products?.[0]?.type === 'C' ? true : false;
  }

  allowEdit(line: BillLine) {
    const indexFirstLine = this.billLines?.findIndex(
      (myLine) => myLine._id === line._id
    );

    return this.isTypeA() ? (indexFirstLine === 0 ? false : true) : true;
  }

  allowDelete(line: BillLine) {
    const indexFirstLine = this.billLines?.findIndex(
      (myLine) => myLine._id === line._id
    );

    return this.isTypeA() ? (indexFirstLine === 0 ? false : true) : true;
  }

  lastLine() {
    return this.billLines?.length > 0
      ? this.billLines[this.billLines.length - 1]
      : null;
  }

  expirationDateFormatted() {
    if (this.expiration_date !== '') {
      return new Date(this.expiration_date);
    } else {
      return new Date();
    }
  }

  isExpired() {
    return moment(this.localTimeStatus).isBefore(new Date());
  }

  setupActivityTimes() {
    const activitiesTimes = this.activities.length
      ? this.activities.sort((first, last) =>
          first.dateTime > last.dateTime ? 1 : -1
        )
      : [];

    this.activitiesTimes = activitiesTimes;
  }

  getTasker() {
    const tasker = this.billLines[0]?.tasker_id
      ? this.billLines[0]?.tasker_id
      : '';

    return tasker;
  }

  isEqualTasker(tasker: string) {
    return tasker === this.getTasker();
  }

  getQuantity() {
    let result;
    for (const bill of this.billLines) {
      if (bill.service === this._id) {
        result = bill.quantity;
        break;
      }
    }
    return result;
  }
}
