import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ISequence, SequenceTypes } from '@tacliatech/types';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { SequenceService } from '@web-frontend/shared/services/sequence';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BackendError } from '../../interfaces';
import { getCountryCodeByCompanyFromLocalStorage } from '../../utils/country-validator';

@Component({
  selector: 'roma-create-sequence',
  templateUrl: './create-sequence.component.html',
  styleUrls: ['./create-sequence.component.scss'],
})
export class CreateSequenceComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<CreateSequenceComponent>,
    private sequenceService: SequenceService,
    private changeDetectorRef: ChangeDetectorRef,
    private toastService: ToastService,
    private i18n: TranslateService,
    private amplitudeService: AmplitudeService,
    @Inject(MAT_DIALOG_DATA)
    public data?: ISequence & { disableBudgetList?: boolean }
  ) {}

  @Input()
  showIncrement = false;

  sub$ = new Subscription();
  types: SequenceTypes[] = Object.values(SequenceTypes) as SequenceTypes[];
  sequenceTypeRef = SequenceTypes;
  isSubmitting = false;
  modifiedSequenceId: string;
  sequence: ISequence;
  submitError = false;
  disableList = false;

  numberPattern = /^[0-9]+$/;

  ngOnInit(): void {
    if (this.data) {
      this.sequence = { ...this.data };
      this.disableList = this.data.disableBudgetList
        ? this.data.disableBudgetList
        : false;
    } else {
      this.sequence = {
        name: '',
        type: SequenceTypes.BILL,
        incrementValue: 1,
        currentNumber: 1,
        prefix: '',
      };
    }

    this.removeCreditNoteTypeWhenCountryIsNotMexico();
    this.draw();
  }

  removeCreditNoteTypeWhenCountryIsNotMexico() {
    const companyCountry = getCountryCodeByCompanyFromLocalStorage();
    if (companyCountry !== 'MX') {
      this.types = this.types.filter(
        (type) => type !== SequenceTypes.CREDIT_NOTE
      );
    }
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  onPaste(event: ClipboardEvent) {
    const pastedData = event.clipboardData.getData('text');
    const isNumber = this.numberPattern.test(pastedData);
    return isNumber;
  }

  onChangeCurrentNumber(): void {
    if (this.sequence.currentNumber <= 0) {
      this.sequence.currentNumber = 1;
    }
  }

  submit() {
    this.submitError = false;
    this.isSubmitting = true;

    if (!this.sequence?.name || !this.sequence?.prefix) {
      this.submitError = true;
      this.isSubmitting = false;
      return;
    }

    const obs$ = this.sequence?.id
      ? this.sequenceService.editSequence(this.sequence)
      : this.sequenceService.addSequence(this.sequence);

    const text = this.sequence?.id
      ? 'sequence.updatedSucces'
      : 'sequence.createdSucces';

    this.sub$.add(
      obs$
        .pipe(
          finalize(() => {
            // Send amplitude event
            this.amplitudeService.sendEvent({
              event: 'settings_addSerie_add',
            });
          })
        )
        .subscribe(
          (res: HttpResponse<ISequence | BackendError>) => {
            this.isSubmitting = false;
            this.modifiedSequenceId =
              this.sequence.id || (res.body as ISequence).id;
            this.toastService.show({
              msDuration: 2000,
              type: 'success',
              text: text,
            });
            this.close(true);
          },
          (error) => {
            this.isSubmitting = false;

            let errorMessage = this.i18n.instant(
              `backendErrors.${error.error?.error}`
            );
            if (errorMessage === `backendErrors.${error.error?.error}`) {
              errorMessage = this.i18n.instant('backendErrors.genericError');
            }

            this.toastService.show({
              msDuration: 4000,
              type: 'error',
              text: errorMessage,
            });
          }
        )
    );
  }

  getTranslationBySequenceType(sequenceType: string) {
    if (sequenceType === SequenceTypes.ADD_BUDGETS) {
      return this.i18n.instant('sequence.types.allBudgets');
    }
    return this.i18n.instant(`sequence.types.${sequenceType.toLowerCase()}`);
  }

  close(e?: boolean) {
    this.dialogRef.close(this.modifiedSequenceId);
    this.isSubmitting = false;
  }

  onKeyPress(event: KeyboardEvent): void {
    const keyCode = '';
    const key = event.key;

    // Asegurarse de que solo se permitan números
    if (!/^\d$/.test(key)) {
      event.preventDefault();
    }
  }
}
