import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';

import { TranslateModule } from '@ngx-translate/core';

import { NgSelectModule } from '@ng-select/ng-select';
import { AutosizeModule } from 'ngx-autosize';
import { NgxMaskModule } from 'ngx-mask';

import { MatInputModule } from '@angular/material/input';
import { AlertModule } from '@web-frontend/shared/helpers/alert';

import { EditableFieldComponent } from './editable-field.component';
import { MaterialModule } from '../../helpers/material/material.module';
import { PermissionsModule } from '../permissions';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  declarations: [EditableFieldComponent],
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    MaterialModule,
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    AutosizeModule,
    NgxMaskModule,
    TranslateModule.forChild(),
    MatGoogleMapsAutocompleteModule,
    AlertModule,
    NgSelectModule,
    PermissionsModule,
    MatDatepickerModule,
  ],
  exports: [EditableFieldComponent],
})
export class EditableFieldModule {}
