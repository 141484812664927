import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'roma-not-result-found',
  templateUrl: './not-result-found.component.html',
  styleUrls: ['./not-result-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotResultFoundComponent implements OnInit {
  @Output()
  change = new EventEmitter<{ action: 'CLEAR' }>();

  @Input() type = 'default';

  constructor() {}

  ngOnInit(): void {}

  clear() {
    this.change.emit({ action: 'CLEAR' });
  }
}
