import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { FileSelectService } from '@web-frontend/shared/helpers/file-select';

export interface FileHandle {
  file: File;
  url?: SafeUrl;
  stringBinary?: string;
}

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  constructor(
    private fileSelect: FileSelectService,
    private i18n: TranslateService,
    private sanitizer: DomSanitizer,
    private changeDetectionRef: ChangeDetectorRef
  ) {}

  @Input()
  multiple = false;

  @Input()
  disable = false;

  @Input()
  fileAdmitData: string[] = ['*'];

  @Input()
  uploadImage = 'assets/icons/gl_file-icon.svg';

  @Input()
  label = 'imports.fileUpload.defaultImport';

  @Input()
  allowDragAndDrop = true;

  @Input()
  showErrorTexts = false;

  @Output()
  onFilesChange = new EventEmitter<FileHandle[]>();

  dragOver = false;
  files: FileHandle[] = [];

  fileFormatError = false;

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragOver = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragOver = false;
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  removeFiles(): void {
    this.files = [];
    this.fileFormatError = false;
    this.onFilesChange.emit(this.files);
  }

  checkFiles(): void {
    this.fileFormatError = this.files.some(
      (it) =>
        !this.fileAdmitData.includes(it.file.name.split('.')[1]) &&
        !this.fileAdmitData.includes('*')
    );
    if (this.fileFormatError) {
      this.files = [];
    }

    if (this.files.length > 1 && this.multiple === false) {
      this.files = [];
      this.fileFormatError = true;
    }

    this.onFilesChange.emit(this.files);

    this.draw();
  }

  get filesNames(): string[] {
    const screenCharacters = window.screen.width < 768 ? 20 : 35;
    return this.files.map((it) => {
      const extension = it.file.name.split('.')[1];
      const name = it.file.name.split('.')[0];
      if (name.length > 20) {
        const shortName = name.substring(0, screenCharacters);
        return `${shortName}...${extension}`;
      } else {
        return `${name}.${extension}`;
      }
    });
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragOver = false;

    if (!this.allowDragAndDrop || this.disable) {
      return;
    }

    this.fileFormatError = false;

    const files: FileHandle[] = [];
    for (let i = 0; i < evt.dataTransfer.files.length; i++) {
      const file = evt.dataTransfer.files[i];
      const url = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(file)
      );
      files.push({ file, url });
    }

    if (files.length > 0) {
      const reader = new FileReader();
      reader.readAsBinaryString(files[0].file); //change for multiple if needed

      reader.addEventListener('load', (event: any) => {
        this.fileFormatError = false;
        files[0].stringBinary = event.target.result;
        this.files.push(files[0]);
        this.checkFiles();
      });
    }
  }

  async openFileSelector(): Promise<void> {
    try {
      const response = await this.fileSelect.openSelector({
        multiple: this.multiple,
        extensions: this.fileAdmitData.filter((it) => it !== '*'),
      });
      const files = response.getAll('file[]') as File[];
      const reader = new FileReader();
      reader.readAsBinaryString(files[0]); //change for multiple if needed

      reader.addEventListener('load', (event: any) => {
        this.fileFormatError = false;
        this.files.push({ file: files[0], stringBinary: event.target.result });
        this.checkFiles();
      });
    } catch (e) {
      this.fileFormatError = true;
    }
  }
}
