import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  Route,
  Router,
  UrlSegment,
} from '@angular/router';
import { PermissionService } from '@web-frontend/shared/services/permissions';

@Injectable()
export class PermissionFeatureGuard implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private permissionService: PermissionService
  ) {}

  public async canActivate() {
    this.permissionService.findAll().subscribe();
    return true;
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    const data = route.data;
    if (data?.featureUser) {
      const canLoad = this.permissionService.permissions.includes(
        data.featureUser
      );
      if (!canLoad) this.router.navigate(['/']);
      return canLoad;
    } else {
      return true;
    }
  }
}
