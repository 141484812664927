import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
  pure: false,
})
export class SearchFilterPipe implements PipeTransform {
  transform(items, filter: { key: string }): any {
    const prefix = filter?.key.replace('+', '');

    if (!items?.length || !prefix) {
      return items;
    }

    return items.filter(
      (item) =>
        item.name.match(new RegExp(prefix, 'i')) ||
        item.dial_code.match(new RegExp(prefix, 'i'))
    );
  }
}
