<div
  #dragContainer
  class="drag-cols-container containerTable"
  cdkDropListGroup
  cdkScrollable
>
  <ng-container>
    <div *ngFor="let column of sortBy('order')">
      <roma-kanban-column
        #kanbanColumn
        cdkDropList
        [id]="column.isSystemColumn ? null : column.name"
        [column]="column"
        [type]="type"
        [otherColumns]="otherColumns(column)"
        [cards]="column.cards"
        [showCardId]="showCardId"
        [showFinishCard]="showFinishCard"
        [showDeleteCard]="showDeleteCard"
        [showCancelCard]="showCancelCard"
        [showRestoreCard]="showRestoreCard"
        [statusData]="statusData"
        [isSystemColumn]="column.isSystemColumn"
        [deleteColumnMessage]="deleteColumnMessage"
        [placeholderAddCard]="placeholderAddCard"
        [cardCreateText]="cardCreateText"
        [cdkDropListData]="column.cards"
        [isLoading]="isLoading"
        [finishSelected]="finishSelected"
        [cardDropPlaceHolderType]="cardDropPlaceHolderType"
        [deleteCardMessage]="deleteCardMessage"
        [restoreCardMessage]="restoreCardMessage"
        [tooltipDeleteSystemColumn]="tooltipDeleteSystemColumn"
        [lastCreatedCardTitle]="lastCreatedCardTitle"
        [featureUserCreate]="featureUserCreate"
        [featureUserUpdate]="featureUserUpdate"
        [featureUserDelete]="featureUserDelete"
        (cdkDropListDropped)="drop($event, column)"
        (onCardDrop)="onCardDrop($event)"
        (onCardClick)="eventCardClick($event)"
        (onCardDblClick)="eventCardDblClick($event)"
        (onAddCard)="eventAddCard($event)"
        (onEditColumn)="eventEditColumn($event)"
        (onDeleteColumn)="eventDeleteColumn($event)"
        (onRestoreCard)="eventRestoreCard($event)"
        (onFinishCard)="eventFinishCard($event)"
        (onUnfinishCard)="eventUnfinishCard($event)"
        (onEditCard)="eventEditCard($event)"
        (onDeleteCard)="eventDeleteCard($event)"
        (onCancelCard)="eventCancelCard($event)"
        (onChangeStatusCard)="eventChangeStatus($event)"
        (onMoveAllCards)="eventMoveAllCards($event)"
        (onCardSelectChange)="eventCardSelectChange($event)"
      >
      </roma-kanban-column>
    </div>
  </ng-container>
  <div class="add-column-container" *ngIf="allowCreateColumn && !isLoading">
    <div class="new-column" [ngClass]="{ 'new-status': addColumn }">
      <div
        *ngIf="!addColumn"
        class="kanban-column-title"
        (click)="addColumnClick()"
      >
        <img src="assets/icons/kanban/gl_plus.svg" />
        {{ 'general.addButton' | translate }}
      </div>
      <div *ngIf="addColumn" class="kanban-column-title-edit">
        <div class="kanban-column-title-edit-top">
          <input
            class="edit-input"
            type="text"
            [placeholder]="'kanban.placeHolderAddStatusTitle' | translate"
            [(ngModel)]="columnName"
            (keydown.enter)="eventAddColumn()"
          />
          <roma-icon-color
            *ngIf="!(resize$ | async)?.payload?.mobile"
            [matMenuTriggerFor]="menu"
            [color]="selectedColor.lightColor"
          ></roma-icon-color>
          <roma-icon-color
            *ngIf="(resize$ | async)?.payload?.mobile"
            [color]="selectedColor.lightColor"
            (click)="openChooseColor()"
          ></roma-icon-color>

          <mat-menu #menu="matMenu" class="panel-choose">
            <div class="container-choose">
              <div class="choose-label">Color</div>
              <roma-choose-color
                [color]="selectedColor.lightColor"
                (change)="changeColor($event)"
              >
              </roma-choose-color>
            </div>
          </mat-menu>
        </div>

        <div class="kanban-column-title-edit-bottom">
          <button class="edit-button" (click)="eventAddColumn()">
            {{ 'kanban.save' | translate }}
          </button>
          <div class="cancel-button" (click)="closeColumnButton()">
            {{ 'clockHour.cancel' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="selectedCards.length > 0"
  class="kanban-footer-dialog"
  [ngClass]="{ modal__in: modalIn }"
>
  <div class="kanban-footer-dialog-text">
    ({{ selectedCards.length }}) {{ 'kanban.selected' | translate }}
  </div>

  <!-- Restore cancelled multiple -->
  <button
    class="kanban-footer-button-mark restore-button"
    romaProFeature
    [featureUser]="featureUserUpdate"
    (clicked)="evenRestoreSelectedCards()"
    *ngIf="
      !selectedHaveFinishedCards && !selectedHaveAliveCards && showRestoreCard
    "
  >
    <img class="close-button" src="assets/icons/kanban/gl_trash-blue.svg" />
    {{ 'kanban.buttonRestore' | translate }}
  </button>

  <!--Mark multiple as unfinished -->
  <button
    class="kanban-footer-button-mark unfinished"
    romaProFeature
    [featureUser]="featureUserUpdate"
    (clicked)="eventUnfinishSelectedCards()"
    *ngIf="selectedHaveFinishedCards && !selectedHaveDeletedCards"
  >
    <img class="close-button" src="assets/icons/kanban/check.svg" />
    {{ 'kanban.buttonMarkAsUnFinished' | translate }}
  </button>

  <!-- Mark multiple as Finished -->
  <button
    class="kanban-footer-button-mark"
    romaProFeature
    [featureUser]="featureUserUpdate"
    (clicked)="eventFinishSelectedCards()"
    *ngIf="
      !selectedHaveFinishedCards && !selectedHaveDeletedCards && showFinishCard
    "
  >
    <img class="close-button" src="assets/icons/kanban/check.svg" />
    {{ 'activity.markAsDone' | translate }}
  </button>

  <!--Move multiple -->
  <button
    class="kanban-footer-button-move"
    [matMenuTriggerFor]="main_menu"
    #moveMenu="matMenuTrigger"
    *ngIf="
      !selectedHaveFinishedCards &&
      !selectedHaveDeletedCards &&
      !(resize$ | async)?.payload?.mobile
    "
    [ngClass]="{ isOpen: moveMenu.menuOpen }"
  >
    <img
      class="close-button"
      [src]="
        moveMenu.menuOpen
          ? 'assets/icons/kanban/gl_move-blue.svg'
          : 'assets/icons/kanban/gl_move-black.svg'
      "
    />
    {{ 'kanban.move' | translate }}
  </button>

  <!--Move multiple mobile button -->
  <button
    class="kanban-footer-button-move"
    *ngIf="!selectedHaveFinishedCards && !selectedHaveDeletedCards && false"
    romaProFeature
    [featureUser]="featureUserUpdate"
    (clicked)="showMoveCardMobileMenu = true"
  >
    <img class="close-button" src="assets/icons/kanban/gl_move-black.svg" />
    {{ 'kanban.move' | translate }}
  </button>

  <!-- Mark multiple as Cancel -->
  <button
    *ngIf="showCancelCard && !selectedHaveDeletedCards"
    class="kanban-footer-button-move"
    romaProFeature
    [featureUser]="featureUserUpdate"
    (clicked)="eventCancelCard(selectedCards)"
  >
    <img src="assets/icons/gl_dialog_close.svg" class="check-icon" />
    {{ 'deals.cancelTitle' | translate }}
  </button>

  <!-- Delete multiple -->
  <button
    class="kanban-footer-button-delete"
    *ngIf="!selectedHaveDeletedCards"
    romaProFeature
    [featureUser]="featureUserDelete"
    (clicked)="openDelete()"
  >
    <img class="close-button" src="assets/icons/kanban/delete.svg" />
    {{ 'kanban.delete' | translate }}
  </button>

  <!-- Move menu -->
  <div class="kanban-footer-button-close">
    <img
      class="close-button"
      src="assets/icons/kanban/close.svg"
      (click)="eventUnselectCards()"
    />
  </div>

  <mat-menu #main_menu="matMenu" class="panel-choose panel-move">
    <ng-container *ngFor="let mainItem of objectKeys(_parsedColumns)">
      <button
        mat-menu-item
        romaProFeature
        [featureUser]="featureUserUpdate"
        (clicked)="eventMoveSelectedCards(_parsedColumns[mainItem])"
      >
        {{ mainItem }}
      </button>
    </ng-container>
  </mat-menu>
</div>

<div
  *ngIf="showMoveCardMobileMenu"
  class="mobile-viewtype-wrapper"
  style="position: fixed !important"
>
  <div class="viewtype-list">
    <h4>
      <img
        (click)="showMoveCardMobileMenu = false"
        src="assets/icons/gl_arrow-full-left.svg"
        style="margin-right: 10px"
      />
      {{ 'kanban.moveTo' | translate }}
    </h4>
    <div class="link-list">
      <div class="search-input">
        <img class="left" src="assets/icons/gl_search.svg" />
        <input
          #inputFilterSearch
          class="filter-input"
          type="text"
          [placeholder]="'clockHour.inputPlaceholder' | translate"
          (keyup)="onKeySearch($event)"
        />
      </div>
      <ng-container *ngFor="let mainItem of objectKeys(_parsedColumns)">
        <div
          class="list-item"
          romaProFeature
          [featureUser]="featureUserUpdate"
          (clicked)="
            eventMoveSelectedCards(_parsedColumns[mainItem]);
            showMoveCardMobileMenu = false
          "
          *ngIf="
            _searchValue
              ? mainItem.toLowerCase().includes(_searchValue.toLowerCase())
              : true
          "
        >
          <mat-icon class="prev-icon">
            <img
              *ngIf="mainItem === selectedCardName"
              width="15px"
              src="/assets/icons/ic_check.svg"
              alt="link"
            />
          </mat-icon>
          <span class="icon-text">
            {{ mainItem }}
          </span>
        </div>
      </ng-container>
      <div class="text-center close-btn-wrapper">
        <img
          (click)="showMoveCardMobileMenu = false"
          class="close-btn"
          src="/assets/icons/btn_close.svg"
          alt="close"
        />
      </div>
    </div>
  </div>
</div>
