<div class="safe-area-padding no-overflow-y">
  <div #modalContainer class="modal-container">
    <div class="actions-header">
      <!-- {{ saleSteps$ | async | json }} -->
      <div class="close-container">
        <img
          *ngIf="haveChanges"
          class="close"
          [src]="'assets/icons/gl_close.svg'"
          width="32px"
          height="32px"
          romaClickDelete
          [message]="'sales.salesCard.modalTitle'"
          [subtitle]="'sales.salesCard.modalSubtitle'"
          [buttonDelete]="'general.buttonExit'"
          (wantDelete)="close()"
        />
        <img
          *ngIf="!haveChanges"
          class="close"
          [src]="'assets/icons/gl_close.svg'"
          width="32px"
          height="32px"
          (click)="close()"
        />
      </div>

      <h2 class="sales-card-title" *ngIf="type === 'ADD'">
        {{ 'sales.salesCard.addTitle' | translate }}
      </h2>
    </div>

    <div #leftCol class="left-col">
      <ng-container *ngIf="(saleSteps$ | async)?.saleForm">
        <!-- task title -->

        <div class="col-header">
          <div class="col-title" *ngIf="!isLoading">
            <h2 class="title">
              {{ 'sales.salesCard.addSubtitle1' | translate }}
            </h2>

            <div *ngIf="(resize$ | async)?.payload?.mobile">
              <div
                class="subtitle-wrapper"
                *ngIf="!(saleStorage$ | async)?.items?.length"
              >
                <h3 class="subtitle">
                  {{ 'sales.salesCard.addSubtitle2' | translate
                  }}<obligatory-field-sign></obligatory-field-sign>
                </h3>
                <div class="tooltip-container">
                  <img
                    src="assets/icons/gl_information-fill-gray.svg"
                    (click)="showDialog()"
                    class="help-icon"
                  />
                  <div
                    *ngIf="showToolTip"
                    class="title-tooltip-wrapper"
                    (click)="toggleTooltip(false)"
                  ></div>
                  <div
                    class="help-tooltip"
                    *ngIf="showToolTip"
                    romaRomaToolTip
                    [showToolTip]="showToolTip"
                    (clicked)="toggleTooltip($event)"
                  >
                    <div>{{ 'sales.salesCard.tooltip' | translate }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="!(resize$ | async)?.payload?.mobile">
              <div class="subtitle-wrapper">
                <h3 class="subtitle">
                  {{ 'sales.salesCard.addSubtitle2' | translate
                  }}<obligatory-field-sign></obligatory-field-sign>
                </h3>
                <div class="tooltip-container">
                  <img
                    src="assets/icons/gl_information-fill-gray.svg"
                    (click)="showDialog()"
                    class="help-icon"
                  />
                  <div
                    *ngIf="showToolTip"
                    class="title-tooltip-wrapper"
                    (click)="toggleTooltip(false)"
                  ></div>
                  <div
                    class="help-tooltip"
                    *ngIf="showToolTip"
                    romaRomaToolTip
                    [showToolTip]="showToolTip"
                    (clicked)="toggleTooltip($event)"
                  >
                    <div>{{ 'sales.salesCard.tooltip' | translate }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- add item rate-->
          <ng-container class="form-group">
            <div
              romaProSelect
              class="row wrapper-pro-feature-user"
              [featureUser]="featureRefUser.Catalogue.show"
            >
              <div class="col-12">
                <mat-progress-spinner
                  *ngIf="data?.mode === 'EDIT' && isLoading"
                  class="loading-spinner"
                  mode="indeterminate"
                  [diameter]="40"
                ></mat-progress-spinner>

                <roma-rates-select-table
                  *ngIf="!isLoading"
                  [dealProducts]="(saleStorage$ | async)?.items"
                  [showTotals]="false"
                  (changeProducts)="changeProducts($event)"
                ></roma-rates-select-table>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="(saleStorage$ | async)?.items?.length">
            <hr class="dashed" />
            <!-- discount -->
            <div *ngIf="!isLoading" class="tax__row">
              <div class="__label">
                {{ 'sales.salesCard.discount' | translate }}
              </div>
              <div class="__content">
                <div class="discount-input">
                  <input
                    type="number"
                    min="0"
                    max="100"
                    [(ngModel)]="discount"
                    (change)="updateDiscount()"
                  />
                  <span class="close-icon" (click)="resetDiscount()"
                    ><img src="/assets/icons/gl_close.svg" alt="close"
                  /></span>
                  <span class="percent-icon">%</span>
                </div>
              </div>
            </div>
            <!-- tax -->
            <div *ngIf="!isLoading" class="tax__row">
              <div
                [ngClass]="{
                  __label_taxes: (saleStorage$ | async)?.taxes.length === 0
                }"
              >
                {{ 'sales.salesCard.tax' | translate }}
              </div>

              <div class="__content">
                <ng-container *ngIf="!(resize$ | async)?.payload?.mobile">
                  <roma-rm-select-v2
                    class="select-input users"
                    [placeholderText]="'sales.salesCard.tax' | translate"
                    [items]="taxesList"
                    [isLoading]="false"
                    [isAbsolute]="true"
                    [addButtonText]="'sales.salesCard.addItem'"
                    [activeItems]="selectedTaxes"
                    mode="MULTIPLE"
                    inputHeight="36px"
                    type="taxes"
                    (changeItems)="changeTaxes($event)"
                    style="width: 300px"
                  >
                  </roma-rm-select-v2>
                </ng-container>

                <ng-container *ngIf="(resize$ | async)?.payload?.mobile">
                  <roma-mobile-modal-list
                    [titleText]="'sales.salesCard.tax' | translate"
                    [isOpen]="showTaxesMobileModalList"
                    [items]="taxesList"
                    [isLoading]="false"
                    [selectedItems]="selectedTaxes"
                    (changeItems)="changeTaxes($event)"
                    (onClose)="showTaxesMobileModalList = false"
                  ></roma-mobile-modal-list>

                  <button
                    type="button"
                    class="add-field-btn"
                    (click)="showTaxesMobileModalList = true"
                  >
                    {{ 'general.add2' | translate }}
                    <img
                      src="assets/icons/gl_plus.svg"
                      style="margin-top: -5px"
                    />
                  </button>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
        <hr />
        <div class="col-body">
          <!--id final -->
          <ng-container
            *ngIf="(idActiveModules$ | async).includes(idModuleRef.Finals)"
            class="form-group"
          >
            <mat-progress-spinner
              *ngIf="data?.mode === 'EDIT' && isLoading"
              class="loading-spinner"
              mode="indeterminate"
              [diameter]="40"
            ></mat-progress-spinner>

            <div class="row" *ngIf="!isLoading">
              <div class="col-4">
                <label class="field-label">
                  <img src="assets/icons/gl_contacto.svg" />
                  {{ 'activity.create.form.idFinal' | translate | uppercase }}
                </label>
              </div>
              <div class="col-7">
                <div style="display: inline-flex">
                  <ng-container *ngIf="!(resize$ | async)?.payload?.mobile">
                    <div
                      romaProSelect
                      class="wrapper-pro-feature-user"
                      [featureUser]="featureRefUser.Final.show"
                    >
                      <roma-rm-select-v2
                        class="select-input users"
                        [placeholderText]="
                          'activity.create.form.idFinal' | translate
                        "
                        [items]="finalSelectItem"
                        [isLoading]="false"
                        [isAbsolute]="true"
                        [activeItems]="selectedFinal"
                        [addButtonText]="'general.add2'"
                        [addButtonFeatureUser]="featureRefUser.Final.create"
                        mode="SINGLE"
                        type="users"
                        (changeItems)="changeFinalSelect($event)"
                        (click)="onClickFinal()"
                        (addElement)="addFinals()"
                        inputHeight="36px"
                        style="width: 200px"
                      >
                      </roma-rm-select-v2>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="(resize$ | async)?.payload?.mobile">
                    <button
                      *ngIf="!selectedFinal.length"
                      type="button"
                      class="add-field-btn"
                      romaProFeature
                      [featureUser]="featureRefUser.Final.create"
                      (clicked)="
                        onClickFinal(); showFinalMobileModalList = true
                      "
                    >
                      {{ 'general.add2' | translate }}
                      <img
                        src="assets/icons/gl_plus.svg"
                        style="margin-top: -5px"
                      />
                    </button>

                    <div
                      romaProSelect
                      class="wrapper-pro-feature-user"
                      [featureUser]="featureRefUser.Final.show"
                    >
                      <roma-mobile-modal-list
                        [titleText]="'activity.create.form.idFinal' | translate"
                        [isOpen]="showFinalMobileModalList"
                        [items]="finalSelectItem"
                        [selectedItems]="selectedFinal"
                        [isLoading]="finalsLoading"
                        [addButtonText]="'general.addFinal'"
                        [showAddButton]="true"
                        (addElement)="addFinals()"
                        (changeItems)="changeFinalSelect($event)"
                        (onClose)="showFinalMobileModalList = false"
                      ></roma-mobile-modal-list>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <hr />
        <div class="col-footer">
          <mat-progress-spinner
            *ngIf="data?.mode === 'EDIT' && isLoading"
            class="loading-spinner"
            mode="indeterminate"
            [diameter]="40"
          ></mat-progress-spinner>

          <button
            *ngIf="!dealLinked && !isLoading"
            type="button"
            class="square-button done-button"
            romaProFeature
            [featureUser]="featureRefUser.Deal.show"
            (clicked)="openSaleDealModal()"
          >
            <img src="assets/icons/gl_features-gray.svg" />
            {{ 'sales.salesCard.createServiceSale' | translate }}
          </button>
          <ng-container *ngIf="dealLinked && !isLoading">
            <h3 class="subtitle">
              {{ 'sales.salesCard.linkedService' | translate }}
            </h3>
            <div class="deal__card__container">
              <div class="deal__info__card">
                <div class="deal__info__card__name">
                  {{ dealLinked?.name | translate }}
                </div>
                <div class="deal__info__card__final__name">
                  {{ dealLinked?.final?.name }}
                </div>
                <div class="deal__info__card__date">
                  <ng-container *ngIf="dealLinked?.initDate">
                    <img src="/assets/icons/gl_calendar-input.svg" />
                    {{
                      dealLinked?.initDate
                        ? (dealLinked?.initDate | date: 'd MMM':'':locale)
                        : '-'
                    }}
                    <ng-container *ngIf="!isEmptyHour(dealLinked?.initDate)">
                      <img
                        src="/assets/icons/gl_clock-outline-gray.svg"
                        style="margin-left: 8px"
                      />
                      {{
                        dealLinked?.initDate
                          ? (dealLinked?.initDate | date: 'HH:mm')
                          : '-'
                      }}
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              <img
                class="clean-input"
                src="assets/icons/gl_close-input.svg"
                (click)="removeSaleDeal()"
              />
            </div>
            <div class="deal__card__info">
              <img src="/assets/icons/gl_information-fill-gray.svg" />
              {{ 'sales.salesCard.dealCardInfo' | translate }}
            </div>
          </ng-container>
        </div>
      </ng-container>
      <!-- Payment-methods -->
      <!-- select payment methods -->
      <ng-container *ngIf="(saleSteps$ | async)?.paymentMethods">
        <h3 class="subtitle" style="margin-bottom: 24px">
          <img
            *ngIf="
              !(saleSteps$ | async)?.paymentRefund &&
              type !== 'EDIT' &&
              !selectedPaymentMethod
            "
            class="arrow"
            [src]="'assets/icons/gl_arrow-full-left.svg'"
            (click)="stepActive(['saleForm'])"
          />
          <img
            *ngIf="(saleSteps$ | async)?.paymentRefund"
            class="arrow"
            [src]="'assets/icons/gl_arrow-full-left.svg'"
            (click)="stepActive(['paymentFinish'])"
          />
          <ng-container
            *ngIf="
              (saleSteps$ | async)?.paymentMethods &&
              !(saleSteps$ | async)?.paymentRefund
            "
            >{{ 'sales.salesCard.paymentMethod' | translate }}</ng-container
          >
          <ng-container *ngIf="(saleSteps$ | async)?.paymentRefund">{{
            'sales.salesCard.refundTitle' | translate
          }}</ng-container>
        </h3>
        <div class="payment__methods__wrapper">
          <div
            class="__method__box"
            [ngClass]="{
              is__active: selectedPaymentMethod === salePaymentMethodRef.Cash
            }"
          >
            <div
              class="click__event_wrapper"
              (click)="
                onChangePaymentMethod(
                  {
                    checked:
                      selectedPaymentMethod === salePaymentMethodRef.Cash
                        ? false
                        : true
                  },
                  salePaymentMethodRef.Cash
                )
              "
            ></div>
            <img [src]="'assets/icons/payment_method-cash.svg'" />
            <h4>{{ 'sales.salesCard.cash' | translate }}</h4>
            <mat-checkbox
              [checked]="
                selectedPaymentMethod === salePaymentMethodRef.Cash
                  ? true
                  : false
              "
            ></mat-checkbox>
          </div>

          <div
            class="__method__box"
            [ngClass]="{
              is__active: selectedPaymentMethod === salePaymentMethodRef.Card
            }"
          >
            <div
              class="click__event_wrapper"
              (click)="
                onChangePaymentMethod(
                  {
                    checked:
                      selectedPaymentMethod === salePaymentMethodRef.Card
                        ? false
                        : true
                  },
                  salePaymentMethodRef.Card
                )
              "
            ></div>
            <img [src]="'assets/icons/payment_method-card.svg'" />
            <h4>{{ 'sales.salesCard.card' | translate }}</h4>
            <mat-checkbox
              [checked]="
                selectedPaymentMethod === salePaymentMethodRef.Card
                  ? true
                  : false
              "
            ></mat-checkbox>
          </div>

          <!-- Card payments but with logic for disabled if stripe is not active -->
          <!-- <div
            class="__method__box"
            [ngClass]="{
              is__active: selectedPaymentMethod === salePaymentMethodRef.Card,
              is__disabled: !stripeAccountIsApproved(),
              'opacity-60': opacity60
            }"
          >
            <div
              class="click__event_wrapper"
              (click)="
                onChangePaymentMethod(
                  {
                    checked:
                      selectedPaymentMethod === salePaymentMethodRef.Card
                        ? false
                        : stripeAccountIsApproved()
                        ? true
                        : false
                  },
                  salePaymentMethodRef.Card
                )
              "
            >
              <div
                *ngIf="
                  !stripeAccountIsApproved() &&
                  showPaymentLinkTooltip() &&
                  !(resize$ | async)?.payload?.mobile
                "
                class="tooltip-disabled-container"
              >
                <div class="help-tooltip" romaRomaToolTip [showToolTip]="true">
                  <div>{{ 'payments.disabled' | translate }}</div>
                </div>
              </div>
            </div>
            <img [src]="'assets/icons/payment_method-card.svg'" />
            <h4>{{ 'sales.salesCard.card' | translate }}</h4>
            <mat-checkbox
              [checked]="
                selectedPaymentMethod === salePaymentMethodRef.Card ? true : false
              "
            ></mat-checkbox>
          </div> -->

          <div
            class="__method__box"
            [ngClass]="{
              is__active: selectedPaymentMethod === salePaymentMethodRef.Others
            }"
          >
            <div
              class="click__event_wrapper"
              (click)="
                onChangePaymentMethod(
                  {
                    checked:
                      selectedPaymentMethod === salePaymentMethodRef.Others
                        ? false
                        : true
                  },
                  salePaymentMethodRef.Others
                )
              "
            ></div>
            <img [src]="'assets/icons/payment_method-others.svg'" />
            <h4>{{ 'sales.salesCard.others' | translate }}</h4>
            <mat-checkbox
              [checked]="
                selectedPaymentMethod === salePaymentMethodRef.Others
                  ? true
                  : false
              "
            ></mat-checkbox>
          </div>
          <div
            class="__method__box custom-tooltip top"
            [ngClass]="{
              is__active:
                selectedPaymentMethod === salePaymentMethodRef.PaymentLink,
              is__disabled: !stripeAccountIsApproved(),
              'opacity-60': opacity60
            }"
            *ngIf="isCountryValid"
          >
            <div class="tiptext" [hidden]="stripeAccountIsApproved()">
              {{ 'payments.methodDisabledTooltip' | translate }}
            </div>
            <div
              class="click__event_wrapper"
              (click)="
                onChangePaymentMethod(
                  {
                    checked:
                      selectedPaymentMethod === salePaymentMethodRef.PaymentLink
                        ? false
                        : stripeAccountIsApproved()
                        ? true
                        : false
                  },
                  salePaymentMethodRef.PaymentLink
                )
              "
            >
              <div
                *ngIf="
                  !stripeAccountIsApproved() &&
                  showPaymentLinkTooltip() &&
                  !(resize$ | async)?.payload?.mobile
                "
                class="tooltip-disabled-container"
              >
                <div class="help-tooltip" romaRomaToolTip [showToolTip]="true">
                  <div>{{ 'payments.disabled' | translate }}</div>
                </div>
              </div>
            </div>
            <img [src]="'assets/icons/payment_method-link.svg'" />
            <h4>{{ 'sales.salesCard.paymentLink' | translate }}</h4>
            <mat-checkbox
              [checked]="
                selectedPaymentMethod === salePaymentMethodRef.PaymentLink
                  ? true
                  : false
              "
            ></mat-checkbox>
          </div>

          <!-- Payments by TRANSFER -->
          <!-- <div
            class="__method__box"
            [ngClass]="{
              is__active: selectedPaymentMethod === salePaymentMethodRef.Transfer,
              is__disabled: !stripeAccountIsApproved(),
              'opacity-60': opacity60
            }"
          >
            <div
              class="click__event_wrapper"
              (click)="
                onChangePaymentMethod(
                  {
                    checked:
                      selectedPaymentMethod === salePaymentMethodRef.Transfer
                        ? false
                        : stripeAccountIsApproved()
                        ? true
                        : false
                  },
                  salePaymentMethodRef.Transfer
                )
              "
            >
              <div
                *ngIf="
                  !stripeAccountIsApproved() &&
                  showPaymentLinkTooltip() &&
                  !(resize$ | async)?.payload?.mobile
                "
                class="tooltip-disabled-container"
              >
                <div class="help-tooltip" romaRomaToolTip [showToolTip]="true">
                  <div>{{ 'payments.disabled' | translate }}</div>
                </div>
              </div>
            </div>
            <img [src]="'assets/icons/payment_method-link.svg'" />
            <h4>Pago por transferencia</h4>
            <mat-checkbox
              [checked]="
                selectedPaymentMethod === salePaymentMethodRef.Transfer
                  ? true
                  : false
              "
            ></mat-checkbox>
          </div> -->
        </div>
        <!-- Banner -->

        <roma-shared-stripe-snackbar
          [inPaymentMethods]="true"
        ></roma-shared-stripe-snackbar>

        <roma-enable-payments-banner
          style="margin-bottom: 20px"
          (close)="close()"
        ></roma-enable-payments-banner>
      </ng-container>
      <!-- Payment finish -->
      <ng-container *ngIf="(saleSteps$ | async)?.paymentFinish">
        <div class="payment__feedback__message text-center">
          <img [src]="'assets/icons/gl_venta-completada.svg'" />
          <h3 class="subtitle">
            {{ 'sales.salesCard.saleCompleted' | translate }}
          </h3>
          <ng-container *ngIf="!(resize$ | async)?.payload?.mobile">
            <div
              class="actions__menu__wrapper"
              #menuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="menu"
              [ngClass]="{ is__open: menuTrigger.menuOpen === true }"
            >
              {{ 'general.actions' | translate }}
              <img [src]="'assets/icons/gl_arrow_down.svg'" />
            </div>
            <mat-menu
              class="custom-template-actions actions-menu custom-sales-actions"
              #menu="matMenu"
              xPosition="after"
            >
              <button
                romaProFeature
                [feature]="featureRef.Sale.SendBudgetEmail"
                mat-menu-item
                (clicked)="sendSaleEmail()"
              >
                <img [src]="'assets/icons/gl_mail-line-gray.svg'" />
                {{ 'deal_part_of_service.sendByEmail' | translate }}
                <div class="icon-star-pro-feature centeredImage">
                  <div class="roma-label-2 warning right-center">
                    <div class="icon"><img src="assets/icons/star.svg" /></div>
                    PRO
                  </div>
                </div>
              </button>

              <button mat-menu-item (click)="download()">
                <img [src]="'assets/icons/gl_download-gray.svg'" />
                {{ 'general.downloadExcel' | translate }}
              </button>

              <button
                mat-menu-item
                (click)="printSaleAction()"
                class="printing-option"
              >
                <img [src]="'assets/icons/gl_print.svg'" />
                {{ 'general.print' | translate }}
                <mat-progress-spinner
                  *ngIf="isPrinting"
                  class="loading-spinner"
                  mode="indeterminate"
                  [diameter]="16"
                ></mat-progress-spinner>
              </button>

              <!-- hidden refund for releas 2.15 -->
              <!-- <button
                *ngIf="!refundedSale"
                mat-menu-item
                (click)="activeRefund()"
              >
                <img [src]="'assets/icons/gl_recurring-gray.svg'" />
                {{ 'sales.salesCard.refund' | translate }}
              </button> -->
              <button
                class="delete"
                mat-menu-item
                romaProFeature
                [featureUser]="featureRefUser.Sale.create"
                (clicked)="openCancelSaleModal()"
              >
                <img [src]="'assets/icons/gl_error-outline.svg'" />
                {{ 'sales.salesCard.cancelSale' | translate }}
              </button>
            </mat-menu>
          </ng-container>
          <ng-container *ngIf="(resize$ | async)?.payload?.mobile">
            <div
              class="actions__menu__wrapper"
              (click)="showMobileActions = true"
              [ngClass]="{ is__open: showMobileActions === true }"
            >
              {{ 'general.actions' | translate }}
              <img [src]="'assets/icons/gl_arrow_down.svg'" />
            </div>

            <div
              *ngIf="showMobileActions"
              class="mobile-viewtype-wrapper actions-mobile-modal"
            >
              <div class="viewtype-list">
                <h4>{{ 'general.actions' | translate }}</h4>
                <div class="link-list" *ngIf="!isLoading">
                  <div
                    class="list-item"
                    romaProFeature
                    [feature]="featureRef.Sale.SendBudgetEmail"
                    (clicked)="sendSaleEmail()"
                  >
                    <img [src]="'assets/icons/gl_mail-line-gray.svg'" />
                    {{ 'deal_part_of_service.sendByEmail' | translate }}
                    <div class="icon-star-pro-feature centeredImage">
                      <div class="roma-label-2 warning right-center">
                        <div class="icon">
                          <img src="assets/icons/star.svg" />
                        </div>
                        PRO
                      </div>
                    </div>
                  </div>
                  <div class="list-item" (click)="download()">
                    <img [src]="'assets/icons/gl_download-gray.svg'" />
                    {{ 'general.downloadExcel' | translate }}
                  </div>

                  <!-- hidden refund for releas 2.15 -->
                  <!-- <div
                    class="list-item"
                    *ngIf="!refundedSale"
                    (click)="activeRefund()"
                  >
                    <img [src]="'assets/icons/gl_recurring-gray.svg'" />
                    {{ 'sales.salesCard.refund' | translate }}
                  </div> -->
                  <div class="list-item delete" (click)="openCancelSaleModal()">
                    <img [src]="'assets/icons/gl_error-outline.svg'" />
                    {{ 'sales.salesCard.cancelSale' | translate }}
                  </div>
                </div>
                <div class="text-center close-btn-wrapper">
                  <img
                    (click)="showMobileActions = false"
                    class="close-btn"
                    src="/assets/icons/btn_close.svg"
                    alt="close"
                  />
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- Sale unpaid or paymentPending -->
      <ng-container
        *ngIf="
          (saleSteps$ | async)?.paymentUnpaid ||
          (saleSteps$ | async)?.paymentPending
        "
      >
        <div class="payment__feedback__message text-center">
          <img [src]="'assets/icons/gl_saveAsUnpaid.svg'" />

          <ng-container
            *ngIf="
              stripe_error &&
              selectedPaymentMethod === salePaymentMethodRef.Card
            "
          >
            <h3 class="subtitle">
              {{ 'sales.salesCard.paymentError' | translate }}
            </h3>

            <roma-sale-error-warning></roma-sale-error-warning>

            <button
              class="roma-button add-button-3"
              style="margin-top: 12px; max-width: 240px"
              (click)="tryAgain()"
            >
              {{ 'sales.salesCard.tryAgain' | translate }}
            </button>
          </ng-container>

          <ng-container
            *ngIf="
              !stripe_error &&
              selectedPaymentMethod !== salePaymentMethodRef.Card
            "
          >
            <h3 *ngIf="(saleSteps$ | async)?.paymentUnpaid" class="subtitle">
              {{ 'sales.salesCard.unpaidSale' | translate }}
            </h3>
            <h3 *ngIf="(saleSteps$ | async)?.paymentPending" class="subtitle">
              {{ 'status.paymentPending' | translate }}
            </h3>
            <button
              *ngIf="!showPaymentLinkFunctionality() && !isLoading"
              class="roma-button add-button-3"
              style="margin-top: 12px; max-width: 240px"
              (click)="payNow(); scrollToTop(modalContainer)"
            >
              {{ 'sales.salesCard.payNow' | translate }}
            </button>
          </ng-container>

          <ng-container *ngIf="!(resize$ | async)?.payload?.mobile">
            <div
              class="actions__menu__wrapper"
              #menuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="menu"
              [ngClass]="{ is__open: menuTrigger.menuOpen === true }"
              style="margin-top: 24px"
            >
              {{ 'general.actions' | translate }}
              <img [src]="'assets/icons/gl_arrow_down.svg'" />
            </div>
            <mat-menu
              class="custom-template-actions actions-menu custom-sales-actions"
              #menu="matMenu"
              xPosition="after"
            >
              <!-- Share payment link -->
              <button
                *ngIf="showPaymentLinkFunctionality()"
                mat-menu-item
                (click)="openShareLink()"
              >
                <img [src]="'assets/icons/gl_link-black.svg'" />
                {{ 'general.shareLink' | translate }}
              </button>
              <!-- Share payment link -->

              <button
                romaProFeature
                [feature]="featureRef.Sale.SendBudgetEmail"
                mat-menu-item
                (clicked)="sendSaleEmail()"
              >
                <img [src]="'assets/icons/gl_mail-line-gray.svg'" />
                {{ 'deal_part_of_service.sendByEmail' | translate }}
                <div class="icon-star-pro-feature centeredImage">
                  <div class="roma-label-2 warning right-center">
                    <div class="icon">
                      <img src="assets/icons/star.svg" />
                    </div>
                    PRO
                  </div>
                </div>
              </button>

              <button mat-menu-item (click)="download()">
                <img [src]="'assets/icons/gl_download-gray.svg'" />
                {{ 'general.downloadExcel' | translate }}
              </button>

              <button
                mat-menu-item
                (click)="printSaleAction()"
                class="printing-option"
              >
                <img [src]="'assets/icons/gl_print.svg'" />
                {{ 'general.print' | translate }}
                <mat-progress-spinner
                  *ngIf="isPrinting"
                  class="loading-spinner"
                  mode="indeterminate"
                  [diameter]="16"
                ></mat-progress-spinner>
              </button>

              <button
                class="delete"
                mat-menu-item
                (click)="openCancelSaleModal()"
              >
                <img [src]="'assets/icons/gl_error-outline.svg'" />
                {{ 'sales.salesCard.cancelSale' | translate }}
              </button>
            </mat-menu>
          </ng-container>

          <ng-container *ngIf="(resize$ | async)?.payload?.mobile">
            <div
              class="actions__menu__wrapper"
              (click)="showMobileActions = true"
              [ngClass]="{ is__open: showMobileActions === true }"
            >
              {{ 'general.actions' | translate }}
              <img [src]="'assets/icons/gl_arrow_down.svg'" />
            </div>

            <div
              *ngIf="showMobileActions"
              class="mobile-viewtype-wrapper actions-mobile-modal"
            >
              <div class="viewtype-list">
                <h4>{{ 'general.actions' | translate }}</h4>
                <div class="link-list" *ngIf="!isLoading">
                  <div
                    class="list-item"
                    *ngIf="showPaymentLinkFunctionality()"
                    (click)="openShareLink()"
                  >
                    <img [src]="'assets/icons/gl_link-black.svg'" />
                    {{ 'general.shareLink' | translate }}
                  </div>
                  <div
                    class="list-item"
                    romaProFeature
                    [feature]="featureRef.Sale.SendBudgetEmail"
                    (clicked)="sendSaleEmail()"
                  >
                    <img [src]="'assets/icons/gl_mail-line-gray.svg'" />
                    {{ 'deal_part_of_service.sendByEmail' | translate }}
                    <div class="icon-star-pro-feature centeredImage">
                      <div class="roma-label-2 warning right-center">
                        <div class="icon">
                          <img src="assets/icons/star.svg" />
                        </div>
                        PRO
                      </div>
                    </div>
                  </div>
                  <div class="list-item" (click)="download()">
                    <img [src]="'assets/icons/gl_download-gray.svg'" />
                    {{ 'general.downloadExcel' | translate }}
                  </div>
                  <div class="list-item delete" (click)="openCancelSaleModal()">
                    <img [src]="'assets/icons/gl_error-outline.svg'" />
                    {{ 'sales.salesCard.cancelSale' | translate }}
                  </div>
                </div>
                <div class="text-center close-btn-wrapper">
                  <img
                    (click)="showMobileActions = false"
                    class="close-btn"
                    src="/assets/icons/btn_close.svg"
                    alt="close"
                  />
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <!-- Sale Refund finish -->
      <ng-container *ngIf="(saleSteps$ | async)?.paymentRefundFinish">
        <div class="payment__feedback__message text-center">
          <img [src]="'assets/icons/gl_refund-finish.svg'" />
          <h3 class="subtitle">
            {{ 'sales.salesCard.refundedSale' | translate }}
          </h3>

          <ng-container *ngIf="!(resize$ | async)?.payload?.mobile">
            <div
              class="actions__menu__wrapper"
              #menuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="menu"
              [ngClass]="{ is__open: menuTrigger.menuOpen === true }"
            >
              {{ 'general.actions' | translate }}
              <img [src]="'assets/icons/gl_arrow_down.svg'" />
            </div>
            <mat-menu
              class="custom-template-actions actions-menu custom-sales-actions"
              #menu="matMenu"
              xPosition="after"
            >
              <button
                romaProFeature
                [feature]="featureRef.Sale.SendBudgetEmail"
                mat-menu-item
                (clicked)="sendSaleEmail()"
              >
                <img [src]="'assets/icons/gl_mail-line-gray.svg'" />
                {{ 'deal_part_of_service.sendByEmail' | translate }}
                <div class="icon-star-pro-feature centeredImage">
                  <div class="roma-label-2 warning right-center">
                    <div class="icon"><img src="assets/icons/star.svg" /></div>
                    PRO
                  </div>
                </div>
              </button>

              <button mat-menu-item (click)="download()">
                <img [src]="'assets/icons/gl_download-gray.svg'" />
                {{ 'general.downloadExcel' | translate }}
              </button>
              <button
                class="delete"
                mat-menu-item
                (click)="openCancelSaleModal()"
              >
                <img [src]="'assets/icons/gl_error-outline.svg'" />
                {{ 'sales.salesCard.cancelSale' | translate }}
              </button>
            </mat-menu>
          </ng-container>

          <ng-container *ngIf="(resize$ | async)?.payload?.mobile">
            <div
              class="actions__menu__wrapper"
              (click)="showMobileActions = true"
              [ngClass]="{ is__open: showMobileActions === true }"
            >
              {{ 'general.actions' | translate }}
              <img [src]="'assets/icons/gl_arrow_down.svg'" />
            </div>

            <div
              *ngIf="showMobileActions"
              class="mobile-viewtype-wrapper actions-mobile-modal"
            >
              <div class="viewtype-list">
                <h4>{{ 'general.actions' | translate }}</h4>
                <div class="link-list" *ngIf="!isLoading">
                  <div
                    class="list-item"
                    romaProFeature
                    [feature]="featureRef.Sale.SendBudgetEmail"
                    (clicked)="sendSaleEmail()"
                  >
                    <img [src]="'assets/icons/gl_mail-line-gray.svg'" />
                    {{ 'deal_part_of_service.sendByEmail' | translate }}
                    <div class="icon-star-pro-feature centeredImage">
                      <div class="roma-label-2 warning right-center">
                        <div class="icon">
                          <img src="assets/icons/star.svg" />
                        </div>
                        PRO
                      </div>
                    </div>
                  </div>
                  <div class="list-item" (click)="download()">
                    <img [src]="'assets/icons/gl_download-gray.svg'" />
                    {{ 'general.downloadExcel' | translate }}
                  </div>
                  <div class="list-item delete" (click)="openCancelSaleModal()">
                    <img [src]="'assets/icons/gl_error-outline.svg'" />
                    {{ 'sales.salesCard.cancelSale' | translate }}
                  </div>
                </div>
                <div class="text-center close-btn-wrapper">
                  <img
                    (click)="showMobileActions = false"
                    class="close-btn"
                    src="/assets/icons/btn_close.svg"
                    alt="close"
                  />
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <!-- Sale cancelled finish or paymentRejected -->
      <ng-container
        *ngIf="
          (saleSteps$ | async)?.paymentCancelFinish ||
          (saleSteps$ | async)?.paymentRejected
        "
      >
        <div class="payment__feedback__message text-center">
          <img [src]="'assets/icons/gl_refund-finish.svg'" />
          <h3
            class="subtitle"
            *ngIf="(saleSteps$ | async)?.paymentCancelFinish"
          >
            {{ 'sales.salesCard.cancelledSale' | translate }}
          </h3>
          <h3 class="subtitle" *ngIf="(saleSteps$ | async)?.paymentRejected">
            {{ 'status.paymentRejected' | translate }}
          </h3>
          <div
            class="payment__feedback__message__detail"
            *ngIf="(saleSteps$ | async)?.paymentCancelFinish"
          >
            {{ 'status.cancelled' | translate }}:
            {{
              (saleStorage$ | async)?.sale?.cancelDate
                | date: 'd MMM YYYY - HH:mm':currentLang
            }}
            <strong>
              {{ (saleStorage$ | async)?.sale?.cancelReason }}
            </strong>
          </div>

          <ng-container *ngIf="!(resize$ | async)?.payload?.mobile">
            <div
              class="actions__menu__wrapper"
              #menuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="menu"
              [ngClass]="{ is__open: menuTrigger.menuOpen === true }"
            >
              {{ 'general.actions' | translate }}
              <img [src]="'assets/icons/gl_arrow_down.svg'" />
            </div>
            <mat-menu
              class="custom-template-actions actions-menu custom-sales-actions"
              #menu="matMenu"
              xPosition="after"
            >
              <ng-container *ngIf="(saleSteps$ | async)?.paymentRejected">
                <!-- Share payment link -->
                <button
                  *ngIf="showPaymentLinkFunctionality()"
                  mat-menu-item
                  (click)="openShareLink()"
                >
                  <img [src]="'assets/icons/gl_link-black.svg'" />
                  {{ 'general.shareLink' | translate }}
                </button>
                <!-- Share payment link -->

                <button
                  romaProFeature
                  [feature]="featureRef.Sale.SendBudgetEmail"
                  mat-menu-item
                  (clicked)="sendSaleEmail()"
                >
                  <img [src]="'assets/icons/gl_mail-line-gray.svg'" />
                  {{ 'deal_part_of_service.sendByEmail' | translate }}
                  <div class="icon-star-pro-feature centeredImage">
                    <div class="roma-label-2 warning right-center">
                      <div class="icon">
                        <img src="assets/icons/star.svg" />
                      </div>
                      PRO
                    </div>
                  </div>
                </button>

                <button mat-menu-item (click)="download()">
                  <img [src]="'assets/icons/gl_download-gray.svg'" />
                  {{ 'general.downloadExcel' | translate }}
                </button>

                <button mat-menu-item (click)="printSaleAction()">
                  <img [src]="'assets/icons/gl_print.svg'" />
                  {{ 'general.print' | translate }}
                  <mat-progress-spinner
                    *ngIf="isPrinting"
                    class="loading-spinner"
                    mode="indeterminate"
                    [diameter]="16"
                  ></mat-progress-spinner>
                </button>

                <button
                  class="delete"
                  mat-menu-item
                  (click)="openCancelSaleModal()"
                >
                  <img [src]="'assets/icons/gl_error-outline.svg'" />
                  {{ 'sales.salesCard.cancelSale' | translate }}
                </button>
              </ng-container>
              <ng-container *ngIf="(saleSteps$ | async)?.paymentCancelFinish">
                <button mat-menu-item (click)="download()">
                  <img [src]="'assets/icons/gl_download-gray.svg'" />
                  {{ 'general.downloadExcel' | translate }}
                </button>
                <button mat-menu-item (click)="printSaleAction()">
                  <img [src]="'assets/icons/gl_print.svg'" />
                  {{ 'general.print' | translate }}
                  <mat-progress-spinner
                    *ngIf="isPrinting"
                    class="loading-spinner"
                    mode="indeterminate"
                    [diameter]="16"
                  ></mat-progress-spinner>
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>

          <ng-container *ngIf="(resize$ | async)?.payload?.mobile">
            <div
              class="actions__menu__wrapper"
              (click)="showMobileActions = true"
              [ngClass]="{ is__open: showMobileActions === true }"
            >
              {{ 'general.actions' | translate }}
              <img [src]="'assets/icons/gl_arrow_down.svg'" />
            </div>

            <div
              *ngIf="showMobileActions"
              class="mobile-viewtype-wrapper actions-mobile-modal"
            >
              <div class="viewtype-list">
                <h4>{{ 'general.actions' | translate }}</h4>
                <div class="link-list" *ngIf="!isLoading">
                  <ng-container *ngIf="(saleSteps$ | async)?.paymentRejected">
                    <!-- Share payment link -->
                    <div
                      *ngIf="showPaymentLinkFunctionality()"
                      class="list-item"
                      (click)="openShareLink()"
                    >
                      <img [src]="'assets/icons/gl_link-black.svg'" />
                      {{ 'general.shareLink' | translate }}
                    </div>
                    <!-- Share payment link -->

                    <div
                      romaProFeature
                      [feature]="featureRef.Sale.SendBudgetEmail"
                      class="list-item"
                      (clicked)="sendSaleEmail()"
                    >
                      <img [src]="'assets/icons/gl_mail-line-gray.svg'" />
                      {{ 'deal_part_of_service.sendByEmail' | translate }}
                      <div class="icon-star-pro-feature centeredImage">
                        <div class="roma-label-2 warning right-center">
                          <div class="icon">
                            <img src="assets/icons/star.svg" />
                          </div>
                          PRO
                        </div>
                      </div>
                    </div>

                    <div class="list-item" (click)="download()">
                      <img [src]="'assets/icons/gl_download-gray.svg'" />
                      {{ 'general.downloadExcel' | translate }}
                    </div>

                    <div
                      class="list-item delete"
                      (click)="openCancelSaleModal()"
                    >
                      <img [src]="'assets/icons/gl_error-outline.svg'" />
                      {{ 'sales.salesCard.cancelSale' | translate }}
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="(saleSteps$ | async)?.paymentCancelFinish"
                  >
                    <div class="list-item" (click)="download()">
                      <img [src]="'assets/icons/gl_download-gray.svg'" />
                      {{ 'general.downloadExcel' | translate }}
                    </div>
                  </ng-container>
                </div>
                <div class="text-center close-btn-wrapper">
                  <img
                    (click)="showMobileActions = false"
                    class="close-btn"
                    src="/assets/icons/btn_close.svg"
                    alt="close"
                  />
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-container>
        <div
          class="row left-col-mobile-actions"
          *ngIf="(resize$ | async)?.payload?.mobile"
        >
          <div class="col-12">
            <hr />
            <div
              *ngIf="
                (saleStorage$ | async)?.items?.length &&
                !(saleSteps$ | async)?.paymentFinish &&
                !(saleSteps$ | async)?.paymentUnpaid &&
                !(saleSteps$ | async)?.paymentPending &&
                !(saleSteps$ | async)?.paymentCancelFinish &&
                !(saleSteps$ | async)?.paymentRejected &&
                !(saleSteps$ | async)?.paymentRefundFinish
              "
              class="wrapper__amounts payment__total__amount"
            >
              <div class="wrapper__amounts__left">
                <h2>Total</h2>
              </div>

              <div class="wrapper__amounts__right">
                <h2 *ngIf="(saleStorage$ | async)?.total">
                  {{
                    amountCheckRefund$((saleStorage$ | async)?.total)
                      | async
                      | currencyFormat
                  }}
                </h2>
              </div>
            </div>

            <button
              *ngIf="(saleSteps$ | async)?.saleForm && !isLoading"
              [disabled]="
                !(saleStorage$ | async)?.items?.length ||
                (saleStorage$ | async)?.total === 0
              "
              class="roma-button add-button-3"
              (click)="submitSaleForm(); scrollToTop(modalContainer)"
            >
              {{ 'sales.salesCard.paymentContinue' | translate }}
            </button>
            <button
              *ngIf="
                (saleSteps$ | async)?.paymentMethods &&
                !(saleSteps$ | async)?.paymentRefund
              "
              [disabled]="
                selectedPaymentMethod === salePaymentMethodRef.None || isLoading
              "
              class="roma-button add-button-3"
              (click)="generatePayment()"
            >
              {{ shareLinkTitle | translate }}
            </button>
            <button
              *ngIf="(saleSteps$ | async)?.paymentRefund"
              [disabled]="
                selectedPaymentMethod === salePaymentMethodRef.None ||
                isLoading ||
                refundStart
              "
              class="roma-button add-button-3 danger-button"
              (click)="finishRefund()"
            >
              <mat-progress-spinner
                *ngIf="refundStart"
                class="loading-spinner"
                mode="indeterminate"
                [diameter]="20"
              ></mat-progress-spinner>
              {{ 'sales.salesCard.refund' | translate }}
            </button>
            <button
              *ngIf="
                (saleSteps$ | async)?.paymentMethods &&
                selectedPaymentMethod === salePaymentMethodRef.None &&
                type !== 'EDIT'
              "
              class="roma-button alt-button"
              (click)="saveUnPaid()"
            >
              {{ 'sales.salesCard.saveAsUnpaid' | translate }}
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!isLoading">
        <roma-sale-preview-card
          *ngIf="
            ((saleSteps$ | async)?.paymentFinish ||
              (saleSteps$ | async)?.paymentUnpaid ||
              (saleSteps$ | async)?.paymentPending ||
              (saleSteps$ | async)?.paymentCancelFinish ||
              (saleSteps$ | async)?.paymentRejected ||
              (saleSteps$ | async)?.paymentRefundFinish) &&
            (resize$ | async)?.payload?.mobile
          "
          [type]="type"
          [salePaymentMethodRef]="salePaymentMethodRef"
          [selectedPaymentMethod]="selectedPaymentMethod"
          [refundedSale]="refundedSale"
          [refundText]="refundText"
          [refundStart]="refundStart"
          [isLoading]="isLoading"
        ></roma-sale-preview-card>
      </ng-container>
    </div>

    <!--/ left column -->

    <!-- right column -->
    <ng-container *ngIf="!(resize$ | async)?.payload?.mobile">
      <div
        class="right-col"
        [ngClass]="{
          finish__col:
            (saleSteps$ | async)?.paymentRefundFinish ||
            (saleSteps$ | async)?.paymentFinish ||
            (saleSteps$ | async)?.paymentUnpaid ||
            (saleSteps$ | async)?.paymentPending ||
            (saleSteps$ | async)?.paymentCancelFinish ||
            (saleSteps$ | async)?.paymentRejected
        }"
      >
        <mat-progress-spinner
          *ngIf="isLoading"
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="40"
        ></mat-progress-spinner>

        <ng-container *ngIf="!isLoading">
          <roma-sale-preview-card
            *ngIf="!(resize$ | async)?.payload?.mobile"
            [type]="type"
            [salePaymentMethodRef]="salePaymentMethodRef"
            [selectedPaymentMethod]="selectedPaymentMethod"
            [refundedSale]="refundedSale"
            [refundText]="refundText"
            [refundStart]="refundStart"
            [isLoading]="isLoading"
            (submitSaleForm)="submitSaleForm()"
            (finishRefund)="finishRefund()"
            (generatePayment)="generatePayment()"
            (saveUnPaid)="saveUnPaid()"
          ></roma-sale-preview-card>

          <div *ngIf="!(saleStorage$ | async)?.items?.length" class="no-items">
            <div class="align-self-center">
              <img
                src="assets/icons/gl-cart-shopping.svg"
                style="margin-bottom: 8px"
              />
              <p>
                {{ 'sales.salesCard.previewText' | translate }}
              </p>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <!-- right column -->
    <!-- </div> -->
  </div>
</div>
