import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss']
})
export class InputPasswordComponent implements OnInit {

  constructor(private changeDetectionRef: ChangeDetectorRef) { }

  @Input()
  showPasswordChecked:boolean = false;

  @Input()
  minLength:number = 6;

  @Input()
  isObligatory:boolean = true;

  @Input()
  disable:boolean = false;

  @Output()
  onChange = new EventEmitter<string>()

  showPassword:boolean = false;
  password:string = '';

  isPasswordOk: boolean = false;

  firstKey:boolean = false;

  ngOnInit(): void {
  }

  onKeyDown($event){
    this.firstKey = true;
    this.isPasswordOk = false;
  }

  onChangeEvent($event){
    this.onChange.emit(this.password);
    this.isValidPassword();
  }

  get errorLength(){
    return this.firstKey && this.minLength !== 0 && this.password.length < this.minLength
  }

  get errorObligatory(){
    return this.firstKey && this.isObligatory && this.password.length === 0;
  }

  public isValidPassword(){
    this.firstKey = true;
    this.isPasswordOk = !this.errorLength && !this.errorObligatory;
    return this.isPasswordOk;
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

}
