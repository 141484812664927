import { PaginateSort } from '@tacliatech/types';

export const INIT_BUDGETS_SEARCH_PARAMS = {
  limit: 50,
  page: 1,
  applyPaginate: true,
  keyword: null,
  from: null,
  to: null,
  [`sortOptions[created_at]`]: null,
  customProperties: null,
  takeCharged: null,
  'categories[]': [],
  'customers[]': [],
  'status[]': [],
  type: null,
  deal: null,
};
