export enum MongoErrorCode {
  UniqueViolation = '23505', // find real number
  SaveObjectError = '13233', // find real number
}

export enum AuthCode {
  WrongCredentials = '1',
  RoleExpeption = '2',
  UserEmpty = '3',
  Not_Authorized = '4',
  Company_Disabled = '5',
}

export enum CrudCode {
  NotFound = 'CRUD_1',
}
export enum UploadCode {
  ParamsError = 'UploadCode_1',
  StrategyError = 'UploadCode_2',
}

export enum BiddingErrorCode {
  BadStatus = 'BIDDING_BAD_STATUS',
  BadType = 'BIDDING_BAD_DEAL_TYPE',
  BiddingHasBeenAccepted = 'BIDDING_HAS_BEEN_ACCEPTED',
}

export enum GenericBussinesCode {
  DTO_ConvertError = 'GenericBussinesCode_1',
  InternalServerError = 'GenericBussinesCode_2',
}

export enum StripeErrorsCode {
  StripeInvalidRequestError = 'StripeInvalidRequestError',
  StripeInvalidAmount = 'StripeInvalidAmount',
  StripeDefaultError = 'StripeDefaultError',
  ConnectionTokenError = 'StripeConnectionTokenError',
  ResourseMissing = 'StripeResourceMissing',
  InvalidRequesError = 'StripeInvalidRequestError-patch',
  InvalidConnectedAccount = 'StripeInvalidConnectedAccount',
}
