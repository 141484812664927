import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'roma-modal-users',
  templateUrl: './modal-users.component.html',
  styleUrls: ['./modal-users.component.scss'],
})
export class ModalUsersComponent implements OnInit {
  title = '';

  @Input()
  users: any;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  ok() {
    this.activeModal.close();
  }
}
