<roma-modal-template
  size="sm"
  [showDefaultButtons]="false"
  [showHeader]="false"
  [responsiveFull]="false"
  [contentMinHeight]="false"
  [footerTemplate]="footerCustomTemplate"
  (cancel)="close()"
>
  <div class="row text-center">
    <div class="col-12">
      <div class="icon">
        <img src="assets/icons/info-icon-2-14.svg" />
      </div>
      <h2 class="modal-title">
        {{ title | translate }}
      </h2>
      <p>
        {{ content | translate }}
      </p>
    </div>
  </div>
</roma-modal-template>

<ng-template #footerCustomTemplate>
  <div class="modalFooter center">
    <div class="groupEndDiv">
      <base-button buttonClass="tertiary" (click)="close()">
        {{ 'general.cancel' | translate }}
      </base-button>
      <base-button
        romaProFeature
        [featureUser]="featureUserRef.CompanyAdjust.update"
        (clicked)="confirmActive()"
      >
        {{ 'payments.activate.complete_now' | translate }}
      </base-button>
    </div>
  </div>
</ng-template>
