import { Injectable } from '@angular/core';

import { DealService } from '../deals';
import { CustomerService } from '../customer';
import { ActivityService } from '../activity';
import { UserService } from '../users';
import { VerticalService } from '../vertical/vertical.service';
import { AuthService } from '../auth/auth.service';
import { SocketService } from '../socket';
import { InternalVendorService } from '../internal-vendor';
import { StorageService } from '../storage/storage.service';
import { AssetService } from '../assets';
import { FinalService, ProjectService } from '..';
import { EquipmentService } from '../equipment';
import { BudgetService } from '../budgets/budgets.service';
import { CompanyModuleService } from '../company';
import { Company } from '@tacliatech/types';
import { PermissionService } from '../permissions';
import { ExpenseService } from '../expense';
import { CatalogueService } from '../catalogue';
import { PaymentsService } from '../../../core/admin/payments/services/payments.service';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(
    private socketService: SocketService,
    private dealService: DealService,
    private customerService: CustomerService,
    private activityService: ActivityService,
    private userService: UserService,
    private verticalsService: VerticalService,
    private authService: AuthService,
    private vendorService: InternalVendorService,
    private assetService: AssetService,
    private finalService: FinalService,
    private equipmentService: EquipmentService,
    private projectService: ProjectService,
    private budgetsService: BudgetService,
    private companyModuleService: CompanyModuleService,
    private permissionService: PermissionService,
    private expenseService: ExpenseService,
    private catalogueService: CatalogueService,
    private paymentsService: PaymentsService
  ) {}

  init() {
    this.socketService.init();
    this.authService.init();
    this.companyModuleService.init();
    this.permissionService.init();
    this.budgetsService.init();
    this.paymentsService.init();

    setTimeout(() => {
      if (StorageService.UserId) {
        this.companyModuleService.idActiveModules$
          .subscribe((modules) => {
            if (modules.includes(Company.IdModule.Deals)) {
              this.dealService.init();
            }

            if (modules.includes(Company.IdModule.Finals)) {
              this.finalService.init();
            }

            if (modules.includes(Company.IdModule.Activities)) {
              this.activityService.init();
            }
            if (modules.includes(Company.IdModule.Projects)) {
              this.projectService.init();
            }
            if (modules.includes(Company.IdModule.Equipments)) {
              this.equipmentService.init();
            }
            if (modules.includes(Company.IdModule.Assets)) {
              this.assetService.init();
            }
            if (modules.includes(Company.IdModule.InternalVendors)) {
              this.vendorService.init();
            }
            if (modules.includes(Company.IdModule.Expenses)) {
              this.expenseService.init();
            }
            if (modules.includes(Company.IdModule.Sales)) {
              this.catalogueService.init();
            }
          })
          .unsubscribe();

        this.customerService.init();
        this.userService.init(StorageService.CompanyId);
        this.verticalsService.init();
      }
    }, 10000);
  }
}
