import { TranslateService } from '@ngx-translate/core';
import {
  CatchmentChannels,
  Company,
  Feature,
  FeatureUser,
  ICustomProperty,
} from '@tacliatech/types';
import { RmFilter } from '@web-frontend/shared/components/globals/rm-filter';
import {
  AssetSearchService,
  AssetService,
  FinalService,
  ProjectService,
  StatusService,
  StorageService,
} from '@web-frontend/shared/services';
import { CompanyModuleService } from '@web-frontend/shared/services/company';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

export const SOURCE_WEB = new RmFilter.Filter([
  new RmFilter.Input({
    type: 'SEARCH',
    keyRequest: 'keyword',
    content: {
      label: '',
      placeholder: 'deals.inputPlaceholder',
    },
  }),
]);

const requestStatus$ = (statusService: StatusService) =>
  statusService.findByUser([StorageService.CompanyId]).pipe(
    map((res) => {
      return res.map((el) => {
        return {
          title: el.name.toString().toLocaleLowerCase(),
          value: `${el._id}`,
          id: `${el._id}`,
        };
      });
    })
  );

const requestAuthors$ = (assetService: AssetService) =>
  assetService.findUsersByAssetsShared().pipe(
    map((res) =>
      res.map((el) => {
        return {
          title: el.name.toString().toLocaleLowerCase(),
          value: el._id,
          id: el._id,
          img: el.img,
        };
      })
    )
  );

const requestProjects$ = (projectService: ProjectService) =>
  projectService.search({ applyPaginate: false }).pipe(
    map((res) =>
      res.docs.map((el) => {
        return {
          title: el.name,
          id: el._id,
          value: el._id,
        };
      })
    )
  );

const showModule$ = (
  companyModuleService: CompanyModuleService,
  idModule: Company.IdModule
) =>
  companyModuleService.idActiveModules$.pipe(
    map((res) => res.includes(idModule))
  );

const requestAssets$ = (assetSearchService: AssetSearchService) =>
  assetSearchService
    .search({
      applyPaginate: false,
      customer: StorageService.CustomerId,
    })
    .pipe(
      map((res) =>
        res.docs.map((el) => {
          return {
            title: el.name.toString().toLocaleLowerCase(),
            value: el._id,
            id: el._id,
          };
        })
      )
    );

const requestFinals$ = (finalService: FinalService) =>
  finalService.search({ applyPaginate: false }).pipe(
    map((res) => res.docs),
    map((res) => {
      return res.map((el) => {
        return {
          title: `${el.name} ${el.lastName ? el.lastName : ''}`,
          id: el._id,
          value: el._id,
          img: el.img,
        };
      });
    })
  );

export const SOURCE_WEB_OPTIONS = (data: {
  statusService: StatusService;
  assetService: AssetService;
  assetSearchService: AssetSearchService;
  customProperties: ICustomProperty[];
  projectService: ProjectService;
  finalService: FinalService;
  companyModuleService: CompanyModuleService;
  i18n: TranslateService;
}) => {
  const controls: RmFilter.Control[] = [
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'status[]',
      source$: requestStatus$(data.statusService),
      content: {
        label: 'table.colum.status',
        placeholder: 'table.colum.status',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'managers[]',
      source$: requestAuthors$(data.assetService),
      content: {
        label: 'imports.columns.responsible',
        placeholder: 'imports.columns.responsible',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'taskers[]',
      source$: requestAuthors$(data.assetService),
      content: {
        label: 'filter.assigned',
        placeholder: 'filter.assigned',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'projects[]',
      source$: requestProjects$(data.projectService),
      show$: showModule$(data.companyModuleService, Company.IdModule.Projects),
      featureUser: FeatureUser.Project.show,
      content: {
        label: 'deals.projectsPh',
        placeholder: 'deals.projectsPh',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'assets[]',
      source$: requestAssets$(data.assetSearchService),
      show$: showModule$(data.companyModuleService, Company.IdModule.Assets),
      featureUser: FeatureUser.Address.show,
      content: {
        label: 'filter.address',
        placeholder: 'filter.address',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'finals[]',
      source$: requestFinals$(data.finalService),
      show$: showModule$(data.companyModuleService, Company.IdModule.Finals),
      featureUser: FeatureUser.Final.show,
      content: {
        label: 'filter.final',
        placeholder: 'filter.final',
      },
    }),
    new RmFilter.RangeDate({
      keyRequest: ['from', 'to'],
      content: {
        label: '',
        startPlaceholder: '',
        endPlaceholder: '',
      },
    }),
  ];

  for (const property of data.customProperties) {
    if (['SELECT'].includes(property.type)) {
      const options = property.options.map((el) => {
        return {
          id: el,
          title: el,
          value: el,
        };
      });
      controls.push(
        new RmFilter.Select({
          type: 'MULTIPLE',
          keyRequest: property.name,
          feature: Feature.Deal.CustomPropertiesFilter,
          keyRequestParent: 'customProperties',
          source$: from([options]),
          content: {
            label: `${data.i18n.instant('filter.custom')} ${property.name}`,
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }

    if (['BOOLEAN'].includes(property.type)) {
      const options = [
        {
          id: 'true',
          title: 'general.yes',
          value: 'true',
        },
        {
          id: 'false',
          title: 'general.no',
          value: 'false',
        },
      ];
      controls.push(
        new RmFilter.Select({
          type: 'SINGLE',
          keyRequest: property.name,
          keyRequestParent: 'customProperties',
          feature: Feature.Deal.CustomPropertiesFilter,
          source$: from([options]),
          content: {
            label: `${data.i18n.instant('filter.custom')} ${property.name}`,
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  for (const property of data.customProperties) {
    if (['DATE'].includes(property.type)) {
      controls.push(
        new RmFilter.InputDate({
          keyRequest: property.name,
          keyRequestParent: 'customProperties',
          feature: Feature.Deal.CustomPropertiesFilter,
          content: {
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  for (const property of data.customProperties) {
    if (property.type === 'STRING') {
      controls.push(
        new RmFilter.Input({
          type: 'STRING',
          keyRequest: property.name,
          keyRequestParent: 'customProperties',
          feature: Feature.Deal.CustomPropertiesFilter,
          content: {
            label: '',
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  for (const property of data.customProperties) {
    if (property.type === 'NUMBER') {
      controls.push(
        new RmFilter.InputRange({
          keyRequest: property.name,
          keyRequestParent: 'customProperties',
          feature: Feature.Deal.CustomPropertiesFilter,
          content: {
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  return new RmFilter.Filter([...controls]);
};

export const SOURCE_QUICK_OPTIONS = [
  new RmFilter.Checkbox({
    keyRequest: 'takeAll',
    content: {
      label: 'deals.viewAll',
    },
  }),
  new RmFilter.Checkbox({
    keyRequest: 'takeToday',
    content: {
      label: 'deals.viewToday',
    },
  }),

  new RmFilter.Checkbox({
    keyRequest: 'takeFinished',
    content: {
      label: 'deals.viewDone',
    },
  }),

  new RmFilter.Checkbox({
    keyRequest: 'takeCancelled',
    content: {
      label: 'deals.viewCanceled',
    },
  }),
];
