import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'roma-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.scss'],
})
export class BackButtonComponent {
  @Output() backClicked = new EventEmitter<void>();

  public back(): void {
    this.backClicked.emit();
  }
}
