<div class="deal__sidebar">
  <div
    class="deal__sidebar__item"
    [ngClass]="{ 'deal__sidebar__item--active': activeTab === 'DETAIL' }"
    (click)="changeTab('DETAIL')"
  >
    <div class="deal__sidebar__item__panel">
      <svg
        *ngIf="activeTab !== 'DETAIL'"
        width="37"
        height="36"
        viewBox="0 0 37 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.5 14.5H20.8333M25.5 18H20.8333M18.5 22.0833C18.1691 20.752 16.7278 19.75 15 19.75C13.2722 19.75 11.8309 20.752 11.5 22.0833M11.7333 26.1667H25.2667C26.5735 26.1667 27.2269 26.1667 27.726 25.9124C28.165 25.6886 28.522 25.3317 28.7457 24.8926C29 24.3935 29 23.7401 29 22.4333V13.5667C29 12.2599 29 11.6065 28.7457 11.1074C28.522 10.6683 28.165 10.3114 27.726 10.0877C27.2269 9.83334 26.5735 9.83334 25.2667 9.83334H11.7333C10.4265 9.83334 9.77315 9.83334 9.27402 10.0877C8.83498 10.3114 8.47802 10.6683 8.25432 11.1074C8 11.6065 8 12.2599 8 13.5667V22.4333C8 23.7401 8 24.3935 8.25432 24.8926C8.47802 25.3317 8.83498 25.6886 9.27402 25.9124C9.77315 26.1667 10.4265 26.1667 11.7333 26.1667ZM16.1667 15.0833C16.1667 15.7277 15.6443 16.25 15 16.25C14.3557 16.25 13.8333 15.7277 13.8333 15.0833C13.8333 14.439 14.3557 13.9167 15 13.9167C15.6443 13.9167 16.1667 14.439 16.1667 15.0833Z"
          stroke="#97A3C5"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <img *ngIf="activeTab === 'DETAIL'" src="assets/icons/detail.svg" />

      <p class="deal__sidebar__item__panel__text">
        {{ 'reports.subtitle' | translate }}
      </p>
    </div>
  </div>
  <div
    *ngIf="type !== 'ADD'"
    class="deal__sidebar__item"
    [ngClass]="{
      'deal__sidebar__item--active': activeTab === 'PART_OF_SERVICE'
    }"
    (click)="changeTab('PART_OF_SERVICE')"
  >
    <div class="deal__sidebar__item__panel">
      <svg
        *ngIf="activeTab === 'PART_OF_SERVICE'"
        width="37"
        height="36"
        viewBox="0 0 37 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6667 12.9232C13.8333 10.3077 16.75 8.92532 18.5 12.9232C18.9166 13.8748 19.1472 14.8265 19.2185 15.7592M19.2185 15.7592C19.5465 20.0509 16.5002 23.9413 12.6667 25.5833V20.8271C12.6667 19.8991 12.6667 19.435 12.818 19.0356C12.9516 18.6827 13.1689 18.3675 13.4511 18.117C13.7706 17.8335 14.2042 17.6684 15.0716 17.3381L19.2185 15.7592ZM19.2185 15.7592L23.1667 14.2559L22.5833 18.2538H25.5M29 22.6667H22M9.16667 22.6667H8"
          stroke="#7982F6"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <img
        *ngIf="activeTab !== 'PART_OF_SERVICE'"
        src="assets/icons/sign.svg"
      />

      <p class="deal__sidebar__item__panel__text">
        {{ 'deals.dealDetail.partOfServices' | translate }}
      </p>
    </div>
  </div>
  <ng-container
    *ngIf="
      (idActiveModules$ | async).includes(idModuleRef.Sales) && type !== 'ADD'
    "
  >
    <div
      class="deal__sidebar__item"
      [ngClass]="{ 'deal__sidebar__item--active': activeTab === 'SALES' }"
      romaProFeature
      [featureUser]="{
        type: 'some',
        features: [
          featureRefUser.Quote.show,
          featureRefUser.Bill.show,
        ]
      }"
      (clicked)="changeTab('SALES')"
    >
      <div class="deal__sidebar__item__panel">
        <svg
          *ngIf="activeTab !== 'SALES'"
          width="37"
          height="36"
          viewBox="0 0 37 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.3333 19.1667H29V26.1667C29 27.4553 27.9553 28.5 26.6667 28.5M24.3333 19.1667V26.1667C24.3333 27.4553 25.378 28.5 26.6667 28.5M24.3333 19.1667V10.7166C24.3333 9.66031 24.3333 9.13216 24.1114 8.82589C23.9179 8.55872 23.6202 8.38599 23.2922 8.35048C22.9162 8.30978 22.4577 8.57181 21.5405 9.09588L21.1761 9.30412C20.8386 9.49701 20.6698 9.59346 20.4905 9.63122C20.3319 9.66464 20.1681 9.66464 20.0095 9.63122C19.8302 9.59346 19.6614 9.49701 19.3239 9.30412L17.0928 8.02921C16.7552 7.83632 16.5864 7.73987 16.4072 7.7021C16.2486 7.66869 16.0847 7.66869 15.9261 7.7021C15.7469 7.73987 15.5781 7.83632 15.2405 8.02921L13.0095 9.30412C12.6719 9.49701 12.5031 9.59346 12.3239 9.63122C12.1653 9.66464 12.0014 9.66464 11.8428 9.63122C11.6636 9.59346 11.4948 9.49701 11.1572 9.30412L10.7928 9.09588C9.87567 8.57181 9.41712 8.30978 9.0411 8.35048C8.7131 8.38599 8.41546 8.55872 8.2219 8.82589C8 9.13216 8 9.66031 8 10.7166V22.9C8 24.8602 8 25.8403 8.38148 26.589C8.71703 27.2475 9.25247 27.783 9.91103 28.1185C10.6597 28.5 11.6398 28.5 13.6 28.5H26.6667M18.5 16.25C17.9167 16.1053 16.9659 16.1 16.1667 16.1053C15.8994 16.1071 16.061 16.0958 15.7 16.1053C14.758 16.1347 13.8353 16.5262 13.8333 17.6354C13.8313 18.8171 15 19.1667 16.1667 19.1667C17.3333 19.1667 18.5 19.4364 18.5 20.6979C18.5 21.646 17.5579 22.0614 16.3838 22.1989C15.4504 22.1989 15 22.2292 13.8333 22.0834M16.1667 22.6667V23.8333M16.1667 14.5V15.6667"
            stroke="#97A3C5"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <img
          *ngIf="activeTab === 'SALES'"
          src="assets/icons/receipt-alt-3.svg"
        />

        <p class="deal__sidebar__item__panel__text">
          {{ 'general.sales' | translate }}
        </p>
      </div>
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      (idActiveModules$ | async).includes(idModuleRef.Expenses) &&
      type !== 'ADD'
    "
  >
    <div
      class="deal__sidebar__item"
      [ngClass]="{
        'deal__sidebar__item--active': activeTab === 'EXPENSE'
      }"
      [ngStyle]="{ 'pointer-events': deal ? 'auto' : 'none' }"
      romaProFeature
      [featureUser]="featureRefUser.Expense.show"
      (clicked)="changeTab('EXPENSE')"
    >
      <div class="deal__sidebar__item__panel">
        <svg
          *ngIf="activeTab !== 'EXPENSE'"
          width="37"
          height="36"
          viewBox="0 0 37 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 28.5H29M8 25H29M11.2939 7.50043C11.778 7.92797 12.0833 8.55333 12.0833 9.25C12.0833 10.5387 11.0387 11.5833 9.75 11.5833C9.05333 11.5833 8.42797 11.278 8.00043 10.7939M11.2939 7.50043C11.4317 7.5 11.5779 7.5 11.7333 7.5H25.2667C25.4221 7.5 25.5683 7.5 25.7061 7.50043M11.2939 7.50043C10.2733 7.5036 9.71378 7.53025 9.27402 7.75432C8.83498 7.97802 8.47802 8.33498 8.25432 8.77402C8.03025 9.21378 8.0036 9.77328 8.00043 10.7939M8.00043 10.7939C8 10.9317 8 11.0779 8 11.2333V17.7667C8 17.9221 8 18.0683 8.00043 18.2061M8.00043 18.2061C8.42797 17.722 9.05333 17.4167 9.75 17.4167C11.0387 17.4167 12.0833 18.4613 12.0833 19.75C12.0833 20.4467 11.778 21.072 11.2939 21.4996M8.00043 18.2061C8.0036 19.2267 8.03025 19.7862 8.25432 20.226C8.47802 20.665 8.83498 21.022 9.27402 21.2457C9.71378 21.4697 10.2733 21.4964 11.2939 21.4996M11.2939 21.4996C11.4317 21.5 11.5779 21.5 11.7333 21.5H25.2667C25.4221 21.5 25.5683 21.5 25.7061 21.4996M29 18.2066C28.5724 17.7222 27.9469 17.4167 27.25 17.4167C25.9613 17.4167 24.9167 18.4613 24.9167 19.75C24.9167 20.4467 25.222 21.072 25.7061 21.4996M29 18.2066C29.0004 18.0687 29 17.9223 29 17.7667V11.2333C29 11.0779 29 10.9317 28.9996 10.7939M29 18.2066C28.9968 19.2269 28.9697 19.7863 28.7457 20.226C28.522 20.665 28.165 21.022 27.726 21.2457C27.2862 21.4697 26.7267 21.4964 25.7061 21.4996M28.9996 10.7939C28.572 11.278 27.9467 11.5833 27.25 11.5833C25.9613 11.5833 24.9167 10.5387 24.9167 9.25C24.9167 8.55333 25.222 7.92797 25.7061 7.50043M28.9996 10.7939C28.9964 9.77328 28.9697 9.21378 28.7457 8.77402C28.522 8.33498 28.165 7.97802 27.726 7.75432C27.2862 7.53025 26.7267 7.5036 25.7061 7.50043M20.8333 14.5C20.8333 15.7887 19.7887 16.8333 18.5 16.8333C17.2113 16.8333 16.1667 15.7887 16.1667 14.5C16.1667 13.2113 17.2113 12.1667 18.5 12.1667C19.7887 12.1667 20.8333 13.2113 20.8333 14.5Z"
            stroke="#97A3C5"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <img
          *ngIf="activeTab === 'EXPENSE'"
          src="assets/icons/expenses_deals-icon.svg"
        />

        <p class="deal__sidebar__item__panel__text">
          {{ 'general.expenses' | translate }}
        </p>
      </div>
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      (idActiveModules$ | async).includes(idModuleRef.Activities) &&
      type !== 'ADD'
    "
  >
    <div
      class="deal__sidebar__item"
      [ngClass]="{
        'deal__sidebar__item--active': activeTab === 'TASKS'
      }"
      [ngStyle]="{ 'pointer-events': deal ? 'auto' : 'none' }"
      romaProFeature
      [featureUser]="featureRefUser.Activity.show"
      (clicked)="changeTab('TASKS')"
    >
      <div class="deal__sidebar__item__panel">
        <svg
          *ngIf="activeTab !== 'TASKS'"
          width="37"
          height="36"
          viewBox="0 0 37 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5667 7.5V9.83333M24.2334 7.5V9.83333M11.6334 28.5H25.1667C26.4735 28.5 27.1269 28.5 27.626 28.2457C28.065 28.022 28.422 27.665 28.6457 27.226C28.9 26.7269 28.9 26.0735 28.9 24.7667V13.5667C28.9 12.2599 28.9 11.6065 28.6457 11.1074C28.422 10.6683 28.065 10.3114 27.626 10.0877C27.1269 9.83333 26.4735 9.83333 25.1667 9.83333H11.6334C10.3266 9.83333 9.67317 9.83333 9.17405 10.0877C8.735 10.3114 8.37805 10.6683 8.15434 11.1074C7.90002 11.6065 7.90002 12.2599 7.90002 13.5667V24.7667C7.90002 26.0735 7.90002 26.7269 8.15434 27.226C8.37805 27.665 8.735 28.022 9.17405 28.2457C9.67317 28.5 10.3266 28.5 11.6334 28.5ZM13.7334 23.25L16.0958 22.7775C16.3018 22.7363 16.4048 22.7157 16.5009 22.678C16.5861 22.6446 16.6671 22.6012 16.7422 22.5489C16.8269 22.4898 16.9011 22.4156 17.0497 22.267L21.9 17.4167C22.5444 16.7723 22.5444 15.7277 21.9 15.0833C21.2557 14.439 20.211 14.439 19.5667 15.0833L14.7163 19.9337C14.5678 20.0822 14.4935 20.1565 14.4345 20.2411C14.3821 20.3162 14.3388 20.3973 14.3053 20.4825C14.2676 20.5785 14.247 20.6815 14.2058 20.8875L13.7334 23.25Z"
            stroke="#97A3C5"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <img
          *ngIf="activeTab === 'TASKS'"
          src="assets/icons/wrp_icon_azul.svg"
        />

        <p class="deal__sidebar__item__panel__text">
          {{ 'general.resultActivities' | translate }}
        </p>
      </div>
    </div>
  </ng-container>
</div>
<div class="deal__body safe-area-padding">
  <div class="deal__body__top">
    <img
      *ngIf="(resize$ | async)?.payload?.mobile && !loading"
      [src]="'assets/icons/gl_close.svg'"
      class="close-button"
      width="32px"
      height="32px"
      (click)="close()"
    />

    <button
      type="button"
      *ngIf="type !== 'ADD'"
      class="square-button"
      [ngClass]="{ active: deal?.isFinished }"
      romaProFeature
      [featureUserHide]="featureRefUser.Deal.update"
      (click)="finishDeal()"
    >
      <img
        [src]="
          !deal?.isFinished
            ? 'assets/icons/gl_check-black.svg'
            : 'assets/icons/gl_check-fill.svg'
        "
      />
      {{
        !deal?.isFinished
          ? ('deals.dealDetail.finished' | translate)
          : ('deals.dealDetail.finished2' | translate)
      }}
    </button>

    <button
      *ngIf="
        !(resize$ | async)?.payload?.mobile &&
        data?.mode === 'EDIT' &&
        !loading &&
        !deal?.cancelled
      "
      type="button"
      class="rounded-button"
      romaProFeature
      [featureUser]="featureRefUser.Deal.delete"
      (clicked)="cancelDealNoModal(deal)"
      [item]="deal"
      [disabled]="loadingCancelDeal"
      type="deal"
    >
      <mat-progress-spinner
        *ngIf="loadingCancelDeal"
        style="margin-right: 5px"
        class="loading-spinner"
        mode="indeterminate"
        [diameter]="20"
      ></mat-progress-spinner>
      <img src="assets/icons/gl_error_black.svg" />
      {{ 'general.cancelDeal' | translate }}
    </button>

    <button
      *ngIf="
        !(resize$ | async)?.payload?.mobile &&
        data?.mode === 'EDIT' &&
        !loading &&
        deal?.cancelled
      "
      type="button"
      class="rounded-button"
      (click)="restoreDealNoModal(deal)"
      [item]="deal"
      [disabled]="loadingRestoreDeal"
      type="deal"
    >
      <mat-progress-spinner
        *ngIf="loadingRestoreDeal"
        style="margin-right: 5px"
        class="loading-spinner"
        mode="indeterminate"
        [diameter]="20"
      ></mat-progress-spinner>
      <img src="assets/icons/restore.svg" />
      {{ 'general.restoreButton' | translate }}
    </button>

    <button
      *ngIf="
        !(resize$ | async)?.payload?.mobile && data?.mode === 'EDIT' && !loading
      "
      type="button"
      class="rounded-button"
      (click)="shareLink()"
    >
      <img src="assets/icons/gl_link-black.svg" />
      {{ 'general.shareLink' | translate }}
    </button>

    <img
      *ngIf="!(resize$ | async)?.payload?.mobile"
      [src]="'assets/icons/gl_close.svg'"
      class="close-button"
      width="32px"
      height="32px"
      (click)="close()"
    />

    <ng-container
      *ngIf="
        (resize$ | async)?.payload?.mobile && data?.mode === 'EDIT' && deal
      "
    >
      <img
        class="more-v"
        [matMenuTriggerFor]="topActiosMenu"
        src="assets/icons/gl_more-vertical.svg"
      />
      <mat-menu
        #topActiosMenu="matMenu"
        xPosition="before"
        class="top__actions__menu actions-menu"
      >
        <button
          *ngIf="data?.mode === 'EDIT' && !loading && !deal?.cancelled"
          type="button"
          romaProFeature
          [featureUser]="featureRefUser.Deal.delete"
          (wantDeleteDeal)="cancelDeal(deal)"
          romaClickDeleteDeal
          [item]="deal"
          subtitle="deleteModal.body_final"
          type="deal"
        >
          <img src="assets/icons/gl_error_black.svg" />
          {{ 'general.cancelDeal' | translate }}
        </button>
        <button
          *ngIf="data?.mode === 'EDIT' && !loading"
          type="button"
          (click)="shareLink()"
        >
          <img src="assets/icons/gl_link-black.svg" />
          {{ 'general.shareLink' | translate }}
        </button>
      </mat-menu>
    </ng-container>
  </div>
  <div class="deal__body__content">
    <roma-wrapper-detail
      *ngIf="activeTab === 'DETAIL'"
      [type]="type"
      [dealData]="deal"
      [customer]="customer"
      (onCreateFinish)="onCreateFinish($event)"
      (updateNotes)="updateNotes($event)"
    >
    </roma-wrapper-detail>

    <roma-wrapper-expenses
      *ngIf="activeTab === 'EXPENSE'"
      [dealData]="deal"
      [type]="type"
    >
    </roma-wrapper-expenses>

    <roma-wrapper-sales
      *ngIf="activeTab === 'SALES'"
      [dealData]="deal"
      [type]="type"
      (closeModalEvt)="close()"
      [isLoading]="loading"
    >
    </roma-wrapper-sales>

    <roma-wrapper-part-of-service
      *ngIf="deal && activeTab === 'PART_OF_SERVICE'"
      [dealData]="deal"
      [type]="type"
      [isLoading]="loading"
    ></roma-wrapper-part-of-service>

    <roma-wrapper-tasks
      *ngIf="deal && activeTab === 'TASKS'"
      [dealData]="deal"
      [type]="type"
      [isLoading]="loading"
    >
    </roma-wrapper-tasks>
  </div>
</div>
