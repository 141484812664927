import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import { Subscription } from 'rxjs';

import {
  Deal,
  IStatus,
  PaginateResponse,
  PaginateSort,
} from '@tacliatech/types';

import { DealSearchService } from '@web-frontend/shared/services';
import { DealPipelineService } from '../deal-pipeline.service';

@Component({
  selector: 'roma-deal-pipeline-item',
  templateUrl: './deal-pipeline-item.component.html',
  styleUrls: ['./deal-pipeline-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealPipelineItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  status: IStatus;

  isLoadingPaginate = false;

  // These properties comes from parent (deal-pipe.component)
  @Input()
  deals: Deal.Output[] = [];

  @Input()
  isLoading = true;
  //

  resultSearch: PaginateResponse<Deal.Output[]>;
  dealsMap = new Map<string, Deal.Output>();

  private sub$ = new Subscription();
  private flightRequest: Subscription;
  private searchParams = {
    limit: 50,
    page: 1,
    sort: PaginateSort.ASC,
    from: null,
    keyFromTo: null,
    'status[]': [],
    optimize: true,
  };

  constructor(
    private cdRef: ChangeDetectorRef,
    private dealSearchService: DealSearchService,
    private dealPipelineService: DealPipelineService
  ) {}

  ngOnInit(): void {
    // this.watchTakeMore();
    // this.watchChangeParams();
    // this.attachParams();
    // this.requestDeals({ loadingGlobal: true });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.deals?.currentValue?.length) {
      this.filterDeals();
    }
  }

  ngOnDestroy(): void {
    // this.sub$.unsubscribe();
    // this.flightRequest.unsubscribe();
  }

  // private watchTakeMore() {
  //   this.sub$.add(
  //     this.dealPipelineService.requestPagination$
  //       .pipe(filter((res) => !!res))
  //       .subscribe(() => {
  //         if (this.resultSearch?.hasNextPage) {
  //           this.searchParams = {
  //             ...this.searchParams,
  //             page: this.searchParams.page + 1,
  //           };

  //           this.requestDeals({ loadingPagination: true });
  //         }
  //       })
  //   );
  // }

  // private watchChangeParams() {
  //   this.sub$.add(
  //     this.dealPipelineService.changeParams$
  //       .pipe(filter((res) => !!res))
  //       .subscribe((res) => {
  //         const status = this.getIdsStatus(res);

  //         this.searchParams = {
  //           ...res,
  //           'status[]': status,
  //           limit: 50,
  //           page: 1,
  //           sort: PaginateSort.ASC,
  //           from: null,
  //           keyFromTo: null,
  //           optimize: true,
  //         };

  //         this.clear();

  //         this.requestDeals({ loadingGlobal: true });
  //       })
  //   );
  // }

  // private attachParams() {
  //   const status = this.getIdsStatus(this.searchParams);

  //   this.searchParams = {
  //     ...this.searchParams,
  //     'status[]': status,
  //   };
  // }

  // private requestDeals({ loadingGlobal = false, loadingPagination = false }) {
  //   if (loadingGlobal) {
  //     this.isLoading = true;
  //     this.draw();
  //   }

  //   if (loadingPagination) {
  //     this.isLoadingPaginate = true;
  //     this.draw();
  //   }

  //   const query = RemoveEmpty(this.searchParams);

  //   if (this.searchParams['status[]'].includes(STATUS_FINALIZED.id)) {
  //     this.searchParams = {
  //       ...this.searchParams,
  //       from: subDays(new Date(), 15),
  //       keyFromTo: 'finishDate',
  //     };
  //   }

  //   if (this.flightRequest) {
  //     this.flightRequest.unsubscribe();
  //   }

  //   const request$ = this.dealSearchService
  //     .search({
  //       ...query,
  //     })
  //     .pipe(
  //       finalize(() => {
  //         this.isLoading = false;
  //         this.isLoadingPaginate = false;
  //         this.draw();
  //       })
  //     );

  //   if (!query?.['status[]'].length) {
  //     this.isLoading = false;
  //     this.isLoadingPaginate = false;
  //     this.draw();
  //     return;
  //   }

  //   this.flightRequest = request$.subscribe((res) => {
  //     this.setDeals(res);
  //   });
  // }

  // private setDeals(response: PaginateResponse<Deal.Output[]>) {
  //   this.resultSearch = response;
  //   this.deals = [...this.deals, ...response.docs];
  // }

  // private draw() {
  //   this.cdRef.detectChanges();
  // }

  // private clear() {
  //   this.dealsMap.clear();

  //   this.deals = [];
  // }

  // private getIdsStatus(params: { [key: string]: any }) {
  //   if (params?.['status[]']?.length) {
  //     const statusMatch = (params['status[]'] as Array<number | string>)
  //       .map((item) => +item)
  //       .some((item) => item === this.status?.id);

  //     if (!statusMatch) {
  //       return [];
  //     }
  //   }

  //   return [this.status.id];
  // }

  filterDeals() {
    this.deals = this.deals.filter((deal: any) => {
      return this.status?.name === deal.status?.name;
    });
  }
}
