<ng-container>
  <div class="wrapper__body">
    <div class="wrapper__body__content">
      <!-- content scrolleable, init feature here -->

      <div *ngIf="isLoading" class="col-12">
        <roma-loading></roma-loading>
      </div>

      <div [ngClass]="{ 'is-hidden': isLoading }">
        <div class="card-header">
          <div class="reference__text" *ngIf="dealData?._id">
            {{ 'deals.dealDetail.ref' | translate }}
            <span class="reference__number">{{ dealData?._id }}</span>
          </div>
          <h2 class="title">{{ dealData?.name }}</h2>
        </div>

        <div class="tabs-in-tab-container">
          <mat-tab-group
            dynamicHeight
            [selectedIndex]="getSelectedIndex()"
            (selectedTabChange)="onTabChange($event)"
          >
            <mat-tab
              [label]="'general.budgets' | translate"
              *ngIf="havePermission(featureRefUser.Quote.show)"
            >
              <roma-budgets
                [showHeader]="false"
                typeShow="BDG"
                [dealId]="dealData?._id"
                (closeServiceCard)="close()"
                romaProFeature
                [featureUserHide]="featureRefUser.Quote.show"
              ></roma-budgets>
            </mat-tab>

            <mat-tab
              [label]="'sidebar.bills' | translate"
              *ngIf="havePermission(featureRefUser.Bill.show)"
            >
              <roma-budgets
                [showHeader]="false"
                typeShow="BILL"
                [dealId]="dealData?._id"
                (closeServiceCard)="close()"
                romaProFeature
                [featureUserHide]="featureRefUser.Bill.show"
              ></roma-budgets>
            </mat-tab>

            <mat-tab
              [label]="'sidebar.waybills' | translate"
              *ngIf="havePermission(featureRefUser.Waybills.show)"
            >
              <roma-budgets
                [showHeader]="false"
                typeShow="WAL"
                [dealId]="dealData?._id"
                (closeServiceCard)="close()"
                romaProFeature
                [featureUserHide]="featureRefUser.Waybills.show"
              ></roma-budgets>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</ng-container>
