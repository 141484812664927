import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {
  ClockHour,
  COMPANY_ID_KEY,
  Feature,
  FeatureUser,
} from '../../../../../types-legacy';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ClockHourMetrics,
  ClockHourService,
  SandboxService,
  StorageService,
  UserService,
} from '../../../../shared/services';
import { PaginateClockHourResponse } from '../../../../shared/interfaces';
import { forkJoin } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { ClockHourView } from '../clock-hour.type';
import { AddClockHour } from '../../../../shared/components/add-clock-hour/add-clock-hour.types';
import { AddClockHourService } from '../../../../shared/components/add-clock-hour';
import { AlertService } from '../../../../shared/helpers/alert';
import { DeleteBySelectionModalComponent } from '../../../../shared/components/delete-by-selection-modal/delete-by-selection-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TableComponent } from '../../../../shared/components/table/table.component';
import { FileWriteService } from '../../../../shared/helpers/file-write';
import { DatePipe } from '@angular/common';
import { DownloadBtnService } from '../../../../shared/components/download-btn/download-btn.service';
import { AnalyticsService } from '../../../../shared/services/analytics/analytics.service';
import AmplitudeEvents from '../../../../../types/amplitude.enum';
import { formatClockDate } from '@web-frontend/shared/utils/format-clock-date';
import { downloadSheet } from '@web-frontend/shared/utils/download-file';

@Component({
  selector: 'roma-clock-hour-user',
  templateUrl: './clock-hour-user.component.html',
  styleUrls: ['./clock-hour-user.component.scss'],
})
export class ClockHourUserComponent implements OnInit {
  @ViewChild(TableComponent)
  table: TableComponent;

  tableMarginTop = '0px';
  distanceToTop = 0;
  columns = ['select', 'date', 'entrance', 'durationAverage', 'status', 'note'];
  userName = '';
  featureRef = Feature;
  featureUserRef = FeatureUser;
  statusRef = ClockHour.Status;

  isLoading: boolean = false;
  userId: string;
  period: { startDate: string; endDate: string } = {
    startDate: '',
    endDate: '',
  };
  addButtonLoading: boolean = false;

  userMetrics: ClockHourMetrics = {
    workedHours: null,
    contractHours: null,
    remainingHours: null,
    extraHours: null,
    pendingRegisters: null,
  };

  viewPeriod: ClockHourView = ClockHourView.Week;

  private searchParams = {
    'idCompanies[]': [StorageService.GetItem(COMPANY_ID_KEY)],
    'idOwners[]': '',
    limit: 7,
    from: null,
    to: null,
  };

  clockHours: ClockHour.Output[] = [];
  resize$ = this.sandBoxService.screenBusChannel$;
  monthlyDays = [];

  currentMonth: number;
  currentYear: number;
  selectedMonth: number;

  firstWeekDay: number;
  lastWeekDay: number;
  firstMonthDay: number;
  lastMonthDay: number;

  matchDateEntries: ClockHour.Output[];
  clockHoursTotal: string;
  clockHoursMedia: string;

  deleteMessage: boolean = false;
  entriesForDelete = [];
  canApprovalOrRejectAll = false;
  defaultQueryParams: {
    from: string;
    to: string;
    view: ClockHourView;
  };

  constructor(
    private readonly i18n: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly cdRef: ChangeDetectorRef,
    private readonly clockHourService: ClockHourService,
    private readonly sandBoxService: SandboxService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly addClockHourService: AddClockHourService,
    private readonly alertService: AlertService,
    public readonly dialog: MatDialog,
    private readonly fileWrite: FileWriteService,
    private readonly datePipe: DatePipe,
    private readonly downloadBtnService: DownloadBtnService,
    private readonly analyticsService: AnalyticsService
  ) {}

  goBack() {
    this.router.navigate(['/admin/clock-hours/admin']);
  }

  ngOnInit() {
    this.userId = this.route.snapshot.params.userId;
    this.searchParams['idOwners[]'] = this.userId;
    this.route.queryParamMap.subscribe((queryParams) => {
      this.defaultQueryParams = {
        from: queryParams.get('from'),
        to: queryParams.get('to'),
        view: queryParams.get('view') as ClockHourView,
      };
    });
    this.loadData();
  }

  getLang() {
    return this.i18n.currentLang;
  }

  getUserData() {
    return this.userService.findById(this.userId).pipe(
      tap((res) => {
        this.userName = res.name;
      })
    );
  }

  checkScroll(e) {
    if (e.target.scrollHeight > 1080) {
      if (e.target.scrollTop <= this.distanceToTop) {
        this.tableMarginTop = '-' + e.target.scrollTop + 'px';
      } else {
        this.tableMarginTop = '-' + this.distanceToTop + 'px';
      }
    }
  }

  convertDate(date: string): { year: number; month: number; day: number } {
    const [year, month, day] = date.split('-').map(Number);
    return { year, month: month - 1, day };
  }

  changePeriod(evt: {
    startDate: string;
    endDate: string;
    view: ClockHourView;
  }) {
    const { startDate, endDate, view } = evt;

    const convertedStartDate = this.convertDate(startDate);
    const convertedEndDate = this.convertDate(endDate);

    this.period = { startDate, endDate };
    this.searchParams.from = JSON.stringify(convertedStartDate);
    this.searchParams.to = JSON.stringify(convertedEndDate);
    this.viewPeriod = view;
    this.selectedMonth = convertedStartDate.month;
    this.currentYear = convertedStartDate.year;

    if (view === ClockHourView.Week) {
      this.firstWeekDay = convertedStartDate.day;
      this.lastWeekDay = convertedEndDate.day;
      this.searchParams.limit = 7;
    } else {
      this.firstMonthDay = convertedStartDate.day;
      this.lastMonthDay = convertedEndDate.day;
      this.searchParams.limit = 31;
    }

    this.loadData();
  }

  getMetrics() {
    return this.clockHourService
      .getUserMetrics(this.userId, this.period.startDate, this.period.endDate)
      .pipe(
        tap((res) => {
          this.userMetrics = res;
        })
      );
  }

  searchClockHours() {
    return this.clockHourService.search({ ...this.searchParams }).pipe(
      tap((res) => {
        this.setClockHours(res);
      })
    );
  }

  private setClockHours(
    response: PaginateClockHourResponse<ClockHour.Output[]>
  ) {
    this.clockHours = response.docs;
    this.clockHoursTotal = ClockHour.formatHoursMinutes(
      response.totalHours,
      response.totalMinutes
    );
    this.clockHoursMedia = ClockHour.getTotalHourFromClockHours(
      this.clockHours,
      20
    );
    this.matchDateEntries = response.docs;
    this.getClockHoursMonthEntries();
  }

  private getDayObject(day: number, month: number, year: number) {
    return (
      this.clockHours.find(
        (clockHour) =>
          clockHour.date.day === day &&
          clockHour.date.month === month &&
          clockHour.date.year === year
      ) || {
        date: { day, month, year },
      }
    );
  }

  private getMonthlyDays(month: number) {
    return this.clockHours.filter(
      (clockHour) => clockHour.date.month === month
    );
  }

  private getWeeklyDays() {
    const lastDayOfCurrentMonth = new Date(
      this.currentYear,
      this.selectedMonth + 1,
      0
    ).getDate();

    const daysArray = Array.from(
      { length: 7 },
      (_, i) => this.firstWeekDay + i
    );

    return daysArray.map((weekDay) =>
      this.getDayObjectForWeek(weekDay, lastDayOfCurrentMonth)
    );
  }

  private getDayObjectForWeek(weekDay: number, lastDayOfCurrentMonth: number) {
    if (weekDay <= lastDayOfCurrentMonth) {
      return this.getDayObject(weekDay, this.selectedMonth, this.currentYear);
    }
    const nextMonth = (this.selectedMonth + 1) % 12;
    const nextYear =
      this.selectedMonth === 11 ? this.currentYear + 1 : this.currentYear;
    return this.getDayObject(
      weekDay - lastDayOfCurrentMonth,
      nextMonth,
      nextYear
    );
  }

  private getClockHoursMonthEntries() {
    if (this.viewPeriod === ClockHourView.Week) {
      this.monthlyDays =
        this.firstWeekDay > this.lastWeekDay ? this.getWeeklyDays() : [];
    } else {
      this.monthlyDays = this.getMonthlyDays(this.selectedMonth);
    }
    this.setTableEntries();
  }

  private setTableEntries() {
    const { tableLength, media, startDay } = this.getTableParameters();
    this.clockHoursMedia = ClockHour.getTotalHourFromClockHours(
      this.clockHours,
      media
    );
    const daysInMonth = this.getDaysInMonth(
      this.currentYear,
      this.selectedMonth
    );

    const daysArray = Array.from(
      { length: tableLength },
      (_, i) => startDay + i
    ).filter((day) => day <= daysInMonth);

    daysArray.forEach((day) =>
      this.addDayIfNotExists(day, this.selectedMonth, this.currentYear)
    );

    this.clockHours = this.sortMonthlyDays();
  }

  private sortMonthlyDays() {
    return this.monthlyDays.sort(
      (a, b) =>
        a.date.year - b.date.year ||
        a.date.month - b.date.month ||
        a.date.day - b.date.day
    );
  }

  private getTableParameters() {
    let tableLength: number;
    let media: number;
    let startDay: number;

    if (this.viewPeriod === ClockHourView.Week) {
      tableLength = 7;
      media = 5;
      startDay = this.firstWeekDay;
    } else {
      tableLength = this.lastMonthDay + 1;
      media = 20;
      startDay = 1;
    }

    return { tableLength, media, startDay };
  }

  private getDaysInMonth(year: number, month: number): number {
    return new Date(year, month + 1, 0).getDate();
  }

  private addDayIfNotExists(day: number, month: number, year: number) {
    let obj = this.monthlyDays.find(
      (o) =>
        o.date.day === day && o.date.month === month && o.date.year === year
    );

    if (!obj) {
      obj = this.getDayObject(day, month, year);
      this.monthlyDays.push(obj);
    }
  }

  private loadData() {
    this.isLoading = true;
    this.cdRef.detectChanges();

    forkJoin({
      userData: this.getUserData(),
      metrics: this.getMetrics(),
      clockHours: this.searchClockHours(),
    }).subscribe({
      next: () => {
        this.isLoading = false;
        this.cdRef.detectChanges();
      },
      error: () => {
        this.isLoading = false;
        this.cdRef.detectChanges();
      },
    });
  }

  launchModalClockHourEdit(evt: ClockHour.Output): void {
    const mode: AddClockHour.Mode = evt?._id ? 'EDIT' : 'ADD';

    this.addClockHourService
      .open({
        data: {
          mode,
          idCompany: StorageService.CompanyId,
          idOwner: this.userId,
          params: evt,
        },
      })
      .subscribe(() => {
        this.loadData();
      });
  }

  async launchModalClockHour() {
    const today = new Date();
    const date = {
      year: today.getFullYear(),
      month: today.getMonth(),
      day: today.getDate(),
    };

    this.addButtonLoading = true;
    this.cdRef.detectChanges();

    const search = await this.clockHourService
      .search({
        ...this.searchParams,
        from: JSON.stringify(date),
        to: JSON.stringify(date),
        limit: 1,
      })
      .toPromise();

    this.addButtonLoading = false;
    this.cdRef.detectChanges();

    const mode = search.docs.length ? 'EDIT' : 'ADD';

    this.addClockHourService
      .open({
        data: {
          mode,
          idCompany: StorageService.CompanyId,
          idOwner: this.userId,
          params: search.docs?.[0],
        },
      })
      .subscribe(() => {
        this.loadData();
      });
  }

  deleteClockHour(evt: ClockHour.Output[]): void {
    const ids = evt.map((entry) => entry._id);

    this.clockHourService
      .deleteMany({
        ids: ids,
      })
      .pipe(
        finalize(() => {
          this.deleteMessage = false;
          this.entriesForDelete = [];
        })
      )
      .subscribe(() => {
        const DELETE_MESSAGE = this.i18n.instant('clockHour.deleteSuccess');

        this.loadData();
        this.alertService.success(DELETE_MESSAGE);
      });
  }

  deleteMany(evt: ClockHour.Output[]) {
    this.entriesForDelete = evt;

    this.canApprovalOrRejectAll = evt
      .map((el) => el.status)
      .every((el) => el === ClockHour.Status.Pending);

    if (evt.length > 0) {
      this.deleteMessage = true;
    } else {
      this.deleteMessage = false;
    }
  }

  updateStatusClockHour(
    status: ClockHour.Status.Approved | ClockHour.Status.Rejected
  ) {
    if (status === ClockHour.Status.Approved) {
      this.analyticsService.trackEvent({
        sources: ['amplitude', 'userflow'],
        eventName: AmplitudeEvents.trackingTimeTeam_bulk_approve,
        eventProperties: {
          event_location: 'user_list',
        },
      });
    } else {
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: AmplitudeEvents.trackingTimeTeam_bulk_decline,
        eventProperties: {
          event_location: 'user_list',
        },
      });
    }
    this.clockHourService
      .updateMany(
        this.entriesForDelete.map((el) => el._id),
        {
          status,
        }
      )
      .subscribe(() => {
        this.entriesForDelete = [];
        this.deleteMessage = false;
        this.cdRef.detectChanges();
        this.loadData();
      });
  }

  openDeleteManyDialog(): void {
    const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
      panelClass: 'delete-by-selection-modal',
      data: {
        isPlural: this.entriesForDelete?.length > 1,
        body: this.i18n.instant('deleteModal.body_final'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'EXECUTE') {
        this.deleteClockHour(this.entriesForDelete);
      }
    });
  }

  unselectItems() {
    this.entriesForDelete = [];
    this.deleteMessage = false;
    this.table.clearChecks();
  }

  downloadReport(): void {
    const formattedDate = {
      startDate: formatClockDate(this.searchParams.from),
      endDate: formatClockDate(this.searchParams.to),
    };
    const filename = `${this.userName}-${formattedDate.startDate}_${formattedDate.endDate}`;

    this.clockHourService
      .getUserReport(
        this.userId,
        formattedDate.startDate,
        formattedDate.endDate
      )
      .subscribe(
        (response) => {
          downloadSheet(response, `${filename}.xlsx`);
          this.downloadBtnService.downloadedSuccessfully();
          this.analyticsService.trackEvent({
            eventName: AmplitudeEvents.trackingTimeTeam_download,
            sources: ['amplitude', 'braze', 'userflow'],
            eventProperties: {
              event_location: 'user_list',
            },
          });
        },
        (error) => {
          console.warn('Error downloading the file', error);
        }
      );
  }
}
