<div class="clock-hours-filters">
  <div class="page-options row">
    <div class="fit-content col-12 options no-padding">
      <div class="action-element fit-content">
        <div class="filter-wrapper mobile-hidden fit-content bg-light-blue">
          <button
            class="btn btn-align fit-content"
            [ngClass]="{ active: viewTypeActive === viewTypeRef.TEAM }"
            (click)="changeViewType(viewTypeRef.TEAM)"
          >
            <mat-icon class="align-icon" svgIcon="user_icon"></mat-icon>
            <span class="align-text">{{
              'clockHour.viewTeam' | translate
            }}</span>
          </button>
          <button
            class="btn btn-align fit-content"
            [ngClass]="{ active: viewTypeActive === viewTypeRef.ALL }"
            (click)="changeViewType(viewTypeRef.ALL)"
          >
            <mat-icon class="align-icon" svgIcon="table_icon"></mat-icon>
            <span class="align-text">{{
              'clockHour.viewAll' | translate
            }}</span>
          </button>
        </div>
      </div>
    </div>
    <div
      *ngIf="!(resize$ | async)?.payload?.mobile; else mobileFilters"
      class="col-8-4 container__filters vertical-line-left"
    >
      <roma-rm-filter-core
        *ngIf="sourceSearch"
        [source]="
          viewTypeActive === viewTypeRef.ALL ? sourceSearch : sourceSearchTeam
        "
        (changes)="rmFilterCoreChanges($event)"
      ></roma-rm-filter-core>
      <ng-container
        *ngIf="viewTypeActive === viewTypeRef.ALL; else teamTemplate"
      >
        <roma-rm-filter-button
          *ngIf="sourceOptions"
          [source]="sourceOptions"
          (changes)="rmFilterCoreChanges($event)"
        ></roma-rm-filter-button>
        <roma-rm-filter-clear
          *ngIf="sourceOptions"
          [sources]="[sourceSearch, sourceOptions]"
          (changes)="rmFilterCoreChanges($event)"
        ></roma-rm-filter-clear>
      </ng-container>
      <ng-template #teamTemplate>
        <div class="date-filter-container">
          <roma-monthly-weekly-filter
            (changePeriodRange)="changePeriod($event)"
          ></roma-monthly-weekly-filter>
        </div>
      </ng-template>
    </div>
    <ng-template #mobileFilters>
      <div *ngIf="viewTypeActive === viewTypeRef.ALL">
        <div class="button-mobile-container">
          <roma-rm-filter-button
            [source]="sourceSecondaryMobileFilter"
            (changes)="rmFilterCoreChanges($event)"
          ></roma-rm-filter-button>
          <roma-shared-download-btn
            [featureExcel]="featureRef.ClockHour.DownloadExcel"
            [showDownloadExcel]="true"
            [showDownloadPdf]="false"
            (download)="download($event)"
            [ngClass]="{ 'is-hidden': isLoading }"
          ></roma-shared-download-btn>
        </div>

        <button
          class="mobile-button-add"
          romaProFeature
          [featureUser]="featureUserRef.ClockHour.create"
          (click)="launchModalClockHour()"
        >
          <img
            src="/assets/icons/gl_plus-1.svg"
            alt="{{ 'general.add' | translate }}"
          />
        </button>
      </div>
    </ng-template>
  </div>
</div>
