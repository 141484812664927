<roma-modal-template
  *ngIf="!showComponent; else body"
  [size]="'md'"
  [customStyles]="
    !(this.resize$ | async).payload.mobile ? { width: '660px' } : null
  "
  [title]="
  !(this.resize$ | async).payload.mobile
    ? (title | translate)
    : ('budgets.title.preview' | translate)
  "
  [headerBottomMargin]="'16px'"
  [showSuccessBtn]="false"
  [showCancelBtn]="false"
  [showDefaultButtons]="false"
  [footerTemplate]="footerContainer"
  (cancel)="close()"
>
  <ng-container *ngTemplateOutlet="body"> </ng-container>
</roma-modal-template>

<ng-template #body>
  <div class="dialog">
    <mat-progress-spinner
      *ngIf="!pdfSrc"
      class="loading-spinner"
      mode="indeterminate"
      [diameter]="60"
      style="margin: auto"
    ></mat-progress-spinner>
    <pdf-viewer
      [src]="pdfSrc"
      [render-text]="true"
      [external-link-target]="'blank'"
      [fit-to-page]="true"
      [original-size]="false"
      [autoresize]="true"
      style="width: 100%; height: 100%"
      [ngStyle]="{ display: pdfSrc ? 'flex' : 'none' }"
    ></pdf-viewer>
    <div *ngIf="isDownloading" class="container-loading">
      <roma-loading></roma-loading>
    </div>
  </div>

  <ng-container *ngIf="mode === 'EDIT'">
    <ng-container *ngTemplateOutlet="footerContainer"> </ng-container>
  </ng-container>
</ng-template>

<ng-template #footerContainer>
  <div class="container-modal-footer"></div>
</ng-template>
