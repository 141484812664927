import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { SplitModule } from '@web-frontend/shared/pipes/split';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DealPipelineComponent } from './deal-pipeline.component';

import { LoadingModule } from '../loading';
import { ScrollContainerModule } from '../scroll-container';
import { DealPipelineItemComponent } from './deal-pipeline-item/deal-pipeline-item.component';
import { PipelineDealCardComponent } from './deal-pipeline-card/deal-pipeline-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { RmBadgeModule } from '../globals/rm-badge';

@NgModule({
  declarations: [
    DealPipelineComponent,
    DealPipelineItemComponent,
    PipelineDealCardComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    LoadingModule,
    SplitModule,
    MatTooltipModule,
    ScrollContainerModule,
    TranslateModule.forChild(),
    RmBadgeModule,
  ],
  exports: [
    DealPipelineComponent,
    DealPipelineItemComponent,
    PipelineDealCardComponent,
  ],
})
export class DealPipelineModule {}
