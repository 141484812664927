import { MatDialogConfig } from '@angular/material/dialog';
import { ICategoryExpense, IProduct, TypeCategory } from '@tacliatech/types';

export interface DataParamsAddCommonCategory {
  type: 'ADD' | 'EDIT';
  typeCategory: TypeCategory;
  object: ICategoryExpense | IProduct;
}

export const DefaultConfig = () => {
  const config = new MatDialogConfig();

  config.data = {
    ...DefaultParams,
  };

  return config;
};

const DefaultParams: DataParamsAddCommonCategory = {
  type: 'ADD',
  typeCategory: TypeCategory.SALES,
  object: null,
};
