import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DaySelectComponent } from './day-select.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule.forChild()],
  declarations: [DaySelectComponent],
  exports: [DaySelectComponent],
})
export class DaySelectModule {}
