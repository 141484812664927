import { IInternalVendor, IUser, IVertical } from '@tacliatech/types';
import { User } from './user.class';

export class InternalVendor implements IInternalVendor {
  name: string;
  email: string;
  password?: string;
  created_at?: Date;
  deleted?: boolean;
  _id?: string;
  verticals?: IVertical[];
  urgency?: boolean;
  phone?: string;
  province?: string;
  hasBeenInvited?: boolean;
  contactName?: string;
  owner: IUser;

  static fromArray(attributes: IInternalVendor[]) {
    return attributes.map((attribute) => new InternalVendor(attribute));
  }

  constructor(attribute: IInternalVendor) {
    const owner = attribute?.owner ? new User(attribute?.owner) : null;

    Object.assign(this, attribute, { owner });
  }
}
