<div
  *ngFor="let color of colors"
  class="item"
  [ngClass]="{ selected: selectedColor?.lightColor === color.lightColor }"
  [style]="{
    backgroundColor: color.lightColor,
    'border-color': color.darkColor
  }"
  (click)="selectColor(color)"
>
  <svg
    *ngIf="selectedColor?.lightColor === color.lightColor"
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.9464 0.266214C17.4866 0.667551 17.554 1.3774 17.0969 1.85171L7.70029 11.6017C7.46471 11.8461 7.12166 11.9909 6.75709 11.9996C6.39252 12.0083 6.04098 11.8803 5.79056 11.6475L0.949894 7.14747C0.463989 6.69577 0.48716 5.98375 1.00165 5.55713C1.51613 5.13052 2.32711 5.15086 2.81302 5.60257L6.66967 9.18781L15.1406 0.398339C15.5977 -0.0759686 16.4062 -0.135123 16.9464 0.266214Z"
      [attr.fill]="color.darkColor"
    />
  </svg>
</div>
