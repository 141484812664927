<div class="card-deal-description">ID: {{ deal?._id | split }}</div>

<div class="card-deal-title" [title]="deal?.name">
  {{ deal?.name }}
</div>

<div class="card-deal-description">
  {{ deal?.description }}
</div>

<div class="card-deal-footer">
  <div class="card-deal-description">
    {{ deal?.initDate | date: company_date_format }}
  </div>
</div>
