import { IFinal } from './final.types';

export const GetFinalName = (final: IFinal) => {
  return final?.name;

  /*return final?.name && final?.fiscalName
    ? `${final?.name} (${final?.fiscalName})`
    : final?.name;*/
};

export const MapperFinal = (finals: IFinal[]): IFinal[] => {
  return finals.map((final) => {
    return {
      ...final,
      name: GetFinalName(final),
    };
  });
};
