<roma-modal-template
  [title]="
    aproveValue === true
      ? ('deals.dealDetail.aproveService' | translate)
      : ('deals.dealDetail.rejectService' | translate)
  "
  [showDefaultButtons]="true"
  (success)="submit()"
  (cancel)="close()"
>
  <label>{{
    aproveValue === true
      ? ('deals.dealDetail.label_aproveService' | translate)
      : ('deals.dealDetail.label_rejectService' | translate)
  }}</label>
</roma-modal-template>
