import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreatePersonComponent } from './create-person.component';
import { ModalTemplateModule } from '../modal-template';
import { MatIconModule } from '@angular/material/icon';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { ImageModule } from '@web-frontend/shared/pipes/image';
import { MatDialogModule } from '@angular/material/dialog';
import { PictureModule } from '../picture/picture.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { FieldErrorDisplayModule } from '../field-error-display';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { AppendCustomPropertiesModule } from '../append-custom-properties';
import { PermissionsModule } from '../permissions';
import { RmPhoneSelectInputModule } from '../globals/rm-phone-select-input';
import { BaseButtonModule } from '@web-frontend/common/components/button/baseButton.module';
import { HourSelectModule } from '../hour-select/hour-select.module';
import { DaySelectModule } from '../day-select';
import { OnlyNumberModule } from '../../directives/onlyNumber';

@NgModule({
  declarations: [CreatePersonComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    MatDialogModule,
    MatIconModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatCheckboxModule,
    MatButtonModule,
    MatRadioModule,
    PictureModule,
    ImageModule,
    MatDialogModule,
    FieldErrorDisplayModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    AppendCustomPropertiesModule,
    PermissionsModule,
    RmPhoneSelectInputModule,
    BaseButtonModule,
    HourSelectModule,
    DaySelectModule,
    OnlyNumberModule,
  ],
  exports: [CreatePersonComponent],
})
export class CreatePersonModule {}
