import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFileUpload, PaginateResponse } from '@tacliatech/types';

@Injectable({
  providedIn: 'root',
})
export class KanbanService {
  private baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = ':API_URL';
  }

  search(query: { [key: string]: any } = {}) {
    delete query?.online;

    const params = new HttpParams({
      fromObject: {
        ...query,
      },
    });

    return this.http.get<any>(`:API_URL/kanban/getKanban`, {
      params,
    });
  }

  create(body: { idCompany: string; type: string }) {
    return this.http.post<{ _id: string; type: string; idCompany: string }>(
      `${this.baseUrl}/kanban/`,
      body
    );
  }

  update(body: any) {
    return this.http.put(`${this.baseUrl}/kanban/`, body);
  }

  delete(id: string) {
    return this.http.delete(`${this.baseUrl}/kanban/${id}`);
  }
}
