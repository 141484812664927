import {
  CustomProperty,
  ICategoryExpense,
  ICustomer,
  IDeal,
  IExpense,
  IProject,
  IUser,
} from '@tacliatech/types';

export class Expense implements IExpense {
  _id?: string;
  dateTime: Date;
  number: number;
  type: number;
  dueDate: Date;
  vendor: ICustomer;
  vendorObj: ICustomer;
  concept: string;
  name: string;
  file: string;
  users: IUser[];
  deal: IDeal;
  dealObj?: IDeal;
  idProject: IProject;
  projectObj?: IProject;
  category: ICategoryExpense;
  categoryObj?: ICategoryExpense;
  subtotal: number;
  total: number;
  createdBy: IUser;
  customer: ICustomer;
  deleted: boolean;
  hasBeenNotified: boolean;
  status: number;
  imgs: string[];
  customProperties: { [key: string]: any } | CustomProperty[];

  static fromArray(attributes) {
    return attributes.map((attribute) => new Expense(attribute));
  }

  constructor(attribute: IExpense) {
    Object.assign(this, attribute);
  }
}
