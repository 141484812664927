import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Activity } from '@tacliatech/types';
import { environment } from '@web-frontend/environments';

interface idItem {
  _id: string;
}

@Component({
  selector: 'roma-list-mobile-activities',
  templateUrl: './list-mobile-activities.component.html',
  styleUrls: ['./list-mobile-activities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListMobileActivitiesComponent implements OnInit {
  _data: Activity.Output[];
  @ViewChildren(MatCheckbox)
  childrenMatCheckbox: QueryList<MatCheckbox>;

  @Input()
  set data(data: Activity.Output[]) {
    this._data = data;
    this.ids = [];
  }

  get data() {
    return this._data;
  }

  ids: idItem[] = [];

  @Output()
  actions = new EventEmitter<idItem[]>();

  @Output()
  onClickRow = new EventEmitter<Activity.Output>();
  showOwnersModal = false;

  constructor(private cdRef: ChangeDetectorRef, public dialog: MatDialog) {}

  ngOnInit(): void {}

  private draw() {
    this.cdRef.detectChanges();
  }

  onCheckboxChange(id: string) {
    if (!this.isChecked({ _id: id })) {
      this.ids.push({ _id: id });
    } else {
      const index = this.ids.findIndex((item) => item._id == id);
      this.ids.splice(index, 1);
    }
    this.actions.next(this.ids);
  }

  eventClickRow(item: Activity.Output) {
    this.onClickRow.emit(item);
  }

  isChecked(item) {
    return this.ids.map((it) => it._id).includes(item._id);
  }

  public clearChecks() {
    for (const checkbox of this.childrenMatCheckbox) {
      checkbox.checked = false;
    }

    this.ids = [];
    this.actions.next([]);
  }

  mapArray(items, field) {
    return items?.map((it) => it[field]);
  }

  isArray(value: any) {
    return Array.isArray(value);
  }

  openActivityOwnersDialog(owners) {
    this.dialog.open(ActivityOwnersDialog, {
      data: owners,
    });
  }
}

export interface ActivityOwnersDialogData {
  img?: string;
  name: string;
}

@Component({
  selector: 'roma-activity-owners-dialog',
  templateUrl: 'activity-owners-dialog-dialog.html',
  styleUrls: ['./activity-owners-dialog-dialog.scss'],
})
export class ActivityOwnersDialog {
  get firebaseURL() {
    return environment.firebaseConfig.storageUrl + '/uploads%2Fuser%2F';
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: ActivityOwnersDialogData) {}
}
