import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  COMPANY_ID_KEY,
  Feature,
  FeatureUser,
  IStatus,
  KanbanCard,
} from '@tacliatech/types';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import {
  SandboxService,
  StatusService,
  StorageService,
} from '@web-frontend/shared/services';
import { AlertService } from '@web-frontend/shared/services/alert/alert.service';
import { KanbanService } from '@web-frontend/shared/services/kanban';
import { fromEvent, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged, filter, map } from 'rxjs/operators';
import {
  DealStatusColors,
  GetDefaultColor,
} from '../deals/deal-status-manager/deal-status-manager-.const';
import { MatMenuTrigger } from '@angular/material/menu';
import { ChooseColorModalService } from '../deals/deal-status-manager/choose-color/choose-modal/choose-color-modal.service';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { ToastData } from '@web-frontend/shared/services/toast/utils/toast-config';
import { MatDialog } from '@angular/material/dialog';
import { DeleteBySelectionModalComponent } from '../delete-by-selection-modal/delete-by-selection-modal.component';
import AmplitudeEvents from 'src/types/amplitude.enum';

class column {
  _id?: string;
  name: string;
}

class kanban {
  _id: string;
  type: string;
  idCompany: string;
  columns?: string[];
  columnsObjs?: {
    _id?: string;
    name: string;
    translate?: string;
    isSystemColumn: boolean;
    cards: KanbanCard[];
    color?: string;
    order?: number;
  }[];
}

@Component({
  selector: 'roma-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.scss'],
})
export class KanbanComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() modalIn = false;

  @ViewChild('dragContainer', { static: true })
  dragContainer: ElementRef<HTMLDivElement>;

  @ViewChild('addColorMenuTrigger')
  addColorMenuTrigger: MatMenuTrigger;

  @Input()
  type: 'activity' | 'deal';

  @Input()
  statusData: IStatus[] = [];

  _cards: KanbanCard[];
  @Input()
  set cards(cards: KanbanCard[]) {
    let existsCards: boolean;
    if (this.cards?.length > 0) {
      existsCards = true;
    }

    this._cards = cards;
    if (
      // @ts-ignore
      existsCards &&
      cards?.length > 0 &&
      (cards[0].finished || cards[0].deleted)
    ) {
      this.kanban = {
        _id: '',
        idCompany: StorageService.CompanyId,
        type: this.type,
        columns: [],
        columnsObjs: [],
      };
      this.initKanbanData();
    } else {
      this.getKanban();
    }
  }

  get cards() {
    return this._cards;
  }

  // @ts-ignore
  _columns: { _id?: string; name: string; color: string }[] = null;
  @Input()
  set columns(columns: { _id?: string; name: string; color: string }[]) {
    this._columns = columns;
    this.getKanban();
  }

  get columns() {
    return this._columns;
  }

  @Input()
  set active(value: boolean) {
    this.activePipeline = value;
  }

  get active() {
    return this.activePipeline;
  }

  get _parsedColumns() {
    const parsedColumns = {};
    // @ts-ignore
    for (let i = 0; i < this.kanban.columnsObjs.length; i++) {
      // @ts-ignore
      parsedColumns[this.kanban.columnsObjs[i].name] = this.kanban.columnsObjs[
        i
      ];
    }
    return parsedColumns;
  }

  @Input()
  isLoading = false;

  @Input()
  showCardId = false;

  @Input()
  showFinishCard = true;

  @Input()
  showDeleteCard = true;

  @Input()
  showCancelCard = true;

  @Input()
  showRestoreCard = true;

  @Input()
  filterByUser = false;

  @Input()
  saveFullCard = false;

  @Input()
  systemColumnOnlyWhenCards = false;

  @Input()
  allowCreateColumn = false;

  @Input()
  allowDeleteColumn = false;

  @Input()
  deleteColumnMessage = 'kanban.deleteMessage';

  @Input()
  placeholderAddCard = 'kanban.placeHolderAddCard';

  @Input()
  cardCreateText = 'kanban.cardCreate';

  @Input()
  withoutColumnText = 'kanban.withoutColumn';

  @Input()
  cardDropPlaceHolderType: 'line' | 'default' = 'default';

  @Input()
  deleteCardMessage = 'general.wantDelete';

  @Input()
  restoreCardMessage = 'general.wantDelete';

  @Input()
  tooltipDeleteSystemColumn = 'kanban.tooltipSystemColumn';

  @Input()
  cardsName = 'kanban.cardsName';

  @Input()
  columnsName = 'kanban.columnsName';

  @Input()
  unfinishToastParms: ToastData = {
    text: 'kanban.toast.unfinish',
    type: 'success',
    msDuration: 4000,
  };

  @Input()
  restoreToastParms: ToastData = {
    text: 'kanban.toast.restore',
    type: 'success',
    msDuration: 4000,
  };

  @Input()
  unfinishSelectionToastParms: ToastData = {
    text: 'kanban.toast.unfinishMany',
    type: 'success',
    msDuration: 4000,
  };

  @Input()
  finishToastParms: ToastData = {
    text: 'kanban.toast.finish',
    type: 'success',
    msDuration: 4000,
  };

  @Input()
  deleteToastParms: ToastData = {
    text: 'kanban.toast.delete',
    type: 'error',
    msDuration: 4000,
    icon: 'assets/icons/kanban/delete.svg',
  };

  @Input()
  featureUserCreate: Feature.Purchasable | FeatureUser.Purchasable =
    Feature.SystemPermission.DefaultAllow;

  @Input()
  featureUserUpdate: Feature.Purchasable | FeatureUser.Purchasable =
    Feature.SystemPermission.DefaultAllow;

  @Input()
  featureUserDelete: Feature.Purchasable | FeatureUser.Purchasable =
    Feature.SystemPermission.DefaultAllow;

  lastCreatedCardTitle?: string;

  @Output()
  onCreateFirstKanban = new EventEmitter();

  @Output()
  onAddColumn = new EventEmitter<string>();

  @Output()
  onChangeColor = new EventEmitter<{ darkColor: string; lightColor: string }>();

  @Output()
  onEditColumn = new EventEmitter<{
    _id: string;
    name: string;
    color?: string;
  }>();

  @Output()
  onDeleteColumn = new EventEmitter<{ column: column; cards: KanbanCard[] }>();

  @Output()
  onCardClick = new EventEmitter<string>();

  @Output()
  onCardDblClick = new EventEmitter<string>();

  @Output()
  onAddCard = new EventEmitter<{ column; card: KanbanCard }>();

  @Output()
  onCardColumnChange = new EventEmitter<{
    previousColumn?: { _id?: string; name: string };
    column: { _id?: string; name: string };
    card: KanbanCard;
  }>();

  @Output()
  onChangeCardColumn = new EventEmitter<{
    previousColum: string;
    column: string;
    card: KanbanCard;
  }>();

  @Output()
  onDeleteCard = new EventEmitter<KanbanCard>();

  @Output()
  onCancelCard = new EventEmitter<KanbanCard>();

  @Output()
  onEditCard = new EventEmitter<KanbanCard>();

  @Output()
  onFinishCard = new EventEmitter<KanbanCard>();

  @Output()
  onUnfinishCard = new EventEmitter<KanbanCard>();

  @Output()
  onMoveAllCards = new EventEmitter<{
    cards: KanbanCard[];
    column: column;
    previousColumn: column;
  }>();

  @Output()
  onCardSelectChange = new EventEmitter<KanbanCard[]>();

  @Output()
  onFinishSelectedCards = new EventEmitter<KanbanCard[]>();

  @Output()
  onUnfinishSelectedCards = new EventEmitter<KanbanCard[]>();

  @Output()
  onRestoreSelectedCards = new EventEmitter<KanbanCard[]>();

  @Output()
  onRestoreCard = new EventEmitter<KanbanCard>();

  @Output()
  onMoveSelectedCards = new EventEmitter<{
    column: column;
    cards: KanbanCard[];
  }>();

  @Output()
  onDeleteSelectedCards = new EventEmitter<KanbanCard[]>();

  @Output()
  onUnfinishToastLinkClick = new EventEmitter<KanbanCard>();

  @Output()
  onRestoreToastLinkClick = new EventEmitter<KanbanCard>();

  @Output()
  onUnfinishSelectionToastLinkClick = new EventEmitter<KanbanCard[]>();

  @Output()
  onFinishToastLinkClick = new EventEmitter<KanbanCard>();

  @Output()
  onDeleteToastLinkClick = new EventEmitter<KanbanCard>();

  @Output()
  onChangeStatus = new EventEmitter<unknown>();

  objectKeys = Object.keys;
  initKanban: kanban;
  kanban: kanban;
  addColumn = false;
  columnName: string;
  private activePipeline = false;
  private SCROLL_DISTANCE_BOTTOM = 10;
  private lastScrollTop = 0;
  private sub$ = new Subscription();
  resize$ = this.sandBoxService.screenBusChannel$;
  showMoveCardMobileMenu = false;
  selectedCardName = '';
  selectedColor: { darkColor: string; lightColor: string } =
    DealStatusColors[0];
  finishSelected = false;
  status: IStatus[] = [];

  constructor(
    private amplitudeService: AmplitudeService,
    private elRef: ElementRef,
    private changeDetectionRef: ChangeDetectorRef,
    private kanbanService: KanbanService,
    private i18n: TranslateService,
    private alertService: AlertService,
    private sandBoxService: SandboxService,
    private chooseColorModalService: ChooseColorModalService,
    private toastService: ToastService,
    private statusService: StatusService,
    public dialog: MatDialog
  ) {}

  isCancelMultiple = false;

  ngOnInit(): void {
    this.getKanban();
    this.sub$.add(
      fromEvent(this.elRef.nativeElement, 'scroll')
        .pipe(
          debounce(() => timer(100)),
          distinctUntilChanged(),
          map((res) => (res as Event).target as HTMLDivElement),
          filter((res) => res.scrollTop >= this.lastScrollTop),
          filter(
            (element) =>
              element.scrollHeight - element.clientHeight - element.scrollTop <=
              this.SCROLL_DISTANCE_BOTTOM
          )
        )
        .subscribe((element) => {
          this.lastScrollTop = element.scrollTop;
          //this.dealPipelineService.requestPagination();
        })
    );
  }

  ngAfterViewInit(): void {}

  _selectedCards: KanbanCard[] = [];
  get selectedCards() {
    this._selectedCards = [];
    this.kanban?.columnsObjs?.forEach((column) => {
      this._selectedCards = this._selectedCards.concat(
        column.cards.filter((fil) => fil.selected == true)
      );
    });
    return this._selectedCards;
  }

  get selectedHaveFinishedCards() {
    return this.selectedCards.some((it) => {
      return it.finished === true;
    });
  }

  get selectedHaveDeletedCards() {
    return this.selectedCards.some((it) => it.deleted === true);
  }

  get selectedHaveAliveCards() {
    return this.selectedCards.some((it) => it.deleted === false);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  unselectAllCards() {
    // @ts-ignore
    for (let i = 0; i < this.kanban?.columnsObjs?.length; i++) {
      // @ts-ignore
      for (let y = 0; y < this.kanban.columnsObjs[i].cards.length; y++) {
        // @ts-ignore
        this.kanban.columnsObjs[i].cards[y].selected = false;
      }
    }
  }

  initKanbanData(onlyColumns = false) {
    if (this.saveFullCard) {
      return;
    }
    if (!this.kanban || !this.cards || this.columns == null) {
      return;
    }

    const tempCards: KanbanCard[] = JSON.parse(JSON.stringify(this.cards));
    //check if columns exists
    if (this.columns && this.columns.length > 0) {
      for (let i = 0; i < this.columns.length; i++) {
        // @ts-ignore
        if (!this.kanban.columns.includes(this.columns[i].name)) {
          // @ts-ignore
          this.kanban.columns.push(this.columns[i].name);
        }

        // @ts-ignore
        const obj = this.kanban.columnsObjs.filter(
          (fil) => fil.name == this.columns[i].name
        );
        if (obj.length == 0) {
          // @ts-ignore
          this.kanban.columnsObjs.push({
            _id: this.columns[i]._id,
            name: this.columns[i].name,
            isSystemColumn: false,
            cards: [],
            color: this.columns[i].color,
          });
        } else {
          // @ts-ignore
          const index = this.kanban.columnsObjs.indexOf(obj[0]);
          // @ts-ignore
          this.kanban.columnsObjs[index]._id = this.columns[i]._id;
          // @ts-ignore
          this.kanban.columnsObjs[index].color = this.columns[i].color;
        }
      }

      if (onlyColumns) {
        return;
      }
    }

    //delete columns
    // @ts-ignore
    for (let i = this.kanban.columns.length - 1; i > -1; i--) {
      if (
        // @ts-ignore
        !this.columns.map((it) => it.name).includes(this.kanban.columns[i]) &&
        // @ts-ignore
        !this.columns.map((it) => it._id).includes(this.kanban.columns[i])
      ) {
        // @ts-ignore
        const name = this.kanban.columns[i];
        // @ts-ignore
        this.kanban.columns.splice(i, 1);
        // @ts-ignore
        const index = this.kanban.columnsObjs.findIndex(
          (it) => it._id == name || it.name == name
        );
        if (index != -1) {
          // @ts-ignore
          this.kanban.columnsObjs.splice(index, 1);
        }
      }
    }

    // @ts-ignore
    const indexSystemColumn = this.kanban.columnsObjs.findIndex(
      (it) => it.isSystemColumn == true
    );

    // @ts-ignore
    for (let i = 0; i < this.kanban.columnsObjs.length; i++) {
      // @ts-ignore
      for (let y = 0; y < this.kanban.columnsObjs[i].cards.length; y++) {
        const card = tempCards.find(
          (fil) =>
            // @ts-ignore
            fil._id == this.kanban.columnsObjs[i].cards[y]._id ||
            // @ts-ignore
            (this.kanban.columnsObjs[i].cards[y]._id == null &&
              // @ts-ignore
              fil.title == this.kanban.columnsObjs[i].cards[y].title)
        );
        if (!card) {
          continue;
        } else {
          // @ts-ignore
          card.order = this.kanban.columnsObjs[i].cards[y].order;
        }
        //column change
        if (
          card.column == null ||
          (!this.columns.map((it) => it._id).includes(card.column) &&
            !this.columns.map((it) => it.name).includes(card.column))
        ) {
          // @ts-ignore
          const indexCard = this.kanban.columnsObjs[
            indexSystemColumn
          ].cards.findIndex(
            (it) =>
              it._id == card._id || (card._id == null && it.title == card.title)
          );
          if (indexCard == -1) {
            // @ts-ignore
            this.kanban.columnsObjs[indexSystemColumn].cards.push(card);
          } else {
            // @ts-ignore
            card.order = this.kanban.columnsObjs[indexSystemColumn].cards[
              indexCard
            ].order;
            // @ts-ignore
            this.kanban.columnsObjs[indexSystemColumn].cards[indexCard] = card;
          }
          const index = tempCards.indexOf(card);
          tempCards.splice(index, 1); //delete de item in array string
          continue;
        }

        if (
          card.column != null &&
          // @ts-ignore
          card.column != this.kanban.columnsObjs[i]._id &&
          // @ts-ignore
          card.column != this.kanban.columnsObjs[i].name
        ) {
          // @ts-ignore
          const colIndex = this.kanban.columnsObjs.findIndex(
            (it) => it._id == card.column || it.name == card.column
          );

          // @ts-ignore
          this.kanban.columnsObjs[colIndex > -1 ? colIndex : i].cards.push(
            card
          );

          const index = tempCards.indexOf(card);
          tempCards.splice(index, 1); //delete de item in array string
          continue;
        }
        const index = tempCards.indexOf(card);
        // @ts-ignore
        card.selected = this.kanban.columnsObjs[i].cards[y].selected;
        // @ts-ignore
        this.kanban.columnsObjs[i].cards[y] = card;
        tempCards.splice(index, 1); //delete de item in array string
      }
      //delete old cards
      // @ts-ignore
      this.kanban.columnsObjs[i].cards = this.kanban.columnsObjs[
        i
      ].cards.filter((fil) => fil.onCreate != undefined);
    }
    //add system column
    if (tempCards.length == 0 && this.systemColumnOnlyWhenCards) {
      this.draw();
      return;
    }

    //find if exists his column
    for (let i = tempCards.length - 1; i > -1; i--) {
      if (!tempCards[i].column) {
        continue;
      }

      // @ts-ignore
      const col = this.kanban.columnsObjs.filter(
        (fil) =>
          fil.name == tempCards[i].column || fil._id == tempCards[i].column
      );
      if (col.length == 0) {
        continue;
      }

      // @ts-ignore
      const index = this.kanban.columnsObjs.indexOf(col[0]);
      // @ts-ignore
      this.kanban.columnsObjs[index].cards.push(tempCards[i]);
      tempCards.splice(i, 1);
    }

    if (indexSystemColumn == -1) {
      // @ts-ignore
      this.kanban.columnsObjs.unshift({
        name: this.i18n.instant(this.withoutColumnText),
        translate: this.withoutColumnText,
        isSystemColumn: true,
        cards: tempCards,
      });
    } else {
      // @ts-ignore
      this.kanban.columnsObjs[
        indexSystemColumn
        // @ts-ignore
      ].cards = this.kanban.columnsObjs[indexSystemColumn].cards.concat(
        tempCards
      );
    }
    this.draw();
  }

  loadingData = false;
  // @ts-ignore
  lastTimeGet: Date;
  getKanban(load = true) {
    if (load) {
      this.isLoading = true;
    }
    const parms: any = {
      idCompany: StorageService.CompanyId,
      type: this.type,
    };
    if (this.filterByUser) {
      parms.idUser = StorageService.UserId;
    }

    if (
      !this.loadingData &&
      (!this.lastTimeGet ||
        new Date().getTime() - this.lastTimeGet.getTime() > 3000)
    ) {
      this.loadingData = true;
      this.lastTimeGet = new Date();
      this.statusService
        .findByUser({
          id: StorageService.GetItem(COMPANY_ID_KEY),
          type: this.type,
        })
        .subscribe((res) => {
          this.status = res;
          this.sub$.add(
            this.kanbanService.search(parms).subscribe((res) => {
              this.loadingData = false;
              if (res.length > 0) {
                this.initKanban = JSON.parse(JSON.stringify(res[0]));
                this.kanban = JSON.parse(JSON.stringify(res[0]));

                this.initKanbanData();
                // @ts-ignore
                this.kanban.columnsObjs[0].order = 0;

                for (let i = 0; i < this.status.length; i++) {
                  // @ts-ignore
                  const colIndex = this.kanban.columnsObjs.findIndex(
                    (item) => item._id === this.status[i]._id
                  );

                  // @ts-ignore
                  this.kanban.columnsObjs[colIndex].order = i + 1;
                }

                this.isLoading = false;
                this.draw();
              } else {
                this.createKanban();
                this.isLoading = false;
              }
            })
          );
        });
    } else {
      this.isLoading = false;
      this.initKanbanData();
    }
  }

  sortBy(prop: string) {
    return this.kanban?.columnsObjs?.sort((a, b) =>
      a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1
    );
  }

  _creatingKanban = false;
  createKanban() {
    if (!this._creatingKanban) {
      this._creatingKanban = true;
      const parms: any = {
        idCompany: StorageService.CompanyId,
        type: this.type,
      };
      if (this.filterByUser) {
        parms.idUser = StorageService.UserId;
      }
      this.sub$.add(
        this.kanbanService.create(parms).subscribe((res) => {
          this.kanban = res;
          this._creatingKanban = false;
          this.onCreateFirstKanban.emit();
          this.initKanbanData();
        })
      );
    }
  }

  updateKanban(loading = true) {
    if (loading) {
      this.isLoading = true;
    }
    const kanbanRefactor: kanban = JSON.parse(JSON.stringify(this.kanban));
    if (!this.saveFullCard) {
      // @ts-ignore
      for (let i = 0; i < kanbanRefactor.columnsObjs.length; i++) {
        // @ts-ignore
        kanbanRefactor.columnsObjs[i].cards = kanbanRefactor.columnsObjs[
          i
        ].cards.map((it, index) => ({
          _id: it._id,
          title: it.title,
          order: index,
        }));
      }
    }
    /*kanbanRefactor.columnsObjs = kanbanRefactor.columnsObjs.filter(
      (fil) => fil.isSystemColumn == false
    );*/
    this.sub$.add(
      this.kanbanService.update(kanbanRefactor).subscribe((res) => {
        this.isLoading = false;
        this.draw();
      })
    );
  }

  onCardDrop(event) {
    this.drop(event.event, event.status);
  }

  drop(event: CdkDragDrop<KanbanCard[]>, status: IStatus) {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_boardMove,
    });
    //can't move finished or deleted tasks
    if (
      event.previousContainer.data[event.previousIndex].finished == true ||
      event.previousContainer.data[event.previousIndex].deleted == true
    ) {
      this.alertService.error(this.i18n.instant('kanban.errors.error2'));
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      const col = this.columns.filter(
        (fil) =>
          fil._id ==
          (event.container.id === 'default' ? null : event.container.id)
      );
      const prevCol = this.columns.filter(
        (fil) =>
          fil._id ==
          (event.previousContainer.id === 'default'
            ? null
            : event.previousContainer.id)
      );
      let colObj = null;
      if (col.length > 0) {
        // @ts-ignore
        colObj = col[0];
      }
      let colPrevObj = null;
      if (prevCol.length > 0) {
        // @ts-ignore
        colPrevObj = prevCol[0];
      }
      const emitObj = {
        previousColumn: colPrevObj,
        column: colObj,
        card: event.container.data[event.currentIndex],
      };
      const index = this.cards.findIndex(
        (it) => it.title == event.container.data[event.currentIndex].title
      );
      if (index != -1) {
        // @ts-ignore
        this.cards[index].column = colObj?._id;
      }
      // @ts-ignore
      this.onCardColumnChange.emit(emitObj);
    }
    if (this.systemColumnOnlyWhenCards) {
      // @ts-ignore
      const systemColumn = this.kanban.columnsObjs.filter(
        (fil) => fil.isSystemColumn == true
      );
      if (systemColumn.length > 0 && systemColumn[0].cards.length == 0) {
        // @ts-ignore
        const index = this.kanban.columnsObjs.indexOf(systemColumn[0]);
        // @ts-ignore
        this.kanban.columnsObjs.splice(index, 1);
        this.draw();
      }
    }
    this.updateKanban();
  }

  openChooseColor() {
    this.chooseColorModalService
      .open({ data: { color: this.selectedColor.lightColor } })
      .subscribe((res) => {
        // @ts-ignore
        this.changeColor(res);
      });
  }

  otherColumns(actualColumn) {
    // @ts-ignore
    return this.kanban.columnsObjs
      .filter((fil) => fil.name != actualColumn.name)
      .map((x) => ({
        _id: x._id,
        name: x.name,
        // @ts-ignore
        color: GetDefaultColor(x?.color),
      }));
  }

  addColumnClick() {
    this.addColumn = !this.addColumn;
    if (this.type === 'deal') {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_board_newStatus,
      });
    }
  }

  closeColumnButton() {
    this.addColumn = !this.addColumn;
    this.selectedColor = DealStatusColors[0];
    if (this.type === 'deal') {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_board_newStatus_cancel,
      });
    }
  }

  eventAddColumn() {
    // @ts-ignore
    this.lastCreatedCardTitle = null;
    if (!this.columnName) {
      // @ts-ignore
      this.columnName = null;
      this.addColumn = !this.addColumn;
      return;
    }

    if (
      // @ts-ignore
      this.kanban.columns
        ?.map((i) => i.toLowerCase())
        ?.includes(this.columnName?.toLocaleLowerCase())
    ) {
      // @ts-ignore
      this.columnName = null;
      this.addColumn = !this.addColumn;
      this.alertService.error(this.i18n.instant('kanban.errors.error1'));
      return;
    }

    // @ts-ignore
    this.kanban.columns.push(this.columnName);
    // @ts-ignore
    this.kanban.columnsObjs.push({
      name: this.columnName,
      isSystemColumn: false,
      cards: [],
      color: this.selectedColor?.lightColor,
    });
    this.updateKanban();
    this.onAddColumn.emit(this.columnName);
    // @ts-ignore
    this.columnName = null;
    this.addColumn = !this.addColumn;
    this.draw();
  }

  eventCardClick($event) {
    // @ts-ignore
    this.lastCreatedCardTitle = null;
    this.onCardClick.emit($event);
  }

  eventCardDblClick($event) {
    // @ts-ignore
    this.lastCreatedCardTitle = null;
    this.onCardDblClick.emit($event);
  }

  eventAddCard($event: { column; card: KanbanCard }) {
    this.updateKanban();
    this.lastCreatedCardTitle = $event.card.title;
    this.onAddCard.emit($event);
  }

  eventEditColumn($event: {
    name: string;
    previousName: string;
    _id: string;
    column: { _id: string; name: string; color: string };
  }) {
    // @ts-ignore
    this.lastCreatedCardTitle = null;
    // @ts-ignore
    const previousColumn = this.kanban.columns.filter(
      (fil) => $event.previousName === fil
    );

    if (previousColumn.length == 0) {
      return;
    }

    // @ts-ignore
    const index = this.kanban.columns.indexOf(previousColumn[0]);

    // @ts-ignore
    const previousObj = this.kanban.columnsObjs.filter(
      (fil) => fil.name == $event.previousName
    );

    if (previousObj.length == 0) {
      return;
    }

    // @ts-ignore
    const indexObj = this.kanban.columnsObjs.indexOf(previousObj[0]);

    // @ts-ignore
    this.kanban.columns[index] = $event.name;
    // @ts-ignore
    this.kanban.columnsObjs[indexObj].name = $event.name;
    // @ts-ignore
    this.kanban.columnsObjs[indexObj].color = $event.column?.color;

    this.updateKanban(false);
    this.draw();

    this.onEditColumn.emit($event.column);
  }

  eventDeleteColumn($event: { column: column; cards: KanbanCard[] }) {
    // @ts-ignore
    this.lastCreatedCardTitle = null;
    this.unselectAllCards();
    // @ts-ignore
    const col = this.kanban.columns.filter((fil) => fil == $event.column.name);
    if (col.length > 0) {
      // @ts-ignore
      this.kanban.columns.splice(this.kanban.columns.indexOf(col[0]), 1);
    }

    // @ts-ignore
    const colObj = this.kanban.columnsObjs.filter(
      (fil) => fil.name == $event.column.name
    );
    if (colObj.length > 0) {
      // @ts-ignore
      this.kanban.columnsObjs.splice(
        // @ts-ignore
        this.kanban.columnsObjs.indexOf(colObj[0]),
        1
      );
    }

    this.toastService.show({
      text: this.i18n
        .instant('kanban.toast.columnDelete')
        .replace('{arg1}', this.i18n.instant(this.columnsName))
        .replace('{column}', $event.column.name),
      type: 'error',
      msDuration: 4000,
      icon: 'assets/icons/kanban/delete.svg',
    });

    this.updateKanban();
    this.onDeleteColumn.emit($event);
  }

  eventFinishCard($event) {
    // @ts-ignore
    this.lastCreatedCardTitle = null;
    if (this.type != 'deal') {
      const params = { ...this.finishToastParms };
      this.toastService.show(params);
    }
    this.onFinishCard.emit($event);
  }

  eventUnfinishCard($event) {
    // @ts-ignore
    this.lastCreatedCardTitle = null;
    if (this.type != 'deal') {
      const params = { ...this.unfinishToastParms };
      params.linkData = $event;
      this.toastService.show(params);
      this.toastService.onClickLink$.subscribe((res) => {
        if (res) {
          this.onUnfinishToastLinkClick.emit(res);
        }
      });
    }
    this.onUnfinishCard.emit($event);
  }

  eventEditCard($event) {
    // @ts-ignore
    this.lastCreatedCardTitle = null;
    this.onEditCard.emit($event);
  }

  eventDeleteCard($event) {
    // @ts-ignore
    this.lastCreatedCardTitle = null;
    this.updateKanban();
    const params = { ...this.deleteToastParms };
    this.toastService.show(params);
    this.onDeleteCard.emit($event);
  }

  eventCancelCard($event) {
    // @ts-ignore
    this.lastCreatedCardTitle = null;
    this.finishSelected = true;

    const actualSelection = this.selectedCards;
    // @ts-ignore
    for (let i = 0; i < this.kanban.columnsObjs.length; i++) {
      // @ts-ignore
      for (let y = this.kanban.columnsObjs[i].cards.length - 1; y > -1; y--) {
        const card = this.selectedCards.filter(
          // @ts-ignore
          (fil) => fil._id == this.kanban.columnsObjs[i].cards[y]._id
        );
        if (card.length > 0) {
          // @ts-ignore
          this.kanban.columnsObjs[i].cards[y].cancelled = true; //.splice(y, 1)
          //console.log(this.kanban.columnsObjs[i].cards[y]);
        }
      }
    }

    setTimeout(() => {
      this.deleteCanceledCards();
      this.unselectAllCards();
      this.finishSelected = false;
    }, 1000);

    // this.toastService.show({
    //   text: this.i18n
    //     .instant('kanban.toast.cancelMany')
    //     .replace('{qtty}', actualSelection.length)
    //     .replace('{arg1}', this.i18n.instant(this.cardsName)),
    //   type: 'success',
    //   msDuration: 4000,
    // });

    let ids: any = [];
    if (Array.isArray($event)) {
      ids = $event.map((i) => i._id);
    } else {
      ids.push($event._id);
    }

    this.onCancelCard.emit(ids);
  }

  eventMoveAllCards($event: {
    cards: KanbanCard[];
    column: column;
    previousColumn: column;
  }) {
    // @ts-ignore
    this.lastCreatedCardTitle = null;
    this.unselectAllCards();
    let indexPrevious = null;
    let index = null;

    // @ts-ignore
    index = this.kanban.columnsObjs.findIndex(
      (x) => x.name == $event.column.name
    );
    // @ts-ignore
    indexPrevious = this.kanban.columnsObjs.findIndex(
      (x) => x.name == $event.previousColumn.name
    );

    if (
      // @ts-ignore
      this.kanban.columnsObjs[indexPrevious].cards.some(
        (it) => it.finished == true
      )
    ) {
      this.toastService.show({
        text: 'kanban.errors.error2',
        type: 'error',
        msDuration: 4000,
      });
      return;
    }

    // @ts-ignore
    const quantityToMove = this.kanban.columnsObjs[indexPrevious].cards.length;

    // @ts-ignore
    this.kanban.columnsObjs[index].cards = this.kanban.columnsObjs[ // @ts-ignore
      index
      // @ts-ignore
    ].cards
      .concat(this.kanban.columnsObjs[indexPrevious].cards);
    // @ts-ignore
    this.kanban.columnsObjs[indexPrevious].cards = [];
    this.updateKanban();

    this.toastService.show({
      text: this.i18n
        .instant('kanban.toast.moveMany')
        .replace('{qtty}', quantityToMove)
        .replace('{arg1}', this.i18n.instant(this.cardsName))
        // @ts-ignore
        .replace('{column}', this.kanban.columnsObjs[index].name),
      type: 'success',
      msDuration: 4000,
    });
    this.onMoveAllCards.emit($event);
  }

  eventCardSelectChange($event) {
    // @ts-ignore
    this.lastCreatedCardTitle = null;
    this.onCardSelectChange.emit([$event.card]);
    this.selectedCardName = $event.title;
    if (this.type === 'deal') {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_board_select,
      });
    }
  }

  eventFinishSelectedCards() {
    // @ts-ignore
    this.lastCreatedCardTitle = null;
    this.finishSelected = true;
    const actualSelection = JSON.parse(JSON.stringify(this.selectedCards));
    // @ts-ignore
    for (let i = 0; i < this.kanban.columnsObjs.length; i++) {
      // @ts-ignore
      for (let y = this.kanban.columnsObjs[i].cards.length - 1; y > -1; y--) {
        const card = this.selectedCards.filter(
          // @ts-ignore
          (fil) => fil._id == this.kanban.columnsObjs[i].cards[y]._id
        );
        if (card.length > 0) {
          // @ts-ignore
          this.kanban.columnsObjs[i].cards[y].finished = true; //.splice(y, 1)
          //console.log(this.kanban.columnsObjs[i].cards[y]);
        }
      }
    }

    setTimeout(() => {
      this.deleteFinishedCards();
      this.unselectAllCards();
      this.finishSelected = false;
    }, 1000);
    this.toastService.show({
      text: this.i18n
        .instant('kanban.toast.finishMany')
        .replace('{qtty}', actualSelection.length)
        .replace('{arg1}', this.i18n.instant(this.cardsName)),
      type: 'success',
      msDuration: 4000,
    });
    this.onFinishSelectedCards.emit(actualSelection);
  }

  _searchValue;
  onKeySearch($event) {
    this._searchValue = $event.target.value;
  }

  deleteFinishedCards() {
    // @ts-ignore
    for (let i = 0; i < this.kanban.columnsObjs.length; i++) {
      // @ts-ignore
      for (let y = this.kanban.columnsObjs[i].cards.length - 1; y > -1; y--) {
        // @ts-ignore
        if (this.kanban.columnsObjs[i].cards[y].finished == true) {
          // @ts-ignore
          this.kanban.columnsObjs[i].cards.splice(y, 1);
        }
      }
    }
  }

  deleteCanceledCards() {
    // @ts-ignore
    for (let i = 0; i < this.kanban.columnsObjs.length; i++) {
      // @ts-ignore
      for (let y = this.kanban.columnsObjs[i].cards.length - 1; y > -1; y--) {
        // @ts-ignore
        if (this.kanban.columnsObjs[i].cards[y].cancelled == true) {
          // @ts-ignore
          this.kanban.columnsObjs[i].cards.splice(y, 1);
        }
      }
    }
  }

  eventUnfinishSelectedCards() {
    // @ts-ignore
    this.lastCreatedCardTitle = null;
    const actualSelection = JSON.parse(JSON.stringify(this.selectedCards));
    // @ts-ignore
    for (let i = 0; i < this.kanban.columnsObjs.length; i++) {
      // @ts-ignore
      for (let y = this.kanban.columnsObjs[i].cards.length - 1; y > -1; y--) {
        const card = this.selectedCards.filter(
          // @ts-ignore
          (fil) => fil._id == this.kanban.columnsObjs[i].cards[y]._id
        );
        if (card.length > 0) {
          // @ts-ignore
          this.kanban.columnsObjs[i].cards.splice(y, 1);
        }
      }
    }
    this.unselectAllCards();

    const params = { ...this.unfinishSelectionToastParms };
    params.linkData = actualSelection;
    params.text = this.i18n
      .instant(params.text)
      .replace('{qtty}', actualSelection.length)
      .replace('{arg1}', this.i18n.instant(this.cardsName));
    this.toastService.show(params);
    this.toastService.onClickLink$.subscribe((res) => {
      if (res) {
        this.onUnfinishSelectionToastLinkClick.emit(res);
      }
    });
    this.onUnfinishSelectedCards.emit(actualSelection);
  }

  eventMoveSelectedCards($event) {
    // @ts-ignore
    this.lastCreatedCardTitle = null;
    const actualSelection = JSON.parse(JSON.stringify(this.selectedCards));
    // @ts-ignore
    const indexColumn = this.kanban.columnsObjs.findIndex(
      (it) => it.name == $event.name
    );

    if (this.selectedCards.some((it) => it.finished == true)) {
      this.toastService.show({
        text: 'kanban.errors.error2',
        type: 'error',
        msDuration: 4000,
      });
      return;
    }

    // @ts-ignore
    for (let i = 0; i < this.kanban.columnsObjs.length; i++) {
      // @ts-ignore
      if (this.kanban.columnsObjs[i].name == $event.name) {
        continue; //ignore the same column where we move
      }
      for (let y = 0; y < actualSelection.length; y++) {
        // @ts-ignore
        const card = this.kanban.columnsObjs[i].cards.filter(
          (fil) => fil._id == actualSelection[y]._id
        );
        if (card.length > 0) {
          // @ts-ignore
          const index = this.kanban.columnsObjs[i].cards.indexOf(card[0]);
          // @ts-ignore
          this.kanban.columnsObjs[indexColumn].cards.push(
            // @ts-ignore
            this.kanban.columnsObjs[i].cards[index]
          );
          // @ts-ignore
          this.kanban.columnsObjs[i].cards.splice(index, 1);
        }
      }
    }
    this.toastService.show({
      text: this.i18n
        .instant('kanban.toast.moveMany')
        .replace('{qtty}', actualSelection.length)
        .replace('{arg1}', this.i18n.instant(this.cardsName))
        .replace('{column}', $event.name),
      type: 'success',
      msDuration: 4000,
    });
    this.updateKanban();
    this.unselectAllCards();
    this.onMoveSelectedCards.emit({
      column: $event,
      cards: actualSelection,
    });
  }

  emitEventAmplitudeDelete(): void {
    if (this.type === 'deal') {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_board_select_delete_start,
      });
    }
  }

  eventUnselectCards() {
    // @ts-ignore
    this.lastCreatedCardTitle = null;
    // @ts-ignore
    for (let i = 0; i < this.kanban.columnsObjs.length; i++) {
      // @ts-ignore
      for (let y = 0; y < this.kanban.columnsObjs[i].cards.length; y++) {
        // @ts-ignore
        if (this.kanban.columnsObjs[i].cards[y].selected == true) {
          // @ts-ignore
          this.kanban.columnsObjs[i].cards[y].selected = false;
        }
      }
    }
    this.onCardSelectChange.emit(this.selectedCards);
  }

  eventRestoreCard($event: KanbanCard) {
    // @ts-ignore
    this.lastCreatedCardTitle = null;
    const params = { ...this.restoreToastParms };
    params.linkData = $event;
    this.toastService.show(params);
    this.toastService.onClickLink$.subscribe((res) => {
      if (res) {
        this.onRestoreToastLinkClick.emit(res);
      }
    });
    this.onRestoreCard.emit($event);
  }

  evenRestoreSelectedCards() {
    // @ts-ignore
    this.lastCreatedCardTitle = null;
    const actualSelection = JSON.parse(JSON.stringify(this.selectedCards));
    // @ts-ignore
    for (let i = 0; i < this.kanban.columnsObjs.length; i++) {
      // @ts-ignore
      for (let y = this.kanban.columnsObjs[i].cards.length - 1; y > -1; y--) {
        const card = this.selectedCards.filter(
          // @ts-ignore
          (fil) => fil._id == this.kanban.columnsObjs[i].cards[y]._id
        );
        if (card.length > 0) {
          // @ts-ignore
          this.kanban.columnsObjs[i].cards.splice(y, 1);
        }
      }
    }
    this.toastService.show({
      text: this.i18n
        .instant('kanban.toast.restoreMany')
        .replace('{qtty}', actualSelection.length)
        .replace('{arg1}', this.i18n.instant(this.cardsName)),
      type: 'success',
      msDuration: 4000,
    });
    this.unselectAllCards();
    this.onRestoreSelectedCards.emit(actualSelection);
  }

  changeColor(evt: { darkColor: string; lightColor: string }) {
    if (this.type === 'deal') {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_board_newStatus_color,
      });
    }
    this.selectedColor = evt;
    this.onChangeColor.emit(this.selectedColor);
    this.draw();
  }

  eventChangeStatus($event) {
    this.onChangeStatus.emit($event);
  }

  openDelete(): void {
    this.lastCreatedCardTitle = null;
    if (this.selectedCards.some((it) => it.finished)) {
      this.toastService.show({
        text: 'kanban.errors.error3',
        type: 'error',
        msDuration: 4000,
      });
      return;
    }
    this.emitEventAmplitudeDelete();
    const actualSelection = JSON.parse(JSON.stringify(this.selectedCards));

    const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
      panelClass: 'delete-by-selection-modal',
      data: {
        isPlural: actualSelection.length > 1,
        body:
          this.type === 'deal'
            ? this.i18n.instant('deleteModal.body_final')
            : undefined,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'EXECUTE') {
        this.kanban.columnsObjs.forEach((column) => {
          column.cards = column.cards.filter((card) => {
            return !this.selectedCards.some(
              (selectedCard) => selectedCard._id === card._id
            );
          });
        });
        this.toastService.show({
          text: this.i18n
            .instant('kanban.toast.deleteMany')
            .replace('{qtty}', actualSelection.length)
            .replace('{arg1}', this.i18n.instant(this.cardsName)),
          type: 'error',
          msDuration: 4000,
          icon: 'assets/icons/kanban/delete.svg',
        });
        this.unselectAllCards();
        this.onDeleteSelectedCards.emit(actualSelection);
      }
    });
  }
}
