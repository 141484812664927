import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TacliaPayments } from '@tacliatech/types';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private http: HttpClient) {}

  updateStatus() {
    return this.http.post<TacliaPayments.IStripe>(
      ':API_URL/payments/snackbar-closed',
      {}
    );
  }
}
