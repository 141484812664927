import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ValidateAllFormFields } from '@tacliatech/types';

import { AlertService } from '@web-frontend/shared/helpers/alert';

@Component({
  selector: 'roma-phone-manager',
  templateUrl: './phone-manager.component.html',
  styleUrls: ['./phone-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneManagerComponent implements OnInit {
  @Input()
  phones: string[];

  @Input()
  maxPhonesAllow: number;

  @Input()
  inputPh = 'finals.addPhone';

  @Input()
  showPhoneIcon = true;

  @Input()
  type: 'tel' | 'text' | 'email' = 'tel';

  @Output()
  addPhone = new EventEmitter<string>();

  @Output()
  removePhone = new EventEmitter<string>();

  @Output()
  changePhones = new EventEmitter<string[]>();

  form = new FormGroup({
    phone: new FormControl(null, [Validators.required]),
  });

  constructor(
    private alertService: AlertService,
    private i18n: TranslateService
  ) {}

  ngOnInit(): void {}

  submit() {
    const isValid = this.checkValidators();
    const hasMaxPhonesAllowed = this.checkMaxLength();

    if (isValid && hasMaxPhonesAllowed) {
      this.emitPhone();
    }
  }

  remove(phone: string) {
    const phones = this.phones.filter((item) => item !== phone);

    this.phones = [...phones];

    this.removePhone.emit(phone);
    this.changePhones.emit(this.phones);
  }

  private emitPhone() {
    const value = this.form.get('phone').value;

    if (value) {
      this.phones = [...this.phones, value];
      this.form.reset();

      this.addPhone.emit(value);
      this.changePhones.emit(this.phones);
    }
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);

    return this.form.valid;
  }

  private checkMaxLength() {
    if (this.maxPhonesAllow) {
      if (this.phones?.length >= this.maxPhonesAllow) {
        this.alertService.error(this.i18n.instant(`phoneManager.maxLength`));
        return false;
      }
    }

    return true;
  }
}
