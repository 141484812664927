import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PaginateResponse, Sale } from '@tacliatech/types';

@Injectable({
  providedIn: 'root',
})
export class SalesSearchService {
  constructor(private http: HttpClient) {}

  search(
    query: { [key: string]: any } = {},
    options = {
      automaticallyHandleCancelled: true,
    }
  ) {
    const params = new HttpParams({
      fromObject: {
        ...query,
      },
    });

    return this.http
      .get<PaginateResponse<Sale.Output[]>>(`:API_URL/search/sales`, {
        params,
      })
      .pipe(
        map((res) => {
          let docs = res?.docs ? res.docs : [];

          docs = docs.map((el) => {
            return {
              ...el,
            };
          });

          return {
            ...res,
            docs,
          };
        })
      );
  }
}
