export const IsJiraTime = (value: string) => {
  const regexPattern = /[0-9]+[h|m|H|M]?\s?([0-9]+[m|M]\s?)?/g;

  const matching = value.match(regexPattern);

  if (matching?.length) {
    const [first] = matching;

    const parser = Array.from(first.toLowerCase());

    if (parser.includes('d') || parser.includes('m') || parser.includes('h')) {
      return true;
    }
  }

  return false;
};

export const fromJiraTimeToMinutes = (value: string) => {
  const factoryValue = value.toLowerCase().replace(/\s+/g, ' ');
  const elementsValue = factoryValue.split(' ');
  const keysMap = new Map<'d' | 'h' | 'm', number>();

  const MinutesFromDay = 1440;
  const MinutesFromHour = 60;

  let total = 0;

  for (const element of elementsValue) {
    if (element.endsWith('d')) {
      const parser = element.replace('d', '');

      keysMap.set('d', +parser);
    } else if (element.endsWith('h')) {
      const parser = element.replace('h', '');

      keysMap.set('h', +parser);
    } else if (element.endsWith('m')) {
      const parser = element.replace('m', '');

      keysMap.set('m', +parser);
    }
  }

  const days = keysMap.get('d');
  const hours = keysMap.get('h');
  const minutes = keysMap.get('m');

  if (days) {
    total += days * MinutesFromDay;
  }

  if (hours) {
    total += hours * MinutesFromHour;
  }

  if (minutes) {
    total += minutes;
  }

  return {
    duration: total,
    parserTime: factoryValue,
  };
};

export const timeConvert = (time: number) => {
  let t = '';

  const j = time / (24 * 60);
  const h = (time % (24 * 60)) / 60;
  const m = (time % (24 * 60)) % 60;

  t = j + ':' + h + ':' + m;

  return t;
};
