<div class="sendDigitalCertificateBody" *ngIf="!sentOk">
  <div class="image-div">
    <img
      class="send-digital-icon"
      src="/assets/icons/bill-digital-certificate/send-digital-icon.svg"
    />
  </div>
  <div class="title-div">
    {{ 'billDigitalCerficiate.sendTitle' | translate }}
  </div>
  <div class="subtitle-div">
    {{ 'billDigitalCerficiate.sendSubtitle' | translate }}
    <img
      tooltipDir
      [tooltipBackground]="'blue'"
      [tooltipMessages]="[
        'billDigitalCerficiate.toolTips.digitalCertificateTooltip1',
        'billDigitalCerficiate.toolTips.digitalCertificateTooltip2',
        {
          type: 'html',
          content:
            'billDigitalCerficiate.toolTips.digitalCertificateTooltipLink'
        }
      ]"
      [tooltipPosition]="'bottom'"
      [tooltipShowOn]="'any'"
      [tooltipHideOn]="'click'"
      src="/assets/icons/gl_information-fill-gray.svg"
    />
  </div>
  <div class="text1-div">
    {{ 'billDigitalCerficiate.sendText1' | translate }}
  </div>
  <div class="file-upload-div">
    <file-upload
      [fileAdmitData]="!isMobile ? ['cer'] : ['*']"
      [label]="'imports.fileUpload.cer'"
      [disable]="isLoading"
      [showErrorTexts]="true"
      (onFilesChange)="onCerFileChange($event)"
    >
    </file-upload>
  </div>
  <div class="file-upload-div">
    <file-upload
      [fileAdmitData]="!isMobile ? ['key'] : ['*']"
      [label]="'imports.fileUpload.key'"
      [disable]="isLoading"
      [showErrorTexts]="true"
      (onFilesChange)="onKeyFileChange($event)"
    >
    </file-upload>
  </div>
  <div class="password-div">
    <div>
      {{ 'billDigitalCerficiate.password' | translate }}
      <img
        tooltipDir
        [tooltipBackground]="'blue'"
        [tooltipMessages]="['billDigitalCerficiate.toolTips.password']"
        [tooltipPosition]="'bottom'"
        [tooltipShowOn]="'any'"
        src="/assets/icons/gl_information-fill-gray.svg"
      />
    </div>
    <div>
      <input-password
        minLength="0"
        [showPasswordChecked]="true"
        [disable]="isLoading"
        (onChange)="onPasswordChange($event)"
      ></input-password>
    </div>
  </div>
  <div class="button-div">
    <button
      class="roma-button add-button-3"
      [ngClass]="{ disable: isButtonDisabled }"
      (click)="onContinue()"
    >
      {{ 'general.continue' | translate }}
    </button>
  </div>
  <div class="footer-div">
    {{ 'billDigitalCerficiate.sendFooter' | translate }}
    <a
      href="https://academy.taclia.com/kb-tickets/new?_gl=1*16gtpk8*_gcl_au*MjU5NDEyMTA3LjE2OTk4NjU2OTE."
      target="_blank"
      (click)="contactUs()"
      >{{ 'billDigitalCerficiate.sendFooterHref' | translate }}</a
    >
  </div>
</div>
<digital-certificate-status
  *ngIf="sentOk"
  [status]="'justSent'"
></digital-certificate-status>
