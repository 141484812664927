<div [ngClass]="[data.class, data.description && 'has-description']">
  <div class="stoast-body">
    <div class="stoast-icon">
      <img [src]="data.icon" />
    </div>
    <div class="stoast-text">
      <div class="stoast-body-text" [innerHtml]="data.text | translate"></div>
      <ng-container *ngIf="data.description">
        <div
          class="stoast-body-description"
          [innerHtml]="data.description | translate"
        ></div>
      </ng-container>
    </div>
  </div>
  <div class="stoast-link" *ngIf="data.link != null" (click)="eventClickLink()">
    {{ data.link | translate }}
  </div>
  <div class="stoast-close" *ngIf="data.showClose">
    <img src="assets/icons/gl_close.svg" (click)="close()" />
  </div>
</div>
