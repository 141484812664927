import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { USER_ID_KEY } from '@web-frontend/shared/constants';
import { AuthService } from '@web-frontend/shared/services';
import { StorageService } from '@web-frontend/shared/services/storage/storage.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private amplitudeService: AmplitudeService
  ) {}

  public canActivate() {
    const keepOnboarding = localStorage.getItem('register');
    if (keepOnboarding) {
      this.router.navigateByUrl('/login/client-register');
      return false;
    }
    const key = StorageService.GetItem(USER_ID_KEY);
    if (!key) {
      this.authService.logout();
      this.router.navigateByUrl('/auth/login');
      return false;
    }
    const user = this.authService.user;

    if (user && user.deleted) {
      this.authService.logout();
      this.router.navigateByUrl('/auth/login');
      return false;
    }

    const data = {
      page: this.router.url,
      event: 'page_event',
    };

    this.amplitudeService.sendPageEvent(data);
    return true;
  }
}
