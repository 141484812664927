import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RmFilter } from '../rm-filter.types';
import { RmFilterModalDashboardDetail } from './rm-filter-modal-dashboard.types';

@Component({
  selector: 'roma-rm-filter-modal-dhbd-detail',
  templateUrl: './rm-filter-modal-dhbd-detail.component.html',
  styleUrls: ['./rm-filter-modal-dhbd-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmFilterModalDhbdDetailComponent implements OnInit {
  @Input()
  select: RmFilter.Select;

  searchParams = {
    keyword: '',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: RmFilterModalDashboardDetail.Params,

    private dialogRef: MatDialogRef<
      RmFilterModalDhbdDetailComponent,
      RmFilterModalDashboardDetail.Result
    >,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.patchParams();
  }

  back() {
    this.dialogRef.close({
      source: this.select,
    });
  }

  search(evt: KeyboardEvent) {
    this.searchParams = {
      keyword: (evt.target as HTMLInputElement).value,
    };

    this.draw();
  }

  changeCheckbox(evt: MouseEvent, item: RmFilter.SelectMultipleItem) {
    let values = this.select.value();
    const value = item.value;

    evt.stopPropagation();
    evt.preventDefault();

    if (values.includes(value)) {
      values = values.filter((el) => el !== value);
    } else {
      if (this.select.type === 'MULTIPLE') {
        values.push(value);
      } else {
        values = [value];
      }
    }

    this.select.data$
      .subscribe((el) => {
        const items = el.filter((item) => values.includes(item.value));
        this.select.setValue(items);
        this.draw();
      })
      .unsubscribe();
  }

  private patchParams() {
    this.select = this.data.source;
    this.select.load();
    this.draw();
  }

  private draw() {
    this.cdRef.detectChanges();
  }
}
