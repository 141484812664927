<div class="line"></div>
<div class="absence-summary-counter-container">
  <h2>{{ title }}</h2>
  <ul class="absence-summary-counter-list">
    <li
      *ngFor="let absence of absenceCounts"
      class="absence-summary-counter-item"
    >
      <div class="absence-summary-counter-type">
        <div
          class="absence-color"
          [style.backgroundColor]="absence.color"
        ></div>
        <div class="absence-details">
          <span class="absence-type">{{
            absence.displayName | translate
          }}</span>
          <span
            class="absence-summary-counter-count"
            aria-label="{{ absence.count }} {{ absence.type }}"
            >{{ absence.count }}</span
          >
        </div>
      </div>
    </li>
  </ul>
</div>
