import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ModalConfirmPaymentComponent } from './modal-confirm-payment/modal-confirm-payment.component';
import { TacliaPayments } from '@tacliatech/types';

@Injectable({
  providedIn: 'root',
})
export class ConfirmPaymentService {
  private resultSource = new Subject<TacliaPayments.CreditCard.ModalResponse>();

  result$ = this.resultSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(config?: MatDialogConfig<TacliaPayments.CreditCard.ModalRequest>) {
    const dialogRef = this.dialog.open(ModalConfirmPaymentComponent, config);

    return dialogRef.afterClosed().pipe(
      tap((res: TacliaPayments.CreditCard.ModalResponse) => {
        this.resultSource.next(res);
      })
    );
  }
}
