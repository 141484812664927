import { Injectable } from '@angular/core';

import { IStatusExpense, TypeExpense } from '@tacliatech/types';

@Injectable({
  providedIn: 'root',
})
export class TypeExpenseService {
  statusExpenses: IStatusExpense[] = [
    {
      id: 1,
      name: 'PAID',
      translate: 'general.status-paid',
    },
    {
      id: 2,
      name: 'PENDING',
      translate: 'general.status-pending',
    },

    {
      id: 3,
      name: 'DUE',
      translate: 'general.status-due',
    },
  ];

  typeExpenses: TypeExpense[] = [
    {
      id: 1,
      name: 'EXPENSE TICKET',
      translate: 'general.expsense-ticket',
    },
    {
      id: 2,
      name: 'PURCHASE TICKET',
      translate: 'general.purchase-ticket',
    },
  ];
  constructor() {}

  findAll(): TypeExpense[] {
    return this.typeExpenses;
  }

  findAllStatusExpenses() {
    return this.statusExpenses;
  }
}
