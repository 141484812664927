import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  BillingAddress,
  BudgetsSetting,
  ValidateAllFormFields,
  countryCodesEN,
  countryCodesES,
  Lang,
} from '@tacliatech/types';

import { StorageService } from '@web-frontend/shared/services';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { BudgetUtilisService } from '../../../core/admin/budgets/budget-edit/budgets-edit.component';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { CompanyService } from '@web-frontend/shared/services/company';

@Component({
  selector: 'roma-budget-billing-data',
  templateUrl: './budget-billing-data.component.html',
  styleUrls: ['./budget-billing-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetBillingDataComponent implements OnInit {
  @Input()
  id: string;

  @Input()
  budget: BudgetsSetting;

  @Input()
  mode: 'ADD' | 'EDIT' = 'ADD';

  private sub$ = new Subscription();

  countryCodes: any = countryCodesES;

  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private budgetService: BudgetService,
    private budgetUtilisService: BudgetUtilisService,
    private toastService: ToastService,
    private i18n: TranslateService,
    private companyService: CompanyService
  ) {
    const LANG = StorageService.GetItem('USER_LANG') as Lang;

    this.countryCodes = LANG === 'en' ? countryCodesEN : countryCodesES;
  }

  form: FormGroup = this.fb.group({
    address: new FormControl(null, [Validators.required]),
    city: new FormControl(null, [Validators.required]),
    postalcode: new FormControl(null, [Validators.required]),
    state: new FormControl(null, [Validators.required]),
    country: new FormControl(null, [Validators.required]),
    owner_id: new FormControl(StorageService.CompanyId, [Validators.required]),
  });

  ngOnInit(): void {
    this.checkMode();
  }

  checkMode() {
    if (this.mode === 'ADD') {
      this.budget = new BudgetsSetting();
    } else {
      this.budget.billing_addres =
        this.budget.billing_addres === undefined
          ? (this.budget.billing_addres = new BillingAddress())
          : this.budget.billing_addres;

      const filter: any = this.countryCodes.filter(
        (e) =>
          e.code.includes(this.budget.billing_addres.country) ||
          e.name.includes(this.budget.billing_addres.country)
      );

      this.form.patchValue({
        address: this.budget.billing_addres.address,
        city: this.budget.billing_addres.city,
        postalcode: this.budget.billing_addres.postalcode,
        state: this.budget.billing_addres.state,
        country: filter ? filter[0]?.name : this.budget.billing_addres.country,
      });
    }
    //  this.form.markAllAsTouched();
    // this.checkValidators()
    this.draw();
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);
    return this.form.valid;
  }

  isFormValid() {
    return this.form.valid;
  }

  submit() {
    const isValid = this.checkValidators();

    if (isValid) {
      const values = this.form.value;
      this.setObject(values);

      const obs$ =
        this.mode === 'EDIT'
          ? this.budgetService.updateOneSettings(this.id, this.budget)
          : this.budgetService.createOneSetting(this.budget);

      this.sub$.add(
        obs$.subscribe(
          (res) => {
            this.budgetUtilisService.refreshBudgetSettings.emit(res);
            this.draw();

            if (this.mode === 'EDIT') {
              this.toastService.show({
                text: this.i18n.instant('budgets.settings.successEdit'),
                type: 'success',
                msDuration: 4000,
              });
            } else {
              this.mode = 'EDIT';
              this.toastService.show({
                text: this.i18n.instant('budgets.settings.successCreated'),
                type: 'success',
                msDuration: 4000,
              });
            }
          },
          (error) => {
            this.toastService.show({
              text: this.i18n.instant('activity.error'),
              type: 'error',
              msDuration: 4000,
            });
          }
        )
      );
    } else {
      this.form.markAllAsTouched();
    }
  }

  setObject(values) {
    this.budget.billing_addres.address = values.address;
    this.budget.billing_addres.city = values.city;
    this.budget.billing_addres.postalcode = values.postalcode;
    this.budget.billing_addres.state = values.state;
    this.budget.billing_addres.country = values.country.code;
    this.budget.idOwner = values.owner_id;
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }
}
