<div class="header-primary">
  <div class="header-controls">
    <div class="header-option">
      <mat-icon
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="changeView()"
      >
        arrow_back_ios
      </mat-icon>
    </div>

    <div class="header-option">
      <mat-icon
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="changeView()"
      >
        arrow_forward_ios
      </mat-icon>
    </div>
  </div>

  <h3
    class="header-title"
    mwlCalendarToday
    [(viewDate)]="viewDate"
    (viewDateChange)="changeView()"
  >
    {{
      viewDate
        | calendarDate
          : view + 'ViewTitle'
          : locale
          : defaultSettings?.budgetPreferences?.day
    }}
  </h3>

  <mat-chip-list aria-label="Change view">
    <mat-chip
      *ngFor="let viewEl of schedulesTypes"
      class="chip-view"
      [ngClass]="{ active: viewEl === view }"
      (click)="changeTypeViews(viewEl.value)"
    >
      {{ viewEl.name | translate | uppercase }}
    </mat-chip>
  </mat-chip-list>
</div>

<roma-loading *ngIf="loading"></roma-loading>
<div *ngIf="!loading" [ngSwitch]="view">
  <mwl-calendar-month-view
    *ngSwitchCase="CalendarView.Month"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    [locale]="locale"
    [activeDayIsOpen]="activeDayIsOpen"
    [cellTemplate]="customCellTemplate"
    [weekStartsOn]="defaultSettings?.budgetPreferences?.day"
    (eventClicked)="eventClick($event.event)"
    #monthly
    (drop)="eventDropped($event)"
  >
  </mwl-calendar-month-view>
  <mwl-calendar-week-view
    *ngSwitchCase="CalendarView.Week"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    [locale]="locale"
    [eventTemplate]="weekEventTemplate"
    [weekStartsOn]="defaultSettings?.budgetPreferences?.day"
    [dayStartHour]="0"
    (eventClicked)="eventClick($event.event)"
    (eventTimesChanged)="eventDroppedDay($event)"
  >
  </mwl-calendar-week-view>
  <mwl-calendar-day-view
    *ngSwitchCase="CalendarView.Day"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    [locale]="locale"
    [weekStartsOn]="defaultSettings?.budgetPreferences?.day"
    [eventTemplate]="weekEventTemplate"
    [dayStartHour]="0"
    (eventClicked)="eventClick($event.event)"
    (eventTimesChanged)="eventDroppedDay($event)"
  >
  </mwl-calendar-day-view>
</div>

<ng-template #weekEventTemplate let-day="day" let-weekEvent="weekEvent">
  <div
    [ngStyle]="{
      'background-color': weekEvent.event.color.third,
      'border-color': weekEvent.event.color.primary,
      color: weekEvent.event.color.secondary,
      width: '100%',
      height: '100%',
      'box-shadow': '0 2px 4px rgba(0, 0, 0, 0.2)',
      display: 'flex',
      'align-items': 'center',
      'justify-content': 'center',
      'text-align': 'center'
    }"
    style="padding: 2px"
    mwlDraggable
    [dropData]="{ event: weekEvent, date: weekEvent.date }"
    [dragAxis]="{ x: weekEvent.draggable, y: weekEvent.draggable }"
    (click)="redirectToDealDetail(weekEvent.event)"
  >
    <span class="name" [title]="weekEvent.event.title">{{
      showTitleInMobile(weekEvent)
    }}</span>
  </div>
</ng-template>

<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div
    mwlDroppable
    (drop)="dateDragEnter($event, day)"
    class="droppable-wrapper"
    (click)="changeToViewEvent(day.date)"
  >
    <div class="cal-cell-top" style="min-height: 50px">
      <span class="cal-day-number">
        {{ day.date | calendarDate: 'monthViewDayNumber':locale }}
      </span>
    </div>
    <ng-container *ngIf="day.events.length <= eventLimit; else EventLimit">
      <small
        *ngFor="
          let e of day.events | slice: 0:eventLimit;
          trackBy: trackByEventId
        "
        class="day-overlay"
        [ngStyle]="{
          'background-color': e.color.third,
          'border-color': e.color.primary,
          color: e.color.secondary
        }"
        style="padding: 2px"
        class="title event-info"
        mwlDraggable
        [dropData]="{ event: e, date: day.date }"
        [dragAxis]="{ x: e.draggable, y: e.draggable }"
        (click)="redirectToDealDetail(e)"
      >
        <span class="time">{{ e.start | date: 'HH:mm' }} </span>
        <span class="name">{{ e.title }}</span>
      </small>
      <!--<span class="day-overlay" (click)="changeToViewEvent(day.date)"> </span>-->
    </ng-container>

    <ng-template
      class="day-overlay"
      #EventLimit
      (click)="changeToViewEvent(day.date)"
    >
      <small
        *ngFor="
          let e of day.events | slice: 0:eventLimit;
          trackBy: trackByEventId
        "
        [ngStyle]="{
          'background-color': e.color.third,
          'border-color': e.color.primary,
          color: e.color.secondary
        }"
        style="padding: 2px"
        class="title event-info"
        mwlDraggable
        [dropData]="{ event: e, date: day.date }"
        [dragAxis]="{ x: e.draggable, y: e.draggable }"
        (click)="redirectToDealDetail(e)"
      >
        <span class="time">{{ e.start | date: 'HH:mm' }} </span>
        <span class="name">{{ e.title }}</span>
      </small>
      <span class="day-overlay" (click)="changeToViewEvent(day.date)">
        <mat-icon class="show-more-icons" (click)="changeToViewEvent(day.date)">
          more_horiz
        </mat-icon>
      </span>
    </ng-template>
  </div>
</ng-template>
