import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';

import { NgSelectModule } from '@ng-select/ng-select';

import { ModalTemplateModule } from '@web-frontend/shared/components/modal-template';
import { FieldErrorDisplayModule } from '@web-frontend/shared/components/field-error-display';

import { EquipmentNotesModule } from '@web-frontend/shared/components/equipments-notes';
import { ModalInfoModule } from '@web-frontend/shared/components/modal-info';
import { PhoneManagerModule } from '@web-frontend/shared/components/phone-manager';
import { AppendCustomPropertiesModule } from '@web-frontend/shared/components/append-custom-properties';

import { AddInternalVendorComponent } from './add-internal-vendor.component';
import { AddInternalVendorService } from './add-internal-vendor.service';
import { ImageSelectorModule } from '@web-frontend/shared/components/image-selector';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { ObligatoryFieldSignModule } from '@web-frontend/shared/components/obligatory-field-sign/obligatory-field-sign.module';
import { BaseButtonModule } from '@web-frontend/common/components/button/baseButton.module';

@NgModule({
  declarations: [AddInternalVendorComponent],
  imports: [
    CommonModule,
    ModalTemplateModule,
    TranslateModule.forChild(),
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    PhoneManagerModule,
    FieldErrorDisplayModule,
    EquipmentNotesModule,
    ModalInfoModule,
    AppendCustomPropertiesModule,
    ImageSelectorModule,
    MatProgressSpinnerModule,
    ClickDeleteModule,
    ObligatoryFieldSignModule,
    BaseButtonModule,
  ],
  exports: [AddInternalVendorComponent],
  providers: [AddInternalVendorService],
})
export class AddInternalVendorModule {}
