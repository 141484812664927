import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ClockHourView } from '../../../../core/admin/clock-hour/clock-hour.type';

@Component({
  selector: 'roma-week-month-switch',
  templateUrl: './week-month-switch.component.html',
  styleUrls: ['./week-month-switch.component.scss'],
})
export class WeekMonthSwitchComponent implements OnChanges {
  @Input() viewPeriod: ClockHourView;
  @Output() viewPeriodChange = new EventEmitter<ClockHourView>();

  constructor(private readonly cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.viewPeriod) {
      this.viewPeriod = changes.viewPeriod.currentValue;
      this.cd.detectChanges();
    }
  }

  clockHourViewRef = ClockHourView;

  changeClockHourView(view: ClockHourView) {
    this.viewPeriodChange.emit(view);
  }
}
