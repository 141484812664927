<div class="phone__select__wrapper">
  <div class="input__wrapper">
    <div
      class="prefix__wrapper"
      [ngClass]="{
        is__active: showPrefixList
      }"
      (click)="prefixListToggle()"
    >
      <input
        #inputFilterSearch
        id="prefixInput"
        type="text"
        class="prefix__input"
        [ngClass]="inputClass"
        [placeholder]="'finals.create.prefix' | translate"
        [(ngModel)]="selectedPrefix"
        (keypress)="omitSpecialChar($event)"
        (change)="phoneChangesEvent()"
      />
      <img
        class="prefix__arrow"
        [ngClass]="inputClass"
        src="assets/icons/gl_down_arrow.svg"
      />
    </div>
    <div
      class="phone__wrapper"
      [ngClass]="{
        is__active: showPrefixList
      }"
    >
      <input
        romaOnlyNumber
        type="tel"
        name="tel"
        maxDecimals="0"
        autocomplete="tel"
        inputmode="numeric"
        class="phone__input"
        maxlength="15"
        [ngClass]="inputClass"
        [placeholder]="'finals.create.phonePh' | translate"
        [value]="selectedNumber"
        (keypress)="numberOnly($event)"
        (blur)="onPhoneChange($event.target.value)"
      />
    </div>
  </div>

  <ng-container *ngIf="!isResponsive">
    <div class="country__codes__list" *ngIf="showPrefixList">
      <div
        class="country__codes__list__prefix single-check"
        *ngFor="let prefix of countryCodes | searchFilter: filterArgs"
        (click)="selectPrefix(prefix.dial_code)"
        [ngClass]="{
          active: selectedPrefix === prefix.dial_code
        }"
      >
        <img
          *ngIf="selectedPrefix === prefix.dial_code"
          class="active-check"
          src="assets/icons/gl_check.svg"
        />
        {{ prefix.name }} ({{ prefix.dial_code }})
      </div>
    </div>
    <div
      *ngIf="showPrefixList"
      class="close__wrapper"
      (click)="prefixListToggle()"
    ></div>
  </ng-container>
</div>

<!-- mobile display -->
<ng-container *ngIf="isResponsive">
  <div class="list-dropdown-select">
    <div class="mobile-display">
      <div
        *ngIf="showPrefixList"
        class="mobile-list-wrapper"
        (click)="showPrefixList = !showPrefixList"
      ></div>
      <div *ngIf="showPrefixList" class="list-box">
        <h4>{{ 'finals.create.prefix' | translate }}</h4>
        <div *ngIf="isLoading" style="text-align: center; width: 100%">
          <mat-progress-spinner
            mode="indeterminate"
            [diameter]="60"
            style="margin: 40px auto"
          ></mat-progress-spinner>
        </div>
        <div *ngIf="!isLoading" class="search-input secondary">
          <input
            #mobileInputFilterSearch
            class="filter-input"
            type="text"
            [placeholder]="'general.search' | translate"
          />
        </div>
        <div class="list-wrapper">
          <div
            class="list-item"
            *ngFor="let prefix of countryCodes | searchFilter: filterArgs"
            [ngClass]="{ active: selectedPrefix === prefix.dial_code }"
            (click)="selectPrefix(prefix.dial_code)"
            style="padding-left: 28px"
          >
            <img
              *ngIf="selectedPrefix === prefix.dial_code"
              class="active-check"
              style="visibility: visible"
              src="assets/icons/gl_check.svg"
            />
            {{ prefix.name }} ({{ prefix.dial_code }})
          </div>
        </div>
        <div class="text-center close-btn-wrapper">
          <img
            (click)="prefixListToggle()"
            class="close-btn"
            src="/assets/icons/btn_close.svg"
            alt="close"
          />
        </div>
      </div>
    </div>
  </div>
</ng-container>
