import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { MapperPlaceResult } from '@web-frontend/shared/class/location';
import { Feature, fromJiraTimeToMinutes, IsJiraTime } from '@tacliatech/types';

import { EditableFieldService } from './editable-field.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { getDateStrResumStr } from '@web-frontend/shared/utils';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from '@web-frontend/shared/utils/custom-date-adapter';
import PlaceResult = google.maps.places.PlaceResult;
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';

@Component({
  selector: 'roma-editable-field',
  templateUrl: './editable-field.component.html',
  styleUrls: ['./editable-field.component.scss'],
  providers: [{ provide: DateAdapter, useExisting: CustomDateAdapter }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableFieldComponent implements OnInit, AfterViewInit {
  @ViewChild('inputEditable')
  inputEditable: ElementRef<HTMLInputElement>;

  @ViewChild('inputDateEditable')
  inputDateEditable: ElementRef<HTMLInputElement>;

  @ViewChild('inputNumberEditable')
  inputNumberEditable: ElementRef<HTMLInputElement>;

  @ViewChild('inputPhoneEditable')
  inputPhoneEditable: ElementRef<HTMLInputElement>;

  @ViewChild('selectArrayString')
  selectArrayString: ElementRef<HTMLInputElement>;

  @ViewChild('selectBoolean')
  selectBoolean: ElementRef<HTMLInputElement>;

  @Input()
  @HostBinding('class.block')
  block = false;

  @Input()
  @HostBinding('class.is-danger')
  isDanger = false;

  @Input()
  set value(value: Date | boolean | string | number | string[]) {
    this.currentValue = value;
  }

  get value() {
    return this.currentValue;
  }

  @Input()
  smSize = false;

  @Input()
  isRequired: boolean;

  // required when type is array-string
  @Input()
  elements: Array<string> = [];

  // required when type is array-string
  @Input()
  isMultiple = false;

  @Input()
  isCustomProperty = false;

  @Input()
  fieldName: string;

  @Input()
  maxLength = 255;

  @Input()
  params = {};

  @Input()
  style = 'native';

  @Input()
  module = '';

  @Input()
  label: string;

  @Input()
  labelPh: string;

  @Input()
  type:
    | 'text'
    | 'date'
    | 'number'
    | 'long-text'
    | 'boolean'
    | 'array-string'
    | 'array-string-multiple'
    | 'phone'
    | 'google-address'
    | 'jira-time' = 'text';

  @Input()
  apiUrl: string;

  @Input()
  showAlertMessage = true;

  @Input()
  editableClass: string;

  @Input()
  updateOnEdit = true;

  @Input()
  placeholderDate = 'general.date';

  @Input()
  maxLengthErrorMessage?: string;

  @Output()
  requestedApi = new EventEmitter<unknown>();

  @Input()
  feature: string = Feature.SystemPermission.DefaultAllow;

  @Output()
  checkInvalidField = new EventEmitter();

  @Output()
  onValueChanged = new EventEmitter<unknown>();

  @Output()
  updateLoading = new EventEmitter();

  isWarning = false;

  isFocus = false;

  currentValue: Date | boolean | string | number | string[];
  nativeInputValut;

  private sub$ = new Subscription();
  private flightRequest!: Observable<unknown>;

  company_date_format = this.budgetService.date_format
    ? this.budgetService.date_format
    : 'dd/MM/yyyy';

  constructor(
    private i18n: TranslateService,
    private changeDetectionRef: ChangeDetectorRef,
    private editableFieldService: EditableFieldService,
    private budgetService: BudgetService,
    private toastService: ToastService,
    private analyticsService: AnalyticsService
  ) {}

  ngAfterViewInit() {
    if (!['jira-time'].includes(this.type)) {
      this.currentValue = this.convertInput(this.currentValue);
      this.draw();
    }
    this.nativeInputValut = this.inputEditable.nativeElement;
  }

  ngOnInit() {}

  checkValidOnSubmit(isAvailable) {
    if (isAvailable) {
      return this.submit(this.nativeInputValut);
    } else {
      return false;
    }
  }

  inputFocus() {
    this.isFocus = true;
    this.draw();
  }

  resolveJiraTime(element?: HTMLInputElement) {
    const value = element?.value;

    const isJiraTime = IsJiraTime(value);

    if (!isJiraTime) {
      this.toastService.show({
        text: this.i18n.instant('general.resolveJiraTime'),
        type: 'error',
        msDuration: 4000,
      });
      this.isDanger = true;
      this.closeFocus();
    } else {
      this.isDanger = false;
      this.draw();
      this.submit(element);
    }
  }

  submitNgSelect(elements?) {
    if (elements.length) {
      if (this.isRequired) {
        this.isDanger = false;
        this.checkInvalidField.next(false);
        this.draw();
      }

      if (this.updateOnEdit == false) {
        this.onValueChanged.emit(elements);
        return false;
      }

      this.resolveRequest(elements);
      return false;
    } else {
      if (this.isRequired) {
        this.checkInvalidField.next(true);
        this.isDanger = true;
        this.draw();
        return true;
      }
      this.draw();
      return false;
    }
  }

  validateLength(element?: HTMLInputElement) {
    const value = element?.value;
    if (value.length == this.maxLength) {
      this.isWarning = true;
      this.draw();
      return true;
    }

    this.isWarning = false;
    this.draw();
    return false;
  }

  submit(element?: HTMLInputElement) {
    let val: any = element?.value;
    if (this.type?.includes('array')) {
      val = val.split(',');
      //prevent and empty array item
      if (val == '') {
        val = undefined;
      }
    }
    const value: any = this.convertOutput(val);
    this.nativeInputValut = element;

    const isEqual = this.isEqualData(value);
    this.isDanger = false;

    if (this.isRequired) {
      if (
        !value ||
        (this.type === 'number' ? isNaN(value) : value === '') ||
        value === null ||
        value === undefined ||
        (element.id === 'inputDateEditable' && !this.isValidDate(value))
      ) {
        this.checkInvalidField.next(true);
        this.isDanger = true;
        return true;
      } else {
        this.checkInvalidField.next(false);
      }
    }

    if (!isEqual) {
      this.resolveRequest(value);
    }

    this.closeFocus();
    this.draw();
    return false;
  }

  abort() {
    this.closeFocus();
  }

  isValidDate(dateObject) {
    if (dateObject === 'Invalid Date') {
      return false;
    }
    return true;
  }

  autoCompleteAddress(result: PlaceResult) {
    const geocoding = MapperPlaceResult(result);
    const isEqual = this.isEqualData(geocoding.formattedAddress);

    // add geocoding params TR-1931
    this.params = {
      ...this.params,
      latitude: geocoding.latitude.toString(),
      longitude: geocoding.longitude.toString(),
    };

    if (!isEqual) {
      this.resolveRequest(geocoding.formattedAddress);
    }

    this.closeFocus();
  }

  autoCompleteChange(value: string) {
    if (!this.flightRequest) {
      this.resolveRequest(value, false);
    }
  }

  convertInput(value: any) {
    let newValue: Date | string | number | boolean = value;

    try {
      if (this.type === 'date') {
        //solo formateamos los formatos yyyy-mm-dd
        if (value && !value?.includes('T')) {
          newValue = getDateStrResumStr(value).parseToISOStringLocal;
        } else {
          newValue = value;
        }
      } else if (this.type === 'boolean') {
        newValue = value;
      } else if (this.type === 'number') {
        newValue = +value;
      } else if (this.type === 'jira-time') {
        newValue = value?.parserTime || '';
      }
    } catch (err) {}

    return newValue;
  }

  private convertOutput(value: string) {
    let newValue: Date | string | number | [] = value;

    if (this.type === 'date') {
      if (newValue != '') {
        const dateParts = newValue.split('/');
        if (typeof newValue === 'string' && newValue.length > 10) {
          newValue = new Date(newValue);
        } else {
          newValue = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
        }
      }
    } else if (this.type === 'jira-time') {
      return fromJiraTimeToMinutes(value);
    } else if (this.type === 'number') {
      return Number(value);
    }

    return newValue;
  }

  private resolveRequest(newValue: unknown, showSuccessMessage = true) {
    this.updateLoading.next(true);
    //if is date
    if (newValue instanceof Date && !isNaN(newValue.valueOf())) {
      newValue = getDateStrResumStr(newValue.toISOString()).formattedDate;
    }

    let requestQuery: any = {
      [this.fieldName]: newValue,
      ...this.params,
    };

    if (this.isCustomProperty) {
      requestQuery = {
        value: newValue,
        ...this.params,
      };
    }

    const obsRequest$ = this.editableFieldService.requestUpdate(
      this.apiUrl,
      requestQuery
    );

    this.flightRequest = obsRequest$;

    if (this.updateOnEdit === false) {
      this.onValueChanged.emit(newValue);
      this.updateLoading.next(false);
      return;
    }

    this.sub$.add(
      obsRequest$
        .pipe(
          finalize(() => {
            this.flightRequest = null;
          })
        )
        .subscribe(
          (res) => {
            this.updateIdentityUser(res);
            this.requestedApi.emit(true);
            this.updateLoading.next(false);
            if (showSuccessMessage && this.showAlertMessage) {
              this.toastService.show({
                text: this.i18n.instant('general.edited'),
                type: 'success',
                msDuration: 4000,
              });
            }
          },
          (err) => {
            this.toastService.show({
              text: this.i18n.instant('general.formErrors2'),
              type: 'error',
              msDuration: 4000,
            });
            this.updateLoading.next(false);
          }
        )
    );
  }

  updateIdentityUser(res): void {
    if (this.module === 'user') {
      const userData: any = {
        id: res.id,
        name: res.name,
        email: res.email,
        phone: res.phone,
        company: res.company._id,
        role: res.role[0],
        created_at: res.created_at,
      };
      localStorage.setItem('userData', JSON.stringify(userData));
      this.analyticsService.identifyUser(['amplitude']);
    }
  }

  private isEqualData(newValue: unknown) {
    return JSON.stringify(this.value) === JSON.stringify(newValue);
  }

  closeFocus() {
    this.isFocus = false;
    this.draw();
  }

  saveCloseFocus(value) {
    if (!this.flightRequest) {
      this.resolveRequest(value, false);
    }
    this.isFocus = false;
    this.draw();
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }
}
