import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'obligatory-field-sign',
  templateUrl: './obligatory-field-sign.component.html',
  styleUrls: ['./obligatory-field-sign.component.scss'],
})
export class ObligatoryFieldSign {
  sign = ' *';
}
