import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { RouterModule } from '@angular/router';
import { PreviewComponent } from './preview.component';
import { ModalTemplateModule } from '@web-frontend/shared/components/modal-template';

@NgModule({
  declarations: [PreviewComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    ClickDeleteModule,
    ModalTemplateModule,
  ],
  exports: [PreviewComponent],
})
export class PreviewModule {}
