import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  SOURCE_FILTER,
  SOURCE_FILTER_TEAM,
  SOURCE_MOBILE,
  SOURCE_SECONDARY_WEB_FILTER,
} from '@web-frontend/core/admin/clock-hour/admin-clock-hour/admin-clock-hour.const';
import { ClockHourView } from '@web-frontend/core/admin/clock-hour/clock-hour.type';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UserSearchService } from '@web-frontend/shared/services';
import { SandboxService } from '@web-frontend/shared/services/sandbox/sandbox.service';
import { Feature, FeatureUser } from '@tacliatech/types';
import { DownloadType } from '@web-frontend/shared/components/download-btn/download-btn.component';
import { RmFilter } from '@web-frontend/shared/components/globals/rm-filter';

export enum ViewType {
  TEAM = 'team',
  ALL = 'all',
}

@Component({
  selector: 'roma-clock-table-header',
  templateUrl: './clock-table-header.component.html',
  styleUrls: ['./clock-table-header.component.scss'],
})
export class ClockTableHeaderComponent implements OnInit, OnChanges {
  @Input() viewTypeAct: ViewType;
  @Output() viewTypeChange = new EventEmitter<ViewType>();
  @Output() filterCoreChanges = new EventEmitter<RmFilter.Changes>();
  @Output() downloadBtnClick = new EventEmitter<DownloadType>();
  @Output() launchModal = new EventEmitter<void>();
  @Output() changeDateRange = new EventEmitter<{
    startDate: string;
    endDate: string;
    selectedPeriod: ClockHourView;
  }>();

  featureRef = Feature;
  featureUserRef = FeatureUser;

  viewTypeActive = ViewType.TEAM;
  sourceSearch = SOURCE_FILTER;
  sourceSearchTeam = SOURCE_FILTER_TEAM;

  sourceOptions = SOURCE_SECONDARY_WEB_FILTER({
    userSearchService: this.userSearchService,
  });

  sourceSecondaryMobileFilter = SOURCE_MOBILE({
    userSearchService: this.userSearchService,
  });

  viewTypeRef = ViewType;
  resize$ = this.sandBoxService.screenBusChannel$;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitzer: DomSanitizer,
    private readonly userSearchService: UserSearchService,
    private readonly sandBoxService: SandboxService
  ) {
    this.matIconRegistry.addSvgIcon(
      'table_icon',
      this.domSanitzer.bypassSecurityTrustResourceUrl(
        'assets/icons/table-horizontal.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'user_icon',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/user.svg')
    );
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.viewTypeAct) {
      this.viewTypeActive = changes.viewTypeAct.currentValue;
      this.draw();
    }
  }

  rmFilterCoreChanges(evt: RmFilter.Changes) {
    this.filterCoreChanges.emit(evt);
  }

  changeViewType(viewType: ViewType) {
    this.viewTypeActive = viewType;
    this.viewTypeChange.emit(viewType);
    this.draw();
  }

  download(evt: DownloadType) {
    this.downloadBtnClick.emit(evt);
  }

  changePeriod(evt: {
    startDate: string;
    endDate: string;
    view: ClockHourView;
  }) {
    this.changeDateRange.emit({
      startDate: evt.startDate,
      endDate: evt.endDate,
      selectedPeriod: evt.view,
    });
  }

  draw() {
    this.cd.detectChanges();
  }

  launchModalClockHour() {
    this.launchModal.emit();
  }
}
