import { IEquipment } from '@tacliatech/types';
import { addHours, endOfDay, isAfter, startOfDay } from 'date-fns';

export const HOURS = () => {
  const initDate = startOfDay(new Date());
  const endDate = endOfDay(new Date());
  const dateResponse = [];

  while (initDate < endDate) {
    dateResponse.push(initDate.toTimeString().substring(0, 5));
    initDate.setMinutes(initDate.getMinutes() + 15);
  }

  return dateResponse;
};

export const ADD_HOUR = (hour: string, amount = 1) => {
  const genericDate = new Date(`2014-09-15T${hour}:00`);

  return addHours(genericDate, amount).toTimeString().substring(0, 5);
};

export const GET_HOURS_GREATER_THAT = (compareHour: string) => {
  const dateResponse = [];

  for (const hour of HOURS()) {
    const genericDate = new Date(`2014-09-15T${hour}:00`);
    const compare = new Date(`2014-09-15T${compareHour}:00`);

    if (isAfter(genericDate, compare)) {
      dateResponse.push(hour);
    }
  }

  return dateResponse;
};

export const HourIsGreaterThat = (hour: string, compareHour: string) => {
  const genericDate = new Date(`2014-09-15T${hour}:00`);
  const compare = new Date(`2014-09-15T${compareHour}:00`);

  return isAfter(genericDate, compare);
};

export const HourIsGreaterThat23PM = (hour: string) => {
  const genericDate = new Date(`2014-09-15T23:00:00`);
  const compare = new Date(`2014-09-15T${hour}:00`);

  return !isAfter(genericDate, compare);
};

export const PARSER_EQUIPMENT = (item: IEquipment) => {
  return {
    title:
      item?.ref && item?.title ? `${item?.ref} - ${item?.title}` : item?.ref,
    name:
      item?.ref && item?.title ? `${item?.ref} - ${item?.title}` : item?.ref,
    id: item?._id,
    value: item?._id,
    img: '',
  };
};
