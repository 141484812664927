<div
  class="row-items"
  [formGroup]="form"
  romaProSelect
  class="wrapper-pro-feature-user"
  [featureUser]="featureUser"
>  <div class="row">
    <!-- MESSAGES AREA -->
    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pr0">
      <div class="messages">
        <mat-card class="h100 default-card mb10">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>
              {{
                'budgets.settings.create.form.messages' | translate
              }}</mat-label
            >
            <textarea
              rows="8"
              cols="40"
              matInput
              [disabled]="isDisabled('messages')"
              formControlName="messages"
              placeholder="{{
                'budgets.settings.create.form.messagesPlaceholder' | translate
              }}"
              (change)="changeMessages()"
            >
            </textarea>
          </mat-form-field>
        </mat-card>
      </div>
    </div>

    <!-- PAYMENT INFO AREA -->
    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pr0">
      <div class="payment-info">
        <mat-card class="h100 default-card mb10">
          <div class="form-group">
            <label
              for="addressAdditional"
              [ngClass]="{ 'is-disabled': !this.validForm }"
            >
              {{ 'budgets.settings.create.form.paymentsMethods' | translate }}
            </label>

            <!--       <ng-select
              class="select-payments"
              bindLabel="name"
              bindValue="_id"
              [attr.disabled]="isDisabled('payment_method')"
              [items]="paymentsMethods"
              [multiple]="false"
              formControlName="payment_method"
              [placeholder]="
                'budgets.settings.create.form.paymentsMethodsPlaceHolder'
                  | translate
              "
              (change)="changePayment($event)"
            >
            </ng-select> -->
            <ng-select
              class="select-payments"
              [attr.disabled]="isDisabled('payment_method')"
              [multiple]="false"
              formControlName="payment_method"
              [placeholder]="
                'budgets.settings.create.form.paymentsMethodsPlaceHolder'
                  | translate
              "
              [notFoundText]="'hiring.form.placeholder_notfound' | translate"
              [items]="paymentsMethods"
              bindLabel="name"
              bindValue="_id"
              (change)="changePayment($event)"
            >
              <!-- <ng-option
                *ngFor="let item of paymentsMethods"
                [value]="item._id"
              >
                {{ translateName(item?.name) | translate }}
              </ng-option> -->
            </ng-select>

            <label class="lbl-payment" for="addressAdditional">
              {{ paymentAssigned.textShow }}
            </label>
          </div>
        </mat-card>
      </div>
    </div>

    <!-- ADDITIONAL INFO (CATEGORY AND PROJECT) AREA -->
    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pr0">
      <div class="add-info">
        <mat-card class="h100 default-card">
          <div class="form-group">
            <label
              for="addressAdditional"
              [ngClass]="{ 'is-disabled': !this.validForm }"
            >
              {{ 'budgets.settings.create.form.categorization' | translate }}
            </label>

            <!-- SELECT SERVICE -->
            <div *ngIf="!isProductsLoading" class="">
              <ng-select
                class="select-payments"
                bindLabel="name"
                bindValue="_id"
                [items]="producsList"
                [multiple]="false"
                [attr.disabled]="isDisabled('category_id')"
                formControlName="category_id"
                (change)="changeCategory($event)"
                [placeholder]="
                  'budgets.settings.create.form.categorization' | translate
                "
                [notFoundText]="'hiring.form.placeholder_notfound' | translate"
              >
              </ng-select>
              <!--            <roma-select-search
                [label]="'budgets.settings.create.form.categorization'"
                [type]="selectType"
                [style]="'d-block my-1'"
                [setValue]="categoryAssigned?._id"
                [showAddButton]="false"
                name="Producto"
                [attr.disabled]="isDisabled('category_id')"
                [isLoading]="isProductsLoading"
                [datasource]="producsList"
                [notFoundText]="'hiring.form.placeholder_notfound'"
                (OnSelectObject)="changeCategory($event)"
                [labelError]="'es un error'"
              >
              </roma-select-search> -->
              <mat-error></mat-error>
              <!--       <ng-container
                *ngIf="
                  controls?.product_id?.invalid &&
                  (controls?.product_id?.dirty || controls?.product_id?.touched)
                "
              >
                <div
                  *ngIf="controls?.product_id?.errors?.required"
                  class="label-invalid"
                >
                  {{ 'hiring.form.product_idError' | translate }}
                </div>
              </ng-container> -->
            </div>
          </div>

          <div
            *ngIf="(idActiveModules$ | async).includes(idModuleRef.Projects)"
            class="form-group"
          >
            <label
              for="addressAdditional"
              [ngClass]="{ 'is-disabled': !this.validForm }"
            >
              {{ 'budgets.settings.create.form.projects' | translate }}
            </label>

            <ng-select
              class="select-payments"
              bindLabel="name"
              bindValue="_id"
              [items]="projects"
              [multiple]="false"
              [attr.disabled]="isDisabled('project_id')"
              formControlName="project_id"
              (change)="changeProject($event)"
              [placeholder]="
                'budgets.settings.create.form.projectsPlaceHolder' | translate
              "
              [notFoundText]="'hiring.form.placeholder_notfound' | translate"
            >
            </ng-select>

            <!--     <button
          class="taclia"
          mat-mini-fab
          color="accent"
          (click)="showCreateProject()"
        >
          <mat-icon>add</mat-icon>
        </button> -->
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<div class="row space"></div>
