import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { FieldErrorDisplayModule } from '../field-error-display';
import { BudgetTotalComponent } from './budget-total.component';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [BudgetTotalComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCardModule,
    FieldErrorDisplayModule,
    MatListModule,
    FormsModule,
  ],
  exports: [BudgetTotalComponent],
})
export class BudgetTotalModule {}
