<div *ngIf="statusObj !== null" class="sendDigitalCertificateBody">
    <div class="image-div">
        <img class="send-digital-icon" [src]="statusObj?.icon"/>
    </div>
    <div class="title-div">
        {{statusObj?.header | translate}}
    </div>
    <div class="link-div" *ngIf="statusObj?.showLink">
        <a [href]="statusObj?.linkHref" target="_blank" (click)="onClickLink()">{{statusObj?.linkText | translate}}</a>
    </div>
    <div class="text1-div">
        {{statusObj?.text | translate}}
    </div>
    <div class="button-div" *ngIf="statusObj?.showButton">
        <button class="roma-button add-button-3" (click)="onClickButton()">
            {{ statusObj?.buttonText | translate}}
        </button>
    </div>
    <div class="footer-div">
        {{'billDigitalCerficiate.sendFooter' | translate}} <a href="https://academy.taclia.com/kb-tickets/new?_gl=1*16gtpk8*_gcl_au*MjU5NDEyMTA3LjE2OTk4NjU2OTE." target="_blank" (click)="contactUs()">{{'billDigitalCerficiate.sendFooterHref' | translate}}</a>
    </div>
</div>
