import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@web-frontend/environments';
import { FreeLimitType } from '@tacliatech/types';

@Injectable({
  providedIn: 'root',
})
export class FreeLimitService {
  private freeLimitSource = new BehaviorSubject<FreeLimitType.Response>({
    _id: null,
    consumed: environment.freeLimits.customProperties,
    customProperties: [],
    files: [],
  });
  freeLimitSource$ = this.freeLimitSource.asObservable();

  firstWayBillCreated$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  getLimit(type: FreeLimitType.AllowedTypes) {
    return this.http.get<FreeLimitType.Response>(
      `:API_URL/free-limit?type=${type}`
    );
  }

  updateLimit(
    type: FreeLimitType.AllowedTypes,
    data: FreeLimitType.UpdateLimitDto
  ) {
    return this.http.put<FreeLimitType.Response>(
      `:API_URL/free-limit?type=${type}`,
      data
    );
  }

  setFreeLimit(limit: FreeLimitType.Response) {
    this.freeLimitSource.next(limit);
  }
}
