import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { EMPTY, Observable, ReplaySubject, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GeocodeApiService } from '../../service/geocode-api.service';
import { address } from '../../types/address.type';

type addressMapLayout = {
  isSearching: boolean;
  notFoundAddress: boolean;
};

type addressMapOutput = {
  found: boolean;
  lat?: number;
  lng?: number;
};

@Component({
  selector: 'roma-address-map',
  templateUrl: './address-map.component.html',
  styleUrls: ['./address-map.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressMapComponent implements OnChanges {
  @Input() public address?: string;

  @Output()
  public onSearch: EventEmitter<addressMapOutput> = new EventEmitter<addressMapOutput>();

  public address$: ReplaySubject<string> = new ReplaySubject();
  public layout$: ReplaySubject<addressMapLayout> = new ReplaySubject();

  public addressResult?: address | null;
  public layoutObs$?: Observable<addressMapLayout>;

  public readonly MARKER_OPTIONS: google.maps.MarkerOptions = {
    draggable: false,
    animation: google.maps.Animation.DROP,
    icon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
  };

  public readonly MAP_OPTIONS: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: false,
    scrollwheel: false,
    fullscreenControl: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };

  private searchSub?: Subscription;

  constructor(
    private geocodeApiService: GeocodeApiService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.layout$.next({
      isSearching: false,
      notFoundAddress: false,
    });

    this.layoutObs$ = this.layout$.asObservable();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['address']) {
      this.addressResult = undefined;
      this.changeDetectorRef.detectChanges();

      if (this.searchSub) {
        this.searchSub.unsubscribe();
      }

      this.layout$.next({
        isSearching: true,
        notFoundAddress: false,
      });

      this.searchSub = this.geocodeApiService
        .getAddressGeocodeInfo(this.address!)
        .pipe(
          tap((x) => {
            if (x.status !== 'OK') {
              this.layout$.next({
                isSearching: false,
                notFoundAddress: true,
              });

              this.onSearch.emit({
                found: false,
              });
            }

            this.layout$.next({
              isSearching: false,
              notFoundAddress: false,
            });

            this.onSearch.emit({
              found: true,
              lat: x.results[0].geometry.location.lat,
              lng: x.results[0].geometry.location.lng,
            });

            this.addressResult = {
              lat: x.results[0].geometry.location.lat,
              lng: x.results[0].geometry.location.lng,
            };

            this.changeDetectorRef.detectChanges();
          }),
          catchError(() => {
            this.layout$.next({
              isSearching: false,
              notFoundAddress: true,
            });

            this.onSearch.emit({
              found: false,
            });

            return EMPTY;
          })
        )
        .subscribe();
    }
  }
}
