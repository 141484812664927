import { TranslateService } from '@ngx-translate/core';
import {
  Company,
  ICustomProperty,
  Feature,
  FeatureUser,
} from '@tacliatech/types';
import { RmFilter } from '@web-frontend/shared/components/globals/rm-filter';
import {
  AssetService,
  DealService,
  ProjectService,
} from '@web-frontend/shared/services';
import { CategoryExpensesService } from '@web-frontend/shared/services/category-expenses';
import { CompanyModuleService } from '@web-frontend/shared/services/company';
import { from } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export const SOURCE_WEB = new RmFilter.Filter([
  new RmFilter.Input({
    type: 'SEARCH',
    keyRequest: 'keyword',
    content: {
      label: '',
      placeholder: 'expense.placeholder',
    },
  }),
]);

const requestAuthors$ = (assetService: AssetService) =>
  assetService.findUsersByAssetsShared().pipe(
    map((res) =>
      res.map((el) => {
        return {
          title: el.name.toString().toLocaleLowerCase(),
          value: el._id,
          id: el._id,
          img: el.img,
        };
      })
    )
  );

const showModule$ = (
  companyModuleService: CompanyModuleService,
  idModule: Company.IdModule
) =>
  companyModuleService.idActiveModules$.pipe(
    map((res) => res.includes(idModule))
  );

const requestDeals$ = (dealService: DealService) =>
  dealService.findAllDealsByCompany_SELECT().pipe(
    filter((res) => !!res),

    map((res) => {
      return res.map((deal) => {
        return {
          title: deal.name,
          id: deal._id,
          value: deal._id,
        };
      });
    })
  );

const requestCategoryExpenses$ = (
  categoryExpensesService: CategoryExpensesService
) =>
  categoryExpensesService.findAll().pipe(
    map((res) =>
      res.map((el) => {
        return {
          title: el.name,
          id: el._id,
          value: el._id,
        };
      })
    )
  );

const requestProjects$ = (projectService: ProjectService) =>
  projectService.search({ applyPaginate: false }).pipe(
    map((res) =>
      res.docs.map((el) => {
        return {
          title: el.name,
          id: el._id,
          value: el._id,
        };
      })
    )
  );

export const SOURCE_WEB_OPTIONS = (data: {
  categoryExpensesService: CategoryExpensesService;
  companyModuleService: CompanyModuleService;
  dealService: DealService;
  assetService: AssetService;
  projectService: ProjectService;
  customProperties: ICustomProperty[];
  i18n: TranslateService;
}) => {
  const controls: RmFilter.Control[] = [
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'users[]',
      source$: requestAuthors$(data.assetService),
      content: {
        label: 'imports.columns.responsible',
        placeholder: 'imports.columns.responsible',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'deals[]',
      source$: requestDeals$(data.dealService),
      show$: showModule$(data.companyModuleService, Company.IdModule.Deals),
      featureUser: FeatureUser.Deal.show,
      content: {
        label: 'expense.create.form.deal',
        placeholder: 'expense.create.form.deal',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'categories[]',
      source$: requestCategoryExpenses$(data.categoryExpensesService),
      content: {
        label: 'expense.create.form.category',
        placeholder: 'expense.create.form.category',
      },
    }),
    new RmFilter.RangeDate({
      keyRequest: ['from', 'to'],
      content: {
        label: '',
        startPlaceholder: '',
        endPlaceholder: '',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'projects[]',
      source$: requestProjects$(data.projectService),
      show$: showModule$(data.companyModuleService, Company.IdModule.Projects),
      featureUser: FeatureUser.Project.show,
      content: {
        label: 'deals.projectsPh',
        placeholder: 'deals.projectsPh',
      },
    }),
  ];

  for (const property of data.customProperties) {
    if (['SELECT'].includes(property.type)) {
      const options = property.options.map((el) => {
        return {
          id: el,
          title: el,
          value: el,
        };
      });
      controls.push(
        new RmFilter.Select({
          type: 'MULTIPLE',
          keyRequest: property.name,

          keyRequestParent: 'customProperties',
          source$: from([options]),
          content: {
            label: `${data.i18n.instant('filter.custom')} ${property.name}`,
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }

    if (['BOOLEAN'].includes(property.type)) {
      const options = [
        {
          id: 'true',
          title: 'general.yes',
          value: 'true',
        },
        {
          id: 'false',
          title: 'general.no',
          value: 'false',
        },
      ];
      controls.push(
        new RmFilter.Select({
          type: 'SINGLE',
          keyRequest: property.name,
          keyRequestParent: 'customProperties',
          source$: from([options]),
          content: {
            label: `${data.i18n.instant('filter.custom')} ${property.name}`,
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  for (const property of data.customProperties) {
    if (['DATE'].includes(property.type)) {
      controls.push(
        new RmFilter.InputDate({
          keyRequest: property.name,
          keyRequestParent: 'customProperties',

          content: {
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  for (const property of data.customProperties) {
    if (property.type === 'STRING') {
      controls.push(
        new RmFilter.Input({
          type: 'STRING',
          keyRequest: property.name,
          keyRequestParent: 'customProperties',

          content: {
            label: '',
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  for (const property of data.customProperties) {
    if (property.type === 'NUMBER') {
      controls.push(
        new RmFilter.InputRange({
          keyRequest: property.name,
          keyRequestParent: 'customProperties',

          content: {
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  return new RmFilter.Filter([
    ...controls,
    new RmFilter.Checkbox({
      keyRequest: 'takeDeleted',
      content: {
        label: 'finals.viewDelete',
      },
    }),
  ]);
};

export const SOURCE_MOBILE_OPTIONS = (data: {
  categoryExpensesService: CategoryExpensesService;
  companyModuleService: CompanyModuleService;
  dealService: DealService;
  assetService: AssetService;
  projectService: ProjectService;
  customProperties: ICustomProperty[];
  i18n: TranslateService;
}) => {
  const controls: RmFilter.Control[] = [
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'users[]',
      source$: requestAuthors$(data.assetService),
      content: {
        label: 'imports.columns.responsible',
        placeholder: 'imports.columns.responsible',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'deals[]',
      source$: requestDeals$(data.dealService),
      show$: showModule$(data.companyModuleService, Company.IdModule.Deals),
      content: {
        label: 'expense.create.form.deal',
        placeholder: 'expense.create.form.deal',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'categories[]',
      source$: requestCategoryExpenses$(data.categoryExpensesService),
      content: {
        label: 'expense.create.form.category',
        placeholder: 'expense.create.form.category',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'projects[]',
      source$: requestProjects$(data.projectService),
      show$: showModule$(data.companyModuleService, Company.IdModule.Projects),
      content: {
        label: 'deals.projectsPh',
        placeholder: 'deals.projectsPh',
      },
    }),
    new RmFilter.RangeDate({
      keyRequest: ['from', 'to'],
      content: {
        label: '',
        startPlaceholder: '',
        endPlaceholder: '',
      },
    }),
  ];

  for (const property of data.customProperties) {
    if (['SELECT'].includes(property.type)) {
      const options = property.options.map((el) => {
        return {
          id: el,
          title: el,
          value: el,
        };
      });
      controls.push(
        new RmFilter.Select({
          type: 'MULTIPLE',
          keyRequest: property.name,

          keyRequestParent: 'customProperties',
          source$: from([options]),
          content: {
            label: `${data.i18n.instant('filter.custom')} ${property.name}`,
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }

    if (['BOOLEAN'].includes(property.type)) {
      const options = [
        {
          id: 'true',
          title: 'general.yes',
          value: 'true',
        },
        {
          id: 'false',
          title: 'general.no',
          value: 'false',
        },
      ];
      controls.push(
        new RmFilter.Select({
          type: 'SINGLE',
          keyRequest: property.name,
          keyRequestParent: 'customProperties',
          source$: from([options]),
          content: {
            label: `${data.i18n.instant('filter.custom')} ${property.name}`,
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  for (const property of data.customProperties) {
    if (['DATE'].includes(property.type)) {
      controls.push(
        new RmFilter.InputDate({
          keyRequest: property.name,
          keyRequestParent: 'customProperties',

          content: {
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  for (const property of data.customProperties) {
    if (property.type === 'STRING') {
      controls.push(
        new RmFilter.Input({
          type: 'STRING',
          keyRequest: property.name,
          keyRequestParent: 'customProperties',

          content: {
            label: '',
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  for (const property of data.customProperties) {
    if (property.type === 'NUMBER') {
      controls.push(
        new RmFilter.InputRange({
          keyRequest: property.name,
          keyRequestParent: 'customProperties',

          content: {
            placeholder: `${data.i18n.instant('filter.custom')} ${
              property.name
            }`,
          },
        })
      );
    }
  }

  return new RmFilter.Filter([
    ...controls,
    new RmFilter.Checkbox({
      keyRequest: 'takeDeleted',
      content: {
        label: 'finals.viewDelete',
      },
    }),
  ]);
};
