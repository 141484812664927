<!-- STEP 1 -->
<div class="taclia-stepper-container" *ngIf="step1">
  <div class="stepper-header">
    <div class="steps">
      <span class="step-active">{{
        'payments.onboarding.surcharges-step' | translate
      }}</span
      ><span class="step-total">{{
        'payments.onboarding.steptotal' | translate
      }}</span>
    </div>
  </div>

  <div class="stepper-body">
    <div class="container-text">
      <div class="title">
        {{ 'payments.onboarding.surcharges-title' | translate }}
        <p class="subtitle">
          {{ 'payments.onboarding.surcharges-subtitle' | translate }}
        </p>
      </div>

      <div class="content-body__1">
        <div class="card-disclaimer">
          <div class="card-free-icon">
            {{ 'payments.onboarding.surcharges-section2-item' | translate }}
          </div>
          <div class="card-title">
            {{ 'payments.onboarding.surcharges-section2-title' | translate }}
          </div>
          <div class="card-text">
            {{ 'payments.onboarding.surcharges-section2-body' | translate }}
          </div>
        </div>

        <div class="card-disclaimer">
          <div class="card-tax-icon">
            {{ 'payments.onboarding.surcharges-section3-item' | translate }}
          </div>
          <div class="card-title">
            {{ 'payments.onboarding.surcharges-section3-title' | translate }}
          </div>
          <div class="card-text">
            {{ 'payments.onboarding.surcharges-section3-body' | translate }}
          </div>
          <div class="card-note">
            {{ 'payments.onboarding.surcharges-section3-note' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="stepper-footer">
    <div class="steps">
      <span class="step-active">{{
        'payments.onboarding.surcharges-step' | translate
      }}</span
      ><span class="step-total">{{
        'payments.onboarding.steptotal' | translate
      }}</span>
      <div class="w3-progress-container w3-round-xlarge">
        <div class="w3-progressbar w3-round-xlarge" style="width: 50%"></div>
      </div>
    </div>
    <section class="footer-buttons">
      <button class="roma-button button-back" (click)="gotoPayments()">
        <span class="mx-2">{{ 'payments.onboarding-back' | translate }}</span>
      </button>
      <button class="roma-button button-continue" (click)="goToStep2()">
        <span class="mx-2">{{
          'payments.onboarding-continue' | translate
        }}</span>
      </button>
    </section>
  </div>
</div>

<!-- STEP 2 -->

<div class="taclia-stepper-container" *ngIf="step2">
  <div class="stepper-header">
    <div class="steps">
      <span class="step-active">{{
        'payments.onboarding.config-step' | translate
      }}</span
      ><span class="step-total">{{
        'payments.onboarding.steptotal' | translate
      }}</span>
    </div>
  </div>
  <div class="stepper-body">
    <div class="container-text">
      <div class="title">
        {{ 'payments.onboarding.config-title' | translate }}
        <p class="subtitle">
          <img src="assets/icons/lock-alt.svg" />
          {{ 'payments.onboarding.config-subtitle' | translate }}
        </p>
      </div>

      <div class="content-body">
        <div class="card">
          <div class="col-number">
            <div class="numbers">1</div>
          </div>
          <div class="col-content">
            <div class="card-title">
              {{ 'payments.onboarding.config-section1-title' | translate }}
            </div>
            <div class="card-text">
              {{ 'payments.onboarding.config-section1-body' | translate }}
            </div>
          </div>
        </div>
        <div class="card">
          <div class="col-number">
            <div class="numbers">2</div>
          </div>
          <div class="col-content">
            <div class="card-title">
              {{ 'payments.onboarding.config-section2-title' | translate }}
            </div>
            <div class="card-text">
              {{ 'payments.onboarding.config-section2-body' | translate }}
            </div>
          </div>
        </div>
        <div class="card card-account">
          <div class="col-number">
            <div class="numbers">3</div>
          </div>
          <div class="col-content">
            <div class="card-title">
              {{ 'payments.onboarding.config-section3-title' | translate }}
            </div>
            <div class="card-text">
              {{ 'payments.onboarding.config-section3-body' | translate }}
            </div>
          </div>
        </div>
        <div class="info-box">
          <div class="info-icon">
            <img src="assets/icons/information-icon.svg" />
          </div>
          <div class="info-text">
            {{ 'payments.onboarding.config-infopanel' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="stepper-footer">
    <div class="steps">
      <span class="step-active">{{
        'payments.onboarding.config-step' | translate
      }}</span
      ><span class="step-total">{{
        'payments.onboarding.steptotal' | translate
      }}</span>
      <div class="w3-progress-container w3-round-xlarge">
        <div class="w3-progressbar w3-round-xlarge" style="width: 100%"></div>
      </div>
    </div>
    <section class="footer-buttons">
      <button class="roma-button button-back" (click)="goToStep1()">
        <span class="mx-2">{{ 'payments.onboarding-back' | translate }}</span>
      </button>
      <button class="roma-button button-continue" (click)="createOnboarding()">
        <span class="mx-2">{{
          'payments.onboarding-continue' | translate
        }}</span>
      </button>
    </section>
  </div>
</div>

<!-- STEP 3 -->

<div class="taclia-stepper-container" *ngIf="step3 && gotoPayments()">
  <roma-payment-status
    [showCloseBtn]="true"
    (closedButton)="gotoPayments()"
    [status]="StatusAccounteEnumRef.NOT_COMPLETED"
  ></roma-payment-status>
</div>

<div class="modal_close_button-container" (click)="gotoPayments()">
  <img class="modal_close_button" [src]="'assets/icons/gl_close.svg'" />
  <div class="modal_close_text">{{ 'general.close' | translate }}</div>
</div>
