export const DealStatusColors = [
  {
    lightColor: '#E3EBFA',
    darkColor: '#6A79A0',
  },
  {
    lightColor: '#FFF1DB',
    darkColor: '#A36A00',
  },
  {
    lightColor: '#FDE8D7',
    darkColor: '#F57200',
  },
  {
    lightColor: '#DFF6E8',
    darkColor: '#247F47',
  },
  {
    lightColor: '#D4EAFC',
    darkColor: '#007BE0',
  },
  {
    lightColor: '#EBE5FF',
    darkColor: '#7A5AF8',
  },
  {
    lightColor: '#FAEEFC',
    darkColor: '#9723AE',
  },
  {
    lightColor: '#FFEBEB',
    darkColor: '#FF474E',
  },
];
