import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalTemplateModule } from '../modal-template';
import { InfoComponent } from './info.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [InfoComponent],
  imports: [CommonModule, ModalTemplateModule, TranslateModule.forChild()],
  exports: [InfoComponent],
})
export class InfoModule {}
