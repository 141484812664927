import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import {
  DynamicPropertyRef,
  EFileType,
  IAsset,
  ICustomProperty,
  IUser,
  Lang,
  TypeRol,
  ValidateAllFormFields,
  countryCodesEN,
  countryCodesES,
} from '@tacliatech/types';

import { GenericComponent } from '@web-frontend/shared/class';
import { FeatureService, StorageService } from '@web-frontend/shared/services';
import { AssetService } from '@web-frontend/shared/services/assets/asset.service';
import { AuthService } from '@web-frontend/shared/services/auth/auth.service';
import { FilesUploadService } from '@web-frontend/shared/services/file-upload/file-upload.service';
import { UserService } from '@web-frontend/shared/services/users';
import {
  EmailCustomValidator,
  FormatEmailValidator,
} from '@web-frontend/shared/validators/email';

import { DataParamsPerson } from './create-person.types';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import {
  FileUploadRes,
  FileUploadService,
} from '@web-frontend/shared/helpers/file-upload';
import { concatMap, finalize, tap } from 'rxjs/operators';
import { CompanyService } from '@web-frontend/shared/services/company';
import { UserflowService } from '@web-frontend/shared/services/userflow/userflow.service';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import { Period } from '@web-frontend/shared/interfaces/period';
import AmplitudeEvents from 'src/types/amplitude.enum';

@Component({
  selector: 'roma-create-person',
  templateUrl: './create-person.component.html',
  styleUrls: ['./create-person.component.scss'],
})
export class CreatePersonComponent extends GenericComponent implements OnInit {
  user: IUser;
  user$ = this.authService.user$;
  typePropertyRef = DynamicPropertyRef;
  initChildForm = false;
  parentFormSubmit = false;
  invalidEditCustomFields = false;
  propertiesIsLoading = false;
  showDisclaimer = false;
  workingHoursError: string | null = null;
  errorHoursMin = false;

  @Input()
  type: 'ADD' | 'EDIT' = 'ADD';

  form: FormGroup;

  tempMode: string;
  formSubmitted = false;

  assets: IAsset[];
  roles = [
    {
      id: TypeRol.USER_ROLE,
      name: 'USER',
      translateValue: 'general.user',
    },
    {
      id: TypeRol.ADMIN_ROLE,
      name: 'ADMINISTRATOR',
      translateValue: 'general.user_admin',
    },
  ];

  roleSelected = TypeRol.USER_ROLE;
  showPermissions = false;

  private sub$ = new Subscription();
  customProperties: ICustomProperty[] = [];
  countryCodes = null;
  initialPhoneValue: { pre: string; number: string } = { pre: '', number: '' };

  imageFile = null;

  errorMessagesVacation = {
    max: 'person.create.form.maxDaysError',
  };

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private assetService: AssetService,
    private userService: UserService,
    private i18n: TranslateService,
    private filesUploadService: FilesUploadService,
    private fileUploadService: FileUploadService,
    private changeDetectionRef: ChangeDetectorRef,
    private amplitudeService: AmplitudeService,
    private dialogRef: MatDialogRef<CreatePersonComponent>,
    private featureService: FeatureService,
    private companyService: CompanyService,
    private userflowService: UserflowService,
    @Inject(MAT_DIALOG_DATA) public data: DataParamsPerson,
    private toastService: ToastService,
    private brazeService: BrazeService,
    private analyticsService: AnalyticsService
  ) {
    super();
    this.createForm();
    this.getAssets();
  }

  getErrorKeys(errors: any): string[] {
    return Object.keys(errors);
  }

  get controls() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.getAssets();
    this.resolveEdit();
    this.setCountryArr();
  }

  setCountryArr(): void {
    const LANG = StorageService.GetItem('USER_LANG') as Lang;

    this.countryCodes = LANG === 'en' ? countryCodesEN : countryCodesES;
    this.countryCodes = this.countryCodes.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  get phoneErrors() {
    const pre = this.f.phoneNumber?.get('prefix');
    const number = this.f.phoneNumber?.get('number');
    return (pre?.errors || number?.errors) && (pre?.touched || number?.touched);
  }

  getAssets() {
    this.user$.subscribe((resp) => {
      const customer =
        typeof resp.company === 'string' ? resp.company : resp.company?._id;

      this.form.patchValue({ refSchemaID: customer });

      // Check if user mode exist and fix it
      this.checkUserMode(resp);

      this.assetService.getAssetsCustomer(customer).subscribe((resp) => {
        this.assets = resp;
      });
    });
  }

  checkUserMode(resp: IUser) {
    this.tempMode = resp.mode;
    if (!StorageService.userMode) {
      this.form.patchValue({ mode: this.tempMode });
    }
  }

  customPropertiesFormChange($event) {
    this.customProperties = $event;
    this.setcustomProperties();
  }

  setcustomProperties() {
    let i = 0;
    for (i = 0; i < this.customProperties?.length; i++) {
      if (this.customProperties[i].isRequired) {
        this.form.addControl(
          this.customProperties[i]._id,
          new FormControl('', Validators.required)
        );
      }
    }
    this.initChildForm = true;
    this.parentFormSubmit = false;
  }

  propertiesLoading(evt) {
    this.propertiesIsLoading = evt;
  }

  handleImageFile(files: FileList) {
    if (this.type === 'EDIT') {
      this.filesUploadService
        .deleteFile(EFileType.USER, this.user.img)
        .subscribe();
    }
    const file: File = files.item(0);
    this.filesUploadService.uploadFile(EFileType.USER, file).subscribe({
      next: (resp: FileUploadRes[]) => {
        if (resp) {
          if (this.type === 'EDIT') {
            this.user.img = resp[0].filename;
          }
          this.imageFile = file;
          this.form.patchValue({ img: resp[0].filename });
          this.draw();
        }
      },
      error: (err) => {
        console.error('error!', err);
      },
    });
  }

  private createForm() {
    this.form = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      position: new FormControl(null, []),
      email: new FormControl(null, {
        validators: [Validators.required, FormatEmailValidator()],
        asyncValidators: [
          EmailCustomValidator.checkIfIsRepeat(this.featureService),
          EmailCustomValidator.checkEmailDomain(this.featureService),
        ],
        updateOn: 'blur',
      }),
      phoneNumber: new FormGroup({
        prefix: new FormControl(''),
        number: new FormControl(''),
      }),
      company: new FormControl(StorageService.CompanyId),
      role: new FormControl(null, [Validators.required]),
      mode: new FormControl(StorageService.userMode),
      img: new FormControl(null),
      permissions: new FormControl([]),
      customProperties: [[]],
      contract: new FormGroup({
        workingHours: new FormControl(null),
        workingHoursPeriod: new FormControl(Period.Week),
        workdays: new FormControl([]),
        vacationDays: new FormControl(null, [Validators.max(365)]),
      }),
    });
  }

  onBlurDays(event: Event): void {
    const input = event.target as HTMLInputElement;
    const vacationDays = input.value.replace(',', '.');
    this.form.get('contract.vacationDays').setValue(vacationDays);
  }

  onChangeDays(event: Event): void {
    const input = event.target as HTMLInputElement;
    const sanitizedVacationDays = String(input.value).replace(
      /(\.\d+|,\d+)/,
      (match) => {
        return match === '.5' || match === ',5' ? match : '';
      }
    );

    this.form.get('contract.vacationDays').setValue(sanitizedVacationDays);
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  phoneChange(evt: {
    pre: string;
    number: number;
    countryCode?: string;
    countryName?: string;
  }) {
    const phoneGroup = this.form.get('phoneNumber') as FormGroup;

    phoneGroup.patchValue({
      prefix: evt.pre,
      number: evt.number,
    });
  }

  numberOnly(event: { which: any; keyCode: any }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onWorkingHoursChange(hours: number): void {
    this.form.get('contract.workingHours')?.setValue(hours);
    this.validateErrorHours();
    this.draw();
  }

  onWorkingHoursPeriodChange(period: Period): void {
    this.form.get('contract.workingHoursPeriod')?.setValue(period);
    this.draw();
  }

  onWorkdaysChange(workdays: string[]): void {
    this.form.get('contract.workdays')?.setValue(workdays);
    this.validateErrorHours();
    this.draw();
  }

  validateErrorHours(): void {
    const workdays = this.form.get('contract.workdays')?.value;
    const workingHoursControl = this.form.get('contract.workingHours')?.value;

    if (this.shouldMarkError(workdays, workingHoursControl)) {
      this.handleValidationHoursError(true);
      this.workingHoursError = 'person.create.form.hourRequired';
    } else {
      this.handleValidationHoursError(false);
    }
    this.draw();
  }

  isInvalidWorkingHoursControl(workingHoursControl: any): boolean {
    return workingHoursControl === 0 || workingHoursControl == null;
  }

  shouldMarkError(workdays: string[], workingHoursControl: any): boolean {
    return (
      workdays.length > 0 &&
      this.isInvalidWorkingHoursControl(workingHoursControl)
    );
  }

  handleValidationHoursError(error: boolean): void {
    if (error) {
      this.setWorkingHoursError();
    } else {
      if (!this.errorHoursMin) {
        this.clearWorkingHoursError();
      }
    }
    this.draw();
  }

  setWorkingHoursError(): void {
    this.form.get('contract.workingHours').setErrors({ invalid: true });
    this.draw();
  }

  clearWorkingHoursError(): void {
    const workdays = this.form.get('contract.workdays')?.value;
    const workingHoursControl = this.form.get('contract.workingHours')?.value;
    if (
      workdays.length === 0 ||
      !this.isInvalidWorkingHoursControl(workingHoursControl)
    ) {
      this.form.get('contract.workingHours')?.setErrors(null);
      this.workingHoursError = null;
    } else {
      this.setWorkingHoursError();
      this.workingHoursError = 'person.create.form.hourRequired';
    }
  }

  updateErrorHoursMin(evt: boolean): void {
    this.errorHoursMin = evt;
    this.draw();
  }

  resolveForm() {
    if (this.type !== 'ADD') {
      this.form.patchValue({
        ...this.user,
        role: this.user.role[0],
      });
    }
  }

  private resolveEdit() {
    if (this.data?.person) {
      this.type = 'EDIT';

      const { person } = this.data;

      this.user = person;

      this.form.patchValue({
        ...person,
        role: person.role[0],
      });

      this.setRole(person.role[0]);
      this.draw();
    }
  }

  setRole(role) {
    const value = this.roles.find((s) => s.id === role)?.id;
    this.roleSelected = value;
  }

  submit() {
    this.parentFormSubmit = true;
    const isValid = this.checkValidators();

    if (!this.formSubmitted && isValid) {
      this.formSubmitted = true;
      const value = this.form.value as IUser;
      this.executeSubmit(value);
    } else {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
    }
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);

    return this.form.valid;
  }

  executeSubmit(value: IUser) {
    if (!value.phoneNumber?.number) {
      delete value.phoneNumber;
    }
    if (value.position === null) value.position = '';
    if (
      value.contract.workingHours === null ||
      value.contract.workingHours === 0
    ) {
      delete value.contract.workingHours;
      delete value.contract.workingHoursPeriod;
      delete value.contract.workdays;
    }
    if (!value.contract.vacationDays) {
      delete value.contract.vacationDays;
    }
    const obs$ =
      this.type === 'ADD'
        ? this.userService.createOne(value)
        : this.userService.updateOne(this.data?.person?._id, this.form.value);

    this.pushSubcription(
      obs$.subscribe(
        (res) => {
          if (this.type === 'ADD') {
            const eventData = {
              event: 'user_create',
              user: res,
            };
            this.amplitudeService.newUserEvent(eventData);
            this.userflowService.sendEvent(eventData);
            this.brazeService.sendEvent(eventData);
            this.brazeService.sendEvent({
              event: BrazeEventType.new_user_invitation,
            });

            if (value.contract) {
              this.analyticsService.trackEvent({
                sources: ['amplitude'],
                eventName: AmplitudeEvents.userInviteAdd,
              });

              if (value.contract.vacationDays) {
                this.analyticsService.trackEvent({
                  sources: ['amplitude'],
                  eventName: AmplitudeEvents.userInvite_add_vacations_info,
                });
              }
            }

            this.sub$.add(
              this.companyService
                .findCountUsersByCompany(StorageService.CompanyId)
                .subscribe((res) =>
                  this.companyService.refreshCompanyCounter$.next(res)
                )
            );
          }

          // Once user was created, image will upload
          // This because if we upload image in firestore before user registration,
          // we will have many images in firestore
          if (this.imageFile) {
            this.fileUploadService
              .uploadFile(EFileType.USER, this.imageFile)
              .pipe(
                finalize(() => this.dialogRef.close(res)),
                concatMap((url: string) => {
                  return this.userService.updateOne(res._id, {
                    img: url,
                    company: StorageService.CompanyId,
                  });
                }),
                tap((resUpload) => {
                  if (resUpload._id === StorageService.UserId) {
                    this.authService.updateUserCache = resUpload;
                  }
                  this.draw();
                })
              )
              .subscribe(() => {
                this.toastService.show({
                  text: this.i18n.instant('general.updateAvatarSuccessfuly'),
                  type: 'success',
                  msDuration: 4000,
                });
              });
          } else {
            this.dialogRef.close(res);
          }

          const message =
            this.type === 'ADD'
              ? 'person.create.success'
              : 'person.edit.success';

          this.toastService.show({
            text: this.i18n.instant(message),
            type: 'success',
            msDuration: 4000,
          });
          this.analyticsService.identifyUser(['amplitude']);
        },
        () => {
          this.toastService.show({
            text: this.i18n.instant('activity.error'),
            type: 'error',
            msDuration: 4000,
          });
          this.formSubmitted = false;
        }
      )
    );
  }

  onPermissionChange(value: string[]) {
    this.form.get('permissions').setValue(value);
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  close() {
    this.dialogRef.close();
  }

  onPaste(event: any): void {
    event.preventDefault();
  }
}
