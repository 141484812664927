import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  DeleteBySelectionModalComponent,
  DeleteDialogData,
} from '@web-frontend/shared/components/delete-by-selection-modal/delete-by-selection-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ShowBudgetComponent } from '@web-frontend/shared/components/show-budget/show-budget.component';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class UnsaveGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ShowBudgetComponent): boolean | Observable<boolean> {
    if (component.canDeactivate()) {
      return true;
    } else {
      let canClose = new Subject<boolean>();
      component.openCheckClose();
      canClose = component.canExitComponent;
      return canClose.asObservable();
    }
  }
}
