import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SendDigitalCertificateComponent } from './send-digital-certificate/send-digital-certificate.component';
import { BillDigitalCertificateComponent } from './bill-digital-certificate.component';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { InputPasswordModule } from '../input-password/input-password.module';
import { LoadingModule } from '../loading';
import { DigitalCertificateStatusComponent } from './digital-certificate-status/digital-certificate-status.component';
import { DigitalCertificateStatusDialogComponent } from './digital-certificate-status-dialog/digital-certificate-status-dialog.component';
import { ModalTemplateModule } from '../modal-template';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { TooltipModule } from '@web-frontend/shared/directives/tooltip/tooltip.module';

@NgModule({
  declarations: [
    BillDigitalCertificateComponent,
    DigitalCertificateStatusComponent,
    DigitalCertificateStatusDialogComponent,
    SendDigitalCertificateComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FileUploadModule,
    InputPasswordModule,
    LoadingModule,
    ModalTemplateModule,
    MatBottomSheetModule,
    TooltipModule
  ],
  exports: [BillDigitalCertificateComponent],
})
export class BillDigitalCertificateModule {}
