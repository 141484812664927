import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export interface DownloadDialogData {
  title?: string;
  body?: string;
  showIcon?: boolean;
  showBody?: boolean;
  btn_cancelLabel?: string;
  confirmLabel?: string;
  isPlural?: boolean;
  quantity?: number;
}

@Component({
  selector: 'roma-download-by-selection-modal',
  templateUrl: './download-by-selection-modal.component.html',
  styleUrls: ['./download-by-selection-modal.component.scss'],
})
export class DownloadBySelectionModalComponent {
  downloadQuantity: number = 0;

  constructor(
    public dialogRef: MatDialogRef<DownloadBySelectionModalComponent>,
    private i18n: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data?: DownloadDialogData
  ) {
    if (!data) {
      this.data = {
        showBody: true,
        showIcon: true,
      };
    }

    this.downloadQuantity = data.quantity || 0;
  }

  getTranslation(translationText: string) {
    return this.i18n
      .instant(translationText)
      .replace('{arg1}', this.downloadQuantity);
  }

  onNoClick(): void {
    this.dialogRef.close('CANCEL');
  }

  exit(): void {
    this.dialogRef.close('CANCEL-X');
  }

  execute(): void {
    this.dialogRef.close('EXECUTE');
  }
}
