import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  from,
  interval as observableInterval,
  Observable,
  Subscription,
  throwError,
} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  finalize,
  map,
  scan,
  takeWhile,
  tap,
} from 'rxjs/operators';

import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { NgSelectComponent } from '@ng-select/ng-select';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import {
  Company,
  DynamicPropertyRef,
  Feature,
  FeatureUser,
  ICatalogue,
  ICustomProperty,
  IDeal,
  MapperFinal,
  Sale,
  StatusSalesId,
  TacliaPayments,
  Tax,
  TypeTax,
} from '@tacliatech/types';

import { RmSelect } from '@web-frontend/shared/components/globals/rm-select/rm-select.types';
import { DataParamsSale } from './create-sale.types';
import { CompanyModuleService } from '@web-frontend/shared/services/company/company-module.service';
import { EditCustomPropertiesComponent } from '../edit-custom-properties';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from '@web-frontend/shared/utils/custom-date-adapter';
import { TaxService } from '@web-frontend/shared/services/tax';
import { CancelSaleModalService } from './cancel-sale-modal/cancel-sale-modal.service';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { ModalMobileInfoComponent } from '@web-frontend/shared/components/modal-mobile-info';

import {
  AssetService,
  AuthService,
  FinalService,
  SalesSearchService,
  SalesService,
  SandboxService,
  StorageService,
  UserService,
} from '@web-frontend/shared/services';

import { UserDealsSelectModalService } from '../user-deals-select-modal';
import { RatesSelect } from '../rates-select-table';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import {
  SaleStorageService,
  SaleStoreService,
} from './create-sale.store.service';
import { UnbindDealSaleModalService } from './unbind-deal-sale-modal';
import { RmDealSelect } from '../globals/rm-deal-select/rm-deal-select.types';
import { SendEmailSaleModalService } from '../send-email-sale-modal';
import { SharePaymentLinkModalService } from './share-payment-link-modal/share-payment-link-modal.service';
import { PaymentsService } from '../../../core/admin/payments/services/payments.service';
import { ExposedError } from '@stripe/terminal-js';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { CreateFinalV2Service } from '../create-final-v2';
import { DeleteBySelectionModalComponent } from '../delete-by-selection-modal/delete-by-selection-modal.component';
import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';

@Component({
  selector: 'roma-create-sale',
  templateUrl: './create-sale.component.html',
  styleUrls: ['./create-sale.component.scss'],
  providers: [{ provide: DateAdapter, useExisting: CustomDateAdapter }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateSaleComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('customerVendor')
  customerVendorSelect: NgSelectComponent;

  @ViewChild('leftCol') private myScrollContainer: ElementRef;

  @ViewChild(EditCustomPropertiesComponent)
  editCustomProperties: EditCustomPropertiesComponent;

  @ViewChild('dealSelect')
  dealSelect: NgSelectComponent;

  @Input()
  type: 'ADD' | 'EDIT' = 'ADD';

  public currentLang: string;

  isPrinting = false;
  featureRef = Feature;
  featureRefUser = FeatureUser;

  public innerWidth: number;
  private sub$ = new Subscription();

  selectedStatus: string = null;
  selectedStatusTitle: string = null;
  statusNames: { id?: string; title: string; value: string }[] = [];

  refreshModalOnClose = false;
  statusLoading = false;
  statusCharged = false;
  customPropertiesLoaded = false;
  hasFeatureHandleActivityCustomProperty = false;
  finalsCharged = false;
  ratesharged = false;
  finalsLoading = false;
  ratesLoading = false;
  markAsDone = false;
  isLoading = false;
  initChildForm = false;
  invalidEditCustomFields = false;
  showFinalsSelect = false;
  showTaxInput = false;

  showToolTip = false;
  refundedSale = false;
  refundStart = false;
  refundText = '';
  shareLinkTitle = 'sales.salesCard.charge';
  isCountryValid = false;

  saleSteps$: Observable<SaleStorageService.SaleStepType> = this.saleStorageService.sale$.pipe(
    map((res) => res.steps)
  );

  amountCheckRefund$ = (amount: number) =>
    this.saleSteps$.pipe(
      map((res) => {
        if (res.paymentRefund || res.paymentRefundFinish) {
          return '-' + amount;
        } else {
          return amount;
        }
      })
    );

  translatePaymentMethod$ = () => {
    return this.saleStorage$.pipe(
      map((res) => {
        return res.sale;
      }),
      map((res) => {
        const selectedPaymentMethod = res.paymentMethod;
        if (selectedPaymentMethod === Sale.PaymentMethodEnum.Cash) {
          return 'sales.salesCard.cash';
        }

        if (selectedPaymentMethod === Sale.PaymentMethodEnum.Card) {
          return 'sales.salesCard.card';
        }

        if (selectedPaymentMethod === Sale.PaymentMethodEnum.Others) {
          return 'sales.salesCard.others';
        }

        if (selectedPaymentMethod === Sale.PaymentMethodEnum.Link) {
          return 'sales.salesCard.paymentLink';
        }

        return this.i18n.instant('sales.salesCard.others');
      })
    );
  };

  total = 0;
  subtotal = 0;
  discount = 0;
  deals: any[] = [];
  paymentDate: Date = new Date();
  ratesList: ICatalogue[] = [];
  finalSelectItem: any[] = [];
  ratesSelectItems: any[] = [];
  selectedDeal: any[] = [];
  selectedFinal: any[] = [];
  selectedRate: any[] = [];
  dealLinked: RmDealSelect.Item = null;
  typePropertyRef = DynamicPropertyRef;
  resize$ = this.sandBoxService.screenBusChannel$;
  idModuleRef = Company.IdModule;

  // sale!: Sale.Output;
  taxes: Tax[] = [];
  selectedTaxes: Tax[] = [];
  taxesList: RmSelect.Items = [];
  typeTaxRef = TypeTax;

  customProperties: ICustomProperty[] = [];
  idActiveModules$ = this.companyModuleService.idActiveModules$;
  salePaymentMethodRef = Sale.PaymentMethodEnum;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  salesProducts: RatesSelect.Items = [];
  selectedPaymentMethod: Sale.PaymentMethodEnum = Sale.PaymentMethodEnum.None;

  saleStorage$ = this.saleStorageService.sale$;
  haveChanges = false;
  idSale: string = null;
  stripeObject: TacliaPayments.IStripe;
  showFinalMobileModalList = false;
  showTaxesMobileModalList = false;
  showMobileActions = false;
  @ViewChild('modalContainer', { read: ElementRef })
  modalContainer!: ElementRef<HTMLElement>;

  stripe_error: ExposedError;

  private patchDialogData!: DataParamsSale;

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<CreateSaleComponent>,
    private i18n: TranslateService,
    public assetService: AssetService,
    private finalService: FinalService,
    private companyModuleService: CompanyModuleService,

    @Inject(MAT_DIALOG_DATA)
    public data: DataParamsSale,

    private sandBoxService: SandboxService,
    private userDealsSelectModalService: UserDealsSelectModalService,
    private taxService: TaxService,
    private cancelSaleModalService: CancelSaleModalService,
    private createFinalV2Service: CreateFinalV2Service,
    private amplitudeService: AmplitudeService,
    private saleStorageService: SaleStoreService,
    public salesSearchService: SalesSearchService,
    private salesService: SalesService,
    private unbindDealSaleModalService: UnbindDealSaleModalService,
    private authService: AuthService,
    private sendEmailSaleModalService: SendEmailSaleModalService,
    private sharePaymentLinkModalService: SharePaymentLinkModalService,
    private paymentsService: PaymentsService,
    private toastService: ToastService,
    private dialog: MatDialog,
    private settingsService: BudgetService,
    private brazeService: BrazeService,
    private userService: UserService,
    private analyticsService: AnalyticsService
  ) {
    this.sub$.add(
      this.i18n.onLangChange.subscribe((event: LangChangeEvent) => {
        this.currentLang = event.lang;
        this.draw();
      })
    );
    this.validateCountry();
  }

  validateCountry() {
    this.isCountryValid = this.userService.validateCountryES();
  }

  async ngOnInit() {
    this.patchDialogParams();
    this.findFinals();
    this.currentLang = this.getLang();
    this.resolveTaxes();

    if (this.data?.mode === 'EDIT') {
      this.type = this.data.mode;
      this.idSale = this.data.idSale;
      await this.requestSale(this.data.idSale);
    }

    this.saleStorageService.commit();
    this.watchStorageChanges();
    this.watchModalShareChanges();
    // this.requestStripeAccountStatus();
    this.watchStripeObject();
    // this.stripeObject = this.paymentsService.stripeAccount;

    if (this.data['deal']) {
      this.fillAutomaticallyFromDeal(this.data['deal']);
    }
    this.dialogRef.disableClose = true;
    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onBackdropClick());
  }

  public async onBackdropClick(result?: string): Promise<void> {
    if (this.haveChanges) {
      const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
        panelClass: 'delete-by-selection-modal',
        data: {
          title: this.i18n.instant('general.withoutSave'),
          confirmLabel: this.i18n.instant('general.buttonExit'),
          showBody: false,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'EXECUTE') {
          this.close();
        }
      });
    } else {
      this.close();
    }
  }

  private patchDialogParams() {
    this.patchDialogData = this.data;
    this.draw();
  }

  getLang() {
    return StorageService.GetItem('USER_LANG');
  }

  watchModalShareChanges() {
    this.sharePaymentLinkModalService.modalClosed$.subscribe((data) => {
      // console.log('sharePaymentLinkModalService.modalClosed data', data);
    });
  }

  watchStripeObject() {
    this.sub$.add(
      this.paymentsService.stripeObject$.subscribe(
        (data: TacliaPayments.IStripe) => {
          this.stripeObject = data;
        }
      )
    );
  }

  ngOnDestroy() {
    this.type = null;
    this.saleStorageService.reset();
    this.sub$.unsubscribe();
  }

  private draw() {
    try {
      this.changeDetectionRef.detectChanges();
      this.changeDetectionRef.markForCheck();
    } catch (err) {}
  }

  ngAfterViewInit() {}

  close() {
    this.dialogRef.close(this.refreshModalOnClose);
  }

  printSaleAction(): void {
    this.isPrinting = true;
    const user = this.authService.user;
    const storage = this.saleStorageService.getSale();
    this.salesService
      .getPdf({
        ids: [storage.sale?._id],
        ticketNumber: storage.sale?.number?.toString(),
        finalName: storage.sale?.final?.title?.toString(),
        user: {
          _id: user._id,
          company: user.company._id,
        },
        lang: this.getLang(),
      })
      .toPromise()
      .then((blob: Blob) => {
        const url = URL.createObjectURL(blob);
        const printWindow = window.open(url);
        printWindow.onload = function () {
          printWindow.print();
        };
      })
      .finally(() => {
        this.isPrinting = false;
        this.draw();
      });
  }

  requestStripeObject() {
    this.isLoading = true;
    this.sub$.add(
      this.paymentsService
        .requestStripeObject()
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          // this.paymentsService.stripeStatusAccount = res;
          this.paymentsService.setStripeObject(res);
        })
    );
  }

  stripeAccountIsApproved(): boolean {
    const approvalStates = [
      TacliaPayments.StatusAccount.APPROVED,
      TacliaPayments.StatusAccount.APPROVED_WITH_WARNING,
    ];

    return approvalStates.includes(this.stripeObject?.onboarding?.status);
  }

  get opacity60(): boolean {
    const states = [
      TacliaPayments.StatusAccount.NOT_EXISTS,
      TacliaPayments.StatusAccount.NOT_STARTED,
      TacliaPayments.StatusAccount.NOT_COMPLETED,
      TacliaPayments.StatusAccount.PENDING,
    ];

    return states.includes(this.stripeObject?.onboarding?.status);
  }

  changeFinalSelect(
    evt: Array<{ _id: string; img: string; title: string; value: string }>
  ) {
    this.selectedFinal = evt;
    const sale = this.saleStorageService.getSale();

    this.saleStorageService.setSale({
      ...sale,
      final: evt.length ? evt[0] : null,
    });
    this.draw();
  }

  private findFinals() {
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Finals)))
        .subscribe(() => {
          this.finalsLoading = true;
          this.draw();

          this.finalService
            .search({
              limit: 999999999,
              optimized: true,
            })
            .pipe(
              map((res) => MapperFinal(res.docs)),
              finalize(() => {
                this.finalsLoading = false;
                this.finalsCharged = true;
                this.draw();
              })
            )
            .subscribe((res) => {
              this.finalSelectItem = res
                .map((res) => {
                  return {
                    title: `${res.name} ${res.lastName || ''}`,
                    value: res._id,
                    _id: res._id,
                    img: '',
                  };
                })
                .sort((a, b) => (a.title > b.title ? 1 : -1));
              this.draw();
            });
        })
    );
  }

  addFinals() {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.sale_card_final_start,
    });
    this.sub$.add(
      this.createFinalV2Service.open().subscribe((data) => {
        this.refreshFinals(data);
      })
    );
  }

  private refreshFinals(idAdded: string) {
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Finals)))
        .subscribe(() => {
          this.finalsLoading = true;
          this.draw();

          this.finalService
            .findAllFinalsByCompany_SELECT()
            .pipe(
              finalize(() => {
                this.finalsLoading = false;
                this.draw();
              })
            )
            .subscribe((res) => {
              this.finalSelectItem = res.map((res) => {
                return {
                  title: `${res.name} ${res.lastName || ''}`,
                  value: res._id,
                  id: res._id,
                };
              });

              this.updateSelectedContact(idAdded);
            });
        })
    );
  }

  async requestSale(id: string) {
    this.isLoading = true;
    try {
      const res = await this.salesSearchService
        .search({
          id,
        })
        .toPromise();

      if (res.docs.length) {
        this.patchFormValues(res.docs[0]);
      }

      this.draw();
    } catch (error) {
      console.log({ error });
    } finally {
      this.isLoading = false;
      this.draw();
    }
  }

  private patchFormValues(sale: Sale.Output) {
    const saleCreate: SaleStorageService.Storage = {
      idStatus: sale.idStatus,
      final: sale?.final,
      deal: sale?.deal,
      taxes: sale?.taxes,
      items: sale?.items,
      paymentMethod: sale?.paymentMethod,
      paymentDate: sale?.paymentDate,
      subtotal: sale?.subtotal,
      total: sale.total,
      discount: sale?.discount,
      discountAmount: sale?.discountAmount,
      cancelReason: sale?.cancelReason,
      steps: SaleStorageService.GenerateSaleSteps(sale.idStatus),
      sale: sale,
      cancelDate: sale?.cancelDate,
    };

    this.saleStorageService.setSale(saleCreate);

    if (saleCreate?.sale?.refund && saleCreate.idStatus === 1) {
      this.refundedSale = true;
      this.refundText = 'sales.salesCard.refundTo';
    }

    if (saleCreate?.sale?.refund && saleCreate.idStatus === 4) {
      this.refundedSale = true;
      this.refundText = 'sales.salesCard.refundFrom';
    }
  }

  openSale(object) {
    const refundedId = object.sale.refund._id;
    if (refundedId) {
      window.open(`/admin/sales?type=sale&id=${refundedId}`, '_blank');
    }
  }

  updateSelectedContact(idContactAdded: string) {
    this.selectedFinal = [
      this.finalSelectItem.find((c) => c.id === idContactAdded),
    ];
    if (this.selectedFinal.length > 0) {
      this.changeFinalSelect(this.selectedFinal);
    }
  }

  resolveTaxes() {
    this.sub$.add(
      this.settingsService.settings$.subscribe((data) => {
        if (data.invoiceSettings?.defaultTaxesList?.length) {
          this.taxes = this.taxService.sort(
            data.invoiceSettings.defaultTaxesList
          );
        } else {
          this.taxes = this.taxService.findAll();
        }

        this.taxes.forEach((tax) => {
          const filterItem = {
            title: tax.name,
            value: tax.value,
            id: tax._id,
            translate: tax.translate,
          };

          this.taxesList.push(filterItem);
        });
      })
    );
  }

  private watchStorageChanges() {
    this.sub$.add(
      this.saleStorageService.sale$.subscribe((res) => {
        if (!this.saleStorageService.storageImage) {
          this.haveChanges = false;
        } else {
          const current = JSON.stringify(res);

          this.haveChanges =
            current !== this.saleStorageService.storageImage ? true : false;
        }

        this.draw();
      })
    );
  }

  onClickFinal() {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.sale_card_addFinal,
    });
    if (!this.finalsCharged) {
      this.findFinals();
    }
  }

  changeProducts(
    event: Array<{
      _id: string;
      stock: number;
      sellPrice: number;
      name: string;
    }>
  ) {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.sale_card_addCatalogue,
    });

    const sale = this.saleStorageService.getSale();

    this.saleStorageService.setSale({
      ...sale,
      items: event,
    });
  }

  updateDiscount() {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.sale_card_addDiscount,
    });

    if (this.discount <= 0 || this.discount >= 100) {
      this.discount = 0;
    }

    if (this.discount === null || this.discount === undefined) {
      this.discount = 0;
    }

    const sale = this.saleStorageService.getSale();

    this.saleStorageService.setSale({
      ...sale,
      discount: this.discount,
    });
  }

  changeTaxes(event: Array<{ title: string; value: number }>) {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.sale_card_addTax,
    });

    this.selectedTaxes = event;
    const taxes: Array<{
      _id: string;
      name: string;
      translate: string;
      value: number;
      type: TypeTax;
    }> = [];

    for (const selectedTax of event) {
      const tax = this.taxes.find(
        (el) => el.value === selectedTax.value && el.name === selectedTax.title
      );
      taxes.push({
        _id: tax?._id ? tax._id : '',
        name: tax?.name ? tax.name : '',
        translate: tax?.translate ? tax.translate : '',
        value: tax?.value ? tax.value : 0,
        type: tax?.type ? tax.type : null,
      });
    }

    const sale = this.saleStorageService.getSale();

    this.saleStorageService.setSale({
      ...sale,
      taxes,
    });
  }

  download() {
    const user = this.authService.user;
    const storage = this.saleStorageService.getSale();
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.sale_cardCompleted_download,
    });
    this.salesService
      .downloadSale({
        ids: [storage.sale?._id],
        ticketNumber: storage.sale?.number?.toString(),
        finalName: storage.sale?.final?.title?.toString(),
        user: {
          _id: user._id,
          company: user.company._id,
        },
        lang: this.getLang(),
      })
      .subscribe((res) => {
        this.toastService.show({
          text: this.i18n.instant('sales.ticketDownloaded'),
          type: 'success',
          msDuration: 4000,
          class: '',
        });
      });
  }

  changeComma(value: number) {
    const valueSttring = String(value);
    return valueSttring.replaceAll('.', ',');
  }

  openSaleDealModal(): void {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.sale_card_deal,
    });

    const sale = this.saleStorageService.getSale();

    this.userDealsSelectModalService
      .open({
        data: {
          selectedDeal: null,
          items: sale.items,
        },
      })
      .subscribe((res: RmDealSelect.Items) => {
        if (res?.length) {
          this.analyticsService.trackEvent({
            sources: ['amplitude'],
            eventName: AmplitudeEvents.sale_card_deal_add,
          });
          const sale = this.saleStorageService.getSale();
          const [deal] = res;

          let storageUpdate: SaleStorageService.Storage = {
            ...sale,
          };

          if (deal.final?._id) {
            this.changeFinalSelect([
              {
                _id: deal.final?._id,
                title: `${deal.final?.name} ${
                  deal.final?.lastName ? deal.final?.lastName : ''
                }`,
                value: deal.final?._id,
                img: '',
              },
            ]);

            storageUpdate = {
              ...storageUpdate,
              final: {
                _id: deal.final?._id,
                title: `${deal.final?.name} ${
                  deal.final?.lastName ? deal.final?.lastName : ''
                }`,
                value: deal.final?._id,
                img: '',
              },
            };

            this.showFinalsSelect = true;
          }

          this.saleStorageService.setSale({
            ...storageUpdate,
            deal: deal,
            items: deal.products || [],
          });

          this.dealLinked = deal;

          this.draw();
        }
      });
  }

  removeSaleDeal() {
    this.unbindDealSaleModalService
      .open({
        data: {
          confirm: false,
        },
      })
      .subscribe((res) => {
        if (res?.confirm) {
          this.amplitudeService.sendEvent({
            event: AmplitudeEvents.sale_card_deal_cancel,
          });
          const sale = this.saleStorageService.getSale();
          this.dealLinked = null;
          this.saleStorageService.setSale({
            ...sale,
            deal: this.dealLinked,
            items: [],
            final: null,
          });
          this.changeFinalSelect([]);
          this.showFinalsSelect = false;
        }
      });
  }

  isEmptyHour(date: Date) {
    return (
      typeof date === 'object' &&
      !date.getHours() &&
      !date.getMinutes() &&
      !date.getSeconds()
    );
  }

  resetDiscount() {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.sale_card_removeDiscount,
    });
    this.discount = 0;

    const sale = this.saleStorageService.getSale();

    this.saleStorageService.setSale({
      ...sale,
      discount: 0,
      discountAmount: 0,
    });
  }

  onChangePaymentMethod(
    event,
    paymentMethod: Sale.PaymentMethodEnum = Sale.PaymentMethodEnum.Cash
  ) {
    const sale = this.saleStorageService.getSale();

    if (event.checked) {
      this.selectedPaymentMethod = paymentMethod;
      const eventTrackingMap = new Map<Sale.PaymentMethodEnum, string>([
        [Sale.PaymentMethodEnum.Cash, AmplitudeEvents.sale_card_payment_cash],
        [Sale.PaymentMethodEnum.Card, AmplitudeEvents.sale_card_payment_card],
        [
          Sale.PaymentMethodEnum.Others,
          AmplitudeEvents.sale_card_payment_other,
        ],
        [
          Sale.PaymentMethodEnum.PaymentLink,
          AmplitudeEvents.sale_card_payment_link,
        ],
        [Sale.PaymentMethodEnum.Link, AmplitudeEvents.sale_card_payment_link],
        [
          Sale.PaymentMethodEnum.Transfer,
          AmplitudeEvents.sale_card_payment_transfer,
        ],
      ]);

      const eventTracking = eventTrackingMap.get(paymentMethod);

      this.analyticsService.trackEvent({
        sources: ['amplitude', 'braze', 'userflow'],
        eventName: eventTracking,
      });

      this.saleStorageService.setSale({
        ...sale,
        paymentMethod: paymentMethod,
      });
    } else {
      // Conditional to show "disabled modal" when payment are not enabled (in mobile)
      if (
        !this.stripeAccountIsApproved() &&
        this.showPaymentLinkTooltip() &&
        window.screen.width < 768 &&
        this.selectedPaymentMethod === Sale.PaymentMethodEnum.None
      ) {
        const dialog = this.dialog.open(ModalMobileInfoComponent, {
          data: {
            html: 'payments.disabled',
            buttonText: 'kanban.dialog.confirmButton',
          },
        });
      }

      this.selectedPaymentMethod = Sale.PaymentMethodEnum.None;

      this.saleStorageService.setSale({
        ...sale,
        paymentMethod: paymentMethod,
      });
    }

    if (paymentMethod === 'link') {
      this.shareLinkTitle = 'sales.salesCard.chargeAndShareLink';
      //Open share link modal here
    } else {
      this.shareLinkTitle = 'sales.salesCard.charge';
    }
  }

  submitSaleForm() {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.sale_card_payment,
    });
    this.analyticsService.trackEvent({
      sources: ['braze', 'userflow'],
      eventName: BrazeEventType.sale_create,
    });

    const sale = this.saleStorageService.getSale();
    if (sale?.items?.length || sale?.total !== 0) {
      this.stepActive(['paymentMethods']);
    }
  }

  payNow() {
    this.type === 'EDIT';
    this.stepActive(['paymentMethods']);
  }

  tryAgain() {
    this.type === 'EDIT';
  }

  saveUnPaid() {
    this.paymentDate = new Date();

    const sale = this.saleStorageService.getSale();

    this.saleStorageService.setSale({
      ...sale,
      paymentDate: this.paymentDate,
      idStatus: StatusSalesId.NotPaid,
    });

    this.sub$.add(
      this.submit().subscribe(() => {
        this.changeModalType('EDIT');
        this.stepActive(['paymentUnpaid']);
        this.refreshModalOnClose = true;
        this.scrollToTop(this.modalContainer.nativeElement);
      })
    );
  }

  private changeModalType(type: 'ADD' | 'EDIT') {
    this.type = type;
    this.patchDialogData['mode'] = type;
    this.draw();
  }

  private stepActive(steps: Array<keyof SaleStorageService.SaleStepType>) {
    const newSaleStep: SaleStorageService.SaleStepType = {
      saleForm: false,
      paymentFinish: false,
      paymentUnpaid: false,
      paymentRefund: false,
      paymentRefundFinish: false,
      paymentMethods: false,
      paymentCancelFinish: false,
      paymentPending: false,
      paymentRejected: false,
    };

    for (const step of steps) {
      newSaleStep[step] = true;
    }

    const sale = this.saleStorageService.getSale();

    this.saleStorageService.setSale({
      ...sale,
      steps: newSaleStep,
    });

    this.draw();
  }

  // @ts-ignore
  private submit() {
    let eventData: { event: 'sale_create' };
    this.isLoading = true;

    if (this.type === 'ADD') {
      eventData = {
        event: 'sale_create',
      };

      const sale = this.saleStorageService.getSale();
      const isNotPaid =
        this.selectedPaymentMethod === this.salePaymentMethodRef.PaymentLink ||
        this.selectedPaymentMethod === this.salePaymentMethodRef.Transfer;

      const saleCreate: Sale.CreateSaleDto = {
        idStatus: isNotPaid ? StatusSalesId.PaymentPending : sale.idStatus,
        final: sale.final,
        deal: sale.deal,
        taxes: sale.taxes,
        items: sale.items,
        paymentMethod: sale.paymentMethod,
        paymentDate: sale.paymentDate,
        subtotal: sale.subtotal,
        total: sale.total,
        discount: sale.discount,
        discountAmount: sale.discountAmount,
      };

      if (
        this.selectedPaymentMethod === this.salePaymentMethodRef.PaymentLink ||
        this.selectedPaymentMethod === this.salePaymentMethodRef.Transfer
      ) {
        const obs$ =
          this.selectedPaymentMethod === this.salePaymentMethodRef.PaymentLink
            ? this.salesService.createSaleAndPaymentLink(saleCreate)
            : this.salesService.createSaleAndTransfer(saleCreate);

        return this.openShareModalAfterCreate_PL(obs$, eventData);
      }

      return this.salesService.createOne(saleCreate).pipe(
        tap((res) => {
          this.idSale = res._id;
        }),
        concatMap((res) => {
          return from(this.requestSale(res._id));
        }),
        finalize(() => {
          this.saleStorageService.commit();
          this.amplitudeService.sendEvent(eventData);

          this.isLoading = false;
          this.haveChanges = false;
          this.draw();
        })
      );
    }
  }

  generatePayment() {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.sale_card_payment_collect,
    });
    const mode = this.patchDialogData?.mode
      ? this.patchDialogData?.mode
      : 'ADD';
    const paymentDate = new Date();
    const sale = this.saleStorageService.getSale();

    this.saleStorageService.setSale({
      ...sale,
      paymentDate,
      idStatus: StatusSalesId.Paid,
    });

    const storage = this.saleStorageService.getSale();

    const obs$ =
      mode === 'ADD'
        ? this.submit()
        : this.editSubmit({
            idStatus: storage.idStatus,
            paymentMethod: storage.paymentMethod,
          });

    this.sub$.add(
      obs$.subscribe((res) => {
        this.paymentDate = paymentDate;

        if (
          this.selectedPaymentMethod ===
            this.salePaymentMethodRef.PaymentLink ||
          this.selectedPaymentMethod === this.salePaymentMethodRef.Transfer
        ) {
          this.stepActive(['paymentUnpaid']);
        } else {
          this.stepActive(['paymentFinish']);
        }
        // this.stepActive(['paymentFinish']);
        this.refreshModalOnClose = true;

        this.scrollToTop(this.modalContainer.nativeElement);
      })
    );
  }

  // @ts-ignore
  editSubmit(updateDto: Sale.UpdateSaleDto) {
    let eventData: { event: 'sale_updated' };

    if (this.type === 'EDIT') {
      eventData = {
        event: 'sale_updated',
      };

      const storage = this.saleStorageService.getSale();

      const saleUpdate: Sale.UpdateSaleDto = {
        ...updateDto,
      };

      if (
        storage.paymentMethod === this.salePaymentMethodRef.PaymentLink &&
        !storage.cancelReason
      ) {
        saleUpdate.idStatus = StatusSalesId.PaymentPending;
        return this.openShareModalAfterUpdate_PL(
          storage.sale._id,
          saleUpdate,
          eventData
        );
      }

      return this.salesService.updateOne(storage.sale._id, saleUpdate).pipe(
        concatMap(() => {
          return from(this.requestSale(storage.sale._id));
        }),
        finalize(() => {
          this.haveChanges = false;
          this.draw();
          this.saleStorageService.commit();
          this.amplitudeService.sendEvent(eventData);
        })
      );
    }
  }

  openShareModalAfterUpdate_PL(
    saleId: string,
    saleUpdate: Sale.UpdateSaleDto,
    eventData: { event: string }
  ) {
    this.isLoading = true;
    return this.salesService.updateOne(saleId, saleUpdate).pipe(
      tap((res) => {
        this.idSale = res['_id'];
      }),
      concatMap((res) => {
        return from(this.requestSale(res['_id']));
      }),
      catchError((error) => {
        this.toastService.show({
          text: this.i18n.instant('sales.paymentLinkCreationError'),
          type: 'error',
          msDuration: 4000,
          class: '',
        });

        return throwError(this.i18n.instant('sales.paymentLinkCreationError'));
      }),
      finalize(() => {
        this.saleStorageService.commit();
        this.amplitudeService.sendEvent(eventData);
        const sale = this.saleStorageService.getSale()?.sale;

        if (sale?.paymentLink?.url) {
          this.sharePaymentLinkModalService.open({
            data: {
              idSale: this.idSale,
              url: sale.paymentLink.url,
            },
          });
        }

        this.isLoading = false;
        this.haveChanges = false;
        this.draw();
      })
    );
  }

  openShareModalAfterCreate_PL(
    obs$: Observable<Sale.Output>,
    eventData: { event: string }
  ) {
    return obs$.pipe(
      tap((res) => {
        this.idSale = res['_id'];
      }),
      concatMap((res) => {
        return from(this.requestSale(res['_id']));
      }),
      catchError((error) => {
        this.toastService.show({
          text: this.i18n.instant('sales.paymentLinkCreationError'),
          type: 'error',
          msDuration: 4000,
          class: '',
        });

        return throwError(this.i18n.instant('sales.paymentLinkCreationError'));
      }),
      finalize(() => {
        this.saleStorageService.commit();
        this.amplitudeService.sendEvent(eventData);
        const sale = this.saleStorageService.getSale()?.sale;

        if (sale?.paymentLink?.url) {
          this.analyticsService.trackEvent({
            sources: ['amplitude'],
            eventName: AmplitudeEvents.sale_view_charge,
          });
          this.sharePaymentLinkModalService.open({
            data: {
              idSale: this.idSale,
              url: sale.paymentLink.url,
            },
          });
        }

        this.isLoading = false;
        this.haveChanges = false;
        this.draw();
      })
    );
  }

  openCancelSaleModal(): void {
    const self = this;
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.sale_cardCompleted_cancel,
    });
    this.cancelSaleModalService
      .open({
        data: {},
      })
      .subscribe((res) => {
        if (res?.cancelReason) {
          const sale = this.saleStorageService.getSale();

          this.saleStorageService.setSale({
            ...sale,
            idStatus: StatusSalesId.Cancelled,
            cancelReason: res?.cancelReason,
            cancelDate: new Date(),
          });

          const storage = this.saleStorageService.getSale();

          self
            .editSubmit({
              idStatus: storage.idStatus,
              cancelReason: storage.cancelReason,
              cancelDate: new Date(),
            })
            .subscribe((res) => {
              this.refreshModalOnClose = true;

              this.toastService.show({
                text: this.i18n.instant('sales.saleCancelSuccess'),
                type: 'error',
                msDuration: 4000,
                class: '',
              });
            });

          this.scrollToTop(this.modalContainer.nativeElement);
        }
      });
  }
  activeRefund() {
    this.stepActive(['paymentRefund', 'paymentMethods']);
  }

  finishRefund() {
    // REFUND SUBMIT
    this.refundStart = true;
    this.salesService.createRefund(this.idSale).subscribe((res) => {
      this.requestSale(res._id).then((res2) => {
        this.stepActive(['paymentRefundFinish']);
        this.haveChanges = false;
        this.refundedSale = true;
        this.refundText = 'sales.salesCard.refundFrom';
        this.refundStart = false;
        this.refreshModalOnClose = true;
        this.scrollToTop(this.modalContainer.nativeElement);
      });
    });
  }

  saveAll() {}

  sendSaleEmail() {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.sale_cardCompleted_email,
    });
    this.sendEmailSaleModalService
      .open({
        data: {
          idSale: this.idSale,
          saleStorage: this.saleStorageService.getSale(),
        },
      })
      .subscribe((res) => {
        this.draw();
      });
  }

  showPaymentLinkFunctionality() {
    const storage = this.saleStorageService.getSale();
    const result =
      Boolean(storage.sale?.paymentLink?.url) ||
      Boolean(storage?.paymentLink?.url);

    return result;
  }

  openShareLink() {
    this.sharePaymentLinkModalService
      .open({
        data: {
          idSale: this.idSale,
          saleStorage: this.saleStorageService.getSale(),
        },
      })
      .subscribe((data) => {
        // console.log('openShareLink subs data', data);
      });
  }

  showDialog(): void {
    const isMobile = window.screen.width < 768;
    if (!isMobile) {
      this.showToolTip = !this.showToolTip;
    } else {
      const dialog = this.dialog.open(ModalMobileInfoComponent, {
        data: {
          html: 'sales.salesCard.tooltip',
          buttonText: 'kanban.dialog.confirmButton',
        },
      });
    }
  }

  toggleTooltip(evt) {
    this.showToolTip = evt;
  }

  showPaymentLinkTooltip() {
    const states = [
      TacliaPayments.StatusAccount.SUSPENDED,
      TacliaPayments.StatusAccount.REJECTED,
    ];

    return states.includes(this.stripeObject?.onboarding?.status);
  }

  scrollToTop(el) {
    const duration = 500;
    const interval = 5;
    const move = (el.scrollTop * interval) / duration;
    observableInterval(interval)
      .pipe(
        scan((acc, curr) => acc - move, el.scrollTop),
        tap((position) => (el.scrollTop = position)),
        takeWhile((val) => val > 0)
      )
      .subscribe();
  }

  fillAutomaticallyFromDeal(deal: IDeal) {
    if (!deal?._id || !deal?.name) return;

    const sale = this.saleStorageService.getSale();

    let storageUpdate: SaleStorageService.Storage = {
      ...sale,
    };

    if (deal.final?._id) {
      this.changeFinalSelect([
        {
          _id: deal.final?._id,
          title: `${deal.final?.name} ${
            deal.final?.lastName ? deal.final?.lastName : ''
          }`,
          value: deal.final?._id,
          img: '',
        },
      ]);

      storageUpdate = {
        ...storageUpdate,
        final: {
          _id: deal.final?._id,
          title: `${deal.final?.name} ${
            deal.final?.lastName ? deal.final?.lastName : ''
          }`,
          value: deal.final?._id,
          img: '',
        },
      };

      this.showFinalsSelect = true;
    }

    this.saleStorageService.setSale({
      ...storageUpdate,
      deal: {
        _id: deal._id,
        name: deal.name,
      },
      items: deal.products || [],
    });

    this.dealLinked = {
      _id: deal._id,
      name: deal.name,
      final: deal.final ?? null,
      products: deal.products ?? [],
    };

    this.haveChanges = true;
    this.draw();
  }
}
