<div *ngIf="activeItems.length" class="active-items-list">
  <div
    *ngFor="let item of activeItems"
    class="active-item"
    (click)="activeItems.length === 1 ? removeItem(item) : null"
  >
    <div *ngIf="type === 'taxes'; else customers">
      {{ item.translate | translate }} {{ item.value }}%
    </div>

    <!--Customers-->
    <ng-template #customers>
      <span class="icon-text"> {{ item?.title }} </span>
    </ng-template>

    <img
      class="clean-input"
      src="assets/icons/gl_close-input.svg"
      (click)="removeItem(item)"
    />
  </div>
</div>

<div
  *ngIf="!activeItems.length && !showPanelElement"
  class="users-list"
  style="padding: 7px 16px; border: 1px solid #c1cdf5; cursor: pointer"
  (click)="showPanelElement = !showPanelElement"
>
  <div class="search-input">
    <div class="fake__input" style="font-size: 14px; color: #8c9dd2">
      {{ 'general.select' | translate }}
    </div>
    <img
      [src]="'assets/icons/gl_arrow_down.svg'"
      (click)="focusOutA = false; clickPanel($event, null)"
      style="right: 0"
    />
  </div>
</div>

<button
  *ngIf="mode === 'MULTIPLE' && activeItems.length && !showPanelElement"
  type="button"
  class="add-field-btn"
  (click)="showPanelElement = !showPanelElement"
>
  {{ 'general.addButton' | translate }}
  <img src="assets/icons/gl_plus.svg" style="margin-top: -5px" />
</button>

<!-- desktop display -->
<ng-container *ngIf="!isResponsive">
  <div [ngStyle]="showPanelElement && { height: '36px' }">
    <!--(focusout)="focusOut('status')"-->
    <div
      *ngIf="!isLoading && showPanelElement && mode === 'SINGLE'"
      romaRomaToolTip
      [showToolTip]="showPanelElement"
      (clicked)="showPanelElement = $event"
      class="users-list"
      [ngStyle]="listWidth !== '' && { width: listWidth }"
      [ngClass]="{ 'is-absolute': isAbsolute }"
      (focusin)="focusInternal = true"
    >
      <div class="search-input">
        <input
          #inputFilterSearch
          class="filter-input"
          [ngStyle]="{ height: inputHeight }"
          type="text"
          [placeholder]="'general.search' | translate"
        />
        <img
          [src]="'assets/icons/gl_arrow_down.svg'"
          (click)="focusOutA = false; clickPanel($event, null)"
        />
      </div>
      <div class="list-wrapper" [ngClass]="{ 'solo-list': type !== 'users' }">
        <div
          *ngFor="let item of items | searchFilter: filterArgs; let i = index"
          class="user-item single-check"
          [ngClass]="{ active: activeItems[0]?.value === item.value }"
          (click)="changeSelect(item)"
        >
          <img
            *ngIf="activeItems[0]?.value === item.value"
            class="active-check"
            src="assets/icons/gl_check.svg"
          />
          <img
            *ngIf="item.hasOwnProperty('img')"
            class="user-img"
            [src]="
              item?.img
                ? (item.img | imageFirebase: 'user')
                : '/assets/icons/table-user-icon.svg'
            "
          />
          {{ item.title | translate }}
          <span *ngIf="item.price" class="__price">
            {{ item.price || 0 | currencyFormat }}
          </span>
        </div>
      </div>
      <div
        *ngIf="type === 'users'"
        class="add-user"
        romaProFeature
        [featureUser]="addButtonFeatureUser"
        (clicked)="addElementHandle($event)"
      >
        <img src="/assets/icons/gl_plus.svg" />
        {{ addButtonText | translate }}
      </div>
    </div>

    <div
      *ngIf="!isLoading && showPanelElement && mode === 'MULTIPLE'"
      romaRomaToolTip
      [showToolTip]="showPanelElement"
      (clicked)="showPanelElement = $event"
      class="users-list"
      style="padding-top: 0"
      [ngStyle]="listWidth !== '' && { width: listWidth }"
      [ngClass]="{ 'is-absolute': isAbsolute, 'taxes-list': type === 'taxes' }"
      (focusin)="focusInternal = true"
    >
      <div class="search-input">
        <input
          #inputFilterSearch
          [ngStyle]="{ height: inputHeight }"
          class="filter-input"
          type="text"
          [placeholder]="'general.search' | translate"
        />
        <img
          [src]="'assets/icons/gl_arrow_down.svg'"
          (click)="focusOutA = false; clickPanel($event, null)"
        />
      </div>
      <div class="list-wrapper">
        <div
          *ngFor="let item of items | searchFilter: filterArgs; let i = index"
          class="user-item"
        >
          <div *ngIf="type !== 'taxes'">
            <mat-checkbox
              (click)="
                anuleFocusOutTimeout();
                changeCheckBox({ checked: !searchCheckFilters(item) }, item)
              "
              [checked]="searchCheckFilters(item) ? true : false"
            >
              <div *ngIf="type === 'taxes'">
                {{ item?.name }}
              </div>

              <img
                *ngIf="item.hasOwnProperty('img')"
                class="user-img"
                [src]="
                  item?.img
                    ? (item.img | imageFirebase: 'user')
                    : '/assets/icons/table-user-icon.svg'
                "
              />
              {{ item.title | translate }}
              <span *ngIf="item.price" class="__price">
                {{ item.price || 0 | currencyFormat }}
              </span>
            </mat-checkbox>
          </div>

          <div
            *ngIf="type === 'taxes'"
            (click)="
              anuleFocusOutTimeout();
              changeCheckBox({ checked: !searchCheckFilters(item) }, item)
            "
          >
            <div class="check__icon_wrapper"></div>
            <img
              *ngIf="searchCheckFilters(item) ? true : false"
              class="active-check taxes"
              src="assets/icons/gl_check.svg"
            />
            {{ item.translate | translate }} {{ item.value }}%
          </div>
        </div>
      </div>
      <div
        *ngIf="type === 'users' && showAddButton"
        class="add-user"
        romaProFeature
        [featureUser]="addButtonFeatureUser"
        (clicked)="addElementHandle($event)"
      >
        <img src="/assets/icons/gl_plus.svg" />
        {{ addButtonText | translate }}
      </div>
    </div>
  </div>
</ng-container>
<!-- mobile display -->
<ng-container *ngIf="isResponsive">
  <div class="list-dropdown-select">
    <div class="mobile-display">
      <div
        *ngIf="showPanelElement"
        class="mobile-list-wrapper"
        (click)="showPanelElement = !showPanelElement"
      ></div>
      <div *ngIf="showPanelElement" class="list-box">
        <h4>{{ placeholderText | translate }}</h4>
        <div *ngIf="isLoading" style="text-align: center; width: 100%">
          <mat-progress-spinner
            mode="indeterminate"
            [diameter]="60"
            style="margin: 40px auto"
          ></mat-progress-spinner>
        </div>
        <div *ngIf="!isLoading" class="search-input secondary">
          <input
            #inputFilterSearch
            class="filter-input"
            type="text"
            [placeholder]="'general.search' | translate"
          />
        </div>
        <div *ngIf="mode === 'SINGLE' && !isLoading" class="list-wrapper">
          <div
            class="list-item"
            *ngFor="let item of items | searchFilter: filterArgs; let i = index"
            [ngClass]="{ active: activeItems[0]?.value === item.value }"
            (click)="changeSelect(item)"
            style="padding-left: 28px"
          >
            <img
              *ngIf="searchCheckFilters(item) ? true : false"
              class="active-check"
              style="visibility: visible"
              src="assets/icons/gl_check.svg"
            />
            {{ item.title | translate }}
          </div>
        </div>
        <div *ngIf="mode === 'MULTIPLE' && !isLoading" class="list-wrapper">
          <div
            *ngFor="let item of items | searchFilter: filterArgs; let i = index"
            class="list-item"
          >
            <div *ngIf="type !== 'taxes'">
              <mat-checkbox
                (click)="
                  anuleFocusOutTimeout();
                  changeCheckBox({ checked: !searchCheckFilters(item) }, item)
                "
                [checked]="searchCheckFilters(item) ? true : false"
              >
                <div *ngIf="type === 'taxes'">
                  {{ item?.name }}
                </div>

                <img
                  *ngIf="item.hasOwnProperty('img')"
                  class="user-img"
                  [src]="
                    item?.img
                      ? (item.img | imageFirebase: 'user')
                      : '/assets/icons/table-user-icon.svg'
                  "
                />
                {{ item.title | translate }}
                <span *ngIf="item.price" class="__price">
                  {{ item.price || 0 | currencyFormat }}
                </span>
              </mat-checkbox>
            </div>

            <div
              *ngIf="type === 'taxes'"
              (click)="
                anuleFocusOutTimeout();
                changeCheckBox({ checked: !searchCheckFilters(item) }, item)
              "
            >
              <div class="check__icon_wrapper"></div>
              <img
                *ngIf="searchCheckFilters(item) ? true : false"
                class="active-check taxes"
                src="assets/icons/gl_check.svg"
              />
              {{ item.translate | translate }} {{ item.value }}%
            </div>
          </div>
        </div>
        <div class="text-center close-btn-wrapper">
          <img
            (click)="showPanelElement = !showPanelElement"
            class="close-btn"
            src="/assets/icons/btn_close.svg"
            alt="close"
          />
        </div>
      </div>
    </div>
  </div>
</ng-container>
