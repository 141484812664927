export enum UserflowEventType {
  absence_approved = 'absence_approved',
  absenceTeam_create = 'absenceTeam_create',
  absence_create = 'absence_create',
  absence_pending_approval = 'absence_pending_approval',
  absence_rejected = 'absence_rejected',
  absenceTeam_edit = 'absenceTeam_edit',
  absence_edit = 'absence_edit',

  deal_start = 'deal_start',
  deal_create = 'deal_create',
  deal_edit = 'deal_edit',
  generalStart_deal = 'generalStart_deal',
  deal_card_editUser_start = 'deal_card_editUser_start',
  deal_card_editOwner_start = 'deal_card_editOwner_start',
  deal_card_editSupervisor_start = 'deal_card_editSupervisor_start',
  deal_canceled = 'deal_canceled',
}
