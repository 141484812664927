import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatesSelectTableComponent } from './rates-select-table.component';
import { I18nChild } from '@web-frontend/shared/i18n';
import { CreateCatalogueModule } from '../create-catalogue';
import { RmSelectModule } from '../globals/rm-select';
import { CurrencyFormatModule } from '@web-frontend/shared/pipes/currency-format/currency-format.module';
import { EditCatalogueModalComponent } from './edit-catalogue-modal/edit-catalogue-modal.component';
import { ModalTemplateModule } from '../modal-template';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MobileModalListModule } from '../mobile-modal-list/mobile-modal-list.module';
import { PermissionsModule } from '../permissions';
import { OnlyNumberModule } from '../../directives/onlyNumber';

@NgModule({
  declarations: [RatesSelectTableComponent, EditCatalogueModalComponent],
  imports: [
    CommonModule,
    I18nChild,
    RmSelectModule,
    CreateCatalogueModule,
    CurrencyFormatModule,
    ModalTemplateModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MobileModalListModule,
    PermissionsModule,
    OnlyNumberModule,
  ],
  exports: [RatesSelectTableComponent],
  providers: [],
})
export class RatesSelectTableModule {}
