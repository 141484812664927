import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  DynamicPropertyRef,
  FreeLimitType,
  ICustomGlobalProperty,
  ICustomGroupGlobalProperty,
  ICustomProperty,
  ICustomPropertyCreateDto,
} from '@tacliatech/types';

import { map, tap } from 'rxjs/operators';
import { FreeLimitService } from '../free-limit';

@Injectable({
  providedIn: 'root',
})
export class DynamicPropertyService {
  constructor(
    private http: HttpClient,
    private freeLimitService: FreeLimitService
  ) {}

  findDynamicProperties(idCompany: string, ref: DynamicPropertyRef) {
    return this.http
      .get<ICustomProperty[]>(
        `:API_URL/dynamic-properties/users/${idCompany}/${ref}`
      )
      .pipe(
        map((res) => {
          const propertiesMap = new Map<string, ICustomProperty>();

          for (const field of res) {
            propertiesMap.set(field.name, field);
          }

          return Array.from(propertiesMap.values());
        })
      );
  }

  createGlobal(data: ICustomPropertyCreateDto) {
    return this.http.post(`:API_URL/dynamic-properties`, { ...data }).pipe(
      tap((res) => {
        this.freeLimitService.setFreeLimit(res as FreeLimitType.Response);
      })
    );
  }

  editGlobal(data: ICustomGlobalProperty) {
    return this.http.put(`:API_URL/dynamic-properties`, { ...data });
  }

  deleteGlobal(id: string) {
    return this.http.delete(`:API_URL/dynamic-properties/${id}/`).pipe(
      tap((res) => {
        this.freeLimitService.setFreeLimit(res as FreeLimitType.Response);
      })
    );
  }

  findGlobalByUserAndRef(id: string, ref: DynamicPropertyRef) {
    return this.http.get<ICustomGlobalProperty[]>(
      `:API_URL/dynamic-properties/users/${id}/${ref}`
    );
  }

  getGroups() {
    return this.http.get<ICustomGroupGlobalProperty[]>(
      `:API_URL/dynamic-properties-group`
    );
  }

  findByGroup(idGroup: string) {
    return this.http.get<ICustomGlobalProperty[]>(
      `:API_URL/dynamic-properties/groups/${idGroup}`
    );
  }

  createGroup({ names }: { names: string[] }) {
    return this.http.post<unknown>(`:API_URL/dynamic-properties-group`, {
      names,
      idCompanies: ['TEST'],
    });
  }

  updateOneGroup(id: string, data: { name: string }) {
    return this.http.put<unknown>(`:API_URL/dynamic-properties-group/${id}`, {
      name: data.name,
    });
  }

  deleteGroup(id: string) {
    return this.http.delete<unknown>(`:API_URL/dynamic-properties-group/${id}`);
  }
}
