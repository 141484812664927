<div class="kanban-column-header">
  <div class="kanban-column-title">
    <ng-container *ngIf="!editName">
      <div class="kanban-column-title-text">
        <roma-rm-badge
          [statusName]="
            column.name?.length > 19
              ? column.name.slice(0, 19) + '...'
              : column.name
          "
          [color]="column?.color"
        >
        </roma-rm-badge>
      </div>
      <div
        *ngIf="cards?.length > 0 && !isLoading"
        class="kanban-column-card-quantity"
        [ngStyle]="{
          'background-color': defaultColor(column?.color)?.lightColor,
          color: defaultColor(column?.color)?.darkColor
        }"
      >
        {{ cards.length }}
      </div>
      <mat-progress-spinner
        *ngIf="isLoading"
        class="kanban-column-loading"
        mode="indeterminate"
        [diameter]="20"
      ></mat-progress-spinner>
    </ng-container>
    <div *ngIf="editName" class="kanban-column-title-edit">
      <div class="kanban-column-title-edit__top">
        <input
          class="edit-input"
          type="text"
          [(ngModel)]="columnName"
          (keydown.enter)="editColumn()"
        />
        <roma-icon-color
          *ngIf="!(resize$ | async)?.payload?.mobile"
          [matMenuTriggerFor]="menu"
          [color]="defaultColor(_tempColor)?.lightColor"
        >
        </roma-icon-color>
        <roma-icon-color
          *ngIf="(resize$ | async)?.payload?.mobile"
          [color]="defaultColor(_tempColor)?.lightColor"
          (click)="openChooseColor()"
        >
        </roma-icon-color>
        <mat-menu #menu="matMenu" class="panel-choose">
          <div class="container-choose">
            <div class="choose-label">Color</div>
            <roma-choose-color
              [color]="defaultColor(column?.color)?.lightColor"
              (change)="changeColor($event)"
            >
            </roma-choose-color>
          </div>
        </mat-menu>
      </div>
      <div class="kanban-column-title-edit__bottom">
        <button class="edit-button" (click)="editColumn()">
          {{ 'kanban.save' | translate }}
        </button>
        <div class="cancel-button" (click)="closeColumnButton()">
          {{ 'clockHour.cancel' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!editName" class="kanban-column-buttons">
    <img
      src="assets/icons/kanban/gl_plus-thin.svg"
      romaProFeature
      [featureUser]="featureUserCreate"
      (clicked)="eventAddCardClick('start')"
    />
    <img
      *ngIf="!(resize$ | async)?.payload?.mobile"
      src="assets/icons/kanban/menu.svg"
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="menu"
    />
    <img
      *ngIf="(resize$ | async)?.payload?.mobile"
      src="assets/icons/kanban/menu.svg"
      (click)="showStatusCardMobileMenu = true"
    />
    <mat-menu
      class="card-actions actions-menu"
      #menu="matMenu"
      xPosition="before"
      (closed)="showMoveAllActivities = false"
    >
      <ng-container *ngIf="!showMoveAllActivities">
        <button
          *ngIf="!isSystemColumn"
          class="update"
          mat-menu-item
          (click)="editName = !editName; _tempColor = column.color"
          style="text-align: left"
        >
          <img src="assets/icons/kanban/edit.svg" />
          {{ 'kanban.ranameColumn' | translate }}
        </button>
        <button
          class="update"
          mat-menu-item
          *ngIf="cards.length > 0"
          romaProFeature
          [featureUser]="featureUserUpdate"
          (clicked)="$event.stopPropagation(); showMoveAllActivities = true"
          style="text-align: left"
        >
          <img src="assets/icons/kanban/move.svg" />
          {{ 'kanban.moveAllCards' | translate }}
        </button>
        <button
          [ngClass]="{ delete: !isSystemColumn, disabled: isSystemColumn }"
          mat-menu-item
          romaProFeature
          [featureUser]="featureUserDelete"
          romaClickDelete
          [message]="deleteColumnMessage"
          [allowClick]="!isSystemColumn"
          (wantDelete)="eventDeleteColumn()"
          style="text-align: left"
        >
          <img
            [src]="
              isSystemColumn
                ? 'assets/icons/kanban/gl_trash-gray.svg'
                : 'assets/icons/gl_trash_red.svg'
            "
          />
          {{ 'kanban.deleteColumn' | translate }}
        </button>
        <div *ngIf="isSystemColumn" class="custom-tooltip">
          <div class="tiptext">
            {{ tooltipDeleteSystemColumn | translate }}
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="showMoveAllActivities">
        <ng-container *ngFor="let mainItem of objectKeys(_parsedColumns)">
          <button
            mat-menu-item
            (click)="
              eventMoveAllCards(_parsedColumns[mainItem]);
              showMoveAllActivities = false
            "
            style="text-align: left"
          >
            {{ mainItem }}
          </button>
        </ng-container>
      </ng-container>
    </mat-menu>
  </div>
</div>
<div
  class="kanban-column-body"
  [ngStyle]="!cards.length && { 'padding-top': '0' }"
  [class.disabled]="isLoading"
>
  <ng-container>
    <!-- cdkDropList para scroll interno al mover las cards en listas largas -->
    <!-- cdkDropListDropped es un output para el cdkDropListDropped de kanban-component  -->
    <!-- cdkDropListData es un input que debe viene y debe ser manejado en kanban-component -->
    <div
      #bodyCards
      [id]="column._id || 'default'"
      class="kanban-column-body-cards"
      cdkDropList
      (cdkDropListDropped)="drop($event, column)"
      [cdkDropListData]="cards"
      [cdkDropListConnectedTo]="otherColumnsIds"
    >
      <roma-kanban-card
        *ngFor="let card of cards"
        cdkDrag
        [cdkDragStartDelay]="!deviceInfo.isDesktopDevice ? 200 : 0"
        [cdkDragDisabled]="disableDragDrop(card)"
        [type]="type"
        [card]="card"
        [statusData]="statusData"
        [showCardId]="showCardId"
        [showFinishCard]="showFinishCard"
        [showDeleteCard]="showDeleteCard"
        [showCancelCard]="showCancelCard"
        [showRestoreCard]="showRestoreCard"
        [finishSelected]="finishSelected"
        [placeholderAddCard]="placeholderAddCard"
        [deleteCardMessage]="deleteCardMessage"
        [restoreCardMessage]="restoreCardMessage"
        [isLoading]="isLoading"
        [featureUserCreate]="featureUserCreate"
        [featureUserUpdate]="featureUserUpdate"
        [featureUserDelete]="featureUserDelete"
        (onCardClick)="eventCardClick($event)"
        (onCardDblClick)="eventCardDblClick($event)"
        (onRestoreCard)="eventRestoreCard($event)"
        (onFinishCard)="eventFinishCard($event)"
        (onChangeStatusCard)="eventChangeStatusCard($event)"
        (onUnfinishCard)="eventUnfinishCard($event)"
        (onEditCard)="eventEditCard($event)"
        (onDeleteCard)="eventDeleteCard($event)"
        (onCancelCard)="eventCancelCard($event)"
        (onAddCard)="eventAddCard($event)"
        (onCardSelectChange)="eventCardSelectChange($event, column.name)"
      >
        <div *ngIf="cardDropPlaceHolderType === 'line'">
          <div
            class="kanban-column-custom-placeholderline"
            *cdkDragPlaceholder
          ></div>
        </div>
        <div *ngIf="cardDropPlaceHolderType === 'default'">
          <div
            class="kanban-column-custom-placeholderdefault"
            *cdkDragPlaceholder
          ></div>
        </div>
      </roma-kanban-card>
    </div>
    <div
      class="kanban-column-create-card"
      romaProFeature
      [featureUser]="featureUserCreate"
      (clicked)="eventAddCardClick('end')"
    >
      <img src="assets/icons/kanban/gl_plus-gray.svg" />
      {{ cardCreateText | translate }}
    </div>
  </ng-container>
</div>
<!-- mobile status card menu -->
<ng-container
  *ngIf="showStatusCardMobileMenu && (resize$ | async)?.payload?.mobile"
>
  <div class="mobile-viewtype-wrapper" style="position: fixed !important">
    <div class="viewtype-list">
      <h4>
        <ng-container *ngIf="!showMoveAllActivities">{{
          'kanban.options' | translate
        }}</ng-container>
        <ng-container *ngIf="showMoveAllActivities">
          <img
            (click)="showMoveAllActivities = false"
            src="assets/icons/gl_arrow-full-left.svg"
            style="margin-right: 10px"
          />
          {{ 'kanban.moveTo' | translate }}
        </ng-container>
      </h4>
      <div class="link-list">
        <ng-container *ngIf="!showMoveAllActivities">
          <div
            class="list-item"
            *ngIf="!isSystemColumn"
            (click)="editName = !editName; showStatusCardMobileMenu = false"
          >
            <mat-icon class="prev-icon">
              <img width="24px" src="assets/icons/kanban/edit.svg" alt="link" />
            </mat-icon>
            <span class="icon-text">
              {{ 'kanban.ranameColumn' | translate }}
            </span>
          </div>
          <div
            class="list-item"
            *ngIf="cards.length > 0"
            (click)="showMoveAllActivities = true"
          >
            <mat-icon class="prev-icon">
              <img width="24px" src="assets/icons/kanban/move.svg" alt="link" />
            </mat-icon>
            <span class="icon-text">
              {{ 'kanban.moveAllCards' | translate }}
            </span>
          </div>
          <div
            class="list-item"
            *ngIf="!isSystemColumn"
            romaClickDelete
            title="Eliminar"
            [message]="deleteColumnMessage"
            romaProFeature
            [featureUser]="featureUserDelete"
            (wantDelete)="eventDeleteColumn()"
          >
            <mat-icon class="prev-icon">
              <img
                width="24px"
                src="assets/icons/gl_trash_red.svg"
                alt="link"
              />
            </mat-icon>
            <span class="icon-text" style="color: #ff474e">
              {{ 'kanban.deleteColumn' | translate }}
            </span>
          </div>
          <div
            class="list-item"
            title="Eliminar"
            *ngIf="isSystemColumn"
            [message]="deleteColumnMessage"
            romaProFeature
            [featureUser]="featureUserDelete"
            (clicked)="deleteSystemColumnMobile()"
          >
            <mat-icon class="prev-icon" style="color: gray; opacity: 0.5">
              <img
                width="24px"
                src="assets/icons/gl_trash_red.svg"
                alt="link"
              />
            </mat-icon>
            <span class="icon-text" style="color: gray; opacity: 0.5">
              {{ 'kanban.deleteColumn' | translate }}
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="showMoveAllActivities">
          <div class="search-input">
            <img class="left" src="assets/icons/gl_search.svg" />
            <input
              #inputFilterSearch
              class="filter-input"
              type="text"
              [placeholder]="'clockHour.inputPlaceholder' | translate"
              (keyup)="onKeySearch($event)"
            />
          </div>
          <div *ngFor="let mainItem of objectKeys(_parsedColumns)">
            <div
              class="list-item"
              romaProFeature
              [featureUser]="featureUserUpdate"
              (clicked)="
                eventMoveAllCards(_parsedColumns[mainItem]);
                showMoveAllActivities = false;
                showStatusCardMobileMenu = false
              "
              *ngIf="
                _searchValue
                  ? mainItem.toLowerCase().includes(_searchValue.toLowerCase())
                  : true
              "
              style="padding: 15px 0 !important"
            >
              <span class="icon-text">
                {{ mainItem }}
              </span>
            </div>
          </div>
        </ng-container>
        <div class="close-btn-wrapper">
          <img
            (click)="showStatusCardMobileMenu = false"
            class="close-btn"
            src="/assets/icons/btn_close.svg"
            alt="close"
          />
        </div>
      </div>
    </div>
  </div>
</ng-container>
