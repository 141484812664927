<div class="safe-area-padding">
  <div class="modal__header">
    <div class="modal__header__top">
      <img
        class="modal__header__top__back"
        src="/assets/icons/gl_arrow-full-left.svg"
        alt="link"
        (click)="back()"
      />
      <div class="modal__header__top__title">
        {{ select.content.label | translate }}
      </div>
    </div>
    <div class="modal__header__body">
      <input
        type="text"
        class="modal__header__body__input"
        [placeholder]="'general.search' | translate"
        (keyup)="search($event)"
      />
    </div>
  </div>
  <div class="modal__body">
    <ng-container *ngIf="select.isLoading$ | async">
      <div class="modal__body__container__spinner">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="!(select.isLoading$ | async)">
      <ng-container
        *ngFor="
          let item of select.data$
            | async
            | filterByParams: searchParams.keyword
        "
      >
        <div class="link__list__item" (click)="changeCheckbox($event, item)">
          <mat-checkbox
            class="link__list__item__checkbox"
            [checked]="select.value().includes(item.value)"
          >
            <div class="link__list__item__text">
              {{ item.title | translate }}
            </div>
          </mat-checkbox>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
