import { ClockHour } from '@tacliatech/types';
import { RmFilter } from '@web-frontend/shared/components/globals/rm-filter';
import { UserSearchService } from '@web-frontend/shared/services';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

export const flightRequestUsers$ = (userSearchService: UserSearchService) =>
  userSearchService.search({ applyPaginate: false }).pipe(
    map((res) => {
      return res.docs
        .map((res) => {
          return {
            title: res.name.toString().toLocaleLowerCase(),
            value: res._id,
            id: res._id,
          };
        })
        .sort((a, b) => (a.title > b.title ? 1 : -1));
    })
  );

export const ClockStatus = [
  {
    title: 'clockAbsence.pending',
    value: ClockHour.Status.Pending,
    id: ClockHour.Status.Pending,
  },
  {
    title: 'clockAbsence.rejected',
    value: ClockHour.Status.Rejected,
    id: ClockHour.Status.Rejected,
  },
  {
    title: 'clockAbsence.approved',
    value: ClockHour.Status.Approved,
    id: ClockHour.Status.Approved,
  },
];

export const SOURCE_FILTER = new RmFilter.Filter([
  new RmFilter.Input({
    type: 'SEARCH',
    keyRequest: 'keyword',
    content: {
      label: '',
      placeholder: 'clockAbsence.inputPlaceholder',
    },
  }),
]);

export const SOURCE_FILTER_TEAM = new RmFilter.Filter([
  new RmFilter.Input({
    type: 'SEARCH',
    keyRequest: 'keyword',
    content: {
      label: '',
      placeholder: 'clockHourAdmin.inputPlaceholder',
    },
  }),
]);

export const SOURCE_SECONDARY_WEB_FILTER = (data: {
  userSearchService: UserSearchService;
}) => {
  return new RmFilter.Filter([
    new RmFilter.RangeDate({
      keyRequest: ['from', 'to'],
      content: {
        label: '',
        startPlaceholder: '',
        endPlaceholder: '',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'idOwners[]',
      source$: flightRequestUsers$(data.userSearchService),
      content: {
        label: '',
        placeholder: 'general.users',
      },
    }),
    new RmFilter.Select({
      type: 'SINGLE',
      keyRequest: 'status[]',
      source$: from([ClockStatus]),
      content: {
        label: '',
        placeholder: 'general.status',
      },
    }),
    new RmFilter.Hour({
      keyRequest: ['avgHoursWorked[]'],
      content: {
        label: 'general.hours',
        placeholder: 'general.hours',
        description: 'clockHour.hoursFilterTooltip',
      },
    }),
  ]);
};

export const SOURCE_MOBILE = (data: {
  userSearchService: UserSearchService;
}) => {
  return new RmFilter.Filter([
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'idOwners[]',
      source$: flightRequestUsers$(data.userSearchService),
      content: {
        label: 'general.users',
        placeholder: 'general.users',
      },
    }),
    new RmFilter.Select({
      type: 'SINGLE',
      keyRequest: 'status[]',
      source$: from([ClockStatus]),
      content: {
        label: 'general.status',
        placeholder: 'general.status',
      },
    }),
    new RmFilter.Hour({
      keyRequest: ['avgHoursWorked[]'],
      content: {
        label: 'general.hours',
        placeholder: 'general.hours',
        description: 'clockHour.hoursFilterTooltip',
      },
    }),
    new RmFilter.RangeDate({
      keyRequest: ['from', 'to'],
      content: {
        label: '',
        startPlaceholder: '',
        endPlaceholder: '',
      },
    }),
  ]);
};
