<ng-container>
  <div class="wrapper__body">
    <div class="wrapper__body__content">
      <div *ngIf="isLoading" class="col-12">
        <roma-loading></roma-loading>
      </div>

      <div [ngClass]="{ 'is-hidden': isLoading }">
        <div class="card-header">
          <div class="reference__text" *ngIf="dealData?._id">
            {{ 'deals.dealDetail.ref' | translate }}
            <span class="reference__number">{{ dealData?._id }}</span>
          </div>
          <h2 class="title">{{ dealData?.name }}</h2>
        </div>

        <roma-activities
          [dealId]="dealData._id"
          [showFilter]="false"
          [showTitle]="false"
          [hiddenNoData]="true"
          [showAddBtn]="true"
        ></roma-activities>
      </div>
    </div>
  </div>
</ng-container>
