<div class="kanban-card-description" *ngIf="showCardId">
  ID: {{ card?._id | split }}
</div>
<div class="kanban-card-header" *ngIf="card">
  <div *ngIf="card.onCreate; else notCreating">
    <div class="kanban-card-selector">
      <img
        *ngIf="!card.selected"
        src="assets/icons/kanban/checkbox-unselected.svg"
      />
    </div>
    <div class="kanban-card-title" [title]="card?.title">
      <input
        #createInput
        id="createInput"
        class="kanban-card-edit-input"
        type="text"
        focus="true"
        [placeholder]="placeholderAddCard | translate"
        (blur)="eventAddCard()"
        (keydown.enter)="eventAddCard()"
        [(ngModel)]="card.title"
        autofocus
      />
    </div>
  </div>
</div>
<ng-template #notCreating>
  <div
    class="kanban-card-selector"
    (mouseenter)="mouseInMenu = true"
    (mouseleave)="mouseInMenu = false"
  >
    <img
      *ngIf="!card.selected"
      (click)="eventCardSelectChange()"
      src="assets/icons/kanban/checkbox-unselected.svg"
    />
    <img
      *ngIf="card.selected"
      (click)="eventCardSelectChange()"
      src="assets/icons/kanban/checkbox-selected.svg"
    />
  </div>
  <div
    *ngIf="card?.title"
    class="kanban-card-title"
    [ngClass]="{ finished: card.finished }"
    [title]="card?.title"
  >
    {{ card?.title }}
  </div>
  <div
  class="kanban-card-subheading"
    [ngClass]="{
      finished: card.finished
    }"
    [title]="card?.title"
  >
    <span [ngClass]="{ 'as-title': !!!card?.title }">
      {{ card?.final }}
    </span>
  </div>
  <div
    class="kanban-card-menu"
    (mouseover)="overMenu()"
    (mouseout)="outMenu()"
    (click)="clickMenu()"
  >
    <img
      *ngIf="menuVisible"
      src="assets/icons/kanban/menu.svg"
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="menu"
      (mouseenter)="mouseInMenu = true"
      (mouseleave)="mouseInMenu = false"
    />
  </div>
</ng-template>
<div *ngIf="card.finished && finishSelected" class="finished-overlay"></div>
<div class="kanban-card-footer" *ngIf="!card.onCreate">
  <div class="kanban-card-footer-left">
    <div
      *ngIf="parsedDate"
      class="kanban-card-date"
      [ngClass]="{ red: showRedCard }"
    >
      <img src="assets/icons/kanban/calendar.svg" />
      {{ parsedDate }}
    </div>

    <div *ngIf="parsedHour" class="kanban-card-hour">
      <img src="/assets/icons/kanban/gl_clock_outline.svg" />
      {{ parsedHour }}
    </div>
  </div>
  <div class="kanban-card-footer-right">
    <div class="kanban-card-comments" *ngIf="card?.comments?.length > 0">
      <img src="assets/icons/kanban/comment.svg" />
      {{ card?.comments?.length }}
    </div>
    <div *ngIf="card?.assignedTo?.length > 0" class="kanban-card-assigned">
      <ng-container *ngFor="let assigned of card?.assignedTo; let i = index; trackBy assigned">
        <ng-container *ngIf="i <= 1">
          <img
            *ngIf="!assigned?.['img']; else assignedToImg"
            src="assets/icons/kanban/user.svg"
          />
          <ng-template #assignedToImg>
            <img src="{{ firebaseURL + assigned?.['img'] }}" />
          </ng-template>
        </ng-container>
      </ng-container>
      {{
        card?.assignedTo?.length > 2 ? '+' + (card.assignedTo.length - 2) : ''
      }}
    </div>
  </div>
</div>

<mat-menu class="actions-menu" #menu="matMenu" xPosition="before">
  <div (mouseleave)="_menuClick = false">
    <button
      class="update"
      mat-menu-item
      *ngIf="card.deleted && showRestoreCard"
      romaProFeature
      [featureUser]="featureUserUpdate"
      (clicked)="eventRestoreCard()"
      style="text-align: left; height: 44px"
    >
      <img src="assets/icons/kanban/restore.svg" style="margin-right: 8px" />
      {{ restoreCardMessage | translate }}
    </button>
    <button
      class="update"
      mat-menu-item
      *ngIf="card.finished && !card.deleted"
      romaProFeature
      [featureUser]="featureUserUpdate"
      (clicked)="eventUnfinishCard()"
      style="text-align: left"
    >
      <img src="assets/icons/kanban/check.svg" />
      {{ 'kanban.cardMarcUnFinish' | translate }}
    </button>
    <button
      class="update"
      mat-menu-item
      *ngIf="!card.finished && !card.deleted && showFinishCard"
      romaProFeature
      [featureUser]="featureUserUpdate"
      (clicked)="eventFinishCard()"
      style="text-align: left"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.4799 3.92748C12.8015 4.19503 12.8416 4.66827 12.5695 4.98447L6.97631 11.4845C6.83608 11.6474 6.63188 11.7439 6.41487 11.7497C6.19787 11.7556 5.98862 11.6702 5.83956 11.515L2.95821 8.51498C2.66898 8.21384 2.68278 7.73916 2.98902 7.45476C3.29526 7.17035 3.77798 7.18391 4.06721 7.48505L6.36284 9.87521L11.405 4.01556C11.6771 3.69935 12.1584 3.65992 12.4799 3.92748Z"
          fill="#6A79A0"
        />
      </svg>
      {{ 'hiring.type.finishService' | translate }}
    </button>
    <button
      class="update"
      mat-menu-item
      (click)="eventEditCard()"
      style="text-align: left"
    >
      <img src="assets/icons/kanban/edit.svg" />
      {{ 'kanban.cardEdit' | translate }}
    </button>
    <button
      style="text-align: left"
      mat-menu-item
      title="Mover a estado"
      [type]="type"
      [matMenuTriggerFor]="listOfStatuses"
      *ngIf="statusData.length > 0"
      (menuOpened)="_overSubMenu = true"
      (menuClosed)="_overSubMenu = false"
    >
      <img src="assets/icons/kanban/move.svg" class="icon-menu" />
      {{ 'kanban.moveToState' | translate }}
    </button>
    <mat-menu
      [hasBackdrop]="false"
      class="panel-choose panel-move"
      #listOfStatuses="matMenu"
      xPosition="after"
    >
      <ng-container *ngFor="let stat of statusData">
        <button
          *ngIf="stat.name !== card.column"
          mat-menu-item
          romaProFeature
          [featureUser]="featureUserUpdate"
          (clicked)="eventChangeStatusCard(card, stat)"
        >
          {{ stat.name }}
        </button>
      </ng-container>
    </mat-menu>
    <button
      class="update"
      *ngIf="!card.cancelled && showCancelCard"
      mat-menu-item
      romaProFeature
      [featureUser]="featureUserUpdate"
      (clicked)="eventCancelCard()"
      style="text-align: left"
    >
      <img src="assets/icons/kanban/cancel.svg" />
      {{ 'kanban.cancel' | translate }}
    </button>

    <button
      class="delete"
      *ngIf="!card.deleted && showDeleteCard"
      mat-menu-item
      (click)="emitEventAmplitudeDelete()"
      romaClickDelete
      [message]="deleteCardMessage"
      romaProFeature
      [featureUser]="featureUserDelete"
      (wantDelete)="eventDeleteCard()"
      style="text-align: left"
    >
      <img src="assets/icons/kanban/delete.svg" />
      {{ 'kanban.delete' | translate }}
    </button>
  </div>
</mat-menu>
