import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Feature, FeatureUser, IStatus, KanbanCard } from '@tacliatech/types';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import {
  DeviceInfoService,
  SandboxService,
} from '@web-frontend/shared/services';
import { Subscription } from 'rxjs';
import { GetDefaultColor } from '../../deals/deal-status-manager/deal-status-manager-.const';
import { ChooseColorModalService } from '../../deals/deal-status-manager/choose-color/choose-modal/choose-color-modal.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { ModalMobileInfoComponent } from '../../modal-mobile-info';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { DeviceDetectorService } from 'ngx-device-detector';
import AmplitudeEvents from 'src/types/amplitude.enum';

class column {
  _id?: string;
  name: string;
  color?: string;
}

@Component({
  selector: 'roma-kanban-column',
  templateUrl: './kanban-column.component.html',
  styleUrls: ['./kanban-column.component.scss'],
})
export class KanbanColumnComponent implements OnInit {
  @ViewChild('menuTrigger') trigger;
  @ViewChild('bodyCards') private myScrollContainer: ElementRef;

  objectKeys = Object.keys;

  @Input()
  column: column;

  @Input()
  isLoading = false;

  _parsedColumns: any = {};
  _parsedSearchColumns: any = {};
  _otherColumns: column[];
  @Input()
  set otherColumns(otherColumns: column[]) {
    this._parsedColumns = {};
    for (let i = 0; i < otherColumns.length; i++) {
      this._parsedColumns[otherColumns[i].name] = otherColumns[i];
    }
    this._otherColumns = otherColumns;
    this._parsedSearchColumns = { ...this._parsedColumns };
    this.getAllColumnsIds(this._otherColumns);
  }
  get otherColumns() {
    return this._otherColumns;
  }

  @Input()
  type: 'activity' | 'deal' = 'activity';

  _cards: KanbanCard[] = [];
  @Input()
  set cards(cards: KanbanCard[]) {
    this._cards = cards;
  }
  get cards() {
    return this._cards;
  }

  @Input()
  statusData: IStatus[] = [];

  @Input()
  isSystemColumn: boolean;

  @Input()
  lastCreatedCardTitle?: string;

  @Input()
  showCardId = false;

  @Input()
  showFinishCard = true;

  @Input()
  showDeleteCard = true;

  @Input()
  showCancelCard = true;

  @Input()
  showRestoreCard = true;

  @Input()
  deleteColumnMessage = 'kanban.deleteMessage';

  @Input()
  placeholderAddCard = 'kanban.placeHolderAddCard';

  @Input()
  cardCreateText = 'kanban.cardCreate';

  @Input()
  cardDropPlaceHolderType: 'line' | 'default' = 'default';

  @Input()
  deleteCardMessage = 'general.wantDelete';

  @Input()
  restoreCardMessage = 'general.wantDelete';

  @Input()
  tooltipDeleteSystemColumn = 'kanban.tooltipSystemColumn';

  @Input()
  finishSelected = false;

  @Input()
  featureUserCreate: Feature.Purchasable | FeatureUser.Purchasable =
    Feature.SystemPermission.DefaultAllow;

  @Input()
  featureUserUpdate: Feature.Purchasable | FeatureUser.Purchasable =
    Feature.SystemPermission.DefaultAllow;

  @Input()
  featureUserDelete: Feature.Purchasable | FeatureUser.Purchasable =
    Feature.SystemPermission.DefaultAllow;

  @Output()
  onEditColumn = new EventEmitter<{
    _id?: string;
    previousName: string;
    name: string;
    column: column;
  }>();

  @Output()
  onDeleteColumn = new EventEmitter<{ column: column; cards: KanbanCard[] }>();

  @Output()
  onCardClick = new EventEmitter<string>();

  @Output()
  onCardDblClick = new EventEmitter<string>();

  @Output()
  onAddCard = new EventEmitter<{ column: column; card: KanbanCard }>();

  @Output()
  onDeleteCard = new EventEmitter<KanbanCard>();

  @Output()
  onCancelCard = new EventEmitter<KanbanCard>();

  @Output()
  onEditCard = new EventEmitter<KanbanCard>();

  @Output()
  onFinishCard = new EventEmitter<KanbanCard>();

  @Output()
  onChangeStatusCard = new EventEmitter<KanbanCard>();

  @Output()
  onUnfinishCard = new EventEmitter<KanbanCard>();

  @Output()
  onRestoreCard = new EventEmitter<KanbanCard>();

  @Output()
  onMoveAllCards = new EventEmitter<{
    cards: KanbanCard[];
    column: column;
    previousColumn: column;
  }>();

  @Output()
  onCardSelectChange = new EventEmitter<KanbanCard>();

  @Output()
  onCardDrop = new EventEmitter<any>();

  editName = false;
  columnName: string;

  defaultColor = (color: string) => GetDefaultColor(color);
  deviceInfo;
  private sub$ = new Subscription();
  resize$ = this.sandBoxService.screenBusChannel$;
  showStatusCardMobileMenu = false;
  showMoveAllActivities = false;
  otherColumnsIds = [];
  @HostBinding('style.--innerHeight') innerHeight = '';
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerHeight = window.innerHeight + 'px';
    this.deviceInfo = this.deviceInfoService.getDeviceInfo();
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private i18n: TranslateService,
    private alertService: AlertService,
    private sandBoxService: SandboxService,
    private chooseColorModalService: ChooseColorModalService,
    private amplitudeService: AmplitudeService,
    private dialog: MatDialog,
    private permissionService: PermissionService,
    private deviceInfoService: DeviceInfoService
  ) {
    this.deviceInfo = this.deviceInfoService.getDeviceInfo();
  }

  ngOnInit(): void {
    this.innerHeight = window.innerHeight + 'px';

    this.columnName = this.column.name;
    if (this.cards.map((it) => it.title).includes(this.lastCreatedCardTitle)) {
      this.goToBottom('auto');
    }
    //console.log('los status que llegan: ', this.statusData);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  isMobile() {
    return window.screen.width < 768;
  }

  _tempColor: string = null;
  editColumn() {
    if (this._tempColor != null) {
      this.column.color = this._tempColor;
    }
    this.onEditColumn.emit({
      _id: this.column._id,
      previousName: this.column.name,
      name: this.columnName,
      column: this.column,
    });
    this._tempColor = null;
    this.editName = !this.editName;
    if (this.type === 'deal') {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_board_header_edit_save,
      });
    }
  }

  changeColor(evt: { darkColor: string; lightColor: string }) {
    this._tempColor = evt.lightColor;
  }

  closeColumnButton() {
    this.editName = !this.editName;
    this.columnName = this.column.name;
    this._tempColor = null;
    if (this.type === 'deal') {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_board_header_rename_cancel,
      });
    }
  }

  eventCardClick($event) {
    this.onCardClick.emit($event);
  }

  eventCardDblClick($event) {
    this.onCardDblClick.emit($event);
  }

  eventAddCardClick(position) {
    const cards = this.cards.filter((fil) => fil.onCreate == true);

    if (cards.length == 0) {
      this.cards.push({ onCreate: true });
    }
    this.goToBottom();
    if (this.type == 'activity') {
      this.amplitudeService.sendEvent({
        event: 'task_start',
      });
    } else {
      if (position == 'top') {
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_board_header_start,
        });
      } else {
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_board_start,
        });
      }
    }
  }

  goToBottom(behavior = 'smooth') {
    setTimeout(() => {
      this.myScrollContainer?.nativeElement.scroll({
        top: this.myScrollContainer.nativeElement.scrollHeight,
        behavior: behavior,
      });
    }, 200);
  }

  eventAddCard($event) {
    if ($event.title == null) {
      for (let i = this._cards.length - 1; i > -1; i--) {
        if (this._cards[i].onCreate == true) {
          this._cards.splice(i, 1);
        }
      }
      return;
    }

    this.onAddCard.emit({ column: this.column, card: $event });
  }

  eventDeleteColumn() {
    if (this.isSystemColumn) {
      return;
    }
    this.onDeleteColumn.emit({ column: this.column, cards: this.cards });
  }

  deleteSystemColumnMobile() {
    this.showStatusCardMobileMenu = false;
    const dialog = this.dialog.open(ModalMobileInfoComponent, {
      data: {
        html: 'kanban.dialog.canNotDeleteColumn',
        buttonText: 'kanban.dialog.confirmButton',
      },
    });
  }

  _searchValue;
  onKeySearch($event) {
    this._searchValue = $event.target.value;
  }

  eventFinishCard($event) {
    const index = this.cards.indexOf($event);
    if (this.cards[index].finished == true) {
      this.alertService.error(this.i18n.instant('kanban.errors.error4'));
      return;
    }
    this.cards[index].finished = true;
    if (index != -1 && index != null) {
      this.cards.splice(index, 1);
    }
    this.onFinishCard.emit($event);
  }

  eventChangeStatusCard($event) {
    this.onChangeStatusCard.emit($event);
  }

  eventUnfinishCard($event) {
    this.onUnfinishCard.emit($event);
  }

  eventEditCard($event) {
    this.onEditCard.emit($event);
  }

  eventDeleteCard($event) {
    const index = this.cards.indexOf($event);
    if (this.cards[index].finished == true) {
      this.alertService.error(this.i18n.instant('kanban.errors.error3'));
      return;
    }
    if (index != -1 && index != null) {
      this.cards.splice(index, 1);
    }
    this.onDeleteCard.emit($event);
  }

  eventCancelCard($event) {
    const index = this.cards.indexOf($event);
    if (this.cards[index].cancelled == true) {
      this.alertService.error(this.i18n.instant('kanban.errors.error3'));
      return;
    }
    if (index != -1 && index != null) {
      this.cards.splice(index, 1);
    }
    this.onCancelCard.emit($event);
  }

  eventMoveAllCards($event) {
    this.onMoveAllCards.emit({
      cards: this.cards,
      column: $event,
      previousColumn: this.column,
    });
  }

  eventCardSelectChange($event, cardTitle) {
    const evt = { card: $event, title: cardTitle };
    this.onCardSelectChange.emit(evt);
  }

  eventRestoreCard($event) {
    const index = this.cards.indexOf($event);

    if (index != -1 && index != null) {
      this.cards.splice(index, 1);
    }
    this.onRestoreCard.emit($event);
  }

  openChooseColor() {
    this.chooseColorModalService
      .open({ data: { color: this.column?.color } })
      .subscribe((res) => {
        this.changeColor(res);
      });
  }

  drop(event: CdkDragDrop<KanbanCard[]>, status: column) {
    const stat: IStatus = {
      _id: status._id,
      id: 1,
      name: status.name,
      allowEdit: false,
      order: 1,
    };
    this.onCardDrop.emit({ event, stat });
  }

  getAllColumnsIds(colums) {
    this.otherColumnsIds = colums.map(({ _id }) => _id || 'default');
  }

  disableDragDrop(card: KanbanCard) {
    return (
      this.isLoading ||
      card.deleted ||
      card.finished ||
      card.cancelled ||
      !this.permissionService.permissions.includes(this.featureUserUpdate)
    );
  }
}
