import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import { PartOfServiceProvider } from '@web-frontend/shared/services/part-of-services';
import { SendEmailPartOfService } from './send-email-part-of-service.types';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { ValidateAllFormFields } from '@tacliatech/types';
import { TranslateService } from '@ngx-translate/core';
import AmplitudeEvents from 'src/types/amplitude.enum';

@Component({
  selector: 'roma-send-email-part-of-service',
  templateUrl: './send-email-part-of-service.component.html',
  styleUrls: ['./send-email-part-of-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendEmailPartOfServiceComponent implements OnInit {
  viewData!: SendEmailPartOfService.ModalParams;

  sppiner = 'Enviando correo';

  form: FormGroup = this.fb.group({
    message: new FormControl(null, []),
    emails: new FormControl(null, [Validators.required]),
    subject: new FormControl(null, [Validators.required]),
    idDeal: new FormControl(null),
  });

  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  destinations: string[] = [];

  isLoading = false;
  messagePersonalizado: string;

  constructor(
    private dialogRef: MatDialogRef<
      SendEmailPartOfServiceComponent,
      SendEmailPartOfService.Response
    >,
    @Inject(MAT_DIALOG_DATA) public data: SendEmailPartOfService.ModalParams,
    private cdRef: ChangeDetectorRef,
    private partOfServiceProvider: PartOfServiceProvider,
    private alertService: AlertService,
    private fb: FormBuilder,
    private amplitudeService: AmplitudeService,
    public i18n: TranslateService
  ) {}

  ngOnInit(): void {
    this.viewData = this.data;
    this.destinations = this.viewData.emailsPreLoading;
    this.form.patchValue({ idDeal: this.viewData.idDeal });
    this.draw();
  }

  submit() {
    this.form.controls['emails'].setValue(this.destinations);
    this.form.controls['emails'].updateValueAndValidity();
    const isValid = this.checkValidators();

    if (isValid) {
      this.isLoading = true;
      //this.convertMesage();
      //console.log('after convert',this.payload());
      this.partOfServiceProvider.sendByEmail(this.payload()).subscribe(() => {
        //this.alertService.success(this.i18n.instant('general.mailSent'));
        this.isLoading = false;
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_proof_emailSend,
        });
        this.dialogRef.close();
      });
    }
    //this.alertService.error('Debe completar todos los campos');
  }

  close() {
    this.dialogRef.close();
  }

  changeEmails(evt: string[]) {
    this.viewData.emailsPreLoading = evt;
    this.draw();
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  convertMesage() {
    const m: string = this.form.get('message').value;
    this.messagePersonalizado = m.replace(/(?:\r\n|\r|\n)/g, '<br>');
    this.form.patchValue({ message: this.messagePersonalizado });
  }

  payload() {
    let m: string = this.form.get('message').value;

    if (m === null) {
      m = '';
    }

    this.messagePersonalizado = m.replace(/(?:\r\n|\r|\n)/g, '<br>');
    //this.form.patchValue({ message: this.messagePersonalizado });

    const value = Object.assign(this.form.value, {
      message: this.messagePersonalizado,
    });
    return value;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (
      value &&
      this.destinations.indexOf(value) === -1 &&
      value.match?.(EMAIL_REGEX)
    ) {
      this.destinations.push(value);
      this.patchTo();
    } else {
      // this.alertService.success("Correo invalido");
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(to: string): void {
    const index = this.destinations.indexOf(to);

    if (index >= 0) {
      this.destinations.splice(index, 1);
      this.patchTo();
    }
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);

    return this.form.valid;
  }

  patchTo() {
    this.form.patchValue({ emails: this.destinations });
  }
}
