import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SelectDealConfirmModalComponent } from './select-deal-confirm-modal.component';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SelectDealConfirmModalService {
  constructor(private dialog: MatDialog) {}

  open(config: MatDialogConfig<any> = new MatDialogConfig()) {
    const dialogRef = this.dialog.open(SelectDealConfirmModalComponent, config);

    return dialogRef.afterClosed().pipe(tap((res) => {}));
  }
}
