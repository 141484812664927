import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveModalModuleComponent } from './active-modal-module.component';
import { ModalTemplateModule } from '@web-frontend/shared/components/modal-template';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionsModule } from '@web-frontend/shared/components/permissions';
import { BaseButtonModule } from '@web-frontend/common/components/button/baseButton.module';

@NgModule({
  declarations: [ActiveModalModuleComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ModalTemplateModule,
    MatProgressSpinnerModule,
    PermissionsModule,
    BaseButtonModule,
  ],
  exports: [ActiveModalModuleComponent],
})
export class ActiveModalModuleModule {}
