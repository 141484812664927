import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BudgetTableComponent } from './budget-table.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EditableFieldModule } from '../editable-field';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { ClickRestoreModule } from '@web-frontend/shared/directives/click-restore/click-restore.module';
import { PermissionsModule } from '../permissions';
import { ClickStopPropagationModule } from '@web-frontend/shared/directives/click-prevent-default';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { RmBadgeModule } from '../globals/rm-badge';
import { CurrencyFormatModule } from '@web-frontend/shared/pipes/currency-format/currency-format.module';

@NgModule({
  declarations: [BudgetTableComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatListModule,
    MatInputModule,
    MatSelectModule,
    EditableFieldModule,
    ReactiveFormsModule,
    ClickDeleteModule,
    ClickRestoreModule,
    MatMenuModule,
    PermissionsModule,
    ClickStopPropagationModule,
    MatCheckboxModule,
    RmBadgeModule,
    CurrencyFormatModule,
  ],
  exports: [BudgetTableComponent],
})
export class BudgetTableModule {}
