<roma-modal-template
  [title]="
    params.type === 'COMPANY'
      ? ('pro.signCompany' | translate)
      : ('pro.signClient' | translate)
  "
  [showDefaultButtons]="false"
  [footerTemplate]="footerContainer"
  (cancel)="close()"
  (success)="submit()"
>
  <roma-signature
    #signature
    [id]="'signature-element'"
    [width]="signatureWidth"
    [height]="229"
  >
  </roma-signature>

  <ng-template #footerContainer>
    <div class="container-footer">
      <ng-container *ngIf="params?.previewMode">
        <div class="container-disclaimer">
          <div class="roma-disclaimer warning">
            <div class="icon top">
              <img src="assets/icons/circle-with-star.svg" />
            </div>
            <div class="body block">
              <span class="highlight">
                {{ 'pro.sign.disclaimer.title' | translate }}&nbsp;
              </span>
              <div
                [innerHtml]="
                  determineCopy('pro.sign.disclaimer.description') | translate
                "
              ></div>
            </div>
          </div>
        </div>
        <div class="modalFooter center">
          <div class="groupEndDiv">
            <base-button buttonClass="tertiary" (click)="close()">
              <ng-container *ngIf="!(resize$ | async)?.payload?.mobile">
                {{ 'general.cancel' | translate }}
              </ng-container>
            </base-button>
            <base-button
              romaProFeature
              [feature]="featureRef.Deal.SignDocuments"
            >
              <img src="assets/icons/star.svg" />
              {{ 'pro.sign.successText' | translate }}
            </base-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!params?.previewMode">
        <div class="modalFooter center">
          <div class="groupEndDiv">
            <base-button buttonClass="tertiary-danger" (click)="delete()">
              {{ 'general.delete' | translate }}
            </base-button>
            <base-button (click)="submit()">
              {{ 'billing.create.submit' | translate }}
            </base-button>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
</roma-modal-template>
