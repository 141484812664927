<div class="row h-100 container-billing-data" [formGroup]="form">
  <div class="title-card">
    {{ 'budgets.settings.title.billingdData' | translate }}
  </div>

  <form [formGroup]="form">
    <div class="global-container">
      <div class="row h-100">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="form-group">
            <label class="lbl" for="title">
              {{ 'budgets.settings.create.form.address' | translate }}(*)
            </label>

            <input
              type="text"
              class="form-control"
              id="address"
              aria-describedby="titleHelp"
              formControlName="address"
              maxLength="50"
              [placeholder]="
                'budgets.settings.create.form.addressPlaceholder' | translate
              "
              [ngClass]="{ 'is-invalid': fieldAddress.displayError || false }"
            />

            <roma-field-error-display
              #fieldAddress
              fieldName="address"
              [errorMsg]="'budgets.settings.create.form.addressError'"
              [form]="form"
            >
            </roma-field-error-display>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="form-group">
            <label class="lbl" for="title">
              {{ 'budgets.settings.create.form.city' | translate }}(*)
            </label>

            <input
              type="text"
              class="form-control"
              id="city"
              aria-describedby="titleHelp"
              formControlName="city"
              maxLength="50"
              [placeholder]="
                'budgets.settings.create.form.cityPlaceholder' | translate
              "
              [ngClass]="{ 'is-invalid': fieldCity.displayError || false }"
            />

            <roma-field-error-display
              #fieldCity
              fieldName="city"
              [errorMsg]="'budgets.settings.create.form.cityError'"
              [form]="form"
            >
            </roma-field-error-display>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="form-group">
            <label class="lbl" for="title">
              {{ 'budgets.settings.create.form.cp' | translate }}(*)
            </label>

            <input
              type="text"
              class="form-control"
              id="postalcode"
              aria-describedby="titleHelp"
              formControlName="postalcode"
              maxLength="50"
              [placeholder]="
                'budgets.settings.create.form.cpPlaceholder' | translate
              "
              [ngClass]="{
                'is-invalid': fieldPostalcode.displayError || false
              }"
            />

            <roma-field-error-display
              #fieldPostalcode
              fieldName="postalcode"
              [errorMsg]="'budgets.settings.create.form.cpError'"
              [form]="form"
            >
            </roma-field-error-display>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="form-group">
            <label class="lbl" for="title">
              {{ 'budgets.settings.create.form.state' | translate }}(*)
            </label>

            <input
              type="text"
              class="form-control"
              id="state"
              aria-describedby="titleHelp"
              formControlName="state"
              maxLength="50"
              [placeholder]="
                'budgets.settings.create.form.statePlaceholder' | translate
              "
              [ngClass]="{ 'is-invalid': fieldState.displayError || false }"
            />

            <roma-field-error-display
              #fieldState
              fieldName="state"
              [errorMsg]="'budgets.settings.create.form.stateError'"
              [form]="form"
            >
            </roma-field-error-display>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="form-group">
            <label class="lbl" for="title">
              {{ 'budgets.settings.create.form.country' | translate }}(*)
            </label>
            <ng-select
              [items]="countryCodes"
              [multiple]="false"
              bindLabel="name"
              formControlName="country"
              id="country"
              [placeholder]="
                'budgets.settings.create.form.countryPlaceholder' | translate
              "
              [notFoundText]="'hiring.form.placeholder_notfound' | translate"
              [ngClass]="{ 'is-invalid': fieldAsset?.displayError || false }"
            >
            </ng-select>

            <roma-field-error-display
              #fieldAsset
              fieldName="title"
              [errorMsg]="'checklist.assetError'"
            >
            </roma-field-error-display>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <button
            type="submit"
            [disabled]="!isFormValid() || form.pristine"
            class="custom-btn-save mt-24"
            mat-button
            color="primary"
            (click)="submit()"
          >
            {{
              mode === 'ADD'
                ? ('activity.create.submit' | translate)
                : ('activity.edit.submit' | translate)
            }}
            <i class="fa fa-check"></i>
          </button>
        </div>
      </div>
    </div>
  </form>
  <roma-snackbar-mexico
    *ngIf="!isFormValid()"
    type="warning"
    [message]="'budgets.settings.create.form.incompleteBillingData' | translate"
  ></roma-snackbar-mexico>
</div>
