<div *ngIf="isLoading" class="col-12">
  <roma-loading></roma-loading>
</div>

<!-- Disclaimer row -->
<roma-pro-disclaimer-banner
  *ngIf="isLowerThan1450px"
  (emitRefresh)="refresh()"
  [rootModule]="'sales'"
></roma-pro-disclaimer-banner>

<div *ngIf="!isLoading && onboardingDetail">
  <ng-container *ngIf="onboardingDetail.status === statusAccount.NOT_EXISTS">
    <roma-payment-activate></roma-payment-activate>
  </ng-container>

  <!--   <ng-container *ngIf="onboardingDetail.status === statusAccount.NOT_STARTED">
    <roma-payment-status
      [linkOnboarding]="linkOnboarding"
      [status]="onboardingDetail.status"
    ></roma-payment-status>
  </ng-container> -->

  <ng-container
    *ngIf="
      onboardingDetail.status === statusAccount.NOT_COMPLETED ||
      onboardingDetail.status === statusAccount.NOT_STARTED ||
      onboardingDetail.status === statusAccount.APPROVED_WITH_WARNING
    "
  >
    <roma-payment-status
      [linkOnboarding]="linkOnboarding"
      [status]="onboardingDetail.status"
    ></roma-payment-status>
  </ng-container>

  <ng-container
    *ngIf="
      onboardingDetail.status === statusAccount.REJECTED ||
      onboardingDetail.status === statusAccount.PENDING ||
      onboardingDetail.status === statusAccount.SUSPENDED
    "
  >
    <roma-payment-status
      [linkOnboarding]="linkOnboarding"
      [status]="onboardingDetail.status"
    ></roma-payment-status>
  </ng-container>

  <!-- Same banner as in payment methods or bills -->
  <!-- <roma-enable-payments-banner [large]="true"></roma-enable-payments-banner> -->

  <ng-container *ngIf="onboardingDetail.status === statusAccount.APPROVED">
    <roma-enable-payments-banner [large]="true"></roma-enable-payments-banner>
    <roma-payment-dashboard></roma-payment-dashboard>
  </ng-container>
</div>
