import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ChooseModalComponent } from './choose-modal.component';
import { ChooseColorModal } from './choose-modal.types';
import {
  MatBottomSheet,
  MatBottomSheetConfig,
} from '@angular/material/bottom-sheet';

@Injectable({
  providedIn: 'root',
})
export class ChooseColorModalService {
  private refreshListSource = new Subject<ChooseColorModal.Result>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private _bottomSheet: MatBottomSheet) {}

  open(
    config: MatBottomSheetConfig<ChooseColorModal.Params> = new MatBottomSheetConfig()
  ) {
    config.panelClass = ['panel__choose-color'];

    const dialogRef = this._bottomSheet.open<
      ChooseModalComponent,
      ChooseColorModal.Params,
      ChooseColorModal.Result
    >(ChooseModalComponent, config);

    return dialogRef.afterDismissed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }
}
