/* eslint-disable @typescript-eslint/no-namespace */

import { ICompany } from '../../interfaces';
import { FeatureUser } from '../permissions';

export namespace Company {
  export interface Output extends ICompany {
    modules?: Company.Module[];
  }

  export interface Module {
    id: Company.IdModule;
    payment: Company.ModulePaymentType;
    title: string;
    description: string;
    image: string;
    active: boolean;
    index: number;
    path: string;
    canAccess: boolean;
  }

  export interface CountUsers {
    companyUsers: number;
  }

  export type ModuleUpdatable = Partial<Omit<Company.Module, 'id' | 'index'>>;

  export type PutUpdateParams = {
    idModule: Company.IdModule;
  } & Company.ModuleUpdatable;

  export type PutUpdatStateModules = {
    category: Company.IdModuleSector;
    active: boolean;
  };

  export type PutUpdateIndex = { orders: Company.IdModule[] };

  export enum ModulePaymentType {
    Free = 'FREE',
  }
  export interface UrchinTrackingModule {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_gclid?: string;
    url_initial?: string;
    url_final?: string;
    url_external?: string;
  }

  export enum stChoiceEnum {
    Timetracking_choice = 'Timetracking_choice',
    Tasks_choice = 'Tasks_choice',
    Expenses_choice = 'Expenses_choice',
    Clients_services_choice = 'Clients_services_choice',
    Invoicing_choice = 'Invoicing_choice',
    Payments_choice = 'Payments_choice',
    OnlineBooking_choice = 'onlineBooking_choice',
    legacy = '',
  }

  export type IdModuleType = Company.IdModule;

  export enum IdModule {
    Activities = 'ACTIVITIES',
    Assets = 'ASSETS',
    ClockHours = 'CLOCK_HOURS',
    Deals = 'DEALS',
    Equipments = 'EQUIPMENTS',
    Expenses = 'EXPENSES',
    Finals = 'FINALS',
    Geolocation = 'GEOLOCATION',
    InternalVendors = 'INTERNAL_VENDORS',
    Payments = 'PAYMENTS',
    Projects = 'PROJECTS',
    Rates = 'RATES',
    Reports = 'REPORTS',
    Sales = 'SALES',
    Unknown = 'UNKNOWN',
    Users = 'USERS',
  }

  export type IdModuleGestionSectorType = Company.IdModuleGestionSector;

  export enum IdModuleGestionSector {
    ClockHours = 'CLOCK_HOURS',
    Activities = 'ACTIVITIES',
    Geolocation = 'GEOLOCATION',
    Equipments = 'EQUIPMENTS',
    Projects = 'PROJECTS',
    Assets = 'ASSETS',
  }

  export type IdModuleSalesSectorType = Company.IdModuleSalesSector;

  export enum IdModuleSalesSector {
    Deals = 'DEALS',
    Sales = 'SALES',
    Finals = 'FINALS',
    Reports = 'REPORTS',
    Payments = 'PAYMENTS',
  }

  export type IdModuleExpensesSectorType = Company.IdModuleExpensesSector;

  export enum IdModuleExpensesSector {
    Expenses = 'EXPENSES',
    InternalVendors = 'INTERNAL_VENDORS',
  }

  export type IdModuleSectorType = Company.IdModuleSector;

  export enum IdModuleSector {
    Sales = 'SALES',
    Gestion = 'GESTION',
    Expenses = 'EXPENSES',
    Activities = 'ACTIVITIES',
    ClockHours = 'CLOCK_HOURS',
  }

  export const RoutesMap = {
    [Company.IdModule.ClockHours]: '/admin/clock-hours',
    [Company.IdModule.Finals]: '/admin/finals',
    [Company.IdModule.Deals]: '/admin/deals',
    [Company.IdModule.Sales]: '/admin/budgets?type=BDG',
    [Company.IdModule.Projects]: '/admin/projects',
    [Company.IdModule.Payments]: '/admin/payments',
    [Company.IdModule.Activities]: '/admin/activities',
    [Company.IdModule.InternalVendors]: '/admin/internal-vendors',
    [Company.IdModule.Equipments]: '/admin/equipments',
    [Company.IdModule.Geolocation]: '/admin/tracking',
    [Company.IdModule.Expenses]: '/admin/expenses',
    [Company.IdModule.Reports]: '/admin/reports?type=service',
    [Company.IdModule.Assets]: '/admin/address',
  };

  export const RoutesPermissionsMap: Partial<
    {
      [key in Company.IdModule]: Array<{
        feature: string;
        url: string;
      }>;
    }
  > = {
    [Company.IdModule.ClockHours]: [
      { feature: FeatureUser.ClockHour.show, url: '/admin/clock-hours' },
      {
        feature: FeatureUser.ClockHourAbsence.show,
        url: '/admin/clock-absence',
      },
    ],
    [Company.IdModule.Finals]: [
      { feature: FeatureUser.Final.show, url: '/admin/finals' },
    ],
    [Company.IdModule.Deals]: [
      { feature: FeatureUser.Deal.show, url: '/admin/deals' },
    ],
    [Company.IdModule.Sales]: [
      { feature: FeatureUser.Sale.show, url: '/admin/sales?type=BDG' },
      { feature: FeatureUser.Quote.show, url: '/admin/budgets?type=BDG' },
      { feature: FeatureUser.Bill.show, url: '/admin/budgets?type=BILL' },
      {
        feature: FeatureUser.Proform.show,
        url: '/admin/budgets?type=PROFM',
      },
      {
        feature: FeatureUser.Waybills.show,
        url: '/admin/budgets?type=WAL',
      },
      { feature: FeatureUser.OnlineProfile.show, url: '/admin/online-booking' },
      { feature: FeatureUser.Catalogue.show, url: '/admin/rates' },
    ],
    [Company.IdModule.Projects]: [
      { feature: FeatureUser.Project.show, url: '/admin/projects' },
    ],
    [Company.IdModule.Payments]: [
      { feature: FeatureUser.Payment.show, url: '/admin/payments' },
    ],
    [Company.IdModule.Activities]: [
      { feature: FeatureUser.Activity.show, url: '/admin/activities' },
    ],
    [Company.IdModule.InternalVendors]: [
      {
        feature: FeatureUser.InternalVendor.show,
        url: '/admin/internal-vendors',
      },
    ],
    [Company.IdModule.Equipments]: [
      { feature: FeatureUser.Equipment.show, url: '/admin/equipments' },
    ],
    [Company.IdModule.Geolocation]: [
      { feature: FeatureUser.Geolocation.show, url: '/admin/tracking' },
    ],
    [Company.IdModule.Expenses]: [
      { feature: FeatureUser.Expense.show, url: '/admin/expenses' },
    ],
    [Company.IdModule.Reports]: [
      {
        feature: FeatureUser.Report.show,
        url: '/admin/reports?type=service',
      },
    ],
    [Company.IdModule.Assets]: [
      { feature: FeatureUser.Address.show, url: '/admin/address' },
    ],
  };

  export const FilterColumnReports = (data: {
    columnsReports: string[];
    nameColumnsReport: string[];
    idModules: Company.IdModule[];
    idActivesModules: Company.IdModule[];
  }): string[] => {
    const resColumns: string[] = [];
    data.columnsReports.forEach((column) => {
      const indexColumn = data.nameColumnsReport.indexOf(column);
      const moduleToEvaluate =
        indexColumn !== -1 ? data.idModules[indexColumn] : null;

      if (
        !data.nameColumnsReport.includes(column) ||
        (moduleToEvaluate && data.idActivesModules.includes(moduleToEvaluate))
      ) {
        resColumns.push(column);
      }
    });
    return resColumns;
  };
}
